import { css } from '@emotion/css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { splitStockForTruck, stockIndicator } from '../../base/utils/dataHelper'
import IPCheckbox from '../../components/IPCheckBox'
import { useAppDispatch } from '../../redux/app/hooks'
import {
  CompaniesGroup,
  IApplyCampaign,
  IAvailableCampModels,
  IBasketDetail,
  WarehousesGroup,
} from '../../redux/slices/basket/basketApi'
import { IProduct } from '../ProductList/models/response/getProductListResponseModel'
import { IOrderNote } from './models/models'
import { getApplyCampaignOnBasketRedux } from '../../redux/slices/basket/basketSlice'
import './BasketItem.scss'
import IPImage from '../../components/IPImage'
import Image from '../../components/IPImage/IPImage'
import IPPriceDisplay from '../../components/IPPriceDisplay'

export interface Props {
  bItem: IBasketDetail
  onProductSelectionChange: any
  reduceProductQuantity: any
  increaseProductQuantity: any
  addItemToBasket: any
  selectedProductIds: number[]
  SingleCampaignProduct: (data: any, modal: boolean) => void
  approval: boolean
  onRemoveProductFromBasket: (basketItem: IBasketDetail) => void
  company: CompaniesGroup
  warehouse: WarehousesGroup
  orderNotes: IOrderNote[]
  setOrderNotes: () => void
  setAddressModal: (ProductId: number, Company: number) => void
  singleCampaigns: any
  clearSingleCampaigns: () => void
  isSoftwareAdresAdd: boolean
  onQuantityChange: any
}

const quantityStyles = css`
  display: flex;
  margin: 0;

  span {
    display: block;
  }

  button {
    display: flex;
    align-items: center;
    color: #ababaf;
    width: 17px;
    height: 26px;
    margin: 0 !important;
    border: none;
    background: none;

    &.plus {
      padding-left: 0;
    }
  }

  input {
    width: 36px !important;
    height: 24px !important;
    min-height: 20px !important;
    margin: 0;
    padding: 0 !important;
    text-align: center;
    line-height: 18px;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    background-color: #ffffff;
    padding-top: 5px;
  }
`

const BasketItem = React.memo(function BasketItem(props: Props) {
  const [bItem, setBItem] = useState<IBasketDetail>(props.bItem)
  const [quantity, setQuantity] = useState(0)

  useEffect(() => {
    setBItem(props.bItem)
    setQuantity(props.bItem.Quantity)
  }, [props.bItem])

  const dispatchRedux = useAppDispatch()

  const changeProductQuantity = (product: IProduct, quantities: string) => {
    const quantityTmp = Number(quantities)
    if (!isNaN(quantityTmp) && bItem.OpportunityId && bItem.OpportunityId > 0) {
      if (product.OpportunityStock > quantityTmp) {
        props.addItemToBasket({
          ProductId: product.Id,
          DetailId: bItem.Id,
          Quantity: Number(quantities) - bItem.Quantity,
          IsOppurtunityStock: bItem.OpportunityId && bItem.OpportunityId > 0 ? true : false,
          OpportunitiyStockId: bItem.OpportunityId && bItem.OpportunityId > 0 ? bItem.OpportunityId : 0,
        })
      } else {
        if (quantityTmp > product.OpportunityStock) {
          Swal.fire({
            icon: 'warning',
            title: 'Girdiğiniz miktarda stok bulunmamaktadır!',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
        setBItem(props.bItem)
        setQuantity(bItem.Quantity)
      }
    } else if (!isNaN(quantityTmp) && product.Stock && product.Stock.Stock && quantityTmp <= product.Stock.Stock) {
      props.addItemToBasket({
        ProductId: product.Id,
        DetailId: bItem.Id,
        Quantity: Number(quantities) - bItem.Quantity,
        IsOppurtunityStock: bItem.OpportunityId && bItem.OpportunityId > 0 ? true : false,
        OpportunitiyStockId: bItem.OpportunityId && bItem.OpportunityId > 0 ? bItem.OpportunityId : 0,
      })
    } else {
      if (quantityTmp > product.Stock.Stock) {
        Swal.fire({
          icon: 'warning',
          title: 'Girdiğiniz miktarda stok bulunmamaktadır!',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
      setBItem(props.bItem)
      setQuantity(bItem.Quantity)
    }
  }

  const changeCampaign = (DetailId: number, ProductId: number, CampaignId: number) => {
    const data: IApplyCampaign = {
      DetailId: DetailId,
      ProductId: ProductId,
      CampaignId: CampaignId,
    }
    dispatchRedux(getApplyCampaignOnBasketRedux(data))
  }

  const renderBrandImage = () => {
    const imageUrl = bItem.Product.BrandDetail?.ImageUrl

    if (!imageUrl) return null

    const isImage =
      imageUrl.indexOf('.jpg') > -1 ||
      imageUrl.indexOf('.jpeg') > -1 ||
      imageUrl.indexOf('.png') > -1 ||
      imageUrl.indexOf('.webp') > -1

    if (isImage) {
      return (
        <img
          src={imageUrl}
          alt=''
          style={{
            width: '30px',
            border: '1px solid #C4C4C4',
            borderRadius: '50%',
          }}
        />
      )
    }
    return null
  }

  let isSelectedGift
  if (props.singleCampaigns.length > 0) {
    props.singleCampaigns.forEach(x => {
      if (x.CampaignInfoModel.CampaignBundleID === bItem.CampaignInfoModel.CampaignBundleID && x.isSelected) {
        isSelectedGift = true
      }
    })
  }

  const campaignSVG = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='18' height='18' fill='#fff'>
      <path d='M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z' />
    </svg>
  )

  return (
    <div
      key={bItem.Id}
      className='productCard productCard2'
      style={{ minHeight: '120px', boxShadow: 'none', paddingInline: 0 }}
    >
      {/* <div className="indicator topLeft">
        {" "}
        <img src="/images/products/discount.svg" alt="" />
      </div> */}
      {bItem.IsSingleCampaign && (
        <div className='indicator topRight'>
          {campaignSVG}
          {isSelectedGift && (
            <span
              className={css`
                position: absolute;
                top: 0;
                right: -3px;
                font-size: 12px;
                line-height: 10px;
                z-index: 5;
              `}
            >
              ✅
            </span>
          )}
        </div>
      )}
      {bItem.IsCampaignExtra ? null : (
        <IPCheckbox
          label=''
          onCheckedChange={(checked: boolean) => props.onProductSelectionChange(checked, bItem.Id)}
          checked={props.selectedProductIds && props.selectedProductIds.findIndex((id: number) => id === bItem.Id) > -1}
        />
      )}
      {bItem.Product && bItem.Product.OpportunityId && bItem.Product.OpportunityId > 0 ? (
        <div className='indicator topLeft2' title='Fırsat Ürünü' style={{ top: '0px', left: '-20px' }}>
          <IPImage src='/images/products/box-percent.png' alt='Fırsat Ürünü' />
        </div>
      ) : null}

      <div className='productImage' style={{ margin: 'inherit', overflow: 'inherit' }}>
        <Link
          to={
            bItem.OpportunityId && bItem.OpportunityId > 0
              ? `/opportunit/detail/${bItem.OpportunityId}`
              : `/product/detail/${bItem.Product.Id}`
          }
          style={{ overflow: 'hidden' }}
        >
          {bItem.Product.Image ? (
            <Image
              src={bItem.Product.Image}
              alt={bItem.Product.Name}
              //style={{ objectFit: 'contain' }}
            />
          ) : (
            <Image src='./images/products/Resim_yok-01.jpg' alt='' style={{ objectFit: 'contain' }} />
          )}
        </Link>
      </div>
      <div
        className='productPrices flex-row'
        style={{
          width: '95px',
        }}
      >
        {/* <span
          className='price2'
          style={{
            color: '#ABABAF',
            fontSize: '13px',
          }}
        >
          {bItem && bItem.Product && bItem.Product.Prices.Special.Price}
        </span> */}
        <IPPriceDisplay
          useDiv={false}
          color='#ABABAF'
          price={bItem && bItem.Product && bItem.Product.Prices.Special.Price}
          integerFontSize='13px'
        />
      </div>
      <img
        src={props.company.CompanyID === 2 ? '/images/netex_logo.png' : props.company.CompanyLogo}
        className='d-block mr-2'
        style={{ width: '50px' }}
        alt=''
      />
      <div className='d-flex flex-column align-items-center gap-3'>
        {bItem.Product && bItem.Product.WarrantyMonth > 0 && (
          <div className='hasTooltip' style={{ width: '30px' }}>
            <img src='/images/products/quality2.png' alt='' />
            <div className='otooltip'>Garanti Süresi: {bItem.Product.WarrantyMonth} Ay</div>
          </div>
        )}
        {bItem.WarehouseName && bItem.WarehouseName === '1920' && (
          <div
            className='hasTooltip'
            style={{ width: '40px', cursor: 'pointer' }}
            onClick={() =>
              props.setAddressModal(
                bItem.ProductID,
                bItem.CompanyID === 1 ? 1000 : bItem.CompanyID === 2 ? 2300 : bItem.CompanyID === 3 ? 1200 : 1100
              )
            }
          >
            {props.isSoftwareAdresAdd === true ? (
              <img src='/images/products/yesil_ikon-01.jpg' alt='Done' />
            ) : (
              <img className='licence' src='/images/products/kirmizi_ikon-01.jpg' alt='' />
            )}
            <div className='otooltip'>Lütfen son kullanıcı bilgilerini girmek için tıklayınız.</div>
          </div>
        )}
      </div>
      {renderBrandImage()}
      <div style={{ maxWidth: '295px', width: '100%' }}>
        <Link
          to={
            bItem.OpportunityId && bItem.OpportunityId > 0
              ? `/opportunit/detail/${bItem.OpportunityId}`
              : `/product/detail/${bItem.Product.Id}`
          }
        >
          <div className='productName' title={bItem.Product.Name}>
            {bItem.Product.Name.slice(0, 40)}
            {bItem.Product.Name.length > 40 && '...'}
          </div>
        </Link>
        <div className='productCode d-flex justify-content-between'>
          <div>
            <span>Ürün kodu:&nbsp;{bItem.Product.ProductCode}</span>
          </div>
          {bItem.Product.StockForTruck && splitStockForTruck(bItem.Product.StockForTruck).length > 0 && (
            <div className='hasTooltip'>
              <img src='/images/products/truck.svg' alt='' />
              <div className='otooltip'>
                {splitStockForTruck(bItem.Product.StockForTruck).map(st => {
                  return 'tarih : ' + st.date + ' stok : ' + st.stok
                })}
              </div>
            </div>
          )}
        </div>
        {bItem.CampaignInfoModel &&
          bItem.CampaignInfoModel.IsCampaign &&
          bItem.CampaignInfoModel.CampaignDescription && (
            <div
              className='productCode'
              style={{
                color: 'red',
                marginTop: '5px',
                height: '40px',
                width: '380px',
              }}
            >
              <span
                style={{
                  whiteSpace: 'nowrap',
                  lineHeight: '2rem',
                }}
              >
                {bItem.CampaignInfoModel?.CampaignDescription}
              </span>
            </div>
          )}
      </div>
      {bItem.IsCampaignExtra ? null : (
        <div className='stock m-t-5'>
          <span>
            {bItem.Product?.OpportunityId != undefined && bItem.Product?.OpportunityId > 0
              ? stockIndicator(bItem.Product?.OpportunityStock)
              : bItem.Product?.Stock && bItem.Product?.Stock.Stock
              ? stockIndicator(bItem.Product?.Stock.Stock)
              : '0'}
          </span>
        </div>
      )}
      <div className={quantityStyles}>
        {bItem.IsCampaignExtra ? null : (
          <button type='button' className='p-2 bd-highlight minus'>
            <i
              className='fas fa-angle-left'
              onClick={() => {
                if (!props.approval) {
                  props
                    .reduceProductQuantity({
                      DetailId: bItem.Id,
                      Quantity: 0,
                    })
                    .then(() => {
                      props.onQuantityChange()
                    })
                    .catch(error => {
                      Swal.fire({
                        icon: 'error',
                        title: 'Hata',
                        text: JSON.stringify(error.message),
                        showConfirmButton: true,
                        allowOutsideClick: false,
                      })
                    })
                } else {
                  Swal.fire({
                    icon: 'question',
                    title:
                      'Adresi onayladığınız için ürün sayısını azaltamazsınız. Adres onayını kaldırmak ister misiniz?',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    showDenyButton: true,
                    confirmButtonText: 'Evet',
                    denyButtonText: 'Hayır',
                  }).then(async result => {
                    result.isConfirmed && window.location.reload()
                  })
                }
              }}
            ></i>
          </button>
        )}

        <input
          disabled={props.approval || bItem.IsCampaignExtra}
          type='text'
          className='bd-highlight qty'
          name='quantity'
          value={quantity}
          onBlur={e => {
            if (bItem.IsCampaignExtra) return
            if (+e.target.value > 0) changeProductQuantity(bItem?.Product, e.target.value)
            else {
              setQuantity(1)
              changeProductQuantity(bItem?.Product, '1')
            }
          }}
          onChange={e => {
            if (bItem.IsCampaignExtra) return
            if (bItem.OpportunityId && bItem.OpportunityId > 0) {
              if (bItem?.Product?.OpportunityStock > bItem?.Quantity) {
                if (!props.approval) {
                  const quantityTmp = Math.floor(Number(e.target.value))
                  if (!isNaN(quantityTmp)) {
                    setQuantity(Number(quantityTmp))
                  }
                  props.onQuantityChange()
                } else {
                  Swal.fire({
                    icon: 'question',
                    title:
                      'Adresi onayladığınız için ürün sayısını değiştiremezsiniz. Adres onayını kaldırmak ister misiniz?',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    showDenyButton: true,
                    confirmButtonText: 'Evet',
                    denyButtonText: 'Hayır',
                  }).then(res => {
                    res.isConfirmed && window.location.reload()
                  })
                }
              }
            } else if (bItem?.Product?.Stock?.Stock > bItem?.Quantity) {
              if (!props.approval) {
                const quantityTmp = Math.floor(Number(e.target.value))
                if (!isNaN(quantityTmp)) {
                  setQuantity(Number(quantityTmp))
                }
                props.onQuantityChange()
              } else {
                Swal.fire({
                  icon: 'question',
                  title:
                    'Adresi onayladığınız için ürün sayısını arttıramazsınız. Adres onayını kaldırmak ister misiniz?',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                  showDenyButton: true,
                  confirmButtonText: 'Evet',
                  denyButtonText: 'Hayır',
                }).then(res => {
                  res.isConfirmed && window.location.reload()
                })
              }
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Girdiğiniz miktarda stok bulunmamaktadır!',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            }
          }}
        />

        {bItem.IsCampaignExtra ? null : (
          <button type='button' className='p-2 bd-highlight plus'>
            <i
              className='fas fa-angle-right'
              onClick={() => {
                if (bItem.OpportunityId && bItem.OpportunityId > 0) {
                  if (bItem?.Product?.OpportunityStock > bItem?.Quantity) {
                    if (!props.approval) {
                      props
                        .increaseProductQuantity({
                          DetailId: bItem.Id,
                          Quantity: 0,
                        })
                        .then(() => {
                          props.onQuantityChange()
                        })
                        .catch(error => {
                          Swal.fire({
                            icon: 'error',
                            title: 'Hata',
                            text: JSON.stringify(error.message),
                            showConfirmButton: true,
                            allowOutsideClick: false,
                          })
                        })
                    } else {
                      Swal.fire({
                        icon: 'question',
                        title:
                          'Adresi onayladığınız için ürün sayısını arttıramazsınız. Adres onayını kaldırmak ister misiniz?',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        showDenyButton: true,
                        confirmButtonText: 'Evet',
                        denyButtonText: 'Hayır',
                      }).then(res => {
                        res.isConfirmed && window.location.reload()
                      })
                    }
                  } else {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Girdiğiniz miktarda stok bulunmamaktadır!',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                    })
                  }
                } else if (bItem?.Product?.Stock?.Stock > bItem?.Quantity) {
                  if (!props.approval) {
                    props
                      .increaseProductQuantity({
                        DetailId: bItem.Id,
                        Quantity: 0,
                      })
                      .then(() => {
                        props.onQuantityChange()
                      })
                      .catch(error => {
                        Swal.fire({
                          icon: 'error',
                          title: 'Hata',
                          text: JSON.stringify(error.message),
                          showConfirmButton: true,
                          allowOutsideClick: false,
                        })
                      })
                  } else {
                    Swal.fire({
                      icon: 'question',
                      title:
                        'Adresi onayladığınız için ürün sayısını arttıramazsınız. Adres onayını kaldırmak ister misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(res => {
                      res.isConfirmed && window.location.reload()
                    })
                  }
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Girdiğiniz miktarda stok bulunmamaktadır!',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                }
              }}
            ></i>
          </button>
        )}
      </div>
      <div className='d-flex flex-column gap-2 align-items-center'>
        {bItem.CampaignInfoModel?.IsCampaign && bItem.IsSingleCampaign && (
          <>
            <div
              onClick={() => {
                props.SingleCampaignProduct(bItem, true)
                // handleGiftAlert()
              }}
            >
              <i
                className='fa fa-exchange text-info'
                style={{
                  // fontSize: giftAlert ? '28px' : '22px',
                  // zIndex: giftAlert ? 12 : 1,
                  fontSize: '22px',
                  zIndex: 1,
                  cursor: 'pointer',
                  position: 'relative',
                }}
                aria-hidden='true'
              ></i>
            </div>
            {/* {giftAlert && giftAlertMarkup} */}
          </>
        )}
      </div>
      <div
        className='productPrices flex-row'
        style={{
          width: '120px',
          justifyContent: 'end',
        }}
      >
        <div className='d-flex flex-column align-items-center'>
          <del className='price2' style={{ color: '#ABABAF', fontSize: '13px' }}>
            {bItem.CampaignInfoModel && bItem.CampaignInfoModel.IsCampaign
              ? bItem.BasketDetailPrice?.SpecialDisplay
              : ''}
          </del>

          {/* <span style={{ color: '#EE333E', fontSize: '1.4rem' }}>
            {bItem.CampaignInfoModel && bItem.CampaignInfoModel.IsCampaign
              ? bItem.CampaignBasketDetailPrice?.SpecialDisplay
              : bItem.BasketDetailPrice
              ? bItem.BasketDetailPrice?.SpecialDisplay
              : bItem.Product?.Prices?.Special?.Price}
          </span> */}

          <IPPriceDisplay
            useDiv={true}
            color='#EE333E'
            price={
              bItem.CampaignInfoModel && bItem.CampaignInfoModel.IsCampaign
                ? bItem.CampaignBasketDetailPrice?.SpecialDisplay
                : bItem.BasketDetailPrice
                ? bItem.BasketDetailPrice?.SpecialDisplay
                : bItem.Product?.Prices?.Special?.Price
            }
            integerFontSize='1.4rem'
            fractionalFontSize='0.9rem'
          />
        </div>
      </div>
      {bItem.IsCampaignExtra ? null : (
        <div style={{ marginTop: '-10px', cursor: 'pointer' }}>
          <div
            onClick={() => {
              if (!props.approval) {
                props.onRemoveProductFromBasket(bItem)
              } else {
                Swal.fire({
                  icon: 'question',
                  title: 'Adresi onayladığınız için ürünü silemezsiniz. Adres onayını kaldırmak ister misiniz?',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                  showDenyButton: true,
                  confirmButtonText: 'Evet',
                  denyButtonText: 'Hayır',
                }).then(res => {
                  res.isConfirmed && window.location.reload()
                })
              }
            }}
          >
            <img src='/images/products/trash.svg' alt='' />
          </div>
        </div>
      )}
      <div className='d-flex flex-wrap gap-3 w-100' style={{ display: 'contents' }}>
        {bItem.AvailableCamps &&
          bItem.AvailableCamps.map((campaign: IAvailableCampModels) => {
            return (
              <div className='d-flex align-items-center gap-2' key={campaign.Id}>
                <input
                  className='white-circle-input form-check-input flex-shrink-0'
                  name={`campaign${bItem.ProductID}`}
                  id={`campaign${bItem.ProductID}`}
                  value={campaign.Id}
                  checked={campaign.IsActive}
                  type='radio'
                  onChange={() => {
                    if (!props.approval) {
                      props.clearSingleCampaigns()
                      changeCampaign(bItem.Id, bItem.ProductID, campaign.Id)
                    } else {
                      Swal.fire({
                        icon: 'question',
                        title:
                          'Adresi onayladığınız için kampanya seçemezsiniz. Adres onayını kaldırmak ister misiniz?',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        showDenyButton: true,
                        confirmButtonText: 'Evet',
                        denyButtonText: 'Hayır',
                      }).then(async result => {
                        result.isConfirmed && window.location.reload()
                      })
                    }
                  }}
                />
                <label
                  className={`m-0 py-0 px-2 ${css`
                    border-radius: 0 !important;
                  `}`}
                  htmlFor='campaing2'
                >
                  {campaign.CampaignDescription}
                </label>
              </div>
            )
          })}
      </div>
    </div>
  )
})

export default BasketItem
