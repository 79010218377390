import React, { useState, useEffect, useMemo } from 'react'
import IPButton from '../../../../../components/IPButton'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'

import IPSelectBox from '../../../../../components/IPSelectBox'
import { ICompany } from '../../../components/models/models'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import { getCompanyApi } from '../../../components/api'
import Table from '../../../../../components/Table'
import { IBasketQuotaReportRequest, IBasketQuotaReportResponse } from '../models/models'
import { exportToXLSX, getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import Swal from 'sweetalert2'
import { BasketQuotaReportApi } from '../services/basketQuotaReportApi'
import InputCampaign from '../../../../../components/InputCampaign'

interface IBasketQuotaReportState {
  companiesOption?: ISelectOption
  basketQuotaTable: IBasketQuotaReportResponse[]
}

function BasketQuotaReport(props: BaseComponentWithContextProps) {
  const [companies, setCompanies] = useState<ICompany[]>([])

  const getInitialState = (): IBasketQuotaReportState => {
    return {
      basketQuotaTable: []
    }
  }

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IBasketQuotaReportState>(intialState)
  const t = translate(Translations)

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push(co)
      })
    setCompanies(data)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const getCompany = (company: string) => {
    const companyMap = {
      '1000': 'INDEX',
      '1100': 'DATAGATE',
      '1200': 'DESPEC',
      '2300': 'NETEX',
    };
    return companyMap[company] || '';
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Bayi Kodu',
        accessor: 'col1',
      },
      {
        Header: 'Bayi Ünvanı',
        accessor: 'col2',
      },
      {
        Header: 'Grup Kodu',
        accessor: 'col3',
      },
      {
        Header: 'Şirket',
        accessor: 'col4',
      },
      {
        Header: 'Kota',
        accessor: 'col5',
      },
      {
        Header: 'Banka',
        accessor: 'col6',
      },
      {
        Header: 'Tutar',
        accessor: 'col7',
      },
      {
        Header: 'Taksit',
        accessor: 'col8',
      },
      {
        Header: 'İşlem Tarihi',
        accessor: 'col9',
      },
      {
        Header: 'BIY No',
        accessor: 'col16',
      },
      {
        Header: 'Sipariş No',
        accessor: 'col10',
      },
      {
        Header: 'Adet',
        accessor: 'col11',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col12',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'col13',
      },
      {
        Header: 'Satır Toplamı',
        accessor: 'col14',
      },
      {
        Header: 'Ödeme Yöntemi',
        accessor: 'col15',
      },
    ],
    []
  )

  const tableData = useMemo(
    () => state.basketQuotaTable.map(item => {
      const data = {
        col1: item.BayiKodu.replace("0000", ""),
        col2: item.BayiUnvan,
        col3: item.KampanyaAdi,
        col4: getCompany(item.Sirket),
        col5: item.KotaKodu,
        col6: item.Banka,
        col7: "",
        col8: item.Taksit,
        col9: new Date(item.IslemTarihi).toLocaleString(),
        col10: item.SiparisNumarasi,
        col11: item.Adet,
        col12: item.UrunKod,
        col13: item.Urunadi,
        col14: item.IyzicoPrice.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.'),
        col15: item.OdemeYontemi,
        col16: item.BIYNumber
      }
      if (item.OdemeYontemi === "Iyzico 3D") {
        data.col7 = (item.ToplamSiparisTutari / 100.0).toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      } else {
        data.col7 = item.ToplamSiparisTutari.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      }

      return data
    }), [state.basketQuotaTable])

  const excelButton = async () => {
    try {
      if (state.basketQuotaTable) {
        const data: any[] = []
        if (state.basketQuotaTable.length > 0) {
          state.basketQuotaTable.map(p => {

            let tutar = p.ToplamSiparisTutari;
            if (p.OdemeYontemi === "Iyzico 3D") {
              tutar /= 100.0;
            }
            return data.push({
              'Bayi Kodu': +p.BayiKodu,
              'Bayi Ünvanı': p.BayiUnvan,
              'Grup Kodu': p.KampanyaAdi,
              'Şirket': getCompany(p.Sirket),
              Kota: p.KotaKodu,
              Banka: p.Banka,
              Tutar: tutar,
              Taksit: p.Taksit,
              'İşlem Tarihi': p.IslemTarihi,
              'Sipariş No': p.SiparisNumarasi,
              'BIY No': p.BIYNumber,
              Adet: p.Adet,
              'Ürün Kodu': p.UrunKod,
              'Ürün Adı': p.Urunadi,
              'Satır Toplamı': p.IyzicoPrice,
              'Ödeme Yöntemi': p.OdemeYontemi,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Sepet Kota Raporu')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const orderReturnStateChange = async () => {
    if (!startDate || !endDate || !state.companiesOption) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarih ve şirket seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: IBasketQuotaReportRequest = {
      StatDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate),
      CompanyCode: state.companiesOption ? String(state.companiesOption.value) : ""
    }
    props.showLoading()
    const basketQuotaReportApi: BasketQuotaReportApi = new BasketQuotaReportApi({})
    basketQuotaReportApi
      .basketQuotaReport(req)
      .then((result: IBasketQuotaReportResponse[]) => {
        setState({ basketQuotaTable: result })
        props.hideLoading()
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }
  return (
    <div className='content col-lg-12 m-auto'>
      <div className='card'>
        <div className='card-header d-flex justify-content-between align-items-center'>
          <span className='h4 mb-0'>
            {t('pages.admin.dealerAndOrderReport.basketQuotaReport.basketQuotaReport.header')}
          </span>
          <div className='d-flex align-items-center'>
            <span>Excel: </span>
            <button
              type='button'
              style={{
                border: '1px solid #2e7d32',
                borderRadius: 0,
                width: '29px',
                height: '29px',
                backgroundColor: 'white',
                margin: '0 10px',
              }}
              onClick={excelButton}
              className='btn text-capitalize background-white-important d-flex align-items-center'
            >
              <i
                className='fas fa-file-excel'
                style={{
                  marginLeft: '-6px',
                  fontSize: '18px',
                  color: '#2e7d32',
                }}
              ></i>
            </button>
          </div>
        </div>
        <div className='card-body'>
          <form className='formbanner'>
            <div className='row mb-3'>
              <div className='col-12 col-md-3 mb-3'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, "Start")}
                  isActive
                />
              </div>
              <div className='col-12 col-md-3 mb-3'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, "End")}
                  isActive
                />
              </div>
              <div className='col-12 col-md-3 mb-3'>
                <IPSelectBox
                  isClearable
                  options={
                    companies &&
                    companies.map((item: ICompany) => ({
                      label: item.Name,
                      value: `${item.Code}`,
                    }))
                  }
                  placeholder="Şirket Seçiniz..."
                  value={state.companiesOption}
                  onChangeSingle={option => setState({ companiesOption: option })}
                />
              </div>
              <div className='col-12 col-md-2 mb-3'>
                <IPButton text='Göster' onClick={orderReturnStateChange} />
              </div>
            </div>
          </form>
          <div className='m-4'>
            <Table columns={tableColumns} data={tableData} currentPage={0} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(BasketQuotaReport)
