import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IPostCampaignQueryListRequest, IPostCampaignQueryResponseModel } from '../models/responseModels'


export class PostCampaignQueryListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/sapCampaign/sapCampaignQuery',
      requesterInfo,
    })
  }

  public async PostCampaignQueryList(request: IPostCampaignQueryListRequest): Promise<IPostCampaignQueryResponseModel[]> {
    return await this.postAsync<IPostCampaignQueryResponseModel[]>(request)
  }
}