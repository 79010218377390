import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IListOpportunity } from '../models/opportunityMarketList'

export class DeleteOpportunityApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/opportunitMarket/delete',
      requesterInfo,
    })
  }

  public async deleteOpportunity(id: number): Promise<IListOpportunity> {
    this.props.url = `${this.props.url}/${id}`
    return await this.deleteAsync<IListOpportunity>()
  }
}
