import { useEffect, useState } from 'react'
import IPImage from '../../../components/IPImage'
import { downloadFileFromUrl } from '../../../base/utils/dataHelper'
import IPButton from '../../../components/IPButton'
import { ISshFinancial } from '../../FinancialInformation/models/models'
import { getSSSPazarApi } from '../services/getSssPazar'

const Accordion = ({ data }) => {
  const [toggleItem, setToggleItem] = useState(false)

  return (
    <div className={`ac-item ${toggleItem ? 'ac-active' : ''}`}>
      <h5 className='ac-title' onClick={() => setToggleItem(!toggleItem)}>
        {data.Question}
      </h5>
      <div className='ac-content' style={{ display: toggleItem ? 'block' : 'none' }}>
        <p dangerouslySetInnerHTML={{ __html: data.Answer }}></p>
        {data.FileType != '.pdf' ?
          <IPImage
            src={data.FileUrl} alt={''} />
          :
          <IPButton
            className={'active indexButton'}
            name='order'
            onClick={() => downloadFileFromUrl(data.FileUrl, data.FileUrl)}
            text='Doküman İndir'
            style={{ width: '10%' }}
          />
        }
      </div>
    </div>
  )
}

export default function FrequentlyAskedQuestions() {
  const [sssData, setSssData] = useState<ISshFinancial[]>([])

  const getSssPazar = async () => {
    const api: getSSSPazarApi = new getSSSPazarApi({})

    api
      .getSssPazar()
      .then((res: any) => {
        setSssData(res)
      })
      .catch(err => { })
  }

  useEffect(() => {
    getSssPazar()
  }, [])

  return (
    <div className='container mt-5 mb-5'>
      <div className='mx-3 m-b-20'>
        <div className='row'>
          <p style={{ fontSize: 24, fontWeight: 500, color: '#616161' }}>
            Sık Sorulan Sorular
          </p>
          <hr style={{ width: '15%', color: '#EA3535', height: '2px' }} />
        </div>
      </div>
      <div className='accordion'>
        {sssData && sssData.map((data: ISshFinancial) => <Accordion data={data} />)}
      </div>
    </div>

  )
}
