import React, { useEffect, useState } from 'react'
import { PostSasReportListApi } from '../services/postSasReportlListApi'
import { useCustomReducer } from '../../../../../base/customHooks'
import { PostSasReportListResponseModel } from '../models/models'
import { ICompany } from '../../../components/models/models'
import { getCompanyApi } from '../../../components/api'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import IPSelectBox from '../../../../../components/IPSelectBox'


interface SasReportListState {
  SasReportList: PostSasReportListResponseModel[]
  companies: ICompany[]
  selectedCompany?: ISelectOption,
}
function SASReport() {
  const getInitialState = (): SasReportListState => {
    return {
      SasReportList: [],
      companies: [],
      selectedCompany: undefined,
    }
  }

  const companies = [
    { label: 'Index', value: 1000 },
    { label: 'Despec', value: 1200 },
    { label: 'Netex', value: 2300 },
    { label: 'Datagate', value: 1100 },
  ]

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<SasReportListState>(intialState)

  // useEffect(() => {
  //   postSasReport()
  // }, [state.companies,state.selectedCompany])

  useEffect(() => {
    if (state.companies.length > 0)
      postSasReport()
  }, [state.companies, state.selectedCompany])

  useEffect(() => {
    getCompany()
  }, [])

  const postSasReport = async () => {
    const getByIdApi = new PostSasReportListApi({})
    const sas = await getByIdApi.PostSasReportList()
    const sasData: PostSasReportListResponseModel[] = [];
    let um = "";
    let i = 1;
    sas.map((s) => {
      if (um.length > 0 && um === s.ProductMan_C)
        i++;
      else
        i = 1;
      um = s.ProductMan_C;
      const data = state.companies.filter((com) => { return com.Code === +s.CompanyCode }).pop()
      return sasData.push({
        EBelge: s.EBelge,
        MatNumber: s.MatNumber,
        ProductCode: s.ProductCode,
        ProductMan_C: s.ProductMan_C,
        ProductMan_N: s.ProductMan_N,
        TESLIMAT_TARIHI: s.TESLIMAT_TARIHI,
        UMSipSayac: s.UMSipSayac,
        Order: i,
        CompanyCode: data ? data.Name : ""
      })
    })
    if (state.selectedCompany)
      setState({ SasReportList: sasData.filter(company => company.CompanyCode === state.selectedCompany?.label) })
    else
      setState({ SasReportList: sasData })
  }

  const getCompany = async () => {
    const company: ICompany[] = await getCompanyApi()
    setState({ companies: company })
  }
  return (
    <div className='card'>
      <div className='card-header d-flex justify-content-between'>
        <span className='h4'>Teslim  Tarihi Geçmiş Kalan SAS Raporu 1</span>
      </div>
      <div className='card-body'>
        <div className='d-flex align-items-end gap-4'>
          <div className='col-lg-2'>
            <label htmlFor='company' className='form-label'>
              Şirket
            </label>
            <IPSelectBox
              isClearable
              id='Şirket'
              name='Şirket'
              options={companies}
              value={state.selectedCompany}
              onChangeSingle={(option) => setState({ selectedCompany: option })}
            />
          </div>
        </div>
        <br></br>
        <div className='row'>
          <div className='col-lg-12'>
            <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
              <div className='row'>
                <div className='col-sm-12'>
                  <table
                    id='datatable'
                    className='table table-bordered table-hover dataTable'
                    role='grid'
                    aria-describedby='datatable_info'
                  >
                    <thead>
                      <tr role='row' className='odd'>
                        <th colSpan={10} style={{ textAlign: 'center', tableLayout: "fixed" }}>
                          Teslim  Tarihi Geçmiş Kalan SAS Raporu 1
                        </th>
                      </tr>
                      <tr role='row' className='odd'>
                        <th>Satır Sayac</th>
                        <th style={{ width: "110px" }}>ÜM.Satır Sayac </th>
                        <th>ÜM.Sip. Sayac</th>
                        <th>Şirket</th>
                        <th>Ürün Müdürü Kodu</th>
                        <th>Ürün Müdürü</th>
                        <th>Belge No</th>
                        <th>Ürün Kodu</th>
                        <th>Ürün SAP Kodu</th>
                        <th>Teslimet Tarihi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.SasReportList.map((a, index) => {
                        return (
                          <tr role='row' className='odd' key={index}>
                            <td>{index + 1}</td>
                            <td>{a.Order}</td>
                            <td>{a.UMSipSayac}</td>
                            <td>{a.CompanyCode}</td>
                            <td>{a.ProductMan_C}</td>
                            <td>{a.ProductMan_N}</td>
                            <td>{a.EBelge}</td>
                            <td>{a.ProductCode}</td>
                            <td>{a.MatNumber}</td>
                            <td>{a.TESLIMAT_TARIHI}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SASReport