import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IWbcListDLRPFProductsList } from '../models/models'

export class ExcelWBCLISTDLRPFProductsListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/Excel/WBCLISTDLRPFProductsList',
      requesterInfo,
    })
  }

  public async excelWBCLISTDLRPFProductsList(req: IWbcListDLRPFProductsList[]): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
