import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { IChannelDataApple, INetChannelMixData } from '../models/models'

export class GetNetChannelListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'NetChannelApple/getNetChannelList',
      requesterInfo,
    })
  }

  public async getNetChannelList(req: any): Promise<INetChannelMixData> {
    return await this.postAsync<INetChannelMixData>(req)
  }
}
