import React, { useEffect, useState } from 'react'
import './css/HomePageTopBrand.scss'
import Image from '../../components/IPImage'
import { Link, useNavigate } from 'react-router-dom'
import Translations from '../../translations'
import { translate } from '../../base/localization'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import { useAppSelector } from '../../redux/app/hooks'
import { DATAGATE_THEME, DEFAULT_THEME, DESPEC_THEME, INDEX_THEME, NETEX_THEME } from '../../base/theme/themeConfig'
import IPButton from '../../components/IPButton'
import { IBanner, IBrandList, ILocationEnum } from './commonModels/models'
import { GetBannersApi } from './services/getBannersApi'
import { ListNoticeApi } from '../Admin/HomeAndSiteOperation/HomePageNotice/services/ListNoticeApiNormal'

const HomePageTopBrand = props => {
  const t = translate(Translations)
  const [banners, setBanners] = useState<IBanner[]>([])
  const [activeTab, setActiveTab] = useState('Markalar')
  const theme = useAppSelector(selectedTheme)
  const navigate = useNavigate()

  const allBrandsPages = async (data: any) => {
    navigate('/brands', {
      state: { allBrand: data },
    })
  }

  // useEffect(() => {
  //   getBanners()
  // }, [])

  const getBanners = async () => {
    const getBanners: GetBannersApi = new GetBannersApi({})
    const banners = await getBanners.getBanners()
    setBanners(
      banners
        .filter((banner: IBanner) => {
          return banner.Location === ILocationEnum.AltBanner
        })
        .sort((a, b) => a.Order - b.Order)
    )
  }

  const handleTabClick = tab => {
    setActiveTab(tab)
  }

  useEffect(() => {
    getNotice()
  }, [])

  const [notice, setNotice] = useState<any>()

  const getNotice = async () => {
    const listNotice: ListNoticeApi = new ListNoticeApi({})

    listNotice
      .listNoticeApi()
      .then((res: any) => {
        setNotice(res[0].Notice)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <React.Fragment>
      <section className='section-back-grey mobile-resize py-5 p-l-40 p-r-40 p text-left m-t-40'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-12'>
              <div className='d-flex justify-content-between'>
                <div className='col-md-3 d-flex justify-content-between flex-column flex-sm-row'>
                  <h4
                    className={`section-back-grey-title ${activeTab === 'Markalar' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Markalar')}
                    style={{ cursor: 'pointer' }}
                  >
                    Markalar
                  </h4>
                  <h4
                    className={`section-back-grey-title ${activeTab === 'Duyurular' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Duyurular')}
                    style={{ cursor: 'pointer' }}
                  >
                    Duyurular
                  </h4>
                </div>
                <div className='col-md-9'>
                  {activeTab === 'Markalar' &&
                    (props.data.Count > 6 ? (
                      <IPButton
                        className={`${theme.name === NETEX_THEME ? 'netex_buttonColor' : ''}${theme.name === INDEX_THEME ? 'index_buttonColor' : ''
                          }${theme.name === DEFAULT_THEME ? 'default_buttonColor' : ''}${theme.name === DESPEC_THEME ? 'despec_buttonColor' : ''
                          }${theme.name === DATAGATE_THEME ? 'datagate_buttonColor' : ''}`}
                        onClick={() => allBrandsPages(props.data)}
                        style={{
                          width: '100px',
                          float: 'right',
                          backgroundColor: '#fff',
                        }}
                        text={t('pages.homePage.allBrand')}
                      />
                    ) : (
                      props.data.Count > props.data.List.length && (
                        <IPButton
                          className={`${theme.name === NETEX_THEME ? 'netex_buttonColor' : ''}${theme.name === INDEX_THEME ? 'index_buttonColor' : ''
                            }${theme.name === DEFAULT_THEME ? 'default_buttonColor' : ''}${theme.name === DESPEC_THEME ? 'despec_buttonColor' : ''
                            }${theme.name === DATAGATE_THEME ? 'datagate_buttonColor' : ''}`}
                          onClick={() => allBrandsPages(props.data)}
                          style={{
                            width: '100px',
                            float: 'right',
                            backgroundColor: '#fff',
                          }}
                          text={t('pages.homePage.allBrand')}
                        />
                      )
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`seperator m-0 ${theme.name === NETEX_THEME ? 'netex_borderBottom' : ''}${theme.name === INDEX_THEME ? 'index_borderBottom' : ''
            }${theme.name === DEFAULT_THEME ? 'default_borderBottom' : ''}${theme.name === DESPEC_THEME ? 'despec_borderBottom' : ''
            }${theme.name === DATAGATE_THEME ? 'datagate_borderBottom' : ''}`}
        ></div>
        {activeTab === 'Markalar' && (
          <div className='row align-items-center text-center mx-3 mt-4'>
            {props.data.List.map(
              (item: IBrandList, index) =>
                index < 6 && (
                  <div className='col-lg-2' key={item.Id}>
                    <div className='top-brands-item brand-item'>
                      <Link to={`/brandDetail?brandId=${item.Id}`} className='m-auto'>
                        <div className='brand-white-background'>
                          <Image
                            className='rounded-circle'
                            src={item.Image ? item.ImageUrl : '/images/notImage2.png'}
                            style={{
                              width: '70px',
                              height: 'unset',
                              minWidth: '50px',
                            }}
                            alt={item.Name}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                )
            )}
          </div>
        )}
        {activeTab === 'Duyurular' && (
          <div style={{ paddingInline: '2rem', paddingBottom: '2rem', paddingTop: '2rem', textAlign: 'center' }}>
            <div dangerouslySetInnerHTML={{ __html: notice }} />
          </div>
        )}
      </section>
      <div className='m-t-40'>
        <div className='row'>
          {banners &&
            banners.map((banner: IBanner) => (
              <div className='col-lg-6 brand-mobile-resize' key={banner.Id}>
                {banner.BannerType !== 1 ? (
                  banner.ProductAddress ? (
                    <a href={banner.ProductAddress} target={banner.BannerType === 6 ? '_blank' : ''} rel='noreferrer'>
                      <div data-lightbox='gallery-image'>
                        <Image src={banner.Image} alt={banner.Title} className='px-2' />
                      </div>
                    </a>
                  ) : (
                    <Link to={`/products?bannerId=${banner.Id}`} target={banner.BannerType === 6 ? '_blank' : ''}>
                      <div data-lightbox='gallery-image'>
                        <Image src={banner.Image} alt={banner.Title} className='px-2' />
                      </div>
                    </Link>
                  )
                ) : (
                  <div data-lightbox='gallery-image'>
                    <Image src={banner.Image} alt={banner.Title} className='px-2' />
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  )
}

export default HomePageTopBrand
