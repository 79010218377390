import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IGetDealerChannelResponseModel } from '../models/getDealerChannelResponseModel'

export class DeleteChannelApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/campaignBundle/DeleteProgram/',
      requesterInfo,
    })
  }

  public async deleteChannel(id: number): Promise<IGetDealerChannelResponseModel[]> {
    this.props.url = `${this.props.url}${id}`
    return await this.deleteAsync<IGetDealerChannelResponseModel[]>()
  }
}
