import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { binRequest } from '../../../MyCard/models/models'
import { IBankInfos } from '../models/PaymentModel'

export class FindBankInfosApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'payment/findBankInfos',
      requesterInfo,
    })
  }

  public async findBankInfos(request: binRequest): Promise<IBankInfos> {
    return await this.postAsync<IBankInfos>(request)
  }
}
