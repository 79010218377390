import React, { useMemo, useState, useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import InputCampaign from '../../../../../components/InputCampaign'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Table from '../../../../../components/Table'
import { EnteredOrderReportForDealerApi } from '../services/enteredOrderReportForDealerApi'
import { IEnteredOrderReportForDealerRequest, IEnteredOrderReportForDealerResponse } from '../models/model'
import Swal from 'sweetalert2'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'

function CurrentEnteredOrders(props: BaseComponentWithContextProps) {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const t = translate(Translations)
  const [startDate, setStartDate] = useState<any>(inititalStartDate)
  const [endDate, setEndDate] = useState<any>(new Date())
  const [currentEnteredOrdersData, setCurrentEnteredOrdersData] = useState<IEnteredOrderReportForDealerResponse[]>([])
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Satır Sayaç',
        accessor: 'col1',
      },
      {
        Header: 'Sipariş Sayaç',
        accessor: 'col2',
      },
      {
        Header: 'Dönem',
        accessor: 'col3',
      },
      {
        Header: 'IP',
        accessor: 'col4',
      },
      {
        Header: 'VisitorID',
        accessor: 'col5',
      },
      {
        Header: 'Cari Kodu',
        accessor: 'col6',
      },
      {
        Header: 'Cari Açıklama',
        accessor: 'col7',
      },
      {
        Header: 'Sipariş Kodu',
        accessor: 'col8',
      },
      {
        Header: 'Sipariş Tutarı',
        accessor: 'col9',
      }, {
        Header: 'MT Bayi Kodu',
        accessor: 'col10',
      },
      {
        Header: 'Kullanıcı Kodu',
        accessor: 'col11',
      },
      {
        Header: 'EMail',
        accessor: 'col12',
      },
      {
        Header: 'Sipariş Kullanıcı Sayımı',
        accessor: 'col13',
      },
      {
        Header: 'İşlem Zamanı',
        accessor: 'col14',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      currentEnteredOrdersData.map((item, index) => {
        const data = {
          col1: item.LineCounter,
          col2: item.OrderCounter,
          col3: item.Period,
          col4: item.IpAddress,
          col5: item.VisitorId,
          col6: item.DealerCode && item.DealerCode.replace("0000", ""),
          col7: item.DealerName,
          col8: item.OrderNumber,
          col9: item.OrderAmountTL.toFixed(2),
          col10: item.MtDealerCode && item.MtDealerCode.replace('0000', ''),
          col11: item.UserName,
          col12: item.Email,
          col13: 1,
          col14: item.Datetime,
        }

        return data
      }),
    [currentEnteredOrdersData]
  )

  const enteredOrderReportForDealer = async () => {
    const req: IEnteredOrderReportForDealerRequest = {
      StartDate: getDateByTimezoneOffset(startDate) as any,
      EndDate: getDateByTimezoneOffset(endDate) as any
    }
    props.showLoading()
    const enteredOrderReportForDealerApi: EnteredOrderReportForDealerApi = new EnteredOrderReportForDealerApi({})

    await enteredOrderReportForDealerApi
      .enteredOrderReportForDealer(req)
      .then(async result => {
        if (result) {
          setCurrentEnteredOrdersData(result)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  useEffect(() => {
    enteredOrderReportForDealer()
  }, [])


  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }


  return (
    <div className='card'>
      <div className='card-header'>
        <span className='h4'>
          {t('pages.admin.dealerAndOrderReport.currentEnteredOrders.currentEnteredOrders.header')}
        </span>
      </div>
      <div className='card-body'>
        <form className='formbanner'>
          <div className='row'>
            <div className='col-md-2 mb-2'>
              <InputCampaign
                type='date'
                id='StartDate'
                name='StartDate'
                label={'Başlangıç Tarihi'}
                value={startDate && toDateTimeLocal(startDate)}
                onChange={e => getDate(e.target.value, "Start")}
                isActive
              />
            </div>
            <div className='col-md-2 mb-2'>
              <InputCampaign
                type='date'
                id='EndDate'
                name='EndDate'
                label={'Bitiş Tarihi'}
                value={endDate && toDateTimeLocal(endDate)}
                onChange={e => getDate(e.target.value, "End")}
                isActive
              />
            </div>
            <div className='col-md-1 mb-3'>
              <IPButton
                type='button'
                className='btn btn-success'
                style={{ width: "25%" }}
                text={t('pages.admin.dealerAndOrderReport.handcraftedOrdersList.handcraftedOrdersList.filtre')}
                onClick={enteredOrderReportForDealer}
              />
            </div>
          </div>
        </form>
        <div className='m-4'>
          <Table columns={tableColumns} data={tableData} currentPage={0} headerName={t('pages.admin.dealerAndOrderReport.currentEnteredOrders.currentEnteredOrders.header')} columnCount={13} />
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(CurrentEnteredOrders)
