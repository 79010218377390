import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import './style.scss'
import { getSssSupportApi } from './services/getSssSupport'
import { ISshSupport } from './models'
const t = translate(Translations)

const Accordion = ({ data }) => {
  const [toggleItem, setToggleItem] = useState(false)

  return (
    <div className={`ac-item ${toggleItem ? 'ac-active' : ''}`}>
      <h5 className='ac-title' onClick={() => setToggleItem(!toggleItem)}>
        <i className='fa fa-question-circle'></i>
        {/* Firmanızdan satın aldığım ürünün garanti durumunu nasıl sorgulayabilirim? */}
        {data.Question}
      </h5>
      <div className='ac-content' style={{ display: toggleItem ? 'block' : 'none' }}>
        {/* Ürüne ait seri numarası ile www.indexpazar.com internet
          sitesinden, mhd@index.com.tr mail adresinden veya 0212 331 21
          99 (Dahili:4) veya 0850 200 28 88 çağrı merkezi numaramızdan
          destek alabilirsiniz. */}
        <p dangerouslySetInnerHTML={{ __html: data.Answer ? data.Answer : '' }}></p>
      </div>
    </div>
  )
}

function Index(props: any) {
  const [sssData, setSssData] = useState<ISshSupport[]>([])

  const getSssSupport = async () => {
    const api: getSssSupportApi = new getSssSupportApi({})

    api
      .getSssSupport()
      .then((res: any) => {
        setSssData(res)
      })
      .catch(err => {})
  }

  useEffect(() => {
    getSssSupport()
  }, [])

  return (
    <div className='sss ' style={{ backgroundColor: 'white' }}>
      <div className='container-fluid'>
        <form>
          <div className='row align-items-center'>
            <div className='heading-text'>
              <h4>{t('pages.mySpecial.slider.sss')}</h4>
            </div>
            <div className='accordion toggle fancy radius clean'>
              {sssData && sssData.map((data: ISshSupport) => <Accordion data={data} />)}
              {/* <div className="ac-item">
                <h5 className="ac-title">
                  <i className="fa fa-question-circle"></i>İnternet sitenizden
                  yapmış olduğum sorgulamada ’’ Kayıt Bulunamadı ’’ mesajı
                  alıyorum ancak ürünü sizden aldım. Servisinize gönderebilir
                  miyim?
                </h5>
                <div className="ac-content" style={{ display: "none" }}>
                  -Yapmış olduğunuz sorgulamada ürüne ait kayıtlara
                  ulaşamadıysanız lütfen ürünün seri numarasını kontrol ederek
                  tekrar deneyiniz. <br />
                  <br /> -Seri numarasını doğru girdiğinizden eminseniz ve hala
                  ürünün kayıtlarına ulaşamıyorsanız, Index Grup şirketlerine
                  ait satın alma faturanızın bir örneği ve ürüne ait garanti
                  belgemiz ile mhd@index.com.tr mail adresimizden veya 0212 331
                  21 99 (Dahili:4) veya 0850 200 28 88 numaralı çağrı
                  merkezimizden destek talep ediniz. <br />
                  <br /> -Ürününüzün seri numarası mevcut değil ve yalnızca ürün
                  kodu var ise Index Grup şirketlerine ait satın alma
                  faturanızın bir örneği ve ürüne ait garanti belgemiz ile Satış
                  Sonrası Hizmetler Departmanımıza
                  getirebilir/gönderebilirsiniz.
                </div>
              </div>
              <div className="ac-item">
                <h5 className="ac-title">
                  <i className="fa fa-question-circle"></i>Yapmış olduğum
                  sorgulamalar sonucunda ürünümün/ürünlerimin kaydına ulaştım.
                  Tarafınıza nasıl ulaştırabilirim?
                </h5>
                <div className="ac-content" style={{ display: "none" }}>
                  Index Grup şirketleri bünyesinde destek hizmeti verilebilen
                  markalara ait ürünleri kargo ile veya bizzat iletişim
                  adresimize müracaat ederek ulaştırabilirsiniz.
                </div>
              </div>
              <div className="ac-item">
                <h5 className="ac-title">
                  <i className="fa fa-question-circle"></i>Ürünümü/Ürünlerimi
                  kendim getireceğim hangi saatler içerisinde getirebilirim?
                </h5>
                <div className="ac-content" style={{ display: "none" }}>
                  Index Grup Şirketleri; Hafta İçi: 08:00 – 12:00 / 13:00 –
                  16:30 saatleri arasında hizmet vermektedir. Not: Hafta içi
                  12:00 – 13:00 saatleri öğlen paydos saatleridir. Belirtilen
                  saatler arasında yalnızca işlemleri tamamlanan ’’ Hazır ’’
                  durumdaki ürünleriniz teslim edilebilmektedir. Teslim Adresi :
                  Cumhuriyet Mahallesi Yahyakaptan Caddesi No:10A D:2
                  Çayırova/KOCAELİ
                </div>
              </div>*/}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
export default Index
