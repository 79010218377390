import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { DealerForLoginRequest, DealerForLoginResponse } from './models'

export class SiteNavigationService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GA4Reporting/DealerForLogin',
      requesterInfo,
    })
  }

  public async DealerForLogin(req: DealerForLoginRequest): Promise<DealerForLoginResponse[]> {
    return await this.postAsync<DealerForLoginResponse[]>(req)
  }
}
