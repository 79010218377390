import IPButton from '../../../components/IPButton'
import IPInput from '../../../components/IPInput'
import IPSelectBox from '../../../components/IPSelectBox'
import './style.scss'
import Translations from '../../../translations'
import { ISSHContact } from './models/response/postSSHContactResponseModel'
import { translate } from '../../../base/localization'
import { useFormik } from 'formik'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { PostSSHContactApi } from './services/postSSHContactApi'
import validationSchema from './validation'
import IPTextarea from '../../../components/IPTextarea'
import { CommonPostResponse } from '../CurrentAccount/Consensus/models/response/ConsensusApplicationResponseModel'
const t = translate(Translations)

function Index(props: any) {
  const titleOptions = [
    { value: 1, label: 'Arızalı ürünüm hakkında bilgi almak istiyorum' },
    { value: 2, label: 'Garanti Sorgulama' },
    { value: 3, label: 'Müşteri Hizmetleri Prosedürleri' },
    { value: 4, label: 'Diğer' },
  ]

  const companyOptions = [
    { value: 1, label: 'Index Grup' },
    { value: 1000, label: 'Index' },
    { value: 1200, label: 'Despec' },
    { value: 1100, label: 'Datagate' },
    { value: 2300, label: 'Netex' },
  ]

  const initialValues = Object.assign({
    Subject: null,
    Body: '',
    Email: '',
    Company: '',
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      Company: { value: '', label: '' },
      Subject: { value: '', label: '' },
      Body: '',
      Email: '',
    },
    onSubmit: async values => {
      const clnValues = _.clone(values)
      const req: ISSHContact = {
        Company: values.Company.label,
        Subject: values.Subject.label,
        Email: values.Email,
        Body: values.Body,
      }
      const postSSHContact: PostSSHContactApi = new PostSSHContactApi({})
      postSSHContact
        .postSSHContact(req)
        .then((result: CommonPostResponse) => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Form Gönderildi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async result => {
              window.location.reload()
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    },
    validationSchema,
  })

  return (
    <div className='myOrder contactForm' style={{ backgroundColor: 'white' }}>
      <div className='container-fluid'>
        <div className='heading-text'>
          <h4>SSH İletişim Formu</h4>
        </div>
        <br></br>
        <div className='box'>
          <form>
            <div className='form-row'>
              <div className='col-12 col-lg-8'>
                <div className='labeltext'>
                  <label htmlFor='location' className='form-label'>
                    {t('pages.mySpecial.sshContactForm.topic')}
                  </label>
                  <IPSelectBox
                    isClearable
                    name='Subject'
                    onBlur={handleBlur}
                    value={values.Subject}
                    placeholder={'Konu seçiniz'}
                    options={titleOptions}
                    onChangeSingle={option => {
                      setFieldValue('Subject', option)
                    }}
                  />
                  <p className='validationError'>
                    {errors.Subject?.label && touched.Subject?.label && errors.Subject?.label}
                  </p>
                </div>
              </div>
              <div className='col-12 col-lg-4'>
                <div className='labeltext'>
                  <label htmlFor='location' className='form-label'>
                    Şirket
                  </label>
                  <IPSelectBox
                    isClearable
                    name='Company'
                    value={values.Company}
                    placeholder={'Şirket seçiniz'}
                    options={companyOptions}
                    onBlur={handleBlur}
                    onChangeSingle={option => {
                      setFieldValue('Company', option)
                    }}
                  />
                  <p className='validationError'>
                    {errors.Company?.label && touched.Company?.label && errors.Company?.label}
                  </p>
                </div>
              </div>
            </div>
            <div className='form-row mt-5'>
              <div className='col-12 col-lg-12 mt-3 mt-lg-0'>
                <label htmlFor='location' className='form-label'>
                  {t('pages.mySpecial.sshContactForm.eposta')}
                  <span className='required'>*</span>
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='Email'
                  //placeholder={t('pages.mySpecial.sshContactForm.eposta')}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Email}
                />
                <p className='validationError'> {errors.Email && touched.Email ? errors.Email : null}</p>
              </div>
            </div>
            <div className='form-row mt-5'>
              <div className='col-md-12'>
                <div className='labeltext'>
                  <label>{t('pages.mySpecial.sshContactForm.message')} </label>
                  <IPTextarea
                    id='Body'
                    className='form-control'
                    name='Body'
                    value={values.Body}
                    //placeholder={t('pages.mySpecial.sshContactForm.message')}
                    onChange={handleChange}
                  />
                  <p className='validationError'> {errors.Body && touched.Body ? errors.Body : null}</p>
                </div>
              </div>
              <div className='col-md-4 offset-8'>
                <IPButton
                  type='button'
                  onClick={() => handleSubmit()}
                  className='button mt-5'
                  isRounded={false}
                  text='Gönder'
                />{' '}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Index
