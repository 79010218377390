import './CreditStatement.scss'
//interface IReportState {}

export default function CreditStatement() {
  // const getInitialState = (): IReportState => {
  //   return {}
  // }
  //const intialState = getInitialState()
  //const [state, setState] = useCustomReducer<IReportState>(intialState)

  return (
    <div className='creditStatement'>
      <h1>Kredi Başvuru, Limit İsteği ve Artırımı</h1>

      {/* <div className='topBar'>
        <div className='boxTitle'>Kredi Durumu</div>
        <div className='creditLimits'>
          <div>Kredi Limiti: 10.000,00 USD</div>
          <div>Kullanılabilir Limit: 10.000,00 USD</div>
        </div>
      </div> */}

      <div className='titleBorderless pt-5'>KREDİ BAŞVURUSU NASIL YAPILIR?</div>

      <p>Kredi başvurusunda bulunmak için kredi başvuru evraklarını doldurup bize göndermeniz gerekmektedir.</p>
      <p>Başvuru evraklarınız eksiksiz ve sorunsuzsa kredi onayına gönderilir.</p>
      <p>Kredi onayına gönderilen başvurular onaylandığında kredi tanımlanır.</p>

      <div className='titleBorderless pt-5'>KREDİ BAŞVURU EVRAKLARI</div>

      <div className='files'>
        <a href='/assetWeb/docs/belge-kontrol-listesi.docx' download className='file' title='İndirmek İçin Tıklayınız!'>
          <img className='mr-2' width={30} src='/images/icons/docs.png' alt='' />
          <div>Belge Kontrol Listesi</div>
        </a>

        <a href='/assetWeb/docs/Kredi_basvuru_form.docx' download className='file' title='İndirmek İçin Tıklayınız!'>
          <img className='mr-2' width={30} src='/images/icons/docs.png' alt='' />
          <div>Kredi Başvuru Formu</div>
        </a>

        <a href='/assetWeb/docs/adi_ort_taah.docx' download className='file' title='İndirmek İçin Tıklayınız!'>
          <img className='mr-2' width={30} src='/images/icons/docs.png' alt='' />
          <div>Adi Ortaklık Taahüdü</div>
        </a>

        <a href='/assetWeb/docs/degisim_taah.docx' download className='file' title='İndirmek İçin Tıklayınız!'>
          <img className='mr-2' width={30} src='/images/icons/docs.png' alt='' />
          <div>Değişim Taahüdü</div>
        </a>

        <a href='/assetWeb/docs/genel_kredi_soz.docx' download className='file' title='İndirmek İçin Tıklayınız!'>
          <img className='mr-2' width={30} src='/images/icons/docs.png' alt='' />
          <div>Genel Kredi Sözleşmesi</div>
        </a>

        <a href='/assetWeb/docs/kefalet_soz.docx' download className='file' title='İndirmek İçin Tıklayınız!'>
          <img className='mr-2' width={30} src='/images/icons/docs.png' alt='' />
          <div>Kefalet Sözleşmesi</div>
        </a>
      </div>

      <div className='titleBorderless pt-5'>KREDİ LİMİT ARTIRIMI</div>

      <p>Kredi başvurusunda bulunmak için kredi başvuru evraklarını doldurup bize göndermeniz gerekmektedir.</p>
    </div>
  )
}
