import React, { useEffect, useState } from 'react'
import './ProductLink.scss'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { IProductLink } from '../models/models'
import { ProductLinkInsertApi } from '../services/productLinkInsertApi'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import _ from 'lodash'
import { ProductLinkUpdateApi } from '../services/productLinkUpdateApi'
import { ServiceRouter } from '../../../../../base/proxy/serviceRouter'
import { excelToJSON } from '../../../../../base/utils/dataHelper'
import IPFile from '../../../../../components/IPFile'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'

function ProductLinkAdd(props: BaseComponentWithContextProps) {

  const t = translate(Translations)
  const [productCode, setProductCode] = useState<ISelectOption[]>([])
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [searchWord, setSearchWord] = useState("")
  const [company, setCompany] = useState<ISelectOption>()
  const [type, setType] = useState<ISelectOption>()
  const [id, setId] = useState(0)
  const onCreateOption = (option, updateFunc) => {
    updateFunc(prevState => {
      const newData = {
        value: option[option.length - 1].value,
        label: option[option.length - 1].label,
      }
      if (prevState && prevState.length > 0) {
        for (const state of prevState) {
          if (state.value === newData.value) return [...prevState]
          return [...prevState, newData]
        }
      }
      return [newData]
    })
  }
  let navigate = useNavigate()

  const getCompanies = async () => {
    props.showLoading()
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push(co)
      })
    setCompanies(data.sort((a, b) => a.Code - b.Code))
    props.hideLoading()
  }

  const handleFilesProductCode = async (files: File[]) => {
    const productCodes: any = await excelToJSON(files[0])
    const data: ISelectOption[] = []
    productCodes.forEach((code) => {
      code.forEach((c) => {
        const label: string = String(c.ProductCode)
        if (data.findIndex((d) => d.label === label) === -1) {
          const newOption: ISelectOption = { label, value: label }
          data.push(newOption)
        }
      })
    })
    const newData = productCode ? [...productCode, ...data] : data
    const uniqueList = newData.filter((item, index, self) => {
      return index === self.findIndex((i) => i.label === item.label)
    })
    setProductCode(uniqueList)
  }

  const types = [
    { label: 'Ürün Kodu', value: 0 },
    { label: 'Ürün ID', value: 1 },
  ]


  const productLinkInsert = async () => {

    if (!productCode || productCode.length === 0) {
      Swal.fire({
        icon: 'error',
        html: "Lütfen ürün giriniz",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    if (type === undefined) {
      Swal.fire({
        icon: 'error',
        html: "Lütfen link tipi seçiniz.",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading();
    const productsCodes = [] as any
    productCode && productCode.map((prod) => {
      return productsCodes.push(prod.label)
    })
    const req: IProductLink = {
      Id: id,
      Header: "",
      SearchWord: searchWord,
      ProductsCode: productsCodes,
      Company: Number(type?.value) === 0 ? Number(company?.value) : undefined,
      IDType: Number(type?.value)
    }
    if (id > 0) {
      const productLinkUpdateApi: ProductLinkUpdateApi = new ProductLinkUpdateApi({})
      productLinkUpdateApi
        .productLinkUpdate(req)
        .then((result: any) => {
          const url = `<a href='/products?linkId=${result.Id}'>${ServiceRouter.getUrl()}${ServiceRouter.getUrl() === "https://dev-b2b.vbt.com.tr:1443/" ? "" : "/"}products?linkId=${result.Id}</a>`
          props.hideLoading();
          Swal.fire({
            icon: 'success',
            html: `Link:${url}`,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async () => {
            navigate('/admin/ProductOperation/ProductLinkList')
          })
        })
        .catch((err: any) => {
          props.hideLoading();
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
      return
    }
    const productLinkInsertApi: ProductLinkInsertApi = new ProductLinkInsertApi({})
    productLinkInsertApi
      .productLinkInsert(req)
      .then((result: any) => {
        const url = `<a href='/products?linkId=${result.Id}'>${ServiceRouter.getUrl()}${ServiceRouter.getUrl() === "https://dev-b2b.vbt.com.tr:1443/" ? "" : "/"}products?linkId=${result.Id}</a>`
        props.hideLoading();
        Swal.fire({
          icon: 'success',
          html: `Link:${url}`,
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(async () => {
          navigate('/admin/ProductOperation/ProductLinkList')
        })
      })
      .catch((err: any) => {
        props.hideLoading();
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const routeLocation = useLocation()

  useEffect(() => {
    getCompanies()
  }, [])

  useEffect(() => {
    if (routeLocation.state) {
      const data = _.clone((routeLocation.state as any)?.linkData)
      setSearchWord(data.SearchWord);
      setType(types.find(a => a.value == data.IDType))
      var comps = companies.find(a => a.Id == data.Company)
      setCompany(({
        label: comps?.Name,
        value: `${comps?.Id}`,
      }))
      const productCodes: ISelectOption[] = [];
      data.ProductsCode.map((prod) => {
        return productCodes.push({ value: prod, label: prod })
      })
      setProductCode(productCodes);
      setId(data.Id)
    }
  }, [companies])

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>
              {t('pages.admin.productOperation.productAttributeChangeData.productFeatureChangeAdd.header')}
            </span>
            <Link to='/admin/ProductOperation/ProductLinkList'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Listeye Dön'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='row m-t-20'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <IPInput
                type='text'
                id='Title'
                className='form-control'
                name='Title'
                value={searchWord}
                placeholder="Arama Kelimesi"
                onChange={(e) => setSearchWord(e.target.value)}
              />
            </div>
          </div>
          <div className='row m-t-20'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <IPSelectBox
                isClearable
                id='Type'
                name='Type'
                placeholder={"Link tipi seçin"}
                options={types}
                isSearchable
                onChangeSingle={option => setType(option)}
                value={type}
              />
            </div>
          </div>
          <div className='row m-t-20'>
            <div className='col-md-3'></div>
            <div className='col-md-2'>
              <a className='campaignExcelBtn' href='/assetWeb/docs/productCode.xlsx' target='_blank'>
                Örnek Excel
              </a>
            </div>
            <div className='col-md-3'>
              <IPFile
                style={{ float: 'right' }}
                id='FileInputProductCode'
                multiple={false}
                handleFiles={e => handleFilesProductCode(e)}
                removeAfterSelect={true}
                className='campaignFileInput'
                label="Excel'den Yükle"
              />
            </div>
          </div>
          <div className='row m-t-20'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <IPSelectBox
                isMulti
                isClearable
                isCreatableSelect
                id='ProductCode'
                name='ProductCode'
                placeholder={"Ürün kodu yazın"}
                closeMenuOnSelect={false}
                isSearchable
                onCreateOption={option => onCreateOption(option, setProductCode)}
                onChangeMulti={option => setProductCode(option)}
                value={productCode}
              />
            </div>
          </div>
          {type?.value == 0 &&
            <div className='row m-t-20'>
              <div className='col-md-3'></div>
              <div className='col-md-6'>
                <IPSelectBox
                  isClearable
                  id='Company'
                  name='Company'
                  placeholder={"Şirket seçin"}
                  options={
                    companies &&
                    companies.map((item: ICompany) => ({
                      label: item.Name,
                      value: `${item.Id}`,
                    }))
                  }
                  isSearchable
                  onChangeSingle={option => setCompany(option)}
                  value={company}
                />
              </div>
            </div>
          }
          <br />
          <div className='row'>
            <div className='col-md-4'></div>
            <div className='col-md-4'>
              <IPButton
                type='submit'
                className='btn btn-primary'
                text={t('pages.admin.productOperation.productAttributeChangeData.productFeatureChangeAdd.send')}
                onClick={productLinkInsert}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default componentWithContext(ProductLinkAdd)
