import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { ICompatibilityTable } from '../models/models'

export class GetCompatibilityTableApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/get-compatibility-table',
      requesterInfo,
    })
  }

  public async getBannergetCompatibilityTable(Id: number): Promise<ICompatibilityTable[]> {
    this.props.url = `${this.props.url}/${Id}`
    return await this.getAsync<ICompatibilityTable[]>()
  }
}
