import React, { useState, useEffect } from 'react'
import { GetEInvoiceAddressApi } from '../services/getEInvoiceAddressApi'
import { useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'

export default function EInvoice() {
  const [eInvoiceUrl, setEInvoiceUrl] = useState("")
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const qpEInvoiceId = searchParams.get('eInvoiceId')
    if (qpEInvoiceId) {
      const api: GetEInvoiceAddressApi = new GetEInvoiceAddressApi({})
      api.getEInvoiceAddress(qpEInvoiceId).then((res: string) => {
        if (res) return setEInvoiceUrl(res)
        Swal.fire({
          icon: 'error',
          title: 'Böyle E-Fatura bulunamamıştır.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Böyle E-Fatura bulunamamıştır.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }, [])

  return (
    eInvoiceUrl === "Dosya bulunamadı." ? <h4 style={{ textAlign: "center" }}>Dosya Bulunamadı</h4> :
      <iframe src={eInvoiceUrl} style={{ height: "100vh" }} />
  )
}
