import { useState } from 'react'
import IPModal from '../../../../../components/IPModal'
import { ISurveyAnswer, ISurveyReport } from '../models'

interface ITableProps {
  report: ISurveyReport
}


export default function Table1(props: ITableProps) {


  const [showModal, setShowModal] = useState<any>()
  const [modalData, setData] = useState<any>()
  const [selectedID, setID] = useState<any>()
  const [answer, setAnswer] = useState<any>()


  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12' style={{ marginTop: props.report.OptionType != 3 ? 50 : 15 }}>
          <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
            <div className='row'>
              <div className='col-sm-12'>
                <table
                  id='datatable'
                  className='table table-bordered table-hover dataTable'
                  role='grid'
                  aria-describedby='datatable_info'
                >
                  <thead>
                    <tr role='row' className='odd'>
                      <th>#</th>
                      <th>Şıklar</th>
                      <th>Sonuçlar</th>
                      <th>Yanıtlayanlar</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.report.Answers &&
                      props.report.Answers.filter(a => a.IsInput == true).map((answer: ISurveyAnswer, index: number) => {
                        return (
                          <tr role='row' className='odd' key={`${answer.Id}`}>
                            <td>{index + 1}</td>
                            <td>{answer.Options}</td>
                            <td>{answer.BitwiseId != 0 ? answer.TotalCount : 1}</td>
                            <td><a style={{ cursor: 'pointer' }}><i className='icon-edit' onClick={() => {
                              setShowModal(true)
                              setData(answer.UserList)
                              setID(answer.UserID)
                              setAnswer(answer.Options)
                            }
                            }></i></a></td>
                          </tr>
                        )
                      })}
                  </tbody>
                  {/* <tfoot>
                <tr>
                  <th>#</th>
                  <th>Şıklar</th>
                  <th>Sonuçlar</th>
                </tr>
              </tfoot> */}
                </table>
              </div>
            </div>
          </div>
        </div>
        <IPModal
          show={showModal}
          onClose={() => setShowModal(false)}
          hideConfirm={true}
          width={1200}
          title={' Yanıt: ' + answer?.toString()} // Fixed title
        >
          {selectedID != null ?
            <table className='table'>
              <thead>
                <tr role='row' className='odd'>
                  <th>Kullanıcı ID</th>
                  <th>Bayi Kodu</th>
                  <th>Kullanıcı Adı</th>
                </tr>
              </thead>
              <tbody>
                {modalData &&
                  modalData.length > 0 &&
                  modalData.map(his => (
                    <>
                      {selectedID === his.UserID &&
                        <tr role='row' className='odd' key={his.Id}>
                          <td> {selectedID === his.UserID ? his.UserID : ''}</td>
                          <td>{selectedID === his.UserID ? (his.DealerCode && his.DealerCode.replace('0000', '')) : ''}</td>
                          <td>{selectedID === his.UserID ? his.UserName : ''}</td>
                        </tr>}
                    </>
                  ))}
              </tbody>
            </table> : <table className='table'>
              <thead>
                <tr role='row' className='odd'>
                  <th>Kullanıcı ID</th>
                  <th>Bayi Kodu</th>
                  <th>Kullanıcı Adı</th>
                </tr>
              </thead>
              <tbody>
                {modalData &&
                  modalData.length > 0 &&
                  modalData.map(his => (
                    <tr role='row' className='odd' key={his.Id}>
                      <td> {his.UserID}</td>
                      <td>{his.DealerCode && his.DealerCode.replace('0000', '')}</td>
                      <td>{his.UserName}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          }
        </IPModal>
      </div>
    </div>
  )
}
