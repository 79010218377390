import React, { useEffect, useState, useMemo } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import Table from './Table'
import { ISubDealerFilter, ISubDealerList, IVirtualPosHistory } from '../models/models'
import { FilterVirtualPosInfosApi } from '../services/filterVirtualPosInfosApi'
import { DeleteVirtualPosInfosApi } from '../services/deleteVirtualPosInfosApi'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'
import { EditVirtualPosInfosApi } from '../services/editVirtualPosInfosApi'
import { EditNoteVirtualPosInfosApi } from '../services/editNoteVirtualPosInfosApi'
import Modal from '../../../../../components/IPModal/IPModal'
import IPModal from '../../../../../components/IPModal/IPModal'
import { VirtualPosInfoHistoryApi } from '../services/virtualPosHistoryApi'
import moment from 'moment'

interface ISubDealerControlState {
  companyOption?: ISelectOption
  bankOptions: ISelectOption[]
  bankOption?: ISelectOption
  dealerCode: string
  subDealerData: ISubDealerList[]
  showModal: boolean
  selectedId?: number
  historyData: IVirtualPosHistory[]
  showHistory: boolean
}

function SubDealerControl(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): ISubDealerControlState => {
    return {
      bankOptions: [],
      dealerCode: "",
      subDealerData: [],
      showModal: false,
      historyData: [],
      showHistory: false
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ISubDealerControlState>(intialState)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [newNote, setNote] = useState<any>('')
  useEffect(() => {
    getCompanies()
  }, [])
  const bankName = [
    { value: 1001, label: 'HALK BANKASI' },
    { value: 2801, label: 'YAPI KREDİ' },
    // { value: 21001, label: 'HALK BANKASI TİC.' },
    // { value: 22801, label: 'YAPI KREDİ TİC.' },
  ]

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push(co)
      })
    setCompanies(data)
  }


  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Şirket',
        accessor: 'col2',
      },
      {
        Header: 'Sap Kodu',
        accessor: 'col3',
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'col4',
      },
      {
        Header: 'Hesap Adı / Ünvan',
        accessor: 'col5',
      },
      {
        Header: 'Banka',
        accessor: 'col6',
      },
      {
        Header: 'Durum',
        accessor: 'col7',
      },
      {
        Header: 'Notlar',
        accessor: 'col8',
      },
      {
        Header: 'Sil',
        accessor: 'col9',
      },
      {
        Header: 'Değiştir',
        accessor: 'col10',
      },
      {
        Header: 'Tarihçe',
        accessor: 'col11',
      },
    ],
    []
  )

  const showHistory = async (id: number) => {
    props.showLoading();
    const virtualHistory: VirtualPosInfoHistoryApi = new VirtualPosInfoHistoryApi({})
    try {
      await virtualHistory
        .historyByID(id)
        .then(res => {
          setState({ showHistory: true, historyData: res })
        })
      props.hideLoading();
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  const tableData = useMemo(
    () =>
      state.subDealerData.map((item, index) => {
        const data = {
          col1: index + 1,
          col2: item.CompanyCode && companies.find((com) => { return +item.CompanyCode === +com.Code })?.Name,
          col3: item.SapCode.length === 10 ? item.SapCode.replace("0000", "") : item.SapCode,
          col4: item.AccountCode.length === 10 ? item.AccountCode.replace("0000", "") : item.AccountCode,
          col5: item.AccountName,
          col6: item.TheName,
          col7: item.Active ? "Aktif" : "Pasif",
          col8: item.Notes != null ? (
            <a
              data-action-type='edit'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              onClick={() => {
                setNote(item.Notes);
                setState({ showModal: true, selectedId: item.Id });
              }}
            >
              {item.Notes}
            </a>
          ) : (
            <a
              data-action-type='edit'
              role='button'
              style={{ cursor: 'pointer', marginLeft: '15px' }}
              data-toggle='tooltip'
              onClick={() => {
                setNote(item.Notes);
                setState({ showModal: true, selectedId: item.Id });
              }}
            >
              <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
            </a>
          ),
          col9: (
            <a
              data-action-type='delete'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.delete')}
              onClick={() => deleteVirtualPosInfos(item.Id)}
            >
              <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
            </a>
          ),
          col10: (
            <a
              data-action-type='edit'
              role='button'
              style={{ cursor: 'pointer', marginLeft: '20px' }}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.edit')}
              onClick={() => editVirtualPosInfos(item.Id)}
            >
              <i className='icon-refresh-ccw' style={{ fontSize: '1.2rem', color: 'green' }}></i>
            </a>
          ),
          col11: (<a style={{ cursor: 'pointer', color: 'blue', marginLeft: '15px' }} onClick={() => showHistory(item.Id)}> <i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i></a>)
        }

        return data
      }),
    [state.subDealerData]
  )

  const excelButton = async () => {
    try {
      if (state.subDealerData) {
        const data: any[] = []
        if (state.subDealerData.length > 0) {
          state.subDealerData.map((item, index) => {
            return data.push({
              Sıra: index + 1,
              Şirket: item.CompanyCode && companies.find((com) => { return +item.CompanyCode === +com.Code })?.Name,
              'Sap Kodu': item.SapCode.length === 10 ? item.SapCode.replace("0000", "") : item.SapCode,
              'Hesap Kodu': item.AccountCode.length === 10 ? item.AccountCode.replace("0000", "") : item.AccountCode,
              'Hesap Adı/Ünvan': item.AccountName,
              Banka: item.TheName,
              Durum: item.Active ? "Aktif" : "Pasif",
              Not: item.Notes,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Alt Bayi Listesi')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const filterSubDealer = async () => {
    const req: ISubDealerFilter = {
      Id: 0,
      CompanyCode: state.companyOption ? String(state.companyOption.value) : "",
      BankRef: state.bankOption ? +state.bankOption.value : 0,
      DealerCode: state.dealerCode.length > 5 ? state.dealerCode.padStart(10, "0") : state.dealerCode
    }
    props.showLoading()
    const filterVirtualPosInfos: FilterVirtualPosInfosApi = new FilterVirtualPosInfosApi({})
    filterVirtualPosInfos
      .filterVirtualPosInfos(req)
      .then((result: ISubDealerList[]) => {
        setState({ subDealerData: result })
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const deleteVirtualPosInfos = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Alt Bayi silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const deleteVirtualPosInfosApi: DeleteVirtualPosInfosApi = new DeleteVirtualPosInfosApi({})
        deleteVirtualPosInfosApi
          .deleteVirtualPosInfos(id)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Alt Bayi Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async () => {
                const deleted = state.subDealerData.filter(x => x.Id !== id)
                setState({ subDealerData: deleted })
              })
            }
            props.hideLoading()
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
  }

  const editNoteVirtualPosInfos = async (id: number, note: string) => {
    Swal.fire({
      icon: 'question',
      title: 'Alt Bayi notunu değiştirmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const editNoteVirtualPosInfosApi: EditNoteVirtualPosInfosApi = new EditNoteVirtualPosInfosApi({})
        editNoteVirtualPosInfosApi
          .editNoteVirtualPosInfos(id, JSON.stringify(newNote))
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Alt Bayi Notunuz Güncellendi',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            }
            props.hideLoading()
            setState({ showModal: false })
            filterSubDealer()
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
    return
  }


  const editVirtualPosInfos = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Alt Bayi aktifliğini değiştirmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const editVirtualPosInfosApi: EditVirtualPosInfosApi = new EditVirtualPosInfosApi({})
        editVirtualPosInfosApi
          .editVirtualPosInfos(id)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Alt Bayi Aktifliği Değiştirildi',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            }
            filterSubDealer()
            props.hideLoading()
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
  }

  return (
    <div className='container'>
      <div className='mb-3'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header'>
              <span className='h4'>
                {t('pages.admin.bankAndInstallementOperation.subDealerControl.subDealerControl.header')}
              </span>
            </div>
            <div className='card-body'>
              <form id='id1' className='formform' noValidate>
                <div className='form-row'>
                  <div className='form-group col-md-3'>
                    <label className='form-label'>Şirket</label>
                    <IPSelectBox
                      isClearable
                      options={
                        companies &&
                        companies.map((item: ICompany) => ({
                          label: item.Name,
                          value: `${item.Code}`,
                        }))
                      }
                      value={state.companyOption}
                      onChangeSingle={option => {
                        if (option) {
                          setState({ companyOption: option })
                        }

                        else {
                          setState({ companyOption: option })
                        }

                      }}
                    />
                  </div>
                  <div className='form-group col-md-3'>
                    <label className='form-label'>Banka</label>
                    <IPSelectBox
                      isClearable
                      options={bankName}
                      value={state.bankOption}
                      onChangeSingle={option => setState({ bankOption: option })}
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <label className='form-label'>Bayi Kodu veya Vergi No</label>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='DealerCode'
                      onChange={(e) => setState({ dealerCode: e.target.value })}
                      value={state.dealerCode}
                    />
                  </div>
                  <div className='form-group col-md-2' style={{ marginTop: "30px" }}>
                    <IPButton
                      text={t('pages.admin.bankAndInstallementOperation.subDealerControl.subDealerControl.filtre')}
                      className='btn btn-danger'
                      onClick={filterSubDealer}
                    />
                  </div>
                  <div className='form-group col-md-2' style={{ marginTop: "30px" }}>
                    <IPButton
                      type='button'
                      className='btn btn-success'
                      text={t(
                        'pages.admin.freeAndOpportunityPosOperation.bank.bankInstallmentReport.exportToExcel'
                      )}
                      onClick={excelButton}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
              <Modal
                title={'Not Güncelle'}
                show={state.showModal}
                onClose={() => {
                  setState({ showModal: false })
                }}
                width={500}
                onConfirm={() => editNoteVirtualPosInfos(state.selectedId!, newNote)}
                confirmText={'Kaydet'}
              >
                <div className='form-group col-md-12 align-items-center '>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='sendMail'
                    required
                    value={newNote}
                    onChange={e => {
                      setNote(e.target.value)
                    }}
                  />
                </div>
              </Modal>
              <IPModal
                show={state.showHistory}
                onClose={() => setState({ showHistory: false, historyData: [] })}
                hideConfirm={true}
                width={1200}
                title='Tarihçe'
              >
                <table className='table'>
                  <thead>
                    <tr role='row' className='odd'>
                      <th>Tarih</th>
                      <th>Şirket</th>
                      <th>SAP Kodu</th>
                      <th>Hesap Adı</th>
                      <th>Banka</th>
                      <th>Durum</th>
                      <th>Silindi</th>
                      <th>Not</th>
                      <th>Bayi Kodu</th>
                      <th>Kullanıcı Adı</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      state.historyData && state.historyData.length > 0 && state.historyData.map((his) => (
                        <tr role='row' className='odd' key={his.Id}>
                          <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                          <td>{his.CompanyCode}</td>
                          <td>{his.Sapcode.replace('0000', '')}</td>
                          <td>{his.UserCode}</td>
                          <td>{his.TheName}</td>
                          <td>{his.Active === 1 ? "Aktif" : "Pasif"}</td>
                          <td>{his.Deleted === 1 ? "Evet" : "Hayır"}</td>
                          <td>{his.Notes}</td>
                          <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                          <td>{his.UserName}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </IPModal>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}
export default componentWithContext(SubDealerControl)
