import { ConfigHelper } from '../constants'
import { ClientStorage } from '../storage'

export function getToken(): string {
  return ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_TOKEN)
}

export function getRefreshToken(): string {
  return ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_REFRESH_TOKEN)
}

export function getTokenCreatedTime() {
  // return Number(String(ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_TOKEN_CREATE_TIME)).slice(-3))
  return Number(ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_TOKEN_CREATE_TIME))
}

export function getUserId() {
  if (!ClientStorage.hasItem(ConfigHelper.INDEXPAZAR_USER_DATA)) {
    return -1
  }

  return Number(JSON.parse(ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_DATA)).UserId)
}

export function isRefreshTime(): boolean {
  const tokenMiliseconds: number = getTokenCreatedTime() - 10800000
  const toDayMiliseconds: number = Number(String(new Date().getTime()))
  const difMinutes: number = (toDayMiliseconds - tokenMiliseconds) / 60000
  return difMinutes >= 102
}
