import '../../css/Footer.scss'
import Image from '../../../components/IPImage'
// import supportunit from "../../../api/supportunit.json";
import { Link } from 'react-router-dom'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import { css } from '@emotion/css'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'

function Footer(props: BaseComponentWithContextProps) {
  const t = translate(Translations)

  const fTop = css`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    align-items: center;

    .fLogo {
      img {
        display: block;
      }
    }

    & > div {
      display: flex;
      justify-content: center;
    }

    @media (max-width: 1000px) {
      gap: 3rem 1rem;

      .fLogo {
        grid-column: span 5;

        img {
          max-width: 280px;
          margin: 0 auto;
        }
      }
    }

    @media (max-width: 770px) {
      grid-template-columns: 1fr 1fr;

      .fLogo {
        grid-column: span 2;
      }
    }
  `

  return (
    <>
      <footer id='footer' className='text-light'>
        <div className='footer-content'>
          <div className='container'>
            <div className='row'>
              <div className={fTop}>
                <div className='fLogo'>
                  <Image alt='' src='/images/footer_logo.png' />
                </div>

                <div>
                  <div className='icon-box mb-0 effect medium center'>
                    <div className='icon'>
                      <Image className='vector' alt='' src='/images/GuvenliAlisveris_CalismaYuzeyi1.png' />
                    </div>
                    <h3>{t('layouts.client.guaranteeOriginality')}</h3>
                  </div>
                </div>

                <div>
                  <div className='icon-box mb-0 effect medium center'>
                    <div className='icon'>
                      <Image className='vector' alt='' src='/images/FinansalCozumCalismaYuzeyi1.png' />
                    </div>
                    <h3>{t('layouts.client.cashPayment')}</h3>
                  </div>
                </div>

                <div>
                  <div className='icon-box mb-0 effect medium center'>
                    <div className='icon'>
                      <Image className='vector' alt='' src='/images/Group 75.png' />
                    </div>
                    <h3>{t('layouts.client.productTracking')}</h3>
                  </div>
                </div>

                <div>
                  <div className='icon-box mb-0 effect medium center'>
                    <div className='icon'>
                      <Image className='vector' alt='' src='/images/Vector (2).png' />
                    </div>
                    <h3>{t('layouts.client.salesSupport')}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='seperator m-0 p-0'></div>
        <div className='footer-content bottom_footer'>
          <div className='container'>
            <div className='row'>
              <div className='col-6 col-lg-4'>
                <div className='widget'>
                  <h4>{t('layouts.client.withIndexGroup')}</h4>
                  <ul className='list'>
                    <li>
                      <Link to='/about'>INDEX GRUP Hakkında</Link>
                    </li>

                    <li>
                      <a target={'_blank'} href='https://indexgrup.com/'>
                        Index Grup
                      </a>
                    </li>
                    <li>
                      <a target={'_blank'} href='https://www.index.com.tr/'>
                        Index AŞ
                      </a>
                    </li>
                    <li>
                      <a target={'_blank'} href='https://www.datagate.com.tr/'>
                        Datagate AŞ
                      </a>
                    </li>
                    <li>
                      <a target={'_blank'} href='https://www.despec.com.tr/'>
                        Despec AŞ
                      </a>
                    </li>
                    <li>
                      <a target={'_blank'} href='https://www.netex.com.tr/'>
                        Netex AŞ
                      </a>
                    </li>
                    <li>
                      <a target={'_blank'} href='https://www.teklos.com.tr/'>
                        Teklos AŞ
                      </a>
                    </li>
                  </ul>
                </div>
                <div className='widget'>
                  <h4>MOBİL UYGULAMALAR</h4>
                  <ul style={{ listStyle: 'none', display: 'flex', padding: 0 }}>
                    <li style={{ marginRight: '2px' }}>
                      <a href="https://play.google.com/store/apps/details?id=com.tr.indexgruppazar " target='_blank'>
                        <img
                          style={{ color: 'black', maxWidth: '130px' }}
                          alt=''
                          src='/images/GooglePlay.png'
                        />
                      </a>
                    </li>
                    <li>
                      <a href="https://play.google.com/store/apps/details?id=com.tr.indexgruppazar " target='_blank'>
                        <img
                          style={{ color: 'black', maxWidth: '130px' }}
                          alt=''
                          src='/images/AppleStore.png'
                        />
                      </a>
                    </li>
                  </ul>
                </div>



              </div>
              <div className='col-6 col-lg-4'>
                <div className='widget'>
                  <h4>{t('layouts.client.customerService')}</h4>
                  <ul className='list'>
                    <li>
                      <Link to='/myspecial/ssh/FaultTracking'>Arıza Takibi</Link>
                    </li>
                    <li>
                      <Link to='/myspecial/xmlSupport'> XML Destek</Link>
                      {/* <a
                      target={"_blank"}
                      href="https://index.vbt.com.tr:1443/myspecial/xmlSupport"
                    ></a> */}
                    </li>
                    <li>
                      <Link to='/myspecial/ssh/SerialNumberCheck'>Seri No Takibi</Link>
                    </li>
                    <li>
                      <Link to='/myspecial/ssh/WarrantyTracking'>Garanti Takibi</Link>
                    </li>
                    <li>
                      <Link to='/myspecial/ssh/SSS'>S.S.S.</Link>
                    </li>
                    {(props.user.IsPermission || props.user.IsMonitorSalesAndOrder) &&
                      <li>
                        <Link to='/myspecial/myorders'>Siparişlerim</Link>
                      </li>
                    }
                    {
                      props.user && props.user.IsDPP && (
                        <li>
                          <Link to='/dpp'>DPP</Link>
                        </li>
                      )}

                    <li>
                      <Link to='/microsoft'>Microsoft </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-6 col-lg-4'>
                <div className='widget'>
                  <h4>{t('layouts.client.shoppingGuideFrom')}</h4>
                  <ul className='list'>
                    <li>
                      <h5> İstanbul Merkez Ofis</h5>
                      <a>
                        Ayazağa Mahallesi, Mimar Sinan Sokak No:21 Seba Office Boulevard, D Blok Kat: 1 Bölüm No: 11
                        Sarıyer, 34485 İstanbul
                      </a>
                      <br />
                      <a href='tel:0 (212) 331 21 21'>0 (212) 331 21 21</a>
                    </li>
                    <li>
                      <h5>TEKLOS AŞ / Lojistik Merkezi</h5>

                      <a>Cumhuriyet Mahallesi Yahyakaptan Caddesi No:10A D:2 Çayırova/Kocaeli</a>

                      <br />

                      <a href='tel:0 (850) 200 28 88'> 0 (850) 200 28 88</a>
                    </li>
                    <li>
                      <h5>Ankara Ofis</h5>
                      <span>
                        <a>Çetin Emeç Bulvarı, Kabil Caddesi No: 4/9 Dikmen, 06460 Ankara</a>

                        <br />
                        <span>
                          <a href='tel:0 (312) 472 82 20'>0 (312) 472 82 20</a>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
                {/* <h4>{t("layouts.client.beInformedAboutTheLatestDiscounts")}</h4>
              <form
                className="widget-subscribe-form p-r-40"
                action="include/subscribe-form.php"
                role="form"
                method="post"
              >
                <div className="input-group">
                  <input
                    aria-required="true"
                    name="widget-subscribe-form-email"
                    className="form-control required email"
                    placeholder={t("layouts.client.enterYourEmail")}
                    type="email"
                  />
                  <span className="input-group-btn">
                    <button
                      type="submit"
                      id="widget-subscribe-submit-button"
                      className="btn"
                    >
                      {t("layouts.client.send")}
                    </button>
                  </span>
                </div>
              </form> */}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-4'>
                <div className='widget'>
                  <h4>Bizimle iletişimde kalın</h4>
                  <div className='social-icons'>
                    <ul>
                      <li className='social-facebook'>
                        <a target='_blank' href='https://www.facebook.com/indexgruptr'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700"><path fill="#a09b97" d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256C0 376 82.7 476.8 194.2 504.5V334.2H141.4V256h52.8V222.3c0-87.1 39.4-127.5 125-127.5c16.2 0 44.2 3.2 55.7 6.4V172c-6-.6-16.5-1-29.6-1c-42 0-58.2 15.9-58.2 57.2V256h83.6l-14.4 78.2H287V510.1C413.8 494.8 512 386.9 512 256h0z" /></svg>
                        </a>
                      </li>
                      <li className='social-twitter'>
                        <a target='_blank' href='https://twitter.com/indexgrup'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700"><path fill="#a09b97" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
                        </a>
                      </li>
                      <li className='social-instagram'>
                        <a href='https://www.instagram.com/indexgrup/?hl=tr' target='_blank'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700"><path fill="#a09b97" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" /></svg>
                        </a>
                      </li>
                      <li className='social-linkedin'>
                        <a target='_blank' href='https://www.linkedin.com/company/index-grup/'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700"><path fill="#a09b97" d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z" /></svg>
                        </a>
                      </li>
                      <li className='social-youtube'>
                        <a href='https://www.youtube.com/user/IndexGrup' target='_blank'>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 700 700"><path fill="#a09b97" d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z" /></svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <br></br>
                  <br></br>
                  <br></br>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='container-fluid'>
                  <div className='row align-items-center text-center'>
                    <div
                      className='icon-box-content mt-5'
                    // style={{
                    //   boxShadow:
                    //     "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                    // }}
                    >
                      <a href='#' className='m-auto'>
                        <img
                          style={{ color: 'black', marginLeft: '8px' }}
                          alt=''
                          src='/images/odeme_logolar.png'
                          width={600}
                        />
                      </a>
                    </div>
                    <a target={'_blank'} href='/images/gizlilik-guvenlik-politikasi.pdf'>
                      Gizlilik ve Güvenlik Politikası
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container-fluid'>
          <div className='row align-items-center text-center' style={{ backgroundColor: 'white' }}>
            <div className='icon-boxxx col-md-3 col-6 '>
              <div className='icon-box-content pl-0'>
                <a href='#' className='m-auto'>
                  <img alt='' src='/images/ındex_logo.png' />
                </a>
              </div>
            </div>
            <div className='icon-boxxx col-md-3 col-6'>
              <div className='icon-box-content pl-0'>
                <a href='#' className='m-auto'>
                  <img alt='' src='/images/datagate_logo.png' />
                </a>
              </div>
            </div>
            <div className='icon-boxxx col-md-3 col-6'>
              <div className='icon-box-content pl-0'>
                <a href='#' className='m-auto'>
                  <img alt='' src='/images/despec_logo.png' />
                </a>
              </div>
            </div>
            <div className='icon-boxxx col-md-3 col-6'>
              <div className='icon-box-content pl-0'>
                <a href='#' className='m-auto'>
                  <img alt='' src='/images/netex_logo.png' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div
        className='yazi'
        style={{
          color: 'black',
          paddingInline: '2rem',
          paddingBottom: '2rem',
          paddingTop: '2rem',
          fontSize: '13px',
          lineHeight: '16px',
          textAlign: 'center',
        }}
      >
        <a>
          Sayfada görülen ürünler, resimleri ve aksesuarları itibarıyla satın aldığınız ürünlerden farklılık
          gösterebilirler. Üretici firma, önceden herhangi bir bilgi vermeden tasarımda değişiklik yapma hakkını saklı
          tutar. Bu değişiklikler ve farklılıklar nedeniyle İndeks Bilgisayar Sistemleri Müh. San. ve Tic. A.Ş.,
          Datagate Bilgisayar Malzemeleri Tic. A.Ş., Despec Bilgisayar Pazarlama Tic. A.Ş. ve Neteks Teknoloji Ürünleri
          A.Ş. herhangi bir sorumluluk kabul etmez. Sayfada bulunan tüm marka, ürün adları, ticari ünvan ve logolar
          ilgili firmalara aittir.
        </a>
      </div>
    </>
  )
}

export default componentWithContext(Footer)
