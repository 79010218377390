import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ICargoResponse } from '../models/models'

export class GetSuggestApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/getSuggestProduct',
      requesterInfo,
    })
  }

  public async getSuggestList(): Promise<any> {
    return await this.getAsync<any>()
  }
}
