import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { GetProductListResponseModel, IProduct } from '../models/response/getProductListResponseModel'

export class GetProductListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/list',
      requesterInfo,
    })
  }

  public async getProducts(): Promise<IProduct[]> {
    return await this.getAsync<IProduct[]>()
  }
}
