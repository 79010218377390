import * as yup from 'yup'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'

const t = translate(Translations)

export const getChangePasswordValidation = (shouldIncludeOldPasswordValidation?: boolean) => {
  const validationShape = Object.assign({})

  if (shouldIncludeOldPasswordValidation) {
    validationShape.oldPassword = yup
      .string()
      .required(t('pages.loginPage.validation.requiredfield'))
      .min(1, t('pages.resetPassword.validation.characterMustBe', ['Parolanız', '1']))
  }

  validationShape.password = yup
    .string()
    .required(t('pages.loginPage.validation.requiredfield'))
    .min(4, t('pages.resetPassword.validation.characterMustBe', ['Parolanız', '4']))

  validationShape.passwordRepeat = yup
    .string()
    .required(t('pages.loginPage.validation.requiredfield'))
    .min(4, t('pages.resetPassword.validation.characterMustBe', ['Parolanız', '4']))

  return yup.object().shape(validationShape)
}
