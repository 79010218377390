/* eslint-disable jsx-a11y/anchor-is-valid */
import { css } from '@emotion/css'
import * as Switch from '@radix-ui/react-switch'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { translate } from '../../../../../base/localization'
import IPButton from '../../../../../components/IPButton'
import IPModal from '../../../../../components/IPModal'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Table from '../../../../../components/Table'
import Translations from '../../../../../translations'
import { IAuthorizedServicesMain, IDescription } from '../models/models'
import { DeleteAuthorizedServicesMainApi } from '../services/deleteAuthorizedServicesMainApi'
import { GetAuthorizedServicesMainApi } from '../services/getAuthorizedServicesMainApi'
import { PutAuthorizedServicesMainApi } from '../services/putAuthorizedServicesApi'

interface IModal {
  showDescription: boolean
  companyListOptions: ISelectOption[]
  companyListOption: any
}

export default function ListAuthorizedServices() {
  const getInitialState = (): IModal => {
    return {
      showDescription: false,
      companyListOption: null,
      companyListOptions: [
        { label: 'Index', value: '1' },
        { label: 'Netex', value: '2' },
        { label: 'Despec', value: '3' },
        { label: 'Datagate', value: '4' },
      ],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IModal>(intialState)
  const [des, setDes] = useState<IDescription>()
  const [brand, setBrand] = useState('')
  const t = translate(Translations)
  const [filteredList, setFilteredList] = useState<IAuthorizedServicesMain[]>([])
  const [brandSelectOptions, setBrandSelectOptions] = useState<ISelectOption[]>([])
  const [brandSelectOption, setBrandSelectOption] = useState<any>()
  const [authorizedServicesMain, setAuthorizedServicesMain] = useState<IAuthorizedServicesMain[]>([])

  useEffect(() => {
    getAuthorizedServices()
  }, [])

  //NOTE: Filtreler için cache tutma
  useEffect(() => {
    const storedCompanyOption = localStorage.getItem('companyListOption')
    if (storedCompanyOption) {
      setState({ ...state, companyListOption: JSON.parse(storedCompanyOption || '') })
    }
    const storedBrandOption = localStorage.getItem('brandSelectOption')
    if (storedBrandOption) {
      setBrandSelectOption(JSON.parse(storedBrandOption))
    }
  }, [])

  const getAuthorizedServices = async () => {
    const uniqueBankLabels = {}

    const getAuthorizedServicesMain: GetAuthorizedServicesMainApi = new GetAuthorizedServicesMainApi({})
    await getAuthorizedServicesMain.getAuthorizedServicesMain().then((result: IAuthorizedServicesMain[]) => {
      if (result) {
        setBrandSelectOptions(
          result
            .map(x => ({
              value: x.BrandId,
              label: x.Brand.Name.toUpperCase(),
            }))
            .filter(option => {
              if (!uniqueBankLabels[option.label]) {
                uniqueBankLabels[option.label] = true
                return true
              }
              return false
            })
        )
        setAuthorizedServicesMain(result as any)
        setFilteredList(result)
      }
    })
  }

  const navigate: any = useNavigate()

  const update = async (Id: any) => {
    var data = authorizedServicesMain.find(auth => {
      return auth.Id === Id
    })

    if (data !== undefined) {
      const clnValues = _.clone(data)
      clnValues.Enable = !clnValues.Enable

      // Check if BrandIds property exists and is an array before accessing it
      if (Array.isArray(clnValues.BrandIds) && clnValues.BrandIds.length > 0) {
        clnValues.BrandIds[0] = clnValues.BrandId
      } else {
        // Handle the case where BrandIds property is not defined or not an array
        clnValues.BrandIds = [clnValues.BrandId]
      }

      const putAuthorizedServicesMain: PutAuthorizedServicesMainApi = new PutAuthorizedServicesMainApi({})
      putAuthorizedServicesMain
        .putAuthorizedServicesMain(clnValues)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Yetkili Servis Güncellendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async res => {
              if (res) {
                setAuthorizedServicesMain(prevState =>
                  prevState.map(row => (row.Id === Id ? { ...row, Enable: clnValues.Enable } : row))
                )
                setFilteredList(prevFilteredList =>
                  prevFilteredList.map(row => (row.Id === Id ? { ...row, Enable: clnValues.Enable } : row))
                )
              }
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }
  }

  const deleteAuthorizedServicesMain = async (Id: any) => {
    if (!Id) {
      return
    }

    Swal.fire({
      icon: 'question',
      title: 'Yetkili servis silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteAuthorizedServicesMain: DeleteAuthorizedServicesMainApi = new DeleteAuthorizedServicesMainApi({})
        deleteAuthorizedServicesMain
          .deleteAuthorizedServicesMain(Id)
          .then(async res => {
            if (res) {
              Swal.fire({
                icon: 'success',
                title: 'Yetkili servis Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async res => {
                if (res) {
                  // Remove the deleted row from the authorizedServicesMain state
                  setAuthorizedServicesMain(prevState => prevState.filter(row => row.Id !== Id))
                  setFilteredList(prevFilteredList => prevFilteredList.filter(row => row.Id !== Id))
                }
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      }
    })
  }

  //#region Tablo ve Filtreleme
  const columnDefinitions = [
    { Header: 'Aktif', accessor: 'Enable' },
    { Header: 'Marka', accessor: 'BrandName' },
    { Header: 'Sıra', accessor: 'OrderNo' },
    { Header: 'Başlangıç Tarihi', accessor: 'StartDate' },
    { Header: 'Bitiş Tarihi', accessor: 'EndDate' },
    { Header: 'Açıklama', accessor: 'Description' },
    { Header: 'Firma', accessor: 'CompanyName' },
    { Header: 'İşlemler', accessor: 'Actions', disableSortBy: true },
  ]

  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableData = useMemo(() => {
    return filteredList.map(dt => ({
      Id: dt.Id,
      Enable: (
        <>
          <Switch.Root className='SwitchRoot' id='active' checked={dt.Enable} onCheckedChange={() => update(dt.Id)}>
            <Switch.Thumb className='SwitchThumb' />
          </Switch.Root>
        </>
      ),
      BrandName: dt.Brand.Name,
      OrderNo: dt.OrderNo,
      StartDate: new Date(dt.StartDate).toLocaleDateString('tr-TR'),
      EndDate: new Date(dt.EndDate).toLocaleDateString('tr-TR'),
      Description: (
        <IPButton
          text='Detay'
          onClick={() => {
            setState({ showDescription: true })
            setDes(dt.Description)
            setState({ showDescription: true })
            setBrand(dt.Brand.Name)
          }}
          type='button'
          style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
          className='btn btn-danger'
        ></IPButton>
      ),
      CompanyName: (
        <div title={dt.Description.CompanyName}>
          {dt.Description.CompanyName.slice(0, 40) + (dt.Description.CompanyName.length > 40 ? ' ...' : '')}
        </div>
      ),
      Actions: (
        <>
          <a
            data-action-type='edit'
            role='button'
            style={{ cursor: 'pointer' }}
            data-toggle='tooltip'
            data-original-title={t('pages.mySpecial.userUpdate.edit')}
            onClick={() => {
              navigate('/admin/brandPageOperation/authorizedServices/addAuthorizedServices', {
                state: { authorizedServicesProcedurData: dt },
              })
            }}
          >
            <i
              className={`${css`
                font-size: 1.2rem;
                color: green;
              `} icon-edit`}
            ></i>
          </a>
          <a
            onClick={() => deleteAuthorizedServicesMain(dt.Id)}
            data-action-type='delete'
            role='button'
            className={css`
              cursor: pointer;
            `}
            data-toggle='tooltip'
            data-original-title={t('pages.mySpecial.userUpdate.delete')}
          >
            <i
              className={`${css`
                font-size: 1.2rem;
                color: red;
              `} icon-trash-2`}
            ></i>
          </a>
        </>
      ),
    }))
  }, [filteredList])

  // Detaylı Süzme fonksiyonu
  const filterList = () => {
    const filterConditions: ((item: IAuthorizedServicesMain) => boolean)[] = []

    if (brandSelectOption) {
      filterConditions.push(b => b.Brand.Name.toUpperCase() === brandSelectOption.label)
    }

    if (state.companyListOption) {
      filterConditions.push(c => c.Brand.Companies.includes(state.companyListOption.value))
    }

    let filteredData: IAuthorizedServicesMain[] = authorizedServicesMain

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
    }
    setFilteredList(filteredData)
    //Note: Filtre Cache kaydı
    if (state.companyListOption) {
      localStorage.setItem('companyListOption', JSON.stringify(state.companyListOption))
    } else {
      localStorage.removeItem('companyListOption')
    }
    if (brandSelectOption) {
      localStorage.setItem('brandSelectOption', JSON.stringify(brandSelectOption))
    } else {
      localStorage.removeItem('brandSelectOption')
    }
  }
  //#endregion

  return (
    <React.Fragment>
      <div className='container'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <span className='h4'>Yetkili Servisler</span>
              <div className='button m-r-20'>
                <Link to='/admin/brandPageOperation/authorizedServices/addAuthorizedServices'>
                  <button type='button' className='btn btn-primary'>
                    Ekle
                  </button>
                </Link>
              </div>
            </div>
            {/* Buton ve boşluk düzenlemsi yapıldı. */}
            <div className='card-body'>
              <div className='detailFilter'>
                <div className='row ms-1'>
                  <div className='form-group col-md-3'>
                    <IPSelectBox
                      isClearable
                      placeholder='Şirket'
                      options={state.companyListOptions}
                      value={state.companyListOption}
                      onChangeSingle={option => {
                        setState({ companyListOption: option })
                      }}
                    />
                  </div>
                  <div className='form-group col-md-3'>
                    <IPSelectBox
                      isClearable
                      placeholder='Marka'
                      options={brandSelectOptions}
                      value={brandSelectOption}
                      onChangeSingle={option => {
                        setBrandSelectOption(option)
                      }}
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <IPButton
                      type='button'
                      className='btn btn-primary'
                      text='Filtrele'
                      onClick={filterList}
                      style={{ height: '45px' }}
                    />
                  </div>
                </div>
              </div>
              <div className='m-4 text-nowrap'>
                <Table columns={tableColumns} data={tableData} textAlign={true} color='white' />
              </div>
            </div>
          </div>
          <IPModal
            show={state.showDescription}
            onClose={() => setState({ showDescription: false })}
            hideConfirm={true}
            width={800}
            title={brand + ' YETKİLİ SERVİS NOKTASI'}
          >
            <div className='col'>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '400px' }}>Ürün Kodu</b> <span className='mr-2'>:</span>
                {des?.ProductGroup}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '400px' }}>Firma İsmi</b>
                <span className='mr-2'>:</span>
                {des?.CompanyName}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '400px' }}>Adres</b> <span className='mr-2'>:</span> {des?.Address}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '400px' }}>Telefon</b> <span className='mr-2'>:</span>
                {des?.Phone}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '400px' }}>E-Posta</b>
                <span className='mr-2'>:</span>
                {des?.Email}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '400px' }}>Web Adresi</b> <span className='mr-2'>:</span>{' '}
                <a href={des?.WebAddress}>{des?.WebAddress}</a>
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '400px' }}>Ücret ödemeli kargo kabul ediliyor mu?</b> <span className='mr-2'>:</span>{' '}
                {des?.IsChargedShippingAccepted}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '400px' }}>Anlaşmalı kargo şirketi(varsa gönderi kodu)</b>{' '}
                <span className='mr-2'>:</span> {des?.ContractedCargoCompany}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '400px' }}>Yetkili servis noktaları</b> <span className='mr-2'>:</span>{' '}
                {des?.AuthorizedServicePoints}
              </div>
            </div>
          </IPModal>
        </div>
      </div>
    </React.Fragment>
  )
}
