import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IAddress } from '../models/models'

export class GetAddressesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'address/list',
      requesterInfo,
    })
  }

  public async getAddresses(request: any): Promise<IAddress> {
    return await this.postAsync<IAddress>(request)
  }
}
