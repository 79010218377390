import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IGetProductByIDResponseModel } from '../models/getProductByIDResponseModel'

export class GetProductByIDApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/',
      requesterInfo,
    })
  }

  public async getProductByID(productId: number): Promise<IGetProductByIDResponseModel> {
    this.props.url = this.props.url + productId
    return await this.getAsync<IGetProductByIDResponseModel>()
  }
}
