import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPRadio from '../../../../../components/IPRadio'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import { useCustomReducer } from '../../../../../base/customHooks'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPInput from '../../../../../components/IPInput'
import { useEffect, useMemo, useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import Swal from 'sweetalert2'
import { ISap } from '../models/requestModels'
import { PostSyncProductApi } from '../services/postSyncProductApi'
import { PostSyncDealerApi } from '../services/postSyncDealerApi'
import { PostSyncCategoryandgroupApi } from '../services/postSyncCategoryandgroupApi'
import { GetSapListApi } from '../services/getSyncHistoryApi'
import Table from '../../../../../components/Table'
import { SapSyncHistoryModel } from '../models/responseModels'
import moment from 'moment'
import InputCampaign from '../../../../../components/InputCampaign'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'

interface ISapState {
  companies: ICompany[]
  companyOption?: ISelectOption
  radioCheck: number
  parameters: string
  parameters2: string
  currentBool: boolean
  currentTypeBool: boolean
  method: string
  parameterType: number
  history: boolean
  historyModel: SapSyncHistoryModel[]
}

function Sap(props: BaseComponentWithContextProps) {
  const t = translate(Translations)

  const getInitialState = (): ISapState => {
    return {
      companies: [],
      radioCheck: 0,
      parameters: '',
      currentBool: false,
      currentTypeBool: false,
      method: "",
      parameterType: 0,
      parameters2: "",
      history: false,
      historyModel: []
    }
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ISapState>(intialState)
  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push(co)
      })
    setState({ companies: data })
  }

  const postSync = async () => {
    if (!state.companyOption) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen şirket seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (state.method.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen method seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (state.radioCheck === 4 && (state.parameters.length === 0 && state.parameters2.length === 0)) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen parametre giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if ((state.radioCheck === 1
      || state.radioCheck === 2
      || state.radioCheck === 3
      || state.radioCheck === 5
      || state.radioCheck === 6)
      && state.parameters.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen parametre giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (
      state.radioCheck === 1 ||
      state.radioCheck === 2 ||
      state.radioCheck === 5 ||
      state.radioCheck === 6 ||
      state.radioCheck === 7 ||
      state.radioCheck === 8 ||
      state.radioCheck === 9 ||
      state.radioCheck === 10
    ) {
      props.showLoading()
      const req: ISap = {
        Company: state.companyOption ? +state.companyOption.value : 0,
        Method: state.method,
        Parameters: state.parameters,
        ParameterType: state.parameterType
      }
      const postSyncProductApi: PostSyncProductApi = new PostSyncProductApi({})
      postSyncProductApi
        .postSyncProduct(req)
        .then((res: any) => {
          if (res) {
            Swal.fire({
              icon: 'info',
              title: res,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          }
        })
        .catch((e: any) => {
          Swal.fire({
            icon: 'error',
            title: e.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
    if (state.radioCheck === 3 || state.radioCheck === 4) {
      props.showLoading()
      const req: ISap = {
        Company: state.companyOption ? +state.companyOption.value : 0,
        Method: state.method,
        Parameters: state.radioCheck === 3
          ? state.parameters.length >= 6
            ? state.parameters.padStart(10, '0')
            : state.parameters
          : (state.parameters.length >= 6
            ? state.parameters.padStart(10, '0')
            : state.parameters) + "|" + state.parameters2,
        ParameterType: state.parameterType
      }
      const postSyncDealerApi: PostSyncDealerApi = new PostSyncDealerApi({})
      postSyncDealerApi
        .postSyncDealer(req)
        .then((res: any) => {
          if (res) {
            Swal.fire({
              icon: 'info',
              title: res,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          }
        })
        .catch((e: any) => {
          Swal.fire({
            icon: 'error',
            title: e.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
    if (state.radioCheck === 11 || state.radioCheck === 12) {
      props.showLoading()
      const req: ISap = {
        Company: state.companyOption ? +state.companyOption.value : 0,
        Method: state.method,
        Parameters: state.parameters,
        ParameterType: state.parameterType
      }
      const postSyncCategoryandgroupApi: PostSyncCategoryandgroupApi = new PostSyncCategoryandgroupApi({})
      postSyncCategoryandgroupApi
        .postSyncCategoryandgroup(req)
        .then((res: any) => {
          if (res) {
            Swal.fire({
              icon: 'info',
              title: res,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          }
        })
        .catch((e: any) => {
          Swal.fire({
            icon: 'error',
            title: e.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
  }

  const History = async () => {
    const req = {
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate)
    }
    props.showLoading()
    const history: GetSapListApi = new GetSapListApi({})
    history.getSyncHistory(req).then((res: any) => {
      if (res) {
        setState({ history: true, historyModel: res })
        props.hideLoading()
      }
    })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const columnDefinitions = [
    { Header: 'Fonksiyon', accessor: 'col1' },
    { Header: 'Metot', accessor: 'col2' },
    { Header: 'Parametre', accessor: 'col3' },
    { Header: 'Şirket', accessor: 'col4' },
    { Header: 'Durum', accessor: 'col5' },
    { Header: 'Başlangıç Tarihi', accessor: 'col6' },
    { Header: 'Bitiş Tarihi', accessor: 'col7' },
    { Header: 'Bayi Kodu', accessor: 'col8' },
    { Header: 'Kullanıcı Adı', accessor: 'col9' },
  ];

  const tableColumns = useMemo(() => columnDefinitions, []);
  // Tablo verileri
  const tableDataAndTotal = useMemo(
    () => {
      const tableData = state.historyModel.map(item => {
        const data = {
          col1: item.FuncName,
          col2: item.Method,
          col3: item.Parameters,
          col4: item.Company,
          col5: item.IsFinished === true ? "Tamamlandı" : "",
          col6: item.StartTime && moment(item.StartTime).format('DD.MM.YYYY HH:mm:ss'),
          col7: item.EndTime && moment(item.EndTime).format('DD.MM.YYYY HH:mm:ss'),
          col8: item.dealerCode && item.dealerCode.replace('0000', ''),
          col9: item.userName,
        }
        // if (!isNaN(+item.PayInfo)) {
        //   totalPayInfo += parseFloat(item.PayInfo);
        // }
        return data
      })
      return {
        tableData,
      };
    }
    , [History])


  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>{t('pages.admin.homeAndSiteOperation.sap.sapList.header')}</span>
            <br />
            <span className='h5'>{t('pages.admin.homeAndSiteOperation.sap.sapList.subHeader')}</span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row m-t-5'>
                <div className='col-lg-4' style={{ marginTop: '15px' }}>
                  <label>Şirket</label>
                  <IPSelectBox
                    isClearable
                    options={
                      state.companies &&
                      state.companies.map((item: ICompany) => ({
                        label: item.Name,
                        value: `${item.Code}`,
                      }))
                    }
                    placeholder='Şirket Seçiniz...'
                    value={state.companyOption}
                    onChangeSingle={option => setState({ companyOption: option })}
                  />
                </div>
                <div className='col-lg-4' style={{ marginTop: '10px' }}>
                  <IPRadio
                    type='radio'
                    text='Ürün Kartları/Fiyatları (Ürün Kodu)'
                    name='Active'
                    checked={state.radioCheck === 1}
                    onChange={() => setState({ radioCheck: 1, parameters: '', parameters2: '', method: 'ProductAndPrice', parameterType: 1 })}
                  />
                  <IPInput
                    type='text'
                    disabled={state.radioCheck === 1 ? false : true}
                    value={state.radioCheck === 1 ? state.parameters : ''}
                    onChange={e => setState({ parameters: e.target.value })}
                  />
                </div>
                <div className='col-lg-4' style={{ marginTop: '10px' }}>
                  <IPRadio
                    type='radio'
                    text='Ürün Kartları/Tanımları (Kota Kodu)'
                    name='Active'
                    checked={state.radioCheck === 2}
                    onChange={() => setState({ radioCheck: 2, parameters: '', parameters2: '', method: 'Product', parameterType: 2 })}
                  />
                  <IPInput
                    type='text'
                    disabled={state.radioCheck === 2 ? false : true}
                    value={state.radioCheck === 2 ? state.parameters : ''}
                    onChange={e => setState({ parameters: e.target.value })}
                  />
                </div>
                <div className='col-lg-4' style={{ marginTop: '10px' }}>
                  <IPRadio
                    type='radio'
                    text='Cari Kartlar (SAP Hesap Kodu)'
                    name='Active'
                    checked={state.radioCheck === 3}
                    onChange={() => setState({ radioCheck: 3, parameters: '', parameters2: '', method: 'Dealer', parameterType: 0 })}
                  />
                  <IPInput
                    type='text'
                    disabled={state.radioCheck === 3 ? false : true}
                    value={state.radioCheck === 3 ? state.parameters : ''}
                    onChange={e => setState({ parameters: e.target.value })}
                  />
                </div>
                <div className='col-lg-8' style={{ marginTop: '10px' }}>
                  <IPRadio
                    type='radio'
                    text='Cari Alt Tip (SAP Hesap Kodu / Alt Tip Kodu)'
                    name='Active'
                    checked={state.radioCheck === 4}
                    onChange={() => setState({ radioCheck: 4, parameters: '', parameters2: '', method: 'DealerSubType', parameterType: 0 })}
                  />
                  <div className='row'>
                    <div className='col-lg-6'>
                      <IPInput
                        type='text'
                        disabled={state.radioCheck === 4 ? false : true}
                        value={state.radioCheck === 4 ? state.parameters : ''}
                        onChange={e => {
                          setState({ parameters: e.target.value })
                        }}
                      />
                    </div>
                    <div className='col-lg-1' style={{ marginTop: '-8px' }}>
                      <h1>/</h1>
                    </div>
                    <div className='col-lg-5'>
                      <IPInput
                        type='text'
                        disabled={state.radioCheck === 4 ? false : true}
                        value={state.radioCheck === 4 ? state.parameters2 : ''}
                        onChange={e => {
                          setState({ parameters2: e.target.value })
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='col-lg-4' style={{ marginTop: '10px' }}>
                  <IPRadio
                    type='radio'
                    text='Ürün Fiyatları (Kota Kodu)'
                    name='Active'
                    checked={state.radioCheck === 5}
                    onChange={() => setState({ radioCheck: 5, parameters: '', parameters2: '', method: 'Price', parameterType: 2 })}
                  />
                  <IPInput
                    type='text'
                    disabled={state.radioCheck === 5 ? false : true}
                    value={state.radioCheck === 5 ? state.parameters : ''}
                    onChange={e => setState({ parameters: e.target.value })}
                  />
                </div>
                <div className='col-lg-4' style={{ marginTop: '10px' }}>
                  <IPRadio
                    type='radio'
                    text='Ürün Özellikleri (Kota Kodu)'
                    name='Active'
                    checked={state.radioCheck === 6}
                    onChange={() => setState({ radioCheck: 6, parameters: '', parameters2: '', method: 'ProductProperty', parameterType: 2 })}
                  />
                  <IPInput
                    type='text'
                    disabled={state.radioCheck === 6 ? false : true}
                    value={state.radioCheck === 6 ? state.parameters : ''}
                    onChange={e => setState({ parameters: e.target.value })}
                  />
                </div>
                <div className='col-lg-4' style={{ marginTop: '10px' }}>
                  {/* <IPRadio
                    type='radio'
                    text='Ürün Kartları/Kelime Arama(Ürün Kodu)'
                    name='Active'
                    checked={state.radioCheck === 7}
                    onChange={() => setState({ radioCheck: 7, parameters: '' })}
                  />
                  <IPInput
                    type='text'
                    disabled={state.radioCheck === 7 ? false : true}
                    value={state.radioCheck === 7 ? state.parameters : ''}
                    onChange={e => setState({ parameters: e.target.value })}
                  /> */}
                </div>
                <div className='col-lg-4' style={{ marginTop: '10px', paddingLeft: '20px' }}>
                  <div className='row'>
                    <IPRadio
                      type='radio'
                      text='Ürün Kartları/Fiyatları (Tüm ürünler)'
                      name='Active'
                      checked={state.radioCheck === 8}
                      onChange={() => setState({ radioCheck: 8, parameters: '', parameters2: '', method: 'ProductAndPrice', parameterType: 0 })}
                    />
                  </div>
                </div>
                <div className='col-lg-4' style={{ marginTop: '10px' }}>
                  <IPRadio
                    type='radio'
                    text='Ürün Marka/Model Tanımları'
                    name='Active'
                    checked={state.radioCheck === 9}
                    onChange={() => setState({ radioCheck: 9, parameters: '', parameters2: '', method: 'ProductBrand', parameterType: 0 })}
                  />
                </div>
                {/* <div className='col-lg-4' style={{ marginTop: '10px' }}>
                  <IPRadio
                    type='radio'
                    text='Bağlantılı/İlintili Ürün Tanımları'
                    name='Active'
                    checked={state.radioCheck === 10}
                    onChange={() => setState({ radioCheck: 10, parameters: '' })}
                  />
                </div> */}
                <div className='col-lg-4' style={{ marginTop: '10px' }}>
                  <IPRadio
                    type='radio'
                    text='Kategori ve Grup Tanımları'
                    name='Active'
                    checked={state.radioCheck === 11}
                    onChange={() => setState({ radioCheck: 11, parameters: '', parameters2: '', method: 'ProductCategory', parameterType: 0 })}
                  />
                </div>
                {/* <div className='col-lg-4' style={{ marginTop: "10px" }}>
                  <IPRadio
                    type='radio'
                    text="Menü Ağacı"
                    name="Active"
                    checked={state.radioCheck === 12}
                    onChange={() => setState({ radioCheck: 12, parameters: "" })}
                  />
                </div> */}
              </div>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Çalıştır'
                style={{ width: '25px', marginRight: '10px', float: 'right', marginTop: '10px' }}
                onClick={postSync}
              />
            </form>
          </div>
          <div className='col-lg-12'>
            <div className='form-row justify-content-center'>
              <div className='form-group col-md-3'>
                <InputCampaign
                  type='datetime-local'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, "Start")}
                  isActive
                />
              </div>

              <div className='form-group col-md-3'>
                <InputCampaign
                  type='datetime-local'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, "End")}
                  isActive
                />
              </div>
            </div>
            <h5 style={{ textAlign: 'center' }}>
              <a onClick={History} style={{ cursor: 'pointer' }}>
                Geçmişi Göster
              </a>
            </h5>
            {state.history &&
              <Table columns={tableColumns} data={tableDataAndTotal.tableData} currentPage={0} headerName={"SAP Güncelleme Geçmişi"} columnCount={16} backgroundColor="red" color="white" />
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Sap)
