import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { useMemo } from 'react'
import { Line } from 'react-chartjs-2'
import { IDashBoardOrderDataResponse } from '../models/models'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const chartOptions = {
  stack: true,
  maintainAspectRatio: true,
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: 'top' as const,
    },
  },
  scales: {
    y: {
      ticks: {
        stepSize: 10,
      },
    },
  },
}

const months = [
  'OCAK',
  'SUBAT',
  'MART',
  'NISAN',
  'MAYIS',
  'HAZIRAN',
  'TEMMUZ',
  'AGUSTOS',
  'EYLUL',
  'EKIM',
  'KASIM',
  'ARALIK',
]

function turnIntoNumber(val: string, symbol: '$' | '€' | '₺') {
  return Number(val.replaceAll('.', '').replace(',', '.').split(` ${symbol}`)[0])
}

export default function CurrencyChart({ data }) {
  const chartData = useMemo(() => {
    const chart: IDashBoardOrderDataResponse[] = data

    const TL: any = []
    const USD: any = []
    const EUR: any = []
    const labels: any = []

    for (const val of chart) {
      // EUR.push(turnIntoNumber(val.OrderAmountEURO, '€'))
      USD.push(turnIntoNumber(val.OrderAmountUSD, '$'))
      TL.push(turnIntoNumber(val.OrderAmountTL, '₺'))
      labels.push(months[val.Month - 1])
    }

    const datasets: any = [
      {
        label: 'Türk Lirası',
        data: TL,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Dolar',
        data: USD,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.5)',
      },
      // {
      //   label: 'Euro',
      //   data: EUR,
      //   borderColor: 'rgb(53, 162, 235)',
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ]

    return {
      labels,
      datasets,
    }
  }, [data])

  return <Line options={chartOptions} data={chartData} />
}
