import React, { useEffect, useState } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPSelectBox from '../../../../../components/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { GetWhiteListModel, WhiteListRequestModel } from '../models/whiteDealerModel'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetWhiteListApi } from '../services/getWhiteListApi'
import Swal from 'sweetalert2'
import { ICompany } from '../../../components/models/models'
import { getCompanyApi } from '../../../components/api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { DeleteWhiteDealerApi } from '../services/deleteWhiteDealerApi'

interface WhiteListState {
  type: number
  typeOption?: ISelectOption
  companyOption?: ISelectOption
  CompanyCode: string
  DealerCode: string
  companies: ICompany[]
  companyData: ISelectOption[]
}

function TransactionList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const [searchParams, setSearchParams] = useSearchParams()

  const companyParam = searchParams.get('Sirket')
  const bayi = searchParams.get('Bayi')
  const tip = searchParams.get('Tip')

  const ISelectOption: ISelectOption[] = [
    {
      label: t('pages.admin.freeAndOpportunityPosOperation.transaction.transactionList.daytime'),
      value: 1,
    },
    {
      label: t('pages.admin.freeAndOpportunityPosOperation.transaction.transactionList.night'),
      value: 2,
    },
    {
      label: t('pages.admin.freeAndOpportunityPosOperation.transaction.transactionList.daytimeNight'),
      value: 3,
    },
    {
      label: t('pages.admin.freeAndOpportunityPosOperation.transaction.transactionList.solo'),
      value: 4,
    },
  ]

  const getInitialState = (): WhiteListState => {
    return {
      type: tip ? Number(ISelectOption.find(a => a.label == tip)?.value) : 0,
      typeOption: tip ? (ISelectOption.find(a => a.label == tip)) : undefined,
      companies: [],
      CompanyCode: '',
      DealerCode: bayi ? bayi : '',
      companyData: [],
    }
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<WhiteListState>(intialState)
  const [whiteList, setWhiteList] = useState<GetWhiteListModel[]>([])

  const getCompanies = async () => {
    props.showLoading()
    const company: ICompany[] = await getCompanyApi()
    const data: ISelectOption[] = []
    company.map(com => {
      return data.push({ value: com.Code, label: com.Name })
    })
    setState({
      companies: company,
      companyData: data,
      CompanyCode: companyParam ? company.find(a => a.Name == companyParam)?.Code.toString() : '',
      companyOption: companyParam ? { label: (company.find(a => a.Name == companyParam))?.Name, value: Number((company.find(a => a.Name == companyParam))?.Code) } : undefined
    })
    props.hideLoading()
  }

  useEffect(() => {
    if
      (state.companies && state.companies.length > 0 && (
        (state.type !== 0) || state.DealerCode != '' || state.CompanyCode != '')
    ) {
      getWhiteList()
    }
  }, [state.companies])

  const getWhiteList = async () => {
    const queryParams = {
      Sirket: state.CompanyCode ? state.companies.find(a => a.Code.toString() == state.CompanyCode)?.Name : undefined,
      Tip: state.type ? ISelectOption.find(a => a.value == Number(state.type))?.label : undefined,
      Bayi: (state.DealerCode !== "" && state.DealerCode != null) ? state.DealerCode : undefined,
    }

    const filteredQueryParams = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const navigateRoute = `/admin/CreditCardPaymentCampaigns/WhiteDealerList?${filteredQueryParams}`
    navigate(navigateRoute)

    props.showLoading()
    const data: WhiteListRequestModel = {
      CompanyCode: state.CompanyCode,
      DealerCode: state.DealerCode && (state.DealerCode.length === 10 ? state.DealerCode.replace("0000", "") : state.DealerCode),
      ListType: state.type,
    }
    const getWhiteListApi: GetWhiteListApi = new GetWhiteListApi({})
    getWhiteListApi
      .getWhiteList(data)
      .then(res => {
        if (res) {
          setWhiteList(res)
          props.hideLoading()
        }
      })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const deleteWhiteDealer = async (company: string, dealerCode: string) => {

    Swal.fire({
      icon: 'question',
      title: 'Beyaz Bayi Kaydını Silmek İstediğinize Emin Misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const data: any = {
          CompanyCode: company,
          DealerCode: dealerCode,
        }
        const deleteWhiteDealerApi: DeleteWhiteDealerApi = new DeleteWhiteDealerApi({})
        deleteWhiteDealerApi
          .deleteWhiteDealer(data)
          .then(res => {
            getWhiteList()
            if (res) {
              Swal.fire({
                icon: 'success',
                title: res,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              props.hideLoading()
            }
          })
          .catch((e: any) => {
            Swal.fire({
              icon: 'error',
              title: e.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
  }

  const navigate: any = useNavigate()

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className='h4'>
              {t('pages.admin.freeAndOpportunityPosOperation.transaction.transactionList.header')}
            </span>
            <div className='form-label col-lg-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className='buttonsuz'>
                <IPButton
                  type='button'
                  className='productCodeFileInput'
                  text={'Ekle'}
                  onClick={() => {
                    navigate(`../admin/CreditCardPaymentCampaigns/AddWhiteDealerList`)
                  }}
                />
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Şirket
                  </label>
                  <IPSelectBox
                    isClearable
                    id='DealerContents'
                    name='DealerContents'
                    options={state.companyData}
                    onChangeSingle={option => {
                      if (option) return setState({ companyOption: option, CompanyCode: option.value.toString() })
                      return setState({ companyOption: option, CompanyCode: '' })
                    }}
                    value={state.companyOption}
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='bayikodu' className='form-label'>
                    {t('pages.admin.freeAndOpportunityPosOperation.transaction.transactionList.listType')}
                  </label>
                  <IPSelectBox
                    isClearable
                    id='DealerContents'
                    name='DealerContents'
                    options={ISelectOption}
                    onChangeSingle={option => {
                      if (option) return setState({ typeOption: option, type: Number(option.value) })
                      return setState({ typeOption: option, type: 0 })
                    }}
                    value={state.typeOption}
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='bayikodu' className='form-label'>
                    {t('pages.admin.freeAndOpportunityPosOperation.transaction.transactionList.dealerCode')}
                  </label>
                  <IPInput
                    type='text'
                    className='form-control'
                    id='bayikodu'
                    value={state.DealerCode}
                    onChange={e => setState({ DealerCode: e.target.value })}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={t('pages.admin.freeAndOpportunityPosOperation.transaction.transactionList.filtre')}
                        onClick={getWhiteList}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </form>
            <br />
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      {
                        whiteList &&
                        <b>{whiteList.length} adet beyaz bayi listeleniyor.</b>
                      }
                      <table
                        id='datatable'
                        className='table table-bordered table-hover dataTable'
                        role='grid'
                        aria-describedby='datatable_info'
                      >
                        <thead>
                          <tr role='row' className='odd'>
                            <th>#</th>
                            <th>Şirket Kodu</th>
                            <th>Bayi Kodu</th>
                            <th>Bayi Adı</th>
                            <th>Gündüz</th>
                            <th>Gece</th>
                            <th>Yalnız 3D</th>
                            <th>İşlemler</th>
                          </tr>
                        </thead>
                        <tbody>
                          {whiteList &&
                            whiteList.length > 0 &&
                            whiteList.map((i, index) => (
                              <tr role='row' className='odd' key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {i.CompanyCode &&
                                    state.companies.find(com => {
                                      return +com.Code === +Number(i.CompanyCode)
                                    })?.Name}
                                </td>
                                <td>{i.DealerCode}</td>
                                <td>{i.DealerName}</td>
                                <td>{i.DayTime === true ? 'E' : 'H'}</td>
                                <td>{i.Night === true ? 'E' : 'H'}</td>
                                <td>{i.Alone3D === true ? 'E' : 'H'}</td>
                                <td>
                                  <a
                                    data-action-type='edit'
                                    role='button'
                                    style={{ cursor: 'pointer' }}
                                    data-toggle='tooltip'
                                    data-original-title='Güncelle'
                                    onClick={() => {
                                      navigate('/admin/CreditCardPaymentCampaigns/AddWhiteDealerList', { state: { data: i, flag: 'update', url: window.location.href } })
                                    }}
                                  >
                                    <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
                                  </a>
                                  <a
                                    data-action-type='delete'
                                    role='button'
                                    style={{ cursor: 'pointer' }}
                                    data-toggle='tooltip'
                                    data-original-title='Sil'
                                    onClick={() => deleteWhiteDealer(i.CompanyCode, i.DealerCode)}
                                  >
                                    <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(TransactionList)
