import React, { useEffect, useState } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPSelectBox from '../../../../../components/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { GetWhiteListModel, WhiteListRequestModel } from '../models/whiteDealerModel'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetWhiteListApi } from '../services/getWhiteListApi'
import Swal from 'sweetalert2'
import { ICompany } from '../../../components/models/models'
import { getCompanyApi } from '../../../components/api'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AddWhiteListApi } from '../services/addWhiteListApi'

interface WhiteListState {
  type: number
  typeOption?: ISelectOption
  typeOptionDay?: ISelectOption
  typeOptionNight?: ISelectOption
  typeOption3D?: ISelectOption
  companyOption?: ISelectOption
  CompanyCode: string
  DealerCode: string
  update: boolean
  companies: ICompany[]
  companyData: ISelectOption[]
  id?: number
}

function AddTransactionList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)

  const getInitialState = (): WhiteListState => {
    return {
      type: 0,
      companies: [],
      CompanyCode: '',
      DealerCode: '',
      companyData: [],
      update: false
    }
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<WhiteListState>(intialState)
  const routeLocation = useLocation()

  const ISelectOption: ISelectOption[] = [
    {
      label: 'Evet',
      value: 1,
    },
    {
      label: 'Hayır',
      value: 0,
    },
  ]

  const getCompanies = async () => {
    props.showLoading()
    const company: ICompany[] = await getCompanyApi()
    const data: ISelectOption[] = []
    company.map(com => {
      return data.push({ value: com.Code, label: com.Name })
    })
    setState({ companies: company, companyData: data })
    props.hideLoading()
  }

  const addWhiteList = async () => {
    try {
      if (!(state.typeOptionDay?.value != null && state.typeOptionNight?.value != null && state.typeOption3D?.value != null && state.DealerCode && state.CompanyCode)) {
        return Swal.fire({
          icon: 'error',
          title: "Bütün Alanları Doldurunuz!",
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
      props.showLoading()
      const req: any = {
        Day: state.typeOptionDay?.value === 1 ? true : false,
        Night: state.typeOptionNight?.value === 1 ? true : false,
        ThreeD: state.typeOption3D?.value === 1 ? true : false,
        DealerCode: state.DealerCode,
        Company: state.CompanyCode,
        Id: state.id,
        Update: state.update
      }

      const list: AddWhiteListApi = new AddWhiteListApi({})
      await list.AddWhiteDealer(req).then(res => {
        if (res) {
          Swal.fire({
            icon: 'success',
            title: res,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        }
      })
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  useEffect(() => {
    if (routeLocation.state && (routeLocation.state as any)?.data && state.companyData) {
      const data = (routeLocation.state as any)?.data
      const flag = (routeLocation.state as any)?.flag
      setState({
        update: flag === 'update' ? true : false,
        companyOption: state.companyData.find(a => a.value == data.CompanyCode),
        DealerCode: data.DealerCode,
        CompanyCode: data.CompanyCode,
        typeOptionDay: ISelectOption.find(a => a.value == data.DayTime),
        typeOption3D: ISelectOption.find(a => a.value == data.Alone3D),
        typeOptionNight: ISelectOption.find(a => a.value == data.Night),
        id: data.Id
      })
    }
  }, [state.companyData])

  const navigate: any = useNavigate()

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className='h4'>
              Beyaz Bayi Ekleme
            </span>
            <div className='form-label col-lg-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className='buttonsuz'>
                <Link
                  to={
                    (routeLocation.state && (routeLocation.state as any)?.url)
                      ? routeLocation.state.url : '/admin/CreditCardPaymentCampaigns/WhiteDealerList'
                  }
                >
                  <IPButton
                    type='button'
                    className='btn btn-success'
                    text={'Listeye Dön'}
                  />
                </Link>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-2'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Şirket
                  </label>
                  <IPSelectBox
                    isClearable
                    id='DealerContents'
                    name='DealerContents'
                    options={state.companyData}
                    onChangeSingle={option => {
                      if (option) return setState({ companyOption: option, CompanyCode: option.value.toString() })
                      return setState({ companyOption: option, CompanyCode: '' })
                    }}
                    value={state.companyOption}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Gündüz
                  </label>
                  <IPSelectBox
                    isClearable
                    id='DealerContents'
                    name='DealerContents'
                    options={ISelectOption}
                    onChangeSingle={option => {
                      if (option) return setState({ typeOptionDay: option, type: Number(option.value) })
                      return setState({ typeOptionDay: option, type: 0 })
                    }}
                    value={state.typeOptionDay}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Gece
                  </label>
                  <IPSelectBox
                    isClearable
                    id='DealerContents'
                    name='DealerContents'
                    options={ISelectOption}
                    onChangeSingle={option => {
                      if (option) return setState({ typeOptionNight: option, type: Number(option.value) })
                      return setState({ typeOptionNight: option, type: 0 })
                    }}
                    value={state.typeOptionNight}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Yalnız 3D
                  </label>
                  <IPSelectBox
                    isClearable
                    id='DealerContents'
                    name='DealerContents'
                    options={ISelectOption}
                    onChangeSingle={option => {
                      if (option) return setState({ typeOption3D: option, type: Number(option.value) })
                      return setState({ typeOption3D: option, type: 0 })
                    }}
                    value={state.typeOption3D}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='bayikodu' className='form-label'>
                    {t('pages.admin.freeAndOpportunityPosOperation.transaction.transactionList.dealerCode')}
                  </label>
                  <IPInput
                    type='number'
                    className='form-control'
                    id='bayikodu'
                    value={state.DealerCode}
                    maxLength={6}
                    onChange={e => {
                      setState({ DealerCode: e.target.value })
                    }
                    }
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={state.update ? 'Güncelle' : 'Ekle'}
                        onClick={addWhiteList}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(AddTransactionList)
