import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export class GetOpportunitMarketApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/opportunit-market',
      requesterInfo,
    })
  }

  public async getOpportunitMarket(): Promise<any[]> {
    return await this.getAsync<any[]>()
  }
}
