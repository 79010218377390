import React, { useEffect, useMemo } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPModal from '../../../../../components/IPModal'
import { useCustomReducer } from '../../../../../base/customHooks'
import { ListParamPosCompanyApi } from '../service/listParamPosCompanyApi'
import Swal from 'sweetalert2'
import { IParamposCompany, IParamposCompanyHistory, IParamposCompanyRequest } from '../models/models'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import Table from '../../../../../components/Table'
import IPButton from '../../../../../components/IPButton'
import * as Switch from '@radix-ui/react-switch'
import { UpdateParamPosCompanyApi } from '../service/updateParamPosCompanyApi'
import { ListParamPosCompanyHistoryApi } from '../service/listParamPosCompanyHistoryApi'

interface IParamposCompanyState {
  showDetail: boolean,
  date: string,
  paramposCompanyList: IParamposCompany[]
  paramposCompanyHistoryList: IParamposCompanyHistory[]
  companies: ICompany[]
}

function ParamposCompany(props: BaseComponentWithContextProps) {
  const getInitialState = (): IParamposCompanyState => {
    return {
      showDetail: false,
      date: new Date().toLocaleString('tr-TR'),
      paramposCompanyList: [],
      companies: [],
      paramposCompanyHistoryList: []
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IParamposCompanyState>(intialState)

  useEffect(() => {
    listParamPosCompany();
    getCompanies();
  }, [])


  const listParamPosCompany = async () => {
    props.showLoading();
    const listParamPosCompanyApi: ListParamPosCompanyApi = new ListParamPosCompanyApi({})

    listParamPosCompanyApi
      .listParamPosCompany()
      .then((res: any) => {
        props.hideLoading();
        setState({ paramposCompanyList: res })
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    setState({ companies: company })
  }

  const updateParamPosCompany = async (data: IParamposCompany, company: any) => {
    const req: IParamposCompanyRequest = {
      CompantCode: data.CompantCode,
      Enabled: !data.Enabled
    }
    try {
      const res = await new UpdateParamPosCompanyApi({}).updateParamPosCompany(req)
      if (res) {
        const dataCompany: IParamposCompany[] = [];
        state.paramposCompanyList.map((com) => {
          if (com.Id === data.Id) {
            return dataCompany.push({
              ...req,
              Id: data.Id
            })
          }
          return dataCompany.push(com)
        })
        setState({ paramposCompanyList: dataCompany })
        Swal.fire({
          icon: 'success',
          title: `${company} şirketi parampos a ${req.Enabled ? 'açılmıştır.' : 'kapatılmıştır.'}`,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const listParamPosCompanyHistory = async (companyCode: number) => {
    props.showLoading();
    const listParamPosCompanyHistoryApi: ListParamPosCompanyHistoryApi = new ListParamPosCompanyHistoryApi({})

    listParamPosCompanyHistoryApi
      .listParamPosCompanyHistory(companyCode)
      .then((res: IParamposCompanyHistory[]) => {
        props.hideLoading();
        if (res.length === 0) {
          return Swal.fire({
            icon: 'warning',
            title: 'Bu şirket için geçmiş bilgisi yoktur.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
        setState({ showDetail: true, paramposCompanyHistoryList: res })
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Aç-Kapa',
        accessor: 'col2',
      },
      {
        Header: 'Şirket',
        accessor: 'col3',
      },
      {
        Header: 'Tarihçe',
        accessor: 'col4',
      },
    ],
    []
  )

  const tableData = useMemo(() =>
    state.paramposCompanyList.map((com, index) => {
      const code = state.companies.find((company) => { return company.Code === com.CompantCode })?.Name
      const data = {
        col1: index + 1,
        col2: (
          <>
            <Switch.Root
              className='SwitchRoot'
              id='active'
              checked={com.Enabled}
              onCheckedChange={() => updateParamPosCompany(com, code)}
            >
              <Switch.Thumb className='SwitchThumb' />
            </Switch.Root>
          </>
        ),
        col3: code,
        col4: (
          <IPButton
            text='Tarihçe'
            type='button'
            onClick={() => {
              listParamPosCompanyHistory(com.CompantCode)
            }}
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        )
      }
      return data
    })
    , [state.paramposCompanyList, state.companies])


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Parampos Şirket Açma | Kapama</span>
          </div>
          {
            state.paramposCompanyList.length > 0 && state.companies.length > 0 &&
            <div className='card-body'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          }
        </div>
      </div>
      <IPModal
        show={state.showDetail}
        onClose={() => setState({ showDetail: false })}
        title={`Parampos Şirket Açma | Kapama Logları`}
        width={700}
        hideConfirm={true}
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>#</th>
              <th>İşlem Tarihi</th>
              <th>İşlemi Yapan Kullanıcı</th>
              <th>İşlem</th>
            </tr>
          </thead>
          <tbody>
            {
              state.paramposCompanyHistoryList && state.paramposCompanyHistoryList.length > 0 && state.paramposCompanyHistoryList
                .sort((a, b) => new Date(b.CreatedDate).getTime() - new Date(a.CreatedDate).getTime()).map((history, index) => (
                  <tr role='row' className='odd' key={index + 1}>
                    <td>{index + 1}</td>
                    <td>{new Date(history.CreatedDate).toLocaleString('tr-TR')}</td>
                    <td>{history.UserName}</td>
                    <td>{history.Enabled ? "Açıldı" : "Kapatıldı"}</td>
                  </tr>
                ))
            }
          </tbody>
        </table>
      </IPModal>
    </div >
  )
}
export default componentWithContext(ParamposCompany)
