import { KeyValuePair } from '../../../base/models'
import { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import { GetBanksApi } from './services/getBanksApi'
import { GetBrandApi } from './services/getBrandApi'
import { GetBrandDetailApi } from './services/getBrandDetailApi'
import { GetCategoryListApi } from './services/getCategoryListApi'
import { GetCompanyApi } from './services/getCompanyApi'
import { GetDealerApi } from './services/getDealerApi'
import { GetDealerTypeListApi } from './services/getDealerTypeListApi'
import { GetSubTypesApi } from './services/getSubTypesApi'
import { GetWarehousesApi } from './services/getWarehousesApi'

export async function getBrandApi() {
  const getBrand: GetBrandApi = new GetBrandApi({})
  const res = await getBrand.getBrand()
  if (res) return res
  return []
}

export async function getBanksApi(Id: string) {
  const getBanks: GetBanksApi = new GetBanksApi({})
  const res = await getBanks.getBanks(Id)
  if (res) return res
  return []
}

export async function getBrandDetailApi(id: number | null, moduleId: number | null) {
  const api: GetBrandDetailApi = new GetBrandDetailApi({})
  const queryParams: KeyValuePair<number> = new KeyValuePair<number>()

  if (id !== null) {
    queryParams.add('Id', id)
  }
  if (moduleId !== null) {
    queryParams.add('moduleId', moduleId)
  }

  api.queryParams = queryParams
  const res = await api.getBrandDetail()
  if (res) return res
  return []
}

export async function getCompanyApi() {
  const getCompanyApi = new GetCompanyApi({})
  const res = await getCompanyApi.getCompanies()
  if (res) return res
  return []
}

export async function getDealerApi() {
  const getDealerApi: GetDealerApi = new GetDealerApi({})

  const res = await getDealerApi.getDealer()

  if (res) return res
  return []
}

export async function getSubTypeApi() {
  const getSubTypesApi: GetSubTypesApi = new GetSubTypesApi({})

  const res = await getSubTypesApi.getSubTypes()

  if (res) return res
  return []
}

export async function getWarehousesApi() {
  const getWarehousesApi: GetWarehousesApi = new GetWarehousesApi({})
  const res = await getWarehousesApi.getWarehouses()
  if (res) return res
  return []
}

export async function getDealerTypeList() {
  const getDealerTypeListApi: GetDealerTypeListApi = new GetDealerTypeListApi({})
  const res = await getDealerTypeListApi.getDealerTypeList()
  if (res) return res
  return []
}

export async function getCategoryList() {
  const getCategoryListApi: GetCategoryListApi = new GetCategoryListApi({})
  const res = await getCategoryListApi.getCategoryList()
  if (res) return res
  return []
}
