import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IBrand } from '../../../BrandDetail/models/models'
import { DealerServiceModel, DealerType, IyzicoCampaignModel, IyzicoHistoryModel } from './IyzicoCampaignModel'

export class IyzicoCampaignService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/iyzicoPaymentCampaign/list',
      requesterInfo,
    })
  }

  public async list(): Promise<IyzicoCampaignModel> {
    return await this.getAsync<IyzicoCampaignModel>()
  }

  public async add(req: any): Promise<IyzicoCampaignModel> {
    this.props.url = 'admin/iyzicoPaymentCampaign/add'
    return await this.postAsync<any>(req)
  }

  public async update(req: any): Promise<IyzicoCampaignModel> {
    this.props.url = 'admin/iyzicoPaymentCampaign/update'
    return await this.putAsync<any>(undefined, req)
  }

  public async delete(id): Promise<string> {
    this.props.url = `admin/iyzicoPaymentCampaign/delete/${id}`
    return await this.deleteAsync<any>()
  }

  public async getBrandList(): Promise<any> {
    this.props.url = 'admin/campaignBundle/getBrandList'
    return await this.getAsync<any>()
  }

  public async getCompanies(): Promise<any> {
    this.props.url = 'admin/company/list'
    return await this.getAsync<any>()
  }

  public async getWareHouseCode(): Promise<any> {
    this.props.url = 'admin/campaignBundle/getWarehouseList'
    return await this.getAsync<any>()
  }

  public async getProductAndQuotaCodes(): Promise<any> {
    this.props.url = 'admin/campaignBundle/getProductCode'
    return await this.getAsync<any>()
  }

  public async getDealerCodes(): Promise<DealerServiceModel[]> {
    this.props.url = 'admin/dealer/listAll'
    return await this.getAsync<any>()
  }

  public async getDealerTypes(): Promise<DealerType[]> {
    this.props.url = 'admin/dealer/dealerTypeList'
    return await this.getAsync<DealerType[]>()
  }

  public async getSubTypes(): Promise<any[]> {
    this.props.url = 'admin/dealerInformation/GetSubTypes'
    return await this.getAsync<any[]>()
  }

  public async getBrand(): Promise<IBrand[]> {
    this.props.url = 'admin/brand/list'
    return await this.getAsync<IBrand[]>()
  }

  public async getInstallments(): Promise<any[]> {
    this.props.url = 'admin/iyzicoPaymentCampaign/listIyzicoInstallment'
    return await this.getAsync<any[]>()
  }

  public async historyByID(request: number): Promise<IyzicoHistoryModel[]> {
    this.props.url = 'admin/iyzicoPaymentCampaign/listIyzicoHistory'
    return await this.postAsync<IyzicoHistoryModel[]>(request)
  }
}
