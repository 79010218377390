import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IGetOrderDetailsRequest } from '../models/request/getMyOrderRequest'
import { IGetOrderDetailsResponse } from '../models/response/getOrderListResponseModel'

export class GetOrderDetailsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'OrdersHistory/GetOrderDetails',
      requesterInfo,
    })
  }

  public async getOrderDetail(request: IGetOrderDetailsRequest): Promise<IGetOrderDetailsResponse[]> {
    return await this.postAsync<IGetOrderDetailsResponse[]>(request)
  }
}
