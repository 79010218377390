import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IProductLimitsRequest, IProductLimitsResponse } from '../models/models'

export class PostProductLimitsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/productLimit/productLimits',
      requesterInfo,
    })
  }

  public async postProductLimits(request: IProductLimitsRequest): Promise<IProductLimitsResponse[]> {
    return await this.postAsync<IProductLimitsResponse[]>(request)
  }
}
