import React, { useEffect, useMemo } from 'react'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { ProductsWithUnspecifiedPropertiesModel } from '../models/models'
import { ICompany } from '../../../components/models/models'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import { ProductsWithUnspecifiedPropertiesApi } from '../services/productsWithUnspecifiedPropertiesApi'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import Table from '../../BestSellerList/components/Table'

interface ProductListState {
  ProductList: ProductsWithUnspecifiedPropertiesModel[]
  selectedCompany?: ISelectOption,
  selectedStatus?: ISelectOption,
}

function ProductsList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)

  const companies = [
    { label: 'Index', value: 1 },
    { label: 'Despec', value: 3 },
    { label: 'Netex', value: 2 },
    { label: 'Datagate', value: 4 },
  ]

  const status = [
    { label: 'Aktif', value: 1 },
    { label: 'Pasif', value: 0 },
  ]

  const getInitialState = (): ProductListState => {
    return {
      ProductList: [],
      selectedCompany: undefined,
      selectedStatus: undefined,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ProductListState>(intialState)

  const getProducts = async () => {
    props.showLoading()
    const getByIdApi = new ProductsWithUnspecifiedPropertiesApi({})
    const product = await (await getByIdApi.ProductsWithUnspecifiedProperties()).sort((a, b) => Number(a.CompanyID) - Number(b.CompanyID))
    var products = product.filter(a => state.selectedCompany ? a.CompanyID === state.selectedCompany?.value : true)
    products = products.filter(a => state.selectedStatus ? a.Enabled === (state.selectedStatus?.value === 1 ? true : false) : true)
    setState({ ProductList: products })
    props.hideLoading()
  }

  useEffect(() => {
    getProducts()
  }, [state.selectedCompany, state.selectedStatus])

  const excelButton = async () => {
    try {
      if (state.ProductList) {
        const data: any[] = []
        if (state.ProductList.length > 0) {
          state.ProductList.map(item => {
            return data.push({
              "Ürün Kodu": item.ProductCode,
              "Ürün Adı": item.Name,
              "Malzeme Numarası": item.MatNumber,
              "Kota": item.QuotaNo,
              "Ürün Müdürü": item.MudCode,
              "Şirket":
                item.CompanyID === 1
                  ? 'Index'
                  : item.CompanyID === 2
                    ? 'Netex'
                    : item.CompanyID === 3
                      ? 'Despec'
                      : item.CompanyID === 4
                        ? 'Datagate'
                        : '',
              "Aktif": item.Enabled === true ? "Evet" : item.Enabled === false ? "Hayır" : "",
              "Durum": item.Status,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Özellikleri Girilmemiş Ürünler Listesi')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'order',
      },
      {
        Header: 'Şirket',
        accessor: 'company',
      },
      {
        Header: 'Kota Kodu',
        accessor: 'quota',
      },
      {
        Header: 'Ürün Müdürü',
        accessor: 'mud',
      },
      {
        Header: 'SAP Kodu',
        accessor: 'matnr',
      },
      {
        Header: 'Ürün Açıklaması',
        accessor: 'name',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'productCode',
      },
      {
        Header: 'Aktif',
        accessor: 'status',
      },
      {
        Header: 'Durum',
        accessor: 'durum',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.ProductList.map((item, index) => {
        const data = {
          productCode: <Link
            target="_blank"
            to={`/product/detail/${item.ID}?from=productList`}
          >
            {item.ProductCode}
          </Link>,
          status: item.Enabled ? "Evet" : "Hayır",
          name: item.Name,
          matnr: item.MatNumber,
          mud: item.MudCode,
          quota: item.QuotaNo,
          company: item.CompanyID === 1 ? "Index" : item.CompanyID === 2 ? "Netex" : item.CompanyID === 3 ? "Despec" : item.CompanyID === 4 ? "Datagate" : "",
          order: index + 1,
          durum: item.Status,
        }
        return data
      }),

    [state.ProductList]
  )

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card' style={{ width: 'fit-content' }}>
          <div className='card-header'>
            <span className='h4'>
              {t('pages.admin.productOperation.productsWithUnspecifiedProperties.unspecifiedProductsList.header')}
            </span>
          </div>
          <div className='card-body'>
            <div className='d-flex align-items-end gap-4'>
              <div className='col-lg-2'>
                <label htmlFor='company' className='form-label'>
                  Şirket
                </label>
                <IPSelectBox
                  isClearable
                  id='Şirket'
                  name='Şirket'
                  options={companies}
                  value={state.selectedCompany}
                  onChangeSingle={(option) => setState({ selectedCompany: option })}
                />
              </div>
              <div className='col-lg-2'>
                <label htmlFor='company' className='form-label'>
                  Durum
                </label>
                <IPSelectBox
                  isClearable
                  id='Status'
                  name='Status'
                  options={status}
                  value={state.selectedStatus}
                  onChangeSingle={(option) => setState({ selectedStatus: option })}
                />
              </div>
              <div className='col-lg-7 d-flex align-items-center justify-content-end'>
                <span>Excel: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButton}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
              </div>
            </div>
            <br></br>
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <Table columns={tableColumns} data={tableData} currentPage={0} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(ProductsList) 
