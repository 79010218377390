import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IUser } from '../models/response/getUserListResponseModel'

export class DeleteUserApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealerInformation/delete/',
      requesterInfo,
    })
  }

  public async deleteUser(userId): Promise<IUser[]> {
    this.props.url = `${this.props.url}${userId}`
    return await this.deleteAsync<IUser[]>()
  }
}
