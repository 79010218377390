import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ISurveyItem, IGetSurveyResponseModel } from '../models/getSurveyResponseModel'

export class GetSurveyListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'survey/list',
      requesterInfo,
    })
  }

  public async getSurveyList(): Promise<ISurveyItem[]> {
    return await this.getAsync<ISurveyItem[]>()
  }
}
