import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ICampaignsProducts } from '../commonModels/models'

export class GetCampaignsProductsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'campaign/CampaignsProducts',
      requesterInfo,
    })
  }

  public async getCampaignsProducts(request: ICampaignsProducts): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
