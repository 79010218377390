import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { GetAuthorizedServicePointsListResponseModel } from '../models/response/getAuthorizedServicePointsListResponseModel'

export class GetAuthorizedServicePointsListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'authorizedServiceMain/listAll',
      requesterInfo,
    })
  }

  public async GetAuthorizedServicePointsList(): Promise<GetAuthorizedServicePointsListResponseModel[]> {
    return await this.getAsync<GetAuthorizedServicePointsListResponseModel[]>()
  }
}
