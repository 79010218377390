import React, { useEffect, useState } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPInput from '../../../../../components/IPInput'
import Swal from 'sweetalert2'
import IPButton from '../../../../../components/IPButton'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import IPDatePicker from '../../../../../components/IPDatePicker'
import { IPostHomePageProductsRequest } from '../models/requestModels'
import { useFormik } from 'formik'
import { PostHomePageProductsApi } from '../services/postHomePageProductsApi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { UpdateHomePageProductsApi } from '../services/updateHomePageProductsApi'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import InputCampaign from '../../../../../components/InputCampaign'
import { data } from 'jquery'

export interface IListHomePageProductsState {
  topFavorites: any[]
  promotionalProducts: any[]
  similarItems: any[]
  mostRecent: any[]
  company?: ISelectOption[]
  companyName: string
  showProduct: boolean
  option: string
  optionId: number
  order: number
  productCode: string
  location?: ISelectOption
  buttonName: string
}

export default function HomePageProducts() {
  let navigate = useNavigate()
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [companyId, setCompanyId] = useState<ISelectOption>()

  const getInitialState = (): IListHomePageProductsState => {
    return {
      topFavorites: [],
      promotionalProducts: [],
      similarItems: [],
      mostRecent: [],
      companyName: '',
      showProduct: false,
      option: '',
      optionId: 0,
      order: 0,
      productCode: '',
      buttonName: 'Ekle'
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListHomePageProductsState>(intialState)
  // const [startDate, setStartDate] = useState<any>(new Date())
  // const [endDate, setEndDate] = useState<any>(new Date())

  useEffect(() => {
    getCompanies()
  }, [])

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: any[] = []
    company.map((com) => {
      if (com.Name != "Index Group") {
        return data.push(com)
      }
      return
    })
    setCompanies(data)
  }


  const routeLocation = useLocation()

  const { handleSubmit, handleChange, handleBlur, setFieldValue, setValues, resetForm, values, errors, touched } = useFormik({
    initialValues: {
      Id: 0,
      CompanyId: null,
      ProductCode: '',
      Order: 0,
      Location: 0,
      // StartDate: getDateByTimezoneOffset(startDate),
      //EndDate: getDateByTimezoneOffset(endDate),
      StartDate: Date,
      EndDate: Date,
      Enabled: true,
    },
    onSubmit: async () => { },
  })
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }
  const location = [
    // {
    //   value: 1,
    //   label: "En Favoriler",
    // },
    // {
    //   value: 2,
    //   label: 'Kampanyalı Ürünler',
    // },
    // {
    //   value: 3,
    //   label: "Benzer Ürünler",
    // },
    {
      value: 4,
      label: 'En Yeniler',
    },
  ]

  useEffect(() => {
    if (routeLocation.state && companies.length > 0) {
      const data = _.clone((routeLocation.state as any)?.homepageProductData)
      // const datas: any[] = []
      // let company = data.CompanyId.split(",")
      companies
        .filter((item: ICompany) => data.CompanyId === String(item.Id))
        .map((com: ICompany) => {
          return setCompanyId({ value: com.Id, label: com.Name })
        })
      const locationData = location.filter((loc) => { return Number(loc.value) === data.Location }).pop()
      setState({ location: locationData, buttonName: "Güncelle" })
      //setStartDate(inititalStartDate)
      // setEndDate(new Date(data.EndDate))
      // setDateRange(Date(data.StartDate), Date(data.EndDate))
      setValues(data)
    } else {
      resetForm()
    }
  }, [routeLocation, companies])

  const addHomepageProducts = () => {
    if (values.Order === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Sıra numarası boş bırakılamaz .',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (values.Location === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Konum boş bırakılamaz .',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (values.ProductCode === '') {
      Swal.fire({
        icon: 'error',
        title: 'Ürün Kodu boş bırakılamaz .',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!endDate || !startDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarihleri giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (state.buttonName === "Güncelle") {
      var homePageProduct: IPostHomePageProductsRequest = {
        Id: values.Id,
        CompanyId: values.CompanyId ? values.CompanyId : "",
        ProductCode: values.ProductCode,
        Order: values.Order,
        Location: values.Location,
        StartDate: getDateByTimezoneOffset(startDate) as any,
        EndDate: getDateByTimezoneOffset(endDate) as any,
        Enabled: values.Enabled,
      }
      const updateProducts: UpdateHomePageProductsApi = new UpdateHomePageProductsApi({})
      updateProducts
        .putHomePageProducts(homePageProduct)
        .then(result => {
          Swal.fire({
            icon: 'success',
            title: 'Ürün Güncellendi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async result => {
            navigate('/admin/homeAndSiteOperation/HomePageProductsList')
          })
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
      return
    }
    var homePageProduct: IPostHomePageProductsRequest = {
      CompanyId: values.CompanyId ? values.CompanyId : "",
      ProductCode: values.ProductCode,
      Order: values.Order,
      Location: values.Location,
      StartDate: getDateByTimezoneOffset(startDate) as any,
      EndDate: getDateByTimezoneOffset(endDate) as any,
      Enabled: values.Enabled,
    }
    const addProducts: PostHomePageProductsApi = new PostHomePageProductsApi({})
    addProducts
      .postHomePageProducts(homePageProduct)
      .then(result => {
        Swal.fire({
          icon: 'success',
          title: 'Ürün Eklendi',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(async result => {
          navigate('/admin/homeAndSiteOperation/HomePageProductsList')
        })
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              <h5>Anasayfa Ürün {state.buttonName}me</h5>
            </span>
          </div>
          <div className='card-body'>
            <div className='col-lg-2'>
              <div className='buttonsuz'>
                <Link to='/admin/homeAndSiteOperation/HomePageProductsList'>
                  <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                </Link>
              </div>
            </div>
            <div className='etkin d-flex justify-content-end'>
              <label className='p-switch switch-color-success d-flex'>
                <label className='floagit t-left mr-4'>
                  Yayınla
                </label>
                <IPCheckbox name='Enabled' onChange={handleChange} checked={values.Enabled} />
              </label>
            </div>
            <div className='row'>
              <div className='form-group col-md-4'>
                <label className='labeltext'>Şirket</label>
                <IPSelectBox
                  isClearable
                  value={companyId}
                  name='CompanyId'
                  options={
                    companies &&
                    companies.map((item: ICompany) => ({
                      label: item.Name,
                      value: `${item.Id}`,
                    }))
                  }
                  onChangeSingle={(option: ISelectOption) => {
                    setFieldValue('CompanyId', option.value)
                    setCompanyId(option)
                  }}
                />
              </div>

              <div className='form-group col-md-4'>
                <label className='labeltext'>Konum</label>
                <IPSelectBox
                  isClearable
                  name='Location'
                  id='Location'
                  options={location}
                  value={state.location}
                  onChangeSingle={(option: ISelectOption) => {
                    setState({ location: option })
                    setFieldValue('Location', Number(option.value))
                  }}
                />
              </div>
              <div className='form-group col-md-4'>
                <label className='labeltext'>Sıra Numarası</label>
                <IPInput
                  type='number'
                  name='Order'
                  id='Order'
                  value={values.Order}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-md-4'>
                <label className='labeltext'>Ürün Kodu</label>
                <IPInput
                  required
                  type='text'
                  name='ProductCode'
                  id='ProductCode'
                  value={values.ProductCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className='form-group col-md-3 align-self-end'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, "Start")}
                  isActive
                />
              </div>
              <div className='form-group col-md-3 align-self-end'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, "End")}
                  isActive
                />
              </div>
              <div className='form-group col-md-2 align-self-end'>
                <IPButton type='button' className='btn btn-success' onClick={() => addHomepageProducts()} text={state.buttonName} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <IPModal
        title={state.companyName + " " + state.option + " " + state.order + ". sıraya Ürün Ekleme"}
        show={state.showProduct}
        onClose={() => setState({ showProduct: false, option: "", optionId: 0, order: 0, productCode: "" })}
        width={'auto'}
        onConfirm={() => addProduct()}>

        <div>

          <label >Ürün Kodu</label>
          <IPInput
            type="text"
            id="Title"
            className="form-control"
            name="Title"
            value={state.productCode}
          />
        </div>

      </IPModal> */}
    </div>
  )
}
