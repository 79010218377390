import React, { useEffect, useState } from 'react'
import { useCustomReducer } from '../../base/customHooks'
import Image from '../../components/IPImage/IPImage'
import { css } from '@emotion/css'
import { useNavigate } from 'react-router-dom'
import IPModal from '../../components/IPModal'
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import { GetCampaignsApi } from './services/getCampaignsApi'
import { AddListToBasketUIViaBundleApi } from './services/addListToBasketUIViaBundleApi'
import { ICampaignsProducts } from '../Homepage/commonModels/models'
import IPButton from '../../components/IPButton'
import { BaseComponentWithContextProps, componentWithContext } from '../../base/customHooks/componentHOC'
import { GetCampaignToProductsApi } from '../ProductList/services/getCampaignToProductsApi'
import { addListToBasketUIViaBundleRedux, setBasketRedux } from '../../redux/slices/basket/basketSlice'

interface ICampaignsState {
  showCampaignModal: boolean,
  data: {
    BundleImage: string,
    CampaignId: number,
    IsAddBasket: boolean,
    ListImage: string,
    CampaignName: string
  }
}
function Index(props: BaseComponentWithContextProps) {
  const theme = useAppSelector(selectedTheme)
  const getInitialState = (): ICampaignsState => {
    return {
      showCampaignModal: false,
      data: {
        BundleImage: "",
        CampaignId: 0,
        IsAddBasket: false,
        ListImage: "",
        CampaignName: ""
      },
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ICampaignsState>(intialState)
  const navigate: any = useNavigate()
  const [campaigns, setCampaigns] = useState<any>()


  const getCampaigns = async (companyId: number) => {
    props.showLoading()
    const data: ICampaignsProducts = {
      PageSize: 1000,
      PageNumber: 0,
      CampaignId: 0,
      Company: companyId
    }
    const api: GetCampaignsApi = new GetCampaignsApi({})
    api.getCampaigns(data).then((res: any) => {
      setCampaigns(res)
      props.hideLoading()
    })
  }
  useEffect(() => {
    getCampaigns(theme.id)
  }, [theme.id])
  const dispatchRedux = useAppDispatch()
  const getBasketAdd = async () => {
    dispatchRedux(addListToBasketUIViaBundleRedux(state.data.CampaignId))
    setState({ showCampaignModal: false })
  }


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Kampanyalar</span>
          </div>
          <div className='card-body'>
            <div className='row m-t-10'>
              {
                campaigns && campaigns.List.map((item) => (
                  <div className='col-12 col-md-6 col-xl-3 card-mobile-resize' style={{ marginBottom: "10px" }}>

                    <div
                      className={`d-flex flex-column justify-content-between campaign ${css`
                      padding: 20px;
                    `}`} key={item.Id}
                    >
                      <div onClick={() => setState({ showCampaignModal: true, data: item })} style={{ cursor: "pointer" }}>
                        <Image src={item.ListImage} alt='' className='campaign' />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <br></br>
            {campaigns && campaigns.Count && ('Toplam ' + campaigns.Count + ' adet kampanya bulunmaktadır.')}
          </div>
        </div>
      </div>
      <IPModal
        title={state.data.CampaignName}
        width='auto'
        maxWidth={1440}
        show={state.showCampaignModal}
        onClose={() => setState({
          showCampaignModal: false,
          data: {
            BundleImage: "",
            CampaignId: 0,
            IsAddBasket: false,
            ListImage: "",
            CampaignName: ""
          },
        })}
        onConfirm={() => navigate(`/products?campaigns=${state.data.CampaignId}`)}
        confirmText="Ürünlere Git"
        buttons={
          state.data.IsAddBasket ?
            <IPButton
              text={'Sepete At'}
              style={{ width: 'auto', padding: '15px 20px' }}
              className='modal-button'
              onClick={() => getBasketAdd()}
            /> : undefined
        }
      >
        <div className='p-3'>
          <Image src={state.data.BundleImage} alt='' className='campaign' />
        </div>
      </IPModal>
    </div>
  )
}
export default componentWithContext(Index)