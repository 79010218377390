import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IDashboardRemainderResponse } from '../models/models'

export class GetDashboardRemainderApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'payment/dashboardRemainder',
      requesterInfo,
    })
  }

  public async getDashboardRemainder(): Promise<IDashboardRemainderResponse[]> {
    return await this.getAsync<IDashboardRemainderResponse[]>()
  }
}
