import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IGetProductsBySectionResponse } from '../models/commonModels'
import { IProduct } from '../models/response/getProductListResponseModel'

export class GetProductsBySectionApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product-list',
      requesterInfo,
    })
  }

  public async getProductsList(section: string): Promise<IGetProductsBySectionResponse> {
    this.props.url = `${this.props.url}/${section}`

    return await this.getAsync<IGetProductsBySectionResponse>()
  }
}
