import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IAddProductLimitRequest } from '../models/models'

export class UpdateProductLimitApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/productLimit/update-limit',
      requesterInfo,
    })
  }

  public async updateProductLimit(request: IAddProductLimitRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
