import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { CampaignBundleModel, CampaignProductCodeModel } from '../models/CampaignBundleModel'

export class CampaignBundleService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/campaignBundle/InsertCampaign',
      requesterInfo,
    })
  }

  public async InsertCampaign(request: CampaignBundleModel): Promise<CampaignBundleModel> {
    return await this.postAsync<any>(request)
  }

  public async getAll(): Promise<CampaignBundleModel[]> {
    this.props.url = 'admin/campaignBundle/getAll'
    return await this.getAsync<any>()
  }

  public async GetById(id): Promise<CampaignBundleModel> {
    this.props.url = `admin/campaignBundle/GetById/${id}`
    return await this.getAsync<any>()
  }

  public async DeleteById(id): Promise<CampaignBundleModel> {
    this.props.url = `admin/campaignBundle/GetById/${id}`
    return await this.deleteAsync<any>()
  }

  public async UpdateCampaign(request: any): Promise<any> {
    this.props.url = 'admin/campaignBundle/UpdateCampaign'
    return await this.putAsync<any>(undefined, request)
  }

  public async getProductCode(): Promise<CampaignProductCodeModel> {
    this.props.url = 'admin/campaignBundle/getProductCode'
    return await this.getAsync<CampaignProductCodeModel>()
  }

  public async getBrandList(): Promise<CampaignBundleModel> {
    this.props.url = 'admin/campaignBundle/getBrandList'
    return await this.getAsync<any>()
  }

  public async deleteCampaingBundle(id: number): Promise<CampaignBundleModel> {
    this.props.url = `admin/campaignBundle/Delete/${id}`
    return await this.getAsync<any>()
  }
  public async getCompany(): Promise<any> {
    this.props.url = 'admin/company/list'
    return await this.getAsync<any>()
  }
  public async getWareHouseCode(): Promise<any> {
    this.props.url = 'admin/campaignBundle/getWarehouseList'
    return await this.getAsync<any>()
  }
  public async checkGiftCode(request: any): Promise<any> {
    this.props.url = 'admin/campaignBundle/CheckGiftCode'
    return await this.postAsync<any>(request)
  }
}
