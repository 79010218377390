import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomReducer } from '../../../../../base/customHooks';
import IPButton from '../../../../../components/IPButton';
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox';
import Translations from '../../../../../translations';
import { translate } from '../../../../../base/localization';
import { GetImageApi } from '../services/getImageApi';
import './VisualDocumentation.scss';
import Modal from '../../../../../components/IPModal/IPModal';
import { IGetProductRequest, IImageHistoryModel, IProduct } from '../models/requestModels';
import { GetProductByIdApi } from '../services/getProductByIdApi';
import Swal from 'sweetalert2';
import IPSelectBox from '../../../../../components/IPSelectBox';
import { GetProductApi } from '../services/getProductApi';
import { getCompanyApi } from '../../../components/api';
import { ICompany } from '../../../components/models/models';
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC';
import IPInput from '../../../../../components/IPInput';
import { IProductList } from '../models/responseModel';
import Table from '../../../../../components/TablePagination';
import moment from 'moment'
import { GetImageHistoryApi } from '../services/getImageHistoryApi';
import { Link } from 'react-router-dom'


interface IProductState {
  allProducts: IProduct[];
  selectedImage?: ISelectOption;
  selectedField?: ISelectOption;
  document: boolean;
  documentData?: any[];
  searchCompanies: ISelectOption[];
  selectedCompany?: ISelectOption | undefined;
  totalRows: number
  currentPage: number
  currentPageSize: number
  historyData: IImageHistoryModel[]
  showHistory: boolean
}

function VisualDocumentationList(props: BaseComponentWithContextProps) {
  const navigate = useNavigate();
  const t = translate(Translations);
  const [products, setProducts] = useState<IProductList[]>([]);
  const [allProducts, setAllProducts] = useState<IProductList[]>([]);

  const getInitialState = (): IProductState => {
    return {
      document: false,
      searchCompanies: [],
      selectedCompany: undefined,
      allProducts: [],
      totalRows: 0,
      currentPage: 0,
      currentPageSize: 10,
      historyData: [],
      showHistory: false
    };
  };

  const initialState = useMemo(() => getInitialState(), []);
  const [state, setState] = useCustomReducer<IProductState>(initialState);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    getCompanies();
  }, []);

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi();
    if (!company) return;
    setState({ searchCompanies: company.map((c) => ({ value: c.Id, label: c.Name })) });
  };

  const showHistory = async (productCode: string) => {
    props.showLoading();
    const imageHistory: GetImageHistoryApi = new GetImageHistoryApi({})
    try {
      await imageHistory
        .historyByCode(JSON.stringify(productCode))
        .then(res => {
          setState({ showHistory: true, historyData: res })
        })
      props.hideLoading();
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }


  const visualDocumentationList = async (pageNumber: number, pageSize: number) => {
    props.showLoading();
    const req: IGetProductRequest = {
      CompanyId: state.selectedCompany ? +state.selectedCompany?.value : 0,
      ProductCode: searchQuery.length > 0 ? searchQuery : undefined,
      PageNumber: pageNumber,
      PageSize: pageSize
    }
    setState({ currentPage: pageNumber, currentPageSize: pageSize })
    const getProductByIdApi = new GetProductApi({});
    try {
      const result = await getProductByIdApi.getProduct(req);
      setProducts(result.ProductList)
      setAllProducts(result.ProductList)
      setState({ totalRows: result.TotalCount })
      props.hideLoading()
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
      setProducts([])
      setAllProducts([])
    }
  };

  const updateProduct = async (data: any) => {
    const getProductByIdApi = new GetProductByIdApi({});
    try {
      const result = await getProductByIdApi.getProductById(data);
      if (result) {
        navigate('/admin/ProductOperation/VisualDocumentationAdd', {
          state: { productData: result },
        });
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Tarihçe',
        accessor: 'Log',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'ProductCode',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'Name',
      },
      {
        Header: 'Şirket',
        accessor: 'CompanyName',
      },
      {
        Header: 'Düzenle',
        accessor: 'Actions',
      },
    ],
    []
  );

  const tableData = useMemo(() => {
    return products.map((x) => {
      let companyName = '';
      switch (x.CompanyId) {
        case 1:
          companyName = 'Index';
          break;
        case 2:
          companyName = 'Netex';
          break;
        case 3:
          companyName = 'Despec';
          break;
        case 4:
          companyName = 'Datagate';
          break;
        case 5:
          companyName = 'deneme';
          break;
        default:
          companyName = '';
      }
      return {
        ProductCode: <Link target='_blank' to={`/product/detail/${x.Id}`}>{x.ProductCode}</Link>,
        Name: x.Name,
        CompanyName: companyName,
        Actions: (
          <div>
            <a
              data-action-type='edit'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              onClick={() => {
                updateProduct(x.Id);
              }}
            >
              <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
            </a>
          </div>
        ),
        Log: <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => showHistory(x.ProductCode)}> <i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i></a>,
      }
    })
  }, [products])

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              {t('pages.admin.productOperation.visualDocumentationCrud.visualDocumentationList.header')}
            </span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-3'>
                <IPSelectBox
                  isClearable
                  id='Şirket'
                  name='Şirket'
                  placeholder='Şirket seçiniz.'
                  options={state.searchCompanies}
                  value={state.selectedCompany}
                  onChangeSingle={(option) => setState({ selectedCompany: option })}
                />
              </div>
              <div className='col-md-3'>
                <IPInput
                  type="text"
                  placeholder="Ürün Kodu/Adı"
                  className="form-control"
                  aria-label="Search"
                  id='searchQuery'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className='col-md-2'>
                <IPButton text="Ara" onClick={() => {
                  setState({ currentPage: 0, currentPageSize: 10 })
                  visualDocumentationList(0, 10)
                }}></IPButton>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='table-responsive'>
                      <Table columns={columns} data={tableData} pageChangeHandler={visualDocumentationList} manualPagination={true} totalRows={state.totalRows} currentPage={state.currentPage} currentPageSize={state.currentPageSize} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={`Resimler`}
        show={state.document ? state.document : false}
        onClose={() => setState({ document: false, documentData: [] })}
        hideConfirm
      >
        <>
          <div className='form-row'>
            <table className='table'>
              <thead className='table-light '>
                <tr>
                  <th>Adı</th>
                  <th>Dosya</th>
                </tr>
              </thead>
              <tbody>
                <tr role='row' className='odd'>
                  <td>Resim Adı</td>
                  <td>Resim</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      </Modal>
      <Modal
        show={state.showHistory}
        onClose={() => setState({ showHistory: false, historyData: [] })}
        hideConfirm={true}
        width={1200}
        title='Günlük Tarihçe'
      >
        <table className='table'>
          <thead>
            <tr role='row' className='odd'>
              <th>Tarih</th>
              <th>Resim Adı</th>
              <th>Resim Yolu</th>
              <th>Sıra No</th>
              <th>Silindi</th>
              <th>Bayi Kodu</th>
              <th>Kullanıcı Adı</th>
            </tr>
          </thead>

          <tbody>
            {
              state.historyData && state.historyData.length > 0 && state.historyData.map((his) => (
                <tr role='row' className='odd' key={his.Id}>
                  <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                  <td>{his.ImageName && his.ImageName}</td>
                  <td>{his.Image && <Link to={`/content/images/${his.Image}`} target='_blank'>{his.Image}</Link>}</td>
                  <td>{his.Description && his.Description}</td>
                  <td>{his.Deleted ? "Evet" : "Hayır"}</td>
                  <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                  <td>{his.UserName}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </Modal>
    </div >
  );
}
export default componentWithContext(VisualDocumentationList);