import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IBasketQuotaReportRequest, IBasketQuotaReportResponse } from '../models/models'

export class BasketQuotaReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/BasketQuotaReport',
      requesterInfo,
    })
  }

  public async basketQuotaReport(request: IBasketQuotaReportRequest): Promise<IBasketQuotaReportResponse[]> {
    return await this.postAsync<IBasketQuotaReportResponse[]>(request)
  }
}
