import { useFormik } from 'formik'
import React, { useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPDatePicker from '../../../../../components/IPDatePicker'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPSelectBox from '../../../../../components/IPSelectBox'
function AddProduct() {
  const t = translate(Translations)
  const [dateRange, setDateRange] = useState<any>([new Date(), new Date()])
  const [startDate, endDate] = dateRange
  const { validateField, setFieldValue, setFieldTouched, values } = useFormik({
    initialValues: {
      StartDate: new Date(),
      EndDate: new Date(),
    },

    onSubmit: async values => {
      values['Location'] = values['Location']['value']
    },
  })
  const statusOptions = [
    {
      value: 1,
      label: 'Normal',
    },
    {
      value: 2,
      label: 'Yeni Ürün',
    },
    {
      value: 3,
      label: 'Özel Ürün',
    },
    {
      value: 4,
      label: 'Kıt Ürün',
    },
    {
      value: 5,
      label: 'Fırsat Pazarı Ürünü',
    },
    {
      value: 4,
      label: 'HP Pazarı Ürünü',
    },
  ]
  return (
    <div>
      <div className='container mb-3'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header'>
              <span className='h4'>{t('pages.admin.homeAndSiteOperation.currentProductCrud.addProduct.header')}</span>
            </div>
            <div className='card-body'>
              <form id='id1' className='formform' noValidate>
                <div className='etkin'>
                  <label className='p-switch switch-color-success'>
                    <label htmlFor='active' className='float-left mr-4'>
                      {t('pages.admin.homeAndSiteOperation.currentProductCrud.addProduct.active')}
                    </label>
                    <IPCheckbox name='Active' checked={false} />
                  </label>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-4'>
                    <label className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.currentProductCrud.addProduct.stockCode')}
                    </label>
                    <IPInput type='text' id='Title' className='form-control' name='Title' />
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-group col-md-4'>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.currentProductCrud.addProduct.oldPrice')}
                    </label>
                    <IPInput type='text' className='form-control' name='Description' />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-4'>
                    <label className='labeltext'>Slogan</label>
                    <IPInput type='text' id='Title' className='form-control' name='Title' />
                    <p className='validation-error'> </p>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-4'>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.currentProductCrud.addProduct.name')}
                    </label>
                    <IPInput type='text' className='form-control' name='Description' />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-4'>
                    <label className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.currentProductCrud.addProduct.description')}
                    </label>
                    <IPInput type='text' id='Title' className='form-control' name='Title' />
                    <p className='validation-error'> </p>
                  </div>
                  <div className='col-lg-4'>
                    <label className='labeltext'>Ürünün Durumu</label>
                    <IPSelectBox options={statusOptions} />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label className='form-label'>Başlangıç Tarihi - Bitiş Tarihi</label>
                    <IPDatePicker
                      selectsRange={true as any}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={update => {
                        setDateRange(update)
                      }}
                    />
                    <p className='validation-error'> </p>
                  </div>

                  <div className='form-group col-md-3'>
                    <label htmlFor='typeCode' className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.currentProductCrud.addProduct.type')}
                    </label>
                    <IPInput type='number' className='form-control mb-5' name='CariTypeCode' />
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-group col-md-3'>
                    <label htmlFor='typeList' className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.currentProductCrud.addProduct.subType')}
                    </label>
                    <IPInput type='number' className='form-control' name='CariSubTypeCode' />
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-row'>
                    <IPButton type='button' className='btn btn-success w-25 float-right' text='Ekle' />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddProduct
