/* eslint-disable jsx-a11y/anchor-is-valid */
import * as Switch from '@radix-ui/react-switch'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import bannerData from '../../../../../api/banner.json'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { translate } from '../../../../../base/localization'
import { toDateTimeLocal } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import IPModal from '../../../../../components/IPModal'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Translations from '../../../../../translations'
import { ICompany } from '../../../../ProductList/models/commonModels'
import { getCompanyApi } from '../../../components/api'
import InputCampaign from '../../DealerChannel/components/DealerChannelInput'
import { IBannerDetail, IBannerFilteredList } from '../models/requestModels'
import { BannerHistoryResponseModel, IBanner } from '../models/responseModels'
import { BannerFilteredListApi } from '../services/bannerFilteredListApi'
import { DeleteBannerApi } from '../services/deleteBannerApi'
import { GetBannerByIdApi } from '../services/getBannerByIdApi'
import { GetBannerCategoriesApi } from '../services/getBannerCategoriesApi'
import { UpdateBannerApi } from '../services/updateBannerApi'
import './ListBanner.scss'
import Table from './Table'

function ListBanner(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const navigate: any = useNavigate()

  const [activeTab, setActiveTab] = useState('all') // Varsayılan olarak tüm verileri göster
  const [filteredBanners, setFilteredBanners] = useState<IBanner[]>([])
  const [filteredBannerBool, setFilteredBannersBool] = useState<boolean>(false)
  const [banners, setBanners] = useState<IBanner[]>([])
  const [companies, setCompanies] = useState<ICompany[]>([])
  type formType = 'create' | 'update' | 'copy'
  const [flag, setFlag] = useState<formType>('create')
  const [showDetail, setShowDetail] = useState<boolean>(false)
  const [showHistoryDetail, setShowHistoryDetail] = useState<boolean>(false)
  const [allHistory, setAllHistory] = useState<BannerHistoryResponseModel[]>([])
  const [bannerCategory, setBannerCategory] = useState<ISelectOption[]>([])
  const [detail, setDetail] = useState<IBannerDetail>({
    Location: '',
    Category: '',
    BannerType: '',
    Counter: '',
    Description: '',
  })

  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000).toISOString()
  const dateFromISO = date => new Date(new Date(date).getTime() - 3 * 3600 * 1000).toISOString()

  const currentMonth = new Date().getMonth()
  const currentYear = new Date().getFullYear()

  const getLastDayOfMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate()
  }

  const initialStartDate = new Date(currentYear, currentMonth, 1, 0, 0, 0)
  const initialEndDate = new Date(currentYear, currentMonth, getLastDayOfMonth(currentYear, currentMonth), 23, 59, 59)

  initialStartDate.setHours(initialStartDate.getHours() + 3)
  initialEndDate.setHours(initialEndDate.getHours() + 3)

  const [startDate, setStartDate] = useState<Date>(initialStartDate)
  const [endDate, setEndDate] = useState<Date>(initialEndDate)

  if (sessionStorage.getItem('StartDate') == null) {
    sessionStorage.setItem('StartDate', startDate.toISOString())
  }

  if (sessionStorage.getItem('EndDate') == null) {
    sessionStorage.setItem('EndDate', endDate.toISOString())
  }

  const startDateString = sessionStorage.getItem('StartDate')
  const endDateString = sessionStorage.getItem('EndDate')
  let StartDate
  let EndDate
  if (startDateString !== '' && endDateString !== '') {
    StartDate = new Date(dateFromISO(startDateString))
    EndDate = new Date(dateFromISO(endDateString))
  }

  const session = {
    Company: sessionStorage.getItem('Company') || 0,
    IsCompanySelected: sessionStorage.getItem('IsCompanySelected') === 'true' || false,
    Location: sessionStorage.getItem('Location') || null,
    Category: sessionStorage.getItem('Category') || 0,
    BannerType: sessionStorage.getItem('BannerType') || 0,
    StartDate: StartDate ? new Date(StartDate) : startDate,
    EndDate: EndDate ? new Date(EndDate) : endDate,
    Page: sessionStorage.getItem('Page') || 0,
  }

  type FilterStates = {
    Company: ISelectOption | null
    Location: ISelectOption | null
    Category: ISelectOption | null
    BannerType: ISelectOption | null
    StartDate: Date | null
    EndDate: Date | null
    IsCompanySelected: boolean
    Page: number
  }

  const filterStates: FilterStates = {
    Company: null,
    Location: null,
    Category: null,
    BannerType: null,
    StartDate: null,
    EndDate: null,
    IsCompanySelected: false,
    Page: 0,
  }
  const [filters, setFilters] = useState(filterStates)
  const updateFilter = (property, value) => {
    setFilters(prev => ({ ...prev, [property]: value, Page: 0 }))
    sessionStorage.setItem('Page', '0')
  }

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    data.push({
      Name: 'Index Grup',
      Image: '',
      IyzipayMerchantKey: '',
      Id: 0,
      Code: '',
    })
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push(co)
      })
    return data
  }

  // const getBanners = async () => {
  //   const getByIdApi = new GetBannerListApi({})

  //   const data = await getByIdApi.getBanners()

  //   if (!data) return []

  //   return data
  // }

  const getBannerCategory = async () => {
    const data: ISelectOption[] = []
    const res = await new GetBannerCategoriesApi({}).getBannerCategories()

    if (res) {
      res.map(r => {
        return data.push({ label: r.Description, value: r.Id })
      })
    }

    return data
  }

  const [categoryMapping, setCategoryMapping] = useState<{ [key: number]: string }>({})

  useEffect(() => {
    Promise.all([getCompanies(), getBannerCategory()]).then(data => {
      const [companies, bannerCategories] = data

      // Create a mapping of value to category label
      const mapping = bannerCategories.reduce((acc, category) => {
        const key = typeof category.value === 'string' ? parseInt(category.value) : category.value
        acc[key] = category.label
        return acc
      }, {} as { [key: number]: string })

      setCategoryMapping(mapping)
      setCompanies(companies)
      setBannerCategory(bannerCategories)

      setBanners(banners)

      const company = companies.find(x => x.Id === Number(session.Company))

      const location = bannerData.location.find(x => x.id === String(session.Location))

      const category = bannerCategories.find(x => x.value === Number(session.Category))

      const bannerType = bannerData.type.find(x => x.id === session.BannerType)

      const states: FilterStates = {
        Company: session.IsCompanySelected && company ? { label: company.Name, value: company.Id } : null,
        Location: location ? { label: location.title, value: location.id } : null,
        Category: category ? category : null,
        BannerType: bannerType ? { label: bannerType.title, value: +bannerType.id } : null,
        StartDate: session.StartDate ? new Date(session.StartDate) : startDate,
        EndDate: session.EndDate ? new Date(session.EndDate) : endDate,
        IsCompanySelected: session.IsCompanySelected,
        Page: +session.Page,
      }

      updateFilter('IsCompanySelected', true)
      setFilters(states)
      setFilteredBannersBool(true)
    })
  }, [])

  useEffect(() => {
    if (filteredBannerBool) filterBanner()
  }, [filteredBannerBool, filters])

  const locationNames = [
    null,
    'Login',
    'Anasayfa Sol Üst',
    'Anasayfa Sol Alt',
    'Anasayfa Sağ',
    'Sayaç Anasayfa Üst',
    'Sayaç Anasayfa Alt',
    'Bana Özel',
    'Pop-up',
    'Orta Banner',
    'Alt Banner',
    'Ürün Detay',
    'Cari Ödeme',
    'Avantaj POS Ödeme',
    'Fırsat POS Ödeme',
    'FÇV2 ödeme',
    'Sepet Ürün',
    'Sepet Ödeme Şekli',
  ]
  const counterLocationNames = [
    null,
    t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.getSlider'),
    t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.beAtTheBottom'),
  ]

  const deleteBanner = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Bannerı silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteBannerApi = new DeleteBannerApi({})
        deleteBannerApi
          .deleteBanner(id)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Banner Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async () => {
                const deleted = banners.filter(x => x.Id !== id)
                setBanners(deleted)
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      }
    })
  }

  const updateBanner = async (id: number) => {
    const getBannerByIdApi: GetBannerByIdApi = new GetBannerByIdApi({})
    await getBannerByIdApi
      .getBannerById(id)
      .then(async result => {
        if (result) {
          setFlag('update')
          navigate('/admin/homeandsiteoperation/banner/add', {
            state: { bannerData: result, flag: 'update' },
          })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const copyBanner = async (data: any) => {
    const getBannerByIdApi: GetBannerByIdApi = new GetBannerByIdApi({})
    await getBannerByIdApi
      .getBannerById(data.Id)
      .then(async result => {
        if (result) {
          setFlag('copy')
          navigate('/admin/homeandsiteoperation/banner/add', {
            state: { bannerData: data, flag: 'copy' },
          })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const handleBannerActive = async (data: any) => {
    const req: any = {
      Id: data.Id,
      Title: data.Title,
      Description: data.Description,
      Location: data.Location,
      CompanyList: data.CompanyList,
      Order: data.Order,
      Category: data.Category,
      CategoryName: data.CategoryName,
      Link: data.Link,
      Active: !data.Active,
      Image: data.Image,
      ImageUrl: data.ImageUrl,
      StartDate: data.StartDate,
      EndDate: data.EndDate,
      CariTypeCode: data.CariTypeCode,
      CariSubTypeCode: data.CariSubTypeCode,
      CounterLocation: data.CounterLocation,
      Counter: data.Counter,
      ProductCode: data.ProductCode,
      ProductAddress: data.ProductAddress,
      BannerType: data.BannerType,
      MobilImage: data.MobilImage,
      IsHomepage: data.IsHomepage,
      PopupLocation: data.PopupLocation ? data.PopupLocation : 0,
      OpenBoxId: data.OpenBoxId ? data.OpenBoxId : 0,
    }
    const updatedData = banners.map(pState => {
      if (pState.Id === data.Id) {
        pState.Active = !pState.Active
      }

      return pState
    })

    try {
      const res = await new UpdateBannerApi({}).putBanner(req)
      if (res) {
        setBanners(updatedData)

        Swal.fire({
          icon: 'success',
          title: `Banner ${req.Active ? 'aktif' : 'pasif'} edilmiştir.`,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const updateOrderNumber = async (data: any) => {
    const req: any = {
      Id: data.Id,
      Title: data.Title,
      Description: data.Description,
      Location: data.Location,
      CompanyList: data.CompanyList,
      Order: data.Order,
      Category: data.Category,
      CategoryName: data.CategoryName,
      Link: data.Link,
      Active: data.Active,
      Image: data.Image,
      ImageUrl: data.ImageUrl,
      StartDate: data.StartDate,
      EndDate: data.EndDate,
      CariTypeCode: data.CariTypeCode,
      CariSubTypeCode: data.CariSubTypeCode,
      CounterLocation: data.CounterLocation,
      Counter: data.Counter,
      ProductCode: data.ProductCode,
      ProductAddress: data.ProductAddress,
      BannerType: data.BannerType,
      MobilImage: data.MobilImage,
      IsHomepage: data.IsHomepage,
      PopupLocation: data.PopupLocation ? data.PopupLocation : 0,
      OpenBoxId: data.OpenBoxId ? data.OpenBoxId : 0,
    }
    const updateBanner: UpdateBannerApi = new UpdateBannerApi({})
    await updateBanner
      .putBanner(req)
      .then(result => {
        if (result) {
          setFlag('update')
          data.flag = 'update'
          Swal.fire({
            icon: 'success',
            title: 'Başarılı',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const handleOrderInput = (value: string, id: number) => {
    if (+value < 0) return

    setBanners(prev =>
      prev.map(x => {
        if (x.Id === id) {
          x.Order = +value
        }
        return x
      })
    )
  }

  const filterBanner = async () => {
    const req: IBannerFilteredList = {
      Company: filters.Company ? +filters.Company.value : 0,
      Location: filters.Location ? +filters.Location.value : 0,
      Category: filters.Category ? +filters.Category.value : 0,
      BannerType: filters.BannerType ? +filters.BannerType.value : 0,
      StartDate: filters.StartDate ? new Date(dateToISO(filters.StartDate)) : null,
      EndDate: filters.EndDate ? new Date(dateToISO(filters.EndDate)) : null,
      IsHomepage: filters.Company ? +filters.Company.value === 0 : false,
      IsCompanySelected: filters.Company ? true : false,
    }

    sessionStorage.setItem('Company', req.Company.toString())
    sessionStorage.setItem('Location', req.Location.toString())
    sessionStorage.setItem('Category', req.Category.toString())
    sessionStorage.setItem('BannerType', req.BannerType.toString())
    sessionStorage.setItem('StartDate', req.StartDate ? req.StartDate.toString() : '')
    sessionStorage.setItem('EndDate', req.EndDate ? req.EndDate.toString() : '')
    sessionStorage.setItem('IsCompanySelected', req.IsCompanySelected.toString())
    sessionStorage.setItem('Page', filters.Page.toString())

    props.showLoading()

    try {
      const res = await new BannerFilteredListApi({}).bannerFilteredList(req)
      if (res) {
        const dataBanner: IBanner[] = []
        res
          .filter(ban => {
            return ban.Active
          })
          .map(ba => {
            return dataBanner.push(ba)
          })
        res
          .filter(ban => {
            return !ban.Active
          })
          .map(ba => {
            return dataBanner.push(ba)
          })
        setBanners(dataBanner)
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'col1',
      },
      {
        Header: 'Aktif',
        accessor: 'col2',
      },
      {
        Header: 'Resim',
        accessor: 'col3',
      },
      {
        Header: 'Sıra',
        accessor: 'col4',
      },
      {
        Header: 'Şirket',
        accessor: 'col5',
      },
      {
        Header: 'Başlık',
        accessor: 'col6',
      },
      {
        Header: 'Detay',
        accessor: 'col7',
      },
      {
        Header: 'Değişiklikler',
        accessor: 'LastAction',
      },
      {
        Header: 'İşlemler',
        accessor: 'col8',
      },
    ],
    []
  )

  //#region banner sütun mapping
  const mapValueToLocation = value => {
    const locationMapping = {
      '0': '',
      '1': 'Login',
      '2': 'Ana Sayfa Sol Üst',
      '3': 'Ana Sayfa Sol Alt',
      '4': 'Ana Sayfa Sağ',
      '5': 'Sayaç Ana Sayfa Üst',
      '6': 'Sayaç Ana Sayfa Alt',
      '7': 'Bana Özel',
      '8': 'Pop-up',
      '9': 'Orta Banner',
      '10': 'Alt Banner',
      '11': 'Ürün Detay',
      '12': 'Cari Ödeme',
      '13': 'Avantaj POS Ödeme',
      '14': 'Fırsat POS Ödeme',
      '15': 'FÇV2 ödeme',
      '16': 'Sepet Ürün',
      '17': 'Sepet Ödeme Şekli',
    }
    return locationMapping[value] || ''
  }

  const mapPopupValueToLocation = value => {
    const popupLocationMapping = {
      0: 'Pop-up',
      1: 'Aç Box',
      2: 'Pop-up ve Aç Box',
      3: '',
    }

    return popupLocationMapping[value] || ''
  }

  const mapValueToType = value => {
    const typeMapping = {
      '0': '',
      '1': 'Link Yok',
      '2': 'Ürüne Link',
      '3': 'Liste Ekranı',
      '4': 'Aynı Pencere Link',
      '5': 'Ürün Alt Tip',
      '6': 'Yeni Pencere Link',
      '7': 'Youtube Video',
      '8': 'Vimeo Video',
      '9': 'Video',
      // Add more mappings as needed
    }

    return typeMapping[value] || ''
  }

  const mapValueToCategory = (value: number): string => {
    return categoryMapping[value] || ''
  }

  const mapValueToCounter = value => {
    const counterMapping = {
      '0': '',
      '1': 'Slider',
      '2': 'Alt Alta',
    }

    return counterMapping[value] || ''
  }
  //#endregion
  const tableData = useMemo(
    () =>
      filteredBanners
        .sort((a, b) => new Date(b.EndDate).getTime() - new Date(a.EndDate).getTime())
        .map((item, index) => {
          let d: string = ''
          item.CompanyList &&
            companies &&
            companies.map(i => {
              if (item.CompanyList.indexOf(i.Id) === 0) {
                if (i.Id > 0) return (d = i.Name)
                if (i.Id === 0 && item.IsHomepage === true) return (d = i.Name)
              }
              if (item.CompanyList.indexOf(i.Id) > 0) {
                if (i.Id > 0) {
                  if (d.length > 0) return (d = d + ', ' + i.Name)
                  return (d = i.Name)
                }

                if (i.Id === 0 && item.IsHomepage === true) {
                  if (d.length > 0) return (d = d + ', ' + i.Name)
                  return (d = i.Name)
                }
              }
            })
          const filteredCompanyList = item.CompanyList.filter(com => com === 0)
          const code =
            filteredCompanyList.length > 0
              ? d
              : item.IsHomepage
              ? d.length > 0
                ? d + ', Index Grup'
                : 'Index Grup'
              : d
          const type =
            Number(item.BannerType) === 1
              ? 'Link Yok'
              : Number(item.BannerType) === 2
              ? 'Ürüne Link'
              : Number(item.BannerType) === 3
              ? 'Liste Ekranı'
              : Number(item.BannerType) === 4
              ? 'Aynı Pencere Link'
              : Number(item.BannerType) === 5
              ? 'Ürün Alt Tip'
              : Number(item.BannerType) === 6
              ? 'Yeni Pencere Link'
              : Number(item.BannerType) === 7
              ? 'Youtube Video'
              : Number(item.BannerType) === 8
              ? 'Vimeo Video'
              : Number(item.BannerType) === 9
              ? 'Video'
              : '-'

          const data = {
            col1: index + 1,
            col2: (
              <>
                <Switch.Root
                  className='SwitchRoot'
                  id='active'
                  checked={item.Active}
                  onCheckedChange={() => handleBannerActive(item)}
                >
                  <Switch.Thumb className='SwitchThumb' />
                </Switch.Root>
              </>
            ),
            col3: <img src={item.ImageUrl} alt='' style={{ width: 100 }} />,
            col4: (
              <>
                <input
                  id='order'
                  type='number'
                  value={item.Order}
                  onChange={e => handleOrderInput(e.target.value, item.Id)}
                  style={{ width: 100 }}
                  data-action-type='order'
                />
                <button
                  className='btn btn-transparent'
                  type='button'
                  data-action-type='order'
                  onClick={() => updateOrderNumber(item)}
                >
                  <i className='icon-refresh-ccw' style={{ color: '#333' }}></i>
                </button>
              </>
            ),
            col5: code,
            col6: <div title={item.Title}>{item.Title.length > 10 ? item.Title.slice(0, 10) + '...' : item.Title}</div>,
            col7: (
              <button
                type='button'
                className={'btn btn-sm btn-secondary'}
                onClick={() => {
                  setShowDetail(true)
                  setDetail({
                    Location: locationNames[item.Location] as string,
                    Category: item.CategoryName,
                    BannerType: type,
                    Counter: item.CounterLocation ? (counterLocationNames[item.CounterLocation] as string) : '',
                    Start: item.StartDate,
                    End: item.EndDate,
                    Description: item.Description ? item.Description : '',
                  })
                }}
              >
                Detay
              </button>
            ),
            LastAction: (
              <IPButton
                text='Tarihçe'
                onClick={() => {
                  setShowHistoryDetail(true)
                  var selectedData = filteredBanners.find(auth => auth.Id === item.Id)
                  if (selectedData) {
                    const { AllHistory } = selectedData

                    if (AllHistory && AllHistory.length > 0) {
                      setAllHistory(AllHistory)
                    } else {
                      setAllHistory([])
                    }
                  } else {
                    setAllHistory([])
                  }
                }}
                type='button'
                style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
                className='btn btn-danger'
              ></IPButton>
            ),
            col8: (
              <div>
                {!(activeTab === 'deleted' || item.Deleted) && (
                  <>
                    <a
                      data-action-type='edit'
                      role='button'
                      style={{ cursor: 'pointer' }}
                      data-toggle='tooltip'
                      data-original-title={t('pages.mySpecial.userUpdate.edit')}
                      onClick={() => updateBanner(item.Id)}
                    >
                      <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
                    </a>
                    <a
                      data-action-type='delete'
                      role='button'
                      style={{ cursor: 'pointer' }}
                      data-toggle='tooltip'
                      data-original-title={t('pages.mySpecial.userUpdate.delete')}
                      onClick={() => deleteBanner(item.Id)}
                    >
                      <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
                    </a>
                  </>
                )}

                <a
                  data-action-type='copy'
                  role='button'
                  style={{ cursor: 'pointer' }}
                  data-toggle='tooltip'
                  data-original-title={t('pages.mySpecial.userUpdate.delete')}
                  onClick={() => copyBanner(item)}
                >
                  <i className='icon-copy' style={{ fontSize: '1.2rem', color: 'blue' }}></i>
                </a>
              </div>
            ),
          }
          return data
        }),
    [filteredBanners]
  )

  const getActiveBanners = () => {
    return banners.filter(item => item.Active)
  }

  const getInactiveBanners = () => {
    return banners.filter(item => !item.Active && !item.Deleted)
  }

  const getDeletedBanners = () => {
    return banners.filter(item => item.Deleted)
  }

  useEffect(() => {
    // Function to update filteredBanners based on the activeTab
    const updateFilteredBanners = () => {
      switch (activeTab) {
        case 'active':
          setFilteredBanners(getActiveBanners())
          break
        case 'inactive':
          setFilteredBanners(getInactiveBanners())
          break
        case 'deleted':
          setFilteredBanners(getDeletedBanners())
          break
        default:
          // If 'all' tab is selected, set filteredBanners to the full data
          setFilteredBanners(banners)
          break
      }
    }

    // Call the function to update filteredBanners when activeTab or banners change
    updateFilteredBanners()
  }, [activeTab, banners])

  return (
    <>
      <div className='container'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header d-flex justify-content-between'>
              <span className='h4'>{t('pages.admin.homeAndSiteOperation.bannersCrud.listBanner.header')}</span>
              <Link to='/admin/homeandsiteoperation/banner/add'>
                <IPButton
                  type='button'
                  className='btn btn-primary m-l-10'
                  text='Ekle'
                  style={{ width: '25px', marginRight: '10px' }}
                />
              </Link>
            </div>
            <div className='card-body'>
              <form className='formbanner'>
                <div className='row m-t-5'>
                  <div className='col-lg-4'>
                    <label className='form-label'>Şirket</label>
                    <IPSelectBox
                      isClearable
                      options={
                        companies &&
                        companies.map((item: ICompany) => ({
                          label: item.Name,
                          value: `${item.Id}`,
                        }))
                      }
                      value={filters.Company}
                      onChangeSingle={option => updateFilter('Company', option)}
                    />
                  </div>
                  <div className='col-lg-4'>
                    <label className='form-label'>Konum</label>
                    <IPSelectBox
                      isClearable
                      options={bannerData.location.map(local => {
                        return { value: local.id, label: local.title }
                      })}
                      value={filters.Location}
                      onChangeSingle={option => updateFilter('Location', option)}
                    />
                  </div>
                  <div className='col-lg-4'>
                    <label className='form-label'>Kategori</label>
                    <IPSelectBox
                      isClearable
                      options={bannerCategory}
                      value={filters.Category}
                      onChangeSingle={option => updateFilter('Category', option)}
                    />
                  </div>
                  <div className='col-lg-4 m-t-10'>
                    <label className='form-label'>Banner Tipi</label>
                    <IPSelectBox
                      isClearable
                      options={bannerData.type.map(local => {
                        return { value: local.id, label: local.title }
                      })}
                      value={filters.BannerType}
                      onChangeSingle={option => updateFilter('BannerType', option)}
                    />
                  </div>
                  <div className='col-lg-4 m-t-10'>
                    <label className='form-label'>Başlangıç Tarihi</label>
                    <div className='form-group'>
                      <InputCampaign
                        type='datetime-local'
                        id='StartDate'
                        name='StartDate'
                        label={''}
                        value={filters.StartDate && toDateTimeLocal(filters.StartDate)}
                        onChange={update => updateFilter('StartDate', update.target.value)}
                        isActive
                      />
                    </div>
                  </div>
                  <div className='col-lg-4 m-t-10'>
                    <label className='form-label'>Bitiş Tarihi</label>
                    <div className='form-group'>
                      <InputCampaign
                        type='datetime-local'
                        id='EndDate'
                        name='EndDate'
                        label={''}
                        value={filters.EndDate && toDateTimeLocal(filters.EndDate)}
                        onChange={update => updateFilter('EndDate', update.target.value)}
                        isActive
                      />
                    </div>
                  </div>
                </div>
                <div className='etkin d-flex justify-content-end'>
                  {/* <IPButton
                    type='button'
                    onClick={() => filterBanner()}
                    className='btn btn-success'
                    text={t('pages.admin.homeAndSiteOperation.bannersCrud.listBanner.strain')}
                    style={{ width: '25px' }}
                  /> */}
                </div>
              </form>
              <div className='m-4'>
                <div className='d-flex '>
                  <div className='m-r-10'>
                    <IPButton
                      className={`tab ${activeTab === 'all' ? 'active-tab' : ''}`}
                      name='all'
                      onClick={() => setActiveTab('all')}
                      text='Tümü'
                    />
                  </div>
                  <div className='m-r-10'>
                    <IPButton
                      className={`tab ${activeTab === 'active' ? 'active-tab' : ''}`}
                      name='active'
                      onClick={() => setActiveTab('active')}
                      text='Aktif Bannerlar'
                    />
                  </div>
                  <div className='m-r-10'>
                    <IPButton
                      className={`tab ${activeTab === 'inactive' ? 'active-tab' : ''}`}
                      name='inactive'
                      onClick={() => setActiveTab('inactive')}
                      text='Pasif Bannerlar'
                    />
                  </div>
                  <div className='m-r-10'>
                    <IPButton
                      className={`tab ${activeTab === 'deleted' ? 'active-tab' : ''}`}
                      name='deleted'
                      onClick={() => setActiveTab('deleted')}
                      text='Silinen Bannerlar'
                    />
                  </div>
                </div>
                <Table columns={tableColumns} data={tableData} currentPage={filters.Page} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={showDetail}
        onClose={() => setShowDetail(false)}
        hideConfirm={true}
        width={800}
        title='Banner Detay'
      >
        <div className='col'>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Konum</b> <span className='mr-2'>:</span>
            {detail.Location}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Kategori</b>
            <span className='mr-2'>:</span>
            {detail.Category}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Sayaç</b> <span className='mr-2'>:</span> {detail.Counter}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Banner ın Tipi</b> <span className='mr-2'>:</span>
            {detail.BannerType}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Başlangıç Tarihi</b> <span className='mr-2'>:</span>
            {detail.Start && new Date(detail.Start).toLocaleDateString('tr-TR')}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Bitiş Tarihi</b> <span className='mr-2'>:</span>
            {detail.End && new Date(detail.End).toLocaleDateString('tr-TR')}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '400px' }}>Açıklama</b> <span className='mr-2'>:</span>
            {detail.Description}
          </div>
        </div>
      </IPModal>
      <IPModal
        show={showHistoryDetail}
        onClose={() => setShowHistoryDetail(false)}
        hideConfirm={true}
        maxWidth={2000}
        title='Banner Tarihçe'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>Kullanıcı</th>
              <th>IP Adres</th>
              <th>Tarihçe Açıklama</th>
              <th>Değişiklik Tarihi</th>
              <th>Sıra</th>
              <th>Başlık</th>
              <th>Şirket</th>
              <th>Başlangıç Tarihi</th>
              <th>Bitiş Tarihi</th>
              <th>Aktif</th>
              <th>Konum</th>
              <th>Kategori</th>
              <th>Sayaç Konumu</th>
              <th>Banner'ın Tipi</th>
              <th>Açıklama</th>
              <th>Link</th>
              <th>Görsel</th>
              <th>Mobil Görsel</th>
              <th>Anasayfa mı?</th>
              <th>AçBox Id</th>
            </tr>
          </thead>
          <tbody>
            {allHistory && allHistory.length > 0 ? (
              allHistory.map((ba, index) => {
                const formatDate = (date: moment.MomentInput) =>
                  date ? moment(date).format('DD.MM.YYYY HH:mm:ss') : ''

                formatDate(ba.StartDate)
                formatDate(ba.EndDate)
                const previousData = index > 0 ? allHistory[index - 1] : null

                let d: string = ''
                const comp = Array.from(ba.CompanyList, digit => Number(digit))

                const addCompanyName = (company: any) => {
                  if (company.Id > 0 || (company.Id === 0 && ba.IsHomepage === true)) {
                    if (d.length > 0) {
                      d += ', '
                    }
                    d += company.Name
                  }
                }

                companies?.forEach(company => {
                  const index = comp.indexOf(company.Id)

                  if (index === 0) {
                    addCompanyName(company)
                  } else if (index > 0) {
                    addCompanyName(company)
                  }
                })

                const filteredCompanyList = comp.filter(com => com === 0)

                const companyNames =
                  filteredCompanyList.length > 0
                    ? d
                    : ba.IsHomepage
                    ? d.length > 0
                      ? `${d}, Index Grup`
                      : 'Index Grup'
                    : d

                const getCellStyle = (key: string) => ({
                  background: previousData && ba[key] !== previousData[key] ? 'green' : 'transparent',
                  color: previousData && ba[key] !== previousData[key] ? 'white' : 'black',
                })

                return (
                  <tr role='row' className='odd' key={ba.Id}>
                    <td>{ba.DealerCode.replace('0000', '') + ' ' + ba.UserName}</td>
                    <td>{ba.UserIp}</td>
                    <td
                      style={{
                        background: ba.OperationType === 'Delete' ? 'red' : 'inherit',
                        color: ba.OperationType === 'Delete' ? 'white' : 'inherit',
                      }}
                    >
                      {ba.HistoryDescription}
                    </td>
                    <td>{formatDate(ba.ChangeDate)}</td>
                    <td style={getCellStyle('Order')}>{ba.Order}</td>
                    <td style={getCellStyle('Title')}>{ba.Title}</td>
                    <td style={getCellStyle('CompanyList')}>{companyNames}</td>
                    <td style={getCellStyle('StartDate')}>{formatDate(ba.StartDate)}</td>
                    <td style={getCellStyle('EndDate')}>{formatDate(ba.EndDate)}</td>
                    <td style={getCellStyle('Active')}>{ba.Active ? 'Aktif' : 'Pasif'}</td>
                    <td style={getCellStyle('Location')}>{mapValueToLocation(ba.Location)}</td>
                    <td style={getCellStyle('Category')}>{mapValueToCategory(ba.Category ?? 0)}</td>
                    <td style={getCellStyle('CounterLocation')}>{mapValueToCounter(ba.CounterLocation)}</td>
                    <td style={getCellStyle('BannerType')}>{mapValueToType(ba.BannerType)}</td>
                    <td style={getCellStyle('Description')}>{ba.Description}</td>
                    <td style={getCellStyle('ProductAddress')}>{ba.ProductAddress}</td>
                    <td style={getCellStyle('FileLabel')}>{ba.FileLabel}</td>
                    <td style={getCellStyle('FileMobilLabel')}>{ba.FileMobilLabel}</td>
                    <td style={getCellStyle('IsHomepage')}>{ba.IsHomepage ? 'Evet' : 'Hayır'}</td>
                    <td style={getCellStyle('OpenBoxId')}>{ba.OpenBoxId}</td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={15}>Değişiklik bilgisi yok.</td>
              </tr>
            )}
          </tbody>
        </table>
      </IPModal>
    </>
  )
}
export default componentWithContext(ListBanner)
