import React, { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import IPButton from '../../../../../components/IPButton'
import { IDealerInfo, IExcel, IReminder } from '../models/models'
import { useCustomReducer } from '../../../../../base/customHooks'
import { PostDealerReminderApi } from '../services/postDealerReminderApi'
import IPInput from '../../../../../components/IPInput'
import { GetCurrentAccountStatementToExcelApi } from '../services/getCurrentAccountStatementToExcelApi'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { downloadFileFromUrl } from '../../../../../base/utils/dataHelper'
import { GetDealerInfoApi } from '../services/getDealerInfoApi'
import InputCampaign from '../../../../../components/InputCampaign'
import IPExcelButton from '../../../../../components/IPExcelButton'

interface IListExtractState {
  companyActive: number
  reminderData?: IReminder
  rdata: IReminder[]
  dealerInfo?: IDealerInfo
  dData: IDealerInfo[]
}

function Index(props: BaseComponentWithContextProps) {
  const getInitialState = (): IListExtractState => {
    return {
      companyActive: 1000,
      rdata: [],
      dData: [],
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListExtractState>(intialState)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = (date: Date) => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const getCurrentAccountStatementApi = async () => {
    if (!startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarihleri giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    window.open(
      `/myspecial/currentAccount/extract/list?startDate=${new Date(startDate).getTime()}&endDate=${new Date(
        endDate
      ).getTime()}`,
      '_blank'
    )
  }

  useEffect(() => {
    postDealerReminder()
    getDealerInfo()
  }, [])

  const showError = (description: any) => {
    Swal.fire({
      icon: 'error',
      title: description,
      showConfirmButton: true,
      allowOutsideClick: false,
    })
  }

  const postDealerReminder = async () => {
    props.showLoading()

    try {
      const postDealerReminderApi = new PostDealerReminderApi({})
      const res = await postDealerReminderApi.postDealerReminder()

      if (res) {
        const relevantReminder = res.find(r => r.Company === 1000)

        setState({
          reminderData: relevantReminder,
          rdata: res,
        })
      }
    } catch (err: any) {
      showError(err.description)
    } finally {
      props.hideLoading()
    }
  }

  const getDealerInfo = async () => {
    props.showLoading()
    const getDealerInfoApi: GetDealerInfoApi = new GetDealerInfoApi({})
    getDealerInfoApi
      .getDealerInfo()
      .then(res => {
        props.hideLoading()
        if (res) {
          res
            .filter(r => {
              return r.CompanyCode === '1000'
            })
            .map(re => {
              return setState({ dealerInfo: re })
            })
          setState({ dData: res })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const selectCompany = async (company: number) => {
    const relevantReminder = state.rdata.find(r => r.Company === company)
    const relevantDealerInfo = state.dData.find(r => r.CompanyCode === String(company))

    setState({
      reminderData: relevantReminder,
      dealerInfo: relevantDealerInfo,
      companyActive: company,
    })
  }

  const getCurrentAccountStatementToExcel = async () => {
    props.showLoading()

    try {
      const getCurrentAccountStatementToExcelApi = new GetCurrentAccountStatementToExcelApi({})
      const data: IExcel = {
        CompanyCode: String(state.companyActive),
        StartDate: new Date(startDate),
        EndDate: new Date(endDate),
      }

      const res = await getCurrentAccountStatementToExcelApi.getCurrentAccountStatementToExcel(data)

      if (res) {
        downloadFileFromUrl('Cari Hesap Ekstresi', res.FileUrl)
      }
    } catch (err: any) {
      showError(err.description)
    } finally {
      props.hideLoading()
    }
  }

  return (
    <div className='container-fluid current-account'>
      <div className='heading-text'>
        <h4>Cari Hesap Özeti</h4>
      </div>
      <nav className='cari-hesap--tab-menu'>
        <ul>
          <li
            onClick={() => selectCompany(1000)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1000 ? 'active-company-tab' : ''}`}
          >
            <span>İNDEX</span>
          </li>
          <li
            onClick={() => selectCompany(1100)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1100 ? 'active-company-tab' : ''}`}
          >
            <span>DATAGATE</span>
          </li>
          <li
            onClick={() => selectCompany(1200)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1200 ? 'active-company-tab' : ''}`}
          >
            <span>DESPEC</span>
          </li>
          <li
            onClick={() => selectCompany(2300)}
            className={`dropdown-submenu company-tab ${state.companyActive === 2300 ? 'active-company-tab' : ''}`}
          >
            <span>NETEX</span>
          </li>
        </ul>
      </nav>
      <div className='card-body px-0'>
        <div className='form-group form-fg'>
          <div>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label className='labeltext'>Ödeme Şekli</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='paymentMethod'
                  value={state.dealerInfo?.PaymentMethod}
                  disabled
                />
              </div>
              <div className='form-group col-md-6'>
                <label className='labeltext'>Genel Vade</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='paymentMethod'
                  value={state.dealerInfo?.GeneralMaturity}
                  disabled
                />
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label className='labeltext'>Bakiye</label>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text' id='basic-addon1'>
                      TL
                    </span>
                  </div>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.TL_BAK.toFixed(2)}
                    disabled
                  />
                </div>
                <br />
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text' id='basic-addon1'>
                      USD
                    </span>
                  </div>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.USD_BAK.toFixed(2)}
                    disabled
                  />
                </div>
                <br />
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text' id='basic-addon1'>
                      EUR
                    </span>
                  </div>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.EUR_BAK.toFixed(2)}
                    disabled
                  />
                </div>
              </div>
              <div className='form-group col-md-6'>
                <label className='labeltext'>Vadesi Geçmiş Bakiye</label>
                <div className='input-group mb-3'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.VGA_TL.toFixed(2)}
                    disabled
                  />
                </div>
                <br />
                <div className='input-group mb-3'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.VGA_USD.toFixed(2)}
                    disabled
                  />
                </div>
                <br />
                <div className='input-group mb-3'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.VGA_EUR.toFixed(2)}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className='row' style={{ justifyContent: 'center' }}>
              <div className='row'>
                <div className='col-12 col-md-3 mb-3'></div>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
              </div>
              <div className='row p-0 m-t-10'>
                <div className='form-group col-md-6'>
                  <IPButton
                    text='Listele'
                    className='button'
                    onClick={() => getCurrentAccountStatementApi()}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      borderRadius: '10px',
                      width: '150px',
                      height: '50px',
                    }}
                  />
                </div>
                <div className='form-group col-md-6'>
                  <IPExcelButton onClick={getCurrentAccountStatementToExcel} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Index)
