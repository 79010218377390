import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { IException } from '../base/models'
import IPCheckbox from '../components/IPCheckBox'
import Modal from '../components/IPModal/IPModal'
import { IBanner } from '../pages/Homepage/commonModels/models'
import { GetPopupBannerApi } from '../pages/Homepage/services/getPopupBannerApi'
import { BannerDontShowAgainPopupApi } from '../pages/Homepage/services/dontShowAgainPopupApi'
import Image from '../components/IPImage/IPImage'
import { useAppDispatch, useAppSelector } from '../redux/app/hooks'
import { selectedTheme } from '../redux/slices/theme/themeSlice'
import { css } from '@emotion/css'
import { addOpenedPopupsCompanyIds, openedPopupsCompanyIdsRedux } from '../redux/slices/user/userSlice'

const withAnnouncementPopup =
  (WrappedComponent: any) =>
    ({ ...props }) => {
      const location = useLocation()
      const dispatch = useAppDispatch()

      const [showAnnouncementPopup, setShowAnnouncementPopup] = useState(false)
      const [banner, setBanner] = useState<IBanner | undefined>()
      const [dontShowAgain, setDontShowAgain] = useState(false)
      const theme = useAppSelector(selectedTheme)
      const openedPopupCompanyIds = useAppSelector(openedPopupsCompanyIdsRedux)

      useEffect(() => {
        if (
          location &&
          location.state &&
          openedPopupCompanyIds.indexOf(theme.id) === -1 &&
          (performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming).type !== 'reload'
        ) {
          getAnnouncementBanner()
        }
      }, [theme])

      const getAnnouncementBanner = () => {
        // TODO : company id
        const api: GetPopupBannerApi = new GetPopupBannerApi({})
        api
          .getBanner(theme.id)
          .then((res: IBanner) => {
            if (res && res.Image) {
              setBanner(res)
              setShowAnnouncementPopup(true)
              dispatch(addOpenedPopupsCompanyIds(theme.id))
            }
          })
          .catch((err: IException) => { })
      }

      const onPopupClose = () => {
        setShowAnnouncementPopup(false)

        if (banner && dontShowAgain) {
          const dontShowAgainPopupApi: BannerDontShowAgainPopupApi = new BannerDontShowAgainPopupApi({})
          dontShowAgainPopupApi.dontShowAgain(banner.Id)
        }
      }
      return (
        <>
          <WrappedComponent {...props} />
          {banner?.CompanyList.map(
            item =>
              ((theme.id === 0 && banner.IsHomepage) || theme.id === Number(item)) && (
                <Modal
                  title={banner?.Title}
                  width={'auto'}
                  show={showAnnouncementPopup}
                  onClose={onPopupClose}
                  hideConfirm={true}
                  checkbox={
                    <IPCheckbox
                      checked={dontShowAgain}
                      label={'Bir daha gösterme'}
                      onChange={e => setDontShowAgain(e.target.checked)}
                      style={{ float: "left" }}
                    />
                  }
                >
                  <div className='p-3'>
                    <div className='row'>
                      {banner && banner.BannerType === 7 ? (
                        banner.VideoId && (
                          <iframe
                            width='100%'
                            style={{ height: '400px' }}
                            src={'https://www.youtube.com/embed/' + banner.VideoId}
                            title='YouTube video player'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                          ></iframe>
                        )
                      ) : banner && banner.BannerType === 8 ? (
                        banner.VideoId && (
                          <iframe
                            src={'https://player.vimeo.com/video/' + banner.VideoId + '?h=ff2b296e05&color=ffffff'}
                            width='640'
                            height='360'
                            allow='autoplay; fullscreen; picture-in-picture'
                          ></iframe>
                        )
                      ) : banner && banner.BannerType !== 1 ? (
                        banner.Image && banner.Image.toLocaleLowerCase('tr-TR').indexOf('.mp4') > -1 ? (
                          <video width='100%' preload='auto' controls style={{ height: '100%', padding: '5px 0 0 0' }}>
                            <source src={banner.ImageUrl} type='video/mp4'></source>
                            Sorry, your browser doesn't support videos.
                          </video>
                        ) : banner.Image.toLocaleLowerCase('tr-TR').indexOf('.MP4') > -1 ? (
                          <video width='100%' preload='auto' controls style={{ height: '100%', padding: '5px 0 0 0' }}>
                            <source src={banner.ImageUrl} type='video/MP4'></source>
                            Sorry, your browser doesn't support videos.
                          </video>
                        ) : banner.ProductAddress ? (
                          <a href={banner.ProductAddress} target={banner.BannerType === 6 ? '_blank' : ''}>
                            {
                              <Image
                                className='banner'
                                style={{ height: '100%', padding: '5px 0 0 0' }}
                                src={banner.ImageUrl}
                                alt={banner.Title}
                              />
                            }
                          </a>
                        ) : (
                          <Link to={`/products?bannerId=${banner.Id}`} target={banner.BannerType === 6 ? '_blank' : ''}>
                            {
                              <Image
                                className='banner'
                                style={{ height: '100%', padding: '5px 0 0 0' }}
                                src={banner.ImageUrl}
                                alt={banner.Title}
                              />
                            }
                          </Link>
                        )
                      ) : (
                        banner && (
                          <Image
                            className='banner'
                            style={{ height: '100%', padding: '5px 0 0 0' }}
                            src={banner.ImageUrl}
                            alt={banner.Title}
                          />
                        )
                      )}
                    </div>
                    {/* <div
                      className={css`
                      position: absolute;
                      bottom: 5px;
                      left: 7px;
                      border-radius: 10px;
                      background-color: rgba(0, 0, 0, 0.5);
                      padding: 5px 10px;
                      color: white;
                      .form-check {
                        margin-bottom: 0px !important;
                      }
                    `}
                    >
                      <IPCheckbox
                        checked={dontShowAgain}
                        label={'Bir daha gösterme'}
                        onChange={e => setDontShowAgain(e.target.checked)}
                      />
                    </div> */}
                  </div>
                </Modal>
              )
          )}
        </>
      )
    }

export { withAnnouncementPopup }
