import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { IMarket } from '../models/models'

export class GetDppMarketingContentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'DppEtCetera/getDppMarketingContent',
      requesterInfo,
    })
  }

  public async getDppMarketingContent(): Promise<IMarket> {
    return await this.getAsync<IMarket>()
  }
}
