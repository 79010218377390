import { css } from '@emotion/css'
import { useNavigate } from 'react-router-dom'
import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useCustomReducer } from '../../base/customHooks'
import { DATAGATE_THEME, DEFAULT_THEME, DESPEC_THEME, INDEX_THEME, NETEX_THEME } from '../../base/theme/themeConfig'
import IPButton from '../../components/IPButton'
import Image from '../../components/IPImage/IPImage'
import IPModal from '../../components/IPModal'
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks'
import { addListToBasketUIViaBundleRedux } from '../../redux/slices/basket/basketSlice'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import React from 'react'

interface ICampaignsState {
  showCampaignModal: boolean
  data: {
    BundleImage: string
    CampaignId: number
    IsAddBasket: boolean
    ListImage: string
    CampaignName: string
  }
}
const HomePageCampaigns = (props: { count: number; data: any[] }) => {
  const theme = useAppSelector(selectedTheme)
  const navigate: any = useNavigate()
  const getInitialState = (): ICampaignsState => {
    return {
      showCampaignModal: false,
      data: {
        BundleImage: '',
        CampaignId: 0,
        IsAddBasket: false,
        ListImage: '',
        CampaignName: '',
      },
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ICampaignsState>(intialState)
  const dispatchRedux = useAppDispatch()
  const getBasketAdd = async () => {
    dispatchRedux(addListToBasketUIViaBundleRedux(state.data.CampaignId))
    setState({ showCampaignModal: false })
  }

  const handleClose = () => {
    setState({
      showCampaignModal: false,
    })

    setTimeout(() => {
      setState({
        showCampaignModal: false,
        data: {
          BundleImage: '',
          CampaignId: 0,
          IsAddBasket: false,
          ListImage: '',
          CampaignName: '',
        },
      })
    }, 300)
  }

  return (
    <section className='home-page-slider section-back-grey mobile-resize py-5 p-l-40 p-r-40 m-t-40 text-left '>
      <div className='row'>
        <div className='col d-flex align-items-center'>
          <Image src='/images/KampanyaliUrunler.png' alt='' style={{ width: '36px', marginTop: '-5px' }} />
          <h4 className='section-back-grey-title'>Kampanyalar</h4>
        </div>
        {props.count > 4 && (
          <div className='col-6'>
            <IPButton
              className={`${theme.name === NETEX_THEME ? 'netex_buttonColor' : ''}${
                theme.name === INDEX_THEME ? 'index_buttonColor' : ''
              }${theme.name === DEFAULT_THEME ? 'default_buttonColor' : ''}${
                theme.name === DESPEC_THEME ? 'despec_buttonColor' : ''
              }${theme.name === DATAGATE_THEME ? 'datagate_buttonColor' : ''}`}
              onClick={() => navigate('/campaings')}
              style={{
                width: 'auto',
                float: 'right',
                backgroundColor: '#fff',
              }}
              text='Tümünü Göster'
            />
          </div>
        )}
      </div>
      <div
        className={`seperator m-0 ${theme.name === NETEX_THEME ? 'netex_borderBottom' : ''}${
          theme.name === INDEX_THEME ? 'index_borderBottom' : ''
        }${theme.name === DEFAULT_THEME ? 'default_borderBottom' : ''}${
          theme.name === DESPEC_THEME ? 'despec_borderBottom' : ''
        }${theme.name === DATAGATE_THEME ? 'datagate_borderBottom' : ''}`}
      ></div>
      <div className='row m-t-10'>
        {props.data.length > 4 ? (
          <Swiper
            navigation={true}
            keyboard={true}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            observer={true}
            observeParents={true}
            modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
            className='mySwiper'
          >
            {[...Array(Math.ceil(props.data.length / 4))].map((_, slideIndex) => (
              <SwiperSlide key={slideIndex} style={{ backgroundColor: 'transparent', justifyContent: 'flex-start' }}>
                <div className='row'>
                  {props.data.slice(slideIndex * 4, (slideIndex + 1) * 4).map((item: any) => (
                    <div
                      key={item.Id}
                      className={`col-12 col-sm-6 col-md-4 col-lg-3 card-mobile-resize${
                        slideIndex === Math.ceil(props.data.length / 4) - 1 ? ' last-column' : ''
                      }`}
                      style={{ backgroundColor: '#f5f5f5' }}
                    >
                      <div
                        className={`d-flex flex-column justify-content-between campaign ${css`
                          padding: 20px;
                        `}`}
                        key={item.Id}
                      >
                        <div
                          onClick={() => setState({ showCampaignModal: true, data: item })}
                          style={{ cursor: 'pointer' }}
                        >
                          <Image src={item.ListImage} alt='' className='campaign' />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          props.data.map(
            (item: any, index) =>
              index < 4 && (
                <div
                  key={item.Id}
                  className={`col-12 col-sm-6 col-md-4 col-lg-3 card-mobile-resize`}
                  style={{
                    flex: '25',
                    maxWidth: 'fit-content',
                  }}
                >
                  <div
                    className={`d-flex flex-column justify-content-between campaign ${css`
                      padding: 20px;
                    `}`}
                    style={{ width: 'fit-content' }}
                    key={item.Id}
                  >
                    <div
                      onClick={() => setState({ showCampaignModal: true, data: item })}
                      style={{ cursor: 'pointer' }}
                    >
                      <Image src={item.ListImage} alt='' className='campaign' />
                    </div>
                  </div>
                </div>
              )
          )
        )}
      </div>
      <IPModal
        title={state.data.CampaignName}
        style={{ textAlign: 'center' }}
        width='auto'
        maxWidth={1440}
        show={state.showCampaignModal}
        onConfirm={() => navigate(`/products?campaigns=${state.data.CampaignId}`)}
        confirmText='Ürünlere Git'
        buttons={
          state.data.IsAddBasket ? (
            <IPButton
              text={'Sepete At'}
              style={{ width: 'auto', padding: '15px 20px', backgroundColor: '#ffa100' }}
              className='modal-button'
              onClick={() => getBasketAdd()}
            />
          ) : undefined
        }
        onClose={handleClose}
      >
        <div className='p-3'>
          <Image
            src={state.data.BundleImage}
            alt=''
            className='campaign'
            sizes='(max-width: 1440px) 100vw, auto'
            loading='eager'
          />
        </div>
      </IPModal>
    </section>
  )
}

export default React.memo(HomePageCampaigns)
