import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IPostHomePageProductsRequest } from '../models/requestModels'
import { IHomePageProducts } from '../models/responseModels'

export class UpdateHomePageProductsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/homepageProduct/update',
      requesterInfo,
    })
  }

  public async putHomePageProducts(request: IPostHomePageProductsRequest): Promise<IHomePageProducts> {
    return await this.putAsync<IHomePageProducts>(undefined, request)
  }
}
