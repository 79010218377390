import React, { useEffect, useMemo, useState } from 'react'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPButton from '../../../../../components/IPButton'
import { Link, useNavigate } from 'react-router-dom'
import IPModal from '../../../../../components/IPModal/IPModal'
import _ from 'lodash'
import { IBrand, ICompany, IDealer } from '../../../components/models/models'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { getBrandApi, getCompanyApi, getDealerApi } from '../../../components/api'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks'
import InputCampaign from './DealerChannelInput'
import { DealerFilteredListApi } from '../services/dealerFilteredListApi'
import { DealerChannelList, IDealerFilteredList } from '../models/getDealerChannelResponseModel'
import { ProgramService } from '../services/ProgramService'
import Table from './Table'
import { DealerChannel, DealerChannelCondition } from '../models/DealerChannelModel'
import Swal from 'sweetalert2'
import { DeleteChannelApi } from '../services/deleteChannelApi'
import { DealerChannelService } from '../services/DealerChannelService'

interface ListDealerState {
  company: ISelectOption[]
  dealer: ISelectOption[]
  brand?: ISelectOption
  showFilterCurrent: boolean
  showDealerChannelCondition: boolean
  dealerNo: number
  filterCurrent?: DealerChannel
  dealerChannelCondition: DealerChannelCondition[]
}

function ListDealer(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const [companies, setCompanies] = useState<ISelectOption[]>([])
  const [dealerCodeOptions, setDealerCodeOptions] = useState<ISelectOption[]>([])
  const [brandOptions, setBrandOptions] = useState<ISelectOption[]>([])
  const [channelList, setChannelList] = useState<DealerChannelList[]>([])
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  useEffect(() => {
    getCompanies()
    dealerService()
    getBrand()
    getProgramAll()
  }, [])
  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    const data: ISelectOption[] = []
    company.map((com) => {
      return data.push({ value: com.Id, label: com.Name })
    })
    setCompanies(data);
  }

  const getInitialState = (): ListDealerState => {
    return {
      company: [],
      dealer: [],
      showFilterCurrent: false,
      showDealerChannelCondition: false,
      dealerNo: 0,
      dealerChannelCondition: []
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ListDealerState>(intialState)

  const dealerService = async () => {
    const dealer: IDealer[] = await getDealerApi()
    const data: ISelectOption[] = [];
    dealer.map((dea) => {
      return data.push({ value: dea.SapCode, label: dea.SapCode.replace("0000", "") })
    })
    setDealerCodeOptions(data)
  }

  const getBrand = async () => {
    const campaignBundleService = new DealerChannelService({})
    const res = await campaignBundleService.getBrandList()
    const data: ISelectOption[] = [];
    res.Entity?.BrandCode.map((bra) => {
      return data.push({ value: bra, label: bra })
    })
    setBrandOptions(data)
  }

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const filterDealer = async () => {
    if (state.company.length === 0) {
      Swal.fire({
        icon: 'error',
        title: "Lütfen şirket seçiniz",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      Swal.fire({
        icon: 'error',
        title: "Lütfen tarihleri seçiniz",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const company: any[] = [];
    state.company.map((com) => {
      return company.push(com.value)
    })
    const dealer: any[] = [];
    state.dealer.map((dea) => {
      return dealer.push(String(dea.value))
    })

    const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000)

    const data: IDealerFilteredList = {
      DealerCodes: dealer,
      Companies: company,
      StartDate: dateToISO(startDate),
      EndDate: dateToISO(endDate),
    }

    props.showLoading();
    const dealerFilteredList: DealerFilteredListApi = new DealerFilteredListApi({})
    dealerFilteredList
      .dealerFilteredList(data)
      .then(res => {
        props.hideLoading();
        if (res) {
          let data: DealerChannelList[] = []
          res.map(r => {
            let i = 0;
            let brands: String[] = []
            if (r.DealerChannelCondition.length > 0) {
              r.DealerChannelCondition.map(con => {
                if (state.brand && String(state.brand.value).length > 0) {
                  if (con.XBrandList.findIndex((xbrand) => { return xbrand != String(state.brand?.value) }) > -1)
                    return i++
                }
                if (con.XBrandList.length > 0) {
                  con.XBrandList.map(list => {
                    if (brands.length > 0) {
                      if (list !== '') {
                        let i = 0
                        brands.map(bra => {
                          if (bra === list) return i++
                        })
                        if (i === 0) return brands.push(list)
                      }
                      return brands
                    } else {
                      if (list !== '') return brands.push(list)
                    }
                  })
                  return brands
                }
                return brands
              })
            }
            if (i === 0) {
              return data.push({
                Id: r.Id,
                DealerChannel: r.DealerChannel,
                DealerChannelCondition: r.DealerChannelCondition,
                BrandList: brands,
              })
            }

          })
          setChannelList(data)
        }
      })
      .catch(e => {
        props.hideLoading();
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }
  const getProgramAll = async () => {
    props.showLoading();
    const service = new ProgramService({})
    service.GetProgramAll()
      .then(res => {
        if (res) {
          props.hideLoading();
          let data: DealerChannelList[] = []
          res.map(r => {
            let brands: String[] = []
            if (r.DealerChannelCondition.length > 0) {
              r.DealerChannelCondition.map(con => {
                if (con.XBrandList.length > 0) {
                  con.XBrandList.map(list => {
                    if (brands.length > 0) {
                      if (list !== '') {
                        let i = 0
                        brands.map(bra => {
                          if (bra === list) return i++
                        })
                        if (i === 0) return brands.push(list)
                      }
                      return brands
                    } else {
                      if (list !== '') return brands.push(list)
                    }
                  })
                  return brands
                }
                return brands
              })
            }
            return data.push({
              Id: r.Id,
              DealerChannel: r.DealerChannel,
              DealerChannelCondition: r.DealerChannelCondition,
              BrandList: brands,
            })
          })
          setChannelList(data)
        }
      })
      .catch(e => {
        props.hideLoading();
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Bundle Kod',
        accessor: 'col2',
      },
      {
        Header: 'Şirket',
        accessor: 'col3',
      },
      {
        Header: 'Marka',
        accessor: 'col4',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'col5',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'col6',
      },
      {
        Header: 'Cari Filtre',
        accessor: 'col7',
      },
      {
        Header: 'Listeler',
        accessor: 'col8',
      },
      {
        Header: 'İşlemler',
        accessor: 'col9',
      },
    ],
    []
  )
  const navigate: any = useNavigate()
  const tableData = useMemo(
    () =>
      channelList.map(dt => {
        let d: String[] = []
        dt.DealerChannel.CompanyCodes &&
          companies &&
          companies.map(i => {
            if (dt.DealerChannel.CompanyCodes.indexOf(String(i.value)) == 0) return d.push(i.label)
          })
        dt.DealerChannel.CompanyCodes &&
          companies &&
          companies.map(i => {
            if (dt.DealerChannel.CompanyCodes.indexOf(String(i.value)) > 0) return d.push("," + i.label)
          })
        var code = d.map(i => {
          return i
        })
        let dBrand: String[] = []
        dt.BrandList &&
          brandOptions &&
          brandOptions.map(i => {
            if (dt.BrandList.indexOf(String(i.value)) === 0) return dBrand.push(i.label)
          })
        dt.BrandList &&
          brandOptions &&
          brandOptions.map(i => {
            if (dt.BrandList.indexOf(String(i.value)) > 0) return dBrand.push(", " + i.label)
          })
        var code = d.map(i => {
          return i
        })
        var brand = dBrand.map(b => {
          return b
        })
        const data = {
          col1: dt.Id,
          col2: dt.DealerChannel.BundleCode,
          col3: code,
          col4: brand,
          col5: new Date(dt.DealerChannel.StartDate).toLocaleDateString('tr-TR'),
          col6: new Date(dt.DealerChannel.EndDate).toLocaleDateString('tr-TR'),
          col7: (
            <button
              type='button'
              className={'btn btn-sm btn-secondary'}
              onClick={() => {
                setState({
                  showFilterCurrent: true,
                  dealerNo: dt.Id,
                  filterCurrent: dt.DealerChannel,
                })
              }}
            >
              Cari Filtre
            </button>
          ),
          col8: (
            <button
              type='button'
              className={'btn btn-sm btn-secondary'}
              onClick={() => {
                setState({
                  showDealerChannelCondition: true,
                  dealerNo: dt.Id,
                  dealerChannelCondition: dt.DealerChannelCondition,
                })
              }}
            >
              Liste
            </button>
          ),
          col9: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.edit')}
                onClick={() => {
                  navigate('/admin/homeAndSiteOperation/DealerChannel', {
                    state: { channelData: dt, flag: 'update' },
                  })
                }}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
              <a
                data-action-type='delete'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.delete')}
                onClick={() => deleteChannel(dt.Id)}
              >
                <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
              </a>
            </div>
          ),
        }

        return data
      }),
    [channelList]
  )

  const deleteChannel = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Bayi Kanal Programını silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteChannelApi = new DeleteChannelApi({})
        const isDeleted = await deleteChannelApi.deleteChannel(id)
        if (!isDeleted) {
          Swal.fire({
            icon: 'success',
            title: 'Bayi kanal silindi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async res => {
            setChannelList(prevState => {
              return prevState.filter((prev) => { return +prev.Id != +id })
            })
          })
        }
      }
    })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Bayi Kanal Program Listesi</span>
            <Link to='/admin/homeAndSiteOperation/DealerChannel'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Ekle'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row m-t-5'>
                <div className='col-lg-2'>
                  <IPSelectBox
                    placeholder="Şirket"
                    isMulti
                    isClearable
                    id='CompanyList'
                    name='CompanyList'
                    closeMenuOnSelect={false}
                    onChangeMulti={option => setState({ company: option })}
                    options={companies}
                    value={state.company}
                  />
                </div>
                <div className='col-lg-2'>
                  <IPSelectBox
                    placeholder="Cari Kod Listesi"
                    isMulti
                    isClearable
                    id='DealerCode'
                    name='DealerCode'
                    closeMenuOnSelect={false}
                    onChangeMulti={option => setState({ dealer: option })}
                    options={dealerCodeOptions}
                    value={state.dealer}
                  />
                </div>
                <div className='col-lg-2'>
                  <div className='form-group'>
                    <IPSelectBox
                      placeholder="Marka"
                      isClearable
                      options={brandOptions}
                      onChangeSingle={option => setState({ brand: option })}
                      value={state.brand}
                    />
                  </div>
                </div>
                <div className='col-lg-2'>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => setStartDate(new Date(e.target.value))}
                    isActive
                  />
                </div>
                <div className='col-lg-2'>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => setEndDate(new Date(e.target.value))}
                    isActive
                  />
                </div>
                <div className='col-lg-2'>
                  <IPButton
                    type='button'
                    onClick={filterDealer}
                    className='btn btn-success'
                    text={t('pages.admin.homeAndSiteOperation.bannersCrud.listBanner.strain')}
                    style={{ width: '25px' }}
                  />
                </div>
              </div>
            </form>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={state.showFilterCurrent}
        onClose={() => setState({ showFilterCurrent: false })}
        hideConfirm={true}
        width={'auto'}
        title='Cari Filtre'
      >
        <div className='col'>
          {state.filterCurrent?.IsDealerListInclude && (
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '200px' }}>Cari Kodu Listesi</b> <span className='mr-2'>:</span>
              {state.filterCurrent?.XDealerCodeList &&
                state.filterCurrent?.XDealerCodeList?.map((code, index) => {
                  if (index > 0) {
                    return ';' + code.replace("0000", "")
                  }
                  return code.replace("0000", "")
                })}
            </div>
          )}
          {state.filterCurrent?.IsDealerTypeInclude && (
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '200px' }}>Cari Tipi Listesi</b> <span className='mr-2'>:</span>
              {state.filterCurrent?.XDealerTypeList?.map((code, index) => {
                if (index > 0) {
                  return ';' + code
                }
                return code
              })}
            </div>
          )}
          {state.filterCurrent?.IsDealerSubTypeInclude && (
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '200px' }}>Cari Alt Tip Listesi</b> <span className='mr-2'>:</span>
              {state.filterCurrent?.XDealerSubTypeList?.map((code, index) => {
                if (index > 0) {
                  return ';' + code
                }
                return code
              })}
            </div>
          )}
          {state.filterCurrent?.XCompanyCodes && state.filterCurrent?.XCompanyCodes.length > 0 && (
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '200px' }}>Grup Firma Listesi</b> <span className='mr-2'>:</span>
              {state.filterCurrent?.XCompanyCodes?.map((code, index) => {
                if (code === 1) {
                  if (index === 0) return 'Index'
                  return '; Index'
                }
                if (code === 4) {
                  if (index === 0) return 'Datagate'
                  return '; Datagate'
                }
                if (code === 3) {
                  if (index === 0) return 'Despec'
                  return '; Despec'
                }
                if (code === 2) {
                  if (index === 0) return 'Netex'
                  return '; Netex'
                }
                if (code === 5) {
                  if (index === 0) return 'Index Grup'
                  return '; Index Grup'
                }
              })}
            </div>
          )}
          {state.filterCurrent?.ProgramDetailDescription &&
            state.filterCurrent?.ProgramDetailDescription.length > 0 && (
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '200px' }}>Açıklama</b> <span className='mr-2'>:</span>
                {state.filterCurrent?.ProgramDetailDescription}
              </div>
            )}
        </div>
      </IPModal>
      <IPModal
        show={state.showDealerChannelCondition}
        onClose={() => setState({ showDealerChannelCondition: false })}
        hideConfirm={true}
        width={600}
        title='Listeler'
      >
        <>
          <div
            // ref={tableRef}
            style={{
              border: '1px solid #000000',
              backgroundColor: 'white',
              padding: '20px',
            }}
          >
            {state.dealerChannelCondition && state.dealerChannelCondition.length > 0 && state.dealerChannelCondition.map(order => {
              return (
                <div className='row'>
                  <div className='col'>
                    {order.XProductCodeList && order.XProductCodeList.length > 0 && (
                      <div className='d-flex align-items-center justify-content-start'>
                        <b style={{ width: '200px' }}>Ürün Kodu Listesi</b> <span className='mr-2'>:</span>
                        {order.XProductCodeList}
                      </div>
                    )}
                    {order.XQuotaCodeList && order.XQuotaCodeList.length > 0 && (
                      <div className='d-flex align-items-center justify-content-start'>
                        <b style={{ width: '200px' }}>Quota Kodu Listesi</b> <span className='mr-2'>:</span>
                        {order.XQuotaCodeList}
                      </div>
                    )}
                    {order.XGroupList && order.XGroupList.length > 0 && (
                      <div className='d-flex align-items-center justify-content-start'>
                        <b style={{ width: '200px' }}>Grup Kodu Listesi</b> <span className='mr-2'>:</span>
                        {order.XGroupList}
                      </div>
                    )}
                    {order.XBrandList && order.XBrandList.length > 0 && (
                      <div className='d-flex align-items-center justify-content-start'>
                        <b style={{ width: '200px' }}>Marka Listesi</b> <span className='mr-2'>:</span>
                        {order.XBrandList}
                      </div>
                    )}
                    {order.XProductSubTypeList && order.XProductSubTypeList.length > 0 && (
                      <div className='d-flex align-items-center justify-content-start'>
                        <b style={{ width: '200px' }}>Alt Tip Listesi</b> <span className='mr-2'>:</span>
                        {order.XProductSubTypeList}
                      </div>
                    )}
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '200px' }}>Adet</b> <span className='mr-2'>:</span>
                      {order.TargetQuantity}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '200px' }}>Tutar</b> <span className='mr-2'>:</span>
                      {order.TargetPrice}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '200px' }}>Açıklama</b> <span className='mr-2'>:</span>
                      {order.ProgramDetailDescription}
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <br></br>
        </>
      </IPModal>
    </div>
  )
}
export default componentWithContext(ListDealer)
