import React, { useMemo, useState, useEffect } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Table from '../../../../../components/Table'
import { IXmlInvoiceReport, IXmlInvoiceReportResponse } from '../models/models'
import { PostXmlInvoiceReportApi } from '../services/postXmlInvoiceReportApi'
import Swal from 'sweetalert2'
import { IListXMLRequest } from '../../XMLUpdate/models/models'
import { GetXmlListApi } from '../../XMLUpdate/services/getXmlListApi'
import { useNavigate } from 'react-router'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPInput from '../../../../../components/IPInput'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { formatPrice } from '../../../../../base/utils/dataHelper'

interface IPrepaidXMLState {
  statusOption: ISelectOption | null
  companiesOption: ISelectOption | null
  dealerCode: string
  companies: ICompany[]
}
function PrepaidXML(props: BaseComponentWithContextProps) {
  const getInitialState = (): IPrepaidXMLState => {
    return {
      statusOption: null,
      companiesOption: null,
      dealerCode: "",
      companies: []
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IPrepaidXMLState>(intialState)
  const [prepaidXmlList, setPrepaidXmlList] = useState<IXmlInvoiceReportResponse[]>([])
  useEffect(() => {
    postXmlInvoiceReport()
    getCompanies()
  }, [])

  const postXmlInvoiceReport = async () => {
    const req: IXmlInvoiceReport = {
      Company: state.companiesOption ? String(state.companiesOption.value) : "",
      DealerCode: state.dealerCode.length > 5 ? state.dealerCode.padStart(10, "0") : state.dealerCode
    }
    props.showLoading()
    const postXmlInvoiceReportApi: PostXmlInvoiceReportApi = new PostXmlInvoiceReportApi({})
    postXmlInvoiceReportApi
      .postXmlInvoiceReport(req)
      .then((result: IXmlInvoiceReportResponse[]) => {
        if (result) {
          // let status = result
          // if (state.statusOption) {
          //   if (state.statusOption.value === 1) {
          //     status = result.filter((res) => { return res.Active })
          //   } else
          //     status = result.filter((res) => { return !res.Active })
          // }
          setPrepaidXmlList(result)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'HESAP ADI/UNVAN',
        accessor: 'col1',
      },
      {
        Header: 'BAYİ KODU',
        accessor: 'col2',
      },
      {
        Header: 'ŞİRKET',
        accessor: 'col3',
      },
      {
        Header: 'MT',
        accessor: 'col4',
      },
      {
        Header: 'EMAİL',
        accessor: 'col5',
      },
      {
        Header: 'OLUŞTURMA TARİHİ',
        accessor: 'col6'
      },
      {
        Header: 'SON FATURA TARİHİ',
        accessor: 'col7',
      },
      {
        Header: 'SON EŞİK GÜN İÇİNDE FATURA TOPLAMI',
        accessor: 'col8',
      },
      {
        Header: 'SON FATURA SONRASI GÜN ADEDİ',
        accessor: 'col9',
      },
      {
        Header: 'ŞİRKET EŞİK DEĞERİ GÜN',
        accessor: 'col10',
      },
      {
        Header: 'ŞİRKET EŞİK DEĞERİ FİYAT',
        accessor: 'col11',
      },
      {
        Header: 'EXPİRE OLMAZ',
        accessor: 'col12',
      },
      {
        Header: 'İŞLEM',
        accessor: 'col13',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      prepaidXmlList.map((item, index) => {
        const data = {
          col1: item.DealerName,
          col2: item.DealerCode.replace("0000", ""),
          col3: item.Company === "1000" ? "Index" : (item.Company === "1100" ? "Datagate" : (item.Company === "1200" ? "Despec" : "Netex")),
          col4: item.MT,
          col5: item.Email,
          col6: item.CreateWhen === "0001-01-01T00:00:00" ? <div style={{ textAlign: "center" }}>-</div> : item.CreateWhen && new Date(String(item.CreateWhen)).toLocaleDateString(),
          col7: item.LastOrderDate === "0001-01-01T00:00:00" ? <div style={{ textAlign: "center" }}>-</div> : item.LastOrderDate && new Date(String(item.LastOrderDate)).toLocaleDateString(),
          col8: formatPrice(item.TotalAmount),
          col9: item.LastOrderDate === "0001-01-01T00:00:00" ? "-" : item.Fark,
          col10: item.Esik,
          col11: formatPrice(item.MinTotalAmount),
          col12: <IPCheckbox checked={item.NeverExpire} disabled={false} />,
          col13: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title="Güncelle"
                onClick={() => getXmlList(item.DealerCode)}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
            </div>
          ),
        }

        return data
      }),
    [prepaidXmlList]
  )

  const navigate: any = useNavigate()
  const getXmlList = async (dealerCode) => {
    const req: IListXMLRequest = {
      searchType: 2,
      dealerCode: dealerCode.length > 5 ? dealerCode.padStart(10, "0") : dealerCode
    }
    props.showLoading()
    const getXmlListApi: GetXmlListApi = new GetXmlListApi({})
    await getXmlListApi
      .getXmlList(req)
      .then(result => {
        navigate('/admin/UserOperation/XMLDealer', {
          state: { xmlData: result, approve: false, id: 0 },
        })
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push(co)
      })
    setState({ companies: data })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Faturasız XML Carileri</span>
          </div>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='col-12 col-md-2 mb-3'>
                <IPSelectBox
                  isClearable
                  name='CompanyId'
                  value={state.companiesOption}
                  onChangeSingle={(option: ISelectOption) => {
                    setState({ companiesOption: option })
                  }}
                  options={
                    state.companies &&
                    state.companies.map((item: ICompany) => ({
                      label: item.Name,
                      value: `${item.Code}`,
                    }))
                  }
                  placeholder="Şirket Seçiniz..."
                />
              </div>
              <div className='col-12 col-md-2 mb-3'>
                <IPInput
                  type='text'
                  id='DealerCode'
                  className='form-control'
                  name='DealerCode'
                  value={state.dealerCode}
                  placeholder="Bayi Kodu"
                  onChange={(e) => setState({ dealerCode: e.target.value })}
                />
              </div>
              {/* <div className='col-12 col-md-2 mb-3'>
                <IPSelectBox
                  isClearable
                  options={[
                    {
                      label: "Aktif",
                      value: 1,
                    },
                    {
                      label: "Pasif",
                      value: 2,
                    },
                  ]}
                  value={state.statusOption}
                  onChangeSingle={option => {
                    setState({ statusOption: option })
                  }}
                  placeholder="Aktiflik Durumu"
                />
              </div> */}
              <div className='col-12 col-md-1 mb-3'>
                <IPButton
                  type='button'
                  className='btn btn-success m-l-10'
                  text='Göster'
                  style={{ width: '25px', marginRight: '10px' }}
                  onClick={postXmlInvoiceReport}
                />
              </div>
            </div>
            <Table columns={tableColumns} data={tableData} currentPage={0} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(PrepaidXML)
