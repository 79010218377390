import { IMenu } from '../models/responseModels'
import { useCustomReducer } from '../../../../../base/customHooks'
import './ListMenu.scss'
import React, { useEffect, useState } from 'react'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPButton from '../../../../../components/IPButton'
import { ISelectGroupBase, ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBoxMenu'
import IPSelectBox from '../../../../../components/IPSelectBox/IPSelectBoxMenu'
import IPSelectBoxMenu from '../../../../../components/IPSelectBox/IPSelectBoxMenu'
import _ from 'lodash'
import IPInput from '../../../../../components/IPInput'
import { ICategory } from '../../CategoryCrud/models/requestModels'
import { GetCategoryListApi } from '../../CategoryCrud/services/getCategoryListApi'
import { GetMenuApi } from '../services/getMenuListApi'
import Swal from 'sweetalert2'
import { useFormik } from 'formik'
import validationSchema from './Validation'
import { UpdateMenuApi } from '../services/updateMenuApi'
import { PostMenuApi } from '../services/postMenuApi'
import { DeleteMenuApi } from '../services/deleteMenuApi'
import { UpdateSwitchApi } from '../services/updateSwitchApi'
import { CommonPostResponse } from '../../../components/models/models'
import IPAccordion from '../../../../../components/IPAccordion'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'

interface IListMenuState {
  categories: ICategory[]
  buttonName: string
  menu: IMenu[]
  buttonChange: number
  categoriesOptions: any
  menusOptions: any
  parent: string
  sapCodes?: string
  menuEnd?: boolean
  catEnd?: boolean
}

function ListMenu(props: BaseComponentWithContextProps) {
  const initialValues: any = {
    Id: 0,
    ParentId: null,
    Enabled: false,
    CompanyId: 0,
    CategoryList: null,
    Title: null,
    Order: 0,
  }
  const ChangeEnd = async () => {
    resetForm()
    getMenu(state.buttonChange)
    setState({ buttonName: 'Ekle' })
  }
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async values => { },
  })
  const t = translate(Translations)

  useEffect(() => {
    getCategories()
    getMenu(0)
  }, [])

  const getInitialState = (): IListMenuState => {
    return {
      categories: [],
      buttonName: 'Ekle',
      menu: [],
      buttonChange: 0,
      categoriesOptions: [],
      menusOptions: [],
      parent: '',
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListMenuState>(intialState)

  const getMenu = async data => {
    props.showLoading()
    const menuApi = new GetMenuApi({})
    const menus = await menuApi.getMenu(data)
    setState({ menu: menus, menuEnd: true })
  }

  const getCategories = async () => {
    props.showLoading()
    const getCategoryApi = new GetCategoryListApi({})
    const category = await getCategoryApi.getCategorys()
    setState({ categories: category, catEnd: true })
  }
  useEffect(() => {
    if (state.menuEnd && state.catEnd)
      props.hideLoading()
    SelectCategoryOption()
    SelectMenuOption()
  }, [state.menu, state.categories])


  const SelectCategoryOption = () => {
    let data: (ISelectOption | ISelectGroupBase)[] = []
    state.categories &&
      state.categories.forEach(category => {
        data.push({ label: category.Name, value: category.Id, sapCode: category.SapCode })
        category.Items &&
          category.Items.forEach((cat: ICategory) => {
            data.push({ label: `${cat.Name}`, value: cat.Id, tabSize: 1, sapCode: cat.SapCode })
            cat.Items &&
              cat.Items.forEach((subCat: ICategory) => {
                data.push({ label: subCat.Name, value: subCat.Id, tabSize: 2, sapCode: subCat.SapCode })
                subCat.Items &&
                  subCat.Items.forEach((subCat2: ICategory) => {
                    data.push({
                      label: subCat2.Name,
                      value: subCat2.Id,
                      tabSize: 3, sapCode: subCat2.SapCode
                    })
                    subCat2.Items &&
                      subCat2.Items.forEach((subCat3: ICategory) => {
                        data.push({
                          label: subCat3.Name,
                          value: subCat3.Id,
                          tabSize: 4, sapCode: subCat3.SapCode
                        })
                        subCat3.Items &&
                          subCat3.Items.forEach((subCat4: ICategory) => {
                            data.push({
                              label: subCat4.Name,
                              value: subCat4.Id,
                              tabSize: 5, sapCode: subCat4.SapCode
                            })
                          })
                      })
                  })
              })
          })
      })
    return setState({ categoriesOptions: data })
  }

  const SelectMenuOption = () => {
    let data: (ISelectOption | ISelectGroupBase)[] = []
    state.menu &&
      state.menu.forEach(category => {
        data.push({ label: category.Title, value: category.Id })
        category.Items &&
          category.Items.forEach((cat: IMenu) => {
            data.push({ label: cat.Title, value: cat.Id, tabSize: 1 })
            cat.Items &&
              cat.Items.forEach((subCat: IMenu) => {
                data.push({
                  label: subCat.Title,
                  value: subCat.Id,
                  tabSize: 2,
                })
                subCat.Items &&
                  subCat.Items.forEach((subCat2: IMenu) => {
                    data.push({
                      label: subCat2.Title,
                      value: subCat2.Id,
                      tabSize: 3,
                    })
                    subCat2.Items &&
                      subCat2.Items.forEach((subCat3: ICategory) => {
                        data.push({
                          label: subCat3.Name,
                          value: subCat3.Id,
                          tabSize: 4,
                        })
                        subCat3.Items &&
                          subCat3.Items.forEach((subCat4: ICategory) => {
                            data.push({
                              label: subCat4.Name,
                              value: subCat4.Id,
                              tabSize: 5,
                            })
                          })
                      })
                  })
              })
          })
      })
    return setState({ menusOptions: data })
  }

  const Operation = data => {
    getMenu(data)
    resetForm()
    setState({ buttonName: 'Ekle', buttonChange: data })
  }
  const action = async data => {
    const currentActiveTab = state.buttonChange
    if (state.buttonName === 'Ekle') {
      return Operation(data)
    }

    const result = await Swal.fire({
      icon: 'question',
      title: 'Şirketi değiştirmek istediğinize emin misiniz? Güncelle de gelen veriler silinecek.',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    })

    if (result.isConfirmed) {
      setState({ buttonChange: data })
      return Operation(data)
    } else {
      setState({ buttonChange: currentActiveTab })
    }
  }
  const readyToEdit = (cat: any, catId: any, parentId: any, parent: string) => {
    setValues(cat)
    let data: any[] = []
    state.categoriesOptions.find(cat => {
      if (catId) {
        catId.map(id => {
          if (cat.value === id) {
            return data.push(cat)
          }
        })
      }
    })

    let menu = state.menusOptions.find(cat => {
      if (cat.value === parentId) {
        return cat
      }
    })

    setFieldValue('CategoryList', data)
    setFieldValue('ParentId', menu)
    setState({ buttonName: 'Güncelle', parent: parent, sapCodes: cat.SapCodes })
  }
  const deleteMenu = async data => {
    Swal.fire({
      icon: 'question',
      title: 'Menüyü silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteMenuApi = new DeleteMenuApi({})
        const isDeleted = await deleteMenuApi.deleteMenu(data.Id)
        if (isDeleted) {
          Swal.fire({
            icon: 'success',
            title: 'Menü silindi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async result => {
            ChangeEnd()
          })
        }
      }
    })
  }

  const editMenu = async () => {
    const clnValues = _.clone(values)
    clnValues.ParentId = clnValues.ParentId ? Number(clnValues.ParentId.value) : 0
    clnValues.CategoryList = clnValues.CategoryList
      ? (clnValues.CategoryList.map((item: ISelectOption) => item.value) as any)
      : []
    clnValues.CompanyId = state.buttonChange
    if (state.buttonName === 'Güncelle') {
      const updateMenu: UpdateMenuApi = new UpdateMenuApi({})
      updateMenu
        .updateMenu(clnValues)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Menü Güncellendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async result => {
              ChangeEnd()
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    } else {
      const postMenu: PostMenuApi = new PostMenuApi({})
      postMenu
        .addMenu(clnValues)
        .then((result: CommonPostResponse) => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Menü Eklendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async result => {
              ChangeEnd()
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }
  }
  const updateSwitch = async data => {
    Swal.fire({
      icon: 'question',
      title: 'Menüyü değiştirmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteMenuApi = new UpdateSwitchApi({})
        const isDeleted = await deleteMenuApi.updateSwitch(data)
        if (isDeleted) {
          Swal.fire({
            icon: 'success',
            title: 'Menü değiştirildi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async result => {
            ChangeEnd()
          })
        }
      }
    })
  }

  const [keyword, setKeyword] = useState('')

  const menus: IMenu[] = []

  if (keyword.length > 0) {
    for (const cat of state.menu as IMenu[]) {
      menus.push(cat)
      for (const subCat of cat.Items as IMenu[]) {
        menus.push(subCat)
        for (const subCat2 of subCat.Items as IMenu[]) {
          menus.push(subCat2)
          for (const subCat3 of subCat2.Items as IMenu[]) {
            menus.push(subCat3)
          }
        }
      }
    }
  }

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-6'>
          <div className='container'>
            <div className='content col-lg-6' style={{ position: 'fixed' }}>
              <div className='card'>
                <div className='card-header'>
                  <span className='h4'>{t('pages.admin.categoryOperation.menuCrud.listMenu.header')}</span>
                </div>
                <div className='card-body'>
                  <form id='form1' className='form-validate' noValidate>
                    {/* <div className="form-row">
                      <IPButton
                        type="button"
                        className="btn btn-primary col-md-2"
                        text=" Formu Sıfırla"
                        onClick={() => {
                          //setFlag("create");
                          setFieldValue("Name", "");
                          setFieldValue("CallCenter", "");
                          setFieldValue("SapCode", "");
                          setFieldValue("Website", "");
                          setFieldValue("Email", "");
                          setState({ buttonName: "Ekle" });
                          //setTmpImage("");
                        }}
                      />
                    </div><br /> */}
                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                        <label htmlFor='orderNumber' className='labeltext'>
                          Kategori
                        </label>
                        <IPSelectBoxMenu
                          isMulti
                          isClearable
                          id='CategoryList'
                          name='CategoryList'
                          closeMenuOnSelect={false}
                          onChangeMulti={(options: ISelectOption[]) => {
                            setFieldValue('CategoryList', options)
                          }}
                          options={state.categoriesOptions}
                          value={values.CategoryList}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-group col-md-12'>
                        <label htmlFor='orderNumber' className='labeltext'>
                          Başlık
                        </label>
                        <IPInput
                          type='text'
                          className='form-control'
                          name='Title'
                          value={values.Title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='form-group col-md-4'>
                        <label htmlFor='orderNumber' className='labeltext'>
                          Sap Kodu
                        </label>
                        <IPInput
                          disabled
                          type='text'
                          className='form-control'
                          name='sapCodes'
                          value={state.sapCodes}
                        // onChange={handleChange}
                        // onBlur={handleBlur}
                        />
                      </div>
                      <div className='form-group col-md-4'>
                        <label htmlFor='orderNumber' className='labeltext'>
                          Üst Menu
                        </label>
                        <IPSelectBox
                          isClearable
                          id='ParentId'
                          name='ParentId'
                          options={state.menusOptions}
                          value={values.ParentId}
                          onChangeSingle={option => {
                            setFieldValue('ParentId', option)
                          }}
                        />
                      </div>
                      <div className='form-group col-md-4'>
                        <label htmlFor='orderNumber' className='labeltext'>
                          Sıra
                        </label>
                        <IPInput
                          type='text'
                          className='form-control'
                          name='Order'
                          value={values.Order}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    </div>
                    <div>
                      <div className='row'>
                        <div className='col-md-9'>
                          {state.parent.length > 0 && <label>Üst Menü: {state.parent}</label>}
                        </div>
                        <div className='col-md-3'>
                          <IPButton
                            type='button'
                            onClick={() => editMenu()}
                            className='btn btn-success'
                            text={state.buttonName}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='col-6'>
          <div className='card'>
            <IPInput type='text' placeholder='Ara...' value={keyword} onChange={e => setKeyword(e.target.value)} />
            {keyword.length > 0 &&
              menus &&
              menus
                .filter((cat: IMenu) => cat.Title.toLowerCase().includes(keyword.toLowerCase()))
                .map((cat: IMenu) => (
                  <>
                    <div className='mResult'>
                      {cat.Title}
                      <div className='accActions'>
                        <span
                          style={{ margin: '0 25px 0 0 ' }}
                          onClick={() => {
                            readyToEdit(cat, cat.CategoryList, cat.ParentId, '')
                          }}
                        >
                          <i className='ml-4 fas fa-edit fa-2xs edit-icon'></i>
                        </span>
                        {!cat.Items ||
                          (cat.Items.length === 0 && (
                            <span
                              style={{ margin: '0 25px 0 0 ' }}
                              onClick={() => {
                                deleteMenu(cat)
                              }}
                            >
                              <i className='ml-4 fas fa-trash fa-2xs trash-icon m-r-15'></i>
                            </span>
                          ))}
                        <label className='p-switch p-switch-sm switch-color-success'>
                          <input
                            type='checkbox'
                            name='switch'
                            onChange={() => updateSwitch(cat.Id)}
                            checked={cat.Enabled}
                          />
                          <span className='p-switch-style'></span>
                        </label>
                      </div>
                    </div>
                  </>
                ))}
            {keyword.length === 0 &&
              state.menu &&
              state.menu.map((cat: IMenu) => (
                <details className='acc' key={cat.Id}>
                  <summary>
                    {cat.Title}
                    <div className='accActions'>
                      <span
                        style={{ margin: '0 25px 0 0 ' }}
                        onClick={() => {
                          readyToEdit(cat, cat.CategoryList, cat.ParentId, '')
                        }}
                      >
                        <i className='ml-4 fas fa-edit fa-2xs edit-icon'></i>
                      </span>
                      {!cat.Items ||
                        (cat.Items.length === 0 && (
                          <span
                            style={{ margin: '0 25px 0 0 ' }}
                            onClick={() => {
                              deleteMenu(cat)
                            }}
                          >
                            <i className='ml-4 fas fa-trash fa-2xs trash-icon m-r-15'></i>
                          </span>
                        ))}
                      <label className='p-switch p-switch-sm switch-color-success'>
                        <input
                          type='checkbox'
                          name='switch'
                          onChange={() => updateSwitch(cat.Id)}
                          checked={cat.Enabled}
                        />
                        <span className='p-switch-style'></span>
                      </label>
                    </div>
                  </summary>
                  <div className='accContent'>
                    {cat.Items &&
                      cat.Items.map((subCat: IMenu) => (
                        <details className='acc' key={subCat.Id}>
                          <summary>
                            {subCat.Title}
                            <div className='accActions'>
                              <span
                                style={{ margin: '0 25px 0 0 ' }}
                                onClick={() => {
                                  readyToEdit(subCat, subCat.CategoryList, subCat.ParentId, cat.Title)
                                }}
                              >
                                <i className='ml-4 fas fa-edit fa-2xs edit-icon'></i>
                              </span>
                              {!subCat.Items ||
                                (subCat.Items.length === 0 && (
                                  <span
                                    style={{ margin: '0 25px 0 0 ' }}
                                    onClick={() => {
                                      deleteMenu(subCat)
                                    }}
                                  >
                                    <i className='ml-4 fas fa-trash fa-2xs trash-icon'></i>
                                  </span>
                                ))}
                              <label className='p-switch p-switch-sm switch-color-success'>
                                <input
                                  type='checkbox'
                                  name='switch'
                                  onChange={() => updateSwitch(subCat.Id)}
                                  checked={subCat.Enabled}
                                />
                                <span className='p-switch-style'></span>
                              </label>
                            </div>
                          </summary>
                          <div className='accContent'>
                            {subCat.Items &&
                              subCat.Items.map((subCat2: IMenu) => (
                                <details className='acc' key={subCat2.Id}>
                                  <summary>
                                    {subCat2.Title}
                                    <div className='accActions'>
                                      <span
                                        style={{
                                          margin: '0 25px 0 0 ',
                                        }}
                                        onClick={() => {
                                          readyToEdit(
                                            subCat2,
                                            subCat2.CategoryList,
                                            subCat2.ParentId,
                                            cat.Title + ' / ' + subCat.Title
                                          )
                                        }}
                                      >
                                        <i className='ml-4 fas fa-edit fa-2xs edit-icon'></i>
                                      </span>
                                      {!subCat2.Items ||
                                        (subCat2.Items.length === 0 && (
                                          <span
                                            style={{
                                              margin: '0 25px 0 0 ',
                                            }}
                                            onClick={() => {
                                              deleteMenu(subCat2)
                                            }}
                                          >
                                            <i className='ml-4 fas fa-trash fa-2xs trash-icon'></i>
                                          </span>
                                        ))}
                                      <label className='p-switch p-switch-sm switch-color-success'>
                                        <input
                                          type='checkbox'
                                          name='switch'
                                          onChange={() => updateSwitch(subCat2.Id)}
                                          checked={subCat2.Enabled}
                                        />
                                        <span className='p-switch-style'></span>
                                      </label>
                                    </div>
                                  </summary>
                                  <div className='accContent'>
                                    {subCat2.Items &&
                                      subCat2.Items.map((subCat3: IMenu) => (
                                        <details className='acc' key={subCat3.Id}>
                                          <summary>
                                            {subCat3.Title}
                                            <div className='accActions'>
                                              <span
                                                style={{
                                                  margin: '0 25px 0 0 ',
                                                }}
                                                onClick={() => {
                                                  readyToEdit(
                                                    subCat3,
                                                    subCat3.CategoryList,
                                                    subCat3.ParentId,
                                                    cat.Title + ' / ' + subCat.Title + ' / ' + subCat2.Title
                                                  )
                                                }}
                                              >
                                                <i className='ml-4 fas fa-edit fa-2xs edit-icon'></i>
                                              </span>
                                              {!subCat3.Items ||
                                                (subCat3.Items.length === 0 && (
                                                  <span
                                                    style={{
                                                      margin: '0 25px 0 0 ',
                                                    }}
                                                    onClick={() => {
                                                      deleteMenu(subCat3)
                                                    }}
                                                  >
                                                    <i className='ml-4 fas fa-trash fa-2xs trash-icon'></i>
                                                  </span>

                                                ))}
                                              <label className='p-switch p-switch-sm switch-color-success'>
                                                <input
                                                  type='checkbox'
                                                  name='switch'
                                                  onChange={() => updateSwitch(subCat3.Id)}
                                                  checked={subCat3.Enabled}
                                                />
                                                <span className='p-switch-style'></span>
                                              </label>
                                            </div>
                                          </summary>
                                          <div className='accContent'>
                                            {subCat3.Items &&
                                              subCat3.Items.map((subCat4: IMenu) => (
                                                <details className='acc' key={subCat3.Id}>
                                                  <summary>
                                                    {subCat4.Title}
                                                    <div className='accActions'>
                                                      <span
                                                        style={{
                                                          margin: '0 25px 0 0 ',
                                                        }}
                                                        onClick={() => {
                                                          readyToEdit(
                                                            subCat4,
                                                            subCat4.CategoryList,
                                                            subCat4.ParentId,
                                                            cat.Title + ' / ' + subCat.Title + ' / ' + subCat2.Title
                                                          )
                                                        }}
                                                      >
                                                        <i className='ml-4 fas fa-edit fa-2xs edit-icon'></i>
                                                      </span>
                                                      {!subCat4.Items ||
                                                        (subCat4.Items.length === 0 && (
                                                          <span
                                                            style={{
                                                              margin: '0 25px 0 0 ',
                                                            }}
                                                            onClick={() => {
                                                              deleteMenu(subCat4)
                                                            }}
                                                          >
                                                            <i className='ml-4 fas fa-trash fa-2xs trash-icon'></i>
                                                          </span>

                                                        ))}
                                                      <label className='p-switch p-switch-sm switch-color-success'>
                                                        <input
                                                          type='checkbox'
                                                          name='switch'
                                                          onChange={() => updateSwitch(subCat4.Id)}
                                                          checked={subCat4.Enabled}
                                                        />
                                                        <span className='p-switch-style'></span>
                                                      </label>
                                                    </div>
                                                  </summary>
                                                </details>
                                              ))}
                                          </div>
                                        </details>

                                      ))}
                                  </div>

                                </details>
                              ))}
                          </div>
                        </details>
                      ))}
                  </div>
                </details>
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default componentWithContext(ListMenu)