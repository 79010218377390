import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IOpenBox } from '../models'

export class ReadOpenBoxApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'openBox/OpenBoxRead',
      requesterInfo,
    })
  }

  public async readOpenBox(openBoxId: number): Promise<boolean> {
    return await this.putAsync<boolean>(openBoxId)
  }
}
