import React, { useEffect, useMemo, useState } from 'react'
import '../style.scss'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../Admin/ProductOperation/IyzicoCampaign/InputCampaign'
import { GetCompaniesApi } from '../../../../../layouts/components/client/services/getCompaniesApi'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { IFaultTraccking, IFaultTracckingRequest } from '../models/models'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Table from './Table'
import { PostFaultTrackingApi } from '../services/PostFaultTrackingApi'
import { ExcelToFaultTrackingApi } from '../services/excelToFaultTrackingApi'
import { downloadFileFromUrl } from '../../../../../base/utils/dataHelper'
import moment from 'moment'
import ExcelButton from '../../../../../components/IPExcelButton/IPExcelButton'

const t = translate(Translations)
interface OrderListState {
  faultTracckingList: IFaultTraccking[]
  searchOption?: ISelectOption
  searchText: string
  company: ISelectOption[]
  companyOption?: ISelectOption
  isClosed: boolean
}

const SearchOptions = [
  { value: 1, label: 'İrsaliye No' },
  { value: 2, label: 'Kargo Takip No' },
  { value: 3, label: 'Ürün Kodu/Adı' },
  { value: 4, label: 'Seri No' },
  { value: 5, label: 'PO' },
  { value: 6, label: 'Form No' },

]

function Index(props: BaseComponentWithContextProps) {
  const getInitialState = (): OrderListState => {
    return {
      faultTracckingList: [],
      searchText: '',
      company: [],
      isClosed: false,
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<OrderListState>(intialState)

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    getCompanies()
  }, [])

  const postFaultTracking = () => {
    const req: IFaultTracckingRequest = {
      CompanyCode: state.companyOption ? String(state.companyOption.value) : '',
      StartDate: state.searchText.length === 0 ? toDateTimeLocal(startDate) : null,
      EndDate: state.searchText.length === 0 ? toDateTimeLocal(endDate) : null,
      IsClosed: state.isClosed,
      SearchType: state.searchOption ? +state.searchOption.value : 0,
      SearchValue: state.searchText,
    }
    props.showLoading()
    const postFaultTrackingApi: PostFaultTrackingApi = new PostFaultTrackingApi({})
    postFaultTrackingApi
      .postFaultTracking(req)
      .then((res: any) => {
        if (res) {
          setState({ faultTracckingList: res })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const excelToFaultTracking = () => {
    const req: IFaultTracckingRequest = {
      CompanyCode: state.companyOption ? String(state.companyOption.value) : '',
      StartDate: state.searchText.length === 0 ? toDateTimeLocal(startDate) : null,
      EndDate: state.searchText.length === 0 ? toDateTimeLocal(endDate) : null,
      IsClosed: state.isClosed,
      SearchType: state.searchOption ? +state.searchOption.value : 0,
      SearchValue: state.searchText,
    }
    props.showLoading()
    const excelToFaultTrackingApi: ExcelToFaultTrackingApi = new ExcelToFaultTrackingApi({})
    excelToFaultTrackingApi
      .excelToFaultTracking(req)
      .then((res: any) => {
        if (res) {
          downloadFileFromUrl('Arıza Takip', res.FileUrl)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getCompanies = async () => {
    const res = await new GetCompaniesApi({}).getCompanyApi()
    if (res) {
      const data: any = []
      data.push({ value: '', label: 'Hepsi' })
      res.forEach(item => {
        if (item.Name === 'Index Group') return
        data.push({ value: item.Code, label: item.Name })
      })
      setState({ company: data })
    }
  }

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sıra No',
        accessor: 'col1',
      },
      {
        Header: 'Şirket',
        accessor: 'col2',
      },
      {
        Header: 'Form No',
        accessor: 'col3',
      },
      {
        Header: 'Giriş Tarihi',
        accessor: 'col4',
      },
      {
        Header: 'Sap Stok Kodu',
        accessor: 'col5',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col6',
      },
      {
        Header: 'Ürün Açıklama',
        accessor: 'col7',
      },
      {
        Header: 'Adet',
        accessor: 'col8',
      },
      {
        Header: 'Seri No',
        accessor: 'col9',
      },
      {
        Header: 'İşlem',
        accessor: 'col10',
      },
      {
        Header: 'Durum',
        accessor: 'col11',
      },
      {
        Header: 'İrsaliye No',
        accessor: 'col12',
      },
      {
        Header: 'PO',
        accessor: 'po',
      },
      {
        Header: 'Kargo Takip No',
        accessor: 'col13',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.faultTracckingList.map((item, index) => {
        const data = {
          col1: index + 1,
          col2: item.Company,
          col3: item.FormNo,
          col4: moment(item.ToDate).format('DD.MM.YYYY'),
          col5: item.MatNumber,
          col6: item.ProductCode,
          col7: item.ProductName,
          col8: item.Quantity,
          col9: item.SeriNo,
          col10: item.Process,
          col11: <p style={{ color: 'red' }}>{item.Status}</p>,
          col12: item.WaybillNo,
          col13: item.CargoNo,
          po: item.PO
        }
        return data
      }),
    [state.faultTracckingList]
  )

  return (
    <div className='myOrder faultTracking' style={{ backgroundColor: 'white' }}>
      <div className='container-fluid'>
        <div className='heading-text'>
          <h4>{t('pages.mySpecial.slider.faultTracking')}</h4>
        </div>
        <div className='box'>
          <form>
            <div className='form-row'>
              <div className='col-12 col-md-4 mb-3'>
                <InputCampaign
                  type='datetime-local'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, 'Start')}
                  isActive
                />
              </div>
              <div className='col-12 col-md-4 mb-3'>
                <InputCampaign
                  type='datetime-local'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, 'End')}
                  isActive
                />
              </div>
              <div className='col-12 col-md-4 mb-3'>
                <IPSelectBox
                  options={state.company}
                  value={state.companyOption}
                  placeholder='Şirket Seçiniz...'
                  onChangeSingle={(option: ISelectOption) => {
                    setState({ companyOption: option })
                  }}
                  isClearable
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='col-12 col-md-4 mb-3'>
                <IPSelectBox
                  options={SearchOptions}
                  value={state.searchOption}
                  placeholder='Arama Kriteri Seçiniz...'
                  onChangeSingle={(option: ISelectOption) => {
                    setState({ searchOption: option })
                  }}
                  isClearable
                />
              </div>
              <div className='col-12 col-md-4 mb-3'>
                <IPInput type='text' onChange={e => setState({ searchText: e.target.value })} />
              </div>
              <div className='col-12 col-md-4 mb-5'>
                <IPCheckbox
                  label={t('pages.mySpecial.faultTracking.closedEntries')}
                  checked={state.isClosed}
                  onChange={() => setState({ isClosed: !state.isClosed })}
                />
              </div>
            </div>
            <div className='myOrderActions m-t-25 m-b-15 justify-content-center'>
              <IPButton
                text='Listele'
                className='button'
                onClick={() => postFaultTracking()}
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '10px',
                  width: '150px',
                  height: '50px',
                }}
              />
              <ExcelButton onClick={excelToFaultTracking} />
            </div>
          </form>
        </div>
        {state.faultTracckingList.length > 0 && <Table columns={tableColumns} data={tableData} currentPage={0} />}
      </div>
    </div>
  )
}

export default componentWithContext(Index)
