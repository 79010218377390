import React, { useEffect, useState } from 'react'
import CheckBox from '../../../components/IPCheckBox/IPCheckBox'
import { translate } from '../../../base/localization'
import Translations from '../../../translations'
import classes from '../styles/survey.module.scss'
import Button from '../../../components/IPButton/IPButton'
import { GetSurveyListApi } from '../services/getSurveyListApi'
import { SaveSurveyApi } from '../services/postSurveyApi'
import { ISurveyItem, OptionTypeEnum } from '../models/getSurveyResponseModel'
import 'core-js/actual/array/group-by'
import 'core-js/actual/array/group-by-to-map'
import { SaveSurveyRequest, SaveSurveyResponse } from '../models/postSurveyModels'
import Swal from 'sweetalert2'
import IPRadio from '../../../components/IPRadio'
import IPCheckbox from '../../../components/IPCheckBox'
import _ from 'lodash'
import IPInput from '../../../components/IPInput'
import { useNavigate, useSearchParams } from 'react-router-dom'

export interface IAnsItem {
  QuestionId: number
  BitwiseId: number
  answerType: OptionTypeEnum
  text: string
}

export default function SurveyMain(props) {
  const [surveyList, setSurveyList] = useState<ISurveyItem[]>([])
  const [ansList, setAnsList] = useState<IAnsItem[]>([])

  const t = translate(Translations)

  useEffect(() => {
    getSurvey()
  }, [])

  const getSurvey = async () => {
    const getSurveyListApi = new GetSurveyListApi({})
    await getSurveyListApi
      .getSurveyList()
      .then(res => {
        setSurveyList(res)
      })
      .catch(err => console.log(err))
  }

  const addItemToSurvey = (
    bitId: number,
    questionId: number,
    answerType: OptionTypeEnum,
    isChecked: boolean,
    inputText: string = ''
  ) => {
    let surveyObj: IAnsItem = {
      QuestionId: questionId,
      BitwiseId: bitId,
      text: inputText,
      answerType,
    }

    if (answerType === OptionTypeEnum.Input) {
      const idx = ansList.findIndex(
        (el: IAnsItem) => el.QuestionId === questionId && answerType === OptionTypeEnum.Input
      )

      if (idx > -1) {
        ansList.splice(idx, 1)
      }

      setAnsList([...ansList, surveyObj])
    }

    if (answerType === OptionTypeEnum.RadioAndInput || answerType === OptionTypeEnum.CheckboxAndInput) {
      const idx = ansList.findIndex(
        (el: IAnsItem) =>
          el.QuestionId === questionId &&
          (el.answerType === OptionTypeEnum.RadioAndInput || el.answerType === OptionTypeEnum.CheckboxAndInput)
      )

      if (idx > -1) {
        ansList.splice(idx, 1)
      }

      setAnsList([...ansList, surveyObj])
    }

    const index = ansList.findIndex(
      (el: IAnsItem) =>
        el.QuestionId === questionId &&
        (answerType === OptionTypeEnum.Radio || (answerType === OptionTypeEnum.CheckBox && el.BitwiseId === bitId))
    )

    if (answerType === OptionTypeEnum.Radio) {
      if (index === -1) {
        setAnsList([...ansList, surveyObj])
      } else {
        ansList.splice(index, 1)
        setAnsList([...ansList, surveyObj])
      }
    } else if (answerType === OptionTypeEnum.CheckBox) {
      if (isChecked) {
        if (index === -1) {
          setAnsList([...ansList, surveyObj])
        }
      } else {
        if (index > -1) {
          const tmp = _.clone(ansList)
          tmp.splice(index, 1)
          setAnsList(tmp)
        }
      }
    }
  }

  const isCheckedAnswer = (bitId: number, questionId: number, answerType: OptionTypeEnum) => {
    return (
      ansList.findIndex(
        (el: IAnsItem) => el.QuestionId === questionId && el.BitwiseId === bitId && answerType === el.answerType
      ) > -1
    )
  }

  const groupByArray = (ansList: any, key: any) => {
    return ansList.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key]
      let el = rv.find(r => r && r.key === v)
      if (el) {
        el.values.push(x)
      } else {
        rv.push({ key: v, values: [x] })
      }
      return rv
    }, [])
  }

  const handleSurveyToApi = async () => {
    const groupedAnsList = groupByArray(ansList, 'QuestionId')
    const postTotalAnsArrToApi = groupedAnsList.map(item => {
      const textAnswers = item.values.filter(
        (valItem: any) =>
          [OptionTypeEnum.Input, OptionTypeEnum.RadioAndInput, OptionTypeEnum.CheckboxAndInput].indexOf(
            valItem.answerType
          ) > -1
      )

      return {
        QuestionId: item.key,
        InputText: textAnswers && textAnswers.length === 1 ? textAnswers[0].text : '',
        OptionType: textAnswers && textAnswers.length === 1 ? textAnswers[0].answerType : item.values[0].answerType,
        TotalAnswer: item.values.map(el => el.BitwiseId).reduce((prev, curr) => prev + curr, 0),
      }
    })

    const saveSurveyToApi = () => {
      const saveSurveyApi: SaveSurveyApi = new SaveSurveyApi({})
      const Answers: SaveSurveyRequest = postTotalAnsArrToApi
      saveSurveyApi
        .saveSurvey(Answers)
        .then((res: SaveSurveyResponse) => {
          getSurvey()
          Swal.fire({
            icon: 'success',
            title: res.Message || 'Anket Kaydedildi.',
            showConfirmButton: true,
            allowOutsideClick: false,
            timer: 1000,
          }).then(() => {
            {
              setAnsList([])
              Swal.fire({
                icon: 'info',
                title: 'Ankete katılım sağladığınız için teşekkür ederiz.',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            }
          })
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'warning',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }

    saveSurveyToApi()
  }

  const displayedCategoriesMap: number[] = []
  const displayedSurveyIDs = new Set()

  const [visibleCategory, setVisibleCategory] = useState<any>(null)
  const navigate: any = useNavigate()

  const handleCategoryClick = (category) => {
    if (visibleCategory === category) {
      setVisibleCategory(null)
      navigate('')
    }
    else {
      setVisibleCategory(category)
      navigate(`?Id=${category}`)
    }

  }

  const [searchParams, setSearchParams] = useSearchParams()

  const idParam = searchParams.get('Id')

  useEffect(() => {
    if (idParam != null) {
      setVisibleCategory(Number(idParam))
    }
  }, [idParam])


  return (
    <div className='container mt-5 mb-5'>
      <div className='mx-3 m-b-20'>
        <div className='row'>
          <p style={{ fontSize: 24, fontWeight: 500, color: '#616161' }}>
            {/* {t("pages.productdetail.ProductReview")} */}
            Anket
          </p>
          <hr style={{ width: '15%', color: '#EA3535', height: '2px' }} />
          <p style={{ fontSize: 20 }}>
            Görüşleriniz bizim için değerli. Sitelerimizi sizin için daha verimli ve kullanışlı hale getirebilmek için
            lütfen görüşlerinizi bizimle paylaşın.
          </p>
        </div>
        <br></br>
        <div className='row'>
          {surveyList.map((surveyItem: ISurveyItem, index) => {
            const surveyID = surveyItem.Survey.Id
            if (!surveyItem.Deleted) {
              displayedCategoriesMap.push(surveyID)
            }
            // Eğer bu SurveyID daha önce görülmediyse, SurveyCategory'yi yazdır
            if (!displayedSurveyIDs.has(surveyID)) {
              displayedSurveyIDs.add(surveyID)
              return (
                <>
                  {surveyItem.Deleted !== true && <b style={{ padding: '15px' }}><span style={{ marginTop: index != 0 ? '3%' : '', cursor: 'pointer', fontSize: '25px' }} onClick={() => handleCategoryClick(surveyItem.Survey.Id)}>

                    {(surveyItem.Survey.Id === visibleCategory) ?
                      <img
                        src='/images/icons/up-arrow.png'
                        alt=''
                        width={35}
                        style={{ filter: 'opacity(0.2)', marginRight: '10px', marginBottom: '10px' }}
                      />
                      :
                      <img
                        src='/images/icons/down-arrow.png'
                        alt=''
                        width={27}
                        style={{ filter: 'opacity(0.2)', marginRight: '10px', marginBottom: '10px' }}
                      />
                    }

                    {surveyItem.Survey.Category}</span></b>}
                  {
                    (surveyItem.Survey.Id === visibleCategory) &&
                    <div key={surveyItem.Id} className={classes.surveyItem}>
                      <div>
                        <p style={{ fontSize: 18 }}>{surveyItem.Text}</p>
                        {surveyItem.Answers.map(ans => {
                          return (
                            <div key={ans.BitwiseId} className={classes.surveyAnsItem}>
                              <div>
                                {(surveyItem.OptionType === OptionTypeEnum.Radio ||
                                  surveyItem.OptionType === OptionTypeEnum.RadioAndInput) &&
                                  ans.Deleted !== true && (
                                    <IPRadio
                                      checked={isCheckedAnswer(ans.BitwiseId, surveyItem.Id, OptionTypeEnum.Radio)}
                                      text={ans.Options}
                                      name={`${surveyItem.Id}`}
                                      disabled={false}
                                      onChange={(e: any) => {
                                        addItemToSurvey(
                                          ans.BitwiseId,
                                          surveyItem.Id,
                                          OptionTypeEnum.Radio,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  )}
                                {(surveyItem.OptionType === OptionTypeEnum.CheckBox ||
                                  surveyItem.OptionType === OptionTypeEnum.CheckboxAndInput) &&
                                  ans.Deleted !== true && (
                                    <IPCheckbox
                                      label={ans.Options}
                                      checked={isCheckedAnswer(ans.BitwiseId, surveyItem.Id, OptionTypeEnum.CheckBox)}
                                      onChange={(e: any) => {
                                        addItemToSurvey(
                                          ans.BitwiseId,
                                          surveyItem.Id,
                                          OptionTypeEnum.CheckBox,
                                          e.target.checked
                                        )
                                      }}
                                    />
                                  )}
                              </div>
                            </div>
                          )
                        })}
                        {(surveyItem.OptionType === OptionTypeEnum.Input ||
                          surveyItem.OptionType === OptionTypeEnum.RadioAndInput ||
                          surveyItem.OptionType === OptionTypeEnum.CheckboxAndInput) && (
                            <div>
                              <IPInput
                                type={'text'}
                                onChange={(e: any) => {
                                  addItemToSurvey(0, surveyItem.Id, surveyItem.OptionType, false, e.target.value)
                                }}
                              />
                            </div>
                          )}
                      </div>
                    </div>

                  }
                </>
              )
            } else {
              // Daha önce görülen SurveyID ise, sadece anket öğesini döndür
              return (
                (surveyItem.Survey.Id === visibleCategory) &&
                <div key={surveyItem.Id} className={classes.surveyItem}>
                  <div>
                    <p style={{ fontSize: 18 }}>{surveyItem.Text}</p>
                    {surveyItem.Answers.map(ans => {
                      return (
                        <div key={ans.BitwiseId} className={classes.surveyAnsItem}>
                          <div>
                            {(surveyItem.OptionType === OptionTypeEnum.Radio ||
                              surveyItem.OptionType === OptionTypeEnum.RadioAndInput) &&
                              ans.Deleted !== true && (
                                <IPRadio
                                  checked={isCheckedAnswer(ans.BitwiseId, surveyItem.Id, OptionTypeEnum.Radio)}
                                  text={ans.Options}
                                  name={`${surveyItem.Id}`}
                                  disabled={false}
                                  onChange={(e: any) => {
                                    addItemToSurvey(
                                      ans.BitwiseId,
                                      surveyItem.Id,
                                      OptionTypeEnum.Radio,
                                      e.target.checked
                                    )
                                  }}
                                />
                              )}
                            {(surveyItem.OptionType === OptionTypeEnum.CheckBox ||
                              surveyItem.OptionType === OptionTypeEnum.CheckboxAndInput) &&
                              ans.Deleted !== true && (
                                <IPCheckbox
                                  label={ans.Options}
                                  checked={isCheckedAnswer(ans.BitwiseId, surveyItem.Id, OptionTypeEnum.CheckBox)}
                                  onChange={(e: any) => {
                                    addItemToSurvey(
                                      ans.BitwiseId,
                                      surveyItem.Id,
                                      OptionTypeEnum.CheckBox,
                                      e.target.checked
                                    )
                                  }}
                                />
                              )}
                          </div>
                        </div>
                      )
                    })}
                    {(surveyItem.OptionType === OptionTypeEnum.Input ||
                      surveyItem.OptionType === OptionTypeEnum.RadioAndInput ||
                      surveyItem.OptionType === OptionTypeEnum.CheckboxAndInput) && (
                        <div>
                          <IPInput
                            type={'text'}
                            onChange={(e: any) => {
                              addItemToSurvey(0, surveyItem.Id, surveyItem.OptionType, false, e.target.value)
                            }}
                          />
                        </div>
                      )}
                  </div>
                </div>
              )
            }
          })}

          {surveyList.length > 0 && visibleCategory != null && (
            <div className='d-flex justify-content-end'>
              <Button onClick={handleSurveyToApi} className='w-25' text='Gönder' />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
