import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IDateRange, TrafficSourceResponse } from '../models/models'

export class TrafficSourcesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GA4Reporting/SessionSource',
      requesterInfo,
    })
  }

  public async trafficSources(request: IDateRange): Promise<TrafficSourceResponse[]> {
    return await this.postAsync<TrafficSourceResponse[]>(request)
  }
}
