import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IProduct } from '../models/response/getProductListResponseModel'
import { Feature, MenuCategory, SearchMenuCatAndCounts } from '../models/searchModel'

export interface ListByRequest {
  menuId: number
  companies: string[]
  take: number
  skip: number
  FeatureList?: {
    FeatureId: number
    FeatureValue: string
  }[]
  GroupIds?: number[]
  HasStock: boolean
  orderString: string
  orderType: null | 'asc' | 'desc'
}

export interface ListByResponse {
  products: IProduct[]
  features: Feature[]
  MenuCategories: MenuCategory[]
  SearchMenuCatAndCounts: SearchMenuCatAndCounts[]
}

export class GetProductListByCategoryIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/listBy2',
      requesterInfo,
    })
  }

  public async getProductsList(req: ListByRequest): Promise<ListByResponse> {
    return await this.postAsync<ListByResponse>(req)
  }
}
