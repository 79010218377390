import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { SearchResponse } from '../models/searchModel'

// Response types.
// IProduct | IProductSearchResponse

export interface SearchRequest {
  searchKey: string
  Categories: string[]
  Companies: string[]
  Result: number
  FeatureList?: FeatureList[]
  GroupIds?: number[]
  Take: number
  skip: number
  MenuTitle: string
  HasStock: boolean
  orderString: string
  orderType: null | 'asc' | 'desc'
}

export interface FeatureList {
  FeatureId: number
  FeatureValue: string
}

export class SearchProductsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'search/productV2',
      requesterInfo,
    })
  }

  public async searchProductsByCategoriesAndCompanies(req: SearchRequest): Promise<SearchResponse> {
    return await this.postAsync<SearchResponse>(req)
  }
}
