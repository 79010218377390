import MainLayout from '../pages/FinancialInformation/FinancialInformationLayout'
import { withLogin } from './withLoginHOC'
import ClientLayout from '../layouts/ClientLayout'
const withFinancialInformationLayout = (props: any) => {
  return (
    <>
      <ClientLayout>
        <MainLayout>{props.children}</MainLayout>
      </ClientLayout>
    </>
  )
}

export default withLogin(withFinancialInformationLayout)
