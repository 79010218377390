import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IFrequentlyAskedQuestions, AddFrequentlyAskedQuestions } from '../models/models'

export class PostFrequentlyAskedQuestionsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/ssh/questionAndAnswerAdd',
      requesterInfo,
    })
  }

  public async postFrequentlyAskedQuestions(request: any): Promise<IFrequentlyAskedQuestions> {
    return await this.postAsync<IFrequentlyAskedQuestions>(request)
  }
}
