import React from 'react'
import { useCustomReducer } from '../../base/customHooks'
import IPButton from '../../components/IPButton'
import BankAccountNumbers from './components/BankAccountNumbers'
import CreditCardCommissionRates2 from './components/CreditCardComissionRates'
import Exchange from './components/Exchange'
import FinancialDocuments from './components/FinancialDocuments'
import FrequentlyAskedQuestions from './components/FrequentlyAskedQuestions'
import { css } from '@emotion/css'

interface IFinancialInformationLayoutState {
  tabName: string
}

// export const financialButtonStyles = css`
//   display: flex;
//   flex-wrap: wrap;
//   margin-bottom: 15px;

//   button {
//     width: auto;
//     background-color: #f5f5f5 !important;
//     color: #252525 !important;
//     padding: 1.5rem;

//     &.active {
//       background-color: #dc3545 !important;
//       color: #fff !important;
//     }
//   }

//   @media (max-width: 620px) {
//     & > *,
//     button {
//       width: 100%;
//     }
//   }
// `
export const financialButtonStyles = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .indexButton {
    color: #9b9999 !important;

    &.active {
      background-color: rgba(227, 9, 24, 1) !important;
      color: white !important;
    }
  }

  .datagateButton {
    color: #9b9999 !important;

    &.active {
      background-color: rgba(24, 180, 220, 1) !important;
      color: white !important;
    }
  }

  .despecButton {
    color: #9b9999 !important;

    &.active {
      background-color: rgba(35, 152, 214, 1) !important;
      color: white !important;
    }
  }

  .netexButton {
    color: #9b9999 !important;

    &.active {
      background-color: rgba(16, 76, 140, 1) !important;
      color: white !important;
    }
  }

  button {
    width: auto;
    background-color: #f5f5f5 !important;
    color: #9b9999 !important;
    padding: 1.5rem;

    &.active {
      background-color: #dc3545 !important;
      color: #fff !important;
    }
  }

  @media (max-width: 620px) {
    & > *,
    button {
      width: 100%;
    }
  }
`

function FinancialInformationLayout(props: any) {
  const getInitialState = (): IFinancialInformationLayoutState => {
    return {
      tabName: 'BankAccountNumbers',
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IFinancialInformationLayoutState>(intialState)

  return (
    <div className='container current-account'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header' style={{ padding: '2.8rem 1rem 0.1rem 1.25rem' }}>
            <div className={financialButtonStyles}>
              <IPButton
                name='order'
                text='Banka Hesap Numaralarımız'
                className={`${state.tabName === 'BankAccountNumbers' ? 'active' : ''}`}
                onClick={() => setState({ tabName: 'BankAccountNumbers' })}
              />
              <IPButton
                className={`${state.tabName === 'CreditCardCommissionRates' ? 'active' : ''}`}
                name='company'
                onClick={() => setState({ tabName: 'CreditCardCommissionRates' })}
                text='Kredi Kartı Komisyon Oranları'
              />
              <IPButton
                className={`${state.tabName === 'Exchange' ? 'active' : ''}`}
                name='company'
                onClick={() => setState({ tabName: 'Exchange' })}
                text='Döviz Kurları'
              />
              <IPButton
                className={`${state.tabName === 'FinancialDocuments' ? 'active' : ''}`}
                name='company'
                onClick={() => setState({ tabName: 'FinancialDocuments' })}
                text='Finansal Evraklar'
              />
              <IPButton
                className={`${state.tabName === 'FrequentlyAskedQuestions' ? 'active' : ''}`}
                name='company'
                onClick={() => setState({ tabName: 'FrequentlyAskedQuestions' })}
                text='Sıkça Sorulan Sorular'
              />
            </div>
          </div>
          <div className='card-body'>
            {state.tabName === 'BankAccountNumbers' && <BankAccountNumbers />}
            {state.tabName === 'CreditCardCommissionRates' && <CreditCardCommissionRates2 />}
            {state.tabName === 'Exchange' && <Exchange />}
            {state.tabName === 'FinancialDocuments' && <FinancialDocuments />}
            {state.tabName === 'FrequentlyAskedQuestions' && <FrequentlyAskedQuestions />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FinancialInformationLayout
