/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import { IHomePageProducts } from '../models/requestModels'
import { GetHomePageProductsListApi } from '../services/getHomePageListApi'
import { DeleteHomePageProductsApi } from '../services/deleteHomePageProductsApi'
import { UpdateHomePageProductsApi } from '../services/updateHomePageProductsApi'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import * as Switch from '@radix-ui/react-switch'
import Table from '../../../../../components/Table'

interface ProductListState {
  searchCompanies: ISelectOption[]
  selectedCompany?: ISelectOption
}

export default function HomePageProductsList() {
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [products, setProducts] = useState<IHomePageProducts[]>([])
  const navigate: any = useNavigate()

  const getInitialState = (): ProductListState => {
    return {
      searchCompanies: [],
      selectedCompany: undefined,
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ProductListState>(intialState)

  useEffect(() => {
    getCompanies()
    getHomePageProductsList()
  }, [])

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()

    if (!company) return

    setCompanies(company)
    setState({ searchCompanies: company.map(x => ({ value: x.Id, label: x.Name })) })
  }

  const getHomePageProductsList = async () => {
    const getHomePageProductsListApi: GetHomePageProductsListApi = new GetHomePageProductsListApi({})
    await getHomePageProductsListApi
      .getHomePageProducts()
      .then(async result => {
        if (result) {
          if (state.selectedCompany) {
            const a = result
              .filter(x => +x.CompanyId === state.selectedCompany?.value)
              .sort((a, b) => Number(a.CompanyId) - Number(b.CompanyId))

            setProducts(a)
          }
          //setProducts(result.sort((a, b) => Number(a.CompanyId) - Number(b.CompanyId)))
          else setProducts(result)
          // props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        //props.hideLoading()
      })
    // if (res) return setProducts(res)
  }

  const location = [
    // {
    //   value: 1,
    //   label: "En Favoriler",
    // },
    {
      value: 2,
      label: 'Kampanyalı Ürünler',
    },
    // {
    //   value: 3,
    //   label: "Benzer Ürünler",
    // },
    {
      value: 4,
      label: 'En Yeniler',
    },
  ]

  const deleteProduct = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Ürünü silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteHomePageProducts = new DeleteHomePageProductsApi({})
        deleteHomePageProducts
          .deleteHomePageProducts(id)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Ürün Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async result => {
                const deleted = products.filter(x => x.Id !== id)
                setProducts(deleted)
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      }
    })
  }

  const updateProduct = async (id: number) => {
    var data = products.find(auth => {
      return auth.Id === id
    })
    navigate('/admin/homeAndSiteOperation/HomePageProducts', {
      state: { homepageProductData: data },
    })
  }
  const handleProductActive = async (row: any) => {
    const data: any = products.map(pState => {
      if (pState.Id === row.Id) {
        pState.Enabled = !pState.Enabled
      }

      return pState
    })

    const updateSwitch = new UpdateHomePageProductsApi({})
    const trueRes = await updateSwitch.putHomePageProducts(data)
    if (trueRes) {
      Swal.fire({
        icon: 'success',
        title: `Ürün ${data.Enabled ? 'aktif' : 'pasif'} edilmiştir.`,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((res: any) => { })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Aktif',
        accessor: 'Enabled',
      },
      {
        Header: 'Konum',
        accessor: 'Location',
      },
      {
        Header: 'Sıra',
        accessor: 'Order',
      },
      {
        Header: 'Şirket',
        accessor: 'Company',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'ProductCode',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'Start',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'End',
      },
      { Header: 'İşlemler', accessor: 'Actions' },
    ],
    []
  )

  const data = useMemo(() => {
    return products.map(x => {
      const company = x.CompanyId.split(',')
      let idList: String[] = []
      company &&
        companies &&
        companies.map(i => {
          if (company.indexOf(String(i.Id)) === 0) return idList.push(i.Name)
        })
      x.CompanyId &&
        companies &&
        companies.map(i => {
          if (company.indexOf(String(i.Id)) > 0) return idList.push(i.Name)
        })
      var code = idList.map(i => {
        return i
      })

      return {
        Enabled: (
          <Switch.Root
            className='SwitchRoot'
            id='active'
            checked={x.Enabled}
            onCheckedChange={() => handleProductActive(x)}
          >
            <Switch.Thumb className='SwitchThumb' />
          </Switch.Root>
        ),
        Location: x.Location === 2 ? 'Kampanyalı Ürünler' : x.Location === 4 ? 'En Yeniler' : '',
        Order: x.Order,
        Company: idList,
        ProductCode: x.ProductCode,
        Start: new Date(x.StartDate).toLocaleDateString('tr-TR'),
        End: new Date(x.EndDate).toLocaleDateString('tr-TR'),
        Actions: (
          <div>
            <a
              data-action-type='edit'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title='Güncelle'
              onClick={() => updateProduct(x.Id)}
            >
              <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
            </a>
            <a
              data-action-type='delete'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title='Sil'
              onClick={() => deleteProduct(x.Id)}
            >
              <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
            </a>
          </div>
        ),
      }
    })
  }, [products])

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              Anasayfa Ürün Listeleme
            </span>
            <div className='button m-r-20'>
              <Link to='/admin/homeAndSiteOperation/HomePageProducts'>
                <button type='button' className='btn btn-primary'>
                  Ekle
                </button>
              </Link>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-2'>
                <label htmlFor='company' className='form-label'>
                  Şirket
                </label>
                <IPSelectBox
                  isClearable
                  id='Şirket'
                  name='Şirket'
                  options={state.searchCompanies}
                  value={state.selectedCompany}
                  onChangeSingle={option => setState({ selectedCompany: option })}
                />
              </div>
              <div className='col-lg-2'>
                <div className='d-flex align-items-bottom'>
                  <IPButton text='Göster' onClick={getHomePageProductsList} />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='table-responsive'>
                <Table columns={columns} data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
