import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
// import { IProduct } from '../models/response/getProductListResponseModel'

export class AddMostSelledExceptionProductApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/addMostSelledExceptionProduct',
      requesterInfo,
    })
  }

  public async addMostSelledExceptionProduct(productCode: string): Promise<any> {
    this.props.url = `${this.props.url}?productCode=${productCode}`

    return await this.getAsync<any>()
  }
}
