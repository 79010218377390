// UserCode: yup
//   .string()
//   .min(
//     3,
//     t("pages.admin.userOperation.userCrud.validation.userCode") +
//     " " +
//     t("pages.admin.userOperation.userCrud.validation.message", ['3'])
//   )
//   .required(t("pages.admin.userOperation.userCrud.validation.requiredField")),
// Name: yup
//   .string()
//   .min(
//     3,
//     t("pages.admin.userOperation.userCrud.validation.name") +
//     " " +
//     t("pages.admin.userOperation.userCrud.validation.message", ['3'])
//   )
//   .required(t("pages.admin.userOperation.userCrud.validation.requiredField")),
// Surname: yup
//   .string()
//   .min(
//     3,
//     t("pages.admin.userOperation.userCrud.validation.surname") +
//     " " +
//     t("pages.admin.userOperation.userCrud.validation.message", ['3'])
//   )
//   .required(t("pages.admin.userOperation.userCrud.validation.requiredField")),
// UserType: yup
//   .object()
//   .shape({
//     label: yup.string(),
//     value: yup.string(),
//   })
//   .required(t("pages.admin.userOperation.userCrud.validation.requiredField")),
//IsSendMail: yup.boolean(),
// AuthorityLevel: yup.string(),
// Status: yup
//   .object()
//   .shape({
//     label: yup.string(),
//     value: yup.string(),
//   })
//   .required(t("pages.admin.userOperation.userCrud.validation.requiredField")),
// Companies: yup.array().of(
//   yup.object().shape({
//     label: yup.string(),
//     value: yup.string(),
//   })
// ),
// Authorities: yup.array().of(
//   yup.object().shape({
//     label: yup.string(),
//     value: yup.string(),
//   })
// ),
// Notes: yup
//   .string()
//   .min(
//     250, t("pages.admin.userOperation.userCrud.validation.message", ["250"])
//   )
//   .required(t("pages.admin.userOperation.userCrud.validation.requiredField")),
// UserName: yup
//   .string()
//   .min(
//     3,
//     t("pages.admin.userOperation.userCrud.validation.userName") +
//     " " +
//     t("pages.admin.userOperation.userCrud.validation.message", ["3"])
//   )
//   .required(t("pages.admin.userOperation.userCrud.validation.requiredField")),
// PasswordHash: yup
//   .string()
//   .min(6, t("pages.admin.userOperation.userCrud.validation.password"))
//   .required(t("pages.admin.userOperation.userCrud.validation.requiredField")),
// PasswordConfirm: yup
//   .string()
//   .required()
//   .oneOf(
//     [yup.ref("PasswordHash"), null],
//     t("pages.admin.userOperation.userCrud.validation.passwordConfirm")
//   ),
// file: yup
//   .string()
//   .required(t('pages.admin.userOperation.userCrud.validation.file'))
// // .test(
//   "fileSize",
//   "File too large",
//   value => value && value.size < FILE_SIZE
// )
// .test(
//   "fileFormat",
//   "Unsupported Format",
//   value => value && SUPPORTED_FORMATS.includes(value.type)
// )

import * as yup from 'yup'

const validations = yup.object().shape({
  MobilePhone: yup
    .string()
    .test('len', 'Telefon numarası 10 karakter olmalı!', val => {
      const a = val
        ?.replaceAll('_', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(' ', '')
        .replaceAll(')', '').length
      return a === 10
    })
    .nullable(),
  UserName: yup
    .string()
    .min(3, 'Kullanıcı adı en az 3 karakter olmalıdır!')
    .required('Kullanıcı adı alanı zorunludur.'),
  Email: yup
    .string()
    .email('Geçerli bir email adresi girmelisiniz!')
    .nullable(),
})

export default validations
