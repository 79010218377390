import React, { useMemo, useState } from 'react'
import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import Swal from 'sweetalert2'
import { DealerDetailForPagePathRequest, DealerDetailForPagePathResponse, PathOptions } from './models'
import { SiteNavigationService } from './services'
import { css } from '@emotion/css'
import Table from '../../../../components/Table'
import { Spinner } from '../../../../components/Spinner'
import IPCheckbox from '../../../../components/IPCheckBox'
import InputCampaign from '../../../../components/InputCampaign'
import moment from 'moment'
import { exportToXLSX } from '../../../../base/utils/dataHelper'

function StatisticsList() {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [filteredStartDate, setfilteredStartDate] = useState(new Date())
  const [filteredEndDate, setfilteredEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const [loading, setLoading] = useState(false)
  const [pathOptions, setPathOptions] = useState<PathOptions>({
    DealerCode: '',
    StartDate: startDate,
    EndDate: endDate,
    TotalShow: false,
    IncludeDenemeCard: false,
  })
  const [pathOptions2, setPathOptions2] = useState<PathOptions>({
    DealerCode: '',
    StartDate: startDate,
    EndDate: endDate,
    TotalShow: true,
    IncludeDenemeCard: false,
  })
  const [paths, setPaths] = useState<DealerDetailForPagePathResponse[]>([])
  const [total, setTotal] = useState(0)

  const t = translate(Translations)

  // function updatePathOptions(prop, val) {
  //   setPathOptions(prev => ({ ...prev, [prop]: val }))
  // }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000).toISOString()

  // const dateValid = () => {
  //   const result = pathOptions.EndDate.getTime() - pathOptions.StartDate.getTime()
  //   if (result < 60000) {
  //     Swal.fire('Bitiş tarihi başlangıç tarihinden önce olamaz.')
  //     updatePathOptions('EndDate', toDateTimeLocal(new Date()))
  //   }
  // }

  async function handleSubmit(e) {
    e.preventDefault()
    if (new Date(endDate) < new Date(startDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Başlangıç bitiş tarihinden sonra olamaz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    setLoading(true)

    const req: DealerDetailForPagePathRequest = {
      DateRange: {
        StartDate: dateToISO(startDate),
        EndDate: dateToISO(endDate),
      },
      DealerCode: pathOptions.DealerCode.length > 0 ? pathOptions.DealerCode : '',
      IncludeDenemeCard: pathOptions.IncludeDenemeCard,
    }
    try {
      const res = await new SiteNavigationService({}).DealerDetailForPagePath(req)
      if (res) {
        setPaths(res)
        setTotal(res.length)
        setPathOptions2({
          StartDate: startDate,
          EndDate: endDate,
          DealerCode: pathOptions.DealerCode,
          TotalShow: pathOptions.TotalShow,
          IncludeDenemeCard: pathOptions.IncludeDenemeCard,
        })
        setfilteredEndDate(endDate)
        setfilteredStartDate(startDate)
        setLoading(false)
      }
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error').then(() => {
        setLoading(false)
      })
    }
  }

  const handleDetail = (data, dealer) => {
    let rows = ''

    if (!data) {
      Swal.fire({
        title: 'Hata',
        icon: 'error',
        html: `<div>
        <div style="display: flex; justify-content: center; margin-bottom: 1rem; font-size: 16px;">
          <b>Veri bulunamadı.</b>
        </div>
      </div>`,
        backdrop: true,
        confirmButtonText: 'KAPAT',
        confirmButtonColor: '#333',
      })
      return
    }

    data.length > 0 &&
      data.forEach(x => {
        rows += `<div style="display: flex; margin-bottom: 0.5rem;">
          <div style="width: 150px; text-align: left;">${new Intl.DateTimeFormat('tr-TR', {
            dateStyle: 'short',
            timeStyle: 'short',
          }).format(new Date(x.Time))}</div>
          <div>${x.Path}</div>
        </div>`
      })

    Swal.fire({
      title: 'Detay',
      icon: 'info',
      width: 'auto',
      html: `<div>
        <b style="display: block; margin-bottom: 1.5rem; font-size: 18px;">${dealer}</b>
        <div style="display: flex; margin-bottom: 1rem; font-size: 16px;">
          <b style="width: 150px; text-align: left;">Ziyaret Zamanı</b>
          <b>Ziyaret Ettiği Sayfalar</b>
        </div>
        ${rows}
      </div>`,
      backdrop: true,
      confirmButtonText: 'KAPAT',
      confirmButtonColor: '#333',
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        accessor: 'DetailsModel',
        width: 50,
      },
      {
        Header: 'Bayi Tipi',
        accessor: 'DealerTypes',
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'DealerCode',
      },
      {
        Header: 'Kullanıcı Kodu',
        accessor: 'UserCode',
      },
      {
        Header: 'Hesap Adı',
        accessor: 'DealerName',
        width: 380,
      },
      {
        Header: 'İlk Erişim',
        accessor: 'StartDate',
        width: 200,
      },
      {
        Header: 'Son Erişim',
        accessor: 'LastDate',
      },
      {
        Header: 'Oturum Süresi',
        accessor: 'TimeValue',
        width: 250,
      },
      // {
      //   Header: 'Bitiş',
      //   accessor: 'EndDate',
      // },
    ],
    []
  )

  const data = useMemo(
    () =>
      paths.map(p => {
        return {
          DetailsModel: (
            <div className='d-flex justify-content-center align-items-center h-100'>
              <i className='icon-info cursor-pointer' onClick={() => handleDetail(p.DetailsModel, p.DealerName)}></i>
            </div>
          ),
          DealerTypes:
            p.DealerTypes &&
            p.DealerTypes.length > 0 &&
            p.DealerTypes.map((x, i) => {
              if (p.DealerTypes.length - 1 === i) return x
              return x + ', '
            }),
          DealerCode: p.DealerCode,
          UserCode: p.UserCode,
          DealerName: p.DealerName,
          StartDate: new Intl.DateTimeFormat('tr-TR', { dateStyle: 'short', timeStyle: 'short' }).format(
            new Date(p.StartDate)
          ),
          LastDate: new Intl.DateTimeFormat('tr-TR', { dateStyle: 'short', timeStyle: 'short' }).format(
            new Date(p.EndDate)
          ),
          TimeValue: p.TimeValue,
          // EndDate: new Intl.DateTimeFormat('tr-TR', { dateStyle: 'short', timeStyle: 'short' }).format(
          //   new Date(p.EndDate)
          // ),
        }
      }),
    [paths]
  )

  const excelButtonWithDetail = async () => {
    try {
      if (paths) {
        const combinedData: any[] = []

        if (paths.length > 0) {
          for (let index = 0; index < paths.length; index++) {
            const p = paths[index]
            const modalData = p.DetailsModel

            let DealerType = ''
            p.DealerTypes.map(dealer => {
              if (DealerType.length > 0) return (DealerType += ', ' + dealer)
              return (DealerType += dealer)
            })

            combinedData.push({
              Sıra: index + 1,
              'Bayi Tipi': DealerType,
              'Hesap Kodu': p.DealerCode,
              'Kullanıcı Kodu': p.UserCode,
              'Hesap Adı': p.DealerName,
              Başlangıç: new Intl.DateTimeFormat('tr-TR', { dateStyle: 'short', timeStyle: 'short' }).format(
                new Date(p.StartDate)
              ),
              'Son Erişim': new Intl.DateTimeFormat('tr-TR', { dateStyle: 'short', timeStyle: 'short' }).format(
                new Date(p.EndDate)
              ),
              Süre: p.TimeValue,
            })

            if (modalData) {
              for (let i = 0; i < modalData.length; i++) {
                const modalRow = modalData[i]

                combinedData.push({
                  Sıra: index + 1,
                  'Bayi Tipi': '',
                  'Hesap Kodu': '',
                  'Kullanıcı Kodu': '',
                  'Hesap Adı': '',
                  'İlk Erişim': '',
                  'Son Erişim': '',
                  Süre: '',
                  'Detay Sıra': i + 1,
                  'Ziyaret Zamanı': moment(modalRow.Time).format('DD.MM.YYYY hh:mm:ss'),
                  'Ziyaret Ettiği Sayfalar': modalRow.Path,
                })
              }
            }
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(
          combinedData,
          moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY') +
            '-' +
            moment(toDateTimeLocal(endDate)).format('DD.MM.YYYY') +
            'Detaylı Site Gezinme İstatistikleri-1'
        )
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const excelButton = async () => {
    try {
      if (paths) {
        const data: any[] = []
        if (paths.length > 0) {
          paths.map((p, index) => {
            return data.push({
              Sıra: index + 1,
              'Hesap Kodu': p.DealerCode,
              'Kullanıcı Kodu': p.UserCode,
              'Hesap Adı': p.DealerName,
              Başlangıç: new Intl.DateTimeFormat('tr-TR', { dateStyle: 'short', timeStyle: 'short' }).format(
                new Date(p.StartDate)
              ),
              'Son Erişim': new Intl.DateTimeFormat('tr-TR', { dateStyle: 'short', timeStyle: 'short' }).format(
                new Date(p.EndDate)
              ),
              Süre: p.TimeValue,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(
          data,
          moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY') +
            '-' +
            moment(toDateTimeLocal(endDate)).format('DD.MM.YYYY') +
            ' Site Gezinme İstatistikleri-1'
        )
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  return (
    <>
      <div className='container'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header d-flex justify-content-between align-items-center'>
              <span className='h4 mb-0'>Site Gezinme İstatistikleri-1</span>
              <div className='d-flex align-items-center'>
                <span>Excel: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButton}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
                <span>Detaylı Excel: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButtonWithDetail}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
              </div>
            </div>
            <div className='card-body'>
              <form
                className={css`
                  display: flex;
                  align-items: center;
                  gap: 1rem;
                  flex-wrap: wrap;
                `}
                onSubmit={handleSubmit}
              >
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.startDate')}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, 'Start')}
                  isActive
                />
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.endDate')}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, 'End')}
                  // onBlur={() => dateValid()}
                  isActive
                />

                <InputCampaign
                  id='dealerCode'
                  label={
                    'Bayi Kodu (' +
                    t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.all') +
                    ')'
                  }
                  style={{ width: 240 }}
                  value={pathOptions.DealerCode}
                  onChange={e => setPathOptions(prev => ({ ...prev, DealerCode: e.target.value }))}
                  isActive={pathOptions.DealerCode.length > 0}
                />
                <IPCheckbox
                  label='Deneme Kartı Dahil'
                  onChange={e =>
                    setPathOptions(prev => ({ ...prev, IncludeDenemeCard: !pathOptions.IncludeDenemeCard }))
                  }
                  checked={pathOptions.IncludeDenemeCard}
                />
                <IPCheckbox
                  label='Toplam Göster'
                  onChange={() => setPathOptions(prev => ({ ...prev, TotalShow: !pathOptions.TotalShow }))}
                  checked={pathOptions.TotalShow}
                />
                <button
                  type='submit'
                  className={`btn btn-success d-block ${css`
                    height: 50px;
                  `}`}
                >
                  {t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.show')}
                </button>
              </form>
              <br />
              <div className='col-lg-12'>
                {!pathOptions2.TotalShow && (
                  <Table
                    columns={columns}
                    data={data}
                    headerName={`${moment(toDateTimeLocal(filteredStartDate)).format('DD.MM.YYYY')} - ${moment(
                      toDateTimeLocal(filteredEndDate)
                    ).format('DD.MM.YYYY')} tarihleri arasında siteyi gezen(ler)`}
                    columnCount={9}
                    textAlign={true}
                    backgroundColor='gray'
                    color='white'
                  />
                )}
                {/* {pathOptions2.TotalShow && total === 0 && (
                  <div
                    style={{
                      backgroundColor: 'gray',
                      color: 'white',
                      textAlign: 'center',
                      paddingLeft: '50px',
                      paddingRight: '50px',
                    }}
                  >{`${pathOptions2.StartDate.toLocaleDateString()} - ${pathOptions2.EndDate.toLocaleDateString()} tarihleri arasında siteyi gezen bulunmamaktadır.`}</div>
                )} */}

                {pathOptions2.TotalShow && total > 0 && (
                  <table>
                    <thead>
                      <tr>
                        <th
                          style={{
                            backgroundColor: 'gray',
                            color: 'white',
                            textAlign: 'center',
                            paddingLeft: '50px',
                            paddingRight: '50px',
                          }}
                        >{`${moment(toDateTimeLocal(filteredStartDate)).format('DD.MM.YYYY')} - ${moment(
                          toDateTimeLocal(filteredEndDate)
                        ).format('DD.MM.YYYY')} tarihleri arasında siteyi gezen(ler)`}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: 'center' }}>{total}</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Spinner loading={loading} />
    </>
  )
}

export default StatisticsList
