import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ICategory } from '../models/models'

export class GetCategoryListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/getCategoryList',
      requesterInfo,
    })
  }

  public async getCategoryList(): Promise<ICategory[]> {
    return await this.getAsync<ICategory[]>()
  }
}
