import { RefObject, useEffect, useState } from 'react'

const useClickOutsideEvent = (ref: RefObject<any>) => {
  const [isClickedOutside, setClickedOutside] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickedOutside(true)
      } else {
        setClickedOutside(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])

  return [isClickedOutside]
}

export { useClickOutsideEvent }
