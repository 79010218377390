import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export class getSssFinancialApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'page/sssFinancial',
      requesterInfo,
    })
  }

  public async getSssFinancial(): Promise<boolean> {
    return await this.getAsync<boolean>()
  }
}
