import { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { translate } from '../../../../base/localization'
import InputCampaign from '../../../../components/InputCampaign'
import IPButton from '../../../../components/IPButton'
import IPInput from '../../../../components/IPInput'
import IPSelectBox from '../../../../components/IPSelectBox'
import Table from '../../../../components/Table'
import Translations from '../../../../translations'
import { GetBrandListApi } from '../../../Homepage/services/getBrandListApi'
import { DealerForPagePathRequest, DealerForPagePathResponse, PathOptions } from './models'
import { PageAnalysisService } from './services'
import { GetMenusApi } from '../../../../layouts/components/client/services/getMenusApi'
import { exportToXLSX } from '../../../../base/utils/dataHelper'

interface IMenu {
  Link: string
  Id: number
  ParentId: number
  Enabled: boolean
  CompanyId: number
  CategoryId: number
  Title: string
  Order: number
  Items: IMenu[]
  isOpen?: boolean
}

function Index(props: BaseComponentWithContextProps) {
  const [pages, setPages] = useState<DealerForPagePathResponse[]>([])
  const [selectedPageFilter, setSelectedPageFilter] = useState<any>()
  const [selectedBrand, setSelectedBrand] = useState<any>()
  const [brands, setBrands] = useState<any>([])
  const [filteredList, setFilteredList] = useState<DealerForPagePathResponse[]>([])
  const [showFilterRow, setShowFilterRow] = useState(false)
  const [searchWord, setSearchWord] = useState('')

  const [menus, setMenus] = useState<IMenu[]>([])
  //Kategori Seviyeleri
  const [level1Options, setLevel1Options] = useState<any>([])
  const [level2Options, setLevel2Options] = useState<any>([])
  const [level3Options, setLevel3Options] = useState<any>([])
  const [level4Options, setLevel4Options] = useState<any>([])
  const [selectedLevel1, setSelectedLevel1] = useState<any>()
  const [selectedLevel2, setSelectedLevel2] = useState<any>()
  const [selectedLevel3, setSelectedLevel3] = useState<any>()
  const [selectedLevel4, setSelectedLevel4] = useState<any>()

  //#region Tarih ve saat ayarları
  const t = translate(Translations)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000).toISOString()
  //#endregion

  const [pathOptions, setPathOptions] = useState<PathOptions>({
    StartDate: startDate,
    EndDate: endDate,
  })
  function updatePathOptions(prop, val) {
    setPathOptions(prev => ({ ...prev, [prop]: val }))
  }

  //#region Get data Methods
  async function DealerForPagePath(e) {
    e.preventDefault()
    const req: DealerForPagePathRequest = {
      DateRange: {
        StartDate: dateToISO(pathOptions.StartDate),
        EndDate: dateToISO(pathOptions.EndDate),
      },
    }
    props.showLoading()
    await new PageAnalysisService({})
      .DealerForPagePath(req)
      .then(async result => {
        if (result) {
          setPages(result)
          setFilteredList(result)
          setShowFilterRow(true)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const handleDetail = data => {
    let rows = ''
    if (!data) {
      Swal.fire({
        title: 'Hata',
        icon: 'error',
        html: `<div>
        <div style="display: flex; justify-content: center; margin-bottom: 1rem; font-size: 16px;">
          <b>Veri bulunamadı.</b>
        </div>
      </div>`,
        backdrop: true,
        confirmButtonText: 'KAPAT',
        confirmButtonColor: '#333',
      })
      return
    }
    // data = data.sort((a, b) => Number(b.Count) - Number(a.Count))
    // const newData = data
    // let kontrol = false;
    // let sayi = 0;
    // let values = [""];

    // data.length > 0 &&
    //   data.forEach(x => {
    //     newData.forEach((a => {
    //       if (a.DealerCode === x.DealerCode) {
    //         sayi++
    //         if (sayi > 1 && values.find(element => element === x.DealerCode))
    //           kontrol = true;
    //     } }))

    //       if (!kontrol) {
    //         rows += `<div style="display: flex; margin-bottom: 0.5rem;">
    //        <div style="width: 150px; text-align: left;">${x.Count}</div>
    //        <div>${x.DealerCode}</div>
    //       </div>`
    //         values.push(x.DealerCode)
    //       }
    //     kontrol = false;
    //     sayi = 0;
    //     }
    //   )

    data = data.sort((a, b) => Number(b.Count) - Number(a.Count))
    const uniqueData = new Set()
    const counter = {}
    const filteredData = data.filter(item => {
      if (uniqueData.has(item.DealerCode)) {
        counter[item.DealerCode]++
        return false
      }
      counter[item.DealerCode] = 1
      return uniqueData.add(item.DealerCode)
    })

    filteredData.length > 0 &&
      filteredData.forEach(x => {
        rows += `<div style="display: flex; margin-bottom: 0.5rem;">
          <div style="width:150px; text-align: left;">${x.Count}</div>
          <div style="width:200px; text-align: left;">${x.DealerCode}</div>
          <div style="width:150px; text-align: center;">${counter[x.DealerCode]}</div>
        </div>`
      })

    Swal.fire({
      title: 'Detay',
      icon: 'info',
      width: 'auto',
      html: `<div>
        <div style="display: flex; margin-bottom: 1rem; font-size: 16px;">
          <b style="width:150px; text-align: left;">Ziyaret Sayısı</b>
          <b style="width:200px; text-align: left;">Ziyaret Eden Bayiler</b>
          <b style="width:150px; text-align: right;">Kullanıcı Sayısı</b>
        </div>
        ${rows}
      </div>`,
      backdrop: true,
      confirmButtonText: 'KAPAT',
      confirmButtonColor: '#333',
    })
  }

  const getMenus = () => {
    const api = new GetMenusApi({})
    api.getMenus().then((res: IMenu[]) => {
      const formattedMenus = res.map(item => ({
        ...item,
        isOpen: false,
        Items: item.Items.map(subItem => ({
          ...subItem,
          isOpen: false,
          Items: subItem.Items.map(subItem2 => ({
            ...subItem2,
            isOpen: false,
            Items: subItem2.Items.map(subItem3 => ({
              ...subItem3,
              isOpen: false,
              Items: subItem3.Items.map(subItem4 => ({
                ...subItem4,
                isOpen: false,
              })),
            })),
          })),
        })),
      }))
      setMenus(formattedMenus)
      setLevel1Options(formattedMenus.map(item => ({ value: item.Id, label: item.Title })))
    })
  }

  const getBrands = async () => {
    const getBrands = new GetBrandListApi({})
    const brand = await getBrands.getBrandList()
    const formattedBrands = brand.map(b => ({ value: b.Id, label: b.Name }))
    setBrands(formattedBrands)
  }
  //#endregion

  //#region Filter useEffects

  //#region Kategori useEffect'leri
  useEffect(() => {
    getMenus()
  }, [])

  useEffect(() => {
    if (selectedLevel1) {
      const level1Item = menus.find(item => item.Id === selectedLevel1.value)
      if (level1Item) {
        setLevel2Options(level1Item.Items.map(item => ({ value: item.Id, label: item.Title })))
      } else {
        setLevel2Options([])
      }
      setSelectedLevel2(null)
      setSelectedLevel3(null)
      setSelectedLevel4(null)
    } else {
      setLevel2Options([])
      setSelectedLevel2(null)
      setSelectedLevel3(null)
      setSelectedLevel4(null)
    }
  }, [selectedLevel1, menus])

  useEffect(() => {
    if (selectedLevel2) {
      const level1Item = menus.find(item => item.Id === selectedLevel1.value)
      if (level1Item) {
        const level2Item = level1Item.Items.find(item => item.Id === selectedLevel2.value)
        if (level2Item) {
          setLevel3Options(level2Item.Items.map(item => ({ value: item.Id, label: item.Title })))
        } else {
          setLevel3Options([])
        }
      } else {
        setLevel3Options([])
      }
      setSelectedLevel3(null)
      setSelectedLevel4(null)
    } else {
      setLevel3Options([])
      setSelectedLevel3(null)
      setSelectedLevel4(null)
    }
  }, [selectedLevel2, selectedLevel1, menus])

  useEffect(() => {
    if (selectedLevel3) {
      const level1Item = menus.find(item => item.Id === selectedLevel1.value)
      if (level1Item) {
        const level2Item = level1Item.Items.find(item => item.Id === selectedLevel2.value)
        if (level2Item) {
          const level3Item = level2Item.Items.find(item => item.Id === selectedLevel3.value)
          if (level3Item) {
            setLevel4Options(level3Item.Items.map(item => ({ value: item.Id, label: item.Title })))
          } else {
            setLevel4Options([])
          }
        } else {
          setLevel4Options([])
        }
      } else {
        setLevel4Options([])
      }
      setSelectedLevel4(null)
    } else {
      setLevel4Options([])
      setSelectedLevel4(null)
    }
  }, [selectedLevel3, selectedLevel2, selectedLevel1, menus])
  //#endregion

  useEffect(() => {
    if (!brands.length) {
      getBrands()
    }
  }, [brands])

  // Clear other filters when one of the level filters is selected
  useEffect(() => {
    if (selectedLevel1 || selectedLevel2 || selectedLevel3 || selectedLevel4) {
      setSearchWord('')
      setSelectedPageFilter(null)
      setSelectedBrand(null)
    }
  }, [selectedLevel1, selectedLevel2, selectedLevel3, selectedLevel4])

  // Clear level filters when another filter is selected
  useEffect(() => {
    if (selectedPageFilter) {
      setSearchWord('')
      setSelectedBrand(null)
      setSelectedLevel1(null)
      setSelectedLevel2(null)
      setSelectedLevel3(null)
      setSelectedLevel4(null)
    }
  }, [selectedPageFilter])
  useEffect(() => {
    if (selectedBrand) {
      setSearchWord('')
      setSelectedPageFilter(null)
      setSelectedLevel1(null)
      setSelectedLevel2(null)
      setSelectedLevel3(null)
      setSelectedLevel4(null)
    }
  }, [selectedBrand])

  useEffect(() => {
    if (searchWord) {
      setSelectedPageFilter(null)
      setSelectedBrand(null)
      setSelectedLevel1(null)
      setSelectedLevel2(null)
      setSelectedLevel3(null)
      setSelectedLevel4(null)
    }
  }, [searchWord])

  //#endregion

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        accessor: 'DealerDetails',
        width: 50,
      },
      {
        Header: 'Sıra',
        accessor: 'Index',
      },
      {
        Header: 'Sayfa Url',
        accessor: 'Path',
        width: 550,
      },
      {
        Header: 'Toplam Tıklanma',
        accessor: 'Total',
      },
      {
        Header: 'Kullanıcı Sayısı',
        accessor: 'TotalUserIds',
      },
      {
        Header: 'Bayi Sayısı',
        accessor: 'DealerCount',
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      filteredList.map((p, index) => {
        return {
          DealerDetails: (
            <div className='d-flex justify-content-center align-items-center h-100'>
              <i className='icon-info cursor-pointer' onClick={() => handleDetail(p.DealerDetails)}></i>
            </div>
          ),
          Index: index + 1,
          Path: p.Path,
          Total: p.Total,
          TotalUserIds: p.TotalUserIds,
          DealerCount: p.DealerCount,
        }
      }),
    [filteredList]
  )

  const mainPages = [
    { value: '/myspecial', label: 'Bana Özel' },
    { value: '/myspecial/myorders', label: 'Siparişlerim' },
    { value: '/myspecial/mypayments', label: 'Ödemelerim' },
    { value: '/mycard', label: 'Sepet' },
    { value: '/homepage', label: 'Anasayfa' },
    { value: '/oneConfig', label: 'HP Ürün Kataloğu' },
    { value: '/myspecial/creditCardRates', label: 'Ödeme Oranları' },
    { value: '/openbox', label: 'AÇBOX' },
    { value: '/dpp', label: 'DPP' },
    { value: '/microsoft', label: 'Microsoft Sayfası' },
    { value: '/sss', label: 'Sık Sorulan Sorular' },
    { value: '/products?brand=HP', label: 'HP Marka Logosu' },
    { value: '/myspecial/reports/open-invoices', label: 'Açık Faturalar' },
    { value: '/myspecial/ssh/WarrantyTracking', label: 'Garanti Takip' },
    { value: '/financial-information/bank-account-numbers', label: 'Banka Hesap Numaraları' },
    { value: '/myspecial/currentAccount/extract', label: 'Cari Hesap Özeti' },
    { value: '/myspecial/ssh/FaultTracking', label: 'Arıza Takibi' },
    { value: '/myspecial/creditCardOperations', label: 'Kredi Kartı Hareketlerim' },
    { value: '/myspecial/ssh/SerialNumberCheck', label: 'Seri No Kontrolü' },
  ]

  //#region Detaylı Süzme fonksiyonu
  const filterList = () => {
    const filterConditions: ((item: DealerForPagePathResponse) => boolean)[] = []

    if (searchWord) {
      filterConditions.push(
        x => x.Path.toUpperCase().includes(searchWord.toUpperCase()) && x.Path.includes('searchKey')
      )
    } else if (selectedPageFilter) {
      filterConditions.push(c => c.Path === selectedPageFilter.value)
    } else if (selectedBrand) {
      filterConditions.push(c => c.Path.toUpperCase().includes(selectedBrand.label.toUpperCase()))
    }

    // Hierarchical filter
    if (selectedLevel4) {
      filterConditions.push(item => item.Path === `/products?categoryId=${selectedLevel4.value}&global-search=true`)
    } else if (selectedLevel3) {
      filterConditions.push(item => item.Path === `/products?categoryId=${selectedLevel3.value}&global-search=true`)
    } else if (selectedLevel2) {
      filterConditions.push(item => item.Path === `/products?categoryId=${selectedLevel2.value}&global-search=true`)
    } else if (selectedLevel1) {
      filterConditions.push(item => item.Path === `/products?categoryId=${selectedLevel1.value}&global-search=true`)
    }

    let filteredData: DealerForPagePathResponse[] = pages

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
    }
    setFilteredList(filteredData)
  }
  //#endregion

  const excelButton = async () => {
    try {
      if (filteredList) {
        const data: any[] = []
        if (filteredList.length > 0) {
          filteredList.map((item, index) => {
            return data.push({
              Sıra: index + 1,
              'Sayfa Url': item.Path,
              'Toplam Tıklama': item.Total,
              'Kullanıcı Sayısı': item.TotalUserIds,
              'Bayi Sayısı': item.DealerCount,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Sayfa Analizi')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>Sayfa Analizi</span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='form-group form-fg'>
              <form id='form1' className='form-validate' noValidate onSubmit={DealerForPagePath}>
                <div className='form-row'>
                  <div className='form-group col-md-3'>
                    <InputCampaign
                      type='date'
                      id='StartDate'
                      name='StartDate'
                      label={t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.startDate')}
                      value={toDateTimeLocal(pathOptions.StartDate)}
                      onChange={e => updatePathOptions('StartDate', new Date(e.target.value))}
                      isActive
                    />
                  </div>
                  <div className='form-group col-md-3'>
                    <InputCampaign
                      type='date'
                      id='EndDate'
                      name='EndDate'
                      label={'Yayın Bitiş Tarihi'}
                      value={toDateTimeLocal(pathOptions.EndDate)}
                      onChange={e => updatePathOptions('EndDate', new Date(e.target.value))}
                      isActive
                    />
                  </div>

                  <div className='form-group col-md-2'>
                    <IPButton
                      type='submit'
                      className='btn btn-danger float-right'
                      text={t('pages.admin.siteReports.mailSend.showButton')}
                    />
                  </div>
                </div>
                {showFilterRow && (
                  <div
                    className='detailFilter'
                    style={{
                      marginTop: '10px',
                      border: '2px solid #9c9cf1',
                      padding: '0px  5px',
                      paddingTop: '10px',
                    }}
                  >
                    <div className='row ms-1'>
                      <div className='form-group col-md-3'>
                        <label>Kategori Bazlı Filtre</label>

                        <IPSelectBox
                          isClearable
                          placeholder='Ana Kategrori'
                          options={level1Options}
                          value={selectedLevel1}
                          onChangeSingle={option => setSelectedLevel1(option)}
                        />

                        {selectedLevel1 && (
                          <IPSelectBox
                            isClearable
                            placeholder='Alt Kategrori'
                            options={level2Options}
                            value={selectedLevel2}
                            onChangeSingle={option => setSelectedLevel2(option)}
                          />
                        )}

                        {selectedLevel2 && (
                          <IPSelectBox
                            isClearable
                            placeholder='Alt Kategrori'
                            options={level3Options}
                            value={selectedLevel3}
                            onChangeSingle={option => setSelectedLevel3(option)}
                          />
                        )}

                        {selectedLevel3 && (
                          <IPSelectBox
                            isClearable
                            placeholder='Son Kategrori'
                            options={level4Options}
                            value={selectedLevel4}
                            onChangeSingle={option => setSelectedLevel4(option)}
                          />
                        )}
                      </div>

                      <div className='form-group col-md-3'>
                        <label>Sayfa Bazlı Filtre</label>

                        <IPSelectBox
                          isClearable
                          placeholder='Sayfa'
                          options={mainPages}
                          value={selectedPageFilter}
                          onChangeSingle={option => {
                            setSelectedPageFilter(option)
                          }}
                        />
                      </div>

                      <div className='form-group col-md-2'>
                        <label>Marka Bazlı Filtre</label>

                        <IPSelectBox
                          isClearable
                          placeholder='Markalar'
                          options={brands}
                          value={selectedBrand}
                          onChangeSingle={option => {
                            setSelectedBrand(option)
                          }}
                        />
                      </div>

                      <div className='form-group col-md-2'>
                        <label>Search Bazlı Filtre</label>
                        <IPInput
                          title='Sadece kullanıcı aramaları ile oluşmuş url sonuçları gelir'
                          type='text'
                          id='Title'
                          className='form-control'
                          name='Title'
                          placeholder='Search sonuçları'
                          onChange={e => setSearchWord(e.target.value)}
                          value={searchWord}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault()
                              filterList()
                            }
                          }}
                        />
                      </div>

                      <div className='col-12 col-md-1 mb-2 ms-2 align-self-center"'>
                        <label></label>
                        <IPButton
                          type='button'
                          className='btn btn-primary'
                          text='Detaylı Süz'
                          onClick={filterList}
                          style={{ height: '45px' }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
            <Table columns={columns} data={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(Index)
