import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { BrandProductRequest } from '../models/productsByBrandModels'
import { IProduct } from '../models/response/getProductListResponseModel'

export class GetProductListByBrandIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/productsByBrandV2',
      requesterInfo,
    })
  }

  public async getProductsList(req: BrandProductRequest): Promise<IProduct[]> {
    return await this.postAsync<IProduct[]>(req)
  }
}

export class GetProductListByModelApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/productsByBrandModel',
      requesterInfo,
    })
  }

  public async getProductsList(req: BrandProductRequest): Promise<IProduct[]> {
    return await this.postAsync<IProduct[]>(req)
  }
}
