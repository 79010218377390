import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import {
  IGetInstallmentSpecialRequest,
  IGetInstallmentSpecial,
  IAllPaymentData,
  PaymentRequestMySpecial,
  PaymentRequestFcvMySpecial,
  PaymentControl,
  IManualCreditCardPayment,
} from '../models/PaymentModel'

export class PaymentService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: '',
      requesterInfo,
    })
  }

  public async getDealerReminder(): Promise<any> {
    this.props.url = 'payment/get-dealer-reminder'
    return await this.postAsync<any>()
  }

  public async cariOdeme(req: PaymentRequestMySpecial): Promise<any> {
    this.props.url = 'payment/cariOdeme'
    return await this.postAsync<any>(req)
  }

  public async cariOdemeThreeds(req: PaymentRequestMySpecial): Promise<any> {
    this.props.url = 'payment/cariOdemeThreeds'
    return await this.postAsync<any>(req)
  }

  public async paymentThreeds(req: IManualCreditCardPayment): Promise<any> {
    this.props.url = 'manualCreditCardPayment/paymentThreeds'
    return await this.postAsync<any>(req)
  }

  public async avantajOdeme(req: PaymentRequestMySpecial): Promise<any> {
    this.props.url = 'payment/avantajOdeme'
    return await this.postAsync<any>(req)
  }

  public async avantajOdemeThreeds(req: PaymentRequestMySpecial): Promise<any> {
    this.props.url = 'payment/avantajOdemeThreeds'
    return await this.postAsync<any>(req)
  }

  public async firsatOdeme(req: PaymentRequestMySpecial): Promise<any> {
    this.props.url = 'payment/firsatOdeme'
    return await this.postAsync<any>(req)
  }

  public async firsatOdemeThreeds(req: PaymentRequestMySpecial): Promise<any> {
    this.props.url = 'payment/firsatOdemeThreeds'
    return await this.postAsync<any>(req)
  }

  public async fcvOdeme(req: PaymentRequestFcvMySpecial): Promise<any> {
    this.props.url = 'payment/fcvOdeme'
    return await this.postAsync<any>(req)
  }

  public async fcvOdemeThreeds(req: PaymentRequestFcvMySpecial): Promise<any> {
    this.props.url = 'payment/fcvOdemeThreeds'
    return await this.postAsync<any>(req)
  }

  public async paymentThreedsResponse(req: any): Promise<any> {
    this.props.url = 'payment/paymentThreedsResponse'
    return await this.postAsync<any>(req)
  }

  public async manuelCreditCardPaymentThreedsResponse(req: any): Promise<any> {
    this.props.url = 'manualCreditCardPayment/paymentThreedsResponse'
    return await this.postAsync<any>(req)
  }

  public async searchBIN(req): Promise<any> {
    this.props.url = 'payment/searchBINSpecial'
    return await this.postAsync<any>(req)
  }

  public async getInstallmentSpecial(req: IGetInstallmentSpecialRequest): Promise<IGetInstallmentSpecial[]> {
    this.props.url = `payment/get-installment-special`
    return await this.postAsync<IGetInstallmentSpecial[]>(req)
  }

  public async getDealerLimit(type: number): Promise<any> {
    this.props.url = `dealer/getDealerLimit/${type}`
    return await this.getAsync<any>()
  }

  public async getAllPaymentData(): Promise<IAllPaymentData> {
    this.props.url = `payment/getAllPaymentData`
    return await this.getAsync<IAllPaymentData>()
  }

  public async paymentControl(req: PaymentControl): Promise<boolean> {
    this.props.url = `payment/paymentControl`
    return await this.postAsync<boolean>(req)
  }
}
