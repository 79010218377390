import * as yup from 'yup'

const validations = yup.object().shape({
  Email: yup
    .string()
    .email('Geçerli bir email adresi girmelisiniz!')
    .nullable()
    .required('Lütfen Email alanını doldurunuz!'),
  // Phone: yup
  // 	.string()
  // 	.test("len", "Telefon numarası 10 karakter olmalı!", (val) => {
  // 		const a = val?.replaceAll('_', '').replaceAll('-', '').replaceAll('(', '').replaceAll(' ', '').replaceAll(')', '').length;
  // 		return a === 10;
  // 	})
  // 	.nullable()
  // 	.required('Lütfen Telefon alanını doldurunuz!'),
})

export default validations
