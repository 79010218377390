import { IUser } from './models/response/getUserListResponseModel'
import { useCustomReducer } from '../../../base/customHooks'
import { useNavigate } from 'react-router-dom'
import { DeleteUserApi } from './services/deleteUserApi'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import React, { useEffect, useRef } from 'react'
import $ from 'jquery'
import { GetUsersApi } from './services/getUsersApi'
import Swal from 'sweetalert2'
import { useAppSelector } from '../../../redux/app/hooks'
import { currentUser } from '../../../redux/slices/user/userSlice'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { logoutUser } from '../../../base/proxy/authenticate'
interface IListUserState {
  userLists?: IUser[]
}

function Index(props: BaseComponentWithContextProps) {
  const user = useAppSelector(currentUser)
  const getInitialState = (): IListUserState => {
    return {
      userLists: [],
    }
  }

  const t = translate(Translations)
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListUserState>(intialState)
  const navigate: any = useNavigate()
  const tableRef = useRef<any>()

  useEffect(() => {
    getUsers()
  }, [null])

  const getUsers = async () => {
    const getUsersApi: GetUsersApi = new GetUsersApi({})

    const res = await getUsersApi.getUsers()
    if (res) {
      setState({ userLists: res })
    }
  }

  const columnDefs = [
    {
      title: '#',
      data: 'Id',
    },
    {
      title: t('pages.mySpecial.userUpdate.accountCode'),
      data: 'UserCode',
      render: data => {
        // Remove leading zeros using parseInt
        const userCode = parseInt(data, 10); // 10 is the radix (base)
        return isNaN(userCode) ? data : userCode;
      },
    },
    {
      title: t('pages.mySpecial.userUpdate.userName'),
      data: 'UserName',
    },
    {
      title: 'Ad',
      data: 'Name',
    },
    {
      title: 'Soyad',
      data: 'Surname',
    },
    {
      title: t('pages.mySpecial.userUpdate.email'),
      data: 'Email',
    },
    {
      title: t('pages.mySpecial.userUpdate.status'),
      data: 'Enable',
      render: data => {
        return `<input type="checkbox" ${data ? 'checked' : ''} onclick="return false;" />`
      },
    },
    {
      title: t('pages.mySpecial.userUpdate.operations'),
      data: 'Id',
      render: (data: any) => {
        return `<a data-action-type="edit" role="button" style="cursor:pointer" data-toggle="tooltip" data-original-title={t('pages.mySpecial.userUpdate.edit')}>
        <i class='icon-edit' style="font-size:1.2rem; color:green"></i></a>
        <a data-action-type="delete" role="button" style="cursor:pointer" data-toggle="tooltip" data-original-title={t('pages.mySpecial.userUpdate.delete')}>
        <i class='icon-trash-2' style="font-size:1.2rem;color:red"></i></a>
        <a data-action-type="changePassword" role="button" style="cursor:pointer" data-toggle="tooltip" data-original-title={t('pages.mySpecial.userUpdate.changePassword')}>
        <i class='fa fa-unlock-alt' style="font-size:1.2rem;color:blue"></i></a>`
      },
    },
  ]

  useEffect(() => {
    const dt = syncTable()

    $('#datatb tbody').on('click', 'a', function () {
      const actionType: 'edit' | 'delete' | 'changePassword' = this.getAttribute('data-action-type')

      var data = dt.row($(this).parents('tr')).data()
      if (data) {
        if (actionType === 'edit') {
          navigate('/myspecial/AddNewUser', { state: { userData: data } })
        } else if (actionType === 'changePassword') {
          navigate('/myspecial/Password', { state: { userId: data.Id } })
        } else if (actionType === 'delete') {
          if (props.user.UserId === data.Id) {
            var message = "Kendi kullanıcınızı silmek istediğinize emin misiniz?";
          }
          else {
            var message = "Kullanıcıyı silmek istediğinize emin misiniz?";
          }
          Swal.fire({
            icon: 'question',
            title: message,
            showConfirmButton: true,
            allowOutsideClick: false,
            showDenyButton: true,
            confirmButtonText: 'Evet',
            denyButtonText: 'Hayır',
          }).then(async result => {
            if (result.isConfirmed) {
              const deleteUserApi = new DeleteUserApi({})

              const isDeleted = await deleteUserApi.deleteUser(data.Id)
              if (isDeleted) {
                setState({
                  userLists: state.userLists?.filter((user: IUser) => {
                    return user.Id !== data.Id
                  }),
                })
                Swal.fire({
                  icon: 'success',
                  title: 'Kullanıcı silindi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                if (props.user.UserId === data.Id) {
                  logoutUser()
                }
              }
            }
          })
        }
      }
    })

    return function () {
      dt.destroy()
    }
  }, [state.userLists])

  const syncTable = () => {
    const data = state.userLists
    const dt = $(tableRef.current).DataTable({
      data: data,
      columns: columnDefs,
      ordering: true,
    })
    return dt
  }

  return (
    <React.Fragment>
      <div className='container-fluid userupdate'>
        <div className='row'>
          <div className='heading-text'>
            <h4>{t('pages.mySpecial.userUpdate.header')}</h4>
          </div>
          <div className='col-lg-6 text-right'>
            <div id='export_buttons' className='mt-2'>
              <div className='dt-buttons btn-group'></div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 '>
            <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
              <div className='row'>
                <div className='col-sm-12'>
                  <div style={{ width: '100%', overflowX: 'auto' }}>
                    <table
                      id={'datatb'}
                      ref={tableRef}
                      className='table table-responsive d-lg-table d-sm-block'
                      style={{ minWidth: '100%' }}
                    ></table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default componentWithContext(Index)
