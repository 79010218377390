import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IReturnList, IReturnRequest } from '../models/models'

export class GetByDealerApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'orderReturn/getByDealer',
      requesterInfo,
    })
  }

  public async getByDealer(request: IReturnRequest): Promise<IReturnList[]> {
    return await this.postAsync<IReturnList[]>(request)
  }
}
