import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { GetProvizyonRequest, IProvizyon } from '../models'

export class GetProvizyonApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetProvizyon',
      requesterInfo,
    })
  }

  public async getProvizyon(request: GetProvizyonRequest): Promise<IProvizyon[]> {
    return await this.postAsync<IProvizyon[]>(request)
  }
}
