import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export class getSSSPazarApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'page/sssPazar',
      requesterInfo,
    })
  }

  public async getSssPazar(): Promise<boolean> {
    return await this.getAsync<boolean>()
  }
}
