import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { IAuthorizedServiceProcedurs } from '../../MySpecial/SSH/SSHProcedures/models/models'

export class GetAuthorizedServicesProcedurApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'authorizedServiceProcedurs/brandProcedurListAll',
      requesterInfo,
    })
  }

  public async getAuthorizedServicesProcedur(id: number): Promise<IAuthorizedServiceProcedurs[]> {
    this.props.url = `${this.props.url}/${id}`
    return await this.getAsync<IAuthorizedServiceProcedurs[]>()
  }
}
