import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IGeneralDashBoardResponse } from '../models/models'

export class GetGeneralDashBoardApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/generalDashBoard',
      requesterInfo,
    })
  }
  public async getGeneralDashBoard(Id: number): Promise<IGeneralDashBoardResponse> {
    this.props.url = `${this.props.url}/${Id}`
    return await this.getAsync<IGeneralDashBoardResponse>()
  }
}
