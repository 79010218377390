import React, { useEffect, useState } from 'react'
import './css/About.css'
import Translations from '../../translations'
import { translate } from '../../base/localization'
import { INDEX_THEME, DATAGATE_THEME, DESPEC_THEME, NETEX_THEME, DEFAULT_THEME } from '../../base/theme/themeConfig'
import { useAppSelector } from '../../redux/app/hooks'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import { componentWithContext } from '../../base/customHooks/componentHOC'
import IPSelectBox from '../../components/IPSelectBox'
import { ISelectOption } from '../../components/IPSelectBox/IPSelectBox'
import { useFormik } from 'formik'
import IPInput from '../../components/IPInput'
import { ICompany } from '../ProductList/models/commonModels'
import { GetCompaniesApi } from '../Admin/CategoryOperation/CategoryCrud/services/getCompaniesApi'
import { useCustomReducer } from '../../base/customHooks'
import IPTextarea from '../../components/IPTextarea'
import { SendContactFormApi } from './services/sendContactFormApi'
import { ISendContactFormRequestModel } from './models'
import { IException } from '../../base/models'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import InputMask from '../../components/InputMask'

interface IAboutUsState {
  companies: ICompany[]
}

const validationSchema = yup.object().shape({
  CommunicationDepartment: yup.object().shape({
    label: yup.string().required('İletişim Departmanı seçmediniz!'),
    value: yup.string().required('İletişim Departmanı seçmediniz!'),
  }),
  Name: yup.string().min(3, 'En az 3 karakter girilmelidir!').required('Lütfen Adınız alanını doldurunuz!'),
  CompanyId: yup.object().shape({
    label: yup.string().required('Firma seçmediniz!'),
    value: yup.string().required('Firma seçmediniz!'),
  }),
  DealerName: yup.string().min(3, 'En az 3 karakter girilmelidir!').required('Firmanız alanını doldurunuz!'),
  Email: yup
    .string()
    .email('Geçerli bir email adresi girmelisiniz!')
    .nullable()
    .required('Lütfen Email alanını doldurunuz!'),
  Phone: yup
    .string()
    .test('len', 'Telefon numarası 10 karakter olmalı!', val => {
      const a = val
        ?.replaceAll('_', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(' ', '')
        .replaceAll(')', '').length
      return a === 10
    })
    .nullable()
    .required('Lütfen Telefon alanını doldurunuz!'),
  Message: yup.string().min(3, 'En az 3 karakter girilmelidir!').required('Mesaj alanını doldurunuz!'),
})

function AboutUs(props: any) {
  const t = translate(Translations)
  const theme = useAppSelector(selectedTheme)
  const [activeTab, setActiveTab] = useState('corporate-tab')

  const getInitialState = (): IAboutUsState => {
    return {
      companies: [],
    }
  }

  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<IAboutUsState>(initialState)

  const communicationDepartments = [
    {
      value: '1',
      label: t('pages.about.aboutUs.support'),
    },
    {
      value: '2',
      label: t('pages.about.aboutUs.salesSupport'),
    },
    {
      value: '3',
      label: t('pages.about.aboutUs.myCurrentAccount'),
    },
  ]

  const getCompanies = () => {
    const api: GetCompaniesApi = new GetCompaniesApi({})
    api.getCompanies().then((res: ICompany[]) => {
      setState({ companies: res })
    })
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const initialValues = Object.assign({
    CompanyId: null,
    CommunicationDepartment: null,
    Name: '',
    AccountCode: '',
    DealerName: '',
    Email: '',
    Phone: '',
    Message: '',
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    validateField,
    setValues,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: async () => {
      const sendFormApi: SendContactFormApi = new SendContactFormApi({})
      const req: ISendContactFormRequestModel = Object.assign({
        CompanyId: values.CompanyId ? Number(values.CompanyId.value) : 0,
        Name: values.Name,
        AccountCode: '',
        CommunicationDepartment: values.CommunicationDepartment ? values.CommunicationDepartment.label : '',
        DealerName: values.DealerName,
        Email: values.Email,
        Phone: values.Phone,
        Message: values.Message,
      })

      sendFormApi
        .sendForm(req)
        .then((res: any) => {
          if (res) {
            setValues(initialValues)

            Swal.fire({
              icon: 'success',
              title: res,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
        })
        .catch((err: IException) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    },
    validationSchema,
  })

  const clearForm = () => {
    setValues(initialValues)
  }

  return (
    <section className='aboutUs' id='page-content'>
      <div className='container'>
        <div className='row'>
          <div className='content col-lg-12'>
            <div className='heading-text mb-0'>
              <div className='row'>
                {theme.name === 'default' && (
                  <div className='col-md-8'>
                    <h5 className='text-uppercase'>
                      INDEX GRUP{' '}
                      <span className='aboutTitle' style={{ color: theme.colors.backgroundColor }}>
                        {activeTab === 'corporate-tab'
                          ? 'Kurumsal Bilgiler'
                          : activeTab === 'transportation-tab'
                          ? 'Ulaşım'
                          : 'İletişim Formu'}{' '}
                      </span>{' '}
                    </h5>
                  </div>
                )}
                {theme.name === INDEX_THEME && (
                  <div className='col-md-8'>
                    <h5 className='text-uppercase'>
                      INDEX -{' '}
                      <span className='aboutTitle' style={{ color: theme.colors.backgroundColor }}>
                        {activeTab === 'corporate-tab'
                          ? 'Kurumsal Bilgiler'
                          : activeTab === 'transportation-tab'
                          ? 'Ulaşım'
                          : 'İletişim Formu'}{' '}
                      </span>{' '}
                    </h5>
                  </div>
                )}
                {theme.name === DATAGATE_THEME && (
                  <div className='col-md-8'>
                    <h5 className='text-uppercase'>
                      DATAGATE -{' '}
                      <span className='aboutTitle' style={{ color: theme.colors.backgroundColor }}>
                        {activeTab === 'corporate-tab'
                          ? 'Kurumsal Bilgiler'
                          : activeTab === 'transportation-tab'
                          ? 'Ulaşım'
                          : 'İletişim Formu'}{' '}
                      </span>{' '}
                    </h5>
                  </div>
                )}
                {theme.name === DESPEC_THEME && (
                  <div className='col-md-8'>
                    <h5 className='text-uppercase'>
                      DESPEC BİLGİSAYAR PAZARLAMA VE TİCARET A.Ş. -{' '}
                      <span className='aboutTitle' style={{ color: theme.colors.backgroundColor }}>
                        {activeTab === 'corporate-tab'
                          ? 'Kurumsal Bilgiler'
                          : activeTab === 'transportation-tab'
                          ? 'Ulaşım'
                          : 'İletişim Formu'}{' '}
                      </span>{' '}
                    </h5>
                  </div>
                )}
                {theme.name === NETEX_THEME && (
                  <div className='col-md-8'>
                    <h5 className='text-uppercase'>
                      NETEKS TEKNOLOJİ ÜRÜNLERİ A.Ş. -{' '}
                      <span className='aboutTitle' style={{ color: theme.colors.backgroundColor }}>
                        {activeTab === 'corporate-tab'
                          ? 'Kurumsal Bilgiler'
                          : activeTab === 'transportation-tab'
                          ? 'Ulaşım'
                          : 'İletişim Formu'}{' '}
                      </span>{' '}
                    </h5>
                  </div>
                )}
                <div className='col-md-4 text-right'>
                  <img src={theme.image} className='img-fluid' />
                </div>
              </div>
            </div>
            <div className='tabs'>
              <ul className='nav nav-tabs' id='myTab' role='tablist'>
                <li className='nav-item'>
                  <a
                    className={`nav-link pl-0 ${activeTab === 'corporate-tab' ? 'active' : ''}`}
                    style={{
                      color: activeTab === 'corporate-tab' ? theme.colors.backgroundColor : '',
                      borderColor: activeTab === 'corporate-tab' ? theme.colors.backgroundColor : '',
                    }}
                    id='corporate-tab'
                    data-toggle='tab'
                    href='#corporate'
                    role='tab'
                    aria-controls='corporate'
                    aria-selected='true'
                    onClick={() => {
                      setActiveTab('corporate-tab')
                    }}
                  >
                    {t('pages.about.aboutUs.corporateInformation')}
                  </a>
                </li>
                <li className='nav-item'>
                  <a
                    className={`nav-link ${activeTab === 'transportation-tab' ? 'active' : ''}`}
                    style={{
                      color: activeTab === 'transportation-tab' ? theme.colors.backgroundColor : '',
                      borderColor: activeTab === 'transportation-tab' ? theme.colors.backgroundColor : '',
                    }}
                    id='transportation-tab'
                    data-toggle='tab'
                    href='#transportation'
                    role='tab'
                    aria-controls='transportation'
                    aria-selected='false'
                    onClick={() => {
                      setActiveTab('transportation-tab')
                    }}
                  >
                    {t('pages.about.aboutUs.transportation')}
                  </a>
                </li>{' '}
                <li className='nav-item'>
                  {' '}
                  <a
                    className={`nav-link ${activeTab === 'contact-tab' ? 'active' : ''}`}
                    style={{
                      color: activeTab === 'contact-tab' ? theme.colors.backgroundColor : '',
                      borderColor: activeTab === 'contact-tab' ? theme.colors.backgroundColor : '',
                    }}
                    id='contact-tab'
                    data-toggle='tab'
                    href='#contact'
                    role='tab'
                    aria-controls='contact'
                    aria-selected='false'
                    onClick={() => {
                      setActiveTab('contact-tab')
                    }}
                  >
                    {' '}
                    {t('pages.about.aboutUs.contactForm')}
                  </a>
                </li>{' '}
              </ul>
              <div className='tab-content' id='myTabContent'>
                <div
                  className={`tab-pane fade show  ${activeTab === 'corporate-tab' ? 'active' : ''}`}
                  id='corporate'
                  role='tabpanel'
                  aria-labelledby='corporate-tab'
                >
                  {theme.name === DEFAULT_THEME && (
                    <div className='heading-text heading-line text-uppercase'>
                      <h5>{t('pages.about.aboutUs.mission')}</h5>
                      <p>
                        Lider dünya markalarıyla ürün yelpazemizde direk distribütör olarak çalışmak, rekabet gücümüzü
                        arttırmak ve iş ortaklarımızın tüm bilişim ürün ihtiyaçlarını,onlara en iyi lojistik hizmetini
                        vererek karşılayan ana tedarik merkezi olmak.
                        <br />
                        <br />
                        Kalite yönetim sistemimize uygun hareket edip, iş ortaklarımızın memnuniyetini sağlamak için
                        beklentilerini iyi anlamak ve oluşan ihtiyaçları katma değer yaratacak bir hizmet anlayışı ile
                        gerçekleştirirken, çalışanlarımızın güven ve memnuniyet duyabilecekleri bir çalışma ortamı
                        sunmak ve bu doğrultuda, çalışma verimliliğimizi ölçmek ve sürekli iyileştirmek.
                      </p>
                      <h5>{t('pages.about.aboutUs.vision')}</h5>
                      <p>
                        Türkiye kişisel bilgisayar bileşenleri sektöründe iş süreçleri, satış sonuçları, müşteri
                        tatmini, birinci sınıf müşteri hizmetleri ve yüksek kaliteli lojistik hizmetlerle lider firma
                        olmak.
                      </p>
                      <h5>{t('pages.about.aboutUs.history')}</h5>
                      <p>
                        İndeks Bilgisayar Sistemleri Mühendislik Sanayi ve Ticaret A.Ş. 10.07.1989 tarihinde Türkiye
                        genelindeki bilgisayar firmalarına, ihtiyaç duydukları tüm ürünleri, iyi organize olmuş bir
                        bilgisayar dağıtıcısı kimliği altında sağlamak amacı ile kurulmuştur. İndeks Bilgisayar A.Ş.
                        200'den fazla dünya markasının dağıtımını gerçekleştirmekte, 450 personele ve 7500'den fazla iş
                        ortağına sahip, 2001 yılından bu yana Bilişim Teknolojileri sektöründe liderliği elinde
                        bulunduran bir şirketler grubu halini almıştır.
                      </p>
                    </div>
                  )}
                  {theme.name === INDEX_THEME && (
                    <div className='heading-text heading-line text-uppercase'>
                      <h5>{t('pages.about.aboutUs.mission')}</h5>
                      <p>
                        Lider dünya markalarıyla ürün yelpazemizde direk distribütör olarak çalışmak, rekabet gücümüzü
                        arttırmak ve iş ortaklarımızın tüm bilişim ürün ihtiyaçlarını,onlara en iyi lojistik hizmetini
                        vererek karşılayan ana tedarik merkezi olmak.
                        <br />
                        <br />
                        Kalite yönetim sistemimize uygun hareket edip, iş ortaklarımızın memnuniyetini sağlamak için
                        beklentilerini iyi anlamak ve oluşan ihtiyaçları katma değer yaratacak bir hizmet anlayışı ile
                        gerçekleştirirken, çalışanlarımızın güven ve memnuniyet duyabilecekleri bir çalışma ortamı
                        sunmak ve bu doğrultuda, çalışma verimliliğimizi ölçmek ve sürekli iyileştirmek.
                      </p>
                      <h5>{t('pages.about.aboutUs.vision')}</h5>
                      <p>
                        Türkiye kişisel bilgisayar bileşenleri sektöründe iş süreçleri, satış sonuçları, müşteri
                        tatmini, birinci sınıf müşteri hizmetleri ve yüksek kaliteli lojistik hizmetlerle lider firma
                        olmak.
                      </p>
                      <h5>{t('pages.about.aboutUs.history')}</h5>
                      <p>
                        İndeks Bilgisayar Sistemleri Mühendislik Sanayi ve Ticaret A.Ş. 10.07.1989 tarihinde Türkiye
                        genelindeki bilgisayar firmalarına, ihtiyaç duydukları tüm ürünleri, iyi organize olmuş bir
                        bilgisayar dağıtıcısı kimliği altında sağlamak amacı ile kurulmuştur. İndeks Bilgisayar A.Ş.
                        200'den fazla dünya markasının dağıtımını gerçekleştirmekte, 450 personele ve 7500'den fazla iş
                        ortağına sahip, 2001 yılından bu yana Bilişim Teknolojileri sektöründe liderliği elinde
                        bulunduran bir şirketler grubu halini almıştır.
                      </p>
                    </div>
                  )}
                  {theme.name === DESPEC_THEME && (
                    <div className='heading-text heading-line text-uppercase'>
                      <h5>{t('pages.about.aboutUs.mission')}</h5>
                      <p>
                        Bilişim ürünlerinin tüketim malzemelerini ve sürekli kullanılan yan ürünlerini tüm markaları
                        kapsayacak şekilde tedarik etmek, çözüm ortakları ile birlikte tek noktadan temin edilmesini
                        sağlamak, istikrarlı ve kalıcı çözümleri ön plana çıkarıp iş ortakları ile birlikte verimli
                        ticari ilişkiler kurmaktır.
                      </p>
                      <h5>{t('pages.about.aboutUs.vision')}</h5>
                      <p>
                        Bilişim Teknolojileri kulvarında, ülkemizdeki tüm iş ortaklarımıza ve müşterilerimize gereken
                        tüketim malzemelerini karşılamak.
                      </p>
                      <h5>{t('pages.about.aboutUs.history')}</h5>
                      <p>
                        Despec Bilgisayar Pazarlama ve Ticaret A.Ş., bilişim teknolojileri (“BT”) tüketim malzemeleri
                        (toner, mürekkep kartuş, şerit, yedekleme ürünleri, kağıt ürünleri ve aksesuarlar ) dağıtımı
                        konusunda faaliyet göstermektedir. 04.01.1995 tarihinde kurulmuş olup esas faaliyetlerine 1998
                        yılının sonlarına doğru başlamıştır.
                        <br />
                        <br />
                        Şirketimize Mayıs 1998’de Merkezi Hollanda’da bulunan tamamı yabancı sermayeli Despec Group B.V
                        %50 hisse ile ortak olmuştur. Despec’in diğer ortağı, Bilecik ailesinin ortak olduğu Desbil
                        Teknolojik Ürünler A.Ş.’dir. Bilecik ailesi, Türkiye’nin önde gelen BT ürünleri dağıtıcısı olan
                        İndeks Bilgisayar Sistemleri Mühendislik Sanayi ve Ticaret A.Ş. (“İndeks”)’in kurucusu ve %58,30
                        ortağıdır.
                        <br />
                        <br />
                        Despec’in, dünyanın önde gelen markalarından oluşan geniş bir ürün portföyüne sahiptir. HP
                        ürünleri (özellikle yazıcı toner ve kartuşları) satışların büyük bir bölümünü oluşturmaktadır.
                        Şirket HP, Oki, Canon, Lexmark, Imation, IBM, Sony, TDK, Targus, Trust, Emtec, Steelseries,
                        Dexim ve Hercules’in bilgisayar malzemelerinin distribütörlüğünü yapmaktadır. Ayrıca Xerox,
                        Kingston, Panasonic, Samsung, Epson, ve Brother’inde bilgisayar tüketim malzemelerine ilişkin
                        ara toptancılığını yapmaktadır.
                        <br />
                        <br />
                        Despec Group B.V, BT tüketim malzemeleri’nin bayilere satışına konsantre olmuş uluslararası bir
                        grubun holding şirketidir. Ürün portföyünde, elektronik ofis malzemeleri, BT çevre ürünleri,
                        dijital ekipmanlar, fotoğrafçılık ve telekomünikasyon aksesuarları alanlarındaki önde gelen
                        markalar yer almaktadır. Despec Group B.V Ortadoğu, Afrika, Benelüks devletleri (Hollanda,
                        Belçika ve Lüksemburg), Fransa, Almanya ve Türkiye de faaliyetlerini yürütmektedir. Bu ülkelerde
                        faaliyetlerinin neredeyse tamamını HP, Oki, Canon ve Lexmark gibi büyük firmaların BT tüketim
                        ürünlerini pazarlayarak sürdürmektedir.
                        <br />
                        <br />
                        Despec International FZCO şirketini 2006 yılında kuran İngiltere’de mukim ve Kanada vatandaşı
                        olan Riyaz Amirali Jamal 2007 yılında Despec Group B.V isimli şirketi satın almıştır. Riyaz
                        Amirali Jamal Despec International FZCO şirketinin % 70 ine sahiptir.{' '}
                      </p>
                    </div>
                  )}
                  {theme.name === DATAGATE_THEME && (
                    <div className='heading-text heading-line text-uppercase'>
                      <h5>{t('pages.about.aboutUs.mission')}</h5>
                      <p>
                        Şirketimizin misyonu “Türkiye teknoloji pazarında, tüm çalışanlarının katılımı ve tüm paydaşları
                        için artı değer yaratarak sürekli gelişen kurumsal, yaratıcı ve güvenilir bir hizmet şirketi
                        olarak varlığını sürdürebilmektir”. Bu tanım Yönetim Kurulu tarafından belirlenmiş olup,
                        şirketin internet sitesi ile kamuoyuna açıklanmıştır.
                      </p>
                      <h5>{t('pages.about.aboutUs.vision')}</h5>
                      <p>
                        Şirketimizin vizyonu “Türkiye teknoloji sektöründe distiribütör olarak iş süreçleri, satış
                        sonuçları, müşteri memnuniyeti , yüksek kaliteli lojistik hizmetlerini kullanarak lider firma
                        “olmaktır.
                      </p>
                      <h5>{t('pages.about.aboutUs.history')}</h5>
                      <p>
                        1992 yılında “Teknolojiye Açılan Kapı” olma vizyonuyla kurulan Datagate AŞ; 2001 yılında Index
                        Grup bünyesine katılmıştır. Türkiye’de uzun yıllar PC bileşenleri ve IT teknolojileri
                        distribütörlüğü yapan Datagate AŞ, 2014 yılı itibarıyla bu pazarlardaki daralma nedeni ile
                        teknoloji pazarının büyüyen kısmı olan akıllı telefon pazarına yönelerek 2014 yılında Avea (Türk
                        Telekom) cihaz (akıllı telefon) bölgesel distribütörü ve Apple ürünleri master distribütörü
                        olarak atanmıştır. 2021 yılında Datagate AŞ’nin Türk Telekom bölgesel cihaz distribütörlüğü sona
                        ermiştir.
                        <br />
                        <br />
                        Datagate AŞ, son yıllardaki yatırımlarla ürün portföyünü bilişim, telekom ve gelecek
                        teknolojileri ürünleriyle daha da güçlendirmiş olup, Türkiye’nin her noktasına akıllı telefon,
                        bilgisayar ve gelecek teknolojilerine ait ürünlerin dağıtımını yapmaya devam etmektedir.
                        Datagate AŞ; Apple, Samsung, Huawei, HTC Vive (sanal gerçeklik) ve Jarvisen translator (yapay
                        zekâ) markalarının resmi distribütörü olup; Xiaomi, Aiwa, Dynabook gibi ürünlerin ise Türkiye
                        genelinde ithalat ve dağıtımını yapmaktadır. Datagate AŞ, “DGATE” koduyla 2006 yılı şubat
                        ayından bu yana BIST’te işlem görüyor.{' '}
                      </p>
                    </div>
                  )}
                  {theme.name === NETEX_THEME && (
                    <div className='heading-text heading-line text-uppercase'>
                      <h5>{t('pages.about.aboutUs.mission')}</h5>
                      <p>
                        Ürünler ve araçlar sağlayarak, teknik, entegrasyon ve finans hizmetleri sunarak, pazar ve
                        teknoloji uzmanlığı ile son müşterilerinize çözümleri daha etkin ve karlı biçimde satabilmenizi
                        sağlayarak, işinizi destekliyoruz.
                      </p>
                      <h5>{t('pages.about.aboutUs.vision')}</h5>
                      <p>
                        Yüksek büyüme eğilimi gösteren gelişmiş teknoloji pazarlarında özgün ve etkin rotalar çizmenin,
                        müşteriler ve üreticiler için temel büyümenin anahtarı olduğuna inanıyoruz.Tüm bunları en iyi
                        markalar, sınıflarının en iyi tedarikçiler, üreticiler, müşteriler ve çalışanlar ile beraber
                        elde ediyoruz.
                      </p>
                      <h5>{t('pages.about.aboutUs.value')}</h5>
                      <p>
                        Network, güvenlik çözümleri, mobilite ve yakınsama konularında dünyanın önde gelen
                        distribütörüyüz. Tedarikçilerimizin pazara ulaşmaları için en etkin rotayı çiziyoruz,
                        müşterilerimize en üst düzeyli destek hizmetlerini sağlıyoruz ve başarımız ile büyümemiz
                        sayesinde çalışanlar için endüstrinin en iyi deneyimini sunuyoruz.{' '}
                      </p>
                      <h5>{t('pages.about.aboutUs.history')}</h5>
                      <p>
                        Ağ ve iletişim teknolojileri alanında faaliyet gösteren Neteks, 1996 yılında kurulmuş olup, 2001
                        yılında Index Grup bünyesine katılmıştır. Neteks dünyada iletişim teknolojileri konusunda lider
                        üreticilerilerin Türkiye Dağıtıcılığı rolünü üstlenmektedir. Yarattığı katma değerli
                        hizmetlerini ve geniş ürün yelpazesini, yaygın işortakları kanalı üzerinden, ülke çapında
                        dağıtımını gerçekleştirmektedir.
                        <br />
                        <br />
                        Neteks'in dağıtımını üstlendiği ürünler arasında, Cisco Systems, Avaya, HP Networking, Juniper,
                        Aruba, Checkpoint, A10, SkyBox Security, Avocent, Corning, HCS HES Cabling Systems, Panduit,
                        Bluecat Networks gibi büyük kuruluşlar yer almaktadır.
                        <br />
                        <br />
                        Kurulduğu günden beri sadece iletişim teknolojileri üzerine odaklanmış ve uzmanlığını bu konuda
                        geliştirmiş olan Neteks, işortakları için yarattığı katma değerle sektörde kendini
                        farklılaştırmıştır.
                        <br />
                        <br />
                        Neteks sermaye yapısı Temmuz 2007 itibariyle değişmiş, %50 hisseleri, dünyanın en büyük
                        networking grubu olan ve Datatec International'a bağlı Westcon Group tarafından satın
                        alınmıştır.
                        <br />
                        <br />
                        Westcon Group tarafından dünyanın gelişmiş ülkelerinde edinilen tecrübe ve ‘’know how’’ Neteks
                        işortaklarına yansıtılmaktadır.{' '}
                      </p>
                    </div>
                  )}

                  <div className='row'>
                    <div className='col-lg-6 pr-0'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='table-responsive'>
                            <h5>{t('pages.about.aboutUs.centerOffice')}</h5>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.address')}</strong>
                                  </td>
                                  <td className='cart-product-name text-left'>
                                    {theme.name === DEFAULT_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:11 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                    {theme.name === INDEX_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:11 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                    {theme.name === NETEX_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:6 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                    {theme.name === DESPEC_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:9 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                    {theme.name === DATAGATE_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:10 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.email')}</strong>
                                  </td>
                                  <td className='cart-product-name text-left'>
                                    {theme.name === DEFAULT_THEME && (
                                      <span>
                                        <a href='mailto:idx-bilgi@index.com.tr'>idx-bilgi@index.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === INDEX_THEME && (
                                      <span>
                                        <a href='mailto:idx-bilgi@index.com.tr'>idx-bilgi@index.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === NETEX_THEME && (
                                      <span>
                                        <a href='mailto:ntx-bilgi@neteks.com.tr'>ntx-bilgi@neteks.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === DESPEC_THEME && (
                                      <span>
                                        <a href='mailto:dpc-bilgi@despec.com.tr'>dpc-bilgi@despec.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === DATAGATE_THEME && (
                                      <span>
                                        <a href='mailto:dgt-bilgi@datagate.com.tr'>dgt-bilgi@datagate.com.tr</a>
                                      </span>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.phone')}</strong>
                                  </td>
                                  <td className='cart-product-name  text-left'>
                                    {theme.name === DEFAULT_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 21 21'>0 (212) 331 21 21</a>
                                      </span>
                                    )}
                                    {theme.name === INDEX_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 21 21'>0 (212) 331 21 21</a>
                                      </span>
                                    )}
                                    {theme.name === NETEX_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 23 23'>0 (212) 331 23 23</a>
                                      </span>
                                    )}
                                    {theme.name === DESPEC_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 24 24'>0 (212) 331 24 24</a>
                                      </span>
                                    )}
                                    {theme.name === DATAGATE_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 27 27'>0 (212) 331 27 27</a>
                                      </span>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.fax')}</strong>
                                  </td>
                                  <td className='cart-product-name  text-left'>
                                    {theme.name === DEFAULT_THEME && <span>0 (212) 332 09 29</span>}
                                    {theme.name === INDEX_THEME && <span>0 (212) 332 09 29</span>}
                                    {theme.name === NETEX_THEME && <span>0 (212) 332 20 24</span>}
                                    {theme.name === DESPEC_THEME && <span>0 (212) 289 13 91</span>}
                                    {theme.name === DATAGATE_THEME && <span>0 (212) 332 15 30</span>}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>Vergi Dairesi</strong>
                                  </td>
                                  <td className='cart-product-name  text-left'>
                                    {theme.name === DEFAULT_THEME && <span>Büyük Mükellefler Vergi Dairesi</span>}
                                    {theme.name === INDEX_THEME && <span>Büyük Mükellefler Vergi Dairesi</span>}
                                    {theme.name === DATAGATE_THEME && <span>Büyük Mükellefler Vergi Dairesi</span>}
                                    {theme.name === DESPEC_THEME && <span>BOĞAZİÇİ KURUMLAR</span>}
                                    {theme.name === NETEX_THEME && <span>Maslak Kurumlar Vergi Dairesi</span>}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>Vergi No</strong>
                                  </td>
                                  <td className='cart-product-name  text-left'>
                                    {theme.name === DEFAULT_THEME && <span>4780028965</span>}
                                    {theme.name === INDEX_THEME && <span>4780028965</span>}
                                    {theme.name === DATAGATE_THEME && <span>2710022637</span>}
                                    {theme.name === DESPEC_THEME && <span>7440030406</span>}
                                    {theme.name === NETEX_THEME && <span>6311023892</span>}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'></td>
                                  <td className='cart-product-name  text-left'>
                                    <span>E-arşiv Email Adresimiz:</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 pl-0'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='table-responsive'>
                            <h5> {t('pages.about.aboutUs.logisticsCenter')}</h5>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <td className='cart-product-name text-left'>
                                    <span>Cumhuriyet Mahallesi Yahyakaptan Caddesi No:10A D:2 Çayırova/KOCAELİ</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name text-left'>
                                    <span></span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name  text-left'>
                                    <span>
                                      {' '}
                                      {t('pages.about.aboutUs.central')}:{' '}
                                      <a href='tel:0 (850) 200 28 28'>0 (850) 200 28 28</a> <br />
                                      Teklos {t('pages.about.aboutUs.callCenter')}:{' '}
                                      <a href='tel:0 (850) 200 2 888'>0 (850) 200 2 888</a>{' '}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name  text-left'>
                                    <span>0 (262) 658 28 06</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name  text-left'>
                                    <span></span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name  text-left'>
                                    <span></span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name  text-left'>
                                    {theme.name === DEFAULT_THEME && (
                                      <span>
                                        <a href='mailto:earsiv.idx@index.com.tr'>earsiv.idx@index.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === INDEX_THEME && (
                                      <span>
                                        <a href='mailto:earsiv.idx@index.com.tr'>earsiv.idx@index.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === DATAGATE_THEME && (
                                      <span>
                                        <a href='mailto:earsiv.dgt@datagate.com.tr'>earsiv.dgt@datagate.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === DESPEC_THEME && (
                                      <span>
                                        <a href='mailto:earsiv.dpc@despec.com.tr'>earsiv.dpc@despec.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === NETEX_THEME && (
                                      <span>
                                        <a href='mailto:earsiv.ntt@neteks.com.tr'>earsiv.ntt@neteks.com.tr</a>
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {theme.name === DATAGATE_THEME && (
                    <div className='col-lg-6 pl-0'>
                      <div className='table-responsive'>
                        <h5>TİCARET SİCİL BİLGİLERİ</h5>
                        <table className='table'>
                          <tbody>
                            <tr>
                              <td className='cart-product-name'>
                                <strong>Tescil Tarihi</strong>
                              </td>
                              <td className='cart-product-name text-left'>
                                <span>03.06.1992</span>
                              </td>
                            </tr>
                            <tr>
                              <td className='cart-product-name'>
                                <strong>Ticaret Sicil Memurluğu</strong>
                              </td>
                              <td className='cart-product-name text-left'>
                                <span>İstanbul Ticaret Sicil Memurluğu</span>
                              </td>
                            </tr>
                            <tr>
                              <td className='cart-product-name'>
                                <strong>Sicil Numarası</strong>
                              </td>
                              <td className='cart-product-name  text-left'>
                                <span>287406</span>
                              </td>
                            </tr>
                            <tr>
                              <td className='cart-product-name'>
                                <strong>Vergi Dairesi</strong>
                              </td>
                              <td className='cart-product-name  text-left'>
                                <span>Büyük Mükellefler Vergi Dairesi</span>
                              </td>
                            </tr>
                            <tr>
                              <td className='cart-product-name'>
                                <strong>Vergi No</strong>
                              </td>
                              <td className='cart-product-name  text-left'>
                                <span>2710022637</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className={`tab-pane fade show  ${activeTab === 'transportation-tab' ? 'active' : ''}`}
                  id='transportation'
                  role='tabpanel'
                  aria-labelledby='transportation-tab'
                >
                  <div className='heading-text heading-line text-uppercase'>
                    <h5>{t('pages.about.aboutUs.address')}</h5>
                  </div>
                  <div className='row'>
                    <div className='col-lg-6 pr-0'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='table-responsive'>
                            <h5>{t('pages.about.aboutUs.centerOffice')}</h5>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.address')}</strong>
                                  </td>
                                  <td className='cart-product-name text-left'>
                                    {theme.name === DEFAULT_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:11 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                    {theme.name === INDEX_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:11 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                    {theme.name === NETEX_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:6 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                    {theme.name === DESPEC_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:9 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                    {theme.name === DATAGATE_THEME && (
                                      <span>
                                        Ayazağa Mahallesi Mimar Sinan Sokak No:21 Seba Office Boulevard D Blok Kat: 1
                                        Bölüm No:10 Sarıyer, 34485 İstanbul
                                      </span>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.email')}</strong>
                                  </td>
                                  <td className='cart-product-name text-left'>
                                    {theme.name === DEFAULT_THEME && (
                                      <span>
                                        <a href='mailto:idx-bilgi@index.com.tr'>idx-bilgi@index.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === INDEX_THEME && (
                                      <span>
                                        <a href='mailto:idx-bilgi@index.com.tr'>idx-bilgi@index.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === NETEX_THEME && (
                                      <span>
                                        <a href='mailto:ntx-bilgi@neteks.com.tr'>ntx-bilgi@neteks.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === DESPEC_THEME && (
                                      <span>
                                        <a href='mailto:dpc-bilgi@despec.com.tr'>dpc-bilgi@despec.com.tr</a>
                                      </span>
                                    )}
                                    {theme.name === DATAGATE_THEME && (
                                      <span>
                                        <a href='mailto:dgt-bilgi@datagate.com.tr'>dgt-bilgi@datagate.com.tr</a>
                                      </span>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.phone')}</strong>
                                  </td>
                                  <td className='cart-product-name  text-left'>
                                    {theme.name === DEFAULT_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 21 21'>0 (212) 331 21 21</a>
                                      </span>
                                    )}
                                    {theme.name === INDEX_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 21 21'>0 (212) 331 21 21</a>
                                      </span>
                                    )}
                                    {theme.name === NETEX_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 23 23'>0 (212) 331 23 23</a>
                                      </span>
                                    )}
                                    {theme.name === DESPEC_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 24 24'>0 (212) 331 24 24</a>
                                      </span>
                                    )}
                                    {theme.name === DATAGATE_THEME && (
                                      <span>
                                        <a href='tel:0 (212) 331 27 27'>0 (212) 331 27 27</a>
                                      </span>
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.fax')}</strong>
                                  </td>
                                  <td className='cart-product-name  text-left'>
                                    {theme.name === DEFAULT_THEME && <span>0 (212) 332 09 29</span>}
                                    {theme.name === INDEX_THEME && <span>0 (212) 332 09 29</span>}
                                    {theme.name === NETEX_THEME && <span>0 (212) 332 20 24</span>}
                                    {theme.name === DESPEC_THEME && <span>0 (212) 289 13 91</span>}
                                    {theme.name === DATAGATE_THEME && <span>0 (212) 332 15 30</span>}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 pl-0'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='table-responsive'>
                            <h5> {t('pages.about.aboutUs.logisticsCenter')}</h5>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <td className='cart-product-name text-left'>
                                    <span>Cumhuriyet Mahallesi Yahyakaptan Caddesi No:10A D:2 Çayırova/KOCAELİ</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name text-left'>
                                    <span></span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name  text-left'>
                                    <span>
                                      {' '}
                                      {t('pages.about.aboutUs.central')}:{' '}
                                      <a href='tel:0 (850) 200 28 28'>0 (850) 200 28 28</a> <br />
                                      Teklos {t('pages.about.aboutUs.callCenter')}:{' '}
                                      <a href='tel:0 (850) 200 2 888'>0 (850) 200 2 888</a>{' '}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name  text-left'>
                                    <span>0 (262) 658 28 06</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-6 pr-0'>
                      <div className='row'>
                        <div className='col-lg-12'>
                          <div className='table-responsive'>
                            <h5>ANKARA {t('pages.about.aboutUs.branch')}</h5>
                            <table className='table'>
                              <tbody>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.address')}</strong>
                                  </td>
                                  <td className='cart-product-name text-left'>
                                    <span>Çetin Emeç Bulvarı Öveçler, 4. Cadde, No: 4/9 Dikmen / ANKARA</span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.phone')}</strong>
                                  </td>
                                  <td className='cart-product-name  text-left'>
                                    <span>
                                      <a href='0 (312) 472 82 20'>0 (312) 472 82 20</a>
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className='cart-product-name'>
                                    <strong>{t('pages.about.aboutUs.fax')}</strong>
                                  </td>
                                  <td className='cart-product-name  text-left'>
                                    <span>0 (312) 472 82 36</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 m-t-50'>
                      <div className='row'>
                        <div className='heading-text heading-line text-uppercase'>
                          <h5>{t('pages.about.aboutUs.map')}</h5>
                        </div>
                        <div className='col-md-6'>
                          <div className='aboutMap'>
                            <h5> {t('pages.about.aboutUs.centerOffice')}</h5>
                            <iframe
                              src='https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d3006.1558748078583!2d28.994288!3d41.1092867!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x14cab6c1d772e913%3A0x5e5106a03c7d3797!2sIndex%20Group%20HQ%20Ayaza%C4%9Fa%20Mahallesi%20Mimar%20Sinan%20Sokak%20No%3A21%20Seba%20Office%20Boulevard%20D%20Blok%20Kat%3A%201%20B%C3%B6l%C3%BCm%20No%3A11%2034485%20Sar%C4%B1yer!3m2!1d41.1092867!2d28.994287999999997!5e0!3m2!1str!2str!4v1649700846022!5m2!1str!2str'
                              width='600'
                              height='600'
                              style={{ border: 0 }}
                              loading='lazy'
                            ></iframe>
                          </div>
                        </div>
                        <div className='col-md-6'>
                          <div className='aboutMap'>
                            <h5> {t('pages.about.aboutUs.logisticsCenter')}</h5>
                            <iframe
                              src='https://www.google.com/maps/embed?pb=!1m19!1m8!1m3!1d6034.260738087244!2d29.3865593!3d40.8690131!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x14cad8eeb590e1e5%3A0xee49792b96ab58c2!2sTeklos%20Teknoloji%20Lojistik%20A.%C5%9E.%20Cumhuriyet%20Mahallesi%20Yahyakaptan%20Caddesi%20No%3A10A%20D%3A2%2041420%20%C3%87ay%C4%B1rova%2FKocaeli!3m2!1d40.8690131!2d29.3865593!5e0!3m2!1str!2str!4v1649700863219!5m2!1str!2str'
                              width='600'
                              height='600'
                              style={{ border: 0 }}
                              loading='lazy'
                            ></iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`tab-pane fade show  ${activeTab === 'contact-tab' ? 'active' : ''}`}
                  id='contact'
                  role='tabpanel'
                  aria-labelledby='contact-tab'
                >
                  <div className='shop-cart'>
                    <form method='post' className='sep-top-md'>
                      <div className='row'>
                        <div className='col-lg-10 '>
                          <div className='row'>
                            <div className='col-lg-12'>
                              <div className='heading-text heading-line text-uppercase'>
                                <p>
                                  Firmamızla veya Sitemizle ilgili mesaj ve önerilerinizi bekliyoruz lütfen aşağıdaki
                                  formu doldurup düşüncelerinizi bizimle paylaşın.
                                </p>
                              </div>
                            </div>
                            <div className='col-lg-12 form-group'>
                              <label className='sr-only-size'>
                                {t('pages.about.aboutUs.communicationsDepartment')}
                                <span className='required'>*</span>
                              </label>
                              <IPSelectBox
                                isClearable
                                name='CommunicationDepartment'
                                value={values.CommunicationDepartment}
                                options={communicationDepartments}
                                onChangeSingle={(newVal: ISelectOption) => {
                                  setFieldValue('CommunicationDepartment', newVal)
                                }}
                              />
                              <div className='validationError'>
                                {(errors.CommunicationDepartment as any)?.label &&
                                  (touched.CommunicationDepartment as any)?.label &&
                                  (errors.CommunicationDepartment as any)?.label}
                              </div>
                            </div>
                            <div className='col-lg-6 form-group'>
                              <label className='sr-only-size'>
                                {t('pages.about.aboutUs.name')}
                                <span className='required'>*</span>
                              </label>
                              <IPInput
                                type='text'
                                className='form-control'
                                name='Name'
                                // placeholder={t('pages.about.aboutUs.name')}
                                value={values.Name}
                                onChange={handleChange}
                                errors={errors.Name as string}
                                touched={touched.Name as boolean}
                              />
                            </div>
                            <div className='col-lg-6 form-group'>
                              <label className='sr-only-size'>
                                Firma
                                <span className='required'>*</span>
                              </label>
                              <IPSelectBox
                                isClearable
                                name='CompanyId'
                                value={values.CompanyId}
                                onChangeSingle={(option: ISelectOption) => {
                                  setFieldValue('CompanyId', option)
                                }}
                                options={state.companies.map((com: ICompany) => {
                                  return {
                                    value: `${com.Id}`,
                                    label: com.Name,
                                  }
                                })}
                              />
                              <div className='validationError'>
                                {(errors.CompanyId as any)?.label &&
                                  (touched.CompanyId as any)?.label &&
                                  (errors.CompanyId as any)?.label}
                              </div>
                            </div>
                            <div className='col-lg-6 form-group'>
                              <label className='sr-only-size'>
                                {t('pages.about.aboutUs.yourCompany')}
                                <span className='required'>*</span>
                              </label>
                              <IPInput
                                type='text'
                                className='form-control'
                                name='DealerName'
                                // placeholder={t('pages.about.aboutUs.yourCompany')}
                                value={values.DealerName}
                                onChange={handleChange}
                                errors={errors.DealerName as string}
                                touched={touched.DealerName as boolean}
                              />
                            </div>
                            <div className='col-lg-6 form-group'>
                              <label className='sr-only-size'>
                                Email
                                <span className='required'>*</span>
                              </label>
                              <IPInput
                                type='text'
                                className='form-control'
                                name='Email'
                                value={values.Email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={errors.Email as string}
                                touched={touched.Email as boolean}
                              />
                            </div>
                            <div className='col-lg-6 form-group'>
                              <label className='sr-only-size'>
                                {t('pages.about.aboutUs.phone')}
                                <span className='required'>*</span>
                              </label>
                              <div>
                                <InputMask
                                  mask='(999)999-9999'
                                  name='Phone'
                                  type='text'
                                  className='form-control'
                                  value={values.Phone}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  errors={errors.Phone as string}
                                  touched={touched.Phone as boolean}
                                />
                              </div>
                            </div>
                            <div className='col-lg-12 form-group'>
                              <label className='sr-only-size'>{t('pages.about.aboutUs.message')}</label>
                              {/* <textarea className="form-control" placeholder="" rows={7}></textarea> */}
                              <IPTextarea
                                rows={7}
                                name='Message'
                                value={values.Message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errors={errors.Message as string}
                                touched={touched.Message as boolean}
                              />
                            </div>
                          </div>
                        </div>
                        <h6 className='my-2 ml-1'>{t('pages.about.aboutUs.notes')}</h6>
                        <div className='col-md-5'>
                          <button type='button' className='IPButton_Button__GWRch' onClick={() => handleSubmit()}>
                            {t('pages.about.aboutUs.send')}
                          </button>
                        </div>
                        <div className='col-md-5'>
                          <button type='button' className='IPButton_Button__GWRch' onClick={clearForm}>
                            {t('pages.about.aboutUs.reset')}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default componentWithContext(AboutUs)
