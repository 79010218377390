import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IOpenInvoicesResponse } from '../models/openInvoicesModel'

export class GetOpenInvoicesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetMyOpenInvoices',
      requesterInfo,
    })
  }

  public async getOpenInvoices(req: string[]): Promise<IOpenInvoicesResponse[]> {
    return await this.postAsync<IOpenInvoicesResponse[]>(req)
  }
}
