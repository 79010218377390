import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IAuthorizedServicesMain } from '../models/models'

export class GetAuthorizedServicesMainApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/authorizedServicesMain/listAll',
      requesterInfo,
    })
  }

  public async getAuthorizedServicesMain(): Promise<IAuthorizedServicesMain[]> {
    return await this.getAsync<IAuthorizedServicesMain[]>()
  }
}
