export enum OptionTypeEnum {
  CheckBox = 0,
  Radio = 1,
  Input = 2,
  CheckboxAndInput = 3,
  RadioAndInput = 4,
}

export interface ISurveyQuestion {
  Id: number
  SurveyId: number
  Text: string
  Survey: {
    Id: number
    Category: string
    Deleted: boolean
  }
}

export interface ISurveyAnswer {
  BitwiseId: number
  Id: number
  Options: string
  Question: null
  QuestionId: number
  IsInput: boolean
  TotalCount: number
  Deleted: boolean
}

export interface ISurveyInfo {
  Category: string
  CreatedDate: string
  Deleted: boolean
  Id: number
  Questions: any[]
}
export interface ISurveyItem {
  Answers: ISurveyAnswer[]
  CreatedDate: Date
  Id: number
  Survey: ISurveyInfo
  SurveyId: number
  Text: string
  OptionType: OptionTypeEnum
  Deleted: boolean
}

export interface IGetSurveyResponseModel {
  Data: ISurveyItem[]
}
