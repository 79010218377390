import React, { useState, useEffect } from 'react'
import IPButton from '../../../components/IPButton'
import IPImage from '../../../components/IPImage'
import IPModal from '../../../components/IPModal'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { GetDppTrainigContentApi } from '../services/getDppTrainigContentApi'
import Swal from 'sweetalert2'
import { ITraining } from '../models/models'
import { useCustomReducer } from '../../../base/customHooks'

interface ITrainingState {
  trainingData?: ITraining
}

function Training(props: BaseComponentWithContextProps) {
  const [productType, setProductType] = useState('')
  const [showSeed, setShowSeed] = useState(false)

  useEffect(() => {
    getTraining()
  }, [])
  const getInitialState = (): ITrainingState => {
    return {
    }
  }

  const intialState = getInitialState()

  const [state, setState] = useCustomReducer<ITrainingState>(intialState)

  const getTraining = async () => {
    props.showLoading()
    const api: GetDppTrainigContentApi = new GetDppTrainigContentApi({})
    api.getDppTrainigContent().then((res: ITraining) => {
      props.hideLoading()
      setState({ trainingData: res })
    }).catch((err: any) => {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    })
  }

  return (
    <div className='container current-account'>
      <div className='content col-lg-12 m-auto'>
        <br></br>
        <div className=''>
          {
            state.trainingData &&
            <div className='card-body'>
              <div className='' style={{ textAlign: 'center', fontSize: '20px' }}>
                <span className='h5'>EĞİTİM SAYFASINA HOŞ GELDİNİZ</span>
              </div>
              <div className='' style={{ textAlign: 'center', fontSize: '20px' }}>
                <span className='h5'>Detaylarına erişmek istediğiniz ürün grubunu seçiniz</span>
              </div>
              <div className='card-body'>
                {/* Button Set Area */}
                <div className='row m-t-10 mb-5' style={{ minHeight: '300px' }}>
                  <div className='col d-flex card-mobile-resize justify-content-center'>
                    <div className='room me-5 image-hover' onClick={() => setProductType('Mac')} >
                      <div className='room image-hover cursor-pointer'>
                        <div className='text-center'>
                          <IPImage className='Mac Mac-original' src='/images/apple/mac.png' alt='' />
                          <IPImage className='Mac Mac-hover' src='/images/apple/mac.png' alt='' />
                        </div>
                        <div className='row' style={{ textAlign: 'center' }}>
                          <h3 style={{ marginTop: '-48px' }}>Mac</h3>
                        </div>
                      </div>
                    </div>
                    <div className='room' onClick={() => setProductType('iPad')}>
                      <div className='room image-hover cursor-pointer'>
                        <div className='text-center'>
                          <IPImage className='Ipad Ipad-original' src='/images/apple/ipad.png' alt='' />
                          <IPImage className='Ipad Ipad-hover' src='/images/apple/ipad.png' alt='' />
                        </div>
                        <div className='row' style={{ textAlign: 'center' }}>
                          <h3 style={{ marginTop: '-48px' }}>iPad</h3>
                        </div>
                      </div>
                    </div>
                    <div className='room' onClick={() => setProductType('iPhone')}>
                      <div className='room ms-5 text-center image-hover cursor-pointer'>
                        <div className=' text-center'>
                          <IPImage className='Iphone Iphone-original' src='/images/apple/iPhone.png' alt='' />
                          <IPImage className='Iphone Iphone-hover' src='/images/apple/iPhone.png' alt='' />
                        </div>
                        <div className='row' style={{ textAlign: 'center' }}>
                          <h3 style={{ marginTop: '-48px' }}>iPhone</h3>
                        </div>
                      </div>
                    </div>
                    <div className='room ms-5' onClick={() => setShowSeed(true)}>
                      <div className='room text-center image-hover cursor-pointer'>
                        <div className=' text-center'>
                          <IPImage className='Seed Seed-original' src='/images/SEED_xs.png' alt='' />
                          <IPImage className='Seed Seed-hover' src='/images/SEED_xs.png' alt='' />
                        </div>
                        <div className='row' style={{ textAlign: 'center' }}>
                          <h3 style={{ marginTop: '-48px' }}>Seed</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {productType === 'Mac' ? (
                  <div className='row m-t-20'>
                    {
                      state.trainingData && state.trainingData.Mac.length > 0 && state.trainingData.Mac.map((mac, index) => (
                        <div className='col-lg-6 card-mobile-resize m-t-20' key={index}>
                          <div
                            className='most-popular-products-borderless'
                            style={{
                              minHeight: '300px',
                              height: '300px',
                            }}
                          >
                            <div className='room'>
                              <div className='room-image text-center'>
                                {
                                  mac.Link ?
                                    <a href={mac.Link} target='_blank' rel="noreferrer">
                                      <IPImage src={mac.Image} alt='' style={{ maxHeight: '150%' }} className='popular_product' />
                                    </a>
                                    : (
                                      mac.Image ? <IPImage src={mac.Image} alt='' style={{ maxHeight: '150%' }} className='popular_product' /> : ""
                                    )
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ) : productType === 'iPad' ? (
                  <div className='row m-t-20'>
                    {
                      state.trainingData && state.trainingData.Ipad.length > 0 && state.trainingData.Ipad.map((ipad, index) => (
                        <div className='col-lg-6 card-mobile-resize m-t-20' key={index}>
                          <div
                            className='most-popular-products-borderless'
                            style={{
                              minHeight: '300px',
                              height: '300px',
                            }}
                          >
                            <div className='room'>
                              <div className='room-image text-center'>
                                {
                                  ipad.Link ?
                                    <a href={ipad.Link} target='_blank' rel="noreferrer">
                                      <IPImage src={ipad.Image} alt='' style={{ maxHeight: '150%' }} className='popular_product' />
                                    </a>
                                    : (
                                      ipad.Image ? <IPImage src={ipad.Image} style={{ maxHeight: '150%' }} alt='' className='popular_product' /> : ""
                                    )
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ) : productType === 'iPhone' ? (
                  <div className='row m-t-20'>
                    {
                      state.trainingData && state.trainingData.IPhone.length > 0 && state.trainingData.IPhone.map((iPhone, index) => (
                        <div className='col-lg-6 card-mobile-resize m-t-20' key={index}>
                          <div
                            className='most-popular-products-borderless'
                            style={{
                              minHeight: '300px',
                              height: '300px',
                            }}
                          >
                            <div className='room'>
                              <div className='room-image text-center'>
                                {
                                  iPhone.Link ?
                                    <a href={iPhone.Link} target='_blank' rel="noreferrer">
                                      <IPImage src={iPhone.Image} alt='' style={{ maxHeight: '150%' }} className='popular_product' />
                                    </a>
                                    : (
                                      iPhone.Image ? <IPImage src={iPhone.Image} alt='' style={{ maxHeight: '150%' }} className='popular_product' /> : ""
                                    )
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          }

        </div>
      </div>
      <IPModal show={showSeed} hideConfirm={true} width={500} onClose={() => setShowSeed(false)}>
        <div className='etkin d-flex justify-content-center'>
          <div className='seed'>
            <a href='/assetWeb/docs/SEED Kayıt - DPP.pdf' target='_blank' className='file'>
              <IPButton type='button' className='btn btn-primary m-l-10' text='SEED Kayıt' style={{ margin: '15px' }} />
            </a>
          </div>
          <div className='seedlogin'>
            <a
              href='https://idmsa.apple.com/IDMSWebAuth/signin?accountName=null&appIdKey=f5824a1b767cb641b2e714df18b5347afa62797b7a1c18f449999755578e2b82&rv=2&offerNativeTakeOver=false&path=/&language=US-EN'
              target='_blank'
              className='file'
              rel="noreferrer"
            >
              <IPButton type='button' className='btn btn-success' text='SEED Giriş' style={{ margin: '15px' }} />
            </a>
          </div>
        </div>
      </IPModal>
    </div >
  )
}
export default componentWithContext(Training)
