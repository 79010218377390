import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export interface ListCurrencyLogHistoryResponse {
  Date: Date
  Usd: number
  Euro: number
  Gbp: number
}

export class ListCurrencyLogHistoryService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/currency/listCurrencyLogHistory',
      requesterInfo,
    })
  }

  public async Post(req: { StartDate: Date; EndDate: Date }): Promise<ListCurrencyLogHistoryResponse[]> {
    return await this.postAsync<ListCurrencyLogHistoryResponse[]>(req)
  }
}
