import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import {
  Basket,
  ISetBasketManagementByIdsRequest,
  ISetBasketManagementByIdsResponse,
  ISimpleBasket,
  ISimpleCampaign,
  ISingleCampaignReplaceRequest,
  ISwitchBasketRequest,
  ISwitchBasketResponse,
} from './basketApi'

export interface IAddItemToBasketRequest {
  ProductId: number
  Quantity: number
  IsOppurtunityStock: boolean
  OpportunitiyStockId: number | null
}

export interface IReduceProductQuantityOnBasketRequest {
  DetailId: number
  Quantity: number
}

export interface IIncreaseProductQuantityOnBasketRequest {
  DetailId: number
  Quantity: number
}

export class GetBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/getBasketUI',
      requesterInfo,
    })
  }

  public async getBasket(): Promise<Basket> {
    return await this.getAsync<Basket>()
  }
}

export class AddItemToBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/addToBasketUI',
      requesterInfo,
    })
  }

  public async addItemToBasket(request: IAddItemToBasketRequest): Promise<ISimpleBasket> {
    return await this.postAsync<ISimpleBasket>(request)
  }
}

export class GetCreateDemandApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/createDemand/pId',
      requesterInfo,
    })
  }

  public async getCreateDemand(): Promise<any> {
    return await this.getAsync<any>()
  }
}
export class RemoveItemFromBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/deleteBasketDetailUI',
      requesterInfo,
    })
  }

  public async removeItemFromBasket(basketItemId: number): Promise<ISimpleBasket> {
    return await this.postAsync<ISimpleBasket>(basketItemId)
  }
}

export class ReduceProductQuantityOnBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/minusToQuantityUI',
      requesterInfo,
    })
  }

  public async reduceProductQuantityOnBasket(request: IReduceProductQuantityOnBasketRequest): Promise<ISimpleBasket> {
    return await this.postAsync<ISimpleBasket>(request)
  }
}

export class IncreaseProductQuantityOnBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/MaxToQuantityUI',
      requesterInfo,
    })
  }

  public async increaseProductQuantityOnBasket(
    request: IIncreaseProductQuantityOnBasketRequest
  ): Promise<ISimpleBasket> {
    return await this.postAsync<ISimpleBasket>(request)
  }
}

export class ClearBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/deleteBasketDetail',
      requesterInfo,
    })
  }

  public async clearBasket(basketId: number): Promise<Basket> {
    this.props.url = `${this.props.url}/id=${basketId}`

    return await this.postAsync<Basket>()
  }
}

export class SwitchBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/switchBasket',
      requesterInfo,
    })
  }

  public async switchBasket(request: ISwitchBasketRequest): Promise<ISwitchBasketResponse> {
    return await this.postAsync<ISwitchBasketResponse>(request)
  }
}

export class SetBasketManageByBasketIdsService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/setbasketManagementUI',
      requesterInfo,
    })
  }

  public async setBasketManagement(
    request: ISetBasketManagementByIdsRequest
  ): Promise<ISetBasketManagementByIdsResponse> {
    return await this.postAsync<ISetBasketManagementByIdsResponse>(request)
  }
}

export class ChangeGiftItemOnBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/SingleCampaignReplaceUI',
      requesterInfo,
    })
  }

  public async changeGiftItem(request: ISingleCampaignReplaceRequest): Promise<ISimpleBasket> {
    return await this.postAsync<ISimpleBasket>(request)
  }
}

export class GetCampaignListOnBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/CampaignList',
      requesterInfo,
    })
  }

  public async getCampaignList(): Promise<ISimpleCampaign[]> {
    return await this.getAsync<ISimpleCampaign[]>()
  }
}

// export class GetApplyCampaignOnBasketService extends ClientProxy {
//   constructor(requesterInfo: IRequesterInfo) {
//     super({
//       url: 'basket2/ApplyCampaignUI',
//       requesterInfo,
//     })
//   }

//   public async getApplyCampaign(id: number): Promise<ISimpleBasket> {
//     this.props.url = `${this.props.url}/${id}`
//     return await this.getAsync<ISimpleBasket>()
//   }
// }

export class GetApplyCampaignOnBasketService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/ApplyCampaignUI',
      requesterInfo,
    })
  }

  public async getApplyCampaign(data: any): Promise<ISimpleBasket> {
    // this.props.url = `${this.props.url}/${id}`
    return await this.postAsync<ISimpleBasket>(data)
  }
}
