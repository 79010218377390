import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IExtract, IAddExtractRequest } from '../models/models'

export class GetCurrentAccountStatementApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealerInformation/getCurrentAccountStatement',
      requesterInfo,
    })
  }

  public async getCurrentAccountStatement(request: IAddExtractRequest): Promise<IExtract[]> {
    return await this.postAsync<IExtract[]>(request)
  }
}
