import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IAddImageRequest, IImage } from '../models/requestModels'

export class PostImageApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/image-add',
      requesterInfo,
    })
  }

  public async addImage(request: IAddImageRequest): Promise<IImage[]> {
    return await this.postAsync<IImage[]>(request)
  }
}
