import React from 'react'

function index(props: any) {
  return (
    <div className='container-fluid'>
      <div className='heading-text'>
        <h4>Bilgi Güncelleme</h4>
      </div>
      <div className='row'>
        <div className='col-lg-12'>
          <h5 style={{ color: '#616161' }}>
            Firmanıza ait bilgilleri güncellemek için firma cari kodunuz, vergi levhanızı, firma adınız ve güncel bilgilerinizi irem.dede@index.com.tr adresine mail yolu ile iletebilirsiniz

            Mail teyidi için: 0 (212) 331 23 71 (İrem DEDE)
          </h5>
        </div>
      </div>
    </div>
  )
}

export default index
