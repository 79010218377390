import { IOrder } from '../models/requestModels'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class GetOrderListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: '/admin/order/fetchAll',
      requesterInfo,
    })
  }

  public async getOrders(): Promise<IOrder[]> {
    return await this.getAsync<IOrder[]>()
  }
}
