import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IyzicoInstallmentRequestModel, IProductIyzicoResponseModel } from '../models/getProductByIDResponseModel'

export class GetIyzicoInstallmentsListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'general/listIyzicoInstallments',
      requesterInfo,
    })
  }

  public async getIyzicoInstallments(request: IyzicoInstallmentRequestModel): Promise<IProductIyzicoResponseModel[]> {
    return await this.postAsync<IProductIyzicoResponseModel[]>(request)
  }
}
