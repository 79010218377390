import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ICampaignsProducts } from '../../Homepage/commonModels/models'
export class GetCampaignsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'campaign/CampaignLineList',
      requesterInfo,
    })
  }

  public async getCampaigns(request: ICampaignsProducts): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
