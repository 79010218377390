import React from 'react'
import { Bar } from 'react-chartjs-2'
import { getRandomColor } from '../../../base/utils/dataHelper'
import { IChannelData } from '../models/models'
import { optionCSS } from 'react-select/dist/declarations/src/components/Option'

interface IChartProps {
  report: any[]
  options: any

}

function StackPage(props: IChartProps) {
  const productModelCountsStok = {};
  props.report.filter(a => a.Status == false).forEach(item => {
    const productModel = item.ProductModel;
    if (productModel in productModelCountsStok) {
      productModelCountsStok[productModel]++;
    } else {
      productModelCountsStok[productModel] = 1;
    }
  });

  // Her bir seçeneğin (option) değerine karşılık gelen tekrar sayılarını bul
  const optionDataStok = props.options.map(option => {
    const productModel = option.value;
    return productModelCountsStok[productModel] || 0; // Eğer ProductModel yoksa, 0 olarak dön
  });

  const productModelCountsSatilan = {};
  props.report.filter(a => a.Status == true).forEach(item => {
    const productModel = item.ProductModel;
    if (productModel in productModelCountsSatilan) {
      productModelCountsSatilan[productModel]++;
    } else {
      productModelCountsSatilan[productModel] = 1;
    }
  });

  // Her bir seçeneğin (option) değerine karşılık gelen tekrar sayılarını bul
  const optionDataSatilan = props.options.map(option => {
    const productModel = option.value;
    return productModelCountsSatilan[productModel] || 0; // Eğer ProductModel yoksa, 0 olarak dön
  });


  const dataPie = {
    labels: props.options.map(a => a.value),
    datasets: [
      {
        label: 'Stokta',
        data: optionDataStok,
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
      },
      {
        label: 'Satılan',
        data: optionDataSatilan,
        backgroundColor: 'rgba(54, 162, 235, 0.5)'
      }
    ]
  }

  return (
    <div>
      <Bar data={dataPie} options={{
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }} />
    </div>
  )
}

export default StackPage
