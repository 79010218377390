import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { SellerApplicationRequestModel } from '../models/request/sellerApplicationRequestModel'
import {
  CityResponseModel,
  DistrictResponseModel,
  SellerApplicationResponseModel,
  TownResponseModel,
} from '../models/response/sellerApplicationResponseModel'

interface IDealerControlRequest {
  ID: number
  TaxOrTcNumber: string
}
export class SellerApplicationService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'applicationRequest/post',
      requesterInfo,
    })
  }

  public async postSellerApplication(request: SellerApplicationRequestModel): Promise<SellerApplicationResponseModel> {
    return await this.postAsync<SellerApplicationResponseModel>(request)
  }

  public async getSellerApplication(trackingNumber): Promise<SellerApplicationRequestModel> {
    this.props.url = `applicationRequest/GetDetail`
    return await this.postAsync<SellerApplicationRequestModel>(trackingNumber)
  }

  public async getSellerApplicationMail(trackingNumber): Promise<SellerApplicationRequestModel> {
    this.props.url = `applicationRequest/GetDetailMail`
    return await this.postAsync<SellerApplicationRequestModel>(trackingNumber)
  }

  public async dealerControl(request: IDealerControlRequest): Promise<boolean> {
    this.props.url = `applicationRequest/dealerControl`
    return await this.postAsync<boolean>(request)
  }

  public async fetchApplicationConfirmationDetails(aid): Promise<any> {
    this.props.url = `applicationRequest/fetchApplicationConfirmationDetails/${aid}`
    return await this.getAsync<any>()
  }

  public async sendConfirmationCode(aid): Promise<any> {
    this.props.url = 'applicationRequest/sendConfirmationCode'
    return await this.postAsync<any>(aid)
  }

  public async confirmTheContract(request): Promise<any> {
    this.props.url = `applicationRequest/confirmTheContract`
    return await this.postAsync<any>(request)
  }

  public async getCity(): Promise<CityResponseModel> {
    this.props.url = `CityTownNeighbourhood/getCity`
    return await this.getAsync<CityResponseModel>()
  }

  public async getTown(plate: number): Promise<TownResponseModel> {
    this.props.url = `CityTownNeighbourhood/getTown/${plate}`
    return await this.postAsync<TownResponseModel>()
  }

  public async getDistrict(townCode: number): Promise<DistrictResponseModel> {
    this.props.url = `CityTownNeighbourhood/getDistrict/${townCode}`
    return await this.postAsync<DistrictResponseModel>()
  }
}
