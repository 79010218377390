import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { BankListResponse, BankInstallmentReportsRequest, BankInstallmentReportsResponse } from '../models/models'

export class GetWhiteDealerListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/bankInstallmentReports',
      requesterInfo,
    })
  }

  public async bankInstallmentReports(req: BankInstallmentReportsRequest): Promise<BankInstallmentReportsResponse[]> {
    return await this.postAsync<BankInstallmentReportsResponse[]>(req)
  }

  public async getBanks(): Promise<BankListResponse[]> {
    this.props.url = 'admin/dealerPaymentOperation/bankList'
    return await this.getAsync<BankListResponse[]>()
  }
}
