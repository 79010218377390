import { css } from '@emotion/css'

const InputWrapperStyle = css`
  position: relative;
  font-weight: 600;
  font-size: 12px;
  line-height: 13px;
  color: #0e0c28;
  border: none;
  height: 50px;
  background-color: #f6f6f6;

  &.invalid {
    background-color: rgba(255, 0, 0, 10%);

    label {
      color: inherit !important;
    }
  }
  label {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    transition: transform 0.3s ease;

    span {
      color: red;
      font-weight: 500;
    }
  }
  input {
    height: auto;
    border: none !important;
    background-color: transparent;
    margin-top: 12px;
  }
  .active,
  input:focus + label {
    transform: translateY(-20px);
    color: #699bf7;
  }
`

interface IInputCampaign
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string
  type?: 'text' | 'number' | 'datetime-local'
  label: string
  required?: boolean
  errors?: any
  touched?: any
  isActive?: boolean
}

function InputCampaign(props: IInputCampaign) {
  const { id, placeholder, type, label, required, errors, touched, isActive, ...otherProps } = props

  return (
    <div className={`${InputWrapperStyle} ${errors && touched ? 'invalid' : ''}`}>
      <input id={id} placeholder={placeholder} type={type ? type : 'text'} {...otherProps} />
      <label
        className={(props.value && props.value.toString().length > 0) || isActive ? 'active' : ''}
        htmlFor={id}
        style={{ paddingBottom: '20px' }}
      >
        {label}
        {required && <span> * {errors && touched && errors}</span>}{' '}
      </label>
    </div>
  )
}

export default InputCampaign
