import * as yup from 'yup'
import Translations from '../../translations'
import { translate } from '../../base/localization'

const t = translate(Translations)
const validations = yup.object().shape({
  taxCode: yup
    .string()
    .min(3, t('pages.loginPage.validation.characterMustBe', ['Hesap kodu', '3']))
    .required(t('pages.loginPage.validation.requiredfield')),
  userName: yup
    .string()
    .min(3, t('pages.loginPage.validation.characterMustBe', ['Kullanıcı adı', '3']))
    .required(t('pages.loginPage.validation.requiredfield')),
  password: yup
    .string()
    .min(1, t('pages.loginPage.validation.characterMustBe', ['Parolanız', '1']))
    .required(t('pages.loginPage.validation.requiredfield')),
})

export default validations
