import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IMyOffers, IMyOffersRequest } from '../models/responseModel'

export class GetMyOffersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'offer/myOffers',
      requesterInfo,
    })
  }

  public async getMyOffers(request: IMyOffersRequest): Promise<IMyOffers[]> {
    return await this.postAsync<IMyOffers[]>(request)
  }
}
