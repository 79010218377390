import { css } from '@emotion/css'
import React, { useEffect } from 'react'

interface IPagination {
  totalPage: number
  currentPage: number
  setPage: (page: number, type: boolean) => void
}

const paginationCSS = css`
  display: flex;
  justify-content: center;

  .page-item {
    cursor: pointer;

    &.active .page-link {
      background-color: #333;
      border: 1px solid #333;
    }

    &.disabled .page-link {
      background-color: #dfdfdf;
    }
  }

  .page-link {
    color: #333;
  }
`

export default function Pagination(props: IPagination) {
  const Item = p => {
    return (
      <div className='page-item' onClick={() => props.setPage(p, true)}>
        <div className='page-link'>{p}</div>
      </div>
    )
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.currentPage])

  return (
    <nav className={paginationCSS}>
      <div
        className={`page-item ${props.currentPage === 1 ? 'disabled' : ''}`}
        onClick={() => {
          if (props.currentPage !== 1) {
            props.setPage(props.currentPage - 1, true)
          }
        }}
      >
        <div className='page-link' aria-label='Previous'>
          <span aria-hidden='true'>
            <i className='fa fa-angle-left'></i>
          </span>
          <span className='sr-only'>
            <i className='fa fa-angle-left'></i>
          </span>
        </div>
      </div>

      {props.currentPage > 3 && Item(1)}

      {props.currentPage > 4 && (
        <div className='page-item' onClick={() => props.setPage(props.currentPage - 3, true)}>
          <div className='page-link'>...</div>
        </div>
      )}

      {props.currentPage > 2 && Item(props.currentPage - 2)}
      {props.currentPage > 1 && Item(props.currentPage - 1)}

      <div className='page-item active'>
        <div className='page-link'>{props.currentPage}</div>
      </div>

      {props.currentPage < props.totalPage && Item(props.currentPage + 1)}
      {props.currentPage < props.totalPage - 1 && Item(props.currentPage + 2)}

      {props.currentPage < props.totalPage - 3 && (
        <div className='page-item' onClick={() => props.setPage(props.currentPage + 3, true)}>
          <div className='page-link'>...</div>
        </div>
      )}

      {props.currentPage < props.totalPage - 3 && Item(props.totalPage)}

      <div
        className={`page-item ${props.currentPage === props.totalPage ? 'disabled' : ''}`}
        onClick={() => {
          if (props.currentPage !== props.totalPage) {
            props.setPage(props.currentPage + 1, true)
          }
        }}
      >
        <div className='page-link' aria-label='Next'>
          <span aria-hidden='true'>
            <i className='fa fa-angle-right'></i>
          </span>
          <span className='sr-only'>
            <i className='fa fa-angle-right'></i>
          </span>
        </div>
      </div>
    </nav>
  )
}
