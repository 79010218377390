import { useState } from 'react'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { translate } from '../../../base/localization'
import { splitStockForTruck, stockIndicator } from '../../../base/utils/dataHelper'
import AddToBasket from '../../../components/AddToBasket'
import IPImage from '../../../components/IPImage'
import IPInput from '../../../components/IPInput'
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks'
import { addItemToBasketRedux } from '../../../redux/slices/basket/basketSlice'
import {
  addToFavoriteProductListRedux,
  productIsOnFavoriteListRedux,
  removeFromFavoriteProductListRedux,
} from '../../../redux/slices/favoriteProduct/favoriteProductSlice'
import Translations from '../../../translations'
import { IProduct } from '../models/response/getProductListResponseModel'
import Star from './Icons/Star'
import IPPriceDisplay from '../../../components/IPPriceDisplay'

interface IProductCard1Props {
  campaignPrice: number
  product: IProduct
  isSelected?: boolean
  onSelectProductForCompare?: () => void
}

export default function ProductCard4(props: IProductCard1Props) {
  const [quantity, setQuantity] = useState(1)
  const t = translate(Translations)
  const navigate: NavigateFunction = useNavigate()
  const dispatch = useAppDispatch()
  const isProductOnFavorites = useAppSelector(productIsOnFavoriteListRedux(props.product.Id))

  const handleQuantity = (e: any) => {
    setQuantity(e.target.value)
  }

  const animateIcon = (e: any) => {
    e.target.closest('svg').classList.add('clickAnimation')
    setTimeout(() => {
      e.target.closest('svg').classList.remove('clickAnimation')
    }, 300)
  }

  const handleStar = (e: any, productId: number) => {
    if (isProductOnFavorites) {
      removeFavorite(productId)
    } else {
      addFavorite(productId)
    }

    animateIcon(e)
  }

  const addFavorite = (productId: number) => {
    dispatch(addToFavoriteProductListRedux(productId))
  }

  const removeFavorite = (productId: number) => {
    dispatch(removeFromFavoriteProductListRedux(productId))
  }

  const addToBasketOrCreateDemand = () => {
    dispatch(
      addItemToBasketRedux({
        ProductId: props.product.Id,
        Quantity: 1,
        IsOppurtunityStock: props.product.OpportunityId ? true : false,
        OpportunitiyStockId: props.product.OpportunityId ?? 0,
      })
    )

    Swal.fire({
      html: `<h5>${t('components.AddToBasket.productWasAddedToBasket')}</h5>`,
      confirmButtonText: `${t('components.AddToBasket.goToBasket')}`,
      confirmButtonColor: '#ec4c4c',
      cancelButtonText: `${t('components.AddToBasket.continueToShopping')}`,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigate('/mycard')
      } else if (result.isDenied) {
      }
    })
  }

  return (
    <div className='productCard' key={props.product.Id}>
      {props.product.OnCampaign && (
        <div className='indicator topLeft'>
          <img src='/images/products/discount.svg' alt='' />
        </div>
      )}
      {props.product?.Stock?.OpportunityFlag > 0 ||
        (props.product?.Stock?.Opportunity > 0 && (
          <div className='indicator topLeft2'>
            {props.product?.Stock?.OpportunityFlag > 0 ? (
              <div className='hasTooltip'>
                <IPImage src='/images/phone-solid.svg' alt='' style={{ width: '15px' }} />
                <div className='otooltip'>Fırsat Stoğu Rezerve Et</div>
              </div>
            ) : (
              props.product?.Stock?.Opportunity > 0 && (
                <div className='hasTooltip'>
                  <a onClick={() => addToBasketOrCreateDemand()}>
                    <IPImage src='/images/products/box-percent.png' alt='' />
                    <div className='otooltip'>
                      Fırsat Stok:{' '}
                      {props.product.Stock && props.product?.Stock?.Opportunity
                        ? stockIndicator(props.product?.Stock?.Opportunity)
                        : '0'}
                    </div>
                  </a>
                </div>
              )
            )}
          </div>
        ))}
      <input
        className='form-check-input check-box productCheckbox'
        type='checkbox'
        checked={props.isSelected}
        onChange={() => props.onSelectProductForCompare && props.onSelectProductForCompare()}
        style={{ top: 80, left: 10, position: 'absolute' }}
      />
      <div className='indicator topRight'>-10%</div>
      <div className='indicator topRight2'>Yeni</div>
      <div className='productImage'>
        <Link to={`/product/detail/${props.product.Id}`} id={props.product.Name}>
          <img src={props.product.Image} alt='' />
        </Link>
      </div>

      <div className='actions'>
        {/* <div className="action">
          <Heart
            color="#C3C3C4"
            hover="#E80000"
            isFavorite={props.isFavoriteProduct}
            click={handleFavorite}
          />
        </div> */}
        <Star
          color='#C3C3C4'
          hover='red'
          isStarred={isProductOnFavorites}
          isFavoriteProduct={isProductOnFavorites}
          click={e => handleStar(e, props.product.Id)}
        />
        {/* <div className="action">
          <img src="/images/products/quality2.png" alt="" />
        </div> */}
        <div className='action'>
          <img src='/images/index.png' alt='' />
        </div>
      </div>

      <div className='productInfo2 text-left'>
        <div className='productName'>{props.product.Name}</div>
        {/* <div className='productModel'>Model: {props.product.Model}</div> */}
        <div className='productCode'>Ürün Kodu: {props.product.ProductCode}</div>
      </div>

      <div className='productInfoBox'>
        <div className='productBrand'>
          <img src='/images/products/asus-logo.png' alt='' />
        </div>
        <div>
          <span className='priceTitle'>Liste Fiyatı: </span>
          <span className='price'>
            {/* {props.product?.Prices?.Normal?.Price} */}
            <IPPriceDisplay price={props.product?.Prices?.Normal?.Price} />
          </span>
        </div>
        <div>
          <span className='priceTitle'>Son kullanıcı fiyatı: </span>
          <span className='price'>
            {/* {props.product?.Prices?.EndUser?.Price} */}
            <IPPriceDisplay price={props.product?.Prices?.EndUser?.Price} />
          </span>
        </div>
        <div>
          <span className='priceTitle2'>Size Özel: </span>
          <span className='price2'>
            {/* {props.product?.Prices?.EndUser?.Price} */}
            <IPPriceDisplay price={props.product?.Prices?.EndUser?.Price} />
          </span>
        </div>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='stock m-t-5'>
            {props.product.Stock && props.product.Stock.Stock
              ? props.product.Stock.Stock > 50
                ? '50 +'
                : props.product.Stock.Stock
              : '0'}
          </div>
          {props.product?.StockForTruck && splitStockForTruck(props.product?.StockForTruck).length > 0 && (
            <div className='hasTooltip'>
              <img src='/images/products/truck.svg' alt='' />
              <div className='otooltip'>
                {splitStockForTruck(props.product?.StockForTruck).map(st => {
                  return 'tarih : ' + st.date + ' stok : ' + st.stok
                })}
              </div>
            </div>
          )}
          <div>
            Adet:{' '}
            <IPInput
              type='number'
              className='stockNumber myCardStockNumber'
              min={0}
              value={quantity}
              onChange={e => handleQuantity(e)}
            />
          </div>
        </div>
      </div>

      <div className='productButton'>
        <AddToBasket
          product={props.product}
          quantity={quantity}
          disabled={props.product.Brand === 'HPE' && `${props.product.Company.Code}` === '2300'}
        />
      </div>
    </div>
  )
}
