import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ISubDealerResponse } from '../models/models'

export class AddVirtualPosInfosApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/virtualPosInfos/add',
      requesterInfo,
    })
  }

  public async addVirtualPosInfos(request: ISubDealerResponse): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
