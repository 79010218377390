/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from 'react'
import Image from '../../../components/IPImage'
import { logoutUser } from '../../../base/proxy/authenticate'
import SearchInput from '../../../pages/ProductList/components/SearchInput'
import { useCustomReducer } from '../../../base/customHooks'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import categoryData from '../../../api/category.json'
import { IProductSearchResponse } from '../../../pages/ProductList/models/response/getProductListResponseModel'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { IProductCard1Props } from '../../../../src/pages/ProductList/components/ProductCard1'
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks'
import { changeTheme } from '../../../redux/slices/theme/themeSlice'
import { DATAGATE_THEME, DESPEC_THEME, INDEX_THEME, NETEX_THEME } from '../../../base/theme/themeConfig'
import { css } from '@emotion/css'
import { searchHistoryKeywordsReducer } from '../../../redux/slices/searchHistory/searchHistorySlice'
import IPCheckbox from '../../../components/IPCheckBox'
import { GetExchangeRatesApi } from './services/getExchangeRatesApi'
import { IExchangeRate, IMenu, IRepresentatives } from './models'
import { GetMenusApi } from './services/getMenusApi'
import { GetRepresentativesApi } from './services/getRepresentativesApi'
import { showFavoriteProductAddingSpinner } from '../../../redux/slices/favoriteProduct/favoriteProductSlice'
import { addBasketError, basketApiCallStatusRedux, clearBasketError } from '../../../redux/slices/basket/basketSlice'
import Swal from 'sweetalert2'
import '../../css/Header.scss'
import { GetOpenBoxByUserIdApi } from '../../../pages/Openbox/services/getOpenBoxByUser'
import { IOpenBox } from '../../../pages/Openbox/models'
import { KeyValuePair } from '../../../base/models'
import { clearOpenedPopupsCompanyIds } from '../../../redux/slices/user/userSlice'
import IPButton from '../../../components/IPButton'
import { GetDealerRemoteListApi } from './services/getDealerRemoteListApi'
import { GetRemoteDealerChangeApi } from './services/getRemoteDealerChangeApi'
import { GetClearRemoteApi } from './services/GetClearRemoteApi'
import IPInput from '../../../components/IPInput'
import { ClientStorage } from '../../../base/storage'
import { ConfigHelper } from '../../../base/constants'
import { GetCompatibilityTableApi } from '../../../pages/PriceLists/services/getCompatibilityTableApi'
import { ICompatibilityTable } from '../../../pages/PriceLists/models/models'
import { useAtom } from 'jotai'
import { hideHeaderAtom } from '../../../atoms'
import AddToBasket from '../../../components/AddToBasketCopy'
import { PostCompanyHomepageClicksApi } from './services/postCompanyHomepageClicksApi'
import { CompanyPageClickRequestModel } from './models/index'
import IPImage from '../../../components/IPImage'

interface HeaderState {
  searchOnClickValue: string
  searchOnChangeValue: string
  categories: any[]
  searchResult?: IProductSearchResponse[]
  exchangeRate?: IExchangeRate
  searchValue?: string
  selectedCompanies: number[]
  selectedCategories: number[]
  menus: IMenu[]
  companyMenus: IMenu[]
  representatives: IRepresentatives[]
  openBoxes: number
  isRepsActive: boolean
  // dealers: any[]
  dealerOption: string
  dealerName: string
  compatibilityTable: ICompatibilityTable[]
  closeMS: boolean
}

const Header = React.memo((props: BaseComponentWithContextProps & IProductCard1Props) => {
  const dispatch = useAppDispatch()

  const basketError = useAppSelector(addBasketError)
  const basketCallStatus = useAppSelector(basketApiCallStatusRedux)
  const navigate: any = useNavigate()
  const location = useLocation()

  const t = translate(Translations)
  const searchHistoryKeywords = useAppSelector(searchHistoryKeywordsReducer)
  const productAddingToFavoriteSpinner = useAppSelector(showFavoriteProductAddingSpinner)

  const getInitialState = (): HeaderState => {
    return {
      searchOnClickValue: '',
      searchOnChangeValue: '',
      categories: categoryData.category,
      selectedCompanies: [],
      selectedCategories: [],
      menus: [],
      companyMenus: [],
      representatives: [],
      openBoxes: 0,
      isRepsActive: false,
      // dealers: [],
      dealerOption: '',
      dealerName: '',
      compatibilityTable: [],
      closeMS: false,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<HeaderState>(intialState)

  const [hideHeader] = useAtom(hideHeaderAtom)

  const url = window.location.pathname.split('/').pop()

  useEffect(() => {
    getExchangeRates()
    getMenus()
    //bu if koşulu login olduktan hemen sonra tetiklenmiyor!
    //const shouldFetchData = window.performance.navigation.type === 1
    //if (shouldFetchData) {
    getRepresentatives()
    if (window.location.pathname != '/openbox') getOpenBoxes()
    getCompatibilityTable()
    //}
  }, [url])

  const getOpenBoxes = () => {
    const getOpenBoxApi: GetOpenBoxByUserIdApi = new GetOpenBoxByUserIdApi({})
    getOpenBoxApi.getOpenBox().then((res: IOpenBox[]) => {
      const data = res.filter(r => {
        return r.Enable
      })
      setState({ openBoxes: data.length })
    })
  }

  useEffect(() => {
    if (basketError && basketError.description && !basketError.isProductAdded && basketCallStatus === 'idle') {
      Swal.fire({
        icon: 'error',
        title: basketError.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then(() => {
        dispatch(clearBasketError())
      })
      dispatch(clearBasketError())
    } else if (basketError?.isProductAdded) {
      if (location.pathname?.indexOf('/mycard') > -1) {
        return
      }

      Swal.fire({
        html: `<h5>${t('components.AddToBasket.productWasAddedToBasket')}</h5>`,
        confirmButtonText: `${t('components.AddToBasket.goToBasket')}`,
        confirmButtonColor: '#ec4c4c',
        cancelButtonText: `${t('components.AddToBasket.continueToShopping')}`,
        showCancelButton: true,
        timer: 4000,
        timerProgressBar: true,
      }).then(result => {
        dispatch(clearBasketError())
        if (result.isConfirmed) {
          navigate('/mycard')
        } else if (result.isDenied) {
        }
      })
      dispatch(clearBasketError())
    }

    dispatch(clearBasketError())
  }, [basketError?.description, basketError?.isProductAdded, basketCallStatus])

  useEffect(() => {
    if (basketCallStatus === 'loading') {
      props.showLoading()
    } else {
      props.hideLoading()
    }
  }, [basketCallStatus])

  //sayfa yenilendiğinde
  // useEffect(() => {
  //   window.performance.navigation.type === 1 && getExchangeRates()
  // }, [window.performance.navigation.type])

  //5 dakikada bir.

  // useEffect(() => {
  //   setTimeout(function () {
  //     getExchangeRates()
  //   }, 300000)
  // }, [state.exchangeRate])

  useEffect(() => {
    if (productAddingToFavoriteSpinner) {
      props.showLoading && props.showLoading()
    } else {
      props.hideLoading && props.hideLoading()
    }
  }, [productAddingToFavoriteSpinner])

  // const getExchangeRates = () => {
  //   const api: GetExchangeRatesApi = new GetExchangeRatesApi({})
  //   api.getExhangeRates().then((res: IExchangeRate) => {
  //     console.log(res)
  //     var count = 0
  //     var usd = res.Usd ? res.Usd.split(',') : ['0', '00']
  //     var eur = res.Eur ? res.Eur.split(',') : ['0', '00']
  //     var gbp = res.Gbp ? res.Gbp.split(',') : ['0', '00']
  //     var basamak = 4

  //     if (usd && usd.length < 2) {
  //       usd = res.Usd.split('.')
  //       if (usd.length < 2) {
  //         usd[0] = res.Usd
  //         usd[1] = ''
  //       }
  //     }

  //     if (eur && eur.length < 2) {
  //       eur = res.Eur.split('.')
  //       if (eur.length < 2) {
  //         eur[0] = res.Eur
  //         eur[1] = ''
  //       }
  //     }

  //     if (gbp && gbp.length < 2) {
  //       gbp = res.Gbp.split('.')
  //       if (gbp.length < 2) {
  //         gbp[0] = res.Gbp
  //         gbp[1] = ''
  //       }
  //     }

  //     var newUsd = usd![0] + ',' + usd![1].substring(0, basamak)
  //     count = usd![1].length
  //     while (count < basamak) {
  //       newUsd += '0'
  //       count++
  //     }

  //     var newEur = eur![0] + ',' + eur![1].substring(0, basamak)
  //     count = eur![1].length
  //     while (count < basamak) {
  //       newEur += '0'
  //       count++
  //     }

  //     var newGbp = gbp![0] + ',' + gbp![1].substring(0, basamak)
  //     count = gbp![1].length
  //     while (count < basamak) {
  //       newGbp += '0'
  //       count++
  //     }

  //     res.Usd = newUsd
  //     res.Eur = newEur
  //     res.Gbp = newGbp
  //     setState({ exchangeRate: res })
  //   })
  // }

  // useEffect(() => {
  //   getDealerRemoteList()
  // }, [])

  // useEffect(() => {
  //   getExchangeRates()
  // }, [url])

  const CACHE_KEY = 'exchangeRatesCache'
  const CACHE_EXPIRATION = 5 * 60 * 1000 // 5 minutes in milliseconds

  const getExchangeRates = () => {
    const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY) || '{}')
    const currentTime = new Date().getTime()

    if (cachedData.timestamp && currentTime - cachedData.timestamp < CACHE_EXPIRATION) {
      setState({ exchangeRate: cachedData.data })
      return
    }

    const api = new GetExchangeRatesApi({})
    api.getExhangeRates().then(res => {
      const formatCurrency = currency => {
        let [intPart, decimalPart = '00'] = currency.split(',').length < 2 ? currency.split('.') : currency.split(',')

        if (!decimalPart) decimalPart = '00'
        return `${intPart},${decimalPart.padEnd(4, '0').substring(0, 4)}`
      }

      res.Usd = formatCurrency(res.Usd || '0')
      res.Eur = formatCurrency(res.Eur || '0')
      res.Gbp = formatCurrency(res.Gbp || '0')

      const cacheData = {
        data: res,
        timestamp: currentTime,
      }
      localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData))

      setState({ exchangeRate: res })
    })
  }

  const getDealerRemoteList = () => {
    const api: GetDealerRemoteListApi = new GetDealerRemoteListApi({})
    api.getDealerRemoteList().then((res: []) => {
      // setState({ dealers: res })
    })
  }

  // const getClearRemote = () => {
  //   props.showLoading()
  //   const api: GetClearRemoteApi = new GetClearRemoteApi({})
  //   api.getClearRemote().then((res: any) => {
  //     const remote = JSON.parse(ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_DATA))
  //     remote.IsRemote = false
  //     remote.AccountCodeRemote = props.user.AccountCode
  //     remote.IsDPP = res.IsDPP
  //     remote.IsNetChannelDealer = res.IsNetChannelDealer
  //     remote.AccountNameRemote = ''
  //     ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_DATA, JSON.stringify(remote))
  //     window.location.reload()
  //     props.hideLoading()
  //   })
  // }
  const getClearRemote = () => {
    props.showLoading()
    const api: GetClearRemoteApi = new GetClearRemoteApi({})
    const homepageUrl = '/homepage'

    api
      .getClearRemote()
      .then((res: any) => {
        try {
          const remote = JSON.parse(ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_DATA))
          remote.IsRemote = false
          remote.AccountCodeRemote = props.user.AccountCode
          remote.IsDPP = res.IsDPP
          remote.IsNetChannelDealer = res.IsNetChannelDealer
          remote.AccountNameRemote = ''
          ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_DATA, JSON.stringify(remote))

          // Instead of reload, navigate to homepage
          window.location.href = homepageUrl
        } catch (error) {
          window.location.href = homepageUrl
        }
      })
      .catch(() => {
        window.location.href = homepageUrl
      })
      .finally(() => {
        props.hideLoading()
      })
  }

  const getRemoteDealerChange = () => {
    if (!state.dealerOption || state.dealerOption.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen Bayi Seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    const data = state.dealerOption.length > 5 ? state.dealerOption.padStart(10, '0') : state.dealerOption
    const api: GetRemoteDealerChangeApi = new GetRemoteDealerChangeApi({})
    const queryParams: KeyValuePair<string> = new KeyValuePair<string>()
    queryParams.add('dealercode', data)
    api.queryParams = queryParams
    api
      .getRemoteDealerChange()
      .then((res: any) => {
        const remote = JSON.parse(ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_DATA))
        remote.IsRemote = true
        remote.IsDPP = res.IsDPP
        remote.IsNetChannelDealer = res.IsNetChannelDealer
        remote.AccountCodeRemote = data
        remote.AccountNameRemote = res.delaerName
        ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_DATA, JSON.stringify(remote))
        window.location.reload()
        props.hideLoading()
      })
      .catch(e => {
        setState({ dealerOption: '' })
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      })
  }

  const getRepresentatives = () => {
    const data: IRepresentatives[] = []
    const api: GetRepresentativesApi = new GetRepresentativesApi({})
    api.getRepresentatives().then((res: IRepresentatives[]) => {
      if (res.find(r => r.Company.Name === 'Index'))
        res
          .filter(r => r.Company.Name === 'Index')
          .map(result => {
            data.push(result)
          })
      if (res.find(r => r.Company.Name === 'Datagate'))
        res
          .filter(r => r.Company.Name === 'Datagate')
          .map(result => {
            data.push(result)
          })
      if (res.find(r => r.Company.Name === 'Despec'))
        res
          .filter(r => r.Company.Name === 'Despec')
          .map(result => {
            data.push(result)
          })
      if (res.find(r => r.Company.Name === 'Netex'))
        res
          .filter(r => r.Company.Name === 'Netex')
          .map(result => {
            data.push(result)
          })
    })
    setState({ representatives: data })
  }
  const [quantities, setQuantities] = useState({})

  const handleQuantity = (productId, value) => {
    props.onAddListToBasketUI &&
      props.onAddListToBasketUI(props.product, value, props.isOpportunityMarket, !props.isSelected)

    // quantities state'ini güncelle, her ürün için ayrı bir state değeri kullanın
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: value,
    }))
  }

  const handleQuantityOnBlur = (productId, value) => {
    if (!value || isNaN(Number(value))) {
      value = 1
      props.onAddListToBasketUI &&
        props.onAddListToBasketUI(props.product, 1, props.isOpportunityMarket, !props.isSelected)
    } else {
      props.onAddListToBasketUI &&
        props.onAddListToBasketUI(props.product, value, props.isOpportunityMarket, !props.isSelected)
    }

    // quantities state'ini güncelle, her ürün için ayrı bir state değeri kullanın
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: value,
    }))
  }

  interface IMenu {
    Link: string
    Id: number
    ParentId: number
    Enabled: boolean
    CompanyId: number
    CategoryId: number
    Title: string
    Order: number
    Items: IMenu[]
    isOpen?: boolean
  }

  const getMenus = () => {
    const api: GetMenusApi = new GetMenusApi({})
    api.getMenus().then((res: IMenu[]) => {
      const menus = res.map((item: IMenu) => {
        for (const subItem of item.Items) {
          subItem.isOpen = false
          for (const subItem2 of subItem.Items) {
            subItem2.isOpen = false
            for (const subItem3 of subItem2.Items) {
              subItem3.isOpen = false
              for (const subItem4 of subItem3.Items) {
                subItem4.isOpen = false
              }
            }
            for (const subItem3 of subItem2.Items) {
              subItem3.isOpen = false
              for (const subItem4 of subItem3.Items) {
                subItem4.isOpen = false
              }
            }
          }
        }
        return {
          ...item,
          isOpen: false,
        }
      })
      setState({ menus, companyMenus: menus })
    })
  }

  const getMenuByCompany = (companyId: number) => {
    const api: GetMenusApi = new GetMenusApi({})
    const queryParams: KeyValuePair<number> = new KeyValuePair<number>()
    queryParams.add('companyId', companyId)
    api.queryParams = queryParams
    api.getMenus().then((res: IMenu[]) => {
      const menus = res.map((item: IMenu) => {
        for (const subItem of item.Items) {
          subItem.isOpen = false
          for (const subItem2 of subItem.Items) {
            subItem2.isOpen = false
          }
        }
        return {
          ...item,
          isOpen: false,
        }
      })
      setState({ companyMenus: menus })
    })
  }

  // useEffect(() => {
  //   getMenuByCompany(props.theme.id)
  // }, [props.theme.id])

  const logout = async () => {
    try {
      props.showLoading()
      await logoutUser()
      dispatch(clearOpenedPopupsCompanyIds())
      props.hideLoading()
    } catch {}
  }

  const ref: any = useRef()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const [isSearchActive, setIsSearchActive] = useState(false)
  const [searchMessage, setSearchMessage] = useState('Aranıyor...')

  const handleOnChangeInputSearch = async (value: string) => {
    // setIsMenuOpen(true);
    // if (value.length > 1) {
    //   setIsSearchActive(true)
    // } else setIsSearchActive(false)
    setState({ searchOnChangeValue: value })
  }

  const handleSearchCompleted = async (result: IProductSearchResponse[]) => {
    // setIsMenuOpen(true);
    // if (value.length > 1) {
    //   setIsSearchActive(true)
    // } else setIsSearchActive(false)
    setState({ searchResult: result })
  }

  const openSearchBox = () => {
    setIsSearchActive(true)
  }

  const closeSearchBox = () => {
    setIsSearchActive(false)
  }

  const routeLocation = useLocation()

  const [theme, setCurrentTheme] = useState(props.theme.name)

  const setTheme = (company: any) => {
    if (routeLocation.pathname === '/homepage') {
      if (company === 'index') {
        if (props.theme.name === INDEX_THEME) return dispatch(changeTheme('default'))
      }
      if (company === 'datagate') {
        if (props.theme.name === DATAGATE_THEME) return dispatch(changeTheme('default'))
      }
      if (company === 'despec') {
        if (props.theme.name === DESPEC_THEME) return dispatch(changeTheme('default'))
      }
      if (company === 'netex') {
        if (props.theme.name === NETEX_THEME) return dispatch(changeTheme('default'))
      }
      setCurrentTheme(company)
      return props.changeAppTheme(company)
    }
    props.changeAppTheme(company)
    setCurrentTheme(company)
    return navigate('/homepage')
  }

  const [hovered, setHovered] = useState({
    index: false,
    datagate: false,
    despec: false,
    netex: false,
  })
  const handleMouseEnter = (company: string) => {
    setHovered(prevState => ({
      ...prevState,
      [company]: true,
    }))
  }

  const handleMouseLeave = (company: string) => {
    setHovered(prevState => ({
      ...prevState,
      [company]: false,
    }))
  }

  const handleCategoryCheckedChange = (categoryId: number, checked: boolean) => {
    const foundedIdx = state.selectedCategories.indexOf(categoryId)

    if (checked) {
      if (foundedIdx === -1) {
        setState({
          selectedCategories: [...state.selectedCategories, categoryId],
        })
      }
    } else {
      if (foundedIdx > -1) {
        const tmpArr = [...state.selectedCategories]
        tmpArr.splice(foundedIdx, 1)
        setState({ selectedCategories: tmpArr })
      }
    }
  }

  const handleCompanyCheckedChange = (companyId: number, checked: boolean) => {
    const foundedIdx = state.selectedCompanies.indexOf(companyId)

    if (checked) {
      if (foundedIdx === -1) {
        setState({
          selectedCompanies: [...state.selectedCompanies, companyId],
        })
      }
    } else {
      if (foundedIdx > -1) {
        const tmpArr = [...state.selectedCompanies]
        tmpArr.splice(foundedIdx, 1)
        setState({ selectedCompanies: tmpArr })
      }
    }
  }

  const [isScrolling, setIsScrolling] = useState(false)
  let prevPos = window.scrollY
  const scrollHandler = () => {
    const topPos = window.scrollY
    if (topPos > 30) {
      setIsScrolling(true)
    }
    if (topPos < prevPos) {
      setIsScrolling(false)
    }
    prevPos = topPos
  }

  useEffect(() => {
    if (window.innerWidth > 992) window.addEventListener('scroll', scrollHandler)

    return () => {
      window.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false)
  const [isMobileSearchActive, setIsMobileSearchActive] = useState(false)

  const toggleSubMenu = (id: number, level?: number) => {
    const menus = state.companyMenus.map((item: IMenu) => {
      if (level === 2) {
        for (const subItem of item.Items) {
          if (id === subItem.Id) {
            subItem.isOpen = !subItem.isOpen
          }
        }
      } else if (level === 3) {
        for (const subItem of item.Items) {
          for (const subItem2 of subItem.Items) {
            if (id === subItem2.Id) {
              subItem2.isOpen = !subItem2.isOpen
            }
          }
        }
      }
      if (id === item.Id) {
        item.isOpen = !item.isOpen
      }
      return item
    })
    setState({ companyMenus: menus })
  }
  const getCompatibilityTable = async () => {
    props.showLoading()
    const getCompatibilityTableApi: GetCompatibilityTableApi = new GetCompatibilityTableApi({})
    getCompatibilityTableApi
      .getBannergetCompatibilityTable(16)
      .then(res => {
        setState({ compatibilityTable: res })
        props.hideLoading()
      })
      .catch(e => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const closeMS = async () => {
    setState({ closeMS: true })
  }

  const [clickLocked, setClickLocked] = useState(false)

  const handleClickForHomepageStats = async (companyId: number) => {
    if (clickLocked) return
    setClickLocked(true)

    const req: CompanyPageClickRequestModel = {
      CompanyId: companyId,
    }
    try {
      const postCompanyHomepageClicks: PostCompanyHomepageClicksApi = new PostCompanyHomepageClicksApi({})
      await postCompanyHomepageClicks.postCompanyHomepageClicks(req)
    } catch (error) {
      console.error('Error posting company homepage clicks:', error)
    } finally {
      setClickLocked(false)
    }
  }

  return (
    <>
      <div className='headerSpacer' style={{ paddingTop: props.user.Remotable ? '201px' : '150px' }}></div>
      <div className='header2' style={{ display: hideHeader ? 'none' : 'block' }}>
        <div className='container'>
          <div id='topbar' className='d-none d-xl-block d-lg-block topbar-fullwidth'>
            <div className='row align-items-center'>
              <div className='col-lg-3 col-md-2 col-xs-2 '>
                <div className='p-dropdown p-dropdown-invert logoDropdown'>
                  <button type='button' className='btn btn-light border-none logo_button mb-0'>
                    <Link to='/homepage' onClick={() => dispatch(changeTheme('default'))}>
                      <Image className='img-fluid' src='/images/indexPazarLogo.png' alt='' />
                    </Link>
                  </button>
                  {state.representatives && state.representatives.length > 0 && (
                    <ul
                      className='p-dropdown-content'
                      style={{ width: window.innerWidth > 992 ? '640px' : '100%', padding: '20px' }}
                    >
                      {state.representatives &&
                        state.representatives.map(
                          (rep, index) =>
                            rep &&
                            rep.Representatives && (
                              <div className='row' key={index}>
                                {rep.Representatives.map((data, i) => (
                                  <div className='col-12 col-md-4 m-t-10' key={i}>
                                    <li
                                      style={{
                                        overflow: 'unset',
                                        height: '140px',
                                        marginLeft: '10px',
                                        marginBottom: index === 3 ? '15px' : '',
                                      }}
                                    >
                                      <div>
                                        <div className='row'>
                                          {i === 1 && (
                                            <div className='dropImage' style={{ marginBottom: '15px' }}>
                                              {
                                                // rep.Company.Id === 4
                                                //   ?
                                                rep.Company.Name === 'Index' ? (
                                                  <img src={rep.Company.Image} alt='' />
                                                ) : rep.Company.Name === 'Datagate' ? (
                                                  <img src={rep.Company.Image} alt='' style={{ padding: '5px' }} />
                                                ) : rep.Company.Name === 'Despec' ? (
                                                  <img src={rep.Company.Image} alt='' />
                                                ) : rep.Company.Name === 'Netex' ? (
                                                  <img src={rep.Company.Image} alt='' />
                                                ) : (
                                                  ''
                                                )
                                                // :
                                                // <img
                                                //   src={rep.Company.Image}
                                                //   alt=""
                                                // />
                                              }
                                            </div>
                                          )}
                                        </div>
                                        {rep.Company.Name === 'Index' ? (
                                          <div className={i === 1 ? '' : 'm-t-60'}>
                                            <span>{data.Type}</span>
                                            <span>{data.NameAndSurname}</span>
                                            <span>{data.Email}</span>
                                            {data.TelNumber.map((tel: string, a) => (
                                              <span key={a}>
                                                <a href={`tel:${tel}`}>
                                                  {'(212) 331 ' +
                                                    tel
                                                      .replaceAll(' ', '')
                                                      .substring(tel.replaceAll(' ', '').length - 4)}
                                                </a>
                                              </span>
                                            ))}
                                          </div>
                                        ) : rep.Company.Name === 'Datagate' ? (
                                          <div className={i === 1 ? '' : 'm-t-60'}>
                                            <span>{data.Type}</span>
                                            <span>{data.NameAndSurname}</span>
                                            <span>{data.Email}</span>
                                            {data.TelNumber.map((tel: string, a) => (
                                              <span key={a}>
                                                <a href={`tel:${tel}`}>
                                                  {'(212) 331 ' +
                                                    tel
                                                      .replaceAll(' ', '')
                                                      .substring(tel.replaceAll(' ', '').length - 4)}
                                                </a>
                                              </span>
                                            ))}
                                          </div>
                                        ) : rep.Company.Name === 'Despec' ? (
                                          <div className={i === 1 ? '' : 'm-t-60'}>
                                            <span>{data.Type}</span>
                                            <span>{data.NameAndSurname}</span>
                                            <span>{data.Email}</span>
                                            {data.TelNumber.map((tel: string, a) => (
                                              <span key={a}>
                                                <a href={`tel:${tel}`}>
                                                  {'(212) 331 ' +
                                                    tel
                                                      .replaceAll(' ', '')
                                                      .substring(tel.replaceAll(' ', '').length - 4)}
                                                </a>
                                              </span>
                                            ))}
                                          </div>
                                        ) : rep.Company.Name === 'Netex' ? (
                                          <div className={i === 1 ? '' : 'm-t-60'}>
                                            <span>{data.Type}</span>
                                            <span>{data.NameAndSurname}</span>
                                            <span>{data.Email}</span>
                                            {data.TelNumber.map((tel: string, a) => (
                                              <span key={a}>
                                                <a href={`tel:${tel}`}>
                                                  {'(212) 331 ' +
                                                    tel
                                                      .replaceAll(' ', '')
                                                      .substring(tel.replaceAll(' ', '').length - 4)}
                                                </a>
                                              </span>
                                            ))}
                                          </div>
                                        ) : (
                                          ''
                                        )}
                                      </div>
                                    </li>
                                  </div>
                                ))}
                              </div>
                            )
                        )}
                    </ul>
                  )}
                </div>
              </div>
              <div
                className='col-lg-9 '
                onClick={() => setIsMenuOpen(oldState => (oldState === true ? !oldState : oldState))}
              >
                <div className='row align-items-center float-right m-auto'>
                  <ul className='list barItems d-flex align-items-center mb-0'>
                    <li>
                      <div className='foreign-currency m-t-5'>
                        <span>{`${'$'}${state.exchangeRate?.Usd}`}</span>
                      </div>
                    </li>
                    <li className={'border-right'}>
                      <div className='foreign-currency m-t-5'>
                        <span>{`${'€'}${state.exchangeRate?.Eur}`}</span>
                      </div>
                    </li>
                    <li>
                      <Link to='/openbox'>
                        <div className='barItemsIcon'>
                          <img src='/images/bell-regular.svg' alt='' width={15} />
                          <span className='badge badge-pill badge-danger' style={{ color: 'white' }}>
                            <span>{state.openBoxes}</span>
                          </span>
                        </div>
                      </Link>
                    </li>
                    {/* <li>
                      <a className="barItemsIcon" href="">
                        <i className="icon-heart"></i>
                      </a>
                    </li> */}
                    {/* TODO : EE - Bu kısımda favori ürün varsa yıldızın içi sarı olacak aksi durumda beyaz olacak.
                    props.hasAnyFavoriteProduct */}
                    <li>
                      <Link to='/myspecial/myfavorites'>
                        <span className='barItemsIcon' style={{ cursor: 'pointer' }}>
                          {props.hasAnyFavoriteProduct ? (
                            <>
                              <i className='fa fa-heart' style={{ color: '#dc3545' }}></i>
                              <span className='badge badge-pill badge-danger' style={{ color: 'white' }}>
                                <span> {props.FavoriteProductCount}</span>
                              </span>
                            </>
                          ) : (
                            <i className='far fa-heart'></i>
                          )}
                        </span>
                      </Link>
                    </li>
                    <li className='border-right'>
                      <Link to='/mycard'>
                        <span className='barItemsIcon' style={{ cursor: 'pointer' }}>
                          <i className='icon-shopping-cart'></i>
                          <span className='badge badge-pill badge-danger' style={{ color: 'white' }}>
                            <span> {props.basketCount}</span>
                          </span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <div className='topbar-dropdown p-dropdown'>
                        <div className='row align-items-center'>
                          <div className='col-auto'>
                            <Image
                              className='avatar'
                              src={props.user.DealerLogo ? props.user.DealerLogo : '/images/notImage.png'}
                              alt=''
                            />
                          </div>
                          <div className='col-auto pl-0'>
                            <div className='title p-10 float-right profileTitle d-flex align-items-center'>
                              <a>
                                {props.user?.AccountCode ? props.user.AccountCode.replace('0000', '') : null}
                                <br />
                                {props.user?.DealerName && props.user.DealerName.length > 20
                                  ? `${props.user.DealerName.substring(0, 20)}...`
                                  : props.user?.DealerName}
                                /{props.user?.UserName}&nbsp;
                              </a>
                              <i className='fas fa-chevron-down fa-fw'></i>
                            </div>
                          </div>
                        </div>
                        <div className='p-dropdown-content'>
                          <div className='widget-myaccount'>
                            <ul className='text-center'>
                              {props.user.IsAdmin && !props.user?.IsRemote && (
                                <li>
                                  <Link to='/admin/home' className='non-transition'>
                                    {' '}
                                    <i className='fa fa-desktop'></i>
                                    {t('layouts.client.managementpanel')}
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link to='/myspecial'>
                                  <i className='icon-user'></i>
                                  {t('layouts.client.specialForMe')}
                                </Link>
                              </li>
                              {(props.user.IsPermission || props.user.IsMonitorSalesAndOrder) && (
                                <li>
                                  <Link to='/myspecial/myorders'>
                                    <i className='icon-package'></i>
                                    {t('layouts.client.myOrders')}
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link to='/financial-information/bank-account-numbers'>
                                  <i className='icon-bar-chart-2'></i>
                                  Finansal Bilgiler
                                </Link>
                              </li>
                              {(props.user.IsPermission || props.user.IsMakeFinancialTransactions) && (
                                <li>
                                  <Link to='/myspecial/mypayments'>
                                    <i className='icon-credit-card'></i>
                                    Ödemeler
                                  </Link>
                                </li>
                              )}
                              <li>
                                <Link to='/sss'>
                                  {' '}
                                  <i className='fa fa-question-circle' />
                                  S.S.S.
                                </Link>
                              </li>
                              <li>
                                <Link to='/survey'>
                                  {' '}
                                  <i className='icon-file-text' />
                                  {t('layouts.client.survey')}
                                </Link>
                              </li>
                              <li>
                                <a role={'link'} onClick={logout}>
                                  <i className='icon-log-out'> </i>
                                  {t('layouts.client.logout')}
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {props.user.MSDay > 0 && (
            <div
              style={{
                position: 'fixed',
                bottom: '85px',
                right: '8px',
                zIndex: '99',
                backgroundColor: '#f25022',
                color: 'white',
                height: '65px',
                display: state.closeMS ? 'none' : '',
              }}
              id='divMsAttachGun'
            >
              <div style={{ textAlign: 'right', marginRight: '10px' }}>
                <span style={{ cursor: 'pointer' }} onClick={closeMS}>
                  Kapat X
                </span>
              </div>
              <div style={{ marginTop: '10px', marginLeft: '12px', fontWeight: 'bold', fontSize: '16px' }}>
                {' '}
                Attach Hakkı için Son {props.user.MSDay} Gün !!!
              </div>
              <div style={{ fontSize: '12px', textAlign: 'center', backgroundColor: '#02a8e6', padding: '3px' }}>
                {' '}
                Cihaz Alımınıza İstinaden <br></br> Microsoft Lisanslarında Sağlanan <br></br> İndirim Fırsatını
                Kaçırmayın !
              </div>
            </div>
          )}
          <div className='d-none d-xl-block d-lg-block topbar-fullwidth'>
            <div className='row align-items-center'>
              <div
                className='col-lg-12'
                onClick={() => setIsMenuOpen(oldState => (oldState === true ? !oldState : oldState))}
              >
                {props.user.Remotable && (
                  <div
                    className='row col-lg-12 align-items-center float-right m-auto'
                    style={{ justifyContent: 'end' }}
                  >
                    <div className='col-lg-6' style={{ textAlign: 'right' }}>
                      <label style={{ cursor: 'text' }}>
                        {props.user?.IsRemote
                          ? 'Seçili Bayi: ' +
                            props.user?.AccountCodeRemote.replace('0000', '') +
                            ' ' +
                            props.user?.AccountNameRemote
                          : 'Bayi Seçilmemiştir'}
                      </label>
                    </div>
                    <div className='col-lg-2'>
                      <IPInput
                        placeholder='Bayi Seçiniz...'
                        type='text'
                        // placeholder={t('pages.about.aboutUs.name')}
                        value={state.dealerOption}
                        onChange={e => setState({ dealerOption: e.target.value.trimEnd().trimStart() })}
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            getRemoteDealerChange()
                          }
                        }}
                      />
                    </div>
                    <IPButton
                      type='button'
                      className='btn btn-primary m-l-10'
                      text='Remote'
                      style={{ width: '25px', marginLeft: '10px' }}
                      onClick={getRemoteDealerChange}
                    />
                    <IPButton
                      type='button'
                      className='btn btn-secondary m-l-10'
                      text='Temizle'
                      style={{ width: '25px', marginLeft: '10px' }}
                      onClick={getClearRemote}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <header id='header' data-fullwidth='true' className={`mid-header   'd-block'}`}>
            <div className='header-inner'>
              <div className='row h-auto justify-content-between'>
                <div className='col-6 col-lg-7 px-sm-0 px-lg-3 d-none d-lg-block'>
                  <div className='row'>
                    <div className='col-2'>
                      <div className='p-dropdown p-dropdown-invert logoDropdown' style={{ height: '65px' }}>
                        <a className='btn btn-transparent text-capitalize menuButton'>
                          <i className='fa fa-bars fa-fw'> </i> {t('layouts.client.menu')}
                        </a>
                        <ul className='p-dropdown-content non-transition' style={{ top: '65px', width: '250px' }}>
                          <li>
                            <Link to='/products?specialFilter=opportunit-market' className='non-transition'>
                              <i className='icon-gift' style={{ color: 'red' }}></i>
                              <span style={{ color: 'red' }}>Fırsat Pazarı</span>
                            </Link>
                          </li>
                          <li>
                            <Link to='/myspecial' className='non-transition'>
                              <i className='icon-user'></i>
                              {t('layouts.client.specialForMe')}
                            </Link>
                          </li>
                          {(props.user.IsPermission || props.user.IsMonitorSalesAndOrder) && (
                            <li>
                              <Link to='/myspecial/myorders' className='non-transition'>
                                <i className='icon-package'></i>
                                {t('layouts.client.myOrders')}
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link to='/financial-information/bank-account-numbers' className='non-transition'>
                              {' '}
                              <i className='icon-bar-chart-2'></i>
                              Finansal Bilgiler
                            </Link>
                          </li>
                          {(props.user.IsPermission || props.user.IsMakeFinancialTransactions) && (
                            <li>
                              <Link to='/myspecial/mypayments'>
                                <i className='icon-credit-card'></i>
                                Ödemeler
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link to='/survey' className='non-transition'>
                              {' '}
                              <i className='icon-file-text' />
                              {t('layouts.client.survey')}
                            </Link>
                          </li>
                          {state.compatibilityTable && state.compatibilityTable.length > 0 && (
                            <li className='hasSubMenu'>
                              <a>
                                {' '}
                                <i className='fa fa-table'></i>
                                Uyumluluk Tablosu (Despec)
                              </a>
                              <nav className='pDropdownSubMenu'>
                                {state.compatibilityTable.map((com, index) => (
                                  <a href={com.Documents[0].FileUrl} key={index}>
                                    {com.Description}
                                  </a>
                                ))}
                              </nav>
                            </li>
                          )}
                          <li>
                            <Link to='/priceLists' className='non-transition'>
                              {' '}
                              <i className='fa fa-list'></i>
                              Fiyat Listeleri(Despec)
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='col-10 m-t-15 p-0 d-none d-lg-block' ref={ref}>
                      <SearchInput
                        onChange={handleOnChangeInputSearch}
                        onOpen={openSearchBox}
                        onClose={closeSearchBox}
                        isSearchActive={isSearchActive}
                        setSearchMessage={setSearchMessage}
                        handleSearchCompleted={handleSearchCompleted}
                        searchValue={state.searchOnChangeValue}
                        selectedCategories={state.selectedCategories}
                        selectedCompanies={state.selectedCompanies}
                      >
                        {isSearchActive && (
                          <div className={`searchDropdown row mx-0 p-t-20 ${isSearchActive ? 'searchActive' : ''}`}>
                            <div className='col-md-3'>
                              <ul className='list-group list-group-flush'>
                                {/* {state.searchResult &&
                                state.searchResult.CategoryList &&
                                state.searchResult.CategoryList.map(
                                  (category: ICategory) => {
                                    return (
                                      <li className="list-group-item border-0" key={category.Id}>
                                        <Link
                                          to={`/products?categoryId=${category.Id}`}
                                          onClick={() =>
                                            setIsSearchActive(false)
                                          }
                                        >
                                          {category.ShortName}
                                        </Link>
                                      </li>
                                    );
                                  }
                                )} */}
                                {state.menus &&
                                  state.menus.map((item: IMenu) => (
                                    <li
                                      className='list-group-item border-0 search-input-checkbox-wrapper'
                                      key={item.Id}
                                    >
                                      <IPCheckbox
                                        label={item.Title}
                                        labelClassName={'search-input-checkbox'}
                                        checked={
                                          state.selectedCategories.findIndex((val: number) => val === item.Id) > -1
                                        }
                                        onCheckedChange={(checked: boolean) =>
                                          handleCategoryCheckedChange(item.Id, checked)
                                        }
                                      />
                                    </li>
                                  ))}
                              </ul>
                            </div>
                            <div className='col-md-7' style={{ overflow: 'auto', height: '500px' }}>
                              <ul className='list-group list-group-flush'>
                                {state.searchResult && state.searchResult.length > 0
                                  ? state.searchResult.map((product: IProductSearchResponse) => {
                                      return (
                                        <li
                                          className='list-group-item border-0'
                                          key={product.ProductId}
                                          style={{ lineHeight: '15px', marginBottom: '-10px' }}
                                        >
                                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ marginLeft: '-5px' }}>
                                              <Link
                                                to={`/product/detail/${product.ProductId}`}
                                                onClick={() => setIsSearchActive(false)}
                                              >
                                                <b style={{ fontWeight: '500' }}>{product.ProductName}</b>
                                              </Link>
                                            </div>
                                            <br></br>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                              <div>
                                                <div className='row' style={{ marginBottom: '-10px' }}>
                                                  <p>
                                                    <span
                                                      style={
                                                        product.Product?.Stock?.Stock > 0
                                                          ? { color: 'red' }
                                                          : { color: 'rgb(15, 189, 224)' }
                                                      }
                                                    >
                                                      Stok:
                                                    </span>
                                                    {(() => {
                                                      let stok = product.Product?.Stock?.Stock
                                                      return stok >= 100
                                                        ? ' 100+'
                                                        : stok >= 50
                                                        ? ' 50+'
                                                        : stok >= 25
                                                        ? ' 25+'
                                                        : stok >= 10
                                                        ? ' 10+'
                                                        : stok >= 5
                                                        ? ' 5+'
                                                        : stok >= 1
                                                        ? ' 1+'
                                                        : ' 0'
                                                    })()}
                                                  </p>
                                                </div>
                                                <div className='row'>
                                                  <p>
                                                    <span
                                                      style={
                                                        product.Product?.Stock?.Stock > 0
                                                          ? { color: 'red' }
                                                          : { color: 'rgb(15, 189, 224)' }
                                                      }
                                                    >
                                                      Fiyat:
                                                    </span>{' '}
                                                    {product.Product.Prices?.Special?.Price.replaceAll(' ', '')}
                                                  </p>
                                                </div>
                                              </div>
                                              <div
                                                className='productButton'
                                                style={{
                                                  font: 'status-bar',
                                                  justifyContent: 'right',
                                                  marginBottom: 'auto',
                                                }}
                                              >
                                                <input
                                                  type='number'
                                                  className='stockNumber myCardStockNumber'
                                                  min={1}
                                                  //controlledField
                                                  value={quantities[product.ProductId] || 1}
                                                  onChange={e => handleQuantity(product.ProductId, e.target.value)}
                                                  onBlur={e => handleQuantityOnBlur(product.ProductId, e.target.value)}
                                                  style={
                                                    product.Product?.Stock?.Stock > 0
                                                      ? { width: '30%', marginLeft: '10px', borderColor: 'red' }
                                                      : {
                                                          width: '30%',
                                                          marginLeft: '10px',
                                                          borderColor: 'rgb(15, 189, 224)',
                                                        }
                                                  }
                                                />
                                                <AddToBasket
                                                  product={product.Product}
                                                  quantity={quantities[product.ProductId] || 1}
                                                  disabled={product.Brand === 'HPE' && product.CompanyId === 2}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </li>
                                      )
                                    })
                                  : state.searchOnChangeValue && searchMessage}
                              </ul>
                              <div className='currentContent'>
                                <h6>{t('layouts.client.yourSearchHistory')}</h6>
                                <div className='d-block mx-auto pt-4'>
                                  <ul className='list list-legend'>
                                    {searchHistoryKeywords &&
                                      searchHistoryKeywords.map((searchHistoryKeyword: string) => {
                                        return (
                                          <li>
                                            <a
                                              role={'button'}
                                              onClick={() => handleOnChangeInputSearch(searchHistoryKeyword)}
                                            >
                                              {searchHistoryKeyword}
                                            </a>
                                          </li>
                                        )
                                      })}
                                  </ul>
                                  <br></br>
                                </div>
                                {/* <div className="seperator m-0 p-0"></div> */}
                                {/* <h6>{t("layouts.client.mostRecentSearches")}</h6>
                              <div className="d-block mx-auto pt-4">
                                <ul className="list list-legend">
                                  <li>
                                    <a href="#">Apple Watch</a>
                                  </li>
                                  <li>
                                    <a href="#">Apple</a>
                                  </li>
                                  <li>
                                    <a href="#">Microsoft</a>
                                  </li>
                                </ul>
                              </div> */}
                                {/* <div className="seperator m-0 p-0"></div>
                              <img src="/images/searchBanner.png" alt="" /> */}
                              </div>
                            </div>
                            <div className='col-md-2'>
                              <ul className='list-group list-group-flush'>
                                <li className='list-group-item border-0 search-input-checkbox-wrapper' key={'index-li'}>
                                  <IPCheckbox
                                    label='Index'
                                    labelClassName={'search-input-checkbox'}
                                    checked={state.selectedCompanies.findIndex((val: number) => val === 1) > -1}
                                    onCheckedChange={(checked: boolean) => handleCompanyCheckedChange(1, checked)}
                                  />
                                </li>
                                <li
                                  className='list-group-item border-0 search-input-checkbox-wrapper'
                                  key={'despec-li'}
                                >
                                  <IPCheckbox
                                    label='Despec'
                                    labelClassName={'search-input-checkbox'}
                                    checked={state.selectedCompanies.findIndex((val: number) => val === 3) > -1}
                                    onCheckedChange={(checked: boolean) => handleCompanyCheckedChange(3, checked)}
                                  />
                                </li>
                                <li
                                  className='list-group-item border-0 search-input-checkbox-wrapper'
                                  key={'datagate-li'}
                                >
                                  <IPCheckbox
                                    label='Datagate'
                                    labelClassName={'search-input-checkbox'}
                                    checked={state.selectedCompanies.findIndex((val: number) => val === 4) > -1}
                                    onCheckedChange={(checked: boolean) => handleCompanyCheckedChange(4, checked)}
                                  />
                                </li>
                                <li className='list-group-item border-0 search-input-checkbox-wrapper' key={'netex-li'}>
                                  <IPCheckbox
                                    label='Netex'
                                    labelClassName={'search-input-checkbox'}
                                    checked={state.selectedCompanies.findIndex((val: number) => val === 2) > -1}
                                    onCheckedChange={(checked: boolean) => handleCompanyCheckedChange(2, checked)}
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </SearchInput>
                    </div>
                  </div>
                </div>
                <div className='col-5 d-none d-lg-block'>
                  <div id='mainMenu'>
                    <div className='container'>
                      <nav>
                        <ul className='d-flex align-items-center'>
                          <li
                            onClick={() => {
                              if (theme !== 'index') {
                                handleClickForHomepageStats(1)
                              }
                              setTheme('index')
                            }}
                            onMouseEnter={() => handleMouseEnter('index')}
                            onMouseLeave={() => handleMouseLeave('index')}
                          >
                            <button type='button' className='btn btn-light border-none p-1'>
                              <Image
                                src='/images/ındex_logo.png'
                                alt=''
                                className={`logo ${
                                  routeLocation.pathname.search('myspecial') > 0
                                    ? ''
                                    : props.theme.name === INDEX_THEME || hovered.index
                                    ? 'img_opacity'
                                    : ''
                                }`}
                              />
                            </button>
                          </li>
                          <li
                            onClick={() => {
                              if (theme !== 'datagate') {
                                handleClickForHomepageStats(2)
                              }
                              setTheme('datagate')
                            }}
                            onMouseEnter={() => handleMouseEnter('datagate')}
                            onMouseLeave={() => handleMouseLeave('datagate')}
                          >
                            <button type='button' className='btn btn-light  btn-rounded p-1'>
                              <Image
                                src='/images/datagate_logo.png'
                                alt=''
                                className={`logo ${
                                  routeLocation.pathname.search('myspecial') > 0
                                    ? ''
                                    : props.theme.name === DATAGATE_THEME || hovered.datagate
                                    ? 'img_opacity'
                                    : ''
                                }`}
                              />
                            </button>
                          </li>
                          <li
                            onClick={() => {
                              if (theme !== 'despec') {
                                handleClickForHomepageStats(3)
                              }
                              setTheme('despec')
                            }}
                            onMouseEnter={() => handleMouseEnter('despec')}
                            onMouseLeave={() => handleMouseLeave('despec')}
                          >
                            <button type='button' className='btn btn-light btn-rounded p-1'>
                              <Image
                                src='/images/despec_logo.png'
                                alt=''
                                className={`logo ${
                                  routeLocation.pathname.search('myspecial') > 0
                                    ? ''
                                    : props.theme.name === DESPEC_THEME || hovered.despec
                                    ? 'img_opacity'
                                    : ''
                                }`}
                              />
                            </button>
                          </li>
                          <li
                            onClick={() => {
                              if (theme !== 'netex') {
                                handleClickForHomepageStats(4)
                              }
                              setTheme('netex')
                            }}
                            onMouseEnter={() => handleMouseEnter('netex')}
                            onMouseLeave={() => handleMouseLeave('netex')}
                          >
                            <button type='button' className='btn btn-light  btn-rounded p-1'>
                              <Image
                                src='/images/netex_logo.png'
                                alt=''
                                className={`logo ${
                                  routeLocation.pathname.search('myspecial') > 0
                                    ? ''
                                    : props.theme.name === NETEX_THEME || hovered.netex
                                    ? 'img_opacity'
                                    : ''
                                }`}
                              />
                            </button>
                          </li>
                          {props.user && props.user.IsDPP && (
                            <li
                              className={css`
                                transition: transform 0.3s !important;
                                &:hover {
                                  transform: scale(1.2) !important;
                                }
                              `}
                            >
                              <Link to='/dpp'>
                                <img src='/images/dpp.svg' alt='DPP' style={{ width: '60px' }} />
                              </Link>
                            </li>
                          )}
                          {props.user && props.user.IsNetChannelDealer && (
                            // <li
                            //   className={css`
                            //     transition: transform 0.3s !important;
                            //     &:hover {
                            //       transform: scale(1.2) !important;
                            //     }
                            //   `}
                            // >
                            //   <Link to='/netChannel?page=report'>
                            //     <img
                            //       className='small-image'
                            //       src='/images/NET-Logo-01.png'
                            //       alt='NET'
                            //       style={{ width: '60px' }}
                            //     />
                            //   </Link>
                            // </li>
                            <li
                              className={css`
                                transition: transform 0.3s !important;
                                &:hover {
                                  transform: scale(1.2) !important;
                                }
                              `}
                            >
                              <Link to='/netChannel?page=report'>
                                <IPImage
                                  className={css`
                                    width: 40px;
                                    height: auto;
                                    image-rendering: -webkit-optimize-contrast;
                                    image-rendering: crisp-edges;
                                    image-rendering: pixelated;
                                  `}
                                  src='/images/netChannel/NET-Logo-01.svg'
                                  alt='NET'
                                />
                              </Link>
                            </li>
                          )}
                          <li
                            className={css`
                              transition: transform 0.3s !important;
                              &:hover {
                                transform: scale(1.2) !important;
                              }
                            `}
                          >
                            <Link to='/microsoft'>
                              <img src='/images/Microsoft/Mslogo.png' alt='Microsoft' style={{ width: '55px' }} />
                            </Link>
                          </li>
                          <li
                            className={css`
                              transition: transform 0.3s !important;
                              &:hover {
                                transform: scale(1.2) !important;
                              }
                            `}
                          >
                            <Link to='/oneConfig'>
                              <img
                                src='https://remote.indexgrup.com/content/images/brand/hp-png-2.png'
                                alt='HP'
                                style={{ width: '40px' }}
                                title='HP Ürün Kataloğu'
                              />
                            </Link>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className='col-7 pl-0 d-block d-lg-none'>
                  <div className='d-flex align-items-center'>
                    <div
                      className='btn btn-transparent text-capitalize menuButton mb-0 py-0 px-0 pl-2'
                      onClick={() => setIsMobileMenuActive(true)}
                    >
                      <i className='fa fa-bars fa-fw' style={{ fontSize: '20px' }}></i>{' '}
                      <div className='d-none d-sm-inline'>{t('layouts.client.menu')}</div>
                    </div>
                    <div className='logo'>
                      <Link to='/homepage' onClick={() => dispatch(changeTheme('default'))}>
                        <Image
                          style={{ maxWidth: 'unset', width: 'unset', maxHeight: '60px' }}
                          src='/images/indexPazarLogo.png'
                          alt=''
                        />
                      </Link>
                    </div>
                  </div>
                </div>
                {/* <div className="col-4 d-block d-lg-none">
                  <div className="d-flex justify-content-center">
                    <Link
                      to="/homepage"
                      onClick={() => dispatch(changeTheme("default"))}
                    >
                      <Image
                        style={{ maxWidth: 'unset', width: 'unset', height: '60px' }}
                        src="/images/indexPazarLogo.png"
                        alt=""
                      />
                    </Link>
                  </div>
                </div> */}
                <div className='col-5 d-lg-none'>
                  <div className='d-flex justify-content-end'>
                    <ul className='list barItems d-flex align-items-center gap-3 mb-0 mr-3'>
                      <li>
                        <i
                          className='fas fa-search'
                          style={{ position: 'relative', fontSize: 22, top: '0.4rem' }}
                          onClick={() => {
                            setIsMobileSearchActive(true)
                            openSearchBox()
                          }}
                        ></i>
                      </li>
                      <li>
                        <Link to='/openbox'>
                          <div
                            className={css`
                              position: relative;
                              width: 22px;
                              height: 22px;
                              font-size: 22px;
                            `}
                          >
                            <img
                              src='/images/bell-regular.svg'
                              alt=''
                              style={{ position: 'relative', bottom: 6, width: 22, height: 22 }}
                            />
                            {state.openBoxes > 0 && (
                              <span
                                className={`badge badge-pill badge-danger 
                                ${css`
                                  font-size: 13px;
                                  position: absolute;
                                  display: grid;
                                  place-items: center;
                                  top: -14px;
                                  right: -0.3rem;
                                  width: max-content;
                                  min-width: 1.7rem;
                                  padding: 0.2rem 0.5rem;
                                  z-index: 1;
                                `}`}
                                style={{ color: 'white' }}
                              >
                                <span style={{ marginTop: '2px' }}>{state.openBoxes}</span>
                              </span>
                            )}
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to='/mycard'>
                          <div
                            className={css`
                              position: relative;
                              height: 22px;
                              font-size: 22px;
                            `}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className='icon-shopping-cart'></i>
                            {props.basketCount > 0 && (
                              <span
                                className={`badge badge-pill badge-danger 
                                ${css`
                                  font-size: 13px;
                                  position: absolute;
                                  display: grid;
                                  place-items: center;
                                  top: -14px;
                                  right: -0.3rem;
                                  width: max-content;
                                  min-width: 1.7rem;
                                  padding: 0.2rem 0.5rem;
                                  z-index: 1;
                                `}`}
                                style={{ color: 'white' }}
                              >
                                <span style={{ marginTop: '2px' }}>{props.basketCount}</span>
                              </span>
                            )}
                          </div>
                        </Link>
                      </li>
                    </ul>
                    <div className='topbar-dropdown p-dropdown'>
                      <div className='d-flex align-items-center'>
                        <div className='flex-shrink-0'>
                          <Image
                            className='avatar'
                            src={props.user.DealerLogo ? props.user.DealerLogo : '/images/notImage.png'}
                            alt=''
                          />
                        </div>
                        <div className='d-none d-md-block'>
                          <div
                            className='title p-10 float-right profileTitle d-flex align-items-center'
                            style={{ borderLeft: 0 }}
                          >
                            <a className='d-flex flex-column gap-1' style={{ lineHeight: '13px' }}>
                              <div style={{ height: '13px' }}>
                                {props.user?.AccountCode ? props.user.AccountCode.replace('0000', '') : null}
                              </div>
                              <div style={{ height: '13px' }}>{props.user?.Name}&nbsp;</div>
                            </a>
                            <i className='fas fa-chevron-down fa-fw'></i>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`p-dropdown-content ${css`
                          padding: 0.5rem 1rem !important;
                          @media (max-width: 500px) {
                            position: fixed !important;
                          }
                          .widget-myaccount {
                            min-width: 160px !important;
                          }
                        `}`}
                      >
                        <div className='widget-myaccount'>
                          <ul className='p-0 text-left'>
                            <div className='d-block d-md-none'>
                              <div className='title pl-2 font-weight-600'>
                                <div style={{ fontSize: '14px', height: '16px' }} className='mb-2'>
                                  {props.user?.AccountCode ? props.user.AccountCode.replace('0000', '') : null}
                                </div>
                                <div style={{ height: '14px' }} className='mb-3'>
                                  {props.user?.Name}&nbsp;
                                </div>
                              </div>
                            </div>
                            <li className='float-left'>
                              <div
                                className='foreign-currency my-2 d-flex justify-content-around'
                                style={{ padding: '0 8px' }}
                              >
                                <span style={{ color: '#dc3545', fontWeight: '600' }}>{`${'$'}${
                                  state.exchangeRate?.Usd
                                }`}</span>
                              </div>
                            </li>
                            <li className='float-left'>
                              <div className='foreign-currency my-2 d-flex justify-content-around'>
                                <span style={{ color: '#dc3545', fontWeight: '600' }}>{`${'€'}${
                                  state.exchangeRate?.Eur
                                }`}</span>
                              </div>
                            </li>
                            <div style={{ clear: 'both' }}></div>
                            {props.user.IsAdmin && (
                              <li>
                                <Link to='/admin/home' className='non-transition'>
                                  {' '}
                                  <i className='fa fa-desktop'></i>
                                  {t('layouts.client.managementpanel')}
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link to='/myspecial'>
                                <i className='icon-user'></i>
                                {t('layouts.client.specialForMe')}
                              </Link>
                            </li>
                            {(props.user.IsPermission || props.user.IsMonitorSalesAndOrder) && (
                              <li>
                                <Link to='/myspecial/myorders'>
                                  <i className='icon-package'></i>
                                  {t('layouts.client.myOrders')}
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link to='/financial-information/bank-account-numbers'>
                                <i className='icon-bar-chart-2'></i>
                                Finansal Bilgiler
                              </Link>
                            </li>
                            {(props.user.IsPermission || props.user.IsMakeFinancialTransactions) && (
                              <li>
                                <Link to='/myspecial/mypayments'>
                                  <i className='icon-credit-card'></i>
                                  Ödemeler
                                </Link>
                              </li>
                            )}
                            <li>
                              <Link to='/survey'>
                                {' '}
                                <i className='icon-file-text' />
                                {t('layouts.client.survey')}
                              </Link>
                            </li>
                            <li>
                              <a role={'link'} onClick={logout}>
                                <i className='icon-log-out'> </i>
                                {t('layouts.client.logout')}
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </div>
        <div className='seperator headerSeperator m-0 p-0'></div>
      </div>
      <div className='container'>
        <header id='header' data-fullwidth='true' className='bot-header d-none d-lg-block' data-transparent='true'>
          <div className='header-inner bottom-header'>
            <div id='mainMenu'>
              <nav>
                <ul className='d-flex align-items-center' style={{ height: '43px' }}>
                  {state.menus &&
                    state.menus.map((item: IMenu, index) => {
                      return (
                        <li
                          style={{ maxHeight: 30 }}
                          className={`${item.Items && item.Items.length > 0 ? 'dropdown' : ''}`}
                          key={'menu' + index}
                        >
                          <Link
                            to={item.Link ? item.Link : `/products?categoryId=${item.Id}&global-search=true`}
                            className={css`&:hover {color: ${props.theme.colors.backgroundColor}!important;`}
                          >
                            {item.Title}
                          </Link>
                          {item.Items && item.Items.length > 0 && (
                            <ul className='dropdown-menu first-d-menu'>
                              {item.Items.map((subItem: IMenu, index) => {
                                return (
                                  <li
                                    key={`${'subMenu' + index}${item.Title}-${subItem.Title}`}
                                    className={subItem.Items && subItem.Items.length > 0 ? 'dropdown-submenu' : ''}
                                  >
                                    <Link
                                      to={
                                        subItem.Link
                                          ? subItem.Link
                                          : `/products?categoryId=${subItem.Id}&global-search=true`
                                      }
                                      className={css`&:hover {color: ${props.theme.colors.backgroundColor}!important;`}
                                    >
                                      {subItem.Title}
                                    </Link>
                                    {subItem.Items && subItem.Items.length > 0 && (
                                      <ul className='dropdown-menu'>
                                        {subItem.Items.map((subItem2: IMenu, index) => {
                                          return (
                                            <li
                                              key={`${'subMenu2' + index}${subItem.Title}-${subItem2.Title}`}
                                              className={
                                                subItem2.Items && subItem2.Items.length > 0 ? 'dropdown-submenu' : ''
                                              }
                                            >
                                              <Link
                                                to={
                                                  subItem2.Link
                                                    ? subItem2.Link
                                                    : `/products?categoryId=${subItem2.Id}&global-search=true`
                                                }
                                                className={css`
                                                  &:hover {
                                                    color: ${props.theme.colors.backgroundColor}!important;
                                                  }
                                                `}
                                              >
                                                {subItem2.Title}
                                              </Link>
                                              {subItem2.Items && subItem2.Items.length > 0 && (
                                                <ul className='dropdown-menu'>
                                                  {subItem2.Items.map((subItem3: IMenu, index) => {
                                                    return (
                                                      <li
                                                        key={`${'subMenu3' + index}${subItem2.Title}-${subItem3.Title}`}
                                                        className={
                                                          subItem3.Items && subItem3.Items.length > 0
                                                            ? 'dropdown-submenu'
                                                            : ''
                                                        }
                                                      >
                                                        <Link
                                                          to={
                                                            subItem3.Link
                                                              ? subItem3.Link
                                                              : `/products?categoryId=${subItem3.Id}&global-search=true`
                                                          }
                                                          className={css`
                                                            &:hover {
                                                              color: ${props.theme.colors.backgroundColor}!important;
                                                            }
                                                          `}
                                                        >
                                                          {subItem3.Title}
                                                        </Link>
                                                        {subItem3.Items && subItem3.Items.length > 0 && (
                                                          <ul className='dropdown-menu'>
                                                            {subItem3.Items.map((subItem4: IMenu, index) => {
                                                              return (
                                                                <li
                                                                  key={`${'subMenu4' + index}${subItem3.Title}-${
                                                                    subItem4.Title
                                                                  }`}
                                                                >
                                                                  <Link
                                                                    to={
                                                                      subItem4.Link
                                                                        ? subItem4.Link
                                                                        : `/products?categoryId=${subItem4.Id}&global-search=true`
                                                                    }
                                                                    className={css`
                                                                      &:hover {
                                                                        color: ${props.theme.colors
                                                                          .backgroundColor}!important;
                                                                      }
                                                                    `}
                                                                  >
                                                                    {subItem4.Title}
                                                                  </Link>
                                                                </li>
                                                              )
                                                            })}
                                                          </ul>
                                                        )}
                                                      </li>
                                                    )
                                                  })}
                                                </ul>
                                              )}
                                            </li>
                                          )
                                        })}
                                      </ul>
                                    )}
                                  </li>
                                )
                              })}
                            </ul>
                          )}
                        </li>
                      )
                    })}
                </ul>
              </nav>
            </div>
          </div>
        </header>
      </div>
      <div
        className={`mobileMenuBackdrop ${isMobileMenuActive ? 'active' : ''}`}
        onClick={() => setIsMobileMenuActive(false)}
      ></div>
      <div className={`mobileMenu ${isMobileMenuActive ? 'active' : ''}`}>
        <div className='mLogo'>
          <img src='/images/indexPazarLogo.png' alt='' className='img-fluid' />
          <button
            className='btn btn-sm btn-light text-sm'
            onClick={() => setState({ isRepsActive: !state.isRepsActive })}
          >
            {state.isRepsActive ? 'Kapat' : 'Temsilciler'}
          </button>
        </div>
        <div className={`representatives ${state.isRepsActive ? 'active' : ''}`}>
          {state.representatives.map((item, i) => (
            <div className='rItem' key={'rp' + i}>
              <img src={item.Company.Image} alt='' />
              {item.Representatives.map((r, i) => (
                <div className='rPerson' key={'r' + i}>
                  <div className='rTitle'>{r.Type}</div>
                  <div className='rInfo'>{r.NameAndSurname}</div>
                  <div className='rInfo'>{r.Email}</div>
                  <div className='rInfo'>{r.TelNumber}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className='companySelection'>
          <div
            onClick={() => {
              handleClickForHomepageStats(1)
              setTheme('index')
            }}
          >
            <Image
              src='/images/ındex_logo.png'
              alt=''
              className={`logo ${
                routeLocation.pathname.search('myspecial') > 0 ? '' : props.theme.name === INDEX_THEME ? 'active' : ''
              }`}
            />
          </div>
          <div
            onClick={() => {
              setTheme('datagate')
              handleClickForHomepageStats(2)
            }}
          >
            <Image
              src='/images/datagate_logo.png'
              alt=''
              className={`logo ${
                routeLocation.pathname.search('myspecial') > 0
                  ? ''
                  : props.theme.name === DATAGATE_THEME
                  ? 'active'
                  : ''
              }`}
            />
          </div>
          <div
            onClick={() => {
              setTheme('despec')
              handleClickForHomepageStats(3)
            }}
          >
            <Image
              src='/images/despec_logo.png'
              alt=''
              className={`logo ${
                routeLocation.pathname.search('myspecial') > 0 ? '' : props.theme.name === DESPEC_THEME ? 'active' : ''
              }`}
            />
          </div>
          <div
            onClick={() => {
              setTheme('netex')
              handleClickForHomepageStats(4)
            }}
          >
            <Image
              src='/images/netex_logo.png'
              alt=''
              className={`logo ${
                routeLocation.pathname.search('myspecial') > 0 ? '' : props.theme.name === NETEX_THEME ? 'active' : ''
              }`}
            />
          </div>
        </div>
        <nav>
          {state.companyMenus &&
            state.companyMenus.map((item: IMenu, index) => (
              <div className='item' key={'mMenu' + index}>
                <Link
                  to={item.Link ? item.Link : `/products?categoryId=${item.Id}`}
                  onClick={() => toggleSubMenu(item.Id)}
                  style={{ cursor: 'pointer' }}
                >
                  {item.Title}
                </Link>
                {item.Items && item.Items.length > 0 && (
                  <>
                    <span onClick={() => toggleSubMenu(item.Id)} style={{ cursor: 'pointer' }}>
                      <i className='fa fa-chevron-right'></i>
                    </span>
                    {item.isOpen && (
                      <div className='subMenu'>
                        <div className='back' onClick={() => toggleSubMenu(item.Id)} style={{ cursor: 'pointer' }}>
                          <i className='fa fa-chevron-left'></i> Geri Dön
                        </div>
                        {item.Items.map((subItem: IMenu) => (
                          <div className='item' key={'mSubMenu' + index}>
                            <Link
                              to={subItem.Link ? subItem.Link : `/products?categoryId=${subItem.Id}`}
                              onClick={() => setIsMobileMenuActive(false)}
                              //onClick={() => toggleSubMenu(subItem.Id, 2)}
                              style={{ cursor: 'pointer' }}
                            >
                              {subItem.Title}
                            </Link>
                            {subItem.Items && subItem.Items.length > 0 && (
                              <>
                                <span onClick={() => toggleSubMenu(subItem.Id, 2)} style={{ cursor: 'pointer' }}>
                                  <i className='fa fa-chevron-right'></i>
                                </span>
                                {subItem.isOpen && (
                                  <div className='subMenu'>
                                    <div
                                      className='back'
                                      onClick={() => toggleSubMenu(subItem.Id, 2)}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <i className='fa fa-chevron-left'></i> Geri Dön
                                    </div>
                                    {subItem.Items.map((subItem2: IMenu, index) => (
                                      <div className='item' key={'mSubMenu2' + index}>
                                        <Link
                                          to={subItem2.Link ? subItem2.Link : `/products?categoryId=${subItem2.Id}`}
                                          onClick={() => setIsMobileMenuActive(false)}
                                        >
                                          {subItem2.Title}
                                        </Link>
                                        {subItem2.Items && subItem2.Items.length > 0 && (
                                          <>
                                            <span
                                              onClick={() => toggleSubMenu(subItem2.Id, 3)}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <i className='fa fa-chevron-right'></i>
                                            </span>
                                            {subItem2.isOpen && (
                                              <div className='subMenu'>
                                                <div
                                                  className='back'
                                                  onClick={() => toggleSubMenu(subItem2.Id, 3)}
                                                  style={{ cursor: 'pointer' }}
                                                >
                                                  <i className='fa fa-chevron-left'></i> Geri Dön
                                                </div>
                                                {subItem2.Items.map((subItem3: IMenu, index) => (
                                                  <div className='item' key={'mSubMenu3' + index}>
                                                    <Link
                                                      to={
                                                        subItem3.Link
                                                          ? subItem3.Link
                                                          : `/products?categoryId=${subItem3.Id}`
                                                      }
                                                      onClick={() => setIsMobileMenuActive(false)}
                                                    >
                                                      {subItem3.Title}
                                                    </Link>
                                                  </div>
                                                ))}
                                              </div>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </div>
            ))}
        </nav>
      </div>
      <div
        className={`mobileSearch ${isMobileSearchActive ? 'active' : ''}`}
        onClick={() => setIsMobileSearchActive(false)}
      >
        <i className='fa fa-close'></i>
        <div className='mobileSearchInput' onClick={e => e.stopPropagation()}>
          <SearchInput
            onChange={handleOnChangeInputSearch}
            onOpen={openSearchBox}
            onClose={closeSearchBox}
            isSearchActive={isSearchActive}
            setSearchMessage={setSearchMessage}
            handleSearchCompleted={handleSearchCompleted}
            searchValue={state.searchOnChangeValue}
            selectedCategories={state.selectedCategories}
            selectedCompanies={state.selectedCompanies}
          >
            {isSearchActive && (
              <div
                className={`searchDropdown searchDropdownMobile row mx-0 p-t-20 ${
                  isSearchActive ? 'searchActive' : ''
                }`}
              >
                <div className='col-12'>
                  <h6>Şirket</h6>
                  <div className='d-flex flex-wrap gap-2'>
                    <IPCheckbox
                      label='Index'
                      labelClassName={'search-input-checkbox'}
                      checked={state.selectedCompanies.findIndex((val: number) => val === 1) > -1}
                      onCheckedChange={(checked: boolean) => handleCompanyCheckedChange(1, checked)}
                    />
                    <IPCheckbox
                      label='Despec'
                      labelClassName={'search-input-checkbox'}
                      checked={state.selectedCompanies.findIndex((val: number) => val === 3) > -1}
                      onCheckedChange={(checked: boolean) => handleCompanyCheckedChange(3, checked)}
                    />
                    <IPCheckbox
                      label='Datagate'
                      labelClassName={'search-input-checkbox'}
                      checked={state.selectedCompanies.findIndex((val: number) => val === 4) > -1}
                      onCheckedChange={(checked: boolean) => handleCompanyCheckedChange(4, checked)}
                    />
                    <IPCheckbox
                      label='Netex'
                      labelClassName={'search-input-checkbox'}
                      checked={state.selectedCompanies.findIndex((val: number) => val === 2) > -1}
                      onCheckedChange={(checked: boolean) => handleCompanyCheckedChange(2, checked)}
                    />
                  </div>
                  <h6>Kategori</h6>
                  <div className='d-flex flex-wrap gap-2'>
                    {state.menus &&
                      state.menus.map((item: IMenu) => (
                        <IPCheckbox
                          label={item.Title}
                          labelClassName={'search-input-checkbox'}
                          checked={state.selectedCategories.findIndex((val: number) => val === item.Id) > -1}
                          onCheckedChange={(checked: boolean) => handleCategoryCheckedChange(item.Id, checked)}
                          key={item.Id}
                        />
                      ))}
                  </div>
                </div>
                <div className='col-12'>
                  <ul className='list-group list-group-flush searchResults'>
                    {state.searchResult &&
                      state.searchResult.map((product: IProductSearchResponse) => {
                        return (
                          <li key={product.ProductId} className='list-group-item border-0'>
                            <Link
                              to={`/product/detail/${product.ProductId}`}
                              onClick={() => {
                                setIsSearchActive(false)
                                setIsMobileSearchActive(false)
                                handleOnChangeInputSearch('')
                              }}
                            >
                              {product.ProductName}
                            </Link>
                          </li>
                        )
                      })}
                  </ul>
                  <div className='currentContent'>
                    <h6>{t('layouts.client.yourSearchHistory')}</h6>
                    <div className='d-block mx-auto pt-2 h-auto'>
                      <ul className='list list-legend'>
                        {searchHistoryKeywords &&
                          searchHistoryKeywords.map((searchHistoryKeyword: string, i) => {
                            return (
                              <li key={'search' + i}>
                                <a role={'button'} onClick={() => handleOnChangeInputSearch(searchHistoryKeyword)}>
                                  {searchHistoryKeyword}
                                </a>
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </SearchInput>
        </div>
      </div>
    </>
  )
})
export default componentWithContext(Header)
