import React, { useEffect, useMemo } from 'react'
import { translate } from '../../../../base/localization'
import Translations from '../../../../translations'
import { useCustomReducer } from '../../../../base/customHooks'
import IPButton from '../../../../components/IPButton'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { Link, useNavigate } from 'react-router-dom'
import { GetSurveyListAdmin } from './services/getPollAdminApi'
import { ISurvey } from './models'
import Swal from 'sweetalert2'
import { IException } from '../../../../base/models'
import Table from '../../../../components/Table'
import { UpdatePollAdminApi } from './services/updatePollAdminApi'

interface IBannerState {
  selecteduserType?: ISelectOption
  surveyList: ISurvey[]
}

export default function Index() {
  const t = translate(Translations)
  const navigate: any = useNavigate()

  const getInitialState = (): IBannerState => {
    return {
      surveyList: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IBannerState>(intialState)

  useEffect(() => {
    getSurveyListAdmin()
  }, [])

  const getSurveyListAdmin = async () => {
    const getSurveyListAdmin = new GetSurveyListAdmin({})
    getSurveyListAdmin
      .getSurveyListAdmin()
      .then((res: ISurvey[]) => {
        if (res && res.length > 0) {
          setState({
            surveyList: res.sort(function (a, b) { return b.Id - a.Id })
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          allowOutsideClick: true,
        })
      })
  }


  const deletePollAdmin = async (survey: ISurvey) => {
    const req: any = {
      Id: survey.Id,
      Category: survey.Category,
      Deleted: true,
      StartDate: survey.StartDate,
      EndDate: survey.EndDate,
      CreatedDate: survey.CreatedDate
    }
    const api: UpdatePollAdminApi = new UpdatePollAdminApi({})
    api
      .updateSurvey(req)
      .then((res: boolean) => {
        Swal.fire({
          icon: 'success',
          title: 'Anket silinmiştir',
          allowOutsideClick: true,
          backdrop: true,
        }).then(() => {
          getSurveyListAdmin();
        })
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          allowOutsideClick: true,
          backdrop: true,
        })
      })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Anket Adı',
        accessor: 'col2',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'col3',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'col4',
      },
      {
        Header: 'İşlemler',
        accessor: 'col5',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.surveyList.map((item) => {
        const data = {
          col1: item.Id,
          col2: item.Category,
          col3: item.StartDate ? new Date(item.StartDate).toLocaleDateString('tr-TR') : '-',
          col4: item.EndDate ? new Date(item.EndDate).toLocaleDateString('tr-TR') : '-',
          col5: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.edit')}
                onClick={() => {
                  navigate('/admin/site-operation/add-poll', {
                    state: { surveyData: item },
                  })
                }}
              >
                <i
                  className='icon-edit'
                  style={{
                    fontSize: '1.2rem',
                    color: 'blue',
                  }}
                ></i>
              </a>
              <a
                data-action-type='delete'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.delete')}
                onClick={() => deletePollAdmin(item)}
              >
                <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
              </a>
              <a
                data-action-type='direct'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                onClick={() => {
                  navigate('/admin/site-operation/PollListChart', {
                    state: { surveyData: item.Id },
                  })
                }}
              >
                <i
                  className='fas fa-paper-plane'
                  style={{
                    fontSize: '1.2rem',
                    color: 'gray',
                    marginLeft: '1rem',
                  }}
                ></i>
              </a>
            </div>
          ),
        }
        return data
      }),
    [state.surveyList]
  )

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Anket Listeleme</span>
            <Link to='/admin/site-operation/add-poll'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Ekle'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='m-4'>
            <Table columns={tableColumns} data={tableData} currentPage={0} />
          </div>
        </div>
      </div>
    </div>
  )
}
