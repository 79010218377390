import { css } from '@emotion/css'
import { useEffect, useState } from 'react'
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Size, useWindowSize } from '../../base/customHooks/useWindowSize'
import { useAppSelector } from '../../redux/app/hooks'
import themeSlice, { selectedTheme } from '../../redux/slices/theme/themeSlice'
import './Stopwatch.scss'

interface IPStopwatch {
  startDate: Date
  endDate: Date
  style?: {}
  imageUrl?: string
  isInSlider?: boolean
  text: string
  height?: string
}

enum TimeInMs {
  Second = 1000,
  Minute = 60 * Second,
  Hour = 60 * Minute,
  Day = 24 * Hour,
}

enum TimeInSec {
  Second = 1,
  Minute = 60 * Second,
  Hour = 60 * Minute,
  Day = 24 * Hour,
}

export default function Stopwatch(props: IPStopwatch) {
  const windowSize: Size = useWindowSize()

  const RESPONSIVE_BREAKPOINT = 560
  const NORMAL_SIZE = 90
  const RESPONSIVE_SIZE = windowSize.width && (windowSize.width - 160) / 4
  const innerCircleSize =
    windowSize.width && windowSize.width < RESPONSIVE_BREAKPOINT && RESPONSIVE_SIZE && RESPONSIVE_SIZE / 1.3

  const dateDiffMs = props.endDate.getTime() - props.startDate.getTime()
  const diffMsFromNow = props.endDate.getTime() - new Date().getTime()

  const [isActive, setActive] = useState(true)

  const calculateRemainingDays = (miliseconds: number) => {
    return Math.floor(miliseconds / TimeInMs.Day)
  }

  const calculateRemainingHours = (miliseconds: number) => {
    return Math.floor((miliseconds % TimeInMs.Day) / TimeInMs.Hour)
  }

  const calculateRemainingMinutes = (miliseconds: number) => {
    return Math.floor((miliseconds % TimeInMs.Hour) / TimeInMs.Minute)
  }
  const theme = useAppSelector(selectedTheme)

  const calculateRemainingSeconds = (miliseconds: number) => {
    return Math.floor((miliseconds % TimeInMs.Minute) / TimeInMs.Second)
  }

  const remainingSeconds = diffMsFromNow > TimeInMs.Second ? calculateRemainingSeconds(diffMsFromNow) : 0
  const remainingMinutes = diffMsFromNow > TimeInMs.Minute ? calculateRemainingMinutes(diffMsFromNow) : 0
  const remainingHours = diffMsFromNow > TimeInMs.Hour ? calculateRemainingHours(diffMsFromNow) : 0
  const remainingDays = diffMsFromNow > TimeInMs.Day ? calculateRemainingDays(diffMsFromNow) : 0

  const renderDays = ({ remainingTime }) => {
    return (
      <div
        className={`InnerCircle ${css`
          width: ${innerCircleSize}px;
          height: ${innerCircleSize}px;
        `}`}
      >
        <div className='TimerValue'>{Math.floor(remainingTime / TimeInSec.Day)}</div>
        <div className='TimerText'>Gün</div>
      </div>
    )
  }

  const renderHours = ({ remainingTime }) => {
    return (
      <div
        className={`InnerCircle ${css`
          width: ${innerCircleSize}px;
          height: ${innerCircleSize}px;
        `}`}
      >
        <div className='TimerValue'>{Math.floor(remainingTime / TimeInSec.Hour)}</div>
        <div className='TimerText'>Saat</div>
      </div>
    )
  }

  const renderMinutes = ({ remainingTime }) => {
    return (
      <div
        className={`InnerCircle ${css`
          width: ${innerCircleSize}px;
          height: ${innerCircleSize}px;
        `}`}
      >
        <div className='TimerValue'>{Math.floor(remainingTime / TimeInSec.Minute)}</div>
        <div className='TimerText'>Dakika</div>
      </div>
    )
  }

  const renderSeconds = ({ remainingTime }) => {
    return (
      <div
        className={`InnerCircle ${css`
          width: ${innerCircleSize}px;
          height: ${innerCircleSize}px;
        `}`}
      >
        <div className='TimerValue'>{remainingTime}</div>
        <div className='TimerText'>Saniye</div>
      </div>
    )
  }

  if (!isActive) {
    return <></>
  }

  return (
    <div className={`${props.isInSlider ? 'w-100' : ''}`}>
      <div className={`${props.isInSlider ? 'pr-0 pl-0' : ''}`}>
        <div className='text-center' style={props.style}>
          <div
            className='StopWatch'
            style={{
              backgroundColor: theme.colors.backgroundColor,
              backgroundImage: props.imageUrl ? `url(${props.imageUrl})` : 'unset',
              backgroundRepeat: 'no-repeat',
              //backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundPositionX: 'left',
              height: props.height,
            }}
          >
            {/* <div className="arrowLeft"></div> */}
            <div className={'StopWatchText'}>{props.text}</div>
            <div className='d-flex align-items-center gap-3'>
              {remainingDays > 0 && (
                <div style={{ border: '6px solid #1b1d20', borderRadius: '50%' }}>
                  <div style={{ margin: '-1px' }}>
                    <CountdownCircleTimer
                      isPlaying
                      size={
                        windowSize.width && windowSize.width < RESPONSIVE_BREAKPOINT ? RESPONSIVE_SIZE : NORMAL_SIZE
                      }
                      colors={theme.colors.backgroundColor as any}
                      trailColor={'#1F2124'}
                      strokeWidth={12}
                      duration={dateDiffMs / TimeInMs.Second}
                      initialRemainingTime={
                        remainingDays * TimeInSec.Day +
                        remainingHours * TimeInSec.Hour +
                        remainingMinutes * TimeInSec.Minute +
                        remainingSeconds
                      }
                      updateInterval={1}
                      onComplete={() => ({
                        shouldRepeat: false,
                        delay: 0,
                        newInitialRemainingTime:
                          remainingDays > 0 ? dateDiffMs / TimeInMs.Second - TimeInSec.Minute + remainingSeconds : 0,
                      })}
                      onUpdate={(remainingTime: number) => {
                        if (remainingTime < 1) {
                          setActive(false)
                        }
                      }}
                    >
                      {renderDays}
                    </CountdownCircleTimer>
                  </div>
                </div>
              )}
              {remainingHours > 0 && (
                <div style={{ border: '6px solid #1b1d20', borderRadius: '50%' }}>
                  <div style={{ margin: '-1px' }}>
                    <CountdownCircleTimer
                      isPlaying
                      size={
                        windowSize.width && windowSize.width < RESPONSIVE_BREAKPOINT ? RESPONSIVE_SIZE : NORMAL_SIZE
                      }
                      colors={theme.colors.backgroundColor as any}
                      trailColor={'#1F2124'}
                      strokeWidth={12}
                      duration={TimeInSec.Day}
                      initialRemainingTime={
                        remainingHours * TimeInSec.Hour + remainingMinutes * TimeInSec.Minute + remainingSeconds
                      }
                      updateInterval={1}
                      onComplete={() => ({
                        shouldRepeat: true,
                        delay: 0,
                        newInitialRemainingTime:
                          remainingDays > 0 ? TimeInSec.Day - TimeInSec.Minute + remainingSeconds : 0,
                      })}
                    >
                      {renderHours}
                    </CountdownCircleTimer>
                  </div>
                </div>
              )}
              {remainingMinutes > 0 && (
                <div style={{ border: '6px solid #1b1d20', borderRadius: '50%' }}>
                  <div style={{ margin: '-1px' }}>
                    <CountdownCircleTimer
                      isPlaying
                      size={
                        windowSize.width && windowSize.width < RESPONSIVE_BREAKPOINT ? RESPONSIVE_SIZE : NORMAL_SIZE
                      }
                      colors={theme.colors.backgroundColor as any}
                      trailColor={'#1F2124'}
                      strokeWidth={12}
                      duration={TimeInSec.Hour}
                      initialRemainingTime={remainingMinutes * TimeInSec.Minute + remainingSeconds}
                      updateInterval={1}
                      onComplete={() => ({
                        shouldRepeat: true,
                        delay: 0,
                        newInitialRemainingTime:
                          remainingDays > 0 || remainingHours > 0
                            ? TimeInSec.Hour - TimeInSec.Minute + remainingSeconds
                            : 0,
                      })}
                    >
                      {renderMinutes}
                    </CountdownCircleTimer>
                  </div>
                </div>
              )}
              {/* {
                remainingSeconds > 0 &&
                <div style={{ border: '6px solid #1b1d20', borderRadius: '50%' }}>
                  <div style={{ margin: '-1px' }}>
                    <CountdownCircleTimer
                      isPlaying
                      size={windowSize.width && windowSize.width < RESPONSIVE_BREAKPOINT ? RESPONSIVE_SIZE : NORMAL_SIZE}
                      colors={theme.colors.backgroundColor as any}
                      trailColor={"#1F2124"}
                      strokeWidth={12}
                      duration={TimeInSec.Minute}
                      initialRemainingTime={remainingSeconds}
                      updateInterval={1}
                      onComplete={() => ({
                        shouldRepeat: true,
                        delay: 0,
                        newInitialRemainingTime:
                          remainingDays > 0 || remainingHours > 0 || remainingMinutes > 0
                            ? 59
                            : 0
                      })}
                    >
                      {renderSeconds}
                    </CountdownCircleTimer>
                  </div>
                </div>
              } */}
              {/* <div className="arrowRight"></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
