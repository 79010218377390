import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IPostUsageReportListRequest, IPostUsageReportResponseModel } from '../models/model'

export class PostUsageReportListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/fcvReport',
      requesterInfo,
    })
  }

  public async PostUsageReportList(request: IPostUsageReportListRequest): Promise<IPostUsageReportResponseModel[]> {
    return await this.postAsync<IPostUsageReportResponseModel[]>(request)
  }
}