import { useCustomReducer } from '../../../../base/customHooks'
import IPButton from '../../../../components/IPButton'
import { useMemo, useState } from 'react'
import '../AccountSummary/AccountSummary.scss'
import { GetProvizyonApi } from './services/getProvizyonApi'
import Swal from 'sweetalert2'
import { GetLogRequest, GetProvizyonRequest, IProvizyon } from './models'
import InputCampaign from '../../../../components/InputCampaign'
import IPSelectBox from '../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import IPCheckbox from '../../../../components/IPCheckBox'
import { exportToXLSX, formatDate } from '../../../../base/utils/dataHelper'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { InsertLogApi } from './services/insertLogApi'
import Table from '../../../../components/Table'
import IPExcelButton from '../../../../components/IPExcelButton'
interface IProvizyonState {
  selectedType: ISelectOption
}

function Provizyon(props: BaseComponentWithContextProps) {
  const getInitialState = (): IProvizyonState => {
    return {
      selectedType: { label: 'Hepsi', value: 'H' },
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IProvizyonState>(intialState)
  const [provData, setProvData] = useState<IProvizyon[]>([])

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const getProv = async () => {
    const req: GetProvizyonRequest = {
      Baslangic: formatDate(startDate),
      Bitis: formatDate(endDate),
      Type: state.selectedType.value.toString(),
    }
    props.showLoading()
    const getProvizyon: GetProvizyonApi = new GetProvizyonApi({})
    getProvizyon
      .getProvizyon(req)
      .then(result => {
        if (result) {
          setProvData(result)
          props.hideLoading()
        } else {
          console.warn('Provizyon result is empty.')
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        if (!err.description || err.description === 'OK') {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: 'Provizyon bulunamadı',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        } else {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
    return
  }

  const typeOptions = [
    { value: 'H', label: 'Hepsi' },
    { value: 'F', label: 'Faturalanmış' },
    { value: 'B', label: 'Bekleyen' },
  ]

  const faturaKes = async (checked: boolean, belNo: string) => {
    const req: GetLogRequest = {
      Check: checked,
      BelNo: belNo,
    }
    props.showLoading()
    const insertLog: InsertLogApi = new InsertLogApi({})
    insertLog
      .insertLogApi(req)
      .then(result => {
        if (result) {
          setProvData(result)
          props.hideLoading()
        } else {
          console.warn('Log error')
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        if (!err.description || err.description === 'OK') {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: 'Provizyon bulunamadı',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        } else {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
    return
  }

  // Tablo başlıkları
  const columnDefinitions = [
    { Header: 'Fatura Kestim', accessor: 'col1' },
    { Header: 'Provizyon Belgesi', accessor: 'col2' },
    { Header: 'Provizyon Referans', accessor: 'col3' },
    { Header: 'Tarih', accessor: 'col4' },
    { Header: 'Tutar', accessor: 'col5' },
    { Header: 'KDV', accessor: 'col6' },
    { Header: "KDV'li Tutar", accessor: 'col7' },
    { Header: 'Prim Tipi', accessor: 'col8' },
    { Header: 'Açıklama', accessor: 'col9' },
    { Header: 'Fatura Durumu', accessor: 'col10' },
    { Header: 'Fatura No', accessor: 'col11' },
  ]

  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableDataAndTotal = useMemo(() => {
    let totalWithKdv = 0
    const tableData = provData.map(item => {
      const cleanedTotalWithKdv = item.TutarKDVLi.replace(/\./g, '').replace(',', '.')
      const TotalWithKdv = parseFloat(cleanedTotalWithKdv)

      const data = {
        col1: (
          <IPCheckbox
            label=''
            onCheckedChange={(checked: boolean) => faturaKes(checked, item.ProvizyonReferans)}
            checked={item.FaturaKestim}
          />
        ),
        col2: item.ProvizyonBelgesi,
        col3: item.ProvizyonReferans,
        col4: item.Tarih,
        col5: item.Tutar,
        col6: item.KDV,
        col7: item.TutarKDVLi,
        col8: item.PrimTipi,
        col9: item.Aciklama,
        col10: item.FaturaDurumu,
        col11: item.FaturaNo,
      }
      if (!isNaN(TotalWithKdv)) {
        totalWithKdv += TotalWithKdv
      }
      return data
    })
    return {
      tableData,
      totalWithKdv,
    }
  }, [provData])

  const excelButton = async () => {
    try {
      if (provData) {
        const data: any[] = []
        if (provData.length > 0) {
          provData.map((item, index) => {
            return data.push({
              Sıra: index + 1,
              'Fatura Kestim': item.FaturaKestim ? 'Evet' : 'Hayır',
              'Provizyon Belgesi': item.ProvizyonBelgesi,
              'Provizyon Referans': item.ProvizyonReferans,
              Tarih: item.Tarih,
              Tutar: parseFloat(item.Tutar.replace(/\./g, '').replace(',', '.')),
              KDV: parseFloat(item.KDV.replace(/\./g, '').replace(',', '.')),
              "KDV'li Tutar": parseFloat(item.TutarKDVLi.replace(/\./g, '').replace(',', '.')),
              'Prim Tipi': item.PrimTipi,
              Açıklama: item.Aciklama,
              'Fatura Durumu': item.FaturaDurumu,
              'Fatura No': item.FaturaNo,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Provizyon Hakediş')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  return (
    <div className='Reports'>
      <div className='heading-text d-flex justify-content-between align-items-center'>
        <h4 style={{ textAlign: 'center' }}>Provizyon Hakediş</h4>
        <div className='d-flex align-items-center'>
          <IPExcelButton onClick={excelButton} />
        </div>
      </div>
      <form>
        <div className='form-row justify-content-center'>
          <div className='form-group col-md-3'>
            <InputCampaign
              type='date'
              id='StartDate'
              name='StartDate'
              label={'Başlangıç Tarihi'}
              //value={values.dateStart}
              value={toDateTimeLocal(startDate)}
              onChange={e => getDate(e.target.value, 'Start')}
              isActive
            />
          </div>
          <div className='form-group col-md-3'>
            <InputCampaign
              type='date'
              id='EndDate'
              name='EndDate'
              label={'Bitiş Tarihi'}
              //value={values.endDate}
              value={toDateTimeLocal(endDate)}
              onChange={e => getDate(e.target.value, 'End')}
              isActive
            />
          </div>
          <div className='form-group col-md-3'>
            <div className='labeltext'>
              <IPSelectBox
                name='Type'
                value={state.selectedType}
                options={typeOptions}
                onChangeSingle={option => {
                  setState({ selectedType: option })
                }}
              />
            </div>
          </div>
          <div className='form-group col-md-3'>
            <IPButton
              text='Listele'
              className='button'
              onClick={() => getProv()}
              style={{
                backgroundColor: 'red',
                color: 'white',
                borderRadius: '10px',
                width: '150px',
                height: '50px',
              }}
            />
          </div>
        </div>
      </form>
      <div className='m-4'>
        <Table
          columns={tableColumns}
          data={tableDataAndTotal.tableData}
          currentPage={0}
          headerName={`İşlem Tarihi Aralığı: ${new Date(startDate).toLocaleDateString()} - ${new Date(
            endDate
          ).toLocaleDateString()} ---TOPLAM KDV'li TUTAR: ${
            tableDataAndTotal.totalWithKdv
              .toFixed(2)
              .replace('.', ',')
              .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'
          }`}
          columnCount={11}
          backgroundColor='red'
          color='white'
        />
      </div>
    </div>
  )
}
export default componentWithContext(Provizyon)
