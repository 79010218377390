import React, { useEffect } from 'react'
import { useCustomReducer } from '../../../../base/customHooks'
import { ISimpleRole } from './models'
import { AddOrUpdateRoleApi } from './services/addOrUpdateRoleApi'
import { GetSecurityRoleApi } from './services/getSecurityRoleApi'
import { IException } from '../../../../base/models'
import IPButton from '../../../../components/IPButton'
import IPInput from '../../../../components/IPInput'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { GetCompaniesApi } from '../../../MySpecial/CurrentAccount/Extract/services/getCompaniesApi'
import { ICompany } from '../../../MyOrders/models/response/getOrderListResponseModel'
import IPSelectBox from '../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'

export interface RoleDefinitionsState {
  roles: ISimpleRole[]
  companies: ICompany[]
  selectedCompanies: ISelectOption[]
  roleId: number
  roleName: string
}

function RoleDefinitions(props: BaseComponentWithContextProps) {
  const getInitialState = (): RoleDefinitionsState => {
    return {
      roles: [],
      companies: [],
      selectedCompanies: [],
      roleId: 0,
      roleName: '',
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<RoleDefinitionsState>(intialState)

  useEffect(() => {
    getRoles()
    getCompanies()
  }, [null])

  const getRoles = () => {
    props.showLoading && props.showLoading()
    const api: GetSecurityRoleApi = new GetSecurityRoleApi({})
    api
      .getSecuritRole()
      .then((res: ISimpleRole[]) => {
        props.hideLoading && props.hideLoading()

        if (res) {
          setState({ roles: res })
        }
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const getCompanies = () => {
    const api: GetCompaniesApi = new GetCompaniesApi({})
    api
      .getCompanies()
      .then((res: ICompany[]) => {
        if (res) {
          setState({ companies: res })
        }
      })
      .catch((err: IException) => {})
  }

  const setRoleToBeUpdated = (roleId: number, roleName: string) => {
    const selectedRole: ISimpleRole | undefined = state.roles.find(
      (elem: ISimpleRole) => elem.IdSecurityRole === roleId
    )
    let authorizedCompanies: ISelectOption[] = []

    if (selectedRole && selectedRole.AuthorizedCompanies) {
      for (const companyId of selectedRole.AuthorizedCompanies) {
        const comp = state.companies.find((element: ICompany) => element.Id === companyId)

        if (comp && !authorizedCompanies.find((elem: ISelectOption) => Number(elem.value) === comp.Id)) {
          authorizedCompanies.push({ label: comp.Name, value: comp.Id })
        }
      }
    }

    setState({ roleId, roleName, selectedCompanies: authorizedCompanies })
  }

  const clearRoleToBeUpdated = () => {
    setRoleToBeUpdated(0, '')
  }

  const addOrUpdateRole = () => {
    if (!state.roleName || state.roleName.length < 3) {
      Swal.fire({
        icon: 'warning',
        title: 'Rol adı en az 3 karakterden oluşmalıdır!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })

      return
    }

    props.showLoading && props.showLoading()
    const updateRole: AddOrUpdateRoleApi = new AddOrUpdateRoleApi({})
    updateRole
      .addOrUpdateRole({
        RoleId: state.roleId,
        RoleName: state.roleName,
        AuthorizedCompanyList: state.selectedCompanies.map((option: ISelectOption) => Number(option.value)),
      })
      .then(result => {
        props.hideLoading && props.hideLoading()

        if (!result) {
          return
        }

        const role = state.roles.find((item: ISimpleRole) => item.IdSecurityRole === result.IdSecurityRole)

        if (role) {
          role.SecurityRoleName = result.SecurityRoleName
          role.AuthorizedCompanies = result.AuthorizedCompanies

          setState({ roles: state.roles })
        } else {
          setState({ roles: [...state.roles, result] })
        }

        Swal.fire({
          icon: 'success',
          title: state.roleId && state.roleId > 0 ? 'Rol Güncellendi' : 'Yeni Rol Eklendi',
          showConfirmButton: true,
          allowOutsideClick: false,
        })

        clearRoleToBeUpdated()
      })
      .catch((err: any) => {
        props.hideLoading && props.hideLoading()

        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const onChangeCompany = (options: ISelectOption[]) => {
    setState({ selectedCompanies: options })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Rol Ekleme/Güncelleme</span>
          </div>
          <div className='card-body'>
            <div className='row mb-3 mt-5'>
              <div className='col-md-12'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-4'>
                      <label htmlFor='kosul' className='form-label'>
                        Rol Adı
                      </label>
                      <IPInput
                        name='roleName'
                        type='text'
                        className='form-control'
                        value={state.roleName}
                        onChange={e => setState({ roleName: e.target.value })}
                      />
                    </div>
                    <div className='col-md-4'>
                      <label className='labeltext'>Şirket</label>
                      <IPSelectBox
                        name='Şirket'
                        placeholder={'Şirket Seçiniz...'}
                        onChangeMulti={onChangeCompany}
                        value={state.selectedCompanies}
                        isMulti
                        closeMenuOnSelect={false}
                        options={state.companies.map((company: ICompany) => {
                          return { value: company.Id, label: company.Name }
                        })}
                      />
                    </div>
                    <div className='col-md-2 mt-5'>
                      <IPButton
                        type='button'
                        className='btn btn-primary'
                        onClick={clearRoleToBeUpdated}
                        text={'Temizle'}
                      />
                    </div>
                    <div className='col-md-2 mt-5'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        onClick={() => addOrUpdateRole()}
                        text={state.roleId && state.roleId > 0 ? 'Güncelle' : 'Ekle'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='container'>
                  <div>
                    <table className='table'>
                      <thead>
                        <tr>
                          <td>Id</td>
                          <td>Rol</td>
                          <td>İşlem</td>
                        </tr>
                      </thead>
                      <tbody>
                        {state.roles &&
                          state.roles.map((item: ISimpleRole) => {
                            return (
                              <tr key={item.IdSecurityRole}>
                                <td>{item.IdSecurityRole}</td>
                                <td>{item.SecurityRoleName}</td>
                                <td>
                                  <a
                                    className='ml-2'
                                    role={'button'}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setRoleToBeUpdated(item.IdSecurityRole, item.SecurityRoleName)
                                    }}
                                  >
                                    <i className='icon-edit'></i>
                                  </a>
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(RoleDefinitions)
