import React, { useEffect, useState } from 'react'
import { useCustomReducer } from '../../../base/customHooks'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import IPSelectBox, { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import Swal from 'sweetalert2'
import Modal from '../../../components/IPModal/IPModal'
import { AddAddressRequest, IAddress, IAddressModel, IDistrict } from '../../MyCard/models/models'
import { AddAddressApi } from '../../MyCard/services/addAddressApi'
import IPInput from '../../../components/IPInput'
import 'react-credit-cards/lib/styles.scss'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import IPButton from '../../../components/IPButton'
import { UpdateAddressApi } from './services/updateAddressApi'
import { DeleteAddressApi } from './services/deleteAddressApi'
import { useFormik } from 'formik'
import validationSchema from './validation'
import InputMask from '../../../components/InputMask'
import IPTextarea from '../../../components/IPTextarea'
import IPImage from '../../../components/IPImage'
import './style.scss'
import { GetCityApi } from '../../MyCard/services/getCityApi'
import { GetTownApi } from '../../MyCard/services/getTownApi'
import _ from 'lodash'
import { GetDistrictApi } from '../../MyCard/services/getDistrictApi'
import { GetAddressSpecialApi } from './services/getAddressSpecialApi'
import IPCheckbox from '../../../components/IPCheckBox'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'

interface AdressState {
  selectedAddress?: ISelectOption
  addresses?: IAddress
  addAddressParams: AddAddressRequest
  showAddressModal: boolean
  isTCValid: boolean
  cities?: ISelectOption[]
  towns?: ISelectOption[]
  districts?: ISelectOption[]
  districtsData?: IDistrict[]
  district?: ISelectOption | null
  town?: ISelectOption | null
  cityBool: boolean
  townBool: boolean
  districtBool: boolean
  countryBool: boolean
  country?: ISelectOption | null
  cityChange: boolean
}

function Index(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): AdressState => {
    return {
      addAddressParams: Object.assign({}),
      showAddressModal: false,
      isTCValid: true,
      cityBool: false,
      townBool: false,
      districtBool: false,
      countryBool: false,
      cityChange: false,
    }
  }

  const CountryOptions = [{ value: 'Türkiye', label: 'Türkiye' }]
  const addressTypeOptions: ISelectOption[] = [
    { label: 'Sevk Adresi', value: 0 },
    { label: 'Lisans Adresi', value: 1 },
  ]

  useEffect(() => {
    getAddresses()
    getCity()
  }, [])

  const intialState = getInitialState()
  const [state, dispatch] = useCustomReducer<AdressState>(intialState)

  const getCity = async (id?) => {
    const getCityApi: GetCityApi = new GetCityApi({})
    const res = await getCityApi.getCity()
    if (res) {
      const data: ISelectOption[] = []
      res.map(city => {
        data.push({ value: city.Id, label: city.Il })
        if (id) {
          if (id === city.Id) dispatch({})
        }
      })
      dispatch({ cities: data })
    }
  }

  const getTown = async (id: number, type?: string, district?: string, neighbourhood?: string) => {
    const getTownApi: GetTownApi = new GetTownApi({})
    const res = await getTownApi.getTown(id)
    if (res) {
      const data: ISelectOption[] = []
      let dis: number = 0
      res.map(town => {
        data.push({ value: town.IlceKodu, label: town.IlceAdi })
        if (type && type === 'update') {
          if (town.IlceAdi === district) {
            dis = Number(town.IlceKodu)
          }
        }
      })
      dispatch({ towns: data })
      if (dis > 0) {
        getDistrict(dis, "update", neighbourhood)
      }
    }
  }

  const getDistrict = async (id: number, type?: string, neighbourhood?: string) => {

    const getDistrictApi: GetDistrictApi = new GetDistrictApi({})

    const res = await getDistrictApi.getDistrict(id)

    if (res) {
      const data: ISelectOption[] = []
      let neighbourhoodData: ISelectOption | null = null
      res.map(district => {
        data.push({ value: district.Id, label: district.MahalleAdi })
        if (type && type === 'update') {
          if (district.MahalleAdi === neighbourhood) {
            neighbourhoodData = { value: district.Id, label: district.MahalleAdi }
          }
        }
      })
      dispatch({ districts: data, districtsData: res, district: neighbourhoodData })
    }
  }

  const getAddresses = async () => {
    props.showLoading()
    const getAddressSpecialApi: GetAddressSpecialApi = new GetAddressSpecialApi({})
    const res = await getAddressSpecialApi.getAddresses()
    if (res) {
      dispatch({ addresses: res })
      props.hideLoading()
    }
  }

  const initialValues = Object.assign({
    Id: 0,
    NameAndSurname: '',
    Country: '',
    City: '',
    District: '',
    PostCode: '',
    SevkCode: '',
    Phone: '',
    Email: '',
    AddressDetail: '',
    AddressTitle: '',
    Unvan: '',
    Neighbourhood: '',
  })
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      addAddress()
    },
  })

  const getNeighborhood = async (id: number) => {
    state.districtsData &&
      state.districtsData.map(data => {
        if (data.Id === id) {
          setFieldValue('PostCode', data.PostaKodu)
          setFieldValue('SevkCode', data.SevkKodu)
        }
      })
  }

  const addAddress = async () => {
    const clnValues = _.clone(values)
    // clnValues.City = clnValues.City
    //   ? clnValues.City.label
    //   : "";
    // clnValues.District = state.town
    //   ? state.town.label
    //   : "";
    if (state.isTCValid) {
      if (values.Id > 0) {
        Swal.fire({
          icon: 'question',
          title: 'Adres güncellemesini onaylıyor musunuz?',
          showConfirmButton: true,
          allowOutsideClick: false,
          showDenyButton: true,
          confirmButtonText: t('pages.mySpecial.address.index.yes'),
          denyButtonText: t('pages.mySpecial.address.index.no'),
        }).then(async result => {
          if (result.isConfirmed) {
            const updateAddressApi: UpdateAddressApi = new UpdateAddressApi({})
            const res = await updateAddressApi.putAddress(clnValues)

            if (res) {
              Swal.fire({
                icon: 'success',
                title: t('pages.mySpecial.address.index.addressUpdated'),
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async result => {
                getAddresses()
                dispatch({ showAddressModal: false })
              })
            }
          } else if (result.isDenied) {
            dispatch({
              showAddressModal: false,
            })
          }
        })
      } else {
        const addAddressApi: AddAddressApi = new AddAddressApi({})

        const res = await addAddressApi.addAddress(clnValues).then(async res => {
          props.hideLoading();
          if (res) {
            getAddresses()
            dispatch({ showAddressModal: false })

            Swal.fire({
              icon: 'success',
              title: t('pages.mySpecial.address.index.newAddressAdded'),
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: t('pages.mySpecial.address.index.addressNotAdded'),
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
        })
          .catch((err: any) => {
            props.hideLoading();
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })


      }
    } else Swal.fire('TC kontrol yapıldı.')
  }


  const deleteAddress = async (addressId: number) => {
    Swal.fire({
      icon: 'question',
      title: t('pages.mySpecial.address.index.deleteSwal'),
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: t('pages.mySpecial.address.index.yes'),
      denyButtonText: t('pages.mySpecial.address.index.no'),
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const deleteBannerApi = new DeleteAddressApi({})

        const isDeleted = await deleteBannerApi.deleteAddress(addressId)
        if (isDeleted) {
          props.hideLoading()
          Swal.fire({
            icon: 'success',
            title: t('pages.mySpecial.address.index.addressDeleted'),
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async result => {
            getAddresses()
          })
        }
      }
    })
  }

  return (
    <React.Fragment>
      <div className='container TopluAddress'>
        <div className='col'>
          <div className='d-flex gap-4 justify-content-center justify-content-lg-end align-items-center flex-wrap'>
            {/* <div className='infoWrapper'>
              <a style={{ color: 'red' }} target={'_blank'} href='/assetWeb/docs/addres.xlsx' className='infoMarker'>
                Toplu Adres Ekleme
              </a>
              <div className='infoBox'>
                <IPImage src='/images/TopluAdres.png' alt='' />
              </div>
            </div> */}

            <IPButton
              className='btn btn border-0'
              style={{
                backgroundColor: 'rgb(238, 51, 62)',
                width: '150px',
              }}
              text={t('pages.mySpecial.address.index.addAddress')}
              onClick={() => {
                dispatch({
                  showAddressModal: true,
                  cityBool: false,
                  townBool: false,
                  districtBool: false,
                })
                resetForm()
              }}
            />
            {/* 
            <IPButton
              className='btn btn border-0'
              style={{
                backgroundColor: 'rgb(238, 51, 62)',
                padding: '10px',
                width: '150px',
              }}
              text='Toplu Adres Ekle'
              onClick={() => {
                dispatch({ showAddressModal: true })
                resetForm()
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className='col-sm-12' style={{ marginTop: '20px' }}>
        <div className='row'>
          {state.addresses &&
            state.addresses?.InvoiceAddressList.map((item: IAddressModel) => {
              return (
                <div className='col-sm-6 p-5'>
                  <div className='card p-5'>
                    <div className='card-header'>
                      <span className='h5'>
                        {' '}
                        {item.AddressTitle} {item.Unvan ? ' | ' + item.Unvan : ''}
                      </span>
                      <p>(Fatura Adresi)</p>
                    </div>
                    <div className='row m-t-10 p-10'>
                      <div className='col-sm-8'>
                        <h5>{item.NameAndSurname}</h5>
                      </div>
                      {!item.Issap && (
                        <div className='col-sm-4'>
                          <a
                            href='#'
                            style={{ color: 'red', float: 'right' }}
                            onClick={() => {
                              dispatch({
                                showAddressModal: true,
                                addAddressParams: JSON.parse(JSON.stringify(item)),
                                districtBool: true,
                                country: {
                                  value: item.Country,
                                  label: item.Country,
                                },
                              })
                              let id = 0
                              state.cities &&
                                state.cities != undefined &&
                                state.cities
                                  .filter(city => {
                                    return city.label === item.City
                                  })
                                  .map(c => {
                                    return (id = Number(c.value))
                                  })
                              getTown(id, 'update', item.District, item.Neighbourhood)
                              setValues(JSON.parse(JSON.stringify(item)))
                            }}
                          >
                            Adresi Düzenle
                          </a>
                        </div>
                      )}
                    </div>
                    <div className='row m-t-10 p-10'>
                      <div className='col'>
                        <p>{item.District}</p>
                        <p>{item.AddressDetail}</p>
                      </div>
                    </div>
                    <div className='row m-t-10 p-10'>
                      <div className='col-sm-9 '>
                        <p>{item.Phone}</p>
                      </div>
                      {!item.Issap && (
                        <div className='col-sm-3 '>
                          <a href='#' style={{ color: 'black', float: 'right' }} onClick={() => deleteAddress(item.Id)}>
                            <i className='icon-trash-2'></i> Sil
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            })}
          {state.addresses &&
            state.addresses?.ShippAddresList.map((item: IAddressModel) => {
              return (
                <div className='col-sm-6 p-5'>
                  <div className='card p-5'>
                    <div className='card-header'>
                      <span className='h5'>
                        {' '}
                        {item.AddressTitle}{' '}
                        {item.Unvan && item.AddressTitle.length > 0 ? ' | ' + item.Unvan : item.Unvan ?? ''}
                      </span>
                      <p>(Sevk Adresi)</p>
                    </div>
                    <div className='row m-t-10 p-10'>
                      <div className='col-sm-8'>
                        <h5>{item.NameAndSurname}</h5>
                      </div>
                      {!item.Issap && (
                        <div className='col-sm-4'>
                          <a
                            href='#'
                            style={{ color: 'red', float: 'right' }}
                            onClick={() => {
                              dispatch({
                                showAddressModal: true,
                                addAddressParams: JSON.parse(JSON.stringify(item)),
                                districtBool: true,
                                country: {
                                  value: item.Country,
                                  label: item.Country,
                                },
                              })
                              let id = 0
                              state.cities &&
                                state.cities != undefined &&
                                state.cities
                                  .filter(city => {
                                    return city.label === item.City
                                  })
                                  .map(c => {
                                    return (id = Number(c.value))
                                  })
                              getTown(id, 'update', item.District, item.Neighbourhood)
                              setValues(JSON.parse(JSON.stringify(item)))
                            }}
                          >
                            Adresi Düzenle
                          </a>
                        </div>)}
                    </div>
                    <div className='row m-t-10 p-10'>
                      <div className='col'>
                        <p>{item.District}</p>
                        <p>{item.AddressDetail}</p>
                      </div>
                    </div>
                    <div className='row m-t-10 p-10'>
                      <div className='col-sm-9 '>
                        <p>{item.Phone}</p>
                      </div>
                      {!item.Issap && (
                        <div className='col-sm-3 '>
                          <a href='#' style={{ color: 'black', float: 'right' }} onClick={() => deleteAddress(item.Id)}>
                            <i className='icon-trash-2'></i> Sil
                          </a>
                        </div>)}
                    </div>
                  </div>
                </div>
              )
            })}
          {state.addresses &&
            state.addresses?.LicenseAddressList.map((item: IAddressModel) => {
              return (
                <div className='col-sm-6 p-5'>
                  <div className='card p-5'>
                    <div className='card-header'>
                      <span className='h5'>
                        {' '}
                        {item.AddressTitle}{' '}
                        {item.Unvan && item.AddressTitle.length > 0 ? ' | ' + item.Unvan : item.Unvan ?? ''}
                      </span>
                      <p>(Yazılım Lisans Adresi)</p>
                    </div>
                    <div className='row m-t-10 p-10'>
                      <div className='col-sm-8'>
                        <h5>{item.NameAndSurname}</h5>
                      </div>
                      <div className='col-sm-4'>
                        <a
                          href='#'
                          style={{ color: 'red', float: 'right' }}
                          onClick={() => {
                            dispatch({
                              showAddressModal: true,
                              addAddressParams: JSON.parse(JSON.stringify(item)),
                              districtBool: true,
                              country: {
                                value: item.Country,
                                label: item.Country,
                              },
                            })
                            let id = 0
                            state.cities &&
                              state.cities != undefined &&
                              state.cities
                                .filter(city => {
                                  return city.label === item.City
                                })
                                .map(c => {
                                  return (id = Number(c.value))
                                })
                            getTown(id, 'update', item.District, item.Neighbourhood)
                            setValues(JSON.parse(JSON.stringify(item)))
                          }}
                        >
                          Adresi Düzenle
                        </a>
                      </div>
                    </div>
                    <div className='row m-t-10 p-10'>
                      <div className='col'>
                        <p>{item.District}</p>
                        <p>{item.AddressDetail}</p>
                      </div>
                    </div>
                    <div className='row m-t-10 p-10'>
                      <div className='col-sm-9 '>
                        <p>{item.Phone}</p>
                      </div>
                      <div className='col-sm-3 '>
                        <a href='#' style={{ color: 'black', float: 'right' }} onClick={() => deleteAddress(item.Id)}>
                          <i className='icon-trash-2'></i> Sil
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </div>

      <Modal
        title={t('pages.mySpecial.address.index.address')}
        show={state.showAddressModal}
        onClose={() =>
          dispatch({
            showAddressModal: false,
            cityBool: false,
            townBool: false,
            districtBool: false,
            towns: undefined,
            districts: undefined,
          })
        }
        onConfirm={handleSubmit}
        confirmText={
          values.Id > 0 ? t('pages.mySpecial.address.index.update') : t('pages.mySpecial.address.index.save')
        }
        width={700}
      >
        <>
          <form className='p-3'>
            <div className='form-row'>
              <div className='form-group col-md-6 align-items-center'>
                <label className=' text-sm-left'>* Adres Tipi</label>
                <IPSelectBox
                  id='Types'
                  name='Types'
                  options={addressTypeOptions}
                  value={(addressTypeOptions.filter(a => a.value === values.isLicense))}
                  onChangeSingle={option => {
                    setFieldValue('isLicense', option.value)
                  }}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6 align-items-center'>
                <label className=' text-sm-left'>* Adı Soyadı / Ünvan</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='NameAndSurname'
                  required
                  value={values.NameAndSurname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className='validation-error'>
                  {' '}
                  {errors.NameAndSurname && touched.NameAndSurname ? errors.NameAndSurname : null}
                </p>
              </div>
              <div className='form-group col-md-6 align-items-center '>
                <label className='text-sm-left'>Yetkili İsmi</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='Unvan'
                  required
                  value={values.Unvan}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6 align-items-center '>
                <label className='text-sm-left'>* {t('pages.mySpecial.address.index.phone')}</label>
                <InputMask
                  mask='(999) 999 99 99'
                  name='Phone'
                  type='text'
                  className='form-control'
                  value={values.Phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className='validation-error'> {errors.Phone && touched.Phone ? errors.Phone : null}</p>
              </div>
              <div className='form-group col-md-6 align-items-center '>
                <label className='text-sm-left'>* E-Mail</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='Email'
                  id='Email'
                  required
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className='validation-error'> {errors.Email && touched.Email ? errors.Email : null}</p>
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6 align-items-center '>
                <label className='text-sm-left'>* Ülke</label>
                <IPSelectBox
                  options={CountryOptions}
                  value={values.country}
                  placeholder={values.Country}
                  //isDisabled={state.countryBool}
                  onChangeSingle={option => {
                    dispatch({
                      country: option,
                    })
                    setFieldValue('Country', option.value)
                  }}
                />
                <p className='validationError'>{errors.Country && touched.Country && errors.Country}</p>
              </div>
              <div className='form-group col-md-6 align-items-center'>
                <label className='text-sm-left'>* İl</label>
                <IPSelectBox
                  isClearable
                  options={state.cities}
                  //isDisabled={state.cityBool}
                  value={
                    state.cities &&
                    state.cities
                      .filter((elem: any) => {
                        return elem.label === (values.City)
                      })
                      .map((elem2: any) => {
                        return { value: elem2.value, label: elem2.label }
                      })[0]
                  }
                  placeholder={values.City}
                  onChangeSingle={option => {
                    setFieldValue('City', option?.label)
                    dispatch({
                      district: { label: '', value: '' },
                      districts: undefined,
                      town: null,
                      towns: [{ label: '', value: '' }],
                      districtBool: false,
                      cityChange: true,
                      districtsData: undefined,
                    })
                    setFieldValue('PostCode', '')
                    setFieldValue('SevkCode', '')
                    setFieldValue('District', '')
                    setFieldValue('Neighbourhood', '')
                    if (option?.label)
                      getTown(Number(option.value))
                  }}
                />
                <p className='validationError'>{errors.City && touched.City && errors.City}</p>
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6 align-items-center'>
                <label className='text-sm-left'>* {t('pages.mySpecial.address.index.district')}</label>
                <IPSelectBox
                  isClearable
                  options={state.towns}
                  value={
                    values.District && state.towns &&
                    state.towns
                      .filter((elem: any) => {
                        return elem.label === values.District
                      })
                      .map((elem2: any) => {
                        return { value: elem2.value, label: elem2.label }
                      })[0]
                  }
                  //isDisabled={state.townBool}
                  //placeholder={values.District}
                  onChangeSingle={option => {
                    dispatch({ town: option })
                    setFieldValue('PostCode', '')
                    setFieldValue('SevkCode', '')
                    setFieldValue('Neighbourhood', '')
                    setFieldValue('District', option?.label)
                    if (option?.label)
                      getDistrict(Number(option.value))
                  }}
                />
                <p className='validationError'>{errors.District && touched.District && errors.District}</p>
              </div>
              <div className='form-group col-md-6 align-items-center '>
                <label className='text-sm-left'>* {t('pages.mySpecial.address.index.neighborhood')}</label>
                <IPSelectBox
                  isClearable
                  options={state.districts}
                  value={values.Neighbourhood && state.district}
                  onChangeSingle={option => {
                    setFieldValue('PostCode', '')
                    setFieldValue('SevkCode', '')
                    if (option?.value)
                      getNeighborhood(Number(option?.value))
                    setFieldValue('Neighbourhood', option?.label)
                    dispatch({ district: option })
                  }}
                />
                <p className='validationError'>{errors.PostCode && touched.PostCode && errors.PostCode}</p>
              </div>
              <div className='form-group col-md-3 align-items-center '>
                <label className='text-sm-left'>* {t('pages.mySpecial.address.index.postCode')}</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='PostCode'
                  disabled
                  required
                  value={values.PostCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className='form-group col-md-3 align-items-center '>
                <label className='text-sm-left'>* Sevk Kodu</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='SevkCode'
                  disabled
                  required
                  value={values.SevkCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className='form-group col-md-6 align-items-center '>
                <label className='text-sm-left'>* Adres Başlığı</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='AddressTitle'
                  required
                  value={values.AddressTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className='validation-error'>
                  {' '}
                  {errors.AddressTitle && touched.AddressTitle ? errors.AddressTitle : null}
                </p>
              </div>
            </div>
            <div className='row align-items-center'>
              <div className='form-group col-md-12 align-items-center '>
                <label className='text-sm-left'>* {t('pages.mySpecial.address.index.address')}</label>
                <IPTextarea
                  className='form-control'
                  name='AddressDetail'
                  required
                  value={values.AddressDetail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <p className='validation-error'>
                  {' '}
                  {errors.AddressDetail && touched.AddressDetail ? errors.AddressDetail : null}
                </p>
              </div>
            </div>
          </form>
        </>
      </Modal>
    </React.Fragment>
  )
}
export default componentWithContext(Index)