import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export class ContractApply extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/SendContract',
      requesterInfo,
    })
  }

  public async contractApply(request: number[]): Promise<boolean> {
    return await this.postAsync<boolean>(request)
  }
}
