import React, { useEffect, useMemo, useState } from 'react'
import Table from '../../../../../components/Table'
import { FilterProductsResponse } from '../models/models'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetNotFilteredProductsApi } from '../services/getNotFilteredProductsApi'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { ICompany } from '../../OpportunityAisleProduct/models/opportunityMarketList'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { Link } from 'react-router-dom'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'

interface ProductListState {
  companies: ICompany[]
  selectedCompany?: ISelectOption,
}

function FilterProducts(props: BaseComponentWithContextProps) {
  const [products, setProducts] = useState<FilterProductsResponse[]>([])

  const companies = [
    { label: 'Index', value: 1 },
    { label: 'Despec', value: 3 },
    { label: 'Netex', value: 2 },
    { label: 'Datagate', value: 4 },
  ]

  const getInitialState = (): ProductListState => {
    return {
      companies: [],
      selectedCompany: undefined,
    }
  }

  const intialState = getInitialState()

  const [state, setState] = useCustomReducer<ProductListState>(intialState)

  useEffect(() => {
    getProducts()
  }, [state.companies, state.selectedCompany])

  const getProducts = async () => {
    props.showLoading();
    const getNotFilteredProductsApi: GetNotFilteredProductsApi = new GetNotFilteredProductsApi({})
    const product = await (await getNotFilteredProductsApi.getNotFilteredProducts()).sort((a, b) => Number(a.Sirket) - Number(b.Sirket))
    if (state.selectedCompany)
      setProducts(product.filter(company => company.Sirket === state.selectedCompany?.value))
    else
      setProducts(product)
    props.hideLoading()
  }

  const columns = useMemo(
    () => [
      {
        Header: "Sıra",
        accessor: 'Id',
      },
      {
        Header: "Durum",
        accessor: 'Active',
      },
      {
        Header: "Şirket",
        accessor: 'Company',
      },
      {
        Header: "Ürün ID",
        accessor: 'ProductId',
      },
      {
        Header: 'SAP Kodu',
        accessor: 'SapCode',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'ProductCode',
      },
      {
        Header: 'Ürün Açıklama',
        accessor: 'ProductDesc',
      },
      {
        Header: 'Kategori ID',
        accessor: 'CategoryId',
      },
      {
        Header: 'Kategori',
        accessor: 'Category',
      },
      {
        Header: 'Özellik ID',
        accessor: 'PropertyId',
      },
      {
        Header: 'Özellik',
        accessor: 'PropertyName',
      },
      {
        Header: 'IC Grup ID',
        accessor: 'ICGroupId',
      },
      {
        Header: 'IC Grup',
        accessor: 'ICGroup',
      },
      // {
      //   Header: 'Tanımsız',
      //   accessor: 'None',
      // },
    ],
    []
  )

  const data = useMemo(
    () =>
      products.map((p, index) => ({
        Id: index + 1,
        Active: p.Activate ? "Aktif" : "Pasif",
        Company: p.Sirket === 1 ? "Index" : (p.Sirket === 2 ? "Netex" : p.Sirket === 3 ? "Despec" : "Datagate"),
        SapCode: p.SapCode,
        ProductId: <Link
          target="_blank"
          to={`/product/detail/${p.UrunID}?from=productList`}
        >
          {p.UrunID}
        </Link>,
        ProductCode: p.UrunKodu,
        ProductDesc: p.UrunAciklama,
        CategoryId: p.KategoriID,
        Category: p.Kategori,
        PropertyId: p.OzellikID,
        Property: p.Ozellik,
        PropertyName: p.OzellikAdi,
        ICGroupId: p.ICGruopID,
        ICGroup: p.ICGroupAdi,
      })),
    [products]
  )

  const excelButton = async () => {
    try {
      if (products) {
        const data: any[] = []
        if (products.length > 0) {
          products.map((p, index) => {
            return data.push({
              Sıra: index + 1,
              Durum: p.Activate ? "Aktif" : "Pasif",
              Şirket: p.Sirket === 1 ? "Index" : (p.Sirket === 2 ? "Netex" : p.Sirket === 3 ? "Despec" : p.Sirket === 4 ? "Datagate" : ""),
              "SAP Kodu": p.SapCode,
              "Ürün ID": p.UrunID,
              "Ürün Kodu": p.UrunKodu,
              "Ürün Açıklaması": p.UrunAciklama,
              "Kategori ID": p.KategoriID,
              Kategori: p.Kategori,
              "Özellik ID": p.OzellikID,
              Özellik: p.Ozellik,
              "Özellik Adı": p.OzellikAdi,
              "IC Grup ID": p.ICGruopID,
              "IC Grup": p.ICGroupAdi
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Filtre Özellikleri Girilmemiş Ürünler Listesi')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card' style={{ width: 'fit-content' }}>
          <div className='card-header'>
            <span className='h4'>
              Filtre Özellikleri Girilmemiş Ürünler Listesi
            </span>
          </div>
          <div className='card-body'>
            <div className='d-flex align-items-end gap-4'>
              <div className='col-lg-2'>
                <label htmlFor='company' className='form-label'>
                  Şirket
                </label>
                <IPSelectBox
                  isClearable
                  id='Şirket'
                  name='Şirket'
                  options={companies}
                  value={state.selectedCompany}
                  onChangeSingle={(option) => setState({ selectedCompany: option })}
                />
              </div>
              <div className='col-10 d-flex align-items-center justify-content-end'>
                <span>Excel: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButton}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <Table columns={columns} data={data} headerName="Filtre Özellikleri Girilmemiş Ürünler Listesi" columnCount={14} textAlign={true} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(FilterProducts)
