import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export class BannerDontShowAgainPopupApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/banner-dont-show',
      requesterInfo,
    })
  }

  public async dontShowAgain(bannerId: number): Promise<boolean> {
    this.props.url = `${this.props.url}/${bannerId}`
    return await this.getAsync<boolean>()
  }
}
