import './IPRadio.scss'

interface IPRadio extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  text: string
  name: string
}

export default function Radio(props: IPRadio) {
  const { type, className, name, text, ...others } = props
  return (
    <label className='p-radio radio-color-danger'>
      <input type='radio' name={name} {...others} />
      <span className={`p-radio-style ${className ?? ''}`}></span>
      {text && <div className='radio-text'>{text}</div>}
    </label>
  )
}
