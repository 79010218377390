import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IOrderReturn, IOrderReturnRequest } from '../models/request/getMyOrderRequest'

export class PostOrderReturnApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'orderReturn/insert',
      requesterInfo,
    })
  }

  public async postOrderReturn(request: IOrderReturn): Promise<IOrderReturnRequest> {
    return await this.postAsync<IOrderReturnRequest>(request)
  }
}
