import { useState, useEffect, Dispatch, SetStateAction } from 'react'

export function usePagination(
  itemList: any[],
  itemListCount: number,
  cardPerPage: number,
  initialPage: number
): [any[], number, number, Dispatch<SetStateAction<number>>, () => void, () => void] {
  const [selectedPage, setSelectedPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(0)
  const [filteredList, setFilteredList] = useState<any[]>([])

  const back = () => {
    setSelectedPage(selectedPage - 1)
  }

  const next = () => {
    setSelectedPage(selectedPage + 1)
  }

  const calculate = () => {
    setFilteredList(itemList.slice((selectedPage - 1) * cardPerPage, selectedPage * cardPerPage))
    if (itemList.length === itemListCount || itemListCount === 0) {
      return setTotalPage(Math.ceil(itemList.length / cardPerPage))
    }
    setTotalPage(Math.ceil(itemListCount / cardPerPage))
  }

  useEffect(() => {
    calculate()

    return () => {}
    // }, [itemListCount, itemList, cardPerPage, initialPage, selectedPage])},
  }, [itemList, cardPerPage, initialPage, selectedPage])

  return [filteredList, totalPage, selectedPage, setSelectedPage, back, next]
}
