import React, { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import Table from './Table'
import { IProductLink } from '../models/models'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetProductLinkApi } from '../services/getProductLinkApi'
import Swal from 'sweetalert2'
import { DeleteProductLinkApi } from '../services/deleteProductLinkApi'
import { ServiceRouter } from '../../../../../base/proxy/serviceRouter'

function ProductLinkList(props: BaseComponentWithContextProps) {
  const [searchWord, setSearchWord] = useState("")
  useEffect(() => {
    getProductLink()
  }, [])
  const t = translate(Translations)
  const [productLink, setProductLink] = useState<IProductLink[]>([])
  const [searchProductLink, setSearchProductLink] = useState<IProductLink[]>([])
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'col1'
      },
      {
        Header: 'Ürün Kodları',
        accessor: 'col2',
      },
      {
        Header: 'Kelime',
        accessor: 'col3',
      },
      {
        Header: 'Şirket',
        accessor: 'company',
      },
      {
        Header: 'Link',
        accessor: 'col4',
      },
      {
        Header: 'Tip',
        accessor: 'type',
      },
      {
        Header: 'İşlemler',
        accessor: 'col5',
      }
    ],
    []
  )
  let navigate = useNavigate()
  const deleteLink = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Link i silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading();
        const deleteProductLinkApi: DeleteProductLinkApi = new DeleteProductLinkApi({})
        deleteProductLinkApi
          .deleteProductLink(id)
          .then(async result => {
            props.hideLoading();
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Link Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async () => {
                setSearchProductLink(prevState => {
                  return prevState.filter((prev) => { return prev.Id != id })
                })
                setProductLink(prevState => {
                  return prevState.filter((prev) => { return prev.Id != id })
                })
              })
            }
          })
          .catch((err: any) => {
            props.hideLoading();
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      }
    })
  }

  const tableData = useMemo(
    () =>
      searchProductLink.map((item, index) => {
        const data = {
          col1: item.Id,
          col2: item.ProductsCode.join(", "),
          col3: item.SearchWord,
          col4: (<Link to={`/products?linkId=${item.Id}`}>{ServiceRouter.getUrl()}{ServiceRouter.getUrl() === "https://dev-b2b.vbt.com.tr:1443/" ? "" : "/"}products?linkId={item.Id}</Link>),
          col5: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.edit')}
                onClick={() => navigate('/admin/ProductOperation/ProductLinkAdd', {
                  state: { linkData: item },
                })}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
              <a
                data-action-type='delete'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.delete')}
                onClick={() => deleteLink(item.Id)}
              >
                <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
              </a>
            </div>
          ),
          company: item.Company === 1 ? "Index" : item.Company === 2 ? "Netex" : item.Company === 3 ? "Despec" : item.Company === 4 ? "Datagate" : "",
          type: item.IDType === 1 ? "Ürün ID" : "Ürün Kodu"
        }

        return data
      }),
    [searchProductLink]
  )

  const getProductLink = async () => {
    props.showLoading();
    const getProductLinkApi: GetProductLinkApi = new GetProductLinkApi({})
    getProductLinkApi
      .getProductLink()
      .then((result: IProductLink[]) => {
        props.hideLoading();
        setProductLink(result)
        setSearchProductLink(result)
      })
      .catch((err: any) => {
        props.hideLoading();
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const searchLink = async () => {
    const data: IProductLink[] = []
    productLink
      .filter(prod => {
        return prod.SearchWord.indexOf(searchWord) > -1
      })
      .map(tab => {
        return data.push(tab)
      })
    setSearchProductLink(data)
  }


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>
              {t('pages.admin.productOperation.productAttributeChangeData.productFeatureChange.header')}
            </span>
            <div className='d-flex justify-content-end'>
              <Link to='/admin/ProductOperation/ProductLinkAdd'>
                <IPButton
                  type='button'
                  className='btn btn-primary'
                  text={'Link Oluştur'}
                />
              </Link>
            </div>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label htmlFor='location' className='form-label'>
                    {t('pages.admin.productOperation.productAttributeChangeData.productFeatureChange.search')}
                  </label>
                  <IPInput type='text' className='form-control' id='SahaDegeri' onChange={e => setSearchWord(e.target.value)} />
                </div>
                <div className='col-lg-5'>
                  <label htmlFor='button' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={t('pages.admin.productOperation.productAttributeChangeData.productFeatureChange.list')}
                        onClick={searchLink}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </form>
            <div className='row'>
              <div className='m-4'>
                <Table columns={tableColumns} data={tableData} currentPage={0} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(ProductLinkList)

