import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { GetSerialNumberCheck } from '../models/request/getMyOrderRequest'
import { ICargoTracking } from '../models/response/cargoTrackingRes'
import {
  GetSerialNumberCheckResponse,
  GetShippingInformationResponse,
} from '../models/response/getOrderListResponseModel'

export class GetCargoTrackingApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetCargoTracking',
      requesterInfo,
    })
  }

  public async getCargoTracking(id: string): Promise<ICargoTracking> {
    this.props.url = `${this.props.url}?deliveryNo=${id}`
    return await this.postAsync<ICargoTracking>()
  }

  public async GetSerialNumberCheck(req: GetSerialNumberCheck): Promise<GetSerialNumberCheckResponse[]> {
    this.props.url = 'MyReports/GetSerialNumberCheck'
    return await this.postAsync<GetSerialNumberCheckResponse[]>(req)
  }

  public async GetShippingInformation(orderNumber: string): Promise<GetShippingInformationResponse> {
    this.props.url = `MyReports/GetShippingInformation?orderNumber=${orderNumber}`
    return await this.postAsync<GetShippingInformationResponse>()
  }
}
