import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IDealerSavedConditionRequest, IPaymentMetod } from '../models/models'

export class GetDealerSavedConditionApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/GetDealerSavedCondition',
      requesterInfo,
    })
  }

  public async getDealerSavedCondition(request: IDealerSavedConditionRequest): Promise<IPaymentMetod[]> {
    return await this.postAsync<IPaymentMetod[]>(request)
  }
}
