import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { ICurrentLogo, IDeleteCurrentLogo } from '../models/models'

export class DeleteCurrentLogoApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealer/deleteLogo/',
      requesterInfo,
    })
  }

  public async deleteCurrent(request: IDeleteCurrentLogo): Promise<IDeleteCurrentLogo> {
    return await this.postAsync<IDeleteCurrentLogo>(request)
  }
}
