import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { ICategoryList } from '../models/models'

export class GetCategoryListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/GetCategoryList',
      requesterInfo,
    })
  }

  public async getCategoryList(): Promise<ICategoryList[]> {
    return await this.getAsync<ICategoryList[]>()
  }
}
