export class ConfigHelper {
  public static INDEXPAZAR_APP_ENV_NAME: string = 'INDEXPAZAR_APP_ENV_NAME'
  public static INDEXPAZAR_APP_LANG: string = 'INDEXPAZAR_APP_LANG'
  public static INDEXPAZAR_USER_TOKEN: string = 'INDEXPAZAR_USER_TOKEN'
  public static INDEXPAZAR_USER_REFRESH_TOKEN: string = 'INDEXPAZAR_USER_REFRESH_TOKEN'
  public static INDEXPAZAR_USER_TOKEN_CREATE_TIME: string = 'INDEXPAZAR_USER_TOKEN_CREATE_TIME'
  public static INDEXPAZAR_USER_DATA: string = 'INDEXPAZAR_USER_DATA'
  public static INDEXPAZAR_SELECTED_THEME: string = 'INDEXPAZAR_SELECTED_THEME'
  public static INDEXPAZAR_OPENED_POPUPS_COMPANYIDS: string = 'INDEXPAZAR_OPENED_POPUPS_COMPANYIDS'
}

export class Pattern {
  public static NUMERIC: string = '[0-9]*'
  public static ALPHABETIC: string = '[a-zA-ZğüşöçİĞÜŞÖÇ]+'
  public static ALPHABETIC_WITH_SPACES: string = '[a-zA-ZğüşöçıİĞÜŞÖÇıs ]+'
  public static ALPHABETIC_LOWERCASE: string = '[a-zğüşöçİĞÜŞÖÇ]+'
  public static ALPHABETIC_LOWERCASE_WITH_SPACES: string = '[a-zğüşöçİĞÜŞÖÇ ]+'
  public static ALPHABETIC_UPPERCASE: string = '[A-ZğüşöçİĞÜŞÖÇ]+'
  public static ALPHABETIC_UPPERCASE_WITH_SPACES: string = '[A-ZğüşöçİĞÜŞÖÇ ]+'
  public static ALPHANUMERIC: string = '[a-zA-Z0-9ğüşöçİĞÜŞÖÇ]+'
  public static ALPHANUMERIC_WITH_SPACES: string = '[a-zA-Z0-9ğüşöçİĞÜŞÖÇ ]+'
  public static ALPHANUMERIC_LOWERCASE: string = '[a-z0-9ğüşöçİĞÜŞÖÇ]+'
  public static ALPHANUMERIC_LOWERCASE_WITH_SPACES: string = '[a-z0-9ğüşöçİĞÜŞÖÇ ]+'
  public static ALPHANUMERIC_UPPERCASE: string = '[A-Z0-9ğüşöçİĞÜŞÖÇ]+'
  public static ALPHANUMERIC_UPPERCASE_WITH_SPACES: string = '[A-Z0-9ğüşöçİĞÜŞÖÇ ]+'
  public static EMAIL: string = '[a-z0-9._%+-@?!$*]+'
  public static PRICE: string = '[0-9]+(\\.[0-9][0-9]?)?'
}

export class ServiceController {
  public static Banner: number = 1
  public static Company: number = 2
  public static Currency: number = 3
  public static Menu: number = 4
  public static Offer: number = 5
  public static AnalyticsReporting: number = 6
  public static ApplicationRequest: number = 7
  public static Auth: number = 8
  public static Authorization: number = 9
  public static AuthorizedServicesMain: number = 10
  public static AuthorizedServicesProcedur: number = 11
  public static Brand: number = 12
  public static CampaignAds: number = 13
  public static CampaignBundle: number = 14
  public static Category: number = 15
  public static Dealer: number = 16
  public static DealerInformation: number = 17
  public static General: number = 18
  public static HomepageProduct: number = 19
  public static OpenBox: number = 20
  public static OpportunitMarket: number = 21
  public static Order: number = 22
  public static ProductAttributes: number = 23
  public static Product: number = 24
  public static ProductGroup: number = 25
  public static ProductGroupRelationship: number = 26
  public static Sap: number = 27
  public static SiteStructure: number = 28
  public static Survey: number = 29
  public static User: number = 30
  public static UserPermission: number = 31
  public static UserProductList: number = 32
  public static WebinarCalander: number = 33
}

export class ServiceBannerAction {
  public static Mail: number = 1
  public static Insert: number = 2
  public static Update: number = 4
  public static Delete: number = 8
  public static BannerReset: number = 16
  public static GetListAdminPanel: number = 32
  public static GetDetail: number = 64
  public static GetFilterTheList: number = 128
}

export class ServiceCompanyAction {
  public static Delete: number = 1
  public static GetList: number = 2
}

export class ServiceCurrencyAction {
  public static UpdateCurrencies: number = 1
}

export class ServiceMenuAction {
  public static List: number = 1
  public static Add: number = 2
  public static Update: number = 4
  public static Delete: number = 8
  public static UpdateStatus: number = 16
  public static Get: number = 32
  public static getAllMenuList: number = 64
  public static getMenuAll: number = 128
}

export class ServiceOfferAction {
  public static AllOffers: number = 1
  public static Delete: number = 2
  public static AddNoteToOffer: number = 4
}

export class ServiceAnalyticsReportingAction {
  public static Sessions: number = 1
  public static AvgSessions: number = 2
  public static SessionsPerUser: number = 4
  public static TrafficSources: number = 8
  public static ActiveUser: number = 16
  public static ChangeStatus: number = 32
  public static AdminForUpdate: number = 64
  public static CreatePdf: number = 128
}
export class ServiceApplicationRequestAction {
  public static GetDetail: number = 1
  public static GetList: number = 2
  public static Update: number = 4
  public static Insert: number = 8
  public static SendContract: number = 16
  public static ChangeStatus: number = 32
  public static AdminForUpdate: number = 64
  public static CreatePdf: number = 128
}

export class ServiceAuthAction {
  public static Logout: number = 1
}
export class ServiceAuthorizationAction {
  public static List: number = 1
}
export class ServiceAuthorizedServicesMainAction {
  public static Insert: number = 1
  public static GetAll: number = 2
  public static Delete: number = 4
  public static Update: number = 8
}
export class ServiceAuthorizedServicesProcedurAction {
  public static Insert: number = 1
  public static GetAll: number = 2
  public static Delete: number = 4
  public static Update: number = 8
}

export class ServiceBrandAction {
  public static Insert: number = 1
  public static Update: number = 2
  public static Delete: number = 4
  public static ListBrands: number = 8
  public static ListBrandDetails: number = 16
  public static AddBrandDetail: number = 32
  public static UpdateBrandDetail: number = 64
  public static DeleteBrandDetail: number = 128
  public static UpdateStatus: number = 256
}
export class ServiceCampaignBundleAction {
  public static InsertCampaign: number = 1
  public static GetAll: number = 2
  public static GetById: number = 4
  public static UpdateCampaign: number = 8
  public static Delete: number = 16
  public static ProductCode: number = 32
  public static BrandList: number = 64
}

export class ServiceCategoryAction {
  public static List: number = 1
  public static Delete: number = 2
  public static Add: number = 4
  public static Update: number = 8
  public static UpdateStatus: number = 16
  public static MenuInsert: number = 32
  public static CategoryInsert: number = 64
  public static GetListByParentId: number = 128
  public static MenuUpdate: number = 256
  public static CategoryUpdate: number = 512
}

export class ServiceDealerAction {
  public static ListAll: number = 1
  public static ListByID: number = 2
  public static InsertDealerByUsers: number = 4
  public static InsertDealer: number = 8
  public static DealerLogoListing: number = 16
  public static ConfirmLogo: number = 32
  public static DeleteLogo: number = 64
  public static DefinedDealerAndUserInquiry: number = 128
}

export class ServiceDealerInformationAction {
  public static AddUser: number = 1
  public static UpdateUser: number = 2
  public static GetByAccountIdDealerCustomers: number = 4
  public static GetDealerCustomersList: number = 8
}
export class ServiceGeneralAction {
  public static ListAllQuestionAndAnswer: number = 1
  public static AddQuestionAnswer: number = 2
  public static UpdateQuestionAnswer: number = 4
  public static DeleteQuestionAnswer: number = 8
  public static TestSms: number = 16
}
export class ServiceHomepageProductAction {
  public static Insert: number = 1
  public static Update: number = 2
  public static Delete: number = 4
  public static ListAll: number = 8
}
export class ServiceOpenBoxAction {
  public static Insert: number = 1
  public static UpdateMatchEntity: number = 2
  public static GetAll: number = 4
  public static Delete: number = 8
}
export class ServiceOpportunitMarketAction {
  public static Insert: number = 1
  public static Update: number = 2
  public static Delete: number = 4
  public static ListOpportunitMarketForAdmin: number = 8
  public static FilteredList: number = 16
}
export class ServiceOrderAction {
  public static Delete: number = 1
  public static FilterByAccountCode: number = 2
  public static FetchAllOrders: number = 4
}
export class ServiceProductAttributesAction {
  public static GetById: number = 1
  public static GetByProductId: number = 2
  public static GetAll: number = 4
  public static ListAll: number = 8
}
export class ServiceProductAction {
  public static Delete: number = 1
  public static GetProductDetail: number = 2
  public static List: number = 4
  public static SearchList: number = 8
  public static SearchList_: number = 16
  public static ListProductsByBrand: number = 32
  public static ListProductsByCategory: number = 64
  public static InsertImage: number = 128
  public static UpdateImage: number = 256
  public static ImageListAll: number = 512
  public static ImageListById: number = 1024
  public static ImageDeleteById: number = 2048
  public static ImageDeleteByIds: number = 4096
  public static SearchByProductCode: number = 8192
}
export class ServiceProductGroupAction {
  public static Insert: number = 1
  public static Update: number = 2
  public static Delete: number = 4
  public static List: number = 8
}
export class ServiceProductGroupRelationshipAction {
  public static Insert: number = 1
  public static Update: number = 2
  public static Delete: number = 4
  public static GetList: number = 8
  public static GetRelatedProducts: number = 16
}
export class ServiceSapAction {
  public static UpdateCategoryList: number = 1
  public static UpdateBrandList: number = 2
  public static UpdateProductList: number = 4
  public static UpdateProductPriceList: number = 8
  public static UpdateAgentList: number = 16
  public static UpdateCurrency: number = 32
  public static UpdateCityList: number = 64
  public static UpdateTownList: number = 128
  public static UpdateNeighbourhoodList: number = 256
  public static UpdateProductDetails: number = 512
  public static UpdateDealer: number = 1024
  public static UpdateProductSubTypes: number = 2048
  public static UpdateCampaignTransfer: number = 4096
  public static UpdateJobReservation: number = 8192
  public static UpdateProductVisibility: number = 16384
  public static UpdateArpSubTypes: number = 32768
  public static UpdateComingStock: number = 65536
  public static UpdateStockInfo: number = 131072
  public static UpdateArpPriceList: number = 262144
}
export class ServiceSiteStructureAction {
  public static Insert: number = 1
  public static Update: number = 2
  public static GetList: number = 4
}

export class ServiceSurveyAction {
  public static ListAll: number = 1
  public static AddQuestion: number = 2
  public static TotalToOptions: number = 4
  public static UptadeQuestion: number = 8
  public static UptadeQuestion_: number = 16
  public static SurveyReportResult: number = 32
  public static SurveyPublishList: number = 64
  public static GetSurveyById: number = 128
  public static AddSurvey: number = 256
}
export class ServiceUserAction {
  public static Get: number = 1
  public static GetAllUsersByTable: number = 2
  public static OpenSessionList: number = 4
  public static EndSession: number = 8
}
export class ServiceUserPermissionAction {
  public static GetAllUserPermission: number = 1
  public static GetSecurityRole: number = 2
  public static GetUserPermissionByUserId: number = 4
  public static AddUserActionList: number = 8
  public static GetAllRolePermission: number = 16
  public static GetRolePermissionByRoleId: number = 32
  public static AddRolePermissionList: number = 64
  public static AddOrUpdateSecurityRole: number = 128
  public static ChangeUsersRole: number = 256
}

export class ServiceUserProductListAction {
  public static BringUserProductList: number = 1
}
export class ServiceWebinarCalanderAction {
  public static Insert: number = 1
  public static GetAllEvents: number = 2
  public static Delete: number = 4
  public static Update: number = 8
}
