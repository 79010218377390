import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import IPImage from '../../../components/IPImage'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { GetDppContentsApi } from '../services/getDppContentsApi'
import Swal from 'sweetalert2'
import { IDPP, IMenu, ITarget } from '../models/models'
import { useCustomReducer } from '../../../base/customHooks'
import './style.scss'
import { GetDPPTargetApi } from '../services/getDPPTargetApi'
import IPButton from '../../../components/IPButton'

interface IDppState {
  dppData: IDPP
  leftTitle: IMenu
  leftData: IMenu[]
  centerTitle: IMenu
  centerData: IMenu[]
  rightTitle: IMenu
  rightData: IMenu[]
  myTargets: ITarget[]
  buttonClick: string
  clicked: boolean
}

function Index(props: BaseComponentWithContextProps) {

  useEffect(() => {
    getDpp()
    getDPPTarget()
  }, [])

  const getInitialState = (): IDppState => {
    return {
      dppData: {
        CenterMenu: [],
        LeftMenu: [],
        RightMenu: [],
        SlideMenu: []
      },
      leftTitle: {
        Deleted: false,
        Id: 0,
        Image: "",
        IsTitle: false,
        Link: "",
        Location: 0,
        Name: "",
        OrderNo: 0
      },
      leftData: [],
      centerTitle: {
        Deleted: false,
        Id: 0,
        Image: "",
        IsTitle: false,
        Link: "",
        Location: 0,
        Name: "",
        OrderNo: 0
      },
      centerData: [],

      rightTitle: {
        Deleted: false,
        Id: 0,
        Image: "",
        IsTitle: false,
        Link: "",
        Location: 0,
        Name: "",
        OrderNo: 0
      },
      rightData: [],

      myTargets: [],
      buttonClick: "Detayları Göster",
      clicked: false,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IDppState>(intialState)

  const [days, setDays] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  const getDpp = async () => {
    props.showLoading()
    const api: GetDppContentsApi = new GetDppContentsApi({})
    try {
      const res: IDPP = await api.getDppContents();
      const leftTitle = res.LeftMenu.find((left) => left.IsTitle);
      const leftData = res.LeftMenu.filter((left) => !left.IsTitle);
      const centerTitle = res.CenterMenu.find((left) => left.IsTitle);
      const centerData = res.CenterMenu.filter((left) => !left.IsTitle);
      const rightTitle = res.RightMenu.find((right) => right.IsTitle);
      const rightData = res.RightMenu.filter((right) => !right.IsTitle);

      setState({
        leftTitle,
        leftData,
        centerTitle,
        centerData,
        rightTitle,
        rightData,
        dppData: res,
      });

      props.hideLoading();
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
      // api.getDppContents().then((res: IDPP) => {
      //   const leftTitle = res.LeftMenu.find((left) => { return left.IsTitle })
      //   if (leftTitle !== undefined)
      //     setState({ leftTitle })
      //   const leftData = res.LeftMenu.filter((left) => { return !left.IsTitle })
      //   if (leftData !== undefined)
      //     setState({ leftData })
      //   const centerTitle = res.CenterMenu.find((left) => { return left.IsTitle })
      //   if (centerTitle !== undefined)
      //     setState({ centerTitle })
      //   const centerData = res.CenterMenu.filter((left) => { return !left.IsTitle })
      //   if (centerData !== undefined)
      //     setState({ centerData })
      //   const rightTitle = res.RightMenu.find((right) => { return right.IsTitle })
      //   if (rightTitle !== undefined)
      //     setState({ rightTitle })
      //   const rightData = res.RightMenu.filter((right) => { return !right.IsTitle })
      //   if (rightData !== undefined)
      //     setState({ rightData })
      //   setState({ dppData: res }) 
      //   props.hideLoading()
      // }).catch((err: any) => {
      //   Swal.fire({
      //     icon: 'error',
      //     title: err.description,
      //     showConfirmButton: true,
      //     allowOutsideClick: false,
      //   })
      props.hideLoading()
    }
  }

  const getDPPTarget = async () => {
    const getLicenseTracking: GetDPPTargetApi = new GetDPPTargetApi({});
    getLicenseTracking.getDPPTargets().then(result => {
      if (result) {
        setState({ myTargets: result })
      } else {
        console.warn("DPPTargets result is empty.");
      }
    }).catch((err: any) => {
      if (!err.description || err.description === "OK") {
        console.log("Hedef verisi bulunamadı")
      } else {
        console.log(err.description)
      }
    })
    return;
  }

  const bitisTarihleri = state.myTargets.map(x => x.BITIS_TARIHI)

  useEffect(() => {
    if (bitisTarihleri.length > 0) {
      const target1 = bitisTarihleri[0];
      if (target1) {
        const [year, month, day] = [
          parseInt(target1.substring(0, 4)),
          parseInt(target1.substring(4, 6)) - 1,
          parseInt(target1.substring(6, 8))
        ];
        const bitisTarihi = new Date(year, month, day, 23, 59, 59);

        const timerInterval = setInterval(() => {
          const now = new Date();
          const difference = Math.max(bitisTarihi.getTime() - now.getTime(), 0) / 1000;

          setDays(Math.floor(difference / 86400));
          setHours(Math.floor((difference % 86400) / 3600));
          setMinutes(Math.floor((difference % 3600) / 60));
          setSeconds(Math.floor(difference % 60));

          if (difference <= 0) {
            clearInterval(timerInterval);
          }
        }, 1000);

        return () => {
          clearInterval(timerInterval);
        };
      }
    } else {
      console.error("Bitis Tarihleri boş");
    }
  }, [bitisTarihleri]);

  const targetDetails = () => {
    if (state.clicked)
      setState({ buttonClick: "Detayları Göster", clicked: false })
    else {
      setState({ buttonClick: "Detayları Gizle", clicked: true })
    }
  }

  const uniqueTargets: string[] = [];

  // state.myTargets dizisini kontrol edin ve eşsiz HEDEF_ADI'ları bulun
  state.myTargets && state.myTargets.forEach(left => {
    if (!uniqueTargets.includes(left.HEDEF_ADI)) {
      uniqueTargets.push(left.HEDEF_ADI);
    }
  });

  const createTable = () => {
    return (
      <table className='secondBox'>
        <thead className='sbHead'>
          <tr>
            <th></th>
            <th>Hedef</th>
            <th>Gerçekleşen</th>
            <th>Kalan</th>
          </tr>
        </thead>
        <tbody>
          {state.myTargets.map((a, index) => (
            <tr key={index}>
              <td>{a.KOTA === "252" ? "Mac" : a.KOTA === "253" ? "iPad" : a.KOTA === "254" ? "İphone" : ""}</td>
              <td>{a.HEDEF}</td>
              <td>{a.SATIS}</td>
              <td>
                {Number(a.HEDEF) - Number(a.SATIS)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  const targetDivs = uniqueTargets.map((hedefAdi, index) => (
    <div
      className='mainBox'
      key={index}
    >
      <div className='row firstBox'>
        <div className='targetName col-lg-3'>
          <span >{hedefAdi}</span>
        </div>
        <div className='timer col-lg-6'>
          <div className="timer-box">
            <span className="timer-value">{days}</span>
            <span className="timer-label">GÜN</span>
          </div>
          <div className="timer-box">
            <span className="timer-value">{hours}</span>
            <span className="timer-label">SAAT</span>
          </div>
          <div className="timer-box">
            <span className="timer-value">{minutes}</span>
            <span className="timer-label">DAKİKA</span>
          </div>
          <div className="timer-box">
            <span className="timer-value">{seconds}</span>
            <span className="timer-label">SANİYE</span>
          </div>
        </div>
        <div className='col-lg-3'>
          <IPButton
            type='button'
            className='detailsBtn'
            text={state.buttonClick}
            onClick={targetDetails}
          />
        </div>
      </div>
      <br></br>
      {state.clicked && createTable()}
    </div>
  ))

  return (
    <div className='container current-account'>
      <div className='content col-lg-12 m-auto'>
        <br></br>
        <div className=''>
          <div className='card-body'>
            {
              state.dppData && state.dppData.SlideMenu && state.dppData.SlideMenu.length > 0 &&
              <Swiper
                cssMode={true}
                navigation={true}
                keyboard={true}
                pagination={{ clickable: true }}
                observer={true}
                observeParents={true}
                modules={[Navigation, Mousewheel, Keyboard, Pagination]}
                className='mySwiper'
              >
                {
                  state.dppData.SlideMenu.map((slide, index) => (
                    slide.Link ?
                      <SwiperSlide key={index}>
                        <a href={slide.Link}>
                          <IPImage
                            className='banner'
                            style={{ height: '100%', padding: '5px 0 0 0' }}
                            src={slide.Image}
                            alt=''
                          />
                        </a>
                      </SwiperSlide>
                      : <SwiperSlide key={index}>
                        <IPImage
                          className='banner'
                          style={{ height: '100%', padding: '5px 0 0 0' }}
                          src={slide.Image}
                          alt=''
                        />
                      </SwiperSlide>
                  ))
                }
              </Swiper>
            }
            {
              targetDivs
            }

            <div className='row m-t-10'>
              {
                state.dppData && state.dppData.LeftMenu && state.dppData.LeftMenu.length > 0 &&
                <div className='col-lg-4 card-mobile-resize'>
                  <div
                    className='most-popular-products-borderless'
                    style={{
                      minHeight: '550px',
                      height: '550px',
                    }}
                  >
                    <div className='room'>
                      {
                        state.leftTitle.Id > 0 &&
                        <>
                          <div className='room-image text-center'>
                            {
                              state.leftTitle.Link ?
                                <Link to={state.leftTitle.Link}>
                                  <IPImage src={state.leftTitle.Image} alt='' className='popular_product' />
                                </Link>
                                : (
                                  state.leftTitle.Image ? <IPImage src={state.leftTitle.Image} alt='' className='popular_product' /> : ""
                                )
                            }
                          </div>
                          <div className='row' style={{ textAlign: 'center' }}>
                            {
                              state.leftTitle.Link ?
                                <Link to={state.leftTitle.Link}>
                                  <h3 className='linkDpp'>{state.leftTitle.Name}</h3>
                                </Link>
                                : (
                                  state.leftTitle.Name ? <h3>{state.leftTitle.Name}</h3> : ""
                                )
                            }
                          </div>
                        </>
                      }
                      {
                        state.leftData.length > 0 && state.leftData.map((left, index) => (
                          <div className='row' style={{ textAlign: 'center' }} key={index}>
                            {
                              left.Link ?
                                <Link to={left.Link}>
                                  <h5 className='linkDpp'>{left.Name}</h5>
                                </Link>
                                : (
                                  left.Name ? <h5>{left.Name}</h5> : ""
                                )
                            }

                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              }
              {
                state.dppData && state.dppData.CenterMenu && state.dppData.CenterMenu.length > 0 &&
                <div className='col-lg-4 card-mobile-resize'>
                  <div
                    className='most-popular-products-borderless'
                    style={{
                      minHeight: '550px',
                      height: '550px',
                    }}
                  >
                    <div className='room'>
                      {
                        state.centerTitle.Id > 0 &&
                        <>
                          <div className='room-image text-center'>
                            {
                              state.centerTitle.Link ?
                                <Link to={state.centerTitle.Link}>
                                  <IPImage src={state.centerTitle.Image} alt='' className='popular_product' />
                                </Link>
                                : (
                                  state.centerTitle.Image ? <IPImage src={state.centerTitle.Image} alt='' className='popular_product' /> : ""
                                )
                            }
                          </div>
                          <div className='row' style={{ textAlign: 'center' }}>
                            {
                              state.centerTitle.Link ?
                                <Link to={state.centerTitle.Link}>
                                  <h3 className='linkDpp'>{state.centerTitle.Name}</h3>
                                </Link>
                                : (
                                  state.centerTitle.Name ? <h3>{state.centerTitle.Name}</h3> : ""
                                )
                            }
                          </div>
                        </>
                      }
                      {
                        state.centerData.length > 0 && state.centerData.map((center, index) => (
                          <div className='row' style={{ textAlign: 'center' }} key={index}>
                            {
                              center.Link ?
                                <Link to={center.Link}>
                                  <h5 className='linkDpp'>{center.Name}</h5>
                                </Link>
                                : (
                                  center.Name ? <h5>{center.Name}</h5> : ""
                                )
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              }
              {
                state.dppData && state.dppData.RightMenu && state.dppData.RightMenu.length > 0 &&
                <div className='col-lg-4 card-mobile-resize'>
                  <div
                    className='most-popular-products-borderless'
                    style={{
                      minHeight: '550px',
                      height: '550px',
                    }}
                  >
                    <div className='room'>
                      {
                        state.rightTitle.Id > 0 &&
                        <>
                          <div className='room-image text-center'>
                            {
                              state.rightTitle.Link ?
                                <Link to={state.rightTitle.Link}>
                                  <IPImage src={state.rightTitle.Image} alt='' className='popular_product' />
                                </Link>
                                : (
                                  state.rightTitle.Image ? <IPImage src={state.rightTitle.Image} alt='' className='popular_product' /> : ""
                                )
                            }
                          </div>
                          <div className='row' style={{ textAlign: 'center' }}>
                            {
                              state.rightTitle.Link ?
                                <Link to={state.rightTitle.Link}>
                                  <h3 className='linkDpp'>{state.rightTitle.Name}</h3>
                                </Link>
                                : (
                                  state.rightTitle.Name ? <h3>{state.rightTitle.Name}</h3> : ""
                                )
                            }
                          </div>
                        </>
                      }
                      {
                        state.rightData.length > 0 && state.rightData.map((right, index) => (
                          <div className='row' style={{ textAlign: 'center' }} key={index}>
                            {
                              right.Link ?
                                <Link to={right.Link}>
                                  <h5 className='linkDpp'>{right.Name}</h5>
                                </Link>
                                : (
                                  right.Name ? <h5>{right.Name}</h5> : ""
                                )
                            }
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              }
            </div>
            <div className='row m-t-10'>
              <div className='col-lg-6'>
                <a href='/dpp/marketing'>
                  <div
                    className='more-card p-10'
                    style={{
                      backgroundImage: 'linear-gradient(110deg, #f0f0f5 60%, #aeaed6 60%)',
                      borderRadius: '15px'
                    }}
                  >
                    <div className='more-header' style={{ color: 'black' }}>
                      <b>Pazarlama</b>
                    </div>
                    <div
                      className='more-text mt-20'
                      style={{
                        textAlign: 'left',
                        color: '#495259',
                      }}
                    >
                      Kampanya duyuruları ve <br />
                      pazarlama dokümanları
                    </div>
                    <div className='more-text mr-2' style={{ textAlign: 'right', color: 'black' }}>
                      Daha fazla bilgi al
                    </div>
                  </div>
                </a>
              </div>
              <div className='col-lg-6'>
                <a href='/dpp/training'>
                  <div
                    className='more-card p-10'
                    style={{
                      backgroundImage: 'linear-gradient(110deg, #fff0e6 60%, #eb9e6c 60%)',
                      borderRadius: '15px'
                    }}
                  >
                    <div className='more-header' style={{ color: 'black' }}>
                      <b>Eğitim</b>
                    </div>
                    <div className='more-text mt-20' style={{ color: '#495259' }}>
                      Ürün teknik bilgi dokümanları ve <br />
                      SEED'e erişim
                    </div>
                    <div className='more-text' style={{ textAlign: 'right', color: 'black' }}>
                      Daha fazla bilgi al
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Index)
