import React, { useMemo, useEffect, useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import Table from '../../../../../components/Table'
import { WbcListDLRPFProductsListApi } from '../services/wbcListDLRPFProductsListApi'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { IPrice, IPriceList, IWbcListDLRPFProductsList } from '../models/models'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPModal from '../../../../../components/IPModal'
import { ExcelWBCLISTDLRPFProductsListApi } from '../services/excelWBCLISTDLRPFProductsListApi'
import { downloadFileFromUrl } from '../../../../../base/utils/dataHelper'
import IPSelectBox from '../../../../../components/IPSelectBox/IPSelectBoxMenu'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'

interface IWbcState {
  wbcList: IWbcListDLRPFProductsList[]
  filterResult: IWbcListDLRPFProductsList[]
  showList: IPriceList[]
  selectedCompany?: ISelectOption
}
function ProductsWithNoPrice(props: BaseComponentWithContextProps) {
  const getInitialState = (): IWbcState => {
    return {
      wbcList: [],
      filterResult: [],
      showList: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IWbcState>(intialState)
  const [showList, setShowList] = useState(false)
  const wbcListDLRPFProductsList = async () => {
    props.showLoading()
    const wbcListDLRPFProductsListApi: WbcListDLRPFProductsListApi = new WbcListDLRPFProductsListApi({})
    await wbcListDLRPFProductsListApi
      .wbcListDLRPFProductsList()
      .then(async result => {
        if (result) {
          setState({ wbcList: result, filterResult: result })
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  useEffect(() => {
    wbcListDLRPFProductsList()
  }, [])


  useEffect(() => {
    if (state.selectedCompany)
      setState({ filterResult: state.wbcList.filter(a => a.Company === state.selectedCompany?.value) })
    else
      setState({ filterResult: state.wbcList })
  }, [state.selectedCompany])


  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'col1',
      },
      {
        Header: 'Sap Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col3',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'col4',
      },
      {
        Header: 'Ürün Sorumlusu',
        accessor: 'col5',
      },
      {
        Header: 'Şirket',
        accessor: 'comp',
      },
      {
        Header: 'Fiyat',
        accessor: 'col6',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.filterResult.map((item, index) => {
        let special = ""
        let normal = ""
        let endUser = ""
        item.Special.length > 0 && item.Special.forEach((spec) => (
          special += `<div style="margin-bottom:10px">${spec.PriceValue}</div>`
        ))
        item.Normal.length > 0 && item.Normal.forEach((spec) => (
          normal += `<div style="margin-bottom:10px">${spec.PriceValue}</div>`
        ))
        item.EndUser.length > 0 && item.EndUser.forEach((spec) => (
          endUser += `<div style="margin-bottom:10px">${spec.PriceValue}</div>`
        ))
        const data = {
          col1: index + 1,
          col2: item.MaterialNumber,
          col3: item.ProductCode,
          col4: item.ProductName,
          col5: item.ProductMDName,
          comp: item.Company,
          col6: (special.length > 0 || normal.length > 0 || endUser.length > 0) && <button
            type='button'
            className={'btn btn-sm btn-secondary'}
            onClick={() => Swal.fire({
              icon: 'info',
              title: 'Fiyat Listesi',
              html: `<div>
            <div class='d-flex gap-4 mb-4'>
              <div style="width:100px">Özel Fiyat</div>
              <div style="width:100px">Liste Fiyat</div>
              <div style="width:100px">Son Kullanıcı</div>
           </div>
            <div class='d-flex gap-4'>
              <div style="width:100px">
                ${special}
               </div>
               <div style="width:100px">
                ${normal}
              </div >
              <div style="width:100px">
                ${endUser}
              </div>
             </div >
         </div > `,
              showConfirmButton: true,
              allowOutsideClick: false,
            })}>Liste</button>
        }

        return data
      }),
    [state.filterResult]
  )
  const excelWBCLISTDLRPFProductsList = async () => {
    props.showLoading()
    try {
      const res = await new ExcelWBCLISTDLRPFProductsListApi({}).excelWBCLISTDLRPFProductsList(state.filterResult)
      if (res) {
        downloadFileFromUrl('WBC-LIST_DLR_PF Fiyatı Olmayan Ürünler Listesi', res.FileUrl)
        props.hideLoading()
      }
    } catch (err: any) {
      console.error(err)
      props.hideLoading()
    }
  }

  const companies = [
    { label: 'Index', value: 'Index' },
    { label: 'Despec', value: 'Despec' },
    { label: 'Netex', value: 'Netex' },
    { label: 'Datagate', value: 'Datagate' },
  ]

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'> WBC-LIST_DLR_PF Fiyatı Olmayan Ürünler Listesi</span>
            <IPButton
              type='button'
              className='btn btn-danger button-excel w-sm-100'
              text='Excele Aktar'
              onClick={excelWBCLISTDLRPFProductsList}
              style={{ width: '200px', float: 'right' }}
            />
          </div>
          <div className='card-body'>
            <div className='d-flex align-items-end gap-4'>
              <div className='col-lg-2'>
                <label htmlFor='company' className='form-label'>
                  Şirket
                </label>
                <IPSelectBox
                  isClearable
                  id='Şirket'
                  name='Şirket'
                  options={companies}
                  value={state.selectedCompany}
                  onChangeSingle={(option) => setState({ selectedCompany: option })}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-14'>
                      <Table columns={tableColumns} data={tableData} currentPage={0} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={showList}
        onClose={() => setShowList(false)}
        hideConfirm={true}
        width={800}
        title='Fiyat Listesi'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead>
            <tr>
              <th>
                Sıra
              </th>
              <th>
                Özel Fiyatı
              </th>
              <th>
                Liste Fiyatı
              </th>
              <th>
                Son Kullanıcı
              </th>
            </tr>
          </thead>
          <tbody>
            {
              state.showList && state.showList.length > 0 && state.showList.map((show, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{show.Special}</td>
                    <td>{show.Normal}</td>
                    <td>{show.EndUser}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}
export default componentWithContext(ProductsWithNoPrice)

