import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IGetBankInstallmentsResponse } from '../models/models'

export class GetBankInstallmentsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'general/bankInstallments',
      requesterInfo,
    })
  }

  public async getBankInstallments(): Promise<IGetBankInstallmentsResponse> {
    return await this.getAsync<IGetBankInstallmentsResponse>()
  }
}
