import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IBrandDetail } from '../models/models'

export class GetBrandDetailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/brand/listBrandDetails',
      requesterInfo,
    })
  }

  public async getBrandDetail(): Promise<IBrandDetail[]> {
    return await this.getAsync<IBrandDetail[]>()
  }
}
