import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ICity } from '../models/models'

export class GetCityApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'CityTownNeighbourhood/getCity',
      requesterInfo,
    })
  }

  public async getCity(): Promise<ICity[]> {
    return await this.getAsync<ICity[]>()
  }
}
