import { useEffect, useRef, useState } from 'react'
import { Spinner } from '../../../../components/Spinner'
import { css } from '@emotion/css'

interface XMLDisplayProps {
  base64Data: string
}

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data).replace("ï»¿", "")
  const byteArrays: Uint8Array[] = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array<number>(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

const XMLDisplay: React.FC<XMLDisplayProps> = ({ base64Data }) => {
  const [decodedXML, setDecodedXML] = useState('')

  const turnToBlobAndRelocate = data => {
    const blob = new Blob([data], { type: 'application/xml' })
    const url = window.URL || window.webkitURL
    const blobURL = url.createObjectURL(blob)
    window.location.href = blobURL
  }

  useEffect(() => {
    if (base64Data.length <= 2 * 1024 * 1024) {
      const decodedData = atob(base64Data).replace("ï»¿", "")
      // turnToBlobAndRelocate(decodedData)
      setDecodedXML(decodedData)
    } else {
      ; (async () => {
        const blob = b64toBlob(base64Data, 'text/xml')
        const decodedData = await blob.text()
        // turnToBlobAndRelocate(decodedData)
        setDecodedXML(decodedData)
      })()
    }
  }, [base64Data])

  function download() {
    const blob = new Blob([decodedXML], { type: 'application/xml' })
    const fileName = 'data.xml'
    // @ts-ignore
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // Internet Explorer desteği
      // @ts-ignore
      // window.navigator.msSaveOrOpenBlob(blob, fileName)
      const fileObject = xmlBase64toFile(res.data.Data, 'xmlfile')

      const blob = new Blob([fileObject], { type: fileObject.type });
      const objectURL = window.URL.createObjectURL(blob);
      window.open(objectURL);
    } else {
      // Diğer tarayıcılar
      var url = window.URL || window.webkitURL
      var link = document.createElement('a')
      link.href = url.createObjectURL(blob)
      link.download = fileName
      link.click()
    }
  }

  return (
    <div>
      {decodedXML ? (
        <>
          <button
            className={css`
              border: 1px solid #333;
              background-color: #333;
              color: #fff;
              padding: 0.5rem 1rem;
            `}
            onClick={download}
          >
            XML İndir
          </button>
          <pre>{decodedXML}</pre>
        </>
      ) : (
        <Spinner loading={true} />
      )}
    </div>
  )
}

export default XMLDisplay
