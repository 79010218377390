import MaskInput from 'react-input-mask'
import classes from '../IPInput/IPInput.module.scss'

interface IInputMaskProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  mask: string
  errors?: string
  touched?: boolean
}

export default function InputMask(props: IInputMaskProps) {
  const { mask, value, errors, touched, onChange, onBlur, onFocus, onPaste, className, readOnly, ...others } = props
  return (
    <>
      <MaskInput
        mask={mask}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onPaste={onPaste}
        readOnly={readOnly ? readOnly : false}
      >
        {inputProps => (
          <input
            className={`${classes.Input} ${className ?? ''}`}
            readOnly={readOnly ? readOnly : false}
            {...others}
            {...inputProps}
          />
        )}
      </MaskInput>
      {errors && touched && <div className='validationError'>{errors}</div>}
    </>
  )
}
