import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IBannerCategory } from '../models/requestModels'

export class PostBannerCategory extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/banner/update-banner-category',
      requesterInfo,
    })
  }

  public async postBannerCategory(req): Promise<IBannerCategory[]> {
    return await this.postAsync<IBannerCategory[]>(req)
  }
}
