import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IProductIsActive } from '../models/models'

export class GetAllProductActiveService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/getAllProducts',
      requesterInfo,
    })
  }

  public async getAllProducts(): Promise<any[]> {
    return await this.getAsync<any[]>()
  }
}
