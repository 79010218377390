import React, { useEffect, useState } from 'react'
import classes from '../styles/style.module.scss'
import { translate } from '../../../../base/localization'
import Translations from '../../../../translations'
import { IProduct } from '../../../ProductList/models/response/getProductListResponseModel'
import { Link } from 'react-router-dom'
import Image from '../../../../components/IPImage/IPImage'

export interface IInfoProps {
  product: IProduct
}

const ProductInfo = (props: IInfoProps) => {
  const { product } = props
  const t = translate(Translations)
  const [descHtml, setDescHtml] = useState<string>('')

  useEffect(() => {
    if (product.ProductDetailProperties)
      setDescHtml(
        (
          product.ProductDetailProperties.find(details => details.Featurename === 'Açıklama') as any
        )?.FeatureValue.replace(/<[^>]+>/g, '').replace('\n', '')
      )
  })

  // useEffect(() => {
  //   console.log((product.ProductDetailProperties.find(details => details.Featurename === "Açıklama") as any)?.FeatureValue);
  //   console.log(descHtml.includes(`\n`));
  // }, [descHtml]);
  const [showFullText, setShowFullText] = useState(false);
  let maxLen = 1000 // maxLen'i buraya taşıyın, bu şekilde component yeniden render olduğunda sıfırlanmaz

  const handleShowFullText = () => {
    setShowFullText(true);
    maxLen = Number.MAX_SAFE_INTEGER;
  };

  const truncatedDesc = (descHtml && descHtml.length > maxLen) ? `${descHtml.slice(0, maxLen)}...` : descHtml;



  return (
    <div>

      {!showFullText && descHtml && (
        <p style={{ fontSize: 16, color: '#000000' }} dangerouslySetInnerHTML={{ __html: truncatedDesc }}></p>
      )}

      {!showFullText && descHtml && maxLen < descHtml.length && (
        <Link to={'#'} onClick={handleShowFullText}>
          <p style={{ color: '#0FBDE0', fontSize: 18 }}> + {t('pages.productdetail.ForMore')} </p>
        </Link>
      )}

      {showFullText && (
        <p style={{ fontSize: 16, color: '#000000' }} dangerouslySetInnerHTML={{ __html: descHtml }}></p>
      )}
      <div className='mt-2' style={{ width: '350px' }}>
        <Image
          //src="/images/product-detail/videoDetail.svg"
          src={product.Image}
          alt=''
          className=''
        />
      </div>
    </div>
  )
}

export default ProductInfo
