import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
// import { RoleUser } from '../models'

export class GetDealerSimpleUsersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/userPermission/GetDealerSimpleUsers',
      requesterInfo,
    })
  }

  public async getDealerSimpleUsers(dealerCode: string): Promise<any[]> {
    this.props.url = `${this.props.url}?dealerCode=${dealerCode}`
    return await this.getAsync<any[]>()
  }
}
