import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'

export class ProductClickReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/productClickStats',
      requesterInfo,
    })
  }

  public async sendMailProductClickReport(req: { StartDate: Date; EndDate: Date ,Company: Number }): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
