import { number, string } from 'yup'
import { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import { ISimpleBasket } from '../../../redux/slices/basket/basketApi'
import { IOrder } from '../../MyOrders/models/response/getOrderListResponseModel'
import { IProduct } from '../../ProductList/models/response/getProductListResponseModel'

export interface IPaymentCard {
  HolderName: string
  CardNumber: string
  ExpireDate: string
  Cvc: string
  Installment: number
}

export interface IBankInfos {
  BinNumber: string
  Commercial: number
  Price: string
  CardType: string
  CardAssociation: string
  CardFamilyName: string
  Force3Ds: number
  BankCode: number
  BankName: string
  ForceCvc: number
  InstallmentPrices: any
}

export interface SaveOrderRequest {
  PaymentCard: IPaymentCard
  ShippingAddressId: number
  BillingAddressId: number
  Note: string
  MakePayment: boolean
  PaymentWay: string
}

export interface ICity {
  Id: number
  Il: string
  PlakaKodu: number
}

export interface binNumberRequest {
  BinNumber: string
  //amount: number;
  basket: ISimpleBasket
  CargoPayment?: ICargoItem[]
}

export interface binRequest {
  BinNumber: string
}

export interface ComplatePaymentRequest {
  PaymentCard: IPaymentCard
  ShippingAddressId?: number
  BillingAddressId?: number
  Note: string
  MakePayment: boolean
  Amount: number
  PaymentWay: string
}

export interface IOrderNote {
  CompanyId: number
  WarehouseId: Number
  Note: string
}

export interface PaymentRequestModel {
  Id: number
  BillingAddressId: number
  MakePayment: boolean
  Amount: number
  SavedConditionList: ISavedConditionList[]
  ShippingAddressId: number
  Note: string
  CardNumber: string
  HolderName: string
  ExpireDate: string
  Cvc: string
  Installment: number
  PaymentWay: string
  isSapAddress?: boolean
  ShippingDate?: Date
  ShippingType?: string
  ShippingName?: string
  Basket?: ISimpleBasket
  CargoPayment?: ICargoItem[]
  InstallmentPrices: IInstallmentPrices[]
  Notes: IOrderNote[]
  SoftwareWarehauseOrderModels: ISoftwareWarehauseOrderModel[]
  MTID: string
}

export interface OrderThreedsRequestModel {
  PaymentResponse: string
  SecretKey: string
}
export interface ISavedConditionList {
  Company: string
  Condition: string
  Value: string
  Currency: string
  AvaibleCurrency?: ISelectOption[]
  CurrencySelect: ISelectOption | null
}

export interface PaymentRequestMySpecial {
  HolderName: string
  CardNumber: string
  ExpireDate: string
  Cvc: string
  Installment: number
  Amount: number
  ComissionAmount: number
  Company: number
}

export interface ComplatePaymentResponse extends IOrder {}
export interface SaveOrderResponse extends IOrder {}

export enum PriceType {
  Special = 0,
  Normal = 1,
  EndUser = 2,
  Campaign = 3,
}

export enum CurrencyType {
  TL = 0,
  Dolar = 1,
  Euro = 2,
}

export interface IOfferProductRequestModel {
  ProductID: number
  Count: number
}

export interface ICreateOfferRequest {
  Id: number
  Products: IOfferProductRequestModel[]
  PriceType: PriceType
  CurrencyType: CurrencyType
  Percent: number
}

export interface IOfferProductModel {
  ProductModel: IProduct
  Count: number
}

export interface ICreateOfferResponseModel {
  Products: IOfferProductModel[]
  Total: number
  TotalDisplay: string
  Kdv: number
  KdvDisplay: string
  SubTotal: number
  SubTotalDisplay: string
}

export interface IUser {
  Id: number
  Enable: boolean
  Name: string
  Surname: string
  UserCode: string
  UserName: string
  PasswordHash: string
  Email: string
  Gsm: string
  CreatedDate: Date
  Deleted: boolean
}

export interface IAddress {
  InvoiceAddressList: IAddressModel[]
  ShippAddresList: IAddressModel[]
  LicenseAddressList: IAddressModel[]
}

export interface IAddressModel {
  isLicense: number
  Id: number
  NameAndSurname: string
  Country: string
  City: string
  District: string
  PostCode: string
  SevkCode: string
  Phone: string
  Email: string
  AddressDetail: string
  AddressTitle: string
  Preferred: string
  Issap: boolean
  Unvan: string
  Neighbourhood: string
}

export interface AddAddressRequest {
  isLicense: number
  Id: number
  NameAndSurname: string
  Country: string
  City: string
  District: string
  PostCode: string
  SevkCode: string
  Phone: string
  Email: string
  AddressDetail: string
  AddressDetail2: string
  AddressTitle: string
  Unvan: string
  Neighbourhood: string
}

export interface IInstallmentPrices {
  InstallmentNumber: number
  Price: number
  PriceValue: string
  TotalPrice: number
  TotalPriceValue: string
  Comission: number
  ComissionValue: string
  InstallmentDetail: KampanyaVade[]
  TaxRate: number
  CargoPrice: number
  CargoPriceValue: string
}

export interface KampanyaVade {
  Installment: number
  Price: number
  Rate: number
  RateUI: string
  ProdId: number
  CompanyId: number
  IyzicoCampaignId: number
  IyzicoCampaignName: string
  MaterialNumber: string
}

export interface ICampaignList {
  CampaignDescription: string
  Id: number
  IsActive: boolean
}

export interface IBinResponse {
  BankCode: number
  BankName: string
  BinNumber: string
  CardAssociation: string
  CardFamilyName: string
  CardType: string
  Commercial: number
  Force3Ds: number
  ForceCvc?: number
  InstallmentPrices: IInstallmentPrices
  Message: string
  Success: boolean
}

export interface IAddNewBasketRequest {
  UserID: number
  BasketName: string
}

export interface IAddNewBasketResponse {
  Baskets: IBaskets[]
}
export interface IBaskets {
  BasketName: string
  Id: number
}

export interface IDeleteBasketResponse {}

export interface IGetBasketListResponse {
  Baskets: ISimpleBasket[]
}

export interface ISwitchBasketRequest {
  BasketId: number
}

export interface ITown {
  Id: number
  IlKodu: string
  IlceKodu: string
  IlceAdi: string
}

export interface IDistrict {
  Id: number
  IlceKodu: string
  SemtAdi: string
  MahalleAdi: string
  SevkKodu: string
  PostaKodu: string
}

export interface ISwitchBasketResponse {
  Baskets: ISimpleBasket[]
}

export interface IAddToBasketRequest {
  ProductId: number
  Quantity: number
}

export interface IAddToBasketResponse {}

export interface IGetBasketManagementByBasketIdResponse {
  ActiveBasket: ISimpleBasket
  ActiveBasketUI: ISimpleBasket
  BasketListsForUser: ISimpleBasket[]
}

export interface ISetBasketManagementByIdsRequest {
  BeasketDetailIDList: number[]
  CurrentBasketID: number
  SelectedBasketID: number
  OperationType: number
}

export interface ISetBasketManagementByIdsResponse {
  ActiveBasket: ISimpleBasket
  BasketListsForUser: ISimpleBasket[]
}

export interface ICreateOfferToSubDealerRequest {
  Id: number
  PercentageRate: number
  PriceType: number
  CurrencyType: number
  BasketList: number[]
}

export interface IOfferSendMailRequest {
  Mail: string
  Data: ICreateOfferToSubDealerRequest
}

export interface ICreateOfferPrice {
  Total: number
  TotalDisplay: string
  Kdv: number
  KdvDisplay: string
  SubTotal: number
  SubTotalDisplay: string
}

export interface ICreateOfferProduct {
  Product: IProduct
  ProductId: number
  Quantity: number
  TotalPrice: string
  UnitPrice: string
  TotalPriceValue: number
  UnitPriceValue: number
  type: number
}

export interface ICreateOfferToSubDealerResponse {
  Id: number
  PriceType: number
  CurrencyType: number
  PercentageRate: number
  Products: ICreateOfferProduct[]
  Price: ICreateOfferPrice
  Date: Date
}

export interface CommonPostResponse {
  IsSuccess: boolean
}

export interface IShippingDate {
  AddressId: number
  ShippingDate: string
}

export interface IShipping {
  ShippingDate: Date
  ShippingTypes: IShippingTypes[]
}

export interface IShippingTypes {
  ShippingName: string
  ShippingType: string
}

export interface IPaymentMetod {
  Company: string
  ConditionList: IConditionList[]
  Currency: []
}

export interface IConditionList {
  Condition: string
  Value: string
}

export interface IDealerSavedCondition {
  Company: string
  Logo: string
  ConditionList: IConditionList[]
  Currency: []
}

export interface IOrderDetail {
  DealerName: string
  Address: string
  City: string
  District: string
  OrderNumber: string
  Currency: number
  CreatedDate: Date
  ShipmentDate: Date
  ShipmentType: string
  OrderReturnCompanyList: IOrderReturnCompanyList[]
  Total: number
  ErrorMessages: []
  IsStockError: IIsStockError[]
  IyzicoOrderNumber?: string
}

export interface IIsStockError {
  StockAvaible: boolean
  OutOfStock?: string
}

export interface IOrderReturnCompanyList {
  Cargo: string
  CargoValue: number
  Company: string
  OrderNumber: string
  PaymentMethod: string
  CurrencyType: string
  CurrencyValue: string
  MT: string
  Items: IItems[]
  TotalPrice: string
  TotalKdv: string
  Total: string
}

export interface IItems {
  Warehouse: string
  ProductCode: string
  ProductName: string
  Quantity: number
  Currency: number
  Price1: string
  Price1Value: number
  Price2: string
  Price2Value: number
  TotalPrice1: string
  TotalPrice1Value: number
  TotalPrice2: string
  TotalPrice2Value: number
  IyzicoNumber?: string
}

export interface ICompany {
  Id: number
  Name: string
  Image: string
  ImageUrl: string
  IyzipayMerchantKey: string
}

export interface IData {
  Id: number
  Image: string
}

export interface ICargoResponse {
  Basket: ISimpleBasket
  CargoItem: ICargoItem[]
}
export interface ICargoItem {
  CargoValue: number
  Company: string
  CompanyId: string
  DisplayValue: string
}
export interface IBasicProductModel {
  Id: number
  Name: string
  Code: string
}

export interface IDealerSavedConditionRequest {
  ShippingType: string
  ProductCodes: string[]
}
export interface ISoftwareWarehauseOrderModel {
  ProductId: number
  Company: number
  NameAndSurname: string
  Country: string
  City: string
  District: string
  PostCode: string
  SevkCode: string
  Phone: string
  Email: string
  AddressDetail: string
  AddressDetail2: string
  AddressTitle: string
  Preferred: string
  Unvan: string
  Neighbourhood: string
  isLicense: number
}

export interface IWarehouses {
  Id: number
  WarehouseId: number
  Warehouse: string
  WarehouseCode: string
  Name: string
}
