import React, { useMemo, useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import Table from '../../../../../components/Table'
import { IPostUsageReportListRequest, IPostUsageReportResponseModel } from '../models/model'
import { useCustomReducer } from '../../../../../base/customHooks'
import Swal from 'sweetalert2'
import { PostUsageReportListApi } from '../services/postCampaignQueryListApi'
import IPInput from '../../../../../components/IPInput'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import InputCampaign from '../../../../../components/InputCampaign'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'

interface CampaignQueryListState {
  UsageReportList: IPostUsageReportResponseModel[]
  FcvName: string
  Company: ISelectOption | null
  companyListOptions: ISelectOption[]
  companyListOption: any
}

function UsageReport(props: BaseComponentWithContextProps) {

  const getInitialState = (): CampaignQueryListState => {
    return {
      UsageReportList: [],
      FcvName: "",
      Company: null,
      companyListOption: null,
      companyListOptions: [
        { label: 'Index', value: 1000 },
        { label: 'Netex', value: 2300 },
        { label: 'Despec', value: 1200 },
        { label: 'Datagate', value: 1100 },
      ],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<CampaignQueryListState>(intialState)
  const [filteredList, setFilteredList] = useState<IPostUsageReportResponseModel[]>([])
  const [showFilterRow, setShowFilterRow] = useState(false);
  const [campaignSelectOptions, setCampaignSelectOptions] = useState<ISelectOption[]>([])
  const [campaignSelectOption, setCampaignSelectOption] = useState<ISelectOption[]>([])
  const [bankSelectOptions, setBankSelectOptions] = useState<ISelectOption[]>([])
  const [bankSelectOption, setBankSelectOption] = useState<any>()
  const [trackingNo, setTrackingNo] = useState('')
  const t = translate(Translations)

  const postUsageReport = async () => {

    let newStartDate = new Date(startDate);
    let syear = newStartDate.getFullYear()
    let smonth = newStartDate.getMonth() + 1
    const startMonth = smonth < 10 ? `0${smonth}` : smonth
    let sday = newStartDate.getDate()
    const startDay = sday < 10 ? `0${sday}` : sday
    const sDate = `${syear}-${startMonth}-${startDay}`
    let newEndDate = new Date(endDate);
    let eyear = newEndDate.getFullYear()
    let emonth = newEndDate.getMonth() + 1
    const endMonth = emonth < 10 ? `0${emonth}` : emonth
    let eday = newEndDate.getDate()
    const endDay = eday < 10 ? `0${eday}` : eday
    const eDate = `${eyear}-${endMonth}-${endDay}`

    const req: IPostUsageReportListRequest = {
      Startdate: sDate,
      EndDate: eDate,
      FcvName: state.FcvName
    }
    const getByIdApi: PostUsageReportListApi = new PostUsageReportListApi({})
    const uniqueBankLabels = {};
    const uniqueTermsLabels = {};
    props.showLoading()
    await getByIdApi.PostUsageReportList(req)
      .then(async result => {
        if (result) {
          setCampaignSelectOptions(result.map(x => ({
            value: x.CampaignName,
            label: x.CampaignName.toUpperCase()
          })).filter(option => {
            if (!uniqueTermsLabels[option.label]) {
              uniqueTermsLabels[option.label] = true;
              return true;
            }
            return false;
          }))
          setBankSelectOptions(result.map(x => ({
            value: x.Banka,
            label: x.Banka.toUpperCase()
          })).filter(option => {
            if (!uniqueBankLabels[option.label]) {
              uniqueBankLabels[option.label] = true;
              return true;
            }
            return false;
          }))
          setState({ UsageReportList: result })
          setFilteredList(result)
          setShowFilterRow(true)
        } else
          setState({ UsageReportList: [] })
        props.hideLoading()
      })
      .catch((err: any) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        setState({ UsageReportList: [] })
      })


  }

  const excelButton = async () => {
    try {
      if (filteredList) {
        const data: any[] = []
        if (filteredList.length > 0) {
          filteredList.map(p => {
            return data.push({
              Id: p.Id,
              'SAP Bayi Kodu': p.DealerSapCode,
              Şirket: p.Company && p.Company === '1000' ? 'Index' : p.Company === '1100' ? 'Datagate' : p.Company === '1200' ? 'Despec' : p.Company === '2300' ? 'Netex' : '',
              'Kampanya Adı': p.CampaignName,
              'Bayi Adı': p.DealerName,
              'Fatura No': p.İnvoiceNo,
              'Başlangıç Tarihi': p.CreatedDate,
              'Makbuz No': p.MakbuzNo,
              Tutar: p.Tutar,
              Banka: p.Banka,
              Taksit: p.Taksit,
              'Takip No': p.TrackingNo,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'FÇV2 Kullanım Raporu')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  // Detaylı Süzme fonksiyonu
  const filterList = () => {
    const filterConditions: ((item: IPostUsageReportResponseModel) => boolean)[] = [];

    if (campaignSelectOption.length > 0) {
      filterConditions.push(b => {
        const selectedLabels = campaignSelectOption.map(option => option.label.toUpperCase());
        return selectedLabels.includes(b.CampaignName.toUpperCase());
      });
    }

    if (bankSelectOption) {
      filterConditions.push(b => b.Banka.toUpperCase() === bankSelectOption.label);
    }

    if (trackingNo) {
      filterConditions.push(b => b.TrackingNo.includes(trackingNo));
    }

    if (state.companyListOption) {
      filterConditions.push(c => c.Company === (state.companyListOption.value))
    }

    let filteredData: IPostUsageReportResponseModel[] = state.UsageReportList

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item));
      });
    }
    setFilteredList(filteredData);
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'col1',
      },
      {
        Header: 'SAP Bayi Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Şirket',
        accessor: 'col3',
      },
      {
        Header: 'Kampanya Adı',
        accessor: 'col4',
      },
      {
        Header: 'Bayi Adı',
        accessor: 'col5',
      },
      {
        Header: 'Fatura No',
        accessor: 'col6',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'col7',
      },
      {
        Header: 'Makbuz No',
        accessor: 'col8',
      },
      {
        Header: 'Tutar',
        accessor: 'col9',
      },
      {
        Header: 'Banka',
        accessor: 'col10',
      },
      {
        Header: 'Taksit',
        accessor: 'col11',
      },
      {
        Header: 'Takip No',
        accessor: 'col12',
      },
    ],
    []
  )
  const tableData = useMemo(
    () =>
      filteredList.map((item, index) => {
        const data = {
          col1: item.Id,
          col2: item.DealerSapCode.replace("0000", ""),
          col3: item.Company && item.Company === '1000' ? 'Index' : item.Company === '1100' ? 'Datagate' : item.Company === '1200' ? 'Despec' : item.Company === '2300' ? 'Netex' : '',
          col4: item.CampaignName,
          col5: item.DealerName,
          col6: item.İnvoiceNo,
          col7: item.CreatedDate.toLocaleDateString(),
          col8: item.MakbuzNo,
          col9: item.Tutar && parseFloat(item.Tutar.toString().replace(/[^\d.,]/g, '').replace(',', '.')).toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' }),
          col10: item.Banka,
          col11: item.Taksit,
          col12: item.TrackingNo
        }
        return data
      }),
    [filteredList]
  )

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>{t('pages.admin.dealerAndOrderReport.usageReport.usageReport.header')}</span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-12 col-md-3 mb-3'>
                  <IPInput type='text' value={state.FcvName} placeholder='Bayi Kodu' onChange={(e) => setState({ FcvName: e.target.value })} />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPButton
                    type='button'
                    className='btn btn-danger'
                    text={t('pages.admin.dealerAndOrderReport.usageReport.usageReport.show')}
                    onClick={postUsageReport}
                  />
                </div>
              </div>
              {showFilterRow && (
                <>
                  <div className='detailFilter' style={{
                    marginTop: '10px',
                    border: '2px solid #9c9cf1',
                    padding: '10px 5px 0px 5px'
                  }}>
                    <div className='row'>
                      <div className='form-group col-md-3'>
                        <IPSelectBox
                          isClearable
                          isMulti
                          isCreatableSelect
                          isSearchable
                          placeholder='Kampanya Seçiniz...'
                          options={campaignSelectOptions}
                          value={campaignSelectOption}
                          onChangeMulti={option => {
                            setCampaignSelectOption(option)
                          }} />
                      </div>
                      <div className='form-group col-md-3'>
                        <IPSelectBox
                          isClearable
                          placeholder='Banka Seçiniz...'
                          options={bankSelectOptions}
                          value={bankSelectOption}
                          onChangeSingle={option => {
                            setBankSelectOption(option)
                          }}
                        />
                      </div>
                      <div className='form-group col-md-3'>
                        <IPSelectBox
                          isClearable
                          placeholder='Şirket Seçiniz...'
                          options={state.companyListOptions}
                          value={state.companyListOption}
                          onChangeSingle={option => {
                            setState({ companyListOption: option })
                          }}
                        />
                      </div>
                      <div className='form-group col-md-3'>
                        <IPInput
                          type='text'
                          id='Title'
                          className='form-control'
                          name='Title'
                          placeholder='Takip No...'
                          onChange={e => setTrackingNo(e.target.value)}
                          value={trackingNo}
                          onKeyDown={e => {
                            if (e.key === 'Enter') {
                              e.preventDefault();
                              filterList();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className='row justify-content-center'>
                      <div className='form-group col-md-2'>
                        <IPButton
                          type='button'
                          className='btn btn-primary'
                          text='Detaylı Süz'
                          onClick={filterList}
                        />
                      </div>
                      <div className='form-group col-md-2'>
                        <IPButton
                          type='button'
                          className='btn btn-success'
                          text='Excele Aktar'
                          onClick={excelButton}
                        />
                      </div>
                    </div>
                  </div></>
              )}
            </form>
            <br />
            {state.UsageReportList && state.UsageReportList.length > 0 &&
              <div className='row'>
                <div className='col-lg-12'>
                  <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <Table columns={tableColumns} data={tableData} currentPage={0} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(UsageReport)
