import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link, NavigateFunction, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import data from '../../../../api/productDetail.json'
import { translate } from '../../../../base/localization'
import { splitStockForTruck, stockIndicator } from '../../../../base/utils/dataHelper'
import AddToBasket from '../../../../components/AddToBasket'
import IPButton from '../../../../components/IPButton'
import Image from '../../../../components/IPImage/IPImage'
import IPInput from '../../../../components/IPInput'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks'
import { addItemToBasketRedux } from '../../../../redux/slices/basket/basketSlice'
import {
  addToFavoriteProductListRedux,
  productIsOnFavoriteListRedux,
  removeFromFavoriteProductListRedux,
} from '../../../../redux/slices/favoriteProduct/favoriteProductSlice'
import { selectedTheme } from '../../../../redux/slices/theme/themeSlice'
import { currentUser } from '../../../../redux/slices/user/userSlice'
import Translations from '../../../../translations'
import { IProductColorResponse } from '../../../Admin/ProductOperation/ProductColor/models/models'
import { CurrencyType } from '../../../MyCard/models/models'
import Star from '../../../ProductList/components/Icons/Star'
import { css } from '@emotion/css'
import ProductIQuotaForm, {
  ProductIQuotaFormField,
  ProductIQuotaFormHandles,
} from '../../../ProductList/components/ProductIQuotaForm'
import { IProduct, IRecommendedProducts } from '../../../ProductList/models/response/getProductListResponseModel'
import {
  IBanner,
  IProductIyzicoResponseModel,
  IyzicoInstallmentRequestModel,
} from '../../models/getProductByIDResponseModel'
import { GetIyzicoInstallmentsListApi } from '../../services/getIyzicoInstallmentsListApi'
import { GetProductBannersApi } from '../../services/getProductBannersApi'
import classes from '../styles/style.module.scss'
import { Navigation, Mousewheel, Keyboard, Pagination, Autoplay } from 'swiper'
import '../../../Homepage/css/styles.scss'
import IPPriceDisplay from '../../../../components/IPPriceDisplay'
export interface IHeaderProps {
  product: IProduct
  recommendedProducts: IRecommendedProducts[]
  IsWin11AndOffice: boolean
  IsOffice365: boolean
  onProductChange: (propduct: IProduct) => void
  Suggest: any
  productColor: IProductColorResponse[]
  otherProducts: any
}

const HeaderLastRight = (props: IHeaderProps & { checkboxState: any }) => {
  const params = useParams()
  const { product, checkboxState } = props

  const showSpecialPrice = checkboxState.specialPrice
  const showBranchPrice = checkboxState.branchPrice
  const showListPrice = checkboxState.listPrice

  const theme = useAppSelector(selectedTheme)
  const user = useAppSelector(currentUser)
  const isProductOnFavorites = useAppSelector(productIsOnFavoriteListRedux(product.Id))
  const dispatch = useAppDispatch()

  const t = translate(Translations)

  const iQuotaFormRef = React.useRef<ProductIQuotaFormHandles>(null)

  const [accOpen, setAccOpen] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const [suggestQuantities, setSuggestQuantities] = useState({})
  const [banner, setBanner] = useState<IBanner[]>([])
  const [favoriteCount, setFavoriteCount] = useState(product.FavoriteCount)
  const [currencyOption, setCurrencyOption] = useState<CurrencyType>(0)

  const [bankInstallmentsData, setBankInstallmentsData] = useState<IProductIyzicoResponseModel[]>()
  const [installment, setInstallment] = useState<IProductIyzicoResponseModel>()
  const [showRates, setShowRates] = useState(false)

  let currency
  if (currencyOption === CurrencyType.TL) currency = 'TRY'
  if (currencyOption === CurrencyType.Dolar) currency = 'USD'
  if (currencyOption === CurrencyType.Euro) currency = 'EUR'

  // const priceNormal = product.Prices?.Normal?.[`${currency}Price`].PriceDisplay
  const priceNormal =
    params.type === 'product'
      ? product.Prices?.Normal?.[`${currency}Price`].PriceDisplay
      : currency === 'TRY'
      ? product.OpportunitPriceList?.TLDisplay
      : currency === 'USD'
      ? product.OpportunitPriceList?.DolarDisplay
      : product.OpportunitPriceList?.EuroDisplay
  const priceSpecial =
    params.type === 'product'
      ? product.Prices?.Special?.[`${currency}Price`].PriceDisplay
      : currency === 'TRY'
      ? product.OpportunitPriceList?.TLDisplay
      : currency === 'USD'
      ? product.OpportunitPriceList?.DolarDisplay
      : product.OpportunitPriceList?.EuroDisplay
  const priceEndUser =
    params.type === 'product'
      ? product.Prices?.EndUser?.[`${currency}Price`].PriceDisplay
      : currency === 'TRY'
      ? product.OpportunitPriceList?.TLDisplay
      : currency === 'USD'
      ? product.OpportunitPriceList?.DolarDisplay
      : product.OpportunitPriceList?.EuroDisplay
  const priceSpecialTax =
    params.type === 'product'
      ? product.Prices?.Tax
      : currency === 'TRY'
      ? product.OpportunitPriceList?.Tax
      : currency === 'USD'
      ? product.OpportunitPriceList?.Tax
      : product.OpportunitPriceList?.Tax
  const priceSpecialKDV =
    params.type === 'product'
      ? product.Prices?.Special?.[`${currency}Price`].KdvDisplay
      : currency === 'TRY'
      ? product.OpportunitPriceList?.TLKdvDisplay
      : currency === 'USD'
      ? product.OpportunitPriceList?.DolarKdvDisplay
      : product.OpportunitPriceList?.EuroKdvDisplay
  // const priceSpecialKDV = product.Prices?.Special?.[`${currency}Price`].KdvDisplay // Fallback: product.Prices?.Special?.Kdv

  useEffect(() => {
    if (params.type === 'product') getBanner()
    setCurrencyOption(product.Prices.Special.Currency)
  }, [])

  const getBanner = async () => {
    const getDealerApi: GetProductBannersApi = new GetProductBannersApi({})
    const res = await getDealerApi.getProductBanners(product.Id)
    setBanner(res)
  }

  const handleQuantity = e => {
    setQuantity(e.target.value)
  }

  const handleQuantityChange = (productId, quantity) => {
    setSuggestQuantities(prevState => ({
      ...prevState,
      [productId]: quantity,
    }))
  }

  const handleQuantityOnBlur = (e: any) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value))) {
      setQuantity(1)
    } else {
      setQuantity(e.target.value)
    }
  }

  const animateIcon = e => {
    e.target.closest('svg').classList.add('clickAnimation')
    setTimeout(() => {
      e.target.closest('svg').classList.remove('clickAnimation')
    }, 300)
  }

  const handleStar = async (e: any) => {
    if (isProductOnFavorites) {
      await removeFavorite(product.Id)
      setFavoriteCount(favoriteCount - 1)
    } else {
      await addFavorite(product.Id)
      setFavoriteCount(favoriteCount + 1)
    }
    const updatedProduct = _.clone(product)
    updatedProduct.IsItFavorite = !isProductOnFavorites

    props.onProductChange && props.onProductChange(updatedProduct)

    animateIcon(e)
  }

  const toggleAcc = () => {
    setAccOpen(!accOpen)
  }

  const addFavorite = async (productId: number) => {
    await dispatch(addToFavoriteProductListRedux(productId))
  }

  const removeFavorite = async (productId: number) => {
    await dispatch(removeFromFavoriteProductListRedux(productId))
  }

  const forwardIQuotePage = (product: IProduct) => {
    /**
      Mandatory Field uEmail was not included in the request body
      Mandatory Field uName was not included in the request body
      Mandatory Field cAccountNum was not included in the request body
      Mandatory Field cName was not included in the request body
      Mandatory Field cPCode was not included in the request body
      */

    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.uEmail, 'vvvv@bbbb.com')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.uName, 'bvcb')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.cAccountNum, '054455411100000')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.cName, 'com')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.cPCode, '34406')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.base, product.ProductCode)
    iQuotaFormRef.current?.setFormInputValueByName(
      ProductIQuotaFormField.ref,
      `${window.location.origin}/api/iquote-service-response/${user.SessionId}`
    )
    iQuotaFormRef.current?.setFormInputValueByName(
      ProductIQuotaFormField.BasketURL,
      `${window.location.origin}/api/iquote-service-response/${user.SessionId}`
    )

    iQuotaFormRef.current?.submitForm()
  }

  const navigate: NavigateFunction = useNavigate()
  const addToBasketOrCreateDemand = () => {
    dispatch(
      addItemToBasketRedux({
        ProductId: product.Id,
        Quantity: 1,
        IsOppurtunityStock: props.product.OpportunityId ? true : false,
        OpportunitiyStockId: props.product.OpportunityId ?? 0,
      })
    )

    Swal.fire({
      html: `<h5>${t('components.AddToBasket.productWasAddedToBasket')}</h5>`,
      confirmButtonText: `${t('components.AddToBasket.goToBasket')}`,
      confirmButtonColor: '#ec4c4c',
      cancelButtonText: `${t('components.AddToBasket.continueToShopping')}`,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigate('/mycard')
      } else if (result.isDenied) {
      }
    })
  }

  // function navigateTab(tab: string) {
  //   $(tab).click()
  //   const top = document.querySelector(tab)?.getBoundingClientRect().top
  //   window.scrollTo({ top })
  // }

  const req: IyzicoInstallmentRequestModel = {
    ProductModel: product,
    Warehouse:
      params.type === 'product' ? product.WarehouseModels[0].WarehouseName : product.OpportunityWarehouse.toString(),
  }

  const getIyzicoInstallments = async () => {
    try {
      const iyzicoInstallments: GetIyzicoInstallmentsListApi = new GetIyzicoInstallmentsListApi({})
      const res: IProductIyzicoResponseModel[] = await iyzicoInstallments.getIyzicoInstallments(req)

      if (res && res.length > 0) {
        setBankInstallmentsData(res)
      }
      // Additional logic if needed
    } catch (error) {
      // Handle errors appropriately
      console.error('Error fetching Iyzico installments:', error)
    }
  }

  useEffect(() => {
    getIyzicoInstallments()
  }, [])

  // const colorLink = () => {
  //   return productColor.map((item, index) => {
  //     const col3Content = item.ProductIds.map((productId, index) => (
  //       <React.Fragment key={index}>
  //         <a href={`/product/detail/${productId}?from=productList`} rel='noopener noreferrer'>
  //           <ColorBlock
  //             colorCode={item.ColorCodes[index]}
  //             productCode={item.ProductCodes[index]}
  //             colorName={item.ColorNames[index]}
  //           />
  //         </a>
  //         {index < item.ProductIds.length - 1 && ' '}
  //       </React.Fragment>
  //     ))

  //     return (
  //       <div key={index} style={{ display: 'flex', alignItems: 'center', margin: '10px', flexWrap: 'wrap' }}>
  //         {col3Content}
  //       </div>
  //     )
  //   })
  // }

  // const ColorBlock = ({ colorCode, productCode, colorName }) => (
  //   <>
  //     <div
  //       style={{
  //         height: '10px',
  //         width: '80px',
  //         display: 'flex',
  //         flexDirection: 'column',
  //         justifyContent: 'center',
  //       }}
  //     >
  //       <span style={{ color: 'black' }}>{colorName}</span>
  //     </div>
  //     <div
  //       style={{
  //         height: '50px',
  //         transition: 'all 0.3s',
  //         display: 'inline-block',
  //       }}
  //     >
  //       <div
  //         style={{
  //           padding: '0px',
  //           height: '30px',
  //           width: '30px',
  //           float: 'left',
  //           textAlign: 'center',
  //         }}
  //       >
  //         <div
  //           style={{
  //             backgroundColor: colorCode,
  //             padding: '5px',
  //             borderRadius: '50%',
  //             height: '30px',
  //             width: '30px',
  //             margin: '10px auto 0',
  //             borderColor: 'black',
  //             borderStyle: 'solid',
  //           }}
  //         />
  //       </div>
  //     </div>
  //   </>
  // )

  const toggleRates = () => {
    //getIyzicoInstallments()
    setShowRates(!showRates)
  }

  useEffect(() => {
    if (
      (props.Suggest && props.Suggest.length > 0) ||
      (product.ProductCampaigns && product.ProductCampaigns.length > 0)
    )
      setShowRates(false)
    else setShowRates(true)
  }, [props.Suggest])

  return (
    <>
      <div className='mt-5 mx-4'>
        <div className={classes.mobilDesc}>
          <div style={{ display: 'relative' }} className={`d-flex flex-column ${classes.headerRightIcons}`}>
            {product.SapCampaignNo && product.SapCampaignNo.length > 0 && (
              <div
                className={`${classes.indicator} ${classes.topRight}`}
                style={{ width: '5%', height: '3%', fontSize: 'x-large' }}
              >
                %
              </div>
            )}
            {/* <div className={`${classes.indicator} ${classes.topRight2}`}>New</div> */}
          </div>
          <div className='row'>
            <div className={classes.descTitle}>
              {product.IsProductOfOpportunity ? (
                <p
                  className='p-r-20'
                  style={{
                    color: '#000',
                    fontSize: 35,
                    fontWeight: 500,
                    marginBottom: '0',
                  }}
                >{`Fırsat Ürünü`}</p>
              ) : (
                <div
                  className='productName'
                  style={{
                    fontSize: '16px',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    marginTop: '-20px',
                  }}
                >
                  {product.Name}
                </div>
              )}
              <div className={classes.actions} style={{ zIndex: '2', left: '78%', width: 'fit-content' }}>
                {/* <div className={classes.action}>
      <Heart
        color="#C3C3C4"
        hover="#E80000"
        isFavorite={isFavorite}
        click={handleFavorite}
      />
    </div> */}
                {params.type === 'product' && (
                  <div style={{ padding: '1rem' }} title='Favorilerime ekle'>
                    <Star
                      className={classes.action}
                      color='#C3C3C4'
                      hover='red'
                      isStarred={isProductOnFavorites}
                      isFavoriteProduct={isProductOnFavorites}
                      click={handleStar}
                    />
                    {/* <div className={classes.action}>
            <img
              src="/images/share-img.svg"
              style={{
                maxWidth: "15px",
                maxHeight: "17px",
                height: "17px",
              }}
              alt=""
            />
          </div>
          <div className={classes.action}>
            <img
              src="/images/compare.png"
              alt=""
              style={{
                maxWidth: "17px",
                maxHeight: "18px",
                height: "18px",
              }}
            />
          </div> */}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='row' style={{ marginTop: '15px' }}>
            <p
              style={{
                color: '#616161',
                fontSize: '14px',
              }}
            >
              {t('pages.productdetail.ProductCode')} : {product.ProductCode}{' '}
            </p>
          </div>
          {(product.Brand as any) === 'HPE' && (
            <img
              style={{ cursor: 'pointer' }}
              src='/images/hpe-config.jpeg'
              alt=''
              onClick={() => forwardIQuotePage(product)}
            />
          )}
          {params.type === 'product' && (
            <div className='row'>
              <div className='d-flex align-items-center' style={{ marginBottom: '15px' }}>
                <div className='mb-4 mx-2'>
                  <Image src='/images/like.svg' alt='' className='' />
                </div>
                <p style={{ fontSize: '14px', color: '#616161', marginBottom: '7px', marginRight: '50px' }}>
                  {favoriteCount} <span>Kullanıcı bu ürünü favorilerine ekledi.</span>
                  {/* {t("pages.productdetail.BuyersOfThisProduct")}{" "}
          <span style={{ fontSize: 18, fontWeight: 500 }}> 88% </span>
          {t("pages.productdetail.Recommends")} */}
                </p>

                <div className='mb-4 mx-2'>
                  <Image style={{ width: '21px' }} src='/images/icons/son_incelenen.png' alt='' className='' />
                </div>
                <p style={{ fontSize: '14px' }}>
                  {product.ViewCount} {t('pages.productdetail.Review')}
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Yeni Renk halkaları */}
        {props.productColor &&
          props.productColor[0]?.ProductCodes?.length === props.productColor[0]?.ProductIds?.length && (
            <>
              {/* Top Row - Color Names */}
              {props.productColor.map((item, outerIndex) => {
                const col3Content = item.ProductIds.map((productId, innerIndex) => (
                  <>
                    {product.Id === item.ProductIds[innerIndex] && item.Type === 1 && (
                      <>
                        <div className='col' style={{ height: '40px', alignSelf: 'start' }}>
                          <span style={{ color: 'black', fontSize: '14px' }}>Renk : {item.ColorNames[innerIndex]}</span>
                        </div>
                      </>
                    )}
                    {innerIndex < item.ProductIds.length - 1 && ' '}
                  </>
                ))
                return (
                  <div className='d-flex' key={outerIndex}>
                    {col3Content}
                  </div>
                )
              })}

              {/* Bottom Row - Color Codes */}
              {props.productColor.map((item, outerIndex) => {
                const col3Content = item.ProductIds.map(
                  (productId, innerIndex) =>
                    item.Type == 1 && (
                      <div className='p-2' key={innerIndex}>
                        <a
                          href={`/product/detail/${productId}?from=productList`}
                          rel='noopener noreferrer'
                          title={product.Id !== item.ProductIds[innerIndex] ? item.ColorNames[innerIndex] : ''}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'start',
                            }}
                          >
                            <div
                              style={{
                                padding: '0px',
                                height: '25px',
                                width: '25px',
                                textAlign: 'center',
                                marginLeft: innerIndex > 0 ? '10px' : '0px',
                                ...(product.Id === item.ProductIds[innerIndex] && {
                                  boxShadow: '#000 1px 2px 0 5px',
                                  borderRadius: '50%',
                                }),
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: item.ColorCodes[innerIndex],
                                  borderRadius: '50%',
                                  height: '25px',
                                  width: '25px',
                                  ...(item.ColorCodes[innerIndex] !== 'white' && { border: '1px solid black' }),
                                  ...(product.Id === item.ProductIds[innerIndex] && {
                                    boxShadow: '0 0 0 5px white',
                                  }),
                                }}
                              />
                            </div>
                          </div>
                        </a>
                        {innerIndex < item.ProductIds.length - 1 && ' '}
                      </div>
                    )
                )
                return (
                  <div className='d-flex ms-2' key={outerIndex}>
                    {col3Content}
                  </div>
                )
              })}
            </>
          )}
        {/* İkili Yönlendirme */}
        {props.productColor.map((item, outerIndex) => {
          const col3Content = item.ProductIds.map(
            (productId, innerIndex) =>
              item.Type === 2 &&
              item.ProductIds[innerIndex] === product.Id &&
              innerIndex === 0 && (
                <>
                  <div className='col mt-3 mb-3' style={{ height: '40px', alignSelf: 'start' }}>
                    <span style={{ color: 'black', fontSize: '14px', fontWeight: 'bold' }}>
                      <a
                        href={`/product/detail/${item.ProductIds[innerIndex + 1]}?from=productList`}
                        rel='noopener noreferrer'
                        style={{ textDecoration: 'underline' }}
                      >
                        {item.Info}
                      </a>
                    </span>
                  </div>
                </>
              )
          )
          return (
            <div className='d-flex' key={outerIndex}>
              {col3Content}
            </div>
          )
        })}

        {/* Renk kutuları Inbox */}
        {/* {productColor && productColor[0]?.ProductCodes?.length === productColor[0]?.ProductIds?.length && (
          <>
            {productColor.map((item, outerIndex) => {
              const col3Content = item.ProductIds.map((productId, innerIndex) => (
                <>
                  {product.Id === item.ProductIds[innerIndex] && (
                    <div className='col' style={{ height: '40px', alignSelf: 'start' }}>
                      <span style={{ color: 'black' }}>Renk : {item.ColorNames[innerIndex]}</span>
                    </div>
                  )}
                  <div className='p-2' key={innerIndex}>
                    <a href={`/product/detail/${productId}?from=productList`} rel='noopener noreferrer'>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'start',
                        }}
                      >
                        <div
                          style={{
                            padding: '0px',
                            height: '30px',
                            width: '30px',
                            textAlign: 'center',
                            marginLeft: innerIndex > 0 ? '10px' : '0px',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: item.ColorCodes[innerIndex],
                              padding: '5px',
                              borderRadius: '50%',
                              height: '30px',
                              width: '30px',
                              borderColor: 'black',
                              borderStyle: 'solid',
                            }}
                          />
                        </div>
                      </div>
                    </a>
                    {innerIndex < item.ProductIds.length - 1 && ' '}
                  </div>
                </>
              ))
              return (
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }} key={outerIndex}>
                  {col3Content}
                </div>
              )
            })}
          </>
        )} */}

        <div className={`row mt-4 ${classes.priceArea}`}>
          <div className={`col-12 col-md-2 ${classes.brandLogo} mb-3 mb-md-0 text-center text-md-start`}>
            <Image
              style={{
                backgroundColor: 'white',
                padding: '7px 25px',
                borderRadius: '10px',
                minWidth: '135px',
                width: '100%', // Ensure the image scales responsively
                maxWidth: '135px', // Limit max width on larger screens
              }}
              src={product.Company?.Id === 2 ? '/images/netex_logo.png' : product.Company?.Image}
              alt='Logo'
              className='seller'
            />
          </div>
          <div className='col-12 col-md-5 pt-3 pt-md-5' style={{ position: 'initial' }}>
            <select
              className={`${classes.indicator} ${classes.topLeftPrice} ${classes.customSelectBox} form-control text-center`}
              onChange={e => setCurrencyOption(Number(e.target.value))}
              value={currencyOption}
            >
              {data &&
                data.currency &&
                data.currency.length > 0 &&
                data.currency.map(dt => (
                  <option value={dt.id} key={dt.id}>
                    {dt.symbol}
                  </option>
                ))}
            </select>

            <div style={{ height: '1rem' }}></div>
            {/* Size Özel Fiyatına Göre */}
            {showSpecialPrice && (
              <div className={classes.autoWidth}>
                <span className='priceTitle'>Size Özel</span>
                {product.SapCampaignNo && product.SapCampaignNo.length > 0 ? (
                  <div>
                    <span
                      className='price'
                      style={{
                        color: theme.colors.price.special,
                      }}
                    >
                      <IPPriceDisplay useDiv={false} price={priceSpecial ?? ''} integerFontSize='19px' />
                    </span>

                    <del
                      className='price'
                      style={{
                        color: theme.colors.price.normal,
                        position: 'absolute',
                        marginLeft: '10px',
                      }}
                    >
                      {priceNormal ?? ''}
                      {/* <IPPriceDisplay price={priceNormal ?? ''} integerFontSize='16px' /> */}
                    </del>
                  </div>
                ) : (
                  <span className='price' style={{ color: theme.colors.price.special }}>
                    <IPPriceDisplay price={priceSpecial ?? ''} integerFontSize='17px' />
                  </span>
                )}
              </div>
            )}
            {/* Liste Fiyatına Göre */}
            {showBranchPrice && (product.OpportunityId == null || product.OpportunityId === 0) && (
              <div className={classes.autoWidth}>
                <span className='priceTitle--gray' style={{ color: theme.colors.price.normal }}>
                  {params.type === 'product' ? 'Liste ' : 'Bayi '} Fiyatı
                </span>
                <span
                  className={`price ${true ? 'price-none-decoration' : ''}`}
                  style={{ color: theme.colors.price.normal }}
                >
                  <IPPriceDisplay price={priceNormal ?? ''} integerFontSize='17px' />
                </span>
              </div>
            )}
            {/* TESK Fiyatına Göre */}
            {showListPrice && (product.OpportunityId == null || product.OpportunityId === 0) && (
              <div className={classes.autoWidth}>
                <span className='priceTitle--gray' style={{ color: theme.colors.price.enduser }}>
                  TESK
                </span>
                <span className='price' style={{ color: theme.colors.price.enduser }}>
                  <IPPriceDisplay price={priceEndUser ?? ''} integerFontSize='16px' />
                </span>
              </div>
            )}
            <p className={classes.autoWidth} style={{ fontSize: 12, fontWeight: 500, color: '#ABABAF' }}>
              <div className='priceTitle--gray'>KDV {priceSpecialTax}%</div>
              {((showSpecialPrice && !showBranchPrice && !showListPrice) ||
                (product.OpportunityId != null && product.OpportunityId > 0)) && (
                <div className='price'>
                  <IPPriceDisplay price={priceSpecialKDV ?? ''} integerFontSize='17px' />
                </div>
              )}
            </p>
          </div>
          <div className='col-12 col-md-5 pt-3 pt-md-5'>
            <div></div>
            {/* <div className='row' style={{ minHeight: '35px' }}>
      <div className="col-md-auto">
        <span
          style={{
            fontSize: '1.8rem',
            color: "#EC1F28",
            fontWeight: 500,
          }}
        >
          {product.Prices?.Special?.Price}
        </span>
      </div>
    </div> */}

            {/* {product.Stock?.OpportunityFlag > 0 ||
      (product.Stock?.Opportunity > 0 && (
        <div style={{ height: 35 }} className="d-flex align-items-center">
          <div className="w-10">
            <Image
              alt=""
              src="/images/product-detail/productBox2.png"
              className="w-10"
              style={{ marginLeft: "-5px" }}
            />
          </div>
          {product.Stock?.OpportunityFlag > 0 ? (
            <div className="hasTooltip">
              <Image
                src="/images/phone-solid.svg"
                alt=""
                style={{ width: "15px" }}
              />
              <div className="otooltip">Fırsat Stoğu Rezerve Et</div>
            </div>
          ) : (
            product.Stock?.Opportunity > 0 && (
              <a onClick={() => addToBasketOrCreateDemand()}>
                <p className="mx-3 my-1" style={{ color: "#EC1F28" }}>
                  FIRSAT PAZARI’nda <br /> Stok:{" "}
                  {product.Stock.Opportunity}{" "}
                </p>
              </a>
            )
          )}
        </div>
      ))} */}

            <div className='row align-items-center' style={{ minHeight: '35px', display: 'block' }}>
              <ul style={{ paddingLeft: '5rem' }}>
                {product.ProductCampaigns &&
                  product.ProductCampaigns.length > 0 &&
                  product.ProductCampaigns.map(p => (
                    <li style={{ color: 'red', fontWeight: '600' }}>{p.ProductDetailDesc}</li>
                  ))}
              </ul>
              <div className='col-md-auto'>
                {(params.type === 'product' && product.Stock?.OpportunityFlag > 0) ||
                  (params.type === 'product' && product.Stock?.Opportunity > 0 && (
                    <div style={{ height: 35 }} className='d-flex align-items-center'>
                      <div className='w-10'>
                        <Image
                          alt=''
                          src='/images/product-detail/productBox2.png'
                          className='w-10'
                          style={{
                            marginLeft: '-5px',
                            backgroundColor: '#fac74b',
                            borderBottomLeftRadius: '13px',
                            marginTop: '-5px',
                          }}
                        />
                      </div>
                      {params.type === 'product' && product.Stock?.OpportunityFlag > 0 ? (
                        <div className='hasTooltip'>
                          <Image src='/images/phone-solid.svg' alt='' style={{ width: '15px' }} />
                          <div className='otooltip'>Fırsat Stoğu Rezerve</div>
                        </div>
                      ) : (
                        params.type === 'product' &&
                        product.OpportunityStock > 0 && (
                          // <a onClick={() => addToBasketOrCreateDemand()}>
                          <a href={`/opportunit/detail/${product.OpportunityId}`}>
                            <p className='mx-3 my-1' style={{ color: '#EC1F28' }}>
                              {props.product.OpportunityPriceDisplay} fiyatla FIRSAT PAZARI'ndan almak için
                              tıklayınız...
                              {/* FIRSAT PAZARI’nda <br /> Stok: {product.Stock && product.Stock?.Opportunity ? stockIndicator(product.Stock?.Opportunity) : "0"}{' '} */}
                            </p>
                          </a>
                        )
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className='row' style={{ minHeight: '35px' }}>
              {product.StockForTruck && splitStockForTruck(product.StockForTruck).length > 0 && (
                <div className='col-md-auto'>
                  <div className='hasTooltip'>
                    <img src='/images/products/truck.svg' alt='' />
                    <div className='otooltip'>
                      {splitStockForTruck(product.StockForTruck).map(st => {
                        return 'tarih : ' + st.date + ' stok : ' + st.stok
                      })}
                    </div>
                  </div>
                </div>
              )}

              <div className='col-md-auto'>
                {/* Hasar Durumu bilgisi */}
                <div className='col' style={{ color: 'red' }}>
                  {params.type !== 'product' ? product.OpportunityShortDescription : ''}
                </div>
                {/* <div className='col'>
                  <span className='priceTitle--gray' style={{ color: theme.colors.price.normal }}>
                    {params.type === 'product' ? 'Stok:' : 'Fırsat Pazarı Stoğu:'}
                  </span>
                  <span
                    className={`price ${true ? 'price-none-decoration' : ''}`}
                    style={{ color: theme.colors.price.normal, marginLeft: '10px', float: 'inline-end' }}
                  >
                    {params.type === 'product'
                      ? product.Stock && product.Stock.Stock
                        ? stockIndicator(product.Stock.Stock)
                        : '0'
                      : product.OpportunityStock && product.OpportunityStock > 0
                      ? stockIndicator(product.OpportunityStock)
                      : '0'}
                    {/* {product.Stock && product.Stock.Stock ? stockIndicator(product.Stock.Stock) : '0'} 
                  </span>
                </div> */}
              </div>
            </div>
          </div>
          <div style={{ height: '2rem', paddingLeft: '15px', marginBottom: '10px' }}>
            <div className='col' style={{ display: 'table-row' }}>
              <span className='priceTitle--gray' style={{ color: theme.colors.price.normal }}>
                {params.type === 'product' ? 'Stok:' : 'Fırsat Pazarı Stoğu:'}
              </span>
              <span
                className={`price ${true ? 'price-none-decoration' : ''}`}
                style={{ color: theme.colors.price.normal, marginLeft: '10px', float: 'inline-end' }}
              >
                {params.type === 'product'
                  ? product.Stock && product.Stock.Stock
                    ? stockIndicator(product.Stock.Stock)
                    : '0'
                  : product.OpportunityStock && product.OpportunityStock > 0
                  ? stockIndicator(product.OpportunityStock)
                  : '0'}
                {/* {product.Stock && product.Stock.Stock ? stockIndicator(product.Stock.Stock) : '0'} */}
              </span>
            </div>
          </div>
          {/* <div className="col-12 p-l-25 p-r-25 col-lg-12 pb-5 d-flex m-auto flex-column">
      <div
        className="pricesBottomBox"
        style={{
          backgroundColor: "#F5F5F5",
          border: "0.5px solid #ABABAF",
        }}
      >
        <ul className="mb-0">
          <li>
            <a href="" className="leftText">
              Lorem ipsum dolor sit amet, consectetur!
            </a>
            <div className="row w-100">
              <div className="col-md-6">
                <p>Lorem ipsum dolor sit amet, consectetur Lorem </p>
              </div>
              <div className="col-md-6">
                <li>
                  <a href="" className="rightText">
                    {" "}
                    consectetur adipiscing elit ut aliquam!
                  </a>
                </li>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div> */}

          {/* <div className="mx-3">
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat,
        possimus. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        Iusto, laudantium!
      </p>
    </div> */}

          <div className=''>
            <IPInput
              type='number'
              className={classes.quantityToBasket}
              min={0}
              value={quantity}
              onChange={e => handleQuantity(e)}
              style={{ width: '15%' }}
            />
          </div>
          <div className={classes.priceLeftBtn}>
            {params.type === 'product' ? (
              <AddToBasket
                product={product}
                quantity={quantity}
                disabled={props.product.Brand === 'HPE' && `${props.product.Company.Code}` === '2300'}
              />
            ) : (
              <AddToBasket
                product={product}
                quantity={quantity}
                isOpportunityMarket={true}
                disabled={props.product.Brand === 'HPE' && `${props.product.Company.Code}` === '2300'}
              />
            )}
          </div>
        </div>

        <div>
          {/* BANK OPTIONS */}
          <div className='row' style={{ width: 'fit-content' }}>
            <div className='col'>
              <div
                className={`${classes.accordion} ${classes.dropdownBank}`}
                onClick={toggleRates}
                title='Iyzico Oranları'
              >
                <p className={`${classes.accTitle}`} style={{ fontSize: 15, fontWeight: 500 }}>
                  *{t('pages.productdetail.BankOptions') + ' '}
                  <i className='icon-arrow-down mt-3'></i>
                </p>
              </div>
            </div>
          </div>

          {showRates && (
            <>
              {/* <h5>Iyzico Sepet Oranları</h5> */}
              <div className='form-group form-fg'>
                <table className='table table-responsive'>
                  <thead className='table-light '>
                    <tr style={{ textAlign: 'center' }}>
                      <th style={{ width: '25%' }}></th>
                      <th style={{ textAlign: 'center', width: '40%' }}>Taksit Sayısı</th>
                      <th style={{ textAlign: 'center', width: '35%' }}>Oran</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bankInstallmentsData &&
                      bankInstallmentsData.map((item, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: 'center' }}>
                            <img
                              src='/images/iyzico_ile_ode_colored_horizontal.png'
                              style={{ width: '70%' }}
                              alt='Iyzico Logo'
                            />
                          </td>
                          <td style={{ textAlign: 'center' }}>{item.Installment && item.Installment}</td>
                          <td style={{ textAlign: 'center' }}>{item.RateUI && `${item.RateUI}%`}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Link to='../myspecial/creditCardRates' target='_blank' className='button-link'>
                <p className='h5' style={{ color: 'black' }}>
                  <strong>
                    <i className='icon-credit-card'></i> Kredi Kartı Komisyon Oranlarını Görmek İçin Tıklayınız
                  </strong>
                </p>
              </Link>
            </>
          )}
        </div>

        <div style={{ margin: '10px 2px' }}>
          {/* Windows Bundle Görselleri */}
          {props.Suggest && props.Suggest.length > 0 && (
            <>
              <div className='col d-flex align-items-center'>
                <h6 className='section-back-grey-title' style={{ fontSize: 'large', marginLeft: '-30px' }}>
                  {'Önerilen Ürünler'}
                </h6>
              </div>
              <div className='row m-t-10'>
                {props.Suggest.length > 2 ? (
                  <Swiper
                    navigation={true}
                    keyboard={true}
                    autoplay={{
                      delay: 8000,
                      disableOnInteraction: true,
                    }}
                    pagination={{ clickable: true }}
                    observer={true}
                    observeParents={true}
                    modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
                    className='mySwiper'
                  >
                    {[...Array(Math.ceil(props.Suggest.length / 2))].map((_, slideIndex) => (
                      <SwiperSlide key={slideIndex} style={{ backgroundColor: 'transparent' }}>
                        <div className='row'>
                          {props.Suggest.slice(slideIndex * 2, (slideIndex + 1) * 2).map((item: any) => (
                            <div
                              className='col-12 col-sm-6 col-md-4 col-lg-6 card-mobile-resize mb-5'
                              key={item.Id}
                              style={{ backgroundColor: '#f5f5f5' }}
                            >
                              <div
                                className={`d-flex flex-column justify-content-start most-popular-products ${css`
                                  padding: 20px;
                                `}`}
                              >
                                <div className='room'>
                                  <div className='room-image text-center'>
                                    <a href={`/product/detail/${item.Id}?from=promotional`}>
                                      {item.Image ? (
                                        <Image
                                          src={item.Image}
                                          alt=''
                                          className='popular_product'
                                          style={{ objectFit: 'contain' }}
                                        />
                                      ) : (
                                        <Image
                                          src='./images/products/Resim_yok-01.jpg'
                                          alt=''
                                          style={{ objectFit: 'contain' }}
                                        />
                                      )}
                                    </a>
                                  </div>
                                </div>
                                <div className='product-description'>
                                  <a href={`/product/detail/${item.Id}?from=promotional`}>
                                    <div className='product-title  text-left'>{item.Name}</div>
                                  </a>
                                  <div className='product-title text-left'>Ürün Kodu: {item.ProductCode}</div>
                                </div>
                                <hr />
                                <div className='row align-items-center product-bottom-fixed justify-content-between '>
                                  <div className='col-5 p-0'>
                                    {item.BrandDetail?.ImageUrl && (
                                      <Link to={`/products?brandId=${item.BrandId}`}>
                                        <Image
                                          src={item.BrandDetail?.ImageUrl}
                                          alt=''
                                          className='popular_product'
                                          style={{ width: '50px' }}
                                        />
                                      </Link>
                                    )}
                                  </div>
                                  <div className='col-7'>
                                    <div className='d-flex flex-column align-items-end'>
                                      {item.CampaignPrice?.CampaignPriceDisplay ? (
                                        <>
                                          <del
                                            className={css`
                                              color: #b3b3b3;
                                              font-size: 19px;
                                            `}
                                          >
                                            {item.Prices?.Special.Price ?? ''}
                                          </del>
                                          <div
                                            className={css`
                                              font-size: 19px;
                                            `}
                                          >
                                            {/* {item.CampaignPrice?.CampaignPriceDisplay} */}
                                            <IPPriceDisplay
                                              price={item.CampaignPrice?.CampaignPriceDisplay}
                                              fractionalFontSize='14px'
                                            />
                                          </div>
                                        </>
                                      ) : (
                                        <div
                                          className={css`
                                            font-size: 19px;
                                          `}
                                        >
                                          {/* {item.Prices?.Special.Price ?? ''} */}
                                          <IPPriceDisplay
                                            price={item.Prices?.Special.Price ?? ''}
                                            fractionalFontSize='14px'
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex align-items-end justify-content-between'>
                                  <div>
                                    {/* Adet:{' '} */}
                                    <IPInput
                                      type='number'
                                      className={`stockNumber myCardStockNumber ${css`
                                        height: 44px;
                                      `}`}
                                      min={1}
                                      controlledField
                                      value={suggestQuantities[item.Id] || 1} // Ürün ID'sine göre miktarı alın
                                      onChange={e => handleQuantityChange(item.Id, parseInt(e.target.value))} // Miktar değiştiğinde handleQuantityChange fonksiyonunu çağırın
                                      onBlur={e => handleQuantityOnBlur(e)}
                                    />
                                  </div>
                                  <div className='productButton'>
                                    <AddToBasket
                                      product={item}
                                      quantity={suggestQuantities[item.Id] || 1} // Ürün ID'sine göre miktarı alın
                                      className={css`
                                        font-size: 14px;
                                        height: 44px;
                                      `}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  props.Suggest.map(
                    (item: any, index) =>
                      index < 2 && (
                        <div className={`col-12 col-sm-6 col-md-4 col-lg-6 card-mobile-resize`} key={item.Id}>
                          <div
                            className={`d-flex flex-column justify-content-start most-popular-products ${css`
                              padding: 20px;
                            `}`}
                          >
                            <div className='room'>
                              <div className='room-image text-center'>
                                <a href={`/product/detail/${item.Id}?from=promotional`}>
                                  {item.Image ? (
                                    <Image
                                      src={item.Image}
                                      alt=''
                                      className='popular_product'
                                      style={{ objectFit: 'contain' }}
                                    />
                                  ) : (
                                    <Image
                                      src='./images/products/Resim_yok-01.jpg'
                                      alt=''
                                      style={{ objectFit: 'contain' }}
                                    />
                                  )}
                                </a>
                              </div>
                            </div>
                            <div className='product-description'>
                              <a href={`/product/detail/${item.Id}?from=promotional`}>
                                <div className='product-title  text-left'>{item.Name}</div>
                              </a>
                              <div className='product-title text-left'>Ürün Kodu: {item.ProductCode}</div>
                            </div>
                            <hr />
                            {/* <div className='text-right'>
                      <del
                        className={css`
                          color: #b3b3b3;
                        `}
                      >
                        {item.Prices?.Special.Price ?? ''}
                      </del>
                      <div
                        className={css`
                          font-size: 19px;
                        `}
                      >
                        {item.CampaignPrice?.CampaignPriceDisplay}
                      </div>
                    </div> */}

                            <div className='row align-items-center product-bottom-fixed justify-content-between '>
                              <div className='col-5 p-0 '>
                                {item.BrandDetail?.ImageUrl && (
                                  <Link to={`/products?brandId=${item.BrandId}`}>
                                    <Image
                                      src={item.BrandDetail?.ImageUrl}
                                      alt=''
                                      className='popular_product'
                                      style={{ width: '50px' }}
                                    />
                                  </Link>
                                )}
                              </div>
                              <div className='col-7 text-right mt-2 mb-1'>
                                {item.CampaignPrice?.CampaignPriceDisplay
                                  ? item.CampaignPrice?.CampaignPriceDisplay
                                  : item.Prices?.Special.Price ?? ''}
                              </div>
                            </div>

                            <div className='d-flex align-items-end justify-content-between'>
                              <div>
                                {/* Adet:{' '} */}
                                <IPInput
                                  type='number'
                                  className={`stockNumber myCardStockNumber ${css`
                                    height: 44px;
                                  `}`}
                                  min={1}
                                  controlledField
                                  value={suggestQuantities[item.Id] || 1} // Ürün ID'sine göre miktarı alın
                                  onChange={e => handleQuantityChange(item.Id, parseInt(e.target.value))} // Miktar değiştiğinde handleQuantityChange fonksiyonunu çağırın
                                  onBlur={e => handleQuantityOnBlur(e)}
                                />
                              </div>
                              <div className='productButton'>
                                <AddToBasket
                                  product={item}
                                  quantity={suggestQuantities[item.Id] || 1} // Ürün ID'sine göre miktarı alın
                                  className={css`
                                    font-size: 14px;
                                    height: 44px;
                                  `}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )
                )}
              </div>
            </>
          )}
        </div>

        {/* Campaign */}
        {product.ProductCampaigns && product.ProductCampaigns.length > 0 && (
          <div className='row'>
            {product.ProductCampaigns.map(
              p =>
                p.ProductListImageUrl && (
                  <div className='col' key={p.CampaignId}>
                    <div className='mb-4'>
                      <img src={p.ProductListImageUrl} alt={p.CampaignDesc} />
                    </div>
                  </div>
                )
            )}
          </div>
        )}

        {/* BANNER */}
        {params.type === 'product' &&
          banner
            .sort((a, b) => a.Order - b.Order)
            .map(item => (
              <div className='row m-t-50 w-125' key={item.Id}>
                <Image src={item.ImageUrl} alt='' className='' />
              </div>
            ))}

        {/* <div className="row m-t-10 w-125">
      <Image
        src="/images/product-detail/banner1-product.svg"
        alt=""
        className=""
      />
    </div> */}
        {/* OTHER SELLER */}

        {props.otherProducts && props.otherProducts.length > 0 && (
          <div className='row my-5' style={{ display: 'flex' }}>
            <p style={{ fontSize: 18, color: '#000', fontWeight: 500 }}>Ürünün Diğer Satıcıları</p>
            <div>
              {props.otherProducts.map((item, index) => (
                <div style={{ display: 'flex' }} key={index}>
                  <div
                    style={{ alignSelf: 'center' }}
                    className='col-lg-3 col-3 d-flex justify-content-between flex-column gap-3 mt-2'
                  >
                    <Image src={item.Product?.Company?.Image} alt='' style={{ width: '100px' }} />
                  </div>
                  <div
                    className='col-lg-5 col-4 d-flex flex-column justify-content-between gap-2'
                    style={{ alignSelf: 'flex-end' }}
                  >
                    <p style={{ fontSize: 18, fontWeight: 500 }}>
                      Size Özel :{' '}
                      {
                        <IPPriceDisplay
                          price={item.Product?.Prices?.Special?.[`${currency}Price`].PriceDisplay ?? '0'}
                        />
                      }
                    </p>
                  </div>
                  <div
                    className={`col-lg-2 col-1 d-flex flex-column justify-content-between gap-2 ${classes.otherSellers}`}
                  >
                    <a href={`/product/detail/${item.Product?.Id}?from=productList`} rel='noopener noreferrer'>
                      <IPButton style={{ color: '#ec4c4c' }} className={classes.otherSeller} text='Satıcıya Git' />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <ProductIQuotaForm ref={iQuotaFormRef} />
      </div>
    </>
  )
}

export default HeaderLastRight
