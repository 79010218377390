import React, { useMemo, useState, useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import InputCampaign from '../../../../../components/InputCampaign'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Table from '../../../../../components/Table'
import { HandMadeOrdersApi } from '../services/handMadeOrdersApi'
import { IHandMadeOrdersRequest, IHandMadeOrdersResponse } from '../models/models'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import Swal from 'sweetalert2'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ICompany } from '../../../components/models/models'
import { getCompanyApi } from '../../../components/api'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'

interface IHandcraftedOrdersListState {
  company: ISelectOption | null,
  orderNumber: string
}
function HandcraftedOrdersList(props: BaseComponentWithContextProps) {
  const getInitialState = (): IHandcraftedOrdersListState => {
    return {
      company: null,
      orderNumber: ""
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IHandcraftedOrdersListState>(intialState)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const t = translate(Translations)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [orders, setOrders] = useState<IHandMadeOrdersResponse[]>([])
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  useEffect(() => {
    getCompanies()
    handMadeOrders()
  }, [])

  const handMadeOrders = async () => {
    const req: IHandMadeOrdersRequest = {
      Company: state.company ? String(state.company.value) : "",
      OrderNumber: state.orderNumber,
      StartDate: startDate && getDateByTimezoneOffset(startDate) as any,
      EndDate: endDate && getDateByTimezoneOffset(endDate) as any
    }
    props.showLoading();
    const handMadeOrdersApi: HandMadeOrdersApi = new HandMadeOrdersApi({})
    await handMadeOrdersApi
      .handMadeOrders(req)
      .then(result => {
        if (result) {
          setOrders(result)
        }
        props.hideLoading();
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    setCompanies(company)
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Satır Sayaç',
        accessor: 'col1',
      },
      {
        Header: 'Sipariş Sayaç',
        accessor: 'col2',
      },
      {
        Header: 'Pazar',
        accessor: 'col3',
      },
      {
        Header: 'Şirket Kodu',
        accessor: 'col4',
      },
      {
        Header: 'Sipariş Kodu',
        accessor: 'col5',
      },
      {
        Header: 'İşlem',
        accessor: 'col6',
      },
      {
        Header: 'Değişiklik Alanı',
        accessor: 'col7',
      },
      {
        Header: 'İşlem Yapan',
        accessor: 'col8',
      },
      {
        Header: 'Bilgi',
        accessor: 'col9',
      },
      {
        Header: 'Eski Değer',
        accessor: 'col10',
      },
      {
        Header: 'Yeni Değer',
        accessor: 'col11',
      },
      {
        Header: 'Toplam Eski Değer',
        accessor: 'col12',
      },
      {
        Header: 'Toplam Yeni Değer',
        accessor: 'col13',
      },
      {
        Header: 'İşlem Zamanı',
        accessor: 'col14',
      },
    ],
    []
  )
  let orderNumber = "";
  let i = 1;
  const tableData = useMemo(
    () => orders.map((item, index) => {
      let time = item.UWhen.slice(0, 8) + " ";
      let when = item.UWhen.slice(8)
      let date = when.slice(0, 2) + ":"
      date += when.slice(2, 4) + ":"
      date += when.slice(4)
      if (index === 0) {
        orderNumber = "";
        i = 1;
      }
      if (orderNumber.length > 0 && orderNumber !== item.OrderNumber) {
        i++
      }
      orderNumber = item.OrderNumber;
      const data = {
        col1: index + 1,
        col2: i,
        col3: item.CompanyName,
        col4: item.CompanyCode,
        col5: item.OrderNumber,
        col6: item.Process,
        col7: item.FName,
        col8: item.UserName,
        col9: item.TabKey,
        col10: item.OldValue,
        col11: item.NewValue,
        col12: item.SumOldValue.toFixed(2),
        col13: item.SumNewValue.toFixed(2),
        col14: time + date
      }
      return data
    }), [orders])
  
  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }
  
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              {t('pages.admin.dealerAndOrderReport.handcraftedOrdersList.handcraftedOrdersList.header')}
            </span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>
                <div className='col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>
                <div className='col-md-2 mb-3'>
                  <IPInput type='text' className='form-control' id='SiparisKodu' placeholder='Sipariş Kodu' onChange={(e) => setState({ orderNumber: e.target.value })} />
                </div>
                <div className='col-md-2 mb-3'>
                  <IPSelectBox
                    isClearable
                    options={
                      companies &&
                      companies.filter((com: ICompany) => { return com.Name !== "Index Group" }).map((item: ICompany) => ({
                        label: item.Name,
                        value: `${item.Code}`,
                      }))
                    }
                    value={state.company}
                    placeholder="Şirket Seçiniz"
                    onChangeSingle={option => setState({ company: option })}
                  />
                </div>
                <div className='col-md-2 mb-3'>
                  <IPButton
                    type='button'
                    className='btn btn-success'
                    style={{ width: "25%" }}
                    onClick={handMadeOrders}
                    text={t('pages.admin.dealerAndOrderReport.handcraftedOrdersList.handcraftedOrdersList.filtre')}
                  />
                </div>
              </div>
            </form>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} headerName={t('pages.admin.dealerAndOrderReport.handcraftedOrdersList.handcraftedOrdersList.header')} columnCount={14} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(HandcraftedOrdersList)
