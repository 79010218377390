import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IPostBrandAddRequest } from '../models/requestBrands'
import { IBrand } from '../../../components/models/models'

export class UpdateBrandApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/brand/updateBrandDetail',
      requesterInfo,
    })
  }

  public async putBrand(request: IPostBrandAddRequest): Promise<IBrand> {
    return await this.putAsync<IBrand>(undefined, request)
  }
}
