import React from 'react'
import Header from './components/admin/Header'
import Footer from './components/admin/Footer'

export function AdminLayout(props: any) {
  return (
    <React.Fragment>
      <Header />
      {props.children}
      <Footer />
    </React.Fragment>
  )
}

export default AdminLayout
