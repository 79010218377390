import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IProductsWithoutPicturesReport } from '../models/models'

export class ProductsWithoutPicturesReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/productsWithoutPicturesReport',
      requesterInfo,
    })
  }

  public async productsWithoutPicturesReport(): Promise<IProductsWithoutPicturesReport[]> {
    // this.props.url = `${this.props.url}/${Id}`
    return await this.getAsync<IProductsWithoutPicturesReport[]>()
  }
}
