import { css } from '@emotion/css'
import { useFormik } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../base/customHooks'
import { getDateByTimezoneOffset } from '../../../base/utils/dataHelper'
import IPButton from '../../../components/IPButton'
import IPSelectBox from '../../../components/IPSelectBox'
import { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../components/InputCampaign'
import { Spinner } from '../../../components/Spinner'
import Table from '../../../components/Table'
import { IExchangeRate } from '../../../layouts/components/client/models'
import { GetExchangeRatesApi } from '../../../layouts/components/client/services/getExchangeRatesApi'
import { ListPastCurrencyResponse, ListPastCurrencyService } from '../services/ListPastCurrency'

export interface ExchangeRateState {
  firstExchangeRate?: IExchangeRate
  paraBirimi?: ISelectOption | null
  fSelect?: ISelectOption | null
  sSelect?: ISelectOption | null
  input?: string
  result?: string | undefined
}

export default function Exchange() {
  const getInitialState = (): ExchangeRateState => {
    return {}
  }

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [StartDate, setStartDate] = useState(inititalStartDate)
  const [EndDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const initialValues = Object.assign({
    Usd: 0,
    Eur: 0,
  })

  const intialState = getInitialState()

  const [state, setState] = useCustomReducer<ExchangeRateState>(intialState)

  const { setValues } = useFormik({
    initialValues: initialValues,

    onSubmit: async values => {},
  })
  useEffect(() => {
    getExchangeRates()
  }, [null])

  const [tableData, setTableData] = useState<ListPastCurrencyResponse[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedCurrency, setSelectedCurrency] = useState<ISelectOption>() // Varsayılan para birimi

  async function handleSubmit() {
    const req = {
      StartDate: getDateByTimezoneOffset(StartDate),
      EndDate: getDateByTimezoneOffset(EndDate),
    }
    setLoading(true)
    try {
      const res = await new ListPastCurrencyService({}).Post(req)
      if (res) {
        res.sort((a, b) => {
          return new Date(b.Date).getTime() - new Date(a.Date).getTime()
        })
        setTableData(res)
        setLoading(false)
      }
    } catch (err: any) {
      setLoading(false)
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Tarih',
        accessor: 'Date',
      },
      {
        Header: 'Dolar',
        accessor: 'Usd',
      },
      {
        Header: 'Euro',
        accessor: 'Euro',
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      tableData.map(p => ({
        Date: new Intl.DateTimeFormat('tr-TR', { dateStyle: 'short' }).format(new Date(p.Date)),
        Usd: p.Usd,
        Euro: p.Euro,
      })),
    [tableData]
  )

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const getExchangeRates = async () => {
    try {
      const api = new GetExchangeRatesApi({})
      const res = await api.getExhangeRates()

      const formatCurrency = value => {
        const parts = value.split(/[.,]/)
        const integerPart = parts[0]
        const decimalPart = parts[1] || ''
        const precision = 4

        const paddedDecimalPart = decimalPart.padEnd(precision, '0').substring(0, precision)
        return `${integerPart},${paddedDecimalPart}`
      }

      const formattedUsd = formatCurrency(res.Usd)
      const formattedEur = formatCurrency(res.Eur)

      setState({
        firstExchangeRate: {
          ...res,
          Usd: formattedUsd,
          Eur: formattedEur,
        },
      })

      setValues({
        Usd: Number(formattedUsd),
        Eur: Number(formattedEur),
      })
    } catch (error) {
      // Handle errors here
      console.error('Error fetching exchange rates:', error)
    }
  }

  const paraBirimleri = [
    { label: 'TRY', value: 'TRY' },
    { label: 'ABD Doları', value: 'USD' },
    { label: 'EUR', value: 'EUR' },
  ]

  const calculate = async () => {
    if (!state.input) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen Çevrilecek Değeri Giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    } else if (state.fSelect && state.sSelect && inputValue && state.firstExchangeRate) {
      const exchangeRates = {
        TRY: 1,
        USD: state.firstExchangeRate?.Usd.replace(',', '.'),
        EUR: state.firstExchangeRate?.Eur.replace(',', '.'),
      }

      const conversionFactors = {
        TRY: 1,
        USD: exchangeRates.USD,
        EUR: exchangeRates.EUR,
      }

      const conversionFactor = conversionFactors[state.fSelect.value] / conversionFactors[state.sSelect.value]

      let calc

      if (state.fSelect.value === state.sSelect.value) calc = Number(inputValue.replace(',', '.')).toFixed(4)
      else calc = (Number(inputValue.replace(',', '.')) * conversionFactor).toFixed(4)

      setState({ result: calc })

      // if (state.fSelect.value === state.sSelect.value)
      //   calc = Number(state.input.replace(",", ".")).toFixed(4)

      // else if (state.fSelect.value === "TRY")
      // {
      //   if (state.sSelect.value === "USD")
      //     calc = (Number(state.input.replace(",",".")) / Number(state.firstExchangeRate?.Usd.replace(",", '.'))).toFixed(4)
      //   else if (state.sSelect.value === "EUR")
      //     calc = (Number(state.input.replace(",", ".")) / Number(state.firstExchangeRate?.Eur.replace(",", '.'))).toFixed(4)
      // }

      // else if (state.fSelect.value === "USD")
      // {
      //   if (state.sSelect.value === "TRY")
      //     calc = (Number(state.input.replace(",", ".")) * Number(state.firstExchangeRate?.Usd.replace(",", '.'))).toFixed(4)
      //   else if (state.sSelect.value === "EUR")
      //     calc = ((Number(state.input.replace(",", "."))) * (Number(state.firstExchangeRate?.Usd.replace(",", '.'))) / Number(state.firstExchangeRate?.Eur.replace(",", '.'))).toFixed(4)
      // }

      // else if (state.fSelect.value === "EUR")
      // {

      //   if (state.sSelect.value === "TRY")
      //     calc = (Number(state.input.replace(",", ".")) * Number(state.firstExchangeRate?.Eur.replace(",", '.'))).toFixed(4)
      //   else if (state.sSelect.value === "USD")
      //     calc = ((Number(state.input.replace(",", "."))) * (Number(state.firstExchangeRate?.Eur.replace(",", '.'))) / Number(state.firstExchangeRate?.Usd.replace(",", '.'))).toFixed(4)
      // }
      // setState({ result: calc })
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen Para Birimlerini Seçin',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const [inputValue, setInputValue] = useState('')

  const formatCurrency = (value: string) => {
    return parseFloat(value.replace(',', '.'))
      .toFixed(2)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
  }

  const handleInputChange = e => {
    // Kullanıcının girdisini alın
    const value = e.target.value
    setState({ input: value })
    setInputValue(value)

    // Sadece sayısal karakterleri kabul edin (virgül, nokta, vb. dahil değil)
    // if (/^\d*\.?\d*$/.test(value)) {
    //   setInputValue(value);
    // }
  }

  return (
    <>
      <Spinner loading={loading} />
      <div className='row'>
        <div className='col-lg-6'>
          <div className='d-flex flex-column flex-sm-row gap-2 mb-3'>
            <InputCampaign
              type='date'
              id='StartDate'
              name='StartDate'
              label={'Başlangıç Tarihi'}
              value={toDateTimeLocal(StartDate)}
              onChange={e => getDate(e.target.value, 'Start')}
              isActive
            />
            <InputCampaign
              type='date'
              id='EndDate'
              name='EndDate'
              label={'Bitiş Tarihi'}
              value={toDateTimeLocal(EndDate)}
              onChange={e => getDate(e.target.value, 'End')}
              isActive
            />
            <IPButton
              type='button'
              className={`btn btn-success ${css`
                width: 150px;
                height: 50px;
                @media (max-width: 575px) {
                  width: 100%;
                }
              `}`}
              text='Listele'
              onClick={handleSubmit}
            />
          </div>
          <Table columns={columns} data={data} />
        </div>
        <div className='col-lg-6'>
          <div className='d-flex flex-column flex-sm-row gap-2 mb-3'>
            <InputCampaign
              id='Dolar'
              label='ABD Doları'
              type='text'
              value={state.firstExchangeRate?.Usd}
              onChange={e => {}}
              placeholder='Dolar'
            />
            <InputCampaign
              id='Euro'
              label='Euro'
              type='text'
              value={state.firstExchangeRate?.Eur}
              onChange={e => {}}
              placeholder='Dolar'
            />
            <IPButton
              type='button'
              className='btn btn-success'
              style={{ width: '25%' }}
              text={'Güncelle'}
              onClick={getExchangeRates}
            />
          </div>
          <br></br>
          <div className='d-flex flex-column flex-sm-row gap-2 mb-3'>
            <div className='col-lg-6'>
              <IPSelectBox
                isClearable
                options={paraBirimleri}
                value={state.fSelect}
                placeholder='Para Birimi Seçiniz'
                onChangeSingle={option => {
                  setState({ fSelect: option })
                  setSelectedCurrency(option)
                }}
              />
            </div>
            <InputCampaign
              label=''
              id='MyVal1'
              type='text'
              value={state.input}
              // onChange={e => getInput(e.target.value)}
              onChange={handleInputChange}
              placeholder='Değer giriniz...'
            />
            <div style={{ borderBottom: '1px solid red', alignSelf: 'center' }}>
              {inputValue && formatCurrency(inputValue)}
            </div>
          </div>
          <div className='d-flex flex-column flex-sm-row gap-2 mb-3'>
            <div className='col-lg-6'>
              <IPSelectBox
                isClearable
                options={paraBirimleri}
                value={state.sSelect}
                placeholder='Para Birimi Seçiniz'
                onChangeSingle={option => setState({ sSelect: option })}
              />
            </div>
            <InputCampaign
              label=''
              id='MyVal2'
              type='text'
              value={
                state.result &&
                parseFloat(state.result)
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              }
              disabled
            />
            <div style={{ borderBottom: '1px solid red', alignSelf: 'center' }}>
              {state.result && formatCurrency(state.result)}
            </div>
          </div>
          <div className='col-md-2 mb-3'>
            <IPButton
              type='button'
              className='btn btn-success'
              style={{ width: '25%' }}
              text={'Hesapla'}
              onClick={calculate}
            />
          </div>
        </div>
      </div>
    </>
  )
}
