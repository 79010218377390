import React, { useState, useEffect } from 'react'
import '../MyReturns.scss'
import InputCampaign from '../../../../components/InputCampaign'
import IPButton from '../../../../components/IPButton'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { GetByDealerApi } from '../services/getByDealerApi'
import { IReturnList, IReturnRequest, ITableOrderRetunFormDetails } from '../models/models'
import IPModal from '../../../../components/IPModal'
import { useCustomReducer } from '../../../../base/customHooks'
import { getDateByTimezoneOffset, modeOfDelivery, productStatus, reasonForReturn } from '../../../../base/utils/dataHelper'
import IPInput from '../../../../components/IPInput'

interface IReturnListState {
  showModal: boolean
  tableOrderRetunFormDetails: ITableOrderRetunFormDetails[]
  orderNo: string
}
function Index(props: BaseComponentWithContextProps) {
  const getInitialState = (): IReturnListState => {
    return {
      showModal: false,
      tableOrderRetunFormDetails: [],
      orderNo: ""
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IReturnListState>(intialState)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState<any>(inititalStartDate)
  const [endDate, setEndDate] = useState<any>(new Date())
  const [returnList, setReturnList] = useState<IReturnList[]>([])
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getByDealer = async () => {
    const inititalEndDate = new Date(endDate)
    inititalEndDate.setDate(inititalEndDate.getDate() + 1)
    const inititalStart = new Date(startDate)
    inititalStart.setDate(inititalStart.getDate() - 1)
    const req: IReturnRequest = {
      SiparisNo: state.orderNo,
      StartDate: getDateByTimezoneOffset(inititalStart) as any,
      EndDate: getDateByTimezoneOffset(inititalEndDate) as any,
    }
    props.showLoading()
    const getByDealerApi: GetByDealerApi = new GetByDealerApi({})
    getByDealerApi
      .getByDealer(req)
      .then((result: IReturnList[]) => {
        setReturnList(result);
        props.hideLoading()
      })
      .catch((err) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })

  }

  useEffect(() => {
    getByDealer()
  }, [])

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='myReturns background-white'>
      <div className='container-fluid'>
        <div className='heading-text'>
          <h4>İptal/İadelerim</h4>
        </div>
        <div className='row mb-3'>
          <div className='col-12 col-md-3 mb-3'>
            <InputCampaign
              type='date'
              id='StartDate'
              name='StartDate'
              label={'Başlangıç Tarihi'}
              value={startDate && toDateTimeLocal(startDate)}
              onChange={e => getDate(e.target.value, "Start")}
              isActive
            />
          </div>
          <div className='col-12 col-md-3 mb-3'>
            <InputCampaign
              type='date'
              id='EndDate'
              name='EndDate'
              label={'Bitiş Tarihi'}
              value={endDate && toDateTimeLocal(endDate)}
              onChange={e => getDate(e.target.value, "End")}
              isActive
            />
          </div>
          <div className='col-12 col-md-3 mb-3'>
            <IPInput type='text' placeholder='Sipariş No' value={state.orderNo} onChange={(e) => setState({ orderNo: e.target.value })} />
          </div>
          <div className='col-12 col-md-2 mb-3'>
            <IPButton text="Listele" onClick={getByDealer} />
          </div>
        </div>
        <div className='row m-t-50 m-l-10 m-r-10'>
          <div className='table-responsive' style={{ height: 'auto' }}>
            <table className='campaignTable table table-bordered table-hover'>
              <thead>
                <tr>
                  <th scope='col' style={{ color: "red", fontSize: "12px" }}>SİPARİŞ NO</th>
                  <th scope='col' style={{ color: "red", fontSize: "12px" }}>TAKİP NO</th>
                  <th scope='col' style={{ color: "red", fontSize: "12px" }}>İŞLEM TİPİ</th>
                  <th scope='col' style={{ color: "red", fontSize: "12px" }}>PO Numarası</th>
                  <th scope='col' style={{ color: "red", fontSize: "12px" }}>İADE FATURA NO</th>
                  <th scope='col' style={{ color: "red", fontSize: "12px" }}>BELGE TARİHİ</th>
                  <th scope='col' style={{ color: "red", fontSize: "12px" }}>ONAY DURUMU</th>
                  <th scope='col' style={{ color: "red", fontSize: "12px" }}>RED NEDENİ</th>
                  <th scope='col' style={{ color: "red", fontSize: "12px" }}>DETAY</th>
                </tr>
              </thead>

              <tbody>
                {
                  returnList && returnList.length > 0 && returnList.map((list) => (
                    <tr key={list.Id}>
                      <td style={{ fontSize: "12px" }}>{list.SiparisNo}</td>
                      <td style={{ fontSize: "12px" }}>{list.TakipFormNo}</td>
                      <td style={{ fontSize: "12px" }}>{list.IslemTipi === 0 ? "İptal" : "İade"}</td>
                      <td style={{ fontSize: "12px" }}>{list.PONumber}</td>
                      <td style={{ fontSize: "12px" }}>{list.IadeFaturaNo}</td>
                      <td style={{ fontSize: "12px" }}>{new Date(list.CreatedDate).toLocaleDateString()}</td>
                      {/* <td style={{ fontSize: "12px" }}>{list.IadeFaturaNo ? "Onaylandı" : (list.Durum === 0 ? "Onay Bekliyor" : (list.Durum === 1 ? "Reddedildi" : "Onaylandı"))}</td> */}
                      <td style={{ fontSize: "12px", color: list.Durum === 3 ? "green" : list.Durum === 2 ? "blue" : list.Durum === 1 ? "red" : list.Durum === 0 ? "orange" : ""}}>{list.Durum===3 ? "Tamamlandı" : (list.Durum === 0 ? "Onay Bekliyor" : (list.Durum === 1 ? "Reddedildi" : "Onaylandı"))}</td>
                      <td style={{ fontSize: "12px" }}>{list.RedNedeni}</td>
                      <td className='position-relative'>
                        <div
                          className='tableIcon'
                          onClick={() => {
                            setState({
                              showModal: true,
                              tableOrderRetunFormDetails: list.TableOrderRetunFormDetails
                            })
                          }
                          }

                        >
                          <i className='icon-info'></i>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <IPModal
        width={1200}
        show={state.showModal}
        onClose={() =>
          setState({
            showModal: false,
            tableOrderRetunFormDetails: []
          })
        }
        closeOnEscapeKey={true}
        hideConfirm={true}
        title="İade Detay"
      >
        <table className='campaignTable table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>SİPARİŞ NO</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>ÜRÜN KODU</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>ÜRÜN ADI</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>ADET</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>BİRİM TUTAR</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>TOPLAM TUTAR</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>PB</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>ÜRÜN DURUMU</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>TESLİMAT ŞEKLİ</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>İADE NEDENİ</th>
              <th scope='col' style={{ color: "red", fontSize: "12px" }}>AÇIKLAMA</th>

            </tr>
          </thead>

          <tbody>
            {
              state.tableOrderRetunFormDetails && state.tableOrderRetunFormDetails.length > 0 && state.tableOrderRetunFormDetails.map((list) => (
                <tr key={list.Id}>
                  <td style={{ fontSize: "12px" }}>{list.SiparisNo}</td>
                  <td style={{ fontSize: "12px" }}>{list.MalzemeNo}</td>
                  <td style={{ fontSize: "12px" }}>{list.MalzemeAdi}</td>
                  <td style={{ fontSize: "12px" }}>{list.Miktar}</td>
                  <td style={{ fontSize: "12px" }}>{list.BirimFiyati.toFixed(2)}</td>
                  <td style={{ fontSize: "12px" }}>{(list.Miktar * list.BirimFiyati).toFixed(2)}</td>
                  <td style={{ fontSize: "12px" }}>{list.Pb === 0 ? "TRY" : (list.Pb === 1 ? "USD" : "EUR")}</td>
                  <td style={{ fontSize: "12px" }}>{productStatus(list.UrunDurumu)}</td>
                  <td style={{ fontSize: "12px" }}>{modeOfDelivery(list.TeslimatSekli)}</td>
                  <td style={{ fontSize: "12px" }}>{reasonForReturn(list.IadeNedeni)}</td>
                  <td style={{ fontSize: "12px" }}>{list.Aciklama}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}

export default componentWithContext(Index)
