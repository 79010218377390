/* eslint-disable jsx-a11y/anchor-is-valid */
import { css } from '@emotion/css'
import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks/customReducer'
import { translate } from '../../../../../base/localization'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import IPModal from '../../../../../components/IPModal'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../../components/InputCampaign'
import Table from '../../../../../components/Table'
import Translations from '../../../../../translations'
import { CommonPostResponse, ICompany } from '../../../components/models/models'
import {
  BankInstallmentHistoryModel,
  BankInstallmentHistoryResponseModel,
  IBank,
  IInstallemnt,
  InstallmentFilterRequestModel,
} from '../models/models'
import { DeleteInstallemntsApi } from '../services/deleteInstallemntsApi'
import { ListInstallemntsApi } from '../services/listInstallemnts'

interface IInstallemntState {
  companies: ICompany[]
  searchCompanies: ISelectOption[]
  selectedCompany?: ISelectOption | undefined
  selectedType?: ISelectOption | undefined
  url?: string
}
const companies = [
  { label: 'Index', value: '1000' },
  { label: 'Netex', value: '2300' },
  { label: 'Despec', value: '1200' },
  { label: 'Datagate', value: '1100' },
]
const types = [
  { label: 'Avantaj Pos', value: '0' },
  { label: 'Fırsat POS', value: '1' },
  { label: 'FÇV2', value: '2' },
  { label: 'Cari Ödeme', value: '3' },
]

function BankUpdateDel(props: BaseComponentWithContextProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const searchCompany = searchParams.get('Sirket')
  const searchType = searchParams.get('Tip')
  const searchSearch = searchParams.get('Arama')
  const searchStart = searchParams.get('Baslangic')
  const searchEnd = searchParams.get('Bitis')

  const [bankInstallment, setBankInstallment] = useState<IInstallemnt[]>([])

  const [searchQuery, setSearchQuery] = useState(searchSearch ? searchSearch : '')
  const t = translate(Translations)
  const tableRef = useRef<any>()
  const [bank, setBank] = useState<IBank[]>([])
  const [showDetail, setShowDetail] = useState<boolean>(false)
  const [installmentDeleted, setInstallmentDeleted] = useState<boolean>(false)
  const [showBankInstallmentHistory, setShowBankInstallmentHistory] = useState<boolean>(false)
  const [showInstallmentHistory, setBankInstallmentHistory] = useState<boolean>(false)
  const [allHistory, setAllHistory] = useState<BankInstallmentHistoryResponseModel[]>([])
  const [installmentHistory, setInstallmentHistory] = useState<BankInstallmentHistoryModel[]>([])

  const getInitialState = (): IInstallemntState => {
    return {
      companies: [],
      searchCompanies: [],
      selectedCompany: searchCompany
        ? {
            label: companies.find(a => a.label === searchCompany)?.label || '',
            value: companies.find(a => a.label === searchCompany)?.value || 0,
          }
        : undefined,
      selectedType: searchType
        ? {
            label: types.find(a => a.label === searchType)?.label || '',
            value: types.find(a => a.label === searchType)?.value || 0,
          }
        : undefined,
    }
  }

  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<IInstallemntState>(initialState)

  // Tarih ve saat ayarları
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }
  // Klavyeden tarih ve saat girebilmeye imkan veren fonksiyon
  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const [startDate, setStartDate] = useState(searchStart ? searchStart : toDateTimeLocal(inititalStartDate))
  const [endDate, setEndDate] = useState(searchEnd ? searchEnd : toDateTimeLocal(new Date()))

  useEffect(
    () => {
      listInstallemnts('Listeleme')
    },
    [
      //searchQuery, state.selectedCompany, state.companies, state.selectedType
    ]
  )

  const listInstallemnts = async type => {
    const queryParams = {
      Sirket: state.selectedCompany ? companies.find(a => a.label === state.selectedCompany?.label)?.label : undefined,
      Tip: state.selectedType ? types.find(a => a.value === state.selectedType?.value)?.label : undefined,
      Arama: searchQuery !== '' && searchQuery != null ? searchQuery : undefined,
      Baslangic: startDate ? startDate : undefined,
      Bitis: endDate ? endDate : undefined,
    }

    const filteredQueryParams = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const navigateRoute = `/admin/BankAndInstallementOperation/BankCrud?${filteredQueryParams}`

    setState({ url: filteredQueryParams })
    navigate(navigateRoute)

    const startDateValid = startDate && (getDateByTimezoneOffset(startDate as Date) as any)
    const endDateValid = endDate && (getDateByTimezoneOffset(endDate as Date) as any)

    if (startDateValid && endDateValid) {
      const req: InstallmentFilterRequestModel = {
        StartDate: startDateValid,
        EndDate: endDateValid,
      }
      props.showLoading()
      const listInstallemntsApi: ListInstallemntsApi = new ListInstallemntsApi({})
      listInstallemntsApi
        .listInstallemnts(req)
        .then(res => {
          if (res) {
            props.hideLoading()
            const data: IInstallemnt[] = []
            res.map(ins => {
              const bank: IBank[] = []
              ins.ModelList.map(model => {
                return bank.push({
                  Id: model.Id,
                  BankId: model.BankId,
                  BankName: model.BankName,
                  Installment: model.Installment,
                  InterestRate: model.InterestRate,
                  BankGroupId: model.BankGroupId,
                  History: model.History,
                })
              })
              data.push({
                GroupId: ins.GroupId,
                Company: ins.ModelList[0].Company,
                Type: ins.ModelList[0].Type,
                StartDate: ins.ModelList[0].StartDate,
                EndDate: ins.ModelList[0].EndDate,
                PaymentTermsId: ins.ModelList[0].PaymentTermsId,
                PaymentTermsName: ins.ModelList[0].PaymentTermsName,
                Bank: bank,
                History: '',
                AllHistory: ins.AllHistory,
              })
            })
            if (type === 'Listeleme') {
              return setBankInstallment(data)
            } else if (type === 'Güncelleme') {
              setBankInstallment(data)
            }
            tableRef.current?.clear()
            tableRef.current?.rows
              .add(
                data.map(dt => {
                  return {
                    GroupId: dt.GroupId,
                    Company:
                      dt.Company === '1000'
                        ? 'İndex'
                        : dt.Company === '1100'
                        ? 'Datagate'
                        : dt.Company === '1200'
                        ? 'Despec'
                        : dt.Company === '2300'
                        ? 'Netex'
                        : '',
                    Start: new Date(dt.StartDate).toLocaleDateString('tr-TR'),
                    End: new Date(dt.EndDate).toLocaleDateString('tr-TR'),
                    PaymentTermsName: dt.PaymentTermsName,
                    Type:
                      dt.Type === '0'
                        ? 'Avantaj POS'
                        : dt.Type === '1'
                        ? 'Fırsat POS'
                        : dt.Type === '2'
                        ? 'FÇV2'
                        : dt.Type === '3'
                        ? 'Cari Ödeme'
                        : '',
                  }
                })
              )
              .draw(true)
          }
        })
        .catch((err: any) => {
          tableRef.current?.clear().draw(true)
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    } else {
      // Dates are not valid, show a warning
      Swal.fire({
        title: 'Geçersiz Tarih',
        text: 'Lütfen geçerli başlangıç ve bitiş tarihleri giriniz.',
        icon: 'warning',
        confirmButtonText: 'Tamam',
      })
    }
  }

  const [deleteInstallementloadingId, setDeleteInstallementLoadingId] = useState<string | null>(null) // Yükleme durumunu takip eden state

  const deleteInstallement = async (groupId: string) => {
    const confirmResult = await Swal.fire({
      icon: 'warning',
      title: 'Emin misiniz?',
      text: 'Banka taksitini silmek istediğinizden emin misiniz?',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet, Sil',
      cancelButtonText: 'İptal',
      allowOutsideClick: false,
    })

    if (confirmResult.isConfirmed) {
      setDeleteInstallementLoadingId(groupId) // Yükleme durumunu başlat

      var data = bankInstallment.find(auth => auth.GroupId === groupId)
      const request: number[] = data ? data.Bank.map(b => Number(b.Id)) : [0]

      const deleteInstallemnts: DeleteInstallemntsApi = new DeleteInstallemntsApi({})
      deleteInstallemnts
        .deleteInstallemnts(request)
        .then((result: CommonPostResponse) => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Banka Taksit Silindi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async result => {
              if (result) listInstallemnts('Güncelleme')
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
        .finally(() => {
          setDeleteInstallementLoadingId(null) // Yükleme durumunu bitir
        })
    }
  }

  const [deleteBankloadingId, setDeleteBankLoadingId] = useState<number | null>(null) // Silme işlemi sırasında yükleme durumunu tutan state

  const deleteBank = async (id: number, bankName: string, installment: number) => {
    const confirmResult = await Swal.fire({
      icon: 'warning',
      title: 'Emin misiniz?',
      text: `${bankName} bankasının "${installment}" taksitini silmek istediğinizden emin misiniz?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Evet, Sil',
      cancelButtonText: 'İptal',
      allowOutsideClick: false,
    })

    if (confirmResult.isConfirmed) {
      setDeleteBankLoadingId(id) // Silme işlemi başladığında loadingId'yi güncelle

      const deleteInstallemnts: DeleteInstallemntsApi = new DeleteInstallemntsApi({})
      deleteInstallemnts
        .deleteInstallemnts([id])
        .then((result: CommonPostResponse) => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: `${bankName} bankasının "${installment}" taksiti Silindi!`,
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async result => {
              if (result) setInstallmentDeleted(true)

              if (bank.length === 1) return setShowDetail(false)

              setBank(bank.filter(b => b.Id !== id))
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
        .finally(() => {
          setDeleteBankLoadingId(null) // Silme işlemi tamamlandığında loadingId'yi sıfırla
        })
    }
  }

  const navigate: any = useNavigate()
  const updateInstallement = async (groupId: string) => {
    var dt = bankInstallment.find(auth => {
      return auth.GroupId === groupId
    })
    navigate('/admin/BankAndInstallementOperation/BankCrud/add', {
      state: { installementData: dt, url: state.url },
    })
  }

  //#region Tablo Kolonları ve Filtreleme
  const columns = useMemo(
    () => [
      {
        Header: 'Şirket',
        accessor: 'Company',
      },
      {
        Header: 'Tip',
        accessor: 'Type',
      },
      {
        Header: 'Kampanya Adı',
        accessor: 'PaymentTermsName',
      },
      {
        Header: 'Detay',
        accessor: 'Detail',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'Start',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'End',
      },
      {
        Header: 'Değişiklikler',
        accessor: 'LastAction',
      },
      {
        Header: 'İşlemler',
        accessor: 'Actions',
      },
    ],
    []
  )

  const filteredData = useMemo(() => {
    let filteredByCompany = bankInstallment
    if (state.selectedCompany?.value !== undefined) {
      filteredByCompany = bankInstallment.filter(dt => dt.Company === state.selectedCompany?.value)
    } else {
      filteredByCompany = bankInstallment
    }

    let filteredByType = filteredByCompany
    if (state.selectedType?.value !== undefined) {
      filteredByType = filteredByCompany.filter(dt => dt.Type === state.selectedType?.value)
    }

    let filteredBySearchQuery = filteredByType.filter(dt => {
      if (searchQuery === '') return true
      return (
        dt.Company.toLowerCase().includes(searchQuery.toLowerCase()) ||
        dt.PaymentTermsName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    })

    return filteredBySearchQuery.map(dt => {
      return {
        Company:
          dt.Company === '1000'
            ? 'Index'
            : dt.Company === '1100'
            ? 'Datagate'
            : dt.Company === '1200'
            ? 'Despec'
            : dt.Company === '2300'
            ? 'Netex'
            : '',
        Type:
          dt.Type === '0'
            ? 'Avantaj POS'
            : dt.Type === '1'
            ? 'Fırsat POS'
            : dt.Type === '2'
            ? 'FÇV2'
            : dt.Type === '3'
            ? 'Cari Ödeme'
            : '',
        PaymentTermsName: dt.PaymentTermsName,
        Detail: (
          <IPButton
            text='Detay'
            onClick={() => {
              var datas = bankInstallment.find(auth => {
                return auth.GroupId === dt.GroupId
              })
              datas?.Bank.sort((a, b) => Number(a.BankId) - Number(b.BankId))
              if (datas) setBank(datas?.Bank)
              setShowDetail(true)
            }}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
        Start: new Date(dt.StartDate).toLocaleString('tr-TR'),
        End: new Date(dt.EndDate).toLocaleString('tr-TR'),
        LastAction: (
          <IPButton
            text='Tarihçe'
            onClick={() => {
              setShowBankInstallmentHistory(true)
              var selectedData = bankInstallment.find(auth => auth.GroupId === dt.GroupId)
              if (selectedData) {
                const { AllHistory } = selectedData

                if (AllHistory && AllHistory.length > 0) {
                  setAllHistory(AllHistory)
                } else {
                  setAllHistory([])
                }
              } else {
                setAllHistory([])
              }
            }}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
        Actions: (
          <>
            <a
              onClick={() => updateInstallement(dt.GroupId)}
              data-action-type='edit'
              role='button'
              className={css`
                cursor: pointer;
              `}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.edit')}
            >
              <i
                className={`${css`
                  font-size: 1.2rem;
                  color: green;
                `} icon-edit`}
              ></i>
            </a>
            <a
              data-action-type='copy'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title='Kopyala'
              onClick={() => {
                navigate('/admin/BankAndInstallementOperation/BankCrud/add', {
                  state: { installementData: dt, flag: 'copy', url: state.url },
                })
              }}
            >
              <i className='icon-copy' style={{ fontSize: '1.2rem', color: 'blue' }}></i>
            </a>
            <a
              onClick={() => deleteInstallement(dt.GroupId)}
              data-action-type='delete'
              role='button'
              className={css`
                cursor: pointer;
              `}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.delete')}
            >
              {deleteInstallementloadingId === dt.GroupId ? (
                <i
                  className={`${css`
                    font-size: 1.2rem;
                    color: gray;
                  `} fas fa-spinner fa-spin`}
                ></i>
              ) : (
                <i
                  className={`${css`
                    font-size: 1.2rem;
                    color: red;
                  `} icon-trash-2`}
                ></i>
              )}
            </a>
          </>
        ),
      }
    })
  }, [bankInstallment, state.selectedCompany, state.selectedType, searchQuery, deleteInstallementloadingId])
  //#endregion

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Banka Taksit Listeleme</span>
            <div className='col-12 col-md-2 mb-2'>
              <label className='p-switch switch-color-success'>
                <Link to='/admin/BankAndInstallementOperation/BankCrud/add'>
                  <IPButton
                    text={'Banka Taksit ' + t('pages.admin.bankAndInstallementOperation.bankCrud.bankUpdateDel.add')}
                    className='btn btn-primary'
                    style={{ height: '45px', marginLeft: '15px' }}
                  />
                </Link>
              </label>
            </div>
          </div>
          <div className='card-body'>
            <div className='row align-items-center'>
              <div className='col-12 col-md-3 mb-3'>
                <InputCampaign
                  type='datetime-local'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={startDate && toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, 'Start')}
                  isActive
                />
              </div>
              <div className='col-12 col-md-3 mb-3'>
                <InputCampaign
                  type='datetime-local'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={endDate && toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, 'End')}
                  isActive
                />
              </div>
              <div className='col-12 col-md-2 mb-3'>
                <IPButton text='Göster' className='btn btn-danger' onClick={() => listInstallemnts('Listeleme')} />
              </div>
            </div>

            <div className='row align-items-center'>
              <div className='col-md-3'>
                <form>
                  <IPSelectBox
                    isClearable
                    id='Şirket'
                    name='Şirket'
                    placeholder='Şirket seçiniz...'
                    options={companies}
                    value={state.selectedCompany}
                    onChangeSingle={option => setState({ selectedCompany: option })}
                  />
                </form>
              </div>
              <div className='col-md-3'>
                <form>
                  <IPSelectBox
                    isClearable
                    id='Şirket'
                    name='Şirket'
                    placeholder='Tip Seçiniz...'
                    options={types}
                    value={state.selectedType}
                    onChangeSingle={option => setState({ selectedType: option })}
                  />
                </form>
              </div>
              <div className='col-md-6'>
                <form>
                  <div className='input-group'>
                    <input
                      type='text'
                      value={searchQuery}
                      onChange={e => setSearchQuery(e.target.value)}
                      placeholder='Arama...'
                      className='form-control'
                      style={{ height: '45px' }}
                    />
                    <div className='input-group-append'>
                      <span className='input-group-text'>
                        <i className='fa fa-search'></i>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4' style={{ padding: '10px' }}>
                <div className='row'>
                  <div className='col-sm-12 pr-4' style={{ overflowX: 'auto' }}>
                    <Table columns={columns} data={filteredData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={showDetail}
        onClose={() => {
          setShowDetail(false)
          if (installmentDeleted) {
            listInstallemnts('Güncelleme')
            setInstallmentDeleted(false)
          }
        }}
        hideConfirm={true}
        width={1000}
        title='Banka Taksit Detay'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>#</th>
              <th>Banka Adı</th>
              <th>Taksit</th>
              <th>Vade(max)</th>
              <th>İşlem</th>
            </tr>
          </thead>
          <tbody>
            {bank && bank.length > 0
              ? bank.map((ba, index) => (
                  <tr role='row' className='odd' key={ba.Id}>
                    <td>{index + 1}</td>
                    <td>{ba.BankName}</td>
                    <td>{ba.Installment}</td>
                    <td>{ba.InterestRate}</td>
                    {/* <td
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <div title='Sil' onClick={() => deleteBank(ba.Id, ba.BankName, ba.Installment)}>
                        {deleteBankloadingId === ba.Id ? (
                          <i className='fas fa-spinner fa-spin' style={{ fontSize: '1.2rem', color: 'gray' }}></i>
                        ) : (
                          <i
                            className='icon-trash-2'
                            style={{ fontSize: '1.2rem', color: 'red', cursor: 'pointer' }}
                          ></i>
                        )}
                      </div>

                      <div
                        title='Tarihçe'
                        className='ml-2'
                        data-toggle='tooltip'
                        style={{ cursor: 'pointer', color: 'blue', fontSize: '35px' }}
                        onClick={() => {
                          if (Array.isArray(ba.History) && ba.History.length > 0) {
                            // Set the installment history directly
                            setInstallmentHistory(ba.History)
                          } else {
                            console.log('No History data to set')
                            setInstallmentHistory([])
                          }
                          setBankInstallmentHistory(true)
                        }}
                      >
                        <i className='icon-clock'></i>
                      </div>
                    </td> */}
                    <td
                      style={{
                        textAlign: 'center',
                        verticalAlign: 'middle',
                      }}
                    >
                      <a
                        title='Tarihçe'
                        className='ml-2'
                        data-toggle='tooltip'
                        style={{
                          cursor: 'pointer',
                          color: 'blue',
                          fontSize: '15px',
                        }}
                        onClick={() => {
                          if (Array.isArray(ba.History) && ba.History.length > 0) {
                            // Set the installment history directly
                            setInstallmentHistory(ba.History)
                          } else {
                            console.log('No History data to set')
                            setInstallmentHistory([])
                          }
                          setBankInstallmentHistory(true)
                        }}
                      >
                        <i className='icon-clock' style={{ fontSize: '15px' }}></i>
                      </a>
                      <a title='Sil' onClick={() => deleteBank(ba.Id, ba.BankName, ba.Installment)}>
                        {deleteBankloadingId === ba.Id ? (
                          <i className='fas fa-spinner fa-spin' style={{ fontSize: '15px', color: 'gray' }}></i>
                        ) : (
                          <i
                            className='icon-trash-2'
                            style={{
                              fontSize: '15px',
                              color: 'red',
                              cursor: 'pointer',
                            }}
                          ></i>
                        )}
                      </a>
                    </td>
                  </tr>
                ))
              : ''}
          </tbody>
        </table>
      </IPModal>
      <IPModal
        show={showBankInstallmentHistory}
        onClose={() => setShowBankInstallmentHistory(false)}
        hideConfirm={true}
        width={1200}
        title='Banka Taksit Tarihçe'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>Değişiklik Tarihi</th>
              <th>Banka Adı</th>
              <th>Şirket</th>
              <th>Başlangıç Tarihi</th>
              <th>Bitiş Tarihi</th>
              <th>Önceki Taksit</th>
              <th>Yeni Taksit</th>
              <th>Önceki Vade</th>
              <th>Yeni Vade</th>
              <th>Kullanıcı</th>
              <th>Ip</th>
              <th>Açıklama</th>
            </tr>
          </thead>
          <tbody>
            {allHistory && allHistory.length > 0
              ? allHistory.map((ba, index) => (
                  <tr role='row' className='odd' key={ba.Id}>
                    <td>{ba.ChangeDate ? moment(ba.ChangeDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                    <td>{ba.BankName}</td>
                    <td>
                      {ba.Company === '1000'
                        ? 'Index'
                        : ba.Company === '1100'
                        ? 'Datagate'
                        : ba.Company === '1200'
                        ? 'Despec'
                        : ba.Company === '2300'
                        ? 'Netex'
                        : ''}
                    </td>
                    <td>{ba.StartDate ? moment(ba.StartDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                    <td>{ba.EndDate ? moment(ba.EndDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                    <td>{ba.OldInstallment}</td>
                    <td>{ba.NewInstallment}</td>
                    <td>{ba.OldInterestRate}</td>
                    <td>{ba.NewRate}</td>
                    <td>{ba.UserName}</td>
                    <td>{ba.UserIP}</td>
                    <td>{ba.Description}</td>
                  </tr>
                ))
              : ''}
          </tbody>
        </table>
      </IPModal>
      <IPModal
        show={showInstallmentHistory}
        onClose={() => setBankInstallmentHistory(false)}
        hideConfirm={true}
        width={1200}
        title='Taksit Tarihçe'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>Değişiklik Tarihi</th>
              <th>Önceki Taksit</th>
              <th>Yeni Taksit</th>
              <th>Önceki Vade</th>
              <th>Yeni Vade</th>
              <th>Kullanıcı Adı</th>
            </tr>
          </thead>
          <tbody>
            {installmentHistory && installmentHistory.length > 0 ? (
              installmentHistory.map((history, index) => (
                <tr role='row' className='odd' key={index}>
                  <td>{history.ChangeDate ? moment(history.ChangeDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                  <td>{history.OldInstallment}</td>
                  <td>{history.NewInstallment}</td>
                  <td>{history.OldRate}</td>
                  <td>{history.NewRate}</td>
                  <td>{history.UserName}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td>No history available</td>
              </tr>
            )}
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}
export default componentWithContext(BankUpdateDel)
