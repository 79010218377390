import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import {
  CompanyHomepageClickResponseModel,
  CompanyHomepageClickResquestModel,
  DealerForHomePageRequest,
  DealerForHomePageResponse,
} from './models'

export class SiteNavigationService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GA4Reporting/DealerForHomePage',
      requesterInfo,
    })
  }

  public async DealerForHomePage(req: DealerForHomePageRequest): Promise<DealerForHomePageResponse[]> {
    return await this.postAsync<DealerForHomePageResponse[]>(req)
  }
}

export class CompanyHomepageClickStatsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/getCompanyHomepageClickStats',
      requesterInfo,
    })
  }

  public async getCompanyHomepageClickStats(
    req: CompanyHomepageClickResquestModel
  ): Promise<CompanyHomepageClickResponseModel> {
    return await this.postAsync<CompanyHomepageClickResponseModel>(req)
  }
}
