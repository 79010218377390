import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { GetSerialNumberCheck } from '../../../MyOrders/models/request/getMyOrderRequest'

export class SeriNoListToExcelApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'excel/seriNoList',
      requesterInfo,
    })
  }

  public async seriNoListToExcel(request: GetSerialNumberCheck): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
