import React, { useEffect, useState, useMemo, useRef } from 'react';
import './VisualDocumentation.scss';
import Swal from 'sweetalert2';
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC';
import { getUploadFileRequest } from '../../../../../base/utils/dataHelper';
import { PostDocApi } from '../services/postDocApi';



function DocumentUpload(props: BaseComponentWithContextProps) {
  const inputEl: any = useRef(null)


  const handleImageFileUpload = async (e: any) => {
    const fileList: any[] = await getUploadFileRequest(e)
    for (const fileData of fileList) {
      if (fileData.size > 50000000) {
        return Swal.fire('Hata', 'Her resim 50 MB\'den küçük olmalıdır!', 'error');
      }

      const allowedTypes = ['.jpeg', '.png', '.gif', '.jpg', '.vnd.openxmlformats-officedocument.wordprocessingml.document', '.xlsx', '.pdf', '.mp4'];
      if (!allowedTypes.includes(fileData.Extension)) {
        return Swal.fire('Hata', 'Hatalı dosya formatı! Sayfayı yeniledikten sonra tekrar deneyiniz.', 'error');
      }
      if (fileData.Extension === '.vnd.openxmlformats-officedocument.wordprocessingml.document')
        fileData.Extension = ".docx"
    }

    // Create the Files array from the fileList array
    const data = {
      Files: fileList.map((fileData, index) => ({
        Id: index,
        Label: '',
        Description: '',
        File: fileData,
      })),
    };

    const postImage: PostDocApi = new PostDocApi({});
    postImage
      .addDoc(data)
      .then((result: any) => {
        Swal.fire({
          icon: 'success',
          title: result,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      });
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              {'Doküman Yükleme'}
            </span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-4'>
                <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                  <i
                    className='icon-plus'
                    style={{ fontSize: '150px', color: '#adb5bd' }}
                    onClick={() => inputEl.current.click()}
                  ></i>
                  <input
                    ref={inputEl}
                    type='file'
                    name='mobilImage'
                    className='form-control'
                    id='mobilImage'
                    style={{ display: 'none' }}
                    onChange={event => {
                      handleImageFileUpload(event)
                    }}
                  />
                  <br />
                  <h5 style={{ color: '#adb5bd' }}>Dosya Yükle</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
export default componentWithContext(DocumentUpload);