import React from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import styles from './spinner.module.scss'
//import { css } from '@emotion/react'
//import { BeatLoader } from 'react-spinners'

interface ISpinnerProps {
  loading: boolean
  title?: React.ReactNode
}

// const override = css`
//   display: block;
//   margin: 10px;
//   border-color: red;
// `

function Spinner(props: ISpinnerProps) {
  return ReactDOM.createPortal(
    <CSSTransition in={props.loading} unmountOnExit timeout={{ enter: 0 }} style={{ zIndex: 3000 }}>
      <div className={styles.otherSpinnersContainer}>
        {/* <BeatLoader loading={props.loading} size={30} margin={4} color={'#630909'} css={override} /> */}
        <img src='/images/loading.gif' alt='Index Loading' width={200} />
      </div>
      {/* <div className={styles['lds-spinner']}>
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        <span className='ddd' style={{ marginBottom: '30px' }}>{props.title}</span>
        <ClipLoader loading={props.loading} css={override} size={150} />
      </div> */}
    </CSSTransition>,
    document.getElementById('ip-spinner')!
  )
}
export { Spinner }
