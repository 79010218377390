import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'

export class GetRemoteDealerChangeApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/remoteDealerChange',
      requesterInfo,
    })
  }

  public async getRemoteDealerChange(): Promise<any> {
    return await this.getAsync<any>()
  }
}
