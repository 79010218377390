import React from 'react'
import IPButton from '../../../../../components/IPButton'
import IPTextarea from '../../../../../components/IPTextarea'
import IPRadio from '../../../../../components/IPRadio'
import './SurprisePriceIdentificationAdd.scss'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'

function SurprisePriceIdentificationAdd() {
  const t = translate(Translations)
  return (
    <div className='container'>
      <div className='mb-3'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header'>
              <span className='h4'>
                {t('pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.header')}
              </span>
            </div>
            <div className='card-body'>
              <form id='id1' className='formform' noValidate>
                <div className='form-row'>
                  <div className='form-group col-md-2  '>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.payment'
                      )}
                    </label>
                  </div>
                  <div className='form-group col-md-3'>
                    <IPTextarea />
                    <p className='validation-error'> </p>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-4' style={{ fontSize: 10 }}>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.excel'
                      )}
                    </label>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-2' style={{ fontSize: 10 }}>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.product'
                      )}
                    </label>
                  </div>
                  <div className='form-group col-md-2  '>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.unitPrice'
                      )}
                    </label>
                  </div>
                  <div className='form-group col-md-2  '>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.money'
                      )}
                    </label>
                  </div>
                  <div className='form-group col-md-2  '>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.startDate'
                      )}
                    </label>
                  </div>
                  <div className='form-group col-md-2  '>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.endDate'
                      )}
                    </label>
                  </div>
                  <div className='form-group col-md-2  '>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.columns'
                      )}
                      (
                      <span style={{ color: 'red' }}>
                        {t(
                          'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.untitled'
                        )}
                      </span>
                      )
                    </label>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-9'>
                    <IPTextarea className='textareaAdd' />
                  </div>
                  <div className='form-group col-md-3'>
                    <span style={{ fontSize: 15 }}>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.enterInformation'
                      )}
                      <b>ENTER</b>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.repeat'
                      )}
                    </span>
                    <label className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.currentSubgroup'
                      )}
                    </label>
                    <div className='d-flex pr-3'>
                      <div className='col-lg-4 mt-1'>
                        <IPRadio
                          text={t(
                            'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.all'
                          )}
                          type='radio'
                          name='radio1'
                        />
                        <IPRadio
                          text={t(
                            'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.none'
                          )}
                          type='radio'
                          name='radio1'
                        />
                      </div>
                      <div className='w-100'>
                        <IPTextarea />
                      </div>
                    </div>
                    <p className='validation-error'> </p>

                    <label className='labeltext'>
                      {t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.current'
                      )}
                    </label>
                    <div className='d-flex pr-3'>
                      <div className='col-lg-4 mt-1'>
                        <IPRadio
                          text={t(
                            'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.all'
                          )}
                          type='radio'
                          name='radio3'
                        />
                        <IPRadio
                          text={t(
                            'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.none'
                          )}
                          type='radio'
                          name='radio3'
                        />
                      </div>
                      <div className='w-100'>
                        <IPTextarea />
                      </div>
                    </div>
                    <p className='validation-error'> </p>
                  </div>
                </div>
                <div className='form-row  '>
                  <div className='form-group col-md-10'></div>
                  <div className='form-group col-md-2'>
                    <IPButton
                      type='button'
                      className='col-md-3 btn btn-primary'
                      text={t(
                        'pages.admin.productOperation.surprisePriceIdentification.surprisePriceIdentificationAdd.save'
                      )}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SurprisePriceIdentificationAdd
