import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IHistory } from '../../../ProductOperation/CampaignBundleCrud/services/InsertFcvService'

export class PaymentTermHistoryByIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/paymentTermHistoryById',
      requesterInfo,
    })
  }

  public async paymentTermHistoryById(Id: number): Promise<IHistory[]> {
    this.props.url = `${this.props.url}/${Id}`
    return await this.getAsync<IHistory[]>()
  }
}
