import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IAddOpportunity } from '../models/requestModel'
import { CommonPostResponse } from '../../../components/models/models'

export class PostAddOpportunityListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/opportunitMarket/postList',
      requesterInfo,
    })
  }

  public async postOpportunityList(request: IAddOpportunity[]): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
