import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { AddAddressRequest, IAddressModel } from '../../../MyCard/models/models'

export class UpdateAddressApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'address/update',
      requesterInfo,
    })
  }

  public async putAddress(request: AddAddressRequest): Promise<IAddressModel> {
    return await this.postAsync<IAddressModel>(request)
  }
}
