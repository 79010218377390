import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import Cookies from 'universal-cookie'

export interface SearchHistoryState {
  searchHistoryKeywords: string[]
}

const initialState: SearchHistoryState = {
  searchHistoryKeywords: [],
}

export const searchHistorySlice = createSlice({
  name: 'searchHistory',
  initialState,
  reducers: {
    setSearchHistory: (state, action: PayloadAction<string>) => {
      const cookies = new Cookies()
      const searchKeywordsHistoryCookie = cookies.get('searchKeywordsHistory')

      if (!action.payload) {
        state.searchHistoryKeywords = searchKeywordsHistoryCookie

        return
      }

      if (!searchKeywordsHistoryCookie) {
        cookies.set('searchKeywordsHistory', [action.payload], { path: '/' })
        state.searchHistoryKeywords = [action.payload]
      } else {
        if (
          searchKeywordsHistoryCookie &&
          searchKeywordsHistoryCookie.length === 1 &&
          searchKeywordsHistoryCookie[0] !== action.payload
        ) {
          cookies.set('searchKeywordsHistory', [action.payload, ...searchKeywordsHistoryCookie], { path: '/' })
          state.searchHistoryKeywords = [action.payload, ...searchKeywordsHistoryCookie]
        } else if (searchKeywordsHistoryCookie && searchKeywordsHistoryCookie.length > 1) {
          const foundedIdx = searchKeywordsHistoryCookie.indexOf(action.payload)

          if (foundedIdx > -1) {
            searchKeywordsHistoryCookie.splice(foundedIdx, 1)
            cookies.set('searchKeywordsHistory', [action.payload, ...searchKeywordsHistoryCookie], { path: '/' })
            state.searchHistoryKeywords = [action.payload, ...searchKeywordsHistoryCookie]

            return
          }

          if (searchKeywordsHistoryCookie && searchKeywordsHistoryCookie.length > 4) {
            searchKeywordsHistoryCookie.splice(searchKeywordsHistoryCookie.length - 1, 1)
            cookies.set('searchKeywordsHistory', [action.payload, ...searchKeywordsHistoryCookie], { path: '/' })
            state.searchHistoryKeywords = [action.payload, ...searchKeywordsHistoryCookie]

            return
          }

          cookies.set('searchKeywordsHistory', [action.payload, ...searchKeywordsHistoryCookie], { path: '/' })
          state.searchHistoryKeywords = [action.payload, ...searchKeywordsHistoryCookie]
        }
      }
    },
  },
})

export const { setSearchHistory } = searchHistorySlice.actions

export const searchHistoryKeywordsReducer = (state: RootState) => state.searchHistory.searchHistoryKeywords

export default searchHistorySlice.reducer
