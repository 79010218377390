import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IHistory } from '../models/models'

export class GetXmlDemandHistoryApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/getXmlDemandHistory',
      requesterInfo,
    })
  }

  public async getXmlDemandHistory(): Promise<IHistory[]> {
    return await this.getAsync<IHistory[]>()
  }
}
