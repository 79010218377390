import { Link, useLocation, useNavigate } from "react-router-dom"
import { BaseComponentWithContextProps, componentWithContext } from "../../../../../base/customHooks/componentHOC"
import IPButton from "../../../../../components/IPButton"
import IPInput from "../../../../../components/IPInput"
import { useCustomReducer } from "../../../../../base/customHooks"
import { IAddData, IDeleteData, IItem, IItemsAdd, ITableData } from "../models/models"
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { uuidv4 } from "../../../../../base/utils"
import IPCheckbox from "../../../../../components/IPCheckBox"
import classes from '../../../../../components/IPFile/IPFile.module.scss'
import { getUploadFileRequest } from "../../../../../base/utils/dataHelper"
import Swal from "sweetalert2"
import { DeleteDppManagementPageApi } from "../services/deleteDppManagementPageApi"
import { SaveUpdateDppManagementPageApi } from "../services/saveUpdateDppManagementPageApi"

interface IListDppAndMicrosoftState {
  pageName: string
  pageId: number
  fieldName: string
  fieldId: number
}

function Add(props: BaseComponentWithContextProps) {
  const getInitialState = (): IListDppAndMicrosoftState => {
    return {
      pageName: "",
      pageId: 0,
      fieldName: "",
      fieldId: 0,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListDppAndMicrosoftState>(intialState)
  const [dppAndMicrosoft, setDppAndMicrosoft] = useState<IItemsAdd[]>([])
  const routeLocation = useLocation()
  useEffect(() => {
    if (routeLocation.state) {
      const data: ITableData = _.clone((routeLocation.state as any)?.dppAndMicrosoft)
      setState({
        pageName: data.Page,
        pageId: data.IdPage,
        fieldName: data.Field,
        fieldId: data.FieldId
      })
      const dt: IItemsAdd[] = []
      data.Items.map((item) => {
        return dt.push({
          Deleted: item.Deleted,
          Id: item.Id,
          StateId: String(item.Id),
          Image: item.Image,
          DetailImage: item.DetailImage,
          IsTitle: item.IsTitle,
          Link: item.Link,
          Location: item.Location,
          Name: item.Name,
          OrderNo: item.OrderNo,
          IsLink: item.IsLink,
          File: {
            Label: "",
            Content: "",
            Extension: "",
            FileType: "",
          },
          DetailFile: {
            Label: "",
            Content: "",
            Extension: "",
            FileType: "",
          },
          FileDoc: {
            Label: "",
            Content: "",
            Extension: "",
            FileType: "",
          }
        })
      })
      if (dt.length === 0) {
        dt.push({
          Deleted: false,
          Id: 0,
          StateId: uuidv4(),
          Image: "",
          DetailImage: "",
          IsTitle: false,
          Link: "",
          Location: 0,
          Name: "",
          OrderNo: 0,
          IsLink: false,
          File: {
            Label: "",
            Content: "",
            Extension: "",
            FileType: "",
          },
          DetailFile: {
            Label: "",
            Content: "",
            Extension: "",
            FileType: "",
          },
          FileDoc: {
            Label: "",
            Content: "",
            Extension: "",
            FileType: "",
          },
        })
      }
      setDppAndMicrosoft(dt)
    }
  }, [routeLocation.state])

  const getDppAndMicrosoft = async (datas: IItemsAdd, type: string, value: any) => {
    const dataDpp: IItemsAdd[] = []
    dppAndMicrosoft.map((dpp) => {
      if (dpp.StateId === datas.StateId) {
        return dataDpp.push({
          Deleted: datas.Deleted,
          Id: datas.Id,
          StateId: datas.StateId,
          Image: datas.Image,
          DetailImage: datas.DetailImage,
          IsTitle: type === "IsTitle" ? value : datas.IsTitle,
          Link: type === "Link" ? value : datas.Link,
          Location: datas.Location,
          Name: type === "Name" ? value : datas.Name,
          OrderNo: type === "OrderNo" ? value : datas.OrderNo,
          File: datas.File,
          DetailFile: datas.DetailFile,
          FileDoc: datas.FileDoc,
          IsLink: type === "IsLink" ? value : datas.IsLink,
        })
      }
      dataDpp.push(dpp)
    })
    setDppAndMicrosoft(dataDpp)
  }

  const handleFiles = async (e: any, datas: string, type: string) => {
    const fileList: any[] = await getUploadFileRequest(e)
    if (fileList && fileList.length > 0) {
      setDppAndMicrosoft(prevState => {
        return prevState.map((prev: any) => {
          if (prev.StateId === datas) {
            const prevData = {
              Deleted: prev.Deleted,
              Id: prev.Id,
              Image: type === "Image" ? "" : prev.Image,
              DetailImage: type === "DetailImage" ? "" : prev.DetailImage,
              IsTitle: prev.IsTitle,
              IsLink: prev.IsLink,
              Link: type === "Document" ? "" : prev.Link,
              Location: prev.Location,
              Name: prev.Name,
              OrderNo: prev.OrderNo,
              File: type === "Image" ? fileList[0] : {
                Label: prev.File.Label,
                Content: prev.File.Content,
                Extension: prev.File.Extension,
                FileType: prev.File.FileType
              },
              DetailFile: type === "DetailImage" ? fileList[0] : {
                Label: prev.DetailFile.Label,
                Content: prev.DetailFile.Content,
                Extension: prev.DetailFile.Extension,
                FileType: prev.DetailFile.FileType
              },
              FileDoc: type === "Document" ? fileList[0] : {
                Label: prev.FileDoc.Label,
                Content: prev.FileDoc.Content,
                Extension: prev.FileDoc.Extension,
                FileType: prev.FileDoc.FileType
              },
              StateId: prev.StateId
            }
            return prevData
          }
          return prev
        })
      })
    }
  }

  const removeDppItem = async (stateId: string, id: number) => {
    if (id > 0) {
      Swal.fire({
        icon: 'question',
        title: 'Silmek istediğinize emin misiniz?',
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) {
          props.showLoading();
          const req: IDeleteData = {
            PageType: state.pageId,
            ItemId: id
          }
          const deleteDppManagementPageApi: DeleteDppManagementPageApi = new DeleteDppManagementPageApi({})
          deleteDppManagementPageApi
            .deleteDppManagementPage(req)
            .then(async result => {
              if (result) {
                Swal.fire({
                  icon: 'success',
                  title: 'Silindi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                }).then(async () => {
                  const dpp = dppAndMicrosoft.filter((mic) => { return mic.Id !== id })
                  setDppAndMicrosoft(dpp)
                })
              }
              props.hideLoading();
            })
            .catch((err: any) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              props.hideLoading();
            })
        }
      })
      return;
    }
    let datas: IItemsAdd[] = []
    if (dppAndMicrosoft.length > 1) {
      datas = dppAndMicrosoft.filter((mic) => { return mic.StateId !== stateId })
    }
    setDppAndMicrosoft(datas)
  }

  const addDppItem = async (id: string) => {
    const dt: IItemsAdd[] = [];
    if (id === "" && dppAndMicrosoft.length === 0) {
      dt.push({
        Deleted: false,
        Id: 0,
        StateId: uuidv4(),
        IsLink: false,
        Image: "",
        DetailImage: "",
        IsTitle: false,
        Link: "",
        Location: 0,
        Name: "",
        OrderNo: 0,
        File: {
          Label: "",
          Content: "",
          Extension: "",
          FileType: "",
        },
        FileDoc: {
          Label: "",
          Content: "",
          Extension: "",
          FileType: "",
        },
        DetailFile: {
          Label: "",
          Content: "",
          Extension: "",
          FileType: "",
        },
      })
    }
    dppAndMicrosoft.map((dpp) => {
      dt.push(dpp)
      if (dpp.StateId === id) {
        dt.push({
          Deleted: false,
          Id: 0,
          StateId: uuidv4(),
          IsLink: false,
          Image: "",
          DetailImage: "",
          IsTitle: false,
          Link: "",
          Location: 0,
          Name: "",
          OrderNo: 0,
          File: {
            Label: "",
            Content: "",
            Extension: "",
            FileType: "",
          },
          FileDoc: {
            Label: "",
            Content: "",
            Extension: "",
            FileType: "",
          },
          DetailFile: {
            Label: "",
            Content: "",
            Extension: "",
            FileType: "",
          },
        })
      }
    })
    setDppAndMicrosoft(dt)
  }

  const copyDppItem = async (id: string) => {
    let datas = dppAndMicrosoft.filter((mic) => { return mic.StateId === id }).pop()
    if (datas) {
      const dt: IItemsAdd[] = [];
      dppAndMicrosoft.map((dpp) => {
        dt.push(dpp)
        if (dpp.StateId === id) {
          dt.push({
            Deleted: false,
            Id: 0,
            StateId: uuidv4(),
            Image: datas ? datas.Image : "",
            DetailImage: datas ? datas.DetailImage : "",
            IsTitle: false,
            Link: datas ? datas.Link : "",
            Location: datas ? datas.Location : 0,
            Name: datas ? datas.Name : "",
            OrderNo: datas ? datas.OrderNo : 0,
            IsLink: false,
            File: datas ? datas.File : {
              Label: "",
              Content: "",
              Extension: "",
              FileType: "",
            },
            FileDoc: datas ? datas.FileDoc : {
              Label: "",
              Content: "",
              Extension: "",
              FileType: "",
            },
            DetailFile: datas ? datas.DetailFile : {
              Label: "",
              Content: "",
              Extension: "",
              FileType: "",
            },
          })
        }
      })
      setDppAndMicrosoft(dt)
    }
  }
  let navigate = useNavigate()
  const addDppAndMicrosoft = async () => {

    const items: IItem[] = [];
    let i = 0;
    dppAndMicrosoft.map((dpp) => {
      if (state.pageId === 1 && dpp.IsTitle && (state.pageId === 1 && (state.fieldId === 2 || state.fieldId === 3 || state.fieldId === 4)))
        i++
      return items.push({
        Id: dpp.Id,
        Location: state.fieldId,
        IsTitle: dpp.IsTitle ? dpp.IsTitle : false,
        Name: dpp.Name ? dpp.Name : "",
        Link: (dpp.FileDoc && dpp.FileDoc.Content) ? "" : dpp.Link,
        Image: (dpp.File && dpp.File.Content) ? "" : dpp.Image,
        DetailImage: (dpp.DetailFile && dpp.DetailFile.Content) ? "" : dpp.DetailImage,
        OrderNo: dpp.OrderNo,
        Deleted: dpp.Deleted,
        File: dpp.File,
        DetailFile: dpp.DetailFile,
        FileDoc: dpp.FileDoc,
        IsLink: dpp.IsLink
      })
    })
    if (i > 1) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen sadece 1 tane başlık seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading();
    const req: IAddData = {
      PageType: state.pageId,
      Items: items
    }
    const saveUpdateDppManagementPageApi: SaveUpdateDppManagementPageApi = new SaveUpdateDppManagementPageApi({})

    saveUpdateDppManagementPageApi
      .saveUpdateDppManagementPage(req)
      .then((res: any) => {
        props.hideLoading();
        if (res) {
          Swal.fire({
            icon: 'success',
            title: `${state.pageName + " " + state.fieldName} güncellenmiştir.`,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async () => {
            navigate('/admin/homeAndSiteOperation/dppAndMicrosoftPagesList')
          })
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>DPP ve Microsoft Sayfaları Ekleme</span>
            <Link to='/admin/homeAndSiteOperation/dppAndMicrosoftPagesList'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Listeye Dön'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='card-body'>
            <form id='id1' className='formform' noValidate>
              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <label className='labeltext'>Sayfa</label>
                  <IPInput
                    value={state.pageName}
                    type='text'
                    disabled={true}
                  />
                </div>
                <div className={'form-group col-md-6'}>
                  <label className='labeltext'>Bölüm</label>
                  <IPInput
                    value={state.fieldName}
                    type='text'
                    disabled={true}
                  />
                </div>
              </div>
              {
                (dppAndMicrosoft && dppAndMicrosoft.length > 0) ? dppAndMicrosoft.map((dpp, index) => (
                  <div key={index}>
                    <br />
                    <div className='row m-t-5'>
                      {
                        state.pageId !== 3 &&
                        <div className='col-md-2'>
                          <IPInput
                            name='Name'
                            type='text'
                            placeholder='Ad'
                            value={dpp.Name}
                            onChange={(e) => getDppAndMicrosoft(dpp, "Name", e.target.value)}
                          />
                        </div>
                      }
                      <div className='col-md-2'>
                        <IPInput
                          // disabled={dpp.FileDoc.Label.length > 0 ? true : false}
                          name='Link'
                          type='text'
                          placeholder='Link'
                          value={
                            dpp.FileDoc.Label.length > 0 ?
                              dpp.FileDoc.Label : dpp.Link
                          }
                          onChange={(e) => getDppAndMicrosoft(dpp, "Link", e.target.value)}
                        />
                      </div>
                      <div className='col-md-2'>
                        <IPInput
                          name='OrderNo'
                          type='text'
                          placeholder='Sıra'
                          value={dpp.OrderNo}
                          onChange={(e) => getDppAndMicrosoft(dpp, "OrderNo", e.target.value)}
                        />
                      </div>
                      {
                        (state.pageId === 1 && (state.fieldId === 2 || state.fieldId === 3 || state.fieldId === 4)) &&
                        <div className='col-md-2'>
                          <IPCheckbox
                            name={`isTitle${index}`}
                            label='Başlık'
                            onChange={(e) => getDppAndMicrosoft(dpp, "IsTitle", !dpp.IsTitle)}
                            checked={dpp.IsTitle}
                          />
                        </div>
                      }
                      {
                        ((state.pageId === 1 && state.fieldId === 1)
                          || (state.pageId === 1 && (state.fieldId === 2 || state.fieldId === 3 || state.fieldId === 4) && dpp.IsTitle)
                          || (state.pageId === 2 && (state.fieldId === 1 || state.fieldId === 2 || state.fieldId === 3 || state.fieldId === 4))
                          || state.pageId === 3
                          || (state.pageId === 4 && (state.fieldId === 1 || state.fieldId === 3))
                        ) &&
                        <div className='col-md-2'>
                          <div className={classes.file}>
                            <input
                              type='file'
                              onInput={(e) => {
                                handleFiles(e, dpp.StateId, "Image")
                              }}
                              multiple={false}
                              id={`ImageFile${index}`}
                            />
                            <label htmlFor={`ImageFile${index}`}>
                              Resim Seç
                            </label>

                          </div>
                          {
                            dpp.File.Label.length > 0 ? <p>{dpp.File.Label}</p> : <p>{dpp.Image}</p>
                          }
                        </div>
                      }
                      <div className='col-md-2'>
                        <IPCheckbox
                          label="Link"
                          checked={dpp.IsLink}
                          name={`isLink${index}`}
                          onChange={(e) => getDppAndMicrosoft(dpp, "IsLink", !dpp.IsLink)}
                        />
                        <p style={{ color: "red" }}>
                          * Link oluşturduysanız işaretleyiniz.
                        </p>
                      </div>
                      {
                        (
                          (state.pageId === 2 && (state.fieldId === 2 || state.fieldId === 3 || state.fieldId === 4 || state.fieldId === 6))
                          || (state.pageId === 4 && state.fieldId !== 1)
                          || state.pageId === 3
                        ) &&
                        <div>


                          <div className='col-md-2'>
                            <div className={classes.file}>
                              <input
                                type='file'
                                onInput={(e) => handleFiles(e, dpp.StateId, "Document")}
                                multiple={false}
                                id={`DocumentFile${index}`}
                              />
                              <label htmlFor={`DocumentFile${index}`}>
                                Dosya Seç
                              </label>
                            </div>
                            <p>
                              {
                                dpp.FileDoc.Label.length > 0 &&
                                <p>{dpp.FileDoc.Label}</p>
                              }
                            </p>
                          </div>
                        </div>
                      }
                      {
                        (
                          (state.pageId === 4 && (state.fieldId === 1))
                        ) &&
                        <div className='col-md-2'>
                          <div className={classes.file}>
                            <input
                              type='file'
                              onInput={(e) => {
                                handleFiles(e, dpp.StateId, "DetailImage")
                              }}
                              multiple={false}
                              id={`DetailImageFile${index}`}
                            />
                            <label htmlFor={`DetailImageFile${index}`}>
                              Detay Resim Seç
                            </label>

                          </div>
                          {
                            dpp.DetailFile && dpp.DetailFile?.Label?.length > 0 ? <p>{dpp.DetailFile?.Label}</p> : <p>{dpp.DetailImage}</p>
                          }
                        </div>
                      }

                    </div>
                    {/* {
                      (
                        (state.pageId === 2 && (state.fieldId === 2 || state.fieldId === 3 || state.fieldId === 4 || state.fieldId === 6))
                        || (state.pageId === 4 && state.fieldId === 2)
                        || state.pageId === 3
                      ) &&
                      <div className="row">
                        <p style={{ color: "red" }}><b>Not: Yüklenen dosya linki link kutucuğuna gelmektedir.</b></p>
                      </div>
                    } */}
                    <div className='row'>
                      <div className='col-3'>
                        <label className='labeltext'></label>
                        <IPButton
                          onClick={e => {
                            removeDppItem(dpp.StateId, dpp.Id)
                          }}
                          type='button'
                          className='btn btn-danger m-t-5'
                          text='Sil'
                        ></IPButton>
                      </div>
                      <div className='col-3' style={{ marginLeft: '20px' }}>
                        <label className='labeltext'></label>
                        <IPButton
                          onClick={e => {
                            addDppItem(dpp.StateId)
                          }}
                          type='button'
                          className='btn btn-success m-t-5'
                          text='Ekle'
                        ></IPButton>
                      </div>
                      <div className='col-3' style={{ marginLeft: '20px' }}>
                        <label className='labeltext'></label>
                        <IPButton
                          onClick={e => {
                            copyDppItem(dpp.StateId)
                          }}
                          type='button'
                          className='btn btn-success m-t-5'
                          text='Kopyala'
                        ></IPButton>
                      </div>
                    </div>
                    {
                      index < dppAndMicrosoft.length - 1 &&
                      <hr />
                    }

                  </div>
                )) :
                  <div className='col-3' style={{ marginLeft: '20px' }}>
                    <label className='labeltext'></label>
                    <IPButton
                      onClick={e => {
                        addDppItem("")
                      }}
                      type='button'
                      className='btn btn-success m-t-5'
                      text='Ekle'
                    ></IPButton>
                  </div>
              }
              <div className='form-row mt-4' style={{ float: 'right' }}>
                <IPButton
                  text="Güncelle"
                  type='button'
                  className='btn btn-success w-25 float-right'
                  onClick={addDppAndMicrosoft}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(Add)