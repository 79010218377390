import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IGetProductRequest } from '../models/requestModels'
import { IGetProductResponse } from '../models/responseModel'

export class GetProductApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/list',
      requesterInfo,
    })
  }
  public async getProduct(request: IGetProductRequest): Promise<IGetProductResponse> {
    return await this.postAsync<IGetProductResponse>(request)
  }
}
