import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IResetPasswordRequest } from '../models/models'

export class ResetPasswordApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'user/resetPassword',
      requesterInfo,
    })
  }

  public async resetPassword(request: IResetPasswordRequest): Promise<boolean> {
    return await this.postAsync<boolean>(request)
  }

  public async chkToken(request: any): Promise<boolean> {
    this.props.url = 'user/chkToken'
    return await this.postAsync<boolean>(request)
  }
}
