import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { GetImeiRequest, IImei } from '../models'

export class GetImeiSeriNoApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetImeiSeriNo',
      requesterInfo,
    })
  }

  public async getImeiSeriNo(request: GetImeiRequest): Promise<IImei[]> {
    return await this.postAsync<IImei[]>(request)
  }
}
