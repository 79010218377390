import React, { useState } from 'react'

import Input from '../../../../components/IPInput/IPInput'
import Button from '../../../../components/IPButton/IPButton'
import classes from '../styles/style.module.scss'
import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import { ProductDetailMail } from '../../services/productDetailMail'
import { ProductDetailMailRequest } from '../../models/productDetailMailRequest'
import Swal from 'sweetalert2'
import IPCheckbox from '../../../../components/IPCheckBox'
import { Spinner } from '../../../../components/Spinner'

export default function Feedback(props) {
  const formState: ProductDetailMailRequest = {
    Id: 0,
    ProductId: props.id,
    ProductPrice: '',
    DescError: '',
    GeneralThought: '',
    TargetPrice: '',
    Quantity: '',
    IsProductDesc: false,
    IsProductPrice: false,
    IsCampaign: false,
    IsDescError: false,
  }
  const [form, setForm] = useState(formState)
  const [loading, setLoading] = useState(false)

  function updateForm(prop: string, val: string | number | boolean) {
    setForm(prev => ({ ...prev, [prop]: val }))
  }

  const t = translate(Translations)

  const handleSubmit = async () => {
    setLoading(true)
    try {
      const res = await new ProductDetailMail({}).insert(form)
      if (res) {
        Swal.fire('Başarılı', 'Geri bildirim başarıyla gönderilmiştir.', 'success').then(() => {
          setForm(formState)
        })
        setLoading(false)
      }
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      setLoading(false)
    }
  }

  return (
    <>
      <Spinner loading={loading} />
      <div className='container mx-3 m-b-20'>
        <div className='row'>
          <div className='col-12'>
            <p className='mt-2 mb-4' style={{ fontSize: 21, color: '#616161' }}>
              {t('pages.productdetail.ShareYourCommentsWithUs')}
            </p>
            <div className='row'>
              <div className='col-12 mb-3'>
                <IPCheckbox
                  id='1'
                  label={t('pages.productdetail.ProductDescriptionAndFeaturesAreSufficient')}
                  onCheckedChange={checked => updateForm('IsProductDesc', checked)}
                  checked={form.IsProductDesc}
                />
              </div>

              <div className='col-12 mb-3'>
                <div className='row'>
                  <div className='col-12 col-md-4'>
                    <IPCheckbox
                      id='2'
                      label={t('pages.productdetail.TheProductPriceIsHigherThanTheMarket')}
                      onCheckedChange={checked => {
                        setForm(prev => ({ ...prev, IsProductPrice: checked, ProductPrice: '' }))
                      }}
                      checked={form.IsProductPrice}
                    />
                  </div>
                  <div className='col-12 col-md-6'>
                    <Input
                      type='text'
                      value={form.ProductPrice}
                      onChange={e => updateForm('ProductPrice', e.target.value)}
                      style={{ height: 35, minHeight: 35 }}
                      disabled={form.IsProductPrice === false}
                    />
                  </div>
                </div>
              </div>

              <div className='col-12 mb-3'>
                <div className='row'>
                  <div className='col-12 col-md-4'>
                    <div className='d-flex h-100'>
                      <div className='mt-auto'>
                        <IPCheckbox
                          id='3'
                          label={t('pages.productdetail.CampaignsCanBeMadeSpecificToTheProduct')}
                          onCheckedChange={checked => {
                            setForm(prev => ({ ...prev, IsCampaign: checked, TargetPrice: '', Quantity: '' }))
                          }}
                          checked={form.IsCampaign}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='col-12 col-md-6'>
                    <div className='row'>
                      <div className={`col-6`}>
                        <Input
                          type='text'
                          value={form.TargetPrice}
                          onChange={e => updateForm('TargetPrice', e.target.value)}
                          style={{ height: 35, minHeight: 35 }}
                          placeholder={t('pages.productdetail.TargetPrice')}
                          disabled={form.IsCampaign === false}
                        />
                      </div>
                      <div className={`col-6`}>
                        <Input
                          type='text'
                          value={form.Quantity}
                          onChange={e => updateForm('Quantity', e.target.value)}
                          style={{ height: 35, minHeight: 35 }}
                          placeholder='Adet'
                          disabled={form.IsCampaign === false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12 col-md-4'>
                <IPCheckbox
                  id='4'
                  label={t('pages.productdetail.ProductDescriptionAndFeaturesAreWrong')}
                  onCheckedChange={checked => {
                    setForm(prev => ({ ...prev, IsDescError: checked, DescError: '' }))
                  }}
                  checked={form.IsDescError}
                />
              </div>
              <div className='col-12 col-md-6'>
                <Input
                  type='text'
                  value={form.DescError}
                  onChange={e => updateForm('DescError', e.target.value)}
                  style={{ height: 35, minHeight: 35 }}
                  disabled={form.IsDescError === false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <p style={{ fontSize: 21, color: '#616161' }}>{t('pages.productdetail.youCanShareItWithUs')}</p>
          <div className='col-9 col-lg-9 '>
            <textarea
              name='GeneralThought'
              id='GeneralThought'
              rows={8}
              className='p-10'
              value={form.GeneralThought}
              onChange={e => updateForm('GeneralThought', e.target.value)}
            />
            <div className='d-flex justify-content-end'>
              <Button text='Gönder' className='w-25' onClick={handleSubmit}></Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
