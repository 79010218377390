import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IBasketDetail } from '../../../redux/slices/basket/basketApi'

export class DeleteBasketApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/deleteBasket',
      requesterInfo,
    })
  }

  public async deleteBasket(): Promise<IBasketDetail> {
    return await this.deleteAsync<IBasketDetail>()
  }
}
