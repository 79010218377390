import axios from 'axios'
import { ConfigHelper } from '../constants'
import { IAuthenticationResponse, IAuthRequest } from '../models/proxyModels'
import { ClientStorage } from '../storage'
import { handleException } from '../utils'
import { ServiceRouter } from './serviceRouter'
import Swal from 'sweetalert2'
import { clearLoginStorage, encrypt } from '../utils/proxyUtils'
import { getRefreshToken, getToken, getUserId, isRefreshTime } from './helper'

function getAuthenticationRequest(accountCode: string, userName: string, password: string): IAuthRequest {
  const authenticationRequest: IAuthRequest = {
    AccountCode: accountCode,
    UserName: userName,
    Password: password,
  }

  return authenticationRequest
}

export function isLoggedIn() {
  return !!ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_TOKEN)
}

export async function authenticate(accountCode: string, username: string, password: string): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      // if (userName == "123vbt" && password == "12345678" && taxCode == "vbt") {
      //   ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_TOKEN, "test-token");

      //   return resolve(true);
      // } else return resolve(false);

      delete axios.defaults.headers.common.Authorization
      axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8'
      axios.defaults.headers.common['UserId'] = '1'
      axios.defaults.headers.common['IsMobile'] = 'false'

      const result = await axios.post<any>(
        ServiceRouter.getAuthenticateUrl(),
        getAuthenticationRequest(accountCode, username, encrypt(password))
      )

      if (result.data.Success) {
        ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_TOKEN, result.data.Data.Token)
        ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_REFRESH_TOKEN, result.data.Data.RefreshToken)
        ClientStorage.setItem(
          ConfigHelper.INDEXPAZAR_USER_TOKEN_CREATE_TIME,
          result.data.Data.CreatedTokenTime.toString()
        )
        ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_DATA, JSON.stringify(result.data.Data))
        ClientStorage.setItem('IsApple', result.data.Data?.IsApple === true ? 1 : 0)

        return resolve(true)
      }
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: result.data.Message,
        showConfirmButton: true,
        allowOutsideClick: true,
        backdrop: 'rgba(255,255,255,.4)',
        // timer: 1500
      })
      return resolve(false)
    } catch (error) {
      reject(handleException(error))
    }
  })
}

export async function logoutUser(): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      delete axios.defaults.headers.common.Authorization
      axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8'
      axios.defaults.headers.common['UserId'] = '1'
      axios.defaults.headers.common['IsMobile'] = 'false'

      axios.defaults.headers['Content-Type'] = 'application/json; charset=utf-8'
      axios.defaults.headers['IsMobile'] = 'false'
      axios.defaults.headers['UserId'] = getUserId()
      axios.defaults.headers.common.Authorization = `Bearer ${getToken()}`

      if (isRefreshTime()) {
        axios.defaults.headers['RefreshToken'] = getRefreshToken()
      }

      const result = await axios.post<any>(ServiceRouter.getLogoutUrl())

      if (result.data.Success) {
        resolve()

        redirectUserToLogin()
      }

      reject()
    } catch (error) {
      // reject(handleException(error));
      reject()
    }
  })
}

export function redirectUserToLogin() {
  clearLoginStorage()

  window.location.href = '/'
}
