import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { CreditCardModel } from '../../MyCreditCards/models/models'

export class AddCreditCardApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/addCreditCard',
      requesterInfo,
    })
  }

  public async addCard(req: any): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
