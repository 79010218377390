import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { ISurvey } from '../models'

export class AddPollAdminApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/survey/AddSurvey',
      requesterInfo,
    })
  }

  public async addSurvey(request: ISurvey): Promise<boolean> {
    return await this.postAsync<boolean>(request)
  }
}
