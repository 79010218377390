import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IDealerInfo } from '../models/models'

export class GetDealerInfoApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealerInformation/dealerInfo',
      requesterInfo,
    })
  }

  public async getDealerInfo(): Promise<IDealerInfo[]> {
    return await this.getAsync<IDealerInfo[]>()
  }
}
