import React, { useEffect } from 'react'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { PostProductPricesReportApi } from '../services/postProductPricesReportApi'
import Swal from 'sweetalert2'
import { IPriceCheckListRequest } from '../models/models'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPButton from '../../../../../components/IPButton'
import { useCustomReducer } from '../../../../../base/customHooks'
import { css } from '@emotion/css'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { downloadFileFromUrl } from '../../../../../base/utils/dataHelper'
import { ExcelToProductPricesReportApi } from '../services/excelToProductPricesReportApi'
import { CampaignBundleService } from '../../CampaignBundleCrud/services/CampaignBundleService'
interface IPriceCheckListState {
  priceCheckList: any[]
  ProductCode?: ISelectOption | undefined
  ProductName: string
  MudCode?: ISelectOption | undefined
  Quota?: ISelectOption
  Brand?: ISelectOption
  productCodeOptions: ISelectOption[]
  mudCodeOptions: ISelectOption[]
  quotaCodeOptions: ISelectOption[]
  brandListOptions: ISelectOption[]
  companyListOptions: ISelectOption[]
  companyListOption: any
}

function PriceChecklist(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  useEffect(() => {
    //postProductPricesReport()
    getProductCode()
    getMudCode()
    getBrandList()
  }, [])
  const getInitialState = (): IPriceCheckListState => {
    return {
      priceCheckList: [],
      ProductName: '',
      mudCodeOptions: [],
      productCodeOptions: [],
      quotaCodeOptions: [],
      brandListOptions: [],
      companyListOption: null,
      companyListOptions: [
        { label: 'Index', value: 1 },
        { label: 'Netex', value: 2 },
        { label: 'Despec', value: 3 },
        { label: 'Datagate', value: 4 },
      ],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IPriceCheckListState>(intialState)

  // const postProductPricesReport = async () => {
  //   const req: IPriceCheckListRequest = {
  //     ProductCode: state.ProductCode ? String(state.ProductCode.value) : '',
  //     ProductName: state.ProductName,
  //     MudCode: state.MudCode ? String(state.MudCode.value) : '',
  //     Quota: state.Quota ? String(state.Quota.value) : '',
  //     Brand: state.Brand ? String(state.Brand.value) : '',
  //     Company: state.companyListOption ? String(state.companyListOption.value) : '',
  //   }
  //   props.showLoading()
  //   const postProductPricesReportApi: PostProductPricesReportApi = new PostProductPricesReportApi({})
  //   await postProductPricesReportApi
  //     .postProductPricesReport(req)
  //     .then(async result => {
  //       if (result) {
  //         props.hideLoading()
  //         setState({ priceCheckList: result })
  //       }
  //     })
  //     .catch((err: any) => {
  //       Swal.fire({
  //         icon: 'error',
  //         title: err.description,
  //         showConfirmButton: true,
  //         allowOutsideClick: false,
  //       })
  //       props.hideLoading()
  //     })
  // }
  const postProductPricesReport = async () => {
    try {
      const req: IPriceCheckListRequest = {
        ProductCode: state.ProductCode ? String(state.ProductCode.value) : '',
        ProductName: state.ProductName,
        MudCode: state.MudCode ? String(state.MudCode.value) : '',
        Quota: state.Quota ? String(state.Quota.value) : '',
        Brand: state.Brand ? String(state.Brand.value) : '',
        Company: state.companyListOption ? String(state.companyListOption.value) : '',
      }

      props.showLoading()

      const postProductPricesReportApi: PostProductPricesReportApi = new PostProductPricesReportApi({})
      const result = await postProductPricesReportApi.postProductPricesReport(req)

      if (result) {
        setState({ priceCheckList: result })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    } finally {
      props.hideLoading()
    }
  }

  const excelToProductPricesReport = async () => {
    const req: IPriceCheckListRequest = {
      ProductCode: state.ProductCode ? String(state.ProductCode.value) : '',
      ProductName: state.ProductName,
      MudCode: state.MudCode ? String(state.MudCode.value) : '',
      Quota: state.Quota ? String(state.Quota.value) : '',
      Brand: state.Brand ? String(state.Brand.value) : '',
      Company: state.companyListOption ? String(state.companyListOption.value) : '',
    }
    props.showLoading()
    const excelToProductPricesReportApi: ExcelToProductPricesReportApi = new ExcelToProductPricesReportApi({})
    await excelToProductPricesReportApi
      .excelToProductPricesReport(req)
      .then(async result => {
        if (result) {
          props.hideLoading()
          downloadFileFromUrl('Fiyat Kontrol Listesi', result.FileUrl)
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getMudCode = async () => {
    try {
      props.showLoading()

      const postProductPricesReportApi = new PostProductPricesReportApi({})
      const res = await postProductPricesReportApi.getMudCode()

      if (res) {
        let temp = res.map(a => ({
          label: a,
          value: a,
        }))

        setState({
          mudCodeOptions: temp,
        })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    } finally {
      props.hideLoading()
    }
  }

  const getProductCode = async () => {
    try {
      props.showLoading()

      const campaignBundleService = new CampaignBundleService({})
      const res = await campaignBundleService.getProductCode()

      if (res) {
        let temp = res.Entity.ProductsInfo.map(a => ({
          label: a.ProductCode,
          value: a.ProductCode,
        }))

        setState({
          productCodeOptions: temp,
          quotaCodeOptions: res.Entity.QuotaCode.map(item => ({
            label: item,
            value: item,
          })),
        })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    } finally {
      props.hideLoading()
    }
  }

  const getBrandList = async () => {
    const campaignBundleService = new CampaignBundleService({})
    const res = await campaignBundleService.getBrandList()
    if (res)
      setState({
        brandListOptions: res.Entity.BrandCode.map(item => {
          return { label: item, value: item }
        }),
      })
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <span className='h4'>{t('pages.admin.productOperation.priceChecklist.priceChecklist.header')}</span>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-lg-2' style={{ marginTop: '10px' }}>
            <label className='form-label'>Ürün Müdürü</label>
            <IPSelectBox
              isClearable
              options={state.mudCodeOptions}
              value={state.MudCode}
              onChangeSingle={option => setState({ MudCode: option })}
            />
          </div>
          <div className='col-lg-2' style={{ marginTop: '10px' }}>
            <label className='form-label'>Ürün Kodu</label>
            <IPSelectBox
              isClearable
              options={state.productCodeOptions}
              value={state.ProductCode}
              onChangeSingle={option => setState({ ProductCode: option })}
            />
          </div>
          <div className='col-lg-2' style={{ marginTop: '10px' }}>
            <label className='form-label'>Marka</label>
            <IPSelectBox
              isClearable
              options={state.brandListOptions}
              value={state.Brand}
              onChangeSingle={option => setState({ Brand: option })}
            />
          </div>
          <div className='col-lg-2' style={{ marginTop: '10px' }}>
            <label className='form-label'>Şirket</label>
            <IPSelectBox
              isClearable
              placeholder='Şirket'
              options={state.companyListOptions}
              value={state.companyListOption}
              onChangeSingle={option => {
                setState({ companyListOption: option })
              }}
            />
          </div>
          <div className='col-lg-2' style={{ marginTop: '10px' }}>
            <label className='form-label'>Kota</label>
            <IPSelectBox
              isClearable
              options={state.quotaCodeOptions}
              value={state.Quota}
              onChangeSingle={option => setState({ Quota: option })}
            />
          </div>
          <IPButton
            type='button'
            className='btn btn-danger m-l-10'
            text='Süz'
            onClick={postProductPricesReport}
            style={{ width: '25px', marginRight: '10px', marginTop: '43px' }}
          />
          <IPButton
            type='button'
            className='btn btn-success m-l-10'
            text='Excel e At'
            onClick={excelToProductPricesReport}
            style={{ width: '25px', marginRight: '10px', marginTop: '43px' }}
          />
        </div>
        {state.priceCheckList &&
          state.priceCheckList.length > 0 &&
          state.priceCheckList.map((price, index) => (
            <div className='row' key={index}>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dt-bootstrap4'>
                  <div
                    className={`table-responsive ${css`
                      ::-webkit-scrollbar {
                        height: 6px;
                      }
                    `}`}
                  >
                    <table
                      id='datatable'
                      className='table table-bordered table-hover dataTable'
                      aria-describedby='datatable_info'
                      style={{ marginTop: '20px' }}
                    >
                      <thead>
                        <tr role='row' className='odd'>
                          {price[0].map((prc, i) => (
                            <th key={i} style={{ minWidth: i > 5 ? '150px' : 'auto' }}>
                              {prc.Header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {price.map((prc, i) => {
                          return (
                            <tr role='row' className='odd' key={i}>
                              {prc.map((p, ind) => {
                                return (
                                  <td key={ind} style={{ minWidth: ind > 5 ? '150px' : 'auto' }}>
                                    {p.Value}
                                  </td>
                                )
                              })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default componentWithContext(PriceChecklist)
