import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import Cards, { Focused } from 'react-credit-cards'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { CreditCardModel } from '../models/models'
import { GetMyCreditCardsApi } from '../services/getMyCreditCardsApi'
import IPButton from '../../../../components/IPButton'
import IPModal from '../../../../components/IPModal'
import IPInput from '../../../../components/IPInput'
import { useCustomReducer } from '../../../../base/customHooks'
import classes from '../../../../components/IPFile/IPFile.module.scss'
import { getUploadFileRequest } from '../../../../base/utils/dataHelper'
import { AddCreditCardApi } from '../services/addCreditCardApi'
import IPCheckbox from '../../../../components/IPCheckBox'
import { DeleteCreditCardApi } from '../services/deleteCreditCardApi'
import { ChangeActivityCreditCardApi } from '../services/changeActivityCreditCardApi'
import IPImage from '../../../../components/IPImage'
import styles from './../../../MyCard/MyCard.module.scss'

interface ICardState {
  cardName?: string,
  cardNumber?: string,
  showModal: boolean
  File?: {
    Label: string
    Content: string
    Extension: string
    FileType: string
  }
}

function CreditCards(props: BaseComponentWithContextProps) {
  const getInitialState = (): ICardState => {
    return {
      showModal: false
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ICardState>(intialState)

  const [myCards, setMyCards] = useState<CreditCardModel[]>([])

  const getMyCards = async () => {
    props.showLoading()
    const getLicenseTracking: GetMyCreditCardsApi = new GetMyCreditCardsApi({})
    getLicenseTracking
      .getMyCreditCards()
      .then(result => {
        if (result) {
          setMyCards(result)
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    return
  }

  const saveCard = async () => {
    if (state.cardNumber === undefined || state.cardName === undefined || state.File === undefined) {
      Swal.fire({
        icon: 'error',
        title: 'Bütün alanlar zorunludur!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    const req = {
      CardNumber: state.cardNumber,
      CardName: state.cardName,
      Image: state.File
    }
    props.showLoading()
    const addCardApi: AddCreditCardApi = new AddCreditCardApi({})
    addCardApi
      .addCard(req)
      .then(result => {
        if (result) {
          setState({ showModal: false, cardName: undefined, cardNumber: undefined, File: undefined })
          Swal.fire({
            icon: 'success',
            title: 'Kartınız kaydedilmiştir.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
          getMyCards()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    return
  }

  useEffect(() => {
    getMyCards()
  }, [])


  const handleFiles = async (e: any) => {
    const fileList: any[] = await getUploadFileRequest(e)
    if (fileList && fileList.length > 0) {
      setState({
        File: fileList[0]
      })
    }
  }

  const deleteCard = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Kartınız silmek istediğinizden emin misiniz?.',
      showConfirmButton: true,
      showDenyButton: true,
      allowOutsideClick: false,
      denyButtonText: 'Hayır',
      confirmButtonText: 'Evet'
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const deleteApi: DeleteCreditCardApi = new DeleteCreditCardApi({})
        deleteApi
          .deleteCard(id)
          .then(result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Kartınız silinmiştir.',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              props.hideLoading()
              getMyCards()
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
        return
      }
    })
  }


  const changeCard = async (id: number) => {
    props.showLoading()
    const changeApi: ChangeActivityCreditCardApi = new ChangeActivityCreditCardApi({})
    changeApi
      .changeActivityCard(id)
      .then(result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Kartınızın aktifliği değiştirilmiştir.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
          getMyCards()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    return
  }


  enum bankNames {
    akbank = '46',
    halkbank = '12',
    denizbank = '134',
    Akbank = '46',
    AlbarakaTurk = '203',
    AlternatifBank = '124',
    Anadolubank = '135',
    Denizbank = '134',
    Fibabanka = '103',
    QNBFinansbank = '111',
    GarantiBBVA = '62',
    Halkbank = '12',
    HSBC = '123',
    INGBank = '99',
    IsBankasi = '64',
    KuveytTurk = '205',
    Odeabank = '146',
    TurkEkonomiBankasi = '32',
    TurkiyeFinans = '206',
    Vakifbank = '15',
    YapiKrediBankasi = '67',
    Sekerbank = '59',
    ZiraatBankasi = '10',
    AktifYatirimBankasi = '143',
  }

  const bankImages: { [key: string]: string } = {
    [bankNames.akbank]: '/images/akbank.png',
    [bankNames.halkbank]: '/images/halkbank.png',
    [bankNames.denizbank]: '/images/denizBank.png',
    [bankNames.AlbarakaTurk]: '/images/product-detail/alBaraka.svg',
    [bankNames.AlternatifBank]: '/images/product-detail/alternatifBank.svg',
    [bankNames.Anadolubank]: '/images/product-detail/anadoluBank.svg',
    [bankNames.Fibabanka]: '/images/product-detail/fibabanka.svg',
    [bankNames.QNBFinansbank]: '/images/finansbank.png',
    [bankNames.GarantiBBVA]: '/images/garantiBankasi.png',
    [bankNames.HSBC]: '/images/product-detail/hsbcBank.svg',
    [bankNames.INGBank]: '/images/product-detail/ingBank.svg',
    [bankNames.IsBankasi]: '/images/isBank.png',
    [bankNames.KuveytTurk]: '/images/product-detail/kuveytTurk.svg',
    [bankNames.Odeabank]: '/images/product-detail/odeabank.svg',
    [bankNames.TurkEkonomiBankasi]: '/images/product-detail/teb.svg',
    [bankNames.TurkiyeFinans]: '/images/product-detail/turkiyeFinansBank.svg',
    [bankNames.Vakifbank]: '/images/product-detail/vakifBank.svg',
    [bankNames.YapiKrediBankasi]: '/images/yapiKredi.png',
    [bankNames.Sekerbank]: '/images/product-detail/sekerBank.svg',
    [bankNames.ZiraatBankasi]: '/images/ZiraatBankasi.png',
    [bankNames.AktifYatirimBankasi]: '/images/product-detail/aktifBank.svg',
  };

  const getBankImageUrl = (bankCode: string): string => {
    return bankImages[bankCode] || '';
  }

  return (
    <div>
      <div className='p-4 d-flex justify-content-between align-items-center'>
        <span className='h4 mb-0'>Kredi Kartlarım</span>
        <IPButton
          className='btn btn border-0'
          style={{
            backgroundColor: 'rgb(238, 51, 62)',
            width: '150px',
          }}
          text={'Kart Ekle'}
          onClick={() => {
            setState({ showModal: true })
          }}
        />
      </div>
      <div className='row'>
        {myCards &&
          myCards.map((item) => (
            <div className='col-sm-6 col-lg-6 mb-4' key={item.Id}>
              <div className='card p-5'>
                {item.bankLogo != "0" &&
                  <IPImage
                    alt=''
                    src={getBankImageUrl(item.bankLogo)}
                    className={styles.banksImage}
                    style={{
                      position: 'absolute',
                      top: '25%',
                      left: '45%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: '999',
                      maxWidth: '90px',
                    }}
                  />
                }
                <b style={{ textAlign: 'center' }}>{item.bankName}</b>
                <br></br>
                <Cards
                  cvc={''}
                  expiry={''}
                  focused={'number'}
                  name={item.CardName}
                  placeholders={{ name: 'AD SOYAD' }}
                  number={item.CardNumber}
                />
                {item.Active ? (
                  <div className='row m-t-10 m-l-10 p-10'>
                    <span className='h5'>
                      <h5 style={{ color: item.Status === 0 ? "orange" : item.Status === 1 ? "red" : item.Status === 2 ? "green" : "" }}>
                        {item.Status === 0 ? "Beklemede " : item.Status === 1 ? "Reddedildi " : item.Status === 2 ? "Onaylandı " : " "}
                        - (Ekleyen: {item.UserName})
                      </h5>
                    </span>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span className='h5'>
                        <IPCheckbox
                          label='Aktif'
                          onClick={() => changeCard(item.Id)}
                          checked={item.Enabled}
                        />
                      </span>
                      <span className='h5 m-r-5'>
                        <IPButton
                          className='btn btn border-0'
                          style={{ backgroundColor: 'rgb(238, 51, 62)' }}
                          text={"Sil"}
                          onClick={() => deleteCard(item.Id)}
                        />
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className='row m-t-10 m-l-10 p-10'>
                    <span className='h5'>
                      <h5 style={{ color: 'red' }}>Kartınız yönetici tarafından kullanıma kapatılmıştır.</h5>
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
      <IPModal
        title={'Kart Ekle'}
        show={state.showModal}
        onClose={() =>
          setState({ showModal: false, cardName: undefined, cardNumber: undefined, File: undefined })
        }
        onConfirm={saveCard}
        confirmText={'Kaydet'}
        width={700}
      >
        <form className='p-3'>
          <div className='form-row'>
            <div className='form-group col-md-6'>
              <label>Kart İsmi</label>
              <IPInput
                type='text'
                className='form-control'
                name='NameAndSurname'
                required
                value={state.cardName}
                onChange={(e) => {
                  setState({ cardName: e.target.value })
                }}
              />
            </div>
            <div className='form-group col-md-6'>
              <label>Kart Numarası</label>
              <IPInput
                type='number'
                className='form-control'
                name='cardNumber'
                required
                value={state.cardNumber}
                onChange={(e) => {
                  setState({ cardNumber: e.target.value })
                }}
              />
            </div>
            <div className='col-md-2'>
              <div className={classes.file}>
                <input
                  type='file'
                  onInput={(e) => handleFiles(e)}
                  multiple={false}
                  id={`DocumentFile`}
                />
                <label htmlFor={`DocumentFile`}>Resim Yükle</label>
              </div>
              <p>
                {state.File && state.File.Label.length > 0 && <p>{state.File.Label}</p>}
              </p>
            </div>
            <div className='col-lg-12'>
              <label style={{ color: 'red' }}>Lütfen kart numarası ve kart ismi görünecek şekilde kartınızın resmini yükleyiniz.</label>
            </div>
          </div>
        </form>
      </IPModal>
    </div>
  )

}
export default componentWithContext(CreditCards)
