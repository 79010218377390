import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export class GetBrandListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'brand/HomePageSliderBrands',
      requesterInfo,
    })
  }

  public async getBrandList(): Promise<any[]> {
    return await this.getAsync<any[]>()
  }
}
