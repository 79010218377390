/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC';
import IPButton from '../../../../../components/IPButton';
import IPSelectBox from '../../../../../components/IPSelectBox';
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox';
import { GetWhiteDealerListApi } from '../../../CreditCardPaymentCampaigns/Dealer/services/getWhiteDealerListApi';
import { IBankAdditionalInstallments } from '../models/models';
import { AddBankAdditionalInstallmentsApi } from '../services/addBankAdditionalInstallmentApi';
import { DeleteBankAdditionalInstallmentApi } from '../services/deleteBankAdditionalInstallmentApi';
import { ListBankAdditionalInstallmentsApi } from '../services/listBankAdditionalInstallmentsApi';
import { UpdateBankAdditionalInstallmentApi } from '../services/updateBankAdditionalInstallmentApi';
import Swal from 'sweetalert2';
import { css } from '@emotion/css';

function AddBankAdditionalInstallment(props: BaseComponentWithContextProps) {

  const location = useLocation();
  const installementData = (location.state as { installementData?: any })?.installementData;

  const [installmentsList, setInstallmentsList] = useState<IBankAdditionalInstallments[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [optionList, setOptionList] = useState<any>({
    bankOptions: [],
  })

  const [formData, setFormData] = useState({
    Id: 0,
    BankNames: [] as ISelectOption[],
    InstallmentOption: [] as ISelectOption[],
    AdditionalInstallment: null,
  });

  const installmentValues: ISelectOption[] = [
    { label: '1 Taksit', value: '1' },
    { label: '2 Taksit', value: '2' },
    { label: '3 Taksit', value: '3' },
    { label: '4 Taksit', value: '4' },
    { label: '5 Taksit', value: '5' },
    { label: '6 Taksit', value: '6' },
    { label: '9 Taksit', value: '9' },
    { label: '12 Taksit', value: '12' },
    { label: '15 Taksit', value: '15' },
    { label: '18 Taksit', value: '18' },
    { label: '24 Taksit', value: '24' },
  ]

  const additionalInstallmentValues: ISelectOption[] = [
    { label: '+1 Taksit', value: '+1' },
    { label: '+2 Taksit', value: '+2' },
    { label: '+3 Taksit', value: '+3' },
    { label: '+4 Taksit', value: '+4' },
    { label: '+5 Taksit', value: '+5' },
    { label: '+6 Taksit', value: '+6' },
  ]

  useEffect(() => {
    async function fetchData() {
      props.showLoading();
      try {
        const [bankOptions] = await Promise.all([GetBanks()]);
        setOptionList({
          bankOptions,
        });
        fetchInstallmentsList();

      } catch (err: any) {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      } finally {
        props.hideLoading();
      }
    }
    fetchData();

    if (installementData) {
      // Update formData and set isEditing if installementData exists
      formData.Id = installementData.BankAdditionalInstallmentID;
      formData.BankNames = [{ label: installementData.BankName, value: installementData.BankName }];
      formData.InstallmentOption = [{ label: installementData.InstallmentOption, value: installementData.InstallmentOption }];
      formData.AdditionalInstallment = installementData.AdditionalInstallment;
      setIsEditing(true);
      setInstallmentsList([
        {
          BankAdditionalInstallmentID: installementData.BankAdditionalInstallmentID,
          BankName: installementData.BankName,
          InstallmentOption: installementData.InstallmentOption,
          AdditionalInstallment: installementData.AdditionalInstallment,
        },
      ])
    }

  }, [])

  async function GetBanks() {
    try {
      const res = await new GetWhiteDealerListApi({}).getBanks()
      if (res) {
        const banks = res.map(x => ({ label: x.BankName, value: x.BankID }))
        if (!banks) return []
        return banks
      }
    } catch (err: any) {
      console.error(err)
    }
  }

  const fetchInstallmentsList = async () => {
    try {
      const api: ListBankAdditionalInstallmentsApi = new ListBankAdditionalInstallmentsApi({})
      const response: IBankAdditionalInstallments[] = await api.lisBankAdditionaltInstallemnts();
      setInstallmentsList(response);
    } catch (error) {
      console.error('Error fetching installments', error);
    }
  };

  const handleBankNamesChange = options => {
    setFormData({
      ...formData,
      BankNames: options,
    });
  };

  const handleInstallmentOptionChange = (option) => {
    setFormData({ ...formData, InstallmentOption: option });
  };

  const handleAdditionalInstallmentChange = (option) => {
    setFormData({ ...formData, AdditionalInstallment: option });
  };

  const handleAddInstallment = async () => {
    try {
      if (
        formData.BankNames.length > 0 &&
        formData.InstallmentOption.length > 0 && // At least one primary installment option selected
        formData.AdditionalInstallment
      ) {
        const selectedBanks = formData.BankNames;
        const selectedPrimaryInstallments = Array.isArray(formData.InstallmentOption)
          ? formData.InstallmentOption
          : [formData.InstallmentOption];

        for (const bank of selectedBanks) {
          const bankName = (bank as any).label;

          for (const primaryInstallmentOption of selectedPrimaryInstallments) {
            const installmentOption = (primaryInstallmentOption as any).value;

            const requestData = {
              Id: formData.Id,
              BankName: bankName,
              InstallmentOption: installmentOption,
              AdditionalInstallment: (formData.AdditionalInstallment as any).value,
            };

            try {
              if (isEditing) {
                const api: UpdateBankAdditionalInstallmentApi = new UpdateBankAdditionalInstallmentApi({});
                await api.updateBankAdditionalInstallment([requestData]);

                Swal.fire({
                  icon: 'success',
                  title: `${bankName} için taksit güncellendi!`,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              } else {
                const api: AddBankAdditionalInstallmentsApi = new AddBankAdditionalInstallmentsApi({});
                await api.addBankAdditionalInstallment([requestData]);

                Swal.fire({
                  icon: 'success',
                  title: `${bankName} için taksit oluşturuldu1`,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                });
              }
            } catch (err) {
              console.error(`Error for ${bankName}: ${err}`);
            }
          }
        }

        // Display a single success message after processing all banks and installments
        if (isEditing) {
          Swal.fire({
            icon: 'success',
            title: 'Taksit başarıyla güncellendi!',
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        }
        else {
          Swal.fire({
            icon: 'success',
            title: 'Taksitler başarıyla eklendi!',
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        }

        // Clear the form and set isEditing to false
        setFormData({
          Id: 0,
          BankNames: [],
          InstallmentOption: [],
          AdditionalInstallment: null,
        });
        setIsEditing(false);

        // Fetch the updated installments list
        fetchInstallmentsList();
      } else {
        console.error('Some of the formData properties are null.');
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  };

  const handleEditInstallment = (installment) => {
    setFormData({
      Id: installment.BankAdditionalInstallmentID,
      BankNames: installment.BankName,
      InstallmentOption: installment.InstallmentOption,
      AdditionalInstallment: installment.AdditionalInstallment
    });
    setIsEditing(true);
  };

  const handleDeleteInstallment = async (id: number) => {
    const shouldDelete = await Swal.fire({
      icon: 'warning',
      title: 'Bu taksiti silmek istediğinizden emin misiniz?',
      showCancelButton: true,
      showConfirmButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
    });

    if (shouldDelete.isConfirmed) {
      try {
        const api: DeleteBankAdditionalInstallmentApi = new DeleteBankAdditionalInstallmentApi({});
        await api.deleteBankAdditionalInstallment(id);
        fetchInstallmentsList();
      } catch (err: any) {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      }
    }
  };

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Banka Ek Taksit {isEditing === true ? ' Güncelleme' : ' Ekleme'}</span>
            <div className='col-12 col-md-2 mb-2'>
              <div className='buttonsuz'>
                <Link to='/admin/BankAndInstallementOperation/AdditionalBankInstallment/AdditionalBankInstallmentList'>
                  <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                </Link>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form id='id1' className='formform' noValidate>
              <div className='row m-t-5' >
                <div className='col-md-4'>
                  <label className='labeltext'>Bankalar</label>
                  <IPSelectBox
                    isDisabled={isEditing}
                    isMulti
                    isClearable
                    id='BankNames'
                    name='BankNames'
                    options={optionList.bankOptions}
                    value={formData.BankNames as unknown as ISelectOption}
                    onChangeMulti={options => {
                      handleBankNamesChange(options);
                    }}
                  />
                </div>
                <div className='col-md-3'>
                  <label className='labeltext'>Asıl Taksit</label>
                  <IPSelectBox
                    isDisabled={isEditing}
                    isMulti
                    isClearable
                    name='InstallmentOption'
                    options={installmentValues}
                    value={formData.InstallmentOption as unknown as ISelectOption}
                    onChangeMulti={option => {
                      handleInstallmentOptionChange(option);
                    }}
                  />
                </div>
                <div className='col-md-2'>
                  <label className='labeltext'>Ek Taksit</label>
                  <IPSelectBox
                    isClearable
                    name='AdditionalInstallment'
                    options={additionalInstallmentValues}
                    value={formData.AdditionalInstallment as unknown as ISelectOption}
                    onChangeSingle={option => {
                      handleAdditionalInstallmentChange(option);
                    }}
                  />
                </div>
                {isEditing &&
                  <div className='col-1'>
                    <label className='labeltext'></label>
                    <IPButton
                      //onClick={ handleDeleteInstallment}
                      type='button'
                      className='btn btn-danger m-t-5'
                      text='Sil'
                      disabled={installmentsList.length < 2 ? true : false}
                    ></IPButton>
                  </div>}

                <div className='col-1' style={{ marginLeft: '20px' }}>
                  <label className='labeltext'></label>
                  <IPButton
                    onClick={handleAddInstallment}
                    type='button'
                    className='btn btn-success m-t-5'
                    text={isEditing ? 'Güncelle' : 'Taksit Ekle'}

                  ></IPButton>
                </div>
              </div>

              <div className='mt-5'>
                <h4>Son Kayıt</h4>
                <table style={{ width: '50%' }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left' }}>Banka Adı</th>
                      <th style={{ textAlign: 'left' }}>Asıl Taksit</th>
                      <th style={{ textAlign: 'left' }}>Ek Taksit</th>
                      <th style={{ textAlign: 'center' }}>İşlemler</th>
                    </tr>
                  </thead>
                  <tbody>
                    {installmentsList.length > 0 && (
                      <tr key={installmentsList[installmentsList.length - 1].BankAdditionalInstallmentID}>
                        <td style={{ textAlign: 'left' }}>{installmentsList[installmentsList.length - 1].BankName}</td>
                        <td style={{ textAlign: 'left' }}>{installmentsList[installmentsList.length - 1].InstallmentOption}</td>
                        <td style={{ textAlign: 'left' }}>{installmentsList[installmentsList.length - 1].AdditionalInstallment}</td>
                        <td style={{ textAlign: 'center' }}>
                          <div>
                            <a
                              onClick={() => handleEditInstallment(installmentsList[installmentsList.length - 1])}
                              data-action-type="edit"
                              role="button"
                              className={css`cursor:pointer`}
                              data-toggle="tooltip"
                              data-original-title="Düzenle"
                            >
                              <i className={`${css`font-size:1.2rem; color:green`} icon-edit`}></i>
                            </a>

                            <a
                              style={{ marginLeft: '10px' }}
                              onClick={() => handleDeleteInstallment(installmentsList[installmentsList.length - 1].BankAdditionalInstallmentID)}
                              data-action-type="delete"
                              role="button"
                              className={css`cursor:pointer`}
                              data-toggle="tooltip"
                              data-original-title="Sil"
                            >
                              <i className={`${css`font-size:1.2rem;color:red`} icon-trash-2`} ></i>
                            </a>
                          </div>

                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(AddBankAdditionalInstallment)