import React, { useEffect } from 'react'
import { useCustomReducer } from '../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { IException } from '../../../../base/models'
import { uuidv4 } from '../../../../base/utils'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import {
  IGetAllRolePermissionsResponse,
  IGetAllUserPermissionsResponse,
  ISimpleUser,
  IUserPermissionModel,
  SecurityActionModel,
  SecurityControllerModel,
} from './models'
import { AddUserPermissionsApi } from './services/addUserPermissionListApi'
import { GetAllUserPermissionsApi } from './services/getAllUserPermissionsApi'
// import { GetAllSimpleUsersApi } from './services/getSimpleUserApi'
import { GetUserPermissionsByUserIdApi } from './services/getUserPermissionByUserIdApi'
import IPButton from '../../../../components/IPButton'
import IPCheckbox from '../../../../components/IPCheckBox'
import IPSelectBox from '../../../../components/IPSelectBox'
import Swal from 'sweetalert2'
import IPInput from '../../../../components/IPInput'
import { GetDealerSimpleUsersApi } from './services/GetDealerSimpleUsersApi'

export interface AuthorizationDefinitionsToUserState {
  authorizationInfo?: IGetAllUserPermissionsResponse
  users: ISimpleUser[]
  selectedUser?: ISelectOption
  selectedAuths: IUserPermissionModel[]
  cariCode: string
}

function AuthorizationDefinitionsToUser(props: BaseComponentWithContextProps) {
  const getInitialState = (): AuthorizationDefinitionsToUserState => {
    return {
      users: [],
      selectedAuths: [],
      cariCode: ""
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<AuthorizationDefinitionsToUserState>(intialState)

  useEffect(() => {
    getAuthorizationInfo()
    // getUsers()
  }, [])

  const getAuthorizationInfo = () => {
    props.showLoading && props.showLoading()
    const api: GetAllUserPermissionsApi = new GetAllUserPermissionsApi({})
    api
      .getAllPermissions()
      .then((res: IGetAllRolePermissionsResponse) => {
        setState({ authorizationInfo: res })
        props.hideLoading && props.hideLoading()
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()
      })
  }

  // const getUsers = () => {
  //   props.showLoading && props.showLoading()

  //   const api: GetAllSimpleUsersApi = new GetAllSimpleUsersApi({})
  //   api
  //     .getAllSimpleUsers()
  //     .then((res: ISimpleUser[]) => {
  //       setState({ users: res })
  //       props.hideLoading && props.hideLoading()
  //     })
  //     .catch((err: IException) => {
  //       props.hideLoading && props.hideLoading()
  //     })
  // }

  const getDealerUsers = () => {
    if (!state.cariCode || state.cariCode.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Önce Cari Kod yazınız',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    props.showLoading && props.showLoading()
    const cariCode = state.cariCode.length > 5 ? state.cariCode.padStart(10, '0')
      : state.cariCode
    const api: GetDealerSimpleUsersApi = new GetDealerSimpleUsersApi({})
    api
      .getDealerSimpleUsers(cariCode)
      .then((res: ISimpleUser[]) => {
        setState({ users: res })
        props.hideLoading && props.hideLoading()
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const onChangeUser = (option: ISelectOption) => {
    props.showLoading && props.showLoading()

    const getPermsByRoleApi: GetUserPermissionsByUserIdApi = new GetUserPermissionsByUserIdApi({})
    getPermsByRoleApi
      .getUserPermissions(Number(option.value))
      .then((res: IUserPermissionModel[]) => {
        props.hideLoading && props.hideLoading()
        setState({ selectedUser: option })

        if (res) {
          setState({ selectedAuths: res })
        }
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const isSelectAction = (controllerId: number, actionNumber: number): boolean => {
    const controllerAuth = state.selectedAuths.find(
      (elem: IUserPermissionModel) => elem.IdSecurityController === controllerId
    )

    if (controllerAuth) {
      return (actionNumber & controllerAuth.ActionNumberTotal) === actionNumber
    }

    return false
  }

  const isSelectActionArray = (controllerId: number, actionNumber: number[]): boolean => {
    const controllerAuth = state.selectedAuths.find(
      (elem: IUserPermissionModel) => elem.IdSecurityController === controllerId
    )

    if (controllerAuth) {
      return actionNumber.every((number) =>
        (controllerAuth.ActionNumberTotal & number) === number
      );
    }

    return false;
  }


  const selectAction = (checked: boolean, controllerId: number, actionNumber: number) => {
    if (checked) {
      const controllerAuth = state.selectedAuths.find(
        (elem: IUserPermissionModel) => elem.IdSecurityController === controllerId
      )

      if (controllerAuth && !((actionNumber & controllerAuth.ActionNumberTotal) === actionNumber)) {
        controllerAuth.ActionNumberTotal = controllerAuth.ActionNumberTotal + actionNumber
        setState({ selectedAuths: state.selectedAuths })
      } else {
        const newAuthController: IUserPermissionModel = {
          IdSecurityController: controllerId,
          IdUser: Number(state.selectedUser?.value),
          ActionNumberTotal: actionNumber,
        }

        setState({ selectedAuths: [...state.selectedAuths, newAuthController] })
      }
    } else {
      const controllerAuth = state.selectedAuths.find(
        (elem: IUserPermissionModel) => elem.IdSecurityController === controllerId
      )

      if (controllerAuth && (actionNumber & controllerAuth.ActionNumberTotal) === actionNumber) {
        controllerAuth.ActionNumberTotal = controllerAuth.ActionNumberTotal - actionNumber
        setState({ selectedAuths: state.selectedAuths })
      }
    }
  }

  const selectActionArray = (checked: boolean, controllerId: number, actionNumbers: SecurityActionModel[]) => {
    const existingAuth = state.selectedAuths.find(
      (elem: IUserPermissionModel) => elem.IdSecurityController === controllerId
    )

    if (checked) {
      if (existingAuth) {
        // If the controller exists in selectedAuths, update its ActionNumberTotal
        actionNumbers.forEach((actionNumber) => {
          existingAuth.ActionNumberTotal |= actionNumber.ActionNumber
        })
      } else {
        // If the controller doesn't exist, create a new entry
        const newAuthController: IUserPermissionModel = {
          IdSecurityController: controllerId,
          IdUser: Number(state.selectedUser?.value),
          ActionNumberTotal: 0, // Initialize with 0 and then update
        };

        actionNumbers.forEach((actionNumber) => {
          newAuthController.ActionNumberTotal |= actionNumber.ActionNumber
        })

        setState({ selectedAuths: [...state.selectedAuths, newAuthController] })
      }
    } else {
      // If the checkbox is unchecked, remove the specified action numbers
      if (existingAuth) {
        actionNumbers.forEach((actionNumber) => {
          existingAuth.ActionNumberTotal &= ~actionNumber.ActionNumber
        })

        // Remove the controller from selectedAuths if ActionNumberTotal becomes zero
        if (existingAuth.ActionNumberTotal === 0) {
          state.selectedAuths = state.selectedAuths.filter(
            (auth) => auth.IdSecurityController !== controllerId
          )
        }
      }

      setState({ selectedAuths: state.selectedAuths })
    }
  }


  const updatePermissions = () => {
    if (!state.selectedUser) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen kullanıcı seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading && props.showLoading()

    const updateUserPermissionsApi: AddUserPermissionsApi = new AddUserPermissionsApi({})
    updateUserPermissionsApi
      .addUserPermissions(Number(state.selectedUser?.value), state.selectedAuths)
      .then(() => {
        props.hideLoading && props.hideLoading()

        Swal.fire({
          icon: 'success',
          title: 'Kullanıcı yetkileri güncellendi.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()

        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Kullanıcıya Yetki Tanımlama</span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='form-group col-md-3'>
                <IPInput
                  name='CariCode'
                  type='text'
                  value={state.cariCode}
                  onChange={e => {
                    setState({ cariCode: e.target.value })
                  }}
                  placeholder='Cari Kodu'
                />
              </div>
              <div className='form-group col-md-2'>
                <IPButton text='Kullanıcı Getir' className='btn btn-danger' onClick={getDealerUsers} />
              </div>
              <div className='form-group col-md-3'>
                <IPSelectBox
                  name='User'
                  placeholder={'Kullanıcı Seçiniz'}
                  onChangeSingle={onChangeUser}
                  value={state.selectedUser}
                  options={state.users.map((user: ISimpleUser) => {
                    return { value: user.Id, label: `${user.UserName}(${user.Name})` }
                  })}
                />
              </div>
              <div className='form-group col-md-2'>
                <IPButton text='Güncelle' className='btn btn-primary' onClick={updatePermissions} />
              </div>
            </div>
            <div className='row'>
              {state.selectedUser && (
                <div className='col-md-10'>
                  {state.authorizationInfo?.SecurityControllerModels?.map((scModel: SecurityControllerModel) => {
                    return (
                      <div key={uuidv4()} className='row mt-2'>
                        <div className='col-md-12'>
                          <div className='row ml-0'>
                            <IPCheckbox
                              label={scModel.Description}
                              checked={isSelectActionArray(scModel.IdSecurityController, state.authorizationInfo!.SecurityActionModels?.filter((item: SecurityActionModel) => item.IdSecurityController === scModel.IdSecurityController).map((saModel: SecurityActionModel) => saModel.ActionNumber))}
                              onChange={(e) => selectActionArray(e.target.checked, scModel.IdSecurityController, state.authorizationInfo!.SecurityActionModels?.filter((item: SecurityActionModel) => item.IdSecurityController === scModel.IdSecurityController))}
                            />
                          </div>
                          <div className='row mt-2 ml-5'>
                            <div className='col-md-12'>
                              {state.authorizationInfo?.SecurityActionModels?.filter((item: SecurityActionModel) => {
                                return item.IdSecurityController === scModel.IdSecurityController
                              }).map((saModel: SecurityActionModel) => {
                                return (
                                  <div key={uuidv4()} className='row'>
                                    <div className='col-md-4'>
                                      <IPCheckbox
                                        label={saModel.ActionName}
                                        checked={isSelectAction(scModel.IdSecurityController, saModel.ActionNumber)}
                                        onChange={e =>
                                          selectAction(
                                            e.target.checked,
                                            scModel.IdSecurityController,
                                            saModel.ActionNumber
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(AuthorizationDefinitionsToUser)
