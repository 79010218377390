import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IEInvoiceAddress } from '../models/request/getMyOrderRequest'

export class GetEInvoiceAddressApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetEInvoiceAddress',
      requesterInfo,
    })
  }

  public async getEInvoiceAddress(request: string): Promise<string> {
    this.props.url = `${this.props.url}?eInvoiceNumber=${request}`
    return await this.postAsync<string>()
  }
}
