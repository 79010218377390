import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import React, { useEffect, useMemo, useState } from 'react'
import Table from '../../../../../components/Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetUndefinedFilteredProductsApi } from '../services/getUndefinedFilteredProductsApi'
import Swal from 'sweetalert2'
import { IGetUndefinedFilteredProducts } from '../models/models'
import { GetNotFilteredIceCatCategoryApi } from '../services/getNotFilteredIceCatCategoryApi'
import IPSelectBox from '../../../../../components/IPSelectBox/IPSelectBoxMenu'
import { ICompany } from '../../../components/models/models'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import { getCompanyApi } from '../../../components/api'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'
import { GetNotFilteredDetailApi } from '../services/getNotFilteredDetailApi'

function UndefinedProducts(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const [products, setProducts] = useState<IGetUndefinedFilteredProducts[]>([])
  const [filteredProducts, setFilterProducts] = useState<IGetUndefinedFilteredProducts[]>([])
  const [filteredProducts2, setFilterProducts2] = useState<IGetUndefinedFilteredProducts[]>([])
  const [products2, setProducts2] = useState<IGetUndefinedFilteredProducts[]>([])
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [companies2, setCompanies2] = useState<ICompany[]>([])
  const [companyOption, setCompanyOptions] = useState<ISelectOption>()
  const [statusOption, setStatusOptions] = useState<ISelectOption>()
  const [companyOption2, setCompanyOptions2] = useState<ISelectOption | null>()
  const [statusOption2, setStatusOptions2] = useState<ISelectOption | null>()
  const [raporType, setRaporType] = useState<ISelectOption>()

  useEffect(() => {
    getCompanies()
    getNotFilteredProducts()
    getNotFilteredIceCatCategory()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'Id',
      },
      {
        Header: "B2B'de Ürün Aktif Mi?",
        accessor: 'Active',
      },
      {
        Header: 'Şirket',
        accessor: 'Company',
      },
      {
        Header: 'B2B Kategori ID',
        accessor: 'CategoryId',
      },
      {
        Header: 'B2B Kategori',
        accessor: 'Category',
      },
      {
        Header: 'B2B Grup ID',
        accessor: 'GroupId',
      },
      {
        Header: 'B2B Grup',
        accessor: 'Group',
      },
      {
        Header: 'IC Kategori ID',
        accessor: 'ICCategoryId',
      },
      {
        Header: 'IC Kategori',
        accessor: 'ICCategory',
      },
      {
        Header: 'IC Grup ID',
        accessor: 'ICGroupID',
      },
      {
        Header: 'IC Grup',
        accessor: 'ICGroup',
      },
      {
        Header: 'IC Özellik ID',
        accessor: 'ICPropertyId',
      },
      {
        Header: 'IC Özellik',
        accessor: 'ICProperty',
      },
      {
        Header: 'B2B Görünen Özellik',
        accessor: 'B2BGorunurOzellik',
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      filteredProducts.map((p, index) => ({
        Id: index + 1,
        Active: p.Activate ? 'Evet' : 'Hayır',
        Company: p.Sirket,
        CategoryId: p.KategoriID,
        Category: p.Kategori,
        ICCategoryId: p.ICKategoriID,
        ICCategory: p.ICKategoriAdi,
        Property: p.Ozellik,
        ICGroupID: p.ICGruopID,
        ICGroup: p.ICGroupAdi,
        GroupId: p.ICGruopID,
        Group: p.ICGroupAdi,
        ICPropertyId: p.OzellikID,
        ICProperty: p.OzellikAdi,
        B2BGorunurOzellik: p.B2BGorunurOzellik,
      })),

    [filteredProducts]
  )

  const excelButton1 = async () => {
    try {
      if (filteredProducts) {
        const data: any[] = []
        if (filteredProducts.length > 0) {
          filteredProducts.map((p, index) => {
            return data.push({
              Sıra: index + 1,
              "B2B'de Ürün Aktif Mi?": p.Activate ? 'Evet' : 'Hayır',
              Şirket: p.Sirket,
              'B2B Kategori ID': p.KategoriID,
              'B2B Kategori': p.Kategori,
              'IC Kategori ID': p.ICKategoriID,
              'IC Kategori': p.ICKategoriAdi,
              Özellik: p.Ozellik,
              'IC Grup ID': p.ICGruopID,
              'IC Grup': p.ICGroupAdi,
              'B2B Grup ID': p.ICGruopID,
              'B2B Grup': p.ICGroupAdi,
              'IC Özellik ID': p.OzellikID,
              'IC Özellik': p.OzellikAdi,
              'B2B Görünen Özellik': p.B2BGorunurOzellik,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Filtre Özellikleri "Tanımsız" Kategori Grup Özellik Listesi')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const columns2 = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'Id',
      },
      {
        Header: "B2B'de Ürün Aktif Mi?",
        accessor: 'Active',
      },
      {
        Header: 'Şirket',
        accessor: 'Company',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'ProductCode',
      },
      {
        Header: 'Ürün Açıklama',
        accessor: 'ProductName',
      },
      {
        Header: 'B2B Kategori ID',
        accessor: 'CategoryId',
      },
      {
        Header: 'B2B Kategori',
        accessor: 'Category',
      },
      {
        Header: 'B2B Grup ID',
        accessor: 'GroupId',
      },
      {
        Header: 'B2B Grup',
        accessor: 'Group',
      },
    ],
    []
  )

  const data2 = useMemo(
    () =>
      filteredProducts2.map((p, index) => ({
        Id: index + 1,
        Active: p.Activate ? 'Evet' : 'Hayır',
        ProductCode: p.UrunKodu,
        ProductName: p.UrunAciklama,
        Category: p.Kategori,
        CategoryId: p.KategoriID,
        GroupId: p.GroupID,
        Group: p.GroupAdi,
        Company: p.Sirket,
      })),
    [filteredProducts2]
  )

  const excelButton2 = async () => {
    try {
      if (filteredProducts2) {
        const data: any[] = []
        if (filteredProducts2.length > 0) {
          filteredProducts2.map((p, index) => {
            return data.push({
              Sıra: index + 1,
              "B2B'de Ürün Aktif Mi?": p.Activate ? 'Evet' : 'Hayır',
              Şirket: p.Sirket,
              'Ürün Kodu': p.UrunKodu,
              'Ürün Açıklama': p.UrunAciklama,
              'B2B Kategori': p.Kategori,
              'B2B Kategori ID': p.KategoriID,
              'B2B Grup ID': p.GroupID,
              'B2B Grup': p.GroupAdi,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(
          data,
          "Tüm Filtre Özellikleri 'Tanımsız' Kategori (IceCat'den Hiç Özelliği Gelmemiş) Grup Özellik Listesi"
        )
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const getNotFilteredProducts = async () => {
    props.showLoading()
    const getUndefinedFilteredProductsApi: GetUndefinedFilteredProductsApi = new GetUndefinedFilteredProductsApi({})
    await getUndefinedFilteredProductsApi
      .getUndefinedFilteredProducts()
      .then(result => {
        if (result) {
          setProducts(result)
          setFilterProducts(result)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getNotFilteredIceCatCategory = async () => {
    props.showLoading()
    if (raporType?.value === 1) {
      const getNotFilteredIceCatCategoryApi: GetNotFilteredIceCatCategoryApi = new GetNotFilteredIceCatCategoryApi({})
      await getNotFilteredIceCatCategoryApi
        .getNotFilteredIceCatCategory()
        .then(result => {
          if (result) {
            setProducts2(result)

            let filteredProducts = result

            if (statusOption2) {
              filteredProducts = filteredProducts.filter(a => a.Activate.toString() === statusOption2.value)
            }

            if (companyOption2) {
              filteredProducts = filteredProducts.filter(a => a.Sirket === companyOption2.label)
            }

            setFilterProducts2(filteredProducts)
          }
          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
    else if (raporType?.value === 2) {
      const getNotFilteredDetailApi: GetNotFilteredDetailApi = new GetNotFilteredDetailApi({})
      await getNotFilteredDetailApi
        .getNotFilteredDetailApi()
        .then(result => {
          if (result) {
            setProducts2(result)

            let filteredProducts = result

            if (statusOption2) {
              filteredProducts = filteredProducts.filter(a => a.Activate.toString() === statusOption2.value)
            }

            if (companyOption2) {
              filteredProducts = filteredProducts.filter(a => a.Sirket === companyOption2.label)
            }

            setFilterProducts2(filteredProducts)
          }
          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
  }

  useEffect(() => {
    if (raporType?.value === 1 || raporType?.value === 2)
      getNotFilteredIceCatCategory()
  }, [raporType])

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push(co)
      })
    setCompanies(data.sort((a, b) => a.Code - b.Code))
    setCompanies2(data.sort((a, b) => a.Code - b.Code))
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              {t('pages.admin.productOperation.filterPropertiesUndefinedProducts.undefinedProducts.header')}/
              {t('pages.admin.productOperation.filterPropertiesUndefinedProducts.undefinedProducts.subHeader')}
            </span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-12'>
                      <div className='row'>
                        <div className='col-3'>
                          <label>Şirket</label>
                          <IPSelectBox
                            isClearable
                            options={
                              companies &&
                              companies.map((item: ICompany) => ({
                                label: item.Name,
                                value: `${item.Code}`,
                              }))
                            }
                            value={companyOption}
                            onChangeSingle={option => {
                              if (option) {
                                setCompanyOptions(option)
                                if (statusOption) {
                                  setFilterProducts(
                                    products.filter(
                                      a => a.Activate.toString() === statusOption.value && a.Sirket === option.label
                                    )
                                  )
                                } else setFilterProducts(products.filter(a => a.Sirket === option.label))
                              } else {
                                setCompanyOptions(option)
                                if (statusOption) {
                                  setFilterProducts(
                                    products.filter(a => a.Activate.toString() === statusOption.value)
                                  )
                                } else setFilterProducts(products)
                              }
                            }}
                          />
                        </div>
                        <div className='col-3'>
                          <label>Durum</label>
                          <IPSelectBox
                            isClearable
                            options={[
                              { value: 'true', label: 'Aktif' },
                              { value: 'false', label: 'Pasif' },
                            ]}
                            value={statusOption}
                            onChangeSingle={option => {
                              if (option) {
                                setStatusOptions(option)
                                if (companyOption) {
                                  setFilterProducts(
                                    products.filter(
                                      a =>
                                        a.Sirket === companyOption.label && a.Activate.toString() === option.value
                                    )
                                  )
                                } else
                                  setFilterProducts(products.filter(a => a.Activate.toString() === option.value))
                              } else {
                                setStatusOptions(option)
                                if (companyOption) {
                                  setFilterProducts(products.filter(a => a.Sirket === companyOption.label))
                                } else setFilterProducts(products)
                              }
                            }}
                          />
                        </div>
                        <div className='col-6 d-flex align-items-center justify-content-end'>
                          <span>Excel: </span>
                          <button
                            type='button'
                            style={{
                              border: '1px solid #2e7d32',
                              borderRadius: 0,
                              width: '29px',
                              height: '29px',
                              backgroundColor: 'white',
                              margin: '0 10px',
                            }}
                            onClick={excelButton1}
                            className='btn text-capitalize background-white-important d-flex align-items-center'
                          >
                            <i
                              className='fas fa-file-excel'
                              style={{
                                marginLeft: '-6px',
                                fontSize: '18px',
                                color: '#2e7d32',
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>
                      {products && products.length > 0 && (
                        <Table
                          columns={columns}
                          data={data}
                          headerName='Filtre Özellikleri "Tanımsız" Kategori Grup Özellik Listesi'
                          columnCount={14}
                          textAlign={true}
                        />
                      )}
                    </div>
                  </div>
                  <br></br>
                  <div className='row m-t-5'>
                    <div className='col-12'>

                      <div className='row'>
                        <div className='col-3'>
                          <label>Şirket</label>
                          <IPSelectBox
                            isClearable
                            options={
                              companies2 &&
                              companies2.map((item: ICompany) => ({
                                label: item.Name,
                                value: `${item.Code}`,
                              }))
                            }
                            value={companyOption2}
                            onChangeSingle={option => {
                              if (option) {
                                setCompanyOptions2(option)
                                if (statusOption2) {
                                  setFilterProducts2(
                                    products2.filter(
                                      a =>
                                        a.Activate.toString() === statusOption2.value && a.Sirket === option.label
                                    )
                                  )
                                } else setFilterProducts2(products2.filter(a => a.Sirket === option.label))
                              } else {
                                setCompanyOptions2(option)
                                if (statusOption2) {
                                  setFilterProducts2(
                                    products2.filter(a => a.Activate.toString() === statusOption2.value)
                                  )
                                } else setFilterProducts2(products2)
                              }
                            }}
                          />
                        </div>
                        <div className='col-3'>
                          <label>Durum</label>
                          <IPSelectBox
                            isClearable
                            options={[
                              { value: 'true', label: 'Aktif' },
                              { value: 'false', label: 'Pasif' },
                            ]}
                            value={statusOption2}
                            onChangeSingle={option => {
                              if (option) {
                                setStatusOptions2(option)
                                if (companyOption2) {
                                  setFilterProducts2(
                                    products2.filter(
                                      a =>
                                        a.Sirket === companyOption2.label && a.Activate.toString() === option.value
                                    )
                                  )
                                } else
                                  setFilterProducts2(products2.filter(a => a.Activate.toString() === option.value))
                              } else {
                                setStatusOptions2(option)
                                if (companyOption2) {
                                  setFilterProducts2(products2.filter(a => a.Sirket === companyOption2.label))
                                } else setFilterProducts2(products2)
                              }
                            }}
                          />
                        </div>
                        <div className='col-4'>
                          <label>Rapor Tipi</label>
                          <IPSelectBox
                            isClearable
                            options={[
                              { value: 1, label: 'Tüm Filtre Özellikleri Tanımsız Ürünler' },
                              { value: 2, label: 'Tanımsız Özellikleri Olan Ürünler' },
                            ]}
                            value={raporType}
                            onChangeSingle={option => {
                              if (option) {
                                setRaporType(option)
                              }
                            }}
                          />
                        </div>
                        <div className='col-2 d-flex align-items-center justify-content-end'>
                          <span>Excel: </span>
                          <button
                            type='button'
                            style={{
                              border: '1px solid #2e7d32',
                              borderRadius: 0,
                              width: '29px',
                              height: '29px',
                              backgroundColor: 'white',
                              margin: '0 10px',
                            }}
                            onClick={excelButton2}
                            className='btn text-capitalize background-white-important d-flex align-items-center'
                          >
                            <i
                              className='fas fa-file-excel'
                              style={{
                                marginLeft: '-6px',
                                fontSize: '18px',
                                color: '#2e7d32',
                              }}
                            ></i>
                          </button>
                        </div>
                      </div>

                      {products2 && products2.length > 0 ? (
                        <Table
                          columns={columns2}
                          data={data2}
                          headerName={`${raporType?.label} Listesi`}
                          columnCount={14}
                          textAlign={true}
                        />
                      ) : 'Veri bulunamadı'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(UndefinedProducts)
