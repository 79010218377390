import React, { useState } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import IPDatePicker from '../../../../../components/IPDatePicker'
import IPInput from '../../../../../components/IPInput'
import IPRadio from '../../../../../components/IPRadio'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPTextarea from '../../../../../components/IPTextarea'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'

interface IProductState {
  selectedTargetLink?: ISelectOption
  selectedBackground?: ISelectOption
  selectedTitle?: ISelectOption
  selectedFont?: ISelectOption
}

function StopwatchOperationsAdd() {
  const t = translate(Translations)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  const getInitialState = (): IProductState => {
    return {}
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IProductState>(intialState)

  const ITargetLinkOption: ISelectOption[] = [
    { label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.noLink'), value: '2' },
    { label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.detail'), value: '3' },
    { label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.campaign'), value: '4' },
    { label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.productList'), value: '5' },
    {
      label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.opportunityMarket'),
      value: '6',
    },
    { label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.freeLink'), value: '7' },
  ]
  const IBackgroundOption: ISelectOption[] = [
    { label: 'geri_sayim_bg.png', value: '2' },
    { label: 'Kronometre_Gece.jpg', value: '3' },
    { label: 'yilbasikronometre.png', value: '4' },
    { label: 'ramazankronemetre.png', value: '5' },
  ]
  const ITitleOption: ISelectOption[] = [
    {
      label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.titleImage', ['1']),
      value: '2',
    },
    {
      label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.titleImage', ['2']),
      value: '3',
    },
    {
      label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.titleImage', ['3']),
      value: '4',
    },
    {
      label: t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.titleImage', ['4']),
      value: '5',
    },
  ]
  const IFontOption: ISelectOption[] = [
    { label: 'Font 1', value: '2' },
    { label: 'Font 2', value: '3' },
    { label: 'Font 3', value: '4' },
    { label: 'Font 4', value: '5' },
  ]
  const onTargetLinkChange = (value: ISelectOption) => {
    setState({ selectedTargetLink: value })
  }
  const onBackgroundChange = (value: ISelectOption) => {
    setState({ selectedBackground: value })
  }
  const onTitleChange = (value: ISelectOption) => {
    setState({ selectedTitle: value })
  }
  const onFontChange = (value: ISelectOption) => {
    setState({ selectedFont: value })
  }

  return (
    <div className='container'>
      <div className='mb-3'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header'>
              <span className='h4'>
                {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.header')}
              </span>
            </div>
            <div className='card-body'>
              <form id='id1' className='formform' noValidate>
                <div className='form-row'>
                  <div className='form-group col-md-6  '>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.title')}
                    </label>
                    <IPInput type='text' className='form-control' id='SahaDegeri' />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-6'>
                    <div className='row'>
                      <div className='col-6'>
                        <label className='labeltext'>
                          {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.startDate')}
                        </label>
                        <IPDatePicker
                          selected={startDate}
                          onChange={date => setStartDate(date as any)}
                          timeInputLabel='Saat :'
                          dateFormat='MM/dd/yyyy h:mm aa'
                          showTimeInput
                        />
                        <p className='validation-error'> </p>
                      </div>
                      <div className='col-6'>
                        <label htmlFor='stockCode' className='labeltext'>
                          {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.endDate')}
                        </label>
                        <IPDatePicker
                          selected={endDate}
                          onChange={date => setEndDate(date as any)}
                          timeInputLabel='Saat :'
                          dateFormat='MM/dd/yyyy h:mm aa'
                          showTimeInput
                        />
                        <p className='validation-error'></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.subTypes')}
                    </label>
                    <div className='d-flex pr-3'>
                      <div className='col-lg-3 mt-1'>
                        <IPRadio
                          text={t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.including')}
                          type='radio'
                          name='radio1'
                        />
                        <IPRadio
                          text={t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.excluding')}
                          type='radio'
                          name='radio1'
                        />
                      </div>
                      <div className='w-100'>
                        <IPTextarea />
                      </div>
                    </div>
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-group col-md-6 pt-1'>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.quantity')}
                    </label>
                    <IPInput type='text' className='form-control' id='SahaDegeri' />
                    <p className='validation-error'></p>
                    <label htmlFor='stockCode' className='labeltext'></label>
                    <div className='row'>
                      <div className='col-4'>
                        <IPRadio
                          text={t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.sold')}
                          type='radio'
                          name='radio1'
                        />
                      </div>
                      <div className='col-4'>
                        <IPRadio
                          text={t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.remainder')}
                          type='radio'
                          name='radio1'
                        />
                      </div>
                      <div className='col-4'>
                        <IPRadio
                          text={t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.display')}
                          type='radio'
                          name='radio1'
                        />
                      </div>
                    </div>
                    <p className='validation-error'></p>
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.clade')}
                    </label>
                    <div className='d-flex pr-3'>
                      <div className='w-100'>
                        <IPTextarea
                          placeholder={t(
                            'pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.firstProduct'
                          )}
                        />
                      </div>
                    </div>
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-group col-md-6 pt-1'>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.increaseCount')}
                    </label>
                    <IPInput type='text' className='form-control' id='SahaDegeri' />
                    <p className='validation-error'></p>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.extra')}
                    </label>
                    <div className='row'>
                      <div className='col-12'>
                        <IPInput type='text' className='form-control' id='SahaDegeri' />
                      </div>
                    </div>
                    <p className='validation-error'></p>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.type')}
                    </label>
                    <IPSelectBox
                      isClearable
                      id='DealerContents'
                      name='DealerContents'
                      options={ITargetLinkOption}
                      onChangeSingle={onTargetLinkChange}
                      value={state.selectedTargetLink}
                    />
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-group col-md-6 pt-1'>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.background')}
                    </label>
                    <IPSelectBox
                      isClearable
                      id='DealerContents'
                      name='DealerContents'
                      options={IBackgroundOption}
                      onChangeSingle={onBackgroundChange}
                      value={state.selectedBackground}
                    />
                    <p className='validation-error'></p>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6  '>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.image')}
                    </label>

                    <IPSelectBox
                      isClearable
                      id='DealerContents'
                      name='DealerContents'
                      options={ITitleOption}
                      onChangeSingle={onTitleChange}
                      value={state.selectedTitle}
                    />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.style')}
                    </label>
                    <IPSelectBox
                      isClearable
                      id='DealerContents'
                      name='DealerContents'
                      options={IFontOption}
                      onChangeSingle={onFontChange}
                      value={state.selectedFont}
                    />
                    <p className='validation-error'> </p>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6  '>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.value')}
                    </label>

                    <IPInput type='text' className='form-control' id='SahaDegeri' />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.message')}
                    </label>
                    <IPInput type='text' className='form-control' id='SahaDegeri' />
                    <p className='validation-error'> </p>
                  </div>
                </div>

                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.showTime')}
                    </label>
                    <IPInput type='text' className='form-control' id='index' />
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-group col-md-6 pt-1'>
                    <div className='row'>
                      <div className='col-6'>
                        <label htmlFor='stockCode' className='labeltext'>
                          {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.quantity')}
                        </label>
                        <IPInput type='text' className='form-control' id='SahaDegeri' />
                        <p className='validation-error'></p>
                      </div>

                      <div className='col-6'>
                        <label htmlFor='stockCode' className='labeltext'>
                          {t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.status')}
                        </label>
                        <div className='row'>
                          <div className='col-6'>
                            <IPRadio
                              text={t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.sold')}
                              type='radio'
                              name='radio1'
                            />
                          </div>
                          <div className='col-6'>
                            <IPRadio
                              text={t(
                                'pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.remainder'
                              )}
                              type='radio'
                              name='radio1'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className='validation-error'></p>
                  </div>
                </div>

                <div className='form-row  '>
                  <div className='form-group col-md-6'></div>

                  <div className='form-group col-md-6'>
                    <div className='row'>
                      <div className='col-md-3'>
                        <IPButton
                          type='button'
                          className='btn btn-primary'
                          text={t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.add')}
                        />
                      </div>
                      <div className='col-md-3'>
                        <IPButton
                          type='button'
                          className='btn btn-danger'
                          text={t('pages.admin.productOperation.stopwatchOperations.stopwatchOperationsAdd.giveUp')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StopwatchOperationsAdd
