import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IDealerForBannersRequest } from '../models/models'

export class GetDealerForBannersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GA4Reporting/DealerForBanners',
      requesterInfo,
    })
  }

  public async getDealerForBanners(request: IDealerForBannersRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
