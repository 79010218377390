import * as yup from 'yup'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'

const t = translate(Translations)
const FILE_SIZE = 12324234
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

const validations = yup.object().shape({
  BundleCode: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  CampaignDescription: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  SapCampaignCode: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  ProductDetailDescription: yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  Active: yup.boolean(),
  CompanyId: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .nullable(),
  Order: yup
    .string()
    .required(
      t('pages.admin.homeAndSiteOperation.bannersCrud.validation.requiredField') +
        ' ' +
        t('pages.admin.homeAndSiteOperation.bannersCrud.validation.serialNumber')
    ),
  Category: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .nullable(),
  Location: yup.object().required(t('pages.admin.homeAndSiteOperation.bannersCrud.validation.requiredField')),
  Counter: yup.object().nullable(),
  Link: yup
    .string()
    .min(6, t('pages.admin.homeAndSiteOperation.bannersCrud.validation.characterMustBe', ['Link', '6'])),
  Title: yup
    .string()
    .min(3, t('pages.admin.homeAndSiteOperation.bannersCrud.validation.characterMustBe', ['Başlık', '3']))
    .required(t('pages.admin.homeAndSiteOperation.bannersCrud.validation.requiredField')),
  Description: yup
    .string()
    .min(6, t('pages.admin.homeAndSiteOperation.bannersCrud.validation.characterMustBe', ['Açıklama', '6']))
    .required(t('pages.admin.homeAndSiteOperation.bannersCrud.validation.requiredField')),
  StartDate: yup.date().min(new Date()).required(''),
  EndDate: yup.date().min(new Date()).required(''),
  CariTypeCode: yup
    .number()
    .min(5, t('pages.admin.homeAndSiteOperation.bannersCrud.validation.characterMustBe', ['Cari kod', '5']))
    .required(t('pages.admin.homeAndSiteOperation.bannersCrud.validation.requiredField')),
  CariSubTypeCode: yup
    .number()
    .min(5, t('pages.admin.homeAndSiteOperation.bannersCrud.validation.characterMustBe', ['Cari alt kodu', '5']))
    .required(t('pages.admin.homeAndSiteOperation.bannersCrud.validation.requiredField')),
  Image: yup
    .mixed()
    .required(t('pages.admin.homeAndSiteOperation.bannersCrud.validation.aFileIsRequired'))
    .test('fileSize', 'File too large', value => value && value.size <= FILE_SIZE),
})

export default validations
