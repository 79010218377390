import { useEffect, useState } from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import IPInput from '../../../components/IPInput'
import './DealerSpecificPromotional.scss'
import { DealerCampaignReportService } from './services'
import Swal from 'sweetalert2'
import { DealerCampaignReportResponse, DetailModel } from './models'
import SlideItem from './SlideItem'
import { Spinner } from '../../../components/Spinner'
import { hideHeaderAtom } from '../../../atoms'
import { useAtom } from 'jotai'

interface ISortControl {
  name: string
  status: boolean
}

export const calcPercent = (current, max) => {
  return (current * 100) / max
}

export const isEarned = (obj: DealerCampaignReportResponse | DetailModel) => {
  // if (obj.IsQuantity && obj.CurrentQuantity >= obj.TargetQuantity) return true

  // if (obj.IsPrice && obj.CurrentPrice >= obj.TargetPrice) return true

  return obj.IsQuantity ? obj.CurrentQuantity >= obj.TargetQuantity : obj.CurrentPrice >= obj.TargetPrice
}

export default function DealerSpecificPromotional() {
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [campaigns, setCampaigns] = useState<DealerCampaignReportResponse[]>([])
  const [selectedCampaign, setSelectedCampaign] = useState<DealerCampaignReportResponse>()
  const [swiper, setSwiper] = useState<any>()
  const [searchInput, setSearchInput] = useState('')
  const [sortControl, setSortControl] = useState<ISortControl | null>(null)
  const [, setHideHeader] = useAtom(hideHeaderAtom)

  const handleCampaign = campaign => {
    setSelectedCampaign(campaign)
    setShowModal(true)
    swiper.slideTo(0)
  }

  useEffect(() => {
    ;(async function () {
      setLoading(true)
      try {
        const res = await new DealerCampaignReportService({}).DealerCampaignReport()
        if (!res) return setLoading(false)
        setCampaigns(res)
        setLoading(false)
      } catch (err: any) {
        setLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    if (showModal) {
      document.body.scrollTo({ top: 220 })
      document.body.style.overflow = 'hidden'
      setHideHeader(true)
      return
    }
    document.body.style.overflow = 'auto'
    setHideHeader(false)

    return () => {
      document.body.style.overflow = 'auto'
      setHideHeader(false)
    }
  }, [showModal])

  const renderArrow = name => {
    return sortControl?.name === name ? (
      sortControl?.status ? (
        <span className='arrowDown'></span>
      ) : (
        <span className='arrowUp'></span>
      )
    ) : (
      ''
    )
  }

  const sortColumn = name => {
    return { name, status: sortControl?.status ? !sortControl.status : true }
  }

  if (campaigns.length === 0) {
    return (
      <div className='dealerSpecificPromotional'>
        <div className='campaignText my-5'>Veri bulunamadı.</div>
      </div>
    )
  }

  const selectedCurrentValue = selectedCampaign?.IsQuantity
    ? selectedCampaign?.CurrentQuantity
    : selectedCampaign?.CurrentPrice
  const selectedTargetValue = selectedCampaign?.IsQuantity
    ? selectedCampaign?.TargetQuantity
    : selectedCampaign?.TargetPrice
  const selectedValueType = selectedCampaign?.IsQuantity ? 'Adet' : null

  return (
    <>
      <div className='dealerSpecificPromotional'>
        <div className='campaignText my-5'>
          <b>{campaigns.length}</b> adet hedef kampanyanız bulunmaktadır.
          {campaigns && (
            <div className='link' onClick={() => handleCampaign(selectedCampaign ? selectedCampaign : campaigns[0])}>
              Detayları görmek için buraya tıklayınız.
            </div>
          )}
        </div>

        {selectedCampaign && (
          <div className='table-responsive mb-5'>
            <table className='targetTable table'>
              <thead>
                <tr>
                  <th scope='col'>KAMPANYA ADI</th>
                  <th scope='col'>GERÇEKLEŞEN/HEDEF</th>
                  <th scope='col'>GEÇERLİLİK TARİHİ</th>
                  <th scope='col'>İŞLEM</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>{selectedCampaign.CampaignName}</td>
                  <td>
                    <div className='position-relative' style={{ height: '65px' }}>
                      <div className='progress position-relative' style={{ height: '40px' }}>
                        <div
                          className='progress-bar bg-danger'
                          role='progressbar'
                          style={{
                            width: `${calcPercent(selectedCurrentValue, selectedTargetValue)}%`,
                            height: '100%',
                          }}
                          aria-valuenow={selectedCurrentValue}
                          aria-valuemin={1}
                          aria-valuemax={selectedTargetValue}
                        ></div>
                        <div className='progressQuantity'>
                          {selectedCampaign?.IsQuantity ? selectedCurrentValue : selectedCampaign?.CurrentPriceValue}{' '}
                          {selectedValueType}
                        </div>
                      </div>
                      <div className='progressQuantityMin'>{1}</div>
                      <div className='progressQuantityMax'>
                        {selectedCampaign?.IsQuantity ? selectedTargetValue : selectedCampaign?.TargetPriceValue}{' '}
                        {selectedValueType}
                      </div>
                    </div>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {new Date(selectedCampaign?.StartDate).toLocaleDateString('tr-TR')} <br />
                    {new Date(selectedCampaign.EndDate).toLocaleDateString('tr-TR')}
                  </td>
                  <td className='position-relative'>
                    <div className='tableIcon' onClick={() => setShowModal(true)}>
                      <i className='icon-info'></i>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        <div className='table-responsive' style={{ height: 'auto' }}>
          <table className='campaignTable table table-bordered table-hover'>
            <thead>
              <tr>
                <th scope='col' onClick={() => setSortControl(sortColumn('code'))}>
                  <div className='d-flex align-items-center gap-2'>
                    KAMPANYA KODU
                    {renderArrow('code')}
                  </div>
                </th>
                <th scope='col' onClick={() => setSortControl(sortColumn('name'))}>
                  <div className='d-flex align-items-center gap-2'>
                    KAMPANYA ADI
                    {renderArrow('name')}
                  </div>
                </th>
                <th scope='col' onClick={() => setSortControl(sortColumn('startDate'))}>
                  <div className='d-flex align-items-center gap-2'>
                    BAŞLANGIÇ TARİHİ
                    {renderArrow('startDate')}
                  </div>
                </th>
                <th scope='col' onClick={() => setSortControl(sortColumn('endDate'))}>
                  <div className='d-flex align-items-center gap-2'>
                    BİTİŞ TARİHİ
                    {renderArrow('endDate')}
                  </div>
                </th>
                <th scope='col' onClick={() => setSortControl(sortColumn('totalPrice'))}>
                  <div className='d-flex align-items-center gap-2'>
                    TOPLAM HARCAMA LİMİTİ
                    {renderArrow('totalPrice')}
                  </div>
                </th>
                <th scope='col' onClick={() => setSortControl(sortColumn('totalQuantity'))}>
                  <div className='d-flex align-items-center gap-2'>
                    TOPLAM HARCAMA ADEDİ
                    {renderArrow('totalQuantity')}
                  </div>
                </th>
                <th scope='col' onClick={() => setSortControl(sortColumn('status'))}>
                  <div className='d-flex align-items-center gap-2'>
                    DURUMU
                    {renderArrow('status')}
                  </div>
                </th>
                <th scope='col'>İŞLEMLER</th>
              </tr>
            </thead>

            <tbody>
              <tr className='inputRow'>
                <td colSpan={6}>
                  <IPInput
                    type='text'
                    placeholder='Ara...'
                    value={searchInput}
                    onChange={e => setSearchInput(e.target.value)}
                  />
                </td>
              </tr>

              {campaigns
                .filter(c => {
                  const filterWord = searchInput.toLowerCase()

                  const filterStatus = isEarned(c) ? 'Hakedildi' : 'Hakedilmedi'

                  let filteredData =
                    c.CampaignName.toLowerCase().includes(filterWord) ||
                    c.CampaignCode.toLowerCase().includes(filterWord) ||
                    filterStatus.toLowerCase().includes(filterWord) ||
                    new Date(c.StartDate).toLocaleDateString('tr-TR').includes(filterWord) ||
                    new Date(c.EndDate).toLocaleDateString('tr-TR').includes(filterWord)

                  return filteredData
                })
                .sort((a, b) => {
                  if (sortControl !== null) {
                    return sortControl.status
                      ? a[sortControl.name] > b[sortControl.name]
                        ? 1
                        : -1
                      : a[sortControl.name] < b[sortControl.name]
                      ? 1
                      : -1
                  }
                  return 0
                })
                .map(campaign => (
                  <tr key={campaign.CampaignId} onClick={() => setSelectedCampaign(campaign)}>
                    <td>{campaign.CampaignCode}</td>
                    <td>{campaign.CampaignName}</td>
                    <td>{new Date(campaign.StartDate).toLocaleDateString('tr-TR')}</td>
                    <td>{new Date(campaign.EndDate).toLocaleDateString('tr-TR')}</td>
                    <td>{campaign.TotalPrice}</td>
                    <td>{campaign.TotalQuantity}</td>
                    <td>{isEarned(campaign) ? 'Hakedildi' : 'Hakedilmedi'}</td>
                    <td className='position-relative'>
                      <div
                        className='tableIcon'
                        onClick={e => {
                          e.stopPropagation()
                          handleCampaign(campaign)
                        }}
                      >
                        <i className='icon-info'></i>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <div className={`campaignModalWrapper ${showModal ? 'active' : ''}`} onClick={() => setShowModal(false)}>
          <div className='campaignModal' onClick={e => e.stopPropagation()}>
            <Swiper
              modules={[Navigation, Pagination]}
              slidesPerView={1}
              onSwiper={setSwiper}
              navigation
              pagination={{ clickable: true }}
            >
              {/* {selectedCampaign && (
                <SwiperSlide key={'c0'}>
                  <SlideItem campaign={selectedCampaign} />
                </SwiperSlide>
              )} */}
              {selectedCampaign &&
                selectedCampaign.DetailModels.length > 0 &&
                selectedCampaign.DetailModels.map((campaign: DetailModel) => (
                  <SwiperSlide key={campaign.CampaignId}>
                    <SlideItem campaign={campaign} />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
      <Spinner loading={loading} />
    </>
  )
}
