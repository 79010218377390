import React from 'react'
import { Link } from 'react-router-dom'
import classes from './List.module.scss'

const List = ({ dataList }) => {
  return (
    <div>
      {dataList.name != "" &&
        <div className={classes.menuTitle}>{dataList.name}</div>
      }
      {dataList.subMenu.map((dataListItem: any) => (
        <>
          {dataListItem.id != null &&
            <div className={classes.menuItem} key={dataListItem.id}>
              <Link className={classes.Link} to={dataListItem.link}>
                <div>{dataListItem.name}</div>
              </Link>
            </div>}

        </>
      ))}
    </div>
  )
}

export default List
