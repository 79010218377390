import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IExchangeRate } from '../models'

export class GetExchangeRatesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/currency',
      requesterInfo,
    })
  }

  public async getExhangeRates(): Promise<IExchangeRate> {
    return await this.getAsync<IExchangeRate>()
  }
}
