import React, { useEffect, useMemo, useState } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { ProductsWithoutPicturesReportApi } from '../services/productsWithoutPicturesReportApi'
import Swal from 'sweetalert2'
import { IProductsWithoutPicturesReport } from '../models/models'
import Table from '../../../../../components/Table'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ICompany } from '../../OpportunityAisleProduct/models/opportunityMarketList'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import { useCustomReducer } from '../../../../../base/customHooks'

interface ProductListState {
  companies: ICompany[]
  selectedCompany?: ISelectOption,
  //selectedStatus?: ISelectOption,
}

function ProductsWithoutPictures(props: BaseComponentWithContextProps) {
  const [pictures, setPictures] = useState<IProductsWithoutPicturesReport[]>([])

  const getInitialState = (): ProductListState => {
    return {
      companies: [],
      selectedCompany: undefined,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ProductListState>(intialState)

  useEffect(() => {
    productsWithoutPicturesReport()
  }, [state.companies, state.selectedCompany])

  const productsWithoutPicturesReport = async () => {
    props.showLoading()
    const productsWithoutPicturesReportApi: ProductsWithoutPicturesReportApi = new ProductsWithoutPicturesReportApi({})
    await productsWithoutPicturesReportApi
      .productsWithoutPicturesReport()
      .then(async result => {
        if (result) {
          var res
          if (state.selectedCompany)
            res = result.filter(company => company.CompanyId === state.selectedCompany?.value).sort((a, b) => Number(a.CompanyId) - Number(b.CompanyId))
          else
            res = (result.sort((a, b) => Number(a.CompanyId) - Number(b.CompanyId)))

          // if (state.selectedStatus)
          //   res = (res.filter(company => company.Status === state.selectedStatus?.value).sort((a, b) => Number(a.CompanyId) - Number(b.CompanyId)))
          setPictures(res)
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      }
      )
  }

  const companies = [
    { label: 'Index', value: 1 },
    { label: 'Despec', value: 3 },
    { label: 'Netex', value: 2 },
    { label: 'Datagate', value: 4 },
  ]

  // const Status = [
  //   { label: 'Aktif', value: "Aktif" },
  //   { label: 'Pasif', value: "Pasif" },
  // ]

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'col1',
      },
      {
        Header: 'Şirket',
        accessor: 'col2',
      },
      {
        Header: 'Kota Kodu',
        accessor: 'col3',
      },
      {
        Header: 'Ürün Md.',
        accessor: 'col4',
      },
      {
        Header: 'SAP Kodu',
        accessor: 'col5',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col6',
      },
      {
        Header: 'Ürün Açıklaması',
        accessor: 'col7',
      },
      // {
      //   Header: 'Durumu',
      //   accessor: 'col8',
      // }
    ],
    []
  )

  const tableData = useMemo(
    () => pictures.map(item => {
      const data = {
        col1: item.SequenceNumber,
        col2: item.CompanyId === 1 ? "Index" : item.CompanyId === 2 ? "Netex" : item.CompanyId === 3 ? "Despec" : item.CompanyId === 4 ? "Datagate" : "",
        col3: item.QuotaCode,
        col4: item.ProductMd,
        col5: item.MaterialNumber,
        col6: item.ProductCode,
        col7: item.ProductName,
        //col8: item.Status
      }

      return data
    }), [pictures])
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Resimleri Eklenmemiş Ürünler Listesi</span>
          </div>
          <div className='card-body'>
            <div className='d-flex align-items-end gap-4'>
              <div className='col-lg-2'>
                <label htmlFor='company' className='form-label'>
                  Şirket
                </label>
                <IPSelectBox
                  isClearable
                  id='Şirket'
                  name='Şirket'
                  options={companies}
                  value={state.selectedCompany}
                  onChangeSingle={(option) => setState({ selectedCompany: option })}
                />
              </div>
              {/* <div className='col-lg-2'>
                <label htmlFor='company' className='form-label'>
                  Durum
                </label>
                <IPSelectBox
                  isClearable
                  id='Durum'
                  name='Durum'
                  options={Status}
                  value={state.selectedStatus}
                  onChangeSingle={(option) => setState({ selectedStatus: option })}
                />
              </div> */}
            </div>
            <Table columns={tableColumns} data={tableData} currentPage={0} columnCount={7} textAlign={true} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(ProductsWithoutPictures)