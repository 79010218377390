import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IPostBannerRequest } from '../models/requestModels'
import { IBanner } from '../models/responseModels'

export class UpdateBannerApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/banner/put',
      requesterInfo,
    })
  }

  public async putBanner(request: any): Promise<IBanner> {
    return await this.putAsync<IBanner>(undefined, request)
  }
}
