import { IBankAdditionalInstallments } from '../models/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class ListBankAdditionalInstallmentsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/bankAdditionalInstallmentsList',
      requesterInfo,
    })
  }

  public async lisBankAdditionaltInstallemnts(): Promise<IBankAdditionalInstallments[]> {
    return await this.getAsync<IBankAdditionalInstallments[]>()
  }
}
