import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class CheckDealerCodeApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/checkDealerCode',
      requesterInfo,
    })
  }

  public async checkDealerCode(DealerCode: string): Promise<string> {
    this.props.url = `${this.props.url}?DealerCode=${DealerCode}`
    return await this.getAsync<string>()
  }
}
