import { useState } from 'react'
import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import {} from '../../../../base/utils/dataHelper'
import { default as IPImage, default as Image } from '../../../../components/IPImage/IPImage'
import { IProduct } from '../../../ProductList/models/response/getProductListResponseModel'
import './style.scss'
export interface ISwiperProps {
  product?: IProduct
}

const Slider = (props: ISwiperProps) => {
  const { product } = props

  const [currentImage, setCurrentImage] = useState<number>(0)
  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null)
  const [currentIndex, setCurrentIndex] = useState<number>(0) // Initialize with the default selected index

  const handleSlideChange = (swiper: any) => {
    setCurrentIndex(swiper.realIndex)
    setCurrentImage(swiper.activeIndex)
  }

  const calculateThumbsSlidesPerView = () => {
    const numImages = product?.Images?.length || 0
    if (numImages === 1) return 1
    else if (numImages === 2) return 2
    else if (numImages >= 3) return 3
    return 1 // Default to 1 if no images
  }

  const calculateBreakpoints = () => {
    const numImages = product?.Images?.length || 0
    const baseSlidesPerView = calculateThumbsSlidesPerView()

    return {
      0: {
        slidesPerView: Math.min(1, baseSlidesPerView),
      },
      640: {
        slidesPerView: Math.min(3, baseSlidesPerView),
      },
      768: {
        slidesPerView: Math.min(3, baseSlidesPerView),
      },
      1200: {
        slidesPerView: Math.min(3, baseSlidesPerView),
      },
    }
  }

  return (
    <div className='row flex-column'>
      {/* Main Swiper */}
      <Swiper
        // onSwiper={(e) => setSwiperFirst(e)}
        onSlideChange={handleSlideChange}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        loop={true}
        autoplay={{
          delay: 15000,
          disableOnInteraction: false,
        }}
        modules={[Navigation, Pagination, Thumbs, Autoplay, FreeMode]}
        className='mySwiper'
      >
        {product &&
          product.Images &&
          product.Images.sort((a, b) => Number(a.Description) - Number(b.Description)).map((item, index) => (
            <SwiperSlide key={index}>
              {/* Opportunity */}
              {product && product.OpportunityId !== 0 && (
                <div title='Fırsat Ürünü' className='mb-3 indicator topLeft2' style={{ marginTop: '-36px' }}>
                  <IPImage src='/images/products/box-percent.png' alt='' />
                </div>
              )}

              {/* BrandDetail */}
              {product &&
                product.BrandDetail &&
                (product && product.OpportunityId === 0 ? (
                  <div
                    className='mb-3'
                    style={{
                      height: '65px',
                      width: '65px',
                      float: 'left',
                      marginLeft: '5px',
                    }}
                  >
                    <Image
                      src={product.BrandDetail.ImageUrl}
                      alt=''
                      className=''
                      style={{
                        borderRadius: '10px',
                      }}
                    />
                  </div>
                ) : (
                  <div className='div' style={{ height: '65px', width: '65px', float: 'left', marginLeft: '5px' }}>
                    <Image
                      src={product.BrandDetail.ImageUrl}
                      alt=''
                      className=''
                      style={{
                        borderRadius: '10px',
                        marginTop: '40px',
                        marginBottom: '10px',
                      }}
                    />
                  </div>
                ))}

              {/* WarrantyMonth */}
              {product &&
                product.WarrantyMonth > 0 &&
                (product && product.OpportunityId === 0 ? (
                  <div
                    className='mb-3 hasTooltip'
                    style={{ width: '35px', position: 'absolute', top: '80px', left: '10px' }}
                  >
                    <img src='/images/products/quality2.png' alt='' />
                    <div className='otooltip'>Garanti Süresi: {product.WarrantyMonth} Ay</div>
                  </div>
                ) : (
                  <div
                    className='mb-3 hasTooltip'
                    style={{ width: '35px', position: 'absolute', top: '110px', left: '10px' }}
                  >
                    <img src='/images/products/quality2.png' alt='' />
                    <div className='otooltip'>Garanti Süresi: {product.WarrantyMonth} Ay</div>
                  </div>
                ))}

              {/* Main Image */}
              <Image
                // className={classes.mainImage}
                alt={item.ImageUrl.includes('firsatImages') ? item.Image : item.ImageUrl}
                src={item.ImageUrl.includes('firsatImages') ? item.Image : item.ImageUrl}
              />
            </SwiperSlide>
          ))}

        {/* Default Image */}
        {!product?.Image && (
          <SwiperSlide>
            {/* Opportunity */}
            {product && product.OpportunityId !== 0 && (
              <div title='Fırsat Ürünü' className='mb-3 indicator topLeft2' style={{ marginTop: '-36px' }}>
                <IPImage src='/images/products/box-percent.png' alt='' />
              </div>
            )}

            {/* BrandDetail */}
            {product &&
              product.BrandDetail &&
              (product && product.OpportunityId === 0 ? (
                <div
                  className='mb-3'
                  style={{
                    height: '65px',
                    width: '65px',
                    float: 'left',
                    marginLeft: '5px',
                    marginTop: product && product.OpportunityId === 0 ? '' : '30px',
                    marginBottom: product && product.OpportunityId === 0 ? '' : '10px',
                  }}
                >
                  <Image
                    src={product.BrandDetail.ImageUrl}
                    alt=''
                    className=''
                    style={{
                      borderRadius: '10px',
                    }}
                  />
                </div>
              ) : (
                <div
                  className='div'
                  style={{ height: '65px', width: '65px', float: 'left', marginLeft: '5px', marginTop: '40px' }}
                >
                  <Image
                    src={product.BrandDetail.ImageUrl}
                    alt=''
                    className=''
                    style={{
                      borderRadius: '10px',
                    }}
                  />
                </div>
              ))}

            {/* WarrantyMonth */}
            {product &&
              product.WarrantyMonth > 0 &&
              (product && product.OpportunityId === 0 ? (
                <div
                  className='mb-3 hasTooltip'
                  style={{ width: '35px', position: 'absolute', top: '80px', left: '10px' }}
                >
                  <img src='/images/products/quality2.png' alt='' />
                  <div className='otooltip'>Garanti Süresi: {product.WarrantyMonth} Ay</div>
                </div>
              ) : (
                <div
                  className='mb-3 hasTooltip'
                  style={{ width: '35px', position: 'absolute', top: '110px', left: '10px' }}
                >
                  <img src='/images/products/quality2.png' alt='' />
                  <div className='otooltip'>Garanti Süresi: {product.WarrantyMonth} Ay</div>
                </div>
              ))}
            <Image alt='null' src='./../../images/products/Resim_yok-01.jpg' />
          </SwiperSlide>
        )}
      </Swiper>

      {/* Thumbnail Swiper */}
      {product?.Images && product.Images.length >= 1 && (
        <div className='row justify-content-center' style={{ padding: '0 190px' }}>
          {product.OpportunityId !== 0 && (
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Ürün Görselleri Temsilidir.</p>
          )}
          <Swiper
            navigation={true}
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={10}
            slidesPerView={calculateThumbsSlidesPerView()}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className='mySwiper my-3'
            breakpoints={calculateBreakpoints()}
          >
            {product.Images.map((item, index) => (
              <SwiperSlide key={index}>
                <div
                  style={{
                    cursor: 'pointer',
                    width: '100px',
                    height: '100px',
                    border: currentIndex === index ? '1px solid #857d7d' : '1px solid #dee2e6',
                  }}
                  onClick={() => {
                    setCurrentIndex(index)
                    setCurrentImage(index)
                  }}
                  className={`align-items-center`}
                >
                  <Image
                    alt={item.ImageUrl.includes('firsatImages') ? item.Image : item.ImageUrl}
                    src={item.ImageUrl.includes('firsatImages') ? item.Image : item.ImageUrl}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  )
}

export default Slider

// const [swiperFirst, setSwiperFirst] = useState<any>()
// const [tmpImage, setTmpImage] = useCustomReducer({
//   Description: '',
//   Id: 0,
//   Image: '',
//   ImageUrl: '',
//   IsCover: false,
//   Label: '',
// })
