import MainLayout from '../layouts/AdminLayout'
import { withLogin } from './withLoginHOC'
import 'datatables.net'

const WithAdminLayout = (props: any) => {
  return (
    <>
      <MainLayout />
      {props.children}
    </>
  )
}

export default withLogin(WithAdminLayout)
