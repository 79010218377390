import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useCustomReducer } from '../../../../../base/customHooks'
import { translate } from '../../../../../base/localization'
import { IAuthorizedServicesProcedur, ProcedureDescription } from '../models/models'
import { DeleteAuthorizedServicesProcedurApi } from '../services/deleteAuthorizedServicesProcedurApi'
import { GetAuthorizedServicesProcedurApi } from '../services/getAuthorizedServicesProcedurApi'
import IPModal from '../../../../../components/IPModal'
import Translations from '../../../../../translations'
import Swal from 'sweetalert2'
import { PutAuthorizedServicesProcedurApi } from '../services/putAuthorizedServicesApi'
import _ from 'lodash'

interface IModal {
  showDescription: boolean
}

export default function ListAuthorizedProcedure() {
  const t = translate(Translations)
  const navigate: any = useNavigate()

  const getInitialState = (): IModal => {
    return {
      showDescription: false,
    }
  }

  const intialState = getInitialState()
  const tableRef = useRef<any>()
  const [state, setState] = useCustomReducer<IModal>(intialState)
  const [des, setDes] = useState<ProcedureDescription[]>([])
  const [brand, setBrand] = useState('')
  const [authorizedServicesProcedur, setAuthorizedServicesProcedur] = useState<IAuthorizedServicesProcedur[]>([])

  const getAuthorizedServices = async () => {
    const getAuthorizedServicesProcedur: GetAuthorizedServicesProcedurApi = new GetAuthorizedServicesProcedurApi({})
    const authorizedServicesProcedur = await getAuthorizedServicesProcedur.getAuthorizedServicesProcedur()
    setAuthorizedServicesProcedur(authorizedServicesProcedur as any)
  }
  const update = async (row: any) => {
    var datas = row?.data()
    var data = authorizedServicesProcedur.find(auth => {
      return auth.Id === datas.Id
    })
    if (data != undefined) {
      const clnValues = _.clone(data)
      clnValues.Enable = !clnValues.Enable
      const putAuthorizedServicesProcedur: PutAuthorizedServicesProcedurApi = new PutAuthorizedServicesProcedurApi({})
      putAuthorizedServicesProcedur
        .putAuthorizedServicesProcedur(clnValues)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Yetkili Servis Güncellendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }
  }
  useEffect(() => {
    getAuthorizedServices()
  }, [])

  const deleteAuthorizedServicesProcedur = async (row: any) => {
    const data = row?.data()
    const id = data?.Id

    if (!data || !id) {
      return
    }

    Swal.fire({
      icon: 'question',
      title: 'Yetkili servis prosedürünü silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteAuthorizedServicesProcedurApi = new DeleteAuthorizedServicesProcedurApi({})
        deleteAuthorizedServicesProcedurApi.deleteAuthorizedServicesProcedur(id).then(async res => {
          if (res) {
            Swal.fire({
              icon: 'success',
              title: 'Yetkili servis prosedürü Silindi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(() => {
              row?.remove().draw(true)
            })
          }
        })
      }
    })
  }

  useEffect(() => {
    let dt: any

    if (authorizedServicesProcedur && authorizedServicesProcedur.length > 0) {
      dt = syncTable(authorizedServicesProcedur)
      tableRef.current = dt
    }

    $('#datatb tbody').on('click', 'button', function () {
      var data = dt.row($(this).parents('tr')).data()
      setDes(data.Description)
      setState({ showDescription: true })
      setBrand(data.BrandName)
    })
    $('#datatb tbody').on('change', 'input', function () {
      const row = dt.row($(this).parents('tr'))
      update(row)
    })
    $('#datatb tbody').on('click', 'a', function () {
      const actionType: 'edit' | 'delete' = this.getAttribute('data-action-type')
      const row = dt.row($(this).parents('tr'))
      var data = row?.data()

      if (!row || !data) {
        return
      }

      if (actionType === 'delete') {
        deleteAuthorizedServicesProcedur(row)
      } else if (actionType === 'edit') {
        var datas = authorizedServicesProcedur.find(auth => {
          return auth.Id === data?.Id
        })
        navigate('/admin/brandPageOperation/authorizedProcedure/addAuthorizedProcedure', {
          state: { authorizedServicesProcedurData: datas },
        })
      }
    })

    return function () {
      dt && dt.destroy()
    }
  }, [authorizedServicesProcedur])

  const syncTable = (datas: IAuthorizedServicesProcedur[]) => {
    const dt = $(tableRef.current).DataTable({
      pageLength: 10,
      data: datas.map(dt => {
        return {
          Id: dt.Id,
          Enable: dt.Enable,
          BrandName: dt.Brand.Name,
          OrderNo: dt.OrderNo,
          StartDate: new Date(dt.StartDate).toLocaleDateString('tr-TR'),
          EndDate: new Date(dt.EndDate).toLocaleDateString('tr-TR'),
          Description: dt.Description,
        }
      }),
      columns: [
        {
          title: 'Aktif',
          data: 'Enable',
          render: (data: any) => {
            if (data) {
              return `  <label class="p-switch p-switch-sm switch-color-success">
                                <input
                                  type="checkbox"
                                  name="switch"
                                  checked
                                />
                                <span class="p-switch-style"></span>
                              </label>`
            } else {
              return `  <label class="p-switch p-switch-sm switch-color-success">
                                <input
                                  type="checkbox"
                                  name="switch"
                                />
                                <span class="p-switch-style"></span>
                              </label>`
            }
          },
        },
        {
          title: 'Marka',
          data: 'BrandName',
        },
        {
          title: 'Sıra',
          data: 'OrderNo',
        },
        {
          title: 'Açıklama',
          data: 'Description',
          render: (data: any) => {
            return ` <button type="text" style="background-color:red ; min-width: 130px;font-size: 16px;
                      font-weight: 600;
                      color: #fff;border: none;
                      outline: none;
                      background-color: #ec4c4c;	width: 100%;
                      height: 44px;display: flex;
                      align-items: center;
                      justify-content: center;">Detay</button>`
          },
        },
        {
          title: 'Başlangıç Tarihi',
          data: 'StartDate',
        },
        {
          title: 'Bitiş Tarihi',
          data: 'EndDate',
        },
        {
          title: 'İşlemler',
          data: 'Id',
          render: (data: any) => {
            return `<a data-action-type="edit" role="button" style="cursor:pointer" data-toggle="tooltip" data-original-title={t('pages.mySpecial.userUpdate.edit')}>
              <i class='icon-edit' style="font-size:1.2rem; color:green"></i></a>
              <a data-action-type="delete" role="button" style="cursor:pointer" data-toggle="tooltip" data-original-title={t('pages.mySpecial.userUpdate.delete')}>
              <i class='icon-trash-2' style="font-size:1.2rem;color:red"></i></a>`
          },
        },
      ],
    } as any)

    return dt
  }

  return (
    <React.Fragment>
      <div className='container'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header'>
              <span className='h4'>Yetkili Servis Prosedürleri Marka Bazında</span>
              <div className='button m-r-20'>
                <Link to='/admin/brandPageOperation/authorizedProcedure/addAuthorizedProcedure'>
                  <button type='button' className='btn btn-primary'>
                    Ekle
                  </button>
                </Link>
              </div>
            </div>
            {/* Buton ve boşluk düzenlemsi yapıldı. */}
            <div className='card-body'>
              {/* <form className='formbanner'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <label htmlFor='location' className='form-label'>
                      <br></br>
                    </label>
                    <div className='button m-r-20'>
                      <Link to='/admin/brandPageOperation/authorizedProcedure/addAuthorizedProcedure'>
                        <button type='button' className='btn btn-primary'>
                          Ekle
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </form> */}
              <div className='m-4 text-nowrap'>
                <table id={'datatb'} ref={tableRef} className='table'></table>
              </div>
            </div>
          </div>
          <IPModal
            show={state.showDescription}
            onClose={() => setState({ showDescription: false })}
            hideConfirm={true}
            width={'auto'}
            title={brand + ' YETKİLİ SERVİS PROSEDÜRÜ'}
          >
            <div>
              {des &&
                des.map((item: ProcedureDescription) => (
                  <div key={item.Id}>
                    <h6>
                      <b>{item.Name}: </b>
                      {item.Detail}
                    </h6>
                  </div>
                ))}
            </div>
          </IPModal>
        </div>
      </div>
    </React.Fragment>
  )
}
