import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class ProductSubTypeListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/productSubTypeList',
      requesterInfo,
    })
  }

  public async productSubTypeList(): Promise<[]> {
    return await this.getAsync<[]>()
  }
}
