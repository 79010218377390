import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IXmlCompanyParameters } from '../models/models'

export class GetXmlCompanyParametersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/GetXmlCompanyParameters',
      requesterInfo,
    })
  }

  public async getXmlCompanyParameters(): Promise<IXmlCompanyParameters[]> {
    return await this.getAsync<IXmlCompanyParameters[]>()
  }
}
