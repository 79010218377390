import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IProductCompareExcelRequest, IProductCompareExcelResponse } from '../models/productCompareExcel'

export class PostProductCompareExcelApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'excel/ProductCompare',
      requesterInfo,
    })
  }

  public async postProductCompareExcel(
    productIdList: IProductCompareExcelRequest[]
  ): Promise<IProductCompareExcelResponse> {
    return await this.postAsync<IProductCompareExcelResponse>(productIdList)
  }
}
