import { css } from '@emotion/css'
import moment from 'moment'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import * as XLSX from 'xlsx'
import { useCustomReducer } from '../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import IPButton from '../../../../components/IPButton'
import ExcelButton from '../../../../components/IPExcelButton/IPExcelButton'
import IPInput from '../../../../components/IPInput'
import MailButton from '../../../../components/IPMailButton'

import IPSelectBox, { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../components/InputCampaign'
import Table from '../../../../components/Table'
import { PeriodicTurnoverSendMailRequest } from '../../Reports/PeriodicReport/models'
import { PeriodicReportService } from '../../Reports/PeriodicReport/services'
import { GetUsersApi } from '../../UserUpdate/services/getUsersApi'
import { ILicenseTracking } from '../models/license'
import { GetLicenseApi } from '../services/getLicenseApi'
import IPModal from '../../../../components/IPModal'
interface MailState {
  showMailModal: boolean
  excelFile: string | null
  mail: string
  base64String: string
  fileName: string | null
  userMailLists?: string[]
}

function Microsoft(props: BaseComponentWithContextProps) {
  const getInitialState = (): MailState => {
    return {
      showMailModal: false,
      excelFile: null,
      mail: '',
      base64String: '',
      fileName: null,
      userMailLists: [],
    }
  }
  const [myLicense, setMyLicense] = useState<ILicenseTracking[]>([])
  const [filteredList, setFilteredList] = useState<ILicenseTracking[]>([])
  const intialState = getInitialState()

  const [state, dispatchState] = useCustomReducer<MailState>(intialState)

  const [cumtomerSelectOptions, setcumtomerSelectOptions] = useState<ISelectOption[]>([])
  const [cumtomerSelectOption, setcumtomerSelectOption] = useState<ISelectOption[]>([])
  const [orderNoFilter, setOrderNoFilter] = useState('')
  const [productCodeFilter, setProductCodeFilter] = useState('')
  const [mailSelectOptions, setMailSelectOptions] = useState<ISelectOption[]>([])
  const [mailSelectOption, setMailSelectOption] = useState<ISelectOption[]>([])

  //#region Tarih
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }
  //#endregion

  const getLicenseTracking = async () => {
    props.showLoading()
    const getLicenseTracking: GetLicenseApi = new GetLicenseApi({})
    getLicenseTracking
      .getLicense()
      .then(result => {
        if (result) {
          const uniqueCustomerNamesLabels = {}

          const customerOptions = result.map(x => ({
            value: x.MUSTERI_ADI,
            label: x.MUSTERI_ADI.toUpperCase(),
          }))
          setcumtomerSelectOptions(
            customerOptions.filter(option => {
              if (!uniqueCustomerNamesLabels[option.label]) {
                uniqueCustomerNamesLabels[option.label] = true
                return true
              }
              return false
            })
          )

          setMyLicense(result.sort((a, b) => Number(b.SIP_NO) - Number(a.SIP_NO)))
          setFilteredList(result.sort((a, b) => Number(b.SIP_NO) - Number(a.SIP_NO)))

          props.hideLoading()
        }
      })
      .catch((err: any) => {
        if (!err.description || err.description === 'OK') {
          Swal.fire({
            icon: 'error',
            title: 'Veri bulunamadı',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }

        props.hideLoading()
      })
    return
  }

  useEffect(() => {
    getLicenseTracking()
  }, [])

  const columnDefinitions = [
    { Header: 'Müşteri Adı', accessor: 'col1' },
    { Header: 'Ürün Kodu', accessor: 'col2' },
    { Header: 'Açıklama', accessor: 'col3' },
    { Header: 'Ürün Anahtarı', accessor: 'col4' },
    { Header: 'Sipariş No', accessor: 'col5' },
    { Header: 'Satın Alma Tarihi', accessor: 'col6' },
  ]

  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableData = useMemo(
    () =>
      filteredList.map(item => {
        const data = {
          col1: item.MUSTERI_ADI,
          col2: item.URUN_KODU,
          col3: item.ACIKLAMA,
          col4: item.URUN_ANAHTARI,
          col5: item.SIP_NO,
          col6: moment(item.SATIN_ALMA_TARIHI, 'DD.MM.YYYY').format('DD.MM.YYYY'),
        }
        return data
      }),
    [filteredList]
  )

  //#region Excel'e aktarma
  const excelButton = async () => {
    try {
      if (filteredList) {
        const data: any[] = []
        if (filteredList.length > 0) {
          filteredList.map(item => {
            return data.push({
              'Müşteri Adı': item.MUSTERI_ADI,
              'Ürün Kodu': item.URUN_KODU,
              Açıklama: item.ACIKLAMA,
              'Ürün Anahtarı': item.URUN_ANAHTARI,
              'Sipariş No': item.SIP_NO,
              'Satın Alma Tarihi': moment(item.SATIN_ALMA_TARIHI, 'DD.MM.YYYY').format('DD.MM.YYYY'),
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Microsoft ESD Ürünleri')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }
  //#endregion

  // Helper function to parse 'dd.MM.yyyy HH:mm:ss' format to a valid Date object
  const parseCustomDate = dateStr => {
    const [datePart, timePart] = dateStr.split(' ') // Split the date and time
    let [day, month, year] = datePart.split('.') // Split the day, month, and year

    // Pad single-digit day and month with leading zeros
    if (day.length === 1) day = '0' + day
    if (month.length === 1) month = '0' + month

    // Create a new date string in the format 'yyyy-MM-ddTHH:mm:ss'
    const formattedDateStr = `${year}-${month}-${day}T${timePart}`

    return new Date(formattedDateStr) // Return the correctly formatted Date object
  }

  //#region Detaylı Süzme fonksiyonu
  const filterList = () => {
    const filterConditions: ((item: ILicenseTracking) => boolean)[] = []

    if (cumtomerSelectOption.length > 0) {
      filterConditions.push(b => {
        const selectedLabels = cumtomerSelectOption.map(option => option.label.toUpperCase())
        return selectedLabels.includes(b.MUSTERI_ADI.toUpperCase())
      })
    }

    if (orderNoFilter) {
      filterConditions.push(b => b.SIP_NO.includes(orderNoFilter.trim()))
    }

    if (productCodeFilter) {
      filterConditions.push(b => b.URUN_KODU.includes(productCodeFilter.trim()))
    }

    // Add date filter condition
    if (startDate && endDate) {
      filterConditions.push(b => {
        const itemDate = parseCustomDate(b.SATIN_ALMA_TARIHI)
        const start = new Date(startDate)
        const end = new Date(endDate)

        return itemDate >= start && itemDate <= end
      })
    }

    let filteredData: ILicenseTracking[] = myLicense

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
    }
    setFilteredList(filteredData)
  }
  //#endregion

  // Reset Filters Function
  const resetFilters = () => {
    setcumtomerSelectOption([])
    setOrderNoFilter('')
    setProductCodeFilter('')
    setStartDate(null)
    setEndDate(null)
    setFilteredList(myLicense) // Reset to original data
  }

  //#region Mail Gönderme
  const api = new PeriodicReportService({})

  const sendMail = async (mailData: {
    to: string // Get the email address from state
    subject: string // Set a default subject
    body: string // Set a default email body
    attachments: {
      fileName: string | null // Get the file name from state
      content: string // Get the base64 content from state
      encoding: string
    }[]
  }) => {
    // Extract values from state
    const { mail, base64String, fileName } = state

    // Validate input
    if (!mail || !base64String) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen bir e-posta ve dosya yükleyin.',
        allowOutsideClick: true,
        backdrop: true,
      })
      return
    }

    // Create the request object
    const req: PeriodicTurnoverSendMailRequest = {
      Base64Data: base64String,
      MailAddress: mail,
    }

    // Show loading indicator
    props.showLoading()

    try {
      // Send the email using the API
      const res = await api.LicenseTrackingSendMail(req)

      if (!res) {
        props.hideLoading()
        return
      }

      // Clear state and close modal after successful send
      dispatchState({ showMailModal: false, mail: '', base64String: '', fileName: '' })

      // Show success message
      Swal.fire({
        icon: 'success',
        title: 'Mail başarıyla gönderildi.',
        allowOutsideClick: true,
        backdrop: true,
      })
    } catch (err: any) {
      // Handle errors
      Swal.fire({
        icon: 'error',
        title: err.description || 'Mail gönderimi sırasında bir hata oluştu.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    } finally {
      // Hide loading indicator
      props.hideLoading()
    }
  }

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    const reader = new FileReader()

    reader.onload = e => {
      const base64 = e.target?.result as string
      // Save base64 string and file name separately in the state
      dispatchState({
        base64String: base64.split('base64,')[1], // Store base64 string
        fileName: file?.name, // Store the file name
      })
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  // This function should return a Blob
  const exportToXLSX = (data: any[], fileName: string): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      try {
        const ws = XLSX.utils.json_to_sheet(data)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')

        // Generate the file as a Blob
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })

        resolve(blob) // Resolve with the Blob
      } catch (error) {
        reject(error)
      }
    })
  }

  const generateExcelAndShowModal = async () => {
    try {
      getUsers()

      if (filteredList && filteredList.length > 0) {
        const data = filteredList.map(item => ({
          'Müşteri Adı': item.MUSTERI_ADI,
          'Ürün Kodu': item.URUN_KODU,
          Açıklama: item.ACIKLAMA,
          'Ürün Anahtarı': item.URUN_ANAHTARI,
          'Sipariş No': item.SIP_NO,
          'Satın Alma Tarihi': moment(item.SATIN_ALMA_TARIHI, 'DD.MM.YYYY').format('DD.MM.YYYY'),
        }))

        // Export the data to XLSX file (this function should return the generated file)
        const excelFile = await exportToXLSX(data, 'Microsoft ESD Ürünleri')

        // Convert the file to base64
        const reader = new FileReader()
        reader.onload = e => {
          const base64 = e.target?.result as string

          // Update the state to include base64String and fileName
          dispatchState({
            base64String: base64.split('base64,')[1], // Extract the base64 string
            fileName: 'Microsoft_ESD_Urunleri.xlsx', // Set a file name
            showMailModal: true, // Open the modal
          })
        }

        reader.readAsDataURL(excelFile)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Excele atılacak veri bulunmamaktadır.',
          allowOutsideClick: true,
          backdrop: true,
        })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description || 'Bir hata oluştu.',
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }
  //#endregion

  const getUsers = async () => {
    const getUsersApi: GetUsersApi = new GetUsersApi({})

    try {
      const res = await getUsersApi.getUsers()
      if (res) {
        // Map the response to a string array of emails
        const emailList: string[] = res
          .map(user => user.Email)
          .filter((email: string | null) => email !== null && email.trim() !== '') // Ensure proper type handling

        console.log(emailList)
        const uniqueMailLabels = {}

        // Extract options for CampaignSelect and BankSelect if isBankGroup is true
        const mailOptions = res.map(x => ({
          value: x.Email,
          label: x.Email,
        }))
        setMailSelectOptions(
          mailOptions
            .filter(option => option.label.trim() !== '' && option.value !== null)
            .filter(option => {
              if (!uniqueMailLabels[option.label]) {
                uniqueMailLabels[option.label] = true
                return true
              }
              return false
            })
        )
        dispatchState({ userMailLists: emailList }) // Set the state to the email list
      }
    } catch (error) {
      console.error('Failed to fetch users:', error)
      // Optionally handle the error here, such as showing a notification
    }
  }

  return (
    <>
      <div className='row'>
        <div className='p-4 d-flex justify-content-between align-items-center'>
          <span className='h4 mb-0'>Microsoft ESD Ürünleri</span>
          <div className='d-flex align-items-center'>
            <MailButton onClick={generateExcelAndShowModal} />
            {/* <MailButton onClick={() => dispatchState({ showMailModal: true })} /> */}
            <ExcelButton onClick={excelButton} />
          </div>
        </div>
        {/* Filtre Seçenekleri */}
        <div
          className='detailFilter'
          style={{
            marginTop: '10px',
            //border: '2px solid #9c9cf1',
            padding: '10px 5px 0px 5px',
          }}
        >
          <div className='row justify-content-center'>
            <div className='form-group col-md-4'>
              <IPSelectBox
                isClearable
                isMulti
                isCreatableSelect
                isSearchable
                placeholder='Müşteri Seçiniz...'
                options={cumtomerSelectOptions}
                value={cumtomerSelectOption}
                onChangeMulti={option => {
                  setcumtomerSelectOption(option)
                }}
              />
            </div>
            <div className='form-group col-md-3'>
              <IPInput
                type='text'
                id='Title'
                className='form-control'
                name='Title'
                placeholder='Sipariş No...'
                onChange={e => setOrderNoFilter(e.target.value)}
                value={orderNoFilter}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    filterList()
                  }
                }}
              />
            </div>{' '}
            <div className='form-group col-md-3'>
              <IPInput
                type='text'
                id='Title'
                className='form-control'
                name='Title'
                placeholder='Ürün Kodu...'
                onChange={e => setProductCodeFilter(e.target.value)}
                value={productCodeFilter}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    filterList()
                  }
                }}
              />
            </div>
            <div className='form-group col-md-3'>
              <InputCampaign
                type='date'
                id='StartDate'
                name='StartDate'
                label={'Başlangıç Tarihi'}
                value={startDate ? toDateTimeLocal(startDate) : ''}
                onChange={e => getDate(e.target.value, 'Start')}
                isActive
              />
            </div>
            <div className='form-group col-md-3'>
              <InputCampaign
                type='date'
                id='EndDate'
                name='EndDate'
                label={'Bitiş Tarihi'}
                value={endDate ? toDateTimeLocal(endDate) : ''}
                onChange={e => getDate(e.target.value, 'End')}
                isActive
              />
            </div>
          </div>
          <div className='row justify-content-center'>
            <div className='form-group col-md-3'>
              <IPButton
                type='button'
                className='btn btn-danger'
                text='Ara'
                onClick={filterList}
                style={{ height: '50px' }}
              />
            </div>
            {/* Reset Button */}
            <div className='form-group col-md-3'>
              <IPButton
                type='button'
                className='btn btn-secondary'
                text='Filtreyi Temizle'
                onClick={resetFilters}
                style={{ height: '50px' }}
              />
            </div>
          </div>
        </div>

        {/* Tablo */}
        <div className='col-lg-12 text-nowrap'>
          <div>
            <div className='row'>
              <div className='m-4'>
                <Table
                  columns={tableColumns}
                  data={tableData}
                  currentPage={0}
                  columnCount={6}
                  backgroundColor='red'
                  color='white'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={state.showMailModal}
        onClose={() => dispatchState({ showMailModal: false })}
        onConfirm={() => {
          const mailData = {
            to: state.mail, // Get the email address from state
            subject: 'Excel Dosyası', // Set a default subject
            body: 'Lütfen ekli excel dosyasını inceleyin.', // Set a default email body
            attachments: [
              {
                fileName: state.fileName, // Get the file name from state
                content: state.base64String, // Get the base64 content from state
                encoding: 'base64', // Set the encoding type
              },
            ],
          }

          sendMail(mailData) // Call the sendMail function with the constructed mailData
        }}
        confirmText='Mail Gönder'
        width={450}
        maxWidth={1440}
        bodyOverflow={false}
      >
        <div className='row m-5 p-5'>
          <div className='form-group col-md-12' style={{ overflow: 'visible' }}>
            <IPSelectBox
              isClearable
              //isMulti
              menuShouldScrollIntoView
              isCreatableSelect
              isSearchable
              placeholder='Mail Adresi Seçiniz...'
              options={mailSelectOptions}
              value={mailSelectOption}
              onChangeMulti={option => {
                setMailSelectOption(option)
                dispatchState({ mail: option.map(x => x.value).join(',') })
              }}
            />
          </div>
          <div className='form-group col-md-5'>
            <input type='file' id='fileInput' onChange={handleFileChange} style={{ display: 'none' }} />
            <label
              htmlFor='fileInput'
              className={css`
                padding: 10px;
                background-color: #f2f2f2;
                color: #555;
                border: 1px solid #ccc;
                cursor: pointer;
                border-radius: 4px;

                &:hover {
                  background-color: #e0e0e0;
                }
              `}
            >
              {state.base64String ? (
                <>
                  Excel Yüklendi{' '}
                  <span
                    onClick={e => {
                      e.stopPropagation()
                      dispatchState({ base64String: '' })
                    }}
                    className={css`display: inline-flex; align-items: center; justify-content: center; font-size: 14px: border: 1px solid #666; padding: 0.5rem`}
                  >
                    x
                  </span>
                </>
              ) : (
                'Excel Yükle'
              )}
            </label>
          </div>
          <div className='form-group col-md-7'>
            <span className='text-muted' style={{ textAlignLast: 'center', fontSize: '12px' }}>
              * Mevcut Tablo Excel dosyası olarak yüklendi.
            </span>
          </div>
        </div>
      </IPModal>
    </>
  )
}
export default componentWithContext(Microsoft)
