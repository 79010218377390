import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ICurrentLogo } from '../models/models'

export class GetCurrentLogoApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealer/dealerLogoListing',
      requesterInfo,
    })
  }

  public async getCurrentLogo(): Promise<ICurrentLogo[]> {
    return await this.getAsync<ICurrentLogo[]>()
  }
}
