import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { INeverUntouchedOrdersRequest, INeverUntouchedOrdersResponse } from '../models/models'

export class NeverUntouchedOrdersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/neverUntouchedOrders',
      requesterInfo,
    })
  }

  public async neverUntouchedOrders(request: INeverUntouchedOrdersRequest): Promise<INeverUntouchedOrdersResponse[]> {
    return await this.postAsync<INeverUntouchedOrdersResponse[]>(request)
  }
}
