import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ISendProductDetailMailRequest } from '../models/productDetailMailRequest'

export class SendProductDetailMailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/sendProductDetailMail',
      requesterInfo,
    })
  }

  public async sendProductDetailMail(req: ISendProductDetailMailRequest): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
