import { ErrorMessage, useFormik } from 'formik'
import React from 'react'
import { valueContainerCSS } from 'react-select/dist/declarations/src/components/containers'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPTextarea from '../../../../../components/IPTextarea'
import { useCustomReducer } from '../../../../../base/customHooks/customReducer'
import { SelectionState } from 'draft-js'

interface IListXMLState {
  selectedCompany?: ISelectOption
}

function AddXmlUpdate() {
  const getInitialState = (): IListXMLState => {
    return {}
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListXMLState>(intialState)

  const initialValues = Object.assign({
    Enable: false,
    Eposta: false,
    GRP: false,
    IDX: false,
    DGT: false,
    DGX: false,
    NTX: false,
    DPC: false,
  })
  const onXMLChange = (value: ISelectOption) => {
    setState({ selectedCompany: value })
  }

  const XMLOptions: ISelectOption[] = [
    {
      value: '1',
      label: 'GRP',
    },
    {
      value: '2',
      label: 'IDX',
    },
    {
      value: '3',
      label: 'DGT',
    },
    {
      value: '4',
      label: 'DGX',
    },
    {
      value: '5',
      label: 'NTX',
    },
    {
      value: '6',
      label: 'DPC',
    },
  ]

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: async () => { },
  })

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>XML BAYİ TANIMLAMA</span>
          </div>
          <div className='card-body'>
            <form id='form1' className='form-validate' noValidate>
              <div className='form-row'>
                <div className='col-lg-6'>
                  <label className='labeltext'>Cari Kod</label>
                  <IPInput type='text' id='Title' className='form-control' name='Title' />
                  <p className='validation-error'> {errors.Title && touched.Title ? errors.Title : null}</p>
                </div>
                <div className=' col-lg-6'>
                  <label htmlFor='location' className='form-label'>
                    E-mail
                    <span className='required'>*</span>
                  </label>
                  <div>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='Email'
                      value={values.Email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className='form-row'>
                <div className='col-lg-6'>
                  <label className='labeltext'>Vergi Numarası</label>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='verginumarası'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className='col-lg-6'>
                  <label className='labektext'>IP</label>
                  <IPInput type='text' name='IP' className='form-control' onChange={handleChange} onBlur={handleBlur} />
                </div>
              </div>
              <div className='form-row'>
                <div className='col-lg-6'>
                  <label className='labeltext'>Notlar</label>
                  <div className='w-100'>
                    <IPTextarea />
                  </div>
                </div>
                <div className='form-group col-md-3 mt-5'>
                  <div className='row'>
                    <div className='col-md-2 '>
                      <label htmlFor='Etkin' className='labeltext'>
                        Etkin
                      </label>
                    </div>
                    <div className='col-md-6'>
                      <IPCheckbox name='Enable' onChange={handleChange} checked={values.Enable} />
                    </div>
                  </div>
                </div>
                <div className='form-group col-md-3 mt-5'>
                  <div className='row'>
                    <div className='col-md-4 '>
                      <label htmlFor='Epostagonder' className='labeltext'>
                        E-Posta Gönder
                      </label>
                    </div>
                    <div className='col-md-6'>
                      <IPCheckbox name='Eposta' onChange={handleChange} checked={values.Eposta} />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col-6'>
                  <label className='labeltext'>XML Şirketleri</label>
                  <IPSelectBox
                    isMulti
                    isClearable
                    id='Contents'
                    name='Contents'
                    options={XMLOptions}
                    onChangeSingle={onXMLChange}
                    value={state.selectedCompany}
                  />
                </div>
              </div>
              <div className='btnstyle'>
                <br />
                <IPButton
                  type='button'
                  onClick={() => handleSubmit()}
                  className='btn btn-success float-center'
                  text='Ekle'
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddXmlUpdate
