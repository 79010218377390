import { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import IPButton from '../../../../components/IPButton'
import IPCustomCheckbox from '../../../../components/IPCustomCheckBox'
import IPInput from '../../../../components/IPInput'
import IPModal from '../../../../components/IPModal'
import IPSelectBox from '../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { IOrderReturnRequest } from '../../MyOrders/models/request/getMyOrderRequest'
import { IGetOrderDetailsResponse } from '../../MyOrders/models/response/getOrderListResponseModel'
import { GetOrderDetailsApi } from '../../MyOrders/services/getOrderDetails'
import { IOrderReturn, IReturnForm, ISelectDetails } from '../models/models'
import '../MyReturns.scss'
import { PostOrderReturnApi } from '../services/postOrderReturnApi'

interface IReturnFormState {
  showModal: boolean
  orderDetails: IGetOrderDetailsResponse[]
  orderDetail: IGetOrderDetailsResponse[]
  returnQuantity?: ISelectOption
  returnQuantityOption: ISelectOption[]
  orderReturnProductState?: ISelectOption
  deliveryType?: ISelectOption
  returnPrice: number
  orderReturnReason?: ISelectOption
  returnDescription: string
  birimTutar: number
  returnIndex: number
  returnData: IReturnForm[]
  id: number[]
  productCode: string
  totalPrice: number
  belgeNo: string
  first: boolean
  productCodeInput: string
}

function ReturnForm(props: BaseComponentWithContextProps) {
  const getInitialState = (): IReturnFormState => {
    return {
      showModal: false,
      orderDetails: [],
      returnQuantityOption: [],
      returnPrice: 0,
      returnDescription: '',
      birimTutar: 0,
      returnIndex: 0,
      returnData: [],
      id: [],
      productCode: '',
      totalPrice: 0,
      belgeNo: '',
      first: true,
      productCodeInput: '',
      orderDetail: [],
    }
  }

  const orderReturnReasonOptions = [
    {
      value: 0,
      label: 'Depoda Bekleyen Ürün',
    },
    {
      value: 1,
      label: 'Ayıplı Ürün',
    },
    {
      value: 2,
      label: 'Yanlış Ürün',
    },
    {
      value: 3,
      label: 'Yanlış Sipariş Verilmesi',
    },
    {
      value: 4,
      label: 'Siparişin Yanlış Çıkarılması',
    },
    {
      value: 5,
      label: 'Siparişten Vazgeçilmesi',
    },
    {
      value: 6,
      label: 'Miktar Hatası',
    },
    {
      value: 7,
      label: 'Uyumsuz Ürün',
    },
    {
      value: 8,
      label: 'Yanlış Katalog Bilgisi',
    },
  ]

  const OrderReturnProductStateOptions = [
    {
      value: 0,
      label: 'Paket Açılmış',
    },
    {
      value: 1,
      label: 'Paket Açılmamış',
    },
    {
      value: 2,
      label: 'Kutu Deforme',
    },
  ]

  const deliveryTypeOptions = [
    {
      value: 0,
      label: 'Kargo',
    },
    {
      value: 1,
      label: 'Depodan Teslim',
    },
  ]

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IReturnFormState>(intialState)
  const routeLocation = useLocation()
  useEffect(() => {
    if (routeLocation.state && state.first) {
      const belgeNo = (routeLocation.state as any)?.order
      setState({ belgeNo: belgeNo, first: false })
      if (belgeNo) getOrderDetails(belgeNo)
    }
  }, [routeLocation])

  const getOrderDetails = async (order: string) => {
    props.showLoading()
    const orderDetails = Object.assign({
      OrderDocNO: order,
      DealerCode: '',
    })
    const getOrderDetailApi: GetOrderDetailsApi = new GetOrderDetailsApi({})
    getOrderDetailApi
      .getOrderDetail(orderDetails)
      .then((result: IGetOrderDetailsResponse[]) => {
        setState({ orderDetails: result, orderDetail: result })
        props.hideLoading()
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getReturnDetails = async (order: IGetOrderDetailsResponse, index: number) => {
    const quantity: ISelectOption[] = []
    for (let i = 0; i < order.TOPLAM_ADET; i++) {
      quantity.push({ value: i + 1, label: i + 1 })
    }
    if (
      state.returnData.length > 0 &&
      state.returnData.findIndex(data => {
        return data.Id === index
      }) > -1
    ) {
      const dta = state.returnData.find(data => {
        return data.Id === index
      })
      setState({
        returnQuantityOption: quantity,
        showModal: true,
        returnQuantity: dta && { value: dta.Quantity, label: dta.Quantity },
        orderReturnProductState:
          dta &&
          OrderReturnProductStateOptions.find(option => {
            return option.value === dta.ReturnProductState
          }),
        deliveryType:
          dta &&
          deliveryTypeOptions.find(option => {
            return option.value === dta.DeliveryType
          }),
        returnPrice: dta?.ReturnPrice,
        orderReturnReason:
          dta &&
          orderReturnReasonOptions.find(option => {
            return option.value === dta.OrderReturnReason
          }),
        returnDescription: dta?.ReturnDescription,
        productCode: dta?.ProductCode,
        totalPrice: dta?.TotalPrice,
        birimTutar: dta?.Price,
      })
      return
    }
    setState({
      returnQuantityOption: quantity,
      showModal: true,
      birimTutar: order.BIRIM_TUTAR,
      returnIndex: index,
      productCode: order.URUN_KODU,
      totalPrice: order.TOPLAM_TUTAR,
    })
  }

  const getReturnData = async () => {
    if (
      !state.returnQuantity ||
      !state.orderReturnProductState ||
      !state.deliveryType ||
      state.returnPrice === 0 ||
      !state.orderReturnReason
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen verileri tam giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    let dta: IReturnForm[] = []
    if (
      state.returnData.length > 0 &&
      state.returnData.filter(data => {
        return data.Id !== state.returnIndex
      }).length > 0
    )
      dta = state.returnData.filter(data => {
        return data.Id !== state.returnIndex
      })
    dta.push({
      Id: state.returnIndex,
      Quantity: state.returnQuantity ? +state.returnQuantity.value : 0,
      ReturnProductState: state.orderReturnProductState ? +state.orderReturnProductState.value : 0,
      DeliveryType: state.deliveryType ? +state.deliveryType.value : 0,
      ReturnPrice: state.returnPrice,
      OrderReturnReason: state.orderReturnReason ? +state.orderReturnReason.value : 0,
      ReturnDescription: state.returnDescription ? state.returnDescription : '',
      ProductCode: state.productCode,
      TotalPrice: state.totalPrice,
      Price: state.birimTutar,
    })
    setState({
      showModal: false,
      returnQuantity: undefined,
      returnQuantityOption: [],
      orderReturnProductState: undefined,
      deliveryType: undefined,
      returnPrice: 0,
      orderReturnReason: undefined,
      returnDescription: '',
      returnIndex: 0,
      returnData: dta,
    })
  }

  const navigate: any = useNavigate()
  const postOrderReturn = async () => {
    const data: ISelectDetails[] = []
    if (state.id.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen ürün seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (state.returnData.length === 0) {
      Swal.fire({
        icon: 'warning',
        title: 'Lütfen seçili ürünleriniz için İşlemler sütunundan veri girişi yapınız.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    state.returnData.map((ret, index) => {
      if (
        state.id.findIndex(i => {
          return i === ret.Id
        }) > -1
      ) {
        return data.push({
          Quantity: ret.Quantity,
          URUN_KODU: ret.ProductCode,
          TOPLAM_TUTAR: ret.TotalPrice,
          BIRIM_TUTAR: ret.Price,
          Aciklama: ret.ReturnDescription,
          IadeNedeni: ret.OrderReturnReason,
          UrunDurum: ret.ReturnProductState,
          TeslimatSekli: ret.DeliveryType,
        })
      }
    })
    if (state.returnData.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lüften iade edilecek ürünlerin bilgilerini giriniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    if (state.returnData.length < state.id.length) {
      Swal.fire({
        icon: 'error',
        title: 'Bilgileri girilmeyen ürünler var.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    const orderDetails: IOrderReturn = {
      SiparisNo: state.belgeNo,
      IslemTipi: 1,
      SelectDetails: data,
    }
    const postOrderReturn: PostOrderReturnApi = new PostOrderReturnApi({})
    postOrderReturn
      .postOrderReturn(orderDetails)
      .then((result: IOrderReturnRequest) => {
        Swal.fire({
          icon: 'success',
          title: `Sipariş No: ${result.SiparisNo} Takip No: ${result.TakipNo}`,
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(res => {
          if (res.isConfirmed) {
            navigate('/myspecial/myReturns')
          }
        })
        props.hideLoading()
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getChecked = async (index: number) => {
    let data: number[] = []
    if (
      state.id.findIndex(i => {
        return i === index
      }) > -1
    )
      data = state.id.filter(i => {
        return i !== index
      })
    else {
      data = state.id
      data.push(index)
    }
    setState({ id: data })
  }

  const searchProduct = async text => {
    const data: IGetOrderDetailsResponse[] = []
    state.orderDetail
      .filter(order => {
        return order.URUN_KODU.indexOf(text.toUpperCase()) > -1
      })
      .map(tab => {
        return data.push(tab)
      })
    setState({ orderDetails: data, productCodeInput: text.toUpperCase() })
  }

  const checkboxStyle = {
    height: '35px',
    border: '#ded4d4 1px solid !important',
    borderRadius: '5px',
  }

  return (
    <div className='container returnForm'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>İade Formu</span>
          </div>
          <div className='card-body'>
            <section id='page-content' className='sidebar-left pt-0 background-white'>
              <Link to='/myspecial/myReturns'>
                <IPButton
                  text='< Önceki İade Formları'
                  style={{ backgroundColor: 'orange', width: '25%', marginLeft: '10px' }}
                />
              </Link>
              {/* <div className='row m-t-50 m-l-10 m-r-10'>
                <div className='col-md-4' style={{
                  backgroundColor: "white",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  borderColor: "whitesmoke"
                }}>
                  <label className='form' style={{ fontSize: "12px" }}>FATURA NO</label>
                  <br />
                  <IPInput
                    type='text'
                    style={{ width: "50%" }}
                  // value={state.orderNo}
                  // onChange={e => setState({ orderNo: e.target.value })}
                  />
                  <br />
                  <IPButton text="FATURA NUMARASINA GÖRE GETİR" style={{ backgroundColor: 'gray', marginTop: "15px", fontSize: "12px" }} />
                </div>
                <div className='col-md-4' style={{
                  backgroundColor: "white",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  borderColor: "whitesmoke"
                }}>
                  <label className='form' style={{ fontSize: "12px" }}>ÜRÜN SERİ NO</label>
                  <br />
                  <IPInput
                    type='text'
                    style={{ width: "50%" }}
                  // value={state.orderNo}
                  // onChange={e => setState({ orderNo: e.target.value })}
                  />
                  <br />
                  <IPButton text="ÜRÜN SERİ NUMARASINA GÖRE GETİR" style={{ backgroundColor: 'gray', marginTop: "15px", fontSize: "12px" }} />
                </div>
                <div className='col-md-4' style={{
                  backgroundColor: "white",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  paddingTop: "40px",
                  paddingBottom: "40px",
                  borderColor: "whitesmoke"
                }}>
                  <label className='form'><b style={{ fontSize: "12px" }}>FATURA NO VE SERİ NO GİRMEDEN TÜM FATURALARINIZI TARİHE GÖRE LİSTELEYEBİLİRSİNİZ.</b></label>
                  <IPButton text="TÜM FATURALARI LİSTELE ->" style={{ backgroundColor: 'gray', marginTop: "34px", fontSize: "12px" }} />
                </div>
              </div> */}
              <div className='row m-5'>
                <div className='col-md-3 mt-5'>
                  <IPInput
                    type='text'
                    // style={{ width: "50%" }}
                    placeholder='Ürün Kodu Ara...'
                    value={state.productCodeInput}
                    onChange={e => searchProduct(e.target.value)}
                  />
                  {/* <IPButton text="ÜRÜN SERİ NUMARASINA GÖRE GETİR" style={{ backgroundColor: 'gray', marginTop: "15px", fontSize: "12px" }} /> */}
                </div>
                {/* <div className='col-md-2'>
                  <IPButton text="Ara" onClick={searchProduct} />
                </div> */}
              </div>

              <div className='row m-t-50 m-l-10 m-r-10'>
                <div className='table-responsive' style={{ height: 'auto' }}>
                  <table className='campaignTable table table-bordered table-hover'>
                    <thead>
                      <tr>
                        <th scope='col' style={{ color: 'red' }}>
                          E-FATURA NO
                        </th>
                        <th scope='col' style={{ color: 'red' }}>
                          SİPARİŞ NO
                        </th>
                        <th scope='col' style={{ color: 'red' }}>
                          ÜRÜN KODU
                        </th>
                        <th scope='col' style={{ color: 'red' }}>
                          ÜRÜN ADI
                        </th>
                        <th scope='col' style={{ color: 'red' }}>
                          MİKTAR
                        </th>
                        <th scope='col' style={{ color: 'red' }}>
                          BİRİM FİYATI
                        </th>
                        <th scope='col' style={{ color: 'red' }}>
                          FİYAT
                        </th>
                        <th scope='col' style={{ color: 'red' }}>
                          PB
                        </th>
                        <th scope='col' style={{ color: 'red' }}>
                          EKLE
                        </th>
                        <th scope='col' style={{ color: 'red' }}>
                          İŞLEMLER
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {state.orderDetails &&
                        state.orderDetails.length > 0 &&
                        state.orderDetails.map((order, index) => (
                          <tr key={index}>
                            <td>{(routeLocation.state as any)?.efatno}</td>
                            <td>{order.SIPARIS_NO}</td>
                            <td>{order.URUN_KODU}</td>
                            <td>{order.URUN_ACIKLAMA}</td>
                            <td>{order.TOPLAM_ADET}</td>
                            <td>{order.BIRIM_TUTAR}</td>
                            <td>{order.TOPLAM_TUTAR}</td>
                            <td>{order.SIPARIS_PARA_BIRIMI}</td>
                            <td>
                              <IPCustomCheckbox
                                checked={
                                  state.id.findIndex(i => {
                                    return i === index
                                  }) > -1
                                }
                                onClick={() => getChecked(index)}
                              />

                              {/* <IPCheckbox
                                style={checkboxStyle}
                                className='border border-secondary'
                                checked={
                                  state.id.findIndex(i => {
                                    return i === index
                                  }) > -1
                                }
                                onClick={() => getChecked(index)}
                              /> */}
                            </td>
                            <td className='position-relative'>
                              <div
                                className='tableIcon'
                                style={{
                                  backgroundColor:
                                    state.id.findIndex(i => {
                                      return i === index
                                    }) > -1
                                      ? 'red'
                                      : 'pink',
                                }}
                                onClick={() => {
                                  if (
                                    state.id.findIndex(i => {
                                      return i === index
                                    }) > -1
                                  )
                                    return getReturnDetails(order, index)
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Lütfen önce ekle butonuna tıklayınınız.',
                                    showConfirmButton: true,
                                    allowOutsideClick: false,
                                  })
                                }}
                              >
                                <i className='icon-info'></i>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='row m-l-10 m-r-10'>
                <div className='col-md-9'>
                  <label>
                    <b style={{ color: 'red' }}>*</b> İade edeceğiniz ürün arızalı ise iade talep formu doldurmanıza
                    gerek yoktur. Yetkili servisinizden alacağınız (DOA) servis raporu ile teknik servisimize
                    başvurmanız yeterlidir.
                  </label>
                </div>
                <div className='col-md-3'>
                  <IPButton text='İADE FORMU OLUŞTUR' style={{ backgroundColor: 'orange' }} onClick={postOrderReturn} />
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <IPModal
        width={1200}
        show={state.showModal}
        onConfirm={() => getReturnData()}
        onClose={() =>
          setState({
            showModal: false,
            returnQuantity: undefined,
            returnQuantityOption: [],
            orderReturnProductState: undefined,
            deliveryType: undefined,
            returnPrice: 0,
            orderReturnReason: undefined,
            returnDescription: '',
            returnIndex: 0,
          })
        }
        closeOnEscapeKey={true}
        title='İade Detay'
      >
        <div className='row' style={{ height: '500px' }}>
          <div className='col-md-2'>
            <label className='form-label'>İADE MİKTARI</label>
            <IPSelectBox
              isClearable
              options={state.returnQuantityOption}
              value={state.returnQuantity}
              onChangeSingle={option =>
                setState({ returnQuantity: option, returnPrice: +option.value * state.birimTutar })
              }
            />
          </div>
          <div className='col-md-2'>
            <label className='form-label'>ÜRÜN DURUMU</label>
            <IPSelectBox
              isClearable
              options={OrderReturnProductStateOptions}
              value={state.orderReturnProductState}
              onChangeSingle={option => setState({ orderReturnProductState: option })}
            />
          </div>
          <div className='col-md-2'>
            <label className='form-label'>TESLİMAT ŞEKLİ</label>
            <IPSelectBox
              isClearable
              options={deliveryTypeOptions}
              value={state.deliveryType}
              onChangeSingle={option => setState({ deliveryType: option })}
            />
          </div>
          <div className='col-md-2'>
            <label className='form-label'>İADE TUTAR</label>
            <IPInput type='text' value={String(state.returnPrice)} disabled={true} />
          </div>
          <div className='col-md-2'>
            <label className='form-label'>İADE NEDENİ</label>
            <IPSelectBox
              isClearable
              options={orderReturnReasonOptions}
              value={state.orderReturnReason}
              onChangeSingle={option => setState({ orderReturnReason: option })}
            />
          </div>
          <div className='col-md-2'>
            <label className='form-label'>İADE AÇIKLAMA</label>
            <IPInput
              type='text'
              value={state.returnDescription}
              onChange={e => setState({ returnDescription: e.target.value })}
            />
          </div>
        </div>
      </IPModal>
    </div>
  )
}

export default componentWithContext(ReturnForm)
