import Select, { ActionMeta, ControlProps, GroupBase, MultiValue, Props, SingleValue } from 'react-select'
import { StylesConfigFunction } from 'react-select/dist/declarations/src/styles'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'

declare type IsMulti = boolean

interface ISelectGroupBase extends GroupBase<ISelectOption> {}

export interface IPSelectBoxProps extends Props<ISelectOption, IsMulti, ISelectGroupBase> {
  // customPropsHere?: string;
  onChangeSingle?: (value: ISelectOption) => void
  onChangeMulti?: (values: ISelectOption[]) => void
  controlStyles?: StylesConfigFunction<ControlProps<ISelectOption, false, ISelectGroupBase>> | undefined
  isCreatableSelect?: boolean
  onCreateOption?: (values: ISelectOption[]) => void
  height?: number
  menuStyle?: boolean
  disabled?: boolean
}

export default function CampaignSelect(props: IPSelectBoxProps) {
  const onChange = (
    newValue: MultiValue<ISelectOption> | SingleValue<ISelectOption>,
    actionMeta: ActionMeta<ISelectOption>
  ) => {
    if (Array.isArray(newValue)) {
      props.onChangeMulti && props.onChangeMulti(newValue as ISelectOption[])
    } else {
      props.onChangeSingle && props.onChangeSingle(newValue as ISelectOption)
    }
  }

  return (
    <Select
      isMulti
      isDisabled={props.disabled ?? false}
      styles={{
        container: base => ({
          ...base,
          width: '100%',
          backgroundColor: '#fff',
        }),
        control: base => ({
          ...base,
          height: props.height ? props.height + 'px' : 'auto',
          boxShadow: 'none',
          '&:focus-within': {
            borderColor: '#699BF7',
          },
        }),
        valueContainer: () => ({
          overflowY: 'auto',
          height: props.height ? props.height + 'px' : 'auto',

          padding: '0.3rem',
          flexGrow: 1,
        }),
        menu: base => ({
          ...base,
          width: props.menuStyle ? '600px' : '100%',
        }),
        multiValueRemove: () => ({
          marginLeft: 'auto',
        }),
        indicatorsContainer: base => ({
          ...base,
          padding: '4px',
        }),
      }}
      options={props.options}
      onChange={onChange}
      value={props.value}
      placeholder={<div style={{ fontWeight: 500, padding: '0.5rem' }}>{props.placeholder}</div>}
    />
  )
}
