import { Link } from 'react-router-dom'
import './FinancialDocuments.scss'

export default function FinancialDocuments() {
  return (
    <div className='financialDocuments'>
      <div className='titleBorderless'>KREDİ KARTI ÖDEME TALİMATI</div>

      <div className='files'>
        <Link to='/assetWeb/docs/temliksozlesme.pdf' target='_blank' className='file' title='İndirmek İçin Tıklayınız!'>
          <img className='mr-2' width={30} src='/images/icons/docs.png' alt='' />
          <div>Kredi Kartı Ödeme Temliknamesi</div>
        </Link>
      </div>
    </div>
  )
}
