import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IUpdateXmlCompanyParams } from '../models/models'

export class UpdateXmlCompanyParamsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/UpdateXmlCompanyParams',
      requesterInfo,
    })
  }

  public async updateXmlCompanyParams(request: IUpdateXmlCompanyParams[]): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
