import { useState } from 'react'
import Masonry from 'react-masonry-css'
import data from '../../../api/adminMenu.json'
import List from '../../../layouts/components/admin/Menu/List'
import classes from '../../../layouts/components/admin/Menu/Menu.module.scss'

export default function Index() {
  const [isMenuActive, setIsMenuActive] = useState(true)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [search, setSearch] = useState('')
  const handleSubMenuSearch = () => {
    if (search) {
      let tempResult: any = []
      let tempSubMenuArray: any = []

      for (const item of data.menu) {
        tempSubMenuArray = []
        tempSubMenuArray = item.subMenu.filter((elem: any) => {
          return elem.name.toLowerCase().includes(search.toLowerCase())
        })
        // const founded = item.subMenu.find((elem: any) => { return elem.name.toLowerCase().includes(search.toLowerCase()) })

        // if (founded) {
        // }

        // for (const menu of item.subMenu) {
        //   if (menu.name.toLowerCase().includes(search.toLowerCase())) {
        //     let temSubMenuObj: any = {
        //       id: menu.id,
        //       name: menu.name,
        //       link: menu.link
        //     }
        //     tempSubMenuArray.push(temSubMenuObj)
        //   }
        // }
        let tempObj: any = {
          id: item.id,
          name: item.name,
          link: item.link,
          subMenu: tempSubMenuArray,
        }
        tempResult.push(tempObj)
      }
      if (tempResult.length > 0) {
        return tempResult
      } else {
        return []
      }
    } else {
      return data.menu
    }
  }
  return (
    <div>
      <div className={`${classes.megaMenu} ${isMenuActive ? classes.active : ''}`}>
        <Masonry
          breakpointCols={4}
          className={classes['my-masonry-grid']}
          columnClassName={classes['my-masonry-grid_column']}
        >
          {handleSubMenuSearch().map((dataList: any) => (
            <div key={dataList.id} className={classes.dataList}>
              <List key={dataList.id} dataList={dataList} />
            </div>
            // <div className="asdasdas" key={index}>{dataList.subMenu.name}</div>
          ))}
        </Masonry>
      </div>
    </div>
  )
}
