import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IAddListToBasketUI } from '../models/commonModels'

export class AddListToBasketUIApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/addListToBasketUI',
      requesterInfo,
    })
  }

  public async addListToBasketUI(productIdList: IAddListToBasketUI[]): Promise<any> {
    return await this.postAsync<any>(productIdList)
  }
}
