import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'

export class GetClearRemoteApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/clearRemote',
      requesterInfo,
    })
  }

  public async getClearRemote(): Promise<[]> {
    return await this.getAsync<[]>()
  }
}
