import React, { useMemo, useEffect, useState } from 'react'
import { useCustomReducer } from '../../../../base/customHooks'
import IPButton from '../../../../components/IPButton'
import IPInput from '../../../../components/IPInput'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import IPSelectBox from '../../../../components/IPSelectBox'
import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import Table from '../../../../components/Table'
import './AddPropertyModification.scss'
import { ProductPropertiesService } from './services'
import { IUpdateChangePropertiesRequest, IUpdatePropertiesRequest, IUpdatePropertiesResponse } from './models'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { ChangePropertiesHistory } from '../AddProductFeatures/models'
import moment from 'moment'
import { GetHistoryApi } from './getHistoryApi'
import InputCampaign from '../../../../components/InputCampaign'

interface IProductState {
  selectedField?: ISelectOption
  productGroups: ISelectOption[]
  sapMaterialNumber: string
  first: boolean
  page: string | null
  history: boolean
  historyModel: ChangePropertiesHistory[]
}

function AddPropertyModification(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): IProductState => {
    return {
      productGroups: [],
      sapMaterialNumber: '',
      page: "0",
      first: true,
      history: false,
      historyModel: []
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IProductState>(intialState)
  const [productProperties, setProductProperties] = useState<IUpdatePropertiesResponse[]>([])

  async function getProductGroups() {
    try {
      const res = await new ProductPropertiesService({}).productGroupsList()
      if (res) {
        const productGroups = res.map(x => ({ label: x.Name, value: x.SapCode }))
        setState({ productGroups })
      }
    } catch (err: any) {
      console.log('Response', err)
    }
  }

  useEffect(() => {
    getProductGroups()
    if (state.first) {
      sessionStorage.setItem('Page', '0')
      setState({ first: false })
    }

  }, [])

  const onFieldChange = async () => {
    const req: IUpdatePropertiesRequest = {
      MatNumber: state.sapMaterialNumber,
      GroupCode: state.selectedField ? (state.selectedField.value as string) : '',
    }
    props.showLoading();
    const productPropertiesService: ProductPropertiesService = new ProductPropertiesService({})

    await productPropertiesService
      .updateProperties(req)
      .then(async result => {
        if (result) {
          setProductProperties(result)
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    setState({ selectedField: state.selectedField })
  }

  const updateChangeProperties = async () => {
    const req: IUpdateChangePropertiesRequest[] = []
    let i = 0;
    productProperties.map(p => {
      if (p.AttribValue && p.AttribValue.length > 0)
        i++;
      return req.push({
        CategoryID: p.CategoryID,
        CATNAME: p.CATNAME,
        GroupID: p.GroupID,
        GroupName: p.GroupName,
        FEATUREID: p.FEATUREID,
        AttribName: p.AttribName,
        AttribValue: p.AttribValue,
        FEATURE_VALUE: p.FEATURE_VALUE
      })
    })
    if (i === 0) {
      Swal.fire({
        icon: 'error',
        title: 'En az 1 tane özellik değeri giriniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading();
    const productPropertiesService: ProductPropertiesService = new ProductPropertiesService({})

    await productPropertiesService
      .updateChangeProperties(req)
      .then(async (result: any) => {
        if (result) {
          props.hideLoading()
          if (result.length === 0) {
            Swal.fire({
              icon: 'error',
              title: 'Kaydınız oluşmadı.',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            return
          }
          Swal.fire({
            icon: 'success',
            title: 'Kaydınız oluşmuştur.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    // setState({ selectedField: value })
  }

  const History = async () => {
    const req = {
      StartDate: startDate,
      EndDate: endDate
    }
    props.showLoading()
    const history: GetHistoryApi = new GetHistoryApi({})
    history.getHistory(req).then((res: any) => {
      if (res) {
        setState({ history: true, historyModel: res })
        props.hideLoading()
      }
    })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const columnDefinitions = [
    { Header: '#', accessor: 'col1' },
    { Header: 'Kategori ID', accessor: 'col2' },
    { Header: 'Kategori Adı', accessor: 'col3' },
    { Header: 'Grup ID', accessor: 'col4' },
    { Header: 'Grup Adı', accessor: 'col5' },
    { Header: 'Özellik ID', accessor: 'col6' },
    { Header: 'Özellik Adı', accessor: 'col7' },
    { Header: 'Eski Değer', accessor: 'col8' },
    { Header: 'Yeni Değer', accessor: 'col9' },
    { Header: 'Mess', accessor: 'col10' },
    { Header: 'Tarih', accessor: 'col11' },
    { Header: 'Bayi Kodu', accessor: 'col12' },
    { Header: 'Kullanıcı Adı', accessor: 'col13' },
  ];

  const tableColumns = useMemo(() => columnDefinitions, []);

  const tableDataAndTotal = useMemo(
    () => {
      const tableData = state.historyModel.map(item => {
        const data = {
          col1: item.Id,
          col2: item.CategoryID,
          col3: item.CATNAME,
          col4: item.GroupID,
          col5: item.GroupName,
          col6: item.FEATUREID,
          col7: item.AttribName,
          col8: item.FEATURE_VALUE,
          col9: item.AttribValue,
          col10: item.Mess,
          col11: item.UpdateTime && moment(item.UpdateTime).format('DD.MM.YYYY HH:mm:ss'),
          col12: item.UserDealer && item.UserDealer.replace('0000', ''),
          col13: item.UserName,
        }
        // if (!isNaN(+item.PayInfo)) {
        //   totalPayInfo += parseFloat(item.PayInfo);
        // }
        return data
      })
      return {
        tableData,
      };
    }
    , [History])


  const onChangeAttribValue = async (e: any, id: number) => {
    setProductProperties(prevState => {
      return prevState.map((prev: IUpdatePropertiesResponse) => {
        if (prev.Id === id) {
          const prevData = {
            AttribName: prev.AttribName,
            AttribValue: e,
            CATNAME: prev.CATNAME,
            CategoryID: prev.CategoryID,
            ChangeValue: prev.ChangeValue,
            FEATUREID: prev.FEATUREID,
            FEATURE_VALUE: prev.FEATURE_VALUE,
            Group1: prev.Group1,
            GroupID: prev.GroupID,
            GroupName: prev.GroupName,
            Id: id,
            MatNumber: prev.MatNumber,
            Name: prev.Name,
            OrderId: prev.OrderId,
            OzTanUrSayisi: prev.OzTanUrSayisi,
            PRODUCTID: prev.PRODUCTID,
            ProductCode: prev.ProductCode
          }
          return prevData
        }
        return prev
      })

    })
    setState({ page: sessionStorage.getItem("Page") })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'IceCat Kategorisi',
        accessor: 'CATNAME',
      },
      {
        Header: 'Grup Adı',
        accessor: 'GroupName',
      },
      {
        Header: 'Özellik Adı',
        accessor: 'AttribName',
      },
      {
        Header: 'Tanımlı Ürün Sayısı',
        accessor: 'OzTanUrSayisi',
      },
      {
        Header: () => null,
        accessor: 'empty',
      },
      {
        Header: () => null,
        accessor: 'empty2',
      },
    ],
    []
  )

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  const data = useMemo(
    () =>
      productProperties.map(p => {
        return {
          CATNAME: <IPInput type='text' className='form-control' value={p.CATNAME} disabled={true} />,
          GroupName: <IPInput type='text' className='form-control' value={p.GroupName} style={{ width: "200px" }} disabled={true} />,
          AttribName: <IPInput type='text' className='form-control' value={p.AttribName} disabled={true} />,
          AttribValue: (
            <IPInput
              type='text'
              className='form-control'
              value={p.AttribValue}
              onChange={e => {
                const productProperty = productProperties.map(x => {
                  if (x.Id === p.Id) {
                    x.AttribValue = e.target.value
                  }
                  return x
                })
                setProductProperties(productProperty)
                setState({ page: sessionStorage.getItem("Page") })
              }}
            />
          ),
          OzTanUrSayisi: <IPInput type='text' className='form-control' value={p.OzTanUrSayisi} style={{ width: "100px" }} disabled={true} />,
          empty: (
            <div>
              <div className='mb-4'>Eski Değer</div>
              <div className='mb-4'>Tanımlı Değişiklik Değeri</div>
              <div>Yeni Değer</div>
            </div>
          ),
          empty2: (
            <div>
              <div>
                <IPInput type='text' className='form-control mb-4' value={p.FEATURE_VALUE} style={{ width: "500px" }} disabled={true} />
              </div>
              <div>
                <IPInput type='text' className='form-control mb-4' value={p.ChangeValue ? p.ChangeValue : "......"} style={{ width: "500px" }} disabled={true} />
              </div>
              <div>
                <IPInput type='text' className='form-control' value={p.AttribValue} style={{ width: "500px" }} onChange={(e) => onChangeAttribValue(e.target.value, p.Id)} />
              </div>
            </div>
          ),
        }
      }),
    [productProperties]
  )

  return (
    // <div className='container'>
    //   <div className='content col-lg-12 m-auto'>
    <div className='card'>
      <div className='card-header'>
        <span className='h4'>Ürün Özellik Değiştirme</span>
      </div>
      <div className='card-body'>
        <form className='formbanner'>
          <div className='row'>
            <div className='col-lg-3'>
              <IPSelectBox
                id='DealerContents'
                name='DealerContents'
                options={state.productGroups}
                value={state.selectedField}
                onChangeSingle={selectedField => setState({ selectedField })}
                placeholder={'Ürün Grubu Seçiniz'}
                isClearable
              />
            </div>
            <div className='col-lg-4 mb-3'>
              <IPInput
                type='text'
                className='form-control'
                id='Product'
                value={state.sapMaterialNumber}
                onChange={e => setState({ sapMaterialNumber: e.target.value })}
                placeholder='SAP Malzeme Numarası'
              />
            </div>
            <div className='col-2 mb-3'>
              <IPButton type='button' className='btn btn-success' text={'Göster'} onClick={onFieldChange} />
            </div>
          </div>
        </form>
        <br />
        <div className='row'>
          <div className='col-lg-12'>
            <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
              <div className='row addPropertyModification'>
                <div className='col-12'>
                  <Table columns={columns} data={data} currentPage={state.page ? +state.page : 0} />
                </div>
                {
                  productProperties.length > 0 &&
                  <div className='col-2 ml-auto'>
                    <IPButton
                      type='button'
                      className='btn btn-success'
                      text="Değiştir"
                      onClick={updateChangeProperties}
                    />
                  </div>
                }
              </div>
            </div>
            <div className='col-lg-12'>
              <div className='form-row justify-content-center'>
                <div className='form-group col-md-3'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>

                <div className='form-group col-md-3'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>
              </div>
              <h5 style={{ textAlign: 'center' }}>
                <a onClick={History} style={{ cursor: 'pointer' }}>
                  Geçmişi Göster
                </a>
              </h5>
              {state.history &&
                <Table columns={tableColumns} data={tableDataAndTotal.tableData} currentPage={0} headerName={"Özellik Değiştirme Geçmişi"} columnCount={16} backgroundColor="red" color="white" />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  )
}
export default componentWithContext(AddPropertyModification)