import React, { useMemo, useState, useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import InputCampaign from '../../../../../components/InputCampaign'
import { getDateByTimezoneOffset, toDateTimeLocal } from '../../../../../base/utils/dataHelper'
import Table from '../../../../../components/Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { IColorHistory, IProductColorResponse } from '../models/models'
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom'
import { GetProductColorApi } from '../services/getProductColorList'
import { ServiceRouter } from '../../../../../base/proxy/serviceRouter'
import { DeleteProductColorApi } from '../services/deleteProductColor'
import { ColorHistoryApi } from '../services/ColorHistoryApi'
import IPModal from '../../../../../components/IPModal'
import { useCustomReducer } from '../../../../../base/customHooks'
import moment from 'moment'
import { colors } from 'react-select/dist/declarations/src/theme'

interface IColorState {
  historyData: IColorHistory[]
  showHistory: boolean
}


function ProductColorList(props: BaseComponentWithContextProps) {
  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Açıklama',
        accessor: 'col2',
      },
      {
        Header: 'Ürünler',
        accessor: 'col3',
      },
      {
        Header: 'Renkler',
        accessor: 'col4',
      },
      {
        Header: 'Şirket',
        accessor: 'col5',
      },
      {
        Header: 'Yönlendirme Tipi',
        accessor: 'col6',
      },
      {
        Header: 'İşlemler',
        accessor: 'col7',
      }
    ],
    []
  )

  const [productColor, setProductColor] = useState<IProductColorResponse[]>([])
  let navigate = useNavigate()


  const deleteColor = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Yönlendirme kaydını silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading();
        const deleteProductLinkApi: DeleteProductColorApi = new DeleteProductColorApi({})
        deleteProductLinkApi
          .deleteProductColor(id)
          .then(async result => {
            props.hideLoading();
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Yönlendirme Kaydı Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async () => {
                getColorList()
              })
            }
          })
          .catch((err: any) => {
            props.hideLoading();
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      }
    })
  }

  const showHistory = async (id: number) => {
    props.showLoading();
    const colorHistory: ColorHistoryApi = new ColorHistoryApi({})
    try {
      await colorHistory
        .historyByID(id)
        .then(res => {
          setState({ showHistory: true, historyData: res })
        })
      props.hideLoading();
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  const tableData = useMemo(
    () =>
      productColor.map((item, index) => {
        const col3Content = item.ProductIds.map((productId, index) => (
          productId != 0 ?
            <React.Fragment key={index}>
              <Link
                target="_blank"
                to={`/product/detail/${productId}?from=productList`}
              >
                {item.ProductCodes[index]}
              </Link>
              {index < item.ProductIds.length - 1 && " , "}
            </React.Fragment> : <React.Fragment key={index}>
              {item.ProductCodes[index]}
              {index < item.ProductIds.length - 1 && " , "}
            </React.Fragment>
        ));

        const data = {
          col1: index + 1,
          col2: item.Info,
          col3: col3Content,
          col4: item.ColorNames.join(" , "),
          col5: item.Company === 1 ? "Index" : item.Company === 2 ? "Netex" : item.Company === 3 ? "Despec" : item.Company === 4 ? "Datagate" : "",
          col6: item.Type === 1 ? "Renk" : "Ürün",
          col7: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={"düzenle"}
                onClick={() => navigate('/admin/ProductOperation/ProductColorAdd', {
                  state: { linkData: item },
                })}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
              <a
                data-action-type='delete'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={"Sil"}
                onClick={() => deleteColor(item.Id)}
              >
                <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
              </a>
              <a style={{ cursor: 'pointer', color: 'blue' }} onClick={() => showHistory(item.Id)}><i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i></a>
            </div>
          ),
        };

        return data;
      }),
    [productColor]
  );

  const getInitialState = (): IColorState => {
    return {
      historyData: [],
      showHistory: false
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IColorState>(intialState)

  const getColorList = async () => {
    props.showLoading();
    const getProductColorApi: GetProductColorApi = new GetProductColorApi({})
    getProductColorApi
      .getProductColor()
      .then((result: IProductColorResponse[]) => {
        props.hideLoading();
        setProductColor(result)
      })
      .catch((err: any) => {
        props.hideLoading();
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  useEffect(() => {
    getColorList()
  }, [])


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Ürün Yönlendirme Listesi</span>
            <Link to='/admin/ProductOperation/ProductColorAdd'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Ekle'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='card-body'>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
        </div>
        <IPModal
          show={state.showHistory}
          onClose={() => setState({ showHistory: false, historyData: [] })}
          hideConfirm={true}
          width={1200}
          title='Tarihçe'
        >
          <table className='table'>
            <thead>
              <tr role='row' className='odd'>
                <th>#</th>
                <th>Tarih</th>
                <th>Ürünler</th>
                <th>Renkler</th>
                <th>Tip</th>
                <th>Şirket</th>
                <th>Bayi Kodu</th>
                <th>Kullanıcı Adı</th>
              </tr>
            </thead>

            <tbody>
              {
                state.historyData && state.historyData.length > 0 && state.historyData.map((his) => (
                  <tr role='row' className='odd' key={his.Id}>
                    <td>{<a
                      className='btn btn-transparent'
                      style={{ color: 'blue' }}
                      type='button'
                      data-action-type='order'
                      onClick={() => {
                        his.ProductCodes = his.ProductCodes?.toString().split('@@')
                        his.ColorIds = his.ColorIds?.toString().split(',').map(Number)
                        navigate('/admin/ProductOperation/ProductColorAdd', {
                          state: {
                            linkData: his,
                            data: 'History',
                          },
                        })
                      }}
                    >
                      {his.Id}
                    </a>}</td>
                    <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                    <td>{his.ProductCodes?.toString().replaceAll('@@', ' - ')}</td>
                    <td>{his.ColorIds}</td>
                    <td>{his.Type === 1 ? "Renk" : "Ürün"}</td>
                    <td>{his.Company}</td>
                    <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                    <td>{his.UserName}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </IPModal>
      </div>
    </div>
  )
}

export default componentWithContext(ProductColorList)
