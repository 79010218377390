import React, { useState, useMemo, useEffect } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import InputCampaign from '../../../../../components/InputCampaign'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPButton from '../../../../../components/IPButton'
import IPModal from '../../../../../components/IPModal'
import Table from '../../../../../components/Table'
import { IDealerForBannersRequest, IDealerForBannersResponse, IDetails } from '../models/models'
import { GetDealerForBannersApi } from '../services/getDealerForBannersApi'
import Swal from 'sweetalert2'
import IPImage from '../../../../../components/IPImage'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'
import IPInput from '../../../../../components/IPInput'
import { translate } from '../../../../../base/localization'
import Translations from '../../../../../translations'
import IPCheckbox from '../../../../../components/IPCheckBox'
import moment from 'moment'
import { GetBannerCategoriesApi } from '../../../../Homepage/services/getBannerCategoriesApi'
interface IBannerClickStatisticsState {
  showModal: boolean
  modalData: IDetails[]
  companies: number[]
  status: number
  statusOption?: ISelectOption
  bannerData: IDealerForBannersResponse[]
  first: boolean
  showModalPopup: boolean
  bannerImg: string
  bannerImgURL: string
  selectedCompany?: ISelectOption
  selectedCompanyValue: string
  companyListOptions: ISelectOption[]
  companyListOption: any
  locationFilter: any
  categoryFilter: any
  bannerTypeFilter: any
  IncludeDenemeCard: boolean
  AllDateStats: boolean
}

const companyMapping = {
  0: 'Index Group',
  1: 'Index',
  2: 'Netex',
  3: 'Despec',
  4: 'Datagate',
}

function Index(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const [filteredList, setFilteredList] = useState<IDealerForBannersResponse[]>([])
  const [bannerNameFilter, setBannerNameFilter] = useState('')
  const [showFilterRow, setShowFilterRow] = useState(false)

  //#region Tarih ve saat ayarları
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  //#endregion

  const getInitialState = (): IBannerClickStatisticsState => {
    return {
      showModal: false,
      modalData: [],
      status: 0,
      bannerData: [],
      first: false,
      showModalPopup: false,
      bannerImg: '',
      bannerImgURL: '',
      companies: [],
      selectedCompany: undefined,
      selectedCompanyValue: '',
      companyListOption: null,
      companyListOptions: [
        { label: 'Index Group', value: 0 },
        { label: 'Index', value: 1 },
        { label: 'Netex', value: 2 },
        { label: 'Despec', value: 3 },
        { label: 'Datagate', value: 4 },
      ],
      locationFilter: null,
      categoryFilter: null,
      bannerTypeFilter: null,
      IncludeDenemeCard: false,
      AllDateStats: false,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IBannerClickStatisticsState>(intialState)

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'Order',
      },
      {
        Header: 'Banner Adı',
        accessor: 'BannerName',
        width: 300,
      },
      {
        Header: 'Bayi Tipi',
        accessor: 'DealerType',
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'DealerCode',
      },
      {
        Header: 'Hesap Adı/Ünvan',
        accessor: 'DealerName',
      },
      {
        Header: 'Tıklama',
        accessor: 'Count',
      },
      {
        Header: 'Son Tıklanma Tarihi',
        accessor: 'LastDate',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.modalData.map((p, index) => {
        let DealerType = ''
        p.DealerType.map(dealer => {
          if (DealerType.length > 0) return (DealerType += ', ' + dealer)
          return (DealerType += dealer)
        })
        return {
          Order: index + 1,
          BannerName: p.BannerName,
          DealerType: DealerType,
          DealerCode: p.DealerCode,
          DealerName: p.DealerName,
          Count: p.Count,
          LastDate: p.LastDate ? new Date(p.LastDate).toLocaleString() : '',
        }
      }),
    [state.modalData]
  )

  //#region filter options
  const [bannerCategoryOptions, setBannerCategoryOptions] = useState<ISelectOption[]>([])
  const [categoryMapping, setCategoryMapping] = useState<{ [key: number]: string }>({})

  const getBannerCategory = async (): Promise<ISelectOption[]> => {
    const data: ISelectOption[] = []
    const res = await new GetBannerCategoriesApi({}).getBannerCategories()

    if (res) {
      res.forEach(r => {
        data.push({ label: r.Description, value: r.Id })
      })
    }

    return data
  }

  useEffect(() => {
    const fetchBannerCategories = async () => {
      const categories = await getBannerCategory()
      setBannerCategoryOptions(categories)

      // Create a mapping of value to category label
      const mapping = categories.reduce((acc, category) => {
        const key = typeof category.value === 'string' ? parseInt(category.value) : category.value
        acc[key] = category.label
        return acc
      }, {} as { [key: number]: string })

      setCategoryMapping(mapping)
    }

    fetchBannerCategories()
  }, [])

  const bannerTypeOptions = [
    {
      value: '1',
      label: 'Link Yok',
    },
    {
      value: '2',
      label: 'Ürüne Link',
    },
    {
      value: '3',
      label: 'Liste Ekranı',
    },
    {
      value: '4',
      label: 'Aynı Pencere Link',
    },
    {
      value: '5',
      label: 'Ürün Alt Tip',
    },
    {
      value: '6',
      label: 'Yeni Pencere Link',
    },
    {
      value: '7',
      label: 'Youtube Video',
    },
    {
      value: '8',
      label: 'Vimeo Video',
    },
    {
      value: '9',
      label: 'Video',
    },
  ]

  const bannerLocationOptions = [
    {
      value: '1',
      label: 'Login',
    },
    {
      value: '2',
      label: 'Ana Sayfa Sol Üst',
    },
    {
      value: '3',
      label: 'Ana Sayfa Sol Alt',
    },
    {
      value: '4',
      label: 'Ana Sayfa Sağ',
    },
    {
      value: '5',
      label: 'Sayaç Ana Sayfa Üst',
    },
    {
      value: '6',
      label: 'Sayaç Ana Sayfa Alt',
    },
    {
      value: '7',
      label: 'Bana Özel',
    },
    {
      value: '8',
      label: 'Pop-up',
    },
    {
      value: '9',
      label: 'Orta Banner',
    },
    {
      value: '10',
      label: 'Alt Banner',
    },
    {
      value: '11',
      label: 'Ürün Detay',
    },
    {
      value: '12',
      label: 'Cari Ödeme',
    },
    {
      value: '13',
      label: 'Avantaj POS Ödeme',
    },
    {
      value: '14',
      label: 'Fırsat POS Ödeme',
    },
    {
      value: '15',
      label: 'FÇV2 ödeme',
    },
    {
      value: '16',
      label: 'Sepet Ürün',
    },
    {
      value: '17',
      label: 'Sepet Ödeme Şekli',
    },
  ]
  //#endregion

  //#region banner sütun mapping
  const mapValueToLocation = value => {
    const locationMapping = {
      '1': 'Login',
      '2': 'Ana Sayfa Sol Üst',
      '3': 'Ana Sayfa Sol Alt',
      '4': 'Ana Sayfa Sağ',
      '5': 'Sayaç Ana Sayfa Üst',
      '6': 'Sayaç Ana Sayfa Alt',
      '7': 'Bana Özel',
      '8': 'Pop-up',
      '9': 'Orta Banner',
      '10': 'Alt Banner',
      '11': 'Ürün Detay',
      '12': 'Cari Ödeme',
      '13': 'Avantaj POS Ödeme',
      '14': 'Fırsat POS Ödeme',
      '15': 'FÇV2 ödeme',
      '16': 'Sepet Ürün',
      '17': 'Sepet Ödeme Şekli',
    }
    return locationMapping[value] || ''
  }

  const mapPopupValueToLocation = value => {
    const popupLocationMapping = {
      0: 'Pop-up',
      1: 'Aç Box',
      2: 'Pop-up ve Aç Box',
    }

    return popupLocationMapping[value] || ''
  }

  const mapValueToType = value => {
    const typeMapping = {
      '1': 'Link Yok',
      '2': 'Ürüne Link',
      '3': 'Liste Ekranı',
      '4': 'Aynı Pencere Link',
      '5': 'Ürün Alt Tip',
      '6': 'Yeni Pencere Link',
      '7': 'Youtube Video',
      '8': 'Vimeo Video',
      '9': 'Video',
      // Add more mappings as needed
    }

    return typeMapping[value] || ''
  }

  const mapValueToCategory = (value: number): string => {
    return categoryMapping[value] || ''
  }

  const mapValueToCounter = value => {
    const counterMapping = {
      '1': 'Slider',
      '2': 'Alt Alta',
    }

    return counterMapping[value] || ''
  }
  //#endregion

  //#region Detaylı Süzme fonksiyonu
  const filterList = () => {
    const filterConditions: ((item: IDealerForBannersResponse) => boolean)[] = []

    if (bannerNameFilter) {
      filterConditions.push(x => x.BannerName.toUpperCase().includes(bannerNameFilter.toUpperCase()))
    }

    if (state.companyListOption) {
      filterConditions.push(c => c.Companies.includes(state.companyListOption.value))
    }

    if (state.locationFilter) {
      filterConditions.push(l => l.Location === Number(state.locationFilter.value))
    }

    if (state.categoryFilter) {
      filterConditions.push(cat => cat.Category === Number(state.categoryFilter.value))
    }

    if (state.bannerTypeFilter) {
      filterConditions.push(type => type.BannerType === Number(state.bannerTypeFilter.value))
    }

    let filteredData: IDealerForBannersResponse[] = state.bannerData

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
    }

    setFilteredList(filteredData)
  }
  //#endregion

  //#region table functions
  const columns = useMemo(
    () => [
      // {
      //   Header: 'Sıra',
      //   accessor: 'Order',
      // },
      {
        Header: 'İstatistik Aralığı',
        accessor: 'DateRange',
      },
      {
        Header: 'Şirket',
        accessor: 'Companies',
      },
      {
        Header: 'Banner Adı',
        accessor: 'BannerName',
        width: 300,
      },
      {
        Header: 'Toplam Tıklama',
        accessor: 'TotalClicks',
      },
      {
        Header: 'Konum',
        accessor: 'Location',
      },
      {
        Header: 'Kategori',
        accessor: 'Category',
      },
      {
        Header: 'Banner Tipi',
        accessor: 'BannerType',
      },
      {
        Header: 'Pop-Up Konumu',
        accessor: 'PopUpLocation',
      },
      {
        Header: 'Sayaç Konumu',
        accessor: 'CounterLocation',
      },
      {
        Header: 'Banner Başlangıç',
        accessor: 'StartDate',
      },
      {
        Header: 'Banner Bitiş',
        accessor: 'EndDate',
      },
      {
        Header: 'Durumu',
        accessor: 'State',
      },
      {
        Header: 'Detay',
        accessor: 'Actions',
      },
      {
        Header: 'Resim',
        accessor: 'BannerImgURL',
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      filteredList.map((p, index) => {
        return {
          // Order: index + 1,
          Companies: p.Companies.map(companyId => companyMapping[companyId] || '').join(', '),
          DateRange: state.AllDateStats
            ? 'Tüm Zamanlar'
            : `${moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY')}-${moment(toDateTimeLocal(endDate)).format(
                'DD.MM.YYYY'
              )}`,
          BannerName: (
            <a
              onClick={() => setState({ showModal: true, modalData: p.Details })}
              style={{ cursor: 'pointer', color: 'blue' }}
            >
              {p.BannerName}
            </a>
          ),
          TotalClicks: p.TotalClick,
          Location: mapValueToLocation(p.Location),
          Category: mapValueToCategory(p.Category),
          BannerType: mapValueToType(p.BannerType),
          PopUpLocation: p.Location === 8 ? mapPopupValueToLocation(p.PopUpLocation) : '',
          CounterLocation: mapValueToCounter(p.CounterLocation),
          StartDate: p.StartDate ? new Date(p.StartDate).toLocaleString() : '',
          EndDate: p.EndDate ? new Date(p.EndDate).toLocaleString() : '',
          State: p.IsDeleted ? 'Silindi' : p.State ? 'Aktif' : 'Pasif',
          Actions: (
            <div>
              <a
                data-action-type='direct'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                onClick={() => setState({ showModal: true, modalData: p.Details })}
              >
                <i
                  className='fas fa-circle-info'
                  style={{
                    fontSize: '1.7rem',
                    color: 'red',
                    marginLeft: '1rem',
                  }}
                ></i>
              </a>
            </div>
          ),
          BannerImgURL: (
            <a
              onClick={() => setState({ showModalPopup: true, bannerImgURL: p.BannerImgURL, bannerImg: p.BannerImg })}
              style={{ cursor: 'pointer' }}
            >
              <img src={p.BannerImgURL} alt='' style={{ width: 100 }} />
            </a>
          ),
        }
      }),
    [filteredList]
  )
  //#endregion
  const excelButtonWithDetail = async () => {
    try {
      if (filteredList) {
        const combinedData: any[] = []

        if (filteredList.length > 0) {
          for (let index = 0; index < filteredList.length; index++) {
            const banner = filteredList[index]
            const modalData = banner.Details

            combinedData.push({
              Sıra: index + 1,
              DateRange: state.AllDateStats
                ? 'Tüm Zamanlar'
                : `${moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY')}-${moment(toDateTimeLocal(endDate)).format(
                    'DD.MM.YYYY'
                  )}`,
              Şirket: banner.Companies.map(companyId => companyMapping[companyId] || '').join(', '),
              'Banner Adı': banner.BannerName,
              'Toplam Tıklama': banner.TotalClick,
              Konum: mapValueToLocation(banner.Location),
              Kategori: mapValueToCategory(banner.Category),
              'Banner Tip': mapValueToType(banner.BannerType),
              'Pop-Up Konumu': banner.Location === 8 ? mapPopupValueToLocation(banner.PopUpLocation) : '',
              'Sayaç Konumu': mapValueToCounter(banner.CounterLocation),
              'Banner Başlangıç Tarihi': banner.StartDate ? new Date(banner.StartDate).toLocaleString() : '',
              'Banner Bitiş Tarihi': banner.EndDate ? new Date(banner.EndDate).toLocaleString() : '',
              Durum: banner.IsDeleted ? 'Silindi' : banner.State ? 'Aktif' : 'Pasif',
            })

            if (modalData) {
              for (let i = 0; i < modalData.length; i++) {
                const modalRow = modalData[i]
                let DealerType = ''
                modalRow.DealerType.map(dealer => {
                  if (DealerType.length > 0) return (DealerType += ', ' + dealer)
                  return (DealerType += dealer)
                })

                combinedData.push({
                  Sıra: index + 1,
                  Şirket: banner.Companies.map(companyId => companyMapping[companyId] || '').join(', '),
                  'Banner Adı': banner.BannerName,
                  'Toplam Tıklama': '',
                  'Banner Başlangıç Tarihi': banner.StartDate ? new Date(banner.StartDate).toLocaleString() : '',
                  'Banner Bitiş Tarihi': banner.EndDate ? new Date(banner.EndDate).toLocaleString() : '',
                  Durum: banner.IsDeleted ? 'Silindi' : banner.State ? 'Aktif' : 'Pasif',
                  'Detay Sıra': i + 1,
                  'Detay Banner Adı': modalRow.BannerName,
                  'Bayi Tipi': DealerType,
                  'Hesap Kodu': modalRow.DealerCode,
                  'Hesap Adı/Ünvan': modalRow.DealerName,
                  Tıklama: modalRow.Count,
                  'Son tıklanma': modalRow.LastDate ? new Date(modalRow.LastDate).toLocaleString() : '',
                })
              }
            }
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(combinedData, 'Detaylı Banner Tıklama İstatistikleri')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const excelButton = async () => {
    try {
      if (filteredList) {
        const data: any[] = []
        if (filteredList.length > 0) {
          filteredList.map((p, index) => {
            return data.push({
              Sıra: index + 1,
              DateRange: state.AllDateStats
                ? 'Tüm Zamanlar'
                : `${moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY')}-${moment(toDateTimeLocal(endDate)).format(
                    'DD.MM.YYYY'
                  )}`,
              Şirket: p.Companies.map(companyId => companyMapping[companyId] || '').join(', '),
              'Banner Adı': p.BannerName,
              'Toplam Tıklama': p.TotalClick,
              Konum: mapValueToLocation(p.Location),
              Kategori: mapValueToCategory(p.Category),
              'Banner Tip': mapValueToType(p.BannerType),
              'Pop-Up Konumu': p.Location === 8 ? mapPopupValueToLocation(p.PopUpLocation) : '',
              'Sayaç Konumu': mapValueToCounter(p.CounterLocation),
              'Banner Başlangıç Tarihi': p.StartDate ? new Date(p.StartDate).toLocaleString() : '',
              'Banner Bitiş Tarihi': p.EndDate ? new Date(p.EndDate).toLocaleString() : '',
              Durum: p.IsDeleted ? 'Silindi' : p.State ? 'Aktif' : 'Pasif',
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Banner Tıklama İstatistikleri')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000).toISOString()

  const getDealerForBanners = async () => {
    if (!startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarih seçiniz...',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: IDealerForBannersRequest = {
      DateRange: {
        StartDate: dateToISO(startDate),
        EndDate: dateToISO(endDate),
      },
      State: state.status,
      IncludeDenemeCard: state.IncludeDenemeCard,
      AllDateStats: state.AllDateStats,
    }
    setState({ IncludeDenemeCard: state.IncludeDenemeCard })
    props.showLoading()
    const getDealerForBannersApi: GetDealerForBannersApi = new GetDealerForBannersApi({})
    await getDealerForBannersApi
      .getDealerForBanners(req)
      .then(result => {
        if (result) {
          setState({ bannerData: result, first: true })
          setFilteredList(result)
          setShowFilterRow(true)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>Banner Tıklama İstatistikleri</span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
              <span>Detaylı Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButtonWithDetail}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <form className='formbanner ms-4'>
              <div className='row mb-3'>
                <div className='col-md-2 col-sm-6 mb-3'>
                  <InputCampaign
                    style={{ cursor: 'pointer' }}
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='col-md-2 col-sm-6 mb-3'>
                  <InputCampaign
                    style={{ cursor: 'pointer' }}
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
                <div className='col-md-2 col-sm-6 mb-3'>
                  <IPSelectBox
                    isClearable
                    options={[
                      { value: 1, label: 'Aktif' },
                      { value: 2, label: 'Pasif' },
                      { value: 3, label: 'Silinmiş' },
                    ]}
                    value={state.statusOption}
                    placeholder='Durum Seçiniz'
                    onChangeSingle={option => {
                      if (option) setState({ statusOption: option, status: +option.value })
                      else setState({ statusOption: option, status: 0 })
                    }}
                  />
                </div>
                <div
                  className='col-md-2 col-sm-6 mb-3'
                  style={{ alignSelf: 'flex-end' }}
                  title='Seçildiğinde tüm zamanların tıklanma sayısı görüntülenir, aksi takdirde girilen aralıktaki tıklanma sayısı görüntülenir.'
                >
                  <IPCheckbox
                    label='Banner Tüm Tıklanma'
                    onChange={e => setState({ AllDateStats: !state.AllDateStats })}
                    checked={state.AllDateStats}
                  />
                </div>
                <div
                  className='col-md-2 col-sm-6 mb-3'
                  style={{ alignSelf: 'flex-end' }}
                  title='Seçildiğinde 203179 Deneme Kartı verileri dahil edilir.'
                >
                  <IPCheckbox
                    label='Deneme Kartı Dahil'
                    onChange={e => setState({ IncludeDenemeCard: !state.IncludeDenemeCard })}
                    checked={state.IncludeDenemeCard}
                  />
                </div>
                <div className='col-md-2 col-sm-6 mb-3'>
                  <IPButton
                    type='button'
                    className='btn btn-danger'
                    style={{ width: '100%' }}
                    text='Süz'
                    onClick={getDealerForBanners}
                  />
                </div>
              </div>
            </form>

            {showFilterRow && (
              <>
                <div
                  className='detailFilter'
                  style={{
                    marginTop: '10px',
                    border: '2px solid #9c9cf1',
                    padding: '10px 5px 0px 5px',
                  }}
                >
                  <div className='row ms-1'>
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        id='Şirket'
                        name='Şirket'
                        placeholder='Şirket seçiniz...'
                        options={state.companyListOptions}
                        value={state.companyListOption}
                        onChangeSingle={option => {
                          setState({ companyListOption: option })
                        }}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        id='Kategori'
                        name='Kategori'
                        placeholder='Kategori seçiniz...'
                        options={bannerCategoryOptions}
                        value={state.categoryFilter}
                        onChangeSingle={option => {
                          setState({ categoryFilter: option })
                        }}
                      />
                    </div>{' '}
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        id='Banner Tip'
                        name='Banner Tip'
                        placeholder='Banner Tipi...'
                        options={bannerTypeOptions}
                        value={state.bannerTypeFilter}
                        onChangeSingle={option => {
                          setState({ bannerTypeFilter: option })
                        }}
                      />
                    </div>{' '}
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        id='Konum'
                        name='Konum'
                        placeholder='Konum seçiniz...'
                        options={bannerLocationOptions}
                        value={state.locationFilter}
                        onChangeSingle={option => {
                          setState({ locationFilter: option })
                        }}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <IPInput
                        style={{ cursor: 'pointer' }}
                        type='text'
                        id='Title'
                        className='form-control'
                        name='Title'
                        placeholder='Banner Adı...'
                        onChange={e => setBannerNameFilter(e.target.value.trim())}
                        value={bannerNameFilter}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            filterList()
                          }
                        }}
                      />
                    </div>
                    <div className='col-12 col-md-2 mb-2'>
                      <IPButton
                        type='button'
                        className='btn btn-primary'
                        text='Detaylı Süz'
                        onClick={filterList}
                        style={{ height: '45px', cursor: 'pointer' }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className='m-4'>
              {state.first && (
                <Table
                  columns={columns}
                  data={data}
                  currentPage={0}
                  headerName={`${moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY')} - ${moment(
                    toDateTimeLocal(endDate)
                  ).format('DD.MM.YYYY')} tarihleri arasındaki bannerlar. ${
                    state.AllDateStats
                      ? '***NOT: Tüm zamanların tıklanma sayısını görüntülüyorsunuz.'
                      : '***NOT: Girilen Tarih aralığındaki tıklanma sayılarını görüntülüyorsunuz.'
                  }`}
                  columnCount={15}
                  textAlign={true}
                  backgroundColor='red'
                  color='white'
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={state.showModal}
        onClose={() => setState({ showModal: false, modalData: [] })}
        hideConfirm={true}
        width={1200}
        title='Banner Tıklama Detay'
      >
        <Table columns={tableColumns} data={tableData} currentPage={0} />
      </IPModal>
      <IPModal
        title={state.bannerImg}
        width='auto'
        maxWidth={1440}
        show={state.showModalPopup}
        onClose={() => setState({ showModalPopup: false })}
        hideConfirm={true}
      >
        <IPImage
          className='banner'
          style={{ height: '100%', padding: '5px 0 0 0' }}
          src={state.bannerImgURL}
          alt={state.bannerImgURL}
        />
      </IPModal>
    </div>
  )
}

export default componentWithContext(Index)
