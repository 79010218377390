import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IFaultTracckingRequest } from '../models/models'

export class PostFaultTrackingApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'ssh/faultTracking',
      requesterInfo,
    })
  }

  public async postFaultTracking(request: IFaultTracckingRequest): Promise<any[]> {
    return await this.postAsync<any[]>(request)
  }
}
