import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IBanks } from '../models/models'

export class GetBanksApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/banks',
      requesterInfo,
    })
  }

  public async getBanks(Id: string): Promise<IBanks[]> {
    this.props.url = `${this.props.url}/${Id}`
    return await this.getAsync<IBanks[]>()
  }
}
