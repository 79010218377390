import React from 'react'
import './IPImage.scss'
interface IPImageProps {
  src: string
  alt: string
  className?: string
  style?: React.CSSProperties
  placeholderSrc?: string
  srcSet?: string
  sizes?: string
  loading?: 'lazy' | 'eager'
}

const Image = (props: IPImageProps) => {
  const { src, alt, className, style, placeholderSrc, srcSet, sizes, loading = 'lazy' } = props // Default to 'lazy' loading

  return (
    <img
      loading={loading}
      src={src}
      alt={alt}
      className={className ? className : ''}
      style={style}
      srcSet={srcSet}
      sizes={sizes}
      onError={e => {
        if (placeholderSrc) {
          ;(e.target as HTMLImageElement).src = placeholderSrc
        }
      }}
    />
  )
}

export default React.memo(Image)
