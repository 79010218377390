import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { ICancelXmlDemandRequest } from '../models/models'

export class CancelXmlDemandApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/cancelXmlDemand',
      requesterInfo,
    })
  }

  public async cancelXmlDemand(request: ICancelXmlDemandRequest): Promise<any[]> {
    return await this.postAsync<any[]>(request)
  }
}
