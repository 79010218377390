import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IChannelFilteredReport } from '../models/getDealerChannelResponseModel'
import { GetDealerChannelListResponseModel } from '../models/getDealerChannelListResponseModel'

export class ChannelFilteredReport extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/campaignBundle/ChannelReport',
      requesterInfo,
    })
  }

  public async channelFilteredList(request: IChannelFilteredReport): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
