import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class GetNotFilteredDetailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetNotFilteredDetail',

      requesterInfo,
    })
  }

  public async getNotFilteredDetailApi(): Promise<any[]> {
    return await this.getAsync<any[]>()
  }
}
