import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IReturnList } from '../models/models'

export class GetOrderReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/orderReturn/getAll',
      requesterInfo,
    })
  }

  public async getOrderReport(req: any): Promise<IReturnList[]> {
    return await this.postAsync<IReturnList[]>(req)
  }
}
