import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ICompany } from '../../../../ProductList/models/commonModels'

export class GetCompaniesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/company-list',
      requesterInfo,
    })
  }

  public async getCompanies(): Promise<ICompany[]> {
    return await this.getAsync<ICompany[]>()
  }
}
