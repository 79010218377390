import React, { useMemo, useState, useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import './BestSellerList.scss'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPInput from '../../../../../components/IPInput'
import Table from './Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { AddMostSelledExceptionProductApi } from '../services/addMostSelledExceptionProductApi'
import Swal from 'sweetalert2'
import { ListMostSelledExceptionProductApi } from '../services/listMostSelledExceptionProductApi'
import { IBestSellers } from '../models/models'
import { DeleteMostSelledExceptionProductApi } from '../services/deleteMostSelledExceptionProductApi'

function BestSellerList(props: BaseComponentWithContextProps) {
  const [bestSellers, setBestSellers] = useState<IBestSellers[]>([])
  const [productCode, setProductCode] = useState("")
  const t = translate(Translations)
  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col2',
      },
      {
        Header: 'İşlem',
        accessor: 'col3',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      bestSellers.map(item => {
        const data = {
          col1: item.Id,
          col2: item.ProductCode,
          col3: (
            <a
              data-action-type='delete'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.delete')}
              onClick={() => deleteMostSelledExceptionProduct(item.Id)}
            >
              <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
            </a>
          ),
        }

        return data
      }),
    [bestSellers]
  )

  const addMostSelledExceptionProduct = async () => {
    props.showLoading()
    try {
      const res = await new AddMostSelledExceptionProductApi({}).addMostSelledExceptionProduct(productCode)
      if (res) {
        setProductCode("");
        listMostSelledExceptionProduct();
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  useEffect(() => {
    listMostSelledExceptionProduct()
  }, [])

  const listMostSelledExceptionProduct = async () => {
    props.showLoading()
    try {
      const res = await new ListMostSelledExceptionProductApi({}).listMostSelledExceptionProduct()
      if (res)
        setBestSellers(res)
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  const deleteMostSelledExceptionProduct = async (id: number) => {
    props.showLoading()
    try {
      const res = await new DeleteMostSelledExceptionProductApi({}).deleteMostSelledExceptionProduct(id)
      if (res) {
        setBestSellers((prevState: any) => prevState.filter(item => item.Id != id))
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-7 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>{t('pages.admin.productOperation.bestSellerList.bestSellerList.header')}</span>
          </div>
          <div className='card-body'>
            <form id='form1' className='form-validate' noValidate>
              {/* <div className='row'>
                <div className='col-2'>
                  <div className='row urun'>{t('pages.admin.productOperation.bestSellerList.bestSellerList.list')}</div>
                </div>
                <div className='col-10 '>
                  <IPTextarea className='textareaAdd' />
                </div>
              </div> */}

              <div className='row '>
                <div className='col-md-6'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='productCode'
                    placeholder='Ürün Kodu'
                    value={productCode}
                    onChange={(e) => setProductCode(e.target.value)}
                  />
                </div>
                <div className='col-md-2'>
                  <IPButton
                    type='button'
                    className='btn btn-success'
                    text={t('pages.admin.productOperation.bestSellerList.bestSellerList.add')}
                    onClick={addMostSelledExceptionProduct}
                  />
                </div>
              </div>
            </form>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(BestSellerList)
