import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IApproveRejectXmlDemandRequest } from '../models/models'

export class ApproveRejectXmlDemandApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/approveRejectXmlDemand',
      requesterInfo,
    })
  }

  public async approveRejectXmlDemand(request: IApproveRejectXmlDemandRequest): Promise<any[]> {
    return await this.postAsync<any[]>(request)
  }
}
