import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IShipping, IShippingDate } from '../models/models'

export class GetShippingDateApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'order/getShippingDate',
      requesterInfo,
    })
  }

  public async getShippingDate(request: IShippingDate): Promise<IShipping> {
    return await this.postAsync<IShipping>(request)
  }
}
