import { IRequesterInfo } from '../../../../base/models/proxyModels'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { PollProductRequest, PollProductResponse } from './models'

export class PollProductListService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/ProductMail/filter',
      requesterInfo,
    })
  }

  public async Filter(req: PollProductRequest): Promise<PollProductResponse[]> {
    return await this.postAsync<PollProductResponse[]>(req)
  }
}
