import MainLayout from '../pages/MySpecial/MySpecialLayout'
import { withLogin } from './withLoginHOC'
import ClientLayout from '../layouts/ClientLayout'
const WithMySpecialLayout = (props: any) => {
  return (
    <>
      <ClientLayout>
        <MainLayout>{props.children}</MainLayout>
      </ClientLayout>
    </>
  )
}

export default withLogin(WithMySpecialLayout)
