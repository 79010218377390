import React from 'react'
//How to use Example
//  <IPPrintButton
//    onClick={() => {
//      printElement(document.getElementById('tableData'))
//    }}
//  />

const PrintButton = ({ onClick, width = 35, height = 35, buttonText = 'Yazdır' }) => {
  return (
    <a onClick={onClick} className='print-button ms-2 d-flex justify-content-center align-items-center'>
      <img
        src='/images/icons/yazicidetay.svg'
        alt='print'
        width={width}
        height={height}
        style={{ cursor: 'pointer', marginRight: '5px' }}
        title='Yazdır'
      />
      {buttonText}
    </a>
  )
}

export default PrintButton
