import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { ServiceRouter } from '../../../../base/proxy/serviceRouter'
import XMLDisplayComponent from './XMLDisplay'
import { xmlBase64toFile } from '../../../../base/utils/dataHelper'

function XmlTokenFree() {
  const [error, setError] = useState<any>(null)
  const [xmlData, setXmlData] = useState<string>('')

  const [searchParams, setSearchParams] = useSearchParams()

  const postXmlRequest = async () => {
    const cmpId = searchParams.get('cmpId')
    const dealerCode = searchParams.get('dealerCode')
    const op = searchParams.get('op')

    const reqUrl = `${ServiceRouter.getServiceUrl()}xml/xml_request?cmpId=${cmpId}&dealerCode=${dealerCode}&op=${op}`

    try {
      const res = await axios.get(reqUrl)

      if (res.data.Message && !res.data.Success) {
        setError(res.data.Message)
      }

      if (res.data.Data) {
        const fileObject = xmlBase64toFile(res.data.Data, 'xmlfile')

        const blob = new Blob([fileObject], { type: fileObject.type });
        const objectURL = window.URL.createObjectURL(blob);
        window.open(objectURL);

        // setXmlData(res.data.Data)
      }
    } catch (err) {
      setError(err)
    }
  }

  useEffect(() => {
    postXmlRequest()
  }, [])

  if (error)
    return (
      <div
        style={{
          backgroundColor: '#333',
          color: '#fff',
          width: '100%',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 30,
        }}
      >
        {error}
      </div>
    )

  return <XMLDisplayComponent base64Data={xmlData} />
}
export default XmlTokenFree
