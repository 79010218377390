import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPSelectBox from '../../../../../components/IPSelectBox'
import './ListOrderRapor.scss'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { ICompany } from '../../../components/models/models'
import { getCompanyApi } from '../../../components/api'
import React, { useState, useEffect, useMemo } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import { IListOrder, IGetOrderOrPaymentDetail, IOrdersAndPayments, IOrderOrPaymentDetail } from '../models/models'
import { GetOrdersAndPaymentsApi } from '../services/getOrdersAndPaymentsApi'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Swal from 'sweetalert2'
import { exportToXLSX, formatPrice, getDateByTimezoneOffset, toDateTimeLocal } from '../../../../../base/utils/dataHelper'
import Table from './Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetOrderOrPaymentDetailApi } from '../services/getOrderOrPaymentDetailApi'
import IPModal from '../../../../../components/IPModal'
import InputCampaign from '../../../../../components/InputCampaign'

interface IListState {
  isTotal: boolean
  isTotalButton: boolean
  isStatusButton: boolean
  typeOption?: ISelectOption
  type: number
  companies: ICompany[]
  company: string
  companyOption?: ISelectOption
  listOrder?: IListOrder
  tableName: string
  orderOrPaymentDetail?: IOrderOrPaymentDetail
  showDetail: boolean
}

function ListOrderRapor(props: BaseComponentWithContextProps) {
  const t = translate(Translations)

  const getInitialState = (): IListState => {
    return {

      isTotal: false,
      type: 0,
      companies: [],
      company: '',
      tableName: '',
      showDetail: false,
      isTotalButton: false,
      isStatusButton: false
    }
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListState>(intialState)

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push(co)
      })
    setState({ companies: data })
  }

  const getOrdersAndPayments = async () => {
    if (!startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarihleri giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!state.companyOption) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen şirket seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!state.typeOption) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen sipariş tipi seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    const data: IOrdersAndPayments = {
      IsShowTotal: state.isTotalButton,
      Type: state.type,
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate),
      Company: state.company,
      IsStatus: state.isStatusButton
    }
    setState({ isTotal: state.isTotalButton })
    const getOrdersAndPaymentsApi: GetOrdersAndPaymentsApi = new GetOrdersAndPaymentsApi({})
    getOrdersAndPaymentsApi.getOrdersAndPayments(data).then(res => {
      if (res) {
        setState({ listOrder: res })
        setfilteredEndDate(endDate)
        setfilteredStartDate(startDate)
        props.hideLoading()
      }
    }).catch((err: any) => {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      })
    })
  }

  const getOrderOrPaymentDetail = async (orderNumber, type, dealer) => {
    props.showLoading()
    const data: IGetOrderOrPaymentDetail = {
      TransactionNumber: orderNumber,
      Type: type,
      Dealer: dealer,
      Company: state.company
    }
    try {
      const getOrderOrPaymentDetailApi: GetOrderOrPaymentDetailApi = new GetOrderOrPaymentDetailApi({})
      getOrderOrPaymentDetailApi.getOrderOrPaymentDetail(data).then(res => {
        if (res) {
          props.hideLoading()
          setState({ orderOrPaymentDetail: res, showDetail: true })
        }
        else {
          props.hideLoading()
        }
      }).catch((err) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: true,
          confirmButtonText: 'Evet',
          denyButtonText: 'Hayır',
        })
      })
    }
    catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      })
    }
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'col1',
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Hesap Adı',
        accessor: 'col3',
      },
      {
        Header: 'Şirket',
        accessor: 'col4',
      },
      {
        Header: 'Sipariş Tipi',
        accessor: 'col5',
      },
      {
        Header: 'Ödeme Tipi',
        accessor: 'col6',
      },
      {
        Header: 'Sipariş No',
        accessor: 'col7',
      },
      {
        Header: 'Kampanya Kodu',
        accessor: 'col8',
      },
      {
        Header: 'Sipariş Anı',
        accessor: 'col9',
      },
      {
        Header: 'Durum',
        accessor: 'col10',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.listOrder?.NotShowTotalList.map((item, index) => {
        const data = {
          col1: (
            <a
              onClick={() => {
                getOrderOrPaymentDetail(item.OrderNumber, item.ProcessType, item.DealerCode)
              }}
              style={{ cursor: 'pointer' }}
            >
              {index + 1}
            </a>
          ),
          col2: item.DealerCode?.replace('0000', ''),
          col3: item.TheName,
          col4:
            state.companies &&
            state.companies.map(i => {
              if (item.Companya === String(i.Code)) {
                return <img className='sellerImage' src={i.Image} alt='' style={{ width: '75px' }} />
              }
            }),
          col5: item.OrderType,
          col6: item.PaymentType,

          col7: item.OrderNumber,
          col8: item.CampaignCode,
          col9: new Date(item.OrderDate).toLocaleString('tr-TR'),
          col10: item.Status
        }
        return data
      }),
    [state.listOrder]
  )

  const excelButton = async () => {
    try {
      if (state.listOrder?.NotShowTotalList) {
        const data: any[] = []
        if (state.listOrder?.NotShowTotalList.length > 0) {
          state.listOrder?.NotShowTotalList.map((item, index) => {
            return data.push({
              Sıra: (index + 1),
              'Hesap Kodu': item.DealerCode?.replace('0000', ''),
              'Hesap Adı': item.TheName,
              Şirket: item.Companya === "1000" ? "Index" : item.Companya === "2300" ? "Netex" : item.Companya === "1200" ? "Despec" : item.Companya === "1100" ? "Datagate" : "",
              'Sipariş Tipi': item.OrderType,
              'Ödeme Tipi': item.PaymentType,
              'Sipariş No': item.OrderNumber,
              'Kampanya Kodu': item.CampaignCode,
              'Sipariş Anı': new Date(item.OrderDate).toLocaleString('tr-TR'),
              Durum: item.Status
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Sipariş/Satışlar Raporu')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }


  const tableColumns2 = useMemo(
    () => [
      {
        Header: 'Hesap Kodu',
        accessor: 'col1',
      },
      {
        Header: 'Hesap Adı',
        accessor: 'col2',
      },
      {
        Header: 'Şirket',
        accessor: 'col3',
      },
      {
        Header: 'Sipariş Adedi',
        accessor: 'col4',
      },
      {
        Header: 'Sipariş Toplamı (KDV Dahil TL)',
        accessor: 'col5',
      },
    ],
    []
  )

  const tableData2 = useMemo(
    () =>
      state.listOrder?.ShowTotalList.map((item, index) => {
        const data = {
          col1: item.DealerSapCode?.replace('0000', ''),
          col2: item.TheName,
          col3:
            state.companies &&
            state.companies.map(i => {
              if (item.Companya === String(i.Code)) {
                return <img className='sellerImage' src={i.Image} alt='' style={{ width: '75px' }} />
              }
            }),
          col4: item.OrderCount,
          col5: item.OrderAmount.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') + " ₺",
        }
        return data
      }),
    [state.listOrder]
  )

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [filteredStartDate, setfilteredStartDate] = useState(new Date())
  const [filteredEndDate, setfilteredEndDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4'>{t('pages.admin.dealerAndOrderReport.orderRapor.listOrderRapor.header')}</span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='d-flex align-items-end'>
                <div className='form-group col-md-2'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>
                <div className='form-group col-md-2'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>
                <div className='form-group col-md-2'>
                  <label htmlFor='location' className='form-label'>
                    Sipariş Tipi
                  </label>
                  <IPSelectBox
                    isClearable
                    options={[
                      { label: 'Hepsi', value: 0 },
                      {
                        label: t('pages.admin.dealerAndOrderReport.orderRapor.listOrderRapor.warehouseShipment'),
                        value: 1,
                      },
                      {
                        label: t('pages.admin.dealerAndOrderReport.orderRapor.listOrderRapor.financialTransactions'),
                        value: 2,
                      },
                    ]}
                    value={state.typeOption}
                    onChangeSingle={(option) => {
                      if (option)
                        return setState({ typeOption: option, type: Number(option.value) })
                      return setState({ typeOption: option, type: 0 })
                    }}
                  />
                </div>
                <div className='form-group col-md-2'>
                  <label htmlFor='location' className='form-label'>
                    Şirket
                  </label>
                  <IPSelectBox
                    isClearable
                    options={
                      state.companies &&
                      state.companies.map((item: ICompany) => ({
                        label: item.Name,
                        value: `${item.Code}`,
                      }))
                    }
                    value={state.companyOption}
                    onChangeSingle={(option) => {
                      if (option)
                        return setState({ companyOption: option, company: String(option.value) })
                      return setState({ companyOption: option, company: "" })
                    }}
                  />
                </div>
                <div className='form-group col-md-2'>
                  <div className='col'>
                    <IPCheckbox
                      name='Active'
                      onClick={() => setState({ isTotalButton: !state.isTotalButton })}
                      checked={state.isTotalButton}
                      label={t('pages.admin.dealerAndOrderReport.orderRapor.listOrderRapor.totalShow')}
                    />
                    <IPCheckbox
                      name='Status'
                      onClick={() => setState({ isStatusButton: !state.isStatusButton })}
                      checked={state.isStatusButton}
                      label={'Durum Sorgula'}
                    />
                  </div>

                </div >
                <div className='form-group col-md-2'>
                  <IPButton
                    type='button'
                    className='btn btn-danger'
                    text='Göster'
                    onClick={getOrdersAndPayments}
                  />
                </div >
              </div>
            </form>
            <br />
            <div className='row'>
              <div className='col-lg-12'>
                {/* {
                  orderList && orderList.length > 0 &&
                  <div className='row' style={{ marginTop: "10px" }}>
                    <h6><b>Sipariş Veren Bayi Bilgileri</b></h6>
                    <table id={'datatb'} ref={tableRef} className='table'></table>
                  </div>
                }
                {
                  userList && userList.length > 0 &&
                  <div className='row' style={{ marginTop: "10px" }}>
                    <h6><b>Login Olan Bayi Bilgileri</b></h6>
                    <table id={'datatb'} ref={tableRef2} className='table'></table>
                  </div>
                } */}

                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>


                  <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                    {state.listOrder && state.listOrder.NotShowTotalList.length > 0 && (
                      <Table
                        columns={tableColumns}
                        data={tableData}
                        colSpan={9}
                        currentPage={0}
                        isTotal={state.isTotal}
                        showTotalAmount={state.listOrder.ShowTotalAmount}
                        showTotalCount={state.listOrder.ShowTotalCount}
                        tableName={`IT Bayileri ${new Date(filteredStartDate).toLocaleDateString() + '-' + new Date(filteredEndDate).toLocaleDateString()
                          } Tarihleri Arasındaki Siparişler`}
                      />
                    )}

                    {state.listOrder && state.listOrder.ShowTotalList.length > 0 && (
                      <Table
                        columns={tableColumns2}
                        data={tableData2}
                        colSpan={5}
                        currentPage={0}
                        isTotal={state.isTotal}
                        showTotalAmount={state.listOrder.ShowTotalAmount}
                        showTotalCount={state.listOrder.ShowTotalCount}
                        tableName={`IT Bayileri ${new Date(filteredStartDate).toLocaleDateString() + '-' + new Date(filteredEndDate).toLocaleDateString()
                          } Tarihleri Arasındaki Siparişler Toplamı (IDX  ve Internet Deneme Kartı Hariç)`}
                      />
                    )}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <IPModal
          show={state.showDetail}
          onClose={() => setState({ showDetail: false })}
          hideConfirm={true}
          width={'auto'}
          title={state.orderOrPaymentDetail?.OrderReturnModel ? 'Sipariş Detay' : 'Ödeme Detay'}
        >
          <>
            {state.orderOrPaymentDetail && state.orderOrPaymentDetail.OrderReturnModel && (
              <div>
                <div className='row'>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '150px' }}>Şirket</b> <span className='mr-2'>:</span>
                      {state.companies.map(i => {
                        if (state.orderOrPaymentDetail?.OrderReturnModel.Company === String(i.Code)) {
                          return <img className='sellerImage' src={i.Image} alt='' style={{ width: '75px' }} />
                        }
                      })}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '150px' }}>Sipariş Numarası</b> <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail.OrderReturnModel.OrderNumber}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '150px' }}>Ödeme Tipi</b> <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail.OrderReturnModel.PaymentMethod}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '150px' }}>Kur Tipi</b> <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail.OrderReturnModel.CurrencyType}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '150px' }}>Kur</b> <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail.OrderReturnModel.CurrencyValue}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '150px' }}>MT</b> <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail.OrderReturnModel.MT}
                    </div>
                  </div>
                </div>
                <br />
                <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                  <thead>
                    <tr className='EInvoicetableDescription'>
                      <td scope='col'>Mal Kodu</td>
                      <td scope='col'>Açıklama</td>
                      <td scope='col'>Mik</td>
                      <td scope='col'>Birim Tutar</td>
                      <td scope='col'>Toplam Tutar</td>
                    </tr>
                  </thead>
                  {state.orderOrPaymentDetail.OrderReturnModel.Items.map(order => (
                    <tbody key={order.ProdId}>
                      <td>{order.ProductCode}</td>
                      <td>{order.ProductName}</td>
                      <td>{order.Quantity}</td>
                      <td style={{ textAlign: 'right' }}>{order.Price2}</td>
                      <td style={{ textAlign: 'right' }}>{order.TotalPrice2}</td>
                    </tbody>
                  ))}
                  <tbody>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>
                      <b>Ara Toplam</b>
                    </td>
                    <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.TotalPrice}</td>
                  </tbody>
                  <tbody>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>
                      <b>Kargo</b>
                    </td>
                    <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.Cargo}</td>
                  </tbody>
                  <tbody>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>
                      <b>KDV</b>
                    </td>
                    <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.TotalKdv}</td>
                  </tbody>
                  <tbody>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>
                      <b>G. Toplam</b>
                    </td>
                    <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.Total}</td>
                  </tbody>
                </table>
              </div>
            )}
            {state.orderOrPaymentDetail?.PaymentReturnModel && (
              <div id='order'>
                <div className='form-row' style={{ justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                  <div className='form-group'>
                    <h4 style={{ marginTop: '10px' }}>Kredi Kartı Makbuzu</h4>
                  </div>
                </div>
                <hr />
                <div className='row'>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Makbuz No</b> <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail?.PaymentReturnModel?.ReceiptNumber}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Tutar (TL) </b>
                      <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail
                        ? formatPrice(state.orderOrPaymentDetail?.PaymentReturnModel?.Amount, 'TRY', 'symbol')
                        : formatPrice(0, 'TRY', 'symbol')}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Belge No</b> <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail?.PaymentReturnModel?.DocumentNo}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Banka </b>
                      <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail?.PaymentReturnModel?.Bank}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Tarih</b> <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail?.PaymentReturnModel?.Date
                        ? new Date(state.orderOrPaymentDetail?.PaymentReturnModel?.Date).toLocaleDateString('tr-TR')
                        : '-'}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Taksit Sayısı </b>
                      <span className='mr-2'>:</span>
                      {state.orderOrPaymentDetail?.PaymentReturnModel?.Installment}
                    </div>
                  </div>
                </div>
                <hr />
                <div className='d-flex align-items-center justify-content-start'>
                  {state.orderOrPaymentDetail?.PaymentReturnModel?.DealerCode}
                </div>
                <div className='d-flex align-items-center justify-content-start'>
                  {state.orderOrPaymentDetail?.PaymentReturnModel?.DealerName}
                </div>
                <h5 style={{ marginTop: '10px' }}>
                  <b>Kredi Kartı Makbuzu</b>
                </h5>
                <hr />
                <div className='d-flex align-items-center justify-content-start'>
                  <b style={{ width: '200px' }}>Ad Soyad </b>
                  <span className='mr-2'>:</span>
                  {state.orderOrPaymentDetail?.PaymentReturnModel?.NameSurname}
                </div>
                <div className='d-flex align-items-center justify-content-start'>
                  <b style={{ width: '200px' }}>Kredi Kartı Numarası</b>
                  <span className='mr-2'>:</span>
                  {state.orderOrPaymentDetail?.PaymentReturnModel?.CrediCard}
                </div>
                <div className='d-flex align-items-center justify-content-start'>
                  <b style={{ width: '200px' }}>Son kullanma Tarihi</b>
                  <span className='mr-2'>:</span>
                  {state.orderOrPaymentDetail?.PaymentReturnModel?.ExpireDate}
                </div>
              </div>
            )}
          </>
        </IPModal>
      </div>
    </div>

  )
}

export default componentWithContext(ListOrderRapor)
