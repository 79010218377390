import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { DealerCampaignReportResponse } from './models'

export class DealerCampaignReportService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'campaign/DealerCampaignReport',
      requesterInfo,
    })
  }

  public async DealerCampaignReport(): Promise<DealerCampaignReportResponse[]> {
    return await this.getAsync<DealerCampaignReportResponse[]>()
  }
}
