import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export interface ListPastCurrencyResponse {
  Date: Date
  Usd: number
  Euro: number
}

export class ListPastCurrencyService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/list-past-currency',
      requesterInfo,
    })
  }

  public async Post(req: { StartDate: Date; EndDate: Date }): Promise<ListPastCurrencyResponse[]> {
    return await this.postAsync<ListPastCurrencyResponse[]>(req)
  }
}
