import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { IMicrosoft } from '../models/models'

export class GetMicrosoftContentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'DppEtCetera/getMicrosoftContent',
      requesterInfo,
    })
  }

  public async getMicrosoftContent(): Promise<IMicrosoft> {
    return await this.getAsync<IMicrosoft>()
  }
}
