import { useState, useEffect, useMemo, ReactChild, ReactFragment, ReactPortal } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { downloadFileFromUrl, formatPrice } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import IPModal from '../../../../../components/IPModal'
import { ICompany } from '../../../../ProductList/models/commonModels'
import { IAddExtractRequest, IExcel, IExtract, IGroupedData, IOrderOrPaymentDetail } from '../models/models'
import { GetCompaniesApi } from '../services/getCompaniesApi'
import { GetCurrentAccountStatementApi } from '../services/getCurrentAccountStatementApi'
import { PostPaymentDetailApi } from '../services/postPaymentDetailApi'
import './styles.scss'
import Table from './Table'
import { PostDealerReminderApi } from '../services/postDealerReminderApi'
import { GetCurrentAccountStatementToExcelApi } from '../services/getCurrentAccountStatementToExcelApi'
import InputCampaign from '../../../../../components/InputCampaign'

interface IListExtractState {
  extract: IExtract[]
  companyActive: string
  currencyActive: string
  companyActiveData: IGroupedData[]
  tl?: IGroupedData
  usd?: IGroupedData
  eur?: IGroupedData
  showDocument: boolean
  orderOrPaymentDetail?: IOrderOrPaymentDetail
  companies: ICompany[]
  totalTl: number
  totalUsd: number
  totalEur: number
}

function ListExtract(props: BaseComponentWithContextProps) {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const getInitialState = (): IListExtractState => {
    return {
      extract: [],
      companyActive: '1000',
      companyActiveData: [],
      currencyActive: 'HEPSI',
      showDocument: false,
      companies: [],
      totalTl: 0,
      totalUsd: 0,
      totalEur: 0
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListExtractState>(intialState)
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    getCurrentAccountStatement()
    getCompanies()
  }, [])

  const qpStartDate = searchParams.get('startDate')
  const qpEndDate = searchParams.get('endDate')

  const StartDate = new Date()
  const EndDate = new Date()
  StartDate.setTime(qpStartDate ? +qpStartDate : 0)
  EndDate.setTime(qpEndDate ? +qpEndDate : 0)

  const toDateTimeLocal = (date: Date) => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  const getCompanies = () => {
    const api: GetCompaniesApi = new GetCompaniesApi({})
    api.getCompanies().then((res: ICompany[]) => {
      setState({ companies: res })
    })
  }

  const getCurrentAccountStatement = async () => {
    props.showLoading();

    try {
      const getCurrentAccountStatementApi = new GetCurrentAccountStatementApi({});
      const data: IAddExtractRequest = {
        StartDate: new Date(StartDate),
        EndDate: new Date(EndDate),
      };

      setStartDate(new Date(data.StartDate));
      setEndDate(new Date(data.EndDate));

      const res = await getCurrentAccountStatementApi.getCurrentAccountStatement(data);

      if (res) {
        setState({ extract: res });
      }
    } catch (err: any) {
      showError(err.description);
    } finally {
      props.hideLoading();
    }
  };

  const getCurrentAccountStatementToExcel = async () => {
    props.showLoading();

    try {
      const getCurrentAccountStatementToExcelApi = new GetCurrentAccountStatementToExcelApi({});
      const data: IExcel = {
        CompanyCode: state.companyActive,
        StartDate: startDate,
        EndDate: endDate,
      };

      const res = await getCurrentAccountStatementToExcelApi.getCurrentAccountStatementToExcel(data);

      if (res) {
        downloadFileFromUrl('Cari Hesap Ekstresi', res.FileUrl);
      }
    } catch (err: any) {
      showError(err.description);
    } finally {
      props.hideLoading();
    }
  };

  const showError = (description) => {
    Swal.fire({
      icon: 'error',
      title: description,
      showConfirmButton: true,
      allowOutsideClick: false,
    });
  };

  useEffect(() => {
    postDealerReminder();
  }, [state.companyActive]);

  const postDealerReminder = async () => {
    props.showLoading();
    const postDealerReminderApi = new PostDealerReminderApi({});

    try {
      const res = await postDealerReminderApi.postDealerReminder();
      if (res) {
        const relevantData = res.find(r => r.Company === +state.companyActive);
        if (relevantData) {
          setState({
            totalTl: relevantData.TL_BAK,
            totalUsd: relevantData.USD_BAK,
            totalEur: relevantData.EUR_BAK
          });
        }
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    } finally {
      props.hideLoading();
    }
  };

  useEffect(() => {
    if (state.extract && state.companyActive) {
      if (
        state.extract.findIndex(r => {
          return r.CompanyCode === state.companyActive
        }) > -1
      ) {
        state.extract
          .filter(r => {
            return r.CompanyCode === state.companyActive
          })
          .map(re => {
            const tl = re.GroupedData.filter(grp => {
              return grp.Currency === 'TL'
            })
            const usd = re.GroupedData.filter(grp => {
              return grp.Currency === 'USD'
            })
            const eur = re.GroupedData.filter(grp => {
              return grp.Currency === 'EUR'
            })
            return setState({ companyActiveData: re.GroupedData, tl: tl[0], usd: usd[0], eur: eur[0] })
          })
      }
    }
  }, [state.extract, state.companyActive])


  const postPaymentDetail = async (invoiceNumber: any) => {
    props.showLoading();
    const postPaymentDetailApi = new PostPaymentDetailApi({});
    try {
      const result = await postPaymentDetailApi.postPaymentDetail(invoiceNumber);
      if (result) {
        setState({ showDocument: true, orderOrPaymentDetail: result });
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    } finally {
      props.hideLoading();
    }
  };

  const generateColumn = (Header, accessor) => ({
    Header,
    accessor,
  });

  const tableColumns = useMemo(
    () => [
      generateColumn(<span style={{ fontWeight: '600' }}>TARİH </span>, 'col1'),
      generateColumn(<span style={{ fontWeight: '600' }}>EVRAK NO </span>, 'col2'),
      generateColumn(<span style={{ fontWeight: '600' }}>BELGE NO </span>, 'col3'),
      generateColumn(<span style={{ fontWeight: '600' }}>İŞLEM </span>, 'col4'),
      generateColumn(<span style={{ fontWeight: '600' }}>VADE </span>, 'col5'),
      generateColumn(<span style={{ fontWeight: '600' }}>P.B </span>, 'col6'),
      generateColumn(<span style={{ fontWeight: '600' }}>KUR </span>, 'col7'),
      generateColumn(<span style={{ fontWeight: '600' }}>BORÇ </span>, 'col8'),
      generateColumn(<span style={{ fontWeight: '600' }}>ALACAK </span>, 'col9'),
      generateColumn(<span style={{ fontWeight: '600' }}>BORÇ BAKİYE </span>, 'col10'),
      generateColumn(<span style={{ fontWeight: '600', whiteSpace: 'nowrap' }}>ALACAK BAKİYE </span>, 'col11'),
    ],
    []
  );

  const navigate: any = useNavigate()

  const formatCurrency = (value) => {
    return value
      .toFixed(2)
      .replace('.', ',')
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  const renderTable = (obj: { Tarih: string | number | Date; EvrakNo: string; Type: number; BelgeNo: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; IslemTuru: any; Vade: string | number | Date; ParaBirimi: any; Kur: number; Borc: number; Alacak: number; BorcBakiye: number; AlacakBakiye: number }[]) => {
    if (!obj) return []

    return obj?.map((dt: { Tarih: string | number | Date; EvrakNo: string; Type: number; BelgeNo: boolean | ReactChild | ReactFragment | ReactPortal | null | undefined; IslemTuru: any; Vade: string | number | Date; ParaBirimi: any; Kur: number; Borc: number; Alacak: number; BorcBakiye: number; AlacakBakiye: number }) => ({
      col1: new Date(dt.Tarih) instanceof Date && new Date(dt.Tarih) < new Date("1989-01-01") ? "" : new Intl.DateTimeFormat('tr-TR').format(new Date(dt.Tarih)),
      col2: dt.EvrakNo,
      col3: (
        <div
          className='link'
          onClick={() => {
            if (dt.Type === 0) {
              return
            }
            if (dt.Type === 1) {
              navigate(`/myspecial/EInvoice?eInvoiceId=${dt.BelgeNo}`)
            }
            if (dt.Type === 2) {
              const req: any = {
                TransactionNumber: dt.EvrakNo,
                OrderNumber: dt.BelgeNo,
                Company: state.companyActive
              }
              postPaymentDetail(req)
            }
          }}
          style={{ cursor: dt.Type === 1 ? '' : 'pointer', fontSize: '15px' }}
        >
          {dt.BelgeNo}
        </div>
      ),
      col4: dt.IslemTuru,
      col5: new Date(dt.Vade) instanceof Date && new Date(dt.Vade) < new Date("1989-01-01") ? "" : new Intl.DateTimeFormat('tr-TR').format(new Date(dt.Vade)),
      col6: dt.ParaBirimi,
      col7: formatCurrency(dt.Kur),
      col8: formatCurrency(dt.Borc),
      col9: formatCurrency(dt.Alacak),
      col10: <div style={{ color: dt.BorcBakiye === 0 ? '#000' : '#DF3A01' }}>{formatCurrency(dt.BorcBakiye)}</div>,
      col11: <div style={{ color: dt.AlacakBakiye === 0 ? '#000' : '#04B486' }}>{formatCurrency(dt.AlacakBakiye)}</div>,
    }))
  }

  const tableData = useMemo(() => {
    if (state.tl) {
      return renderTable(state.tl.Data)
    }
    return []
  }, [state.tl])

  const tableData2 = useMemo(() => {
    if (state.usd) {
      return renderTable(state.usd.Data)
    }
    return []
  }, [state.usd])

  const tableData3 = useMemo(() => {
    if (state.eur) {
      return renderTable(state.eur.Data)
    }
    return []
  }, [state.eur])

  const getCurrentAccountStatementApi = () => {
    if (!startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarihleri giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    window.open(
      `/myspecial/currentAccount/extract/list?startDate=${new Date(startDate).getTime()}&endDate=${new Date(endDate).getTime()}`,
      '_blank'
    )
  }

  return (
    <>
      <div className='container-fluid'>
        <div className='card-header'>
          <span className='h4'>Cari Hesap Özeti</span>
        </div>
        <div className='card-body'>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '100px' }}>Şirket</b> <span className='mr-2'>:</span>
            {props.user?.IsRemote
              ? 'Seçili Şirket: ' +
              props.user?.AccountNameRemote
              :
              props.user.DealerName}
          </div>
          <div className='d-flex align-items-center justify-content-start'>
            <b style={{ width: '100px' }}>Hesap Kodu</b>
            <span className='mr-2'>:</span>
            {props.user?.IsRemote
              ? 'Seçili Bayi: ' +
              props.user?.AccountCodeRemote.replace('0000', '')
              :
              props.user.AccountCode && props.user.AccountCode.replace("0000", "")}
          </div>
          <div className='row' style={{ backgroundColor: '#efefef' }}>
            <div className='col-12 col-md-3 mb-3' style={{ marginBottom: '20px', marginTop: '20px' }}>
              <InputCampaign
                type='date'
                id='StartDate'
                name='StartDate'
                label={'Başlangıç Tarihi'}
                value={toDateTimeLocal(startDate)}
                onChange={e => getDate(e.target.value, "Start")}
                isActive
              />
            </div>
            <div className='col-12 col-md-3 mb-3' style={{ marginBottom: '20px', marginTop: '20px' }}>
              <InputCampaign
                type='date'
                id='EndDate'
                name='EndDate'
                label={'Bitiş Tarihi'}
                value={toDateTimeLocal(endDate)}
                onChange={e => getDate(e.target.value, "End")}
                isActive
              />
            </div>
            <div className='col-12 col-md-3 mb-3' style={{ marginBottom: '20px', marginTop: '20px' }}>
              <IPButton
                type='button'
                className='btn btn-danger button-excel w-sm-100'
                text='Excele Aktar'
                onClick={() => getCurrentAccountStatementToExcel()}
                style={{ width: '200px', float: 'right' }}
              />
            </div>
            <div className='col-12 col-md-3 mb-3' style={{ marginBottom: '20px', marginTop: '20px' }}>
              <IPButton
                type='button'
                className='btn btn-success button-list w-sm-100'
                style={{
                  backgroundColor: 'white',
                  color: 'red',
                  borderColor: 'red',
                  width: '200px',
                  float: 'left',
                }}
                text='Listele'
                onClick={() => getCurrentAccountStatementApi()}
              />
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-start' style={{ marginTop: '10px' }}>
            <b style={{ width: '200px' }}>RAPOR ARALIĞI</b> <span className='mr-2'>:</span>
            {new Intl.DateTimeFormat('tr-TR').format(StartDate) +
              ' - ' +
              new Intl.DateTimeFormat('tr-TR').format(EndDate)}
          </div>
        </div>
        <nav className='cari-hesap--tab-menu'>
          <ul>
            <li
              onClick={() => setState({ companyActive: '1000' })}
              className={`dropdown-submenu company-tab ${state.companyActive === '1000' ? 'active-company-tab' : ''}`}
            >
              <span>INDEX</span>
            </li>
            <li
              onClick={() => setState({ companyActive: '1100' })}
              className={`dropdown-submenu company-tab ${state.companyActive === '1100' ? 'active-company-tab' : ''}`}
            >
              <span>DATAGATE</span>
            </li>
            <li
              onClick={() => setState({ companyActive: '1200' })}
              className={`dropdown-submenu company-tab ${state.companyActive === '1200' ? 'active-company-tab' : ''}`}
            >
              <span>DESPEC</span>
            </li>
            <li
              onClick={() => setState({ companyActive: '2300' })}
              className={`dropdown-submenu company-tab ${state.companyActive === '2300' ? 'active-company-tab' : ''}`}
            >
              <span>NETEX</span>
            </li>
          </ul>
        </nav>
        <div className='cari-hesap--row'>
          <div className='cari-hesap--col'>
            <b>TOPLAM BAKİYE</b>
          </div>
          <div className='cari-hesap--col' style={{ color: '#da291c' }}>
            <b>{state.totalTl.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} TL</b>
          </div>
          <div className='cari-hesap--col' style={{ color: '#008000' }}>
            <b>{state.totalUsd.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} USD</b>
          </div>
          <div className='cari-hesap--col' style={{ color: '#182747' }}>
            <b>{state.totalEur.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.')} EUR</b>
          </div>
        </div>
        <nav className='cari-hesap--tab-menu mt-4'>
          <ul>
            <li
              onClick={() => setState({ currencyActive: 'USD' })}
              className={`dropdown-submenu currency-tab ${state.currencyActive === 'USD' ? 'active-currency-tab' : ''}`}
            >
              <span>USD</span>
            </li>
            <li
              onClick={() => setState({ currencyActive: 'TL' })}
              className={`dropdown-submenu currency-tab ${state.currencyActive === 'TL' ? 'active-currency-tab' : ''}`}
            >
              <span>TRY</span>
            </li>
            <li
              onClick={() => setState({ currencyActive: 'EUR' })}
              className={`dropdown-submenu currency-tab ${state.currencyActive === 'EUR' ? 'active-currency-tab' : ''}`}
            >
              <span>EUR</span>
            </li>
            <li
              onClick={() => setState({ currencyActive: 'HEPSI' })}
              className={`dropdown-submenu currency-tab ${state.currencyActive === 'HEPSI' ? 'active-currency-tab' : ''
                }`}
            >
              <span>HEPSİ</span>
            </li>
          </ul>
        </nav>
        {(state.currencyActive === 'HEPSI' || state.currencyActive === 'TL') && state.tl && state.tl.Data && (
          <>
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '100px' }}>Para Birimi</b> <span className='mr-2'>:</span>
              <b>TL</b>
            </div>
            <Table
              columns={tableColumns}
              data={tableData}
              currentPage={0}
              general={{
                GeneralDebt: state.tl.GeneralDebt,
                GeneralDebtBalance: state.tl.GeneralDebtBalance,
                GeneralReceivable: state.tl.GeneralReceivable,
                GeneralReceivableBalance: state.tl.GeneralReceivableBalance,
              }}
              currency={'TRY'}
            />
          </>
        )}
        <br />
        {(state.currencyActive === 'HEPSI' || state.currencyActive === 'USD') && state.usd && state.usd.Data && (
          <>
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '100px' }}>Para Birimi</b> <span className='mr-2'>:</span>
              <b>USD</b>
            </div>
            <Table
              columns={tableColumns}
              data={tableData2}
              currentPage={0}
              general={{
                GeneralDebt: state.usd.GeneralDebt,
                GeneralDebtBalance: state.usd.GeneralDebtBalance,
                GeneralReceivable: state.usd.GeneralReceivable,
                GeneralReceivableBalance: state.usd.GeneralReceivableBalance,
              }}
              currency={'USD'}
            />
          </>
        )}
        <br />
        {(state.currencyActive === 'HEPSI' || state.currencyActive === 'EUR') && state.eur && state.eur.Data && (
          <>
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '100px' }}>Para Birimi</b> <span className='mr-2'>:</span>
              <b>EUR</b>
            </div>
            <Table
              columns={tableColumns}
              data={tableData3}
              currentPage={0}
              general={{
                GeneralDebt: state.eur.GeneralDebt,
                GeneralDebtBalance: state.eur.GeneralDebtBalance,
                GeneralReceivable: state.eur.GeneralReceivable,
                GeneralReceivableBalance: state.eur.GeneralReceivableBalance,
              }}
              currency={'EUR'}
            />
          </>
        )}
        <div className='row p-0 m-t-10'>
          <div className='form-group col-md-6'>
            <IPButton
              type='button'
              className='btn btn-danger button-excel w-sm-100'
              text='Yazdır'
              onClick={() => { window.print() }}
              style={{ width: '200px', float: 'right' }}
            />
          </div>
          <div className='form-group col-md-6'>
            <IPButton
              type='button'
              className='btn btn-danger button-list w-sm-100'
              style={{
                // backgroundColor: 'white',
                // color: 'red',
                // borderColor: 'red',
                width: '200px',
                float: 'left',
              }}
              text='Kapat'
              onClick={() => window.close()}
            />
          </div>
        </div>
      </div>
      <IPModal
        show={state.showDocument}
        onClose={() => setState({ showDocument: false })}
        hideConfirm={true}
        width={'auto'}
        title={state.orderOrPaymentDetail?.OrderReturnModel ? 'Sipariş Detay' : 'Ödeme Detay'}
      >
        <>
          {state.orderOrPaymentDetail && state.orderOrPaymentDetail.OrderReturnModel && (
            <div>
              <div className='row'>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Şirket</b> <span className='mr-2'>:</span>
                    {state.companies.map(i => {
                      if (state.orderOrPaymentDetail?.OrderReturnModel.Company === String(i.Code)) {
                        return <img className='sellerImage' src={i.Image} alt='' style={{ width: '75px' }} />
                      }
                    })}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Sipariş Numarası</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.OrderNumber}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Ödeme Tipi</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.PaymentMethod}
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Kur Tipi</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.CurrencyType}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Kur</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.CurrencyValue}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>MT</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.MT}
                  </div>
                </div>
              </div>
              <br />
              <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                <thead>
                  <tr className='EInvoicetableDescription'>
                    <td scope='col'>Mal Kodu</td>
                    <td scope='col'>Açıklama</td>
                    <td scope='col'>Mik</td>
                    <td scope='col'>Birim Tutar</td>
                    <td scope='col'>Toplam Tutar</td>
                  </tr>
                </thead>
                {state.orderOrPaymentDetail.OrderReturnModel.Items.map(order => (
                  <tbody key={order.ProdId}>
                    <td>{order.ProductCode}</td>
                    <td>{order.ProductName}</td>
                    <td>{order.Quantity}</td>
                    <td style={{ textAlign: 'right' }}>{order.Price1}</td>
                    <td style={{ textAlign: 'right' }}>{order.TotalPrice1}</td>
                  </tbody>
                ))}
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>
                    <b>Ara Toplam</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.TotalPrice}</td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>
                    <b>Kargo</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.Cargo}</td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>
                    <b>KDV</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.TotalKdv}</td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>
                    <b>G. Toplam</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.Total}</td>
                </tbody>
              </table>
            </div>
          )}
          {state.orderOrPaymentDetail?.PaymentReturnModel && (
            <div id='order'>
              <div className='form-row' style={{ justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                <div className='form-group'>
                  <h4 style={{ marginTop: '10px' }}>Kredi Kartı Makbuzu</h4>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Makbuz No</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.ReceiptNumber}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Tutar (TL) </b>
                    <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail
                      ? formatPrice(state.orderOrPaymentDetail?.PaymentReturnModel?.Amount, 'TRY', 'symbol')
                      : formatPrice(0, 'TRY', 'symbol')}
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Belge No</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.DocumentNo}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Banka </b>
                    <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.Bank}
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Tarih</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.Date
                      ? new Date(state.orderOrPaymentDetail?.PaymentReturnModel?.Date).toLocaleDateString('tr-TR')
                      : '-'}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Taksit Sayısı </b>
                    <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.Installment}
                  </div>
                </div>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-start'>
                {state.companyActive === '1000' ? "Index Bilgisayar Sistemleri Tic. A.Ş." : (state.companyActive === '1100' ? "DATAGATE BİLGİSAYAR MALZEMELERİ TİCARET A.Ş" : (state.companyActive === '1200' ? "Despec Bilgisayar Pazarlama Tic. A.Ş." : "NETEKS TEKNOLOJİ ÜRÜNLERİ A.Ş."))}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.DealerCode.replace("0000", "")}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.DealerName}
              </div>
              <h5 style={{ marginTop: '10px' }}>
                <b>Kredi Kartı Makbuzu</b>
              </h5>
              <hr />
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '200px' }}>Ad Soyad </b>
                <span className='mr-2'>:</span>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.NameSurname}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '200px' }}>Kredi Kartı Numarası</b>
                <span className='mr-2'>:</span>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.CrediCard}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '200px' }}>Son kullanma Tarihi</b>
                <span className='mr-2'>:</span>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.ExpireDate}
              </div>
            </div>
          )}
        </>
      </IPModal>
    </>
  )
}
export default componentWithContext(ListExtract)
