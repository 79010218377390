import React, { useEffect, useMemo } from 'react'
import { IGetLetterOfIndemnityCommandRequest, IGetLetterOfIndemnityCommandResponseList, ILetterGuarantee } from '../models/models'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { useCustomReducer } from '../../../../../base/customHooks'
import Table from '../../../../../components/Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetLetterGuaranteeApi } from '../services/getLetterGuaranteeApi'
import Swal from 'sweetalert2'
interface ILetterGuaranteeState {
  letterGuarantee: ILetterGuarantee[]
  total: string
  color: any[]
}

function Index(props: BaseComponentWithContextProps) {
  const getInitialState = (): ILetterGuaranteeState => {
    return {
      letterGuarantee: [],
      total: "",
      color: []
    }
  }

  const t = translate(Translations)
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ILetterGuaranteeState>(intialState)

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Belge No',
        accessor: 'col1',
      },
      {
        Header: 'Belge Tarihi',
        accessor: 'col2',
      },
      {
        Header: 'Vade Tarihi',
        accessor: 'col3',
      },
      {
        Header: 'Banka',
        accessor: 'col4',
      },
      {
        Header: 'Şube',
        accessor: 'col5',
      },
      {
        Header: 'Teminat Tutarı',
        accessor: 'col6',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.letterGuarantee.map(item => {
        const data = {
          col1: item.No,
          col2: new Date(item.StartDate).toLocaleDateString(),
          col3: new Date(item.EndDate).toLocaleDateString(),
          col4: item.Banka,
          col5: item.Tax_Loc,
          col6: item.TeminatStr,
        }
        return data
      }),
    [state.letterGuarantee]
  )

  const getLetterOfIndemnity = async () => {
    const req: IGetLetterOfIndemnityCommandRequest = {
      SapCode: props.user.IsRemote ? props.user.AccountCodeRemote : props.user.AccountCode
    }
    props.showLoading()
    const api: GetLetterGuaranteeApi = new GetLetterGuaranteeApi({})
    await api
      .getLetterGuarantee(req)
      .then(async (result: any) => {
        if (result) {
          if (result.length === 0) {
            Swal.fire({
              icon: 'error',
              title: 'Teminat mektubunuz bulunmamaktadır.',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            setState({ letterGuarantee: [] })
            props.hideLoading()
            return
          }
          const colorData: any[] = [];
          result.GetLetterOfIndemnityCommandResponseList.map((res: ILetterGuarantee) => {
            var tarih = new Date(new Date().setDate(new Date().getDate() - 30))
            if (new Date() > new Date(res.EndDate))
              colorData.push("#dc3545")
            else if (new Date(res.EndDate) < tarih)
              colorData.push("#fd7e14")
            else
              colorData.push("#198754")
          })
          setState({ letterGuarantee: result.GetLetterOfIndemnityCommandResponseList, total: result.Toplam, color: colorData })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  useEffect(() => {
    getLetterOfIndemnity()
  }, [])

  return (
    <div className='row'>
      <div className='col-lg-12 text-nowrap'>
        <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
          <div className='row'>
            <div className='col-md-12'>
              <Table columns={tableColumns} data={tableData} currentPage={0} columnCountTotal={4} total={state.total} colorRow={state.color} />
              {state.letterGuarantee && state.letterGuarantee.length > 0 &&
                <div className='row mt-4 mt-lg-0' style={{ whiteSpace: 'pre-wrap' }}>
                  <div className='col-md-12'>
                    <p>
                      Vadesi biten Teminatlar <b style={{ color: '#dc3545' }}>kırmızı</b> renk ile
                    </p>
                  </div>
                  <div className='col-md-12'>
                    <p>
                      Vadesinin bitmesine 1 aydan az kalanlar <b style={{ color: '#fd7e14' }}>turuncu</b> renk ile
                    </p>
                  </div>
                  <div className='col-md-12'>
                    <p>
                      Vadesinin bitmesine 1 aydan fazla olanlar <b style={{ color: '#198754' }}>yeşil</b> renk ile
                      gösterilmektedir.
                    </p>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Index)