import * as yup from 'yup'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'

const t = translate(Translations)
const validations = yup.object().shape({
  // CompanyID: yup
  //   .number()
  //   .required(t('pages.admin.categoryOperation.categoryCrud.listCategory.requiredField')),
  LongName: yup
    .string()
    .min(2, t('pages.admin.categoryOperation.categoryCrud.listCategory.name', ['uzun', '3']))
    .required(t('pages.admin.categoryOperation.categoryCrud.listCategory.requiredField')),
  IsMenu: yup.boolean(),
  ShortName: yup
    .string()
    .min(2, t('pages.admin.categoryOperation.categoryCrud.listCategory.name', ['kısa', '3']))
    .required(t('pages.admin.categoryOperation.categoryCrud.listCategory.requiredField')),
  Sequence: yup.number().required(t('pages.admin.categoryOperation.categoryCrud.listCategory.requiredField')),
})

export default validations
