import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ICreateOfferToSubDealerResponse, IOfferSendMailRequest } from '../models/models'

export class OfferSendMailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'offer/send-mail',
      requesterInfo,
    })
  }

  public async offerSendMail(request: IOfferSendMailRequest): Promise<ICreateOfferToSubDealerResponse> {
    return await this.postAsync<ICreateOfferToSubDealerResponse>(request)
  }
}
