import React, { useEffect, useState } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { GetCompatibilityTableApi } from '../services/getCompatibilityTableApi'
import Swal from 'sweetalert2'
import { ICompatibilityTable } from '../models/models'
import { downloadFileFromUrl } from '../../../base/utils/dataHelper'
import IPButton from '../../../components/IPButton'
import { Link } from 'react-router-dom'

function Index(props: BaseComponentWithContextProps) {
  const [compatibilityTable, setCompatibilityTable] = useState<ICompatibilityTable[]>([])
  useEffect(() => {
    getCompatibilityTable()
  }, [])

  const getCompatibilityTable = async () => {
    props.showLoading()
    const getCompatibilityTableApi: GetCompatibilityTableApi = new GetCompatibilityTableApi({})
    getCompatibilityTableApi
      .getBannergetCompatibilityTable(17)
      .then(res => {
        setCompatibilityTable(res)
        props.hideLoading()
      })
      .catch(e => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  return (
    <div className='container addBanner'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              Fiyat Listeleri
            </span>
          </div>
          <div className='card-body'>
            {
              compatibilityTable.map((com) => (
                <div className='linkDpp' style={{ textAlign: 'center' }} >
                  <Link to={com.Documents[0].FileUrl}
                    onClick={() => downloadFileFromUrl(com.Description, com.Documents[0].FileUrl)}
                  >
                    {com.Description}
                  </Link>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Index)