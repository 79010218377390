import React from 'react'
import { Pie } from 'react-chartjs-2'
import { getRandomColor, pickColor } from '../../../base/utils/dataHelper'
import { IChannelData } from '../models/models'
import { optionCSS } from 'react-select/dist/declarations/src/components/Option'

interface IChartProps {
  report: any[]
  options: any

}

function ChartsPage1(props: IChartProps) {
  const backgroundColors = props.options.map((a, index) => pickColor(index))

  const productModelCounts = {};
  props.report.forEach(item => {
    const productModel = item.ProductModel;
    if (productModel in productModelCounts) {
      productModelCounts[productModel]++;
    } else {
      productModelCounts[productModel] = 1;
    }
  });

  // Her bir seçeneğin (option) değerine karşılık gelen tekrar sayılarını bul
  const optionData = props.options.map(option => {
    const productModel = option.value;
    return productModelCounts[productModel] || 0; // Eğer ProductModel yoksa, 0 olarak dön
  });


  const dataPie = {
    labels: props.options.map(a => a.value),
    datasets: [
      {
        data: optionData, // [40, 20, 10, 10, 20],
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
      },
    ],
  }

  return (
    <div>
      <Pie data={dataPie} options={{ responsive: true }} />
    </div>
  )
}

export default ChartsPage1
