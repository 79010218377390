import React from 'react'
import { css } from '@emotion/css'

const formatPrice = priceString => {
  if (typeof priceString !== 'string') {
    console.error(`Invalid price: ${priceString}`)
    return { integerPart: '0', fractionalPart: '00', currency: '' }
  }

  // Extract numeric part and currency symbol from the string
  const [pricePart, currency] = priceString.trim().split(' ')
  // Remove periods (thousand separators) and replace comma with dot for proper parsing
  const numericPrice = parseFloat(pricePart.replace(/\./g, '').replace(',', '.'))

  if (isNaN(numericPrice)) {
    console.error(`Invalid price: ${priceString}`)
    return { integerPart: '0', fractionalPart: '00', currency: '' }
  }

  // Convert back to string with commas as thousand separators and format to two decimals
  const formattedPrice = numericPrice.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  const [integerPart, fractionalPart] = formattedPrice.split(',')

  return { integerPart, fractionalPart, currency }
}

const PriceDisplay = ({
  price,
  integerFontSize = '',
  fractionalFontSize = '10px',
  color = '',
  useDiv = true,
  strikeThrough = false, //üstü çizili fiyat
}) => {
  const { integerPart, fractionalPart, currency } = formatPrice(price)

  const integerPartStyle = css`
    font-size: ${integerFontSize};
    color: ${color};
    text-decoration: ${strikeThrough ? 'line-through' : 'none'};
  `

  const fractionalPartStyle = css`
    font-size: ${fractionalFontSize};
    color: ${color};
  `

  const currencyStyle = css`
    font-size: ${integerFontSize};
    color: ${color};
    font-family: 'Nexa', sans-serif;
  `

  if (useDiv) {
    return (
      <div
        className={css`
          font-size: ${integerFontSize};
          color: ${color};
        `}
      >
        <span className={integerPartStyle}>{integerPart}</span>
        <span className={fractionalPartStyle}>,{fractionalPart}</span>&nbsp;
        <span className={currencyStyle}>{currency}</span>
      </div>
    )
  }

  return (
    <>
      <span className={integerPartStyle}>{integerPart}</span>
      <span className={fractionalPartStyle}>,{fractionalPart}</span>&nbsp;
      <span className={currencyStyle}>{currency}</span>
    </>
  )
}

export default PriceDisplay
