import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from '../../components/IPImage'
import { Link } from 'react-router-dom'
import { useAppSelector } from '../../redux/app/hooks'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import { Navigation, Pagination, Autoplay, Keyboard } from 'swiper'
import './css/HomePageHeader.scss'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { useCustomReducer } from '../../base/customHooks'
import { GetBrandListApi } from './services/getBrandListApi'
import { createImageUrl } from '../../base/utils/dataHelper'

interface IHomepageHeaderState {
  brands: any[]
}

function HomePageHeader() {
  const theme = useAppSelector(selectedTheme)
  useEffect(() => {
    getBrands()
  }, [])

  const getInitialState = (): IHomepageHeaderState => {
    return {
      brands: [],
    }
  }

  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<IHomepageHeaderState>(initialState)

  const getBrands = async () => {
    const getBrands = new GetBrandListApi({})
    const brand = await getBrands.getBrandList()
    setState({ brands: brand as any })
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col position-relative'>
          <div className='homePage-header mx-5'>
            {state.brands && (
              <Swiper
                slidesPerView='auto'
                spaceBetween={15}
                slidesPerGroup={3}
                slideToClickedSlide={false}
                watchSlidesProgress={true}
                noSwipingClass='brand-no-swipe'
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                loop={false}
                navigation={{
                  prevEl: `#customLeftArrowHeader`,
                  nextEl: `#customRightArrowHeader`,
                }}
                keyboard
                modules={[Navigation, Keyboard, Pagination, Autoplay]}
                breakpoints={{
                  0: {
                    slidesPerView: 4,
                  },
                  640: {
                    slidesPerView: 6,
                  },
                  768: {
                    slidesPerView: 8,
                  },
                  1200: {
                    slidesPerView: 10,
                  },
                }}
                className='mySwiper my-3'
              >
                {state.brands.map((img, index) =>
                  theme.id === 0 ? (
                    <SwiperSlide key={index} className='brand-no-swipe'>
                      <Link
                        to={`/products?brand=${img.Name}&seller=${theme.name}`}
                        className={`brandItem${img.Name.length > 10 ? ' CellWithComment' : ''}`}
                      >
                        <div className='brand-white-background'>
                          <Image
                            className='rounded-circle'
                            alt={img.Name}
                            src={img.ImageUrl ? img.ImageUrl : img.Image ? img.Image : '/images/notImage2.png'}
                          />
                        </div>
                      </Link>
                    </SwiperSlide>
                  ) : (
                    img.Companies.map(
                      item =>
                        theme.id === Number(item) && (
                          <SwiperSlide key={index} className='brand-no-swipe'>
                            <Link
                              to={`/products?brand=${img.Name}&seller=${theme.name}`}
                              className={`brandItem${img.Name.length > 10 ? ' CellWithComment' : ''}`}
                            >
                              <div className='brand-white-background'>
                                <Image
                                  className='rounded-circle'
                                  alt={img.Name}
                                  src={
                                    img.ImageUrl
                                      ? img.ImageUrl
                                      : img.Image
                                      ? createImageUrl(img.Image)
                                      : '/images/notImage2.png'
                                  }
                                />
                              </div>
                            </Link>
                          </SwiperSlide>
                        )
                    )
                  )
                )}
              </Swiper>
            )}
            <div id='customLeftArrowHeader' className='customLeftArrow'></div>
            <div id='customRightArrowHeader' className='customRightArrow'></div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HomePageHeader
