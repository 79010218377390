import React, { useEffect } from 'react'
import { useCustomReducer } from '../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../base/customHooks/componentHOC'
import IPSelectBox, { ISelectOption } from '../../components/IPSelectBox/IPSelectBox'
import Swal from 'sweetalert2'
import Modal from '../../components/IPModal/IPModal'
import IPButton from '../../components/IPButton'
import IPCheckbox from '../../components/IPCheckBox'
import {
  IAddNewBasketResponse,
  IGetBasketListResponse,
  IGetBasketManagementByBasketIdResponse,
  ISetBasketManagementByIdsRequest,
  ISetBasketManagementByIdsResponse,
} from './models/models'
import IPInput from '../../components/IPInput'
import { IException, KeyValuePair } from '../../base/models'
import { formatPrice } from '../../base/utils/dataHelper'
import IPRadio from '../../components/IPRadio'
import { AddNewBasketApi } from './services/addNewBasketApi'
import { GetUserBasketListApi } from './services/getUserBasketListApi'
import { GetBasketManagementByBasketIdApi } from './services/basketManagementGetByBasketIdApi'
import { DeleteBasketApi } from './services/deleteBasketApi'
import { IBasketDetail, ISimpleBasket, ISwitchBasketResponse } from '../../redux/slices/basket/basketApi'

enum BasketManagementTransactionType {
  Copy = 1,
  Transfer = 2,
  Delete = 3,
}

export interface BasketManagementState {
  showBasketsUpdateHistoryModal: boolean
  selectedBasketToReadyAction: ISelectOption[]
  newBasketNameBool: boolean
  newBasketName: string
  activeBasket: ISelectOption[]
  selectedBasketFromMoveOrCopyAction: ISelectOption[]
  selectedBasketToMoveOrCopyAction: ISelectOption[]
  baskets: ISelectOption[]
  newBasketInputValue: string
  selectedBasketDetails: IBasketDetail[]
  selectedItemIds: number[]
}

function BasketManagement(props: BaseComponentWithContextProps) {
  const getInitialState = (): BasketManagementState => {
    return {
      showBasketsUpdateHistoryModal: false,
      newBasketInputValue: '',
      selectedBasketToReadyAction: [],
      activeBasket: [],
      selectedBasketFromMoveOrCopyAction: [],
      selectedBasketToMoveOrCopyAction: [],
      baskets: [],
      selectedBasketDetails: [],
      selectedItemIds: [],
      newBasketNameBool: false,
      newBasketName: ' ',
    }
  }

  const intialState = getInitialState()
  const [state, dispatchState] = useCustomReducer<BasketManagementState>(intialState)

  useEffect(() => {
    getBaskets()
  }, [])

  const getBaskets = () => {
    const getBasketListApi: GetUserBasketListApi = new GetUserBasketListApi({})
    getBasketListApi
      .getBaskets()
      .then((res: IGetBasketListResponse) => {
        if (res && res.Baskets) {
          dispatchState({
            baskets: res.Baskets.map((elem: ISimpleBasket) => {
              return { value: elem.Id, label: elem.BasketName }
            }),
            selectedBasketToReadyAction: res.Baskets.filter((item: ISimpleBasket) => item.IsActive).map(
              (elem: ISimpleBasket) => {
                return { value: elem.Id, label: elem.BasketName }
              }
            ),
            activeBasket: res.Baskets.filter((item: ISimpleBasket) => item.IsActive).map((elem: ISimpleBasket) => {
              return { value: elem.Id, label: elem.BasketName }
            }),
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const switchBasket = () => {
    const basketId = Number(state.selectedBasketToReadyAction[0].value)

    props.switchBasket &&
      props
        .switchBasket({ BasketId: basketId })
        .then((res: ISwitchBasketResponse) => {
          if (res) {
            dispatchState({
              newBasketNameBool: true,
              baskets: res.Baskets.map((elem: ISimpleBasket) => {
                return { value: elem.Id, label: elem.BasketName }
              }),
            })

            const activeBasket = res.Baskets.find((item: ISimpleBasket) => item.IsActive)

            if (activeBasket) {
              Swal.fire({
                // position: 'top-end',
                icon: 'success',
                title: `Geçerli sepetiniz "${activeBasket.BasketName}" olacak şekilde değiştirildi.`,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            }
          }
        })
        .catch((err: IException) => {
          if (err.description) {
            return Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
          Swal.fire({
            icon: 'error',
            title: err.code,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
  }

  const removeBasket = () => {
    const api = new DeleteBasketApi({})
    const queryParams: KeyValuePair<number> = new KeyValuePair<number>()
    const Id = Number(state.selectedBasketToReadyAction[0].value)
    queryParams.add('id', Id)
    api.queryParams = queryParams

    api
      .deleteBasket()
      .then((res: IBasketDetail) => {
        if (res) {
          dispatchState({
            baskets: state.baskets.filter((elem: ISelectOption) => elem.value !== Id),
            selectedBasketToReadyAction: [],
          })

          Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: `"${basketNameToBeRemove}" adlı sepet silindi.`,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })

    const basketId = Number(state.selectedBasketToReadyAction[0].value)
    const basketNameToBeRemove = state.selectedBasketToReadyAction[0].label

    const deleteBasketApi: DeleteBasketApi = new DeleteBasketApi({})
    deleteBasketApi
      .deleteBasket()
      .then((res: IBasketDetail) => {
        if (res) {
          dispatchState({ baskets: state.baskets.filter((elem: ISelectOption) => elem.value !== `${basketId}`) })

          Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: `"${basketNameToBeRemove}" adlı sepet silindi.`,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const addNewBasket = () => {
    var error = false

    for (var i = 0; i < state.baskets.length; i++) {
      if (state.baskets[i].label === state.newBasketInputValue) error = true
    }

    if (error) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Aynı sepet adı kullanılmıştır!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })

      return
    }
    if (!state.newBasketInputValue || state.newBasketInputValue.length < 3) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Yeni sepet adı en az 3 karakterden oluşmalı!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })

      return
    }
    props.showLoading()
    const api: AddNewBasketApi = new AddNewBasketApi({})
    api
      .addNewBasket({ UserID: props.user.UserId, BasketName: state.newBasketInputValue })
      .then((res: IAddNewBasketResponse) => {
        props.hideLoading()
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Yeni sepet eklendi',
          showConfirmButton: true,
          allowOutsideClick: false,
        })

        const data: ISelectOption[] = []
        res.Baskets.map(baskets => data.push({ value: baskets.Id, label: baskets.BasketName }))

        dispatchState({ baskets: data, newBasketInputValue: '' })
      })
      .catch((err: IException) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const applyChangesToBasket = (action: BasketManagementTransactionType) => {
    const request: ISetBasketManagementByIdsRequest = Object.assign({})
    request.BeasketDetailIDList = state.selectedItemIds
    request.CurrentBasketID = Number(state.selectedBasketFromMoveOrCopyAction[0].value)

    if (action !== BasketManagementTransactionType.Delete) {
      request.SelectedBasketID = Number(state.selectedBasketToMoveOrCopyAction[0].value)
    }

    request.OperationType = action

    props.copyMoveOrDeleteFromBasket &&
      props
        .copyMoveOrDeleteFromBasket(request)
        .then((res: ISetBasketManagementByIdsResponse) => {
          if (res) {
            let message: string = ''

            switch (action) {
              case BasketManagementTransactionType.Copy:
                message = `"${state.selectedBasketFromMoveOrCopyAction[0].label}" sepetinden seçtiğiniz ürünler "${state.selectedBasketToMoveOrCopyAction[0].label}" sepetine kopyalanmıştır.`
                break
              case BasketManagementTransactionType.Transfer:
                message = `Seçtiğiniz ürünler "${state.selectedBasketToMoveOrCopyAction[0].label}" sepetine taşınmıştır.`
                break
              case BasketManagementTransactionType.Delete:
                message = `Seçtiğiniz ürünler "${state.selectedBasketFromMoveOrCopyAction[0].label}" sepetinden silinmiştir.`
                break
              default:
                break
            }

            Swal.fire({
              // position: 'top-end',
              icon: 'success',
              title: message,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
        })
        .catch((err: IException) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
  }

  const moveCopyAndDeleteBasketItems = () => {
    if (!state.selectedBasketToReadyAction || !state.selectedBasketToReadyAction[0]) {
      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: 'Yapacağınız işlem için sepet seçmelisiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })

      return
    }

    props.showLoading && props.showLoading()

    const api: GetBasketManagementByBasketIdApi = new GetBasketManagementByBasketIdApi({})
    const qp: KeyValuePair<string> = new KeyValuePair<string>()
    qp.add('id', `${state.selectedBasketToReadyAction[0].value}`)
    api.queryParams = qp
    api
      .getBasketDetails()
      .then((res: IGetBasketManagementByBasketIdResponse) => {
        if (res && res.ActiveBasketUI && res.ActiveBasketUI.Companies && res.ActiveBasketUI.Companies.length > 0) {
          let items: IBasketDetail[] = []

          for (const companyItem of res.ActiveBasketUI.Companies) {
            if (companyItem.Warehouses) {
              for (const warehouseItem of companyItem.Warehouses) {
                if (warehouseItem.BasketDetail && warehouseItem.BasketDetail.length > 0) {
                  items = [...items, ...warehouseItem.BasketDetail]
                }
              }
            }
          }

          dispatchState({
            selectedBasketDetails: items,
            selectedBasketFromMoveOrCopyAction: state.selectedBasketToReadyAction,
            selectedItemIds: [],
          })
        } else {
          dispatchState({
            selectedBasketDetails: [],
            selectedBasketFromMoveOrCopyAction: undefined,
            selectedItemIds: [],
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
      .finally(() => {
        props.hideLoading && props.hideLoading()
      })
  }

  const isSelectedItem = (itemId: number) => {
    return state.selectedItemIds.indexOf(itemId) > -1
  }

  const isSelectedAllItems = () => {
    return state.selectedBasketDetails.length === state.selectedItemIds.length
  }

  const onItemCheckedChange = (checked: boolean, itemId: number) => {
    if (!checked) {
      dispatchState({ selectedItemIds: state.selectedItemIds.filter((id: number) => id !== itemId) })
    } else {
      const idx = state.selectedItemIds.findIndex((id: number) => id === itemId)

      if (idx === -1) {
        dispatchState({ selectedItemIds: [...state.selectedItemIds, itemId] })
      }
    }
  }

  const selectUnselectAllItems = (checked: boolean) => {
    if (checked) {
      dispatchState({ selectedItemIds: state.selectedBasketDetails.map((elem: IBasketDetail) => elem.Id) })
    } else {
      dispatchState({ selectedItemIds: [] })
    }
  }

  return (
    <React.Fragment>
      <section>
        <div className='container container-fluid'>
          <div className='form-row'>
            <h5>
              Geçerli Sepet Adı:
              {state.newBasketNameBool
                ? state.newBasketName
                : state.activeBasket && state.activeBasket[0]
                  ? state.activeBasket[0].label
                  : ' '}
            </h5>
          </div>

          <div className='form-row mt-2'>
            <div className='form-group col-md-3 align-items-center'>
              <IPSelectBox
                name='currencyType'
                value={state.selectedBasketToReadyAction}
                options={state.baskets}
                onChangeSingle={(newVal: ISelectOption) => {
                  dispatchState({ selectedBasketToReadyAction: [newVal] })
                }}
              />
            </div>
            <div className='form-group col-md-3 align-items-center'>
              <IPButton name='setAsActiveBasket' onClick={switchBasket} text='Geçerli Sepet Yap' />
            </div>
            <div className='form-group col-md-3 align-items-center'>
              <IPButton name='removeBasket' onClick={removeBasket} text='Sepeti Sil' />
            </div>
          </div>

          <hr />

          <div className='form-row mt-3'>
            <div className='form-group col-md-3 align-items-center'>
              <IPInput
                type='text'
                className='form-control'
                name='basketName'
                value={state.newBasketInputValue}
                onChange={(e: any) => dispatchState({ newBasketInputValue: e.target.value })}
              />
            </div>
            <div className='form-group col-md-3 align-items-center'>
              <IPButton name='addNewBasket' onClick={addNewBasket} text='Adlı Sepet Oluştur' />
            </div>
          </div>

          <hr />

          <div className='form-row mt-3'>
            <div className='form-group col-md-3 align-items-center'>
              <IPButton
                name='moveCopyAndDeleteBasketItems'
                onClick={moveCopyAndDeleteBasketItems}
                text='Sepet İçeriğini Taşı/Kopyala/Sil'
              />
            </div>
          </div>

          {state.selectedBasketFromMoveOrCopyAction && state.selectedBasketFromMoveOrCopyAction[0] && (
            <div>
              <div className='form-row'>
                <h5>Seçilen Sepet Adı: {state.selectedBasketFromMoveOrCopyAction[0].label}</h5>
              </div>

              <div className='form-row mt-3'>
                <div className='form-group col-md-12 align-items-center'>
                  <table className='table table-borderless table-hover'>
                    <thead>
                      <tr className='EInvoicetableDescription'>
                        <td scope='col'>
                          <IPCheckbox
                            label='Tümünü Seç'
                            labelClassName='font-weight-bold'
                            checked={isSelectedAllItems()}
                            onCheckedChange={selectUnselectAllItems}
                          />
                        </td>
                        <td scope='col'>Stok Durumu</td>
                        <td scope='col'>Ürün Kodu</td>
                        <td scope='col'>Ürün Açıklaması</td>
                        <td scope='col'>Birim Fiyat</td>
                        <td scope='col'>Toplam Fiyat</td>
                        <td scope='col'>Adet</td>
                      </tr>
                    </thead>
                    <tbody>
                      {state.selectedBasketDetails &&
                        state.selectedBasketDetails.map((basketDetail: IBasketDetail) => {
                          return (
                            <tr key={basketDetail?.Id}>
                              <td scope='col'>
                                <IPCheckbox
                                  checked={isSelectedItem(basketDetail?.Id)}
                                  onCheckedChange={(checked: boolean) => onItemCheckedChange(checked, basketDetail?.Id)}
                                />
                              </td>
                              <td scope='col'>{basketDetail?.Product?.Stock?.Stock}</td>
                              <td scope='col'>{basketDetail?.Product?.ProductCode}</td>
                              <td scope='col'>{basketDetail?.Product?.Name}</td>
                              <td scope='col'>{basketDetail?.Product?.Prices?.Normal?.Price}</td>
                              <td scope='col'>
                                {basketDetail?.Product?.Prices?.Normal?.Price ?? 0 * basketDetail.Quantity}
                              </td>
                              <td scope='col'>{basketDetail?.Quantity}</td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='form-row mt-2'>
                <div className='form-group col-md-3 align-items-center'>
                  <label className='sr-only-size'>Ürünleri taşımak/kopyalamak istediğiniz sepet</label>
                  <IPSelectBox
                    name='targetBasket'
                    value={state.selectedBasketToMoveOrCopyAction}
                    options={state.baskets.filter((elem: ISelectOption) => {
                      return (
                        !state.selectedBasketFromMoveOrCopyAction ||
                        !state.selectedBasketFromMoveOrCopyAction[0] ||
                        elem.value !== state.selectedBasketFromMoveOrCopyAction[0].value
                      )
                    })}
                    onChangeSingle={(newVal: ISelectOption) => {
                      dispatchState({ selectedBasketToMoveOrCopyAction: [newVal] })
                    }}
                  />
                </div>
              </div>

              <div className='form-row mt-3'>
                <div className='form-group col-md-3 align-items-center'>
                  <IPButton
                    name='moveSelectedBasketItems'
                    onClick={() => applyChangesToBasket(BasketManagementTransactionType.Transfer)}
                    text='Seçtiklerimi Taşı'
                  />
                </div>
                <div className='form-group col-md-3 align-items-center'>
                  <IPButton
                    name='copySelectedBasketItems'
                    onClick={() => applyChangesToBasket(BasketManagementTransactionType.Copy)}
                    text='Seçtiklerimi Kopyala'
                  />
                </div>
                <div className='form-group col-md-3 align-items-center'>
                  <IPButton
                    name='removeSelectedBasketItemsFromBasket'
                    onClick={() => applyChangesToBasket(BasketManagementTransactionType.Delete)}
                    text='Seçtiklerimi Sil'
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Modal
        title={'Güncelleme Geçmişi'}
        width={1000}
        show={state.showBasketsUpdateHistoryModal}
        onClose={() => dispatchState({ showBasketsUpdateHistoryModal: false })}
        hideConfirm
      >
        <div className='p-3'>
          <div className='form-row mt-2'>
            <div className='form-group col-md-2 align-items-center'>
              <IPRadio name={'percentage'} text={'Özel Fiyatıma'} />
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default componentWithContext(BasketManagement)
