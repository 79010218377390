import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'

export class GetHistoryApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/propertyHistory',
      requesterInfo,
    })
  }

  public async getHistory(req: any): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
