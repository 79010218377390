import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { IDPP, ITarget } from '../models/models'

export class GetDPPTargetApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetDPPTarget',
      requesterInfo,
    })
  }

  public async getDPPTargets(): Promise<ITarget[]> {
    return await this.postAsync<ITarget[]>()
  }
}