import React, { useEffect, useState } from 'react'
import DatePicker, { registerLocale, ReactDatePickerProps } from 'react-datepicker'
//import DatePicker, { registerLocale, ReactDatePickerProps } from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'

import tr from 'date-fns/locale/tr'
import { css } from '@emotion/css'
//registerLocale("tr", tr);

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';

interface IPDatePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
> extends ReactDatePickerProps<CustomModifierNames, WithRange> { }

const IPDatePicker = (props: IPDatePickerProps) => {
  useEffect(() => { }, [])
  registerLocale('tr', tr);

  return (
    <div>
      <DatePicker
        dateFormat='dd/MM/yyyy'
        className={`${css`
          height: 47px;
        `} ${props.className ? props.className : ''}`}
        {...props}
        calendarStartDay={1}
        locale={tr}
      />
    </div>
  )
}

export default IPDatePicker
