import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { DealerChannelModel, ProductCode } from '../models/DealerChannelModel'

export class DealerChannelService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/campaignBundle/InsertProgram',
      requesterInfo,
    })
  }

  public async InsertProgram(request: DealerChannelModel): Promise<DealerChannelModel> {
    return await this.postAsync<any>(request)
  }

  public async getAll(): Promise<DealerChannelModel> {
    this.props.url = 'admin/campaignBundle/getAll'
    return await this.getAsync<any>()
  }

  public async GetById(id): Promise<DealerChannelModel> {
    this.props.url = `admin/campaignBundle/GetById/${id}`
    return await this.getAsync<any>()
  }

  public async DeleteById(id): Promise<DealerChannelModel> {
    this.props.url = `admin/campaignBundle/DeleteProgram/${id}`
    return await this.deleteAsync<any>()
  }

  public async UpdateProgram(request: any): Promise<any> {
    this.props.url = 'admin/campaignBundle/UpdateProgram'
    return await this.putAsync<any>(undefined, request)
  }

  public async getProductCode(): Promise<ProductCode> {
    this.props.url = 'admin/campaignBundle/getProductCode'
    return await this.getAsync<ProductCode>()
  }

  public async getBrandList(): Promise<DealerChannelModel> {
    this.props.url = 'admin/campaignBundle/getBrandList'
    return await this.getAsync<any>()
  }
}
