import { css } from '@emotion/css'
import { useMemo } from 'react'
import { usePagination, useSortBy, useTable } from 'react-table'

export default function Table({ columns, data, currentPage }) {
  const defaultColumn = useMemo(
    () => ({
      //width: 130,
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // @ts-ignore
      initialState: { pageIndex: currentPage ? currentPage : 0 },
    },
    useSortBy,
    usePagination
    //useBlockLayout
  )

  const tableStyles = css`
    tbody {
      border-top: none !important;
    }
    td {
      padding: 0.3rem 9px !important;
      white-space: nowrap !important;
    }
  `

  return (
    <>
      <div
        className={`table-responsive ${css`
          transform: rotateX(180deg);

          ::-webkit-scrollbar {
            height: 6px;
          }
        `}`}
      >
        <table
          className={`table ${css`
            transform: rotateX(180deg);
          `} ${tableStyles}`}
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // @ts-ignore
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  {...row.getRowProps()}
                  style={{
                    backgroundColor: row.values.col14 === 'Evet' ? 'lightgray' : '',
                  }}
                >
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div>
        <button
          className='btn btn-sm btn-secondary'
          onClick={() => {
            gotoPage(0)
            sessionStorage.setItem('Page', '0')
          }}
          disabled={!canPreviousPage}
        >
          {'<<'}
        </button>{' '}
        <button
          className='btn btn-sm btn-secondary'
          onClick={() => {
            previousPage()
            sessionStorage.setItem('Page', String(pageIndex - 1))
          }}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <button
          className='btn btn-sm btn-secondary'
          onClick={() => {
            nextPage()
            sessionStorage.setItem('Page', String(pageIndex + 1))
          }}
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
        <button
          className='btn btn-sm btn-secondary'
          onClick={() => {
            gotoPage(pageCount - 1)
            sessionStorage.setItem('Page', String(pageCount - 1))
          }}
          disabled={!canNextPage}
        >
          {'>>'}
        </button>{' '}
        <span>
          Sayfa{' '}
          <strong>
            {pageIndex + 1} / {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Sayfaya git:{' '}
          <input
            type='number'
            defaultValue={pageIndex + 1}
            onChange={e => {
              if (Number(e.target.value) < 0) return
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
              sessionStorage.setItem('Page', String(page))
            }}
            style={{ width: 80 }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
            sessionStorage.setItem('PageSize', String(e.target.value))
          }}
          style={{ width: 120 }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize} Satır Göster
            </option>
          ))}
        </select>
      </div>
    </>
  )
}
