import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IImage, IUpdateImageRequest } from '../models/requestModels'

export class UpdateImageApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/image-update',
      requesterInfo,
    })
  }

  public async updateImage(request: IUpdateImageRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
