import React, { useRef, useState } from 'react'
import Swal from 'sweetalert2'
import classes from './IPFile.module.scss'

interface IPFile extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string
  label?: string
  handleFiles?: (files: any[]) => void
  hideSelectedFilesList?: boolean
  removeAfterSelect?: boolean
  excelIcon?: boolean
}

export default function File(props: IPFile) {
  const { id, label, onInput, multiple, className, removeAfterSelect, excelIcon, ...others } = props

  const fileRef = useRef<HTMLInputElement>(null)

  const [files, setFiles] = useState<any[]>([])

  const handleFiles = e => {
    const targetFiles = e.target.files
    if (multiple) {
      setFiles(prevState => {
        for (let i = 0; i < prevState.length; i++) {
          const state = prevState[i]
          for (let j = 0; j < targetFiles.length; j++) {
            const file = targetFiles[j]
            if (file.name === state.name && file.size === state.size) {
              Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Seçtiğiniz dosya zaten mevcut!',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              return prevState
            }
          }
        }
        const updatedFiles = [...prevState, ...targetFiles]
        props.handleFiles && props.handleFiles(updatedFiles)
        return updatedFiles
      })
    }
    props.handleFiles && props.handleFiles(targetFiles)
    if (fileRef.current && removeAfterSelect) fileRef.current.value = ''
  }

  const handleRemove = e => {
    const item = e.target.previousElementSibling.textContent
    setFiles(prevState => {
      const updatedFiles = prevState.filter(state => state.name !== item)
      props.handleFiles && props.handleFiles(updatedFiles)
      return updatedFiles
    })
  }

  return (
    <>
      {!props.hideSelectedFilesList && files.length > 0 && (
        <div className={classes.files}>
          {files.map(f => (
            <div key={Math.random()}>
              <span>{f.name}</span> <span onClick={handleRemove}>X</span>
            </div>
          ))}
        </div>
      )}
      <div className={classes.file}>
        <input
          type='file'
          ref={fileRef}
          id={id}
          onInput={handleFiles}
          multiple={multiple ? multiple : true}
          {...others}
        />
        <label className={className ? className : ''} htmlFor={id}>
          {excelIcon ? <i className='fas fa-file-excel' /> : null}
          {label ? label : ''}
        </label>
      </div>
    </>
  )
}
