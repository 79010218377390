import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ICargoResponse } from '../models/models'

export class GetCargoListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'address/get-cargo-list',
      requesterInfo,
    })
  }

  public async getCargoList(request: any): Promise<ICargoResponse> {
    return await this.postAsync<ICargoResponse>(request)
  }
}
