import { useCustomReducer } from '../../../../../base/customHooks'
import IPSelectBox from '../../../../../components/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPModal from '../../../../../components/IPModal'
import { uuidv4 } from '../../../../../base/utils'

interface ISelectOption {
  value: string | number
  label: string
  isDisabled?: boolean
  statusCode?: number
}

interface IUpdateState {
  status?: ISelectOption
  statusCode?: ISelectOption[] | ISelectOption
  selectedStatusCodeOption: ISelectOption[]
  hideStatusCode: boolean
}

function DealerOnlineApplication(props) {
  const t = translate(Translations)

  const getInitialState = (): IUpdateState => {
    return {
      selectedStatusCodeOption: [{ label: '', value: '' }],
      hideStatusCode: false,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IUpdateState>(intialState)

  const statusOption: ISelectOption[] = [
    { label: 'Başvurunuzun onaylanması için eksik evraklarınızın tamamlanması gerekmektedir.', value: 2 },
    {
      label: 'Başvurunuz onaylandı. E-posta adresinize gönderilen bayilik sözleşmenizi onaylamanız beklenmektedir.',
      value: 4,
    },
    { label: 'On-line bayilik sözleşmesi onayınız tarafımıza ulaşmıştır.', value: 5 },
    {
      label:
        'Aramıza hoş geldiniz. Bayilik süreciniz tamamlanmıştır. Kullanıcı bilgileriniz mail yoluyla tarafınıza iletilmiştir.',
      value: 6,
    },
    { label: 'Başvurunuz onaylanmadı.', value: 7 },
  ]

  const statusCode1Option: ISelectOption[] = [
    {
      label: 'Güncel Vergi Levhası Fotokopiniz eksiktir, Lütfen sisteme yükleyiniz.',
      value: 1000,
    },
    {
      label: 'Ticari Sicil Gazetesi Fotokopisi(Ortaklık ve iş alanını gösteren) eksiktir. Lütfen sisteme yükleyiniz.',
      value: 1001,
    },
    {
      label: 'İmza Sirküleri Fotokopisi eksiktir. Lütfen sisteme yükleyiniz.',
      value: 1002,
    },
    {
      label: 'Nüfus Cüzdan Fotokopiniz eksiktir. Lütfen sisteme yükleyiniz.',
      value: 1003,
    },
    {
      label: 'Faaliyet Belgesi ya da Yoklama Fişi fotokopiniz eksiktir. Lütfen sisteme yükleyiniz.',
      value: 1004,
    },
  ]

  const statusCode2Option: ISelectOption[] = [
    {
      label: 'Vergi levhasında ve ticari sicil gazetesindeki faaliyet alanınız, faaliyet alanlarımıza uygun değil.',
      value: 2000,
    },
    {
      label: 'İmza sirküleri ile bayilik sözleşmesindeki ıslak imza uyuşmuyor.',
      value: 2001,
    },
    {
      label: 'Yetkili kişi ile Kimlik fotokopisi uyuşmuyor.',
      value: 2002,
    },
    {
      label: 'Daha önce cari kartınız açılmıştır.',
      value: 2003,
    },
  ]

  const onStatusChange = (value: ISelectOption) => {
    setState({ status: value })

    if (value.value !== 2 && value.value !== 7) {
      setState({ hideStatusCode: true })
    } else setState({ hideStatusCode: false })

    if (value.value === 2) {
      setState({ selectedStatusCodeOption: statusCode1Option })
    }
    if (value.value === 4) {
      setState({ statusCode: { label: '', value: 4000 } })
    }
    if (value.value === 5) {
      setState({ statusCode: { label: '', value: 3000 } })
    }
    if (value.value === 6) {
      setState({ statusCode: { label: '', value: 5000 } })
    }
    if (value.value === 7) {
      setState({ selectedStatusCodeOption: statusCode2Option })
    }
  }

  const onStatusCode1Change = (value: ISelectOption) => {
    setState({ statusCode: value as any })
  }

  let statusInfo: any = []
  let statusCode: number | null = null
  if (props.selectedApplication && props.selectedApplication.StatusInformation) {
    statusCode = props.selectedApplication.Status
    statusInfo = JSON.parse(props.selectedApplication.StatusInformation)
  }

  return (
    <IPModal
      width={1200}
      show={props.showModal}
      onConfirm={() => props.putApplicationRequest(state)}
      onClose={() => props.closeModal()}
      closeOnEscapeKey={true}
    >
      <div className='card' style={{ minHeight: '90vh' }}>
        <div className='card-body'>
          <form id='form1' className='form-validate' noValidate>
            <div className='form-row'>
              <div className='form-group col-md-12'>
                <h4 style={{ textAlign: 'center' }}>
                  {t('pages.admin.dealerInformationFormOperation.dealerInformation.dealerOnlineApplication.status')}
                </h4>
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <IPSelectBox
                  id='DealerContents'
                  name='DealerContents'
                  options={statusOption}
                  onChangeSingle={onStatusChange}
                  value={state.status}
                />
                {/* <p className='validText'>
                  {props.errors.status?.label &&
                    props.touched.status?.label &&
                    props.errors.status.label}
                </p> */}
              </div>
              {state.hideStatusCode
                ? state.hideStatusCode
                : true && (
                  <div className='form-group col-md-6'>
                    <IPSelectBox
                      id='DealerContents'
                      name='DealerContents'
                      options={state.selectedStatusCodeOption}
                      onChangeSingle={onStatusCode1Change}
                      value={state.statusCode}
                    />
                  </div>
                )}
            </div>
            {statusInfo && (
              <div className='row mb-4'>
                {statusCode && statusCode !== 0 && (
                  <div className='col-12 mb-2'>Başvuru Durumu: {props.statusOptionText(statusCode)}</div>
                )}
                {statusInfo.length > 0 && (
                  <div className='col-12'>
                    {statusInfo.map(msg => {
                      return (
                        <div key={uuidv4()} className='statusInfoMessage'>
                          <div className='d-flex gap-4'>
                            <div>{new Date(msg.Date).toLocaleDateString('tr-TR')}</div>
                            <div>
                              {msg.Status === null ? (
                                <div style={{ display: 'block', marginBottom: '6px' }}>{msg.Message}</div>
                              ) : (
                                <div style={{ display: 'block', marginBottom: '6px' }}>{msg.Status}</div>
                              )}

                              {msg.Status !== null && (
                                <div style={{ display: 'block', marginBottom: '6px' }}>{msg.Message}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </IPModal>
  )
}

export default DealerOnlineApplication
