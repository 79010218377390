import i18next from 'i18next'
import { ConfigHelper } from '../constants'
import { ClientStorage } from '../storage'

export const translate = (resources: any, language?: string) => {
  if (!language) {
    language = ClientStorage.getItem(ConfigHelper.INDEXPAZAR_APP_LANG)
  }

  const instance = i18next.createInstance(
    {
      interpolation: {
        escapeValue: false,
      },
      lng: language ?? 'tr',
      resources,
    },
    (err, t) => {}
  )

  const translateFunc = (key: string, params?: string[]): string => {
    let translation = instance.t(key)

    if (params && params.length > 0) {
      params.forEach((param, index) => {
        translation = translation.replace('${' + index + '}', param)
      })
    }

    return translation
  }

  return translateFunc
}
