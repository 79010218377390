import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'

export class B2BGeneralReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dailyGeneralReport',
      requesterInfo,
    })
  }

  public async sendB2BGeneralReport(req: { StartDate: Date; EndDate: Date }): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
