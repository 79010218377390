import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import {
  GetPeriodicTurnoverSubQueryResponse,
  PeriodicReportRequest,
  PeriodicTurnoverSendMailRequest,
  SapPeriodicTurnOverResponseModel,
} from './models'

export class PeriodicReportService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetPeriodicTurnover',
      requesterInfo,
    })
  }

  public async GetPeriodicTurnOver(request: PeriodicReportRequest): Promise<SapPeriodicTurnOverResponseModel[]> {
    return await this.postAsync<SapPeriodicTurnOverResponseModel[]>(request)
  }

  public async GetPeriodicTurnoverSubProduct(
    request: PeriodicReportRequest
  ): Promise<GetPeriodicTurnoverSubQueryResponse[]> {
    this.props.url = 'MyReports/GetPeriodicTurnoverSubProduct'
    return await this.postAsync<GetPeriodicTurnoverSubQueryResponse[]>(request)
  }

  public async GetPeriodicTurnoverSubBrand(
    request: PeriodicReportRequest
  ): Promise<GetPeriodicTurnoverSubQueryResponse[]> {
    this.props.url = 'MyReports/GetPeriodicTurnoverSubBrand'
    return await this.postAsync<GetPeriodicTurnoverSubQueryResponse[]>(request)
  }

  public async GetPeriodicTurnoverSubProductOne(
    request: PeriodicReportRequest
  ): Promise<GetPeriodicTurnoverSubQueryResponse[]> {
    this.props.url = 'MyReports/GetPeriodicTurnoverSubProductOne'
    return await this.postAsync<GetPeriodicTurnoverSubQueryResponse[]>(request)
  }

  public async GetPeriodicTurnoverSubProductSecond(
    request: PeriodicReportRequest
  ): Promise<GetPeriodicTurnoverSubQueryResponse[]> {
    this.props.url = 'MyReports/GetPeriodicTurnoverSubProductSecond'
    return await this.postAsync<GetPeriodicTurnoverSubQueryResponse[]>(request)
  }

  public async GetPeriodicTurnoverSubProductThird(
    request: PeriodicReportRequest
  ): Promise<GetPeriodicTurnoverSubQueryResponse[]> {
    this.props.url = 'MyReports/GetPeriodicTurnoverSubProductThird'
    return await this.postAsync<GetPeriodicTurnoverSubQueryResponse[]>(request)
  }

  public async GetPeriodicTurnoverSubBrandOne(
    request: PeriodicReportRequest
  ): Promise<GetPeriodicTurnoverSubQueryResponse[]> {
    this.props.url = 'MyReports/GetPeriodicTurnoverSubBrandOne'
    return await this.postAsync<GetPeriodicTurnoverSubQueryResponse[]>(request)
  }

  public async PeriodicTurnoverSendMail(request: PeriodicTurnoverSendMailRequest): Promise<any> {
    this.props.url = 'MyReports/periodicTurnoverSendMail'
    return await this.postAsync<any>(request)
  }

  public async LicenseTrackingSendMail(request: PeriodicTurnoverSendMailRequest): Promise<any> {
    this.props.url = 'MyReports/licenseTrackingSendMail'
    return await this.postAsync<any>(request)
  }
}
