import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IGetOrdersProcessGroup } from '../models/response/getDealerChannelListResponseModel'

export class GetOrdersProcessGroupApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'OrdersHistory/GetOrdersProcessGroup',
      requesterInfo,
    })
  }

  public async getOrdersProcessGroup(): Promise<IGetOrdersProcessGroup[]> {
    return await this.getAsync<IGetOrdersProcessGroup[]>()
  }
}
