import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { ICreditState, ICreditStateRequest } from '../models/models'

export class GetCreditStateApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetCreditState',
      requesterInfo,
    })
  }

  public async getCreditState(request: ICreditStateRequest): Promise<ICreditState[]> {
    return await this.postAsync<ICreditState[]>(request)
  }
}
