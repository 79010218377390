import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'

export class SendMakbuzInfoApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/sendMakbuzInfoMail',
      requesterInfo,
    })
  }

  public async sendMakbuzMail(req: { StartDate: Date; EndDate: Date, Company: Number }): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
