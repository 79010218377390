import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IFcvTable } from '../../../ProductOperation/CampaignBundleCrud/services/InsertFcvService'

export interface DealerControlReq {
  ClusterType: number
  CompanyCode: string
  Data: string[]
}

export class DealerControl extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/dealerControl',
      requesterInfo,
    })
  }

  public async dealerControl(request: DealerControlReq): Promise<DealerControlReq> {
    return await this.postAsync<DealerControlReq>(request)
  }
}
