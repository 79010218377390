import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { CreditCardModel } from '../../MyCreditCards/models/models'

export class GetMyCreditCardsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/myCreditCards',
      requesterInfo,
    })
  }

  public async getMyCreditCards(): Promise<CreditCardModel[]> {
    return await this.getAsync<CreditCardModel[]>()
  }
}
