import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { INetChannelContentData } from '../models/model'

export class GetNetChannelContentListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/netChannelContent/GetNetChannelContentGroup',
      requesterInfo,
    })
  }

  public async getNetChannelContentList(req: number): Promise<INetChannelContentData[]> {
    this.props.url = `${this.props.url}/${req}`
    return await this.getAsync<INetChannelContentData[]>()
  }
}
