import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { DeleteFrequentlyAskedQuestions } from '../models/models'

export class DeleteFrequentlyAskedQuestionsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/ssh/questionAndAnswerDelete',
      requesterInfo,
    })
  }

  public async deleteFrequentlyAskedQuestions(id: number): Promise<DeleteFrequentlyAskedQuestions> {
    this.props.url = `${this.props.url}/${id}`
    return await this.postAsync<DeleteFrequentlyAskedQuestions>()
  }
}
