import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import {
  CreditCardPrintPdfDetailRequest,
  CreditCardTransactionsRequest,
  CreditCardTransactionsResponse,
  SendCreditCardCancellationEmailRequest,
} from './creditCardOperationsModel'

export class creditCardOperationsService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealerInformation/creditCardTransactions',
      requesterInfo,
    })
  }

  public async creditCardTransactions(req: CreditCardTransactionsRequest): Promise<CreditCardTransactionsResponse[]> {
    return await this.postAsync<CreditCardTransactionsResponse[]>(req)
  }

  public async sendCreditCardCancellationEmail(req: SendCreditCardCancellationEmailRequest): Promise<boolean> {
    this.props.url = 'dealerInformation/sendCreditCardCancellationEmail'
    return await this.postAsync<boolean>(req)
  }

  public async creditCardPrintPdfDetail(req: CreditCardPrintPdfDetailRequest): Promise<string> {
    this.props.url = 'dealerInformation/creditCardPrintPdfDetail'
    return await this.postAsync<string>(req)
  }
}
