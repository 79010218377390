import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { EInvoicesRequest, EInvoicesResponse } from '../models/eInvioices'

export class GetElectronicInvoicesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetElectronicInvoices',
      requesterInfo,
    })
  }

  public async getElectronicInvoices(request: EInvoicesRequest): Promise<EInvoicesResponse[]> {
    return await this.postAsync<EInvoicesResponse[]>(request)
  }
}
