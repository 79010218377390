import { ICategory } from '../models/requestModels'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class GetCategoryListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/category/list',
      requesterInfo,
    })
  }

  public async getCategorys(): Promise<ICategory[]> {
    return await this.getAsync<ICategory[]>()
  }
}
