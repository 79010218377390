import { useMemo, useState } from 'react'
import { translate } from '../../../../base/localization'
import Translations from '../../../../translations'
import { PollProductListService } from './services'
import Swal from 'sweetalert2'
import InputCampaign from '../../../../components/InputCampaign'
import { formatDateForCampaignInput } from '../../../../base/utils/dataHelper'
import IPInput from '../../../../components/IPInput'
import IPButton from '../../../../components/IPButton'
import { PollProductResponse } from './models'
import Table from '../../../../components/Table'
import { getDateByTimezoneOffset } from '../../../../base/utils/dataHelper'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'

function Index(props: BaseComponentWithContextProps) {
  const initialStartDate = new Date()
  initialStartDate.setDate(1)
  initialStartDate.setHours(0)
  initialStartDate.setMinutes(0)
  initialStartDate.setSeconds(0)

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const [startDate, setStartDate] = useState(initialStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  const [data, setData] = useState<PollProductResponse[]>([])
  const filterState = {
    StartDate: initialStartDate,
    EndDate: new Date(),
    DealerCode: '',
    ProductCode: '',
  }
  const [filters, setFilters] = useState(filterState)
  function updateFilters(prop, val) {
    setFilters(prev => ({ ...prev, [prop]: val }))
  }

  const dateValid = () => {
    const result = filters.EndDate.getTime() - filters.StartDate.getTime()
    if (result < 60000) {
      Swal.fire('Bitiş tarihi başlangıç tarihinden önce olamaz.')
      updateFilters('EndDate', new Date())
    }
  }

  const t = translate(Translations)

  const handleSubmit = async () => {
    props.showLoading()
    const filterStates = {
      StartDate: getDateByTimezoneOffset(filters.StartDate),
      EndDate: getDateByTimezoneOffset(filters.EndDate),
      DealerCode: filters.DealerCode.length > 5 ? filters.DealerCode.padStart(10, '0') : filters.DealerCode,
      ProductCode: filters.ProductCode,
    }
    try {
      const res = await new PollProductListService({}).Filter(filterStates)
      if (res) {
        setData(res)
        props.hideLoading()
      }
    } catch (err: any) {
      props.hideLoading()
      console.error(err)
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const tableColumns = useMemo(() => {
    return [
      {
        Header: 'Sıra',
        accessor: 'Order',
      },
      {
        Header: 'Bayi Kodu',
        accessor: 'SapCode',
      },
      {
        Header: 'Bayi Adı',
        accessor: 'DealerName',
      },
      {
        Header: 'Kullanıcı',
        accessor: 'User',
      },
      {
        Header: 'Firma Tipi',
        accessor: 'CompanyType',
      },
      {
        Header: 'MT',
        accessor: 'MT',
      },
      // {
      //   Header: 'Kanal Sorumlusu',
      //   accessor: 'ChannelSorumlu',
      // },
      {
        Header: 'Ürün Kodu',
        accessor: 'ProductCode',
      },
      {
        Header: 'Kota Kodu',
        accessor: 'QuotaNo',
      },
      {
        Header: 'Ürün Müdürü',
        accessor: 'MudName',
      },
      {
        Header: 'Ürün Açıklaması ve Özellikleri Yeterli',
        accessor: 'IsProductDesc',
      },
      {
        Header: 'Ürün Fiyatı Piyasadan Daha Fazla',
        accessor: 'IsProductPrice',
      },
      {
        Header: 'Kampanyalar Ürüne Özel Yapılabilir',
        accessor: 'IsCampaign',
      },
      {
        Header: 'Ürün Açıklaması ve Özellikleri Yanlış',
        accessor: 'IsDescError',
      },
      {
        Header: 'Index Pazar ile ilgili düşünceniz varsa, bizimle paylaşabilirsiniz',
        accessor: 'GeneralThought',
      },
      {
        Header: 'İşlem Tarihi',
        accessor: 'CreatedDate',
      },
    ]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const tableData = useMemo(
    () =>
      data.length > 0
        ? data.map((p, index) => ({
            Order: index + 1,
            SapCode: p.SapCode && p.SapCode.replace('0000', ''),
            DealerName: p.DealerName ?? '-',
            User: p.UserCode,
            CompanyType: p.CompanyType ?? '-',
            MT: p.MT ?? '-',
            ProductCode: p.ProductCode && p.ProductCode,
            QuotaNo: p.QuotaNo && p.QuotaNo,
            MudName: p.MudName,
            IsProductDesc: p.IsProductDesc ? 'Evet' : 'Hayır',
            IsProductPrice: p.IsProductPrice ? (p.ProductPrice.length > 0 ? p.ProductPrice : 'Evet') : 'Hayır',
            IsCampaign: (
              <>
                {!p.IsProductPrice && (
                  <>
                    <div>Hedef Fiyat:</div>
                    <div>Miktar:</div>
                  </>
                )}
                {p.IsProductPrice && (
                  <>
                    <div>Hedef Fiyat: {p.TargetPrice}</div>
                    <div>Miktar: {p.Quantity}</div>
                  </>
                )}
              </>
            ),
            IsDescError: p.IsDescError ? (p.DescError && p.DescError.length > 0 ? p.DescError : 'Evet') : 'Hayır',
            CreatedDate: new Date(p.CreatedDate).toLocaleDateString('tr-TR'),
            GeneralThought: p.GeneralThought,
          }))
        : [],
    [data]
  )

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            {/* <span className='h4'>{t('pages.admin.siteReports.pollList.header')}</span> */}
            <span className='h4'>Ürün Anket Listesi</span>
          </div>

          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-4 mb-3'>
                <div>
                  <label htmlFor='startDate' className='labeltext'>
                    {t('pages.admin.siteReports.mailSend.processDateRange')}
                  </label>
                </div>
                <div className='row'>
                  <div className='col-12 col-md-6 mb-3'>
                    <InputCampaign
                      type='date'
                      id='StartDate'
                      name='StartDate'
                      label={'Başlangıç Tarihi'}
                      value={toDateTimeLocal(startDate)}
                      onChange={e => getDate(e.target.value, "Start")}
                      isActive
                    />
                  </div>
                  <div className='col-12 col-md-6 mb-3'>
                    <InputCampaign
                      type='date'
                      id='EndDate'
                      name='EndDate'
                      label={'Bitiş Tarihi'}
                      value={toDateTimeLocal(endDate)}
                      onChange={e => getDate(e.target.value, "End")}
                      onBlur={dateValid}
                      isActive
                    />
                  </div>
                </div>
              </div>
              <div className='col-lg-3 mb-3'>
                <div>
                  <label htmlFor='name' className='labeltext'>
                    {t('pages.admin.siteReports.pollProductList.sellerCode')}{' '}
                  </label>
                </div>
                <div>
                  <IPInput
                    type='text'
                    className='form-control'
                    placeholder={t('pages.admin.siteReports.pollProductList.sellerCode')}
                    onChange={e => updateFilters('DealerCode', e.target.value)}
                    value={filters.DealerCode}
                  />
                </div>
              </div>
              <div className='col-lg-3 mb-3'>
                <div>
                  <label htmlFor='name' className='labeltext'>
                    {t('pages.admin.siteReports.productClick.productCode')}{' '}
                  </label>
                </div>
                <div>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='Name'
                    placeholder={t('pages.admin.siteReports.productClick.productCode')}
                    onChange={e => updateFilters('ProductCode', e.target.value)}
                    value={filters.ProductCode}
                  />
                </div>
              </div>
              <div className='col-lg-2 mb-3'>
                <IPButton
                  type='submit'
                  style={{ marginTop: '34px', minWidth: 0 }}
                  className='btn btn-success float-right'
                  text={t('pages.admin.siteReports.mailSend.showButton')}
                  onClick={handleSubmit}
                />
              </div>
            </div>
            <div className='row'>
              <div className='col-12'>
                <label htmlFor='startDate' className='labeltext'>
                  {t('pages.admin.siteReports.pollList.pollResult')}
                </label>
              </div>
              <div className='col-12'>
                <Table columns={tableColumns} data={tableData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Index)