import React, { useEffect, useState, useMemo } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPInput from '../../../../../components/IPInput'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { PostUserListApi } from '../services/postUserListApi'
import { IListUser, IUserHistory, IUserList } from '../models/response/getUserListResponseModel'
import { useCustomReducer } from '../../../../../base/customHooks'
import Swal from 'sweetalert2'
import { IDealer } from '../../../components/models/models'
import { getDealerApi } from '../../../components/api'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import Table from '../../../../../components/Table'
import { DeleteUserApi } from '../services/deleteUserApi'
import { HistoryUserApi } from '../services/historyUserApi'
import IPModal from '../../../../../components/IPModal'
import moment from 'moment'

interface IListUserState {
  postUser: IListUser
  //dealerLists: IDealer[]
}
function ListUser(props: BaseComponentWithContextProps) {

  const [searchParams, setSearchParams] = useSearchParams()

  const paramDealer = searchParams.get('account')
  const paramUser = searchParams.get('name')
  const paramName = searchParams.get('user')
  const paramEmail = searchParams.get('email')
  const paramStatus = searchParams.get('status')

  const getInitialState = (): IListUserState => {
    return {
      postUser: {
        AccountCode: paramDealer!,
        AccountName: paramUser!,
        UserCode: paramName!,
        Email: paramEmail!,
        Status: Number(paramStatus),
      },
      //dealerLists: []
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListUserState>(intialState)

  useEffect(() => {
    if (
      (state.postUser.AccountCode !== "" && state.postUser.AccountCode !== null) ||
      (state.postUser.AccountName !== "" && state.postUser.AccountName !== null) ||
      (state.postUser.UserCode !== "" && state.postUser.UserCode !== null) ||
      (state.postUser.Email !== "" && state.postUser.Email !== null) ||
      (state.postUser.Status !== 0 && state.postUser.Status !== null)
    ) {

      const queryParams = {
        account: (state.postUser.AccountCode !== "" && state.postUser.AccountCode !== null) ? String(state.postUser.AccountCode) : undefined,
        name: (state.postUser.AccountName !== "" && state.postUser.AccountName !== null) ? String(state.postUser.AccountName) : undefined,
        user: (state.postUser.UserCode !== "" && state.postUser.UserCode !== null) ? String(state.postUser.UserCode) : undefined,
        email: (state.postUser.Email !== "" && state.postUser.Email !== null) ? String(state.postUser.Email) : undefined,
        status: (state.postUser.Status !== null && state.postUser.Status !== 0) ? String(state.postUser.Status) : undefined
      };

      const filteredQueryParams = Object.entries(queryParams)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      const navigateRoute = `/admin/UserOperation/ListUser?${filteredQueryParams}`;
      navigate(navigateRoute)


      filterUser();
    }

  }, [])

  const [accountCode, setAccountCode] = useState<any>(undefined)
  const [status, setStatus] = useState<any>(undefined)
  const [userLists, setUserLists] = useState<IUserList[]>([])
  const [history, setHistory] = useState<IUserHistory[]>([])
  const [show, setShowHistory] = useState<boolean>(false)
  const navigate: any = useNavigate()
  const routeLocation = useLocation()
  const handleUserAdd = async () => {
    navigate(`/admin/UserOperation/AddUser`, { state: { type: 'User' } })
  }



  const filterUser = async () => {
    const queryParams = {
      account: (state.postUser.AccountCode !== "" && state.postUser.AccountCode !== null) ? String(state.postUser.AccountCode) : undefined,
      name: (state.postUser.AccountName !== "" && state.postUser.AccountName !== null) ? String(state.postUser.AccountName) : undefined,
      user: (state.postUser.UserCode !== "" && state.postUser.UserCode !== null) ? String(state.postUser.UserCode) : undefined,
      email: (state.postUser.Email !== "" && state.postUser.Email !== null) ? String(state.postUser.Email) : undefined,
      status: (state.postUser.Status !== null && state.postUser.Status !== 0) ? String(state.postUser.Status) : undefined
    };

    const filteredQueryParams = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    const navigateRoute = `/admin/UserOperation/ListUser?${filteredQueryParams}`
    navigate(navigateRoute);
    props.showLoading()
    const postUserListApi: PostUserListApi = new PostUserListApi({})
    await postUserListApi
      .postUsers(state.postUser)
      .then(result => {
        if (result) {
          setUserLists(result)
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    return
  }

  const statusOptions: ISelectOption[] = [
    { value: '1', label: 'Aktif' },
    { value: '2', label: 'Pasif' },
    { value: '3', label: 'Silindi' },
    { value: '4', label: 'Onay Bekliyor' },
    { value: '5', label: 'Bloke' },
  ]

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Ad',
        accessor: 'col3',
      },
      {
        Header: 'Soyad',
        accessor: 'col11',
      },
      {
        Header: 'Kullanıcı Adı',
        accessor: 'col4',
      },
      {
        Header: 'Oluşturulma Tarihi',
        accessor: 'col5',
      },
      {
        Header: 'E-Posta',
        accessor: 'col6',
      },
      {
        Header: 'Durum',
        accessor: 'col7',
      },
      {
        Header: 'Admin Kullanıcısı',
        accessor: 'col8',
      },
      {
        Header: 'Süper Admin Kullanıcısı',
        accessor: 'col9',
      },
      {
        Header: 'İşlemler',
        accessor: 'col10',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      userLists.map((dt, index) => {
        const data = {
          col1: index + 1,
          col2: dt.DealerCode.replace('0000', ''),
          col3: dt.Name,
          col4: dt.UserName,
          col5: dt.CreatedDate && new Date(dt.CreatedDate).toLocaleDateString(),
          col6: dt.Email,
          col7: statusOptions.find(option => option.value === dt.Status.toString())?.label || "",
          col8: dt.IsAdmin ? "Evet" : "Hayır",
          col9: dt.IsSu ? "Evet" : "Hayır",
          col10: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title="Güncelle"
                onClick={() => {
                  navigate(`/admin/UserOperation/AddUser`, { state: { userProp: dt.Id, type: 'UserUpdate', url: window.location.href } })
                }}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
              {dt.Status !== 3 && (
                <a
                  data-action-type='delete'
                  role='button'
                  style={{ cursor: 'pointer' }}
                  data-toggle='tooltip'
                  data-original-title="Sil"
                  onClick={() => deleteUser(dt)}
                >
                  <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
                </a>
              )}
              <a
                data-action-type='history'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={'Tarihçe'}
                onClick={() => showHistory(dt.Id)}
              >
                <i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i>
              </a>
            </div>
          ),
          col11: dt.Surname
        }

        return data
      }),
    [userLists]
  )

  const showHistory = async (id: number) => {
    props.showLoading();
    const userHistory: HistoryUserApi = new HistoryUserApi({})
    try {
      await userHistory
        .historyByID(id)
        .then(res => {
          setShowHistory(true)
          setHistory(res)
        })
      props.hideLoading();
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  const deleteUser = async (data: any) => {

    Swal.fire({
      icon: 'success',
      title: data.Name + ' kullanıcısını silmek istediğinize emin misiniz?',
      confirmButtonText: 'Evet',
      confirmButtonColor: '#8CD4F5',
      cancelButtonText: 'Hayır',
      showCancelButton: true,
      cancelButtonColor: '#ec4c4c',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const api: DeleteUserApi = new DeleteUserApi({})
        await api.deleteUser(data.Id).then(res => {
          if (res) {
            Swal.fire({
              icon: 'info',
              title: 'Kullanıcı silindi',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            filterUser()
            props.hideLoading()
          }
        })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Kullanıcı {
              (routeLocation.state as any)?.type === 'User'
                ? 'Ekleme'
                : (routeLocation.state as any)?.type === 'UserUpdate'
                  ? 'Güncelleme'
                  : 'Ekleme'
            }</span>
            <IPButton
              type='button'
              className='btn btn-primary m-l-10'
              text='Ekle'
              onClick={handleUserAdd}
              style={{ width: '25px', marginRight: '10px' }}
            />
          </div>
          <div className='card-body'>
            <div className='row m-t-20'>
              <div className='col-lg-4'>
                <IPInput
                  type='text'
                  placeholder='Hesap Kodu'
                  id='AccountCode'
                  value={state.postUser.AccountCode}
                  onChange={e => {
                    const AccountCode = e.target.value
                    setState({
                      postUser: {
                        AccountCode: AccountCode,
                        AccountName: state.postUser.AccountName,
                        UserCode: state.postUser.UserCode,
                        Email: state.postUser.Email,
                        Status: state.postUser.Status,
                      },
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      filterUser();
                    }
                  }}
                />
              </div>
              <div className='col-lg-4'>
                <IPInput
                  type='text'
                  id='AccountName'
                  className='form-control'
                  placeholder='Ad Soyad'
                  value={state.postUser.AccountName}
                  onChange={e => {
                    const AccountName = e.target.value
                    setState({
                      postUser: {
                        AccountCode: state.postUser.AccountCode,
                        AccountName: AccountName,
                        UserCode: state.postUser.UserCode,
                        Email: state.postUser.Email,
                        Status: state.postUser.Status,
                      },
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      filterUser();
                    }
                  }}
                />
              </div>
              <div className='col-lg-4'>
                <IPInput
                  type='text'
                  id='UserCode'
                  value={state.postUser.UserCode}
                  className='form-control'
                  placeholder='Kullanıcı Adı'
                  onChange={e => {
                    const UserCode = e.target.value
                    setState({
                      postUser: {
                        AccountCode: state.postUser.AccountCode,
                        AccountName: state.postUser.AccountName,
                        UserCode: UserCode,
                        Email: state.postUser.Email,
                        Status: state.postUser.Status,
                      },
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      filterUser();
                    }
                  }}
                />
              </div>
              <div className='col-lg-4 m-t-5'>
                <IPInput
                  type='text'
                  id='Email'
                  value={state.postUser.Email}
                  className='form-control'
                  placeholder='Email'
                  onChange={e => {
                    const Email = e.target.value
                    setState({
                      postUser: {
                        AccountCode: state.postUser.AccountCode,
                        AccountName: state.postUser.AccountName,
                        UserCode: state.postUser.UserCode,
                        Email: Email,
                        Status: state.postUser.Status,
                      },
                    })
                  }}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      filterUser();
                    }
                  }}
                />
              </div>
              <div className='col-lg-4 m-t-5'>
                <IPSelectBox
                  isClearable
                  placeholder="Durum Seçiniz..."
                  options={statusOptions}
                  value={statusOptions.find(option => option.value === state.postUser.Status.toString())}
                  onChangeSingle={option => {
                    if (option === null) {
                      setState({
                        postUser: {
                          AccountCode: state.postUser.AccountCode,
                          AccountName: state.postUser.AccountName,
                          UserCode: state.postUser.UserCode,
                          Email: state.postUser.Email,
                          Status: 0,
                        },
                      })
                      setStatus('')
                    } else {
                      setState({
                        postUser: {
                          AccountCode: state.postUser.AccountCode,
                          AccountName: state.postUser.AccountName,
                          UserCode: state.postUser.UserCode,
                          Email: state.postUser.Email,
                          Status: Number(option.value),
                        },
                      })
                      setStatus(option)
                    }
                  }}
                />
              </div>
              <div className='col-lg-2 m-t-5'>
                <IPButton
                  type='button'
                  onClick={filterUser}
                  className='btn btn-success'
                  text="Süz"
                />
              </div>
            </div>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
          <IPModal
            show={show}
            onClose={() => {
              setShowHistory(false)
              setHistory([])
            }}
            hideConfirm={true}
            width={1500}
            title='Tarihçe'
          >
            <table className='table'>
              <thead>
                <tr role='row' className='odd'>
                  <th>#</th>
                  <th>Ad</th>
                  <th>Soyad</th>
                  <th>Tel</th>
                  <th>Bayi Kodu</th>
                  <th>Mail</th>
                  <th>Durum</th>
                  <th>MT</th>
                  <th>Rapor</th>
                  <th>MT Şirketleri</th>
                  <th>Not</th>
                  <th>Tarih</th>
                  <th>Güncelleyen Bayi</th>
                  <th>Güncelleyen Kullanıcı</th>
                  <th>Güncelleme Notu</th>
                </tr>
              </thead>

              <tbody>
                {
                  history && history.length > 0 && history.map((his) => (
                    <tr role='row' className='odd' key={his.Id}>
                      <td>{<a
                        className='btn btn-transparent'
                        type='button'
                        data-action-type='order'
                      >
                        {his.Id}
                      </a>}</td>
                      <td>{his.Name}</td>
                      <td>{his.Surname}</td>
                      <td>{his.Gsm}</td>
                      <td>{his.DealerSapCode && his.DealerSapCode.replace('0000', '')}</td>
                      <td>{his.Email}</td>
                      <td>{his.Status === 1 ? "Aktif" : his.Status === 2 ? "Pasif" : his.Status === 3 ? "Silinmiş" : his.Status === 4 ? "Onay Bekliyor" : his.Status === 5 ? "Bloke" : ""}</td>
                      <td>{his.MtUser === true ? "Evet" : ""}</td>
                      <td>{his.ReportUser === true ? "Evet" : ""}</td>
                      <td>{his.MtReportCompanyList}</td>
                      <td>{his.Notes}</td>
                      <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                      <td>{his.UpdaterUserDealer && his.UpdaterUserDealer.replace('0000', '')}</td>
                      <td>{his.UpdaterUserName}</td>
                      <td>{his.UpdaterNote}</td>

                    </tr>
                  ))
                }
              </tbody>
            </table>
          </IPModal>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(ListUser)