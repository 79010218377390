import { translate } from '../../base/localization'
import { IProduct } from '../../pages/ProductList/models/response/getProductListResponseModel'
import Translations from '../../translations'
import IPButton from '../IPButton'
import { useAppDispatch } from '../../redux/app/hooks'
import { addItemToBasketRedux } from '../../redux/slices/basket/basketSlice'
import Swal from 'sweetalert2'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { GetCreateDemandApi } from '../../redux/slices/basket/basketService'
import { KeyValuePair } from '../../base/models'
import { BaseComponentWithContextProps, componentWithContext } from '../../base/customHooks/componentHOC'

interface IAddToBasket {
  product: IProduct
  quantity: number
  isRounded?: undefined | boolean
  className?: string
  isOpportunityMarket?: boolean
  disabled?: boolean
}

function AddToBasket(props: IAddToBasket & BaseComponentWithContextProps) {
  const t = translate(Translations)
  const dispatch = useAppDispatch()
  const navigate: NavigateFunction = useNavigate()

  const isRounded = [undefined, null, true].indexOf(props.isRounded) > -1

  const isDisabledButton = !((props.isOpportunityMarket && props.product.OpportunityStock > 0) ||
    props.product?.Prices?.Special?.Value > 0 &&
    props.product?.Prices?.Normal?.Value > 0 &&
    props.product?.Prices?.EndUser?.Value > 0
  )

  const addToBasketOrCreateDemand = async () => {
    if (isNaN(props.quantity)) {
      return
    }

    if (isDisabledButton) {
      return
    }

    if (Number(props.quantity) < 1) {
      Swal.fire({
        icon: 'error',
        title: 'Ürün adedi 0 dan büyük olmalıdır.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    if (props.isOpportunityMarket && props.product.OpportunityStock > 0) {
      dispatch(
        addItemToBasketRedux({
          ProductId: props.product.Id,
          Quantity: Number(props.quantity),
          IsOppurtunityStock: true,
          OpportunitiyStockId: props.product.OpportunityId ?? 0,
        })
      )
    } else {
      if (props.product?.Stock?.Stock === 0 && props.product?.Stock?.Stock < Number(props.quantity)) {
        const api: GetCreateDemandApi = new GetCreateDemandApi({})
        const queryParams: KeyValuePair<number> = new KeyValuePair<number>()
        queryParams.add('pId', props.product.Id)
        api.queryParams = queryParams

        const confirmation = await Swal.fire({
          title: 'Bu ürün için Müşteri Temsilcinize talep iletmek istediğinize emin misiniz?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#ec4c4c',
          cancelButtonText: 'Vazgeç',
          confirmButtonText: 'Evet',
        })

        if (confirmation.isConfirmed) {
          try {
            props.showLoading()
            const result = await api.getCreateDemand()
            if (result) {
              props.hideLoading()
              Swal.fire({
                html: `<h5>${result}</h5>`,
                confirmButtonText: `${t('components.AddToBasket.continueToShopping')}`,
                confirmButtonColor: '#ec4c4c',
              })
                .then(result => { })
                .catch((err: any) => {
                  props.hideLoading()
                  Swal.fire({
                    icon: 'error',
                    title: err.description,
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                })
            }
          } catch (err: any) {
            props.hideLoading()
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
        } else {
          Swal.close() // Close the SweetAlert modal
        }

        // await api
        //   .getCreateDemand()
        //   .then(async result => {
        //     if (result) {
        //       Swal.fire({
        //         html: `<h5>${result}</h5>`,
        //         confirmButtonText: `${t('components.AddToBasket.continueToShopping')}`,
        //         confirmButtonColor: '#ec4c4c',
        //       }).then(result => { })
        //     }
        //   })
        //   .catch((err: any) => {
        //     Swal.fire({
        //       icon: 'error',
        //       title: err.description,
        //       showConfirmButton: true,
        //       allowOutsideClick: false,
        //     })
        //   })
      } else {
        dispatch(
          addItemToBasketRedux({
            ProductId: props.product.Id,
            Quantity: Number(props.quantity),
            IsOppurtunityStock: false,
            OpportunitiyStockId: 0,
          })
        )
      }
    }
  }

  return (
    <>
      {props.isOpportunityMarket ? (
        <>
          <IPButton
            text={
              props.product?.OpportunityStock > 0
                ? t('components.AddToBasket.addToBasket')
                : t('components.AddToBasket.createDemand')
            }
            className={props.className ?? ''}
            // isRounded={isRounded}
            style={{
              opacity: isDisabledButton ? 0.5 : 1,
              cursor: isDisabledButton ? 'default' : 'pointer',
              backgroundColor: props.product?.OpportunityStock < 1 ? '#0FBDE0' : '',
            }}
            onClick={addToBasketOrCreateDemand}
            icon='shopping-cart-line.svg'
          />
        </>
      ) : (
        <>
          {props.product?.Stock?.StockFlag > 0 ? (
            // <a href="tel:03124728220">
            <IPButton
              text='Rezerve'
              className={props.className ?? 'addToBasket'}
              // isRounded={isRounded}
              style={{
                opacity: isDisabledButton ? 0.5 : 1,
                cursor: isDisabledButton ? 'default' : 'pointer',
                backgroundColor: '#699bf7',
              }}
              icon='phone-solid.svg'
            />
          ) : (
            // </a>
            <IPButton
              text={
                props.product?.Stock?.Stock > 0
                  ? t('components.AddToBasket.addToBasket')
                  : t('components.AddToBasket.createDemand')
              }
              className={props.className ?? ''}
              // isRounded={isRounded}
              style={{
                opacity: isDisabledButton ? 0.5 : 1,
                cursor: isDisabledButton ? 'default' : 'pointer',
                backgroundColor: props.product?.Stock?.Stock < 1 ? '#0FBDE0' : '',
              }}
              onClick={() => {
                if (props.disabled)
                  return Swal.fire({
                    icon: 'error',
                    title: 'Bu ürünü sepete atmak için lütfen iquote ürün linkini kullanınız.',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                addToBasketOrCreateDemand()
              }}
              icon='shopping-cart-line.svg'
            />
          )}
        </>
      )}
    </>
  )
}

export default componentWithContext(AddToBasket)
