import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { DealerChannelResponse } from '../models/getDealerChannelResponseModel'

export interface IDealerService {
  TheCode: string
  TypeCode: string
}

export interface Company {
  Name: string
  Image: string
  ImageUrl: string
  IyzipayMerchantKey: string
  Id: number
}

export class ProgramService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/campaignBundle/GetProgramAll',
      requesterInfo,
    })
  }

  public async GetProgramAll(): Promise<DealerChannelResponse[]> {
    return await this.getAsync<DealerChannelResponse[]>()
  }
  public async GetProgramById(id): Promise<DealerChannelResponse> {
    this.props.url = `admin/campaignBundle/GetProgramById/${id}`
    return await this.getAsync<any>(id)
  }
  public async UpdateProgram(req): Promise<DealerChannelResponse> {
    this.props.url = 'admin/campaignBundle/UpdateProgram'
    return await this.putAsync<any>(req)
  }
  public async DeleteProgram(id): Promise<DealerChannelResponse> {
    this.props.url = `admin/campaignBundle/DeleteProgram/${id}`
    return await this.deleteAsync<any>(id)
  }
  public async GetCompanyList(): Promise<Company[]> {
    this.props.url = 'admin/company/list'
    return await this.getAsync<Company[]>()
  }
}
