import React, { useState, useEffect } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import { GetOpenInvoicesApi } from '../services/opneInvoicesApi'
import { IOpenInvoicesResponse } from '../models/openInvoicesModel'
import './OpenInvoices.scss'
import IPButton from '../../../../../components/IPButton'
import { downloadFileFromUrl, formatPrice, printElement } from '../../../../../base/utils/dataHelper'
import { IException } from '../../../../../base/models'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { PostPaymentDetailApi } from '../../../CurrentAccount/Extract/services/postPaymentDetailApi'
import { IOrderOrPaymentDetail } from '../../../CurrentAccount/Extract/models/models'
import { useNavigate } from 'react-router-dom'
import IPModal from '../../../../../components/IPModal'
import { GetCompaniesApi } from '../../../CurrentAccount/Extract/services/getCompaniesApi'
import { ICompany } from '../../../../ProductList/models/commonModels'
import { OpenInvoicesExcelApi } from '../services/openInvoicesExcelApi'
import ExcelButton from '../../../../../components/IPExcelButton/IPExcelButton'
import IPPrintButton from '../../../../../components/IPPrintButton'
import IPPriceDisplay from '../../../../../components/IPPriceDisplay'

interface IOpenInvoicesState {
  companyActive: number
  selectData: IOpenInvoicesResponse[]
  invoiceNo: string
  modal: boolean
  orderOrPaymentDetail?: IOrderOrPaymentDetail
  companies: ICompany[]
}

function OpenInvoices(props: BaseComponentWithContextProps) {
  const getInitialState = (): IOpenInvoicesState => {
    return {
      companyActive: 0,
      selectData: [],
      invoiceNo: '',
      modal: false,
      companies: [],
    }
  }
  const [openInvoices, setOpenInvoices] = useState<IOpenInvoicesResponse[]>([])
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IOpenInvoicesState>(intialState)

  // const selectCompany = async (company: number) => {
  //   const data: IOpenInvoicesResponse[] = []
  //   if (company === 0) {
  //     return setState({ companyActive: company, selectData: openInvoices })
  //   }
  //   openInvoices.filter((r) => {
  //     return +r.ComponyCode === +company
  //   }).map((re) => {
  //     return data.push(re)
  //   })
  //   setState({ companyActive: company, selectData: data })
  // }

  useEffect(() => {
    getOpenInvoices(0)
    getCompanies()
  }, [])

  const getOpenInvoices = async (company: number) => {
    props.showLoading()
    setState({ companyActive: company })
    const getOpenInvoices: GetOpenInvoicesApi = new GetOpenInvoicesApi({})
    getOpenInvoices
      .getOpenInvoices(company ? (company === 0 ? [] : [String(company)]) : [])
      .then(async res => {
        if (res) {
          setState({ selectData: res })
          setOpenInvoices(res)
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        setState({ selectData: [] })
        setOpenInvoices([])
        props.hideLoading()
      })
  }

  const exportToExcel = () => {
    const openInvoicesExcelApi: OpenInvoicesExcelApi = new OpenInvoicesExcelApi({})
    openInvoicesExcelApi
      .openInvoicesExcel(state.companyActive)
      .then((res: any) => {
        downloadFileFromUrl('Elektronik Faturalar', res.FileUrl)
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const navigate: any = useNavigate()
  const postPaymentDetail = async (invoiceNumber: string) => {
    props.showLoading()
    const postPaymentDetailApi: PostPaymentDetailApi = new PostPaymentDetailApi({})
    postPaymentDetailApi
      .postPaymentDetail(invoiceNumber)
      .then(async result => {
        if (result) {
          setState({ invoiceNo: invoiceNumber, modal: false, orderOrPaymentDetail: result })
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getCompanies = () => {
    const api: GetCompaniesApi = new GetCompaniesApi({})
    api.getCompanies().then((res: ICompany[]) => {
      setState({ companies: res })
    })
  }

  return (
    <div className='OpenInvoices'>
      <div className='heading-text'>
        <h4>Açık Faturalar</h4>
      </div>
      <nav className='cari-hesap--tab-menu'>
        <ul>
          <li
            onClick={() => getOpenInvoices(1000)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1000 ? 'active-company-tab' : ''}`}
          >
            <span>İNDEX</span>
          </li>
          <li
            onClick={() => getOpenInvoices(1100)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1100 ? 'active-company-tab' : ''}`}
          >
            <span>DATAGATE</span>
          </li>
          <li
            onClick={() => getOpenInvoices(1200)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1200 ? 'active-company-tab' : ''}`}
          >
            <span>DESPEC</span>
          </li>
          <li
            onClick={() => getOpenInvoices(2300)}
            className={`dropdown-submenu company-tab ${state.companyActive === 2300 ? 'active-company-tab' : ''}`}
          >
            <span>NETEX</span>
          </li>
          <li
            onClick={() => getOpenInvoices(0)}
            className={`dropdown-submenu company-tab ${state.companyActive === 0 ? 'active-company-tab' : ''}`}
          >
            <span>HEPSİ</span>
          </li>
        </ul>
      </nav>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <b style={{ color: 'red', margin: '0 10px' }}>Vadesi Biten: Kırmızı</b>
        <b style={{ color: 'orange', margin: '0 10px' }}>7 Günden Az: Turuncu</b>
        <b style={{ color: 'green', margin: '0 10px' }}>7 Günden Fazla: Yeşil</b>
      </div>
      <br></br>

      <div className='row all-toPrint' id='data'>
        {/* compony info start */}
        <div className='row'>
          <div className='col-lg-12'>
            <div className='componyInfoContainer'>
              <div className='firstRow flex-column flex-lg-row'>
                <div className='Part'>
                  <div className='Title'>Şirket</div>
                  <div className='Description'>
                    :{' '}
                    {state.companyActive === 0
                      ? 'HEPSİ'
                      : state.companyActive === 1000
                      ? 'İNDEX'
                      : state.companyActive === 1100
                      ? 'DATAGATE'
                      : state.companyActive === 1200
                      ? 'DESPEC'
                      : 'NETEX'}
                  </div>
                </div>
                <div className='secondPart'>
                  <div className='Title'>İl/İlçe</div>
                  <div className='Description'>
                    : {openInvoices.length > 0 && openInvoices[0]?.ProvinceName}/
                    {openInvoices.length > 0 && openInvoices[0]?.DistrictName}
                  </div>
                </div>
              </div>
              <div className='firstRow flex-column flex-lg-row'>
                <div className='Part'>
                  <div className='Title'>Şube</div>
                  <div className='Description'>: {openInvoices.length > 0 && openInvoices[0]?.BranchCode}</div>
                </div>
                <div className='secondPart'>
                  <div className='Title'>Telefon</div>
                  <div className='Description'>: {openInvoices.length > 0 && openInvoices[0]?.Phone}</div>
                </div>
              </div>
              <div className='firstRow flex-column flex-lg-row'>
                <div className='Part'>
                  <div className='Title'>Hesap Kodu</div>
                  <div className='Description'>
                    : {openInvoices.length > 0 && openInvoices[0]?.DealerCode.replace('0000', '')}
                  </div>
                </div>
                <div className='secondPart'>
                  <div className='Title'>Faks</div>
                  <div className='Description'>: {openInvoices.length > 0 && openInvoices[0]?.Fax}</div>
                </div>
              </div>
              <div className='firstRow flex-column flex-lg-row'>
                <div className='Part'>
                  <div className='Title'>Ünvan</div>
                  <div className='Description'>: {openInvoices.length > 0 && openInvoices[0]?.DealerTitle}</div>
                </div>
              </div>
            </div>
          </div>
          <div id='tableData'>
            {state.selectData &&
              state.selectData.length > 0 &&
              state.selectData[0].Data.length > 0 &&
              state.selectData[0].Data.findIndex(dta => {
                return dta.ParaBirimi === 'TRY'
              }) > -1 && (
                <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                  <thead>
                    <tr className='tableDescription'>
                      {state.companyActive === 0 && <td scope='col'>Şirket</td>}
                      <td scope='col'>Tarih</td>
                      <td scope='col'>Evrak No</td>
                      <td scope='col'>Belge No</td>
                      <td scope='col'>Vade</td>
                      <td scope='col' style={{ textAlign: 'right' }}>
                        Kalan (₺)
                      </td>
                      <td scope='col' style={{ textAlign: 'right' }}>
                        Kalan (₺)
                      </td>
                    </tr>
                    <tr className='currencyHeader'>
                      <td scope='col' colSpan={state.companyActive === 0 ? 7 : 6} style={{ fontWeight: 'bold' }}>
                        Para Birimi: TL
                      </td>
                    </tr>
                  </thead>
                  {state.selectData &&
                    state.selectData.length > 0 &&
                    state.selectData.map(
                      open =>
                        open.Data &&
                        open.Data.length > 0 &&
                        open.Data.filter(dta => {
                          return dta.ParaBirimi === 'TRY'
                        }).map(dt =>
                          dt.Invoices.map((item, index) => (
                            <tr
                              className='expiredInvoices'
                              onClick={() => {
                                if (item.DocumantNo.slice(0, 3) === 'TKY') return postPaymentDetail(item.DocumantNo)
                                window.open(`/myspecial/EInvoice?eInvoiceId=${item.DocumantNo}`)
                              }}
                              key={index}
                            >
                              {state.companyActive === 0 && (
                                <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                  {+open.ComponyCode === 1000
                                    ? 'İNDEX'
                                    : +open.ComponyCode === 1100
                                    ? 'DATAGETE'
                                    : +open.ComponyCode === 1200
                                    ? 'DESPEC'
                                    : 'NETEX'}
                                </td>
                              )}
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.Date.split('', 10)}{' '}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.PaperWorkNo}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.DocumantNo}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.ExpiryDate.split('', 10)}
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green',
                                }}
                              >
                                {/* {item.remainder.toFixed(2) + ' ₺'} */}
                                <IPPriceDisplay
                                  price={item.remainder.toFixed(2).replace('.', ',') + ' ₺'}
                                  integerFontSize='15px'
                                  fractionalFontSize='12px'
                                />
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green',
                                }}
                              >
                                {/* {item.remainderTL.toFixed(2) + ' ₺'} */}
                                <IPPriceDisplay
                                  price={item.remainderTL.toFixed(2).replace('.', ',') + ' ₺'}
                                  integerFontSize='15px'
                                  fractionalFontSize='12px'
                                />
                              </td>
                            </tr>
                          ))
                        )
                    )}
                  <tr>
                    <td scope='col' colSpan={state.companyActive === 0 ? 4 : 3}>
                      Toplam
                    </td>
                    <td scope='col' colSpan={2}></td>
                    <td scope='col' style={{ textAlign: 'right' }}>
                      {/* {state.selectData &&
                        state.selectData.length > 0 &&
                        Number(
                          state.selectData
                            .map(
                              open =>
                                open.Data &&
                                open.Data.length > 0 &&
                                open.Data.filter(dta => dta.ParaBirimi === 'TRY').reduce(
                                  (total, dt) =>
                                    total + dt.Invoices.reduce((sum, item) => sum + (item.remainderTL ?? 0), 0),
                                  0
                                )
                            )
                            .reduce((sum, val) => Number(sum) + Number(val), 0)
                        ).toFixed(2) + ' ₺'} */}
                      <IPPriceDisplay
                        price={
                          state.selectData &&
                          state.selectData.length > 0 &&
                          Number(
                            state.selectData
                              .map(
                                open =>
                                  open.Data &&
                                  open.Data.length > 0 &&
                                  open.Data.filter(dta => dta.ParaBirimi === 'TRY').reduce(
                                    (total, dt) =>
                                      total + dt.Invoices.reduce((sum, item) => sum + (item.remainderTL ?? 0), 0),
                                    0
                                  )
                              )
                              .reduce((sum, val) => Number(sum) + Number(val), 0)
                          )
                            .toFixed(2)
                            .replace('.', ',') + ' ₺'
                        }
                        integerFontSize='15px'
                        fractionalFontSize='12px'
                      />
                    </td>
                  </tr>
                </table>
              )}
            {state.selectData &&
              state.selectData.length > 0 &&
              state.selectData[0].Data.length > 0 &&
              state.selectData[0].Data.findIndex(dta => {
                return dta.ParaBirimi === 'USD'
              }) > -1 && (
                <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                  <thead>
                    <tr className='tableDescription'>
                      {state.companyActive === 0 && <td scope='col'>Şirket</td>}
                      <td scope='col'>Tarih</td>
                      <td scope='col'>Evrak No</td>
                      <td scope='col'>Belge No</td>
                      <td scope='col'>Vade</td>
                      <td scope='col' style={{ textAlign: 'right' }}>
                        Kalan ($)
                      </td>
                      <td scope='col' style={{ textAlign: 'right' }}>
                        Kalan (₺)
                      </td>
                    </tr>
                    <tr className='currencyHeader'>
                      <td scope='col' colSpan={state.companyActive === 0 ? 7 : 6} style={{ fontWeight: 'bold' }}>
                        Para Birimi: USD
                      </td>
                    </tr>
                  </thead>
                  {state.selectData &&
                    state.selectData.length > 0 &&
                    state.selectData.map(
                      open =>
                        open.Data &&
                        open.Data.length > 0 &&
                        open.Data.filter(dta => {
                          return dta.ParaBirimi === 'USD'
                        }).map(dt =>
                          dt.Invoices.map((item, index) => (
                            <tr
                              className='expiredInvoices'
                              onClick={() => {
                                if (item.DocumantNo.slice(0, 3) === 'TKY') return postPaymentDetail(item.DocumantNo)
                                window.open(`/myspecial/EInvoice?eInvoiceId=${item.DocumantNo}`)
                              }}
                              key={index}
                            >
                              {state.companyActive === 0 && (
                                <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                  {+open.ComponyCode === 1000
                                    ? 'İNDEX'
                                    : +open.ComponyCode === 1100
                                    ? 'DATAGETE'
                                    : +open.ComponyCode === 1200
                                    ? 'DESPEC'
                                    : 'NETEX'}
                                </td>
                              )}
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.Date.split('', 10)}{' '}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.PaperWorkNo}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.DocumantNo}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.ExpiryDate.split('', 10)}
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green',
                                }}
                              >
                                {/* {item.remainder.toFixed(2) + " $"} */}
                                <IPPriceDisplay
                                  price={item.remainder.toFixed(2).replace('.', ',') + ' $'}
                                  integerFontSize='15px'
                                  fractionalFontSize='12px'
                                />
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green',
                                }}
                              >
                                {/* {item.remainderTL.toFixed(2) + ' ₺'} */}
                                <IPPriceDisplay
                                  price={item.remainderTL.toFixed(2).replace('.', ',') + ' ₺'}
                                  integerFontSize='15px'
                                  fractionalFontSize='12px'
                                />
                              </td>
                            </tr>
                          ))
                        )
                    )}
                  <tr>
                    <td scope='col' colSpan={state.companyActive === 0 ? 4 : 3}>
                      Toplam
                    </td>
                    <td scope='col' colSpan={2}></td>
                    <td scope='col' style={{ textAlign: 'right' }}>
                      {/* {state.selectData &&
                        state.selectData.length > 0 &&
                        Number(
                          state.selectData
                            .map(
                              open =>
                                open.Data &&
                                open.Data.length > 0 &&
                                open.Data.filter(dta => dta.ParaBirimi === 'USD').reduce(
                                  (total, dt) =>
                                    total + dt.Invoices.reduce((sum, item) => sum + (item.remainderTL ?? 0), 0),
                                  0
                                )
                            )
                            .reduce((sum, val) => Number(sum) + Number(val), 0)
                        ).toFixed(2) + ' ₺'} */}
                      <IPPriceDisplay
                        price={
                          state.selectData &&
                          state.selectData.length > 0 &&
                          Number(
                            state.selectData
                              .map(
                                open =>
                                  open.Data &&
                                  open.Data.length > 0 &&
                                  open.Data.filter(dta => dta.ParaBirimi === 'USD').reduce(
                                    (total, dt) =>
                                      total + dt.Invoices.reduce((sum, item) => sum + (item.remainderTL ?? 0), 0),
                                    0
                                  )
                              )
                              .reduce((sum, val) => Number(sum) + Number(val), 0)
                          )
                            .toFixed(2)
                            .replace('.', ',') + ' ₺'
                        }
                        integerFontSize='15px'
                        fractionalFontSize='12px'
                      />
                    </td>
                  </tr>
                </table>
              )}
            {state.selectData &&
              state.selectData.length > 0 &&
              state.selectData[0].Data.length > 0 &&
              state.selectData[0].Data.findIndex(dta => {
                return dta.ParaBirimi === 'EUR'
              }) > -1 && (
                <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                  <thead>
                    <tr className='tableDescription'>
                      {state.companyActive === 0 && <td scope='col'>Şirket</td>}
                      <td scope='col'>Tarih</td>
                      <td scope='col'>Evrak No</td>
                      <td scope='col'>Belge No</td>
                      <td scope='col'>Vade</td>
                      <td scope='col' style={{ textAlign: 'right' }}>
                        Kalan (€)
                      </td>
                      <td scope='col' style={{ textAlign: 'right' }}>
                        Kalan (₺)
                      </td>
                    </tr>
                    <tr className='currencyHeader'>
                      <td scope='col' colSpan={state.companyActive === 0 ? 7 : 6} style={{ fontWeight: 'bold' }}>
                        Para Birimi: EURO
                      </td>
                    </tr>
                  </thead>
                  {state.selectData &&
                    state.selectData.length > 0 &&
                    state.selectData.map(
                      open =>
                        open.Data &&
                        open.Data.length > 0 &&
                        open.Data.filter(dta => {
                          return dta.ParaBirimi === 'EUR'
                        }).map(dt =>
                          dt.Invoices.map((item, index) => (
                            <tr
                              className='expiredInvoices'
                              onClick={() => {
                                if (item.DocumantNo.slice(0, 3) === 'TKY') return postPaymentDetail(item.DocumantNo)
                                window.open(`/myspecial/EInvoice?eInvoiceId=${item.DocumantNo}`)
                              }}
                              key={index}
                            >
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {+open.ComponyCode === 1000
                                  ? 'İNDEX'
                                  : +open.ComponyCode === 1100
                                  ? 'DATAGETE'
                                  : +open.ComponyCode === 1200
                                  ? 'DESPEC'
                                  : 'NETEX'}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.Date.split('', 10)}{' '}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.PaperWorkNo}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.DocumantNo}
                              </td>
                              <td style={{ color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green' }}>
                                {item.ExpiryDate.split('', 10)}
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green',
                                }}
                              >
                                {/* {item.remainder.toFixed(2) + ' €'} */}
                                <IPPriceDisplay
                                  price={item.remainder.toFixed(2).replace('.', ',') + ' €'}
                                  integerFontSize='15px'
                                  fractionalFontSize='12px'
                                />
                              </td>
                              <td
                                style={{
                                  textAlign: 'right',
                                  color: item.Color === 0 ? 'red' : item.Color === 1 ? 'orange' : 'green',
                                }}
                              >
                                {/* {item.remainderTL.toFixed(2) + ' ₺'} */}
                                <IPPriceDisplay
                                  price={item.remainderTL.toFixed(2).replace('.', ',') + ' ₺'}
                                  integerFontSize='15px'
                                  fractionalFontSize='12px'
                                />
                              </td>
                            </tr>
                          ))
                        )
                    )}
                  <tr>
                    <td scope='col' colSpan={state.companyActive === 0 ? 4 : 3}>
                      Toplam
                    </td>
                    <td scope='col' colSpan={2}></td>
                    <td scope='col' style={{ textAlign: 'right' }}>
                      {/* {state.selectData &&
                        state.selectData.length > 0 &&
                        Number(
                          state.selectData
                            .map(
                              open =>
                                open.Data &&
                                open.Data.length > 0 &&
                                open.Data.filter(dta => dta.ParaBirimi === 'EUR').reduce(
                                  (total, dt) =>
                                    total + dt.Invoices.reduce((sum, item) => sum + (item.remainderTL ?? 0), 0),
                                  0
                                )
                            )
                            .reduce((sum, val) => Number(sum) + Number(val), 0)
                        ).toFixed(2) + ' ₺'} */}
                      <IPPriceDisplay
                        price={
                          state.selectData &&
                          state.selectData.length > 0 &&
                          Number(
                            state.selectData
                              .map(
                                open =>
                                  open.Data &&
                                  open.Data.length > 0 &&
                                  open.Data.filter(dta => dta.ParaBirimi === 'EUR').reduce(
                                    (total, dt) =>
                                      total + dt.Invoices.reduce((sum, item) => sum + (item.remainderTL ?? 0), 0),
                                    0
                                  )
                              )
                              .reduce((sum, val) => Number(sum) + Number(val), 0)
                          )
                            .toFixed(2)
                            .replace('.', ',') + ' ₺'
                        }
                        integerFontSize='15px'
                        fractionalFontSize='12px'
                      />
                    </td>
                  </tr>
                </table>
              )}
          </div>
          <hr style={{ width: '100%' }} />

          <div className='d-flex justify-content-end'>
            <IPPrintButton
              onClick={() => {
                printElement(document.getElementById('tableData'))
              }}
            />
            <ExcelButton onClick={exportToExcel} />
          </div>
        </div>
      </div>
      <IPModal
        show={state.modal}
        onClose={() => setState({ modal: false })}
        hideConfirm={true}
        width={'auto'}
        title={state.orderOrPaymentDetail?.OrderReturnModel ? 'Sipariş Detay' : 'Ödeme Detay'}
      >
        <>
          {state.orderOrPaymentDetail && state.orderOrPaymentDetail.OrderReturnModel && (
            <div>
              <div className='row'>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Şirket</b> <span className='mr-2'>:</span>
                    {state.companies.map(i => {
                      if (state.orderOrPaymentDetail?.OrderReturnModel.Company === String(i.Code)) {
                        return <img className='sellerImage' src={i.Image} alt='' style={{ width: '75px' }} />
                      }
                    })}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Sipariş Numarası</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.OrderNumber}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Ödeme Tipi</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.PaymentMethod}
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Kur Tipi</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.CurrencyType}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>Kur</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.CurrencyValue}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '150px' }}>MT</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail.OrderReturnModel.MT}
                  </div>
                </div>
              </div>
              <br />
              <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                <thead>
                  <tr className='EInvoicetableDescription'>
                    <td scope='col'>Mal Kodu</td>
                    <td scope='col'>Açıklama</td>
                    <td scope='col'>Mik</td>
                    <td scope='col'>Birim Tutar</td>
                    <td scope='col'>Toplam Tutar</td>
                  </tr>
                </thead>
                {state.orderOrPaymentDetail.OrderReturnModel.Items.map(order => (
                  <tbody key={order.ProdId}>
                    <td>{order.ProductCode}</td>
                    <td>{order.ProductName}</td>
                    <td>{order.Quantity}</td>
                    <td style={{ textAlign: 'right' }}>{order.Price1}</td>
                    <td style={{ textAlign: 'right' }}>{order.TotalPrice1}</td>
                  </tbody>
                ))}
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>
                    <b>Ara Toplam</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.TotalPrice}</td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>
                    <b>Kargo</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.Cargo}</td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>
                    <b>KDV</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.TotalKdv}</td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td style={{ textAlign: 'right' }}>
                    <b>G. Toplam</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>{state.orderOrPaymentDetail.OrderReturnModel.Total}</td>
                </tbody>
              </table>
            </div>
          )}
          {state.orderOrPaymentDetail?.PaymentReturnModel && (
            <div id='order'>
              <div className='form-row' style={{ justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
                <div className='form-group'>
                  <h4 style={{ marginTop: '10px' }}>Kredi Kartı Makbuzu</h4>
                </div>
              </div>
              <hr />
              <div className='row'>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Makbuz No</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.ReceiptNumber}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Tutar (TL) </b>
                    <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail
                      ? formatPrice(state.orderOrPaymentDetail?.PaymentReturnModel?.Amount, 'TRY', 'symbol')
                      : formatPrice(0, 'TRY', 'symbol')}
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Belge No</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.DocumentNo}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Banka </b>
                    <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.Bank}
                  </div>
                </div>
                <div className='col'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Tarih</b> <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.Date
                      ? new Date(state.orderOrPaymentDetail?.PaymentReturnModel?.Date).toLocaleDateString('tr-TR')
                      : '-'}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Taksit Sayısı </b>
                    <span className='mr-2'>:</span>
                    {state.orderOrPaymentDetail?.PaymentReturnModel?.Installment}
                  </div>
                </div>
              </div>
              <hr />
              <div className='d-flex align-items-center justify-content-start'>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.DealerCode}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.DealerName}
              </div>
              <h5 style={{ marginTop: '10px' }}>
                <b>Kredi Kartı Makbuzu</b>
              </h5>
              <hr />
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '200px' }}>Ad Soyad </b>
                <span className='mr-2'>:</span>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.NameSurname}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '200px' }}>Kredi Kartı Numarası</b>
                <span className='mr-2'>:</span>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.CrediCard}
              </div>
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '200px' }}>Son kullanma Tarihi</b>
                <span className='mr-2'>:</span>
                {state.orderOrPaymentDetail?.PaymentReturnModel?.ExpireDate}
              </div>
            </div>
          )}
        </>
      </IPModal>
    </div>
  )
}
export default componentWithContext(OpenInvoices)
