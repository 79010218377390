import { IRequesterInfo } from '../base/models'
import { ClientProxy } from '../base/proxy/clientProxy'

export interface IsNot3DPaymentRequest {
  Companies: string[]
}
export class IsNot3DPayment extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/isNot3DPayment',
      requesterInfo,
    })
  }

  public async post(request: IsNot3DPaymentRequest): Promise<boolean> {
    return await this.postAsync<boolean>(request)
  }
}
