import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IAnalytics, IDateRange } from '../models/models'

export class AvgSessionsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/AnalyticsReporting/avgSessions',
      requesterInfo,
    })
  }

  public async avgSessions(request: IDateRange): Promise<IAnalytics> {
    return await this.postAsync<IAnalytics>(request)
  }
}
