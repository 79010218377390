import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { SaveSurveyRequest, SaveSurveyResponse } from '../models/postSurveyModels'

export class SaveSurveyApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'survey/post',
      requesterInfo,
    })
  }
  public async saveSurvey(request: SaveSurveyRequest): Promise<SaveSurveyResponse> {
    return await this.postAsync<SaveSurveyResponse>(request)
  }
}
