import React, { useEffect, useState, useRef, useCallback } from 'react'
import GetOneConfigApi from '../services/getOneConfigApi'
import Swal from 'sweetalert2'
import { useAppSelector } from '../../../redux/app/hooks'
import { currentUser } from '../../../redux/slices/user/userSlice'

declare global {
  interface Window {
    embedProductSelector: any
    OneConfigEmbed: any
  }
}

const useOneConfig = () => {
  const [config, setConfig] = useState({ jwt: '', gateway: '' })

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const api = new GetOneConfigApi({})
        const result = await api.getOneConfigContents()
        if (result) {
          setConfig({
            jwt: result.Jwt,
            gateway: result.Gateway,
          })
        }
      } catch (err: any) {
        Swal.fire({
          icon: 'error',
          title: err?.description || 'An error occurred',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    }

    fetchConfig()
  }, [])

  return config
}

const ProductSelector = () => {
  const user = useAppSelector(currentUser)
  const { jwt, gateway } = useOneConfig()
  const embedProductSelectorRef = useRef<HTMLDivElement>(null)

  const loadProductSelector = useCallback(() => {
    if (jwt && gateway) {
      const config = {
        gateway,
        token: jwt,
        currencyCode: 'USD',
        languageCode: 'tr_TR',
        region: 'EU',
        country: 'TR',
        businessModel: 'Indirect',
        userEmail: user.Email,
        userId: user.UserId,
        userType: 'Internal',
        proCategory: 'compute',
        companyId: 'Index',
        pricingGeo: 'TR',
        incoTerms: 'DDP',
        mDCPOrgID: '99',
        resellerID: '',
        purchaseAgreementNumber: '',
        transactionID: '',
        locationID: '2001444759',
        prtnrPortalFlag: false,
        isStandAloneAccess: false,
        isDisplayProdCountry: false,
        colourOverrides: {
          primary: '#0096d6',
          secondary: '#d7410b',
          primaryHover: '#075782',
          primaryLight: '#e0f2fe',
          primaryLoader: '#22c55e',
          info: '#0171AD',
          success: '#4E9A51',
          warning: '#D7410B',
          error: '#D84646',
        },
        showMyConfigButton: localStorage.getItem('showMyConfigButton') !== 'false',
        //showSearchConfig: true,
        showStartingPointPrice: true,
        showListPriceText: true,
        openConfigCb,
      }

      window.embedProductSelector
        .launch(config)
        .then((success: any) => console.log(success))
        .catch((error: any) => console.error(error))
    }
  }, [jwt, gateway, user.Email, user.UserId])

  const loadOneConfigSnippet = useCallback(
    (payload: any) => {
      const config = {
        language: 'tr_TR',
        configId: payload.configID,
        region: 'EU',
        country: 'TR',
        priceId: '',
        priceDesc: 'TRUSDDP',
        openMrktId: '',
        contractId: '',
        marketProgramType: '',
        marketProgramName: '',
        source: '',
        banded: false,
        configRegion: '',
        productType: 'compute',
        priceListType: '',
        pricingGeo: 'TR',
        resellerID: '',
        routeToMarket: '',
        originatingAsset: 'OCA',
        userType: 'Internal',
        token: jwt,
        gateway,
        colourOverrides: {
          primary: '#0096d6',
          secondary: '#d7410b',
          primaryHover: '#075782',
          secondaryHover: '#e0f2fe',
          primaryLoader: '#22c55e',
        },
        mdcpOrgId: '99',
        showOM: true,
        bomUpdateCb: () => {},
        addToCart: (err: any, configId: any, bom: any) => {
          if (err) {
            console.log('Failed to add to cart :', err)
          } else {
            console.log('Added to cart successfully')
            console.log('Config ID :', configId)
            console.log('BOM details :', bom)
          }
        },
        showHeader: localStorage.getItem('hideHeader') !== 'true',
        showProductSummary: true,
        showSustainability: localStorage.getItem('showSustainability') === 'true',
        enableBomDeltaPrice: true,
        buttonTextToCart: 'Add To Cart',
        showRequestConfig: localStorage.getItem('showAddToCart') !== 'true',
        enableExport: true,
        showSaveConfig: true,
        showReset: true,
        requestConfigCb: loadProductSelector,
      }

      window.OneConfigEmbed.init(config)
        .then((success: any) => console.log(success))
        .catch((error: any) => console.error(error))
    },
    [jwt, gateway, loadProductSelector]
  )

  const openConfigCb = useCallback(
    (payload: any) => {
      window.embedProductSelector.cleanUp()
      loadOneConfigSnippet(payload)
    },
    [loadOneConfigSnippet]
  )

  useEffect(() => {
    if (jwt && gateway) {
      const scriptUrls = [
        'https://oc-product-selector.hpcloud.hp.com/productSelector.js',
        'https://oc-product-selector.hpcloud.hp.com/snippet/snippet.js',
      ]

      let scriptsLoaded = 0

      const handleLoad = () => {
        scriptsLoaded += 1
        if (scriptsLoaded === scriptUrls.length) {
          loadProductSelector()
        }
      }

      const scriptElements = scriptUrls.map(src => {
        const script = document.createElement('script')
        script.src = src
        script.async = true
        script.onload = handleLoad
        document.body.appendChild(script)
        return script
      })

      return () => {
        scriptElements.forEach(script => {
          if (document.body.contains(script)) {
            document.body.removeChild(script)
          }
        })
      }
    }
  }, [jwt, gateway, loadProductSelector])

  return (
    <div>
      <div id='embedProductSelector' ref={embedProductSelectorRef}></div>
      <div id='oneConfigEmbed'></div>
    </div>
  )
}

export default ProductSelector
