import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export interface ListCurrencyLogHistoryResponse {
  Date: Date
  Usd: number
  Euro: number
  Gbp: number
}

export class LastTwoCurrencyInfoService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/currency/lastTwoCurrencyInfo',
      requesterInfo,
    })
  }

  public async getLastTwoCurrInfo(): Promise<ListCurrencyLogHistoryResponse[]> {
    return await this.getAsync<ListCurrencyLogHistoryResponse[]>()
  }
}
