import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export class GetSuggestApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/productSuggest/',
      requesterInfo,
    })
  }

  public async getSuggest(request: number): Promise<any> {
    this.props.url = this.props.url + request
    return await this.postAsync<any>(request)
  }
}
