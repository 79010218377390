import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Translations from '../../translations'
import { translate } from '../../base/localization'
import './styles.scss'
import Image from '../../components/IPImage'
import IPInput from '../../components/IPInput'
import Button from '../../components/IPButton'
import Swal from 'sweetalert2'
import { IResetPasswordRequest } from './models/models'
import validationSchema from './validation'
import { useNavigate, Link, useLocation } from 'react-router-dom'
import { ResetPasswordApi } from '../ResetPassword/services/resetPasswordApi'
import { useParams } from 'react-router-dom'
import { IException } from '../../base/models'
import { encrypt } from '../../base/utils/proxyUtils'
import { isLoggedIn } from '../../base/proxy/authenticate'

function ResetPassword(props: any) {
  const t = translate(Translations)
  let navigate = useNavigate()
  const location = useLocation()
  const params = useParams()

  const [showResetPasswordPage, setShowResetPasswordPage] = useState(false)
  const [error, setError] = useState<any>()
  const [username, setUser] = useState<any>()

  const checkToken = async () => {
    try {
      const api: ResetPasswordApi = new ResetPasswordApi({})
      const req = {
        ResetToken: params.number != null ? params.number : '',
      }
      const res = await api.chkToken(req)
      if (res) {
        setUser(res)
      }
    } catch (err: any) {
      setError(err)
    }
  }

  useEffect(() => {
    isLoggedInLocal()
    checkToken()
  }, [])

  const isLoggedInLocal = () => {
    if (isLoggedIn()) {
      navigate('/homepage', { replace: true, state: { from: location.pathname } })
    } else {
      setShowResetPasswordPage(true)
    }
  }

  const { handleSubmit, handleChange, handleBlur, values, errors, touched } = useFormik({
    initialValues: {
      resetToken: '',
      password: '',
      passwordRepeat: '',
    },
    onSubmit: async values => {
      if (values.password === values.passwordRepeat) {
        resetPassword()
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Şifreler aynı değildir',
        })
      }
    },
    validationSchema,
  })

  const resetPassword = async () => {
    const resetPasswordApi: ResetPasswordApi = new ResetPasswordApi({})
    const forgetPasswordModel: IResetPasswordRequest = {
      ResetToken: params.number != null ? params.number.toString() : '',
      Password: encrypt(values.password),
      PasswordRepeat: encrypt(values.passwordRepeat),
    }

    resetPasswordApi
      .resetPassword(forgetPasswordModel)
      .then((res: boolean) => {
        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Şifreniz değiştirilmiştir.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })

          navigate('/login')
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Bir hata meydana geldi',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  if (error) {
    return (
      <div className='errorPage'>
        <img className='errLogo' alt='Index Pazar Logo center' src='/images/indexPazarLogo.png' />
        <div>{error.description}</div>
        <Link to='/'>Geri Dön</Link>
      </div>
    )
  }

  return (
    <React.Fragment>
      {showResetPasswordPage && (
        <div className='login-section col-12 col-md-4 mx-auto'>
          <div className='logo text-center' style={{ padding: '100px' }}>
            <Image className='form_logo' alt='Index Pazar Logo center' src='/images/indexPazarLogo.png' />
          </div>
          <div className='form-container'>
            <div className='form-group form-fg'>
              <div className='input-group'>
                <h5 className='col-12'>{`Kullanıcı Adınız: ${username}`}</h5>
              </div>
              <br></br>
              <div className='input-group show-hide-password'>
                <label className='col-12'>{t('pages.resetPassword.index.password')}</label>
                <IPInput
                  name='password'
                  className='form-control '
                  placeholder={t('pages.resetPassword.index.password')}
                  type='password'
                  value={values.password}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.password}
                  errors={errors.password}
                />
                <div className='input-group-append'>
                  <span className='input-group-text'>
                    <i className='icon-eye-off' aria-hidden='true'></i>
                  </span>
                </div>
              </div>
              <br />
              <div className='input-group show-hide-password'>
                <label className='col-12'>{t('pages.resetPassword.index.passwordRepeat')}</label>
                <IPInput
                  name='passwordRepeat'
                  className='form-control '
                  placeholder={t('pages.resetPassword.index.passwordRepeat')}
                  type='password'
                  value={values.passwordRepeat}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.passwordRepeat}
                  errors={errors.passwordRepeat}
                />
                <div className='input-group-append'>
                  <span className='input-group-text'>
                    <i className='icon-eye-off' aria-hidden='true'></i>
                  </span>
                </div>
              </div>
            </div>
            <div className='form-group mt-2'>
              <Button
                className='btn-md btn-fg btn-block'
                type='button'
                text={t('pages.resetPassword.index.reset')}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default ResetPassword
