import { IRequesterInfo } from "../../../../base/models"
import { ClientProxy } from "../../../../base/proxy/clientProxy"
import { CampaignBundleModel } from "../../../Admin/ProductOperation/CampaignBundleCrud/models/CampaignBundleModel"


export class BrandList extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'campaign/getBrandList',
      requesterInfo,
    })
  }

  public async getBrandList(): Promise<CampaignBundleModel> {
    return await this.getAsync<any>()
  }
}
