import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IOpenBox } from '../models'

export class GetOpenBoxByUserIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'openBox/GetOpenBoxByUserId',
      requesterInfo,
    })
  }

  public async getOpenBox(): Promise<IOpenBox[]> {
    return await this.getAsync<IOpenBox[]>()
  }
}
