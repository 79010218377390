import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { ICompany } from '../models/models'

export class GetCompanyApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/company/list',
      requesterInfo,
    })
  }

  public async getCompanies(): Promise<ICompany[]> {
    return await this.getAsync<ICompany[]>()
  }
}
