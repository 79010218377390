import { useEffect, useState } from 'react'
import './IPCheckBox.scss'

interface IPCheckbox extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id?: string
  label?: string
  labelClassName?: string
  onCheckedChange?: (checked: boolean) => void
  removeMarginBottom?: boolean
}

export default function CheckBox(props: IPCheckbox) {
  const { type, className, id, label, labelClassName, onCheckedChange, ...otherProps } = props
  const [checked, setChecked] = useState(otherProps.checked)

  useEffect(() => {
    setChecked(otherProps.checked ?? false)
  }, [otherProps.checked])

  return (
    <div className={`form-check`} style={{ paddingLeft: '0', marginBottom: props.removeMarginBottom ? '0px' : '' }}>
      <input
        type='checkbox'
        checked={checked}
        className={`form-check-input ${className ?? ''}`}
        id={otherProps.name ?? ''}
        onChange={e => {
          if (!props.disabled) {
            setChecked(e.target.checked)
            onCheckedChange && onCheckedChange(e.target.checked)
            otherProps.onChange && otherProps.onChange(e)
          }
        }}
        {...otherProps}
      />
      {label && (
        <label
          className={`form-check-label ${labelClassName ?? ''}`}
          htmlFor={otherProps.name ?? ''}
          onClick={() => {
            if (!props.disabled) {
              setChecked(!checked)
              onCheckedChange && onCheckedChange(!checked)
            }
          }}
        >
          {label}
        </label>
      )}
    </div>
  )
}
