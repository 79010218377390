import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IBIN, IBINHistory } from '../models/models'

export class GetBINHistoryApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/listHistoryById',
      requesterInfo,
    })
  }

  public async historyByID(id: number): Promise<IBINHistory[]> {
    return await this.postAsync<IBINHistory[]>(id)
  }
}
