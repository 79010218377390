import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'
import { IListXMLRequest, IListXMLResponse } from '../models/models'

export class GetXmlListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/getXmlList',
      requesterInfo,
    })
  }

  public async getXmlList(request: IListXMLRequest): Promise<IListXMLResponse[]> {
    return await this.postAsync<IListXMLResponse[]>(request)
  }
}
