import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ISubDealerFilter, ISubDealerList, IVirtualPosHistory } from '../models/models'

export class VirtualPosInfoHistoryApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/virtualPosInfos/listHistoryById',
      requesterInfo,
    })
  }

  public async historyByID(request: number): Promise<IVirtualPosHistory[]> {
    return await this.postAsync<IVirtualPosHistory[]>(request)
  }
}
