import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../base/customHooks'
import { IException } from '../../../../base/models'
import IPButton from '../../../../components/IPButton'
import { ISurveyReport } from './models'
import Chart from './PollListChart/ChartBar'
import Table from './PollListChart/Table'
import TableInput from './PollListChart/TableInput'
import TextAnswersView from './PollListChart/TextAnswersView'
import { GetSurveyById } from './services/getSurveyById'
import IPModal from '../../../../components/IPModal'
import { exportToXLSX } from '../../../../base/utils/dataHelper'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'

interface ISurveyReportState {
  surveyReport: ISurveyReport[]
}

function PollListChart(props: BaseComponentWithContextProps) {
  const getInitialState = (): ISurveyReportState => {
    return {
      surveyReport: [],
    }
  }
  const location = useLocation()
  const intialState = getInitialState()

  const [state, setState] = useCustomReducer<ISurveyReportState>(intialState)

  useEffect(() => {
    if (location.state as any) {
      const data = _.clone((location.state as any)?.surveyData)
      props.showLoading()
      const getSurvey = new GetSurveyById({})
      getSurvey
        .getSurveyResultById(data)
        .then((res: ISurveyReport[]) => {
          props.hideLoading()
          setState({ surveyReport: res })
        })
        .catch((err: IException) => {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            allowOutsideClick: true,
          })
        })
    }
  }, [location])

  const [showModal, setShowModal] = useState<any>()
  const [modalData, setData] = useState<any>()

  //#region Excel'e aktarma

  const excelButton = async () => {
    try {
      if (!state.surveyReport || state.surveyReport.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Excele atılacak veri bulunmamaktadır.',
          allowOutsideClick: true,
          backdrop: true,
        })
        return
      }

      const combinedData = state.surveyReport.flatMap((p, index) => {
        const answers = p.Answers || []
        let totalCount = 0

        const questionData = {
          Sıra: 'Soru ' + (index + 1),
          Soru: p.Text,
        }

        const answerData = answers.flatMap((modalRow, i) => {
          const answerCount = modalRow.IsInput ? 1 : modalRow.TotalCount || 0
          totalCount += answerCount

          return [
            {
              Sıra: '',
              Soru: '',
              'Cevap No': (i + 1).toString(),
              Cevaplar: modalRow.Options,
              'Cevaplanma Sayısı': answerCount,
              'Toplam Cevaplanma Sayısı': 0,
            },
          ]
        })

        answerData.push({
          Sıra: '',
          Soru: '',
          'Cevap No': '',
          Cevaplar: '',
          'Cevaplanma Sayısı': 0,
          'Toplam Cevaplanma Sayısı': totalCount,
        })

        return [questionData, ...answerData]
      })

      exportToXLSX(combinedData, 'Anket Sonuçları')
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }
  //#endregion

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Anket Sonuç Grafikleri</span>
            <div className='d-flex align-items-center' style={{ marginLeft: '55%' }}>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
            <Link to='/admin/site-operation/pollList'>
              <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
            </Link>
          </div>
          <div className='card-body'>
            <div className='container-wrap'>
              {state.surveyReport &&
                state.surveyReport.map((report: ISurveyReport, index) => {
                  return (
                    <div className='row' key={`${report.QuestionId}`} style={{ marginBottom: '3%' }}>
                      {index === 0 && (
                        <>
                          <h5 style={{ fontSize: 25, textAlign: 'center', backgroundColor: 'gray' }}>
                            <span style={{ marginRight: '3%' }}>Toplam Soru Sayısı: {report.QuestionCount}</span>
                            <span>
                              Katılan Bayi Sayısı:{' '}
                              {
                                <span
                                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer', marginRight: '3%' }}
                                  onClick={() => {
                                    setData(report.DealerList)
                                    setShowModal(true)
                                  }}
                                >
                                  {report.DealerCount}
                                </span>
                              }
                            </span>
                            <span>
                              Katılan Kullanıcı Sayısı:{' '}
                              {
                                <span
                                  style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
                                  onClick={() => {
                                    setData(report.UserList)
                                    setShowModal(true)
                                  }}
                                >
                                  {report.UserList.length}
                                </span>
                              }
                            </span>
                          </h5>
                        </>
                      )}
                      {
                        report.OptionType === 1 || report.OptionType === 2 ? (
                          <>
                            <h5 style={{ fontSize: 23 }}>{report.Text}</h5>
                            <div className='col-4'>
                              <Chart report={report} />
                            </div>
                            <div className='col-5'>
                              <Table report={report} />
                            </div>
                          </>
                        ) : (
                          report.OptionType === 4 || report.OptionType === 5 ? (
                            <>
                              <h5 style={{ fontSize: 23 }}>{report.Text}</h5>
                              <div className='col-4'>
                                <Chart report={report} />
                              </div>
                              <div className='col-5'>
                                <Table report={report} />
                              </div>
                              <div className='col-12'>
                                <TableInput report={report} />
                              </div>
                            </>
                          ) : (
                            <>
                              <h5 style={{ fontSize: 23 }}>{report.Text}</h5>
                              <div className='col-12'>
                                <TableInput report={report} />
                              </div>
                            </>
                          )
                        )
                      }



                      {/* <div className='col-7'>
                        <TextAnswersView report={report} />
                      </div> */}
                    </div>
                  )
                })}
            </div>
            <IPModal
              show={showModal}
              onClose={() => setShowModal(false)}
              hideConfirm={true}
              width={1000}
              title={'Katılan Kullanıcılar'}
            >
              {
                <table className='table'>
                  <thead>
                    <tr role='row' className='odd'>
                      <th>#</th>
                      <th>Bayi Kodu</th>
                      <th style={{ textAlign: 'left' }}>Bayi Adı</th>
                      <th style={{ textAlign: 'left' }}>Kullanıcı</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalData &&
                      modalData.length > 0 &&
                      modalData.map((his, index) => (
                        <tr role='row' className='odd'>
                          <td style={{ width: '10%' }}>{index + 1}</td>
                          <td style={{ width: '20%' }}>{his.DealerCode && his.DealerCode.replace('0000', '')}</td>
                          <td style={{ textAlign: 'left' }}>{his.DealerName}</td>
                          <td style={{ textAlign: 'left' }}>{his.Username}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              }
            </IPModal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(PollListChart)