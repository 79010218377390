import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { DealerChannelList, IDealerFilteredList } from '../models/getDealerChannelResponseModel'

export class DealerFilteredListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/campaignBundle/filterProgram',
      requesterInfo,
    })
  }

  public async dealerFilteredList(request: IDealerFilteredList): Promise<DealerChannelList[]> {
    return await this.postAsync<DealerChannelList[]>(request)
  }
}
