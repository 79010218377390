import { IHomePageProducts } from '../models/requestModels'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class GetHomePageProductsListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/homepageProduct/listAll',
      requesterInfo,
    })
  }

  public async getHomePageProducts(): Promise<IHomePageProducts[]> {
    return await this.getAsync<IHomePageProducts[]>()
  }
}
