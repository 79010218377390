import React, { useEffect, useRef, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './FrequentlyAskedQuestions.scss'
import IPInput from '../../../../../components/IPInput'
import '../components/TextEditor.scss'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import _ from 'lodash'
import { useCustomReducer } from '../../../../../base/customHooks'
import { IFrequentlyAskedQuestions } from '../models/models'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { PutFrequentlyAskedQuestionsApi } from '../services/putFrequentlyAskedQuestionsApi'
import { PostFrequentlyAskedQuestionsApi } from '../services/postFrequentlyAskedQuestionsApi'
import Image from '../../../../../components/IPImage/IPImage'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { getUploadFileRequest } from '../../../../../base/utils/dataHelper'
import classes from '../../../../../components/IPFile/IPFile.module.scss'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'

interface IFrequentlyAskedQuestionsState {
  frequentlyAskedQuestions?: IFrequentlyAskedQuestions[]
  question?: string,
  answer?: string,
  file?: any,
  place?: ISelectOption[]
  order: number
  status: boolean
}

function AddFrequentlyAskedQuestions(props: BaseComponentWithContextProps) {
  const routeLocation = useLocation()

  const locationsOptions = [
    { value: 'Finansal', label: 'Finansal Bilgiler' },
    { value: 'SSH', label: 'SSH' },
    { value: 'Pazar', label: 'Pazar' },
  ]

  const getInitialState = (): IFrequentlyAskedQuestionsState => {
    return {
      frequentlyAskedQuestions: [],
      order: 0,
      status: true
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IFrequentlyAskedQuestionsState>(intialState)

  let navigate = useNavigate()
  const inputEl: any = useRef(null)

  const addQuestion = async () => {
    props.showLoading()
    var req: any = {
      Id: (routeLocation.state as any) ? (routeLocation.state?.data.Id) : 0,
      Question: state.question,
      Answer: state.answer,
      Order: state.order,
      Place: state.place ? state.place.map(a => a.value) : undefined,
      Enabled: state.status,
      File: state.file?.length > 0 ? state.file[0]?.File : undefined,
    }

    if ((routeLocation.state as any)?.flag === 'update') {
      const update: PutFrequentlyAskedQuestionsApi = new PutFrequentlyAskedQuestionsApi({})
      await update
        .putFrequentlyAskedQuestions(req)
        .then(result => {
          if (result) {
            props.hideLoading()
            Swal.fire({
              icon: 'success',
              title: 'Soru başarıyla güncellendi.',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
          if (routeLocation?.state?.url)
            window.location.href = ((routeLocation?.state?.url))
          else
            navigate('/admin/settings/frequentlyAskedQuestions')
        }).catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
    else {
      const addQuestion: PostFrequentlyAskedQuestionsApi = new PostFrequentlyAskedQuestionsApi({})
      await addQuestion
        .postFrequentlyAskedQuestions(req)
        .then(result => {
          if (result) {
            props.hideLoading()
            Swal.fire({
              icon: 'success',
              title: 'Soru başarıyla eklendi.',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
          if (routeLocation?.state?.url)
            window.location.href = ((routeLocation?.state?.url))
          else
            navigate('/admin/settings/frequentlyAskedQuestions')
        }).catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }

  }

  const handleImageFileUpload = async (e: any) => {
    const fileList: any[] = await getUploadFileRequest(e)

    for (const fileData of fileList) {
      if (fileData.size > 20000000) {
        return Swal.fire('Hata', 'Her dosya 20 MB\'den küçük olmalıdır!', 'error');
      }

      const allowedTypes = ['.jpeg', '.png', '.gif', '.jpg', '.pdf'];
      if (!allowedTypes.includes(fileData.FileType)) {
        return Swal.fire('Hata', 'Her dosya jpeg, png, gif veya pdf formatında olmalıdır!', 'error');
      }
    }

    // Create the Files array from the fileList array
    const data = {
      Files: fileList.map((fileData, index) => ({
        Id: index,
        Label: '',
        Description: '',
        File: fileData,
      })),
    };

    setState({ file: data.Files })
  }

  useEffect(() => {
    if ((routeLocation.state as any)) {
      var routeData = routeLocation.state?.data
      setState({
        question: routeData.Question,
        answer: routeData.Answer,
        order: routeData.Order,
        status: routeData.Enabled,
        place: routeData.Place.split(';').map(place =>
          locationsOptions.find(option => option.value === place)
        ),
      })
    }
  }, [])


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              <h5>Soru Ekleme</h5>
            </span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='col-lg-6 d-flex justify-content-start'>
                  <Link to={(routeLocation.state?.url)
                    ? routeLocation.state.url : '/admin/settings/frequentlyAskedQuestions'}>
                    <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                  </Link>
                </div>
              </div>
              <div className='col-lg-6 d-flex justify-content-end'>
                <IPButton
                  type='button'
                  className='btn btn-success w-25 mb-5 float-right'
                  text={
                    (routeLocation.state as any)?.flag === 'update'
                      ? 'Güncelle'
                      : 'Ekle'
                  }
                  onClick={() => addQuestion()}
                />
              </div>
            </div>
            <div className='col-12 mt-5'>
              <div className='row mb-4'>
                <div className='col-12'>
                  <label className={`${styles.labelText}`}>Soru</label>
                  <IPInput
                    type='text'
                    id='Question'
                    className='form-control'
                    name='Question'
                    value={state.question}
                    placeholder='Soru'
                    onChange={e => setState({ question: e.target.value })}
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <div className='col-lg-12'>
                  <label className='labeltext'>Cevap</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={state.answer}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setState({ answer: data })
                    }}
                    onBlur={(event, editor) => { }}
                    onFocus={(event, editor) => { }}
                  />
                </div>
                <div className='row mt-4'>
                  <div className='col-lg-4'>
                    <label className='labeltext'>Yer</label>
                    <IPSelectBox
                      isMulti
                      isClearable
                      id='Types'
                      name='Types'
                      closeMenuOnSelect={false}
                      onChangeMulti={(options: ISelectOption[]) => {
                        setState({ place: options })
                      }}
                      options={
                        locationsOptions &&
                        locationsOptions.map(item => ({
                          label: item.label,
                          value: item.value,
                        }))
                      }
                      value={state.place}
                    />
                  </div>
                  <div className='col-lg-2'>
                    <label className='labeltext'>Sıra</label>
                    <IPInput
                      id='Order'
                      type='number'
                      name='Order'
                      onChange={(e) => {
                        setState({ order: Number(e.target.value) })
                      }}
                      value={state.order}
                    />
                  </div>
                  <div className='col-lg-2'>
                    <label className='labeltext'>Aktif</label>
                    <IPCheckbox
                      id='status'
                      name='status'
                      onChange={(e) => {
                        setState({ status: !state.status })
                      }}
                      checked={state.status}
                    />
                  </div>
                  <div className='col-lg-3 card-mobile-resize'>
                    <div className='col-md-5'>
                      <div>
                        <input
                          type='file'
                          onInput={(e) => {
                            handleImageFileUpload(e)
                          }}
                          multiple={false}
                        />
                      </div>
                      {
                        (routeLocation.state as any)?.flag === 'update'
                          ? (routeLocation.state as any)?.data.FileUrl : (state.file?.Label?.length > 0 && <p>{state.file.Label}</p>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(AddFrequentlyAskedQuestions)
