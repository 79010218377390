import { css } from '@emotion/css'
import { useState } from 'react'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { IException } from '../../../../base/models'
import { downloadFileFromUrl, getDateByTimezoneOffset, printElement } from '../../../../base/utils/dataHelper'
import IPButton from '../../../../components/IPButton'
import IPExcelButton from '../../../../components/IPExcelButton'
import IPInput from '../../../../components/IPInput'
import IPModal from '../../../../components/IPModal'
import InputCampaign from '../../../../components/InputCampaign'
import { GetSerialNumberCheck } from '../../MyOrders/models/request/getMyOrderRequest'
import { GetSerialNumberCheckResponse } from '../../MyOrders/models/response/getOrderListResponseModel'
import { GetCargoTrackingApi } from '../../MyOrders/services/getCargoTracking'
import { InvoiceDetails } from '../OpenInvoices/models/getInvoiceDetail'
import { GetInvoicesDetailsApi } from '../OpenInvoices/services/getInvoiceDetails'
import './EInvoice.scss'
import { EInvoicesRequest, IData } from './models/eInvioices'
import { ElectronicInvoicesToExcelApi } from './services/electronicInvoicesToExcelApi'
import { GetElectronicInvoicesApi } from './services/getElectronicInvoices'
import { SeriNoListToExcelApi } from './services/seriNoListToExcelApi'

interface IStates {
  selectedCompany: string | null
  filterData: any
  checkFilter: boolean
  einvoiceNo: string
  einvoiceDetails: InvoiceDetails[]
  showSerialNumbers: boolean
  serialNumbers: GetSerialNumberCheckResponse[]
  companyCode: string
  invoiceNumber: string
  fatNo: string
}

function EInvoice(props2: BaseComponentWithContextProps) {
  const getInitialState = (): IStates => {
    return {
      selectedCompany: null,
      filterData: [],
      checkFilter: false,
      einvoiceNo: '',
      einvoiceDetails: [],
      showSerialNumbers: false,
      serialNumbers: [],
      companyCode: '',
      invoiceNumber: '',
      fatNo: '',
    }
  }

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const toDateTimeLocal = date => {
    if (date != null) {
      return date
        .toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(' ', 'T')
    }
    return undefined
  }

  // const getDate = async (data: any, title: string) => {
  //   if (title === "Start")
  //     setStartDate(data)
  //   else
  //     setEndDate(data)
  // }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IStates>(intialState)

  const initialValues = Object.assign({
    DealerCode: '',
    StartDate: startDate,
    Enddate: endDate,
    FaturaNo: state.fatNo,
  })

  const getInvoiceDetails = async (einvoice: string) => {
    const orderDetails = Object.assign({
      DealerCode: '',
      InvoiceNo: '',
      E_InvoiceNo: einvoice,
    })
    const getInvoiceDetailApi: GetInvoicesDetailsApi = new GetInvoicesDetailsApi({})
    getInvoiceDetailApi
      .getInvoiceDetail(orderDetails)
      .then((result: InvoiceDetails[]) => {
        setState({ einvoiceNo: einvoice, einvoiceDetails: result })
      })
      .catch(error => console.log(error))
  }

  const exportToExcel = () => {
    const req: EInvoicesRequest = {
      DealerCode: '',
      StartDate: startDate ? getDateByTimezoneOffset(startDate) : undefined,
      EndDate: endDate ? getDateByTimezoneOffset(endDate) : undefined,
    }
    const electronicInvoicesToExcelApi: ElectronicInvoicesToExcelApi = new ElectronicInvoicesToExcelApi({})
    electronicInvoicesToExcelApi
      .electronicInvoicesToExcel(req)
      .then((res: any) => {
        downloadFileFromUrl('Elektronik Faturalar', res.FileUrl)
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const [eInvoices, setEInvoices] = useState<EInvoicesResponse[]>([])
  const [eInvoicesFilter, setFilterEInvoices] = useState<EInvoicesResponse[]>([])

  const getEInvoices = async () => {
    if ((startDate && !endDate) || (endDate && !startDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Başlangıç ve bitiş tarihleri aynı anda seçilmelidir.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    props2.showLoading()

    try {
      const getEInvoices: GetElectronicInvoicesApi = new GetElectronicInvoicesApi({})

      const res = await getEInvoices.getElectronicInvoices(initialValues)
      if (res) {
        setEInvoices(res)
        setFilterEInvoices(res)
      }
    } catch {
    }
    props2.hideLoading()

  }

  const getSerialNumberCheck = async (req: GetSerialNumberCheck) => {
    // props.showLoading()
    try {
      const res = await new GetCargoTrackingApi({}).GetSerialNumberCheck(req)
      // props.hideLoading()
      setState({
        showSerialNumbers: true,
        serialNumbers: res.map(x => ({ ...x, Date: new Date(x.Date) })),
        companyCode: req.CompanyCode,
        invoiceNumber: req.InvoiceNumber,
      })
    } catch (err: any) {
      // props.hideLoading()
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const seriNoListToExcel = async () => {
    const req: GetSerialNumberCheck = {
      CompanyCode: state.companyCode,
      InvoiceNumber: state.invoiceNumber,
    }
    try {
      const res = await new SeriNoListToExcelApi({}).seriNoListToExcel(req)
      downloadFileFromUrl('Seri No Listesi', res.FileUrl)
    } catch (err: any) {
      // props.hideLoading()
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  interface EInvoicesResponse {
    COMPANY_CODE: string
    Data: IData[]
  }

  return (
    <div className='container-fluid'>
      <div className='heading-text'>
        <h4 style={{ textAlign: 'center' }}>Elektronik Faturalar</h4>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='Reports'>
            <form>
              <div className='form-row justify-content-center'>
                <div className='form-group col-md-3'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>

                <div className='form-group col-md-3'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
              </div>
            </form>
            <div className='myOrderActions m-t-25 m-b-15 justify-content-center'>
              <IPButton
                text='Listele'
                className='button'
                onClick={() => getEInvoices()}
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '10px',
                  width: '150px',
                  height: '50px',
                }}
              />
              <IPExcelButton onClick={exportToExcel} />
            </div>
            <div className='myOrderActions d-flex justify-content-center mt-3'>
              <IPInput
                type='text'
                value={state.fatNo}
                placeholder='Fatura No / E-Fatura No / İrsaliye No'
                onChange={e => {
                  setState({ fatNo: e.target.value })
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {eInvoices && eInvoices.length === 0 ? (
        <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
      ) : (
        <>
          <div className='buttonContainer'>
            <IPButton
              text='Hepsi'
              className={`invoiceBtn ${state.selectedCompany === null ? 'active' : ''}`}
              onClick={() => {
                setState({ selectedCompany: null })
              }}
            />
            <IPButton
              text='Index'
              className={`invoiceBtn ${state.selectedCompany === '1000' ? 'active' : ''}`}
              onClick={() => {
                setState({ selectedCompany: '1000' })
              }}
            />
            <IPButton
              text='Netex'
              className={`invoiceBtn ${state.selectedCompany === '2300' ? 'active' : ''}`}
              onClick={() => setState({ selectedCompany: '2300' })}
            />
            <IPButton
              text='Datagate'
              className={`invoiceBtn ${state.selectedCompany === '1100' ? 'active' : ''}`}
              onClick={() => setState({ selectedCompany: '1100' })}
            />
            <IPButton
              text='Despec'
              className={`invoiceBtn ${state.selectedCompany === '1200' ? 'active' : ''}`}
              onClick={() => setState({ selectedCompany: '1200' })}
            />

            <div className='buttonBorder'></div>
          </div>
          {eInvoices && eInvoices.length === 0 ? (
            <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
          ) : state.selectedCompany === null ? (
            <div className='row'>
              <div className='col-12' id='allTable'>
                <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                  {/* state.dummyData?.filter((item) => { */}
                  {/* // 	let filter
								// 	if (state.selectedCompany === null)
								// 		filter = item.date >= state.startDate && item.date <= state.endDate
								// 	else filter = item.date >= state.startDate && item.date <= state.endDate && item.company === state.selectedCompany
								// 	if (filter.length > 0) setState({ checkFilter: true })
								// 	state.checkFilter = true
								// 	return filter
								// }).length > 0 ? */}

                  <thead>
                    <tr className='EInvoicetableDescription'>
                      <td scope='col'>Fatura Şirketi</td>
                      <td scope='col'>Elektronik Fatura</td>
                      <td scope='col'>Fatura No</td>
                      <td scope='col'>Mali Yıl</td>
                      <td scope='col'>Tarih</td>
                      <td scope='col' style={{ width: '155px' }}>
                        Dosya Yolu
                      </td>
                      <td scope='col'>Seri No</td>
                    </tr>
                  </thead>
                  {/* :
								 <div className='text-center mt-5'>Seçtiğiniz kriterlerde fatura bulunmamaktadır.</div> */}
                  {eInvoices &&
                    eInvoices.map((item: EInvoicesResponse) => (
                      <tbody>
                        {item.Data &&
                          item.Data.map((i: IData, index) => (
                            <tr key={index}>
                              <td>
                                {/* <Link to={`/myspecial/EInvoice?eInvoiceId=${i.SENT_ID}`} target="_blank"> */}
                                {i.COMPANY_CODE === '1000'
                                  ? 'IDX'
                                  : i.COMPANY_CODE === '1200'
                                    ? 'DPC'
                                    : i.COMPANY_CODE === '1100'
                                      ? 'DGX'
                                      : 'NTX'}
                                {/* </Link> */}
                              </td>
                              <td>
                                <a href={`/myspecial/EInvoice?eInvoiceId=${i.SENT_ID}`} target='_blank'>
                                  {i.SENT_ID}
                                </a>
                              </td>
                              <td>
                                {/* <Link to={`/myspecial/EInvoice?eInvoiceId=${i.SENT_ID}`} target="_blank"> */}
                                {i.INVOICE_NO}
                                {/* </Link> */}
                              </td>
                              <td>
                                {/* <Link to={`/myspecial/EInvoice?eInvoiceId=${i.SENT_ID}`} target="_blank"> */}
                                {i.THE_YEAR}
                                {/* </Link> */}
                              </td>
                              <td>
                                {/* <Link to={`/myspecial/EInvoice?eInvoiceId=${i.SENT_ID}`} target="_blank"> */}
                                {i.DATE.split('', 10)}
                                {/* </Link> */}
                              </td>
                              <td>{i.FILE_NAME}</td>
                              <td>
                                <a
                                  onClick={() =>
                                    getSerialNumberCheck({
                                      CompanyCode: i.COMPANY_CODE,
                                      InvoiceNumber: i.INVOICE_NO,
                                    })
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  <img src='/images/my-orders/my-orders.svg' alt='' />
                                  <div>
                                    <div className='itemName'>Seri No Kontrolü</div>
                                    <div className='itemValue'></div>
                                  </div>
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    ))}
                </table>
              </div>
            </div>
          ) : (
            ''
          )}
          {eInvoices && eInvoices.length === 0 ? (
            <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
          ) : state.selectedCompany === '1000' ? (
            eInvoices.findIndex(list => {
              return state.selectedCompany === list.COMPANY_CODE
            }) === -1 ? (
              <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
            ) : (
              <div className='row'>
                <div className='col-12' id='allTable'>
                  <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                    <thead>
                      <tr className='EInvoicetableDescription'>
                        <td scope='col'>Fatura Şirketi</td>
                        <td scope='col'>Elektronik Fatura</td>
                        <td scope='col'>Fatura No</td>
                        <td scope='col'>Mali Yıl</td>
                        <td scope='col'>Tarih</td>
                        <td scope='col' style={{ width: '155px' }}>
                          Dosya Yolu
                        </td>
                        <td scope='col'>Seri No</td>
                      </tr>
                    </thead>
                    {eInvoices
                      .filter(list => {
                        return state.selectedCompany === list.COMPANY_CODE
                      })
                      .map((item: EInvoicesResponse) => (
                        <tbody>
                          {item.Data &&
                            item.Data.map((i: IData, index) => (
                              <tr key={index}>
                                <td>{i.COMPANY_CODE === '1000' ? 'IDX' : ''}</td>
                                <td>
                                  <a href={`/myspecial/EInvoice?eInvoiceId=${i.SENT_ID}`} target='_blank'>
                                    {i.SENT_ID}
                                  </a>
                                </td>
                                <td>{i.INVOICE_NO}</td>
                                <td>{i.THE_YEAR}</td>
                                <td>{i.DATE.split('', 10)}</td>
                                <td>{i.FILE_NAME}</td>
                                <td>
                                  <a
                                    onClick={() =>
                                      getSerialNumberCheck({
                                        CompanyCode: '1000',
                                        InvoiceNumber: i.INVOICE_NO,
                                      })
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <img src='/images/my-orders/my-orders.svg' alt='' />
                                    <div>
                                      <div className='itemName'>Seri No Kontrolü</div>
                                      <div className='itemValue'></div>
                                    </div>
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ))}
                  </table>
                </div>
              </div>
            )
          ) : (
            ''
          )}
          {eInvoices && eInvoices.length === 0 ? (
            <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
          ) : state.selectedCompany === '1100' ? (
            eInvoices.findIndex(list => {
              return state.selectedCompany === list.COMPANY_CODE
            }) === -1 ? (
              <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
            ) : (
              <div className='row'>
                <div className='col-12' id='allTable'>
                  <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                    <thead>
                      <tr className='EInvoicetableDescription'>
                        <td scope='col'>Fatura Şirketi</td>
                        <td scope='col'>Elektronik Fatura</td>
                        <td scope='col'>Fatura No</td>
                        <td scope='col'>Mali Yıl</td>
                        <td scope='col'>Tarih</td>
                        <td scope='col' style={{ width: '155px' }}>
                          Dosya Yolu
                        </td>
                        <td scope='col'>Seri No</td>
                      </tr>
                    </thead>
                    {eInvoices
                      .filter(list => {
                        return state.selectedCompany === list.COMPANY_CODE
                      })
                      .map((item: EInvoicesResponse) => (
                        <tbody>
                          {item.Data &&
                            item.Data.map((i: IData, index) => (
                              <tr key={index}>
                                <td>{i.COMPANY_CODE === '1100' ? 'DGX' : ''}</td>
                                <td>
                                  <a href={`/myspecial/EInvoice?eInvoiceId=${i.SENT_ID}`} target='_blank'>
                                    {i.SENT_ID}
                                  </a>
                                </td>
                                <td>{i.INVOICE_NO}</td>
                                <td>{i.THE_YEAR}</td>
                                <td>{i.DATE.split('', 10)}</td>
                                <td>{i.FILE_NAME}</td>
                                <td>
                                  <a
                                    onClick={() =>
                                      getSerialNumberCheck({
                                        CompanyCode: '1100',
                                        InvoiceNumber: i.INVOICE_NO,
                                      })
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <img src='/images/my-orders/my-orders.svg' alt='' />
                                    <div>
                                      <div className='itemName'>Seri No Kontrolü</div>
                                      <div className='itemValue'></div>
                                    </div>
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ))}
                  </table>
                </div>
              </div>
            )
          ) : (
            ''
          )}
          {eInvoices && eInvoices.length === 0 ? (
            <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
          ) : state.selectedCompany === '1200' ? (
            eInvoices.findIndex(list => {
              return state.selectedCompany === list.COMPANY_CODE
            }) === -1 ? (
              <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
            ) : (
              <div className='row'>
                <div className='col-12' id='allTable'>
                  <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                    <thead>
                      <tr className='EInvoicetableDescription'>
                        <td scope='col'>Fatura Şirketi</td>
                        <td scope='col'>Elektronik Fatura</td>
                        <td scope='col'>Fatura No</td>
                        <td scope='col'>Mali Yıl</td>
                        <td scope='col'>Tarih</td>
                        <td scope='col' style={{ width: '155px' }}>
                          Dosya Yolu
                        </td>
                        <td scope='col'>Seri No</td>
                      </tr>
                    </thead>
                    {eInvoices
                      .filter(list => {
                        return state.selectedCompany === list.COMPANY_CODE
                      })
                      .map((item: EInvoicesResponse) => (
                        <tbody>
                          {item.Data &&
                            item.Data.map((i: IData, index) => (
                              <tr key={index}>
                                <td>{i.COMPANY_CODE === '1200' ? 'DPC' : ''}</td>
                                <td>
                                  <a href={`/myspecial/EInvoice?eInvoiceId=${i.SENT_ID}`} target='_blank'>
                                    {i.SENT_ID}
                                  </a>
                                </td>
                                <td>{i.INVOICE_NO}</td>
                                <td>{i.THE_YEAR}</td>
                                <td>{i.DATE.split('', 10)}</td>
                                <td>{i.FILE_NAME}</td>
                                <td>
                                  <a
                                    onClick={() =>
                                      getSerialNumberCheck({
                                        CompanyCode: '1200',
                                        InvoiceNumber: i.INVOICE_NO,
                                      })
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <img src='/images/my-orders/my-orders.svg' alt='' />
                                    <div>
                                      <div className='itemName'>Seri No Kontrolü</div>
                                      <div className='itemValue'></div>
                                    </div>
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ))}
                  </table>
                </div>
              </div>
            )
          ) : (
            ''
          )}
          {eInvoices && eInvoices.length === 0 ? (
            <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
          ) : state.selectedCompany === '2300' ? (
            eInvoices.findIndex(list => {
              return state.selectedCompany === list.COMPANY_CODE
            }) === -1 ? (
              <p style={{ textAlign: 'center' }}>Veri Bulunamadı</p>
            ) : (
              <div className='row'>
                <div className='col-12' id='allTable'>
                  <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                    <thead>
                      <tr className='EInvoicetableDescription'>
                        <td scope='col'>Fatura Şirketi</td>
                        <td scope='col'>Elektronik Fatura</td>
                        <td scope='col'>Fatura No</td>
                        <td scope='col'>Mali Yıl</td>
                        <td scope='col'>Tarih</td>
                        <td scope='col' style={{ width: '155px' }}>
                          Dosya Yolu
                        </td>
                        <td scope='col'>Seri No</td>
                      </tr>
                    </thead>
                    {eInvoices
                      .filter(list => {
                        return state.selectedCompany === list.COMPANY_CODE
                      })
                      .map((item: EInvoicesResponse) => (
                        <tbody>
                          {item.Data &&
                            item.Data.map((i: IData, index) => (
                              <tr key={index}>
                                <td>{i.COMPANY_CODE === '2300' ? 'NTX' : ''}</td>
                                <td>
                                  <a href={`/myspecial/EInvoice?eInvoiceId=${i.SENT_ID}`} target='_blank'>
                                    {i.SENT_ID}
                                  </a>
                                </td>
                                <td>{i.INVOICE_NO}</td>
                                <td>{i.THE_YEAR}</td>
                                <td>{i.DATE.split('', 10)}</td>
                                <td>{i.FILE_NAME}</td>
                                <td>
                                  <a
                                    onClick={() =>
                                      getSerialNumberCheck({
                                        CompanyCode: '2300',
                                        InvoiceNumber: i.INVOICE_NO,
                                      })
                                    }
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <img src='/images/my-orders/my-orders.svg' alt='' />
                                    <div>
                                      <div className='itemName'>Seri No Kontrolü</div>
                                      <div className='itemValue'></div>
                                    </div>
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ))}
                  </table>
                </div>
              </div>
            )
          ) : (
            ''
          )}
          {eInvoices && eInvoices.length === 0 ? (
            ''
          ) : eInvoices.findIndex(list => {
            return state.selectedCompany === list.COMPANY_CODE
          }) === -1 ? (
            ''
          ) : (
            <div className='row'>
              <div className='form-group col-md-4'></div>
              <div className='form-group col-md-4'>
                <IPButton
                  type='button'
                  className='btn btn-danger button-excel w-sm-100'
                  text='Yazdır'
                  onClick={() => {
                    printElement(document.getElementById('allTable'))
                  }}
                  style={{ width: '200px', float: 'right' }}
                />
              </div>
            </div>
          )}
        </>
      )}
      {/* <IPModal width={'auto'} show={modal} hideConfirm={true} onClose={closeDocumentModal} closeOnEscapeKey={true}>
        <div className={classes.documentModal}>
          {state.einvoiceDetails.length === 0 ? (
            <h4>Fatura detayı bulunmamaktadır! </h4>
          ) : (
            <>
              <h4>Fatura Detayı</h4>
              {state.einvoiceDetails.map((item: InvoiceDetails) => (
                <>
                  <div
                    style={{
                      border: '1px solid #000000',
                      backgroundColor: 'white',
                      padding: '20px',
                    }}
                  >
                    <div className='row'>
                      <div className='col'>
                        <h6>
                          <b>Fatura No: </b>
                          {item.Data.InvoiceNo}
                        </h6>
                        <h6>
                          <b>Tarih: </b>
                          {item.Data.Date.split('', 10)}
                        </h6>
                        <h6>
                          <b>Kur: </b>
                          {item.Data.Currency === 1 ? 'TL' : item.Data.Currency === 2 ? 'USD' : 'EUR'}{' '}
                        </h6>
                      </div>

                      <div className='col'>
                        <h6>
                          <b>Vade Tarihi:</b>
                          {item.Data.ExpiryDate.split('', 10)}{' '}
                        </h6>
                        <h6>
                          <b>Sevkiyat Tarihi:</b>
                          {item.Data.ShippmentDate.split('', 10)}{' '}
                        </h6>
                        <h6>
                          <b>Kur Tipi: </b> {item.Data.CurrencyType}
                        </h6>
                      </div>
                      <div className='col'>
                        <h6>
                          <b>Müşteri Temsilcisi: </b>
                          {item.Data.CustomerRepresenter}{' '}
                        </h6>
                        <h6>
                          <b>Ödeme Şekli: </b>
                          {item.Data.ShippmentType}{' '}
                        </h6>
                        <h6>
                          <b>Adres: </b>
                          {item.Data.Adres}{' '}
                        </h6>
                      </div>
                    </div>
                  </div>

                  <br></br>

                  <>
                    <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                      <thead>
                        <tr className='EInvoicetableDescription'>
                          <td scope='col'>Ürün Kodu</td>
                          <td scope='col'>Açıklama</td>
                          <td scope='col'>Adet</td>
                          <td scope='col'>Birim Fiyat</td>
                          <td scope='col'>KDV Tutarı</td>
                          <td scope='col'>Tutar</td>
                        </tr>
                      </thead>
                      {item.Data.Contents.map((invoice: IContents) => (
                        <tbody>
                          <td>{invoice.UrunKodu}</td>
                          <td>{invoice.UrunAciklama}</td>
                          <td>{invoice.Adet}</td>
                          <td>{invoice.BPB_AdetTutar}</td>
                          <td>{invoice.BPB_KalemToplam}</td>
                          <td>{invoice.KalemToplamTRY}</td>
                        </tbody>
                      ))}
                    </table>
                    <div className='row'>
                      <div className='col'></div>
                      <div className='col'></div>
                      <div className='col'>
                        <h6>
                          <b>Toplam</b>
                          {formatPrice(item.Data.ToplamKDV_TRY)}
                        </h6>
                        <h6>
                          <b>KDV</b> {formatPrice(item.Data.PBP_ToplamKDV)}
                        </h6>
                        <h6>
                          <b>G.Toplam</b>
                          {formatPrice(item.Data.ToplamKDV_TRY + item.Data.PBP_ToplamKDV)}
                        </h6>
                      </div>
                    </div>
                  </>
                </>
              ))}
            </>
          )}
        </div>
      </IPModal> */}
      <IPModal
        show={state.showSerialNumbers}
        onClose={() => setState({ showSerialNumbers: false })}
        onConfirm={() => seriNoListToExcel()}
        confirmText='Excele At'
        maxWidth={1200}
      >
        <>
          <h4>Seri No Kontrolü</h4>

          {state.serialNumbers.length === 0 && 'Kayıt bulunamadı.'}
          {state.serialNumbers.length > 0 && (
            <div
              className={`table-responsive ${css`
                tr td,
                tr th {
                  padding: 0.5rem !important;
                }
              `}`}
            >
              <table className='table table-striped' style={{ minWidth: 860 }}>
                <thead>
                  <tr className='thead-dark'>
                    <th scope='col'>Seri No</th>
                    <th scope='col'>Ürün Kodu</th>
                    <th scope='col'>Marka</th>
                    <th scope='col'>Adet</th>
                    <th scope='col'>IDX Fatura No</th>
                    <th scope='col'>Fatura No</th>
                    <th scope='col'>Tarih</th>
                    <th scope='col'>Birim Tutar</th>
                    <th scope='col'>Kur</th>
                  </tr>
                </thead>
                <tbody>
                  {state.serialNumbers.map(x => (
                    <tr>
                      <th>{x.SeriNo}</th>
                      <td>{x.ProductCode}</td>
                      <td>{x.Marka}</td>
                      <td>1</td>
                      <td>{x.EInvoiceNumber}</td>
                      <td>{x.InvoiceNumber}</td>
                      <td>{x.Date.toLocaleDateString()}</td>
                      <td>
                        {x.NETWRBR.toFixed(2)} {x.CurrencyType}
                      </td>
                      <td>{x.Currency.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      </IPModal>
    </div>
  )
}
export default componentWithContext(EInvoice)
