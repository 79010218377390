import React, { useEffect } from 'react'
import AppRoutes from './routes'
import { useAppDispatch } from './redux/app/hooks'
import { getFreshBasketRedux } from './redux/slices/basket/basketSlice'
import { setUser } from './redux/slices/user/userSlice'
import { changeTheme } from './redux/slices/theme/themeSlice'
import { ClientStorage } from './base/storage'
import { ConfigHelper } from './base/constants'
import { setSearchHistory } from './redux/slices/searchHistory/searchHistorySlice'
import { getFavoriteProductListRedux } from './redux/slices/favoriteProduct/favoriteProductSlice'
import { getThemeFromStorage } from './base/utils/dataHelper'
import './default.scss'

function App() {
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!ClientStorage.hasItem(ConfigHelper.INDEXPAZAR_USER_DATA)) {
      return
    }
    if (`${window.location.pathname}` === "/xml/xml-request") {
      return
    }

    if (`${window.location.pathname}` != "/AppleNetChannel") {
      dispatch(getFreshBasketRedux())
      dispatch(getFavoriteProductListRedux())
    }

    dispatch(setUser())
    // dispatch(changeTheme(getThemeFromStorage()));
    dispatch(setSearchHistory(''))
  }, [])

  return (
    <div>
      <AppRoutes />
    </div>
  )
}

export default App
