import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IProduct } from '../../../pages/ProductList/models/response/getProductListResponseModel'

export interface GetFavouriteProductListRequestModel {
  Type: string
  UserId?: number
}

export interface GetFavouriteProductListResponseModel {
  Id: number
  Type: string
  ProductId: number
  UserId: number
  Product: IProduct
}

export interface GetFavouriteProductListRequestModel {
  Type: string
  UserId?: number
}

export interface GetFavouriteProductListResponseModel {
  Id: number
  Type: string
  ProductId: number
  UserId: number
  Product: IProduct
}

export interface IAddFavoriteRequest {
  Id?: number
  Type: string
  ProductId: number
}

export class GetFavouriteProductListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'userProductList/favourite',
      requesterInfo,
    })
  }

  public async getFavouriteProductList(): Promise<IProduct[]> {
    return await this.postAsync<IProduct[]>()
  }
}

export class AddToFavouriteProductListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'userProductList/favourite',
      requesterInfo,
    })
  }

  public async addFavorite(productId: number): Promise<number[]> {
    this.props.url = `${this.props.url}/${productId}`
    return await this.postAsync<number[]>()
  }
}

export class RemoveFavoriteApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'userProductList/favourite',
      requesterInfo,
    })
  }

  public async removeFavorite(productId: number): Promise<number[]> {
    this.props.url = `${this.props.url}/${productId}`
    return await this.postAsync<number[]>()
  }
}
