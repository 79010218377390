import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ISession } from '../models/models'

export class GetSessionApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/user/openSessionList',
      requesterInfo,
    })
  }

  public async getSession(): Promise<ISession[]> {
    return await this.getAsync<ISession[]>()
  }
}
