import React from 'react'
import Item from './Item/Item'
import Header from './Header'
import Sidebar from './Sidebar'
import CustomCard from './Components/CustomCard'
import { NavLink } from 'react-router-dom'

function SSHLayout(props: any) {
  return (
    <div className='container'>
      <div className='row justify-content-start justify-content-lg-end' >
        <div className='col-lg-3'
        style={{display:"flex"}}>
          <img src="/images/telefon.png" alt="" className="m-r-10" style={{width:"max-content",height:"max-content"}}></img>
          <span style={{ color: 'rgb(97, 97, 97)' }}>0850 200 28 88</span>
        </div>
        <div className='col-lg-3'
          style={{ display: "flex" }}>
          <img src="/images/fax.png" alt="" className="m-r-10" style={{ width: "max-content", height: "max-content" }}></img>
          <span style={{ color: 'rgb(97, 97, 97)' }}>0262 658 28 06</span>
        </div>
        <div className='col-lg-3'
          style={{ display: "flex" }}>
          <NavLink to='/myspecial/ssh/SSHContactForm'>
            <img src="/images/iletisimformu.png" alt="" className="m-r-10" style={{ width: "max-content", height: "max-content" }}></img>
            <span style={{ color: 'rgb(97, 97, 97)' }}>İletişim Formu</span>
          </NavLink>
           
          
        </div>
      </div>
      <br></br>
      <div className='card d-none d-lg-block'></div>
      <br></br>
    </div>
  )
}

export default SSHLayout
