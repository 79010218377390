import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IUser, AddUserRequest } from '../models/models'

export class UpdateUserApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealerInformation/updateUser',
      requesterInfo,
    })
  }

  public async putUser(request: AddUserRequest): Promise<IUser> {
    return await this.putAsync<IUser>(undefined, request)
  }
}
