import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPTextarea from '../../../../../components/IPTextarea'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks'
import Swal from 'sweetalert2'
import { CheckDealerCodeApi } from '../services/checkDealerCodeApi'
import { AddUpdateXmlApi } from '../services/addUpdateXmlApi'
import { IXmlDealer } from '../models/models'
import { IDetailModels } from '../models/models'
import _ from 'lodash'
import { IApproveRejectXmlDemandRequest } from '../../XmlStateListing/models/models'
import { ApproveRejectXmlDemandApi } from '../../XmlStateListing/services/approveRejectXmlDemandAdminApi'
import { GetProductCodeApi } from '../services/getProductCodeApi'
import { ProductSubTypeListApi } from '../services/productSubTypeListApi'
import { GetBrandListWithIdApi } from '../services/getBrandListWithIdApi'
import { GetCategoryListApi } from '../services/getCategoryListApi'
import { GetDealerExceptionalsApi } from '../services/getDealerExceptionalsApi'

interface IXmlDealerState {
  dealerCode: string
  taxNumber: string
  email: string
  idx: IDetailModels
  ntx: IDetailModels
  dpc: IDetailModels
  // grp: IDetailModels
  dgx: IDetailModels
  notes: string
  detailId: number
  id: number
  update: boolean
  arpCode: string
  approve: boolean
  approveId: number
  productCodeIncluded: ISelectOption[]
  productCodeExcluded: ISelectOption[]
  quotaIncluded: ISelectOption[]
  quotaExcluded: ISelectOption[]
  productCodeData: ISelectOption[]
  quotaData: ISelectOption[]
  subTypeData: ISelectOption[]
  subTypeIncluded: ISelectOption[]
  subTypeExcluded: ISelectOption[]
  brandData: ISelectOption[]
  brandIncluded: ISelectOption[]
  brandExcluded: ISelectOption[]
  categoryData: ISelectOption[]
  categoryIncluded: ISelectOption[]
  categoryExcluded: ISelectOption[]
  XmlAktive: boolean
}

function XmlDealer(props: BaseComponentWithContextProps) {

  const getInitialState = (): IXmlDealerState => {
    return {
      dealerCode: "",
      taxNumber: "",
      email: "",
      idx: {
        DetailId: 0,
        CmpCode: "IDX",
        active: false,
        NeverExpire: false
      },
      ntx: {
        DetailId: 0,
        CmpCode: "NTX",
        active: false,
        NeverExpire: false
      },
      dpc: {
        DetailId: 0,
        CmpCode: "DPC",
        active: false,
        NeverExpire: false
      },
      // grp: {
      //   DetailId: 0,
      //   CmpCode: "GRP",
      //   active: false
      // },
      dgx: {
        DetailId: 0,
        CmpCode: "DGX",
        active: false,
        NeverExpire: false
      },
      notes: "",
      detailId: 0,
      id: 0,
      update: false,
      arpCode: "",
      approve: false,
      approveId: 0,
      productCodeIncluded: [],
      productCodeExcluded: [],
      quotaIncluded: [],
      quotaExcluded: [],
      productCodeData: [],
      quotaData: [],
      subTypeData: [],
      subTypeIncluded: [],
      subTypeExcluded: [],
      brandData: [],
      brandIncluded: [],
      brandExcluded: [],
      categoryData: [],
      categoryIncluded: [],
      categoryExcluded: [],
      XmlAktive: false
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IXmlDealerState>(intialState)
  const [staticOption, setStaticOption] = useState<ISelectOption[]>([])

  const checkDealerCode = async () => {
    if (!state.dealerCode || state.dealerCode.length === 0) {
      Swal.fire({
        icon: 'error',
        title: "Cari Kodu Giriniz",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const checkDealerCodeApi: CheckDealerCodeApi = new CheckDealerCodeApi({})

    await checkDealerCodeApi
      .checkDealerCode(state.dealerCode.length > 5 ? state.dealerCode.padStart(10, "0") : state.dealerCode)
      .then(async result => {
        if (result) {
          setState({ taxNumber: result })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const getDealerExceptionals = async () => {
    const data: any[] = _.clone((routeLocation.state as any)?.xmlData)
    const staticIp = data[0] && data[0].StaticIp && data[0].StaticIp.split("/");
    const ip: ISelectOption[] = [];
    staticIp && staticIp.map((statIp) => {
      return ip.push({ value: statIp, label: statIp })
    })
    let idx: IDetailModels = {
      DetailId: 0,
      CmpCode: 'IDX',
      active: false,
      NeverExpire: false
    }
    let ntx: IDetailModels = {
      DetailId: 0,
      CmpCode: 'NTX',
      active: false,
      NeverExpire: false
    }
    let dpc: IDetailModels = {
      DetailId: 0,
      CmpCode: 'DPC',
      active: false,
      NeverExpire: false
    }
    // let grp: IDetailModels = {
    //   DetailId: 0,
    //   CmpCode: 'GRP',
    //   active: false
    // }
    let dgx: IDetailModels = {
      DetailId: 0,
      CmpCode: 'DGX',
      active: false,
      NeverExpire: false
    }
    data && data.length > 0 && data.map((dt) => {
      if (dt.CmpCode === "IDX" || dt.CmpCode === "ID_") {
        idx = {
          DetailId: dt.DetailId,
          CmpCode: 'IDX',
          active: dt.CmpCode === "IDX",
          NeverExpire: dt.NeverExpire
        }
      }
      if (dt.CmpCode === "NTX" || dt.CmpCode === "NT_") {
        ntx = {
          DetailId: dt.DetailId,
          CmpCode: 'NTX',
          active: dt.CmpCode === "NTX",
          NeverExpire: dt.NeverExpire
        }
      }
      if (dt.CmpCode === "DPC" || dt.CmpCode === "DP_") {
        dpc = {
          DetailId: dt.DetailId,
          CmpCode: 'DPC',
          active: dt.CmpCode === "DPC",
          NeverExpire: dt.NeverExpire
        }
      }
      if (dt.CmpCode === "DGX" || dt.CmpCode === "DX_") {
        dgx = {
          DetailId: dt.DetailId,
          CmpCode: 'DGX',
          active: dt.CmpCode === "DGX",
          NeverExpire: dt.NeverExpire
        }
      }
      // if (dt.CmpCode === "GRP" || dt.CmpCode === "GR_") {
      //   grp = {
      //     DetailId: dt.DetailId,
      //     CmpCode: 'GRP',
      //     active: dt.CmpCode === "GRP"
      //   }
      // }
    })
    setStaticOption(ip)
    setState({
      update: true,
      dealerCode: data[0] && data[0].SAPCode && data[0].SAPCode.replace("0000", ""),
      taxNumber: data[0] && data[0].TaxNumber,
      email: data[0] && data[0].Email,
      notes: data[0] && data[0].Notes,
      idx: idx,
      ntx: ntx,
      dpc: dpc,
      // grp: grp,
      dgx: dgx,
      arpCode: data[0] && data[0].APRCode,
      id: data[0] && data[0].Id,
      approve: (routeLocation.state as any)?.approve,
      approveId: (routeLocation.state as any)?.id,
      XmlAktive: data[0] && data[0].XmlAktive,
    })
    props.showLoading()
    const getDealerExceptionalsApi: GetDealerExceptionalsApi = new GetDealerExceptionalsApi({})
    await getDealerExceptionalsApi
      .getDealerExceptionals(data[0] && data[0].SAPCode)
      .then(async result => {
        if (result) {
          const productCodeIncluded = result.IncludeProductCode.map((code) => {
            return { label: code, value: code }
          })
          const productCodeExcluded = result.ExcludeProductCode.map((code) => {
            return { label: code, value: code }
          })
          const subTypeIncluded = result.IncludeProductSubTypes.map((code) => {
            return { label: code, value: code }
          })
          const subTypeExcluded = result.ExcludeProductSubTypes.map((code) => {
            return { label: code, value: code }
          })
          const quotaIncluded = result.IncludeQuotaNo.map((code) => {
            return { label: code, value: code }
          })
          const quotaExcluded = result.ExcludeQuotaNo.map((code) => {
            return { label: code, value: code }
          })
          const dataBrandCode: ISelectOption[] = []
          result.IncludeBrandID.map((code) => {
            state.brandData.map((brand) => {
              if (String(brand.value) === String(code))
                return dataBrandCode.push(brand)
              return
            })
          })
          const brandIncluded = dataBrandCode
          const dataBrand: ISelectOption[] = []
          result.ExcludeBrandID.map((code) => {
            state.brandData.map((brand) => {
              if (String(brand.value) === String(code))
                return dataBrand.push(brand)
              return
            })
          })
          const brandExcluded = dataBrand
          const dataCategory: ISelectOption[] = []
          result.IncludeCategoryCode.map((code) => {
            state.categoryData.map((brand) => {
              if (String(brand.value) === String(code))
                return dataCategory.push(brand)
              return
            })
          })
          const categoryIncluded = dataCategory
          const dataCategoryCode: ISelectOption[] = []
          result.ExcludeCategoryCode.map((code) => {
            state.categoryData.map((brand) => {
              if (String(brand.value) === String(code))
                return dataCategoryCode.push(brand)
              return
            })
          })
          const categoryExcluded = dataCategoryCode
          setState({
            productCodeIncluded,
            productCodeExcluded,
            subTypeIncluded,
            subTypeExcluded,
            quotaExcluded,
            quotaIncluded,
            brandIncluded,
            brandExcluded,
            categoryIncluded,
            categoryExcluded
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        props.hideLoading()
        if (err.description === "OK") return
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  useEffect(() => {
    getProductCode()
  }, [])

  const getProductCode = async () => {
    props.showLoading()
    const getProductCodeApi: GetProductCodeApi = new GetProductCodeApi({})

    await getProductCodeApi
      .getProductCode()
      .then(async res => {
        if (res) {
          const productSubTypeListApi: ProductSubTypeListApi = new ProductSubTypeListApi({})

          await productSubTypeListApi
            .productSubTypeList()
            .then(async result => {
              if (result) {
                const getBrandListWithIdApi: GetBrandListWithIdApi = new GetBrandListWithIdApi({})
                await getBrandListWithIdApi
                  .getBrandListWithId()
                  .then(async rs => {
                    if (rs) {
                      const getCategoryListApi: GetCategoryListApi = new GetCategoryListApi({})
                      await getCategoryListApi
                        .getCategoryList()
                        .then(async r => {
                          if (r) {
                            const categoryData = r.map((ca) => {
                              return { label: ca.Name, value: ca.SapCode }
                            })
                            const brandData = rs.Entity.map((en) => {
                              return { label: en.BrandCode, value: en.Id }
                            })
                            const productCodeData = res.Entity.ProductsInfo.map(item => {
                              return { label: item.ProductCode, value: item.ProductCode }
                            })
                            const quotaData = res.Entity.QuotaCode.map(item => {
                              return { label: item, value: item }
                            })
                            const subTypeData = result.map(item => {
                              return { label: item, value: item }
                            })
                            setState({
                              productCodeData,
                              quotaData,
                              subTypeData,
                              brandData,
                              categoryData
                            })
                          }
                          props.hideLoading()
                        }).catch((err: any) => {
                          Swal.fire({
                            icon: 'error',
                            title: err.description,
                            showConfirmButton: true,
                            allowOutsideClick: false,
                          })
                          props.hideLoading()
                        })
                    }
                  }).catch((err: any) => {
                    Swal.fire({
                      icon: 'error',
                      title: err.description,
                      showConfirmButton: true,
                      allowOutsideClick: false,
                    })
                    props.hideLoading()
                  })
              }
            }).catch((err: any) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              props.hideLoading()
            })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const addUpdateXml = async () => {
    if (!state.dealerCode || state.dealerCode.length === 0 || state.taxNumber.length === 0 || state.email.length === 0 || !staticOption) {
      Swal.fire({
        icon: 'error',
        title: "Cari Kodu Giriniz",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const addUpdateXmlApi: AddUpdateXmlApi = new AddUpdateXmlApi({})
    let data = "";
    staticOption.map((ip, index) => {
      if (index === 0)
        return data = data + ip.label
      return data = data + "/" + ip.label
    })
    const ExcludeBrandID = state.brandExcluded.map((exc) => { return String(exc.value) })
    const ExcludeCategoryCode = state.categoryExcluded.map((exc) => { return String(exc.value) })
    const ExcludeProductCode = state.productCodeExcluded.map((exc) => { return String(exc.value) })
    const ExcludeProductSubTypes = state.subTypeExcluded.map((exc) => { return String(exc.value) })
    const ExcludeQuotaNo = state.quotaExcluded.map((exc) => { return String(exc.value) })
    const IncludeBrandID = state.brandIncluded.map((exc) => { return String(exc.value) })
    const IncludeCategoryCode = state.categoryIncluded.map((exc) => { return String(exc.value) })
    const IncludeProductCode = state.productCodeIncluded.map((exc) => { return String(exc.value) })
    const IncludeProductSubTypes = state.subTypeIncluded.map((exc) => { return String(exc.value) })
    const IncludeQuotaNo = state.quotaIncluded.map((exc) => { return String(exc.value) })
    const req: IXmlDealer = {
      id: state.id,
      SAPCode: state.dealerCode.length > 5 ? state.dealerCode.padStart(10, "0") : state.dealerCode,
      APRCode: state.arpCode,
      TheName: '',
      Email: state.email,
      TaxNumber: state.taxNumber,
      StaticIp: data,
      Notes: state.notes,
      DetailModels: [
        state.idx,
        state.ntx,
        state.dpc,
        state.dgx,
        // state.grp
      ],
      DealerExceptionals: {
        ExcludeBrandID,
        ExcludeCategoryCode,
        ExcludeProductCode,
        ExcludeProductSubTypes,
        ExcludeQuotaNo,
        IncludeBrandID,
        IncludeCategoryCode,
        IncludeProductCode,
        IncludeProductSubTypes,
        IncludeQuotaNo
      },
      XmlAktive: state.XmlAktive
    }
    props.showLoading()
    await addUpdateXmlApi
      .addUpdateXml(req)
      .then(async result => {
        if (result) {
          if (state.approve) {
            const req: IApproveRejectXmlDemandRequest = {
              Id: state.approveId,
              status: 1
            }
            const approveRejectXmlDemandApi: ApproveRejectXmlDemandApi = new ApproveRejectXmlDemandApi({})
            await approveRejectXmlDemandApi
              .approveRejectXmlDemand(req)
              .then((result: any) => {
                Swal.fire({
                  icon: 'success',
                  title: 'Xml başvurusu onaylandı.',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                setStaticOption([])
                setState({
                  dealerCode: "",
                  taxNumber: "",
                  email: "",
                  idx: {
                    DetailId: 0,
                    CmpCode: "IDX",
                    active: false,
                    NeverExpire: false
                  },
                  ntx: {
                    DetailId: 0,
                    CmpCode: "NTX",
                    active: false,
                    NeverExpire: false
                  },
                  dpc: {
                    DetailId: 0,
                    CmpCode: "DPC",
                    active: false,
                    NeverExpire: false
                  },
                  // grp: {
                  //   DetailId: 0,
                  //   CmpCode: "GRP",
                  //   active: false
                  // },
                  dgx: {
                    DetailId: 0,
                    CmpCode: "DGX",
                    active: false,
                    NeverExpire: false
                  },
                  notes: "",
                  detailId: 0,
                  id: 0,
                  update: false,
                  arpCode: "",
                  approve: false,
                  productCodeIncluded: [],
                  productCodeExcluded: [],
                  quotaIncluded: [],
                  quotaExcluded: [],
                  subTypeIncluded: [],
                  subTypeExcluded: [],
                  brandIncluded: [],
                  brandExcluded: [],
                  categoryIncluded: [],
                  categoryExcluded: [],
                  XmlAktive: false
                })
                props.hideLoading()
              }).catch((err: any) => {
                Swal.fire({
                  icon: 'error',
                  title: err.description,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                props.hideLoading()
              })
            return
          }
          Swal.fire({
            icon: 'success',
            title: `XML ${state.update ? "güncellendi." : "kaydedildi"}`,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((res) => {
            if (res.isConfirmed) {
              setStaticOption([])
              setState({
                dealerCode: "",
                taxNumber: "",
                email: "",
                idx: {
                  DetailId: 0,
                  CmpCode: "IDX",
                  active: false,
                  NeverExpire: false
                },
                ntx: {
                  DetailId: 0,
                  CmpCode: "NTX",
                  active: false,
                  NeverExpire: false
                },
                dpc: {
                  DetailId: 0,
                  CmpCode: "DPC",
                  active: false,
                  NeverExpire: false
                },
                // grp: {
                //   DetailId: 0,
                //   CmpCode: "GRP",
                //   active: false
                // },
                dgx: {
                  DetailId: 0,
                  CmpCode: "DGX",
                  active: false,
                  NeverExpire: false
                },
                notes: "",
                detailId: 0,
                id: 0,
                update: false,
                arpCode: "",
                approve: false,
                productCodeIncluded: [],
                productCodeExcluded: [],
                quotaIncluded: [],
                quotaExcluded: [],
                subTypeIncluded: [],
                subTypeExcluded: [],
                brandIncluded: [],
                brandExcluded: [],
                categoryIncluded: [],
                categoryExcluded: [],
                XmlAktive: false
              })
            }
          })
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const routeLocation = useLocation()
  useEffect(() => {
    if (routeLocation.state && state.categoryData.length > 0 && state.brandData.length > 0) {
      getDealerExceptionals()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeLocation, state.categoryData, state.brandData])

  const onCreateOption = (option, updateFunc) => {
    updateFunc(prevState => {
      const newData = {
        value: option[option.length - 1].value,
        label: option[option.length - 1].label,
      }
      if (prevState && prevState.length > 0) {
        for (const state of prevState) {
          if (state.value === newData.value) return [...prevState]
          return [...prevState, newData]
        }
      }
      return [newData]
    })
  }
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>XML {state.update ? "Güncelleme" : "Ekleme"}</span>
            <Link to='/admin/UserOperation/XMLUpdate/listXML'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-20'
                text='Listeye Dön'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='etkin d-flex justify-content-end m-10'>
            <IPCheckbox
              name='XmlAktive'
              onChange={() => { setState({ XmlAktive: !state.XmlAktive }) }}
              checked={state.XmlAktive}
              label="Durum" />
          </div>
          <div className='card-body'>
            <div className='col-md-2'>
              Sistem#: {state.id}
            </div>
            <div className='row'>
              <div className='col-md-3'>
                <IPInput
                  disabled={state.update}
                  type='text'
                  id='dealerCode'
                  className='form-control'
                  name='dealerCode'
                  value={state.dealerCode}
                  placeholder="Cari Kod *"
                  onChange={(e) => setState({ dealerCode: e.target.value })}
                />
              </div>
              {
                !state.update &&
                <div className='col-md-2'>
                  <IPButton
                    type='button'
                    onClick={checkDealerCode}
                    className='btn btn-success float-right'
                    text="Vergi Numarası Getir"
                  />
                </div>
              }
              <div className='col-md-3'>
                <IPInput
                  disabled={true}
                  type='text'
                  id='TaxNumber'
                  className='form-control'
                  name='TaxNumber'
                  value={state.taxNumber}
                  placeholder="Vergi Numarası"
                />
              </div>
              <div className={state.update ? 'col-md-6' : 'col-md-4'}>
                <IPInput
                  type='text'
                  id='Email'
                  className='form-control'
                  name='Email'
                  value={state.email}
                  placeholder="Email *"
                  onChange={(e) => setState({ email: e.target.value })}
                />
              </div>

            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Ürün Kodu Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='ProductCodeIncluded'
                  name='ProductCodeIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ productCodeIncluded: options })
                  }}
                  options={state.productCodeData}
                  value={state.productCodeIncluded}
                  placeholder="Ürün Kodu Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Ürün Kodu Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='ProductCodeExcluded'
                  name='ProductCodeExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ productCodeExcluded: options })
                  }}
                  options={state.productCodeData}
                  value={state.productCodeExcluded}
                  placeholder="Ürün Kodu Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Ürün Alt Tipi Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='SubTypeIncluded'
                  name='SubTypeIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ subTypeIncluded: options })
                  }}
                  options={state.subTypeData}
                  value={state.subTypeIncluded}
                  placeholder="Ürün Alt Tipi Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Ürün Alt Tipi Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='SubTypeExcluded'
                  name='SubTypeExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ subTypeExcluded: options })
                  }}
                  options={state.subTypeData}
                  value={state.subTypeExcluded}
                  placeholder="Ürün Alt Tipi Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Kota Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='QuotaIncluded'
                  name='QuotaIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ quotaIncluded: options })
                  }}
                  options={state.quotaData}
                  value={state.quotaIncluded}
                  placeholder="Kota Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Kota Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='QuotaExcluded'
                  name='QuotaExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ quotaExcluded: options })
                  }}
                  options={state.quotaData}
                  value={state.quotaExcluded}
                  placeholder="Kota Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Marka Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='BrandIncluded'
                  name='BrandIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ brandIncluded: options })
                  }}
                  options={state.brandData}
                  value={state.brandIncluded}
                  placeholder="Marka Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Marka Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='BrandExcluded'
                  name='BrandExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ brandExcluded: options })
                  }}
                  options={state.brandData}
                  value={state.brandExcluded}
                  placeholder="Marka Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Kategori Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='CategoryIncluded'
                  name='CategoryIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ categoryIncluded: options })
                  }}
                  options={state.categoryData}
                  value={state.categoryIncluded}
                  placeholder="Kategori Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Kategori Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='CategoryExcluded'
                  name='CategoryExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ categoryExcluded: options })
                  }}
                  options={state.categoryData}
                  value={state.categoryExcluded}
                  placeholder="Kategori Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <IPSelectBox
                  isMulti
                  isClearable
                  isCreatableSelect
                  id='Ip'
                  name='Ip'
                  closeMenuOnSelect={false}
                  isSearchable
                  placeholder="IP *"
                  onChangeMulti={(options: ISelectOption[]) => {
                    setStaticOption(options)
                  }}
                  onCreateOption={option => onCreateOption(option, setStaticOption)}
                  value={staticOption}
                />
                <div className='row'>
                  <div className='col-md-2'>
                    <IPCheckbox
                      label='IDX'
                      checked={state.idx.active}
                      onClick={() => setState({
                        idx: {
                          DetailId: state.idx.DetailId,
                          CmpCode: state.idx.CmpCode,
                          active: !state.idx.active,
                          NeverExpire: state.idx.NeverExpire
                        }
                      })}
                    />
                  </div>
                  <div className='col-md-4'>
                    <IPCheckbox
                      label='IDX Expire Olmaz'
                      checked={state.idx.NeverExpire}
                      onClick={() => setState({
                        idx: {
                          DetailId: state.idx.DetailId,
                          CmpCode: state.idx.CmpCode,
                          active: state.idx.active,
                          NeverExpire: !state.idx.NeverExpire
                        }
                      })}
                    />
                  </div>
                  <div className='col-md-2'>
                    <IPCheckbox
                      label='NTX'
                      checked={state.ntx.active}
                      onClick={() => setState({
                        ntx: {
                          DetailId: state.ntx.DetailId,
                          CmpCode: state.ntx.CmpCode,
                          active: !state.ntx.active,
                          NeverExpire: state.ntx.NeverExpire
                        }
                      })}
                    />
                  </div>
                  <div className='col-md-4'>
                    <IPCheckbox
                      label='NTX Expire Olmaz'
                      checked={state.ntx.NeverExpire}
                      onClick={() => setState({
                        ntx: {
                          DetailId: state.ntx.DetailId,
                          CmpCode: state.ntx.CmpCode,
                          active: state.ntx.active,
                          NeverExpire: !state.ntx.NeverExpire
                        }
                      })}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-md-2'>
                    <IPCheckbox
                      label='DPC'
                      checked={state.dpc.active}
                      onClick={() => setState({
                        dpc: {
                          DetailId: state.dpc.DetailId,
                          CmpCode: state.dpc.CmpCode,
                          active: !state.dpc.active,
                          NeverExpire: state.dpc.NeverExpire
                        }
                      })}
                    />
                  </div>
                  <div className='col-md-4'>
                    <IPCheckbox
                      label='DPC Expire Olmaz'
                      checked={state.dpc.NeverExpire}
                      onClick={() => setState({
                        dpc: {
                          DetailId: state.dpc.DetailId,
                          CmpCode: state.dpc.CmpCode,
                          active: state.dpc.active,
                          NeverExpire: !state.dpc.NeverExpire
                        }
                      })}
                    />
                  </div>
                  <div className='col-md-2'>
                    <IPCheckbox
                      label='DGX'
                      checked={state.dgx.active}
                      onClick={() => setState({
                        dgx: {
                          DetailId: state.dgx.DetailId,
                          CmpCode: state.dgx.CmpCode,
                          active: !state.dgx.active,
                          NeverExpire: state.dgx.NeverExpire
                        }
                      })}
                    />
                  </div>
                  <div className='col-md-4'>
                    <IPCheckbox
                      label='DGX Expire Olmaz'
                      checked={state.dgx.NeverExpire}
                      onClick={() => setState({
                        dgx: {
                          DetailId: state.dgx.DetailId,
                          CmpCode: state.dgx.CmpCode,
                          active: state.dgx.active,
                          NeverExpire: !state.dgx.NeverExpire
                        }
                      })}
                    />
                  </div>
                  {/* <div className='col-md-2'>
                    <IPCheckbox
                      label='GRP'
                      checked={state.grp.active}
                      onClick={() => setState({
                        grp: {
                          DetailId: state.grp.DetailId,
                          CmpCode: state.grp.CmpCode,
                          active: !state.grp.active
                        }
                      })}
                    />
                  </div> */}
                </div>
              </div>
              <div className='col-md-6'>
                <IPTextarea
                  id='notes'
                  value={state.notes}
                  onChange={e => setState({ notes: e.target.value })}
                  placeholder='Notlar'
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-10'></div>
              <div className='col-md-2'>
                <IPButton
                  type='button'
                  onClick={addUpdateXml}
                  className='btn btn-success float-right'
                  text={state.approve ? "Onayla" : (state.update ? "Güncelle" : "Ekle")}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(XmlDealer)
