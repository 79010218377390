import Menu from './Menu/Menu'

export function Header(props: any) {
  return (
    <div>
      <Menu />
    </div>
  )
}
export default Header
