import { useCustomReducer } from '../../../../base/customHooks'
import IPButton from '../../../../components/IPButton'
import IPSelectBox from '../../../../components/IPSelectBox'
import './CreditCardTransactions.scss'

const Months = [
  { value: 1, label: 'Ocak' },
  { value: 2, label: 'Şubat' },
  { value: 3, label: 'Mart' },
  { value: 4, label: 'Nisan' },
  { value: 5, label: 'Mayıs' },
  { value: 6, label: 'Haziran' },
  { value: 7, label: 'Temmuz' },
  { value: 8, label: 'Ağustos' },
  { value: 9, label: 'Eylül' },
  { value: 10, label: 'Ekim' },
  { value: 11, label: 'Kasım' },
  { value: 12, label: 'Aralık' },
]

const Years = [
  { value: 2016, label: '2016' },
  { value: 2017, label: '2017' },
  { value: 2018, label: '2018' },
  { value: 2019, label: '2019' },
  { value: 2020, label: '2020' },
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
]

const companyOptions = [
  { value: 1, label: 'Index' },
  { value: 1, label: 'Datagate' },
  { value: 1, label: 'Despec' },
  { value: 1, label: 'Netex' },
]

interface IReportState { }

export default function CreditCardTransactions(props: any) {
  const getInitialState = (): IReportState => {
    return {}
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IReportState>(intialState)

  return (
    <div className='creditCardStatement'>
      <div className='heading-text'>
        <h4>Kredi Kartı Hareketlerim</h4>
      </div>
      <div className='row'>
        <div className='col-lg col-sm-12'>
          <IPSelectBox options={Months} className='' />
        </div>
        <div className='col-lg col-sm-12 mt-3 mt-lg-0'>
          <IPSelectBox options={Years} className='' />
        </div>
        <div className='col-lg col-sm-12 mt-3 mt-lg-0'>
          <IPSelectBox options={companyOptions} className='' />
        </div>
        <div className='col-lg col-sm-12 mt-3 mt-lg-0'>
          <IPButton text='Göster' className='button w-25 float-left' />
        </div>
      </div>

      <div className='table-responsive'>
        <table className='table  mt-5'>
          <thead>
            <tr>
              <th scope='col'>İşlem Tarihi</th>
              <th scope='col'>Kullanıcı Adı</th>
              <th scope='col'>Sipariş No</th>
              <th scope='col'>İşlem No</th>
              <th scope='col'>Doküman No</th>
              <th scope='col'>İşlem Tipi</th>
              <th scope='col'>Banka</th>
              <th scope='col'>Adı Soyadı</th>
              <th scope='col'>Taksit</th>
              <th scope='col'>Taksit Tutar</th>
              <th scope='col'>Toplam Tutar</th>
              <th scope='col'>3D</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope='row'>27/10/2021</th>
              <td>admin</td>
              <td>0100452111</td>
              <td>TK00763510</td>
              <td>0100452111</td>
              <td>Bedava Pos İşlemi</td>
              <td>İŞ BANKASI</td>
              <td>OKAN GÖRGÜLÜ</td>
              <td>3</td>
              <td>2.500,00 TL</td>
              <td>7.500,00 TL</td>
              <td>Evet</td>
              <div className='row'>
                <div className='col mr-5'>
                  <IPButton text='Yazdır' className='button mt-2' />
                </div>
                <div className='col mr-5'>
                  <IPButton text='İptal' className='button mt-2' />
                </div>
              </div>
            </tr>
            <tr>
              <th scope='row'>27/10/2021</th>
              <td>admin</td>
              <td>0100452111</td>
              <td>TK00763510</td>
              <td>0100452111</td>
              <td>Bedava Pos İşlemi</td>
              <td>İŞ BANKASI</td>
              <td>OKAN GÖRGÜLÜ</td>
              <td>3</td>
              <td>2.500,00 TL</td>
              <td>7.500,00 TL</td>
              <td>Evet</td>
              <div className='row'>
                <div className='col mr-5'>
                  <IPButton text='Yazdır' className='button mt-2' />
                </div>
                <div className='col mr-5'>
                  <IPButton text='İptal' className='button mt-2' />
                </div>
              </div>
            </tr>
            <tr>
              <th scope='row'>27/10/2021</th>
              <td>admin</td>
              <td>0100452111</td>
              <td>TK00763510</td>
              <td>0100452111</td>
              <td>Bedava Pos İşlemi</td>
              <td>İŞ BANKASI</td>
              <td>OKAN GÖRGÜLÜ</td>
              <td>3</td>
              <td>2.500,00 TL</td>
              <td>7.500,00 TL</td>
              <td>Evet</td>
              <div className='row'>
                <div className='col mr-5'>
                  <IPButton text='Yazdır' className='button mt-2' />
                </div>
                <div className='col mr-5'>
                  <IPButton text='İptal' className='button mt-2' />
                </div>
              </div>
            </tr>
            <tr>
              <th scope='row'>27/10/2021</th>
              <td>admin</td>
              <td>0100452111</td>
              <td>TK00763510</td>
              <td>0100452111</td>
              <td>Bedava Pos İşlemi</td>
              <td>İŞ BANKASI</td>
              <td>OKAN GÖRGÜLÜ</td>
              <td>3</td>
              <td>2.500,00 TL</td>
              <td>7.500,00 TL</td>
              <td>Evet</td>
              <div className='row'>
                <div className='col mr-5'>
                  <IPButton text='Yazdır' className='button mt-2' />
                </div>
                <div className='col mr-5'>
                  <IPButton text='İptal' className='button mt-2' />
                </div>
              </div>
            </tr>
            <tr>
              <th scope='row'>27/10/2021</th>
              <td>admin</td>
              <td>0100452111</td>
              <td>TK00763510</td>
              <td>0100452111</td>
              <td>Bedava Pos İşlemi</td>
              <td>İŞ BANKASI</td>
              <td>OKAN GÖRGÜLÜ</td>
              <td>3</td>
              <td>2.500,00 TL</td>
              <td>7.500,00 TL</td>
              <td>Evet</td>
              <div className='row'>
                <div className='col mr-5'>
                  <IPButton text='Yazdır' className='button mt-2' />
                </div>
                <div className='col mr-5'>
                  <IPButton text='İptal' className='button mt-2' />
                </div>
              </div>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='row mt-5 d-flex justify-content-center'>
        <div className='col-12 col-lg-2'>
          <IPButton text="Excel'e Aktar" className='button' />
        </div>
        <div className='col-12 col-lg-2 mt-3 mt-lg-0'>
          <IPButton text='Yazdır' className='button' />
        </div>
        <div className='col-12 col-lg-2 mt-3 mt-lg-0'>
          <IPButton text='Gönder' className='button ' />
        </div>
      </div>
    </div>
  )
}
