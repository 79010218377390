import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export class GetIsContinueToPurchasePhaseApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/getIsContinueToPurchasePhase',
      requesterInfo,
    })
  }

  public async getIsContinueToPurchasePhase(request: any[]): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
