import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'

export class DeleteImageFirsatApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/image-firsat-delete',
      requesterInfo,
    })
  }

  public async deleteImage(imageId: number): Promise<any> {
    this.props.url = `${this.props.url}?imageId=${imageId}`
    return await this.postAsync<any>()
  }
}
