import React, { useEffect } from 'react'
import IPInput from '../../../components/IPInput'
import IPSelectBox from '../../../components/IPSelectBox'
import './style.scss'
import { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../base/customHooks'
import IPCheckbox from '../../../components/IPCheckBox'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import { AddUserRequest } from './models/models'
import { AddUserApi } from './services/addUserApi'
import { UpdateUserApi } from './services/updateUserApi'
import IPTextarea from '../../../components/IPTextarea'
import { useAppSelector } from '../../../redux/app/hooks'
import { currentUser } from '../../../redux/slices/user/userSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import IPButton from '../../../components/IPButton'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import * as yup from 'yup'

interface UserState {
  addUserParams: AddUserRequest
  userToBeUpdated?: any
}
function AddNewUser(props: BaseComponentWithContextProps) {
  let navigate = useNavigate()
  const user = useAppSelector(currentUser)
  const t = translate(Translations)
  const routeLocation = useLocation()

  useEffect(() => {
    if (routeLocation.state as any) setValues((routeLocation.state as any)?.userData)
    else resetForm()
  }, [])

  const getInitialState = (): UserState => {
    return {
      addUserParams: Object.assign({}),
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<UserState>(intialState)

  const ISelectOption: ISelectOption[] = [
    { label: t('pages.mySpecial.addNewUser.index.active'), value: '2' },
    { label: t('pages.mySpecial.addNewUser.index.passive'), value: '3' },
  ]

  const validations = yup.object().shape({
    UserCode: yup
      .string()
      .min(
        3,
        t('pages.mySpecial.addNewUser.validation.userCode') + ' ' + t('pages.mySpecial.addNewUser.validation.message')
      )
      .required(t('pages.mySpecial.addNewUser.validation.requiredField')),
    Name: yup
      .string()
      .min(3, t('pages.mySpecial.addNewUser.validation.name') + ' ' + t('pages.mySpecial.addNewUser.validation.message'))
      .required(t('pages.mySpecial.addNewUser.validation.requiredField')),
    Surname: yup
      .string()
      .min(3, t('pages.mySpecial.addNewUser.validation.surname') + ' ' + t('pages.mySpecial.addNewUser.validation.message'))
      .required(t('pages.mySpecial.addNewUser.validation.requiredField')),
    Email: yup
      .string()
      .email(t('pages.mySpecial.addNewUser.validation.email'))
      .min(3, 'Mail ' + t('pages.mySpecial.addNewUser.validation.message'))
      .required(t('pages.mySpecial.addNewUser.validation.requiredField')),
    Status: yup
      .object()
      .shape({
        label: yup.string(),
        value: yup.string(),
      })
      .required(t('pages.mySpecial.addNewUser.validation.requiredField')),
    Notes: yup.string(),
    UserName: yup
      .string()
      .min(
        3,
        t('pages.mySpecial.addNewUser.validation.userName') + ' ' + t('pages.mySpecial.addNewUser.validation.message')
      )
      .required(t('pages.mySpecial.addNewUser.validation.requiredField')),
    PasswordHash: yup
      .string()
      .min(3, t('pages.mySpecial.addNewUser.validation.password'))
      .required(t('pages.mySpecial.addNewUser.validation.requiredField')),
    PasswordConfirm: yup
      .string()
      .oneOf([yup.ref('PasswordHash'), null], t('pages.mySpecial.addNewUser.validation.passwordConfirm'))
      .required(t('pages.mySpecial.addNewUser.validation.requiredField'))
  })

  const { handleChange, handleBlur, setValues, setFieldValue, resetForm, values, errors, touched } =
    useFormik({
      initialValues: {
        Id: 0,
        UserCode: user.AccountCode,
        UserName: '',
        Name: '',
        Surname: '',
        PasswordHash: '',
        PasswordConfirm: '',
        Email: '',
        Enable: true,
        Notes: '',
        IsMostAuthorized: false,
        IsMonitorSalesAndOrder: false,
        IsOrder: false,
        IsMakeFinancialTransactions: false,
        IsMonitorFinancialAndCurrentMovements: false,
        IsSeeSpecialPrice: false,
        IsAddAdmin: false,
        SpecialPriceRate: 0,
      },
      validationSchema: validations,
      // onSubmit: async values => {
      //   //addUser();
      // },
      onSubmit: async (values, { setSubmitting }) => {
        // Check if there are any validation errors
        if (Object.keys(errors).length > 0) {
          setSubmitting(false); // Set submitting to false to enable form submission
          return; // Exit the function and prevent submission
        }

        // Proceed with form submission if there are no validation errors
        try {
          // Perform the form submission logic here
          // Example: await addUser(values);

          // Reset the form after successful submission
          resetForm();

          // Optionally, display a success message or navigate to a different page
          Swal.fire({
            icon: 'success',
            title: 'Form submitted successfully!',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(result => {
            navigate('/myspecial/UserUpdate');
          });
        }
        catch (error) {
          // Handle the submission error
          Swal.fire({
            icon: 'error',
            title: 'Submission failed',
            showConfirmButton: true,
            allowOutsideClick: false,
          });
        }
        finally {
          setSubmitting(false); // Set submitting to false after submission
        }
      },
    })

  const addUser = async () => {
    if (values.Id > 0) {
      const updateUserApi: UpdateUserApi = new UpdateUserApi({})
      await updateUserApi
        .putUser(values)
        .then(async result => {
          Swal.fire({
            icon: 'success',
            title: t('pages.mySpecial.addNewUser.index.swalTitle'),
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async result => {
            navigate('/myspecial/UserUpdate')
          })
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    } else {
      const addUserApi: AddUserApi = new AddUserApi({})
      await addUserApi
        .addUser(values)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Kullanıcı Eklendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async result => {
              navigate('/myspecial/UserUpdate')
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }
  }
  return (
    <div className='container-fluid special-valid'>
      <div className='heading-text'>
        <h4>
          {values.Id > 0
            ? t('pages.mySpecial.addNewUser.index.updateUser')
            : t('pages.mySpecial.addNewUser.index.addingNewUser')}
        </h4>
      </div>
      <div className='card-body px-0'>
        <div className='form-group form-fg'>
          <form id='form1' className='form-validate' noValidate>
            <div className='form-row'>
              <div className='form-group '>
                <label htmlFor='text' className='labeltext'>
                  • {t('pages.mySpecial.addNewUser.index.item1')}
                </label>
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group '>
                <label htmlFor='text' className='labeltext'>
                  • {t('pages.mySpecial.addNewUser.index.item2')}
                </label>
              </div>
              <div className='form-row'>
                <div className='form-group '>
                  <label htmlFor='text' className='labeltext'>
                    • {t('pages.mySpecial.addNewUser.index.item3')}
                  </label>
                </div>
              </div>
            </div>
            <br className='d-none d-lg-block' />
            <div className='row'>
              <div className='form-group col-auto'>
                <label className='labeltext' style={{ fontSize: '1rem' }}>
                  {t('pages.mySpecial.addNewUser.index.accountCode')}
                </label>
              </div>

              <div className='form-group col-auto'>
                <label className='labeltext' style={{ fontSize: '1rem' }}>
                  : {props.user?.IsRemote
                    ? 'Seçili Bayi: ' +
                    props.user?.AccountCodeRemote.replace('0000', '') 
                    : 
                  props.user.AccountCode && props.user.AccountCode.replace("0000", "")}
                </label>
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='addnewuser' className='labeltext'>
                  {t('pages.mySpecial.addNewUser.index.userName')}
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='UserName'
                  value={values.UserName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.UserName}
                  errors={errors.UserName}
                />
              </div>
              <div className='form-group col-md-3'>
                <label htmlFor='addnewuser' className='labeltext'>
                  Ad
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='Name'
                  value={values.Name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.Name}
                  errors={errors.Name}
                />
              </div>
              <div className='form-group col-md-3'>
                <label htmlFor='addnewuser' className='labeltext'>
                  Soyad
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='Surname'
                  value={values.Surname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.Surname}
                  errors={errors.Surname}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='password' className='labeltext'>
                  {t('pages.mySpecial.addNewUser.index.password')}
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='PasswordHash'
                  value={(routeLocation.state as any) ? '' : values.PasswordHash}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.PasswordHash}
                  errors={errors.PasswordHash}
                  //disabled={(routeLocation.state as any) ? true : false}
                  disabled={(routeLocation.state === "/mySpecial/AddNewUser") ? true : false}
                // touched={values.Id > 0 ? false : touched.PasswordHash}
                // errors={values.Id > 0 ? errors.PasswordHash = undefined : errors.PasswordHash}
                />
              </div>
              <div className='form-group col-md-6'>
                <label htmlFor='password' className='labeltext'>
                  {t('pages.mySpecial.addNewUser.index.passwordConfirm')}
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='PasswordConfirm'
                  value={(routeLocation.state as any) ? '' : values.PasswordConfirm}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.PasswordConfirm}
                  errors={errors.PasswordConfirm}
                  //disabled={(routeLocation.state as any) ? true : false}
                  disabled={(routeLocation.state === "/mySpecial/AddNewUser") ? true : false}
                // touched={values.Id > 0 ? false : touched.PasswordConfirm}
                // errors={values.Id > 0 ? "" : errors.PasswordConfirm}
                />
              </div>
            </div>
            <div className='form-row'>
              <div className='form-group col-md-6'>
                <label htmlFor='status' className='labeltext'>
                  {t('pages.mySpecial.addNewUser.index.status')}
                </label>
                <IPSelectBox
                  isClearable
                  id='DealerContents'
                  name='Enable'
                  options={[
                    {
                      label: t('pages.mySpecial.addNewUser.index.active'),
                      value: '1',
                    },
                    {
                      label: t('pages.mySpecial.addNewUser.index.passive'),
                      value: '0',
                    },
                  ]}
                  value={
                    [
                      {
                        label: t('pages.mySpecial.addNewUser.index.active'),
                        value: '1',
                      },
                      {
                        label: t('pages.mySpecial.addNewUser.index.passive'),
                        value: '0',
                      },
                    ]
                      .filter((elem: any) => {
                        if (values.Enable) return elem.value === '1'
                        else return elem.value === '0'
                      })
                      .map((elem2: any) => {
                        return { value: elem2.value, label: elem2.label }
                      })[0]
                  }
                  onChangeSingle={(newVal: ISelectOption) => {
                    if (newVal.value == 0) setFieldValue('Enable', false)
                    else setFieldValue('Enable', true)
                  }}
                />
              </div>
              <div className='form-group col-md-6'>
                <label className='labeltext'>{t('pages.mySpecial.addNewUser.index.email')}</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='Email'
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.Email}
                  errors={errors.Email}
                />
              </div>
            </div>
            <div className='form-row '>
              <div className='form-group col-md-6'>
                <label htmlFor='notes' className='labeltext'>
                  {t('pages.mySpecial.addNewUser.index.notes')}
                </label>
                <IPTextarea
                  className='bayinotlar'
                  name='Notes'
                  value={values.Notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.Notes}
                  errors={errors.Notes}
                />
              </div>
              <div className='form-group col-md-6    '>
                <div className='form-group col-md-12 '>
                  <div className='row'>
                    <div className='col-8'>
                      <label htmlFor='executive' className='float-left mr-4'>
                        {t('pages.mySpecial.addNewUser.index.isTheMostAuthoritativeAdministrator')}
                      </label>
                    </div>
                    <div className='col-4'>
                      <IPCheckbox
                        name='IsMostAuthorized'
                        checked={values.IsMostAuthorized}
                        onChange={e => {
                          setFieldValue('IsMostAuthorized', e.target.checked)
                        }}
                        disabled={values.IsAddAdmin}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-8'>
                      <label htmlFor='SalesOrder' className='float-left mr-4'>
                        {t('pages.mySpecial.addNewUser.index.canMonitorSalesAndOrderMovements')}
                      </label>
                    </div>
                    <div className='col-4'>
                      <IPCheckbox
                        name='IsMonitorSalesAndOrder'
                        checked={values.IsMonitorSalesAndOrder}
                        onChange={e => {
                          setFieldValue('IsMonitorSalesAndOrder', e.target.checked)
                        }}
                        disabled={values.IsAddAdmin}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-8'>
                      <label htmlFor='Canorder' className='float-left mr-4'>
                        {t('pages.mySpecial.addNewUser.index.canOrder')}
                      </label>
                    </div>
                    <div className='col-4'>
                      <IPCheckbox
                        name='IsOrder'
                        checked={values.IsOrder}
                        onChange={e => {
                          setFieldValue('IsOrder', e.target.checked)
                        }}
                        disabled={values.IsAddAdmin}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-8'>
                      <label htmlFor='financialtransactions' className='float-left mr-4'>
                        {t('pages.mySpecial.addNewUser.index.canMakeFinancialTransactions')}
                      </label>
                    </div>
                    <div className='col-4'>
                      <IPCheckbox
                        name='IsMakeFinancialTransactions'
                        checked={values.IsMakeFinancialTransactions}
                        onChange={e => {
                          setFieldValue('IsMakeFinancialTransactions', e.target.checked)
                        }}
                        disabled={values.IsAddAdmin}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-8'>
                      <label htmlFor='currentmovements' className='float-left mr-4'>
                        {t('pages.mySpecial.addNewUser.index.canMonitorFinancialAndCurrentMovements')}
                      </label>
                    </div>
                    <div className='col-4'>
                      <IPCheckbox
                        name='IsMonitorFinancialAndCurrentMovements'
                        checked={values.IsMonitorFinancialAndCurrentMovements}
                        onChange={e => {
                          setFieldValue('IsMonitorFinancialAndCurrentMovements', e.target.checked)
                        }}
                        disabled={values.IsAddAdmin}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-8'>
                      <label htmlFor='myprice' className='float-left mr-4'>
                        {t('pages.mySpecial.addNewUser.index.dontSeeSpecialPrice')}
                      </label>
                    </div>
                    <div className='col-4'>
                      <IPCheckbox
                        name='IsSeeSpecialPrice'
                        checked={values.IsSeeSpecialPrice}
                        onChange={e => {
                          setFieldValue('IsSeeSpecialPrice', e.target.checked)
                        }}
                        disabled={values.IsAddAdmin}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-5'>
                      <label htmlFor='myprice' className='float-left mr-4'>
                        {t('pages.mySpecial.addNewUser.index.specialPrice')} + %
                      </label>
                    </div>
                    <div className='col-7'>
                      <IPInput
                        type='text'
                        className='form-control'
                        name='SpecialPriceRate'
                        value={values.SpecialPriceRate}
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <IPButton
              type='button'
              className='btn btn-success w-25 mb-5 float-right'
              text={
                values.Id > 0
                  ? t('pages.mySpecial.addNewUser.index.update')
                  : t('pages.mySpecial.addNewUser.index.save')
              }
              onClick={() => {
                if (Object.keys(errors).length === 0 && Object.keys(touched).length > 0) {
                  addUser();
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Form Eksik',
                    text: 'Lütfen gerekli tüm alanları doldurunuz.',
                  });
                }
              }}
            />
          </form>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(AddNewUser)