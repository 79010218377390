import React, { useEffect } from 'react'
import Image from '../../../components/IPImage'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { GetBrandDetailApi } from '../services/getBrandDetailApi'
import { IBrandDetail, IDocument, IModul, IModuleDetailModel } from '../models/models'
import { downloadFileFromUrl, productGroupImage } from '../../../base/utils/dataHelper'
import IPButton from '../../../components/IPButton'
import { translate } from '../../../base/localization'
import Translations from '../../../translations'
import { useCustomReducer } from '../../../base/customHooks'
import { css } from '@emotion/css'
import { GetAuthorizedServicesProcedurApi } from '../services/getAuthorizedServicePointsListApi'
import { GetAuthorizedServiceMainApi } from '../services/getAuthorizedServiceMainApi'
import IPImage from '../../../components/IPImage'
import '../components/style.scss';

interface IBrandDetailState {
  brands: IBrandDetail
  currentDocument: IDocument[]
  currentButton: any[]
  buttonChange: number
  menu: IModul[]
  logo: string
  banner: string
  news: any
  newsData: any
}
export default function BrandDetail() {
  const routeLocation = useLocation()
  const getInitialState = (): IBrandDetailState => {
    return {
      brands: {
        Brand: {
          Id: 0,
          Name: '',
          Enable: false,
          Image: '',
          ImageUrl: '',
          SapCode: '',
          CallCenter: '',
          Website: '',
          BrandDetails: [],
        },
        IsAuthorizedServiceMain: false,
        IsAuthorizedServiceProcedur: false,
        ProfileList: [],
        ProductGroups: [],
        News: [],
      },
      currentDocument: [],
      currentButton: [],
      buttonChange: 0,
      menu: [],
      logo: '',
      banner: '',
      news: [],
      newsData: {},
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IBrandDetailState>(intialState)
  const [tmpImage, setTmpImage] = useCustomReducer({
    id: 0,
    documents: {
      Id: 0,
      Name: '',
      FileName: '',
      FileUrl: '',
    },
  })

  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    const qpBrandId = searchParams.get('brandId')
    getBrands(qpBrandId)
  }, [searchParams])

  const getBrands = async data => {
    const getBrands = new GetBrandDetailApi({})
    const brand = await getBrands.getBrand(data)
    setState({ brands: brand as any })
  }

  const callSSH = async (id: number, title: string) => {
    if (title === 'Procedur') {
      const getAuthorizedServicesProcedur: GetAuthorizedServicesProcedurApi = new GetAuthorizedServicesProcedurApi({})
      await getAuthorizedServicesProcedur.getAuthorizedServicesProcedur(id).then(result => {
        navigate('/myspecial/ssh/SSHProcedures', { state: { data: result } })
      })
    } else {
      const getAuthorizedServiceMain: GetAuthorizedServiceMainApi = new GetAuthorizedServiceMainApi({})
      await getAuthorizedServiceMain.getAuthorizedServiceMain(id).then(result => {
        navigate('/myspecial/ssh/AuthorizedServicePoints', { state: { data: result } })
      })
    }
  }

  useEffect(() => {
    state.currentDocument?.map(document => {
      if (document.Name === 'Logo') setState({ logo: document.FileUrl })
      else setState({ banner: document.FileUrl })
    })
  }, [state.currentDocument])
  const ChangeButton = async (buttonId: number) => {
    setState({ buttonChange: buttonId })
    if (buttonId === 6) {
      const data: any[] = []
      state.brands &&
        state.brands.News &&
        state.brands.News.map(news => {
          data.push({
            id: news.Id,
            topic: news.Topic,
            description: news.Description,
            link: news.Link,
            shortDescription: news.ShortDescription,
          })
        })
      state.brands && setState({ newsData: state.brands, news: data })
    } else {
      state.brands?.ProfileList.map(profile => {
        if (profile.Type === buttonId) {
          const data: IModuleDetailModel[] = []
          if (profile.Type === 4) {
            data.push(profile.ModuleDetails[profile.ModuleDetails.length - 1])
            setTmpImage({ id: data[0].Documents[0].Id as any, documents: data[0].Documents[0] as any })
          } else {
            profile.ModuleDetails.map(module => data.push(module))
          }
          setState({ currentButton: data })
        }
      })
    }
  }
  const t = translate(Translations)
  const IModulOption = [
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.general'),
      Id: 1,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.videos'),
      Id: 2,
    },

    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.technicaldocuments'),
      Id: 3,
    },

    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.ımages'),
      Id: 4,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.links'),
      Id: 5,
    },
    {
      Name: 'Haberler / Duyurular',
      Id: 6,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.ImportantInformation'),
      Id: 7,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.degreepreview360'),
      Id: 8,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.licensing'),
      Id: 9,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.managedClients'),
      Id: 10,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.managedservers'),
      Id: 11,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.Managementservers'),
      Id: 12,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.MSOffice'),
      Id: 13,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.hptopconfig'),
      Id: 14,
    },
    {
      Name: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.Inteltechnologyprovider'),
      Id: 15,
    },
  ]
  useEffect(() => {
    let data: IModul[] = []
    state.brands?.ProfileList.map(profile => {
      if (profile.Type !== 1) {
        IModulOption.map(modul => {
          if (modul.Id === profile.Type) {
            data.push(modul)
          }
        })
      } else {
        const data: IDocument[] = []
        profile.ModuleDetails &&
          profile.ModuleDetails[profile.ModuleDetails.length - 1].Documents.map(doc => data.push(doc))
        setState({ currentDocument: data })
      }
    })
    if (state.brands && state.brands.News && state.brands.News.length > 0) {
      data.push({
        Name: 'Haberler / Duyurular',
        Id: 6,
      })
    }
    setState({ menu: data })
  }, [state.brands])
  useEffect(() => {
    if (state.menu.length > 0) {
      setState({ buttonChange: state.menu[0].Id })
      state.brands?.ProfileList.map(profile => {
        if (profile.Type === state.menu[0].Id) {
          const data: IModuleDetailModel[] = []
          if (profile.Type === 4) {
            data.push(profile.ModuleDetails[profile.ModuleDetails.length - 1])
            setTmpImage({ id: data[0].Documents[0].Id as any, documents: data[0].Documents[0] as any })
          } else {
            profile.ModuleDetails.map(module => data.push(module))
          }
          setState({ currentButton: data })
        }
      })
    }
  }, [state.menu])

  const wrapperCSS = css`
    display: flex;
    height: 50vh;
    margin-block: 2rem;
    gap: 0.5rem;
  `
  const leftColCSS = css`
    display: flex;
    flex-direction: column;
    width: 20%;
    gap: 0.5rem;
  `
  const rightColCSS = css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    border: 1px solid #ddd;
    padding: 0.5rem;
  `
  const brandCSS = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20%;
    border: 1px solid #ddd;
    padding: 1rem 0.5rem;
    cursor: pointer;
    transition: border-color 0.3s;
    &.active,
    &:hover {
      border-color: #aaa;
    }
  `
  const brandImageCSS = css`
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  `
  const selectedImageCSS = css`
    display: block;
    max-width: 100%;
    max-height: 100%;
  `
  const navigate: any = useNavigate()
  const callNewsAnnouncements = async (id: number) => {
    state.brands?.ProfileList.map(profile => {
      if (profile.Type === 1) {
        const dt: IDocument[] = []
        profile.ModuleDetails &&
          profile.ModuleDetails[profile.ModuleDetails.length - 1].Documents.map(doc => dt.push(doc))
        navigate('/brands/newsAnnouncements', { state: { brand: dt, id: id, search: routeLocation.search } })
      }
    })
  }
  return (
    <div className='container'>
      <div className='header-text m-t-10 pt-4'>
        <h4>{state.brands && state.brands.Brand.Name}</h4>
        <hr />
      </div>
      {state.brands.ProfileList &&
        state.brands.ProfileList.length > 0 &&
        (state.logo || state.banner || state.brands?.Brand?.Website || state.brands?.Brand?.CallCenter) && (
          <div className='container mt-4'>
            <div className='row'>
              <div className='col-lg-2 mt-lg-0'>
                <div className='top-state.brands-item'>
                  {
                    state.brands.Brand.Image && state.brands.Brand.Image.length > 0 ?
                      <Image
                        className=''
                        src={state.brands.Brand.ImageUrl}
                        alt={state.brands ? state.brands.Brand.Name : ''}
                      /> :
                      <Image
                        className=''
                        src={state.logo}
                        alt={state.brands ? state.brands.Brand.Name : ''}
                      />
                  }
                </div>
              </div>
              <div className='col-lg-7' style={{ width: '400px' }}>
                {
                  state.banner && <Image className='' src={state.banner} alt={state.brands ? state.brands.Brand.Name : ''} />
                }
              </div>
              <div className='col-lg-3 mt-6'>
                <label>
                  {' '}
                  Çağrı merkezi:{' '}
                  <a href={'tel:' + (state.brands && state.brands.Brand.CallCenter)}>
                    {state.brands && state.brands.Brand.CallCenter}
                  </a>
                </label>
                <br />
                <label>
                  Web Sitesi:{' '}
                  <a href={state.brands && state.brands.Brand.Website} target='_blank'>
                    {state.brands && state.brands.Brand.Website}
                  </a>
                </label>
              </div>
            </div>
            <hr />
          </div>

        )}
      {state.brands.ProductGroups && state.brands.ProductGroups.length > 0 && (
        <div className='col-12'>
          <div className=''>
            <label>ÜRÜN GRUPLARI</label>
            <div style={{ marginLeft: "0px", marginRight: "0px", display: "grid", gridTemplateColumns: "repeat(auto-fill, minmax(115px,1fr))", gap: "1rem" }}>
              {state.brands.ProductGroups.map((group) => {
                const image = productGroupImage(group.GroupName)
                return (
                  image.length > 0 ? (
                    <Link
                      to={`/products?brand=${state.brands.Brand.Name}&category=${group.GroupCode}`}
                    >
                      <IPImage src={image} alt="" />
                    </Link>
                  ) :
                    <Link
                      to={`/products?brand=${state.brands.Brand.Name}&category=${group.GroupCode}`}
                    >
                      <div className='position-relative'>
                        <IPImage src={"images/notImage2.png"} alt="" />
                        <p className='position-absolute bottom-0' style={{
                          inset: "0", display: "flex", alignItems: "flex-end", backgroundColor: "rgba(0,0,0,0.5)", color: "white", justifyContent: "center", padding: "10px"
                        }}>{group.GroupName}</p>
                      </div>
                    </Link>
                )
              })}
            </div>
          </div>
          <hr />
        </div>
      )}
      {state.menu && state.menu.length > 0 && (
        <>
          <div className='col-12'>
            <div className='d-flex gap-2'>
              {state.menu?.map(button =>
                state.buttonChange == button.Id ? (
                  <IPButton
                    className={button.Name}
                    name={button.Name}
                    onClick={() => ChangeButton(button.Id)}
                    text={button.Name}
                    style={{
                      width: 'auto',
                      padding: '10px',
                      color: 'white',
                      backgroundColor: '#ec1d25',
                      overflowWrap: 'anywhere',
                      height: '100%',
                      minHeight: '50px',
                    }}
                  />
                ) : (
                  <IPButton
                    className={button.Name}
                    name={button.Name}
                    onClick={() => ChangeButton(button.Id)}
                    text={button.Name}
                    style={{
                      width: 'auto',
                      padding: '10px',
                      color: 'black',
                      backgroundColor: '#c4c4c4',
                      overflowWrap: 'anywhere',
                      height: '100%',
                      minHeight: '50px',
                    }}
                  />
                )
              )}
            </div>
          </div>
          <div className='col-12 m-l-10'>
            <div className='row' key={state.buttonChange}>
              {state.buttonChange === 2 ? (
                state.currentButton &&
                state.currentButton.map(curr => {
                  {
                    return curr.VideoType && curr.VideoType === 1 ? (
                      <div className='col-md-6'>
                        <video
                          width='100%'
                          preload='auto'
                          controls
                          style={{ height: '100%', padding: '5px 5px 5px 5px' }}
                        >
                          <source src={curr.ImageUrl} type='video/mp4'></source>
                          Sorry, your browser doesn't support videos.
                        </video>
                      </div>
                    ) : curr.VideoId && curr.VideoType === 2 ? (
                      <div className='col-md-6'>
                        <iframe
                          width='100%'
                          style={{ height: '400px', padding: '15px 15px 15px 15px' }}
                          src={'https://www.youtube.com/embed/' + curr.VideoId}
                          title='YouTube video player'
                          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        ></iframe>
                      </div>
                    ) : curr.VideoId && curr.VideoType === 3 ? (
                      <div className='col-md-6'>
                        <iframe
                          src={'https://player.vimeo.com/video/' + curr.VideoId + '?h=ff2b296e05&color=ffffff'}
                          width='640'
                          height='360'
                          style={{ padding: '5px 5px 5px 5px' }}
                          allow='autoplay; fullscreen; picture-in-picture'
                        ></iframe>
                      </div>
                    ) : (
                      ''
                    )
                  }
                })
              ) : state.buttonChange === 3 ||
                state.buttonChange === 5 ||
                state.buttonChange === 7 ||
                state.buttonChange === 8 ||
                state.buttonChange === 9 ||
                state.buttonChange === 11 ||
                state.buttonChange === 12 ||
                state.buttonChange === 13 ||
                state.buttonChange === 15 ? (
                <div key={state.buttonChange}>
                  {state.currentButton &&
                    state.currentButton.map(curr => {
                      return (
                        <div key={curr.Id}>
                          <h4>{curr.Description}</h4>
                          {curr.Link.length > 0 && <h5>Linkler</h5>}
                          <div className='row'>
                            {curr.Link &&
                              curr.Link.map(link => (
                                <div className='col-md-4' key={link.Id}>
                                  <a href={link.Link} style={{ marginRight: '20px', textAlign: 'center' }}>
                                    {link.Name}
                                  </a>
                                </div>
                              ))}
                            {curr.Documents.length > 0 && <h5>Dosyalar</h5>}
                            {curr.Documents &&
                              curr.Documents.map(document => (
                                <div
                                  className='col-md-2'
                                  key={document.Id}
                                  onClick={() => downloadFileFromUrl(document.Name, document.FileUrl)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  {(document.FileName &&
                                    document.FileName.toLocaleLowerCase('tr-TR').indexOf('.png') > -1) ||
                                    document.FileName.toLocaleLowerCase('tr-TR').indexOf('.jpg') > -1 ||
                                    document.FileName.toLocaleLowerCase('tr-TR').indexOf('.jpeg') > -1 ||
                                    document.FileName.toLocaleLowerCase('tr-TR').indexOf('.gif') > -1 ? (
                                    <Image
                                      className={document.Name}
                                      src={document.FileUrl}
                                      alt={document.Name}
                                      style={{ width: '200px', height: '200px', objectFit: 'cover' }}
                                    />
                                  ) : (
                                    <>
                                      <Image className='belge' src='/images/belge_ikon.png' alt='belge' />{' '}
                                      <p>{document.Name}</p>
                                    </>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      )
                    })}
                </div>
              ) : state.buttonChange === 4 ? (
                state.currentButton &&
                state.currentButton.map(curr => {
                  return (
                    <div className={wrapperCSS}>
                      <div className={leftColCSS}>
                        {curr.Documents &&
                          curr.Documents.map(document => (
                            <div
                              className={`${brandCSS} ${tmpImage.id === document.Id ? 'active' : ''}`}
                              style={{ height: `${100 / curr.Documents.length}%` }}
                              onClick={() => setTmpImage({ id: document.Id, documents: document as any })}
                            >
                              {(document.FileName &&
                                document.FileName.toLocaleLowerCase('tr-TR').indexOf('.png') > -1) ||
                                document.FileName.toLocaleLowerCase('tr-TR').indexOf('.jpg') > -1 ||
                                document.FileName.toLocaleLowerCase('tr-TR').indexOf('.jpeg') > -1 ||
                                document.FileName.toLocaleLowerCase('tr-TR').indexOf('.gif') > -1 ? (
                                <Image className={document.Name} src={document.FileUrl} alt={document.Name} />
                              ) : (
                                <Image className='belge' src='/images/belge_ikon.png' alt='belge' />
                              )}
                            </div>
                          ))}
                      </div>
                      <div className={rightColCSS}>
                        {tmpImage ? (
                          <Image
                            className={selectedImageCSS}
                            src={tmpImage.documents.FileUrl}
                            alt={tmpImage.documents.Name}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  )
                })
              ) : state.buttonChange === 6 ? (
                state.news &&
                state.news.map(curr => {
                  return (
                    <div className='card m-t-10' style={{ backgroundColor: 'gainsboro' }} key={curr.id}>
                      <h4>{curr.topic}</h4>
                      {curr.link && curr.link.length > 0 ? (
                        <a href={curr.link} style={{ cursor: 'pointer' }}>
                          Habere git
                        </a>
                      ) : curr.shortDescription && curr.shortDescription.length > 0 ? (
                        <a
                          onClick={() => {
                            callNewsAnnouncements(curr.id)
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          {curr.shortDescription + '...'}
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            callNewsAnnouncements(curr.id)
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          Haberin devamını okumak için tıklayınız...
                        </a>
                      )}
                    </div>
                  )
                })
              ) : state.buttonChange === 14 ? (
                <p>{t('pages.admin.brandPageOperation.brandInformation.brandUpdate.hptopconfig')}</p>
              ) : (
                ''
              )}
            </div>
          </div>
          <hr />
        </>
      )}
      <div className='row'>
        {state.brands.IsAuthorizedServiceProcedur && (
          <div className='col-md-5 image-hover'>
            <a onClick={() => callSSH(state.brands.Brand.Id, 'Procedur')}>
              <Image className='ssh ssh-original' src='/images/AuthorizedService/ssd.png' alt='' />
              <Image className='ssh ssh-hover' src='/images/AuthorizedService/ssd_hover.png' alt='' />
            </a>
          </div>
        )}
        {state.brands.IsAuthorizedServiceMain && (
          <div className='col-md-5 image-hover'>
            <a onClick={() => callSSH(state.brands.Brand.Id, 'Servis')}>
              <Image className='sshYetkili sshYetkili-original' src='/images/AuthorizedService/ytsn.png' alt='' />
              <Image className='sshYetkili sshYetkili-hover' src='/images/AuthorizedService/ytsn_hover.png' alt='' />
            </a>
          </div>
        )}
      </div>
      <br />
      {/* <hr />
			<div className='row'>
				<label>ÖNE ÇIKAN ÜRÜNLER</label>
			</div>
			<hr /> */}
    </div>
  )
}