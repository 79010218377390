import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { IChannelData } from '../models/models'

export class GetNetChannelListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'NetChannel/getNetChannelList',
      requesterInfo,
    })
  }

  public async getNetChannelList(req: any): Promise<IChannelData[]> {
    return await this.postAsync<IChannelData[]>(req)
  }
}
