import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IBanner } from '../models/PaymentModel'

export class GetBannerDetailsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'general/getBannerDetails',
      requesterInfo,
    })
  }

  public async getBannerDetails(request: number[]): Promise<IBanner[]> {
    return await this.postAsync<IBanner[]>(request)
  }
}
