import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { ProductsWithUnspecifiedPropertiesModel } from '../models/models'

export class ProductsWithUnspecifiedPropertiesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/productsWithUnspecifiedProperties',
      requesterInfo,
    })
  }

  public async ProductsWithUnspecifiedProperties(): Promise<ProductsWithUnspecifiedPropertiesModel[]> {
    return await this.getAsync<ProductsWithUnspecifiedPropertiesModel[]>()
  }
}