import React, { useEffect, useRef, useState } from 'react'
import { dataTablesElement, getDateByTimezoneOffset } from "../../../../base/utils/dataHelper"
import IPDatePicker from "../../../../components/IPDatePicker"
import { IOrderList, IUserList } from './models/models'
import { UserOrderReportApi } from "./services/userOrderReportApi"
import InputCampaign from '../../../../components/InputCampaign'


export default function Index() {
  const [userList, setUserList] = useState<IUserList[]>([])
  const [orderList, setOrderList] = useState<IOrderList[]>([])
  const tableRef = useRef<any>()
  const tableRef2 = useRef<any>()
  const [tableBoolean, setTableBoolean] = useState(false)
  const [table2Boolean, setTable2Boolean] = useState(false)
  const postUserOrderReport = async () => {
    const userOrderReportApi: UserOrderReportApi = new UserOrderReportApi({})

    const data = {
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate),
    }
    const res = await userOrderReportApi.postUser(data)
    if (res) {
      if (res.OrderList) {
        if (tableBoolean) {
          tableRef.current?.clear()
          tableRef.current?.rows
            .add(
              res.OrderList.map(dt => {
                return {
                  DealerCode: dt.DealerCode.replace("0000", ""),
                  TheName: dt.TheName,
                  UserName: dt.UserName,
                  OrderNumber: dt.OrderNumber,
                  OrderDate: new Date(dt.OrderDate).toLocaleString('tr-TR'),
                  OrderTransactionNumber: dt.OrderTransactionNumber,
                  SapOrderNumber: dt.SapOrderNumber
                }
              })
            )
            .draw(true)
        } else
          setOrderList(res.OrderList)
      }
      if (res.UserList) {
        if (table2Boolean) {
          tableRef2.current?.clear()
          tableRef2.current?.rows
            .add(
              res.UserList.map(dt => {
                return {
                  DealerSapCode: dt.DealerSapCode.replace("0000", ""),
                  TheName: dt.TheName,
                  LoginDate: new Date(dt.LoginDate).toLocaleDateString('tr-TR'),
                  LoginCount: dt.LoginCount
                }
              })
            )
            .draw(true)
        } else
          setUserList(res.UserList)
      }
    }

  }
  useEffect(() => {
    postUserOrderReport()
  }, [])

  useEffect(() => {
    let dtOrder: any
    let dtUser: any
    if (!table2Boolean && userList && userList.length > 0) {
      setTable2Boolean(true)
      dtUser = syncTable2(userList)
      tableRef2.current = dtUser
    }
    if (!tableBoolean && orderList && orderList.length > 0) {
      setTableBoolean(true)
      dtOrder = syncTable(orderList)
      tableRef.current = dtOrder
    }
  }, [userList, orderList])

  const syncTable2 = (datas: IUserList[]) => {
    const dataTables = dataTablesElement()
    const dt = $(tableRef2.current).DataTable({
      dom: dataTables.dom,
      pageLength: dataTables.pageLength,
      language: dataTables.language,
      buttons: dataTables.buttons,
      data: datas.map(dt => {
        return {
          DealerSapCode: dt.DealerSapCode.replace("0000", ""),
          TheName: dt.TheName,
          LoginDate: new Date(dt.LoginDate).toLocaleDateString('tr-TR'),
          LoginCount: dt.LoginCount
        }
      }),
      columns: [
        {
          title: 'Sap Kodu',
          data: 'DealerSapCode',
        },
        {
          title: 'Bayi Adı',
          data: 'TheName',
        },
        {
          title: 'Giriş Tarihi',
          data: 'LoginDate',
        },
        {
          title: 'Girilen Hesap Sayısı',
          data: 'LoginCount',
        },
      ],
    } as any)

    return dt
  }

  const syncTable = (datas: IOrderList[]) => {
    const dataTables = dataTablesElement()
    const dt = $(tableRef.current).DataTable({
      dom: dataTables.dom,
      pageLength: dataTables.pageLength,
      language: dataTables.language,
      buttons: dataTables.buttons,
      data: datas.map(dt => {
        return {
          DealerCode: dt.DealerCode.replace("0000", ""),
          TheName: dt.TheName,
          UserName: dt.UserName,
          OrderNumber: dt.OrderNumber,
          OrderDate: new Date(dt.OrderDate).toLocaleString('tr-TR'),
          OrderTransactionNumber: dt.OrderTransactionNumber,
          SapOrderNumber: dt.SapOrderNumber
        }
      }),
      columns: [
        {
          title: 'Sap Kodu',
          data: 'DealerCode',
        },
        {
          title: 'Bayi Adı',
          data: 'TheName',
        },
        {
          title: 'Kullanıcı Adı',
          data: 'UserName',
        },
        {
          title: 'Sap Sipariş Numarası',
          data: 'SapOrderNumber',
        },
        {
          title: 'Sipariş Numarası',
          data: 'OrderNumber',
        },
        {
          title: 'İyzico Sipariş Numarası',
          data: 'OrderTransactionNumber',
        },
        {
          title: 'Sipariş Tarihi',
          data: 'OrderDate',
        },
      ],
    } as any)

    return dt
  }

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              <h5>Bayi Login ve Sipariş Raporu</h5>
            </span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-12 col-md-2 mb-2'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-2 mb-2'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>
                {/* <div className='col-lg-3'>
                  <label htmlFor='location' className='form-label'>
                    Başlangıç-Bitiş Tarihi
                  </label>
                  <div className='date'>
                    <IPDatePicker
                      selectsRange={true as any}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={update => {
                        setDateRange(update)
                      }}
                    />
                  </div>
                </div> */}
                <div className='col-lg-1'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <button
                        type='button'
                        onClick={() => {
                          postUserOrderReport()
                        }}
                        className='btn btn-success'
                      >
                        Göster
                      </button>
                    </div>
                  </label>
                </div>
              </div>
              {
                orderList && orderList.length > 0 &&
                <div className='row' style={{ marginTop: "10px" }}>
                  <h6><b>Sipariş Veren Bayi Bilgileri</b></h6>
                  <table id={'datatb'} ref={tableRef} className='table'></table>
                </div>
              }
              {
                userList && userList.length > 0 &&
                <div className='row' style={{ marginTop: "10px" }}>
                  <h6><b>Login Olan Bayi Bilgileri</b></h6>
                  <table id={'datatb'} ref={tableRef2} className='table'></table>
                </div>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
