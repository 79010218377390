import { useEffect, useState } from 'react'
import { Pattern } from '../../base/constants'
import classes from './IPInput.module.scss'

export enum IPInputDataPattern {
  Numeric = 1,
  Alphabetic = 2,
  AlphabeticWithSpaces = 3,
  AlphabeticLowercase = 4,
  AlphabeticLowercaseWithSpaces = 5,
  AlphabeticUppercase = 6,
  AlphabeticUppercaseWithSpaces = 7,
  Alphanumeric = 8,
  AlphanumericWithSpaces = 9,
  AlphanumericLowercase = 10,
  AlphanumericLowercaseWithSpaces = 11,
  AlphanumericUppercase = 12,
  AlphanumericUppercaseWithSpaces = 13,
  Email = 14,
}

interface IPInput extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  type: 'text' | 'email' | 'password' | 'number' | 'tel' | 'date'
  dataPattern?: IPInputDataPattern
  errors?: string
  touched?: boolean
  hideArrows?: boolean
  skipCustomValidation?: boolean
  controlledField?: boolean
}

export default function Input(props: IPInput) {
  const { type, className, errors, touched, hideArrows, dataPattern, ...others } = props

  const [value, setValue] = useState(props.value ? `${props.value}` : '')
  const [pattern, setPattern] = useState<any>(undefined)

  useEffect(() => {
    setValue(props.value ? `${props.value}` : '')
  }, [props.value])

  useEffect(() => {
    if (props.pattern) {
      setPattern(props.pattern)
    } else {
      if (!props.pattern && dataPattern) {
        let _pattern: string = ''

        switch (props.dataPattern) {
          case IPInputDataPattern.Numeric:
            _pattern = Pattern.NUMERIC
            break
          case IPInputDataPattern.Alphabetic:
            _pattern = Pattern.ALPHABETIC
            break
          case IPInputDataPattern.AlphabeticWithSpaces:
            _pattern = Pattern.ALPHABETIC_WITH_SPACES
            break
          case IPInputDataPattern.AlphabeticLowercase:
            _pattern = Pattern.ALPHABETIC_LOWERCASE
            break
          case IPInputDataPattern.AlphabeticLowercaseWithSpaces:
            _pattern = Pattern.ALPHABETIC_LOWERCASE_WITH_SPACES
            break
          case IPInputDataPattern.AlphabeticUppercase:
            _pattern = Pattern.ALPHABETIC_UPPERCASE
            break
          case IPInputDataPattern.AlphabeticUppercaseWithSpaces:
            _pattern = Pattern.ALPHABETIC_UPPERCASE_WITH_SPACES
            break
          case IPInputDataPattern.Alphanumeric:
            _pattern = Pattern.ALPHANUMERIC
            break
          case IPInputDataPattern.AlphanumericWithSpaces:
            _pattern = Pattern.ALPHANUMERIC_WITH_SPACES
            break
          case IPInputDataPattern.AlphanumericLowercase:
            _pattern = Pattern.ALPHANUMERIC_LOWERCASE
            break
          case IPInputDataPattern.AlphanumericLowercaseWithSpaces:
            _pattern = Pattern.ALPHANUMERIC_LOWERCASE_WITH_SPACES
            break
          case IPInputDataPattern.AlphanumericUppercase:
            _pattern = Pattern.ALPHANUMERIC_UPPERCASE
            break
          case IPInputDataPattern.AlphanumericUppercaseWithSpaces:
            _pattern = Pattern.ALPHANUMERIC_UPPERCASE_WITH_SPACES
            break
          case IPInputDataPattern.Email:
            _pattern = Pattern.EMAIL
            break
          default:
            break
        }

        setPattern(_pattern)
      }
    }
  }, [null])

  return (
    <>
      <input
        {...others}
        type={props.type ?? 'text'}
        className={`${classes.Input} ${hideArrows ? classes.hideArrows : ''} ${props.className ?? ''}`}
        value={value}
        pattern={pattern}
        onChange={event => {
          if (props.skipCustomValidation) {
            props.onChange && props.onChange(event)
          } else {
            const newVal = event.target.validity.valid || event.target.value === '' ? event.target.value : value

            if (!props.controlledField) {
              setValue(newVal)
            }

            event.target.value = newVal
            props.onChange && props.onChange(event)
          }
        }}
        onBlur={event => {
          if (props.skipCustomValidation) {
            props.onBlur && props.onBlur(event)
          } else {
            const newVal = event.target.validity.valid || event.target.value === '' ? event.target.value : value

            if (!props.controlledField) {
              setValue(newVal)
            }

            event.target.value = newVal
            props.onBlur && props.onBlur(event)
          }
        }}
      />
      <div className='validationError'>{errors && touched && errors}</div>
    </>
  )
}
