import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'

export class GetDocumentImageApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetDocumentImage',
      requesterInfo,
    })
  }

  public async getImage(belNo: string): Promise<any> {
    return await this.postAsync<any>(belNo)
  }
}
