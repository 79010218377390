import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
export class EditNoteVirtualPosInfosApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/virtualPosInfos/editNote/',
      requesterInfo,
    })
  }

  public async editNoteVirtualPosInfos(id: number, note: string): Promise<any> {
    this.props.url = `${this.props.url}${id}`
    return await this.postAsync<any>(note)
  }
}
