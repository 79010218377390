import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IProductQuotaModel } from '../models/models'

export class GetProductCodeApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/campaignBundle/getProductCode',
      requesterInfo,
    })
  }

  public async getProductCode(): Promise<IProductQuotaModel> {
    return await this.getAsync<IProductQuotaModel>()
  }
}
