import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { RoleUser } from '../models'

export class GetRoleUsersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/userPermission/GetRoleUsers',
      requesterInfo,
    })
  }

  public async getRoleUsers(roleId: number): Promise<RoleUser[]> {
    this.props.url = `${this.props.url}?roleId=${roleId}`
    return await this.getAsync<RoleUser[]>()
  }
}
