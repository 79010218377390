import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfigHelper } from '../../../base/constants'
import { ClientStorage } from '../../../base/storage'
import getTheme, {
  DATAGATE_THEME,
  DATAGATE_THEME_TYPE,
  DEFAULT_THEME,
  DEFAULT_THEME_TYPE,
  DESPEC_THEME,
  DESPEC_THEME_TYPE,
  INDEX_THEME,
  INDEX_THEME_TYPE,
  IThemeConfig,
  NETEX_THEME,
  NETEX_THEME_TYPE,
} from '../../../base/theme/themeConfig'
import { RootState } from '../../app/store'

export interface ThemeState {
  selectedTheme: IThemeConfig
}

const initialState: ThemeState = {
  selectedTheme: getTheme(DEFAULT_THEME), // default theme
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    changeTheme: (
      state,
      action: PayloadAction<
        INDEX_THEME_TYPE | DESPEC_THEME_TYPE | NETEX_THEME_TYPE | DATAGATE_THEME_TYPE | DEFAULT_THEME_TYPE
      >
    ) => {
      let selectedThemeLocal: IThemeConfig

      if ([DEFAULT_THEME, INDEX_THEME, NETEX_THEME, DESPEC_THEME, DATAGATE_THEME].indexOf(action.payload) === -1) {
        selectedThemeLocal = getTheme(DEFAULT_THEME)
      } else {
        selectedThemeLocal = getTheme(action.payload)
      }

      ClientStorage.setItem(ConfigHelper.INDEXPAZAR_SELECTED_THEME, selectedThemeLocal.name)

      state.selectedTheme = selectedThemeLocal
    },
  },
})

export const { changeTheme } = themeSlice.actions

export const selectedTheme = (state: RootState) => state.theme.selectedTheme

export default themeSlice.reducer
