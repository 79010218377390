import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { translate } from '../../../../../base/localization'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../../components/InputCampaign'
import Translations from '../../../../../translations'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import { SendNoPicturesApi } from '../services/SendNoPicturesApi'
import { B2BGeneralReportApi } from '../services/generalB2BReportApi'
import { ProductClickReportApi } from '../services/productClickReportApi'
import { SendMakbuzInfoApi } from '../services/SendMakbuzInfoApi'
import { exportToXLSX, getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import { B2BMixReportApi } from '../services/sendB2BMixReportApi'
import { LoginReportApi } from '../services/loginReportApi'
interface ISendReportsState {
  company: ISelectOption | null
}
function Index(props: BaseComponentWithContextProps) {
  const [selectedReportType, setSelectedReportType] = useState('')

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const t = translate(Translations)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const [companies, setCompanies] = useState<ICompany[]>([])
  const endDateCopy = new Date(endDate)
  endDateCopy.setHours(23, 59, 59)

  const req = {
    StartDate: startDate,
    EndDate: endDateCopy,
  }

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getInitialState = (): ISendReportsState => {
    return {
      company: null,
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ISendReportsState>(intialState)

  useEffect(() => {
    getCompanies()
  }, [])

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    setCompanies(company)
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const sendProductClickReport = async () => {
    props.showLoading()

    const maxDuration = 15 * 24 * 60 * 60 * 1000 // Maximum duration in milliseconds (15 days)
    const startDateTime = getDateByTimezoneOffset(startDate).getTime()
    const endDateTime = new Date(endDate).getTime() // Convert endDate to Date object
    const duration = endDateTime - startDateTime

    if (duration > maxDuration) {
      // If duration exceeds 15 days, show error message and return without calling the API
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Başlangıç ve bitiş tarihleri arasındaki süre 15 günden fazla olamaz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
      return
    }

    // Proceed with sending the report
    const req = {
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: endDate,
      Company: state.company?.value ? Number(state.company.value) : 5,
    }

    try {
      const api: ProductClickReportApi = new ProductClickReportApi({})
      const result = await api.sendMailProductClickReport(req)

      if (result) {
        Swal.fire({
          icon: 'success',
          title: 'Mail Başarıyla Gönderildi.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    } finally {
      props.hideLoading()
    }
  }

  const sendB2BGeneralReport = async () => {
    props.showLoading()

    const maxDuration = 15 * 24 * 60 * 60 * 1000 // Maximum duration in milliseconds (15 days)
    const startDateTime = getDateByTimezoneOffset(startDate).getTime()
    const endDateTime = new Date(endDate).getTime() // Convert endDate to Date object
    const duration = endDateTime - startDateTime

    if (duration > maxDuration) {
      // If duration exceeds 15 days, show error message and return without calling the API
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Başlangıç ve bitiş tarihleri arasındaki süre 15 günden fazla olamaz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
      return
    }

    const req = {
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: endDate,
    }
    const api: B2BGeneralReportApi = new B2BGeneralReportApi({})
    await api
      .sendB2BGeneralReport(req)
      .then(result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Mail Başarıyla Gönderildi.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const sendB2BMixReport = async () => {
    props.showLoading()

    const maxDuration = 15 * 24 * 60 * 60 * 1000 // Maximum duration in milliseconds (15 days)
    const startDateTime = getDateByTimezoneOffset(startDate).getTime()
    const endDateTime = new Date(endDate).getTime() // Convert endDate to Date object
    const duration = endDateTime - startDateTime

    if (duration > maxDuration) {
      // If duration exceeds 15 days, show error message and return without calling the API
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Başlangıç ve bitiş tarihleri arasındaki süre 15 günden fazla olamaz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
      return
    }

    const req = {
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: endDate,
    }
    const api: B2BMixReportApi = new B2BMixReportApi({})
    await api
      .sendB2BMixReport(req)
      .then(result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Mail Başarıyla Gönderildi.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const sendNoPictures = async () => {
    props.showLoading()

    const maxDuration = 15 * 24 * 60 * 60 * 1000 // Maximum duration in milliseconds (15 days)
    const startDateTime = getDateByTimezoneOffset(startDate).getTime()
    const endDateTime = new Date(endDate).getTime() // Convert endDate to Date object
    const duration = endDateTime - startDateTime

    if (duration > maxDuration) {
      // If duration exceeds 15 days, show error message and return without calling the API
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Başlangıç ve bitiş tarihleri arasındaki süre 15 günden fazla olamaz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
      return
    }

    const api: SendNoPicturesApi = new SendNoPicturesApi({})
    await api
      .sendNoPicturesMail()
      .then(result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Mail Başarıyla Gönderildi.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const sendMakbuz = async () => {
    props.showLoading()

    const maxDuration = 15 * 24 * 60 * 60 * 1000 // Maximum duration in milliseconds (15 days)
    const startDateTime = getDateByTimezoneOffset(startDate).getTime()
    const endDateTime = new Date(endDate).getTime() // Convert endDate to Date object
    const duration = endDateTime - startDateTime

    if (duration > maxDuration) {
      // If duration exceeds 15 days, show error message and return without calling the API
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Başlangıç ve bitiş tarihleri arasındaki süre 15 günden fazla olamaz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
      return
    }

    const req = {
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: endDate,
      Company: state.company?.value ? Number(state.company.value) : 5,
    }
    const api: SendMakbuzInfoApi = new SendMakbuzInfoApi({})
    await api
      .sendMakbuzMail(req)
      .then(result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Mail Başarıyla Gönderildi.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const loginReport = async () => {
    const req = {
      Company: state.company?.value
    }
    props.showLoading()
    const api: LoginReportApi = new LoginReportApi({})
    await api
      .sendLoginExcel(req)
      .then(result => {
        if (result) {
          try {
            const data: any[] = []
            result.map(item => {
              return data.push({
                'Bayi Kodu': item.SAPCode,
                'Bayi Adı': item.TheName,
                'Şirket': item.Company,
                'Login': item.Login.toString(),
                'Blokaj': item.Blokaj,
              })
            })
            const today = new Date();
            const formattedDate = today.toLocaleDateString('tr-TR', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            });
            exportToXLSX(data, `${state.company ? state.company.label : 'IndexGrup'} Login Raporu - ${formattedDate}`)
          } catch (err: any) {
            Swal.fire({
              icon: 'error',
              title: err.description,
              allowOutsideClick: true,
              backdrop: true,
            })
          }
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  // Rapor türleri
  const reportTypes = [
    { id: 'b2bGeneral', label: 'B2B Genel Rapor' },
    { id: 'b2bMix', label: 'B2B Günlük Rapor-29' },
    { id: 'productClick', label: 'Ürün Tıklama İstatistikleri' },
    { id: 'noPictures', label: 'Resimleri Olmayan Ürünler' },
    { id: 'makbuz', label: 'Makbuz Raporu' },
    { id: 'login', label: 'Login Raporu' },
    // Eğer daha fazla rapor türü eklemek isterseniz, buraya ekleyebilirsiniz
  ]

  // Radio butonların üçerli gruplara ayrılması
  const groupedRadioButtons: { id: string; label: string; disabled?: boolean }[][] = []
  for (let i = 0; i < reportTypes.length; i += 3) {
    groupedRadioButtons.push(reportTypes.slice(i, i + 3))
  }

  // Radio butonların oluşturulması
  const radioButtons = groupedRadioButtons.map((group, index) => (
    <div className='row mb-3' key={index}>
      {group.map(reportType => (
        <div className='col-md-4' key={reportType.id}>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              name='reportType'
              id={reportType.id}
              value={reportType.id}
              onChange={() => setSelectedReportType(reportType.id)}
              checked={selectedReportType === reportType.id}
              disabled={reportType.disabled}
            />
            <label className='form-check-label' htmlFor={reportType.id}>
              {reportType.label}
            </label>
          </div>
        </div>
      ))}
    </div>
  ))

  const sendReport = () => {
    switch (selectedReportType) {
      case 'b2bGeneral':
        sendB2BGeneralReport()
        break
      case 'b2bMix':
        sendB2BMixReport()
        break
      case 'productClick':
        sendProductClickReport()
        break
      case 'noPictures':
        sendNoPictures()
        break
      case 'makbuz':
        sendMakbuz()
        break
      case 'login':
        loginReport()
        break
      default:
        // Handle case where no report type is selected
        break
    }
  }

  //Raporda Tarih filtresi varsa ekle
  const shouldRenderDateInputs = () => {
    return ['b2bGeneral', 'productClick', 'makbuz', 'b2bMix'].includes(selectedReportType)
  }

  //Raporda Şirket filtresi varsa ekle
  const shouldRenderCompanyInputs = () => {
    return ['productClick', 'login'].includes(selectedReportType)
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12'>
          <div className='card'>
            <div className='card-header'>
              <span className='h4'>B2B Özel Raporları</span>
            </div>
            <div className='card-body'>
              <form className='formbanner' style={{ textAlign: 'center' }}>
                <div className='row'>
                  {/* B2B Genel Rapor */}
                  <div className='mb-3'>{radioButtons}</div>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-lg-3'>
                    {/* Tarih Seçimleri */}
                    {shouldRenderDateInputs() && (
                      <>
                        <div className='mb-3'>
                          <InputCampaign
                            type='date'
                            style={{ width: '100%' }}
                            id='StartDate'
                            name='StartDate'
                            label={'Başlangıç Tarihi'}
                            value={toDateTimeLocal(startDate)}
                            onChange={e => getDate(e.target.value, 'Start')}
                            isActive
                          />
                        </div>
                        <div className='mb-3'>
                          <InputCampaign
                            style={{ width: '100%' }}
                            type='date'
                            id='EndDate'
                            name='EndDate'
                            label={'Bitiş Tarihi'}
                            value={toDateTimeLocal(endDate)}
                            onChange={e => getDate(e.target.value, 'End')}
                            isActive
                          />
                        </div>
                      </>
                    )}
                    {/* Şirket Seçimi */}
                    {shouldRenderCompanyInputs() && (
                      <div className='mb-3'>
                        <IPSelectBox
                          isClearable
                          options={companies
                            .filter(com => com.Name !== 'Index Group').sort((a, b) => a.Code - b.Code)
                            .map(item => ({
                              label: item.Name,
                              value: `${item.Id}`,
                            }))}
                          value={state.company}
                          placeholder={'Şirket'}
                          onChangeSingle={option => setState({ company: option })}
                        />
                        <label style={{ display: selectedReportType === 'login' ? 'flex' : 'none', color: 'red', textAlign: 'left' }}>Boş bırakılırsa tüm şirketler</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className='row justify-content-center'>
                  <div className='col-lg-3'>
                    {/* Mail Gönder Butonu */}
                    <div className='mb-3'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        style={{ width: '100%', display: selectedReportType === 'login' ? 'none' : '' }}
                        text={'Mail Gönder'}
                        onClick={sendReport}
                        disabled={!selectedReportType || selectedReportType === 'login'}
                      />
                    </div>
                    <div className='mb-3'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        style={{ width: '100%', display: selectedReportType != 'login' ? 'none' : '' }}
                        text={'Excele Kaydet'}
                        onClick={sendReport}
                        disabled={!selectedReportType || selectedReportType !== 'login'}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(Index)
