import React, { useState, useEffect } from 'react'
import { useCustomReducer } from '../../../base/customHooks'
import { downloadFileFromUrl, getDateByTimezoneOffset } from '../../../base/utils/dataHelper'
import IPButton from '../../../components/IPButton'
import IPModal from '../../../components/IPModal'
import {
  IMyOffers,
  IMyOffersRequest,
  IOfferSendMail,
  IProducts,
} from './models/responseModel'
import { GetMyOffersApi } from './service/myOffers'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { MyofferByIdApi } from './service/myofferByIdApi'
import { IException } from '../../../base/models'
import IPInput from '../../../components/IPInput'
import { OfferSendMailApi } from './service/offerSendMailApi'
import { DeleteOfferApi } from './service/deleteOfferApi'
import InputCampaign from '../../../components/InputCampaign'

interface MyOffers {
  showDetails: boolean
  showDealerDetails: boolean
  offerData: IMyOffers[]
  productDetail: IProducts[]
  showSendMailModal: boolean
  sendMail: string
  offerId: number
}

function MyOffersAdmin(props: BaseComponentWithContextProps) {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const getInitialState = (): MyOffers => {
    return {
      showDetails: false,
      showDealerDetails: false,
      offerData: [],
      productDetail: [],
      showSendMailModal: false,
      sendMail: "",
      offerId: 0
    }
  }
  useEffect(() => {
    getMyOffers();
  }, [])

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<MyOffers>(intialState)
  const getMyOffers = async () => {
    props.showLoading();
    const getMyOffersApi: GetMyOffersApi = new GetMyOffersApi({})
    const data: IMyOffersRequest = {
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate),
    }
    getMyOffersApi.getMyOffers(data).then(async res => {
      if (res) {
        setState({ offerData: res })
        return props.hideLoading()
      }
    }).catch((err: any) => {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    })
  }

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  const excelToOffer = (id: number) => {
    props.showLoading();
    const excelToOfferApi: MyofferByIdApi = new MyofferByIdApi({})
    excelToOfferApi
      .myofferById(id)
      .then((res: any) => {
        props.hideLoading();
        if (res) {
          downloadFileFromUrl('Tekliflerim', res.FileUrl)

        } else {
          Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Excel Oluşturulamadı!',
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        props.hideLoading();
      })
  }

  const deleteOffer = (id: number) => {
    props.showLoading();
    const deleteOfferApi: DeleteOfferApi = new DeleteOfferApi({})
    deleteOfferApi
      .deleteOffer(id)
      .then((res: any) => {
        props.hideLoading();
        if (res) {
          Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Teklif Silindi',
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
          const deleted = state.offerData.filter(x => x.Id !== id)
          setState({ offerData: deleted })
        } else {
          Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Excel Oluşturulamadı!',
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        props.hideLoading();
      })
  }

  const offerSendMail = async () => {
    const req: IOfferSendMail = {
      Mail: state.sendMail,
      OfferId: state.offerId
    }
    props.showLoading();
    const offerSendMailApi: OfferSendMailApi = new OfferSendMailApi({})
    offerSendMailApi
      .offerSendMail(req)
      .then((res: any) => {
        props.hideLoading();
        if (res) {
          Swal.fire({
            icon: 'success',
            title: `${state.sendMail} adresine mail gönderildi`,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading();
          setState({ showSendMailModal: false, sendMail: "" })
        } else {
          Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Teklif Oluşturulamadı!',
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
          props.hideLoading();
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        props.hideLoading();
      })
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div>
      <h4>Tekliflerim</h4>
      <div className='row mb-3'>
        <div className='col-12 col-md-3 mb-3'>
          <InputCampaign
            type='date'
            id='StartDate'
            name='StartDate'
            label={'Başlangıç Tarihi'}
            value={toDateTimeLocal(startDate)}
            onChange={e => getDate(e.target.value, "Start")}
            isActive
          />
        </div>
        <div className='col-12 col-md-3 mb-3'>
          <InputCampaign
            type='date'
            id='EndDate'
            name='EndDate'
            label={'Bitiş Tarihi'}
            value={toDateTimeLocal(endDate)}
            onChange={e => getDate(e.target.value, "End")}
            isActive
          />
        </div>
        <div className='col-12 col-md-3 mb-3'>
          <IPButton text='Göster' onClick={() => getMyOffers()} />
        </div>
      </div>

      <div>
        {state.offerData && state.offerData.length === 0 ? "Veri Bulunamadı" :
          <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover m-t-10'>
            <thead>
              <tr className='EInvoicetableDescription'>
                <td scope='col'>Teklif Tarihi</td>
                <td scope='col'>Fiyat Türü</td>
                <td scope='col'>Cari Fiyat Türü</td>
                <td scope='col'>Detay</td>
                <td scope='col'>Teklif Gönder</td>
                <td scope='col'>Excele At</td>
                <td>İşlemler</td>
              </tr>
            </thead>
            <tbody>
              {state.offerData.map((item: IMyOffers) => (
                <tr key={item.Id}>
                  <td> {item.Date ? new Date(item.Date).toLocaleDateString('tr-TR') : '-'}</td>
                  <td>
                    {item.PriceType === 1
                      ? 'Normal'
                      : item.PriceType === 0
                        ? 'Özel'
                        : item.PriceType === 2
                          ? 'Son Kullanıcı'
                          : ''}
                  </td>
                  <td>
                    {item.CurrencyType === 0
                      ? 'TL'
                      : item.CurrencyType === 1
                        ? 'Dolar'
                        : item.CurrencyType === 2
                          ? 'Euro'
                          : ''}
                  </td>
                  <td>
                    <IPButton
                      onClick={() => {
                        setState({ productDetail: item.Products, showDealerDetails: true })
                      }}
                      text='Detay'
                    ></IPButton>
                  </td>
                  <td>
                    <IPButton
                      name='offerSendMailModal'
                      onClick={() => {
                        setState({ showSendMailModal: true, offerId: item.Id })
                      }}
                      text='Teklif Gönder'
                    />
                  </td>
                  <td>
                    <IPButton
                      onClick={() => {
                        excelToOffer(item.Id)
                      }}
                      text='Excele At'
                    ></IPButton>
                  </td>
                  <td>
                    <a
                      data-action-type='delete'
                      role='button'
                      style={{ cursor: 'pointer' }}
                      data-toggle='tooltip'
                      data-original-title="Sil"
                      onClick={() => deleteOffer(item.Id)}
                    >
                      <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
        <IPModal
          show={state.showDealerDetails}
          onClose={() => setState({ showDealerDetails: false })}
          onConfirm={() => window.print()}
          confirmText='Yazdır'
          width={1200}
        >
          <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
            <thead>
              <tr className='EInvoicetableDescription'>
                <td scope='col' style={{ "padding": "10px" }}>Resim</td>
                <td scope='col' style={{ "padding": "10px" }}>Ürün Kodu</td>
                <td scope='col' style={{ "padding": "10px" }}>Ürün Adı</td>
                <td scope='col' style={{ "padding": "10px" }}>Miktar</td>
                <td scope='col' style={{ "padding": "10px" }}>Birim Fiyat</td>
                <td scope='col' style={{ "padding": "10px" }}>Fiyat</td>
              </tr>
            </thead>
            <tbody>
              {state.productDetail &&
                state.productDetail.map((item: IProducts) => (
                  <tr key={item.ProductId}>
                    <td style={{ "padding": "10px" }}>
                      <img style={{ width: '50px' }} src={item.Product.Image} />
                    </td>
                    <td style={{ "padding": "10px" }}>{item.Product.ProductCode}</td>
                    <td style={{ "padding": "10px" }}>{item.Product.Name}</td>
                    <td style={{ "padding": "10px" }}>{item.Quantity}</td>
                    <td style={{ "padding": "10px" }}>
                      <span className='price'>{item.UnitPrice}</span>
                    </td>
                    <td style={{ "padding": "10px" }}>
                      <span className='price'>{item.TotalPrice}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </IPModal>
        <IPModal
          show={state.showSendMailModal}
          onClose={() => {
            setState({ showSendMailModal: false })
          }}
          width={400}
          onConfirm={offerSendMail}
          confirmText={'Mail Gönder'}
        >
          <div className='form-group col-md-12 align-items-center '>
            <label className='text-sm-left'>Mail Adresi</label>
            <IPInput
              type='text'
              className='form-control'
              name='sendMail'
              required
              value={state.sendMail}
              onChange={e => {
                setState({ sendMail: e.target.value })
              }}
            />
          </div>
        </IPModal>
      </div>
    </div >
  )
}
export default componentWithContext(MyOffersAdmin)