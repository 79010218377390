import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IDppManagementPageData } from '../models/models'

export class ListDppManagementPageDataApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'DppEtCetera/listDppManagementPageData',
      requesterInfo,
    })
  }

  public async listDppManagementPageData(): Promise<IDppManagementPageData> {
    return await this.getAsync<IDppManagementPageData>()
  }
}
