import * as yup from 'yup'

const validations = yup.object().shape({
  Company: yup.object().shape({
    label: yup.string().required('Şirket seçmediniz!'),
    value: yup.number().required('Şirket seçmediniz!'),
  }),
  Subject: yup.object().shape({
    label: yup.string().required('Konu seçmediniz!'),
    value: yup.number().required('Konu seçmediniz!'),
  }),
  Email: yup.string().required('Email adresi girmediniz!'),
  Body: yup.string().required('Lütfen iletinizi giriniz!'),
})

export default validations
