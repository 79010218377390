import { IRequesterInfo } from '../../../../base/models/proxyModels'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { DealerForPagePathRequest, DealerForPagePathResponse } from './models'

export class PageAnalysisService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GA4Reporting/DealerForPagePath',
      requesterInfo,
    })
  }

  public async DealerForPagePath(req: DealerForPagePathRequest): Promise<DealerForPagePathResponse[]> {
    return await this.postAsync<DealerForPagePathResponse[]>(req)
  }
}
