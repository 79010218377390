import React from 'react'
import Header from './components/client/Header'
import Footer from './components/client/Footer'
import { componentWithContext } from '../base/customHooks/componentHOC'

const ClientLayout = (props: any) => {
  return (
    <React.Fragment>
      <Header theme={props.theme} />
      <div style={{ paddingTop: props.user.Remotable ? '0px' : '0px', minHeight: '90vh' }}>{props.children}</div>
      <Footer />
    </React.Fragment>
  )
}

export default componentWithContext(ClientLayout)
