import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'
import { IAddData } from '../models/models'

export class SaveUpdateDppManagementPageApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'DppEtCetera/saveUpdateDppManagementPage',
      requesterInfo,
    })
  }

  public async saveUpdateDppManagementPage(request: IAddData): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
