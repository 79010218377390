import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPDatePicker from '../../../../../components/IPDatePicker'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { PostBannerApi } from '../../BannersCrud/services/postBannerApi'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPRadio from '../../../../../components/IPRadio'
import IPTextarea from '../../../../../components/IPTextarea'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'

function AdsAndCampingAdd() {
  const t = translate(Translations)
  const venueOptions = [
    {
      value: 1,
      label: 'PopUp',
    },
    {
      value: 2,
      label: 'AçBox',
    },
  ]

  const { validateField, setFieldValue, setFieldTouched, setValues, values } = useFormik({
    initialValues: {
      StartDate: new Date(),
      EndDate: new Date(),
      Venues: [],
    },

    onSubmit: async values => {
      values['Location'] = values['Location']['value']
    },
  })

  return (
    <div>
      <div className='container mb-3'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header'>
              <span className='h4'>
                {t('pages.admin.homeAndSiteOperation.AdsAndCampaingCrud.AdsAndCampaingAdd.header')}
              </span>
            </div>
            <div className='card-body'>
              <form id='id1' className='formform' noValidate>
                <div className='etkin'>
                  <label className='p-switch switch-color-success'>
                    <label htmlFor='active' className='float-left mr-4'>
                      {t('pages.admin.homeAndSiteOperation.AdsAndCampaingCrud.AdsAndCampaingAdd.active')}
                    </label>
                    <IPCheckbox name='Active' checked={false} />
                  </label>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.AdsAndCampaingCrud.AdsAndCampaingAdd.topic')}
                    </label>
                    <IPInput type='text' id='Title' className='form-control' name='Title' />
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.AdsAndCampaingCrud.AdsAndCampaingAdd.venue')}
                    </label>
                    <IPSelectBox
                      isMulti
                      isClearable
                      className='Venues'
                      name='Venues'
                      onChangeMulti={(option: ISelectOption[]) => {
                        //TO DO
                        setFieldValue('Venues', option)
                      }}
                      options={venueOptions}
                      value={values.Venues as any}
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-4'>
                    <label className='labeltext'>Cari Kodu</label>
                    <div className='d-flex pr-3'>
                      <div className='col-lg-4 mt-1'>
                        <IPRadio text='Dahil' type='radio' name='radio3' />
                        <IPRadio text='Hariç' type='radio' name='radio3' />
                      </div>
                      <div className='w-100'>
                        <IPTextarea />
                      </div>
                    </div>
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-group col-md-4'>
                    <label className='labeltext'>Cari Grup Kodu</label>
                    <div className='d-flex pr-3'>
                      <div className='col-lg-4 mt-1'>
                        <IPRadio text='Dahil' type='radio' name='radio3' />
                        <IPRadio text='Hariç' type='radio' name='radio3' />
                      </div>
                      <div className='w-100'>
                        <IPTextarea />
                      </div>
                    </div>
                    <p className='validation-error'> </p>
                  </div>
                  <div className='form-group col-md-4'>
                    <label className='labeltext'>Cari Alt Grup Kodu</label>
                    <div className='d-flex pr-3'>
                      <div className='col-lg-4 mt-1'>
                        <IPRadio text='Dahil' type='radio' name='radio3' />
                        <IPRadio text='Hariç' type='radio' name='radio3' />
                      </div>
                      <div className='w-100'>
                        <IPTextarea />
                      </div>
                    </div>
                    <p className='validation-error'> </p>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.addImage')}
                    </label>
                    <input
                      type='file'
                      name='Image'
                      className='form-control'
                      id='customFile'
                      onChange={event => {
                        let reader = new FileReader()
                        let Image = event.target.files![0]
                        if (Image) {
                          reader.readAsDataURL(Image)
                          setFieldValue('Image', Image)
                        }
                      }}
                    />
                  </div>
                </div>
                {/* <div className="form-row">
                  <div className="form-group col-md-4">
                    <label htmlFor="Bundle" className="labeltext">
                      {t('pages.admin.productOperation.campaignBundleCrud.campaignBundleAdd.start')}
                    </label>
                    <IPDatePicker
                      selected={values.StartDate}
                      onChange={}
                      timeInputLabel="Saat :"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="Bundle" className="labeltext">
                      {t('pages.admin.productOperation.campaignBundleCrud.campaignBundleAdd.end')}
                    </label>
                    <IPDatePicker
                      selected={values.EndDate}
                      onChange={}
                      timeInputLabel="Saat :"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      showTimeInput
                    />
                  </div>
                </div> */}
                <div className='form-row'>
                  <div className='form-group col-md-12'>
                    <IPButton type='button' className='btn btn-success w-25 mb-5 float-right' text='Ekle' />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AdsAndCampingAdd
