import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IUserPermissionModel } from '../models'

export class GetUserPermissionsByUserIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/userPermission/GetUserPermissionByUserId',
      requesterInfo,
    })
  }

  public async getUserPermissions(userId: number): Promise<IUserPermissionModel[]> {
    this.props.url = `${this.props.url}/${userId}`
    return await this.getAsync<IUserPermissionModel[]>()
  }
}
