import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IDetail } from '../models/models'

export class GetBrandListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'brand/HomePageBannerBrands',
      requesterInfo,
    })
  }

  public async getBrandList(pageSize: number, id: number): Promise<IDetail> {
    this.props.url = `${this.props.url}/${id}/${pageSize}/IsHomePage`
    return await this.getAsync<IDetail>()
  }
}
