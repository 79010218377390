import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'

export class OpenInvoicesExcelApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'excel/openInvoices',
      requesterInfo,
    })
  }

  public async openInvoicesExcel(request: number): Promise<any> {
     this.props.url = `${this.props.url}?companyCode=${request}`
    return await this.postAsync<any>(request)
  }
}
