import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IProductLink } from '../models/models'

export class ProductLinkUpdateApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/productLink/Update',
      requesterInfo,
    })
  }

  public async productLinkUpdate(request: IProductLink): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
