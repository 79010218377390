import React, { useState, useEffect } from 'react'
import './style.scss'
import IPImage from '../../../components/IPImage'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { GetMicrosoftContentApi } from '../services/GetMicrosoftContentApi'
import Swal from 'sweetalert2'
import { IMicrosoft } from '../models/models'
import { useCustomReducer } from '../../../base/customHooks'
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { css } from '@emotion/css'
import IPModal from '../../../components/IPModal'

interface IMicrosoftState {
  microsoftData?: IMicrosoft
}

function Microsoft(props: BaseComponentWithContextProps) {
  const [buttonMicrosoft, setButtonMicrosoft] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedDetailImageUrl, setSelectedDetailImageUrl] = useState('')
  const [selectedlink, setSelectedLink] = useState('')

  const getInitialState = (): IMicrosoftState => {
    return {}
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IMicrosoftState>(intialState)

  useEffect(() => {
    getMicrosoft()
  }, [])

  const getMicrosoft = async () => {
    props.showLoading()
    const api: GetMicrosoftContentApi = new GetMicrosoftContentApi({})
    api
      .getMicrosoftContent()
      .then((res: IMicrosoft) => {
        setState({ microsoftData: res })
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const openModal = (link, detailImage) => {
    setSelectedDetailImageUrl(detailImage)
    setModalIsOpen(true)
    setSelectedLink(link)
  }

  const closeModal = () => {
    setModalIsOpen(false)

    setTimeout(() => {
      setSelectedDetailImageUrl('')
      setSelectedLink('')
    }, 300)
  }

  return (
    <div className='container current-account'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          {state.microsoftData && (
            <div className='card-body'>
              {state.microsoftData && (
                <Swiper
                  cssMode={true}
                  navigation={true}
                  keyboard={true}
                  pagination={{ clickable: true }}
                  observer={true}
                  observeParents={true}
                  modules={[Navigation, Mousewheel, Keyboard, Pagination]}
                  className='mySwiper'
                  style={{ height: 'auto' }}
                >
                  {
                    // Area to show slides which connected to backend dynamic
                    state.microsoftData.SlideMenu?.map((slide, index) =>
                      slide.Link ? (
                        <SwiperSlide key={index}>
                          <a href={slide.Link}>
                            <IPImage
                              className='banner'
                              style={{ height: '100%', padding: '5px 0 0 0' }}
                              src={slide.Image}
                              alt=''
                            />
                          </a>
                        </SwiperSlide>
                      ) : (
                        <SwiperSlide key={index}>
                          <IPImage
                            className='banner'
                            style={{ height: '100%', padding: '5px 0 0 0' }}
                            src={slide.Image}
                            alt=''
                          />
                        </SwiperSlide>
                      )
                    )
                  }
                  {/* Static Slide Area */}
                  <SwiperSlide>
                    <a href='/products?brand=MICROSOFT&seller=default' target='_blank'>
                      <IPImage
                        className='banner'
                        style={{ height: '100%', padding: '5px 0 0 0' }}
                        src='/images/Microsoft/Ms_banner.jpg'
                        alt=''
                      />
                    </a>
                  </SwiperSlide>
                </Swiper>
              )}
              {/* Button Set Area */}
              <div className='row m-t-10 mb-5'>
                <div className='col d-flex card-mobile-resize justify-content-center'>
                  <div className='room me-5' onClick={() => setButtonMicrosoft('Mc_Kampanya')}>
                    <div className='room-image text-center image-hover'>
                      <IPImage
                        className='Mc_Kampanya Mc_Kampanya-original'
                        src='/images/Microsoft/microsoftkampanyalar.png'
                        alt=''
                      />
                      <IPImage
                        className='Mc_Kampanya Mc_Kampanya-hover'
                        src='/images/Microsoft/microsoftkampanyalar.png'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='room' onClick={() => setButtonMicrosoft('Mc_Hedef')}>
                    <div className='room image-hover'>
                      <div className='room-image text-center'>
                        <IPImage
                          className='Mc_Hedef Mc_Hedef-original'
                          src='/images/Microsoft/microsoftyazilimhedeftakip.png'
                          alt=''
                        />
                        <IPImage
                          className='Mc_Hedef Mc_Hedef-hover'
                          src='/images/Microsoft/microsoftyazilimhedeftakip.png'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div className='room' onClick={() => setButtonMicrosoft('Mc_Proje')}>
                    <div className='room ms-5 text-center  image-hover'>
                      <div className='room-image text-center'>
                        <IPImage
                          className='Mc_Proje Mc_Proje-original'
                          src='/images/Microsoft/microsofprojekaydi.png'
                          alt=''
                        />
                        <IPImage
                          className='Mc_Proje Mc_Proje-hover'
                          src='/images/Microsoft/microsofprojekaydi.png'
                          alt=''
                        />
                      </div>
                    </div>
                  </div>
                  <div className='room ms-5' onClick={() => setButtonMicrosoft('Mc_Akademi')}>
                    <div className='room-image text-center image-hover'>
                      <IPImage
                        className='Mc_Akademi Mc_Akademi-original'
                        src='/images/Microsoft/microsofakademi.png'
                        alt=''
                      />
                      <IPImage
                        className='Mc_Akademi Mc_Akademi-hover'
                        src='/images/Microsoft/microsofakademi.png'
                        alt=''
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12 ms-2 m-t-20'>
                  {buttonMicrosoft === 'Mc_Kampanya' ? (
                    <>
                      <h3 className='text-center'>Microsoft Kampanyalar</h3>
                      <hr />
                      <div className='row'>
                        {/* {
                          state.microsoftData && state.microsoftData.Campaigns && state.microsoftData.Campaigns.length > 0 &&
                          state.microsoftData.Campaigns.map((camp, index) => (
                            <div className='col-md-6' key={index}>
                              <IPImage src={camp.Image} alt='' />
                            </div>
                          ))
                        } */}

                        <div className='card-body'>
                          <div className='row m-t-10'>
                            {state.microsoftData &&
                              state.microsoftData.Campaigns &&
                              state.microsoftData.Campaigns.length > 0 &&
                              state.microsoftData.Campaigns.map((camp, index) => (
                                <div
                                  className='col-12 col-md-6 col-xl-3 card-mobile-resize'
                                  style={{ marginBottom: '10px' }}
                                  key={index}
                                >
                                  <div
                                    className={`d-flex flex-column justify-content-between campaign ${css`
                                      padding: 20px;
                                    `}`}
                                  >
                                    <div
                                      onClick={() => openModal(camp.Link, camp.DetailImage ? camp.DetailImage : '')}
                                      style={{ cursor: 'pointer' }}
                                    >
                                      <IPImage src={camp.Image} alt='' className='campaign' />
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <br></br>

                          <IPModal
                            show={modalIsOpen}
                            onClose={closeModal}
                            width='auto'
                            maxWidth={952}
                            link={selectedlink}
                            confirmText='Ürünlere Git'
                          >
                            <div className='p-3'>
                              <IPImage
                                src={selectedDetailImageUrl || '/images/products/Resim_yok-01.jpg'}
                                alt='ESD'
                                className='campaign'
                                sizes='(max-width: 952px) 100vw, auto'
                                loading='eager'
                              />
                            </div>
                          </IPModal>
                        </div>
                      </div>
                    </>
                  ) : buttonMicrosoft === 'Mc_Hedef' ? (
                    <>
                      <h3 className='text-center'>Microsoft Yazılım Hedef Takip</h3>
                      <hr />
                      <span className='h5 text-center'>
                        <h5> Microsoft Yazılım için Hedef takibiniz bulunmamaktadır.</h5>
                        <h5>
                          {' '}
                          Microsoft Hedef Programı Hakkında Detaylı Bilgi için{' '}
                          <a href='mailto: microsoft@index.com.tr'>microsoft@index.com.tr</a> adresine mail
                          atabilirsiniz.
                        </h5>
                      </span>
                    </>
                  ) : buttonMicrosoft === 'Mc_Proje' ? (
                    <>
                      <h3 className='text-center'>Microsoft Proje Kaydı</h3>
                      <hr />
                      <span className='h5 text-center'>
                        <h5> Kayıt Bulunamadı!</h5>
                      </span>
                    </>
                  ) : buttonMicrosoft === 'Mc_Akademi' ? (
                    <>
                      <h3 className='text-center'>Microsoft Akademi</h3>
                      <hr />
                      <div className='row m-t-20'>
                        {state.microsoftData &&
                          state.microsoftData.Academy &&
                          state.microsoftData.Academy.length > 0 &&
                          state.microsoftData.Academy.map((academy, index) => (
                            <div className='row card-mobile-resize' key={index}>
                              <div
                                className='most-popular-products'
                                style={{
                                  minHeight: '210px',
                                  height: '210px',
                                }}
                              >
                                <div className='room'>
                                  <div className='room-image text-center'>
                                    <table className='MicrosoftAkademi-table'>
                                      <tbody>
                                        <tr>
                                          <td className='academy-column'>
                                            <div className='seed'>
                                              {academy.Link ? (
                                                <a
                                                  href={academy.Link}
                                                  target='_blank'
                                                  rel='noreferrer'
                                                  className='file'
                                                >
                                                  {academy.Image ? (
                                                    <IPImage
                                                      src={academy.Image}
                                                      alt=''
                                                      style={{ width: '180px', minWidth: '180px' }}
                                                    />
                                                  ) : (
                                                    ''
                                                  )}
                                                </a>
                                              ) : academy.Image ? (
                                                <IPImage
                                                  src={academy.Image}
                                                  alt=''
                                                  style={{ width: '180px', minWidth: '180px' }}
                                                />
                                              ) : (
                                                ''
                                              )}
                                              {/* <p style={{ color: 'red', width: '120px' }}>{academy.Name}</p> */}
                                            </div>
                                          </td>
                                          {/* Static Description area  */}
                                          <td className='description-column'>
                                            <div
                                              className='description'
                                              style={{ paddingLeft: '30px', textAlign: 'start' }}
                                            >
                                              {academy.Id === 10 && (
                                                <p style={{ color: 'black', fontSize: '13px' }}>
                                                  Microsoft ExpertZone eğitim portalına kayıt olarak faydalı içerikler
                                                  hakkında bilgi edinebilir, sertifika alabilir, puan ve rozetler
                                                  kazanarak profilinizi zengin hale getirebilirsiniz.
                                                  <br />
                                                  Microsoft ExpertZone hakkında daha detaylı bilgi için{' '}
                                                  <a
                                                    href='https://expertzone.microsoft.com'
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    style={{ color: 'black', fontWeight: 'bold' }}
                                                  >
                                                    Tıklayınız!
                                                  </a>
                                                </p>
                                              )}
                                              {academy.Id === 12 && (
                                                <p style={{ color: 'black', fontSize: '13px' }}>
                                                  Elektronik Lisansı tercih ettiğiniz için teşekkür ederiz!
                                                  <br />
                                                  ESD Kurulum aşamasına geçmeden önce yapmanız gerekenleri öğrenmek için{' '}
                                                  <a
                                                    href='\assetWeb\docs\ESDKurulumKilavuzu.pdf'
                                                    target='_blank'
                                                    style={{ color: 'black', fontWeight: 'bold' }}
                                                  >
                                                    Tıklayınız!
                                                  </a>
                                                </p>
                                              )}
                                              {academy.Id === 13 && (
                                                <p style={{ color: 'black', fontSize: '13px' }}>
                                                  Daha iyi işbirliği ve üretkenlik için Office 21 güncellendi! Microsoft
                                                  365 Business Apps, Microsoft 365 Business Basic, Microsoft 365
                                                  Business Standard ve Microsoft 365 Business Premium hakkında detaylı
                                                  bilgiler için{' '}
                                                  <a
                                                    href='\assetWeb\docs\Office_2021.pdf'
                                                    target='_blank'
                                                    style={{ color: 'black', fontWeight: 'bold' }}
                                                  >
                                                    Tıklayınız!
                                                  </a>
                                                </p>
                                              )}
                                              {academy.Id === 18 && (
                                                <p style={{ color: 'black', fontSize: '13px' }}>
                                                  Microsoft, dünyadaki tüm kullanıcıların ve organizasyonların daha
                                                  fazlasını başarabilmesini sağlamak ve onları güçlü kılmak adına
                                                  geçmişte olduğu gibi bugün ve yarın da yanınızdadır!
                                                  <br />
                                                  Microsoft Ürünleri hakkında daha detaylı bilgi için{' '}
                                                  <a
                                                    href='/assetWeb/docs/microsoftMektup.pdf'
                                                    target='_blank'
                                                    style={{ color: 'black', fontWeight: 'bold' }}
                                                  >
                                                    Tıklayınız!
                                                  </a>
                                                </p>
                                              )}
                                              {academy.Id === 14 && (
                                                <p style={{ color: 'black', fontSize: '13px' }}>
                                                  Bu bölümde temel lisanslamalar nelerdir ve neden orijinal yazılım
                                                  tercih etmeniz gerektiğine dair bilgilere ulaşabilirsiniz.
                                                  <br />
                                                  Detaylar için{' '}
                                                  <a
                                                    href='/assetWeb\docs\TemelLisanslamaveOrijinalYazilim.pdf'
                                                    target='_blank'
                                                    style={{ color: 'black', fontWeight: 'bold' }}
                                                  >
                                                    Tıklayınız!
                                                  </a>
                                                </p>
                                              )}
                                              {academy.Id === 15 && (
                                                <p style={{ color: 'black', fontSize: '13px' }}>
                                                  Bu bölümde Windows 11 Ticari İşletmeler için Hibrit Çalışma Alanı
                                                  Çözümleri ile Üretkenliği, İşbirliğini, tutarlılığı, güvenliği ön
                                                  planda tutmayı amaçlarken, aynı zamanda Tüketici için Sadelik,
                                                  Bağlantıda Kalma, İçerik Zenginliği, Oyun platformlarına ulaşım
                                                  kolaylığı sağlar.
                                                  <br />
                                                  Detaylar için{' '}
                                                  <a
                                                    href='\assetWeb\docs\Windows11.pdf'
                                                    target='_blank'
                                                    style={{ color: 'black', fontWeight: 'bold' }}
                                                  >
                                                    Tıklayınız!
                                                  </a>
                                                </p>
                                              )}
                                              {academy.Id === 11 && (
                                                <p style={{ color: 'black', fontSize: '13px' }}>
                                                  Bu bölümde Neden OEM Windows Server 2022 tercih etmeniz gerektiğine
                                                  dair bilgilere ulaşabilirsiniz.
                                                  <br />
                                                  Detaylar için{' '}
                                                  <a
                                                    href='https://remote.indexgrup.com/content/documents/DppDoc/Server2022-pdf.pdf'
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    style={{ color: 'black', fontWeight: 'bold' }}
                                                  >
                                                    Tıklayınız!
                                                  </a>
                                                </p>
                                              )}
                                              {academy.Id === 16 && (
                                                <p style={{ color: 'black', fontSize: '13px' }}>
                                                  Windows 11 belirtimlerini, özelliklerini ve bilgisayar
                                                  gereksinimlerini incelemek için{' '}
                                                  <a
                                                    href='https://www.microsoft.com/tr-tr/windows/windows-11-specifications#table1'
                                                    target='_blank'
                                                    rel='noreferrer'
                                                    style={{ color: 'black', fontWeight: 'bold' }}
                                                  >
                                                    Tıklayınız!
                                                  </a>
                                                </p>
                                              )}
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Microsoft)
