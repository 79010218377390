import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IDashBoardSapOrderDataRequest, IDashBoardSapOrderDataResponse } from '../models/models'

export class DashBoardSapOrderDataApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'general/dashBoardSapOrderData',
      requesterInfo,
    })
  }

  public async dashBoardSapOrderData(request: IDashBoardSapOrderDataRequest): Promise<IDashBoardSapOrderDataResponse> {
    return await this.postAsync<IDashBoardSapOrderDataResponse>(request)
  }
}
