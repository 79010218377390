import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IBrand } from '../models/models'

export class GetBrandApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/brand/list',
      requesterInfo,
    })
  }

  public async getBrand(): Promise<IBrand[]> {
    return await this.getAsync<IBrand[]>()
  }
}
