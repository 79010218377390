import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IProductColorRequest } from '../models/models'

export class UpdateProductColorApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/color/updateColor',
      requesterInfo,
    })
  }

  public async updateProductColor(request: IProductColorRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
