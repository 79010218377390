import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { OrderMailRequest } from '../models/orderExcel'

export class OrderMailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'order/emailTheOrder',
      requesterInfo,
    })
  }

  public async orderSendMail(request: OrderMailRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
