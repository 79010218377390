import { useEffect, useState } from 'react'
import { translate } from '../../../../../base/localization'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import InputCampaign from '../../../../../components/InputCampaign'
import Translations from '../../../../../translations'
import ChartsPage2 from '../../../Dashboard/IChartsBar/ChartBar2'
import { ITable } from '../models/models'
import { AvgSessionsApi } from '../services/avgSessionsApi'
import { SessionsPerUserApi } from '../services/sessionsPerUserApi'
import { SessionsPostApi } from '../services/sessionsPostApi'
import { TrafficSourcesApi } from '../services/trafficSourcesApi'

function Analytics() {
  const t = translate(Translations)

  const [state, setState] = useState<ITable[]>([])

  //const [dateRange, setDateRange] = useState<any>([new Date(), new Date()])
  //const [startDate, endDate] = dateRange
  const [selectedDayCount, setSelectedDayCount] = useState<number>(1)
  const [chartData, setchartData] = useState<any>([{ DateValue: new Date().toDateString(), Value: '60' }])
  const [perUserData, setPerUserData] = useState<any>([{ DateValue: new Date().toDateString(), Value: '60' }])
  const [sessionPost, setSessionPost] = useState<any>([{ DateValue: new Date().toDateString(), Value: '60' }])
  const [trafficSource, settrafficSource] = useState<any>([{ DateValue: new Date().toDateString(), Value: '' }])
  const [trafficData, setTrafficData] = useState<any[]>([])

  const postAnalytics = async () => {
    const datas: ITable[] = []
    let total = 0
    const avgSessionApi: AvgSessionsApi = new AvgSessionsApi({})

    const data = {
      DateRange: {
        StartDate: getDateByTimezoneOffset(startDate) as any,
        EndtDate: getDateByTimezoneOffset(endDate) as any,
      },
    }
    // if (endDate != null && startDate != null) {
    //   setSelectedDayCount((endDate.getTime() - startDate.getTime()) / 86400000 + 1)
    // }

    const res = await avgSessionApi.avgSessions(data)
    if (res) {
      total = 0
      res.Rows.map(item => {
        return (total = total + Number(item.Value))
      })
      setchartData(res.Rows)
      datas.push({
        Name: 'Ortalama Oturum Süresi',
        Value: res.Totals2,
      })
    }
    const sessionsPerUserApi: SessionsPerUserApi = new SessionsPerUserApi({})
    const perData: any = await sessionsPerUserApi.sessionsPerUser(data)
    if (perData) {
      total = 0
      perData.Rows.map(item => {
        return (total = total + Number(item.Value))
      })
      setPerUserData(perData.Rows)
      datas.push({
        Name: 'Ortalama Kullanıcı Sayısı',
        Value: total,
      })
    }
    const sessionsPostApi: SessionsPostApi = new SessionsPostApi({})
    const sessionPostData: any = await sessionsPostApi.sessionsPost(data)
    if (sessionPostData) {
      total = 0
      sessionPostData.Rows.map(item => {
        return (total = total + Number(item.Value))
      })
      setSessionPost(sessionPostData.Rows)
      datas.push({
        Name: 'Ortalama Aktif Kullanıcı Sayısı',
        Value: total,
      })
    }
    const trafficSourcesApi: TrafficSourcesApi = new TrafficSourcesApi({})
    const trafficData: any = await trafficSourcesApi.trafficSources(data)

    if (trafficData) {
      // total = 0;
      // trafficData.Rows.map((item) => {
      //   return (total = total + Number(item.Value));
      // });

      // let uniqueChars: any = [];
      // trafficData.forEach((c) => {
      //   if (!uniqueChars.DateValue.includes(c.DateValue)) {
      //     uniqueChars.push(c);
      //   }
      // });
      const trafficDataControl: any = []

      const newTrafficData = trafficData.filter(element => {
        const isDublicate = trafficDataControl.includes(element.DateValue)
        if (!isDublicate) {
          trafficDataControl.push(element.DateValue)
          return true
        }
        return false
      })

      settrafficSource(newTrafficData)

      // datas.push({
      //   Name: "Trafik",
      //   Value: total,
      // });
    }
    setState(datas)
  }

  useEffect(() => {
    postAnalytics()
  }, [])

  useEffect(() => {
    if (trafficSource && trafficSource.length >= 1) asd()
  }, [trafficSource])

  const asd = () => {
    const tmpLines: { value: string; searchEngine: string }[] = []
    const lines: any[] = []

    for (const elem of trafficSource) {
      if (elem.Value) {
        for (const item of elem.Value) {
          const idx = tmpLines.findIndex(elem => elem.searchEngine === item[1])

          if (idx === -1) {
            tmpLines.push({ value: item[0], searchEngine: item[1] })
          }
        }
      }
    }

    let ctr = 1
    for (const line of tmpLines) {
      const obj = Object.assign({
        spanGaps: true,
        label: line.searchEngine,
        fill: true,
        lineTension: 0.3,
        backgroundColor: 'rgba(130, 182, 217, .1)',
        borderColor: `${`rgba(1${ctr}0, 1${ctr}2, 2${ctr}5, 0.7)`}`,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: `${`rgba(1${ctr}0, 1${ctr}2, 2${ctr}5, 1)`}`,
        pointBackgroundColor: 'rgb(255, 0, 0)',
        pointBorderWidth: 10,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(130, 182, 217)',
        pointHoverBorderColor: 'rgba(220, 220, 220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        //data: trafficSource,
        data: [],
        hidden: ctr !== 1 ? true : false,
      })
      ctr = ctr < 5 ? ctr + 1 : ctr + 0

      const values = (trafficSource as any[]).map(mapItem => {
        const tmp = mapItem.Value.find(f => {
          return f[1] === line.searchEngine
        })

        if (tmp) {
          return Number(tmp[0])
        }

        return 0
      })

      obj.data = values
      lines.push(obj)
    }

    setTrafficData(lines)
  }

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>{t('pages.admin.siteReports.analytics.header')}</span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-5'>
                  <label htmlFor='location' className='form-label'>
                    {t('pages.admin.siteReports.analytics.startEndDate')}
                  </label>
                  <div className='row' style={{ justifyContent: 'center', rowGap: '10px' }}>
                    <div className='col-12 col-md-6 mb-3'>
                      <InputCampaign
                        type='date'
                        id='StartDate'
                        name='StartDate'
                        label={'Başlangıç Tarihi'}
                        value={toDateTimeLocal(startDate)}
                        onChange={e => getDate(e.target.value, 'Start')}
                        isActive
                      />
                    </div>
                    <div className='col-12 col-md-6 mb-3'>
                      <InputCampaign
                        type='date'
                        id='EndDate'
                        name='EndDate'
                        label={'Bitiş Tarihi'}
                        value={toDateTimeLocal(endDate)}
                        onChange={e => getDate(e.target.value, 'End')}
                        isActive
                      />
                    </div>
                  </div>
                  {/* <div className='date'>
                    <IPDatePicker
                      selectsRange={true as any}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={update => {
                        setDateRange(update)
                      }}
                    />
                  </div> */}
                </div>
                <div className='col-lg-1'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <button
                        type='button'
                        onClick={() => {
                          postAnalytics()
                        }}
                        className='btn btn-success'
                      >
                        {t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.show')}
                      </button>
                    </div>
                  </label>
                </div>
              </div>
            </form>
            <ChartsPage2
              type='Analytic'
              data={chartData}
              perUserData={perUserData}
              sessionPost={sessionPost}
              trafficSource={trafficData}
              dateValues={trafficSource}
            />
            <br />
            <div className='row m-b-30 m-t-10'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <table
                        id='datatable'
                        className='table table-bordered table-hover dataTable'
                        role='grid'
                        aria-describedby='datatable_info'
                      >
                        <thead>
                          <tr role='row' className='odd'>
                            <th>İsim</th>
                            <th>Değer</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.map((item, i) => (
                            <tr role='row' className='odd' key={i}>
                              <td>
                                <h5 className='googleAnalyticsName'>{item.Name}</h5>
                              </td>
                              <td>
                                <span className='googleAnalyticsValue float-right'>
                                  {item.Name === 'Ortalama Oturum Süresi'
                                    ? Number((+item.Value / 60).toFixed(2))
                                    : item.Name === 'Ortalama Kullanıcı Sayısı'
                                    ? Number((+item.Value / selectedDayCount).toFixed(2))
                                    : Math.floor(+item.Value / selectedDayCount)}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Analytics
