import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IGetAllRolePermissionsResponse } from '../models'

export class GetAllRolePermissionsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/userPermission/GetAllRolePermission',
      requesterInfo,
    })
  }

  public async getAllRolePermissions(): Promise<IGetAllRolePermissionsResponse> {
    return await this.getAsync<IGetAllRolePermissionsResponse>()
  }
}
