import React, { useState, useEffect, useMemo } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPButton from '../../../../../components/IPButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { CampaignBundleModel, CampaignBundleHistoryModel } from '../models/CampaignBundleModel'
import Swal from 'sweetalert2'
import { CampaignBundleService } from '../services/CampaignBundleService'
import Table from '../../../../../components/Table'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import InputCampaign from '../../../../../components/InputCampaign'
import { GetCampaignHistory } from '../services/getCampaignHistory'
import moment from 'moment'
import IPModal from '../../../../../components/IPModal'

interface ICampaignList {
  FilterCampaignList: CampaignBundleModel[]
  companyListOption: any
  campaignName: string
  statusOption: ISelectOption | null | undefined
  historyData: CampaignBundleHistoryModel[]
  showHistory: boolean
  url?: string
}
function CampaignBundleList(props: BaseComponentWithContextProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const searchCompany = searchParams.get('Sirket')
  const searchStatus = searchParams.get('Durum')
  const searchCampaign = searchParams.get('Kampanya')
  const searchStart = searchParams.get('Baslangic')
  const searchEnd = searchParams.get('Bitis')

  const companyListOptions = [
    { label: 'Index Grup', value: 5 },
    { label: 'Index', value: 1 },
    { label: 'Netex', value: 2 },
    { label: 'Despec', value: 3 },
    { label: 'Datagate', value: 4 },
  ]

  const statusListOptions = [
    { value: '1', label: 'Aktif' },
    { value: '2', label: 'Pasif' },
  ]



  const [startDate, setStartDate] = useState(searchStart ? searchStart : "")
  const [endDate, setEndDate] = useState(searchEnd ? searchEnd : "")
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  const getInitialState = (): ICampaignList => {
    return {
      FilterCampaignList: [],
      campaignName: searchCampaign && (searchCampaign != null || searchCampaign != "undefined") ? searchCampaign : "",
      companyListOption: searchCompany ? { label: companyListOptions.find(a => a.label == (searchCompany))?.label || "", value: companyListOptions.find(a => a.label == (searchCompany))?.value || 0 } : undefined,
      statusOption: searchStatus ? { label: statusListOptions.find(a => a.label == (searchStatus))?.label || "", value: statusListOptions.find(a => a.label == (searchStatus))?.value || 0 } : undefined,
      historyData: [],
      showHistory: false,
    }
  }

  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<ICampaignList>(initialState)
  const [campaignList, setCampaignList] = useState<CampaignBundleModel[]>([])
  const [filterCampaignList, setFilterCampaignList] = useState<CampaignBundleModel[]>([])
  const navigate = useNavigate()

  const getAllCampaigns = async () => {
    props.showLoading()
    const campaignBundleService: CampaignBundleService = new CampaignBundleService({})
    await campaignBundleService
      .getAll()
      .then(res => {
        setCampaignList(res)
        setFilterCampaignList(res)
        props.hideLoading()
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          allowOutsideClick: true,
        })
        props.hideLoading()
      })
  }

  const showHistory = async (id: number) => {
    props.showLoading()
    const campaignHistory: GetCampaignHistory = new GetCampaignHistory({})
    try {
      await campaignHistory.historyByID(id).then(res => {
        setState({ showHistory: true, historyData: res })
      })
      props.hideLoading()
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const deleteCampaingBundle = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Kampanyayı silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const campaignBundleService: CampaignBundleService = new CampaignBundleService({})

        await campaignBundleService
          .deleteCampaingBundle(id)
          .then(res => {
            Swal.fire({
              icon: 'success',
              title: 'Kampanya silinmiştir.',
              allowOutsideClick: true,
            }).then(() => {
              setCampaignList(prevState => prevState.filter(item => item.CampaignBundle.Id !== id))
              setFilterCampaignList(prevState => prevState.filter(item => item.CampaignBundle.Id !== id))
            })
            props.hideLoading()
          })
          .catch(err => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              allowOutsideClick: true,
            })
            props.hideLoading()
          })
      }
    })
  }

  useEffect(() => {
    getAllCampaigns()
    if (searchStart)
      setStartDate(searchStart)
    if (searchEnd)
      setEndDate(searchEnd)

  }, [])

  useEffect(() => {
    filterCampaing()

  }, [campaignList])

  const tableColumns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'col1',
      },
      {
        Header: '#',
        accessor: 'col2',
      },
      {
        Header: 'Kampanya Adı',
        accessor: 'col3',
      },
      {
        Header: 'Şirket',
        accessor: 'col4',
      },
      {
        Header: 'Başlangıç',
        accessor: 'col5',
      },
      {
        Header: 'Bitiş',
        accessor: 'col6',
      },
      {
        Header: 'Durum',
        accessor: 'col7',
      },
      {
        Header: 'İşlemler',
        accessor: 'col8',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      filterCampaignList.map((dt, index) => {
        const dtas: any[] = []
        dt.CampaignBundle.XCompanyCodes.map((com, index) => {
          const company =
            com === 1 ? 'Index' : com === 2 ? 'Netex' : com === 3 ? 'Despec' : com === 4 ? 'Datagate' : 'Index Grup'
          return dtas.push(index === 0 ? company : ', ' + company)
        })
        const data = {
          col1: index + 1,
          col2: dt.CampaignBundle.Id,
          col3: dt.CampaignBundle.BundleCode,
          col4: dtas,
          col5: dt.CampaignBundle.StartDate ? new Date(dt.CampaignBundle.StartDate).toLocaleDateString('tr-TR') : '-',
          col6: dt.CampaignBundle.EndDate ? new Date(dt.CampaignBundle.EndDate).toLocaleDateString('tr-TR') : '-',
          col7: dt.CampaignBundle.IsPublish ? 'Aktif' : 'Pasif',
          col8: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title='Güncelle'
                onClick={() => {
                  navigate('/admin/ProductOperation/CampaignBundleAdd', { state: { campaignData: dt, flag: 'update', url: state.url } })
                }}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
              <a
                data-action-type='delete'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title='Sil'
                onClick={() => deleteCampaingBundle(dt.CampaignBundle.Id)}
              >
                <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
              </a>

              <a
                data-action-type='copy'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title='Kopyala'
                onClick={() => {
                  navigate('/admin/ProductOperation/CampaignBundleAdd', { state: { campaignData: dt, flag: 'copy', url: state.url } })
                }}
              >
                <i className='icon-copy' style={{ fontSize: '1.2rem', color: 'blue' }}></i>
              </a>

              <a
                data-action-type='copy'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title='Tarihçe'
                onClick={() => {
                  showHistory(dt.CampaignBundle.Id)
                }}
              >
                <i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i>
              </a>
            </div>
          ),
        }

        return data
      }),
    [filterCampaignList]
  )
  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const filterCampaing = () => {
    if (startDate && !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen bitiş tarihi seçiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const sDate = new Date(startDate)
    const eDate = new Date(endDate)
    if (sDate > eDate) {
      Swal.fire({
        icon: 'error',
        title: 'Bitiş tarihi başlangıç tarihinden küçük veya eşit olamaz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    const queryParams = {
      Sirket: state.companyListOption ? companyListOptions.find(a => a.label == (state.companyListOption?.label))?.label : undefined,
      Durum: state.statusOption ? statusListOptions.find(a => a.value == (state.statusOption?.value))?.label : undefined,
      Kampanya: (state.campaignName !== "" && state.campaignName != null) ? state.campaignName : undefined,
      Baslangic: startDate ? startDate : undefined,
      Bitis: endDate ? endDate : undefined,
    };

    const filteredQueryParams = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const navigateRoute = `/admin/ProductOperation/CampaignBundleList?${filteredQueryParams}`;

    setState({ url: filteredQueryParams })
    navigate(navigateRoute)

    let filterCampaing: CampaignBundleModel[] = []
    let i = 0

    if (state.campaignName.length > 0) {
      i++
      const searchQuery = state.campaignName.toLocaleLowerCase()
      filterCampaing = campaignList.filter(cam => {
        const campaignNameLower = cam.CampaignBundle.BundleCode.toLocaleLowerCase()
        return campaignNameLower.includes(searchQuery)
      })
    }

    if (state.companyListOption) {
      if (filterCampaing.length > 0) {
        filterCampaing = filterCampaing.filter(cam => {
          return cam.CampaignBundle.XCompanyCodes.indexOf(+state.companyListOption?.value) > -1
        })
      }
      if (i === 0) {
        filterCampaing = campaignList.filter(cam => {
          return cam.CampaignBundle.XCompanyCodes.indexOf(+state.companyListOption?.value) > -1
        })
      }
      i++
    }
    if (state.statusOption) {
      if (filterCampaing.length > 0) {
        filterCampaing = filterCampaing.filter(cam => {
          return state.statusOption?.value === '1' ? cam.CampaignBundle.IsPublish : !cam.CampaignBundle.IsPublish
        })
      }
      if (i === 0) {
        filterCampaing = campaignList.filter(cam => {
          return state.statusOption?.value === '1' ? cam.CampaignBundle.IsPublish : !cam.CampaignBundle.IsPublish
        })
      }
      i++
    }
    if (startDate && endDate) {
      if (filterCampaing.length > 0) {
        filterCampaing = filterCampaing.filter(cam => {
          return (
            (eDate >= new Date(cam.CampaignBundle.StartDate) && new Date(cam.CampaignBundle.StartDate) >= sDate) ||
            (sDate <= new Date(cam.CampaignBundle.EndDate) && new Date(cam.CampaignBundle.EndDate) <= eDate) ||
            (new Date(cam.CampaignBundle.EndDate) >= eDate && new Date(cam.CampaignBundle.StartDate) <= sDate)
          )
        })
      }
      if (i === 0) {
        filterCampaing = campaignList.filter(cam => {
          return (
            (eDate >= new Date(cam.CampaignBundle.StartDate) && new Date(cam.CampaignBundle.StartDate) >= sDate) ||
            (sDate <= new Date(cam.CampaignBundle.EndDate) && new Date(cam.CampaignBundle.EndDate) <= eDate) ||
            (new Date(cam.CampaignBundle.EndDate) >= eDate && new Date(cam.CampaignBundle.StartDate) <= sDate)
          )
        })
      }
      i++
    }
    if (i === 0) return setFilterCampaignList(campaignList)
    setFilterCampaignList(filterCampaing)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Kampanya Listesi</span>
            <div className='col-12 col-md-2 mb-2'>
              <IPButton
                type='button'
                className='btn btn-primary'
                text='Kampanya Ekle'
                onClick={() => navigate('/admin/ProductOperation/CampaignBundleAdd')}
                style={{ marginLeft: '15px' }}
              />
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='form-group col-md-2'>
                <IPInput
                  type='text'
                  id='Title'
                  className='form-control'
                  name='Title'
                  placeholder='Kampanya Adı'
                  onChange={e => setState({ campaignName: e.target.value })}
                  value={state.campaignName}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      e.preventDefault()
                      filterCampaing()
                    }
                  }}
                />
              </div>
              <div className='form-group col-md-2'>
                <IPSelectBox
                  isClearable
                  placeholder='Şirket Seçiniz...'
                  options={companyListOptions}
                  value={state.companyListOption}
                  onChangeSingle={option => {
                    setState({ companyListOption: option })
                  }}
                />
              </div>
              <div className='form-group col-md-2'>
                <IPSelectBox
                  isClearable
                  placeholder='Durum Seçiniz...'
                  options={statusListOptions}
                  value={state.statusOption}
                  onChangeSingle={option => {
                    setState({ statusOption: option })
                  }}
                />
              </div>
              <div className='col-12 col-md-2 mb-2'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={startDate && toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, 'Start')}
                  isActive
                />
              </div>
              <div className='col-12 col-md-2 mb-2'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={endDate && toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, 'End')}
                  isActive
                />
              </div>
              <div className='col-12 col-md-2 mb-2'>
                <IPButton type='button' className='btn btn-success' text='Süz' onClick={filterCampaing} />
              </div>
            </div>
            <Table columns={tableColumns} data={tableData} currentPage={0} />
          </div>
          <IPModal
            show={state.showHistory}
            onClose={() => setState({ showHistory: false, historyData: [] })}
            hideConfirm={true}
            width={1200}
            title='Tarihçe'
          >
            <table className='table'>
              <thead>
                <tr role='row' className='odd'>
                  <th>#</th>
                  <th>Tarih</th>
                  <th>Bayi Kodu</th>
                  <th>Kullanıcı Adı</th>
                </tr>
              </thead>

              <tbody>
                {state.historyData &&
                  state.historyData.length > 0 &&
                  state.historyData.map(his => (
                    <tr role='row' className='odd' key={his.Id}>
                      <td>
                        {
                          <a
                            className='btn btn-transparent'
                            style={{ color: 'blue' }}
                            type='button'
                            data-action-type='order'
                            onClick={() => {
                              navigate('/admin/ProductOperation/CampaignBundleAdd', {
                                state: { campaignData: his, flag: 'history', url: state.url },
                              })
                            }}
                          >
                            {his.Id}
                          </a>
                        }
                      </td>
                      <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                      <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                      <td>{his.UserName}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </IPModal>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(CampaignBundleList)
