import React, { useRef } from 'react'

export enum ProductIQuotaFormField {
  host = 'host',
  token = 'token',
  mfr = 'mfr',
  uEmail = 'uEmail',
  uName = 'uName',
  cAccountNum = 'cAccountNum',
  cName = 'cName',
  cPCode = 'cPCode',
  base = 'base',
  ref = 'ref',
  BasketURL = 'BasketURL',
  SessionID = 'SessionID',
}

export interface ProductIQuotaFormHandles {
  submitForm: () => void
  setFormInputValueByName: (field: ProductIQuotaFormField, value: string) => void
}

const ProductIQuotaForm: React.ForwardRefRenderFunction<ProductIQuotaFormHandles, any> = (props, forwardedRef) => {
  const formRef = useRef<HTMLFormElement>(null)
  const hostInputRef = useRef<HTMLInputElement>(null)
  const tokenInputRef = useRef<HTMLInputElement>(null)
  const mfrInputRef = useRef<HTMLInputElement>(null)
  const uEmailInputRef = useRef<HTMLInputElement>(null)
  const uNameInputRef = useRef<HTMLInputElement>(null)
  const cAccountNumInputRef = useRef<HTMLInputElement>(null)
  const cNameInputRef = useRef<HTMLInputElement>(null)
  const cPCodeInputRef = useRef<HTMLInputElement>(null)
  const baseInputRef = useRef<HTMLInputElement>(null)
  const refInputRef = useRef<HTMLInputElement>(null)
  const BasketURLInputRef = useRef<HTMLInputElement>(null)
  const SessionIDInputRef = useRef<HTMLInputElement>(null)

  React.useImperativeHandle(forwardedRef, () => ({
    submitForm: () => {
      formRef && formRef.current && formRef.current.submit && formRef.current.submit()
    },
    setFormInputValueByName: (fieldName: ProductIQuotaFormField, fieldValue: string) => {
      setFieldValueByName(fieldName, fieldValue)
    },
  }))

  const setFieldValueByName = (fieldName: ProductIQuotaFormField, fieldValue: string) => {
    switch (fieldName) {
      case ProductIQuotaFormField.host:
        hostInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.token:
        tokenInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.mfr:
        mfrInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.uEmail:
        uEmailInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.uName:
        uNameInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.cAccountNum:
        cAccountNumInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.cName:
        cNameInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.cPCode:
        cPCodeInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.base:
        baseInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.ref:
        refInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.BasketURL:
        BasketURLInputRef.current!.value = fieldValue
        break
      case ProductIQuotaFormField.SessionID:
        SessionIDInputRef.current!.value = fieldValue
        break
      default:
        break
    }
  }

  return (
    <form
      method='post'
      target='_blank'
      ref={formRef}
      action='https://iquote.hpe.com/aspx/gatekeeper.aspx'
      id='frmiQuote'
      name='frmiQuote'
    >
      <input type='hidden' name='host' ref={hostInputRef} value={'DARTR34406'} />
      <input type='hidden' name='token' ref={tokenInputRef} value={'F0453EA754C14CFE87BB25DD1D3CC893'} />
      <input type='hidden' name='mfr' ref={mfrInputRef} value={'HPE'} />
      <input type='hidden' name='uEmail' ref={uEmailInputRef} />
      <input type='hidden' name='uName' ref={uNameInputRef} />
      <input type='hidden' name='cAccountNum' ref={cAccountNumInputRef} />
      <input type='hidden' name='cName' ref={cNameInputRef} />
      <input type='hidden' name='cPCode' ref={cPCodeInputRef} />
      <input type='hidden' name='base' ref={baseInputRef} />
      <input type='hidden' name='ref' ref={refInputRef} />
      <input type='hidden' name='BasketURL' ref={BasketURLInputRef} />
      <input type='hidden' name='SessionID' ref={SessionIDInputRef} />
    </form>
  )
}

export default React.forwardRef(ProductIQuotaForm)
