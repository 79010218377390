import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IProductColorRequest, IProductColorResponse } from '../models/models'

export class GetProductColorByProductApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'color/listColorByProduct',
      requesterInfo,
    })
  }

  public async getProductColor(code: number): Promise<IProductColorResponse[]> {
    return await this.postAsync<IProductColorResponse[]>(JSON.stringify(code));
  }
}
