import { translate } from '../../../../base/localization'
import Translations from '../../../../translations'
import { IProduct } from '../../../ProductList/models/response/getProductListResponseModel'
export interface IPaymentProps {
  product: IProduct
}

const PaymentMethod = (props: IPaymentProps & { checkboxState: any; handleCheckboxChange: (name: string) => void }) => {
  const t = translate(Translations)
  const { product, checkboxState, handleCheckboxChange } = props

  return (
    <div>
      <div className='row mt-4 justify-content-end'>
        {/* Size Özel Fiyatına Göre */}
        <div className='col-auto'>
          <label className='p-switch p-switch-sm switch-color-secondary'>
            <input
              type='checkbox'
              name='specialPrice'
              checked={checkboxState.specialPrice}
              onChange={() => handleCheckboxChange('specialPrice')}
            />
            <span className='p-switch-style'></span>
            <h5
              id='title2'
              style={{
                fontSize: '15px',
                marginLeft: '10px',
                display: 'inline',
              }}
            >
              {t('pages.productdetail.ToSpecialPrice')}
            </h5>
          </label>
        </div>
        {/* Liste Fiyatına Göre */}
        <div className='col-auto'>
          <label className='p-switch p-switch-sm switch-color-secondary'>
            <input
              type='checkbox'
              name='branchPrice'
              checked={checkboxState.branchPrice}
              onChange={() => handleCheckboxChange('branchPrice')}
            />
            <span className='p-switch-style'></span>
            <h5
              id='title2'
              style={{
                fontSize: '15px',
                marginLeft: '10px',
                display: 'inline',
              }}
            >
              {t('pages.productdetail.ToBranchPrice')}
            </h5>
          </label>
        </div>
        {/* TESK Fiyatına Göre */}
        <div className='col-auto'>
          <label className='p-switch p-switch-sm switch-color-secondary'>
            <input
              type='checkbox'
              name='listPrice'
              checked={checkboxState.listPrice}
              onChange={() => handleCheckboxChange('listPrice')}
            />
            <span className='p-switch-style'></span>
            <h5
              id='title2'
              style={{
                fontSize: '15px',
                marginLeft: '10px',
                display: 'inline',
              }}
            >
              {t('pages.productdetail.ToListPrice')}
            </h5>
          </label>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethod
