import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse, IPostSessionListRequest } from '../models/models'

export class CloseSessionApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/user/endSession',
      requesterInfo,
    })
  }

  public async postSession(request: IPostSessionListRequest): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
