import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'


export class DeleteProductLinkApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/productLink/delete/',
      requesterInfo,
    })
  }

  public async deleteProductLink(id: number): Promise<any> {
    this.props.url = `${this.props.url}${id}`
    return await this.deleteAsync<any>()
  }
}
