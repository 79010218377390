import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'

export class B2BMixReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/b2bDailyMixReport',
      requesterInfo,
    })
  }

  public async sendB2BMixReport(req: { StartDate: Date; EndDate: Date }): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
