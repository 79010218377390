import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IHomePageProducts } from '../models/responseModels'

export class DeleteHomePageProductsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/homepageProduct/delete/',
      requesterInfo,
    })
  }

  public async deleteHomePageProducts(id: number): Promise<IHomePageProducts[]> {
    this.props.url = `${this.props.url}${id}`
    return await this.deleteAsync<IHomePageProducts[]>()
  }
}
