import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { ReceiptExcel, ReceiptExcelResponse } from '../models/receiptExcel'

export class PostReceiptExcel extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'excel/ReceiptExcel',
      requesterInfo,
    })
  }

  public async postReceiptExcel(request: ReceiptExcel): Promise<ReceiptExcelResponse> {
    return await this.postAsync<ReceiptExcelResponse>(request)
  }
}
