import * as yup from 'yup'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
// const FILE_SIZE = 19201212121;
// const SUPPORTED_FORMATS = [
//   "image/jpg",
//   "image/jpeg",
//   "image/gif",
//   "image/png"
// ];
const t = translate(Translations)
const validations = yup.object().shape({
  Email: yup
    .string()
    .email('Geçerli bir email adresi girmelisiniz!')
    .nullable()
    .min(5, 'En az 5 karakter girilmelidir!')
    .max(40, 'En fazla 40 karakter girilmelidir!')
    .required('Lütfen email alanını doldurunuz!'),
  NameAndSurname: yup
    .string()
    .min(5, 'En az 5 karakter girilmelidir!')
    .max(40, 'En fazla 40 karakter girilmelidir!')
    .required('Lütfen adı soyadı alanını doldurunuz!'),
  Phone: yup
    .string()
    .test('len', 'Telefon numarası 10 karakter olmalı!', val => {
      const a = val
        ?.replaceAll('_', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(' ', '')
        .replaceAll(')', '').length
      return a === 10
    })
    .nullable()
    .required('Lütfen telefon alanını doldurunuz!'),
  AddressDetail: yup
    .string()
    .min(5, 'En az 5 karakter girilmelidir!')
    .max(80, 'En fazla 80 karakter girilmelidir!')
    .required('Lütfen adres giriniz!'),
  AddressTitle: yup
    .string()
    .min(5, 'En az 5 karakter girilmelidir!')
    .max(40, 'En fazla 40 karakter girilmelidir!')
    .required('Lütfen adres başlığı giriniz!'),
  Country: yup.string().required('Lütfen ülke seçiniz!'),
  City: yup.string().required('Lütfen il seçiniz!'),
  District: yup.string().required('Lütfen ilçe seçiniz!'),
  PostCode: yup.string().required('Lütfen Mahalle seçiniz!'),
})

export default validations
//TODO GSM EMAİL REGEX ADDED
