import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export class AddListToBasketUIViaBundleApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/addListToBasketUIViaBundle',
      requesterInfo,
    })
  }

  public async addListToBasketUIViaBundle(id: number): Promise<any> {
    this.props.url = `${this.props.url}?bundleCampaignId=${id}`
    return await this.postAsync<any>()
  }
}
