import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import axios from 'axios'
import { css } from '@emotion/css'
import Swal from 'sweetalert2'

import IPButton from '../../components/IPButton'
import IPCheckbox from '../../components/IPCheckBox'
import { SellerApplicationService } from './services/sellerApplicationService'
import { ServiceRouter } from '../../base/proxy/serviceRouter'

const sellerApplicationService = new SellerApplicationService({})

const cSwal = (message: string, title?: string, icon?: 'success' | 'error') => {
  let option: any = {
    html: `<div style='font-size: 1vw'>${message}</div>`,
    showConfirmButton: true,
    allowOutsideClick: false,
  }

  if (icon) option.icon = icon
  if (title) option.title = title

  return Swal.fire(option)
}

export default function Confirmation() {
  const aid = window.location.href.split('code=')[1]

  const navigate = useNavigate()

  const [error, setError] = useState<any>(null)
  const [pdf, setPdf] = useState('')
  const [phone, setPhone] = useState('')
  const [smsSent, setSmsSent] = useState(false)
  const [smsCode, setSmsCode] = useState('')
  const [appConfirmed, setAppConfirmed] = useState(false)

  const fetchAppDetails = useCallback(async () => {
    try {
      const res: any = await axios.post(
        `${ServiceRouter.getServiceUrl()}applicationRequest/fetchApplicationConfirmationDetails`,
        {
          TrackingId: aid,
        }
      )

      if (res.data.Message && !res.data.Success) {
        setError(res.data.Message)
      }

      if (res.data.Data) {
        setPdf(res.data.Data.PdfName)
        setPhone(res.data.Data.Phone)
      }
    } catch (err) {
      setError(err)
    }
  }, [aid])

  useEffect(() => {
    fetchAppDetails()
  }, [fetchAppDetails])

  const sendConfirmationCode = async () => {
    if (aid && aid.length > 0) {
      try {
        const res = await sellerApplicationService.sendConfirmationCode({ TrackingId: aid })

        if (res) {
          setSmsSent(true)
        }
      } catch (err: any) {
        Swal.fire('Hata', err.description, 'error')
      }
    }
  }

  const confirmTheContract = async () => {
    if (!appConfirmed) return cSwal('Sözleşmeyi onaylamadınız.', 'Hata', 'error')

    const req = {
      IsContractConfirmation: appConfirmed,
      SmsCode: smsCode,
      TrackingNumber: aid,
    }

    try {
      const res = await sellerApplicationService.confirmTheContract(req)

      cSwal(res, 'Başarılı', 'success').then(result => {
        if (result.isConfirmed) {
          navigate('/login')
        }
      })
    } catch (err: any) {
      cSwal(err.description, 'Hata', 'error')
    }
  }

  const errorStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    height: 100vh;
    font-size: 24px;
  `

  if (error) {
    return (
      <h1 className={errorStyles}>
        <img src='/images/index.png' alt='' />
        {error}
        <Link
          to='/login'
          className={css`
            color: #333;
            &:hover {
              color: #d90010 !important;
            }
          `}
        >
          Anasayfa
        </Link>
      </h1>
    )
  }

  return (
    <div className='container my-4'>
      <div
        className={css`
          background-color: #ee333e;
          padding: 1rem;
          font-size: 1.2vw;
          font-weight: 600;
          color: #fff;
        `}
      >
        Online Bayilik Sözleşmesi Onaylama
      </div>

      <iframe title='Online Bayilik Sözleşmesi' src={pdf} style={{ height: '70vh' }}></iframe>

      <div
        className={css`
          display: flex;
          flex-direction: column;
          gap: 1rem;
          font-size: 0.9vw;
          color: #252525;
        `}
      >
        Sözleşmenizi onaylamak için önce aşağıdaki Onay Kodu gönder butonuna tıklamanız gerekiyor. <br />
        Online Başvuru sırasında belirttiğiniz Cep Telefonunuza ( {phone} ) Onay Kodu gönderilecektir.
        <IPButton
          text={`${smsSent ? 'Onay Kodu Gönderildi' : 'Onay Kodu Gönder'}`}
          className={css`
            width: 200px;

            &:disabled {
              background-color: gray;
            }
          `}
          onClick={sendConfirmationCode}
          disabled={smsSent}
        />
        {smsSent && (
          <div style={{ marginBottom: '1rem' }}>
            <div>
              Onay Kodu Doğrulama :{' '}
              <input
                type='text'
                className={css`
                  width: 150px !important;
                `}
                onChange={e => setSmsCode(e.target.value)}
              />
            </div>
            <IPCheckbox
              label='Sözleşmeyi okudum, onaylıyorum.'
              checked={appConfirmed}
              onCheckedChange={e => setAppConfirmed(e)}
            />
            <IPButton
              text='Onayla'
              className={css`
                width: 200px;
              `}
              onClick={confirmTheContract}
            />
          </div>
        )}
      </div>
    </div>
  )
}
