import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { DealerType } from '../models/DealerChannelModel'

export interface IDealerService {
  TheCode: string
  TypeCode: string
}

export class DealerService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealer/listAll',
      requesterInfo,
    })
  }

  public async listAll(): Promise<IDealerService> {
    return await this.getAsync<any>()
  }

  public async getDealerTypes(): Promise<DealerType[]> {
    this.props.url = 'admin/dealer/dealerTypeList'
    return await this.getAsync<DealerType[]>()
  }
}
