import React, { useEffect, useState } from 'react'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import '../style.scss'
import { IAuthorizedServiceProcedurs, IBrand } from '../models/models'
import { GetAuthorizedServicesProcedurApi } from '../services/getAuthorizedServicePointsListApi'
import { useNavigate } from 'react-router-dom'
import { css } from '@emotion/css'

function SshProceduresBrandList(props: any) {
  const t = translate(Translations)
  const [authorizedServicesProcedurBrand, setAuthorizedServicesProcedurBrand] = useState<IBrand[]>([])
  const [authorizedServicesProcedur, setAuthorizedServicesProcedur] = useState<IAuthorizedServiceProcedurs[]>([])
  useEffect(() => {
    getAuthorizedServices()
  }, [])
  const getAuthorizedServices = async () => {
    const getAuthorizedServicesProcedur: GetAuthorizedServicesProcedurApi = new GetAuthorizedServicesProcedurApi({})
    const authorizedServicesPro = await getAuthorizedServicesProcedur.getAuthorizedServicesProcedur()
    const data: IBrand[] = []
    if (authorizedServicesPro) {
      authorizedServicesPro.map((pro, index) => {
        return data.push({ BrandId: pro.BrandId, Image: pro.Brand.ImageUrl })
      })
    }
    setAuthorizedServicesProcedur(authorizedServicesPro as any)
    setAuthorizedServicesProcedurBrand(data as any)
  }
  const navigate: any = useNavigate()
  const callProcedure = async (data: number) => {
    const auth: IAuthorizedServiceProcedurs[] = []
    authorizedServicesProcedur.map(pro => {
      if (pro.BrandId === data) return auth.push(pro)
    })
    navigate('/myspecial/ssh/SSHProcedures', { state: { data: auth } })
  }
  return (
    <div className='sshProcedures ' style={{ backgroundColor: 'white' }}>
      <div className='container-fluid'>
        <form>
          <div className='row align-items-center'>
            <div className='heading-text'>
              <h4>{t('pages.mySpecial.sshProcedures.authorizedServiceProcedures')}</h4>
            </div>
            <div className='border-div'>
              <div className='formEl'>
                <span>{t('pages.mySpecial.sshProcedures.authorizedServicePoints')}</span>
              </div>
              <a href='/myspecial/ssh/authorizedServicePoints' className='descriptionSSH'>
                {t('pages.mySpecial.sshProcedures.description')}
              </a>
            </div>
            <div className='heading-text'>
              <h4>{t('pages.mySpecial.sshProcedures.warrantyProcessesBrands')}</h4>
            </div>
            <div>
              <ul
                className={css`
                  list-style-type: none;
                  padding: 0;
                  display: flex;
                  flex-wrap: wrap;
                `}
              >
                {authorizedServicesProcedurBrand.map(procedur => (
                  <li
                    key={procedur.BrandId}
                    className={css`
                      width: 10%;
                      margin-right:10px;
                      margin-bottom:10px;
                    `}
                  >
                    <a onClick={() => callProcedure(procedur.BrandId)}>
                      <div className='brand-white-background' style={{ border: '1px solid rgb(221, 221, 221)' }}>
                        <img
                          className='rounded-circle'
                          alt={''}
                          src={procedur.Image}
                        />
                      </div>
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SshProceduresBrandList
