import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IProduct } from '../models/response/getProductListResponseModel'

export class GetProductListByBannerIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/banner-link/',
      requesterInfo,
    })
  }

  public async getProductsList(bannerId: number): Promise<IProduct[]> {
    this.props.url = `${this.props.url}${bannerId}`

    return await this.getAsync<IProduct[]>()
  }
}
