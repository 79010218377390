import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IBankInfos, binRequest } from '../models/models'

export class FindBankInfosApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'payment/findBankInfos',
      requesterInfo,
    })
  }

  public async findBankInfos(request: binRequest): Promise<IBankInfos> {
    return await this.postAsync<IBankInfos>(request)
  }
}
