import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IUserChangeUserPasswordRequest } from '../models/models'

export class UserChangePasswordApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'user/changeUserPassword',
      requesterInfo,
    })
  }

  public async userChangePassword(request: IUserChangeUserPasswordRequest): Promise<boolean> {
    return await this.postAsync<boolean>(request)
  }
}
