import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import IPButton from '../../../../../components/IPButton'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks'
import _ from 'lodash'
import IPInput from '../../../../../components/IPInput'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import InputCampaign from '../../../../../components/InputCampaign'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPSelectBox from '../../../../../components/IPSelectBox/IPSelectBoxMenu'
import IPFile from '../../../../../components/IPFile'
import { excelToJSON, getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import Swal from 'sweetalert2'
import CampaignSelect from '../../CampaignBundleCrud/components/CampaignSelect'
import { DealerService } from '../../CampaignBundleCrud/services/DealerService'
import { GetSubTypesApi } from '../../../components/services/getSubTypesApi'
import { DealerType } from '../../CampaignBundleCrud/models/CampaignBundleModel'
import '../../CampaignBundleCrud/components/CampaignBundleList.scss'
import { CampaignBundleService } from '../../CampaignBundleCrud/services/CampaignBundleService'
import { ICategoryList } from '../../../components/models/models'
import { getCategoryList } from '../../../components/api'
import { GetPoductSubTypeListApi } from '../../CampaignBundleCrud/services/getPoductSubTypeListApi'
import { IProductSuggestRequest } from '../models/ProductSuggestListModel'
import { AddProductSuggestApi } from '../services/addProductSuggest'
import { UpdateProductSuggestApi } from '../services/updateProductSuggest'

enum CariListType {
  CariCode,
  CariType,
  CariAltType,
}
interface IAddProductColorState {
  history: boolean
  condOpen: boolean
  update: boolean
  enabled: boolean
  dealerInclude: boolean
  dealerTypeInclude: boolean
  dealerSubTypeInclude: boolean
  productCodes: ISelectOption[]
  productCodeOptions?: ISelectOption[]
  quotaCodeOptions?: ISelectOption[]
  categoryOptions?: ISelectOption[]
  productSubTypeOptions?: ISelectOption[]
  brandOptions?: string[]
  companyListOptions: ISelectOption[]
  companyOption: ISelectOption
  quotaList: ISelectOption[]
  brandList: ISelectOption[]
  catList: ISelectOption[]
  productSubTypeList: ISelectOption[]
  info: string
  name: string
  id?: number
  order: number
  selectedDealerList?: CariListType
  dealerCodeList: ISelectOption[]
  dealerTypeList: ISelectOption[]
  dealerSubTypeList: ISelectOption[]
  dealerTypeOptions: ISelectOption[]
  dealerSubTypeOptions: ISelectOption[]
  getSubTypesBool: boolean
  getDealerTypesBool: boolean
  getProductCodeBool: boolean
  getBrandListBool: boolean
  getCategoryBool: boolean
  getProductSubTypesBool: boolean
  isBasket: boolean
  isDetail: boolean
}

function ProductSuggestAdd(props: BaseComponentWithContextProps) {
  const getInitialState = (): IAddProductColorState => {
    return {
      history: false,
      condOpen: false,
      update: false,
      companyOption: { label: '', value: '' },
      companyListOptions: [
        { label: 'Index', value: 1 },
        { label: 'Netex', value: 2 },
        { label: 'Despec', value: 3 },
        { label: 'Datagate', value: 4 },
      ],
      productCodes: [],
      quotaList: [],
      brandList: [],
      catList: [],
      productSubTypeList: [],
      info: '',
      name: '',
      enabled: true,
      order: 0,
      dealerInclude: true,
      dealerTypeInclude: true,
      dealerSubTypeInclude: true,
      dealerCodeList: [],
      dealerTypeList: [],
      dealerSubTypeList: [],
      dealerTypeOptions: [],
      dealerSubTypeOptions: [],
      getSubTypesBool: false,
      getDealerTypesBool: false,
      getProductCodeBool: false,
      getBrandListBool: false,
      getCategoryBool: false,
      getProductSubTypesBool: false,
      isBasket: false,
      isDetail: false,
      selectedDealerList: CariListType.CariCode,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IAddProductColorState>(intialState)
  const location = useLocation()
  let navigate = useNavigate()

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }
  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const setDealerCodes = (dealerCodes, optionArr, fieldValue) => {
    if (fieldValue === 'XDealerCodeList') {
      const codeData = dealerCodes.map(item => ({
        label: String(item.DealerCode),
        value: String(item.DealerCode),
      }))

      let list: { label: string; value: string }[] = []

      codeData.forEach(codeItem => {
        if (!state.dealerCodeList.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })

      setState({
        dealerCodeList: [
          ...state.dealerCodeList,
          ...list.filter((item, index, self) => {
            return index === self.findIndex(i => i.label === item.label)
          }),
        ],
      })
    } else if (fieldValue === 'XDealerTypeList') {
      let list: any = []
      let codeData: any = []
      dealerCodes.forEach(item => {
        const codes: any = state[optionArr]?.find((code: any) => {
          if (item.DealerCode < 6) {
            return code.value === item.DealerCode
          } else {
            return code.value === '0000' + item.DealerCode
          }
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push(codes)
      })
      if (codeData.length === 0) return setState({ dealerTypeList: list })

      Swal.fire({
        icon: 'question',
        title: `${codeData.map(dt => {
          return dt
        })} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) {
          return
        }
        return setState({ dealerTypeList: list })
      })
    } else if (fieldValue === 'XDealerSubTypeList') {
      let list: any = []
      let codeData: any = []
      dealerCodes.forEach(item => {
        const codes: any = state[optionArr]?.find((code: any) => {
          if (item.DealerCode < 6) {
            return code.value === item.DealerCode
          } else {
            return code.value === '0000' + item.DealerCode
          }
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push(codes)
      })
      if (codeData.length === 0) return setState({ dealerSubTypeList: list })

      Swal.fire({
        icon: 'question',
        title: `${codeData.map(dt => {
          return dt
        })} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) {
          return
        }
        return setState({ dealerSubTypeList: list })
      })
    }
  }

  const handleFiles = async (files: File[], type: 'DealerCode' | 'DealerType' | 'DealerSubType') => {
    const dealerCodes: any = await excelToJSON(files[0])

    if (type === 'DealerCode') {
      setDealerCodes(dealerCodes[0], 'dealerCodeOptions', 'XDealerCodeList')
      return
    }
    if (type === 'DealerType') {
      setDealerCodes(dealerCodes[0], 'dealerTypeOptions', 'XDealerTypeList')
      return
    }
    if (type === 'DealerSubType') {
      setDealerCodes(dealerCodes[0], 'dealerSubTypeOptions', 'XDealerSubTypeList')
      return
    }
  }

  const enumFunction = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }

  const [productData, setProductData] = useState<ISelectOption[]>([])

  const onCreateOption = (option, updateFunc) => {
    updateFunc(prevState => {
      const newData = {
        value: option[option.length - 1].value,
        label: option[option.length - 1].label,
      }
      if (prevState && prevState.length > 0) {
        for (const state of prevState) {
          if (state.value === newData.value) return [...prevState]
          return [...prevState, newData]
        }
      }
      return [newData]
    })
  }

  const [inputValue, setInputValue] = useState('')

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string; value: string }[] = []
      words.forEach((word: any) => {
        if (!state.dealerCodeList.some((item: any) => item.label === word)) {
          const newItem = { label: word, value: word }
          dealerList.push(newItem)
        }
      })
      setState({ dealerCodeList: [...state.dealerCodeList, ...dealerList] })
      setInputValue('')
    }
  }

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }

  const [inputValueCond, setInputValueCond] = useState('')

  const handleInputCondChange = event => {
    setInputValueCond(event.target.value)
  }

  const handleInputKeyPressCond = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValueCond()
    }
  }

  const handleAddValueCond = () => {
    if (inputValueCond.trim() !== '') {
      let prodList: { label: string; value: string }[] = []

      if (state.productCodes) {
        prodList = state.productCodes.map(item => ({
          label: item.label,
          value: String(item.value),
        }))
      }
      if (!state.productCodes?.some(item => item.label === inputValueCond)) {
        const newItem = { label: inputValueCond, value: inputValueCond }
        prodList.push(newItem)
      }
      setState({ productCodes: prodList })
      setInputValueCond('')
    }
  }

  const getDealerTypes = async () => {
    const dealerService = new DealerService({})
    const res: any = await dealerService.getDealerTypes()
    if (res) {
      const dealerTypeOptions: ISelectOption[] = []
      res.forEach((item: DealerType) => {
        item && dealerTypeOptions.push({ value: item.MusteriGrubu, label: item.MusteriGrubuAciklama })
      })
      setState({ dealerTypeOptions })
    }
    setState({ getDealerTypesBool: true })
  }

  const getSubTypes = async () => {
    const getSubTypes: GetSubTypesApi = new GetSubTypesApi({})
    const res = await getSubTypes.getSubTypes()

    if (res) {
      const dealerSubTypeOptions: ISelectOption[] = []
      res.forEach((item: any) => {
        item && dealerSubTypeOptions.push({ value: item, label: item })
      })
      setState({ dealerSubTypeOptions })
    }
    setState({ getSubTypesBool: true })
  }

  const getProductCode = async () => {
    const campaignBundleService = new CampaignBundleService({})
    const res = await campaignBundleService.getProductCode()
    if (res) {
      let temp = res.Entity.ProductsInfo.map(a => {
        return {
          label: a.ProductCode + '       ' + a.ProductName,
          value: a.ProductCode,
        }
      })
      setState({
        productCodeOptions: temp,
        quotaCodeOptions: res.Entity.QuotaCode.map(item => {
          return { label: item, value: item }
        }),
      })
    }
    setState({ getProductCodeBool: true })
  }

  const getBrandList = async () => {
    const campaignBundleService = new CampaignBundleService({})
    const res = await campaignBundleService.getBrandList()
    if (res) setState({ brandOptions: res.Entity?.BrandCode })
    setState({ getBrandListBool: true })
  }

  const getCategory = async () => {
    const data: ICategoryList[] = await getCategoryList()
    const categoryOptions: ISelectOption[] = []
    if (data) {
      data.map(dt => {
        return categoryOptions.push({ value: dt.SapCode, label: dt.Name })
      })
    }
    setState({ categoryOptions })
    setState({ getCategoryBool: true })
  }

  const getProductSubTypes = async () => {
    const getPoductSubTypeList: GetPoductSubTypeListApi = new GetPoductSubTypeListApi({})
    const res = await getPoductSubTypeList.getPoductSubTypeList()
    if (res) {
      const subType: ISelectOption[] = []
      res.forEach((item: any) => {
        item && subType.push({ value: item, label: item })
      })
      setState({ productSubTypeOptions: subType })
    }
    setState({ getProductSubTypesBool: true })
  }

  useEffect(() => {
    getSubTypes()
    getDealerTypes()
    getProductCode()
    getBrandList()
    getCategory()
    getProductSubTypes()
  }, [])

  useEffect(() => {
    if (
      state.getSubTypesBool &&
      state.getDealerTypesBool &&
      state.getProductCodeBool &&
      state.getBrandListBool &&
      state.getCategoryBool &&
      state.getProductSubTypesBool &&
      state.condOpen
    ) {
      props.hideLoading()
    } else {
      if (state.condOpen) props.showLoading()
    }
  }, [
    state.condOpen,
    state.getSubTypesBool,
    state.getDealerTypesBool,
    state.getProductCodeBool,
    state.getBrandListBool,
    state.getCategoryBool,
    state.getProductSubTypesBool,
  ])

  useEffect(() => {
    if (location.state && location.state.linkData) {
      if (location.state.data === 'history') setState({ history: true })
      else if (location.state.data === 'update') setState({ update: true })

      if (
        !(
          state.getSubTypesBool &&
          state.getDealerTypesBool &&
          state.getProductCodeBool &&
          state.getBrandListBool &&
          state.getCategoryBool &&
          state.getProductSubTypesBool
        )
      ) {
        props.showLoading()
      } else {
        props.hideLoading()

        if (location.state.data === 'history') {
          let dealerList: { label: string; value: string }[] = []
          if (location.state.linkData.DealerCodes.length > 0) {
            location.state.linkData.DealerCodes.toString()
              .split(',')
              .forEach((word: any) => {
                const cleanedWord = word.toString().replace('0000', '')
                if (!dealerList.some(item => item.label === cleanedWord)) {
                  const newItem = { label: cleanedWord, value: cleanedWord }
                  dealerList.push(newItem)
                }
              })
          }

          let suggestList: { label: string; value: string }[] = []

          location.state.linkData.SuggestProductCodes.toString()
            .split('@@')
            .forEach((word: any) => {
              const cleanedWord = word
              if (!suggestList.some(item => item.label === cleanedWord)) {
                const newItem = { label: cleanedWord, value: cleanedWord }
                suggestList.push(newItem)
              }
            })
          setState({
            isBasket: location.state.linkData.IsBasket,
            isDetail: location.state.linkData.IsDetail,
            enabled: location.state.linkData.Enabled,
            info: location.state.linkData.Info,
            name: location.state.linkData.Name,
            companyOption: state.companyListOptions.find(a => a.value == location.state.linkData.Company),
            order: location.state.linkData.Order,
            dealerInclude: location.state.linkData.DealerCodesInclude,
            dealerTypeInclude: location.state.linkData.DealerTypesInclude,
            dealerSubTypeInclude: location.state.linkData.DealerSubTypesInclude,
            dealerCodeList: dealerList,
            dealerTypeList: state.dealerTypeOptions.filter(option =>
              location.state.linkData.DealerTypes.toString().split(',').includes(option.value)
            ),
            dealerSubTypeList: state.dealerSubTypeOptions.filter(option =>
              location.state.linkData.DealerSubTypes.toString().split('@@').includes(option.value)
            ),
            productCodes: state.productCodeOptions?.filter(option =>
              location.state.linkData.ProductList.toString().split('@@').includes(option.value)
            ),
            quotaList: state.quotaCodeOptions?.filter(option =>
              location.state.linkData.QuotaList.toString().split(',').includes(option.value)
            ),
            brandList: state.brandOptions
              ?.map(option => ({ label: option, value: option }))
              .filter(option => location.state.linkData.BrandList === option.value),

            productSubTypeList: state.productSubTypeOptions?.filter(option =>
              location.state.linkData.ProductSubTypeList.toString().split('@@').includes(option.value)
            ),
            catList: state.categoryOptions?.filter(option =>
              location.state.linkData.CatList.toString().split(',').includes(option.value)
            ),
          })
          setProductData(suggestList)
          setStartDate(location.state.linkData.StartDate)
          setEndDate(location.state.linkData.EndDate)
        } else if (location.state.data === 'update') {
          let dealerList: { label: string; value: string }[] = []

          if (location.state.linkData.DealerCodes.length > 0) {
            location.state.linkData.DealerCodes.toString()
              .split(',')
              .forEach((word: any) => {
                const cleanedWord = word.toString().replace('0000', '')
                if (!dealerList.some(item => item.label === cleanedWord)) {
                  const newItem = { label: cleanedWord, value: cleanedWord }
                  dealerList.push(newItem)
                }
              })
          }

          let suggestList: { label: string; value: string }[] = []

          location.state.linkData.SuggestProductCodes.toString()
            .split(',')
            .forEach((word: any) => {
              const cleanedWord = word
              if (!suggestList.some(item => item.label === cleanedWord)) {
                const newItem = { label: cleanedWord, value: cleanedWord }
                suggestList.push(newItem)
              }
            })

          setState({
            id: location.state.linkData.Id,
            isBasket: location.state.linkData.IsBasket,
            isDetail: location.state.linkData.IsDetail,
            enabled: location.state.linkData.Enabled,
            info: location.state.linkData.Info,
            name: location.state.linkData.Name,
            companyOption: state.companyListOptions.find(a => a.label == location.state.linkData.Company),
            order: location.state.linkData.Order,
            dealerInclude: location.state.linkData.DealerCodesInclude,
            dealerTypeInclude: location.state.linkData.DealerTypesInclude,
            dealerSubTypeInclude: location.state.linkData.DealerSubTypesInclude,
            dealerCodeList: dealerList,
            dealerTypeList: state.dealerTypeOptions.filter(option =>
              location.state.linkData.DealerTypes.toString().split(',').includes(option.value)
            ),
            dealerSubTypeList: state.dealerSubTypeOptions.filter(option =>
              location.state.linkData.DealerSubTypes.toString().split(',').includes(option.value)
            ),
            productCodes: state.productCodeOptions?.filter(option =>
              location.state.linkData.ProductList.toString().split(',').includes(option.value)
            ),
            quotaList: state.quotaCodeOptions?.filter(option =>
              location.state.linkData.QuotaList.toString().split(',').includes(option.value)
            ),
            brandList: state.brandOptions
              ?.map(option => ({ label: option, value: option }))
              .filter(option => location.state.linkData.BrandList === option.value),

            productSubTypeList: state.productSubTypeOptions?.filter(option =>
              location.state.linkData.ProductSubTypeList.toString().split(',').includes(option.value)
            ),
            catList: state.categoryOptions?.filter(option =>
              location.state.linkData.CatList.toString().split(',').includes(option.value)
            ),
          })
          setProductData(suggestList)
          setStartDate(location.state.linkData.StartDate)
          setEndDate(location.state.linkData.EndDate)
        }
      }
    }
  }, [
    state.getSubTypesBool,
    state.getDealerTypesBool,
    state.getProductCodeBool,
    state.getBrandListBool,
    state.getCategoryBool,
    state.getProductSubTypesBool,
  ])

  const addSuggestion = async () => {
    props.showLoading()
    const req: IProductSuggestRequest = {
      Id: state.id,
      SuggestProductCodes: productData.map(a => a.value.toString()),
      Info: state.info,
      Name: state.name,
      Company: state.companyOption ? Number(state.companyOption.value) : 0,
      DealerCodes: state.dealerCodeList.map(a => '0000' + a.value.toString()),
      DealerCodesInclude: state.dealerInclude,
      DealerTypes: state.dealerTypeList.map(a => a.value.toString()),
      DealerTypesInclude: state.dealerTypeInclude,
      DealerSubTypes: state.dealerSubTypeList.map(a => a.value.toString()),
      DealerSubTypesInclude: state.dealerSubTypeInclude,
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate),
      Enabled: state.enabled,
      ProductList: state.productCodes.map(a => a.value.toString()),
      BrandList: state.brandList.map(a => a.value.toString()),
      QuotaList: state.quotaList.map(a => a.value.toString()),
      CatList: state.catList.map(a => a.value.toString()),
      ProductSubTypeList: state.productSubTypeList.map(a => a.value.toString()),
      Order: state.order,
      IsBasket: state.isBasket,
      IsDetail: state.isDetail,
    }

    if (state.update) {
      const updateSuggest: UpdateProductSuggestApi = new UpdateProductSuggestApi({})
      await updateSuggest
        .updateProductSuggest(req)
        .then(result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Ürün önerme kaydı güncellendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
          props.hideLoading()
          navigate('/admin/ProductOperation/ProductSuggestList')
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    } else {
      const addSuggest: AddProductSuggestApi = new AddProductSuggestApi({})
      await addSuggest
        .addProductSuggest(req)
        .then(result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Ürün önerme kaydı eklendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
          props.hideLoading()
          navigate('/admin/ProductOperation/ProductSuggestList')
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
  }

  return (
    <div className='container addProductLimitation'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Ürün Önerme Ekleme</span>
            <Link to='/admin/ProductOperation/ProductSuggestList'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Listeye Dön'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='row m-5'>
            <div className='col-md-3'>
              <label>Önerme Adı</label>
              <IPInput
                type='text'
                id='Name'
                className='form-control'
                name='Name'
                value={state.name}
                disabled={state.history}
                onChange={e => setState({ name: e.target.value })}
              />
            </div>
            <div className='col-md-5'>
              <label>Açıklama</label>
              <IPInput
                type='text'
                id='Title'
                className='form-control'
                name='Title'
                value={state.info}
                disabled={state.history}
                onChange={e => setState({ info: e.target.value })}
              />
            </div>
            <div className='col-md-2'>
              <br></br>
              <InputCampaign
                type='datetime-local'
                disabled={state.history}
                id='StartDate'
                name='StartDate'
                label={'Başlangıç Tarihi'}
                value={toDateTimeLocal(startDate)}
                onChange={e => getDate(e.target.value, 'Start')}
                isActive
              />
            </div>
            <div className='col-md-2'>
              <br></br>
              <InputCampaign
                type='datetime-local'
                disabled={state.history}
                id='EndDate'
                name='EndDate'
                label={'Bitiş Tarihi'}
                value={toDateTimeLocal(endDate)}
                onChange={e => getDate(e.target.value, 'End')}
                isActive
              />
            </div>
          </div>
          <div>
            <div className='formCard'>
              <div className='cariFilterContainer'>
                <div className='d-flex gap-4'>
                  <div className='leftContainer w-50'>
                    <div>
                      <a className='campaignExcelBtn' href='/assetWeb/docs/dealer-codes-example.xlsx' target='_blank'>
                        Örnek Excel
                      </a>

                      <div className='listBtnGroup'>
                        <IPButton
                          className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariCode)}`}
                          text={`Cari Kodu Listesi ${state.dealerCodeList?.length > 0 ? '✅' : ''}`}
                          onClick={() => setState({ selectedDealerList: CariListType.CariCode })}
                        />
                        <IPButton
                          className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariType)}`}
                          text={`Cari Tipi Listesi ${state.dealerTypeList?.length > 0 ? '✅' : ''}`}
                          onClick={() => setState({ selectedDealerList: CariListType.CariType })}
                        />
                        <IPButton
                          className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariAltType)}`}
                          text={`Cari Alt Tipi Listesi ${state.dealerSubTypeList?.length > 0 ? '✅' : ''}`}
                          onClick={() =>
                            setState({
                              selectedDealerList: CariListType.CariAltType,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className='p-0 m-0'>
                      <div
                        className={`cariFilterTextAreaWrap ${enumFunction(
                          state.selectedDealerList,
                          CariListType.CariCode
                        )}`}
                      >
                        <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                          <IPFile
                            disabled={state.history}
                            id='FileInputDealerCode'
                            multiple={false}
                            handleFiles={e => handleFiles(e, 'DealerCode')}
                            removeAfterSelect={true}
                            className='campaignFileInput'
                            label="Excel'den Yükle"
                          />

                          <IPButton
                            disabled={state.history}
                            text={'Dahil'}
                            className={`btnIncluded ${state.dealerInclude ? 'active' : ''}`}
                            onClick={() => setState({ dealerInclude: true })}
                          />
                          <IPButton
                            disabled={state.history}
                            text={'Hariç'}
                            className={`btnExcept ${!state.dealerInclude ? 'active' : ''}`}
                            onClick={() => setState({ dealerInclude: false })}
                          />
                        </div>
                        <CampaignSelect
                          placeholder={(location.state as any) ? state.dealerCodeList : 'Cari Kod Listesi'}
                          height={180}
                          //options={state.dealerCodeOptions}
                          onChangeMulti={(options: ISelectOption[]) => {
                            if ((location.state as any)?.data !== 'history') setState({ dealerCodeList: options })
                          }}
                          value={state.dealerCodeList}
                        />
                        <input
                          disabled={(location.state as any)?.data === 'history' && true}
                          type='text'
                          placeholder='Cari kodu yazın'
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyPress={handleInputKeyPress}
                        />
                      </div>

                      <div
                        className={`cariFilterTextAreaWrap ${enumFunction(
                          state.selectedDealerList,
                          CariListType.CariType
                        )}`}
                      >
                        <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                          <div className='btns'>
                            <IPFile
                              id='FileInputDealerType'
                              multiple={false}
                              handleFiles={e => handleFiles(e, 'DealerType')}
                              removeAfterSelect={true}
                              className='campaignFileInput'
                              label="Excel'den Yükle"
                            />
                          </div>
                          <IPButton
                            disabled={state.history}
                            text={'Dahil'}
                            className={`btnIncluded ${state.dealerTypeInclude ? 'active' : ''}`}
                            onClick={() => setState({ dealerTypeInclude: true })}
                          />
                          <IPButton
                            disabled={state.history}
                            text={'Hariç'}
                            className={`btnExcept ${!state.dealerTypeInclude ? 'active' : ''}`}
                            onClick={() => setState({ dealerTypeInclude: false })}
                          />
                        </div>
                        <CampaignSelect
                          placeholder={'Cari Tipi Listesi'}
                          height={180}
                          options={state.dealerTypeOptions}
                          onChangeMulti={(options: ISelectOption[]) => {
                            if ((location.state as any)?.data != 'history') setState({ dealerTypeList: options })
                          }}
                          value={state.dealerTypeList}
                        />
                      </div>

                      <div
                        className={`cariFilterTextAreaWrap ${enumFunction(
                          state.selectedDealerList,
                          CariListType.CariAltType
                        )}`}
                      >
                        <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                          {/* <div className='btns'>
												<IPFile id='FileInputDealerSubType' multiple={false} handleFiles={e => handleFiles(e, 'DealerSubType')} removeAfterSelect className='campaignFileInput' label="Excel'den Yükle" />
											</div> */}
                          <IPButton
                            disabled={state.history}
                            text={'Dahil'}
                            className={`btnIncluded ${state.dealerSubTypeInclude ? 'active' : ''}`}
                            onClick={() => setState({ dealerSubTypeInclude: true })}
                          />
                          <IPButton
                            disabled={state.history}
                            text={'Hariç'}
                            className={`btnExcept ${!state.dealerSubTypeInclude ? 'active' : ''}`}
                            onClick={() => setState({ dealerSubTypeInclude: false })}
                          />
                        </div>
                        <CampaignSelect
                          placeholder={'Cari Alt Tipi Listesi'}
                          height={180}
                          options={state.dealerSubTypeOptions}
                          onChangeMulti={(options: ISelectOption[]) => {
                            if ((location.state as any)?.data != 'history') setState({ dealerSubTypeList: options })
                          }}
                          value={state.dealerSubTypeList}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='rightContainer w-50'>
                    <div className='row m-5'>
                      <div className='col-md-4'>
                        <label>Şirket</label>
                        <IPSelectBox
                          isClearable
                          className='Company'
                          name='Company'
                          options={state.companyListOptions}
                          isDisabled={state.history}
                          value={state.companyOption}
                          onChangeSingle={option => {
                            setState({ companyOption: option })
                          }}
                        />
                      </div>
                      <div className='col-md-4'>
                        <label>Sıra No</label>
                        <IPInput
                          type='number'
                          id='Order'
                          className='form-control'
                          name='Order'
                          value={state.order}
                          disabled={state.history}
                          onChange={e => setState({ order: Number(e.target.value) })}
                        />
                      </div>
                      <div className='col-md-4'>
                        <br></br>
                        <IPCheckbox
                          name='Active'
                          onClick={() => setState({ enabled: !state.enabled })}
                          checked={state.enabled}
                          disabled={state.history}
                          label={'Aktif'}
                        />
                      </div>
                    </div>
                    <br></br>
                    <div className='row m-5'>
                      <div className='col-md-4'>
                        <IPCheckbox
                          name='Basket'
                          onClick={() => setState({ isBasket: !state.isBasket })}
                          checked={state.isBasket}
                          disabled={state.history}
                          label={'Sepette Göster'}
                        />
                      </div>
                      <div className='col-md-4'>
                        <IPCheckbox
                          name='Detail'
                          onClick={() => setState({ isDetail: !state.isDetail })}
                          checked={state.isDetail}
                          disabled={state.history}
                          label={'Ürün Detayda Göster'}
                        />
                      </div>
                    </div>
                    <br></br>
                    <div className='row m-5'>
                      <label>ÖNERİLECEK ÜRÜNLER</label>
                      <div className='m-5'>
                        <IPSelectBox
                          isMulti
                          isCreatableSelect
                          name='ProductCode'
                          isDisabled={state.history}
                          placeholder='Önerilecek Ürün Kodu'
                          onChangeMulti={(options: ISelectOption[]) => {
                            setProductData(options)
                          }}
                          //options={state.productCode}
                          value={productData}
                          onCreateOption={option => onCreateOption(option, setProductData)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <label className='w-100 text-center' id='conditions'>
              <span
                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                onClick={() => setState({ condOpen: !state.condOpen })}
              >
                ÖNERME KOŞULLARI
              </span>
            </label>
            {state.condOpen && (
              <div className='campaignWrap m-5' style={{ backgroundColor: 'white', minHeight: '50vh' }}>
                <div className='topAreaListGroup'>
                  <div>
                    <div
                      className='topAreaInputGroup position-relative'
                      data-toggle='tooltip'
                      data-placement='top'
                      title='Ürün Adı'
                    >
                      <div className='w-100 text-center'>Ürün Kodu</div>
                      <CampaignSelect
                        disabled={state.history}
                        isDisabled={state.history}
                        //options={state.productCodeOptions}
                        value={state.productCodes}
                        onChangeMulti={(options: ISelectOption[]) => setState({ productCodes: options })}
                        placeholder={'Ürün Kodu'}
                        menuStyle={true}
                      />
                      <input
                        type='text'
                        disabled={state.history}
                        placeholder='Ürün kodu yazın'
                        value={inputValueCond}
                        onChange={handleInputCondChange}
                        onKeyPress={handleInputKeyPressCond}
                      />
                    </div>
                  </div>

                  <div>
                    <div className='topAreaInputGroup position-relative'>
                      <div className='w-100 text-center'>Kota</div>
                      <CampaignSelect
                        disabled={state.history}
                        options={state.quotaCodeOptions}
                        placeholder={'Quota Kodu'}
                        value={state.quotaList}
                        onChangeMulti={options => setState({ quotaList: options })}
                      />
                    </div>
                  </div>

                  <div className='topAreaInputGroup position-relative'>
                    <div className='w-100 text-center'>Kategori</div>
                    <CampaignSelect
                      disabled={state.history}
                      placeholder={'Kategori'}
                      value={state.catList}
                      options={state.categoryOptions}
                      onChangeMulti={options => setState({ catList: options })}
                    />
                  </div>

                  <div className='topAreaInputGroup position-relative'>
                    <div className='w-100 text-center'>Marka</div>
                    <CampaignSelect
                      disabled={state.history}
                      placeholder={'Marka'}
                      options={state.brandOptions?.map(item => {
                        return { label: item, value: item }
                      })}
                      // placeholder={location.state ? campaignBasketConditions.map(a => a.XBrandList) : 'Marka Kodu'}
                      value={state.brandList}
                      onChangeMulti={options => setState({ brandList: options })}
                    />
                  </div>

                  <div className='topAreaInputGroup position-relative'>
                    <div className='w-100 text-center'>Ürün Alt Tipi</div>
                    <CampaignSelect
                      disabled={state.history}
                      options={state.productSubTypeOptions}
                      placeholder={'Alt Tip Kodu'}
                      // placeholder={
                      //   location.state ? campaignBasketConditions.map(a => a.XProductSubTypeList) : 'Alt Tip Kodu'
                      // }
                      value={state.productSubTypeList}
                      onChangeMulti={options => setState({ productSubTypeList: options })}
                    />
                  </div>
                </div>
              </div>
            )}

            <div className='row m-5' style={{ display: 'flex', justifyContent: 'end' }}>
              <div className='col-md-6 m-5' style={{ display: state.history ? 'none' : 'flex', justifyContent: 'end' }}>
                <IPButton
                  text='Kaydet'
                  type='button'
                  disabled={state.history}
                  className='btn btn-success w-25'
                  onClick={addSuggestion}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(ProductSuggestAdd)
