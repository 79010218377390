import React, { MouseEventHandler, useCallback, useState } from 'react'
import Select, {
  Props,
  GroupBase,
  MenuProps,
  components,
  GroupProps,
  InputProps,
  ActionMeta,
  MultiValue,
  NoticeProps,
  OptionProps,
  SingleValue,
  StylesConfig,
  ControlProps,
  MenuListProps,
  ContainerProps,
  MultiValueProps,
  SingleValueProps,
  PlaceholderProps,
  GroupHeadingProps,
  CSSObjectWithLabel,
  ValueContainerProps,
  ClearIndicatorProps,
  LoadingIndicatorProps,
  DropdownIndicatorProps,
  IndicatorSeparatorProps,
  IndicatorsContainerProps,
} from 'react-select'
import { StylesConfigFunction } from 'react-select/dist/declarations/src/styles'
import CreatableSelect from 'react-select/creatable'
import { translate } from '../../base/localization'
import useIPTheme from '../../base/theme/useIPTheme'
import Translations from '../../translations'

const EMOJIS = ['👍', '🤙', '👏', '👌', '🙌', '✌️', '🖖', '👐']

export interface ISelectOption {
  value: string | number
  label: any
  isDisabled?: boolean
  tabSize?: number
}

declare type IsMulti = boolean

export interface ISelectGroupBase extends GroupBase<ISelectOption> {}

export interface IPSelectBoxProps extends Props<ISelectOption, IsMulti, ISelectGroupBase> {
  // customPropsHere?: string;
  onChangeSingle?: (value: ISelectOption) => void
  onChangeMulti?: (values: ISelectOption[]) => void
  controlStyles?: StylesConfigFunction<ControlProps<ISelectOption, false, ISelectGroupBase>> | undefined
  isCreatableSelect?: boolean
  onCreateOption?: (values: ISelectOption[]) => void
}

const IPControl = (props: ControlProps<ISelectOption, IsMulti>) => {
  // @ts-ignore
  const { emoji, onEmojiClick } = props.selectProps
  const style = { cursor: 'pointer' }

  return (
    <components.Control {...props}>
      {/* <span onMouseDown={onEmojiClick} style={style}>
        {emoji}
      </span> */}
      {props.children}
    </components.Control>
  )
}

const IPDropdownIndicator = (props: DropdownIndicatorProps<ISelectOption, IsMulti>): JSX.Element => {
  return <components.DropdownIndicator {...props}>-</components.DropdownIndicator>
}

const IPOption = (props: OptionProps<ISelectOption, boolean, ISelectGroupBase>): JSX.Element => {
  const theme = useIPTheme()

  return (
    <div style={{ display: 'flex' }}>
      {props.data.tabSize && props.data.tabSize > 0 ? (
        <span style={{ paddingLeft: `${props.data.tabSize * 20}px` }}>&nbsp;</span>
      ) : null}
      <components.Option {...props} />
    </div>
  )
}

export default function IPSelectBox(props: IPSelectBoxProps) {
  const t = translate(Translations)
  const ipTheme = useIPTheme()
  const [clickCount, setClickCount] = useState(0)

  const [createdOptions, setCreatedOptions] = useState<ISelectOption[]>([])

  const onClick: MouseEventHandler<HTMLSpanElement> = e => {
    setClickCount(clickCount + 1)
    e.preventDefault()
    e.stopPropagation()
  }

  const onChange = (
    newValue: MultiValue<ISelectOption> | SingleValue<ISelectOption>,
    actionMeta: ActionMeta<ISelectOption>
  ) => {
    setCreatedOptions(newValue as ISelectOption[])

    if (Array.isArray(newValue)) {
      props.onChangeMulti && props.onChangeMulti(newValue as ISelectOption[])
    } else {
      props.onChangeSingle && props.onChangeSingle(newValue as ISelectOption)
    }
  }

  const onCreateOption = (inputValue: string, oldVal: any) => {
    const option: ISelectOption = Object.assign({})
    option.value = inputValue
    option.label = inputValue

    let tmpCreatedOptions
    if (oldVal) {
      tmpCreatedOptions = [...oldVal, ...createdOptions]
    } else {
      tmpCreatedOptions = [...createdOptions]
    }

    if (createdOptions.findIndex((elem: ISelectOption) => elem.value === inputValue) === -1) {
      tmpCreatedOptions.push(option)
      setCreatedOptions(tmpCreatedOptions)
    }

    props.onCreateOption && props.onCreateOption(tmpCreatedOptions)
  }

  const customFilter = useCallback((candidate: any, searchText: string) => {
    if (
      candidate?.data?.label?.toString().toLocaleLowerCase('tr-TR').includes(searchText?.toLocaleLowerCase('tr-TR')) ||
      candidate?.data?.value?.toString().toLocaleLowerCase('tr-TR').includes(searchText?.toLocaleLowerCase('tr-TR'))
    ) {
      return true
    } else {
      return false
    }
  }, [])

  const styles: StylesConfig<ISelectOption, false, ISelectGroupBase> = {
    control: props.controlStyles
      ? props.controlStyles
      : (base: CSSObjectWithLabel, props: ControlProps<ISelectOption, false, ISelectGroupBase>) => {
          return {
            ...base,
            // paddingLeft: '1rem',
            // paddingTop: props.selectProps.isClearable ? "2px" : "5px",
            // padding: 0,
            // height: '43px',
            // maxHeight: '43px'
            //backgroundColor: ipTheme.colors.button.background
          }
        },
    menu: (base: CSSObjectWithLabel, props: MenuProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    option: (base: CSSObjectWithLabel, props: OptionProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    noOptionsMessage: (base: CSSObjectWithLabel, props: NoticeProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    singleValue: (base: CSSObjectWithLabel, props: SingleValueProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    multiValue: (base: CSSObjectWithLabel, props: MultiValueProps<ISelectOption, false, ISelectGroupBase>) => {
      const opacity = props.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return {
        ...base,
        opacity,
        transition,
      }
    },
    placeholder: (base: CSSObjectWithLabel, props: PlaceholderProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    dropdownIndicator: (
      base: CSSObjectWithLabel,
      props: DropdownIndicatorProps<ISelectOption, false, ISelectGroupBase>
    ) => {
      return {
        ...base,
        paddingTop: '4px',
      }
    },
    group: (base: CSSObjectWithLabel, props: GroupProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    groupHeading: (base: CSSObjectWithLabel, props: GroupHeadingProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    loadingIndicator: (
      base: CSSObjectWithLabel,
      props: LoadingIndicatorProps<ISelectOption, false, ISelectGroupBase>
    ) => {
      return {
        ...base,
      }
    },
    loadingMessage: (base: CSSObjectWithLabel, props: NoticeProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    indicatorSeparator: (
      base: CSSObjectWithLabel,
      props: IndicatorSeparatorProps<ISelectOption, false, ISelectGroupBase>
    ) => {
      return {
        ...base,
        padding: 0,
        margin: 0,
        height: '42px',
      }
    },
    indicatorsContainer: (
      base: CSSObjectWithLabel,
      props: IndicatorsContainerProps<ISelectOption, false, ISelectGroupBase>
    ) => {
      return {
        ...base,
      }
    },
    input: (base: CSSObjectWithLabel, props: InputProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
        padding: 0,
        margin: 0,
      }
    },
    clearIndicator: (base: CSSObjectWithLabel, props: ClearIndicatorProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
        paddingTop: '5px',
      }
    },
    container: (base: CSSObjectWithLabel, props: ContainerProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    valueContainer: (base: CSSObjectWithLabel, props: ValueContainerProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    multiValueLabel: (base: CSSObjectWithLabel, props: MultiValueProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    multiValueRemove: (base: CSSObjectWithLabel, props: MultiValueProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    menuList: (base: CSSObjectWithLabel, props: MenuListProps<ISelectOption, false, ISelectGroupBase>) => {
      return {
        ...base,
      }
    },
    menuPortal: (base: CSSObjectWithLabel, props: any) => {
      return {
        ...base,
      }
    },
  }

  const emoji = EMOJIS[clickCount % EMOJIS.length]

  if (props.isCreatableSelect) {
    return (
      <CreatableSelect
        {...props}
        placeholder={props.placeholder ?? t('components.selectBox.pleaseSelect')}
        styles={styles}
        onChange={onChange}
        onCreateOption={e => onCreateOption(e, props.value)}
        components={{
          Control: IPControl,
          Option: IPOption,
          // DropdownIndicator,
        }}
        filterOption={customFilter}
        theme={theme => {
          return {
            ...theme,
            borderRadius: 0,
            spacing: {
              baseUnit: 5,
              controlHeight: 5,
              menuGutter: 3,
            },
            colors: {
              ...theme.colors,
              // primary25: 'blue',
              // primary: 'blue',
            },
          }
        }}
        // @ts-ignore
        emoji={emoji}
        onEmojiClick={onClick}
      />
    )
  }

  return (
    <Select
      {...props}
      placeholder={props.placeholder ?? t('components.selectBox.pleaseSelect')}
      styles={styles}
      onChange={onChange}
      components={{
        Control: IPControl,
        Option: IPOption,
        // DropdownIndicator,
      }}
      filterOption={customFilter}
      theme={theme => {
        return {
          ...theme,
          borderRadius: 0,
          spacing: {
            baseUnit: 5,
            controlHeight: 5,
            menuGutter: 3,
          },
          colors: {
            ...theme.colors,
            // primary25: 'blue',
            // primary: 'blue',
          },
        }
      }}
      // @ts-ignore
      emoji={emoji}
      onEmojiClick={onClick}
    />
  )
}

// Possible Custom Components

/**
  ClearIndicator
  Control
  DropdownIndicator
  DownChevron
  CrossIcon
  Group
  GroupHeading
  IndicatorsContainer
  IndicatorSeparator
  Input
  LoadingIndicator
  Menu
  MenuList
  MenuPortal
  LoadingMessage
  NoOptionsMessage
  MultiValue
  MultiValueContainer
  MultiValueLabel
  MultiValueRemove
  Option
  Placeholder
  SelectContainer
  SingleValue
  ValueContainer
 */
