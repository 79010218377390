import { useState } from 'react'
import { Link } from 'react-router-dom'

interface IProductBrand {
  id: number
  name: string
  image: string
  brandLogo: string
}

export default function ProductBrand(props: IProductBrand) {
  const [isActive, setIsActive] = useState(false)

  const hoverIn = () => {
    setIsActive(true)
  }

  const hoverOut = () => {
    setIsActive(false)
  }

  return (
    <div className='productBrand2' onMouseEnter={hoverIn} onMouseLeave={hoverOut}>
      <img src={props.brandLogo} alt='' />
      <div className={`productImagePopup ${isActive ? 'active' : ''}`}>
        <div className='productImage'>
          <Link to={`/product/detail/${props.id}`} id={props.name}>
            <img src={props.image} alt='' />
          </Link>
        </div>
      </div>
    </div>
  )
}
