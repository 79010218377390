import { useEffect } from 'react'
import { uuidv4 } from '../../base/utils'

const useIyzicoPayment = (paying: boolean, src: string) => {
  useEffect(() => {
    const elemId = uuidv4()

    if (paying) {
      const firstScriptElement = document.getElementsByTagName('script')[0]

      let newScriptElement = document.createElement('script')
      newScriptElement.id = elemId
      newScriptElement.src = src ? src : process.env.PUBLIC_URL + '/assetWeb/js/iyzitest.js'

      if (firstScriptElement && firstScriptElement.parentNode) {
        firstScriptElement.parentNode.insertBefore(newScriptElement, firstScriptElement)
      } else {
        document.head.appendChild(newScriptElement)
      }
    }

    return () => {
      const element = document.getElementById(elemId)

      if (element && element.parentNode) {
        element.parentNode.removeChild(element)
      }
    }
  }, [paying])
}

export default useIyzicoPayment
