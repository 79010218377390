import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { GetLogRequest, GetProvizyonRequest, IProvizyon } from '../models'

export class InsertLogApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/InsertProvizyonLog',
      requesterInfo,
    })
  }

  public async insertLogApi(request: GetLogRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
