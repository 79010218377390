import React, { useMemo, useState } from 'react'
import { translate } from '../../../../../base/localization'
import Translations from '../../../../../translations'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../../components/InputCampaign'
import Table from '../../../../../components/Table'
import { useCustomReducer } from '../../../../../base/customHooks'
import Swal from 'sweetalert2'
import { IMailSendRequest, IMailSendResponse } from '../models/models'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { AlertEmailSubmissionsReportApi } from '../services/alertEmailSubmissionsReportApi'


interface IMailSendState {
  mailSendData: IMailSendResponse[]
  alertEMailTypeOption: ISelectOption | null
  orderInfoMailOption: ISelectOption | null
}

function Index(props: BaseComponentWithContextProps) {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const t = translate(Translations)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const getInitialState = (): IMailSendState => {
    return {
      mailSendData: [],
      alertEMailTypeOption: null,
      orderInfoMailOption: null
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IMailSendState>(intialState);

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'col1',
      },
      {
        Header: 'Konu',
        accessor: 'col2',
      },
      {
        Header: 'Giden Mail',
        accessor: 'col3',
      },
      {
        Header: 'Mesaj Başlığı',
        accessor: 'col4',
      },
      {
        Header: 'Durum',
        accessor: 'col5',
      },
      {
        Header: 'Gönderildiği Tarih',
        accessor: 'col6',
      },
      {
        Header: 'Deneme Sayısı',
        accessor: 'col7',
      }
    ],
    []
  )

  const tableData = useMemo(
    () => state.mailSendData.map((item) => {
      const data = {
        col1: item.SequenceNumber,
        col2: item.Subject,
        col3: item.Mails && item.Mails.join(),
        col4: item.MessageHeader,
        col5: item.Status,
        col6: item.SendDate,
        col7: item.SendCount,
      }

      return data
    }), [state.mailSendData])

  const getMailSend = async () => {
    if (!startDate || !endDate || !state.alertEMailTypeOption || !state.orderInfoMailOption) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen zorunlu parametreleri doldurunuz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (new Date(startDate) > new Date(endDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Başlangıç tarihi bitiş tarihinden sonra olamaz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: IMailSendRequest = {
      StartDate: startDate && getDateByTimezoneOffset(startDate) as any,
      EndDate: endDate && getDateByTimezoneOffset(endDate) as any,
      MailType: state.alertEMailTypeOption ? +state.alertEMailTypeOption.value : 0,
      StateType: state.orderInfoMailOption ? +state.orderInfoMailOption.value : 0,
    }
    props.showLoading();
    const alertEmailSubmissionsReportApi: AlertEmailSubmissionsReportApi = new AlertEmailSubmissionsReportApi({})
    await alertEmailSubmissionsReportApi
      .alertEmailSubmissionsReport(req)
      .then(result => {
        if (result) {
          setState({ mailSendData: result })
        }
        props.hideLoading();
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const alertEMailTypeOptions: ISelectOption[] = [
    { label: 'Ürün Detayı', value: 1 },
    { label: 'Finansal Çözümler', value: 2 },
    { label: 'Şifre Hatırlatma', value: 3 },
    { label: 'Admin Şifre Tanımı', value: 4 }
  ]

  const orderInfoMailOptions: ISelectOption[] = [
    { label: 'Başarısız', value: 0 },
    { label: 'Başarılı', value: 1 },
    { label: 'Hepsi', value: 2 },
  ]

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>{t('pages.admin.siteReports.mailSend.header')}</span>
          </div>

          <div className='card-body'>
            <form className='formbanner'>
              <div className='row mb-3'>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label="Başlangıç Tarihi *"
                    value={startDate && toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label="Bitiş Tarihi *"
                    value={endDate && toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPSelectBox
                    isClearable
                    options={alertEMailTypeOptions}
                    value={state.alertEMailTypeOption}
                    placeholder="Gönderim Şekli *"
                    onChangeSingle={option => setState({ alertEMailTypeOption: option })}
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPSelectBox
                    isClearable
                    options={orderInfoMailOptions}
                    value={state.orderInfoMailOption}
                    placeholder="İşlem Tipi *"
                    onChangeSingle={option => setState({ orderInfoMailOption: option })}
                  />
                </div>
                <div className='col-12 mb-3' style={{ display: "flex", justifyContent: "end" }}>
                  <IPButton text='Göster' onClick={getMailSend} style={{ width: "10%" }} />
                </div>
              </div>
            </form>
            <div style={{ width: "100%" }}>
              <Table columns={tableColumns} data={tableData} headerName={`İşlem Tarihi Aralığı: ${new Date(startDate).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()} tarihleri arasındaki e-posta (site yönetim uyarı) gönderimleri`} columnCount={7} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Index)