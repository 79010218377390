import React, { useMemo, useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { PostCampaignQueryListApi } from '../services/postCampaignQueryListApi'
import { IPostCampaignQueryListRequest, IPostCampaignQueryResponseModel } from '../models/responseModels'
import { useCustomReducer } from '../../../../../base/customHooks'
import Swal from 'sweetalert2'
import Table from './Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { ICompany } from '../../../components/models/models'
import { getCompanyApi } from '../../../components/api'
import { toDateTimeLocal } from '../../../../../base/utils/dataHelper'
import moment from 'moment'

interface CampaignQueryListState {
  CampaignReportList: IPostCampaignQueryResponseModel[]
  Dealer: string,
  ProductCode: string
  companyOption: ISelectOption | null
  companies: ICompany[]
}

function CampaignQuery(props: BaseComponentWithContextProps) {

  const getInitialState = (): CampaignQueryListState => {
    return {
      CampaignReportList: [],
      Dealer: "",
      ProductCode: "",
      companyOption: null,
      companies: []
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<CampaignQueryListState>(intialState)
  const t = translate(Translations)

  const postCampaignQueryReport = async () => {
    if (!state.companyOption) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen şirket seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (state.ProductCode.length === 0 && state.Dealer.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen ürün kodu veya bayi kodu giriniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: IPostCampaignQueryListRequest = {
      ProductCode: state.ProductCode,
      DealerCode: state.Dealer.length > 5 ? state.Dealer.padStart(10, '0')
        : state.Dealer,
      CompanyCode: state.companyOption ? String(state.companyOption.value) : ""
    }
    props.showLoading();
    const getByIdApi = new PostCampaignQueryListApi({})
    await getByIdApi.PostCampaignQueryList(req)
      .then(async result => {
        setState({ CampaignReportList: result })
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }
  const tableColumns = useMemo(
    () => [

      {
        Header: 'ID',
        accessor: 'col1',
      },
      {
        Header: 'TrxNo',
        accessor: 'col2',
      },
      {
        Header: 'Bayi Kodu',
        accessor: 'col3',
      },
      {
        Header: 'Bayi Tipi',
        accessor: 'col4',
      },
      {
        Header: 'Bayi Alt Tipi',
        accessor: 'col5',
      },
      {
        Header: 'Materyal Numarası',
        accessor: 'col6',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col7',
      },
      {
        Header: 'Marka',
        accessor: 'col8',
      },
      {
        Header: 'Kota',
        accessor: 'col9',
      },
      {
        Header: 'Ürün Alt Tipi',
        accessor: 'col10',
      },
      {
        Header: 'Fiyat',
        accessor: 'col11',
      },
      {
        Header: 'Para Birimi',
        accessor: 'col12',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'col13',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'col14',
      },
    ],
    []
  )
  const tableData = useMemo(
    () =>
      state.CampaignReportList.map((item, index) => {
        const data = {
          col1: item.Id,
          col2: item.TransferNumber,
          col3: item.TheCode && item.TheCode.replace("0000", ""),
          col4: item.BayiTipi,
          col5: item.BayiAltTipi,
          col6: item.MetarialNumber,
          col7: item.ProductCode,
          col8: item.Brand,
          col9: item.Quota,
          col10: item.UrunAltTipi,
          col11: item.KampanyaFiyati ? (item.KampanyaFiyati.indexOf(",") > -1 ? Number(item.KampanyaFiyati.replace(",", ".")).toFixed(2) : Number(item.KampanyaFiyati) + ".00") : 0.00,
          col12: item.KampanyaParaBirimi,
          col13: moment(toDateTimeLocal(item.BaslanicTarihi)).format('DD.MM.YYYY, h:mm:ss'),
          col14: moment(toDateTimeLocal(item.BitisTarihi)).format('DD.MM.YYYY, h:mm:ss'),
          //col13: new Date(toDateTimeLocal(item.BaslanicTarihi).toLocaleString('tr-TR')),
          //col14: new Date(item.BitisTarihi).toLocaleString('tr-TR')

        }
        return data
      }),
    [state.CampaignReportList]
  )

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    setState({ companies: company.filter((co) => { return co.Name !== "Index Group" }) })
  }
  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <div className='content col-lg-12 m-auto'>
      <div className='card'>
        <div className='card-header'>
          <span className='h4'>Kampanya Sorgulama</span>
        </div>
        <div className='card-body'>
          <form className='formbanner'>
            <div className='row'>
              <div className='col-lg-3'>
                <IPInput type='text' value={state.Dealer} placeholder={t('pages.admin.homeAndSiteOperation.campaignQuery.campaignQuery.dealerCode')} onChange={(e) => setState({ Dealer: e.target.value })} />
              </div>
              <div className='col-lg-3'>
                <IPInput type='text' value={state.ProductCode} placeholder={t('pages.admin.homeAndSiteOperation.campaignQuery.campaignQuery.productCode')} onChange={(e) => setState({ ProductCode: e.target.value })} />
              </div>
              <div className='col-lg-3'>
                <IPSelectBox
                  isClearable
                  options={
                    state.companies &&
                    state.companies.map((item: ICompany) => ({
                      label: item.Name,
                      value: `${item.Code}`,
                    }))
                  }
                  placeholder="Şirket Seçiniz..."
                  value={state.companyOption}
                  onChangeSingle={option => setState({ companyOption: option })}
                />
              </div>
              <div className='col-lg-1'>
                <IPButton
                  text={t('pages.admin.homeAndSiteOperation.campaignQuery.campaignQuery.inquire')}
                  className='btn btn-success'
                  onClick={postCampaignQueryReport}
                />
              </div>
            </div>
          </form>
          <br />
          {state.CampaignReportList && state.CampaignReportList.length > 0 &&
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <Table columns={tableColumns} data={tableData} currentPage={0} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(CampaignQuery)
