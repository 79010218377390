import React, { useMemo, useState, useEffect } from 'react'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPInput from '../../../../../components/IPInput'
import IPButton from '../../../../../components/IPButton'
import Table from '../../../../../components/Table'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ICompany } from '../../../components/models/models'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { getCompanyApi } from '../../../components/api'
import Swal from 'sweetalert2'
import { INotFoundSpecialPriceCodeRequest, INotFoundSpecialPriceCodeResponse } from '../models/models'
import { NotFoundSpecPriceListApi } from '../services/notFoundSpecPriceListApi'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { ServiceRouter } from '../../../../../base/proxy/serviceRouter'
interface IProductsWithNoSpecialPriceState {
  productCode: string
  date: string
  company: ISelectOption | null
  notSpecialPriceData: INotFoundSpecialPriceCodeResponse[]
}

function ProductsWithNoSpecialPrice(props: BaseComponentWithContextProps) {
  const getInitialState = (): IProductsWithNoSpecialPriceState => {
    return {
      productCode: "",
      date: "",
      company: null,
      notSpecialPriceData: []
    }
  }

  const [companies, setCompanies] = useState<ICompany[]>([])
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IProductsWithNoSpecialPriceState>(intialState)
  const t = translate(Translations)

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Ürün Kodu',
        accessor: 'col1',
      },
      {
        Header: 'Cari Kod',
        accessor: 'col2',
      },
      {
        Header: 'Şirket Kodu',
        accessor: 'col3',
      },
      {
        Header: 'Özel Fiyat Kodu',
        accessor: 'col4',
      },
      {
        Header: 'Cari Tip Kodu',
        accessor: 'col5',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.notSpecialPriceData.map(item => {
        const data = {
          col1: item.UrunKodu,
          col2: item.BayiKodu.replace("0000", ""),
          col3: companies.find((co) => { return String(co.Code) === item.Sirket })?.Name,
          col4: item.OzelFiyatKodu,
          col5: item.CariTip,
        }
        return data
      }),
    [state.notSpecialPriceData]
  )

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    setCompanies(
      company
        .filter(com => {
          return com.Name !== 'Index Group'
        }))
  }

  const navigate: any = useNavigate()

  const buttonOnClick = async () => {
    if (!state.productCode || state.productCode.length === 0 || !state.company) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen ürün kodu ve şirket giriniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      setState({ date: "" })
      return
    }

    let navigateRoute: string = `/admin/ProductOperation/ProductsWithNoSpecialPrice?product=${String(state.productCode)}&company=${String(state.company.value)}`
    navigate(navigateRoute)
  }

  const noPriceApi = async () => {
    const product = searchParams.get('product')
    const company = searchParams.get('company')

    const req: INotFoundSpecialPriceCodeRequest = {
      ProductCode: String(product),
      Company: String(company)
    }

    if (req.ProductCode != "null" && req.Company != "null") {
      props.showLoading()
      setState({ productCode: req.ProductCode, company: { label: companies.find(a => a.Code.toString() == req.Company)?.Name, value: req.Company } })
      const notFoundSpecPriceListApi: NotFoundSpecPriceListApi = new NotFoundSpecPriceListApi({})

      await notFoundSpecPriceListApi
        .notFoundSpecPriceList(req)
        .then(async result => {
          if (result) {
            setState({ date: new Date().toLocaleString(), notSpecialPriceData: result })
            props.hideLoading()
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
  }

  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    if (companies.length === 0) {
      getCompanies()
    }
  }, [companies])

  useEffect(() => {
    if (companies.length > 0) {
      noPriceApi()
    }
  }, [searchParams.get('product'), searchParams.get('company'), companies])


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              {t('pages.admin.productOperation.productsWithNoSpecialPrice.productsWithNoSpecialPrice.header')}
            </span>
            <br />

          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-md-3'>
                <IPInput type='text' placeholder='Ürün Kodu' value={state.productCode} onChange={(e) => setState({ productCode: e.target.value })} />
                <span>{state.date}</span>
              </div>
              <div className='col-md-3'>
                <IPSelectBox
                  isClearable
                  options={
                    companies &&
                    companies.map((item: ICompany) => ({
                      label: item.Name,
                      value: `${item.Code}`,
                    }))
                  }
                  placeholder="Şirket Seçiniz..."
                  value={state.company}
                  onChangeSingle={option => setState({ company: option })}
                />
              </div>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Göster'
                style={{ width: '25px' }}
                onClick={buttonOnClick}
              />
            </div>
            <div className='mt-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(ProductsWithNoSpecialPrice)
