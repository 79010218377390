import { useEffect, useState } from 'react'
import { css } from '@emotion/css'
import { IOrderNote } from './models/models'

export default function WarehouseNote(props) {
  const [value, setValue] = useState(props.value ? props.value : '')

  useEffect(() => {
    setValue(props.value ? props.value : '')
  }, [props.value])
  const handleChange = (val: string) => {
    setValue(val)

    const toBeUpdatedNoteIdx = props.orderNotes.findIndex((noteElem: IOrderNote) => {
      return noteElem.CompanyId === props.companyId && noteElem.WarehouseId === props.warehouseId
    })

    if (toBeUpdatedNoteIdx > -1) {
      props.orderNotes[toBeUpdatedNoteIdx].Note = val
    }

    props.setOrderNotes()
  }

  return (
    <div
      className={`position-relative w-100 ${css`
        textarea:focus + div {
          display: none;
        }
      `}`}
    >
      <textarea
        className={`form-control rounded-0 ${css`
          border: 1px solid transparent;
          &:focus {
            border: 1px solid #dee2e6;
          }
        `}`}
        id='exampleFormControlTextarea1'
        rows={1}
        value={value}
        onChange={e => handleChange(e.target.value)}
      ></textarea>
      {value.length === 0 && (
        <div
          className={css`
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 13px;
            font-style: italic;
            pointer-events: none;
            padding-left: 0.4rem;
            ${props.companyColor
              ? `span {
                padding: 0 0.4rem;
                color: ${props.companyColor}
              }`
              : ''}
          `}
        >
          depo siparişiniz için notunuzu <span>buraya</span> girebilirsiniz...
        </div>
      )}
    </div>
  )
}
