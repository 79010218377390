import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { FilterProductsResponse } from '../models/models'

export class GetNotFilteredProductsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetUndefinedFilteredProducts',
      requesterInfo,
    })
  }

  public async getNotFilteredProducts(): Promise<FilterProductsResponse[]> {
    return await this.getAsync<FilterProductsResponse[]>()
  }
}
