import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IBannerCategory } from '../models/requestModels'

export class GetBannerCategoriesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/banner/get-banner-categories',
      requesterInfo,
    })
  }

  public async getBannerCategories(): Promise<IBannerCategory[]> {
    return await this.getAsync<IBannerCategory[]>()
  }
}
