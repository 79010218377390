import React, { useEffect, useState, useMemo } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetXmlCompanyParametersApi } from '../services/getXmlCompanyParametersApi'
import Swal from 'sweetalert2'
import { IUpdateXmlCompanyParams, IXmlCompanyParameters } from '../models/models'
import Table from '../../../../../components/Table'
import IPModal from '../../../../../components/IPModal'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPInput from '../../../../../components/IPInput'
import { UpdateXmlCompanyParamsApi } from '../services/updateXmlCompanyParamsApi'

interface IXmlCompanyParametersListState {
  showDetail: boolean
  companyCode: string
  company: string
  wariningDayBefore: number
  stopDayBefore: number
  minTotalAmount: number
  forInfoMailAddresses: string
  id: number
}

function XmlCompanyParametersList(props: BaseComponentWithContextProps) {
  const [companyParametersList, setCompanyParametersList] = useState<IXmlCompanyParameters[]>([])

  const getInitialState = (): IXmlCompanyParametersListState => {
    return {
      showDetail: false,
      companyCode: "",
      company: "",
      wariningDayBefore: 0,
      stopDayBefore: 0,
      minTotalAmount: 0,
      forInfoMailAddresses: "",
      id: 0
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IXmlCompanyParametersListState>(intialState)

  const getXmlCompanyParameters = async () => {
    props.showLoading()
    const getXmlCompanyParametersApi: GetXmlCompanyParametersApi = new GetXmlCompanyParametersApi({})

    await getXmlCompanyParametersApi
      .getXmlCompanyParameters()
      .then(async result => {
        if (result) {
          setCompanyParametersList(result)
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const updateXmlCompanyParams = async () => {
    const req: IUpdateXmlCompanyParams = {
      Id: state.id,
      CompanySapCode: state.companyCode,
      WariningDayBefore: state.wariningDayBefore > 0 ? state.wariningDayBefore : 0,
      StopDayBefore: state.stopDayBefore > 0 ? state.stopDayBefore : 0,
      MinTotalAmount: state.minTotalAmount > 0 ? state.minTotalAmount : 0,
      ForInfoMailAddresses: state.forInfoMailAddresses,
      Deleted: false
    }
    props.showLoading()
    const updateXmlCompanyParamsApi: UpdateXmlCompanyParamsApi = new UpdateXmlCompanyParamsApi({})

    await updateXmlCompanyParamsApi
      .updateXmlCompanyParams([req])
      .then(async result => {
        if (result) {
          setCompanyParametersList(prevState => {
            return prevState.map((prev: IXmlCompanyParameters) => {
              if (prev.Id === state.id) {
                prev = {
                  Id: prev.Id,
                  CompanySapCode: prev.CompanySapCode,
                  WariningDayBefore: state.wariningDayBefore,
                  StopDayBefore: state.stopDayBefore,
                  MinTotalAmount: state.minTotalAmount,
                  ForInfoMailAddresses: state.forInfoMailAddresses,
                  Deleted: false
                }
              }
              return prev
            })
          })
          setState({ showDetail: false })
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }
  useEffect(() => {
    getXmlCompanyParameters()
  }, [])

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Şirket',
        accessor: 'col2',
      },
      {
        Header: 'Uyarı Gün Sayısı',
        accessor: 'col3',
      },
      {
        Header: 'Durdurma Gün Sayısı',
        accessor: 'col4',
      },
      {
        Header: 'Minimum Tutar',
        accessor: 'col5',
      },
      // {
      //   Header: 'Mail Adresleri',
      //   accessor: 'col6',
      // },
      {
        Header: 'İşlem',
        accessor: 'col7',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      companyParametersList.map((item, index) => {
        const company = item.CompanySapCode === "1000" ? "İndex" : (item.CompanySapCode === "1100" ? "Datagate" : (item.CompanySapCode === "1200" ? "Despec" : "Netex"))
        const data = {
          col1: index + 1,
          col2: company,
          col3: item.WariningDayBefore,
          col4: item.StopDayBefore,
          col5: item.MinTotalAmount.toFixed(2),
          // col6: item.ForInfoMailAddresses,
          col7: (
            <div className='ml-2'
              onClick={() => {
                return setState({
                  showDetail: true,
                  companyCode: item.CompanySapCode,
                  company,
                  wariningDayBefore: item.WariningDayBefore,
                  stopDayBefore: item.StopDayBefore,
                  minTotalAmount: item.MinTotalAmount,
                  forInfoMailAddresses: item.ForInfoMailAddresses,
                  id: item.Id
                })
              }}
            >
              <i className='icon-edit' style={{ color: 'green' }}>
              </i>{' '}
            </div>)
        }
        return data
      }),
    [companyParametersList]
  )

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>XML Genel Şirket Parametreleri</span>
          </div>
          <div className='card-body'>
            <Table columns={tableColumns} data={tableData} currentPage={0} />
          </div>
        </div>
      </div>
      <IPModal
        show={state.showDetail}
        onClose={() => setState({ showDetail: false })}
        width={500}
        confirmText='Kaydet'
        onConfirm={updateXmlCompanyParams}
        title={`XML ${state.company} Şirketi Parametreleri Güncelleme`}
      >
        <>
          <label>Uyarı Gün Sayısı</label>
          <IPInput type="text" value={String(state.wariningDayBefore)} onChange={(e) => setState({ wariningDayBefore: +e.target.value })} />
          <label>Durdurma Gün Sayısı</label>
          <IPInput type="text" value={String(state.stopDayBefore)} onChange={(e) => setState({ stopDayBefore: +e.target.value })} />
          <label>Minimum Tutar</label>
          <IPInput type="text" value={String(state.minTotalAmount)} onChange={(e) => setState({ minTotalAmount: +e.target.value })} />
          {/* <label>Mail Adresleri</label>
          <IPInput type="text" value={state.forInfoMailAddresses} onChange={(e) => setState({ forInfoMailAddresses: e.target.value })} /> */}
          {/* <p style={{ color: "red" }}>Birden fazla mail adresi ekleyeceksiniz ; ile ayırınız.</p> */}
        </>
      </IPModal>
    </div>
  )
}
export default componentWithContext(XmlCompanyParametersList)
