import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IPostAnnouncementListRequest } from '../models/requestModels'

export class PostAnnouncementApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/openbox/post',
      requesterInfo,
    })
  }

  public async postAnnouncement(request: IPostAnnouncementListRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
