import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IBrandDetail } from '../commonModels/models'

export class GetBrandDetailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'brand/HomePageBannerBrands',
      requesterInfo,
    })
  }
  public async getBrandList(pageSize: number, id: number): Promise<IBrandDetail> {
    this.props.url = `${this.props.url}/${id}/${pageSize}/IsHomePage`
    return await this.getAsync<IBrandDetail>()
  }
}
