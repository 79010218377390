import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'

export class DeleteBrandApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/brand/deleteBrand',
      requesterInfo,
    })
  }

  public async deleteBrand(request: number): Promise<CommonPostResponse> {
    this.props.url = `${this.props.url}/${request}`
    return await this.deleteAsync<CommonPostResponse>()
  }
}
