import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ICreateOfferToSubDealerRequest, ICreateOfferToSubDealerResponse } from '../models/models'

export class CreateOfferToSubDealerApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'offer/createOfferToSubDealer',
      requesterInfo,
    })
  }

  public async createOffer(request: ICreateOfferToSubDealerRequest): Promise<ICreateOfferToSubDealerResponse> {
    return await this.postAsync<ICreateOfferToSubDealerResponse>(request)
  }
}
