import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IAnnouncementList } from '../models/requestModels'

export class DeleteAnnouncementApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/openbox/',
      requesterInfo,
    })
  }

  public async deleteAnnouncement(id: number): Promise<IAnnouncementList[]> {
    this.props.url = `${this.props.url}${id}`
    return await this.deleteAsync<IAnnouncementList[]>()
  }
}
