import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export default class GetOneConfigApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'oneConfig/getOneConfigPage',
      requesterInfo,
    })
  }
  public async getOneConfigContents(): Promise<any> {
    return await this.getAsync<any>()
  }
}
