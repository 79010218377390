import { IInstallemnts, InstallmentFilterRequestModel } from '../models/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class ListInstallemntsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/installemnts',
      requesterInfo,
    })
  }

  public async listInstallemnts(req: InstallmentFilterRequestModel): Promise<IInstallemnts[]> {
    return await this.postAsync<IInstallemnts[]>(req)
  }
}
