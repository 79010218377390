import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import '../style.scss'
import { useCustomReducer } from '../../../../base/customHooks'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { useEffect, useRef } from 'react'
import { GetDealerChannelListResponseModel, GetDealerChannelListResponseSayacModel, IGetOrdersProcessGroup } from '../models/response/getDealerChannelListResponseModel'
import { GetDealerChannelListApi } from '../services/getDealerChannelListApi'
import IPImage from '../../../../components/IPImage'
import CountdownTimer from '../../../../components/IPCountdownTimer/CountdownTimer'
import { GetOrdersProcessGroupApi } from '../services/getOrdersProcessGroupApi'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'

interface DealerChannelListState {
  DealerChannelPointsList: GetDealerChannelListResponseModel[]
  OrdersProcessGroup: IGetOrdersProcessGroup[]
  sayac: GetDealerChannelListResponseSayacModel[]
}
const t = translate(Translations)

function DealerChannelPrograms(props: BaseComponentWithContextProps) {
  const getInitialState = (): DealerChannelListState => {
    return {
      DealerChannelPointsList: [],
      sayac: [],
      OrdersProcessGroup: []
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<DealerChannelListState>(intialState)


  useEffect(() => {
    // getChannelDealerServicePoints()
    getOrdersProcessGroup()
  }, [])

  const routeLocation = useLocation()

  // const getChannelDealerServicePoints = async () => {
  //   if (routeLocation.state) {
  //     const data = _.clone((routeLocation.state as any)?.data)
  //     if (data.length > 0) {
  //       setState({ DealerChannelPointsList: data })
  //     }
  //   } else {
  //     const getDealerChannelListApi: GetDealerChannelListApi = new GetDealerChannelListApi({})
  //     const data: GetDealerChannelListResponseSayacModel[] = [];
  //     const dataDealerChannelPointsList: GetDealerChannelListResponseModel[] = [];
  //     getDealerChannelListApi
  //       .GetDealerChannelList()
  //       .then((result: GetDealerChannelListResponseModel[]) => {
  //         if (result) {
  //           result.map((res) => {
  //             const NOW_IN_MS = new Date(res.EndDate).getTime();
  //             const date = new Date().getTime();
  //             if (NOW_IN_MS < date)
  //               return
  //             dataDealerChannelPointsList.push(res)
  //             return data.push({
  //               dateTimeAfterThreeDays: NOW_IN_MS
  //             })
  //           })
  //         }
  //         setState({ DealerChannelPointsList: dataDealerChannelPointsList, sayac: data })
  //       })
  //       .catch(error => console.log(error))
  //   }
  // }

  const getOrdersProcessGroup = async () => {
    props.showLoading()
    const getOrdersProcessGroupApi: GetOrdersProcessGroupApi = new GetOrdersProcessGroupApi({})
    const data: GetDealerChannelListResponseSayacModel[] = [];
    const dataDealerChannelPointsList: IGetOrdersProcessGroup[] = [];
    getOrdersProcessGroupApi
      .getOrdersProcessGroup()
      .then((result: IGetOrdersProcessGroup[]) => {
        if (result) {
          if (result) {
            result.map((res) => {
              const NOW_IN_MS = new Date(res.EndDate).getTime();
              const date = new Date().getTime();
              if (NOW_IN_MS < date)
                return
              dataDealerChannelPointsList.push(res)
              return data.push({
                dateTimeAfterThreeDays: NOW_IN_MS
              })
            })
          }
          setState({ OrdersProcessGroup: dataDealerChannelPointsList, sayac: data })
        }
        props.hideLoading()
      })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const tableRef = useRef<any>()
  useEffect(() => {
    let dt: any

    if (state.DealerChannelPointsList && state.DealerChannelPointsList.length > 0) {
      dt = syncTable()
    }

    return function () {
      dt && dt.destroy()
    }
  }, [state.DealerChannelPointsList])

  const syncTable = () => {
    const dt = $(tableRef.current).DataTable({
      pageLength: 10,
    } as any)
    return dt
  }
  return (
    <div className='container-fluid dealerChannelProgram'>
      <form>
        <div className='row align-items-center'>
          <div className='heading-text'>
            <h4>Bayi Kanal Programı</h4>
          </div>

          <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover striped imeiTable' style={{ tableLayout: "fixed" }}>
            <thead>
              <tr className='imeiTableMainTitle'>
                <th></th>
                <th>Kampanya Açıklaması</th>
                <th>Adet/Tutar</th>
                <th>Hedef</th>
                <th>Toplam Gerçekleşen</th>
                <th>Toplam Kalan</th>
                <th>Gerçekleşti</th>
                <th style={{ width: "250px" }}>Kalan Süre</th>
              </tr>
            </thead>

            {state.OrdersProcessGroup.map((a, index) => (
              <tbody>
                <tr
                  key={index + 1}
                  data-toggle='collapse'
                  data-target={`.multi-collapse${index}`}
                  aria-controls='multiCollapseExample1'
                  className='imeiTableDescription'
                >
                  <td>
                    <div className='d-flex justify-content-center imeiRowNumber'>
                      <i className='icon-info imeiTextIcon'></i>
                    </div>
                  </td>
                  <td>{a.ProgramDescription} </td>
                  <td>{a.IsPrice ? "Tutar" : "Adet"} </td>
                  <td>{a.TargetValue} </td>
                  <td>{a.ComplatedValue} </td>
                  <td>{a.ResponseAmountOrQuantity} </td>
                  <td>{a.Check ? "Evet" : "Hayır"} </td>
                  <td>
                    <CountdownTimer targetDate={state.sayac[index].dateTimeAfterThreeDays} />
                  </td>
                </tr>
                <tr className={`collapse multi-collapse${index} imeiTableTitleInImei`}>
                  {/* <td>Hedeflenen Ürün</td> */}
                  <td colSpan={2}>Ürün Kodu</td>
                  <td>Marka</td>
                  <td>Hedef</td>
                  <td>Gerçekleşen</td>
                  <td>Kalan</td>
                  <td>Geçerlilik Tarihi</td>
                  <td>Detaylı Açıklama</td>
                </tr>

                {
                  a.Details.map((detail, i) => (
                    <tr
                      className={`collapse multi-collapse${index}`}
                      key={i}
                    >
                      {/* <td>{detail.ProductName}</td> */}
                      <td colSpan={2}>{detail.ProductCode}</td>
                      <td>{detail.BrandDetail && detail.BrandDetail?.ImageUrl &&
                        <IPImage
                          src={detail.BrandDetail?.ImageUrl}
                          alt="" style={{ width: "50px" }}
                        />}
                        {" "}
                      </td>
                      <td>{detail.TargetQty > 0 ? detail.TargetQty : detail.TargetPrice}</td>
                      <td>{detail.TargetQty > 0 ? detail.ComplatedQty : detail.ComplatedPrice}</td>
                      <td>{detail.TargetQty > 0 ? (detail.TargetQty - detail.ComplatedQty > 0 ? detail.TargetQty - detail.ComplatedQty : 0) : (detail.TargetPrice - detail.ComplatedPrice > 0 ? detail.TargetPrice - detail.ComplatedPrice : 0)}</td>
                      <td>{new Date(a.EndDate).toLocaleDateString('tr-TR')}</td>
                      <td>{a.ProgramDetailDescription}</td>
                    </tr>
                  ))
                }
              </tbody>
            ))}

          </table>
        </div>
      </form>
    </div>
  )
}

export default componentWithContext(DealerChannelPrograms)
