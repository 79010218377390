import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { OrderMailRequest } from '../../../MyCard/models/orderExcel'

export class OrderMailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'payment/emailTheOrder',
      requesterInfo,
    })
  }

  public async orderSendMail(request: OrderMailRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
