import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IAnnouncementList, IPostAnnouncementListRequest } from '../models/requestModels'

export class UpdateAnnouncementApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/openbox/update',
      requesterInfo,
    })
  }

  public async putAnnouncement(request: any): Promise<any> {
    return await this.putAsync<any>(undefined, request)
  }
}
