import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { CreditCardModel } from '../../MyCreditCards/models/models'

export class ChangeActivityCreditCardApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/changeActivityCreditCard',
      requesterInfo,
    })
  }

  public async changeActivityCard(req: any): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
