import { IPostHomePageProductsRequest } from './../models/requestModels'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'
import { GetHomePageProductsByIdResponseModel } from '../models/responseModels'

export class PostHomePageProductsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/homepageProduct/insert',
      requesterInfo,
    })
  }

  public async postHomePageProducts(
    request: IPostHomePageProductsRequest
  ): Promise<GetHomePageProductsByIdResponseModel> {
    return await this.postAsync<GetHomePageProductsByIdResponseModel>(request)
  }
}
