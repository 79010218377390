import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import basketReducer from '../slices/basket/basketSlice'
import userReducer from '../slices/user/userSlice'
import themeReducer from '../slices/theme/themeSlice'
import searchHistoryReducer from '../slices/searchHistory/searchHistorySlice'
import favoritaProductSlice from '../slices/favoriteProduct/favoriteProductSlice'

export const store = configureStore({
  reducer: {
    basket: basketReducer,
    user: userReducer,
    theme: themeReducer,
    searchHistory: searchHistoryReducer,
    favoriteProduct: favoritaProductSlice,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
