export enum OptionTypeEnum {
  CheckBox = 0,
  Radio = 1,
  Input = 2,
  CheckboxAndInput = 3,
  RadioAndInput = 4,
}

export interface ISurveyAnswer {
  Id: number
  Guid: string
  BitwiseId?: number
  UserID?: number
  QuestionId: number
  TotalCount?: number
  TotalAnswer?: number
  InputText?: string
  Options: string
  Deleted?: boolean
  Enabled?: boolean
  Question: string
  UserList: IUserList
  IsInput: boolean
}

export interface IUserList {
  Id: number
  UserID: number
  DealerCode: string
  UserName: string
}

export interface ISurveyQuestion {
  Id: number
  Guid: string
  SurveyId: number
  OptionType: OptionTypeEnum
  Text: string
  IsInput: boolean
  CreatedDate: Date
  Deleted: boolean
  Survey: string
  Enabled?: boolean
  Answers: ISurveyAnswer[]
}

export interface ISurvey {
  Id: number
  Category: string
  Deleted: boolean
  StartDate: Date
  EndDate: Date
  CreatedDate: Date
  Questions: ISurveyQuestion[]
  DealerCodes: string[]
  DealerCodesInclude: boolean
  DealerTypes: string[]
  DealerTypesInclude: boolean
  DealerSubTypes: string[]
  DealerSubTypesInclude: boolean
}

export interface ISurveyReport {
  UsedTime: Date
  QuestionId: number
  QuestionCount: number
  DealerCount: number
  DealerList: ISurveyDealers[]
  UserList: ISurveyUsers[]
  Text: string
  IsInput: boolean
  Answers: ISurveyAnswer[]
  InputList: any
  OptionType: number
}

export interface ISurveyDealers {
  DealerCode: string
  DealerName: string
}

export interface ISurveyUsers {
  DealerCode: string
  DealerName: string
  Username: string
}
