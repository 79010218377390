import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IReturnList } from '../models/models'

export class SetDeletedApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/orderReturn/setDeleted',
      requesterInfo,
    })
  }

  public async setDeleted(req: number): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
