import React, { useEffect } from 'react'
import IPInput from '../../../../components/IPInput'
import './styles.scss'
import { useCustomReducer } from '../../../../base/customHooks'
import { PostDealerReminderApi } from '../Extract/services/postDealerReminderApi'
import { IReminder } from '../Extract/models/models'
interface IListExtractState {
  companyActive: number
  reminderData?: IReminder
  rdata: IReminder[]
}

export default function Index(props: any) {
  const getInitialState = (): IListExtractState => {
    return {
      companyActive: 1000,
      rdata: []
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListExtractState>(intialState)
  useEffect(() => {
    postDealerReminder()
  }, [])

  const postDealerReminder = async () => {
    const postDealerReminderApi: PostDealerReminderApi = new PostDealerReminderApi({})
    postDealerReminderApi.postDealerReminder().then(async res => {
      if (res) {
        res.filter((r) => {
          return r.Company === 1000
        }).map((re) => {
          return setState({ reminderData: re })
        })
        setState({ rdata: res })
      }
    })
  }

  const selectCompany = async (company: number) => {
    state.rdata.filter((r) => {
      return r.Company === company
    }).map((re) => {
      return setState({ reminderData: re })
    })
    setState({ companyActive: company })
  }

  return (
    <div className='container-fluid current-account'>
      <div className='heading-text'>
        <h4>Cari Hesap Özeti</h4>
      </div>
      <nav className='cari-hesap--tab-menu'>
        <ul>
          <li
            onClick={() => selectCompany(1000)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1000 ? 'active-company-tab' : ''}`}
          >
            <span>INDEX</span>
          </li>
          <li
            onClick={() => selectCompany(1100)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1100 ? 'active-company-tab' : ''}`}
          >
            <span>DATAGATE</span>
          </li>
          <li
            onClick={() => selectCompany(1200)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1200 ? 'active-company-tab' : ''}`}
          >
            <span>DESPEC</span>
          </li>
          <li
            onClick={() => selectCompany(2300)}
            className={`dropdown-submenu company-tab ${state.companyActive === 2300 ? 'active-company-tab' : ''}`}
          >
            <span>NETEX</span>
          </li>
        </ul>
      </nav>
      <div className='card-body px-0'>
        <div className='form-group form-fg'>
          <div>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label className='labeltext'>Ödeme Şekli</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='paymentMethod'
                  disabled
                />
              </div>
              <div className='form-group col-md-6'>
                <label className='labeltext'>Genel Vade</label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='paymentMethod'
                  disabled
                />
              </div>
            </div>
            <div className='row'>
              <div className='form-group col-md-6'>
                <label className='labeltext'>Bakiye</label>
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text' id='basic-addon1'>
                      TL
                    </span>
                  </div>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.TL_BAK.toFixed(2)}
                    disabled
                  />
                </div>
                <br />
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text' id='basic-addon1'>
                      USD
                    </span>
                  </div>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.USD_BAK.toFixed(2)}
                    disabled
                  />
                </div>
                <br />
                <div className='input-group mb-3'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text' id='basic-addon1'>
                      EUR
                    </span>
                  </div>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.EUR_BAK.toFixed(2)}
                    disabled
                  />
                </div>
              </div>
              <div className='form-group col-md-6'>
                <label className='labeltext'>Vadesi Geçmiş Bakiye</label>
                <div className='input-group mb-3'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.VGA_TL.toFixed(2)}
                    disabled
                  />
                </div>
                <br />
                <div className='input-group mb-3'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.VGA_USD.toFixed(2)}
                    disabled
                  />
                </div>
                <br />
                <div className='input-group mb-3'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='paymentMethod'
                    value={state.reminderData?.VGA_EUR.toFixed(2)}
                    disabled
                  />
                </div>
              </div>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}
