import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IInstallmentInquiry } from '../models/PaymentModel'

export class InstallmentInquiryApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'manualCreditCardPayment/installmentInquiry',
      requesterInfo,
    })
  }

  public async installmentInquiry(request: IInstallmentInquiry): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
