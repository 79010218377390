
import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IApplicationRequestHistory } from '../models/response/getApplicationRequestList'

export class SellerApplicationHistoryApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/applicationRequest/listHistoryById',
      requesterInfo,
    })
  }

  public async historyByID(request: number): Promise<IApplicationRequestHistory[]> {
    return await this.postAsync<IApplicationRequestHistory[]>(request)
  }
}
