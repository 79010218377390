import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { downloadFileFromUrl } from '../../../base/utils/dataHelper'
import IPImage from '../../../components/IPImage'
import './style.scss'
import classes from '../../../components/IPButton/IPButton.module.scss'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { GetDppMarketingContentApi } from '../services/getDppMarketingContentApi'
import Swal from 'sweetalert2'
import { IMarket } from '../models/models'
import { useCustomReducer } from '../../../base/customHooks'

interface IMarketState {
  marketData: IMarket
  marketBool: boolean
}

function Marketing(props: BaseComponentWithContextProps) {

  useEffect(() => {
    getMarket()
  }, [])
  const getInitialState = (): IMarketState => {
    return {
      marketData: {
        CenterMenu: {
          Deleted: false,
          Id: 0,
          Image: "",
          IsTitle: false,
          Link: "",
          Location: 0,
          Name: "",
          OrderNo: 0
        },
        Documents: [],
        LeftMenu: {
          Deleted: false,
          Id: 0,
          Image: "",
          IsTitle: false,
          Link: "",
          Location: 0,
          Name: "",
          OrderNo: 0
        },
        PriceList: {
          Deleted: false,
          Id: 0,
          Image: "",
          IsTitle: false,
          Link: "",
          Location: 0,
          Name: "",
          OrderNo: 0
        },
        RightMenu: {
          Deleted: false,
          Id: 0,
          Image: "",
          IsTitle: false,
          Link: "",
          Location: 0,
          Name: "",
          OrderNo: 0
        },
        UpperMenu: {
          Deleted: false,
          Id: 0,
          Image: "",
          IsTitle: false,
          Link: "",
          Location: 0,
          Name: "",
          OrderNo: 0
        }
      },
      marketBool: false
    }
  }

  const intialState = getInitialState()

  const [state, setState] = useCustomReducer<IMarketState>(intialState)

  const getMarket = async () => {
    props.showLoading()
    const api: GetDppMarketingContentApi = new GetDppMarketingContentApi({})
    api.getDppMarketingContent().then((res: IMarket) => {
      props.hideLoading()
      setState({ marketData: res, marketBool: true })
    }).catch((err: any) => {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    })
  }

  return (
    <div className='container current-account'>
      <div className='content col-lg-12 m-auto'>
        <br></br>
        <div className=''>
          {
            state.marketBool &&
            <div className='card-body'>
              <div className='row'>
                {
                  state.marketData && state.marketData.UpperMenu && (
                    state.marketData.UpperMenu.Link ?
                      <Link to={state.marketData.UpperMenu.Link}>
                        {
                          state.marketData.UpperMenu.Image ?
                            <IPImage
                              src={state.marketData.UpperMenu.Image}
                              alt=''
                              className='popular_product'
                              style={{ width: '1350px' }}
                            />
                            : ""
                        }
                      </Link>
                      : (
                        state.marketData.UpperMenu.Image ?
                          <IPImage
                            src={state.marketData.UpperMenu.Image}
                            alt=''
                            className='popular_product'
                            style={{ width: '1350px' }}
                          />
                          : ""
                      )
                  )
                }

              </div>
              <div className='row' style={{ textAlign: 'center', marginTop: '50px' }}>
                <h3>Logo ve kampanya duyuru dokümanlarına şimdi ulaşın!</h3>
              </div>
              <div className='row' style={{ marginTop: '50px' }}>
                {
                  state.marketData && state.marketData.LeftMenu && state.marketData.LeftMenu.Id > 0 &&
                    state.marketData.LeftMenu.Link && state.marketData.LeftMenu.Name ?
                    <div
                      className='col-md-4'
                      onClick={() => downloadFileFromUrl(state.marketData.LeftMenu.Name, state.marketData.LeftMenu.Link)}
                      style={{ cursor: 'pointer', textAlign: 'center' }}
                    >
                      {
                        state.marketData.LeftMenu.Image ?
                          <IPImage
                            className='dpp_V1'
                            src={state.marketData.LeftMenu.Image}
                            alt=''
                            style={{ objectFit: 'cover', height: '200px' }}
                          />
                          : ""
                      }
                    </div>
                    : state.marketData.LeftMenu.Image ?
                      <IPImage
                        className='dpp_V1'
                        src={state.marketData.LeftMenu.Image}
                        alt=''
                        style={{ objectFit: 'cover', height: '200px' }}
                      />
                      : ""
                }
                {
                  state.marketData && state.marketData.CenterMenu && state.marketData.CenterMenu.Id > 0 &&
                    state.marketData.CenterMenu.Link && state.marketData.CenterMenu.Name ?
                    <div
                      className='col-md-4'
                      onClick={() => downloadFileFromUrl(state.marketData.CenterMenu.Name, state.marketData.CenterMenu.Link)}
                      style={{ cursor: 'pointer', textAlign: 'center' }}
                    >
                      {
                        state.marketData.CenterMenu.Image ?
                          <IPImage
                            className='dpp_V1'
                            src={state.marketData.CenterMenu.Image}
                            alt=''
                            style={{ objectFit: 'cover', height: '200px' }}
                          />
                          : ""
                      }
                    </div>
                    : state.marketData.CenterMenu.Image ?
                      <IPImage
                        className='dpp_V1'
                        src={state.marketData.CenterMenu.Image}
                        alt=''
                        style={{ objectFit: 'cover', height: '200px' }}
                      />
                      : ""
                }
                {
                  state.marketData && state.marketData.RightMenu && state.marketData.RightMenu.Id > 0 &&
                    state.marketData.RightMenu.Link && state.marketData.RightMenu.Name ?
                    <div
                      className='col-md-4'
                      onClick={() => downloadFileFromUrl(state.marketData.RightMenu.Name, state.marketData.RightMenu.Link)}
                      style={{ cursor: 'pointer', textAlign: 'center' }}
                    >
                      {
                        state.marketData.RightMenu.Image ?
                          <IPImage
                            className='dpp_V1'
                            src={state.marketData.RightMenu.Image}
                            alt=''
                            style={{ objectFit: 'cover', height: '200px' }}
                          />
                          : ""
                      }
                    </div>
                    : state.marketData.RightMenu.Image ?
                      <IPImage
                        className='dpp_V1'
                        src={state.marketData.RightMenu.Image}
                        alt=''
                        style={{ objectFit: 'cover', height: '200px' }}
                      />
                      : ""
                }
              </div>
              <hr style={{ marginTop: '50px', color: 'red' }} />
              <div className='row' style={{ textAlign: 'center', marginTop: '50px' }}>
                <h3>Dökümanlar</h3>
              </div>
              <div className='row' style={{ marginTop: '50px' }}>
                {
                  state.marketData && state.marketData.PriceList && state.marketData.PriceList.Id > 0 &&
                  <div
                    className='col-md-4'
                    onClick={() => downloadFileFromUrl(state.marketData.PriceList.Name, state.marketData.PriceList.Link)}
                    style={{ cursor: 'pointer' }}
                  >
                    <button className={`${classes.Button} button buttonWide`}>
                      <span>Fiyat Listesi</span>
                    </button>
                  </div>
                }
                {/* <div
                className='col-md-4'
                onClick={() => downloadFileFromUrl('Kampanya Görseli Kare', '/images/AppleBanner52.jpg')}
                style={{ cursor: 'pointer' }}
              >
                <button className={`${classes.Button} button buttonSquare`}>
                  <span>Kampanya Görseli Kare</span>
                </button>
              </div> */}

              </div>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(Marketing)