import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IGetXmlInfoDetailsAdminRequest, IGetXmlInfoDetailsAdminResponse } from '../models/models'

export class GetXmlInfoDetailsAdminApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/getXmlInfoDetailsAdmin',
      requesterInfo,
    })
  }

  public async getXmlInfoDetailsAdmin(
    request: IGetXmlInfoDetailsAdminRequest
  ): Promise<IGetXmlInfoDetailsAdminResponse[]> {
    return await this.postAsync<IGetXmlInfoDetailsAdminResponse[]>(request)
  }
}
