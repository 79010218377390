import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse, IPostAuthorizedServicesMain } from '../models/models'

export class PostAuthorizedServicesMainApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/authorizedServicesMain/insert',
      requesterInfo,
    })
  }

  public async postAuthorizedServicesMain(request: IPostAuthorizedServicesMain): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
