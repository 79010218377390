import { Chart as ChartJS, Colors, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'react-chartjs-2'

ChartJS.register(ArcElement, Colors, Tooltip, Legend)

export default function PieChart({ values }) {
  if (!values) return null

  let chartOptions = {
    maintainAspectRatio: true,
    responsive: true,
    plugins: {
      colors: {
        forceOverride: true,
      },
    },
  }

  const labels: any = []
  const data: any = []

  for (const val of values) {
    labels.push(val.Name)
    data.push(val.Value)
  }

  const chartData = {
    labels,
    datasets: [
      {
        data,
      },
    ],
  }

  return <Pie options={chartOptions} data={chartData} />
}
