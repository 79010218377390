import { useEffect, useState } from 'react'
import { IProduct } from '../../../ProductList/models/response/getProductListResponseModel'
export interface ISpecProp {
  product: IProduct
}
export default function ProductSpecifications(props: ISpecProp) {
  const { product } = props
  const [descHtml, setDescHtml] = useState<string>('')

  useEffect(() => {
    if (product.ProductDetailProperties)
      setDescHtml(
        (
          product.ProductDetailProperties.find(details => details.Featurename === 'Açıklama') as any
        )?.FeatureValue.replace(/<[^>]+>/g, '').replace('\n', '')
      )
  }, [product.ProductDetailProperties])

  const uniqueFeatureIds: string[] = []

  return (
    <div className='mb-5' id={'Product-Feature-Product'}>
      <div className='row justify-content-center'>
        <div className='col-lg-10 d-flex flex-column'>
          <table className='table table-borderless table-hover' style={{ marginBottom: '0px', lineHeight: 'normal' }}>
            {product.ProductDetailProperties &&
              product.ProductDetailProperties.filter(detail => {
                // Filter to keep only unique FeatureIds
                if (!uniqueFeatureIds.includes(detail.Featurename)) {
                  uniqueFeatureIds.push(detail.Featurename)
                  return true
                }
                return false
              })
                .sort((a, b) => {
                  // FeatureId = 1 olan değeri başa al
                  if (a.Featureid === 1 && b.Featureid !== 1) {
                    return -1
                  } else if (a.Featureid !== 1 && b.Featureid === 1) {
                    return 1
                  }
                  // FeatureValue = "" veya "Tanımsız" ise sıralamada sona koy
                  if (
                    (a.FeatureValue === '' || a.FeatureValue === 'Tanımsız') &&
                    b.FeatureValue !== '' &&
                    b.FeatureValue !== 'Tanımsız'
                  ) {
                    return 1 // a'yı b'nin önüne koy
                  } else if (
                    a.FeatureValue !== '' &&
                    a.FeatureValue !== 'Tanımsız' &&
                    (b.FeatureValue === '' || b.FeatureValue === 'Tanımsız')
                  ) {
                    return -1 // b'yi a'nın önüne koy
                  }
                  // Diğer durumlarda Seqnum'a göre sırala
                  return a.Seqnum - b.Seqnum
                })
                .map((detail, index) => {
                  return (
                    <tbody>
                      <tr style={{ background: index % 2 === 0 ? '#f1f1f1' : 'white' }}>
                        <td
                          className='p-l-20 p-r-20'
                          style={{ fontSize: 16, fontWeight: 500, whiteSpace: 'nowrap', width: '40%' }}
                        >
                          {detail.Featurename}
                        </td>
                        <td
                          className='p-l-20 p-r-20'
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: '#000000',
                            textAlign: 'right',
                            whiteSpace: 'normal',
                            width: '40%',
                          }}
                        >
                          <ul style={{ listStyle: 'none', padding: 0, margin: 0, whiteSpace: 'break-spaces' }}>
                            {detail.Featurename !== 'Açıklama' ? (
                              detail.Featurename === 'Desteklenen Windows çalışma sistemleri' ? (
                                detail.FeatureValue.split(',').map((feature, index) => (
                                  <li style={{ whiteSpace: 'pre-wrap' }} key={index}>
                                    {feature.trim()}
                                  </li>
                                ))
                              ) : (
                                <li
                                  style={
                                    detail.Featurename === 'Açıklama Diğer'
                                      ? { whiteSpace: 'initial' }
                                      : { whiteSpace: 'pre-wrap' }
                                  }
                                >
                                  {detail.FeatureValue}
                                </li>
                              )
                            ) : (
                              //<li style={{ whiteSpace: 'initial' }} dangerouslySetInnerHTML={{ __html: descHtml }}></li>
                              <p
                                dangerouslySetInnerHTML={{ __html: descHtml }}
                                style={{ whiteSpace: 'initial', fontSize: '12px', fontWeight: '600' }}
                              ></p>
                            )}
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  )
                })}
          </table>
        </div>
      </div>
    </div>
  )
}

// const data = {
//   name: 'Apple MacBook Air MGN63 2020 - 13 inch Laptop',
//   weight: '1.2kg',
//   dimensions: '288x200x17.9',
//   cpuManufacturer: 'Intel',
//   processorSeries: 'Celeron',
//   processorModel: 'N4020',
//   cpuSpeedRange: 'Up to 2.0GHz',
//   cache: '4 MB',
//   ramCapacity: '1.1UP TO 2.8',
//   ramType: '4 GB',
//   internalMemoryType: 'SSD',
// }

// const groupProducts =
//   product.ProductDetailProperties.reduce((group, product) => {
//     const { Groupname } = product;  //neye göre gruplanacak
//     group[Groupname] = group[Groupname] ?? [];
//     group[Groupname].push(product);
//     return group;
//   }, {});

// Object.keys(groupProducts).map((item, index) => {
//   return (
//     <div
//       style={{ background: "#f1f2f2" }}
//       className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
//     >
//       <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
//         {/* {t("pages.productdetail.Weight")} */}
//         {item}
//       </p>
//       <p
//         className="mt-3 mx-2"
//         style={{ fontSize: 16, fontWeight: 500, color: "#000000", textAlign: "right" }}
//       >
//         {/* {data.weight} */}

//         {(Object.values(groupProducts) as any)[index].map((qualify, qualifyIndex) =>

//           (Object.values(groupProducts) as any)[index].length === (qualifyIndex + 1) ? qualify.FeatureValue :
//             qualify.FeatureValue + ", "

//         )}
//       </p>
//     </div>
//   )
// })

/* <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.Dimensions")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>{" "}
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.CPUManufacturer")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>{" "}
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.ProcessorSeries")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.ProcessorModel")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.CPUSpeedRange")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.Capacity")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div> */

/* sag kolon */

/* <div className="col-lg-6 d-flex flex-column gap-2">
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.Weight")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.Dimensions")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>{" "}
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.CPUManufacturer")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>{" "}
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.ProcessorSeries")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.ProcessorModel")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.CPUSpeedRange")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>
          <div
            style={{ background: "#f1f2f2" }}
            className="d-flex justify-content-between align-items-center p-l-20 p-r-20 "
          >
            <p className="mt-3 mx-2" style={{ fontSize: 16, fontWeight: 500 }}>
              {t("pages.productdetail.Capacity")}
            </p>
            <p
              className="mt-3 mx-2"
              style={{ fontSize: 16, fontWeight: 500, color: "#000000" }}
            >
              {data.weight}
            </p>
          </div>
        </div> */
