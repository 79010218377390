import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'
import { IDeleteData } from '../models/models'

export class DeleteDppManagementPageApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'DppEtCetera/deleteDppManagementPage',
      requesterInfo,
    })
  }

  public async deleteDppManagementPage(request: IDeleteData): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
