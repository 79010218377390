import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { FCVObject, IListAllFCV, IListPaymentTerms } from './InsertFcvService'

export interface FCVRule {
  CompanyNumber: string
  FcvDataRule: FcvDataRuleObj
}

export interface FcvDataRuleObj {
  QuotaList: []
  MaterialList: MaterialList[]
  DagitimKanaliList: []
  BayiCodes: []
  SubTypes: []
}

export interface MaterialList {
  MaterialNumber: string
  ProductName: string
}

export class GetAllFcvService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/listPaymentTerms',
      requesterInfo,
    })
  }

  public async listAllFCV(request: IListAllFCV): Promise<IListPaymentTerms> {
    return await this.postAsync<IListPaymentTerms>(request)
  }
}
