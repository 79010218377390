import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'

export class GetNetChannelContentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'NetChannel/getNetChannelContent',
      requesterInfo,
    })
  }

  public async getDataList(): Promise<any> {
    return await this.getAsync<any>()
  }
}
