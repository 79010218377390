import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IAddOrUpdateRoleRequest, ISimpleRole } from '../models'

export class AddOrUpdateRoleApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/userPermission/AddOrUpdateSecurityRole',
      requesterInfo,
    })
  }

  public async addOrUpdateRole(request: IAddOrUpdateRoleRequest): Promise<ISimpleRole> {
    return await this.postAsync<ISimpleRole>(request)
  }
}
