import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IMyOffers, IMyOffersRequest } from '../models/responseModel'

export class MyofferByIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'excel/myofferById',
      requesterInfo,
    })
  }

  public async myofferById(request: number): Promise<any> {
    this.props.url = `${this.props.url}/${request}`
    return await this.postAsync<any>()
  }
}
