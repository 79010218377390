import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { ICompany } from '../models'

export class GetCompaniesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/company-list',
      requesterInfo,
    })
  }

  public async getCompanyApi(): Promise<ICompany[]> {
    return await this.getAsync<ICompany[]>()
  }
}
