import React, { useRef, useState } from 'react'
import Swal from 'sweetalert2'
import classes from './IPFile.module.scss'

interface IPFile extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  id: string
  label?: string
  handleFiles?: (files: any[]) => void
  hideSelectedFilesList?: boolean
  removeAfterSelect?: boolean
  excelIcon?: boolean
}

export default function File(props: IPFile) {
  const { id, label, onInput, multiple, className, removeAfterSelect, excelIcon, ...others } = props

  const fileRef = useRef<HTMLInputElement>(null)

  const [files, setFiles] = useState<any[]>([])

  const handleFiles = e => {
    const allowedFileTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    const maxFileSize = 10 * 1024 * 1024; // 10MB

    // Convert FileList to an array
    const targetFilesArray = Array.from(e.target.files as FileList).map(file => file as File);


    const filteredFiles = targetFilesArray.filter(file => {
      if (!allowedFileTypes.includes(file.type)) {
        Swal.fire({
          icon: 'error',
          title: 'Hata',
          text: `Yalnızca PDF, JPG ve PNG tipindeki dosyaları yükleyebilirsiniz!`,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        return false;
      }
      if (file.size > maxFileSize) {
        Swal.fire({
          icon: 'error',
          title: 'Hata',
          text: `Dosya boyutu en fazla 10MB olmalıdır!`,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
        return false;
      }
      return true;
    });

    if (filteredFiles.length === 0) return;

    if (multiple) {
      setFiles(prevState => {
        for (let i = 0; i < prevState.length; i++) {
          const state = prevState[i];
          for (let j = 0; j < filteredFiles.length; j++) {
            const file = filteredFiles[j];
            if (file.name === state.name && file.size === state.size) {
              Swal.fire({
                icon: 'error',
                title: 'Hata',
                text: 'Seçtiğiniz dosya zaten mevcut!',
                showConfirmButton: true,
                allowOutsideClick: false,
              });
              return prevState;
            }
          }
        }
        const updatedFiles = [...prevState, ...filteredFiles];
        props.handleFiles && props.handleFiles(updatedFiles);
        return updatedFiles;
      });
    } else {
      props.handleFiles && props.handleFiles(filteredFiles);
    }

    if (fileRef.current && removeAfterSelect) {
      fileRef.current.value = '';
    }
  };


  const handleRemove = e => {
    const item = e.target.previousElementSibling.textContent
    setFiles(prevState => {
      const updatedFiles = prevState.filter(state => state.name !== item)
      props.handleFiles && props.handleFiles(updatedFiles)
      return updatedFiles
    })
  }

  return (
    <>
      {!props.hideSelectedFilesList && files.length > 0 && (
        <div className={classes.files}>
          {files.map(f => (
            <div key={Math.random()}>
              <span>{f.name}</span> <span onClick={handleRemove}>X</span>
            </div>
          ))}
        </div>
      )}
      <div className={classes.file}>
        <input
          type='file'
          ref={fileRef}
          id={id}
          accept=".pdf,.png,.jpeg,.jpg"
          onInput={handleFiles}
          multiple={multiple ? multiple : true}
          {...others}
        />
        <label className={className ? className : ''} htmlFor={id}>
          {excelIcon ? <i className='fas fa-file-excel' /> : null}
          {label ? label : ''}
        </label>
      </div>
    </>
  )
}
