import { useCallback, useEffect, useState } from 'react'
import HomePageMostRecent from './HomePageMostRecent'
import HomePagePromotionalProducts from './HomePagePromotionalProducts'
import HomePageRecentVisits from './HomePageRecentVisits'
import HomePageTopBrand from './HomePageTopBrand'
import HomePageTopFavorites from './HomePageTopFavorites'

import { KeyValuePair } from '../../base/models'
import { useAppSelector } from '../../redux/app/hooks'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import HomePageCampaigns from './HomePageCampaigns'
import { IBestSellers, IBrandDetail, ICampaignsProducts } from './commonModels/models'
import './css/styles.scss'
import { GetBrandDetailApi } from './services/getBrandDetailApi'
import { GetCampaignsProductsApi } from './services/getCampaignsProductsApi'
// import data from '../../api/campaign.json'
import { css } from '@emotion/css'
import Swal from 'sweetalert2'
import { translate } from '../../base/localization'
import IPCheckbox from '../../components/IPCheckBox'
import IPModal from '../../components/IPModal/IPModalContract'
import IPRadio from '../../components/IPRadio'
import Translations from '../../translations'
import { GetCampaignsApi } from '../Campaings/services/getCampaignsApi'
import '../Register/components/SellerApplication.scss'
import HomePageBestSellers from './HomePageBestSellers'
import { ContractApply } from './services/contractapplyApi'
import { ContractPopUp } from './services/contractpopupApi'
import { GetMostSelledProductsByCatagoriesApi } from './services/getMostSelledProductsByCatagoriesApi'

// function HomePageCardLists(props) {
//   const [brand, setBrand] = useState<IBrandDetail>({
//     List: [],
//     Count: 0,
//   })
//   const theme = useAppSelector(selectedTheme)
//   useEffect(() => {
//     getBrands()
//     getCampaignsProducts(theme.id)
//     getCampaigns(theme.id)
//     getMostSelledProductsByCatagories()
//   }, [theme.id])

//   useEffect(() => {
//     IsContractPopup()
//   }, [])

//   const [popup, setPopup] = useState<boolean>(false)
//   const [showModal, setShowModal] = useState<boolean>(false)
//   const [appModel, setAppModel] = useState<any>()
//   const [tel, setTel] = useState<string>('')
//   const [mail, setMail] = useState<string>('')

//   const [kvkk, setKvkk] = useState<boolean>(false)
//   const [onay, setOnay] = useState<number>(0)

//   const IsContractPopup = async () => {
//     const api = new ContractPopUp({})
//     api
//       .contractPopup()
//       .then((res: any) => {
//         if (res) {
//           setPopup(true)
//           setAppModel(res)
//         }
//       })
//       .catch((err: any) => {
//         console.log(err)
//       })
//   }

//   const sendContract = async (id: number) => {
//     if (onay && kvkk) {
//       const req: number[] = [id]
//       const api = new ContractApply({})
//       api
//         .contractApply(req)
//         .then((res: any) => {
//           if (res) {
//             Swal.fire({
//               icon: 'success',
//               title: 'Sözleşme tarafınıza gönderilmiştir.',
//               showConfirmButton: true,
//               allowOutsideClick: true,
//             })
//             setPopup(false)
//           }
//         })
//         .catch((err: any) => {
//           Swal.fire({
//             icon: 'error',
//             title: 'Hata! Lütfen daha sonra tekrar deneyin.',
//             showConfirmButton: true,
//             allowOutsideClick: true,
//           })
//         })
//     } else {
//       Swal.fire({
//         icon: 'error',
//         title: 'Devam etmeden önce KVKK metnini onaylamalı ve Ticari Elektronik İletilerini kabul etmelisiniz.',
//         showConfirmButton: true,
//         allowOutsideClick: true,
//       })
//     }
//   }

//   const getBrands = async () => {
//     const api = new GetBrandDetailApi({})
//     const queryParams: KeyValuePair<string> = new KeyValuePair<string>()
//     queryParams.add('IsHomePage', 'true')
//     api.queryParams = queryParams
//     api.getBrandList(6, theme.id).then((res: IBrandDetail) => {
//       setBrand(res)
//     })
//   }
//   const [campaignsProducts, setCampaignsProducts] = useState<any>({
//     Count: 0,
//     Products: [],
//   })

//   const [campaigns, setCampaigns] = useState<any>()
//   const [bestSellers, setBestSellers] = useState<IBestSellers[]>()

//   const t = translate(Translations)

//   const getCampaignsProducts = async (companyId: number) => {
//     const data: ICampaignsProducts = {
//       PageSize: 10000,
//       PageNumber: 0,
//       CampaignId: 0,
//       Company: companyId
//     }
//     const api: GetCampaignsProductsApi = new GetCampaignsProductsApi({})
//     api.getCampaignsProducts(data).then((res: any) => {
//       if (res)
//         return setCampaignsProducts(res)
//       setCampaignsProducts({
//         Count: 0,
//         Products: [],
//       })
//     }).catch((e) => {
//       setCampaignsProducts({
//         Count: 0,
//         Products: [],
//       })
//     })
//   }

//   const getCampaigns = async (companyId: number) => {
//     const data: ICampaignsProducts = {
//       PageSize: 10000,
//       PageNumber: 0,
//       CampaignId: 0,
//       Company: companyId,
//     }
//     const api: GetCampaignsApi = new GetCampaignsApi({})
//     api.getCampaigns(data).then((res: any) => {
//       setCampaigns(res)
//     })
//   }

//   const getMostSelledProductsByCatagories = async () => {
//     const api: GetMostSelledProductsByCatagoriesApi = new GetMostSelledProductsByCatagoriesApi({})
//     const queryParams: KeyValuePair<number> = new KeyValuePair<number>()
//     queryParams.add('dayBefore', 7)
//     queryParams.add('minSelledProductCount ', 0)
//     queryParams.add('minSelledProductQuantityCount', 5)
//     queryParams.add('company', theme.id)
//     api.queryParams = queryParams
//     api.getMostSelledProductsByCatagories().then((res: IBestSellers[]) => {
//       setBestSellers(res)
//     })
//   }

//   return (
//     <div className='container'>
//       {campaigns && campaigns.Count > 0 && <HomePageCampaigns data={campaigns.List} count={campaigns.Count} />}
//       {campaignsProducts && campaignsProducts.Count > 0 && (
//         <HomePagePromotionalProducts data={campaignsProducts.Products} count={campaignsProducts.Count} />
//       )}
//       {props.product && props.product.MostFavourites && props.product.MostFavourites.Count > 0 && (
//         <HomePageTopFavorites data={props.product.MostFavourites.Products} count={props.product.MostFavourites.Count} />
//       )}
//       {props.product && props.product.RecentVisits && props.product.RecentVisits.Count > 0 && (
//         <HomePageRecentVisits data={props.product.RecentVisits.Products} count={props.product.RecentVisits.Count} />
//       )}
//       {/* {
//         product &&
//         product.RecentVisits &&
//         product.RecentVisits.Count > 0 &&
//         <HomePageSimilarItems data={data["SimilarItems"]} />
//       } */}
//       {props.product && props.product.NewProducts && props.product.NewProducts.Count > 0 && (
//         <HomePageMostRecent data={props.product.NewProducts.Products} count={props.product.NewProducts.Count} />
//       )}
//       {bestSellers && bestSellers.length > 0 && <HomePageBestSellers data={bestSellers} />}
//       {brand && brand.Count > 0 && <HomePageTopBrand data={brand} />}
//       <IPModal
//         show={popup}
//         width={1000}
//         style={{ textAlignLast: 'center' }}
//         onClose={() => setPopup(false)}
//         onConfirm={() => {
//           sendContract(appModel.Id)
//         }}
//       >
//         <div className='sellerApplicationForm'>
//           <div
//             style={{
//               position: 'relative',
//               width: '100%',
//               maxWidth: '100%',
//             }}
//           >
//             <img
//               src='images/sozlesme_yenileme_.jpg'
//               alt=''
//               style={{
//                 width: '100%',
//                 height: 'auto',
//                 display: 'block',
//               }}
//             />
//             <div
//               style={{
//                 position: 'absolute',
//                 top: '63.5%', // Alt kenara göre ayarlayabilirsiniz
//                 left: '50%',
//                 transform: 'translateX(-50%)',
//                 textAlignLast: 'left',
//                 width: '100%',
//                 boxSizing: 'border-box',
//               }}
//             >
//               <p
//                 style={{
//                   marginTop: '0',
//                   display: 'block',
//                   fontWeight: 'bold',
//                   textAlignLast: 'left',
//                   paddingLeft: '45%',
//                   fontSize: '120%',
//                   color: 'white',
//                 }}
//               >
//                 {appModel && appModel.MobilePhone}
//               </p>
//               <p
//                 style={{
//                   margin: '0',
//                   display: 'block',
//                   fontWeight: 'bold',
//                   textAlignLast: 'left',
//                   paddingLeft: '45%',
//                   fontSize: '120%',
//                   color: 'white',
//                 }}
//               >
//                 {appModel && appModel.Email}
//               </p>
//             </div>
//           </div>
//           <div className='form-group col-md-12'>
//             <div className='infoWrapper'>
//               <div className='acceptForm'>
//                 {t('pages.register.bayiForms.electronicMessage')}
//                 <a style={{ color: 'red' }} className='infoMarker'>
//                   ?
//                 </a>
//                 <div className='infoBox'>
//                   {' '}
//                   ‘’ İNDEKS GRUP ŞİRKETLERİ ürün ve hizmetleri ile ilgili kampanyalar, tanıtımlar, duyurular hakkında
//                   link ve yönlendirmeleri, tanıtımları, haberleri veya reklamları da içerebilen şekilde E-BAYİ'yi
//                   bilgilendirmek için E-BAYİ'nin gerek sözleşmenin imzalanması sırasında gerekse sonradan bildirdiği
//                   telefon numaraları, faks, posta ve e-posta adreslerinden oluşan tüm bilgileri kullanabilir, yasal
//                   düzenleme kapsamında ticari iletişim ve ticari elektronik ileti gönderebilir. E-BAYİ, bu kapsamda
//                   bildirdiği telefon numaraları, faks, posta ve e-posta adresleri gibi iletişim bilgileri için izin
//                   vermeye yetkili olduğunu, verdiği iletişim bilgilerinin doğru ve gerçek olduğunu, kendisi ile ilişkisi
//                   olmayan 3. kişilere ait bilgileri sağlaması halinde doğabilecek tüm zarardan sorumlu olduğunu kabul,
//                   beyan ve taahhüt eder. ‘’
//                 </div>
//               </div>
//             </div>
//             <div className='d-flex gap-4' style={{ display: 'flex', justifyContent: 'space-around' }}>
//               <IPRadio
//                 text={t('pages.register.bayiForms.yes')}
//                 name='CommercialMessage'
//                 onClick={() => {
//                   setOnay(1)
//                 }}
//                 checked={onay === 1}
//               />
//               <IPRadio
//                 text={t('pages.register.bayiForms.no')}
//                 name='CommercialMessage'
//                 onClick={() => {
//                   setOnay(0)
//                 }}
//                 checked={onay === 0}
//               />
//             </div>
//           </div>
//           <div className='form-group col-md-12' style={{ display: 'flex', justifyContent: 'space-around' }}>
//             <div className='acceptForm'>
//               <div className='d-flex align-items-center'>
//                 <div>
//                   {t('pages.register.bayiForms.kvk')}{' '}
//                   <span
//                     className={css`
//                       color: #ec4c4c;
//                       cursor: pointer;
//                       &:hover {
//                         color: red;
//                       }
//                     `}
//                     onClick={() => setShowModal(true)}
//                   >
//                     {t('pages.register.bayiForms.click')}
//                   </span>
//                 </div>
//                 {kvkk ? '✅' : ''}
//               </div>
//             </div>
//           </div>
//         </div>
//       </IPModal>
//       <IPModal
//         title='KVKK Aydınlatma Metni'
//         width={1200}
//         show={showModal}
//         confirmText='Onayla'
//         onConfirm={() => {
//           setKvkk(true)
//           setShowModal(false)
//         }}
//         onClose={() => {
//           setShowModal(false)
//         }}
//         closeOnEscapeKey={true}
//         checkbox={
//           <div className='d-flex align-items-center'>
//             <IPCheckbox
//               name='KvkApproval'
//               onCheckedChange={(checked: boolean) => {
//                 setKvkk(true)
//               }}
//               checked={kvkk}
//             />
//             <div onClick={() => setKvkk(true)} className='ml-3 mb-2' style={{ cursor: 'pointer' }}>
//               KVKK metnini okudum ve onaylıyorum.
//             </div>
//           </div>
//         }
//       >
//         <>
//           {window.innerWidth < 1000 && (
//             <iframe
//               title='kvkk-aydinlatma-metni'
//               src='https://docs.google.com/gview?embedded=true&url=https://dev-b2b.vbt.com.tr:1443/assetWeb/docs/kvkk.pdf'
//               style={{ height: '90vh' }}
//             ></iframe>
//           )}
//           {window.innerWidth > 1000 && (
//             <iframe title='kvkk-aydinlatma-metni' src='/assetWeb/docs/kvkk.pdf' style={{ height: '90vh' }}></iframe>
//           )}
//         </>
//       </IPModal>
//     </div>
//   )
// }

//export default HomePageCardLists

function HomePageCardLists(props) {
  const [brand, setBrand] = useState<IBrandDetail>({ List: [], Count: 0 })
  const theme = useAppSelector(selectedTheme)

  const [popup, setPopup] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [appModel, setAppModel] = useState<any>(null)
  const [tel, setTel] = useState<string>('')
  const [mail, setMail] = useState<string>('')
  const [kvkk, setKvkk] = useState<boolean>(false)
  const [onay, setOnay] = useState<number>(0)

  const [campaignsProducts, setCampaignsProducts] = useState<any>({ Count: 0, Products: [] })
  const [campaigns, setCampaigns] = useState<any>(null)
  const [bestSellers, setBestSellers] = useState<IBestSellers[]>([])

  const t = translate(Translations)

  const fetchBrands = useCallback(async () => {
    const api = new GetBrandDetailApi({})
    const queryParams = new KeyValuePair<string>()
    queryParams.add('IsHomePage', 'true')
    api.queryParams = queryParams
    const res = await api.getBrandList(6, theme.id)
    setBrand(res)
  }, [theme.id])

  const fetchCampaignsProducts = useCallback(async (companyId: number) => {
    const data: ICampaignsProducts = {
      PageSize: 10000,
      PageNumber: 0,
      CampaignId: 0,
      Company: companyId,
    }
    const api = new GetCampaignsProductsApi({})
    try {
      const res = await api.getCampaignsProducts(data)
      setCampaignsProducts(res)
    } catch {
      setCampaignsProducts({ Count: 0, Products: [] })
    }
  }, [])

  const fetchCampaigns = useCallback(async (companyId: number) => {
    const data: ICampaignsProducts = {
      PageSize: 10000,
      PageNumber: 0,
      CampaignId: 0,
      Company: companyId,
    }
    const api = new GetCampaignsApi({})
    const res = await api.getCampaigns(data)
    setCampaigns(res)
  }, [])

  const fetchBestSellers = useCallback(async () => {
    const api = new GetMostSelledProductsByCatagoriesApi({})
    const queryParams = new KeyValuePair<number>()
    queryParams.add('dayBefore', 7)
    queryParams.add('minSelledProductCount', 0)
    queryParams.add('minSelledProductQuantityCount', 5)
    queryParams.add('company', theme.id)
    api.queryParams = queryParams
    const res = await api.getMostSelledProductsByCatagories()
    setBestSellers(res)
  }, [theme.id])

  const isContractPopup = useCallback(async () => {
    const api = new ContractPopUp({})
    try {
      const res = await api.contractPopup()
      if (res) {
        setPopup(true)
        setAppModel(res)
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  useEffect(() => {
    fetchBrands()
    fetchCampaignsProducts(theme.id)
    fetchCampaigns(theme.id)
    fetchBestSellers()
  }, [theme.id, fetchBrands, fetchCampaignsProducts, fetchCampaigns, fetchBestSellers])

  useEffect(() => {
    isContractPopup()
  }, [isContractPopup])

  const sendContract = async (id: number) => {
    if (onay && kvkk) {
      const req: number[] = [id]
      const api = new ContractApply({})
      try {
        const res = await api.contractApply(req)
        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Sözleşme tarafınıza gönderilmiştir.',
            showConfirmButton: true,
            allowOutsideClick: true,
          })
          setPopup(false)
        }
      } catch {
        Swal.fire({
          icon: 'error',
          title: 'Hata! Lütfen daha sonra tekrar deneyin.',
          showConfirmButton: true,
          allowOutsideClick: true,
        })
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Devam etmeden önce KVKK metnini onaylamalı ve Ticari Elektronik İletilerini kabul etmelisiniz.',
        showConfirmButton: true,
        allowOutsideClick: true,
      })
    }
  }

  return (
    <div className='container'>
      {campaigns && campaigns.Count > 0 && <HomePageCampaigns data={campaigns.List} count={campaigns.Count} />}
      {campaignsProducts && campaignsProducts.Count > 0 && (
        <HomePagePromotionalProducts data={campaignsProducts.Products} count={campaignsProducts.Count} />
      )}
      {props.product && props.product.MostFavourites && props.product.MostFavourites.Count > 0 && (
        <HomePageTopFavorites data={props.product.MostFavourites.Products} count={props.product.MostFavourites.Count} />
      )}
      {props.product && props.product.RecentVisits && props.product.RecentVisits.Count > 0 && (
        <HomePageRecentVisits data={props.product.RecentVisits.Products} count={props.product.RecentVisits.Count} />
      )}
      {/* {
        product &&
        product.RecentVisits &&
        product.RecentVisits.Count > 0 &&
        <HomePageSimilarItems data={data["SimilarItems"]} />
      } */}
      {props.product && props.product.NewProducts && props.product.NewProducts.Count > 0 && (
        <HomePageMostRecent data={props.product.NewProducts.Products} count={props.product.NewProducts.Count} />
      )}
      {bestSellers && bestSellers.length > 0 && <HomePageBestSellers data={bestSellers} />}
      {brand && brand.Count > 0 && <HomePageTopBrand data={brand} />}
      <IPModal
        show={popup}
        width={1000}
        style={{ textAlignLast: 'center' }}
        onClose={() => setPopup(false)}
        onConfirm={() => {
          sendContract(appModel.Id)
        }}
      >
        <div className='sellerApplicationForm'>
          <div
            style={{
              position: 'relative',
              width: '100%',
              maxWidth: '100%',
            }}
          >
            <img
              src='images/sozlesme_yenileme_.jpg'
              alt=''
              style={{
                width: '100%',
                height: 'auto',
                display: 'block',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: '63.5%', // Alt kenara göre ayarlayabilirsiniz
                left: '50%',
                transform: 'translateX(-50%)',
                textAlignLast: 'left',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <p
                style={{
                  marginTop: '0',
                  display: 'block',
                  fontWeight: 'bold',
                  textAlignLast: 'left',
                  paddingLeft: '45%',
                  fontSize: '120%',
                  color: 'white',
                }}
              >
                {appModel && appModel.MobilePhone}
              </p>
              <p
                style={{
                  margin: '0',
                  display: 'block',
                  fontWeight: 'bold',
                  textAlignLast: 'left',
                  paddingLeft: '45%',
                  fontSize: '120%',
                  color: 'white',
                }}
              >
                {appModel && appModel.Email}
              </p>
            </div>
          </div>
          <div className='form-group col-md-12'>
            <div className='infoWrapper'>
              <div className='acceptForm'>
                {t('pages.register.bayiForms.electronicMessage')}
                <a style={{ color: 'red' }} className='infoMarker'>
                  ?
                </a>
                <div className='infoBox'>
                  {' '}
                  ‘’ İNDEKS GRUP ŞİRKETLERİ ürün ve hizmetleri ile ilgili kampanyalar, tanıtımlar, duyurular hakkında
                  link ve yönlendirmeleri, tanıtımları, haberleri veya reklamları da içerebilen şekilde E-BAYİ'yi
                  bilgilendirmek için E-BAYİ'nin gerek sözleşmenin imzalanması sırasında gerekse sonradan bildirdiği
                  telefon numaraları, faks, posta ve e-posta adreslerinden oluşan tüm bilgileri kullanabilir, yasal
                  düzenleme kapsamında ticari iletişim ve ticari elektronik ileti gönderebilir. E-BAYİ, bu kapsamda
                  bildirdiği telefon numaraları, faks, posta ve e-posta adresleri gibi iletişim bilgileri için izin
                  vermeye yetkili olduğunu, verdiği iletişim bilgilerinin doğru ve gerçek olduğunu, kendisi ile ilişkisi
                  olmayan 3. kişilere ait bilgileri sağlaması halinde doğabilecek tüm zarardan sorumlu olduğunu kabul,
                  beyan ve taahhüt eder. ‘’
                </div>
              </div>
            </div>
            <div className='d-flex gap-4' style={{ display: 'flex', justifyContent: 'space-around' }}>
              <IPRadio
                text={t('pages.register.bayiForms.yes')}
                name='CommercialMessage'
                onClick={() => {
                  setOnay(1)
                }}
                checked={onay === 1}
              />
              <IPRadio
                text={t('pages.register.bayiForms.no')}
                name='CommercialMessage'
                onClick={() => {
                  setOnay(0)
                }}
                checked={onay === 0}
              />
            </div>
          </div>
          <div className='form-group col-md-12' style={{ display: 'flex', justifyContent: 'space-around' }}>
            <div className='acceptForm'>
              <div className='d-flex align-items-center'>
                <div>
                  {t('pages.register.bayiForms.kvk')}{' '}
                  <span
                    className={css`
                      color: #ec4c4c;
                      cursor: pointer;
                      &:hover {
                        color: red;
                      }
                    `}
                    onClick={() => setShowModal(true)}
                  >
                    {t('pages.register.bayiForms.click')}
                  </span>
                </div>
                {kvkk ? '✅' : ''}
              </div>
            </div>
          </div>
        </div>
      </IPModal>
      <IPModal
        title='KVKK Aydınlatma Metni'
        width={1200}
        show={showModal}
        confirmText='Onayla'
        onConfirm={() => {
          setKvkk(true)
          setShowModal(false)
        }}
        onClose={() => {
          setShowModal(false)
        }}
        closeOnEscapeKey={true}
        checkbox={
          <div className='d-flex align-items-center'>
            <IPCheckbox
              name='KvkApproval'
              onCheckedChange={(checked: boolean) => {
                setKvkk(true)
              }}
              checked={kvkk}
            />
            <div onClick={() => setKvkk(true)} className='ml-3 mb-2' style={{ cursor: 'pointer' }}>
              KVKK metnini okudum ve onaylıyorum.
            </div>
          </div>
        }
      >
        <>
          {window.innerWidth < 1000 && (
            <iframe
              title='kvkk-aydinlatma-metni'
              src='https://docs.google.com/gview?embedded=true&url=https://dev-b2b.vbt.com.tr:1443/assetWeb/docs/kvkk.pdf'
              style={{ height: '90vh' }}
            ></iframe>
          )}
          {window.innerWidth > 1000 && (
            <iframe title='kvkk-aydinlatma-metni' src='/assetWeb/docs/kvkk.pdf' style={{ height: '90vh' }}></iframe>
          )}
        </>
      </IPModal>
    </div>
  )
}

export default HomePageCardLists
