import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { ISubscriptionTracking } from '../models/models'

export class GetSubscriptionTrackingApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetSubscriptionTracking',
      requesterInfo,
    })
  }

  public async getSubscriptionTracking(): Promise<ISubscriptionTracking[]> {
    return await this.getAsync<ISubscriptionTracking[]>()
  }
}
