import { Link, useNavigate } from 'react-router-dom'
import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { translate } from '../../base/localization'
import { DATAGATE_THEME, DEFAULT_THEME, DESPEC_THEME, INDEX_THEME, NETEX_THEME } from '../../base/theme/themeConfig'
import IPButton from '../../components/IPButton'
import Image from '../../components/IPImage'
import { useAppSelector } from '../../redux/app/hooks'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import Translations from '../../translations'
import { ProductListSpecialFilterType } from '../ProductList/models/commonModels'
import './css/styles.scss'
import IPPriceDisplay from '../../components/IPPriceDisplay'

function HomePageRecentVisits(props) {
  const t = translate(Translations)
  const theme = useAppSelector(selectedTheme)
  const navigate: any = useNavigate()

  return (
    <section className='home-page-slider section-back-grey mobile-resize py-5 p-l-40 p-r-40 p text-left m-t-40'>
      <div className='row'>
        <div className='col d-flex align-items-center'>
          <Image src='/images/SonIncelediginizUrunler.png' alt='' style={{ width: '36px', marginTop: '-5px' }} />
          <h4 className='section-back-grey-title'>{t('pages.homePage.yourRecentVisits')}</h4>
        </div>
        {props.count > 4 && (
          <div className='col-6'>
            <IPButton
              className={`${theme.name === NETEX_THEME ? 'netex_buttonColor' : ''}${
                theme.name === INDEX_THEME ? 'index_buttonColor' : ''
              }${theme.name === DEFAULT_THEME ? 'default_buttonColor' : ''}${
                theme.name === DESPEC_THEME ? 'despec_buttonColor' : ''
              }${theme.name === DATAGATE_THEME ? 'datagate_buttonColor' : ''}`}
              onClick={() => navigate(`/products?specialFilter=${ProductListSpecialFilterType.RecentVisits}`)}
              style={{
                width: 'auto',
                float: 'right',
                backgroundColor: '#fff',
              }}
              text='Tümünü Göster'
            />
          </div>
        )}
      </div>
      <div
        className={`seperator m-0 ${theme.name === NETEX_THEME ? 'netex_borderBottom' : ''}${
          theme.name === INDEX_THEME ? 'index_borderBottom' : ''
        }${theme.name === DEFAULT_THEME ? 'default_borderBottom' : ''}${
          theme.name === DESPEC_THEME ? 'despec_borderBottom' : ''
        }${theme.name === DATAGATE_THEME ? 'datagate_borderBottom' : ''}`}
      ></div>

      <div className='row m-t-10'>
        {props.data.length > 4 ? (
          <Swiper
            navigation={true}
            keyboard={true}
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            observer={true}
            observeParents={true}
            modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
            className='mySwiper'
          >
            {[...Array(Math.ceil(props.data.length / 4))].map((_, slideIndex) => (
              <SwiperSlide key={slideIndex} style={{ backgroundColor: 'transparent' }}>
                <div className='row'>
                  {props.data.slice(slideIndex * 4, (slideIndex + 1) * 4).map((item: any) => (
                    <div
                      className={`col-12 col-sm-6 col-md-4 col-lg-3 card-mobile-resize${
                        slideIndex === Math.ceil(props.data.length / 4) - 1 ? ' last-column' : ''
                      }`}
                      key={item.Id}
                      style={{ backgroundColor: '#f5f5f5' }}
                    >
                      <div className='your-recent-visits text-center'>
                        <div className='room '>
                          <div className='room-image text-center '>
                            <Link to={`/product/detail/${item.Id}?from=recentVisits`}>
                              {item.Image ? (
                                <Image
                                  src={item.Image}
                                  alt={item.Name}
                                  className='RecentVisits'
                                  style={{ objectFit: 'contain' }}
                                />
                              ) : (
                                <Image
                                  src='./images/products/Resim_yok-01.jpg'
                                  alt=''
                                  style={{ objectFit: 'contain' }}
                                />
                              )}
                            </Link>
                          </div>
                        </div>
                        <div className='product-description '>
                          <Link to={`/product/detail/${item.Id}?from=recentVisits`}>
                            <div className='product-title text-left'>{item.Name}</div>
                          </Link>
                          <div className='product-title text-left'>Ürün Kodu: {item?.ProductCode}</div>
                        </div>
                        {/* <div className='product'>
                          <div className='product-detail p-0 float-right mb-4'>
                            <div className='product-price mt-0 w-100'>
                              {props.product?.SapCampaignNo && props.product?.SapCampaignNo.length > 0 && (
                                <ul className='list list-legend d-flex align-items-center justify-content-between m-0'>
                                  <li className='m-0'>{item.Prices?.Normal?.Price}</li>
                                </ul>
                              )}
                              <ul className='list list-legend d-flex align-items-center justify-content-between m-0'>
                                <li className='m-0'>
                                  {item.Stock && item.Stock.Stock > 0 ? (
                                    item.Prices?.Special?.Price
                                  ) : (
                                    <span style={{ color: 'red', float: 'unset', backgroundColor: 'white' }}>
                                      {t('pages.homePage.outOfStock')}
                                    </span>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div> */}

                        <div className='row align-items-center product-bottom-fixed justify-content-between mb-3'>
                          <div className='col-5 p-0 '>
                            {item.BrandDetail?.ImageUrl && (
                              <Link to={`/products?brandId=${item.BrandId}`}>
                                <Image
                                  src={item.BrandDetail?.ImageUrl}
                                  alt=''
                                  className='popular_product'
                                  style={{ width: '50px' }}
                                />
                              </Link>
                            )}
                          </div>
                          <div className='col-7 text-right mt-2'>
                            <div style={{ display: 'grid' }}>
                              {item.Stock?.Stock > 0 && <span style={{ fontSize: '16px' }}>Size Özel</span>}
                              {props.product?.SapCampaignNo && props.product?.SapCampaignNo.length > 0 && (
                                <ul
                                  className=' d-flex align-items-center justify-content-between m-0'
                                  style={{
                                    listStyle: 'none',
                                    fontWeight: '600',
                                    fontSize: 'larger',
                                  }}
                                >
                                  <li className='m-0'>
                                    {/* {item.Prices?.Normal?.Price} */}
                                    <IPPriceDisplay
                                      price={item.Prices?.Normal?.Price ?? '0,00 $'}
                                      fractionalFontSize='14px'
                                    />
                                  </li>
                                </ul>
                              )}
                              <ins>
                                {item.Stock && item.Stock.Stock > 0 ? (
                                  // item.Prices?.Special?.Price
                                  <IPPriceDisplay
                                    price={item.Prices?.Special?.Price ?? '0,00 $'}
                                    fractionalFontSize='14px'
                                  />
                                ) : (
                                  <span style={{ color: 'red' }}>{t('pages.homePage.outOfStock')}</span>
                                )}
                              </ins>
                            </div>
                          </div>
                        </div>
                        <hr />
                        {/* <Link to='#'>
                          <div className='similar-products' style={{ bottom: '25px' }}>
                            {t('pages.homePage.similarProducts')}
                            <i className='fas fa-chevron-right fa-fw'></i>
                          </div>
                        </Link> */}
                      </div>
                    </div>
                  ))}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          props.data.map(
            (item: any, index) =>
              index < 4 && (
                <div className={`col-12 col-sm-6 col-md-4 col-lg-3 card-mobile-resize`} key={item.Id}>
                  <div className='your-recent-visits text-center'>
                    <div className='room '>
                      <div className='room-image text-center '>
                        <Link to={`/product/detail/${item.Id}?from=recentVisits`}>
                          {item.Image ? (
                            <Image
                              src={item.Image}
                              alt={item.Name}
                              className='RecentVisits'
                              style={{ objectFit: 'contain' }}
                            />
                          ) : (
                            <Image src='./images/products/Resim_yok-01.jpg' alt='' style={{ objectFit: 'contain' }} />
                          )}
                        </Link>
                      </div>
                    </div>
                    <div className='product-description '>
                      <Link to={`/product/detail/${item.Id}?from=recentVisits`}>
                        <div className='product-title text-left'>{item.Name}</div>
                      </Link>
                      <div className='product-title text-left'>Ürün Kodu: {item?.ProductCode}</div>
                    </div>
                    <div className='product'>
                      <div className='product-detail p-0 float-right mb-4'>
                        <div style={{ display: 'grid' }}>
                          {item.Stock?.Stock > 0 && <span style={{ fontSize: '16px' }}>Size Özel</span>}
                          <div className='product-price mt-0 w-100'>
                            {props.product?.SapCampaignNo && props.product?.SapCampaignNo.length > 0 && (
                              <ul
                                className=' d-flex align-items-center justify-content-between m-0'
                                style={{
                                  listStyle: 'none',
                                  fontWeight: '600',
                                  fontSize: 'larger',
                                }}
                              >
                                <li className='m-0'>
                                  {/* {item.Prices?.Normal?.Price} */}
                                  <IPPriceDisplay
                                    price={item.Prices?.Normal?.Price ?? '0,00 $'}
                                    fractionalFontSize='14px'
                                  />
                                </li>
                              </ul>
                            )}
                            <ul
                              className='d-flex align-items-center justify-content-between m-0'
                              style={{
                                listStyle: 'none',
                                fontWeight: '600',
                                fontSize: 'larger',
                              }}
                            >
                              <li className='m-0'>
                                {item.Stock && item.Stock.Stock > 0 ? (
                                  // item.Prices?.Special?.Price
                                  <IPPriceDisplay
                                    price={item.Prices?.Special?.Price ?? '0,00 $'}
                                    fractionalFontSize='14px'
                                  />
                                ) : (
                                  <span style={{ color: 'red', float: 'unset', backgroundColor: 'white' }}>
                                    {t('pages.homePage.outOfStock')}
                                  </span>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    {/* <Link to='#'>
                      <div className='similar-products' style={{ marginBottom: '25px' }}>
                        {t('pages.homePage.similarProducts')}
                        <i className='fas fa-chevron-right fa-fw'></i>
                      </div>
                    </Link> */}
                  </div>
                </div>
              )
          )
        )}
      </div>
    </section>
  )
}

export default HomePageRecentVisits
