import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'

export class UpdateBankAdditionalInstallmentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/updateBankAdditionalInstallment',
      requesterInfo,
    })
  }

  public async updateBankAdditionalInstallment(request: any): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
