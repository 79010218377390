import React, { useEffect, useState } from 'react'
import { IOrder } from '../models/responseModels'
import { GetOrderListApi } from '../services/getOrderListApi'
import { Link } from 'react-router-dom'

import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'

export default function ListOrder() {
  const t = translate(Translations)
  const [orders, setOrders] = useState<IOrder[]>([])

  useEffect(() => {
    // id geliyorsa
    getOrders()
  }, [])

  // const [deneme, setDeneme] = useState(orders.length)

  const getOrders = async () => {
    const getByIdApi = new GetOrderListApi({})

    const orders = await getByIdApi.getOrders()

    setOrders((orders as any).Data)
    //setstate banners
  }
  
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>{t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.header')}</span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label htmlFor='location' className='form-label'>
                    {t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.field')}
                  </label>
                  <IPSelectBox
                    options={[
                      { label: t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.choose'), value: '1' },
                      { label: t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.accountCode'), value: '2' },
                    ]}
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='location' className='form-label'>
                    {t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.fieldValueCondition')}
                  </label>
                  <IPInput type='text' className='form-control' id='location' />
                </div>
                <div className='col-lg-2 buttonSuz'>
                  <IPButton
                    type='button'
                    className='btn btn-success'
                    text={t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.filtre')}
                  />
                </div>
              </div>
            </form>
            <br />
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <table
                        id='datatable'
                        className='table table-bordered table-hover dataTable'
                        role='grid'
                        aria-describedby='datatable_info'
                      >
                        <thead>
                          <tr role='row' className='odd'>
                            <th>#</th>
                            <th>{t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.companyName')}</th>
                            <th>{t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.productName')}</th>
                            <th>{t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.brand')}</th>
                            <th>{t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.number')}</th>
                            <th>{t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.price')}</th>
                            <th>{t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.operation')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {orders &&
                            (orders as any[]).map((item: IOrder) => (
                              <tr role='row' className='odd' key={item.Id}>
                                <td>{item.OrderNumber}</td>
                                <td>İnternet Deneme Kartı</td>
                                <td>Deneme 1</td>
                                <td>Deneme 1</td>
                                <td>Deneme 1</td>
                                <td>Deneme 1</td>
                                <td>
                                  <a className='ml-2' href='#' data-toggle='tooltip' data-original-title='Delete'>
                                    {t('pages.admin.dealerAndOrderReport.orderOperation.listOrder.orderCancel')}
                                  </a>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
