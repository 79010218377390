import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IBank } from '../models/models'

export class GetBankListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'paramPosTransaction/bankList',
      requesterInfo,
    })
  }

  public async getBankList(): Promise<IBank[]> {
    return await this.getAsync<IBank[]>()
  }
}
