export interface IDealerReminder {
  Company: number
  USD_BAK: number
  EUR_BAK: number
  TL_BAK: number
  VGA_USD: number
  VGA_EUR: number
  VGA_TL: number
  BAK_USD: number
  BAS_USDI: number
  USD_VGA: number
  USD_VGAI: number
}

export interface IInstallmentPrices {
  InstallmentNumber: number
  Price: string
  TotalPrice: string
}

export interface IBinResponse {
  BankCode: number
  BankName: string
  BinNumber: string
  CardAssociation: string
  CardFamilyName: string
  CardType: string
  Commercial: number
  Force3Ds: number
  ForceCvc?: number
  InstallmentPrices: IInstallmentPrices[]
  Message: string
  Success: boolean
}

export interface PaymentRequestMySpecial {
  HolderName: string
  CardNumber: string
  ExpireDate: string
  Cvc: string
  Installment: number
  Amount: number
  ComissionAmount: number
  Company: number
  InterestRate: number
  BankId: string
  Currency: string
  IsIncluding: boolean
  DealerLimitId?: number
  AmountHash?: string
  isMT: boolean
}

export interface IPaymentInvoice {
  FatuaNo: string
  Tutar: number
  IsClosed: boolean
}

export interface PaymentRequestFcvMySpecial extends PaymentRequestMySpecial {
  Faturalar: IPaymentInvoice[]
  FcvId: number
}

export interface IGetInstallmentSpecialRequest {
  Company: string
  BinNumber: string
  Type: number
  Amount: number
  IsIncluding: boolean
}
export interface IBankInfos {
  BinNumber: string
  Commercial: number
  Price: string
  CardType: string
  CardAssociation: string
  CardFamilyName: string
  Force3Ds: number
  BankCode: number
  BankName: string
  ForceCvc: number
  InstallmentPrices: any
}
export interface IGetInstallmentSpecial {
  Type: string
  StartDate: Date
  EndDate: Date
  BankName: string
  BankNote: string
  AdditionalInstallment: string
  Installment: number
  InterestRate: number
  Id: number
  DealerCodes: string
  BankId: string
  ComissionAmount: number
  TotalAmount: number
  Company: string
  TotalMain: number
  TotalRate: number
  ComissionAmountValue: string
  TotalAmountValue: string
  TotalAmountTLValue: string
  TotalAmountDolarValue: string
  TotalAmountEuroValue: string
  InfoValueDolar: string
  InfoValueEuro: string
  InfoValueTL: string
  ParamPosDetail?: IParamPosDetail
}

export interface IParamPosDetail {
  Installment: number,
  InterestRate: number,
  TotalRate: number,
  ComissionAmount: number,
  ComissionAmountValue: string,
  TotalAmount: number,
  TotalAmountTLValue: string,
  TotalAmountDolarValue: string,
  TotalAmountEuroValue: string,
  TotalMain: number,
  InfoValue: number,
  InfoValueTL: string,
  InfoValueDolar: string,
  InfoValueEuro: string
}

export interface ILimit {
  CompanyCode: string
  Id: number
  Limit: number
  RemainingTotal: number
  PaymentTermsName: string
  PaymentTermsId: number
  UsedTotal: number
}

export enum PaymentTypeEnum {
  Avantaj = 0,
  Firsat = 1,
  Fcv = 2,
  CariOdeme = 3,
}

export interface IDealerLimit {
  List: ILimit[]
  Type: PaymentTypeEnum
}

export interface IInvoice {
  AnlikKur: number
  BelgeParaBirimi: string
  Company: string
  FCVID: number
  FCVName: string
  FaturaID: number
  FaturaNo: string
  FaturaTarihi: Date
  OdenmisMiktar: number
  ToplamTry: number
}

export interface IAllPaymentData {
  DealerReminders?: IDealerReminder[]
  DealerLimits?: IDealerLimit[]
  Invoices?: IInvoice[]
  IsIncludedList?: string[]
}

export interface IPaymentDetail {
  Amount: number
  Bank: string
  Date: Date
  DealerCode: string
  DealerName: string
  DocumentNo: string
  Installment: number
  NameSurname: string
  ReceiptNumber: string
  CrediCard: string
  ExpireDate: string
}

export interface IBanner {
  Id: number
  Title: string
  Description: string
  Location: number
  CompanyList: number[]
  Category: number
  Order: number
  Active: true
  StartDate: Date
  EndDate: Date
  CariTypeCode: []
  CariSubTypeCode: []
  Image: string
  ImageUrl: string
  MobilImage: string
  MobilImageUrl: string
  CounterLocation: number
  DontShowToUsers: string
  ProductCode: []
  ProductAddress: string
  BannerType: number
  VideoId: string
  IsHomepage: true
}

export interface PaymentControl {
  Amount: number
  BinNumber: string
  Company: string
  IsIncluding: boolean
  PaymentTermsId: number
  Type: number
  Installment: number
  UserId: number
}

export interface IManualCreditCardPaymentControl {
  Amount: number
  BinNumber: string
  Company: string
  DealerCode: string
  ComissionType: number
  Installment: number
}

export interface IManualCreditCardPayment {
  HolderName: string
  CardNumber: string
  ExpireDate: string
  Cvc: string
  Installment: number
  Amount: number
  Company: number
  DealerCode: string
  InterestRate: number
  BankID: string
  Currency: string
  ComissionType: number
  ComissionAmount: number
}

export interface IInstallmentInquiry {
  Company: string
  DealerCode: string
  Amount: number
  BinNumber: string
  ComissionType: number
}
