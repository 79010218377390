import { Reducer, useReducer } from 'react'

function createReducer<T>(): Reducer<T, { payload: Partial<T> }> {
  return (state: T, action: { payload: Partial<T> }): T => {
    const newState: any = { ...state }

    Object.assign(newState, action.payload)

    return newState
  }
}

export function useCustomReducer<T>(initialState: T): [T, (props: Partial<T>) => void] {
  const reducer = createReducer<T>()

  const [state, dispatch] = useReducer(reducer, initialState)

  const dispatchPayload = (props: Partial<T>) => {
    dispatch({
      payload: props,
    })
  }

  return [state, dispatchPayload]
}
