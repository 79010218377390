import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IEnteredOrderReportForDealerRequest, IEnteredOrderReportForDealerResponse } from '../models/model'

export class EnteredOrderReportForDealerApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/enteredOrderReportForDealer',
      requesterInfo,
    })
  }

  public async enteredOrderReportForDealer(
    req: IEnteredOrderReportForDealerRequest
  ): Promise<IEnteredOrderReportForDealerResponse[]> {
    return await this.postAsync<IEnteredOrderReportForDealerResponse[]>(req)
  }
}
