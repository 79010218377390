import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IMenu } from '../models'

export class GetMenusApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/menu',
      requesterInfo,
    })
  }

  public async getMenus(): Promise<IMenu[]> {
    return await this.getAsync<IMenu[]>()
  }
}
