import React from 'react'
import ClientLayout from '../layouts/ClientLayout'
import { withAnnouncementPopup } from './withAnnouncementPopup'
import { withLogin } from './withLoginHOC'

const WithClientLayout = (props: any) => {
  return (
    <React.Fragment>
      <ClientLayout>{props.children}</ClientLayout>
    </React.Fragment>
  )
}

export default withAnnouncementPopup(withLogin(WithClientLayout))
