import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'

export class GetProductOtherCompany extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/productFromOtherCompanies',
      requesterInfo,
    })
  }

  public async getProductOtherCompany(requestData: any): Promise<any> {

    return await this.postAsync<any>(requestData);
  }
}
