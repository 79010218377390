import React, { useEffect, useState } from 'react'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import { useFormik } from 'formik'
import IPInput from '../../../../../components/IPInput/IPInput'
import IPButton from '../../../../../components/IPButton/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { PutAuthorizedServicesMainApi } from '../services/putAuthorizedServicesApi'
import Swal from 'sweetalert2'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PostAuthorizedServicesMainApi } from '../services/postAuthorizedServicesProcedurApi'
import validationSchema from './validation'
import { css } from '@emotion/css'
import { IBrand } from '../../../components/models/models'
import { getBrandApi } from '../../../components/api'
import IPTextarea from '../../../../../components/IPTextarea'
import InputCampaign from '../../../../../components/InputCampaign'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'

interface IMarkaState {
  brandList: IBrand[]
  buttonName: string
  authorizedServices: any
}

function AddAuthorizedServices(props: BaseComponentWithContextProps) {
  const getInitialState = (): IMarkaState => {
    return {
      brandList: [],
      buttonName: 'Ekle',
      authorizedServices: {},
    }
  }

  const intialState = getInitialState()

  const [state, setState] = useCustomReducer<IMarkaState>(intialState)
  useEffect(() => {
    getBrand()
  }, [])

  const getBrand = async () => {
    const data: any[] = await getBrandApi()
    setState({ brandList: data })
  }

  const initialValues = Object.assign({
    Id: 0,
    Enable: false,
    BrandIds: [] as ISelectOption[],
    OrderNo: 0,
    StartDate: Date,
    EndDate: Date,
    ProductGroup: '',
    CompanyName: '',
    Address: '',
    Phone: '',
    Email: '',
    WebAddress: '',
    IsChargedShippingAccepted: '',
    ContractedCargoCompany: '',
    AuthorizedServicePoints: '',
  })

  const { handleSubmit, handleChange, handleBlur, setValues, setFieldValue, resetForm, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async values => {
        if (values.BrandIds.length < 1) {
          Swal.fire({
            icon: 'error',
            title: 'Lütfen marka seçiniz!',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          return
        }
        const req = Object.assign({
          Id: values.Id,
          BrandIds:
            values.BrandIds && values.BrandIds.length > 1
              ? values.BrandIds.map(x => x.value)
              : values.BrandIds && values.BrandIds.length === 1
              ? [values.BrandIds[0].value]
              : values.BrandId && [values.BrandId.value],

          OrderNo: values.OrderNo,
          StartDate: getDateByTimezoneOffset(startDate),
          EndDate: getDateByTimezoneOffset(endDate),
          Description: {
            ProductGroup: values.ProductGroup,
            CompanyName: values.CompanyName,
            Address: values.Address,
            Phone: values.Phone,
            Email: values.Email,
            WebAddress: values.WebAddress,
            IsChargedShippingAccepted: values.IsChargedShippingAccepted,
            ContractedCargoCompany: values.ContractedCargoCompany,
            AuthorizedServicePoints: values.AuthorizedServicePoints,
          },
          Enable: values.Enable,
        })

        if (state.buttonName === 'Güncelle') {
          props.showLoading()
          const putAuthorizedServicesMain: PutAuthorizedServicesMainApi = new PutAuthorizedServicesMainApi({})
          putAuthorizedServicesMain
            .putAuthorizedServicesMain(req)
            .then(async result => {
              if (result) {
                props.hideLoading()
                Swal.fire({
                  icon: 'success',
                  title: 'Yetkili Servis Güncellendi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                }).then((res: any) => {
                  navigate('/admin/BrandPageOperation/authorizedServices/listAuthorizedServices')
                })
              }
            })
            .catch((err: any) => {
              props.hideLoading()
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        } else {
          props.showLoading()
          const postAuthorizedServicesMain: PostAuthorizedServicesMainApi = new PostAuthorizedServicesMainApi({})
          postAuthorizedServicesMain
            .postAuthorizedServicesMain(req)
            .then(async result => {
              if (result) {
                props.hideLoading()
                Swal.fire({
                  icon: 'success',
                  title: 'Yetkili Servis Eklendi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                }).then((res: any) => {
                  navigate('/admin/BrandPageOperation/authorizedServices/listAuthorizedServices')
                })
              }
            })
            .catch((err: any) => {
              props.hideLoading()
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        }
      },
    })

  const routeLocation = useLocation()

  useEffect(() => {
    if (routeLocation.state) {
      const data = (routeLocation.state as any)?.authorizedServicesProcedurData
      setState({ authorizedServices: data, buttonName: 'Güncelle' })
      //setDateRange([data.StartDate ? new Date(data.StartDate) : null, data.EndDate ? new Date(data.EndDate) : null])

      state.authorizedServices.BrandId = state.brandList
        .filter(item => Number(item.Id) === Number(state.authorizedServices.BrandId))
        .map(com => {
          return { value: com.Id, label: com.Name }
        })[0]
      values.BrandIds = data.BrandId as unknown as ISelectOption[]
      values.ProductGroup = data.Description?.ProductGroup
      values.CompanyName = data.Description?.CompanyName
      values.Address = data.Description?.Address
      values.Phone = data.Description?.Phone
      values.Email = data.Description?.Email
      values.WebAddress = data.Description?.WebAddress
      values.IsChargedShippingAccepted = data.Description?.IsChargedShippingAccepted
      values.ContractedCargoCompany = data.Description?.ContractedCargoCompany
      values.AuthorizedServicePoints = data.Description?.AuthorizedServicePoints

      setEndDate(getDateByTimezoneOffset(data.EndDate))
      setStartDate(getDateByTimezoneOffset(data.StartDate))
      setValues(data)
    } else {
      resetForm()
    }
  }, [state.brandList])

  const navigate: any = useNavigate()

  const formStyles = css`
    input {
      margin-bottom: 1rem;
    }
    .validationError {
      position: relative;
    }
  `
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4'>Yetkili Servisler</span>
            <div className='col-lg-2'>
              <div className='buttonsuz'>
                <Link to='/admin/BrandPageOperation/authorizedServices/listAuthorizedServices'>
                  <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                </Link>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form id='form1' className={formStyles} onSubmit={handleSubmit}>
              <div className='row'>
                <div className='float-right'>
                  <label className='p-switch switch-color-success' style={{ float: 'right' }}>
                    <label htmlFor='active' className='float-left mr-4'>
                      Etkin
                    </label>
                    <IPCheckbox type='checkbox' name='Enable' onChange={handleChange} checked={values.Enable} />
                  </label>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-4'>
                  <label className='labeltext'>Markalar</label>
                  <IPSelectBox
                    isClearable
                    isMulti
                    id='BrandIds'
                    name='BrandIds'
                    options={
                      state.brandList &&
                      state.brandList.map(brand => {
                        return { label: brand.Name, value: brand.Id }
                      })
                    }
                    onChangeMulti={options => setFieldValue('BrandIds', options)}
                    value={values.BrandIds as unknown as ISelectOption}
                  />
                </div>
                <div className='form-group col-md-4'>
                  <label className='labeltext'>Sıralama</label>
                  <div>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='OrderNo'
                      value={values.OrderNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className='form-group col-md-2 align-self-center'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='form-group col-md-2 align-self-center'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
              </div>

              <div className='row mt-2'>
                <div className='col-lg-4'>
                  <label className='labeltext'> Ürün Grubu</label>
                  <div>
                    <IPTextarea
                      className='form-control'
                      name='ProductGroup'
                      value={values.ProductGroup}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='labeltext'> Firma İsmi</label>
                  <div>
                    <IPTextarea
                      className='form-control'
                      name='CompanyName'
                      value={values.CompanyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='labeltext'> Adres</label>
                  <div>
                    <IPTextarea
                      className='form-control'
                      name='Address'
                      value={values.Address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-lg-4'>
                  <label className='labeltext'> Telefon</label>
                  <div>
                    <IPTextarea
                      className='form-control'
                      name='Phone'
                      value={values.Phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='labeltext'> E-Posta</label>
                  <div>
                    <IPTextarea
                      className='form-control'
                      name='Email'
                      value={values.Email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.Email as string}
                      touched={touched.Email as boolean}
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='labeltext'>Web Adresi</label>
                  <div>
                    <IPTextarea
                      className='form-control'
                      name='WebAddress'
                      value={values.WebAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className='row mt-2'>
                <div className='col-lg-4'>
                  <label className='labeltext'> Ücret Ödemeli Kargo Kabul Ediliyor Mu?</label>
                  <div>
                    <IPTextarea
                      className='form-control'
                      name='IsChargedShippingAccepted'
                      value={values.IsChargedShippingAccepted}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='labeltext'> Anlaşmalı Kargo Şirketi (Gönderi Kodu) </label>
                  <div>
                    <IPTextarea
                      className='form-control'
                      name='ContractedCargoCompany'
                      value={values.ContractedCargoCompany}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
                <div className='col-lg-4'>
                  <label className='labeltext'>Yetkili Servis Noktaları</label>
                  <div>
                    <IPTextarea
                      className='form-control'
                      name='AuthorizedServicePoints'
                      value={values.AuthorizedServicePoints}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div className='row m-t-10'>
                <div className='etkin d-flex justify-content-end'>
                  <IPButton
                    type='submit'
                    className='btn btn-success'
                    text={state.buttonName}
                    style={{ width: '25%' }}
                  ></IPButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(AddAuthorizedServices)
