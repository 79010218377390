import MainLayout from '../pages/MySpecial/CurrentAccount/CurrentAccountLayout'
import { withLogin } from './withLoginHOC'
import ClientLayout from '../layouts/ClientLayout'
import SSHLayout from '../pages/MySpecial/SSHLayout'
const WithSSHLayout = (props: any) => {
  return (
    <>
        <SSHLayout>
          <MainLayout>{props.children}</MainLayout>
        </SSHLayout>
    </>
  )
}

export default withLogin(WithSSHLayout)
