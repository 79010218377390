/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useState } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { ListBankAdditionalInstallmentsApi } from '../services/listBankAdditionalInstallmentsApi'
import { BankAdditionalInstallmentListResponseModel, IBankAdditionalInstallments } from '../models/models'
import { useCustomReducer } from '../../../../../base/customHooks'
import { IException } from '../../../../../base/models'
import Swal from 'sweetalert2'
import Table from '../../../../../components/Table'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'
import { Link, useNavigate } from 'react-router-dom'
import { css } from '@emotion/css'
import { translate } from '../../../../../base/localization'
import Translations from '../../../../../translations'
import { DeleteBankAdditionalInstallmentApi } from '../services/deleteBankAdditionalInstallmentApi'
import IPButton from '../../../../../components/IPButton'
import IPModal from '../../../../../components/IPModal'
import moment from 'moment'

export interface BankAdditionalInstallmentListState {
  additionalInstallments: IBankAdditionalInstallments[]
}

function BankAdditionalInstallmentList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)

  const getInitialState = (): BankAdditionalInstallmentListState => {
    return {
      additionalInstallments: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<BankAdditionalInstallmentListState>(intialState)
  const [allHistory, setAllHistory] = useState<BankAdditionalInstallmentListResponseModel[]>([])
  const [showHistoryDetail, setShowHistoryDetail] = useState<boolean>(false)

  useEffect(() => {
    getBankAdditionalInstallment()
  }, [])

  const getBankAdditionalInstallment = () => {
    props.showLoading && props.showLoading()
    const api: ListBankAdditionalInstallmentsApi = new ListBankAdditionalInstallmentsApi({})
    api
      .lisBankAdditionaltInstallemnts()
      .then((result: IBankAdditionalInstallments[]) => {
        props.hideLoading && props.hideLoading()

        if (result) {
          setState({ additionalInstallments: result })
        }
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  // Tablo başlıkları ve sütun değerleri
  const columnDefinitions = [
    { Header: 'Sıra', accessor: 'col1' },
    { Header: 'Banka Adı', accessor: 'col2' },
    { Header: 'Ana Taksit', accessor: 'col3' },
    { Header: 'Ek Taksit Seçeneği', accessor: 'col4' },
    { Header: 'Değişiklikler', accessor: 'col5' },
    { Header: 'İşlemler', accessor: 'col6' },
  ]
  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableData = useMemo(
    () =>
      state.additionalInstallments.map((item, index) => {
        const data = {
          col1: index + 1,
          col2: item.BankName,
          col3: item.InstallmentOption,
          col4: item.AdditionalInstallment,
          col5: (
            <IPButton
              text='Tarihçe'
              onClick={() => {
                setShowHistoryDetail(true)
                var selectedData = state.additionalInstallments.find(
                  auth => auth.BankAdditionalInstallmentID === item.BankAdditionalInstallmentID
                )
                if (selectedData) {
                  const { AllHistory } = selectedData

                  if (AllHistory && AllHistory.length > 0) {
                    setAllHistory(AllHistory)
                  } else {
                    setAllHistory([])
                  }
                } else {
                  setAllHistory([])
                }
              }}
              type='button'
              style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
              className='btn btn-danger'
            ></IPButton>
          ),
          col6: (
            <div>
              <a
                onClick={() => updateInstallement(item.BankAdditionalInstallmentID)}
                data-action-type='edit'
                role='button'
                className={css`
                  cursor: pointer;
                `}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.edit')}
              >
                <i
                  className={`${css`
                    font-size: 1.2rem;
                    color: green;
                  `} icon-edit`}
                ></i>
              </a>
              <a
                onClick={() => deleteInstallement(item.BankAdditionalInstallmentID)}
                data-action-type='delete'
                role='button'
                className={css`
                  cursor: pointer;
                `}
                data-toggle='tooltip'
                data-original-title={t('pages.mySpecial.userUpdate.delete')}
              >
                <i
                  className={`${css`
                    font-size: 1.2rem;
                    color: red;
                  `} icon-trash-2`}
                ></i>
              </a>
            </div>
          ),
        }
        return data
      }),
    [state.additionalInstallments]
  )

  const excelButton = async () => {
    try {
      if (state.additionalInstallments) {
        const data: any[] = []
        if (state.additionalInstallments.length > 0) {
          state.additionalInstallments.map((item, index) => {
            return data.push({
              Sıra: index + 1,
              'Banka Adı': item.BankName,
              'Ana Taksit': item.InstallmentOption,
              'Ek Taksit Seçeneği': item.AdditionalInstallment,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Banka Ek Taksitleri')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const deleteInstallement = async (ID: number) => {
    const shouldDelete = await Swal.fire({
      icon: 'warning',
      title: 'Bu taksiti silmek istediğinizden emin misiniz?',
      showCancelButton: true,
      showConfirmButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
    })

    if (shouldDelete.isConfirmed) {
      const data = state.additionalInstallments.find(item => item.BankAdditionalInstallmentID === ID)

      if (data) {
        const { BankAdditionalInstallmentID } = data // Destructure the ID
        const deleteInstallemnts = new DeleteBankAdditionalInstallmentApi({})

        try {
          const result = await deleteInstallemnts.deleteBankAdditionalInstallment(BankAdditionalInstallmentID)

          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Banka Ek Taksiti Silindi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async result => {
              if (result.isConfirmed) {
                getBankAdditionalInstallment()
              }
            })
          }
        } catch (err: any) {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    }
  }

  const navigate: any = useNavigate()
  const updateInstallement = async (Id: number) => {
    var dt = state.additionalInstallments.find(auth => {
      return auth.BankAdditionalInstallmentID === Id
    })
    navigate('/admin/BankAndInstallementOperation/AdditionalBankInstallment/AddBankAdditionalInstallment', {
      state: { installementData: dt },
    })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <div className='col-12 col-md-9 mb-2'>
              <span className='h4 mb-0'>Banka Ek Taksit Seçenekleri</span>
            </div>

            <div className='col-12 col-md-2 mb-2'>
              <label className='p-switch switch-color-success'>
                <Link to='/admin/BankAndInstallementOperation/AdditionalBankInstallment/AddBankAdditionalInstallment'>
                  <IPButton
                    text='Banka Ek Taksit Ekle'
                    className='btn btn-primary'
                    style={{ height: '45px', marginLeft: '15px' }}
                  />
                </Link>
              </label>
            </div>
            <div className='col-12 col-md-1'>
              <div className='d-flex align-items-center'>
                <span>Excel: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButton}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={showHistoryDetail}
        onClose={() => setShowHistoryDetail(false)}
        hideConfirm={true}
        width={1200}
        title='Banka Taksit Tarihçe'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>Değişiklik Tarihi</th>
              <th>Banka Adı</th>
              <th>Asıl Taksit</th>
              <th>Önceki Ek Taksit</th>
              <th>Yeni Ek Taksit</th>
              <th>Kullanıcı</th>
              <th>Ip</th>
              <th>Açıklama</th>
            </tr>
          </thead>
          <tbody>
            {allHistory && allHistory.length > 0
              ? allHistory.map((ba, index) => (
                  <tr role='row' className='odd' key={ba.Id}>
                    <td>{ba.ChangeDate ? moment(ba.ChangeDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                    <td>{ba.BankName}</td>
                    <td>{ba.InstallmentOption}</td>
                    <td>{ba.OldAdditionalInstallment}</td>
                    <td>{ba.NewAdditionalInstallment}</td>
                    <td>{ba.UserName}</td>
                    <td>{ba.UserIp}</td>
                    <td>{ba.Description}</td>
                  </tr>
                ))
              : ''}
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}
export default componentWithContext(BankAdditionalInstallmentList)
