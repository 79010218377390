import { useCustomReducer } from '../../../../base/customHooks'
import IPButton from '../../../../components/IPButton'
import IPDatePicker from '../../../../components/IPDatePicker'
import './ImeiSerialNumber.scss'
import { GetProductsClickReportDataApi } from './services/getProductsClickReportDataApi'
import { IException } from '../../../../base/models'
import Swal from 'sweetalert2'
import { GetProductsClickReportDataRequest, IContents, IImei } from './models'
import {
  exportToXLSX,
  formatDate,
  formatDateRange,
  FormatDateRangeEnum,
  formatPrice,
} from '../../../../base/utils/dataHelper'
import { useState } from 'react'
import { GetImeiSeriNoApi } from './services/getImeiSeriNoApi'
import IPModal from '../../../../components/IPModal'
import InputCampaign from '../../../../components/InputCampaign'
import ExcelButton from '../../../../components/IPExcelButton/IPExcelButton'

interface IStates {
  situation: string
  showInvoice: boolean
  invoiceDetail?: IImei
  contents?: IContents
}

export default function Reports(props: any) {
  const getInitialState = (): IStates => {
    return {
      situation: 'M',
      showInvoice: false,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IStates>(intialState)
  const exportToExcel = () => {
    const getReportDataApi: GetProductsClickReportDataApi = new GetProductsClickReportDataApi({})
    const req: GetProductsClickReportDataRequest = {
      StartDate: startDate,
      EndDate: endDate,
    }
    getReportDataApi
      .getReportData(req)
      .then((res: any) => {
        exportToXLSX(res, 'Ürün Tıklanma Raporu')
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
      })
  }

  const [imeiserialNo, setImeiserialNo] = useState<IImei[]>([])
  const getImeiSeriNo = async () => {
    const sdate = formatDate(startDate)
    const edate = formatDate(endDate)
    const data = {
      DealerCode: '',
      StartDate: sdate,
      EndDate: edate,
      Situation: state.situation,
    }
    const getImeiSeriNo: GetImeiSeriNoApi = new GetImeiSeriNoApi({})

    const res = await getImeiSeriNo.getImeiSeriNo(data)
    setImeiserialNo(res)
  }

  const getTotalPrice = (data: IContents[]) => {
    let total = 0
    data.map(d => {
      if (d.KDV) total += d.KDV
      if (d.ProductPrice) total += d.ProductPrice
      return total
    })
    return total.toFixed(2)
  }

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  return (
    <div className='Reports'>
      <div className='heading-text'>
        <h4 style={{ textAlign: 'center' }}>Imei/Seri No Sorgulama </h4>
      </div>
      <div className='row'>
        <div className='col-12'>
          <div className='Reports'>
            <form>
              <div className='formElements d-flex row justify-content-center'>
                <div className='row' style={{ justifyContent: 'center' }}>
                  <div className='col-12 col-md-3 mb-3'>
                    <InputCampaign
                      type='date'
                      id='StartDate'
                      name='StartDate'
                      label={'Başlangıç Tarihi'}
                      value={toDateTimeLocal(startDate)}
                      onChange={e => getDate(e.target.value, 'Start')}
                      isActive
                    />
                  </div>
                  <div className='col-12 col-md-3 mb-3'>
                    <InputCampaign
                      type='date'
                      id='EndDate'
                      name='EndDate'
                      label={'Bitiş Tarihi'}
                      value={toDateTimeLocal(endDate)}
                      onChange={e => getDate(e.target.value, 'End')}
                      isActive
                    />
                  </div>
                </div>
              </div>
            </form>
            <div className='myOrderActions d-flex justify-content-center'>
              <div className='form-check white-circle pl-0'>
                <input
                  className='white-circle-input form-check-input'
                  name='paymentRadio'
                  id='exampleRadios1'
                  value='option1'
                  checked={state.situation === 'M'}
                  type='radio'
                  onChange={e =>
                    setState({
                      situation: 'M',
                    })
                  }
                />
                <label className='form-check-label m-0 ' htmlFor='exampleRadios1'>
                  <span style={{ fontSize: '15px' }}>Satış</span>
                </label>
              </div>
              <div className='form-check white-circle pl-0'>
                <input
                  className='white-circle-input form-check-input'
                  name='paymentRadio'
                  id='exampleRadios1'
                  value='option1'
                  checked={state.situation === 'O'}
                  type='radio'
                  onChange={e =>
                    setState({
                      situation: 'O',
                    })
                  }
                />
                <label className='form-check-label m-0 ' htmlFor='exampleRadios1'>
                  <span style={{ fontSize: '15px' }}>İptal</span>
                </label>
              </div>
            </div>
            {/* <div className='myOrderActions d-flex justify-content-center mt-5'>
              <IPButton text='Listele' className='button' onClick={() => getImeiSeriNo()} />
              <IPButton text="Excel'e Aktar" onClick={exportToExcel} className='button ml-0 ml-lg-5' />
            </div> */}
            <div className='myOrderActions m-t-25 m-b-15 justify-content-center'>
              <IPButton
                text='Listele'
                className='button'
                onClick={() => getImeiSeriNo()}
                style={{
                  backgroundColor: 'red',
                  color: 'white',
                  borderRadius: '10px',
                  width: '150px',
                  height: '50px',
                }}
              />
              <ExcelButton onClick={exportToExcel} />
            </div>
          </div>
        </div>
      </div>
      {imeiserialNo.length > 0 && (
        <table
          className='table table-borderless table-responsive d-sm-block d-lg-table table-hover striped imeiTable'
          style={{ tableLayout: 'fixed' }}
        >
          <thead>
            <tr className='imeiTableMainTitle'>
              <th style={{ width: '10px' }}>#</th>
              <th style={{ width: '130px' }}>Satır No</th>
              <th style={{ width: '200px' }}>Fatura No</th>
              <th>Fatura Tarihi</th>
              <th>Ödeme Methodu</th>
              <th>Toplam Fiyat</th>
              <th style={{ width: '145px' }}>Kur</th>
            </tr>
          </thead>
          {imeiserialNo.map((imei, index) => (
            <tbody>
              <tr
                style={{ cursor: 'pointer' }}
                data-toggle='collapse'
                data-target={`.multi-collapse${index}`}
                aria-controls='multiCollapseExample1'
                className='imeiTableDescription'
              >
                <td>
                  <div className='d-flex justify-content-center imeiRowNumber'>
                    <i className='icon-align-justify imeiTextIcon'></i>
                  </div>
                </td>
                <td>
                  <div className='d-flex justify-content-center imeiRowNumber'>
                    <div>{index + 1}</div>
                  </div>
                </td>
                <td>{imei.Data.InvoiceNo}</td>
                <td>{imei.Data.InvoiceDate}</td>
                <td>{imei.Data.PayMethod}</td>
                <td>{imei.Data.Contents && getTotalPrice(imei.Data.Contents)}</td>
                <td>{imei.Data.DocCurrency}</td>
              </tr>
              <tr className={`collapse multi-collapse${index} imeiTableTitleInImei`}>
                <td colSpan={2}>Doküman No</td>
                <td>Imei</td>
                <td>Ürün Kodu</td>
                <td>Ürün Açıklaması</td>
                <td style={{ width: '200px' }}>Ürün Fiyatı</td>
                <td style={{ width: '200px' }}>KDV</td>
              </tr>
              {imei.Data.Contents.map((content, i) => (
                <tr
                  style={{ cursor: 'pointer' }}
                  className={`collapse multi-collapse${index}`}
                  key={i}
                  onClick={() => setState({ showInvoice: true, invoiceDetail: imei, contents: content })}
                >
                  <td colSpan={2}>{content.DocNo}</td>
                  <td>{content.Imei}</td>
                  <td>{content.ProductCode}</td>
                  <td>{content.ProductDescription}</td>
                  <td>
                    {imei.Data.DocCurrency === 'TRY'
                      ? formatPrice(content.ProductPrice, 'TRY', 'symbol')
                      : imei.Data.DocCurrency === 'USD'
                      ? formatPrice(content.ProductPrice, 'USD', 'symbol')
                      : imei.Data.DocCurrency === 'EUR'
                      ? formatPrice(content.ProductPrice, 'EUR', 'symbol')
                      : formatPrice(content.ProductPrice, 'TRY', 'symbol')}
                  </td>
                  <td>
                    {imei.Data.DocCurrency === 'TRY'
                      ? formatPrice(content.KDV, 'TRY', 'symbol')
                      : imei.Data.DocCurrency === 'USD'
                      ? formatPrice(content.KDV, 'USD', 'symbol')
                      : imei.Data.DocCurrency === 'EUR'
                      ? formatPrice(content.KDV, 'EUR', 'symbol')
                      : formatPrice(content.KDV, 'TRY', 'symbol')}
                  </td>
                </tr>
              ))}
            </tbody>
          ))}
        </table>
      )}
      <IPModal
        show={state.showInvoice}
        onClose={() => {
          setState({ showInvoice: false })
        }}
        hideConfirm={true}
        width={1000}
      >
        <div id='order'>
          <div className='form-row' style={{ justifyContent: 'center', marginTop: '20px', marginBottom: '20px' }}>
            <div className='form-group'>
              <h5>
                <b>Fatura Detayı</b>
              </h5>
            </div>
          </div>
          <hr />
          <div
            style={{
              border: '1px solid #000000',
              backgroundColor: 'white',
              padding: '20px',
            }}
          >
            <div className='row'>
              <div className='col'>
                <h6>
                  <b>Fatura No: </b> {state.invoiceDetail?.Data?.InvoiceNo}
                </h6>
                <h6>
                  <b>Ödeme Şekli: </b>
                  {state.invoiceDetail?.Data?.PayMethod}
                </h6>
                <h6>
                  <b>Kur Tipi: </b>
                  {state.invoiceDetail?.Data?.DocCurrency}
                </h6>
              </div>
              <div className='col'>
                <h6>
                  <b>Tarih : </b>
                  {state.invoiceDetail?.Data?.InvoiceDate}
                </h6>
                <h6>
                  <b>Vade Tarihi: </b>
                  {state.invoiceDetail?.Data?.InvoiceDate}
                </h6>
                <h6>
                  <b>Kur: </b>
                </h6>
              </div>
              <div className='col'>
                <h6>
                  <b>MT : </b>
                  {}
                </h6>
                <h6>
                  <b>Sevk Tarihi: </b>
                  {state.invoiceDetail?.Data?.InvoiceDate}
                </h6>
                <h6>
                  <b>Sevk Biçimi: </b>
                </h6>
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <h6>
                  <b>Sevk Adresi: </b>
                </h6>
              </div>
            </div>
          </div>
          <hr />
          <table className='table table-borderless table-hover m-t-5'>
            <thead>
              <tr className='EInvoicetableDescription'>
                <td scope='col'>Ürün Kodu</td>
                <td scope='col'>Açıklama</td>
                <td scope='col'>Adet</td>
                <td scope='col'>Birim Tutar TL</td>
                <td scope='col'>Birim Tutar Döviz</td>
              </tr>
            </thead>
            <tbody>
              <td>{state.contents?.ProductCode}</td>
              <td>{state.contents?.ProductDescription}</td>
              <td>1</td>
              <td>
                {state.contents?.ProductPrice
                  ? formatPrice(state.contents?.ProductPrice, 'TRY', 'symbol')
                  : formatPrice(0, 'TRY', 'symbol')}
              </td>
              <td>
                {state.contents?.ProductPrice
                  ? state.invoiceDetail?.Data?.DocCurrency === 'TRY'
                    ? formatPrice(state.contents?.ProductPrice, 'TRY', 'symbol')
                    : state.invoiceDetail?.Data?.DocCurrency === 'USD'
                    ? formatPrice(state.contents?.ProductPrice, 'USD', 'symbol')
                    : state.invoiceDetail?.Data?.DocCurrency === 'EUR'
                    ? formatPrice(state.contents?.ProductPrice, 'EUR', 'symbol')
                    : formatPrice(state.contents?.ProductPrice, 'TRY', 'symbol')
                  : formatPrice(0, 'TRY', 'symbol')}
              </td>
            </tbody>
            <tbody>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <b>Toplam</b>
              </td>
              <td>
                {state.contents?.ProductPrice
                  ? state.invoiceDetail?.Data?.DocCurrency === 'TRY'
                    ? formatPrice(state.contents?.ProductPrice, 'TRY', 'symbol')
                    : state.invoiceDetail?.Data?.DocCurrency === 'USD'
                    ? formatPrice(state.contents?.ProductPrice, 'USD', 'symbol')
                    : state.invoiceDetail?.Data?.DocCurrency === 'EUR'
                    ? formatPrice(state.contents?.ProductPrice, 'EUR', 'symbol')
                    : formatPrice(state.contents?.ProductPrice, 'TRY', 'symbol')
                  : formatPrice(0, 'TRY', 'symbol')}
              </td>
            </tbody>
            <tbody>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <b>KDV</b>
              </td>
              <td>
                {state.contents?.KDV
                  ? state.invoiceDetail?.Data?.DocCurrency === 'TRY'
                    ? formatPrice(state.contents?.KDV, 'TRY', 'symbol')
                    : state.invoiceDetail?.Data?.DocCurrency === 'USD'
                    ? formatPrice(state.contents?.KDV, 'USD', 'symbol')
                    : state.invoiceDetail?.Data?.DocCurrency === 'EUR'
                    ? formatPrice(state.contents?.KDV, 'EUR', 'symbol')
                    : formatPrice(state.contents?.KDV, 'TRY', 'symbol')
                  : formatPrice(0, 'TRY', 'symbol')}
              </td>
            </tbody>
            <tbody>
              <td></td>
              <td></td>
              <td></td>
              <td>
                <b>G. Toplam</b>
              </td>
              <td>
                {state.contents?.ProductPrice && state.contents?.KDV
                  ? state.invoiceDetail?.Data?.DocCurrency === 'TRY'
                    ? formatPrice(state.contents?.ProductPrice + state.contents?.KDV, 'TRY', 'symbol')
                    : state.invoiceDetail?.Data?.DocCurrency === 'USD'
                    ? formatPrice(state.contents?.ProductPrice + state.contents?.KDV, 'USD', 'symbol')
                    : state.invoiceDetail?.Data?.DocCurrency === 'EUR'
                    ? formatPrice(state.contents?.ProductPrice + state.contents?.KDV, 'EUR', 'symbol')
                    : formatPrice(state.contents?.ProductPrice + state.contents?.KDV, 'TRY', 'symbol')
                  : formatPrice(0, 'TRY', 'symbol')}
              </td>
            </tbody>
            {/* {order.Items.map((item, index) => (
							<tbody key={index}>
								<td>{item.Warehouse}</td>
								<td>{item.ProductCode}</td>
								<td>{item.ProductName}</td>
								<td>{item.Quantity}</td>
								<td>{formatPrice(item.Price, "TRY", "symbol")}</td>
								<td>{formatPrice(item.Price, "USD", "symbol")}</td>
								<td>{formatPrice(item.TotalPrice, "TRY", "symbol")}</td>
								<td>{formatPrice(item.TotalPriceDolar, "USD", "symbol")}</td>
							</tbody>
						))}
						<tbody>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td><b>Toplam</b></td>
							<td>
								{
									state.orderDetails.Currency === 0
										? formatPrice(order.Total, "TRY", "symbol")
										: (state.orderDetails.Currency === 1
											? formatPrice(order.Total, "USD", "symbol")
											: formatPrice(order.Total, "EUR", "symbol")
										)
								}
							</td>
						</tbody>
						<tbody>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td><b>KDV</b></td>
							<td>
								{
									state.orderDetails.Currency === 0
										? formatPrice(order.TotalKdv, "TRY", "symbol")
										: (state.orderDetails.Currency === 1
											? formatPrice(order.TotalKdv, "USD", "symbol")
											: formatPrice(order.TotalKdv, "EUR", "symbol")
										)
								}
							</td> */}
            {/* </tbody>
					<tbody>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td><b>G. Toplam</b></td>
						<td>
							{
								state.orderDetails.Currency === 0
									? formatPrice(order.TotalPrice, "TRY", "symbol")
									: (state.orderDetails.Currency === 1
										? formatPrice(order.TotalPrice, "USD", "symbol")
										: formatPrice(order.TotalPrice, "EUR", "symbol")
									)
							}
						</td>
					</tbody> */}
          </table>
        </div>
      </IPModal>
    </div>
  )
}
