import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import IPButton from '../../../../components/IPButton'
import IPInput from '../../../../components/IPInput'
import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import './style.scss'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { GetGuaranteeDetailApi } from './services/getGuaranteeDetailApi'
import { IGuarantee, IWarrantyTracking } from './models/models'

const t = translate(Translations)
function Index(props: BaseComponentWithContextProps) {
  const [guarantee, setGuarantee] = useState<IGuarantee>()
  const [serialNumber, setSerialNumber] = useState('')
  const getGuaranteeDetail = async () => {
    if (serialNumber.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen seri numarası giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const data: IWarrantyTracking = {
      DealerCode: '',
      SeriNo: serialNumber,
      Brands: [],
    }
    const getGuaranteeDetailApi: GetGuaranteeDetailApi = new GetGuaranteeDetailApi({})
    getGuaranteeDetailApi
      .getGuaranteeDetail(data)
      .then((res: IGuarantee) => {
        setGuarantee(res)
      })
      .catch(e => {
        Swal.fire({
          icon: 'error',
          title: 'Seri numarası bulunamadı',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(() => {
          setGuarantee(undefined)
        })
      })
  }

  return (
    <div className='warrantyTracking ' style={{ backgroundColor: 'white' }}>
      <div className='container-fluid'>
        <div className='heading-text'>
          <h4>{t('pages.mySpecial.slider.warrantyTracking')}</h4>
        </div>
        <form>
          <div className='row align-items-center'>
            <div className='col-12 col-lg-7'>
              <div className='formEl'>
                <label>{t('pages.mySpecial.warrantyTracking.serialNumber')}</label>
                <IPInput
                  type='text'
                  onChange={e => {
                    setSerialNumber(e.target.value)
                  }}
                />
              </div>
            </div>
            <div className='col-12 col-lg-4  offset-lg-1'>
              <IPButton
                text={t('pages.mySpecial.warrantyTracking.search')}
                className='button mt-5'
                isRounded={false}
                onClick={() => getGuaranteeDetail()}
              />{' '}
            </div>
          </div>
          <div className='seperator my-4'></div>
          <p>{t('pages.mySpecial.warrantyTracking.description')}</p>
        </form>
        {guarantee && (
          <table
            id='datatable'
            className='table table-bordered table-hover dataTable '
            role='grid'
            aria-describedby='datatable_info'
          >
            <tr role='row' className='odd'>
              <th style={{ verticalAlign: 'inherit' }}>Ürün Kodu</th>
              <td style={{ width: 'max-content' }}>{guarantee.PRODUCT_CODE}</td>
            </tr>
            <tr role='row' className='odd'>
              <th style={{ verticalAlign: 'inherit' }}>Ürün Adı</th>
              <td style={{ width: 'max-content' }}>{guarantee.PRODUCT_NAME}</td>
            </tr>
            {guarantee.ZZKTN && (guarantee.ZZKTN === "214" || guarantee.ZZKTN === "215") ?
              (
                <tr role='row' className='odd'>
                  <th style={{ verticalAlign: 'inherit' }}>Garanti Durumu</th>
                  <td style={{ width: 'max-content' }}>Canon Eurasia Garantili</td>
                </tr>
              ) : (
                <>
                  <tr role='row' className='odd'>
                    <th style={{ verticalAlign: 'inherit' }}>Çıkış Tarihi</th>
                    <td style={{ width: 'max-content' }}>{guarantee.OKUTMA_TARIHI}</td>
                  </tr>
                  <tr role='row' className='odd'>
                    <th style={{ verticalAlign: 'inherit' }}>Fatura No</th>
                    <td style={{ width: 'max-content' }}>{guarantee.FAT_NO}</td>
                  </tr>
                  <tr role='row' className='odd'>
                    <th style={{ verticalAlign: 'inherit' }}>Garanti Bitiş</th>
                    <td style={{ width: 'max-content' }}>{guarantee.WARRANTY}</td>
                  </tr>
                </>)
            }

          </table>
        )}
      </div>
    </div>
  )
}

export default componentWithContext(Index)
