import { IProduct } from '../../ProductList/models/response/getProductListResponseModel'

export enum IBannerTypeEnum {
  LinkYok = 1,
  UruneLink = 2,
  ListeEkrani = 3,
  AyniPencereLink = 4,
  ÜrünAltTip = 5,
  YeniPencereLink = 6,
  YoutubeVideo = 7,
  VimeoVideo = 8,
  Video = 9,
}
export enum ILocationEnum {
  Login = 1,
  AnaSayfaSolUst = 2,
  AnaSayfaSolAlt = 3,
  AnaSayfaSag = 4,
  SayacAnaSayfaUst = 5,
  SayacAnaSayfaAlt = 6,
  BanaOzel = 7,
  Modal = 8,
  OrtaBanner = 9,
  AltBanner = 10,
}
export enum ICounterLocationEnum {
  Slider = 1,
  AltAlta = 2,
}

export interface IBanner {
  Id: number
  Title: string
  Description: string
  Location: ILocationEnum
  CompanyList: number[]
  Category: 0
  Order: 0
  Link: string
  Active: boolean
  StartDate: Date
  EndDate: Date
  CariTypeCode: []
  CariSubTypeCode: []
  Image: string
  ImageUrl: string
  MobilImage: string
  CounterLocation: ICounterLocationEnum
  DontShowToUsers: string
  ProductCode: []
  ProductAddress: string
  BannerType: IBannerTypeEnum
  VideoId: string
  IsHomepage: boolean
}
export interface IBrandList {
  Id: number
  Image: string
  ImageUrl: string
  Name: string
  Companies: []
}

export interface IBrandDetail {
  List: IBrandList[]
  Count: number
}

export interface IHomepageProducts {
  Id: number
  Name: string
  Image: string
  SapCode: string
}

export interface IProductList {
  MostFavourites: IProducts
  NewProducts: IProducts
  OpportunitMarket: IProducts
  PromotionalProducts: IProducts
  RecentVisits: IProducts
}

export interface IProducts {
  Count: number
  Products: IProduct[]
}

export interface ICampaignsProducts {
  PageSize: number
  PageNumber: number
  CampaignId: number
  Company: number
}

export interface IBestSellers {
  CategoryName: string
  Product: IProduct
}
