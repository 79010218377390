import React, { useMemo, useState, useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import InputCampaign from '../../../../../components/InputCampaign'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Table from '../../../../../components/Table'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import { INeverUntouchedOrdersRequest, INeverUntouchedOrdersResponse } from '../models/models'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import { NeverUntouchedOrdersApi } from '../services/neverUntouchedOrdersApi'
import Swal from 'sweetalert2'

interface INoneUntouchedOrdersListState {
  company: ISelectOption | null,
  orderNumber: string
  isTouched: boolean
}
function NoneUntouchedOrdersList(props: BaseComponentWithContextProps) {
  const getInitialState = (): INoneUntouchedOrdersListState => {
    return {
      company: null,
      orderNumber: "",
      isTouched: false
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<INoneUntouchedOrdersListState>(intialState)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const t = translate(Translations)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [orders, setOrders] = useState<INeverUntouchedOrdersResponse[]>([])
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  useEffect(() => {
    getCompanies()
    neverUntouchedOrders()
  }, [])

  const neverUntouchedOrders = async () => {
    const req: INeverUntouchedOrdersRequest = {
      Company: state.company ? String(state.company.value) : "",
      OrderNumber: state.orderNumber,
      StartDate: startDate && getDateByTimezoneOffset(startDate) as any,
      EndDate: endDate && getDateByTimezoneOffset(endDate) as any,
      IsTouched: state.isTouched
    }
    props.showLoading();
    const neverUntouchedOrdersApi: NeverUntouchedOrdersApi = new NeverUntouchedOrdersApi({})
    await neverUntouchedOrdersApi
      .neverUntouchedOrders(req)
      .then(result => {
        if (result) {
          setOrders(result)
        }
        props.hideLoading();
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    setCompanies(company)
  }
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Değme Durumu',
        accessor: 'col1',
      },
      {
        Header: 'Satır Sayaç',
        accessor: 'col2',
      },
      {
        Header: 'Sipariş Sayaç',
        accessor: 'col3',
      },
      {
        Header: 'Pazar',
        accessor: 'col4',
      },
      {
        Header: 'Şirket',
        accessor: 'col5',
      },
      {
        Header: 'Cari Kodu',
        accessor: 'col6',
      },
      {
        Header: 'Cari Adı',
        accessor: 'col7',
      },
      {
        Header: 'Sipariş No',
        accessor: 'col8',
      },
      {
        Header: 'Sipariş Tarihi',
        accessor: 'col9',
      },
      {
        Header: 'Oluşturma Tarihi',
        accessor: 'col10',
      },
      {
        Header: 'Durumu',
        accessor: 'col11',
      },
      {
        Header: 'Onaya Gönder',
        accessor: 'col12',
      },
      {
        Header: 'Ödeme Şekli',
        accessor: 'col13',
      },
      {
        Header: 'Ödeme Şekli Açıklama',
        accessor: 'col14',
      },
      {
        Header: 'Fatura No',
        accessor: 'col15',
      },
      {
        Header: 'Net Değer',
        accessor: 'col16',
      },
      {
        Header: 'Para Birimi',
        accessor: 'col17',
      },
      {
        Header: 'Net Değer TRY(Sip. Fiy. Kurundan)',
        accessor: 'col18',
      },
      {
        Header: 'Net Değer TRY(Sip. Muh. Kurundan)',
        accessor: 'col19',
      },
      {
        Header: 'Net Değer TRY(Gün. Kurundan)',
        accessor: 'col20',
      },
      {
        Header: 'Kur Durumu',
        accessor: 'col21',
      },
      {
        Header: 'Net Değer USD',
        accessor: 'col22',
      },
      {
        Header: 'TRY > USD Kuru',
        accessor: 'col23',
      }
    ],
    []
  )
  let orderNumber = "";
  let i = 1;
  const tableData = useMemo(
    () =>
      orders.map((item, index) => {
        if (index === 0) {
          orderNumber = "";
          i = 1;
        }
        if (orderNumber.length > 0 && orderNumber !== item.OrderNumber) {
          i++
        }
        orderNumber = item.OrderNumber;
        const data = {
          col1: <div dangerouslySetInnerHTML={{ __html: item.EL_DEGME_DURUMU }}></div>,
          col2: index + 1,
          col3: i,
          col4: item.CompanyName,
          col5: item.CompanyCode,
          col6: item.DealerCode.replace("0000", ""),
          col7: item.KUN_EXP,
          col8: item.OrderNumber,
          col9: item.ERDAT,
          col10: item.AUDAT,
          col11: item.Status,
          col12: item.TICKEDSENDAPPROVE,
          col13: item.PAYTYPE,
          col14: item.PAYTYPEEXP,
          col15: item.InvoiceNumber,
          col16: item.NetWorth.toFixed(2),
          col17: item.Currency,
          col18: item.NetWorthTLFiy.toFixed(2),
          col19: item.NetWorthTLMuh.toFixed(2),
          col20: item.NetWorthTL.toFixed(2),
          col21: item.KURDURUM,
          col22: item.NetWorthUSD.toFixed(2),
          col23: item.KUR_TRY2USD.toFixed(2)
        }

        return data
      }),
    [orders]
  )

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <span className='h4'>
          {t('pages.admin.dealerAndOrderReport.noneUntouchedOrdersList.noneUntouchedOrdersList.header')}
        </span>
      </div>
      <div className='card-body'>
        <form className='formbanner'>
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <InputCampaign
                type='date'
                id='StartDate'
                name='StartDate'
                label={'Başlangıç Tarihi'}
                value={toDateTimeLocal(startDate)}
                onChange={e => getDate(e.target.value, "Start")}
                isActive
              />
            </div>
            <div className='col-md-3 mb-3'>
              <InputCampaign
                type='date'
                id='EndDate'
                name='EndDate'
                label={'Bitiş Tarihi'}
                value={toDateTimeLocal(endDate)}
                onChange={e => getDate(e.target.value, "End")}
                isActive
              />
            </div>
            <div className='col-md-2 mb-3'>
              <IPInput type='text' className='form-control' id='SiparisKodu' placeholder='Sipariş Kodu' onChange={(e) => { setState({ orderNumber: e.target.value }) }} />
            </div>
            <div className='col-md-2 mb-3'>
              <IPSelectBox
                isClearable
                options={
                  companies &&
                  companies.filter((com: ICompany) => { return com.Name !== "Index Group" }).map((item: ICompany) => ({
                    label: item.Name,
                    value: `${item.Code}`,
                  }))
                }
                value={state.company}
                placeholder="Şirket Seçiniz"
                onChangeSingle={option => setState({ company: option })}
              />
            </div>
            <div className='col-md-2 mb-3'>
              <IPCheckbox
                label={t(
                  'pages.admin.dealerAndOrderReport.noneUntouchedOrdersList.noneUntouchedOrdersList.veryUntouched'
                )}
                checked={state.isTouched}
                onCheckedChange={isChecked => setState({ isTouched: isChecked })}
              />
            </div>
            <div className='col-md-10 mb-3'></div>
            <div className='col-md-2 mb-3'>
              <IPButton
                type='button'
                className='btn btn-success'
                style={{ width: "25%" }}
                text={t('pages.admin.dealerAndOrderReport.handcraftedOrdersList.handcraftedOrdersList.filtre')}
                onClick={neverUntouchedOrders}
              />
            </div>
          </div>
        </form>
        <div className='m-4'>
          <Table columns={tableColumns} data={tableData} currentPage={0} headerName={t('pages.admin.dealerAndOrderReport.noneUntouchedOrdersList.noneUntouchedOrdersList.header')} columnCount={23} />
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(NoneUntouchedOrdersList)
