import './../UserStyle.scss'
import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import IPInput from '../../../../components/IPInput'
import IPButton from '../../../../components/IPButton'
import { ListDefinedCompanyApi } from './services/listDefinedCompanyApi'
import { IDefinedCompany } from './models/models'
import { useCustomReducer } from '../../../../base/customHooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { ISelectOption } from '../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import IPSelectBox from '../../../../components/IPSelectBox'

interface DefinedState {
  definedCompanies: IDefinedCompany
  tableDisable: boolean
  accountCodeOrTaxNumber: string
}

function Index(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): DefinedState => {
    return {
      definedCompanies: Object.assign({}),
      tableDisable: false,
      accountCodeOrTaxNumber: '',
    }
  }
  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<DefinedState>(initialState)
  const statusOptions: ISelectOption[] = [
    {
      value: 1,
      label: t('pages.admin.userOperation.userCrud.addUser.active'),
    },
    {
      value: 2,
      label: t('pages.admin.userOperation.userCrud.addUser.passive'),
    },
    {
      value: 3,
      label: t('pages.admin.userOperation.userCrud.addUser.deleted'),
    },
    {
      value: 4,
      label: t('pages.admin.userOperation.userCrud.addUser.waitingForApproval'),
    },
    { value: 5, label: 'Bloke' },
  ]

  const [status, setStatus] = useState<any>(null)
  const navigate: any = useNavigate()
  const filterDefined = async (accountCodeOrTaxNumber: string, status: number) => {
    props.showLoading()
    const listDefinedCompanyApi = new ListDefinedCompanyApi({})
    let code = accountCodeOrTaxNumber
    if (accountCodeOrTaxNumber.length > 5) code = accountCodeOrTaxNumber.padStart(10, '0')
    navigate(`/admin/UserOperation/DefinedCompany?DealerCode=${code}&Status=${status}`)
    const listDefined = await listDefinedCompanyApi.listDefinedCompany(code).catch((err: any) => {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    }
    )
    if (listDefined) {
      if (status != 0)
        listDefined.DealerUsers = listDefined.DealerUsers.filter(a => a.Status === status)
      props.hideLoading()
      setState({
        tableDisable: true,
        definedCompanies: listDefined,
        accountCodeOrTaxNumber: code,
      })
    }
    else {
      props.hideLoading()
    }
  }

  const [searchParams, setSearchParams] = useSearchParams()

  const dealerCodeForFilter = searchParams.get('DealerCode')
  const statusFilter = searchParams.get('Status')

  useEffect(() => {
    setStatus(statusOptions.find(a => a.value == Number(statusFilter)))
    if (dealerCodeForFilter !== null && dealerCodeForFilter !== undefined && dealerCodeForFilter !== "") {
      filterDefined(dealerCodeForFilter.toString(), statusFilter ? Number(statusFilter) : 0)
    }
  }, [])



  const handleUserEdit = async userId => {
    navigate(`/admin/UserOperation/AddUser`, { state: { userProp: userId, type: 'Defined', url: window.location.href } })
  }
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              <h5>Tanımlı Şirket ve Kullanıcı Sorgulama</h5>
            </span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-4'>
                  <label htmlFor='location' className='form-label'>
                    {t('pages.admin.userOperation.definedCompany.accountCodeOrTaxNumber')}
                  </label>
                  <IPInput
                    type='text'
                    className='form-control'
                    id='location'
                    value={state.accountCodeOrTaxNumber}
                    onChange={e => {
                      setState({ accountCodeOrTaxNumber: e.target.value })
                    }}
                  />
                </div>
                <div className='col-lg-4'>
                  <label htmlFor='location' className='form-label'>
                    Durum
                  </label>
                  <IPSelectBox
                    isClearable
                    className='Status'
                    name='Status'
                    onChangeSingle={(option: ISelectOption) => {
                      setStatus(option)
                    }}
                    options={statusOptions}
                    value={status}
                    placeholder="Durum Seçiniz..."
                  />
                </div>
                <div className='col-lg-2'>
                  <div className='button buttonSuz'>
                    <IPButton
                      type='button'
                      className='btn btn-success'
                      text={t('pages.admin.userOperation.definedCompany.strain')}
                      onClick={() => filterDefined(state.accountCodeOrTaxNumber, status ? status.value : 0)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <br />
            {state.tableDisable ? (
              <div className='row'>
                <div className='col-lg-12'>
                  <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <table
                          id='datatable'
                          className='table table-bordered table-hover dataTable'
                          role='grid'
                          aria-describedby='datatable_info'
                        // disabled ={}
                        >
                          <thead>
                            <tr role='row' className='odd'>
                              <th>
                                {state.definedCompanies.DealerCode} | {state.definedCompanies.DealerSAPCode}{' '}
                                {state.definedCompanies.DealerName}
                              </th>
                            </tr>
                            <tr role='row' className='odd'>
                              <th>{t('pages.admin.userOperation.definedCompany.sapCompanyCode')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state.definedCompanies.DealerCompamySAPCodes &&
                              state.definedCompanies.DealerCompamySAPCodes.map((item: any, index) => (
                                <tr role='row' className='odd' key={index}>
                                  <td>{item}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                      <div className='col-sm-4'>
                        <table
                          id='datatable'
                          className='table table-bordered table-hover dataTable'
                          role='grid'
                          aria-describedby='datatable_info'
                        >
                          <thead>
                            <tr role='row' className='odd'>
                              {/* <th>
                                PAZAR <br />Şirket Kodu
                              </th> */}
                              <th>Kullanıcı Kodu</th>
                            </tr>
                          </thead>
                          <tbody>
                            {state.definedCompanies.DealerUsers &&
                              state.definedCompanies.DealerUsers.map((item: any) => (
                                <tr role='row' className='odd' key={item.Id}>
                                  <td>
                                    <a onClick={() => handleUserEdit(item.Id)} style={{ cursor: 'pointer' }}>
                                      {item.UserName}
                                    </a>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Index)