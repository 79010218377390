import { IRequesterInfo } from '../../../../base/models/proxyModels'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { DealerForProductsFilterRequest, DealerForProductsFilterResponse } from './models'

export class ProductClickStatisticsService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GA4Reporting/DealerForProducts',
      requesterInfo,
    })
  }

  public async DealerForProductsFilter(
    req: DealerForProductsFilterRequest
  ): Promise<DealerForProductsFilterResponse[]> {
    return await this.postAsync<DealerForProductsFilterResponse[]>(req)
  }
}
