import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useCustomReducer } from '../../../../../base/customHooks'
import { translate } from '../../../../../base/localization'
import { BrandModul } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import IPImage from '../../../../../components/IPImage'
import Modal from '../../../../../components/IPModal/IPModal'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Table from '../../../../../components/Table'
import Translations from '../../../../../translations'
import { getBrandApi, getBrandDetailApi } from '../../../components/api'
import { IBrand, IBrandDetail, IDocuments, ILink } from '../../../components/models/models'
import { DeleteBrandDetailApi } from '../services/deleteBrandDetailApi'

interface IBrandInformationState {
  selectedMarka?: ISelectOption | null
  selectedModul?: ISelectOption | null
  brandList?: IBrand[]
  brandDetailList: IBrandDetail[]
  tableView: boolean
  Id: number | null
  moduleId: number | null
  link: boolean
  document: boolean
  linkData: ILink[]
  documentData: IDocuments[]
  selectedModul1To15: ISelectOption | null
  selectedModul16And17: ISelectOption | null
}

function BrandInformation() {
  const t = translate(Translations)
  const getInitialState = (): IBrandInformationState => {
    return {
      tableView: false,
      Id: 0,
      moduleId: 0,
      link: false,
      document: false,
      linkData: [],
      documentData: [],
      selectedModul1To15: null,
      selectedModul16And17: null,
      brandDetailList: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IBrandInformationState>(intialState)

  useEffect(() => {
    getBrand()
    getBrandList(state.Id, state.moduleId)
  }, [])

  const getBrand = async () => {
    const data: any[] = await getBrandApi()
    setState({ brandList: data })
  }

  const getBrandList = async (id, moduleId) => {
    const data = await getBrandDetailApi(id, moduleId)
    setState({ brandDetailList: data })
  }

  const IModulOption: ISelectOption[] = BrandModul()

  const options1To15 = IModulOption.filter(option => Number(option.value) <= 15)
  const options16And17 = IModulOption.filter(option => Number(option.value) > 15)

  const navigate: any = useNavigate()
  const addBrand = async (brand, modul) => {
    navigate('/admin/BrandPageOperation/BrandUpdate', {
      state: { brand: brand, modul: modul, data: 'data' },
    })
  }

  const deleteBrandDetail = async (brandDetailId: number, moduleId: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Marka Detaili silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteBrandDetailApi = new DeleteBrandDetailApi({})
        const isDeleted = await deleteBrandDetailApi.deleteBrand(brandDetailId, moduleId)
        if (isDeleted) {
          Swal.fire({
            icon: 'success',
            title: 'Marka Detail Silindi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async r => {
            if (r.isConfirmed) {
              getBrandList(state.Id, state.moduleId)
            }
          })
        }
      }
    })
  }

  const updateBrandDetail = async data => {
    data.flag = 'Güncelle'
    navigate('/admin/BrandPageOperation/BrandUpdate', {
      state: {
        brandDetailData: data,
        data: 'Güncelle',
      },
    })
  }

  //#region Table
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Aktif',
        accessor: 'col1',
      },
      {
        Header: 'Resimler',
        accessor: 'col2',
      },
      {
        Header: 'Marka',
        accessor: 'col3',
      },
      {
        Header: 'Modul',
        accessor: 'col4',
      },
      {
        Header: 'Açıklama',
        accessor: 'col5',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'col6',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'col7',
      },
      {
        Header: 'Linkler',
        accessor: 'col8',
      },
      {
        Header: 'İşlemler',
        accessor: 'col9',
      },
    ],
    []
  )

  const imageExtensions = ['.png', '.jpeg', '.jpg', '.gif', '.webp']

  const isImageFile = url => {
    return imageExtensions.some(ext => url?.toLocaleLowerCase('tr-TR').includes(ext))
  }

  const tableData = useMemo(
    () =>
      state.brandDetailList &&
      Array.isArray(state.brandDetailList) &&
      state.brandDetailList.map((item, index) => {
        const documentsExist = Array.isArray(item.Documents) && item.Documents.length > 0
        const data = {
          col1: (
            <label className='p-switch p-switch-sm switch-color-success'>
              <input type='checkbox' name='switch' checked={item.Enabled} />
              <span className='p-switch-style'></span>
            </label>
          ),
          col2:
            documentsExist && item.Documents.some(doc => isImageFile(doc.FileUrl)) ? (
              <div style={{ display: 'block', position: 'relative', width: '300px', height: '300px' }}>
                <Swiper
                  navigation={true}
                  keyboard={true}
                  pagination={{ clickable: true }}
                  modules={[Navigation, Mousewheel, Keyboard, Pagination]}
                  style={{ width: '100%' }}
                >
                  {item.Documents.filter(doc => isImageFile(doc.FileUrl)).map(doc => (
                    <SwiperSlide key={doc.Id}>
                      <IPImage src={doc.FileUrl} alt={doc.Name} style={{ width: 'auto' }} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : (
              <></>
            ),
          col3:
            state.brandList && Array.isArray(state.brandList)
              ? state.brandList.find(brand => brand.Id === item.BrandId)?.Name || ''
              : '',
          col4:
            IModulOption && Array.isArray(IModulOption)
              ? IModulOption.find(modul => modul.value === String(item.Type))?.label || ''
              : '',
          col5:
            item.Description.length > 10 ? (
              <div className='CellWithComment'>
                {item.Description.slice(0, 10) + '...'}
                <span className='CellComment'>{item.Description}</span>
              </div>
            ) : (
              item.Description
            ),
          col6: new Date(item.StartDate).toLocaleDateString('tr-TR'),
          col7: new Date(item.EndDate).toLocaleDateString('tr-TR'),
          col8: (
            <IPButton
              text='Linkler'
              onClick={() =>
                setState({
                  link: true,
                  linkData: item.Link,
                  documentData: item.Documents,
                })
              }
              style={{ width: '25px' }}
            />
          ),
          col9: (
            <>
              <a
                className='ml-2'
                data-toggle='tooltip'
                data-original-title={t('pages.admin.homeAndSiteOperation.bannersCrud.listBanner.edit')}
                onClick={() => {
                  updateBrandDetail(item)
                }}
                style={{ cursor: 'pointer' }}
              >
                <i className='icon-edit' style={{ color: 'green' }}></i>
              </a>
              <a
                className='ml-2'
                data-toggle='tooltip'
                data-original-title={t('pages.admin.homeAndSiteOperation.bannersCrud.listBanner.delete')}
                onClick={() => deleteBrandDetail(item.Id, item.Type)}
                style={{ cursor: 'pointer' }}
              >
                <i className='icon-trash-2' style={{ color: 'red' }}></i>
              </a>
            </>
          ),
        }
        return data
      }),
    [state.brandDetailList]
  )
  //#endregion

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>
              {t('pages.admin.brandPageOperation.brandInformation.brandInformation.header')}(Dosya Yükleme)
            </span>
            <IPButton
              type='button'
              className='btn btn-primary m-l-10'
              style={{ width: '25px', marginRight: '10px' }}
              text='Marka Ekle'
              onClick={() => addBrand(state.selectedMarka, state.selectedModul)}
            />
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label htmlFor='bayikodu' className='form-label'>
                    {t('pages.admin.brandPageOperation.brandInformation.brandInformation.brand')}
                  </label>
                  <IPSelectBox
                    isClearable
                    id='Brand'
                    name='Brand'
                    options={
                      state.brandList &&
                      state.brandList.map(brand => {
                        return { label: brand.Name, value: brand.Id }
                      })
                    }
                    onChangeSingle={option => {
                      if (option) {
                        setState({
                          selectedMarka: option,
                          Id: Number(option.value),
                        })
                      } else {
                        setState({
                          selectedMarka: null, // Reset selectedMarka
                          Id: null, // Reset Id to null
                        })
                      }
                    }}
                    value={state.selectedMarka}
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Modül
                  </label>
                  <IPSelectBox
                    isClearable
                    id='DealerContents1To15'
                    name='DealerContents1To15'
                    options={options1To15}
                    onChangeSingle={option => {
                      if (option) {
                        setState({
                          selectedModul: option,
                          moduleId: Number(option.value),
                          selectedModul1To15: option, // Set selected option for 1-15
                          selectedModul16And17: null, // Clear the 16-17 select box
                        })
                      } else {
                        setState({
                          selectedModul: null,
                          moduleId: null,
                          selectedModul1To15: null, // Clear the 1-15 select box
                          selectedModul16And17: state.selectedModul16And17,
                        })
                      }
                    }}
                    value={state.selectedModul1To15}
                  />
                </div>

                <div className='col-lg-3'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Despec Modül
                  </label>
                  <IPSelectBox
                    isClearable
                    id='DealerContents16And17'
                    name='DealerContents16And17'
                    options={options16And17}
                    onChangeSingle={option => {
                      if (option) {
                        setState({
                          selectedModul: option,
                          moduleId: Number(option.value),
                          selectedModul1To15: null, // Clear the 1-15 select box
                          selectedModul16And17: option, // Set selected option for 16-17
                        })
                      } else {
                        setState({
                          selectedModul: null,
                          moduleId: null,
                          selectedModul1To15: state.selectedModul1To15,
                          selectedModul16And17: null, // Clear the 16-17 select box
                        })
                      }
                    }}
                    value={state.selectedModul16And17}
                  />
                </div>
                <div className='col-lg-2 align-self-end'>
                  <br />
                  <div className='button'>
                    <IPButton
                      type='button'
                      className='btn btn-success'
                      text={t('pages.admin.brandPageOperation.brandInformation.brandInformation.filtre')}
                      onClick={() => getBrandList(state.Id, state.moduleId)}
                    />
                  </div>
                </div>
              </div>
            </form>
            <br />
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <Table columns={tableColumns} data={tableData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={`Linkler`}
        show={state.link ? state.link : false}
        onClose={() => setState({ link: false, linkData: [] })}
        hideConfirm
        width='auto'
      >
        <>
          <div className='form-row'>
            <table className='table'>
              <thead className='table-light '>
                <tr>
                  <th>Başlık</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {state.documentData &&
                  state.documentData.map(list => (
                    <tr role='row' className='odd' key={list.Id}>
                      <td>{list.Name}</td>
                      <td>
                        <a href={list.FileUrl} target='_blank' rel='noopener noreferrer'>
                          {list.FileUrl}
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </>
      </Modal>
    </div>
  )
}

export default BrandInformation
