import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { CommonPostResponse, IPostReport } from '../models/models'

export class UserOrderReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/user/userOrderReport',
      requesterInfo,
    })
  }
  public async postUser(request: IPostReport): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
