import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IForgetPasswordRequest } from '../models/models'

export class ForgetPasswordApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'user/forgetPassword',
      requesterInfo,
    })
  }

  public async forgetPassword(request: IForgetPasswordRequest): Promise<boolean> {
    return await this.postAsync<boolean>(request)
  }
}
