import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ISimpleRole } from '../models'

export class GetSecurityRoleApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/userPermission/GetSecurityRole',
      requesterInfo,
    })
  }

  public async getSecuritRole(): Promise<ISimpleRole[]> {
    return await this.getAsync<ISimpleRole[]>()
  }
}
