import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { CommonPostResponse } from '../../../components/models/models'
import { IAddOpportunity } from '../models/requestModel'

export class UpdateOpportunityApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/opportunitMarket/put',
      requesterInfo,
    })
  }

  public async putOpportunity(request: any): Promise<CommonPostResponse> {
    return await this.putAsync<CommonPostResponse>(undefined, request)
  }
}
