import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'

export interface OutboundClickRequestModel {
  BannerId: number
  BannerName: string
}

export class PostOutboundClicksApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'general/outboundLinkTrackClick',
      requesterInfo,
    })
  }

  public async postOutboundClicks(request: OutboundClickRequestModel): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
