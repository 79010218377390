import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import IPButton from '../../../../../components/IPButton'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { getDealerApi, getDealerTypeList, getSubTypeApi, getWarehousesApi } from '../../../components/api'
import { IDealer, IDealerType, IWarehouses } from '../../../components/models/models'
import InputCampaign from '../../../../../components/InputCampaign'
import { excelToJSON, getDateByTimezoneOffset, toDateTimeLocal, uuidv4 } from '../../../../../base/utils/dataHelper'
import IPInput from '../../../../../components/IPInput'
import "./style.scss"
import IPFile from '../../../../../components/IPFile'
import Swal from 'sweetalert2'
import CampaignSelect from '../../IyzicoCampaign/CampaignSelect'
import IPTextarea from '../../../../../components/IPTextarea'
import { IAddProductLimitRequest, IProductQuantity } from '../models/models'
import { GetProductCodeApi } from '../services/getProductCodeApi'
import _ from 'lodash'
import { AddProductLimitApi } from '../services/addProductLimitApi'
import { UpdateProductLimitApi } from '../services/updateProductLimitApi'

enum CariListType {
  CariCode,
  CariType,
  CariAltType,
}
interface IAddProductLimitationState {
  selectedDealerList: CariListType
  title: string
  active: boolean
  quantityBool: boolean
  maxQuantity: any
  maxQuantityBool: boolean
  warehouseCode?: ISelectOption
  warehouseCodeOption: ISelectOption[]
  CariTypeCode: ISelectOption[]
  CariSubTypeCode: ISelectOption[]
  CariCode: ISelectOption[]
  //dealerLists?: IDealer[]
  subTypes: string[]
  types: IDealerType[]
  notes: string
  first: boolean
  productCode: ISelectOption[]
  id: number
  history: boolean
}

function AddProductLimitation(props: BaseComponentWithContextProps) {
  const getInitialState = (): IAddProductLimitationState => {
    return {
      selectedDealerList: CariListType.CariCode,
      title: "Ekleme",
      active: false,
      maxQuantity: 0,
      quantityBool: false,
      maxQuantityBool: false,
      warehouseCodeOption: [],
      CariTypeCode: [],
      CariSubTypeCode: [],
      CariCode: [],
      //dealerLists: [],
      subTypes: [],
      types: [],
      notes: "",
      first: true,
      productCode: [],
      id: 0,
      history: false
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IAddProductLimitationState>(intialState)
  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())
  const [productData, setProductData] = useState<ISelectOption[]>([])
  //const [productCodeOptions, setProductCodeOptions] = useState<ISelectOption[]>([])
  const [productQuantityData, setProductQuantityData] = useState<IProductQuantity[]>([{
    StateId: uuidv4(),
    ProductCode: [],
    Quantity: undefined
  }])

  const getWarehouseCode = async () => {
    const warehouseCode: IWarehouses[] = await getWarehousesApi()
    const data: ISelectOption[] = []
    warehouseCode.filter((ware) => {
      return ware.WarehouseId !== 5
    }).map((house) => {
      return data.push({ value: house.Warehouse, label: house.Warehouse })
    })
    setState({ warehouseCodeOption: data })
  }

  useEffect(() => {
    getWarehouseCode()
    //getProductCode()
    //getDealer()
    getSubType()
    getDealerTypes()
  }, [])

  const enumFunction = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }

  const setDealerCodes = (dealerCodes, fieldValue) => {
    let list: any = []
    let codeData: any = []
    if (fieldValue === 'CariTypeCode') {
      const codeDatam = dealerCodes.map(item => ({
        label: String(item.DealerCode),
        value: String(item.DealerCode)
      }))


      codeDatam.forEach((codeItem) => {
        if (!state.CariCode?.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })
      const newData = state.CariCode ? [...state.CariCode, ...list] : list
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex((i) => i.label === item.label)
      })
      setState({ CariCode: uniqueList })
    }
    if (fieldValue === 'CariType') {
      dealerCodes.forEach(item => {
        const codes: any = state.subTypes?.find((sub: any) => {
          return sub === item.DealerCode
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push({ label: codes, value: codes })
      })
    }
    if (fieldValue === 'CariSubTypeCode') {
      dealerCodes.forEach(item => {
        const codes: any = state.types?.find((sub: IDealerType) => {
          return sub.MusteriGrubu === item.DealerCode
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push({ label: codes, value: codes })
      })
    }
    if (codeData.length === 0) {
      if (fieldValue === 'CariTypeCode')
        return
      if (fieldValue === 'CariType')
        return setState({ CariTypeCode: list })
      if (fieldValue === 'CariSubTypeCode')
        return setState({ CariSubTypeCode: list })
    }
    Swal.fire({
      icon: 'question',
      title: `${codeData.map(dt => {
        return dt
      })} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        return
      }
      if (fieldValue === 'CariTypeCode')
        return setState({ CariCode: list })
      if (fieldValue === 'CariType')
        return setState({ CariTypeCode: list })
      if (fieldValue === 'CariSubTypeCode')
        return setState({ CariSubTypeCode: list })
    })
  }

  const handleFiles = async (files: File[], type: 'DealerCode' | 'DealerSubType' | 'DealerType') => {
    const dealerCodes: any = await excelToJSON(files[0])

    if (type === 'DealerCode') {
      setDealerCodes(dealerCodes[0], 'CariTypeCode')
      return
    }
    if (type === 'DealerSubType') {
      setDealerCodes(dealerCodes[0], 'CariSubTypeCode')
      return
    }
    if (type === 'DealerType') {
      setDealerCodes(dealerCodes[0], 'CariType')
      return
    }
  }

  const onCreateOption = (option, updateFunc) => {
    updateFunc(prevState => {
      const newData = {
        value: option[option.length - 1].value,
        label: option[option.length - 1].label,
      }
      if (prevState && prevState.length > 0) {
        for (const state of prevState) {
          if (state.value === newData.value) return [...prevState]
          return [...prevState, newData]
        }
      }
      return [newData]
    })
  }


  const handleProductQuantity = (e, item, type: "ProductCode" | "Quantity") => {
    setProductQuantityData(prevState => {
      return prevState.map((prev: IProductQuantity) => {
        if (prev.StateId === item.StateId) {
          prev = {
            StateId: prev.StateId,
            ProductCode: type === "ProductCode" ? e : prev.ProductCode,
            Quantity: type === "Quantity" ? e.target.value : prev.Quantity,
          }
        }
        const uniqueList = prev.ProductCode.filter((item, index, self) => {
          return index === self.findIndex((i) => i.value === item.value)
        })
        prev.ProductCode = uniqueList
        return prev
      })
    })
  }

  // const getProductCode = async () => {
  //   const getProductCodeApi: GetProductCodeApi = new GetProductCodeApi({})
  //   await getProductCodeApi
  //     .getProductCode()
  //     .then(async result => {
  //       if (result) {
  //         const productCode = result.Entity.ProductsInfo.map(item => {
  //           return { label: item.ProductCode, value: item.ProductCode }
  //         })
  //         setProductCodeOptions(productCode)
  //         setState({ productCode })
  //       }
  //     })
  //     .catch((err: any) => {
  //       Swal.fire({
  //         icon: 'error',
  //         title: err.description,
  //         showConfirmButton: true,
  //         allowOutsideClick: false,
  //       })
  //     })
  // }

  const handleQuantityOnBlur = (e: any, item) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value)) || Number(e.target.value) < 0) {
      handleProductQuantity(1, item, "Quantity")
    } else {
      if (Number(e.target.value) < 0) {
        Swal.fire({
          icon: 'error',
          title: "0 dan küçük olmalıdır",
          showConfirmButton: true,
          allowOutsideClick: true,
        })
        handleProductQuantity(1, item, "Quantity")
        return
      }
      handleProductQuantity(e, item, "Quantity")
    }
  }

  const addProductQuantity = () => {
    setProductQuantityData([
      ...productQuantityData,
      {
        StateId: uuidv4(),
        ProductCode: [],
        Quantity: undefined
      },
    ])
  }

  const removeProductQuantity = (item) => {
    Swal.fire({
      icon: 'question',
      title: 'Silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        setProductQuantityData(productQuantityData.filter((pro) => { return pro.StateId !== item }))
        return;
      }
    })
  }
  let navigate = useNavigate()
  const routeLocation = useLocation()
  const addProductLimit = async () => {
    const ProductCodes: string[] = []
    const ProductCodesQuantity: number[] = []
    let i = 0
    if (state.maxQuantityBool) {
      if (!productData || productData.length === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen ürün kodu giriniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }
      productData.map((pro) => {
        ProductCodes.push(String(pro.value))
      })
    } else {
      productQuantityData.map((pro) => {
        if (pro.ProductCode.length === 0 || !pro.Quantity || pro.Quantity === 0) {
          return i++;
        }
        pro.ProductCode.map((prod) => {
          ProductCodesQuantity.push(Number(pro.Quantity))
          return ProductCodes.push(String(prod.value))
        })
      })
    }

    if (i > 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen ürün kodu ve adet eksik girmeyiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const DealerCodeList = state.CariCode.map((cari) => {
      return String(cari.value).padStart(10, '0');
    })
    const DealerTypeList = state.CariTypeCode.map((cari) => {
      return String(cari.value)
    })
    const DealerSubTypeList = state.CariSubTypeCode.map((cari) => {
      return String(cari.value)
    })
    props.showLoading()
    const req: IAddProductLimitRequest = {
      Id: state.id,
      ProductCodes,
      ProductCodesQuantity,
      MaxQuantity: state.maxQuantity ? state.maxQuantity : 0,
      WarehouseCode: state.warehouseCode ? String(state.warehouseCode?.value) : "",
      DealerCodeList,
      DealerTypeList,
      DealerSubTypeList,
      StartDate: getDateByTimezoneOffset(startDate) as any,
      EndDate: getDateByTimezoneOffset(endDate) as any,
      Notes: state.notes,
      Enabled: state.active
    }
    if (state.id > 0) {
      const updateProductLimitApi: UpdateProductLimitApi = new UpdateProductLimitApi({})

      await updateProductLimitApi
        .updateProductLimit(req)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: "Ürün adet sınırı güncellendi",
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(() => {
              navigate('/admin/ProductOperation/ProductQuantityLimitList')
            })
          }
          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
      return
    }
    const addProductLimitApi: AddProductLimitApi = new AddProductLimitApi({})

    await addProductLimitApi
      .addProductLimit(req)
      .then(async result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: "Ürün adet sınırı eklendi",
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(() => {
            navigate('/admin/ProductOperation/ProductQuantityLimitList')
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  // const getDealer = async () => {
  //   props.showLoading()
  //   await getDealerApi()
  //     .then(async result => {
  //       if (result) {
  //         setState({ dealerLists: result })
  //       }
  //       props.hideLoading()
  //     })
  //     .catch((err: any) => {
  //       Swal.fire({
  //         icon: 'error',
  //         title: err.description,
  //         showConfirmButton: true,
  //         allowOutsideClick: false,
  //       })
  //       props.hideLoading()
  //     })
  // }

  const getSubType = async () => {
    const subType: string[] = await getSubTypeApi()
    if (subType) setState({ subTypes: subType })
  }

  const getDealerTypes = async () => {
    const dealerType: IDealerType[] = await getDealerTypeList()
    if (dealerType) setState({ types: dealerType })
  }

  useEffect(() => {
    if (routeLocation.state) {
      if ((routeLocation.state as any)?.data === 'History')
        setState({ history: true })
      const data = _.clone((routeLocation.state as any)?.productLimitData)
      const CariCode: ISelectOption[] = []
      data.DealerCodeList.map((code) => {
        CariCode.push({
          value: `${code}`,
          label: code.replace('0000', ''),
        })
      }
      )

      const CariTypeCode: ISelectOption[] = []
      state.types && state.types.map((type) => {
        if (data.DealerTypeList.findIndex((code) => { return code === type.MusteriGrubu }) > -1) {
          CariTypeCode.push({ value: type.MusteriGrubu, label: type.MusteriGrubuAciklama })
        }
      })

      const CariSubTypeCode: ISelectOption[] = []
      state.subTypes && state.subTypes.map((type) => {
        if (data.DealerSubTypeList.findIndex((code) => { return code === type }) > -1) {
          CariSubTypeCode.push({ value: type, label: type })
        }
      })
      const dataProductQuantity: IProductQuantity[] = []
      const productCode: ISelectOption[] = []
      if (!data.MaxQuantity || data.MaxQuantity === 0) {
        data.ProductCodes.map((pro, index) => {
          dataProductQuantity.push({
            StateId: uuidv4(),
            ProductCode: [{
              value: pro, label: pro.replace("0000", "")
            }],
            Quantity: data.ProductCodesQuantity[index]
          })
        })
      } else {
        data.ProductCodes.map((pro) => {
          productCode.push({ value: pro, label: pro })
        })
      }
      setProductData(productCode)
      setProductQuantityData(dataProductQuantity)
      setState({
        active: data.Enabled,
        maxQuantity: data.MaxQuantity ? (data.MaxQuantity > 0 ? data.MaxQuantity : null) : null,
        maxQuantityBool: data.MaxQuantity ? (data.MaxQuantity > 0 ? true : false) : false,
        warehouseCode: data.WarehouseCode && { value: data.WarehouseCode, label: data.WarehouseCode },
        notes: data.Notes,
        CariCode,
        CariTypeCode,
        CariSubTypeCode,
        id: data.Id
      })
      setStartDate(new Date(data.StartDate))
      setEndDate(new Date(data.EndDate))
    }
  }, [routeLocation])

  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string, value: string }[] = []
      words.forEach((word: any) => {
        if (!state.CariCode?.some((item: any) => item.label === word)) {
          const newItem = { label: word, value: word }
          dealerList.push(newItem)
        }
      })

      const newData = state.CariCode ? [...state.CariCode, ...dealerList] : dealerList
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex((i) => i.label === item.label)
      })
      setState({ CariCode: uniqueList })
      setInputValue('');
    }
  };

  return (
    <div className='container addProductLimitation'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Ürün Adet Sınırı {state.title}</span>
            <Link to='/admin/ProductOperation/ProductQuantityLimitList'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Listeye Dön'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='card-body'>
            <form id='id1' className='formform' noValidate>
              <div className='etkin d-flex justify-content-end'>
                <label className='p-switch switch-color-success d-flex'>
                  <IPCheckbox name='Active' onChange={() => setState({ active: !state.active })} checked={state.active} label='Yayınla' disabled={state.history} />
                </label>
              </div>
              <div className='row'>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    disabled={state.history}
                    value={startDate && toDateTimeLocal(startDate)}
                    onChange={e => setStartDate(new Date(e.target.value))}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    disabled={state.history}
                    value={endDate && toDateTimeLocal(endDate)}
                    onChange={e => setEndDate(new Date(e.target.value))}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPInput
                    type='text'
                    value={state.maxQuantity}
                    placeholder='Max Adet'
                    disabled={state.history}
                    onChange={(e) => {
                      if (e.target.value.length > 0)
                        return setState({ maxQuantityBool: true, maxQuantity: +e.target.value })
                      setState({ maxQuantityBool: false, maxQuantity: null })
                    }} />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPSelectBox
                    isClearable
                    name='WarehouseCode'
                    placeholder="Depo Seçiniz"
                    isDisabled={state.history}
                    value={state.warehouseCode}
                    options={state.warehouseCodeOption}
                    onChangeSingle={(newVal: ISelectOption) => {
                      setState({ warehouseCode: newVal })
                    }}
                  />
                  <p style={{ color: "red" }}>*Boş bırakılırsa bütün depolar</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='cariFilterContainer'>
                    <div className='d-flex gap-6 flex-wrap'>
                      <div className='leftContainer'>
                        <div>
                          <a
                            className='campaignExcelBtn'
                            href='/assetWeb/docs/dealer-codes-example.xlsx'
                            target='_blank'
                          >
                            Örnek Excel
                          </a>
                          <div className='listBtnGroup'>
                            <IPButton
                              className={`listCariBtn ${enumFunction(
                                state.selectedDealerList,
                                CariListType.CariCode
                              )}`}
                              text={`Cari Kodu Listesi ${state.CariCode?.length > 0 ? '✅' : ''}`}
                              onClick={() =>
                                setState({
                                  selectedDealerList: CariListType.CariCode,
                                })
                              }
                            />
                            <IPButton
                              className={`listCariBtn ${enumFunction(
                                state.selectedDealerList,
                                CariListType.CariType
                              )}`}
                              text={`Cari Tipi Listesi ${state.CariTypeCode?.length > 0 ? '✅' : ''}`}
                              onClick={() =>
                                setState({
                                  selectedDealerList: CariListType.CariType,
                                })
                              }
                            />
                            <IPButton
                              className={`listCariBtn ${enumFunction(
                                state.selectedDealerList,
                                CariListType.CariAltType
                              )}`}
                              text={`Cari Alt Tipi Listesi ${state.CariSubTypeCode?.length > 0 ? '✅' : ''}`}
                              onClick={() =>
                                setState({
                                  selectedDealerList: CariListType.CariAltType,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className='p-0 m-0'>
                          <div
                            className={`cariFilterTextAreaWrap ${enumFunction(
                              state.selectedDealerList,
                              CariListType.CariCode
                            )}`}
                          >
                            <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                              <IPFile
                                id='FileInputDealerCode'
                                multiple={false}
                                handleFiles={e => handleFiles(e, 'DealerCode')}
                                removeAfterSelect={true}
                                className='campaignFileInput'
                                label="Excel'den Yükle"
                                disabled={state.history}
                              />
                            </div>
                            <CampaignSelect
                              placeholder={'Cari Kod Listesi'}
                              height={180}
                              // options={
                              //   state.dealerLists &&
                              //   state.dealerLists.map((com: IDealer) => {
                              //     return {
                              //       value: `${com.SapCode}`,
                              //       label: com.SapCode.replace('0000', ''),
                              //     }
                              //   })
                              // }
                              value={state.CariCode}
                              onChangeMulti={(options: ISelectOption[]) => {
                                if (!state.history)
                                  setState({ CariCode: options })
                              }}
                            />
                            <input
                              type="text"
                              placeholder="Cari kodu yazın"
                              value={inputValue}
                              onChange={handleInputChange}
                              onKeyPress={handleInputKeyPress}
                              disabled={state.history}
                            />
                          </div>

                          <div
                            className={`cariFilterTextAreaWrap ${enumFunction(
                              state.selectedDealerList,
                              CariListType.CariAltType
                            )}`}
                          >
                            <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                              <div className='btns'>
                                <IPFile
                                  id='FileInputDealerType'
                                  multiple={false}
                                  handleFiles={e => handleFiles(e, 'DealerSubType')}
                                  removeAfterSelect={true}
                                  className='campaignFileInput'
                                  label="Excel'den Yükle"
                                  disabled={state.history}
                                />
                              </div>
                            </div>
                            <CampaignSelect
                              placeholder={'Cari Alt Tipi Listesi'}
                              height={180}
                              options={state.subTypes?.map((item: any) => {
                                return { label: item, value: item }
                              })}
                              onChangeMulti={(options: ISelectOption[]) => {
                                if (!state.history)
                                  setState({ CariSubTypeCode: options })
                              }}
                              value={state.CariSubTypeCode}
                            />
                          </div>
                          <div
                            className={`cariFilterTextAreaWrap ${enumFunction(
                              state.selectedDealerList,
                              CariListType.CariType
                            )}`}
                          >
                            <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                              <div className='btns'>
                                <IPFile
                                  id='FileInputDealerType'
                                  multiple={false}
                                  handleFiles={e => handleFiles(e, 'DealerType')}
                                  removeAfterSelect={true}
                                  className='campaignFileInput'
                                  label="Excel'den Yükle"
                                  disabled={state.history}
                                />
                              </div>
                            </div>
                            <CampaignSelect
                              placeholder={'Cari Tipi Listesi'}
                              height={180}
                              options={state.types?.map((item: IDealerType) => {
                                return { label: item.MusteriGrubu, value: item.MusteriGrubuAciklama }
                              })}
                              onChangeMulti={(options: ISelectOption[]) => {
                                if (!state.history)
                                  setState({ CariTypeCode: options })
                              }}
                              value={state.CariTypeCode}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-8'>
                  <IPTextarea placeholder='Notlar' value={state.notes} onChange={(e) => { setState({ notes: e.target.value }) }} disabled={state.history} />
                  {
                    state.maxQuantityBool &&
                    <IPSelectBox
                      isMulti
                      isCreatableSelect
                      name='ProductCode'
                      isDisabled={state.history}
                      placeholder="Ürün Kodu"
                      onChangeMulti={(options: ISelectOption[]) => {
                        setProductData(options)
                      }}
                      //options={state.productCode}
                      value={productData}
                      onCreateOption={option => onCreateOption(option, setProductData)}
                    />
                  }
                </div>
              </div>
              {
                !state.maxQuantityBool && productQuantityData.map((pro) => (
                  <div className='row m-t-10' key={pro.StateId}>
                    <div className='col-6'>
                      <IPSelectBox
                        isMulti
                        isClearable
                        isCreatableSelect
                        isDisabled={state.history}
                        id='ProductCode'
                        name='ProductCode'
                        onChangeMulti={(options: ISelectOption[]) => {
                          handleProductQuantity(options, pro, "ProductCode")
                        }}
                        //options={productCodeOptions}
                        value={pro.ProductCode}
                        placeholder="Ürün Kodu"
                        onCreateOption={option => handleProductQuantity(option, pro, "ProductCode")}
                      />
                    </div>
                    <div className='col-2'>
                      <IPInput
                        name='Quantity'
                        type='text'
                        placeholder='Adet'
                        disabled={state.history}
                        value={pro.Quantity}
                        onChange={e => {
                          handleProductQuantity(e, pro, "Quantity")
                        }}
                        onBlur={e => {
                          if (e.target.value)
                            handleQuantityOnBlur(e, pro)
                        }}
                      />
                    </div>
                    {
                      productQuantityData.length > 1 &&
                      <div className='col-1'>
                        <IPButton
                          onClick={e => {
                            removeProductQuantity(pro.StateId)
                          }}
                          type='button'
                          className='btn btn-danger'
                          text='Sil'
                          disabled={state.history}
                        ></IPButton>
                      </div>
                    }
                    <div className='col-1' style={{ marginLeft: '20px' }}>
                      <IPButton
                        onClick={e => {
                          addProductQuantity()
                        }}
                        type='button'
                        className='btn btn-success'
                        disabled={state.history}
                        text='Ekle'
                      ></IPButton>
                    </div>
                  </div>
                ))
              }
              <div className='form-row mt-4' style={{ float: 'right', display: state.history ? 'none' : '' }}>
                <IPButton
                  text="Kaydet"
                  type='button'
                  className='btn btn-success w-25'
                  onClick={addProductLimit}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(AddProductLimitation)
