import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { IAuthorizedServiceProcedurs } from '../../MySpecial/SSH/SSHProcedures/models/models'
import { GetAuthorizedServicePointsListResponseModel } from '../../MySpecial/SSH/AuthorizedServicePoints/models/response/getAuthorizedServicePointsListResponseModel'

export class GetAuthorizedServiceMainApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'authorizedServiceMain/brandMainListAll',
      requesterInfo,
    })
  }

  public async getAuthorizedServiceMain(id: number): Promise<GetAuthorizedServicePointsListResponseModel[]> {
    this.props.url = `${this.props.url}/${id}`
    return await this.getAsync<GetAuthorizedServicePointsListResponseModel[]>()
  }
}
