import React from 'react'

const MailButton = ({ onClick, width = 25, height = 25, buttonText = 'Mail Gönder' }) => {
  return (
    <a onClick={onClick} className='excel-button ms-2 d-flex justify-content-center align-items-center'>
      <img
        src='/images/icons/epostadetay.svg'
        alt=''
        width={width}
        height={height}
        style={{ cursor: 'pointer', marginRight: '5px' }}
        title='Mail Gönder'
      />
      {buttonText}
    </a>
  )
}

export default MailButton
