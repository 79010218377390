import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'

export class NetLoginCheckApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'NetChannel/netLoginCheck',
      requesterInfo,
    })
  }

  public async netLoginCheck(): Promise<boolean> {
    return await this.getAsync<boolean>()
  }
}
