import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IXmlList } from '../models/models'

export class GetXmlInfoDetailsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/getXmlInfoDetails',
      requesterInfo,
    })
  }

  public async getXmlInfoDetails(): Promise<IXmlList> {
    return await this.getAsync<IXmlList>()
  }
}
