import React, { useEffect } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import IPButton from '../../../../../components/IPButton'
import { GetProductCodeApi } from '../../XMLDealer/services/getProductCodeApi'
import { ProductSubTypeListApi } from '../../XMLDealer/services/productSubTypeListApi'
import { GetBrandListWithIdApi } from '../../XMLDealer/services/getBrandListWithIdApi'
import { GetCategoryListApi } from '../../XMLDealer/services/getCategoryListApi'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { GetGeneralExceptionalsApi } from '../services/getGeneralExceptionalsApi'
import { IXmlData } from '../models/models'
import { InsertUpdateXmlGeneralExceptionalsApi } from '../services/insertUpdateXmlGeneralExceptionalsApiApi'

interface IXmlDealerState {
  productCodeIncluded: ISelectOption[]
  productCodeExcluded: ISelectOption[]
  quotaIncluded: ISelectOption[]
  quotaExcluded: ISelectOption[]
  productCodeData: ISelectOption[]
  quotaData: ISelectOption[]
  subTypeData: ISelectOption[]
  subTypeIncluded: ISelectOption[]
  subTypeExcluded: ISelectOption[]
  brandData: ISelectOption[]
  brandIncluded: ISelectOption[]
  brandExcluded: ISelectOption[]
  categoryData: ISelectOption[]
  categoryIncluded: ISelectOption[]
  categoryExcluded: ISelectOption[]
}

function XmlAllDealerUpdate(props: BaseComponentWithContextProps) {
  const getInitialState = (): IXmlDealerState => {
    return {
      productCodeIncluded: [],
      productCodeExcluded: [],
      quotaIncluded: [],
      quotaExcluded: [],
      productCodeData: [],
      quotaData: [],
      subTypeData: [],
      subTypeIncluded: [],
      subTypeExcluded: [],
      brandData: [],
      brandIncluded: [],
      brandExcluded: [],
      categoryData: [],
      categoryIncluded: [],
      categoryExcluded: []
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IXmlDealerState>(intialState)

  useEffect(() => {
    getProductCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      (state.productCodeData && state.productCodeData.length > 0) &&
      (state.quotaData && state.quotaData.length > 0)
      && (state.brandData && state.brandData.length > 0) && (state.subTypeData && state.subTypeData.length > 0)
      && (state.categoryData && state.categoryData.length > 0))
      getGeneralExceptionals()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.productCodeData, state.quotaData, state.subTypeData, state.categoryData, state.brandData])

  const getGeneralExceptionals = async () => {
    props.showLoading()
    const getGeneralExceptionalsApi: GetGeneralExceptionalsApi = new GetGeneralExceptionalsApi({})
    await getGeneralExceptionalsApi
      .getGeneralExceptionals()
      .then((result: IXmlData) => {
        if (result) {
          const productCodeIncluded = result.IncludeProductCode.map((code) => {
            return { label: code, value: code }
          })
          const productCodeExcluded = result.ExcludeProductCode.map((code) => {
            return { label: code, value: code }
          })
          const subTypeIncluded = result.IncludeProductSubTypes.map((code) => {
            return { label: code, value: code }
          })
          const subTypeExcluded = result.ExcludeProductSubTypes.map((code) => {
            return { label: code, value: code }
          })
          const quotaIncluded = result.IncludeQuotaNo.map((code) => {
            return { label: code, value: code }
          })
          const quotaExcluded = result.ExcludeQuotaNo.map((code) => {
            return { label: code, value: code }
          })
          const dataBrandCode: ISelectOption[] = []
          result.IncludeBrandID.map((code) => {
            state.brandData.map((brand) => {
              if (String(brand.value) === String(code))
                return dataBrandCode.push(brand)
              return
            })
          })
          const brandIncluded = dataBrandCode
          const dataBrand: ISelectOption[] = []
          result.ExcludeBrandID.map((code) => {
            state.brandData.map((brand) => {
              if (String(brand.value) === String(code))
                return dataBrand.push(brand)
              return
            })
          })
          const brandExcluded = dataBrand
          const dataCategory: ISelectOption[] = []
          result.IncludeCategoryCode.map((code) => {
            state.categoryData.map((brand) => {
              if (String(brand.value) === String(code))
                return dataCategory.push(brand)
              return
            })
          })
          const categoryIncluded = dataCategory
          const dataCategoryCode: ISelectOption[] = []
          result.ExcludeCategoryCode.map((code) => {
            state.categoryData.map((brand) => {
              if (String(brand.value) === String(code))
                return dataCategoryCode.push(brand)
              return
            })
          })
          const categoryExcluded = dataCategoryCode
          setState({
            productCodeIncluded,
            productCodeExcluded,
            subTypeIncluded,
            subTypeExcluded,
            quotaExcluded,
            quotaIncluded,
            brandIncluded,
            brandExcluded,
            categoryIncluded,
            categoryExcluded
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getProductCode = async () => {
    props.showLoading()
    const getProductCodeApi: GetProductCodeApi = new GetProductCodeApi({})

    await getProductCodeApi
      .getProductCode()
      .then(async res => {
        if (res) {
          const productSubTypeListApi: ProductSubTypeListApi = new ProductSubTypeListApi({})

          await productSubTypeListApi
            .productSubTypeList()
            .then(async result => {
              if (result) {
                const getBrandListWithIdApi: GetBrandListWithIdApi = new GetBrandListWithIdApi({})
                await getBrandListWithIdApi
                  .getBrandListWithId()
                  .then(async rs => {
                    if (rs) {
                      const getCategoryListApi: GetCategoryListApi = new GetCategoryListApi({})
                      await getCategoryListApi
                        .getCategoryList()
                        .then(async r => {
                          if (r) {
                            const categoryData = r.map((ca) => {
                              return { label: ca.Name, value: ca.SapCode }
                            })
                            const brandData = rs.Entity.map((en) => {
                              return { label: en.BrandCode, value: en.Id }
                            })
                            const productCodeData = res.Entity.ProductsInfo.map(item => {
                              return { label: item.ProductCode, value: item.ProductCode }
                            })
                            const quotaData = res.Entity.QuotaCode.map(item => {
                              return { label: item, value: item }
                            })
                            const subTypeData = result.map(item => {
                              return { label: item, value: item }
                            })
                            setState({
                              productCodeData,
                              quotaData,
                              subTypeData,
                              brandData,
                              categoryData
                            })
                          }
                          props.hideLoading()
                        }).catch((err: any) => {
                          Swal.fire({
                            icon: 'error',
                            title: err.description,
                            showConfirmButton: true,
                            allowOutsideClick: false,
                          })
                          props.hideLoading()
                        })
                    }
                  }).catch((err: any) => {
                    Swal.fire({
                      icon: 'error',
                      title: err.description,
                      showConfirmButton: true,
                      allowOutsideClick: false,
                    })
                    props.hideLoading()
                  })
              }
            }).catch((err: any) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              props.hideLoading()
            })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const updateXml = async () => {
    const insertUpdateXmlGeneralExceptionalsApi: InsertUpdateXmlGeneralExceptionalsApi = new InsertUpdateXmlGeneralExceptionalsApi({})
    const ExcludeBrandID = state.brandExcluded.map((exc) => { return String(exc.value) })
    const ExcludeCategoryCode = state.categoryExcluded.map((exc) => { return String(exc.value) })
    const ExcludeProductCode = state.productCodeExcluded.map((exc) => { return String(exc.value) })
    const ExcludeProductSubTypes = state.subTypeExcluded.map((exc) => { return String(exc.value) })
    const ExcludeQuotaNo = state.quotaExcluded.map((exc) => { return String(exc.value) })
    const IncludeBrandID = state.brandIncluded.map((exc) => { return String(exc.value) })
    const IncludeCategoryCode = state.categoryIncluded.map((exc) => { return String(exc.value) })
    const IncludeProductCode = state.productCodeIncluded.map((exc) => { return String(exc.value) })
    const IncludeProductSubTypes = state.subTypeIncluded.map((exc) => { return String(exc.value) })
    const IncludeQuotaNo = state.quotaIncluded.map((exc) => { return String(exc.value) })
    const req: IXmlData = {
      ExcludeBrandID,
      ExcludeCategoryCode,
      ExcludeProductCode,
      ExcludeProductSubTypes,
      ExcludeQuotaNo,
      IncludeBrandID,
      IncludeCategoryCode,
      IncludeProductCode,
      IncludeProductSubTypes,
      IncludeQuotaNo
    }
    props.showLoading()
    await insertUpdateXmlGeneralExceptionalsApi
      .insertUpdateXmlGeneralExceptionals(req)
      .then(async result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: `XML güncellendi.`,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>XML Tüm Bayilere Güncelleme</span>
          </div>
          <div className='card-body'>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Ürün Kodu Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='ProductCodeIncluded'
                  name='ProductCodeIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ productCodeIncluded: options })
                  }}
                  options={state.productCodeData}
                  value={state.productCodeIncluded}
                  placeholder="Ürün Kodu Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Ürün Kodu Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='ProductCodeExcluded'
                  name='ProductCodeExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ productCodeExcluded: options })
                  }}
                  options={state.productCodeData}
                  value={state.productCodeExcluded}
                  placeholder="Ürün Kodu Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Ürün Alt Tipi Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='SubTypeIncluded'
                  name='SubTypeIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ subTypeIncluded: options })
                  }}
                  options={state.subTypeData}
                  value={state.subTypeIncluded}
                  placeholder="Ürün Alt Tipi Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Ürün Alt Tipi Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='SubTypeExcluded'
                  name='SubTypeExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ subTypeExcluded: options })
                  }}
                  options={state.subTypeData}
                  value={state.subTypeExcluded}
                  placeholder="Ürün Alt Tipi Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Kota Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='QuotaIncluded'
                  name='QuotaIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ quotaIncluded: options })
                  }}
                  options={state.quotaData}
                  value={state.quotaIncluded}
                  placeholder="Kota Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Kota Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='QuotaExcluded'
                  name='QuotaExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ quotaExcluded: options })
                  }}
                  options={state.quotaData}
                  value={state.quotaExcluded}
                  placeholder="Kota Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Marka Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='BrandIncluded'
                  name='BrandIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ brandIncluded: options })
                  }}
                  options={state.brandData}
                  value={state.brandIncluded}
                  placeholder="Marka Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Marka Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='BrandExcluded'
                  name='BrandExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ brandExcluded: options })
                  }}
                  options={state.brandData}
                  value={state.brandExcluded}
                  placeholder="Marka Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-6'>
                <label>Kategori Dahil</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='CategoryIncluded'
                  name='CategoryIncluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ categoryIncluded: options })
                  }}
                  options={state.categoryData}
                  value={state.categoryIncluded}
                  placeholder="Kategori Dahil"
                />
              </div>
              <div className='col-md-6'>
                <label>Kategori Hariç</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  id='CategoryExcluded'
                  name='CategoryExcluded'
                  onChangeMulti={(options: ISelectOption[]) => {
                    setState({ categoryExcluded: options })
                  }}
                  options={state.categoryData}
                  value={state.categoryExcluded}
                  placeholder="Kategori Hariç"
                />
              </div>
            </div>
            <div className='row m-t-10'>
              <div className='col-md-10'></div>
              <div className='col-md-2'>
                <IPButton
                  type='button'
                  onClick={updateXml}
                  className='btn btn-success float-right'
                  text="Güncelle"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default componentWithContext(XmlAllDealerUpdate)
