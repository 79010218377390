import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IProduct } from '../models/requestModels'

export class GetProductByIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product',
      requesterInfo,
    })
  }

  public async getProductById(Id: number): Promise<IProduct> {
    this.props.url = `${this.props.url}/${Id}`
    return await this.getAsync<IProduct>()
  }
}
