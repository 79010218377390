import { calcPercent, isEarned } from './DealerSpecificPromotional'

const progressQuantityStyle = {
  position: 'absolute' as 'absolute',
  inset: '0',
  margin: 'auto',
  width: 'max-content',
  height: 'max-content',
  color: '#fff',
  fontSize: '18px',
  fontWeight: '600',
}

const progressQuantityMinStyle = {
  position: 'absolute' as 'absolute',
  left: '0',
  bottom: '0',
  color: '#252525',
  fontSize: '12px',
  fontWeight: '500',
}

const progressQuantityMaxStyle = {
  position: 'absolute' as 'absolute',
  right: '0',
  bottom: '0',
  color: '#252525',
  fontSize: '12px',
  fontWeight: '500',
}

export default function SlideItem({ campaign }) {
  const currentValue = campaign.IsQuantity ? campaign.CurrentQuantity : campaign.CurrentPrice
  const targetValue = campaign.IsQuantity ? campaign.TargetQuantity : campaign.TargetPrice
  const valueType = campaign?.IsQuantity ? 'Adet' : null

  return (
    <div className='w-100 pt-4 px-5 pb-5'>
      <div className='d-flex justify-content-between mb-5'>
        {/* Kampanya Kodu/Adı */}
        <div style={{ fontWeight: '600', fontStyle: 'italic' }}>{campaign.CampaignCode}</div>
        <div style={{ color: '#c51b3a' }}>
          <i className='icon-clock'></i> {new Date(campaign.StartDate).toLocaleDateString('tr-TR')}
        </div>
      </div>

      <div className='mb-4' style={{ fontWeight: '600', fontStyle: 'italic' }}>
        HEDEFLERİNİZ
      </div>

      <div className='position-relative' style={{ height: '65px' }}>
        <div className='progress position-relative' style={{ height: '40px' }}>
          <div
            className='progress-bar bg-danger'
            role='progressbar'
            style={{
              width: `${calcPercent(currentValue, targetValue)}%`,
              height: '100%',
            }}
            aria-valuenow={currentValue}
            aria-valuemin={1}
            aria-valuemax={targetValue}
          ></div>
          <div style={progressQuantityStyle}>
            {campaign?.IsQuantity ? currentValue : campaign?.CurrentPriceValue} {valueType}
          </div>
        </div>
        <div style={progressQuantityMinStyle}>
          {1} {valueType}
        </div>
        <div style={progressQuantityMaxStyle}>
          {campaign?.IsQuantity ? targetValue : campaign?.TargetPriceValue}
          {valueType}
        </div>
      </div>

      <div
        className='d-flex justify-content-center align-items-center'
        style={{
          fontSize: '36px',
          fontWeight: '500',
          color: '#c51b3a',
          marginTop: '2rem',
        }}
      >
        <i className='icon-target' style={{ position: 'relative', bottom: '2px', marginRight: '8px' }}></i>{' '}
        {campaign?.IsQuantity ? currentValue : campaign?.CurrentPriceValue} {valueType}
      </div>

      <div
        className='alert mt-4'
        style={{
          backgroundColor: '#f7dddd',
          // color: '#252525',
          color: '#d55e6c',
          fontWeight: '600',
          fontSize: '18px',
          textAlign: 'center',
          marginTop: '2rem',
        }}
      >
        {/* Açıklama ksımı */}
        {campaign.CampaignName}
      </div>

      <div className='d-flex justify-content-center mt-4'>
        <div
          style={{
            display: 'inline-block',
            backgroundColor: isEarned(campaign) ? '#56c856' : '#f51a34',
            color: '#fff',
            fontWeight: '500',
            padding: '0.2rem 0.5rem',
            borderRadius: '6px',
            margin: '0 auto',
            fontSize: '13px',
          }}
        >
          {campaign.ProductCode}
        </div>
      </div>

      <div className='d-flex justify-content-center mt-4'>
        <div
          style={{
            display: 'inline-block',
            backgroundColor: isEarned(campaign) ? '#56c856' : '#f51a34',
            color: '#fff',
            fontWeight: '500',
            padding: '0.2rem 0.5rem',
            borderRadius: '6px',
            margin: '0 auto',
            fontSize: '13px',
          }}
        >
          {isEarned(campaign) ? 'Ödül hak edildi.' : 'Henüz bu ödülü hak edemediniz.'}
        </div>
      </div>

      {/* <div className='alert mt-4' style={{ backgroundColor: '#f7dddd', color: '#d55e6c' }}>
          {1} - {targetValue} {selectedValueType} arası alımda ürün 170$ + KDV
        </div> */}
    </div>
  )
}
