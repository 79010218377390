import { IProduct } from '../models/response/getProductListResponseModel'

export interface ICompany {
  Name: string
  Image: string
  IyzipayMerchantKey: string
  Id: number
  Code: string
}

export interface ICategory {
  Id: number
  ParentId: number
  Companies: string
  IsMenu: boolean
  Link?: string
  Name: string
  LongName: string
  SapCodes?: string
  Order: number
  CategoryNumber: string
}

export interface IBrandDocument {
  Id: string
  Name: string
  FileName: string
}

export interface IBrandLink {
  Id: string
  Name: string
  Link: string
}

export interface IBrandDetail {
  Id: number
  Type: number
  BrandId: number
  Description: string
  Documents: IBrandDocument[]
  Link: IBrandLink[]
  StartDate: Date
  EndDate: Date
  Enabled: boolean
}

export interface IBrand {
  Id: number
  Name: string
  Image: string
  ImageUrl: string
}

export interface IGetProductsBySectionResponse {
  Count: number
  Products: IProduct[]
  ProductsCount: number
}

export const ProductListSpecialFilterType = {
  OpportunityMarket: 'opportunit-market',
  Campaigns: 'campaigns',
  MostFavorites: 'most-favourites',
  PromotionalProducts: 'promotional-products',
  RecentVisits: 'recent-visits',
  NewestProducts: 'new-products',
  BestSellers: 'best-sellers',
}

export interface IBestSellersCampaing {
  Id: number
  CategoryName: string
}

export interface IBestSellersData {
  Index: number
  Id: number
  CategoryName: string
}

export interface IAddListToBasketUI {
  DetailId: number
  ProductId: number
  Quantity: number
  IsOppurtunityStock: boolean
  OpportunitiyStockId: number
}
