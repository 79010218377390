import React, { useEffect } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPRadio from '../../../../../components/IPRadio'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { IPInputDataPattern } from '../../../../../components/IPInput/IPInput'
import Cards, { Focused } from 'react-credit-cards'
import { formatCreditCardNumber, formatCVC, formatExpirationDate } from '../../../../MyCard/creditCardUtils'
import InputMask from '../../../../../components/InputMask'
import { optionCSS } from 'react-select/dist/declarations/src/components/Option'
import { getDealerApi } from '../../../components/api'

interface IListBankState {
  selectedBank?: ISelectOption
  selectedMount?: ISelectOption
  selectedYears?: ISelectOption
  amount: string | number
  ccCVC: string | number
  ccExpiry: string | number
  ccFocus: Focused | undefined
  ccName: string
  ccNumber: string
  dealerLists?: ISelectOption[]
  selectedDealer?: string | number
}

function ManuelCredit(props: any) {
  const t = translate(Translations)
  const getInitialState = (): IListBankState => {
    return {
      amount: 0,
      ccCVC: '',
      ccExpiry: '',
      ccFocus: 'number',
      ccName: '',
      ccNumber: '',
      dealerLists: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListBankState>(intialState)

  const ISelectOption: ISelectOption[] = [
    { label: 'AKBANK', value: '2' },
    { label: 'GARANTİ', value: '3' },
    { label: 'HALK BANKASI', value: '4' },
    { label: 'HSBCBANK', value: '5' },
    { label: 'YAPI KREDİ', value: '6' },
    { label: 'ZİRAAT BANKASI', value: '7' },
    { label: 'Diğer Bankar', value: '8' },
    { label: 'AKBANK TİC.', value: '9' },
    { label: 'GARANTİ TİC.', value: '10' },
    { label: 'ZİRAAT BANKASI TİC.', value: '11' },
    { label: 'ZİRAAT BANKASI KUR.', value: '12' },
  ]

  const IMountOption: ISelectOption[] = [
    { label: '01', value: '2' },
    { label: '02', value: '3' },
    { label: '03', value: '4' },
    { label: '04', value: '5' },
    { label: '05', value: '6' },
    { label: '06', value: '7' },
    { label: '07', value: '8' },
    { label: '08', value: '9' },
    { label: '09', value: '10' },
    { label: '10', value: '11' },
    { label: '11', value: '12' },
    { label: '12', value: '13' },
  ]
  const IYearsOption: ISelectOption[] = [
    { label: '2022', value: '2' },
    { label: '2023', value: '3' },
    { label: '2024', value: '4' },
    { label: '2025', value: '5' },
    { label: '2026', value: '6' },
    { label: '2027', value: '7' },
    { label: '2028', value: '8' },
    { label: '2029', value: '9' },
    { label: '2030', value: '10' },
    { label: '2031', value: '11' },
    { label: '2032', value: '12' },
  ]

  const onBankChange = (value: ISelectOption) => {
    setState({ selectedBank: value })
  }
  const onMountChange = (value: ISelectOption) => {
    setState({ selectedMount: value })
  }
  const onYearsChange = (value: ISelectOption) => {
    setState({ selectedYears: value })
  }

  useEffect(() => {
    getDealer()
  }, [])

  const getDealer = async () => {
    const dealer: any[] = await getDealerApi()
    setState({ dealerLists: dealer })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>{t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.header')}</span>
          </div>

          <div className='card-body'>
            <form id='id1' className='formform'>
              <div className='row'>
                <div className='col-6'>
                  <div className='bayikod'>
                    <label htmlFor='location' className='form-label'>
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.step', ['1'])}{' '}
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.yourPaymentInformation')}
                    </label>
                    <div className='form-group col-md-12'>
                      <label htmlFor='location' className='form-label'>
                        {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.dealerCode')}
                      </label>
                      <div className='dealarCodeSelect'>
                        <IPSelectBox
                          isCreatableSelect
                          isSearchable
                          isClearable
                          name='DealersID'
                          options={state.dealerLists}
                          onChangeSingle={(option: ISelectOption | undefined) => {
                            setState({ selectedDealer: option?.value })
                          }}
                        />
                      </div>
                    </div>

                    <div className='form-group col-md-12'>
                      <label htmlFor='location' className='form-label'>
                        {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.amountYouWant')}
                      </label>
                      <div className='siparisKodu'>
                        {/* <IPInput type="text" className="form-control" id="SiparisKodu" /> */}
                        <IPInput
                          type='number'
                          name='number'
                          className='form-control'
                          placeholder='Tutar'
                          dataPattern={IPInputDataPattern.Numeric}
                          value={state.amount}
                          onChange={e => {
                            const { target } = e
                            if (+target.value < 0) {
                              target.value = '0'
                              setState({ amount: 0 })
                              return
                            }
                            setState({ amount: target.value })
                          }}
                        />
                      </div>
                    </div>
                    <div className='form-group col-md-12'>
                      <label htmlFor='location' className='form-label'>
                        {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.commissionType')}
                      </label>
                      <div className='row'>
                        <div className='siparisKodu col-4'>
                          <IPRadio
                            text={t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.excludingCommission')}
                            name='radio'
                          />
                        </div>
                        <div className='siparisKodu col-4'>
                          <IPRadio
                            text={t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.commissionIncluded')}
                            name='radio'
                          />
                        </div>
                        <div className='siparisKodu col-4'>
                          <IPRadio
                            text={t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.noCommission')}
                            name='radio'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='form-group col-md-6'></div>
                  </div>

                  <div className='form-group col-md-12'></div>
                  <label htmlFor='location' className='form-label'>
                    {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.step', ['2'])}{' '}
                    {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.selectBankAndInstallment')}
                  </label>

                  <div className='form-group col-md-12'>
                    <label htmlFor='location' className='form-label'>
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.commissionType')}
                    </label>
                    <div className='row'>
                      <div className='siparisKodu col-4'>
                        <IPRadio
                          text={t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.individualCard')}
                          name='radio'
                        />
                      </div>
                      <div className='siparisKodu col-4'>
                        <IPRadio
                          text={t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.commercialCard')}
                          name='radio'
                        />
                      </div>
                    </div>
                    <label htmlFor='location' className='form-label'>
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.bank')}
                    </label>
                    <IPSelectBox
                      isClearable
                      id='DealerContents'
                      name='DealerContents'
                      options={ISelectOption}
                      onChangeSingle={onBankChange}
                      value={state.selectedBank}
                    />
                    <br />
                    <label htmlFor='location' className='form-label' style={{ color: 'red' }}>
                      Diğer bankalar seçeneği kayıtlı olmayan bankalar için geçerlidir.
                      <br /> Bu seçenekle 3D işlem yapılamamaktadır.
                      <br /> YKB 300 TL altındaki işlemlerinize banka tarafından taksit uygulanmamaktadır.
                      <br />
                      Akbank'ta 100 TL ve altı çekimlerde taksitlendirme yapılmamaktadır.
                      <br />
                      Yukarıdaki tabloda bulunan oranlar KDV dahil toplam maliyetlerinizdir.
                    </label>
                  </div>
                </div>

                {/* <div className='col-6'>
                  <label htmlFor="location" className="form-label" >
                    {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.step', ["3"])}  {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.creditCard')}

                  </label>
                  <div className="form-group col-md-12">
                    <label htmlFor="location" className="form-label" >
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.nameSurName')}
                    </label>
                    <div className='siparisKodu'>
                      <IPInput type="text" className="form-control" id="SiparisKodu" />
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="location" className="form-label" >
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.cardNo')}
                    </label>
                    <div className='siparisKodu'>
                      <div className='row'>
                        <div className='col-md-3 pr-lg-0 px-sm-3 '>
                          <IPInput type="number" className="form-control" id="SiparisKodu" />
                        </div>
                        <div className='col-md-3 p-lg-0 px-sm-3'>
                          <IPInput type="number" className="form-control" id="SiparisKodu" />
                        </div>
                        <div className='col-md-3 p-lg-0 px-sm-3'>
                          <IPInput type="number" className="form-control" id="SiparisKodu" />
                        </div>
                        <div className='col-md-3 pl-lg-0 px-sm-3'>
                          <IPInput type="number" className="form-control" id="SiparisKodu" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="location" className="form-label" >
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.expirationDate')}
                    </label>
                    <div className='siparisKodu'>
                      <div className='row'>
                        <div className='col-6'>
                          <IPSelectBox isClearable id="DealerContents" name="DealerContents" options={IMountOption} onChangeSingle={onMountChange} value={state.selectedMount} placeholder={t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.month')} />
                        </div>
                        <div className='col-6'>
                          <IPSelectBox isClearable id="DealerContents" name="DealerContents" options={IYearsOption} onChangeSingle={onYearsChange} value={state.selectedYears} placeholder={t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.year')} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group col-md-12">
                    <label htmlFor="location" className="form-label" >
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.securityNo')}
                    </label>
                    <div className='siparisKodu'>
                      <div className='row'>
                        <div className='col-3'>
                          <IPInput type="number" className="form-control" id="SiparisKodu" />
                        </div>
                        <div className='col-6'>
                          <label htmlFor="location" className="form-label" >
                            {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.cvcNo')}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <label htmlFor="location" className="form-label" >
                      <br />
                      <div className='buttonsuz'>
                        <IPButton type="button" className="btn btn-success" text={t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.payment')} />
                      </div>
                    </label>
                  </div>
                </div> */}
                <div className='col-6'>
                  <label htmlFor='location' className='form-label'>
                    {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.step', ['3'])}{' '}
                    {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.creditCard')}
                  </label>
                  <div className='form-group col-md-12'>
                    <label htmlFor='location' className='form-label'>
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.cardNo')}
                    </label>
                    <IPInput
                      type='tel'
                      name='text'
                      className='form-control'
                      placeholder='Kart Numarası'
                      //pattern="[\d| ]{16,22}"
                      dataPattern={IPInputDataPattern.Numeric}
                      maxLength={16}
                      required
                      onChange={e => {
                        const { target } = e

                        target.value = formatCreditCardNumber(target.value)

                        setState({ ccNumber: target.value })
                      }}
                      onFocus={() => {
                        setState({ ccFocus: 'number' })
                      }}
                    />
                  </div>
                  <div className='form-group col-md-12'>
                    <label htmlFor='location' className='form-label'>
                      {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.nameSurName')}
                    </label>
                    <IPInput
                      type='text'
                      name='name'
                      className='form-control'
                      placeholder='Ad Soyad'
                      dataPattern={IPInputDataPattern.AlphabeticWithSpaces}
                      required
                      onChange={e => {
                        const { target } = e

                        setState({ ccName: target.value })
                      }}
                      onFocus={() => setState({ ccFocus: 'name' })}
                    />
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div className='form-group col-md-6'>
                      <label htmlFor='location' className='form-label'>
                        {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.expirationDate')}
                      </label>
                      <InputMask
                        mask={'99/99'}
                        type='tel'
                        name='expiry'
                        placeholder='Son Kullanma Tarihi'
                        //pattern="\d\d/\d\d"
                        //dataPattern={IPInputDataPattern.Numeric}
                        required
                        onChange={e => {
                          const { target } = e

                          target.value = formatExpirationDate(target.value)

                          setState({ ccExpiry: target.value })
                        }}
                        onFocus={() => setState({ ccFocus: 'expiry' })}
                      />
                    </div>
                    <div className='form-group col-md-6'>
                      <label htmlFor='location' className='form-label'>
                        {t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.cvcNo')}
                      </label>
                      <IPInput
                        type='tel'
                        name='cvc'
                        className='form-control'
                        placeholder='CVC'
                        //pattern="\d{3,4}"
                        dataPattern={IPInputDataPattern.Numeric}
                        maxLength={3}
                        required
                        onChange={e => {
                          const { target } = e

                          target.value = formatCVC(target.value)

                          setState({ ccCVC: target.value })
                        }}
                        onFocus={() => setState({ ccFocus: 'cvc' })}
                      />
                    </div>
                  </div>
                  <div className='d-flex justify-content-between mt-4'>
                    <div className='col-md-6'>
                      <Cards
                        cvc={state.ccCVC}
                        expiry={state.ccExpiry}
                        focused={state.ccFocus}
                        name={state.ccName}
                        number={state.ccNumber}
                        placeholders={{ name: 'AD SOYAD' }}
                      />
                    </div>
                    <div className='col-md-6 d-flex justify-content-end align-items-end'>
                      <label htmlFor='location' className='form-label'>
                        <br />
                        <div className='buttonsuz'>
                          <IPButton
                            type='button'
                            className='btn btn-danger'
                            text={t('pages.admin.mailOrderOperation.manuelCredit.manuelCredit.payment')}
                          />
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div />
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ManuelCredit
