import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ISapRequest, ISapResponse } from '../models/models'

export class FilterListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/sapRecord/filterList',
      requesterInfo,
    })
  }

  public async filterList(request: ISapRequest): Promise<ISapResponse[]> {
    return await this.postAsync<ISapResponse[]>(request)
  }
}
