import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IAddNewBasketRequest, IAddNewBasketResponse } from '../models/models'

export class AddNewBasketApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/addNewBasket',
      requesterInfo,
    })
  }

  public async addNewBasket(request: IAddNewBasketRequest): Promise<IAddNewBasketResponse> {
    return await this.postAsync<IAddNewBasketResponse>(request)
  }
}
