import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import IPButton from '../../../../../components/IPButton'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { getDealerApi, getDealerTypeList, getSubTypeApi, getWarehousesApi } from '../../../components/api'
import { IDealer, IDealerType, IWarehouses } from '../../../components/models/models'
import InputCampaign from '../../../../../components/InputCampaign'
import { excelToJSON, getDateByTimezoneOffset, toDateTimeLocal, uuidv4 } from '../../../../../base/utils/dataHelper'
import IPInput from '../../../../../components/IPInput'
import "./style.scss"
import IPFile from '../../../../../components/IPFile'
import Swal from 'sweetalert2'
import CampaignSelect from '../../IyzicoCampaign/CampaignSelect'
import IPTextarea from '../../../../../components/IPTextarea'
import { IColor, IProductColorRequest } from '../models/models'
import { GetProductColorApi } from '../services/getProductColorList'
import _ from 'lodash'
import { AddProductColorApi } from '../services/addProductColor'
import { GetColorApi } from '../services/getColors'
import { UpdateProductColorApi } from '../services/updateProductColor'

interface IAddProductColorState {
  productCode: string[]
  colorOption: ISelectOption[]
  companyOption: ISelectOption
  typeOption: ISelectOption
  colors: IColor[]
  companyListOptions: ISelectOption[]
  typeListOptions: ISelectOption[]
  history: boolean
}

function AddProductColor(props: BaseComponentWithContextProps) {
  const getInitialState = (): IAddProductColorState => {
    return {
      productCode: [],
      colorOption: [],
      history: false,
      companyOption: { label: "", value: "" },
      typeOption: { label: "", value: "" },
      colors: [],
      companyListOptions: [
        { label: 'Index', value: 1 },
        { label: 'Netex', value: 2 },
        { label: 'Despec', value: 3 },
        { label: 'Datagate', value: 4 },
      ],
      typeListOptions: [
        { label: 'Renk', value: 1 },
        { label: 'Ürün', value: 2 },
      ],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IAddProductColorState>(intialState)
  let navigate = useNavigate()

  const onCreateOption = (option, updateFunc) => {
    updateFunc(prevState => {
      const newData = {
        value: option[option.length - 1].value,
        label: option[option.length - 1].label,
      }
      if (prevState && prevState.length > 0) {
        for (const state of prevState) {
          if (state.value === newData.value) return [...prevState]
          return [...prevState, newData]
        }
      }
      return [newData]
    })
  }

  const getProductValues = (options: ISelectOption[]): any[] => {
    return options.map(option => option.value);
  }

  const getColorValues = (options: ISelectOption[]): any[] => {
    return options.map(option => option.value);
  }

  const getCompanyValues = (option: ISelectOption): any => {
    return option?.value ? option.value : 0;
  }

  const findCompany = (options: ISelectOption[], companyValue: number): ISelectOption | undefined => {
    return options.find(option => option.value === companyValue) as ISelectOption;
  };

  const addProductColor = async () => {
    props.showLoading()
    const req: IProductColorRequest = {
      ProductCodes: getProductValues(productCode),
      ColorIds: getColorValues(state.colorOption),
      Info: searchWord,
      Id: 0,
      Company: getCompanyValues(state.companyOption),
      Type: Number(state.typeOption.value)
    }

    const addProductColorApi: AddProductColorApi = new AddProductColorApi({})

    await addProductColorApi
      .addProductColor(req)
      .then(async result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: "Ürün yönlendirme eklendi",
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
        props.hideLoading()
        navigate('/admin/ProductOperation/ProductColorList')
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const updateProductColor = async () => {
    props.showLoading()
    const req: IProductColorRequest = {
      ProductCodes: getProductValues(productCode),
      ColorIds: getColorValues(state.colorOption),
      Info: searchWord,
      Id: listID,
      Company: getCompanyValues(state.companyOption),
      Type: Number(state.typeOption.value)
    }

    const updateProductColorApi: UpdateProductColorApi = new UpdateProductColorApi({})

    await updateProductColorApi
      .updateProductColor(req)
      .then(async result => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: "Ürün yönlendirme kaydı güncellendi",
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
        props.hideLoading()
        navigate('/admin/ProductOperation/ProductColorList')
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const [searchWord, setSearchWord] = useState("")
  const [listID, setListID] = useState(0)
  const [productCode, setProductCode] = useState<ISelectOption[]>([])
  const [colors, setColors] = useState<IColor[]>([])

  const getColors = async () => {
    const getColorApi: GetColorApi = new GetColorApi({})
    const data: IColor[] = []
    const color = await getColorApi.getColors()
    color
      .map(co => {
        data.push(co)
      })
    setColors(data)
  }

  useEffect(() => {
    getColors()
  }, [])

  const routeLocation = useLocation()

  useEffect(() => {
    const fetchData = async () => {
      if (routeLocation.state) {
        props.showLoading()
        const data = _.clone((routeLocation.state as any)?.linkData)
        const options = createOptions(data.ProductCodes)
        setProductCode(options)
        setSearchWord(data.Info)
        setListID(data.Id)
        setState({ companyOption: (findCompany(state.companyListOptions, data.Company)!), typeOption: state.typeListOptions.find(a => a.value == data.Type) })
        if ((routeLocation.state as any)?.data === 'History') {
          setState({ history: true })
          try {
            const getColorApi: GetColorApi = new GetColorApi({})
            const dataColor: IColor[] = await getColorApi.getColors()
            const colorOptions = createOptionColorsFromHistory(data.ColorIds, dataColor)
            setState({ colorOption: colorOptions })
            props.hideLoading()
          } catch (error) {
            console.error('Error fetching colors:', error)
            props.hideLoading()
          }
        }
        else {
          try {
            const getColorApi: GetColorApi = new GetColorApi({})
            const dataColor: IColor[] = await getColorApi.getColors()
            const colorOptions = createOptionColors(data.ColorNames, dataColor)
            setState({ colorOption: colorOptions })
            props.hideLoading()
          } catch (error) {
            console.error('Error fetching colors:', error)
            props.hideLoading()
          }
        }


      }
    }

    fetchData()
  }, [routeLocation.state])

  const createOptions = (productCodes) => {
    const options = productCodes.map((code) => ({
      label: code,
      value: code,
    }));
    return options
  }

  const createOptionColors = (color, dataColor) => {
    const options = color.map((code) => {
      const matchingColor = dataColor.find((a) => a.ColorName === code)
      const colorID = matchingColor ? matchingColor.Id : null

      return {
        label: code,
        value: colorID,
      }
    })

    return options
  }

  const createOptionColorsFromHistory = (color, dataColor) => {
    const options = color.map((code) => {
      const matchingColor = dataColor.find((a) => a.Id === code)
      const colorName = matchingColor ? matchingColor.ColorName : null

      return {
        label: colorName,
        value: color,
      }
    })

    return options
  }

  return (
    <div className='container addProductLimitation'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Ürün Yönlendirme Ekleme</span>
            <Link to='/admin/ProductOperation/ProductColorList'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Listeye Dön'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='row m-t-20'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <label>Yönlendirme Tipi</label>
              <IPSelectBox
                className="Type"
                name="Type"
                options={state.typeListOptions}
                isDisabled={routeLocation.state}
                value={state.typeOption}
                onChangeSingle={option => {
                  setState({ typeOption: option })
                }}
              />
            </div>
          </div>
          {
            state.typeOption.value === 2 && (
              <div className='row m-t-20'>
                <div className='col-md-3'></div>
                <div className='col-md-6'>
                  <label style={{ color: 'red' }}>Açıklama ve yönlendirme yalnızca 1.üründen 2.ürüne yapılacaktır.</label>
                </div>
              </div>)
          }
          <div className='row m-t-20'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <label>Ürün Açıklaması</label>
              <IPInput
                type='text'
                id='Title'
                className='form-control'
                name='Title'
                value={searchWord}
                disabled={state.history}
                onChange={(e) => setSearchWord(e.target.value)}
              />
            </div>
          </div>
          <div className='row m-t-20'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <label>Ürün Kodu</label>
              <IPSelectBox
                isMulti
                isClearable
                isCreatableSelect
                id='ProductCode'
                name='ProductCode'
                closeMenuOnSelect={false}
                isDisabled={state.history}
                placeholder={''}
                isSearchable
                onCreateOption={option => onCreateOption(option, setProductCode)}
                onChangeMulti={option => setProductCode(option)}
                value={productCode}
              />
            </div>
            <div className='col-md-6'>

            </div>
          </div>
          {
            state.typeOption.value === 1 ? (<div className='row m-t-20'>
              <div className='col-md-3'></div>
              <div className='col-md-6'>
                <label>Renk</label>
                <IPSelectBox
                  isMulti
                  isClearable
                  placeholder={''}
                  isDisabled={state.history}
                  className="Colors"
                  name="Colors"
                  onChangeMulti={(option: ISelectOption[]) => {
                    setState({ colorOption: option });
                  }}
                  options={
                    colors &&
                    colors.map((item: IColor) => ({
                      label: item.ColorName,
                      value: `${item.Id}`,
                    }))
                  }
                  value={state.colorOption.map((selectedOption) => ({
                    label: selectedOption.label,
                    value: `${selectedOption.value}`,
                  }))}
                />
              </div>
            </div>)
              : <></>
          }

          <div className='row m-t-20'>
            <div className='col-md-3'></div>
            <div className='col-md-6'>
              <label>Şirket</label>
              <IPSelectBox
                isClearable
                className="Company"
                name="Company"
                options={state.companyListOptions}
                isDisabled={state.history}
                value={state.companyOption}
                onChangeSingle={option => {
                  setState({ companyOption: option })
                }}
              />
            </div>
          </div>
          <br />
          <div className='row'>
            <div className='col-md-6'></div>
            <div className='col-md-6 mb-5' style={{ display: state.history ? 'none' : '' }}>
              <IPButton
                text="Kaydet"
                type='button'
                disabled={state.history}
                className='btn btn-success w-25'
                onClick={routeLocation.state ? updateProductColor : addProductColor}
              />
            </div>
          </div>
        </div>
        <br></br>
        {state.typeOption.value === 1 &&
          <div className='card'>
            <div className='card-header d-flex justify-content-between mb-5'>
              <span className='h4'>Renk Tablosu</span>
            </div>
            <div className='row'>
              {colors && colors.length > 0 && (
                <>
                  {colors.map((item) => (
                    <div className='col-md-4 p-2'>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                      >
                        <div
                          style={{
                            padding: '0px',
                            height: '50px',
                            width: '50px',
                            textAlign: 'center',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: item.ColorCode,
                              borderRadius: '50%',
                              height: '50px',
                              width: '50px',
                              ...(item.ColorCode !== 'white' && { border: '1px solid black' }),
                            }}
                          />
                        </div>
                      </div>
                      <p style={{ textAlign: 'center', fontSize: '20px' }}>{item.ColorName}  ({item.ColorCode})</p>
                    </div>
                  ))}
                </>
              )}
            </div>
            <br />
          </div>
        }
      </div>
    </div >
  )
}
export default componentWithContext(AddProductColor)
