import { css } from '@emotion/css'
import { Fragment, useMemo, useEffect, useState } from 'react'
import { useTable, usePagination, useSortBy } from 'react-table'

interface Props {
  columns: any
  data: any
  currentPage?: number
  currentPageSize?: number
  defaultColumnOptions?: any
  headerName?: string
  columnCount?: number
  color?: string
  backgroundColor?: string
  textAlign?: boolean
  columnCountTotal?: number
  total?: string
  colorRow?: any[]
  manualPagination: boolean
  pageChangeHandler(data1, data2): void
  totalRows: number

}

export default function TablePagination({ manualPagination = false, columns, data, currentPage, currentPageSize, defaultColumnOptions, headerName, columnCount, color, backgroundColor, textAlign, columnCountTotal, total, colorRow, pageChangeHandler, totalRows }: Props) {
  const defaultColumn = useMemo(
    () =>
      defaultColumnOptions
        ? defaultColumnOptions
        : {
          //width: 130,
        },
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // @ts-ignore
    page,
    // @ts-ignore
    canPreviousPage,
    // @ts-ignore
    canNextPage,
    // @ts-ignore
    pageOptions,
    // @ts-ignore
    pageCount,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    nextPage,
    // @ts-ignore
    previousPage,
    // @ts-ignore
    setPageSize,
    prepareRow,
    // @ts-ignore
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // @ts-ignore
      initialState: { pageIndex: currentPage ? currentPage : 0, pageSize: currentPageSize ? currentPageSize : 10 },
      manualPagination
    },
    useSortBy,
    usePagination,
  )

  useEffect(() => {
    pageChangeHandler(pageIndex, pageSize)
  }, [pageSize])

  useEffect(() => {
    if (currentPageSize)
      setPageSize(currentPageSize)
  }, [currentPageSize])


  const noOfPages = Math.ceil(totalRows / pageSize);
  const tableStyles = css`
    tbody {
      border-top: none !important;
    }
    td {
      padding: 0.3rem 9px !important;
    }
  `

  const actionStyles = css`
    display: flex;
    gap: 0.5rem;
    margin-top: 1rem;
    @media (max-width: 724px) {
      flex-direction: column;
    }
  `

  if (data.length === 0) {
    return <>Veri bulunamadı.</>
  }

  return (
    <>
      <div
        className={`table-responsive ${css`
          transform: rotateX(180deg);

          ::-webkit-scrollbar {
            height: 6px;
          }
        `}`}
      >
        <table
          className={`table ${css` width:100%;
            transform: rotateX(180deg);
          `} ${tableStyles}`}
          {...getTableProps()}
        >
          <thead style={{ backgroundColor: "#e0e0e2" }}>
            {
              headerName && headerName.length > 0 &&
              <tr>
                <th colSpan={columnCount} style={{ backgroundColor: backgroundColor ? backgroundColor : 'white', color: color ? color : "black", textAlign: textAlign ? "center" : "left" }}>{headerName}</th>
              </tr>
            }
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // @ts-ignore
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()} style={{ color: colorRow ? colorRow[i] : "black" }}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
            {
              columnCountTotal && total &&
              <tr>
                <th colSpan={columnCountTotal}></th>
                <th><b>TOPLAM:</b></th>
                <th>{total}</th>
              </tr>
            }
          </tbody>
        </table>
      </div>
      <div className={actionStyles}>
        <div>
          <button
            className='btn btn-sm btn-secondary'
            onClick={() => {
              gotoPage(0)
              pageChangeHandler(0, pageSize)
              sessionStorage.setItem('Page', '0')
            }}
            disabled={pageIndex > 0 ? false : !canPreviousPage}
          >
            {'<<'}
          </button>{' '}
          <button
            className='btn btn-sm btn-secondary'
            onClick={() => {
              previousPage()
              pageChangeHandler(pageIndex - 1, pageSize)
              sessionStorage.setItem('Page', String(pageIndex - 1))
            }}
            disabled={pageIndex > 1 ? false : !canPreviousPage}
          >
            {'<'}
          </button>{' '}
          <button
            className='btn btn-sm btn-secondary'
            onClick={() => {
              nextPage()
              pageChangeHandler(pageIndex + 1, pageSize)
              sessionStorage.setItem('Page', String(pageIndex + 1))
            }}
            disabled={noOfPages > pageIndex + 1 ? false : !canPreviousPage}
          >
            {'>'}
          </button>{' '}
          <button
            className='btn btn-sm btn-secondary'
            onClick={() => {
              gotoPage(pageCount - 1)
              pageChangeHandler(noOfPages - 1, pageSize)
              sessionStorage.setItem('Page', String(pageCount - 1))
            }}
            disabled={noOfPages > pageIndex + 1 ? false : !canPreviousPage}
          >
            {'>>'}
          </button>{' '}
        </div>
        <div>
          <span>
            Sayfa{' '}
            <strong>
              {pageIndex + 1} / {noOfPages}
            </strong>{' '}
          </span>
          <span>
            | Sayfaya Git:{' '}
            <input
              type='number'
              defaultValue={pageIndex + 1}
              onChange={e => {
                if (Number(e.target.value) < 0) return
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
                pageChangeHandler(page, pageSize)
                sessionStorage.setItem('Page', String(page))
              }}
              style={{ width: 80 }}
            />
          </span>{' '}
        </div>
        <div>
          <select
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
              sessionStorage.setItem('PageSize', String(e.target.value))
            }}
            style={{ width: 130 }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize} Satır Göster
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  )
}
