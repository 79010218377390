import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { GetInvoicesDetailRequest, InvoiceDetails } from '../models/getInvoiceDetail'

export class GetInvoicesDetailsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetInvoiceDetails',
      requesterInfo,
    })
  }

  public async getInvoiceDetail(request: GetInvoicesDetailRequest): Promise<InvoiceDetails[]> {
    return await this.postAsync<InvoiceDetails[]>(request)
  }
}
