import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IBrand } from '../../../components/models/models'

export class DeleteBrandDetailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/brand/deleteBrandDetail',
      requesterInfo,
    })
  }

  public async deleteBrand(id: number, moduleId: number): Promise<IBrand[]> {
    this.props.url = `${this.props.url}/${id}/${moduleId}`
    return await this.deleteAsync<IBrand[]>()
  }
}
