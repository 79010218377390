import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IParamposList } from '../models/models'

export class ListParamPosInsatallmentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'paramPosTransaction/listParamPosInsatallment',
      requesterInfo,
    })
  }

  public async listParamPosInsatallment(request: number): Promise<IParamposList[]> {
    this.props.url = `${this.props.url}/?bankId=${request}`
    return await this.getAsync<IParamposList[]>()
  }
}
