import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import { useEffect, useRef, useState } from 'react'
import { GetCurrentLogoApi } from './services/getCurrentLogoApi'
import { ICurrentLogo } from './models/models'
import './style.scss'
import Swal from 'sweetalert2'
import { DeleteCurrentLogoApi } from './services/deleteCurrentLogoApi'
import { UpdateCurrentLogoApi } from './services/updateCurrentLogoApi'

export default function Index() {
  const tableRef = useRef<any>()
  const [currentLogos, setCurrentLogos] = useState<ICurrentLogo[]>([])
  const t = translate(Translations)

  useEffect(() => {
    // id geliyorsa
    getCurrentLogo()
  }, [])

  const getCurrentLogo = async () => {
    const currentLogoApi = new GetCurrentLogoApi({})

    const logos = await currentLogoApi.getCurrentLogo()
    setCurrentLogos(logos as any)
  }

  const deleteCurrentLogo = async (row: any) => {
    var datas = row?.data()
    var dt = currentLogos.find(auth => {
      return auth.DealerId === datas.DealerId
    })

    Swal.fire({
      icon: 'question',
      title: 'Yeni Logoyu onaylamadığınıza  emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        if (dt) {
          const deleteCurrentLogoApi = new DeleteCurrentLogoApi({})
          const data = {
            DealerId: dt.DealerId,
            LogoId: dt.UploadedLogo.LogoId,
          }
          const isDeleted = await deleteCurrentLogoApi.deleteCurrent(data)
          if (isDeleted) {
            row?.remove().draw(true)
            Swal.fire({
              icon: 'success',
              title: 'Yeni Logo Onaylanmadı',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
        }
      }
    })
  }
  const updateCurrentLogo = async (row: any) => {
    var datas = row?.data()
    var dt = currentLogos.find(auth => {
      return auth.DealerId === datas.DealerId
    })
    if (dt) {
      const updateCurrentLogoApi = new UpdateCurrentLogoApi({})
      const data = {
        DealerId: dt.DealerId,
        LogoId: dt.UploadedLogo.LogoId,
      }
      const isUpdate = await updateCurrentLogoApi.updateCurrent(data)
      if (isUpdate) {
        row?.remove().draw(true)
        Swal.fire({
          icon: 'success',
          title: 'Logo Aktif edildi.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    }
  }
  useEffect(() => {
    let dt: any

    if (currentLogos && currentLogos.length > 0) {
      dt = syncTable(currentLogos)
      tableRef.current = dt
    }
    $('#datatb tbody').on('click', 'a', function () {
      const actionType: 'active' | 'delete' = this.getAttribute('data-action-type')
      const row = dt.row($(this).parents('tr'))
      var data = row?.data()

      if (!row || !data) {
        return
      }

      if (actionType === 'delete') {
        deleteCurrentLogo(row)
      }
      if (actionType === 'active') {
        updateCurrentLogo(row)
      }
    })
    return function () {
      dt && dt.destroy()
    }
  }, [currentLogos])

  const syncTable = (datas: ICurrentLogo[]) => {
    const dt = $(tableRef.current).DataTable({
      pageLength: 10,
      data: datas.map(dt => {
        return {
          DealerId: dt.DealerId,
          AccountCode: dt.AccountCode.replace('0000', ''),
          UsedLogo: dt.UsedLogo ? dt.UsedLogo.ImageUrl : '/images/notImage.png',
          UploadedLogo: dt.UploadedLogo.ImageUrl,
        }
      }),
      columns: [
        {
          title: '#',
          data: 'DealerId',
        },
        {
          title: t('pages.admin.userOperation.currentLogo.current'),
          data: 'AccountCode',
        },
        {
          title: 'Logo',
          data: 'UsedLogo',
          render: (data: any) => {
            return `<img src=${data} alt="" style="width: 50px"/>`
          },
        },
        {
          title: 'Yeni Logo',
          data: 'UploadedLogo',
          render: (data: any) => {
            return `<img src=${data} alt="" style="width: 50px"/>`
          },
        },
        {
          title: 'Aktif Et',
          data: 'Id',
          render: (data: any) => {
            return `<a data-action-type="active"><i class="icon-check-circle" style="color: green"></i></a>`
          },
        },
        {
          title: 'Sil',
          data: 'Id',
          render: (data: any) => {
            return `<a data-action-type="delete"><i class="icon-trash-2" style=" color: red; margin-left: 10px"></i></a>`
          },
        },
      ],
    } as any)
    return dt
  }
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              <h5>{t('pages.admin.userOperation.currentLogo.header')} </h5>
            </span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <table id={'datatb'} ref={tableRef} className='table'></table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
