import { useRef, useState } from 'react'

import data from '../../../../api/adminMenu.json'
import classes from './Menu.module.scss'

import List from './List'
import Links from './Link'
import Masonry from 'react-masonry-css'
import Image from '../../../../components/IPImage'
import { logoutUser } from '../../../../base/proxy/authenticate'
import { Link } from 'react-router-dom'
import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import { currentUser } from '../../../../redux/slices/user/userSlice'
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks'
import { initialize } from 'react-ga'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'

function Menu(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const inputEl = useRef<HTMLInputElement>(null)
  const searchButton = useRef(null)
  const menuButton = useRef(null)

  const logout = async () => {
    try {
      props.showLoading()
      await logoutUser()
      props.hideLoading()
    } catch {}
  }

  const [isMenuActive, setIsMenuActive] = useState(false)
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [search, setSearch] = useState('')

  const user = useAppSelector(currentUser)
  const dispatch = useAppDispatch()

  const handleMenu = () => {
    setIsMenuActive(!isMenuActive)
  }

  const closeOnEscape = e => {
    const key = e.key || e.keyCode
    if (key === 'Escape' || key === 'Esc' || key === 27) {
      setIsSearchActive(false)
    }
  }

  const toggleSearch = () => {
    if (!isSearchActive) {
      setIsSearchActive(true)
      setIsMenuActive(true)
      setTimeout(() => inputEl.current!.focus(), 100)
    } else setIsSearchActive(false)
  }

  const handleClose = e => {
    if ([inputEl.current, searchButton.current, menuButton.current, undefined, null].indexOf(e.target) > -1) return
    setIsSearchActive(false)
    setIsMenuActive(false)
  }

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const handleSubMenuSearch = () => {
    if (search) {
      let tempResult: any = []
      let tempSubMenuArray: any = []

      for (const item of data.menu) {
        tempSubMenuArray = []
        tempSubMenuArray = item.subMenu.filter((elem: any) => {
          return elem.name?.toLowerCase().includes(search?.toLowerCase())
        })
        // const founded = item.subMenu.find((elem: any) => { return elem.name.toLowerCase().includes(search.toLowerCase()) })

        // if (founded) {
        // }

        // for (const menu of item.subMenu) {
        //   if (menu.name.toLowerCase().includes(search.toLowerCase())) {
        //     let temSubMenuObj: any = {
        //       id: menu.id,
        //       name: menu.name,
        //       link: menu.link
        //     }
        //     tempSubMenuArray.push(temSubMenuObj)
        //   }
        // }
        let tempObj: any = {
          id: item.id,
          name: item.name,
          link: item.link,
          subMenu: tempSubMenuArray,
        }
        tempResult.push(tempObj)
      }
      if (tempResult.length > 0) {
        return tempResult
      } else {
        return []
      }
    } else {
      return data.menu
    }
  }

  return (
    <div className={classes.Menu} onClick={handleClose}>
      <nav>
        <div className={classes.menuBar}>
          <Link to='/homepage'>
            <Image className={classes.imglogo} src='/images/indexPazarLogo.png' alt='' />
          </Link>

          <div className={classes.linkWrapper}>
            <div className={classes.innerLinkWrapper}>
              <Links
                link='/admin/home'
                text='YÖNETİM PANELİ'
                iconSrcBlack='/images/Anasayfa.png'
                iconSrcWhite='/images/AnasayfaBeyaz.png'
              />
              <Links
                link='/admin/dashboard'
                text='DASHBOARD'
                iconSrcBlack='/images/DashboardIkon.png'
                iconSrcWhite='/images/Dashboard.png'
              />
              <Links
                link='/admin/homeAndSiteOperation/SapList'
                text='SAP GÜNCELLEME'
                iconSrcBlack='/images/sapguncelleme.png'
                iconSrcWhite='/images/sapguncellemebeyaz.png'
              />
              <Links
                link='/admin/UserOperation/ListUser'
                text='KULLANICI LİSTESİ'
                iconSrcBlack='/images/KullaniciGuncelleme.png'
                iconSrcWhite='/images/KullaniciGuncellemeBeyaz.png'
              />
              <Links
                link='/admin/ProductOperation/DocumentUpload'
                text='DOKÜMAN YÜKLEME'
                iconSrcBlack='/images/dokumanyukle.png'
                iconSrcWhite='/images/dokumanyuklebeyaz.png'
              />
              <Links
                link='/admin/ProductOperation/VisualDocumentationList'
                text='ÜRÜN GÖRSEL YÜKLEME'
                iconSrcBlack='/images/gorselguncelleme.png'
                iconSrcWhite='/images/gorselguncellemebeyaz.png'
              />
              <Links
                link='/admin/BankAndInstallementOperation/BankCrud'
                text='BANKA TAKSİT BİLGİLERİ'
                iconSrcBlack='/images/BankaTaksitGuncelleme.png'
                iconSrcWhite='/images/BankaTaksitGuncellemeBeyaz.png'
              />
              <Links
                link='/admin/ProductOperation/CampaignBundleList'
                text='KAMPANYA İŞLEMLERİ'
                iconSrcBlack='/images/BundleEkleme.png'
                iconSrcWhite='/images/BundleEklemeBeyaz.png'
              />
              <Links
                link='/admin/homeAndSiteOperation/AnnouncementList'
                text='DUYURULAR'
                iconSrcBlack='/images/DuyuruListesi.png'
                iconSrcWhite='/images/DuyuruListesiBeyaz.png'
              />
            </div>

            <input
              ref={inputEl}
              className={`${classes.searchInput} ${isSearchActive ? classes.active : ''}`}
              onChange={handleSearch}
              type='text'
              placeholder={t('layouts.admin.search')}
              onKeyDown={closeOnEscape}
            />
          </div>
        </div>

        {/* <Links
          className={classes.favoriteLink}
          link="/"
          text={t("layouts.admin.addFavorite")}
          iconSrc="/images/products/plusicon.png"
        />         */}

        <div className={classes.actions}>
          <div className={classes.searchButton}>
            <img src='/images/admin/magnifying-glass-solid.svg' alt='' ref={searchButton} onClick={toggleSearch} />
          </div>
          <div className={classes.ProfilImage}>
            <div className='topbar-dropdown p-dropdown'>
              <div className='row align-items-center'>
                <div className='col-auto'>
                  <div className='title p-0 float-right profileTitle'>
                    <a href=''>
                      <Image
                        className='avatar'
                        src={
                          user.DealerLogo
                            ? user.DealerLogo === 'https://index-content.vbt.com.tr:1443/'
                              ? '/images/notImage.png'
                              : user.DealerLogo
                            : '/images/notImage.png'
                        }
                        alt=''
                        style={{ marginRight: '0' }}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className='p-dropdown-content'>
                <div className={classes.profilbutton}>
                  <ul className='text-center' style={{ paddingLeft: 'initial' }}>
                    <li>
                      <Link to='/'>
                        {' '}
                        <i className='fa fa-desktop'></i>
                        {t('layouts.admin.goToTheSite')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/myspecial'>
                        {' '}
                        <i className='icon-user'></i>
                        {t('layouts.admin.myProfile')}
                      </Link>
                    </li>
                    {/* <li>
                      <a role={'button'}>
                        <i className='icon-clock'></i>
                        {t('layouts.admin.settings')}
                      </a>
                    </li> */}
                    <li>
                      <a role={'button'} onClick={logout}>
                        <i className='icon-clock'></i>
                        {t('layouts.admin.logout')}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className={`${classes.megaMenu} ${isMenuActive ? classes.active : ''}`}>
        <Masonry
          breakpointCols={4}
          className={classes['my-masonry-grid']}
          columnClassName={classes['my-masonry-grid_column']}
        >
          {handleSubMenuSearch().map((dataList: any) => (
            <div key={dataList.id} className={classes.dataList}>
              <List key={dataList.id} dataList={dataList} />
            </div>
            // <div className="asdasdas" key={index}>{dataList.subMenu.name}</div>
          ))}
        </Masonry>
      </div>
    </div>
  )
}

export default componentWithContext(Menu)
