import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IProductLink } from '../models/models'

export class GetProductLinkApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/productLink/GetAll',
      requesterInfo,
    })
  }

  public async getProductLink(): Promise<IProductLink[]> {
    return await this.getAsync<IProductLink[]>()
  }
}
