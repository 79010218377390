import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IUntouchedOrdersRequest, IUntouchedOrdersResponse } from '../models/models'

export class PostUntouchedOrdersListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/untouchedOrders',
      requesterInfo,
    })
  }

  public async postUntouchedOrdersList(request: IUntouchedOrdersRequest): Promise<IUntouchedOrdersResponse[]> {
    return await this.postAsync<IUntouchedOrdersResponse[]>(request)
  }
}
