import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { WebinarCalanderRequestModel } from '../models/WebinarCalanderRequestModel'

export class WebinarCalanderAdminService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/webinarCalander/post',
      requesterInfo,
    })
  }

  public async postWebinarCalanderApplication(
    request: WebinarCalanderRequestModel
  ): Promise<WebinarCalanderRequestModel> {
    return await this.postAsync<any>(request)
  }

  public async deleteEvent(request: any): Promise<any> {
    this.props.url = 'admin/webinarCalander/delete'
    return await this.deleteAsync<any>(request)
  }

  public async updateEvent(request: any): Promise<any> {
    this.props.url = 'admin/webinarCalander/put'
    return await this.putAsync<any>(undefined, request)
  }
}

export class GetAllEvents extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/webinarCalander/get',
      requesterInfo,
    })
  }

  public async getAllEvents(): Promise<any> {
    return await this.getAsync<any>()
  }
}
