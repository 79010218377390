import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { MyOrdersResponse } from '../models/myOrdersExcel'
import { IGetMyOrders } from '../models/request/getMyOrderRequest'

export class PostMyOrderExcel extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'excel/OrderListExcel',
      requesterInfo,
    })
  }

  public async postMyOrderExcel(request: IGetMyOrders): Promise<MyOrdersResponse> {
    return await this.postAsync<MyOrdersResponse>(request)
  }
}
