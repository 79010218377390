import React, { useEffect } from 'react'
import IPInput from '../../../components/IPInput'
import Swal from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { getChangePasswordValidation } from './validation'
import IPButton from '../../../components/IPButton'
import { ChangePasswordApi } from './services/changePasswordApi'
import { IChangeUserPasswordRequest, IUserChangeUserPasswordRequest } from './models/models'
import { encrypt } from '../../../base/utils/proxyUtils'
import { IException } from '../../../base/models'
import { logoutUser } from '../../../base/proxy/authenticate'
import { UserChangePasswordApi } from './services/userChangePasswordApi'
import { IPInputDataPattern } from '../../../components/IPInput/IPInput'

export default function Index(props: any) {
  let navigate = useNavigate()
  const routeLocation = useLocation()

  const validationSchema = getChangePasswordValidation((routeLocation.state as any)?.userId > 0 ? false : true)

  useEffect(() => {
    if (routeLocation.state as any) {
      setFieldValue('userId', (routeLocation.state as any)?.userId)
    } else {
      resetForm()
    }
  }, [])

  const { handleSubmit, handleChange, handleBlur, setValues, setFieldValue, resetForm, values, errors, touched } =
    useFormik({
      initialValues: {
        userId: 0,
        oldPassword: '',
        password: '',
        passwordRepeat: '',
      },
      onSubmit: async values => {
        if (values.password === values.passwordRepeat) {
          if (values.userId === 0) changePassword()
          else userChangePassword()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Şifreler aynı değildir',
          })
        }
      },
      validationSchema,
    })

  const changePassword = async () => {
    const changePasswordApi: ChangePasswordApi = new ChangePasswordApi({})
    const forgetPasswordModel: IChangeUserPasswordRequest = {
      Password: encrypt(values.password),
      OldPassword: encrypt(values.oldPassword),
    }
    changePasswordApi
      .changePassword(forgetPasswordModel)
      .then((res: boolean) => {
        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Şifreniz değiştirilmiştir tekrar giriş yapmak için yönlendirileceksiniz.',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async result => {
            logoutUser()
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Bir hata meydana geldi',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const userChangePassword = async () => {
    const userChangePasswordApi: UserChangePasswordApi = new UserChangePasswordApi({})
    const forgetPasswordModel: IUserChangeUserPasswordRequest = {
      Password: encrypt(values.password),
      UserId: values.userId,
    }
    userChangePasswordApi
      .userChangePassword(forgetPasswordModel)
      .then((res: boolean) => {
        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Kullanıcının şifresi değiştirildi',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          navigate('/myspecial/UserUpdate')
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Bir hata meydana geldi',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  return (
    <div className='container-fluid special-valid'>
      <div className='heading-text'>
        <h4>Şifre Değiştir</h4>
      </div>
      <div className='card-body px-0'>
        <div className='form-group form-fg'>
          <div className='form-row' style={{ justifyContent: 'center' }}>
            {!(values.userId && values.userId > 0) && (
              <div className='form-group col-md-6'>
                <label htmlFor='oldPassword' className='labeltext'>
                  Eski Şifre
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='oldPassword'
                  value={values.oldPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.oldPassword}
                  errors={errors.oldPassword}
                />
              </div>
            )}
          </div>
          <div className='form-row' style={{ justifyContent: 'center' }}>
            <div className='form-group col-md-6'>
              <label htmlFor='password' className='labeltext'>
                Yeni Şifre
              </label>
              <IPInput
                type='text'
                className='form-control'
                name='password'
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.password}
                errors={errors.password}
              />
            </div>
          </div>
          <div className='form-row' style={{ justifyContent: 'center' }}>
            <div className='form-group col-md-6'>
              <label htmlFor='passwordRepeat' className='labeltext'>
                Şifre Tekrar
              </label>
              <IPInput
                type='text'
                className='form-control'
                name='passwordRepeat'
                value={values.passwordRepeat}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.passwordRepeat}
                errors={errors.passwordRepeat}
              />
            </div>
          </div>
          <div className='form-row' style={{ justifyContent: 'center' }}>
            <div className='form-group col-md-6'>
              <IPButton
                className='btn-md btn-fg btn-block'
                type='button'
                text='Kaydet'
                onClick={e => {
                  handleSubmit()
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
