import React, { useState, useEffect, useMemo } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { GetSubscriptionTrackingApi } from '../services/getSubscriptionTrackingApi'
import Swal from 'sweetalert2'
import InputCampaign from '../../../../components/InputCampaign'
import { formatDateForCampaignInput, toDateTimeLocal } from '../../../../base/utils/dataHelper'
import IPSelectBox from '../../../../components/IPSelectBox'
import IPInput from '../../../../components/IPInput'
import IPButton from '../../../../components/IPButton'
import Table from '../../../../components/Table'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../base/customHooks'
import { ISubscriptionTracking } from '../models/models'

interface ISubscriptionTrackingState {
  brandOption?: ISelectOption
  statusOption?: ISelectOption
  periodOption?: ISelectOption
  orderNumber: string
}
function SubscriptionTracking(props: BaseComponentWithContextProps) {
  const [activeTab, setActiveTab] = useState('renewals-tab')
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [mySubscriptions, setMySubscriptions] = useState<ISubscriptionTracking[]>([])
  const [filterMySubscriptions, setFilterMySubscriptions] = useState<ISubscriptionTracking[]>([])
  const [endMySubscriptions, setEndMySubscriptions] = useState<ISubscriptionTracking[]>([])
  const getInitialState = (): ISubscriptionTrackingState => {
    return {
      orderNumber: '',
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ISubscriptionTrackingState>(intialState)
  const [brand, setBrand] = useState<ISelectOption[]>([])
  const getSubscriptionTracking = async () => {
    props.showLoading()
    const getSubscriptionTrackingApi: GetSubscriptionTrackingApi = new GetSubscriptionTrackingApi({})
    await getSubscriptionTrackingApi
      .getSubscriptionTracking()
      .then(result => {
        if (result) {
          props.hideLoading()
          const data: ISubscriptionTracking[] = []
          let i = 0
          result.map((res: ISubscriptionTracking, index) => {
            const sDate = new Date(
              res.KAYIT_TARIHI.slice(0, 4) + '-' + res.KAYIT_TARIHI.slice(4, 6) + '-' + res.KAYIT_TARIHI.slice(6, 8)
            )
            if (+res.GARANTI_AY > 0) {
              const inititalEndDate = new Date(sDate)
              inititalEndDate.setMonth(inititalEndDate.getMonth() + +res.GARANTI_AY)
              i++
              return data.push({
                ...res,
                BASLANGIC_TARIHI: sDate,
                BITIS_TARIHI: inititalEndDate,
              })
            }
            data.push({
              ...res,
              BASLANGIC_TARIHI: sDate,
            })
          })
          if (i > 0) {
            data.sort(function (a, b) {
              const aDate = a.BITIS_TARIHI
              const bDate = b.BITIS_TARIHI
              if (bDate < aDate) {
                return -1
              }
              if (bDate > aDate) {
                return 1
              }
              return 0
            })
          } else {
            data.sort(function (a, b) {
              const aDate = a.BASLANGIC_TARIHI
              const bDate = b.BASLANGIC_TARIHI
              if (bDate < aDate) {
                return -1
              }
              if (bDate > aDate) {
                return 1
              }
              return 0
            })
          }
          setMySubscriptions(data)
          setFilterMySubscriptions(data)
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    return
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Şirket',
        accessor: 'col1',
      },
      {
        Header: 'Abonelik',
        accessor: 'col2',
      },
      {
        Header: 'Sipariş No',
        accessor: 'col3',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'col4',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'col5',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col6',
      },
      {
        Header: 'Ürün Adet',
        accessor: 'col7',
      },
    ],
    []
  )

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Şirket',
        accessor: 'col1',
      },
      {
        Header: 'Sipariş No',
        accessor: 'col2',
      },
      {
        Header: 'İşlem/Abonelik',
        accessor: 'col3',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col4',
      },
      {
        Header: 'Ürün',
        accessor: 'col5',
      },
      {
        Header: 'Miktar',
        accessor: 'col6',
      },
      // {
      //   Header: 'Birim Fiyat',
      //   accessor: 'col7',
      // },
      {
        Header: 'Tutar',
        accessor: 'col7',
      },
      {
        Header: 'PB',
        accessor: 'col8',
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      filterMySubscriptions.map((item: ISubscriptionTracking) => ({
        col1:
          item.VKORG === '1000'
            ? 'İndex'
            : item.VKORG === '1100'
            ? 'Datagate'
            : item.VKORG === '1200'
            ? 'Despec'
            : 'Netex',
        col2: item.ZZMRK,
        col3: item.SIPARIS_BELGENO,
        col4: item.BASLANGIC_TARIHI.toLocaleDateString(),
        col5: item.BITIS_TARIHI && item.BITIS_TARIHI.toLocaleDateString(),
        col6: item.URUN_KODU,
        col7: item.ADET,
      })),
    [filterMySubscriptions]
  )

  const tbData = useMemo(
    () =>
      endMySubscriptions.map((item: ISubscriptionTracking) => ({
        col1:
          item.VKORG === '1000'
            ? 'İndex'
            : item.VKORG === '1100'
            ? 'Datagate'
            : item.VKORG === '1200'
            ? 'Despec'
            : 'Netex',
        col2: item.ZZMRK,
        col3: item.SIPARIS_BELGENO,
        col4: item.BASLANGIC_TARIHI.toLocaleDateString(),
        col5: item.BITIS_TARIHI.toLocaleDateString(),
        col6: item.URUN_KODU,
        col7: item.ADET,
      })),
    [endMySubscriptions]
  )

  const tableData = useMemo(
    () =>
      mySubscriptions.map((item: any) => ({
        col1:
          item.VKORG === '1000'
            ? 'İndex'
            : item.VKORG === '1100'
            ? 'Datagate'
            : item.VKORG === '1200'
            ? 'Despec'
            : 'Netex',
        col2: item.SIPARIS_BELGENO,
        col3: item.SIPARIS_BELGENO,
        col4: item.URUN_KODU,
        col5: item.URUN_ADI,
        col6: item.ADET,
        // col7: "",
        col7: item.KALEM_TOPLAM_SPB.toFixed(2),
        col8: item.SIPARIS_PARA_BIRIMI,
      })),
    [mySubscriptions]
  )

  useEffect(() => {
    getSubscriptionTracking()
  }, [])

  useEffect(() => {
    const data: ISelectOption[] = []
    mySubscriptions.map(abone => {
      if (data.length === 0) data.push({ value: abone.ZZMRK, label: abone.ZZMRK })
      if (
        data.findIndex(d => {
          return d.value === abone.ZZMRK
        }) === -1
      )
        data.push({ value: abone.ZZMRK, label: abone.ZZMRK })
    })
    setBrand(data)
  }, [mySubscriptions])

  const searchMySubscriptions = async () => {
    let data: ISubscriptionTracking[] = []
    if ((startDate && !endDate) || (!startDate && endDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen başlangıç ve bitiş tarihini birlikte giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (startDate && endDate) {
      const inititalEndDate = new Date(endDate)
      inititalEndDate.setDate(inititalEndDate.getDate() + 1)
      const inititalStart = new Date(startDate)
      inititalStart.setDate(inititalStart.getDate() - 1)
      data = mySubscriptions.filter(sub => {
        return (
          (new Date(inititalStart) < sub.BASLANGIC_TARIHI && sub.BASLANGIC_TARIHI < new Date(inititalEndDate)) ||
          (new Date(inititalStart) < sub.BITIS_TARIHI && sub.BITIS_TARIHI < new Date(inititalEndDate))
        )
      })
    }
    if (state.brandOption) {
      if (data.length > 0)
        data = data.filter(sub => {
          return state.brandOption && sub.ZZMRK === state.brandOption.value
        })
      else if (!startDate && !endDate)
        data = mySubscriptions.filter(sub => {
          return state.brandOption && sub.ZZMRK === state.brandOption.value
        })
      else data = []
    }
    if (state.statusOption && +state.statusOption.value > 0) {
      if (data.length > 0) {
        if (+state.statusOption.value === 1)
          data = data.filter(sub => {
            return new Date() < sub.BITIS_TARIHI || new Date() === sub.BITIS_TARIHI
          })
        else
          data = data.filter(sub => {
            return sub.BITIS_TARIHI < new Date()
          })
      } else if (!startDate && !endDate) {
        if (+state.statusOption.value === 1)
          data = mySubscriptions.filter(sub => {
            return new Date() < sub.BITIS_TARIHI || new Date() === sub.BITIS_TARIHI
          })
        else
          data = mySubscriptions.filter(sub => {
            return sub.BITIS_TARIHI < new Date()
          })
      } else data = []
    }
    if (state.orderNumber.length > 0) {
      if (data.length > 0)
        data = data.filter(sub => {
          return sub.SIPARIS_BELGENO === state.orderNumber
        })
      else if (!startDate && !endDate && !state.brandOption)
        data = mySubscriptions.filter(sub => {
          return sub.SIPARIS_BELGENO === state.orderNumber
        })
      else data = []
    }
    if (
      state.orderNumber.length === 0 &&
      !state.brandOption &&
      !endDate &&
      !startDate &&
      (!state.statusOption || +state.statusOption.value === 0)
    )
      data = mySubscriptions
    setFilterMySubscriptions(data)
  }

  const searchPeriod = async (option: ISelectOption) => {
    let data: ISubscriptionTracking[] = []
    setState({ periodOption: option })
    const inititalDate = new Date()
    inititalDate.setDate(inititalDate.getDate() + (+option.value + 1))
    data = mySubscriptions.filter(sub => {
      return new Date() < new Date(sub.BITIS_TARIHI) && new Date(sub.BITIS_TARIHI) < new Date(inititalDate)
    })
    setEndMySubscriptions(data)
  }

  return (
    <div className='container-fluid'>
      <div className='heading-text mb-3'>
        <h4>Abonelik Takip</h4>
      </div>
      <div className='tabs'>
        <ul className='nav nav-tabs' id='myTab' role='tablist'>
          <li className='nav-item' style={{ cursor: 'pointer' }}>
            <a
              className={`nav-link pl-0  ${activeTab === 'renewals-tab' ? 'active' : ''}`}
              id='renewals-tab'
              data-toggle='tab'
              role='tab'
              aria-controls='renewals'
              aria-selected='true'
              onClick={() => {
                setActiveTab('renewals-tab')
              }}
            >
              Yakın Yenilemeler
            </a>
          </li>
          <li className='nav-item' style={{ cursor: 'pointer' }}>
            <a
              className={`nav-link pl-0  ${activeTab === 'mysubscriptions-tab' ? 'active' : ''}`}
              id='mysubscriptions-tab'
              data-toggle='tab'
              role='tab'
              aria-controls='mysubscriptions'
              aria-selected='false'
              onClick={() => {
                setActiveTab('mysubscriptions-tab')
              }}
            >
              Aboneliklerim
            </a>
          </li>{' '}
          <li className='nav-item' style={{ cursor: 'pointer' }}>
            {' '}
            <a
              className={`nav-link pl-0  ${activeTab === 'mySubscriptionCart-tab' ? 'active' : ''}`}
              id='mySubscriptionCart-tab'
              data-toggle='tab'
              role='tab'
              aria-controls='mySubscriptionCart'
              aria-selected='false'
              onClick={() => {
                setActiveTab('mySubscriptionCart-tab')
              }}
            >
              Abonelik Sepetim
            </a>
          </li>
        </ul>
        {activeTab === 'renewals-tab' && (
          <div>
            <div className='col-md-4'>
              <IPSelectBox
                isClearable
                placeholder='Periyot Aralığı Seçiniz'
                options={[
                  { value: 15, label: 'Son 15 Gün' },
                  { value: 30, label: 'Son 1 Ay' },
                  { value: 90, label: 'Son 3 Ay' },
                  { value: 180, label: 'Son 6 Ay' },
                  { value: 360, label: 'Son 1 Yıl' },
                ]}
                value={state.periodOption}
                onChangeSingle={option => {
                  if (option) return searchPeriod(option)
                  setEndMySubscriptions([])
                  setState({ periodOption: option })
                }}
              />
            </div>
            <Table columns={columns} data={tbData} currentPage={0} />
          </div>
        )}
        {activeTab === 'mysubscriptions-tab' && (
          <>
            <div className='row'>
              <div className='col-12 col-md-3 mb-3'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={startDate && formatDateForCampaignInput(startDate)}
                  onChange={e => {
                    if (e.target.value) return setStartDate(new Date(e.target.value))
                    setStartDate(null)
                  }}
                  isActive
                />
              </div>
              <div className='col-12 col-md-3 mb-3'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={endDate && formatDateForCampaignInput(endDate)}
                  onChange={e => {
                    if (e.target.value) return setEndDate(new Date(e.target.value))
                    setEndDate(null)
                  }}
                  isActive
                />
              </div>
              <div className='col-12 col-md-3 mb-3'>
                <IPSelectBox
                  isClearable
                  placeholder='Marka Seçiniz'
                  options={brand}
                  value={state.brandOption}
                  onChangeSingle={option => setState({ brandOption: option })}
                />
              </div>
              <div className='col-12 col-md-3 mb-3'>
                <IPInput
                  type='text'
                  placeholder='Sipariş No'
                  value={state.orderNumber}
                  onChange={e => setState({ orderNumber: e.target.value })}
                />
              </div>
            </div>
            <div className='row'>
              <p style={{ color: 'red' }}>
                * Seçilen tarih aralığı, seçili ürünün alınma ve sonlanma tarihlerinden herhangi biri için dikkate
                alınacaktır.
              </p>
            </div>
            <div className='row'>
              <div className='col-12 col-md-3 mb-3'>
                <IPSelectBox
                  isClearable
                  placeholder='Durum Seçiniz'
                  options={[
                    { value: 0, label: 'Hepsi' },
                    { value: 1, label: 'Aktif' },
                    { value: 2, label: 'Pasif' },
                  ]}
                  value={state.statusOption}
                  onChangeSingle={option => setState({ statusOption: option })}
                />
              </div>
              <div className='col-12 col-md-3 mb-3'>
                <IPButton text='Ara' type='button' onClick={searchMySubscriptions} />
              </div>
            </div>

            <div>
              <Table columns={columns} data={data} currentPage={0} />
            </div>
          </>
        )}
        {activeTab === 'mySubscriptionCart-tab' && (
          <div>
            <Table columns={tableColumns} data={tableData} currentPage={0} />
          </div>
        )}
      </div>
    </div>
  )
}
export default componentWithContext(SubscriptionTracking)
