import { useState } from 'react'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { translate } from '../../../../../base/localization'
import { getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../../components/InputCampaign'
import Translations from '../../../../../translations'
import { PostInfoMailListModel, PostInfoMailListRequestModel } from '../models/InfoMailModel'
import { GetInformationOrderMailListApi } from '../services/getInfoMailListApi'

interface InfoMailListState {
  type: number
  typeOption?: ISelectOption
  StartDate: Date
  EndDate: Date
}
function InformationOrderMailList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)

  const ISelectOption: ISelectOption[] = [
    { label: t('pages.admin.dealerAndOrderReport.informationOrderMail.informationOrderMail.all'), value: 2 },
    { label: t('pages.admin.dealerAndOrderReport.informationOrderMail.informationOrderMail.successful'), value: 1 },
    { label: t('pages.admin.dealerAndOrderReport.informationOrderMail.informationOrderMail.unsuccessful'), value: 0 },
  ]

  const getInitialState = (): InfoMailListState => {
    return {
      type: 0,
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate),
    }
  }

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const [infoMailList, setInfoMailList] = useState<PostInfoMailListModel[]>([])
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<InfoMailListState>(intialState)

  const postInfoMailList = async () => {
    props.showLoading()
    const data: PostInfoMailListRequestModel = {
      Type: state.type,
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate),
    }
    const postInfoMailListApi: GetInformationOrderMailListApi = new GetInformationOrderMailListApi({})
    postInfoMailListApi
      .postInfoMailList(data)
      .then(res => {
        if (res) {
          setInfoMailList(res)
          props.hideLoading()
        }
      })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }
  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }
  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              {t('pages.admin.dealerAndOrderReport.informationOrderMail.informationOrderMail.header')}
            </span>
          </div>
          <div className='card-body'>
            <form>
              <div className='form-row'>
                <div className='form-group col-md-3 align-self-end'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>

                <div className='form-group col-md-3 align-self-end'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
                <div className='form-group col-md-3'>
                  <label htmlFor='location' className='form-label'>
                    {t('pages.admin.dealerAndOrderReport.informationOrderMail.informationOrderMail.operationType')}
                  </label>
                  <IPSelectBox
                    isClearable
                    options={ISelectOption}
                    value={state.typeOption}
                    onChangeSingle={option => {
                      if (option) return setState({ typeOption: option, type: Number(option.value) })
                      return setState({ typeOption: option, type: 0 })
                    }}
                  />
                </div>
                <div className='form-group col-md-3'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={t('pages.admin.dealerAndOrderReport.informationOrderMail.informationOrderMail.show')}
                        onClick={postInfoMailList}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </form>
            <br />
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-sm-12'>
                      <table
                        id='datatable'
                        className='table table-bordered table-hover dataTable'
                        role='grid'
                        aria-describedby='datatable_info'
                      >
                        <thead>
                          <tr role='row' className='odd'>
                            <th>#</th>
                            <th>Sipariş No</th>
                            <th>Mesaj Başlığı</th>
                            <th>Durum</th>
                            <th>Gönderildiği Tarih</th>
                            <th>Deneme Sayısı</th>
                          </tr>
                        </thead>
                        <tbody>
                          {infoMailList &&
                            infoMailList.length > 0 &&
                            infoMailList.map((i, index) => (
                              <tr role='row' className='odd' key={index}>
                                <td>{index + 1}</td>
                                <td>{i.OrderNumber}</td>
                                <td>{i.MessageHeader}</td>
                                <td>{i.Status}</td>
                                <td>{i.SendDate}</td>
                                <td>{i.SendCount}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(InformationOrderMailList)
