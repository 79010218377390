import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IBanner } from '../models/models'

export class GetLoginBannersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'auth/loginBanners',
      requesterInfo,
    })
  }

  public async getBanners(): Promise<IBanner[]> {
    return await this.getAsync<IBanner[]>()
  }
}
