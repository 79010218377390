import React, { useMemo, useState, useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import InputCampaign from '../../../../../components/InputCampaign'
import { getDateByTimezoneOffset, toDateTimeLocal } from '../../../../../base/utils/dataHelper'
import Table from '../../../../../components/Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { ILimitHistory, IProductLimitsRequest, IProductLimitsResponse } from '../models/models'
import { PostProductLimitsApi } from '../services/postProductLimitsApi'
import Swal from 'sweetalert2'
import { Link, useNavigate } from 'react-router-dom'
import { DeleteLimitApi } from '../services/DeleteLimitApi'
import { LimitHistoryApi } from '../services/LimitHistoryApi'
import IPModal from '../../../../../components/IPModal'
import moment from 'moment'

function ProductQuantityLimitList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const initialStartDate = new Date()
  initialStartDate.setDate(1)
  initialStartDate.setHours(0)
  initialStartDate.setMinutes(0)
  initialStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState<any>(initialStartDate)
  const [endDate, setEndDate] = useState<any>(new Date())
  const [active, SetActive] = useState(true)
  const [show, setShowHistory] = useState(false)
  const [history, setHistory] = useState<ILimitHistory[]>([])


  const [limitList, SetLimitList] = useState<IProductLimitsResponse[]>([])

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Adet',
        accessor: 'col3',
      },
      {
        Header: 'Depo',
        accessor: 'col4',
      },
      {
        Header: 'Cari Kodu',
        accessor: 'col5',
      },
      {
        Header: 'Cari Tipi',
        accessor: 'col6',
      },
      {
        Header: 'Cari Alt Tipi',
        accessor: 'col7',
      },
      {
        Header: 'Başlangıç Tarihi',
        accessor: 'col8',
      },
      {
        Header: 'Bitiş Tarihi',
        accessor: 'col9',
      },
      {
        Header: 'Etkin',
        accessor: 'col10',
      },
      {
        Header: 'İşlemler',
        accessor: 'col11',
      }
    ],
    []
  )

  const tableData = useMemo(
    () => limitList.map((item, index) => {
      let productCode = ""
      item.ProductCodes.map((pro, i) => {
        if (i > 0)
          return productCode += ", " + pro
        return productCode += pro
      })
      let limit = "";
      if (item.ProductCodesQuantity.length > 0) {
        item.ProductCodesQuantity.map((pro, i) => {
          if (i > 0)
            return limit += ", " + String(pro)
          return limit += String(pro)
        })
      } else {
        limit = String(item.MaxQuantity)
      }
      let dealerCode = ""
      item.DealerCodeList.map((pro: string, i) => {
        if (i > 0)
          return dealerCode += ", " + pro.replace("0000", "")
        return dealerCode += pro.replace("0000", "")
      })
      let dealerType = ""
      item.DealerTypeList.map((pro: string, i) => {
        if (i > 0)
          return dealerType += ", " + pro
        return dealerType += pro
      })
      let dealerSubType = ""
      item.DealerSubTypeList.map((pro: string, i) => {
        if (i > 0)
          return dealerSubType += ", " + pro
        return dealerSubType += pro
      })
      const data = {
        col1: index + 1,
        col2: productCode,
        col3: limit,
        col4: item.WarehouseCode,
        col5: dealerCode,
        col6: dealerType,
        col7: dealerSubType,
        col8: new Date(item.StartDate).toLocaleString(),
        col9: new Date(item.EndDate).toLocaleString(),
        col10: item.Enabled ? "Evet" : "Hayır",
        col11: (
          <div>
            <a
              data-action-type='edit'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.edit')}
              onClick={() => updateProductLimit(item)}
            >
              <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
            </a>
            <a
              data-action-type='delete'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.delete')}
              onClick={() => deleteLimit(item.Id)}
            >
              <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
            </a>
            <a
              data-action-type='history'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title={'Tarihçe'}
              onClick={() => showHistory(item.Id)}
            >
              <i className='icon-clock' style={{ fontSize: '1.2rem', color: 'blue' }}></i>
            </a>
          </div>
        ),
      }
      return data
    }), [limitList])

  const showHistory = async (id: number) => {
    props.showLoading();
    const limitHistory: LimitHistoryApi = new LimitHistoryApi({})
    try {
      await limitHistory
        .historyByID(id)
        .then(res => {
          setShowHistory(true)
          setHistory(res)
        })
      props.hideLoading();
    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  const postProductLimits = async () => {
    props.showLoading()
    const req: IProductLimitsRequest = {
      StartDate: getDateByTimezoneOffset(startDate) as any,
      EndDate: getDateByTimezoneOffset(endDate) as any,
      IsActive: active
    }
    const postProductLimitsApi: PostProductLimitsApi = new PostProductLimitsApi({})

    await postProductLimitsApi
      .postProductLimits(req)
      .then(async result => {
        if (result) {
          SetLimitList(result)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const deleteLimit = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteLimitApi: DeleteLimitApi = new DeleteLimitApi({})

        await deleteLimitApi
          .deleteLimit(id)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: result,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              SetLimitList(limitList.filter((limit) => { return limit.Id !== id }))
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
        return;
      }
    })



  }

  useEffect(() => {
    postProductLimits()
  }, [])

  let navigate = useNavigate()

  const updateProductLimit = async (item: any) => {
    navigate('/admin/ProductOperation/AddProductLimitation', {
      state: { productLimitData: item },
    })
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Ürün Adet Sınırı Listeleme</span>
            <Link to='/admin/ProductOperation/AddProductLimitation'>
              <IPButton
                type='button'
                className='btn btn-primary m-l-10'
                text='Ekle'
                style={{ width: '25px', marginRight: '10px' }}
              />
            </Link>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row mb-3'>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={startDate && toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={endDate && toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-1 mb-3'>
                  <IPCheckbox label='Aktif' checked={active} onChange={() => SetActive(!active)} />
                </div>
                <div className='col-12 col-md-2 mb-3'>
                  <IPButton text='Göster' onClick={postProductLimits} />
                </div>
              </div>
            </form>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
          <IPModal
            show={show}
            onClose={() => {
              setShowHistory(false)
              setHistory([])
            }}
            hideConfirm={true}
            width={1200}
            title='Tarihçe'
          >
            <table className='table'>
              <thead>
                <tr role='row' className='odd'>
                  <th>#</th>
                  <th>Tarih</th>
                  <th>Bayi Kodu</th>
                  <th>Kullanıcı Adı</th>
                </tr>
              </thead>

              <tbody>
                {
                  history && history.length > 0 && history.map((his) => (
                    <tr role='row' className='odd' key={his.Id}>
                      <td>{<a
                        className='btn btn-transparent'
                        style={{ color: 'blue' }}
                        type='button'
                        data-action-type='order'
                        onClick={() => {
                          navigate('/admin/ProductOperation/AddProductLimitation', {
                            state: { productLimitData: his, data: 'History' }
                          })
                        }}
                      >
                        {his.Id}
                      </a>}</td>
                      <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                      <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                      <td>{his.UserName}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </IPModal>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(ProductQuantityLimitList)
