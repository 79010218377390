import * as yup from 'yup'
import Translations from '../../translations'
import { translate } from '../../base/localization'

const t = translate(Translations)
const validations = yup.object().shape({
  password: yup
    .string()
    .min(3, t('pages.resetPassword.validation.characterMustBe', ['Parolanız', '3']))
    .required(t('pages.loginPage.validation.requiredfield')),
  passwordRepeat: yup
    .string()
    .min(3, t('pages.resetPassword.validation.characterMustBe', ['Parolanız', '3']))
    .required(t('pages.loginPage.validation.requiredfield')),
})

export default validations
