import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { ISap } from '../models/requestModels'

export class PostSyncProductApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/sap/sync-product',
      requesterInfo,
    })
  }

  public async postSyncProduct(request: ISap): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
