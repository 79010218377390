import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
export class GetProductListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/product-list',
      requesterInfo,
    })
  }

  public async getProductList(): Promise<any> {
    return await this.getAsync<any>()
  }
}
