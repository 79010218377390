import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ConfigHelper } from '../../../base/constants'
import { ClientStorage } from '../../../base/storage'
import { RootState } from '../../app/store'
// import { getUser } from "./userApi";

export interface UserModel {
  UserId: number
  SessionId: string
  Name: string
  UserName: string
  AccountCode: string
  IsAdmin: boolean
  IsDPP: boolean
  Token: string
  RefreshToken: string
  CreatedTokenTime: number
  HasChangePassword: boolean
  ExceptionMessage: string
  BeHalfOfToken: string
  BeHalfOfUserId: string
  DealerLogo: string
  DealerName: string
  Email: string
}

export interface UserState {
  currentUser: UserModel
  openedPopupsCompanyIds: number[]
}

const initialState: UserState = {
  currentUser: Object.assign({}),
  openedPopupsCompanyIds: [],
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

// export const setUser = createAsyncThunk(
//   "user/setUser",
//   async (userId: string) => {
//     const response = await getUser(userId);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const userSlice = createSlice({
  name: 'user',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: state => {
      if (ClientStorage.hasItem(ConfigHelper.INDEXPAZAR_USER_DATA)) {
        state.currentUser = JSON.parse(ClientStorage.getItem(ConfigHelper.INDEXPAZAR_USER_DATA))
      }
    },
    setUserManuelly: (state, action?: PayloadAction<UserModel>) => {
      if (action) {
        state.currentUser = action.payload
        ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_DATA, JSON.stringify(action.payload))
      }
    },
    addOpenedPopupsCompanyIds: (state, action: PayloadAction<number>) => {
      if (state.openedPopupsCompanyIds.indexOf(action.payload) === -1) {
        state.openedPopupsCompanyIds = [...state.openedPopupsCompanyIds, action.payload]

        ClientStorage.setItem(
          ConfigHelper.INDEXPAZAR_OPENED_POPUPS_COMPANYIDS,
          JSON.stringify(state.openedPopupsCompanyIds)
        )
      }
    },
    clearOpenedPopupsCompanyIds: state => {
      state.openedPopupsCompanyIds = []
      ClientStorage.removeItem(ConfigHelper.INDEXPAZAR_OPENED_POPUPS_COMPANYIDS)
    },
  },
  extraReducers: builder => {},
})

export const { setUser } = userSlice.actions
export const { setUserManuelly } = userSlice.actions
export const { addOpenedPopupsCompanyIds } = userSlice.actions
export const { clearOpenedPopupsCompanyIds } = userSlice.actions
export const currentUser = (state: RootState) => state.user.currentUser
export const openedPopupsCompanyIdsRedux = (state: RootState) => state.user.openedPopupsCompanyIds
export default userSlice.reducer
