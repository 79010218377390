import React, { useEffect, useState } from 'react'
import Form from './Form'
import Slider from './Slider'
import { useLocation, useNavigate } from 'react-router-dom'
import { isLoggedIn } from '../../base/proxy/authenticate'
import './styles.scss'
import { BaseComponentWithContextProps, componentWithContext } from '../../base/customHooks/componentHOC'
import { GetMyOrdersApi } from '../MySpecial/MyOrders/services/getMyOrdersApi'
import { formatDate } from '../../base/utils/dataHelper'
import Swal from 'sweetalert2'

function Login(props: BaseComponentWithContextProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const [showLoginPage, setShowLoginPage] = useState(false)

  useEffect(() => {
    isLoggedInLocal()
  }, [])

  // const isLoggedInLocal = () => {
  //   if (isLoggedIn()) {
  //     if (props?.user?.IsApple)
  //       navigate('/AppleNetChannel?page=report', { replace: true, state: { from: location.pathname } })
  //     else
  //       navigate('/homepage', { replace: true, state: { from: location.pathname } })

  //   } else {
  //     setShowLoginPage(true)
  //   }
  // }
  let sdate = ''
  let edate = ''

  const tarih = new Date()
  tarih.setDate(tarih.getDate() - 15)
  sdate = formatDate(tarih)
  edate = formatDate(new Date())

  const initialValues = Object.assign({
    CompanyCode: [],
    OrderDocNO: '',
    DealerCode: '',
    StartDate: sdate,
    EndDate: edate,
  })

  const isLoggedInLocal = async () => {
    if (!isLoggedIn()) {
      setShowLoginPage(true)
      return
    }

    const navigateToPage = url => {
      navigate(url, { replace: true, state: { from: location.pathname } })
    }

    const { IsApple } = props?.user || {}

    if (IsApple) {
      navigateToPage('/AppleNetChannel?page=report')
    } else {
      navigateToPage('/homepage')
      getMyOrders()
    }
  }

  const getMyOrders = async () => {
    try {
      const getOrderListApi = new GetMyOrdersApi({})
      getOrderListApi.getMyOrders(initialValues)
    } catch (error: any) {
      console.error('Error fetching orders:', error)
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while fetching orders.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  return (
    <div>
      {showLoginPage && (
        <div className='login-fg'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-xl-8 col-lg-7 col-md-12 bg'>
                <Slider />
              </div>
              <div className='col-xl-4 col-lg-5 col-md-12 login'>
                <Form showLoading={props.showLoading} hideLoading={props.hideLoading} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default componentWithContext(Login)
