import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IListUser, IUserList } from '../models/response/getUserListResponseModel'

export class PostUserListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/user/list',
      requesterInfo,
    })
  }

  public async postUsers(request: IListUser): Promise<IUserList[]> {
    return await this.postAsync<IUserList[]>(request)
  }
}
