import React from 'react'
import './style.scss'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import { useAppSelector } from '../../../redux/app/hooks'
import { currentUser } from '../../../redux/slices/user/userSlice'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'

function Index(props: BaseComponentWithContextProps) {
  const user = useAppSelector(currentUser)
  const t = translate(Translations)
  return (
    <React.Fragment>
      {/* <div className="row  m-t-20">
        <p style={{ fontSize: "1.3rem" }}>
          Firmanıza ait bilgilleri güncellemek için firma cari kodunuz, vergi
          numaranızı, firma adınız ve güncel bilgilerinizi
          eral.urem@index.com.tr adresine mail yolu ile iletebilirsiniz
          <br />
          Mail teyidi için: 0 (212) 331 23 61 (Eral Ürem)
        </p>
      </div> */}
      {/* <div className='seperator d-none d-lg-block'></div> */}
      <div className='row justify-content-start justify-content-lg-start'>
        
          <div
            className='background-white px-3 pt-2 pb-1'
            style={{
              // border: '1px solid #E0E0E2',
              cursor: 'text',
            }}
          >
            <span style={{ color: '#EE3439', fontWeight: '500' }}>Hesap Kodu:&nbsp;&nbsp;</span>
          <span style={{ color: '#616161', fontWeight: '500' }}>{props.user?.IsRemote ? 'Seçili Bayi: ' + props.user?.AccountCodeRemote.replace('0000', '') : props.user.AccountCode && props.user.AccountCode.replace("0000", "")}</span>
          </div>
        
        {/* <div className="col-auto">
          <div className="productButton">
            <button
              type="button"
              className="IPButton_Button__GWRch background-white px-3 pt-2 pb-1"
              style={{
                border: "1px solid #E0E0E2",
              }}
            >
              <span style={{ color: "#EE3439", fontWeight: "500" }}>
                Kredi Limiti:&nbsp;&nbsp;
              </span>
              <span style={{ color: "#616161", fontWeight: "500" }}>
                10.000,00 USD
              </span>
            </button>
          </div>
        </div> */}
      </div>
      {/* <section
        className="section-back-grey text-left"
        style={{ boxShadow: "none", background: "transparent" }}
      >
        <span className="ml-3" style={{ color: "#616161", fontWeight: "500" }}>
          İnternet deneme kartı
        </span>
        <div className="seperator m-0"></div>
      </section> */}
      <div className='pageTitle pl-0 pt-3'>
        <span>{user.DealerName ? user.DealerName : user.Name}</span>
      </div>
    </React.Fragment>
  )
}

export default componentWithContext(Index)
