import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IXmlData } from '../models/models'

export class InsertUpdateXmlGeneralExceptionalsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/InsertUpdateXmlGeneralExceptionals',
      requesterInfo,
    })
  }

  public async insertUpdateXmlGeneralExceptionals(request: IXmlData): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
