import React, { useState } from 'react'
import { Spinner } from '../../components/Spinner'
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks'
import {
  Basket,
  ISetBasketManagementByIdsRequest,
  ISwitchBasketRequest,
  ISetBasketManagementByIdsResponse,
  ISwitchBasketResponse,
  ISimpleBasket,
} from '../../redux/slices/basket/basketApi'
import {
  IAddItemToBasketRequest,
  IReduceProductQuantityOnBasketRequest,
  IIncreaseProductQuantityOnBasketRequest,
} from '../../redux/slices/basket/basketService'
import {
  getBasketCountRedux,
  addItemToBasketRedux,
  getFreshBasketRedux,
  removeItemFromBasketRedux,
  clearBasketRedux,
  switchBasketRedux,
  copyMoveOrDeleteFromBasketRedux,
  reduceProductQuantityOnBasketRedux,
  increaseProductQuantityOnBasketRedux,
} from '../../redux/slices/basket/basketSlice'
import { FavoriteProductsCountRedux, hasAnyFavoriteProductsRedux } from '../../redux/slices/favoriteProduct/favoriteProductSlice'
import { changeTheme, selectedTheme } from '../../redux/slices/theme/themeSlice'
import { currentUser } from '../../redux/slices/user/userSlice'
import {
  DATAGATE_THEME_TYPE,
  DESPEC_THEME_TYPE,
  INDEX_THEME_TYPE,
  IThemeConfig,
  NETEX_THEME_TYPE,
} from '../theme/themeConfig'

export interface BaseComponentWithContextProps {
  user: any
  getFreshBasket: () => Promise<Basket>
  addItemToBasket: (request: IAddItemToBasketRequest) => Promise<ISimpleBasket>
  removeItemFromBasket: (basketItemId: number) => Promise<ISimpleBasket>
  reduceProductQuantity: (request: IReduceProductQuantityOnBasketRequest) => Promise<ISimpleBasket>
  increaseProductQuantity: (requst: IIncreaseProductQuantityOnBasketRequest) => Promise<ISimpleBasket>
  clearBasket: (basketId: number) => Promise<Basket>
  switchBasket: (request: ISwitchBasketRequest) => Promise<ISwitchBasketResponse>
  copyMoveOrDeleteFromBasket: (request: ISetBasketManagementByIdsRequest) => Promise<ISetBasketManagementByIdsResponse>
  basketCount: number
  hasAnyFavoriteProduct: boolean
  FavoriteProductCount: number
  changeAppTheme: (theme: INDEX_THEME_TYPE | DESPEC_THEME_TYPE | NETEX_THEME_TYPE | DATAGATE_THEME_TYPE) => void
  showLoading: () => void
  hideLoading: () => void
  theme: IThemeConfig
}

const componentWithContext =
  (WrappedComponent: any) =>
    ({ ...props }) => {
      const [loading, setLoading] = useState(false)
      const basketCount = useAppSelector(getBasketCountRedux)
      const hasAnyFavoriteProduct = useAppSelector(hasAnyFavoriteProductsRedux)
      const FavoriteProductCount = useAppSelector(FavoriteProductsCountRedux)
      const theme = useAppSelector(selectedTheme)
      const currentUserLocal = useAppSelector(currentUser)
      const dispatch = useAppDispatch()

      const addItemToBasket = async (request: IAddItemToBasketRequest): Promise<ISimpleBasket> => {
        return await dispatch(addItemToBasketRedux(request)).unwrap()
      }

      const removeItemFromBasket = async (basketItemId: number): Promise<ISimpleBasket> => {
        return await dispatch(removeItemFromBasketRedux(basketItemId)).unwrap()
      }

      const getFreshBasket = async (): Promise<Basket> => {
        return await dispatch(getFreshBasketRedux()).unwrap()
      }

      const clearBasket = async (basketId: number): Promise<Basket> => {
        return await dispatch(clearBasketRedux(basketId)).unwrap()
      }

      const reduceProductQuantity = async (request: IReduceProductQuantityOnBasketRequest): Promise<ISimpleBasket> => {
        return await dispatch(reduceProductQuantityOnBasketRedux(request)).unwrap()
      }

      const increaseProductQuantity = async (
        request: IIncreaseProductQuantityOnBasketRequest
      ): Promise<ISimpleBasket> => {
        return await dispatch(increaseProductQuantityOnBasketRedux(request)).unwrap()
      }

      const switchBasket = async (request: ISwitchBasketRequest): Promise<ISwitchBasketResponse> => {
        return await dispatch(switchBasketRedux(request)).unwrap()
      }

      const copyMoveOrDeleteFromBasket = async (
        request: ISetBasketManagementByIdsRequest
      ): Promise<ISetBasketManagementByIdsResponse> => {
        return await dispatch(copyMoveOrDeleteFromBasketRedux(request)).unwrap()
      }

      const changeAppTheme = (theme: INDEX_THEME_TYPE | DESPEC_THEME_TYPE | NETEX_THEME_TYPE | DATAGATE_THEME_TYPE) => {
        dispatch(changeTheme(theme))
      }

      const showLoading = () => {
        setLoading(true)
      }

      const hideLoading = () => {
        setLoading(false)
      }

      const context: BaseComponentWithContextProps = {
        user: currentUserLocal,
        basketCount,
        hasAnyFavoriteProduct,
        FavoriteProductCount,
        addItemToBasket,
        removeItemFromBasket,
        getFreshBasket,
        clearBasket,
        reduceProductQuantity,
        increaseProductQuantity,
        switchBasket,
        copyMoveOrDeleteFromBasket,
        changeAppTheme,
        showLoading,
        hideLoading,
        theme,
      }

      return (
        <React.Fragment>
          <Spinner loading={loading} />
          <WrappedComponent {...props} {...context} />
        </React.Fragment>
      )
    }

export { componentWithContext }
