import React, { useMemo, useState, useEffect } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Table from '../../../../../components/Table'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPButton from '../../../../../components/IPButton'
import { ISelectOption } from '../../../HomeAndSiteOperation/BulkAdding/components/BulkAdding'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPInput from '../../../../../components/IPInput'
import { IApproveRejectXmlDemandRequest, IGetXmlInfoDetailsAdminRequest, IGetXmlInfoDetailsAdminResponse } from '../models/models'
import InputCampaign from '../../../../../components/InputCampaign'
import { getDateByTimezoneOffset, toDateTimeLocal } from '../../../../../base/utils/dataHelper'
import { GetXmlInfoDetailsAdminApi } from '../services/getXmlInfoDetailsAdminApi'
import Swal from 'sweetalert2'
import { ApproveRejectXmlDemandApi } from '../services/approveRejectXmlDemandAdminApi'
import { useNavigate } from 'react-router-dom'

interface IXmlStateListingState {
  // approveStatus: ISelectOption[]
  // approveStatusData: number[]
  dealerCode: string
  xmlStateListingData: IGetXmlInfoDetailsAdminResponse[]
}

function XmlStateListing(props: BaseComponentWithContextProps) {
  const getInitialState = (): IXmlStateListingState => {
    return {
      // approveStatus: [{
      //   value: 0,
      //   label: 'Onay Bekliyor',
      // }],
      dealerCode: "",
      xmlStateListingData: [],
      // approveStatusData: []
    }
  }
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IXmlStateListingState>(intialState)
  const tableColumns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'col1',
      },
      {
        Header: 'Cari Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Başvuran',
        accessor: 'col3',
      },
      {
        Header: 'Başvuru Tarihi',
        accessor: 'col4',
      },
      {
        Header: 'Şirket',
        accessor: 'col5',
      },
      {
        Header: 'Email',
        accessor: 'col6',
      },
      {
        Header: 'Vergi No',
        accessor: 'col7',
      },
      {
        Header: 'Statik IP',
        accessor: 'col8',
      },
      {
        Header: 'Durum',
        accessor: 'col9',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.xmlStateListingData.map((item, index) => {
        const data = {
          col1: index + 1,
          col2: item.DealerSapCode.replace("0000", ""),
          col3: item.CreateUser,
          col4: item.CreateDate.toLocaleString(),
          col5: item.Companies,
          col6: item.Email,
          col7: item.TaxNumber,
          col8: item.IpAddresses,
          col9: item.ApproveStatusId === 0
            ? (
              <div>
                <a
                  data-action-type='edit'
                  role='button'
                  style={{ cursor: 'pointer' }}
                  data-toggle='tooltip'
                  data-original-title="Onayla"
                  onClick={() => approveRejectXmlDemand(item.Id, 0)}
                  className='CellWithComment ml-auto'
                >
                  <i className="fa fa-thumbs-up" style={{ color: "#29db7f", width: "20px" }}></i>
                  {
                    state.xmlStateListingData.length === 1 ?
                      <span className='CellComment' style={{ marginTop: "-60px", marginLeft: "-40px" }}>Onayla</span>
                      : <span className='CellComment'>Onayla</span>
                  }
                </a>
                <a
                  data-action-type='edit'
                  role='button'
                  style={{ cursor: 'pointer' }}
                  data-toggle='tooltip'
                  data-original-title="Red"
                  onClick={() => {
                    Swal.fire({
                      icon: 'question',
                      title: 'Xml başvurusunu reddetmek istediğinize emin misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(async result => {
                      if (result.isConfirmed) {
                        approveRejectXmlDemand(item.Id, 2)
                      }
                    })
                  }}
                  className='CellWithComment'
                >
                  <i className="fa fa-thumbs-down" style={{ color: "red", width: "20px" }}></i>
                  {
                    state.xmlStateListingData.length === 1 ?
                      <span className='CellComment' style={{ marginTop: "-60px", marginLeft: "-40px" }}>Reddet</span>
                      : <span className='CellComment'>Reddet</span>
                  }
                </a>
              </div >
            )
            : item.ApproveStatus,
        }
        return data
      }),
    [state.xmlStateListingData]
  )

  // const approveStatusOptions = [
  //   {
  //     value: 0,
  //     label: 'Onay Bekliyor',
  //   },
  //   {
  //     value: 1,
  //     label: 'Onaylandı',
  //   },
  //   {
  //     value: 2,
  //     label: 'Reddedildi',
  //   }
  //   // {
  //   //   value: 3,
  //   //   label: 'İptal Edildi',
  //   // }
  // ]

  const getXmlInfoDetailsAdmin = async () => {
    // if (state.approveStatus.length === 0) {
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Lütfen durum seçiniz',
    //     showConfirmButton: true,
    //     allowOutsideClick: false,
    //   })
    //   return
    // }
    // const approveStatus: number[] = []
    // state.approveStatus.map((app) => { return approveStatus.push(+app.value) })
    const req: IGetXmlInfoDetailsAdminRequest = {
      StartDate: startDate && getDateByTimezoneOffset(startDate) as any,
      EndDate: endDate && getDateByTimezoneOffset(endDate) as any,
      ApproveStatus: [1],
      DealerCode: state.dealerCode,
      Take: 9999999,
      Skip: 0
    }
    props.showLoading()
    const getXmlInfoDetailsAdminApi: GetXmlInfoDetailsAdminApi = new GetXmlInfoDetailsAdminApi({})
    await getXmlInfoDetailsAdminApi
      .getXmlInfoDetailsAdmin(req)
      .then(result => {
        setState({ xmlStateListingData: result })
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }
  const navigate: any = useNavigate()
  const approveRejectXmlDemand = async (id: number, status: number) => {
    const req: IApproveRejectXmlDemandRequest = {
      Id: id,
      status: status
    }
    props.showLoading()
    const approveRejectXmlDemandApi: ApproveRejectXmlDemandApi = new ApproveRejectXmlDemandApi({})
    await approveRejectXmlDemandApi
      .approveRejectXmlDemand(req)
      .then((result: any) => {
        if (status === 0) {
          navigate('/admin/UserOperation/XMLDealer', {
            state: { xmlData: result.model, approve: true, id: id },
          })
          return
        }
        Swal.fire({
          icon: 'error',
          title: 'Xml başvuru reddedildi.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })

        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  useEffect(() => {
    getXmlInfoDetailsAdmin()
  }, [])


  return (
    <div className='card'>
      <div className='card-header'>
        <span className='h4'>XML Durum Listeleme</span>
      </div>
      <div className='card-body'>
        <div className='row mb-3'>
          <div className='col-md-3 mb-3'>
            <InputCampaign
              type='datetime-local'
              id='StartDate'
              name='StartDate'
              label={'Başlangıç Tarihi'}
              value={toDateTimeLocal(startDate)}
              onChange={e => setStartDate(new Date(e.target.value))}
              isActive
            />
          </div>
          <div className='col-md-3 mb-3'>
            <InputCampaign
              type='datetime-local'
              id='EndDate'
              name='EndDate'
              label={'Bitiş Tarihi'}
              value={toDateTimeLocal(endDate)}
              onChange={e => setEndDate(new Date(e.target.value))}
              isActive
            />
          </div>
          <div className='col-md-3 mb-3'>
            <IPInput type='text' className='form-control' id='SiparisKodu' placeholder='Bayi Kodu' value={state.dealerCode} onChange={(e) => { setState({ dealerCode: e.target.value }) }} />
          </div>
          {/* <div className='col-md-4 mb-3'>
            <IPSelectBox
              isMulti
              isClearable
              id='ApproveStatus'
              name='ApproveStatus'
              closeMenuOnSelect={false}
              onChangeMulti={(options: ISelectOption[]) => {
                setState({ approveStatus: options })
              }}
              placeholder="Durum Seçiniz..."
              options={approveStatusOptions}
              value={state.approveStatus}
            />
          </div> */}
          <div className='col-12 col-md-3 mb-3'>
            <IPButton
              type='button'
              className='btn btn-success m-l-10'
              text='Göster'
              style={{ width: '25px', marginRight: '10px' }}
              onClick={getXmlInfoDetailsAdmin}
            />
          </div>
        </div>
        <div className='row'>
          <Table columns={tableColumns} data={tableData} currentPage={0} />
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(XmlStateListing)
