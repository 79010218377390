import React, { useEffect, useState, useMemo } from 'react'
import IPInput from '../../../components/IPInput'
import Translations from '../../../translations'
import { translate } from '../../../base/localization'
import IPButton from '../../../components/IPButton'
import { useCustomReducer } from '../../../base/customHooks'
import { formatAsCurrency, formatDate, formatPriceNegatif } from '../../../base/utils/dataHelper'
import { GetOrdersByDealersApi } from './services/getOrdersByDealersApi'
import { ICompany, ICompony, ICreditState, ICreditStateRequest, IDashBoardOrderDataRequest, IDashBoardOrderDataResponse, IDashBoardSapOrderDataResponse, IDashboardRemainderResponse, IOrder } from './models/models'
import { GetCompanyApi } from './services/getCompanyApi'
import { GetCreditStateApi } from './services/getCreditStateApi'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import './style.scss'
import { GetDashboardRemainderApi } from './services/getDashboardRemainderApi'
import { DashBoardOrderDataApi } from './services/dashBoardOrderDataApi'
import Table from '../../../components/Table'
import CurrencyChart from '../../Admin/Dashboard/components/CurrencyChart'
import IPDatePicker from '../../../components/IPDatePicker'
import { DashBoardSapOrderDataApi } from './services/dashBoardSapOrderDataApi'

interface ContactState {
  buttonOrderChange: string
  buttonChange: string
  buttonChannelChange: number
  data: IOrder
  dataPrice: any[]
  total: number
  totalPrice: number
  buttonPriceChange: string
  total2: string
  totalPrice2: string
  index: ICompony
  despec: ICompony
  datagate: ICompony
  netex: ICompony
  companyActive: number
  currencyActive: string
  creditStateData?: ICreditState
  cStateData: ICreditState[]
  amountIndex: string
  amountVgaIndex: string
  amountNetex: string
  amountVgaNetex: string
  amountDatagate: string
  amountVgaDatagate: string
  amountDespec: string
  amountVgaDespec: string
  amount: string
  amountVga: string
  dashboardRemainder: IDashboardRemainderResponse[]
  orderData: IDashBoardOrderDataResponse[]
  toggleTable: boolean
  orderSapData?: IDashBoardSapOrderDataResponse
}

function Contact(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const [data, setData] = useState<IOrder>({
    CompanyOrderDetails: [],
    componyOrdersCount: [],
    dataSapList: [],
  })
  const inititalStartDate = new Date()
  inititalStartDate.setMonth(0)
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)

  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    getOrdersByDealers('usd')
    getCompany()
    getCreditState("USD")
    getDashboardRemainder()
    dashBoardOrderData()
  }, [])
  const [companies, setCompanies] = useState<ICompany[]>([])
  const getOrdersByDealers = async (money) => {
    let sdate = formatDate(new Date())
    let edate = formatDate(new Date())
    const tarih = new Date()
    tarih.setMonth(tarih.getMonth() - 1)
    if (state.buttonChange === 'month') sdate = formatDate(tarih)
    const data = {
      DealerCode: "",
      StartDate: sdate,
      EndDate: edate,
    }
    props.showLoading()
    const dashBoardSapOrderData: DashBoardSapOrderDataApi = new DashBoardSapOrderDataApi({})
    await dashBoardSapOrderData.dashBoardSapOrderData(data).then(res => {
      if (res) {
        setState({ orderSapData: res, buttonOrderChange: money })
        props.hideLoading()
      }
    })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    // if (getOrdersData) {
    //   setData(getOrdersData)
    //   setState({ buttonOrderChange: money })
    // }
  }

  const getCreditState = async (type: "USD" | "TRY" | "EUR") => {
    const req: ICreditStateRequest = {
      Currency: type
    }
    const getCreditStateApi: GetCreditStateApi = new GetCreditStateApi({})

    await getCreditStateApi
      .getCreditState(req)
      .then(async (result: ICreditState[]) => {
        if (result) {
          setState({ cStateData: result, creditStateData: result.find((cre) => { return +cre.Company === state.companyActive }) })
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const getDashboardRemainder = async () => {
    props.showLoading()
    const getDashboardRemainderApi: GetDashboardRemainderApi = new GetDashboardRemainderApi({})
    await getDashboardRemainderApi
      .getDashboardRemainder()
      .then(async (result: IDashboardRemainderResponse[]) => {
        result.map((res) => {
          if (res.Name === "1000")
            return setState({ amountIndex: res.AmountUSD, amountVgaIndex: res.AmountVgaUSD })
          if (res.Name === "1100")
            return setState({ amountDatagate: res.AmountUSD, amountVgaDatagate: res.AmountVgaUSD })
          if (res.Name === "1200")
            return setState({ amountDespec: res.AmountUSD, amountVgaDespec: res.AmountVgaUSD })
          if (res.Name === "2300")
            return setState({ amountNetex: res.AmountUSD, amountVgaNetex: res.AmountVgaUSD })
          if (res.Name === "Toplam")
            return setState({ amount: res.AmountUSD, amountVga: res.AmountVgaUSD })
        })
        setState({ dashboardRemainder: result })
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const selectDashboardRemainder = async (type: "USD" | "TRY" | "EUR",) => {

    setState({
      buttonPriceChange: type === "USD" ? 'usd' : (type === "TRY" ? "try" : "eur"),
    })
    state.dashboardRemainder.map((res) => {
      if (type === "USD") {
        if (res.Name === "1000")
          setState({ amountIndex: res.AmountUSD, amountVgaIndex: res.AmountVgaUSD })
        if (res.Name === "1100")
          setState({ amountDatagate: res.AmountUSD, amountVgaDatagate: res.AmountVgaUSD })
        if (res.Name === "1200")
          setState({ amountDespec: res.AmountUSD, amountVgaDespec: res.AmountVgaUSD })
        if (res.Name === "2300")
          setState({ amountNetex: res.AmountUSD, amountVgaNetex: res.AmountVgaUSD })
        if (res.Name === "Toplam")
          setState({ amount: res.AmountUSD, amountVga: res.AmountVgaUSD })
      }
      if (type === "TRY") {
        if (res.Name === "1000")
          setState({ amountIndex: res.AmountTL, amountVgaIndex: res.AmountVgaTL })
        if (res.Name === "1100")
          setState({ amountDatagate: res.AmountTL, amountVgaDatagate: res.AmountVgaTL })
        if (res.Name === "1200")
          setState({ amountDespec: res.AmountTL, amountVgaDespec: res.AmountVgaTL })
        if (res.Name === "2300")
          setState({ amountNetex: res.AmountTL, amountVgaNetex: res.AmountVgaTL })
        if (res.Name === "Toplam")
          setState({ amount: res.AmountTL, amountVga: res.AmountVgaTL })
      }
      if (type === "EUR") {
        if (res.Name === "1000")
          setState({ amountIndex: res.AmountEURO, amountVgaIndex: res.AmountVgaEURO })
        if (res.Name === "1100")
          setState({ amountDatagate: res.AmountEURO, amountVgaDatagate: res.AmountVgaEURO })
        if (res.Name === "1200")
          setState({ amountDespec: res.AmountEURO, amountVgaDespec: res.AmountVgaEURO })
        if (res.Name === "2300")
          setState({ amountNetex: res.AmountEURO, amountVgaNetex: res.AmountVgaEURO })
        if (res.Name === "Toplam")
          setState({ amount: res.AmountEURO, amountVga: res.AmountVgaEURO })
      }
    })
  }

  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000)
  const dashBoardOrderData = async () => {
    const inititalEndDate = new Date(endDate)
    inititalEndDate.setMonth(inititalEndDate.getMonth() + 1)
    inititalEndDate.setDate(0)
    inititalEndDate.setHours(23)
    inititalEndDate.setMinutes(59)
    inititalEndDate.setSeconds(59)
    const inititalStartDate = new Date(startDate)
    inititalStartDate.setDate(1)
    inititalStartDate.setHours(0)
    inititalStartDate.setMinutes(0)
    inititalStartDate.setSeconds(0)
    const req: IDashBoardOrderDataRequest = {
      StartDate: dateToISO(inititalStartDate),
      EndDate: dateToISO(inititalEndDate),
    }
    const dashBoardOrderDataApi: DashBoardOrderDataApi = new DashBoardOrderDataApi({})
    await dashBoardOrderDataApi
      .dashBoardOrderData(req)
      .then(async (result: IDashBoardOrderDataResponse[]) => {
        setState({ orderData: result })
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const getCompany = async () => {
    const getCompanyApi = new GetCompanyApi({})
    const res = await getCompanyApi.getCompanies()
    if (res) setCompanies(res)
  }

  useEffect(() => {
    setState({
      index: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      datagate: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      despec: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      netex: {
        OrderPrice: 0,
        OrderCount: 0,
      },
    })
    let i = 0
    let k = 0
    let total = 0
    data.componyOrdersCount.map(com => {
      if (com.Company === '1000' || com.Company === '1100' || com.Company === '1200' || com.Company === '2300') {
        i = i + com.OrdercountUSD
        k = com.OrderPriceUSD * 100
        total = total + k
        if (com.Company === '1000')
          setState({
            index: {
              OrderPrice: com.OrderPriceUSD,
              OrderCount: com.OrdercountUSD,
            },
          })
        if (com.Company === '1100')
          setState({
            datagate: {
              OrderPrice: com.OrderPriceUSD,
              OrderCount: com.OrdercountUSD,
            },
          })
        if (com.Company === '1200')
          setState({
            despec: {
              OrderPrice: com.OrderPriceUSD,
              OrderCount: com.OrdercountUSD,
            },
          })
        if (com.Company === '2300')
          setState({
            netex: {
              OrderPrice: com.OrderPriceUSD,
              OrderCount: com.OrdercountUSD,
            },
          })
      }
    })
    setState({
      buttonOrderChange: 'usd',
      buttonChange: 'day',
      data: data,
    })
  }, [data])


  const getInitialState = (): ContactState => {
    return {
      buttonOrderChange: 'usd',
      buttonChange: 'day',
      buttonChannelChange: 0,
      data: data,
      dataPrice: [],
      total: 0,
      totalPrice: 0,
      buttonPriceChange: 'usd',
      total2: '',
      totalPrice2: '',
      index: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      despec: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      datagate: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      netex: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      companyActive: 1000,
      cStateData: [],
      currencyActive: "USD",
      amountIndex: "",
      amountVgaIndex: "",
      amountNetex: "",
      amountVgaNetex: "",
      amountDatagate: "",
      amountVgaDatagate: "",
      amountDespec: "",
      amountVgaDespec: "",
      amount: "",
      amountVga: "",
      dashboardRemainder: [],
      orderData: [],
      toggleTable: false
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ContactState>(intialState)
  const moneyChange = async money => {
    setState({
      index: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      datagate: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      despec: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      netex: {
        OrderPrice: 0,
        OrderCount: 0,
      },
    })
    let i = 0
    let k = 0
    let total = 0
    data.componyOrdersCount.map(com => {
      if (com.Company === '1000' || com.Company === '1100' || com.Company === '1200' || com.Company === '2300') {
        if (money === 'usd') {
          i = i + com.OrdercountUSD
          k = com.OrderPriceUSD * 100
        }
        if (money === 'try') {
          i = i + com.OrderCountTRY
          k = com.OrderPriceTRY * 100
        }
        if (money === 'eur') {
          i = i + com.OrderCountEUR
          k = com.OrderPriceEUR * 100
        }
        total = total + k
        if (com.Company === '1000') {
          if (money === 'usd') {
            setState({
              index: {
                OrderPrice: com.OrderPriceUSD,
                OrderCount: com.OrdercountUSD,
              },
            })
          }
          if (money === 'try') {
            setState({
              index: {
                OrderPrice: com.OrderPriceTRY,
                OrderCount: com.OrderCountTRY,
              },
            })
          }
          if (money === 'eur') {
            setState({
              index: {
                OrderPrice: com.OrderPriceEUR,
                OrderCount: com.OrderCountEUR,
              },
            })
          }
        }
        if (com.Company === '1100') {
          if (money === 'usd') {
            setState({
              datagate: {
                OrderPrice: com.OrderPriceUSD,
                OrderCount: com.OrdercountUSD,
              },
            })
          }
          if (money === 'try') {
            setState({
              datagate: {
                OrderPrice: com.OrderPriceTRY,
                OrderCount: com.OrderCountTRY,
              },
            })
          }
          if (money === 'eur') {
            setState({
              datagate: {
                OrderPrice: com.OrderPriceEUR,
                OrderCount: com.OrderCountEUR,
              },
            })
          }
        }
        if (com.Company === '1200') {
          if (money === 'usd') {
            setState({
              despec: {
                OrderPrice: com.OrderPriceUSD,
                OrderCount: com.OrdercountUSD,
              },
            })
          }
          if (money === 'try') {
            setState({
              despec: {
                OrderPrice: com.OrderPriceTRY,
                OrderCount: com.OrderCountTRY,
              },
            })
          }
          if (money === 'eur') {
            setState({
              despec: {
                OrderPrice: com.OrderPriceEUR,
                OrderCount: com.OrderCountEUR,
              },
            })
          }
        }
        if (com.Company === '2300') {
          if (money === 'usd') {
            setState({
              netex: {
                OrderPrice: com.OrderPriceUSD,
                OrderCount: com.OrdercountUSD,
              },
            })
          }
          if (money === 'try') {
            setState({
              netex: {
                OrderPrice: com.OrderPriceTRY,
                OrderCount: com.OrderCountTRY,
              },
            })
          }
          if (money === 'eur') {
            setState({
              netex: {
                OrderPrice: com.OrderPriceEUR,
                OrderCount: com.OrderCountEUR,
              },
            })
          }
        }
      }
    })
    total = total / 100
    setState({
      buttonOrderChange: money,
      buttonChange: state.buttonChange,
      data: data,
      total: i,
      totalPrice: total,
    })
  }

  const dayChange = async (date, channel) => {
    let sdate = ''
    let edate = ''
    let chan

    if (channel === 30 || channel === 10) {
      chan = channel.toString()
    }
    else {
      chan = null
    }

    if (date === 'day') {
      sdate = formatDate(new Date())
      edate = formatDate(new Date())
    }
    if (date === 'month') {
      const tarih = new Date()
      tarih.setMonth(tarih.getMonth() - 1)
      sdate = formatDate(tarih)
      edate = formatDate(new Date())
    }
    const data = {
      DealerCode: '',
      StartDate: sdate,
      EndDate: edate,
      Channel: chan,
    }
    props.showLoading()
    const dashBoardSapOrderData: DashBoardSapOrderDataApi = new DashBoardSapOrderDataApi({})
    await dashBoardSapOrderData.dashBoardSapOrderData(data).then(res => {
      if (res) {
        setState({ orderSapData: res, buttonChange: date })
      }
      props.hideLoading()
    })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const selectCompany = async (company: number) => {
    setState({ creditStateData: state.cStateData.find((cre) => { return +cre.Company === company }), companyActive: company })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Ay',
        accessor: 'col2',
      },
      {
        Header: 'Sipariş Adedi',
        accessor: 'col3',
      },
      {
        Header: 'Tutar(TRY)',
        accessor: 'col4',
      },
      {
        Header: 'Tutar(USD)',
        accessor: 'col5',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.orderData.map((item: IDashBoardOrderDataResponse, index) => ({
        col1: index + 1,
        col2:
          item.Month === 1
            ? "Ocak"
            : (item.Month === 2
              ? "Şubat"
              : (item.Month === 3
                ? "Mart"
                : (item.Month === 4
                  ? "Nisan"
                  : (item.Month === 5
                    ? "Mayıs"
                    : (item.Month === 6
                      ? "Haziran"
                      : (item.Month === 7
                        ? "Temmuz"
                        : (item.Month === 8
                          ? "Ağustos"
                          : (item.Month === 9
                            ? "Eylül"
                            : (item.Month === 10
                              ? "Ekim"
                              : (item.Month === 11
                                ? "Kasım"
                                : "Aralık")))))))))),
        col3: item.OrderCount,
        col4: item.OrderAmountTL,
        col5: item.OrderAmountUSD
      })),
    [state.orderData]
  )

  const orderPriceAndQuantity = (title: string, value: string) => {
    const orderSapData = state.orderSapData ? (state.orderSapData.CompanyOrderDetails.length > 0 ? state.orderSapData.CompanyOrderDetails : []) : [];
    const orderSapDataFindIndex = orderSapData.findIndex((com) => { return com.Name === String(value) });
    const orderSapDataFind = orderSapData.find((com) => { return com.Name === String(value) });
    let datas: any = 0
    let datasPrice: any = state.buttonOrderChange === "usd"
      ? "0.00 $"
      : (state.buttonOrderChange === "try"
        ? "0.00 ₺"
        : "0.00 €")
    if (orderSapDataFindIndex > -1) {
      if (title === "Quantity") {
        datas = (state.buttonOrderChange === "usd"
          ? (orderSapDataFind
            ? orderSapDataFind.USDCount
            : 0)
          : (state.buttonOrderChange === "try"
            ? (orderSapDataFind
              ? orderSapDataFind.TLCount
              : 0)
            : (orderSapDataFind
              ? orderSapDataFind.EUROCount
              : 0)))
      } else {
        datasPrice = (state.buttonOrderChange === "usd"
          ? (orderSapDataFind
            ? orderSapDataFind.USDPrice
            : "0.00 $")
          : (state.buttonOrderChange === "try"
            ? (orderSapDataFind
              ? orderSapDataFind.TLPrice
              : "0.00 ₺")
            : (orderSapDataFind
              ? orderSapDataFind.EUROPrice
              : "0.00 ₺"))
        )
      }
    }
    if (title === "Quantity") return datas
    return datasPrice
  }

  return (
    <React.Fragment>
      <h5>Kredi Limit Bilgilerim</h5>
      <nav className='currency--tab-menu'>
        <ul>
          <li
            onClick={() => {
              getCreditState("USD")
              setState({ currencyActive: "USD" })
            }}
            className={`dropdown-submenu currency-tab ${state.currencyActive === "USD" ? 'active-currency-tab' : ''}`}
          >
            <span>USD</span>
          </li>
          <li
            onClick={() => {
              getCreditState("TRY")
              setState({ currencyActive: "TRY" })
            }}
            className={`dropdown-submenu currency-tab ${state.currencyActive === "TRY" ? 'active-currency-tab' : ''}`}
          >
            <span>TRY</span>
          </li>
          <li
            onClick={() => {
              getCreditState("EUR")
              setState({ currencyActive: "EUR" })
            }}
            className={`dropdown-submenu currency-tab ${state.currencyActive === "EUR" ? 'active-currency-tab' : ''}`}
          >
            <span>EUR</span>
          </li>
        </ul>
      </nav>
      <nav className='company--tab-menu'>

        <ul>
          <li
            onClick={() => selectCompany(1000)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1000 ? 'active-company-tab' : ''}`}
          >
            <span>İNDEX</span>
          </li>
          <li
            onClick={() => selectCompany(1100)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1100 ? 'active-company-tab' : ''}`}
          >
            <span>DATAGATE</span>
          </li>
          <li
            onClick={() => selectCompany(1200)}
            className={`dropdown-submenu company-tab ${state.companyActive === 1200 ? 'active-company-tab' : ''}`}
          >
            <span>DESPEC</span>
          </li>
          <li
            onClick={() => selectCompany(2300)}
            className={`dropdown-submenu company-tab ${state.companyActive === 2300 ? 'active-company-tab' : ''}`}
          >
            <span>NETEX</span>
          </li>
        </ul>
      </nav>
      {
        state.creditStateData ?
          <div className='row'>
            <div className='col-md-4'>
              <label className='form-label'>Kredi Limiti</label>
              <IPInput type='text' value={formatAsCurrency(state.creditStateData.SKREDI_LIMIT, state.currencyActive === "USD" ? "USD" : (state.currencyActive === "TRY" ? "TRY" : "EUR"))} disabled={true} />
            </div>
            <div className='col-md-4'>
              <label className='form-label'>Kullanılabilir Limit</label>
              <IPInput type='text' value={formatAsCurrency(state.creditStateData.Kalan_Tutar, state.currencyActive === "USD" ? "USD" : (state.currencyActive === "TRY" ? "TRY" : "EUR"))} disabled={true} />
            </div>
            <div className='col-md-4'>
              <label className='form-label'>Bakiye</label>
              <IPInput type='text' value={formatAsCurrency(state.creditStateData.RPR_PB_BAK, state.currencyActive === "USD" ? "USD" : (state.currencyActive === "TRY" ? "TRY" : "EUR"))} disabled={true} />
            </div>
          </div>
          : "Veri Bulunamadı"
      }
      <div className='special-home'>
        <div className='d-none d-lg-block'>
          <div className='d-flex justify-content-between m-t-15'>
            <div className='d-flex m-t-15'>
              <div className='m-r-10'>
                {state.buttonOrderChange === 'usd' ? (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='usd'
                    onClick={() => moneyChange('usd')}
                    text='USD'
                    style={{
                      backgroundColor: '#008000',
                      color: 'white',
                    }}
                  />
                ) : (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='usd'
                    onClick={() => moneyChange('usd')}
                    text='USD'
                  />
                )}
              </div>
              <div className='m-r-10'>
                {state.buttonOrderChange === 'try' ? (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='try'
                    onClick={() => moneyChange('try')}
                    text='TRY'
                    style={{
                      backgroundColor: 'rgba(218, 41, 28)',
                      color: 'white',
                    }}
                  />
                ) : (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='try'
                    onClick={() => moneyChange('try')}
                    text='TRY'
                  />
                )}
              </div>
              {/* <div className='m-r-10'>
                {state.buttonOrderChange === 'eur' ? (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='eur'
                    onClick={() => moneyChange('eur')}
                    text='EUR'
                    style={{
                      backgroundColor: '#182747',
                      color: 'white',
                    }}
                  />
                ) : (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='eur'
                    onClick={() => moneyChange('eur')}
                    text='EUR'
                  />
                )}
              </div> */}
            </div>
            <div className='d-flex  m-t-15'>
              <div className='m-r-10'>
                {state.buttonChannelChange === 0 ? (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='all'
                    onClick={() => {
                      dayChange(state.buttonChange, 0)
                      setState({ buttonChannelChange: 0 })
                    }}
                    text='HEPSİ'
                    style={{
                      backgroundColor: 'blue',
                      color: 'white',
                    }}
                  />
                ) : (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='all'
                    onClick={() => {
                      dayChange(state.buttonChange, 0)
                      setState({ buttonChannelChange: 0 })
                    }}
                    text='HEPSİ'
                  />
                )}
              </div>
              <div className='m-r-10'>
                {state.buttonChannelChange === 30 ? (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='b2b'
                    onClick={() => {
                      dayChange(state.buttonChange, 30)
                      setState({ buttonChannelChange: 30 })
                    }}
                    text='WEB'
                    style={{
                      backgroundColor: '#008000',
                      color: 'white',
                    }}
                  />
                ) : (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='b2b'
                    onClick={() => {
                      dayChange(state.buttonChange, 30)
                      setState({ buttonChannelChange: 30 })
                    }}
                    text='WEB'
                  />
                )}
              </div>
              <div className='m-r-10'>
                {state.buttonChannelChange === 10 ? (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='sap'
                    onClick={() => {
                      dayChange(state.buttonChange, 10)
                      setState({ buttonChannelChange: 10 })
                    }}
                    text='OFİS'
                    style={{
                      backgroundColor: 'rgba(218, 41, 28)',
                      color: 'white',
                    }}
                  />
                ) : (
                  <IPButton
                    className='form-control special-home-button-left'
                    name='sap'
                    onClick={() => {
                      dayChange(state.buttonChange, 10)
                      setState({ buttonChannelChange: 10 })
                    }}
                    text='OFİS'
                  />
                )}
              </div>
            </div>
            <div className='d-flex m-t-15'>
              <div className='m-r-10'>
                {state.buttonChange === 'day' ? (
                  <IPButton
                    className='form-control special-home-button-right'
                    name='day'
                    onClick={() => dayChange('day', state.buttonChannelChange)}
                    text='Bugün'
                    style={{
                      backgroundColor: 'rgba(218, 41, 28)',
                      color: 'white',
                      borderRadius: '21.5px 21.5px 0 0 !important',
                    }}
                  />
                ) : (
                  <IPButton
                    className='form-control special-home-button-right'
                    name='day'
                    onClick={() => dayChange('day', state.buttonChannelChange)}
                    text='Bugün'
                  />
                )}
              </div>
              <div className='m-r-10'>
                {state.buttonChange === 'month' ? (
                  <IPButton
                    className='form-control special-home-button-right'
                    name='month'
                    onClick={() => dayChange('month', state.buttonChannelChange)}
                    text='Son 1 Ay'
                    style={{
                      backgroundColor: 'rgba(218, 41, 28)',
                      color: 'white',
                      marginLeft: '10px',
                      borderRadius: '21.5px 21.5px 0 0 !important',
                    }}
                  />
                ) : (
                  <IPButton
                    className='form-control special-home-button-right'
                    name='month'
                    onClick={() => dayChange('month', state.buttonChannelChange)}
                    text='Son 1 Ay'
                    style={{ marginLeft: '10px' }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='d-block d-lg-none'>
          <div className='d-flex row'>
            <div className='col-md-6 col-6'>
              {state.buttonChange === 'day' ? (
                <IPButton
                  className='form-control special-home-button-right'
                  name='day'
                  onClick={() => dayChange('day', state.buttonChannelChange)}
                  text='Bugün'
                  style={{
                    backgroundColor: 'rgba(218, 41, 28)',
                    color: 'white',
                  }}
                />
              ) : (
                <IPButton
                  className='form-control special-home-button-right'
                  name='day'
                  onClick={() => dayChange('day', state.buttonChannelChange)}
                  text='Bugün'
                />
              )}
            </div>
            <div className='col-md-6 col-6'>
              {state.buttonChange === 'month' ? (
                <IPButton
                  className='form-control special-home-button-right'
                  name='month'
                  onClick={() => dayChange('month', state.buttonChannelChange)}
                  text='Son 1 Ay'
                  style={{
                    backgroundColor: 'rgba(218, 41, 28)',
                    color: 'white',
                  }}
                />
              ) : (
                <IPButton
                  className='form-control special-home-button-right'
                  name='month'
                  onClick={() => dayChange('month', state.buttonChannelChange)}
                  text='Son 1 Ay'
                />
              )}
            </div>
          </div>
          <div className='d-flex m-t-15 overflow-x-scroll'>
            <div className='m-r-10'>
              {state.buttonOrderChange === 'usd' ? (
                <IPButton
                  className='form-control special-home-button-left'
                  name='usd'
                  onClick={() => moneyChange('usd')}
                  text='USD'
                  style={{
                    backgroundColor: '#008000',
                    color: 'white',
                  }}
                />
              ) : (
                <IPButton
                  className='form-control special-home-button-left'
                  name='usd'
                  onClick={() => moneyChange('usd')}
                  text='USD'
                />
              )}
            </div>
            <div className='m-r-10'>
              {state.buttonOrderChange === 'try' ? (
                <IPButton
                  className='form-control special-home-button-left'
                  name='try'
                  onClick={() => moneyChange('try')}
                  text='TRY'
                  style={{
                    backgroundColor: 'rgba(218, 41, 28)',
                    color: 'white',
                  }}
                />
              ) : (
                <IPButton
                  className='form-control special-home-button-left'
                  name='try'
                  onClick={() => moneyChange('try')}
                  text='TRY'
                />
              )}
            </div>
            {/* <div className='m-r-10'>
              {state.buttonOrderChange === 'eur' ? (
                <IPButton
                  className='form-control special-home-button-left'
                  name='eur'
                  onClick={() => moneyChange('eur')}
                  text='EUR'
                  style={{
                    backgroundColor: '#182747',
                    color: 'white',
                  }}
                />
              ) : (
                <IPButton
                  className='form-control special-home-button-left'
                  name='eur'
                  onClick={() => moneyChange('eur')}
                  text='EUR'
                />
              )}
            </div> */}
          </div>
        </div>

        <div
          style={{
            border: '1px solid #F0F0F1',
            backgroundColor: 'white',
            padding: '20px',
          }}
        >
          <div className='row'>
            <div className='col-lg-6 col-12'>
              <b className='d-block table-title'>Sipariş Sayıları</b>
              <table className='table table-bordered d-sm-block d-lg-table table-responsive dataTable XmlTable'>
                <tbody>
                  <tr role='row'>
                    <td
                      style={{
                        width: '100px',
                        backgroundColor: '#F1F2F2',
                        height: 300,
                      }}
                      rowSpan={companies.length + 1}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: (companies.length - 1) * 75 + 'px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                        <p
                          style={{
                            fontSize: '1.3rem',
                            color: '#00A843',
                            fontWeight: '600',
                          }}
                        >
                          {orderPriceAndQuantity("Quantity", "Toplam")}
                        </p>
                      </div>
                    </td>
                  </tr>
                  {companies
                    .filter(com => {
                      return com.Name !== 'Index Group'
                    })
                    .map(data => (
                      <tr className='' key={data.Id}>
                        <td style={{ verticalAlign: 'middle' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <span style={{ color: '#676767', fontWeight: '500' }}>{data.Name}</span>
                            <p
                              style={{
                                fontSize: '1.3rem',
                                color: '#00A843',
                                fontWeight: '600',
                                marginBottom: 0,
                                wordBreak: 'break-word',
                              }}
                            >
                              {orderPriceAndQuantity("Quantity", String(data.Code))}
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className='col-12 col-lg-6'>
              <b className='d-block table-title'>Sipariş Tutarları</b>
              <table className='table table-bordered d-sm-block d-lg-table dataTable table-responsive XmlTable'>
                <tbody>
                  <tr role='row'>
                    <td
                      style={{
                        width: '100px',
                        backgroundColor: '#F1F2F2',
                        height: 300,
                      }}
                      rowSpan={companies.length + 1}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: (companies.length - 1) * 75 + 'px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                        <p
                          style={{
                            fontSize: '1.3rem',
                            color: '#00A843',
                            fontWeight: '600',
                          }}
                        >
                          {orderPriceAndQuantity("Price", "Toplam")}
                        </p>
                      </div>
                    </td>
                  </tr>
                  {companies
                    .filter(com => {
                      return com.Name !== 'Index Group'
                    })
                    .map(data => (
                      <tr className='' key={data.Id}>
                        <td style={{ verticalAlign: 'middle' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <span style={{ color: '#676767', fontWeight: '500' }}>{data.Name}</span>
                            <p
                              style={{
                                fontSize: '1.3rem',
                                color: '#00A843',
                                fontWeight: '600',
                                marginBottom: 0,
                                wordBreak: 'break-word',
                              }}
                            >
                              {orderPriceAndQuantity("Price", data.Code)}
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-between m-t-15 overflow-x-scroll'>
          <div className='d-flex m-t-15'>
            <div className='m-r-10'>
              {state.buttonPriceChange === 'usd' ? (
                <IPButton
                  className='form-control special-home-button-left'
                  name='usd'
                  onClick={() =>
                    selectDashboardRemainder("USD")
                  }
                  text='USD'
                  style={{
                    backgroundColor: '#008000',
                    color: 'white',
                  }}
                />
              ) : (
                <IPButton
                  className='form-control special-home-button-left'
                  name='usd'
                  onClick={() =>
                    selectDashboardRemainder("USD")
                  }
                  text='USD'
                />
              )}
            </div>
            <div className='m-r-10'>
              {state.buttonPriceChange === 'try' ? (
                <IPButton
                  className='form-control special-home-button-left'
                  name='try'
                  onClick={() =>
                    selectDashboardRemainder("TRY")
                  }
                  text='TRY'
                  style={{
                    backgroundColor: 'rgba(218, 41, 28)',
                    color: 'white',
                  }}
                />
              ) : (
                <IPButton
                  className='form-control special-home-button-left'
                  name='try'
                  onClick={() =>
                    selectDashboardRemainder("TRY")
                  }
                  text='TRY'
                />
              )}
            </div>
            {/* <div className='m-r-10'>
              {state.buttonPriceChange === 'eur' ? (
                <IPButton
                  className='form-control special-home-button-left'
                  name='eur'
                  onClick={() =>
                    selectDashboardRemainder("EUR")
                  }
                  text='EUR'
                  style={{
                    backgroundColor: '#182747',
                    color: 'white',
                  }}
                />
              ) : (
                <IPButton
                  className='form-control special-home-button-left'
                  name='eur'
                  onClick={() =>
                    selectDashboardRemainder("EUR")
                  }
                  text='EUR '
                />
              )}
            </div> */}
          </div>
        </div>
        <div
          style={{
            border: '1px solid #F0F0F1',
            backgroundColor: 'white',
            padding: '20px',
          }}
        >
          <div className='row '>
            <div className='col-12 col-lg-6'>
              <b className='d-block table-title'>Kredi Durumum</b>
              <table className='table table-bordered d-sm-block d-lg-table table-responsive dataTable XmlTable'>
                <tbody>
                  <tr role='row'>
                    <td
                      style={{
                        width: '100px',
                        backgroundColor: '#F1F2F2',
                        height: 300,
                      }}
                      rowSpan={companies.length + 1}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: companies.length * 75 + 'px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                        <p
                          style={{
                            fontSize: '1.3rem',
                            color: '#00A843',
                            fontWeight: '600',
                          }}
                        >
                          {formatPriceNegatif(state.amount, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))}
                        </p>
                      </div>
                    </td>
                  </tr>
                  {companies
                    .filter(com => {
                      return com.Name !== 'Index Group'
                    })
                    .map(data => (
                      <tr className='' key={data.Id}>
                        <td style={{ verticalAlign: 'middle' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <span style={{ color: '#676767', fontWeight: '500' }}>{data.Name}</span>
                            <p
                              style={{
                                fontSize: '1.3rem',
                                color: '#00A843',
                                fontWeight: '600',
                                marginBottom: 0,
                                wordBreak: 'break-word',
                              }}
                            >
                              {data.Name === 'Index'
                                ? formatPriceNegatif(state.amountIndex, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))
                                : data.Name === 'Datagate'
                                  ? formatPriceNegatif(state.amountDatagate, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))
                                  : data.Name === 'Despec'
                                    ? formatPriceNegatif(state.amountDespec, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))
                                    : formatPriceNegatif(state.amountNetex, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))
                              }
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div className='col-12 col-lg-6'>
              <b className='d-block table-title'>VGA</b>
              <table className='table table-bordered d-sm-block d-lg-table table-responsive dataTable XmlTable'>
                <tbody>
                  <tr role='row'>
                    <td
                      style={{
                        width: '100px',
                        backgroundColor: '#F1F2F2',
                        height: 300,
                      }}
                      rowSpan={companies.length + 1}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: companies.length * 75 + 'px',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                        <p
                          style={{
                            fontSize: '1.3rem',
                            color: '#00A843',
                            fontWeight: '600',
                          }}
                        >
                          {formatPriceNegatif(state.amountVga, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))}
                        </p>
                      </div>
                    </td>
                  </tr>
                  {companies
                    .filter(com => {
                      return com.Name !== 'Index Group'
                    })
                    .map(data => (
                      <tr className='' key={data.Id}>
                        <td style={{ verticalAlign: 'middle' }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <span style={{ color: '#676767', fontWeight: '500' }}>{data.Name}</span>
                            <p
                              style={{
                                fontSize: '1.3rem',
                                color: '#00A843',
                                fontWeight: '600',
                                marginBottom: 0,
                                wordBreak: 'break-word',
                              }}
                            >
                              {data.Name === 'Index'
                                ? formatPriceNegatif(state.amountVgaIndex, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))
                                : data.Name === 'Datagate'
                                  ? formatPriceNegatif(state.amountVgaDatagate, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))
                                  : data.Name === 'Despec'
                                    ? formatPriceNegatif(state.amountVgaDespec, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))
                                    : formatPriceNegatif(state.amountVgaNetex, (state.buttonPriceChange === "usd" ? "USD" : state.buttonPriceChange === "try" ? "TRY" : "EUR"))}
                            </p>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className='col-12 col-md-3 mb-3'>
            <IPDatePicker
              selected={startDate}
              // @ts-ignore
              onChange={([startDate, endDate]) => {
                setStartDate(startDate)
                setEndDate(endDate)
              }}
              // @ts-ignore
              selectsRange
              startDate={startDate}
              endDate={endDate}
              dateFormat='MM/yyyy'
              minDate={new Date('2016')}
              maxDate={new Date()}
              showMonthYearPicker
            />
          </div>
          <div className='col-12 col-md-2 mt-1 mb-3'>
            <button
              type='button'
              style={{ width: "100%" }}
              onClick={dashBoardOrderData}
              className='btn btn-success'
            >
              {t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.show')}
            </button>
          </div>
          <div className='col-12 col-md-3 mt-1 mb-3'>
            <button
              type='button'
              onClick={() => {
                setState({ toggleTable: !state.toggleTable })
              }}
              className='btn btn-success'
            >
              Tabloyu {state.toggleTable ? "Kaldır" : "Göster"}
            </button>
          </div>
        </div>
        {
          state.orderData.length > 0 &&
          <div className='col-12'>
            <h5>Toplam Sipariş</h5>
            <CurrencyChart data={state.orderData} />
          </div>
        }
        {state.toggleTable && (
          <div className='col-12'>
            <div className='row '>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment >
  )
}

export default componentWithContext(Contact)
