import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'

export class DeleteAddressApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'address/delete',
      requesterInfo,
    })
  }

  public async deleteAddress(id: number): Promise<boolean> {
    this.props.url = `${this.props.url}/${id}`
    return await this.postAsync<boolean>()
  }
}
