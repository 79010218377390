import { IException } from '../../../base/models'
import { ClientStorage } from '../../../base/storage'
import { AddListToBasketUIViaBundleApi } from '../../../pages/Campaings/services/addListToBasketUIViaBundleApi'
import { CurrencyType } from '../../../pages/MyCard/models/models'
import { IAddListToBasketUI } from '../../../pages/ProductList/models/commonModels'
import { IProduct } from '../../../pages/ProductList/models/response/getProductListResponseModel'
import { AddListToBasketUIApi } from '../../../pages/ProductList/services/addListToBasketUIApi'
import {
  IAddItemToBasketRequest,
  AddItemToBasketService,
  RemoveItemFromBasketService,
  IReduceProductQuantityOnBasketRequest,
  IIncreaseProductQuantityOnBasketRequest,
  ClearBasketService,
  SwitchBasketService,
  SetBasketManageByBasketIdsService,
  GetBasketService,
  ReduceProductQuantityOnBasketService,
  IncreaseProductQuantityOnBasketService,
  ChangeGiftItemOnBasketService,
  GetCampaignListOnBasketService,
  GetApplyCampaignOnBasketService,
} from './basketService'

export interface ICampaignBasketDetailPrice {
  EndUser: number
  EndUserKdv: number
  Normal: number
  NormalKdv: number
  Special: number
  SpecialKdv: number
  EndUserCurrency: number
  EndUserDisplay: string
  NormalCurrency: number
  NormalDisplay: string
  SpecialCurrency: number
  SpecialDisplay: string
  Tax: number
}

export interface ICampaignInfoModel {
  IsCampaign: boolean
  CampaignDescription: string
  CampaignBundleID: number
}

export interface ISimpleCampaign {
  Id: number
  CampaignDescription: string
  IsActive: boolean
}

export interface IBasketDetail {
  BasketDetailPrice: IBasketDetailPrice
  BasketID: number
  CampaignBasketDetailPrice: ICampaignBasketDetailPrice
  CampaignInfoModel: ICampaignInfoModel
  CompanyID: number
  CompanyName: string
  CompanyLogo: string
  Id: number
  IsGiftProduct: boolean
  IsCampaignExtra: boolean
  IsSingleCampaign: boolean
  Product: IProduct
  ProductID: number
  Quantity: number
  WareHauseID: number
  WarehouseName: string
  Currency: any
  OpportunityId?: number
  AvailableCamps: IAvailableCampModels[]
}

export interface ISingleCampaignReplaceRequest {
  Id: number
  BasketId: number
  ProductId: number
  Quantity: number
  CompanyId: number
  WarehauseId: number
  IsCampaign: boolean
  CampaignBundleId: number
  CampaignPrice: number
  IsCampaignExtra: boolean
  IsSingleCampaign: boolean
}

export interface IBasketDetailPrice {
  EndUser: number
  EndUserKdv: number
  Normal: number
  NormalKdv: number
  Special: number
  SpecialKdv: number
  EndUserCurrency: number
  EndUserDisplay: string
  NormalCurrency: number
  NormalDisplay: string
  SpecialCurrency: number
  SpecialDisplay: string
  Tax: number
}

export interface IBasketPrice {
  TL?: BasketPriceModel
  USD?: BasketPriceModel
  EUR?: BasketPriceModel
}

export interface IApplyCampaign {
  DetailId: number
  ProductId: number
  CampaignId: number
}
export interface BasketPriceModel {
  Quantity: number
  Value: number
  ValueDisplay: string
  Kdv: number
  KdvDisplay: string
  Total: number
  Display: string
  Tax: number
  Currency: CurrencyType
}

export interface WarehousesGroup {
  WarehouseID: number
  WarehouseName: string
  BasketID: number
  Quantity: number
  Price: BasketPriceModel
  BasketDetail: IBasketDetail[]
}

export interface CompaniesGroup {
  CompanyID: number
  CompanyLogo: string
  BasketId: number
  Quantity: number
  Price: BasketPriceModel
  Warehouses: WarehousesGroup[]
}

export interface ISimpleBasket {
  AvailableCampModels: IAvailableCampModels[]
  BasketName: string
  // BasketDetails: IBasketDetail[];
  Companies: CompaniesGroup[]
  SuggestProductList: IBasketDetail[]
  SingleCampaignItems: IBasketDetail[]
  Id: number
  BasketCount: number
  IsActive: boolean
  BasketNewPrice: IBasketPrice
  BasketPrice: IBasketPrice
  UserId: number
  DealerId?: number
  CampaignBasketInfo: ICampaignBasketInfo
}

export interface ICampaignBasketInfo {
  IsCampaign: boolean
  CampaignDescription: string
  CampaignSapCode: string
  CampaignBundleID: 0
  IsSingleCampaign: boolean
  CampaignPrice: string
  DiscountRate: string
}
export interface IAvailableCampModels {
  CampaignDescription: string
  Id: number
  IsActive: boolean
}

export interface Basket {
  Id: number
  ActiveBasket: ISimpleBasket
}

export interface ISwitchBasketRequest {
  BasketId: number
}

export interface ISwitchBasketResponse {
  Baskets: ISimpleBasket[]
  BasketUI: ISimpleBasket
}

export interface IAddToBasketRequest {
  ProductId: number
  Quantity: number
}

export interface IAddToBasketResponse { }
export interface IAddToBasketResponse { }

export interface IGetBasketManagementByBasketIdResponse {
  ActiveBasket: ISimpleBasket
  BasketListsForUser: ISimpleBasket[]
}

export interface ISetBasketManagementByIdsRequest {
  BeasketDetailIDList: number[]
  CurrentBasketID: number
  SelectedBasketID: number
  OperationType: number
}

export interface ISetBasketManagementByIdsResponse {
  ActiveBasket: ISimpleBasket
  ActiveBasketUI: ISimpleBasket
  BasketListsForUser: ISimpleBasket[]
}

export interface BasketClearRequest {
  firmId: number
}

export function getBasketApi() {
  return new Promise<Basket>(async (resolve, reject) => {
    const api: GetBasketService = new GetBasketService({})

    try {
      const basket = await api.getBasket()

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}

export function addItemToBasketApi(request: IAddItemToBasketRequest) {
  return new Promise<ISimpleBasket>(async (resolve, reject) => {
    const api: AddItemToBasketService = new AddItemToBasketService({})

    try {
      const basket = await api.addItemToBasket(request)

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}

export function addListToBasketUIViaBundle(request: number) {
  return new Promise<ISimpleBasket>(async (resolve, reject) => {
    const api: AddListToBasketUIViaBundleApi = new AddListToBasketUIViaBundleApi({})

    try {
      const basket = await api.addListToBasketUIViaBundle(request)

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}

export function addListToBasketUIMulti(request: IAddListToBasketUI[]) {
  return new Promise<ISimpleBasket>(async (resolve, reject) => {
    const api = new AddListToBasketUIApi({})

    try {
      const basket = await api.addListToBasketUI(request)

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}

export function removeItemFromBasketApi(basketItemId: number) {
  return new Promise<ISimpleBasket>(async (resolve, reject) => {
    const api: RemoveItemFromBasketService = new RemoveItemFromBasketService({})

    try {
      const basket = await api.removeItemFromBasket(basketItemId)

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}

export function reduceProductQuantityOnBasketApi(request: IReduceProductQuantityOnBasketRequest) {
  return new Promise<ISimpleBasket>(async (resolve, reject) => {
    const api: ReduceProductQuantityOnBasketService = new ReduceProductQuantityOnBasketService({})

    try {
      const basket = await api.reduceProductQuantityOnBasket(request)

      resolve(basket)
    } catch (error: any) {
      reject(error.description)
    }
  })
}

export function increaseProductQuantityOnBasketApi(request: IIncreaseProductQuantityOnBasketRequest) {
  return new Promise<ISimpleBasket>(async (resolve, reject) => {
    const api: IncreaseProductQuantityOnBasketService = new IncreaseProductQuantityOnBasketService({})

    try {
      const basket = await api.increaseProductQuantityOnBasket(request)
      resolve(basket)
    } catch (error: any) {
      reject(error.description)
    }
  })
}

export function ClearBasketApi(basketId: number) {
  return new Promise<Basket>(async (resolve, reject) => {
    const api: ClearBasketService = new ClearBasketService({})

    try {
      const basket = await api.clearBasket(basketId)

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}

export function SwitchBasketApi(request: ISwitchBasketRequest) {
  return new Promise<ISwitchBasketResponse>(async (resolve, reject) => {
    const api: SwitchBasketService = new SwitchBasketService({})

    try {
      const basket = await api.switchBasket(request)

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}

export function SetBasketManageByBasketIdsApi(request: ISetBasketManagementByIdsRequest) {
  return new Promise<ISetBasketManagementByIdsResponse>(async (resolve, reject) => {
    const api: SetBasketManageByBasketIdsService = new SetBasketManageByBasketIdsService({})

    try {
      const basket = await api.setBasketManagement(request)

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}

export function ChangeGiftItemOnBasketApi(request: ISingleCampaignReplaceRequest) {
  return new Promise<ISimpleBasket>(async (resolve, reject) => {
    const api: ChangeGiftItemOnBasketService = new ChangeGiftItemOnBasketService({})

    try {
      const basket = await api.changeGiftItem(request)

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}

export function GetCampaignListOnBasketApi() {
  return new Promise<ISimpleCampaign[]>(async (resolve, reject) => {
    const api: GetCampaignListOnBasketService = new GetCampaignListOnBasketService({})

    try {
      const campaigns = await api.getCampaignList()

      resolve(campaigns)
    } catch (error) {
      reject(error)
    }
  })
}

// export function GetApplyCampaignOnBasketApi(id: number) {
//   return new Promise<ISimpleBasket>(async (resolve, reject) => {
//     const api: GetApplyCampaignOnBasketService = new GetApplyCampaignOnBasketService({})

//     try {
//       const basket = await api.getApplyCampaign(id)

//       resolve(basket)
//     } catch (error) {
//       reject(error)
//     }
//   })
// }

export function GetApplyCampaignOnBasketApi(data: IApplyCampaign) {
  return new Promise<ISimpleBasket>(async (resolve, reject) => {
    const api: GetApplyCampaignOnBasketService = new GetApplyCampaignOnBasketService({})

    try {
      const basket = await api.getApplyCampaign(data)

      resolve(basket)
    } catch (error) {
      reject(error)
    }
  })
}
