import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { binNumberRequest } from '../models/models'

export class SendBinNumberApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'payment/searchBIN',
      requesterInfo,
    })
  }

  public async sendBinNumber(request: binNumberRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
