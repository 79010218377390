import React, { useEffect, useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPTextarea from '../../../../../components/IPTextarea'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { ICompany } from '../../../components/models/models'
import { getCompanyApi } from '../../../components/api'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import { GetWhiteDealerListApi } from '../../../CreditCardPaymentCampaigns/Dealer/services/getWhiteDealerListApi'
import Swal from 'sweetalert2'
import { ISubDealerResponse } from '../models/models'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { AddVirtualPosInfosApi } from '../services/addVirtualPosInfosApi'
import { Link, useNavigate } from 'react-router-dom'
import { excelToJSON } from '../../../../../base/utils/dataHelper'
import IPFile from '../../../../../components/IPFile'

interface ISubDealerAddState {
  companiesOption: ISelectOption | null
  bankOption: ISelectOption | null
  approvalOption: ISelectOption | null
  notes: string
  taxNumberString: string
}

function SubDealerAdd(props: BaseComponentWithContextProps) {
  const getInitialState = (): ISubDealerAddState => {
    return {
      notes: "",
      companiesOption: null,
      bankOption: null,
      approvalOption: null,
      taxNumberString: ""
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ISubDealerAddState>(intialState)
  const t = translate(Translations)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [banks, setBanks] = useState<ISelectOption[]>([])
  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    const data: ICompany[] = []
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map((co) => {
        data.push(co)
      })
    setCompanies(data)
  }
  const approvalStatus = [
    {
      value: 1,
      label: t('pages.admin.bankAndInstallementOperation.subDealerAdd.subDealerAdd.approval'),
    },
    {
      value: 0,
      label: t('pages.admin.bankAndInstallementOperation.subDealerAdd.subDealerAdd.rejection'),
    },
  ]

  useEffect(() => {
    getCompanies()
    getBanks();
  }, [])

  const getBanks = async () => {
    try {
      const res = await new GetWhiteDealerListApi({}).getBanks()
      if (res) {
        const targetBankIDs = [1001, 2801]
        const banks = res.filter((x) => targetBankIDs.includes(x.BankID))
          .map((m) => ({ label: m.BankName, value: m.BankID }))
        if (!banks) return []
        return setBanks(banks)
      }
    } catch (err: any) {
      console.error(err)
    }
  }

  const addDealer = async () => {
    if (!state.bankOption) {
      Swal.fire({
        icon: 'error',
        html: "Lütfen banka seçiniz.",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (state.bankOption && +state.bankOption.value !== 1001 && +state.bankOption.value !== 2801) {
      Swal.fire({
        icon: 'error',
        html: "Lütfen Halk Bankası ve Yapı Kredi bankalarından birini seçiniz.",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!state.companiesOption) {
      Swal.fire({
        icon: 'error',
        html: "Lütfen şirket seçiniz.",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!state.approvalOption) {
      Swal.fire({
        icon: 'error',
        html: "Lütfen vergi numarası giriniz.",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const data = (state.taxNumberString.split('\n'))
    const req: ISubDealerResponse = {
      Id: 0,
      CompanyCode: state.companiesOption ? String(state.companiesOption.value) : "",
      BankRef: state.bankOption ? +state.bankOption.value : 0,
      BankName: state.bankOption ? state.bankOption.label : 0,
      TaxNumberList: data,
      Approval: state.approvalOption ? +state.approvalOption.value : 0,
      Notes: state.notes
    }
    props.showLoading()
    const addVirtualPosInfosApi: AddVirtualPosInfosApi = new AddVirtualPosInfosApi({})
    addVirtualPosInfosApi
      .addVirtualPosInfos(req)
      .then((result: any) => {
        Swal.fire({
          icon: 'success',
          title: 'Alt Bayi Eklendi',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  // const onCreateOption = (option, updateFunc) => {
  //   updateFunc(prevState => {
  //     const newData = {
  //       value: option[option.length - 1].value,
  //       label: option[option.length - 1].label,
  //     }
  //     if (prevState && prevState.length > 0) {
  //       for (const state of prevState) {
  //         if (state.value === newData.value) return [...prevState]
  //         return [...prevState, newData]
  //       }
  //     }
  //     return [newData]
  //   })
  // }

  const handleFiles = async (files: File[]) => {
    const vergiNo: any = await excelToJSON(files[0])
    const dt: ISelectOption[] = []

    vergiNo.forEach(code => {
      code.forEach(c => {
        const codes = c.VergiNo;
        if (dt.findIndex(d => d.label === codes) === -1) {
          dt.push({ value: codes, label: codes });
        }
      });
    });

    const taxNumberString = dt.map(item => item.label).join('\n');
    setState({ taxNumberString: taxNumberString });
  }

  return (
    <div className='container'>
      <div className='mb-3'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header d-flex justify-content-between'>
              <span className='h4'>{t('pages.admin.bankAndInstallementOperation.subDealerAdd.subDealerAdd.header')}</span>
              <Link to='/admin/BankAndInstallementOperation/SubDealerControl'>
                <IPButton
                  type='button'
                  className='btn btn-primary m-l-10'
                  text='Listeye Git'
                  style={{ width: '25px', marginRight: '10px' }}
                />
              </Link>
            </div>
            <div className='card-body'>
              <form id='id1' className='formform' noValidate>
                <div className='form-row'>
                  <div className='col-lg-4'>
                    <label className='form-label'>Şirket</label>
                    <IPSelectBox
                      isClearable
                      options={
                        companies &&
                        companies.map((item: ICompany) => ({
                          label: item.Name,
                          value: `${item.Code}`,
                        }))
                      }
                      value={state.companiesOption}
                      onChangeSingle={option => setState({ companiesOption: option })}
                    />
                  </div>
                  <div className='form-group col-md-4'>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.bankAndInstallementOperation.subDealerAdd.subDealerAdd.bankName')}
                    </label>
                    <IPSelectBox
                      isClearable
                      options={banks}
                      value={state.bankOption}
                      onChangeSingle={(option) => {
                        if (option && +option.value !== 1001 && +option.value !== 2801) {
                          if (!state.bankOption)
                            return setState({ bankOption: null })
                          return
                        }
                        setState({ bankOption: option })
                      }}
                    />
                  </div>
                  <div className='form-group col-md-4'>
                    <label className='labeltext'>
                      {t('pages.admin.bankAndInstallementOperation.subDealerAdd.subDealerAdd.approvalRejection')}
                    </label>
                    <IPSelectBox
                      isClearable
                      options={approvalStatus}
                      value={state.approvalOption}
                      onChangeSingle={option => setState({ approvalOption: option })}
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-lg-6'>
                    <label className='labeltext'>
                      {t('pages.admin.bankAndInstallementOperation.subDealerAdd.subDealerAdd.taxNumber')}
                    </label>
                    <IPTextarea
                      value={state.taxNumberString}
                      onChange={e => {
                        var newParams = state.taxNumberString
                        newParams = e.target.value
                        setState({ taxNumberString: newParams })
                      }}
                      style={{ height: "24rem" }}
                    />
                  </div>
                  <div className='form-group col-lg-6'>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.bankAndInstallementOperation.subDealerAdd.subDealerAdd.notes')}
                    </label>
                    <IPTextarea
                      value={state.notes}
                      style={{ height: "24rem" }}
                      onChange={(e) => { setState({ notes: e.target.value }) }} />
                  </div>
                </div>
                <div className='form-row mt-1 mb-3'>
                  <div className='form-group col-md-6'>
                    <IPFile
                      id='FileInputProductCode'
                      multiple={false}
                      handleFiles={e => handleFiles(e)}
                      removeAfterSelect={true}
                      className='productCodeFileInput'
                      label="Excel'den Yükle"
                    />
                    <a
                      style={{ color: 'red' }}
                      target={'_blank'}
                      href='/assetWeb/docs/vergiNo.xlsx'
                      className='infoMarker'
                    >
                      Örnek Excel
                    </a>
                  </div>
                  <div className='form-group col-md-6'>
                    <IPButton
                      text={t('pages.admin.bankAndInstallementOperation.subDealerAdd.subDealerAdd.save')}
                      className='col-md-3 btn btn-success float-right'
                      onClick={addDealer}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(SubDealerAdd)
