import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IHandMadeOrdersRequest, IHandMadeOrdersResponse } from '../models/models'

export class HandMadeOrdersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/handMadeOrders',
      requesterInfo,
    })
  }

  public async handMadeOrders(request: IHandMadeOrdersRequest): Promise<IHandMadeOrdersResponse[]> {
    return await this.postAsync<IHandMadeOrdersResponse[]>(request)
  }
}
