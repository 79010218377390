import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import {
  IUpdateChangePropertiesRequest,
  IUpdatePropertiesRequest,
  IUpdatePropertiesResponse,
  ProductGroups,
} from './models'

export class ProductPropertiesService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/ProductProperties/updateProperties',
      requesterInfo,
    })
  }

  public async updateProperties(req: IUpdatePropertiesRequest): Promise<IUpdatePropertiesResponse[]> {
    return await this.postAsync<IUpdatePropertiesResponse[]>(req)
  }

  public async updateChangeProperties(req: IUpdateChangePropertiesRequest[]): Promise<boolean> {
    this.props.url = 'admin/ProductProperties/updateChangeProperties'
    return await this.postAsync<boolean>(req)
  }
  public async productGroupsList(): Promise<ProductGroups[]> {
    this.props.url = 'admin/product/productGroupsList'
    return await this.getAsync<ProductGroups[]>()
  }
}
