import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { FCVObject } from '../../../ProductOperation/CampaignBundleCrud/services/InsertFcvService'

export class UpdatePaymentTerms extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/updatePaymentTerms',
      requesterInfo,
    })
  }

  public async updatePaymentTerms(request: FCVObject): Promise<FCVObject> {
    return await this.postAsync<FCVObject>(request)
  }
}
