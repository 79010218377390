import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../base/customHooks'
import { BaseComponentWithContextProps } from '../../../../base/customHooks/componentHOC'
import { translate } from '../../../../base/localization'
import { CategoryTree, printElement } from '../../../../base/utils/dataHelper'
import IPInput from '../../../../components/IPInput'
import IPModal from '../../../../components/IPModal'
import IPTextarea from '../../../../components/IPTextarea'
import Translations from '../../../../translations'
import { IProduct, IRecommendedProducts } from '../../../ProductList/models/response/getProductListResponseModel'
import { ISendProductDetailMailRequest } from '../../models/productDetailMailRequest'
import { SendProductDetailMailApi } from '../../services/sendProductDetailMailApi'
import PaymentMethod from '../PaymentMethod/PaymentMethod'
import Slider from '../Slider'
import classes from '../styles/style.module.scss'
import HeaderLastRight from './HeaderLastRight'
import { IProductColorResponse } from '../../../Admin/ProductOperation/ProductColor/models/models'
export interface IHeaderProps {
  product: IProduct
  recommendedProducts: IRecommendedProducts[]
  onProductChange: (propduct: IProduct) => void
  prop: BaseComponentWithContextProps
  IsWin11AndOffice: boolean
  IsOffice365: boolean
  Suggest: any
  productColor: IProductColorResponse[]
  otherProducts: any
}
interface IHeaderLastState {
  Name: string
  Emails: string
  Subject: string
  Note: string
}

const HeaderLast = (props: IHeaderProps) => {
  const { product, recommendedProducts } = props
  const [showMail, setShowMail] = useState(false)

  const t = translate(Translations)
  const item = product.catTree ? CategoryTree(product.catTree) : ''
  const onProductChange = (product: IProduct) => {
    props.onProductChange && props.onProductChange(product)
  }
  const getInitialState = (): IHeaderLastState => {
    return {
      Name: props.prop.user.AccountCode.replace('0000', '') + ' / ' + props.prop.user.DealerName,
      Emails: '',
      Subject: props.product.ProductCode + ' / ' + props.product.Name,
      Note: '',
    }
  }

  const [checkboxState, setCheckboxState] = useState({
    specialPrice: true,
    branchPrice: true,
    listPrice: true,
  })
  // Define a function to update the checkbox state
  const handleCheckboxChange = name => {
    setCheckboxState(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }))

    // Store the individual checkbox state in localStorage
    localStorage.setItem(name, JSON.stringify(!checkboxState[name]))
  }

  // Retrieve the checkbox state from localStorage when the component mounts
  useEffect(() => {
    const specialPriceState = localStorage.getItem('specialPrice')
    const branchPriceState = localStorage.getItem('branchPrice')
    const listPriceState = localStorage.getItem('listPrice')

    if (specialPriceState) {
      setCheckboxState(prevState => ({
        ...prevState,
        specialPrice: JSON.parse(specialPriceState),
      }))
    }

    if (branchPriceState) {
      setCheckboxState(prevState => ({
        ...prevState,
        branchPrice: JSON.parse(branchPriceState),
      }))
    }

    if (listPriceState) {
      setCheckboxState(prevState => ({
        ...prevState,
        listPrice: JSON.parse(listPriceState),
      }))
    }
  }, [])

  useEffect(() => {
    if (product.ProductDetailProperties)
      setDescHtml(
        (
          product.ProductDetailProperties.find(details => details.Featurename === 'Açıklama') as any
        )?.FeatureValue.replace(/<[^>]+>/g, '').replace('\n', '')
      )
  }, [product.ProductDetailProperties])

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IHeaderLastState>(intialState)
  const params = useParams()
  const [descHtml, setDescHtml] = useState<string>('')

  const sendProductDetailMail = async () => {
    if (state.Emails.length === 0 || state.Name.length === 0 || state.Subject.length === 0 || state.Note.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tüm bilgileri giriniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.prop.showLoading()
    const req: ISendProductDetailMailRequest = {
      Name: state.Name,
      Emails: state.Emails.replace(' ', '').split(','),
      Subject: state.Subject,
      Note: state.Note,
      ProductId: props.product.Id,
    }
    const sendProductDetailMailApi: SendProductDetailMailApi = new SendProductDetailMailApi({})
    await sendProductDetailMailApi
      .sendProductDetailMail(req)
      .then(async (result: any) => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: result,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          setShowMail(false)
          setState({
            Name: props.prop.user.AccountCode.replace('0000', '') + ' / ' + props.prop.user.DealerName,
            Emails: '',
            Subject: props.product.ProductCode + ' / ' + props.product.Name,
            Note: '',
          })
          props.prop.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.prop.hideLoading()
      })
  }

  const uniqueFeatureIds: string[] = []
  const uniqueFeatureIds2: string[] = []

  const location = useLocation()
  const isOpportunity = location.pathname.includes('opportunit')

  return (
    <React.Fragment>
      <div className='row'>
        <div className='col-md-10 p-0'>
          <p style={{ fontSize: 17, fontWeight: 600 }}>
            {params.type === 'opportunit' ? (
              <div className='row' style={{ float: 'left', margin: '0px', padding: '0px' }}>
                {
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Link to='/' style={{ margin: '0', padding: '0' }}>
                      Ana Sayfa
                    </Link>
                    <div style={{ marginLeft: '5px', marginRight: '5px', fontSize: 'initial' }}>|</div>
                    <Link to='/products?specialFilter=opportunit-market' style={{ margin: '0', padding: '0' }}>
                      Fırsat Pazarı Ürünleri
                    </Link>
                  </div>
                }
              </div>
            ) : (
              item.map((cat, index) => (
                <div className='row' key={cat?.Id} style={{ float: 'left', margin: '0px', padding: '0px' }}>
                  {cat ? (
                    index === 0 || index === 1 ? (
                      <div>
                        {cat.Title}{' '}
                        <p style={{ float: 'right', marginLeft: '5px', marginRight: '5px', fontSize: 'initial' }}>
                          {' '}
                          |{' '}
                        </p>
                      </div>
                    ) : (
                      <Link
                        to={`/products?categoryId=${cat.Id}&global-search=true`}
                        style={{ margin: '0px', padding: '0px' }}
                      >
                        {cat.Title}{' '}
                        {item.length - 1 !== index && (
                          <p style={{ float: 'right', marginLeft: '5px', marginRight: '5px', fontSize: 'initial' }}>
                            {' '}
                            |{' '}
                          </p>
                        )}
                      </Link>
                    )
                  ) : null}
                </div>
              ))
            )}
          </p>

          {/* <p style={{ fontSize: 17, fontWeight: 600 }}>
            <Link to={`/products?categoryId=${product.Category.IsMenu ? product.Category.Id : product.Category.ParentId}`}>
              {product.Category.Name}{product.Category.LongName ? `(${product.Category.LongName})` : ''}
            </Link>
          </p> */}
        </div>
        <div
          className='col-md-2 text-right'
          style={{ top: product.OpportunitPriceList && isOpportunity ? '80px' : '125px', zIndex: '2', left: '10px' }}
        >
          <a style={{ cursor: 'pointer' }} onClick={() => setShowMail(true)} className='p-6'>
            <img style={{ marginTop: '-5px' }} src='/images/icons/epostadetay.svg' alt='' width={40} height={40} />
          </a>
          <a onClick={() => printElement(document.getElementById('Features-Print'))} className='p-3'>
            <img src='/images/icons/yazicidetay.svg' alt='' width={40} height={40} style={{ cursor: 'pointer' }} />
          </a>
        </div>
      </div>
      {/* Yazdırma butonu içeriği */}
      <div className='d-none' id='Features-Print'>
        <div className='row'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='col-12 mb-3'>
                {product && product.BrandDetail && (
                  <img src={product.BrandDetail.ImageUrl} alt='' className='img-fluid' style={{ width: '100px' }} />
                )}
              </div>
              <div className='col-12'>
                <div className={classes.mobilTitle}>
                  <p style={{ fontSize: 21, fontWeight: 500, color: '#000000' }}>
                    {product.IsProductOfOpportunity ? (
                      <p style={{ color: 'red', fontSize: 22, fontWeight: 500 }}>{`Fırsat Ürünü ${product.Name} `}</p>
                    ) : (
                      product.Name
                    )}
                  </p>
                  <p className='mb-5' style={{ fontSize: 18 }}>
                    {t('pages.productdetail.ProductCode')} : {product.ProductCode}{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='mb-3'>
              <img src={product.Image} alt='' className='img-fluid' style={{ width: '250px' }} />
            </div>
          </div>
        </div>
        <div className='row'>
          <h3>Teknik Özellikler</h3>
          <div className='col-lg-12'>
            <table
              className='table table-borderless table-hover'
              style={{ marginBottom: '0px', lineHeight: 'normal', backgroundColor: 'gray', width: '100%' }}
            >
              {product.ProductDetailProperties &&
                product.ProductDetailProperties.filter(detail => {
                  // Filter to keep only unique FeatureIds
                  if (!uniqueFeatureIds.includes(detail.Featurename)) {
                    uniqueFeatureIds.push(detail.Featurename)
                    return true
                  }
                  return false
                })
                  .sort((a, b) => {
                    // FeatureId = 1 olan değeri başa al
                    if (a.Featureid === 1 && b.Featureid !== 1) {
                      return -1
                    } else if (a.Featureid !== 1 && b.Featureid === 1) {
                      return 1
                    }
                    // FeatureValue = "" veya "Tanımsız" ise sıralamada sona koy
                    if (
                      (a.FeatureValue === '' || a.FeatureValue === 'Tanımsız') &&
                      b.FeatureValue !== '' &&
                      b.FeatureValue !== 'Tanımsız'
                    ) {
                      return 1 // a'yı b'nin önüne koy
                    } else if (
                      a.FeatureValue !== '' &&
                      a.FeatureValue !== 'Tanımsız' &&
                      (b.FeatureValue === '' || b.FeatureValue === 'Tanımsız')
                    ) {
                      return -1 // b'yi a'nın önüne koy
                    }
                    // Diğer durumlarda Seqnum'a göre sırala
                    return a.Seqnum - b.Seqnum
                  })
                  .map((detail, index) => (
                    <tbody key={index}>
                      <tr style={{ backgroundColor: index % 2 === 0 ? '#f1f1f1' : 'white' }}>
                        <td
                          className='p-l-20 p-r-20'
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          {detail.Featurename}
                        </td>
                        <td
                          className='p-l-20 p-r-20'
                          style={{
                            fontSize: 16,
                            fontWeight: 500,
                            color: '#000000',
                            textAlign: 'right',
                            whiteSpace: 'normal',
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <ul style={{ listStyle: 'none', padding: 0, margin: 0, flex: 1 }}>
                            {detail.Featurename !== 'Açıklama' ? (
                              detail.Featurename === 'Desteklenen Windows çalışma sistemleri' ? (
                                detail.FeatureValue.split(',').map((feature, index) => (
                                  <li style={{ whiteSpace: 'pre-wrap' }} key={index}>
                                    {feature.trim()}
                                  </li>
                                ))
                              ) : (
                                <li
                                  style={
                                    detail.Featurename === 'Açıklama Diğer'
                                      ? { whiteSpace: 'initial' }
                                      : { whiteSpace: 'pre-wrap' }
                                  }
                                >
                                  {detail.FeatureValue}
                                </li>
                              )
                            ) : (
                              <p
                                dangerouslySetInnerHTML={{ __html: descHtml }}
                                style={{
                                  whiteSpace: 'initial',
                                  fontSize: '12px',
                                  fontWeight: '600',
                                }}
                              ></p>
                            )}
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  ))}
            </table>
          </div>
        </div>
      </div>

      <div className='d-flex row mb-5' style={{ border: '1px solid #DFDFDF' }}>
        <div className='col-lg-6' style={{ background: '#FFFFFF' }}>
          <div className={classes.mobilTitle} style={{ display: 'none' }}>
            <p style={{ fontSize: 21, fontWeight: 500, color: '#000000' }}>
              {product.IsProductOfOpportunity ? (
                <p style={{ color: 'red', fontSize: 22, fontWeight: 500 }}>{`Fırsat Ürünü ${product.Name} `}</p>
              ) : (
                product.Name
              )}
            </p>
            <p className='mb-5' style={{ fontSize: 18 }}>
              {t('pages.productdetail.ProductCode')} : {product.ProductCode}{' '}
            </p>
          </div>
          {/* IMAGES SLİDER TODO */}
          <div className='row d-flex'>
            {/* <div className="col-lg-2 col-2 d-flex flex-column gap-2 ">
               {
                product.Images.map((img, i) => (
                  <Image
                    className="w-50"
                    key={i}
                    src={img.ImageUrl}
                    alt=""
                  />
                ))
              } 

            </div> */}

            <div className='col-lg-12 col-12 productDetailsSlider'>
              <Slider product={product} />
            </div>
            {/* <div className="col-lg-2 col-2 d-flex  flex-column gap-2">
              <div
                className={classes.colorCircle}
                style={{ background: "#0000000" }}
              />
              <div
                className={classes.colorCircle}
                style={{ background: "#FFEAB8" }}
              />
              <div
                className={classes.colorCircle}
                style={{ background: "#C4C4C4" }}
              />
            </div> */}
          </div>
          <hr style={{ width: '100%' }} />

          {/* DESCRIPTION */}
          <div className='p-l-20 p-r-20' id='productDetail'>
            <div className='row productDetailFeatures' style={{ display: 'flex', flexWrap: 'wrap' }}>
              {product.ProductDetailProperties &&
                product.ProductDetailProperties.filter(detail => {
                  // Filter to keep only unique FeatureIds
                  if (
                    !uniqueFeatureIds2.includes(detail.Featurename) &&
                    detail.Featurename !== 'Açıklama' &&
                    detail.Featurename !== 'Açıklama Diğer'
                  ) {
                    uniqueFeatureIds2.push(detail.Featurename)
                    return true
                  }
                  return false
                })
                  .sort((a, b) => {
                    // FeatureId = 1 olan değeri başa al
                    if (a.Featureid === 1 && b.Featureid !== 1) {
                      return -1
                    } else if (a.Featureid !== 1 && b.Featureid === 1) {
                      return 1
                    }

                    // FeatureValue = "" veya "Tanımsız" ise sıralamada sona koy
                    if (
                      (a.FeatureValue === '' || a.FeatureValue === 'Tanımsız') &&
                      b.FeatureValue !== '' &&
                      b.FeatureValue !== 'Tanımsız'
                    ) {
                      return 1 // a'yı b'nin önüne koy
                    } else if (
                      a.FeatureValue !== '' &&
                      a.FeatureValue !== 'Tanımsız' &&
                      (b.FeatureValue === '' || b.FeatureValue === 'Tanımsız')
                    ) {
                      return -1 // b'yi a'nın önüne koy
                    }

                    // Diğer durumlarda Seqnum'a göre sırala
                    return a.Seqnum - b.Seqnum
                  })
                  .map((item, index) =>
                    index < 8 ? (
                      <div className='col-12 col-md-6' style={{ display: 'flex' }}>
                        <p style={{ fontWeight: 600, flex: 1 }}>{item.Featurename + ': '}</p>
                        <p
                          style={{
                            flex: 1,
                            color: '#857d7d',
                            width: 'inherit',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {item.FeatureValue}
                        </p>
                      </div>
                    ) : null
                  )}
              <div
                onClick={() => {
                  document.getElementById('specifications-tab')?.click()
                  setTimeout(() => {
                    document.getElementById('Product-Feature-Product')?.scrollIntoView()
                  }, 200)
                }}
              >
                <Link to='#specifications-tab' style={{ color: 'black', fontWeight: '500' }}>
                  Tüm Teknik Özellikler <i className='icon-arrow-down mt-4'></i>
                </Link>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center gap-2 me-5 my-3'>
            <i className='icon-info mx-3' style={{ fontSize: 25 }}>
              {' '}
            </i>
            <div>
              <p className='mb-0' style={{ fontSize: '1rem' }}>
                Ürünlere ait bilgi ve görseller ilgili ürün üreticisi tarafından tedarik edilmekte olup yalnızca bilgi
                amaçlı olarak bu internet sitesinde yer almaktadır. İlgili üreticinin ürün bilgisi ve görselinde
                değişiklik yapma hakkı saklıdır. Bu bilgi ve görsellerdeki hatalardan Index Bilgisayar Sistemleri Tic.
                A.Ş.- Datagate Bilgisayar A.Ş.-Despec Bilgisayar Pazarlama Tic. A.Ş. - Netex Teknoloji Ürünleri Tic.
                A.Ş. sorumlu tutulamaz. Ürün bilgi ve görselleri izinsiz kullanılamaz, çoğaltılamaz.
              </p>
            </div>
          </div>
        </div>
        <div className={`col-lg-6 ${classes.headerRight}`} style={{ background: ' #F5F5F5' }}>
          <HeaderLastRight
            product={product}
            recommendedProducts={recommendedProducts}
            onProductChange={onProductChange}
            IsWin11AndOffice={props.IsWin11AndOffice}
            IsOffice365={props.IsOffice365}
            checkboxState={checkboxState}
            Suggest={props.Suggest}
            productColor={props.productColor}
            otherProducts={props.otherProducts}
          />
        </div>
      </div>

      {/* <section
        className='section-back-grey background-white py-5  text-left '
        style={{ marginBottom: '10px', boxShadow: 'none' }}
      >
        {' '}
        <div className='container'>
          <h4 className='section-back-grey-title'>Benzer Ürünler</h4> 
          <div className='seperator m-0'></div>
         
          <div className='homePage-header m-b-40 '>
            <HeaderSwiper
              recommendedProducts={recommendedProducts}
              product={product}
            />
            <Swiper
              slidesPerView={4}
              spaceBetween={30}
              slidesPerGroup={1}
              loop={false}
              navigation={{
                prevEl: '.customLeftArrow',
                nextEl: '.customRightArrow',
              }}
              modules={[Pagination, Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 3,
                },
                640: {
                  slidesPerView: 3,
                },
                768: {
                  slidesPerView: 4,
                },
                1200: {
                  slidesPerView: 4,
                },
              }}
              className='mySwiper my-3 morePadProduct'
            >
              {recommendedProducts &&
                recommendedProducts.map(product => {
                  return (
                    <SwiperSlide key={product.Product.Id}>
                      <ProductCard1 key={product.Product.Id} product={product.Product} />
                    </SwiperSlide>
                  )
                })}
            </Swiper>
            <div className='customLeftArrow' style={{ top: '50%', left: '2px' }}></div>
            <div className='customRightArrow' style={{ top: '50%', right: '2px' }}></div>
          </div>
        </div>
      </section> */}
      <IPModal
        show={showMail}
        onClose={() => {
          setShowMail(false)
        }}
        width={1000}
        onConfirm={sendProductDetailMail}
        confirmText={'E-posta Gönder'}
        title='E-posta Gönder'
      >
        <>
          <div className='row mb-2'>
            <div className='col-md-3'></div>
            <div className='col-md-2' style={{ marginTop: '10px' }}>
              Ad Soyad / Unvan
            </div>
            <div className='col-md-4'>
              <IPInput type='text' value={state.Name} onChange={e => setState({ Name: e.target.value })} />
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-3'></div>
            <div className='col-md-2' style={{ marginTop: '10px' }}>
              E-posta
            </div>
            <div className='col-md-4'>
              <IPInput type='text' value={state.Emails} onChange={e => setState({ Emails: e.target.value })} />
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-3'></div>
            <div className='col-md-2' style={{ marginTop: '10px' }}>
              Konu
            </div>
            <div className='col-md-4'>
              <IPInput type='text' value={state.Subject} onChange={e => setState({ Subject: e.target.value })} />
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-3'></div>
            <div className='col-md-2' style={{ marginTop: '10px' }}>
              Kişisel Notunuz
            </div>
            <div className='col-md-4'>
              <IPTextarea value={state.Note} onChange={e => setState({ Note: e.target.value })} />
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-3'></div>
            <div className='col-md-9'>
              <p style={{ color: 'red' }}>* Tüm alanların doldurulması zorunludur.</p>
            </div>
          </div>
          <div className='row mb-2'>
            <div className='col-md-3'></div>
            <div className='col-md-9'>
              <p style={{ color: 'red' }}>** Birden fazla kişiye e-posta atmak için, adreslerin arasına "," koyunuz.</p>
            </div>
          </div>
        </>
      </IPModal>
      <PaymentMethod checkboxState={checkboxState} handleCheckboxChange={handleCheckboxChange} product={product} />
    </React.Fragment>
  )
}

export default HeaderLast
