import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { AddLogoRequest, ILogo } from '../models/models'

export class AddLogoApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealer/addLogo',
      requesterInfo,
    })
  }

  public async addLogo(request: AddLogoRequest): Promise<string> {
    return await this.postAsync<string>(request)
  }
}
