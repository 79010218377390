import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { CommonPostResponse } from '../models/models'

export class GiftCodeInputApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/GiftCodeInputUI',
      requesterInfo,
    })
  }

  public async giftCodeInput(code: string): Promise<CommonPostResponse> {
    this.props.url = `${this.props.url}?code=${code}`
    return await this.postAsync<CommonPostResponse>()
  }
}
