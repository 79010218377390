import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IXmlInvoiceReport, IXmlInvoiceReportResponse } from '../models/models'

export class PostXmlInvoiceReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/XmlInvoiceReport',
      requesterInfo,
    })
  }

  public async postXmlInvoiceReport(req: IXmlInvoiceReport): Promise<IXmlInvoiceReportResponse[]> {
    return await this.postAsync<IXmlInvoiceReportResponse[]>(req)
  }
}
