import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Table from '../../../../../components/Table'

interface INetChannelState {}

function NetChannelContentList(props: BaseComponentWithContextProps) {
  const getInitialState = (): INetChannelState => {
    return {}
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<INetChannelState>(intialState)

  const locations = [
    { label: 'NetAnasayfa Banner', value: 1 },
    { label: 'NetAnasayfa Ürünler', value: 2 },
    { label: 'iPhone Ürün Serisi Dökümanları', value: 3 },
    //{ label: 'Görseller', value: 4 },
    //{ label: 'Eğitim  Dokümanları', value: 5 },
  ]

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Sayfa',
        accessor: 'col2',
      },
      {
        Header: 'İşlemler',
        accessor: 'col3',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      locations.map((item, index) => {
        const data = {
          col1: index + 1,
          col2: locations.find(a => a.value == item.value)?.label,
          col3: (
            <a
              data-action-type='edit'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title='Güncelle'
              onClick={() => updateDppAndMicrosoftPages(item.value)}
            >
              <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
            </a>
          ),
        }
        return data
      }),
    [locations]
  )

  const navigate: any = useNavigate()

  const updateDppAndMicrosoftPages = async (location: number) => {
    navigate('/admin/homeAndSiteOperation/netChannelContentAdd', {
      state: { pageName: locations.find(a => a.value === location)?.label, pageID: location },
    })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>NetChannel Sayfası</span>
          </div>
          <div className='card-body'>
            <Table columns={tableColumns} data={tableData} currentPage={0} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(NetChannelContentList)
