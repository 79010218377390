import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IChannelFilteredReport } from '../models/getDealerChannelResponseModel'

export class ToExcel extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/Excel/dealerChannelReport',
      requesterInfo,
    })
  }

  public async dealerChannelReport(request: IChannelFilteredReport): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
