import _ from 'lodash'
import { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { translate } from '../../../../../base/localization'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'
import IPButton from '../../../../../components/IPButton'
import ExcelButton from '../../../../../components/IPExcelButton/IPExcelButton'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Translations from '../../../../../translations'
import { GetAuthorizedServicePointsListResponseModel } from '../models/response/getAuthorizedServicePointsListResponseModel'
import { GetAuthorizedServicePointsListApi } from '../services/getAuthorizedServicePointsListApi'
import '../style.scss'
const Table = lazy(() => import('../../../../../components/Table'))

interface AuthorizedServicePointsListState {
  AuthorizedServicePointsList: GetAuthorizedServicePointsListResponseModel[]
  companyListOptions: ISelectOption[]
  companyListOption: any
}
const t = translate(Translations)

function AuthorizedServicePoints(props: BaseComponentWithContextProps) {
  const getInitialState = (): AuthorizedServicePointsListState => {
    return {
      AuthorizedServicePointsList: [],
      companyListOption: null,
      companyListOptions: [
        { label: 'Index', value: '1' },
        { label: 'Netex', value: '2' },
        { label: 'Despec', value: '3' },
        { label: 'Datagate', value: '4' },
      ],
    }
  }

  const intialState = getInitialState()

  const [filteredList, setFilteredList] = useState<GetAuthorizedServicePointsListResponseModel[]>([])
  const [brandSelectOptions, setBrandSelectOptions] = useState<ISelectOption[]>([])
  const [brandSelectOption, setBrandSelectOption] = useState<any>()
  const [productGroupListOptions, setProductGroupListOptions] = useState<ISelectOption[]>([])
  const [productGroupListOption, setProductGroupListOption] = useState<any>()
  const [state, setState] = useCustomReducer<AuthorizedServicePointsListState>(intialState)

  useEffect(() => {
    getAuthorizedServicePoints()
  }, [])

  const routeLocation = useLocation()

  const getAuthorizedServicePoints = async () => {
    const uniqueBankLabels = {}

    if (routeLocation.state) {
      const data = _.clone((routeLocation.state as any)?.data)
      if (data.length > 0) {
        setState({ AuthorizedServicePointsList: data })
        setFilteredList(data)
        setBrandSelectOptions(
          data
            .map(x => ({
              value: x.BrandId,
              label: x.Brand.Name.toUpperCase(),
            }))
            .filter(option => {
              if (!uniqueBankLabels[option.label]) {
                uniqueBankLabels[option.label] = true
                return true
              }
              return false
            })
        )
        setProductGroupListOptions(
          data
            .map(x => ({
              value: x.Description.ProductGroup,
              label: x.Description.ProductGroup.toUpperCase().replace('\n', '').replace('\t', ''),
            }))
            .filter(option => {
              if (!uniqueBankLabels[option.label]) {
                uniqueBankLabels[option.label] = true
                return true
              }
              return false
            })
        )
      }
    } else {
      props.showLoading()
      const getAuthorizedServicePointsListApi: GetAuthorizedServicePointsListApi =
        new GetAuthorizedServicePointsListApi({})
      await getAuthorizedServicePointsListApi
        .GetAuthorizedServicePointsList()
        .then((result: GetAuthorizedServicePointsListResponseModel[]) => {
          if (result) {
            setBrandSelectOptions(
              result
                .map(x => ({
                  value: x.BrandId,
                  label: x.Brand.Name.toUpperCase(),
                }))
                .filter(option => {
                  if (!uniqueBankLabels[option.label]) {
                    uniqueBankLabels[option.label] = true
                    return true
                  }
                  return false
                })
            )
            setProductGroupListOptions(
              result
                .map(x => ({
                  value: x.Description.ProductGroup,
                  label: x.Description.ProductGroup.toUpperCase().replace('\n', '').replace('\t', ''),
                }))
                .filter(option => {
                  if (!uniqueBankLabels[option.label]) {
                    uniqueBankLabels[option.label] = true
                    return true
                  }
                  return false
                })
            )
            setState({ AuthorizedServicePointsList: result })
            setFilteredList(result)
          }
          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
    }
  }

  // Detaylı Süzme fonksiyonu
  const filterList = () => {
    const filterConditions: ((item: GetAuthorizedServicePointsListResponseModel) => boolean)[] = []

    if (brandSelectOption) {
      filterConditions.push(b => b.Brand.Name.toUpperCase() === brandSelectOption.label)
    }

    if (productGroupListOption) {
      filterConditions.push(b => {
        const cleanProductGroup = b.Description.ProductGroup.replace(/[\n\t]/g, '').toUpperCase()
        const isMatch = cleanProductGroup === productGroupListOption.label.toUpperCase()
        const isEmpty = cleanProductGroup === ''
        return productGroupListOption.value === 'Tüm Modeller' ? isMatch || isEmpty : isMatch
        //productGroup değeri boşsa bu Tüm Modeller'i kapsadığı anlamına gelmektedir, bu yüzden boşlarda Tüm Modeller seçeneği ile gelmeli.
      })
    }

    if (state.companyListOption) {
      filterConditions.push(c => c.Brand.Companies.includes(state.companyListOption.value))
    }

    let filteredData: GetAuthorizedServicePointsListResponseModel[] = state.AuthorizedServicePointsList

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
    }
    setFilteredList(filteredData)
  }

  //#region Table Part
  const columns = useMemo(() => {
    return [
      {
        Header: 'MARKA',
        accessor: 'brandName',
      },
      {
        Header: 'ÜRÜN GRUBU',
        accessor: 'productGroup',
      },
      {
        Header: 'FİRMA İSMİ',
        accessor: 'companyName',
      },
      {
        Header: 'ADRES',
        accessor: 'address',
      },
      {
        Header: 'TELEFON',
        accessor: 'phone',
      },
      {
        Header: 'E-POSTA',
        accessor: 'email',
      },
      {
        Header: 'WEB ADRESİ',
        accessor: 'webAddress',
      },
      // {
      //   Header: 'ÜCRET ÖDEMELİ KARGO KABUL EDİLİYOR MU?',
      //   accessor: 'isChargedShippingAccepted',
      // },
      // {
      //   Header: 'ANLAŞMALI KARGO ŞİRKETİ (VARSA GÖNDERİ KODU)',
      //   accessor: 'contractedCargoCompany',
      // },
      {
        Header: 'YETKİLİ SERVİS NOKTALARI',
        accessor: 'authorizedServicePoints',
      },
    ]
  }, [])

  const data = useMemo(
    () =>
      filteredList.map(point => {
        const {
          Brand: { Name: brandName } = {},
          Description: {
            ProductGroup: productGroup,
            CompanyName: companyName,
            Address: address,
            Phone: phone,
            Email: email,
            WebAddress: webAddress,
            AuthorizedServicePoints: authorizedServicePoints,
          } = {},
          Description: {
            IsChargedShippingAccepted: isChargedShippingAccepted,
            ContractedCargoCompany: contractedCargoCompany,
          } = {},
        } = point

        // ProductGroup boş ise "Tüm Modeller" olarak ayarla
        const finalProductGroup = productGroup || 'Tüm Modeller'

        return {
          brandName,
          productGroup: finalProductGroup,
          companyName,
          address,
          phone,
          email,
          webAddress,
          isChargedShippingAccepted,
          contractedCargoCompany,
          authorizedServicePoints,
        }
      }),
    [filteredList]
  )
  //#endregion

  //#region Excel'e aktarma
  const excelButton = async () => {
    try {
      if (filteredList) {
        const data: any[] = []
        if (filteredList.length > 0) {
          filteredList.map(point => {
            return data.push({
              'Marka Adı': point.Brand.Name,
              'Ürün Grubu': point.Description.ProductGroup,
              'Firma Adı': point.Description.CompanyName,
              Adres: point.Description.Address,
              Telefon: point.Description.Phone,
              'E-Mail': point.Description.Email,
              'Web Sitesi': point.Description.WebAddress,
              'ÜCRET ÖDEMELİ KARGO KABUL EDİLİYOR MU?': point.Description.IsChargedShippingAccepted,
              'ANLAŞMALI KARGO ŞİRKETİ (VARSA GÖNDERİ KODU)': point.Description.ContractedCargoCompany,
              'YETKİLİ SERVİS NOKTALARI': point.Description.AuthorizedServicePoints,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'YETKİLİ SERVİSLER')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }
  //#endregion

  return (
    <div className='authorizedServicePoints ' style={{ backgroundColor: 'white' }}>
      <div className='container-fluid'>
        <div className='row align-items-center'>
          <div className='p-4 d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>YETKİLİ SERVİSLER</span>
            <div className='d-flex align-items-center'>
              <ExcelButton onClick={excelButton} />
            </div>
          </div>
          <div className='detailFilter'>
            <div className='row ms-1'>
              <div className='form-group col-md-3'>
                <IPSelectBox
                  isClearable
                  placeholder='Şirket'
                  options={state.companyListOptions}
                  value={state.companyListOption}
                  onChangeSingle={option => {
                    setState({ companyListOption: option })
                  }}
                />
              </div>
              <div className='form-group col-md-3'>
                <IPSelectBox
                  isClearable
                  placeholder='Marka'
                  options={brandSelectOptions}
                  value={brandSelectOption}
                  onChangeSingle={option => {
                    setBrandSelectOption(option)
                  }}
                />
              </div>
              <div className='form-group col-md-3'>
                <IPSelectBox
                  isClearable
                  placeholder='Ürün Grubu'
                  options={productGroupListOptions}
                  value={productGroupListOption}
                  onChangeSingle={option => {
                    setProductGroupListOption(option)
                  }}
                />
              </div>
              <div className='form-group col-md-2'>
                <IPButton
                  type='button'
                  className='btn btn-primary'
                  text='Filtrele'
                  onClick={filterList}
                  style={{ height: '45px' }}
                />
              </div>
            </div>
          </div>
          <Suspense fallback={props.showLoading}>
            <div className='col-md-12'>
              <Table
                columns={columns}
                data={data}
                headerName={`Yetkili Servisler`}
                columnCount={10}
                textAlign={true}
                color='white'
              />
            </div>
          </Suspense>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(AuthorizedServicePoints)
