import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IXmlData } from '../models/models'
export class GetGeneralExceptionalsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerXml/getGeneralExceptionals',
      requesterInfo,
    })
  }

  public async getGeneralExceptionals(): Promise<IXmlData> {
    return await this.getAsync<IXmlData>()
  }
}
