import { ConfigHelper } from '../constants'
import { KeyValuePair, ServiceCallTypeEnum, IException, IRequesterInfo } from '../models'
import { ClientStorage } from '../storage'
import { handleException } from '../utils'
import { redirectUserToLogin } from './authenticate'
import * as ServiceProxy from './serviceProxy'

interface IClientProxyProps {
  url: string
  hasFullUrl?: boolean
  requesterInfo?: IRequesterInfo
}

export class ClientProxy {
  public props: IClientProxyProps
  public queryParams: KeyValuePair<any>

  constructor(props: IClientProxyProps) {
    this.props = props
    this.queryParams = new KeyValuePair<any>()
  }

  protected async getAsync<TResponse>(id?: string): Promise<TResponse> {
    return await this.callById<TResponse>(ServiceCallTypeEnum.Get, 'Get', id)
  }

  protected async postAsync<TResponse>(request?: any): Promise<TResponse> {
    return await this.callByRequest<TResponse>(ServiceCallTypeEnum.Post, this.props.url, 'Post', request)
  }

  protected async deleteAsync<TResponse>(id?: string): Promise<TResponse> {
    return await this.callById(ServiceCallTypeEnum.Delete, 'Delete', id)
  }

  protected async putAsync<TResponse>(id: string | number | undefined, request?: any): Promise<TResponse> {
    let url: string = this.props.url

    if (id) {
      const idVal = id.toString()

      if (idVal.length > 0) {
        url = url.concat('/').concat(idVal)
      }
    }

    return await this.callByRequest<TResponse>(ServiceCallTypeEnum.Put, url, 'Put', request)
  }

  private async callById<TResponse>(
    serviceCallTypeEnum: ServiceCallTypeEnum,
    operationName: string,
    id?: string
  ): Promise<TResponse> {
    const url = this.getQueryUrl(id)

    return await ServiceProxy.Call<TResponse>(serviceCallTypeEnum, operationName, this.getClientProps({ url }))
      .then((response: any) => {
        if (response.data.AuthToken && response.data.AuthToken.RefreshToken && response.data.AuthToken.Token) {
          ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_TOKEN, response.data.AuthToken.Token)
          ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_REFRESH_TOKEN, response.data.AuthToken.RefreshToken)
          //ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_TOKEN_CREATE_TIME, new Date().getTime().toString())
          ClientStorage.setItem(
            ConfigHelper.INDEXPAZAR_USER_TOKEN_CREATE_TIME,
            response.data.AuthToken.CreatedTokenTime.toString()
          )
        }

        if (response.data.Data === null && response.data.Message && response.data.Message !== "OK") {
          throw {
            code: '',
            description: response.data.Message,
            success: response.data.Success,
          } as IException
        }

        return response.data.Data
      })
      .catch(async err => {
        const response: IException = handleException(err)

        if (
          [401, 403, 430].indexOf(response.httpStatusCode ?? -1) > -1 &&
          response.reason !== 'UnauthorizedRequestForAdminPanel'
        ) {
          redirectUserToLogin()
        }

        throw response
      })
  }

  private async callByRequest<TResponse>(
    serviceCallTypeEnum: ServiceCallTypeEnum,
    url: string,
    operationName: string,
    request?: any
  ): Promise<TResponse> {
    return await ServiceProxy.Call<TResponse>(serviceCallTypeEnum, operationName, this.getClientProps({ url }), request)
      .then((response: any) => {
        if (response.data.AuthToken && response.data.AuthToken.RefreshToken && response.data.AuthToken.Token) {
          ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_TOKEN, response.data.AuthToken.Token)
          ClientStorage.setItem(ConfigHelper.INDEXPAZAR_USER_REFRESH_TOKEN, response.data.AuthToken.RefreshToken)
          ClientStorage.setItem(
            ConfigHelper.INDEXPAZAR_USER_TOKEN_CREATE_TIME,
            response.data.AuthToken.CreatedTokenTime.toString()
          )
        }
        if (response.data.Data === null && response.data.Message) {
          throw {
            code: response.data.Message,
            description: response.data.Message,
            success: response.data.Success,
          } as IException
        }
        return response.data.Data
      })
      .catch(async err => {
        console.log(err.response)
        if (!err.response) {
          throw {
            code: err.code,
            description: err.description,
          }
        }
        if (err.response.status === 400) {
          throw {
            code: err.code,
            description: 'Tanımınızı kontrol ediniz.',
          }
        }
        if (!err.description) {
          throw {
            code: err.code,
            description: 'Oturum Süreniz Dolmuştur!',
          }
        }
        if (err.response.status && err.response.status === 401) {
          redirectUserToLogin()
        }
        const response: IException = handleException(err)

        if (
          [401, 403, 430].indexOf(response.httpStatusCode ?? -1) > -1 &&
          response.reason !== 'UnauthorizedRequestForAdminPanel'
        ) {
          redirectUserToLogin()
        }

        throw response
      })
  }

  private getClientProps = (options: Partial<IClientProxyProps>): IClientProxyProps => {
    return Object.assign({}, this.props, options)
  }

  private getQueryUrl = (pathQuery?: string): string => {
    let url: string = this.props.url

    if (pathQuery && pathQuery.length > 0) {
      url = url.concat('/').concat(pathQuery)
    }

    const queries: string[] = []

    if (this.queryParams.hasValue()) {
      queries.push(this.getQueryFromHash(this.queryParams))
    }

    if (queries.length > 0) {
      url = url.concat('?')

      queries.forEach((query, index) => {
        url = url.concat(query)

        if (index < queries.length - 1) {
          url = url.concat('&')
        }
      })
    }

    return url
  }

  private getQueryFromHash = (hash: KeyValuePair<any>): string => {
    const hashObject = hash.join()

    return $.param(hashObject)
  }
}
