import { useState } from 'react'
import { Link } from 'react-router-dom'

import classes from './Link.module.scss'

interface ILink {
  link: string
  text: string
  iconSrcBlack: string
  iconSrcWhite: string
  className?: string
}

export default function LinkItem(props: ILink) {
  const [validImage, setValidImage] = useState(props.iconSrcBlack)

  return (
    <Link
      to={props.link}
      className={`${classes.Link} ${props.className ?? ''}`}
      onMouseEnter={() => setValidImage(props.iconSrcWhite)}
      onMouseLeave={() => setValidImage(props.iconSrcBlack)}
    >
      <div className={classes.icon}>
        <img src={validImage} alt='' style={{ color: 'black' }} />
      </div>
      <span>{props.text}</span>
    </Link>
  )
}
