import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IOpportunitFilter } from '../models/opportunitFilterModel'
import { IBuyersDetailData, IListOpportunity } from '../models/opportunityMarketList'

export class ListOpportunityMarketApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/opportunitMarket/list',
      requesterInfo,
    })
  }

  public async getOpportunityMarket(): Promise<IListOpportunity[]> {
    return await this.getAsync<IListOpportunity[]>()
  }
  public async postOpportunityFilters(request: IOpportunitFilter): Promise<IListOpportunity[]> {
    this.props.url = `admin/opportunitMarket/filter`
    return await this.postAsync<IListOpportunity[]>(request)
  }

  public async getBuyersDetails(pId: number): Promise<IBuyersDetailData[]> {
    this.props.url = `admin/opportunitMarket/buyersDetails/${pId}`
    return await this.getAsync<IBuyersDetailData[]>()
  }
}
