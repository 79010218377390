import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ITown } from '../models/models'

export class GetTownApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'CityTownNeighbourhood/getTown',
      requesterInfo,
    })
  }

  public async getTown(id: number): Promise<ITown[]> {
    this.props.url = `${this.props.url}/${id}`
    return await this.postAsync<ITown[]>()
  }
}
