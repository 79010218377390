import React, { useEffect, useState } from 'react'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from '../../components/IPImage'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import './styles.scss'
import { Link } from 'react-router-dom'
import Translations from '../../translations'
import { translate } from '../../base/localization'

// import required modules
import { Navigation, Mousewheel, Autoplay, Keyboard } from 'swiper'
import { IBanner } from './models/models'
import { GetLoginBannersApi } from './services/getLoginBannersApi'
export default function Slider() {
  const [banners, setBanners] = useState<IBanner[]>([])
  useEffect(() => {
    getBanners()
  }, [])
  const getBanners = async () => {
    const getLoginBannersApi: GetLoginBannersApi = new GetLoginBannersApi({})

    const banners = await getLoginBannersApi.getBanners()
    setBanners(banners.sort((a, b) => a.Order - b.Order))
  }
  const t = translate(Translations)
  return (
    <Swiper
      autoplay={{
        delay: 6000,
        disableOnInteraction: false,
      }}
      cssMode={true}
      navigation={true}
      pagination={true}
      mousewheel={true}
      keyboard={true}
      modules={[Navigation, Mousewheel, Autoplay, Keyboard]}
      className='mySwiper'
    >
      {banners &&
        banners.map(banner => (
          <SwiperSlide key={banner.Id}>
            <Image className='swiper_image' alt={banner.Title} src={banner.ImageUrl} />
            <div className='bg-overlay'></div>
            <div className='info login__slide--content'>
              <h2>
                <span>{banner.Title}</span>
                <br /> {banner.Description}
              </h2>
              <div className='container box-transform'>
                <div className='row justify-content-center align-items-center'>
                  <div className='col-auto text-center background-white text-light'>
                    <h3>İletişim Bilgilerimiz</h3>
                    <p style={{ width: '450px' }}>
                      Ayazağa Mahallesi, Mimar Sinan Sokak No:21 Seba Office Boulevard, D Blok Kat: 1 Bölüm No: 11
                      Sarıyer, 34485 İstanbul
                    </p>
                    <Link to={`tel:0 (212) 331 21 21`}>0 (212) 331 21 21</Link>
                    <Link to='www.indexpazar.com'></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className='row right_fixed_texts'>
              <div className='col-md-6 text-left text-light'>
                <span>
                  <a target={'_blank'} href='/images/yasal-uyari-bilgilendirme.pdf'>
                    {t('pages.loginPage.slider.legalNoticeInformationText')}
                  </a>
                </span>
              </div>
              {/* <div className='col-md-6 text-right text-light'>
                <span>
                  <a target={'_blank'} href='https://www.indexpazar.com/sessman/E-Bayisozlesme.pdf'>
                    {t('pages.loginPage.slider.samplePartnershipAgreement')}
                  </a>
                </span>
              </div> */}
            </div>
          </SwiperSlide>
        ))}
    </Swiper>
  )
}
