import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IUser, AddUserRequest } from '../models/models'

export class AddUserApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealerInformation/addUser',
      requesterInfo,
    })
  }

  public async addUser(request: AddUserRequest): Promise<IUser> {
    return await this.postAsync<IUser>(request)
  }
}
