import React, { useMemo, useState, useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import InputCampaign from '../../../../../components/InputCampaign'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Table from '../../../../../components/Table'
import { IPromotionProductListRequest, IPromotionProductListResponse } from '../models/models'
import { GetPromotionProductListApi } from '../services/getPromotionProductListApi'
import Swal from 'sweetalert2'
import { formatDate, formatDateForCampaignInput } from '../../../../../base/utils/dataHelper'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ICompany } from '../../../components/models/models'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import { getCompanyApi } from '../../../components/api'

interface IPromotionalProductListsState {
  company: ISelectOption | null
}
function Index(props: BaseComponentWithContextProps) {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const t = translate(Translations)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [promotionProductList, setPromotionProductList] = useState<IPromotionProductListResponse[]>([])
  const [companies, setCompanies] = useState<ICompany[]>([])
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getInitialState = (): IPromotionalProductListsState => {
    return {
      company: null
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IPromotionalProductListsState>(intialState)

  const getPromotionProductList = async () => {
    if (!state.company) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen şirket seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: IPromotionProductListRequest = {
      Company: state.company ? String(state.company.value) : "",
      Country: "",
      EndDate: formatDate(endDate),
      StartDate: formatDate(startDate),
    }
    props.showLoading();
    const getPromotionProductListApi: GetPromotionProductListApi = new GetPromotionProductListApi({})
    await getPromotionProductListApi
      .getPromotionProductList(req)
      .then(result => {
        if (result) {
          setPromotionProductList(result)
        }
        props.hideLoading();
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading();
      })
  }
  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sipariş No',
        accessor: 'SIP_NO',
      },
      {
        Header: 'Sip. Tarihi',
        accessor: 'SIP_TAR',
      },
      {
        Header: 'Teslimat No',
        accessor: 'TES_NO',
      },
      {
        Header: 'Tes. Tarihi',
        accessor: 'TES_TAR',
      },
      {
        Header: 'Bayi Kodu',
        accessor: 'DEALER',
      },
      {
        Header: 'Bayi Adı',
        accessor: 'NAME',
      },
      {
        Header: 'SAP Kodu',
        accessor: 'MATERIALNUMBER',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'URUN_KODU',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'PRODUCTNAME',
      },
      {
        Header: 'Seri No/IMEI',
        accessor: 'SERINO',
      }
    ],
    []
  )


  useEffect(() => {
    getCompanies()
  }, [])

  const data = useMemo(
    () =>
      promotionProductList.map(p => ({
        SIP_NO: p.SIP_NO,
        SIP_TAR: new Date(p.SIP_TAR).toLocaleDateString(),
        TES_NO: p.TES_NO,
        TES_TAR: new Date(p.TES_TAR).toLocaleDateString(),
        DEALER: p.DEALER.replace("0000", ""),
        NAME: p.NAME1,
        MATERIALNUMBER: p.MATERIALNUMBER,
        URUN_KODU: p.URUN_KODU,
        SERINO: p.SERINO,
        PRODUCTNAME: p.ARKTX
      })),
    [promotionProductList]
  )

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    setCompanies(company)
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='card'>
      <div className='card-header'>
        <span className='h4'>
          Promosyon Ürünler Listesi
        </span>
      </div>
      <div className='card-body'>
        <form className='formbanner'>
          <div className='row'>
            <div className='col-md-3 mb-3'>
              <InputCampaign
                type='date'
                id='StartDate'
                name='StartDate'
                label={'Başlangıç Tarihi'}
                value={toDateTimeLocal(startDate)}
                onChange={e => getDate(e.target.value, "Start")}
                isActive
              />
            </div>
            <div className='col-md-3 mb-3'>
              <InputCampaign
                type='date'
                id='EndDate'
                name='EndDate'
                label={'Bitiş Tarihi'}
                value={toDateTimeLocal(endDate)}
                onChange={e => getDate(e.target.value, "End")}
                isActive
              />
            </div>
            <div className='col-md-2 mb-3'>
              <IPSelectBox
                isClearable
                options={
                  companies &&
                  companies.filter((com: ICompany) => { return com.Name !== "Index Group" }).map((item: ICompany) => ({
                    label: item.Name,
                    value: `${item.Code}`,
                  }))
                }
                value={state.company}
                placeholder="Şirket Seçiniz"
                onChangeSingle={option => setState({ company: option })}
              />
            </div>
            <div className='col-md-1 mb-3'>
              <IPButton
                type='button'
                className='btn btn-success'
                style={{ width: "25%" }}
                text={t('pages.admin.dealerAndOrderReport.handcraftedOrdersList.handcraftedOrdersList.filtre')}
                onClick={getPromotionProductList}
              />
            </div>
          </div>
        </form>
        <div className='m-4'>
          <Table columns={tableColumns} data={data} currentPage={0} headerName="Promosyon Çıkışı Yapılan Ürünler Listesi" columnCount={13} />
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(Index)
