import React from 'react';
import { useCountdown } from './useCountdown';
import './IPCountdownTimer.scss';
import DateTimeDisplay from './DateTimeDisplay';


const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="show-counter">
      <DateTimeDisplay value={days} type={'Gün'} isDanger={days <= 3} />
      <p>:</p>
      <DateTimeDisplay value={hours} type={'Saat'} isDanger={false} />
      <p>:</p>
      <DateTimeDisplay value={minutes} type={'Dakika'} isDanger={false} />
      <p>:</p>
      <DateTimeDisplay value={seconds} type={'Saniye'} isDanger={false} />
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
