import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ProductDetailMailRequest } from '../models/productDetailMailRequest'

export class ProductDetailMail extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'productDetailMail/Insert',
      requesterInfo,
    })
  }

  public async insert(req: ProductDetailMailRequest): Promise<boolean> {
    return await this.postAsync<boolean>(req)
  }
}
