import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IGetBasketManagementByBasketIdResponse } from '../models/models'

export class GetBasketManagementByBasketIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/basketManagementByIdUI',
      requesterInfo,
    })
  }

  public async getBasketDetails(): Promise<IGetBasketManagementByBasketIdResponse> {
    return await this.getAsync<IGetBasketManagementByBasketIdResponse>()
  }
}
