import { css } from '@emotion/css'
import moment from 'moment'
import { useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { translate } from '../../../../base/localization'
import { exportToXLSX } from '../../../../base/utils/dataHelper'
import InputCampaign from '../../../../components/InputCampaign'
import Table from '../../../../components/Table'
import Translations from '../../../../translations'
import { DealerForLoginRequest, DealerForLoginResponse, PathOptions } from './models'
import { SiteNavigationService } from './services'
import IPCheckbox from '../../../../components/IPCheckBox'

function StatisticsList(props: BaseComponentWithContextProps) {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [filteredStartDate, setfilteredStartDate] = useState(new Date())
  const [filteredEndDate, setfilteredEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const [pathOptions, setPathOptions] = useState<PathOptions>({
    SubTypeCode: '',
    StartDate: startDate,
    EndDate: endDate,
    IncludeDenemeCard: false,
  })
  const [pathOptions2, setPathOptions2] = useState<PathOptions>({
    SubTypeCode: '',
    StartDate: startDate,
    EndDate: endDate,
    IncludeDenemeCard: false,
  })
  const [paths, setPaths] = useState<DealerForLoginResponse[]>([])
  const [first, setFirst] = useState<boolean>(false)

  function updatePathOptions(prop, val) {
    setPathOptions(prev => ({ ...prev, [prop]: val }))
  }

  const t = translate(Translations)

  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000).toISOString()

  // const dateValid = () => {
  //   const result = pathOptions.EndDate.getTime() - pathOptions.StartDate.getTime()
  //   if (result < 60000) {
  //     Swal.fire('Bitiş tarihi başlangıç tarihinden önce olamaz.')
  //     updatePathOptions('EndDate', toDateTimeLocal(new Date()))
  //   }
  // }

  async function handleSubmit(e) {
    e.preventDefault()
    if (new Date(endDate) < new Date(startDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Başlangıç bitiş tarihinden sonra olamaz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: DealerForLoginRequest = {
      DateRange: {
        StartDate: dateToISO(startDate),
        EndDate: dateToISO(endDate),
      },
      SubTypeCode: pathOptions.SubTypeCode.length > 0 ? pathOptions.SubTypeCode : '',
      IncludeDenemeCard: pathOptions.IncludeDenemeCard,
    }
    props.showLoading()
    await new SiteNavigationService({})
      .DealerForLogin(req)
      .then(result => {
        if (result) {
          setPaths(result)
          setFirst(true)
          setPathOptions2(pathOptions)
          setfilteredEndDate(endDate)
          setfilteredStartDate(startDate)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'Order',
        width: 120,
      },
      {
        Header: 'SAP Kodu',
        accessor: 'DealerCode',
        width: 120,
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'UserCode',
      },
      {
        Header: 'Hesap Adı',
        accessor: 'DealerName',
        width: 400,
      },
      {
        Header: 'Bayi Tipi & Satış Temsilcisi',
        accessor: 'DealerTypes',
        width: 300,
      },
      {
        Header: 'Şehir',
        accessor: 'City',
        width: 100,
      },
      {
        Header: 'İlk Erişim',
        accessor: 'StartDate',
        width: 160,
      },
      {
        Header: 'Son Erişim',
        accessor: 'LastDate',
        width: 160,
      },
      {
        Header: 'Toplam',
        accessor: 'Total',
        width: 80,
      },
      {
        Header: 'Sap',
        accessor: 'Sap',
        width: 80,
      },
      {
        Header: 'INT',
        accessor: 'Int',
        width: 80,
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      paths.map((p, index) => {
        return {
          Order: index + 1,
          DealerCode: p.DealerCode,
          UserCode: p.UserCode && p.UserCode.slice(4),
          DealerName: p.DealerName,
          DealerTypes:
            p.DealerTypes &&
            p.DealerTypes.map((x, i) => (
              <div>
                {x.Type} - {x.Channel ? x.Channel : null}
              </div>
            )),
          City: p.City,
          StartDate: new Date(p.StartDate).toLocaleString(),
          LastDate: new Date(p.LastDate).toLocaleString(),
          Total: p.TotalSessions,
          Sap: '0.00',
          Int: '0.00',
        }
      }),
    [paths]
  )

  const excelButton = async () => {
    try {
      if (paths) {
        const data: any[] = []
        if (paths.length > 0) {
          paths.map((p, index) => {
            return data.push({
              Sıra: index + 1,
              'SAP Kodu': p.DealerCode,
              'Hesap Kodu': p.UserCode.replace('0000', ''),
              'Hesap Adı': p.DealerName,
              Şehir: p.City,
              'İlk Erişim': new Date(p.StartDate).toLocaleString(),
              'Son Erişim': new Date(p.LastDate).toLocaleString(),
              Toplam: p.TotalSessions,
              Sap: '0.00',
              Int: '0.00',
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(
          data,
          moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY') +
            '-' +
            moment(toDateTimeLocal(endDate)).format('DD.MM.YYYY') +
            'Bayi Gezinme İstatistikleri'
        )
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const excelButtonWithDetail = async () => {
    try {
      if (paths) {
        const combinedData: any[] = []

        if (paths.length > 0) {
          for (let index = 0; index < paths.length; index++) {
            const p = paths[index]
            const modalData = p.DealerTypes

            combinedData.push({
              Sıra: index + 1,
              'SAP Kodu': p.DealerCode,
              'Hesap Kodu': p.UserCode.replace('0000', ''),
              'Hesap Adı': p.DealerName,
              Şehir: p.City,
              'İlk Erişim': new Date(p.StartDate).toLocaleString(),
              'Son Erişim': new Date(p.LastDate).toLocaleString(),
              Toplam: p.TotalSessions,
              Sap: '0.00',
              Int: '0.00',
            })

            if (modalData) {
              for (let i = 0; i < modalData.length; i++) {
                combinedData.push({
                  Sıra: index + 1,
                  'SAP Kodu': p.DealerCode,
                  'Hesap Kodu': p.UserCode.replace('0000', ''),
                  'Hesap Adı': p.DealerName,
                  Şehir: p.City,
                  'İlk Erişim': new Date(p.StartDate).toLocaleString(),
                  'Son Erişim': new Date(p.LastDate).toLocaleString(),
                  Toplam: '0',
                  Sap: '0.00',
                  Int: '0.00',
                  'Bayi Tipi Sıra': i + 1,
                  'Bayi Tipi & Satış Temsilcisi': modalData[i].Type + '-' + modalData[i].Channel,
                })
              }
            }
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(
          combinedData,
          moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY') +
            '-' +
            moment(toDateTimeLocal(endDate)).format('DD.MM.YYYY') +
            'Bayi Gezinme İstatistikleri (Bayi Tip Dahil)'
        )
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>
              {' '}
              Bayi {t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.header')}
            </span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
              <span>Bayi Tipleri Dahil Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButtonWithDetail}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <form
              className={css`
                display: flex;
                align-items: center;
                gap: 1rem;
                flex-wrap: wrap;
              `}
              onSubmit={handleSubmit}
            >
              <InputCampaign
                type='date'
                id='StartDate'
                name='StartDate'
                label={t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.startDate')}
                value={toDateTimeLocal(startDate)}
                // onChange={e => updatePathOptions('StartDate', new Date(e.target.value))}
                onChange={e => getDate(e.target.value, 'Start')}
                isActive
              />

              <InputCampaign
                type='date'
                id='EndDate'
                name='EndDate'
                label={t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.endDate')}
                value={toDateTimeLocal(endDate)}
                //onChange={e => updatePathOptions('EndDate', new Date(e.target.value))}
                onChange={e => getDate(e.target.value, 'End')}
                // onBlur={() => dateValid()}
                isActive
              />

              <InputCampaign
                id='SubTypeCode'
                label={
                  'Bayi Alt Tipi (' +
                  t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.all') +
                  ')'
                }
                style={{ width: 240 }}
                value={pathOptions.SubTypeCode}
                onChange={e => setPathOptions(prev => ({ ...prev, SubTypeCode: e.target.value }))}
                isActive={pathOptions.SubTypeCode.length > 0}
              />
              <IPCheckbox
                label='Deneme Kartı Dahil'
                onChange={e => setPathOptions(prev => ({ ...prev, IncludeDenemeCard: !pathOptions.IncludeDenemeCard }))}
                checked={pathOptions.IncludeDenemeCard}
              />
              <button
                type='submit'
                className={`btn btn-danger d-block ${css`
                  height: 50px;
                `}`}
              >
                {t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.show')}
              </button>
            </form>
            <br />
            <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-sm-12'>
                    {first && (
                      <Table
                        columns={columns}
                        data={data}
                        headerName={`${moment(toDateTimeLocal(filteredStartDate)).format('DD.MM.YYYY')} - ${moment(
                          toDateTimeLocal(filteredEndDate)
                        ).format('DD.MM.YYYY')} tarihleri arasında siteyi gezenler`}
                        columnCount={11}
                        textAlign={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(StatisticsList)
