import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'
import { IPostUser } from '../models/response/getUserListResponseModel'

export class PostUserApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/user/add',
      requesterInfo,
    })
  }

  public async postUser(request: IPostUser): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
