import * as FileSaver from 'file-saver'
import Swal from 'sweetalert2'
import * as XLSX from 'xlsx'
import { ConfigHelper } from '../constants'
import { ClientStorage } from '../storage'
import { DATAGATE_THEME, DEFAULT_THEME, DESPEC_THEME, INDEX_THEME, NETEX_THEME } from '../theme/themeConfig'
import Translations from '../../translations'
import { translate } from '../../base/localization'

export function concatenateAll(...args: string[]) {
  return args.join('')
}

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/
const reMsAjax = /^\/Date\((d|-|.*)\)[/|\\]$/

export function customJsonDateParser(key: any, value: any) {
  if (typeof value === 'string') {
    let a = reISO.exec(value)

    if (a) {
      return new Date(value)
    }

    a = reMsAjax.exec(value)

    if (a) {
      const b = a[1].split(/[-+,.]/)

      return new Date(b[0] ? +b[0] : 0 - +b[1])
    }
  }

  return value
}

export const uuidv4 = (): string => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (Math.random() * 16) | 0

    // eslint-disable-next-line no-mixed-operators
    const v = c === 'x' ? r : (r & 0x3) | 0x8

    return v.toString(16)
  })
}

export const handleImageUpload = (file: File): Promise<string> => {
  return new Promise(resolve => {
    const reader = new FileReader()

    reader.onload = data => {
      // @ts-ignore
      resolve(data.target!.result)
    }

    reader.readAsDataURL(file)
  })
}

export const getUploadFileRequest = async (event: any) => {
  let base64Str: string = ''
  const fileList: any[] = []
  if (event && event.target && event.target.files) {
    for (const file of event.target.files) {
      base64Str = await handleImageUpload(file)
      const fileReq = {
        size: file.size,
        Label: file.name,
        Content: base64Str.split(',')[1],
        Extension: file.type
          ? file.type === 'application/x-zip-compressed'
            ? '.zip'
            : file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              ? '.xlsx'
              : file.type === 'application/vnd.ms-excel'
                ? '.xls'
                : `.${file.type.substr(file.type.lastIndexOf('/') + 1)}`
          : '',
        FileType: file.type
          ? file.type === 'application/x-zip-compressed'
            ? '.zip'
            : `.${file.type.substr(file.type.lastIndexOf('/') + 1)}`
          : '',
      }

      fileList.push(fileReq)
    }
  }

  return fileList
}

export const getUploadFileRequestSplit = async (event: any) => {
  let base64Str: string = ''
  const fileList: any[] = []

  if (event && event.target && event.target.files) {
    for (const file of event.target.files) {
      base64Str = await handleImageUpload(file)
      var name = file.name.lastIndexOf('.')
      const fileReq = {
        Label: file.name,
        Content: base64Str.split(',')[1],
        Extension: file.name.substring(name, file.name.length),
        FileType: file.type,
      }

      fileList.push(fileReq)
    }
  }

  return fileList
}

export const exportToXLSX = (csvData: any, fileName: string) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const ws = XLSX.utils.json_to_sheet(csvData)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })
  FileSaver.saveAs(data, fileName + fileExtension)
}

export const createImageUrl = (imagePath: string) => {
  if (!imagePath) {
    return ''
  }

  if (imagePath[0] === '/') {
    return `${process.env.REACT_APP_IMAGE_URL ?? ''}${imagePath}`
  }

  return `${process.env.REACT_APP_IMAGE_URL ?? ''}/${imagePath}`
}

export const fixDateForApiRequest = (date: Date): Date => {
  if (!date) {
    return new Date()
  }

  const tmpDate = new Date()
  tmpDate.setTime(date.getTime() + 12 * 60 * 60 * 1000)

  return tmpDate
}

export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF'
  let color = '#'

  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }

  return color
}



export const pickColor = (number: number) => {
  const
    colors = [
      '#463e23', '#4e74c1', '#5cb10a', '#95bc3e', '#039f4d', '#34049a', '#fe2437', '#537554', '#afa297', '#2925e3',
      '#1d8f92', '#bd833a', '#92516f', '#e2e21e', '#00dbe7', '#836eee', '#a0c2f6', '#e3479e', '#cdddd3', '#e590ca',
      '#4fd99a', '#1dea04', '#161635', '#fb44fe', '#1bf867', '#9825fe', '#06790e', '#91f5c2', '#c42ecd', '#45f7eb',
      '#fd646b', '#a84a07', '#c50404', '#43d834', '#093c79', '#c9e578', '#0469e7', '#fe8603', '#71124c', '#6a3ba6',
      '#cc166e', '#83ff19', '#f9c55d', '#8400c0', '#fbeea9', '#fc0dbe', '#2992fe', '#67b56f', '#a563b4', '#fdd0ff'
    ];

  return colors[number];
};


export const isValidIBAN = (input: string) => {
  var CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  }

  const iban = input.toUpperCase().replace(/[^A-Z0-9]/g, '')
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/)

  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false
  }

  let digits: string = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: string) => {
    return `${letter.charCodeAt(0) - 55}`
  })

  return mod97(digits) === 1
}

function mod97(digits: string) {
  let checksum: number | string = digits.slice(0, 2)
  let fragment: string = ''

  for (var offset = 2; offset < digits.length; offset += 7) {
    fragment = String(checksum) + digits.substring(offset, offset + 7)
    checksum = parseInt(fragment, 10) % 97
  }

  return checksum
}

export const isValidTCKN = (value: string) => {
  if (!/^[1-9]\d{10}$/.test(value)) {
    return false
  }

  const digits = value.split('')

  const d10 = Number(digits[9])
  const d11 = Number(digits[10])

  let sumOf10 = 0
  let evens = 0
  let odds = 0

  for (let index = 0; index < digits.length; index++) {
    let d: string | number = Number(digits[index])

    if (index < 10) {
      sumOf10 += d
    }

    if (index < 9) {
      if ((index + 1) % 2 === 0) {
        evens += d
      } else {
        odds += d
      }
    }
  }

  if (sumOf10 % 10 !== d11) {
    return false
  }

  if ((odds * 7 + evens * 9) % 10 !== d10) {
    return false
  }

  if ((odds * 8) % 10 !== d11) {
    return false
  }

  return true
}

export const isValidPostCode = (postCode: string, city: string = '') => {
  if (!postCode || postCode.length !== 5) {
    return false
  }

  const cityCodes: string[] = []

  let idx = 1

  while (idx <= 81) {
    cityCodes.push(`${idx}`.padStart(2, '0'))

    idx++
  }

  // TODO : Check the postCode and cityCode is match.

  return postCode && cityCodes.indexOf(postCode.substring(0, 2)) > -1
}

export const isValidVKN = (vkn: string) => {
  if (!/^[0-9]{10}$/.test(vkn)) {
    return false
  }

  let tmp: number
  let sum: number = 0

  let lastDigit: number = Number(vkn.charAt(9))

  for (let index = 0; index < 9; index++) {
    let digit: number = Number(vkn.charAt(index))
    tmp = (digit + 10 - (index + 1)) % 10
    sum = tmp === 9 ? sum + tmp : sum + ((tmp * Math.pow(2, 10 - (index + 1))) % 9)
  }

  return lastDigit === (10 - (sum % 10)) % 10
}

export const formatPrice = (
  price: number,
  currency: 'TRY' | 'USD' | 'EUR' = 'TRY',
  currencyDisplay: 'none' | 'code' | 'name' | 'symbol' = 'none'
) => {
  const locale = currency === 'USD' ? 'en-US' : currency === 'EUR' ? 'en-GB' : 'tr-TR'

  var formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    currencyDisplay: currencyDisplay === 'symbol' || currencyDisplay === 'none' ? undefined : currencyDisplay,
  })

  if (currencyDisplay === 'symbol') {
    var addSymbol
    if (currency === 'TRY') {
      addSymbol = '₺'
    } else if (currency === 'USD') {
      addSymbol = '$'
    } else if (currency === 'EUR') {
      addSymbol = '€'
    }

    return (
      `${formatter
        .formatToParts(price)
        .map<string>((part: Intl.NumberFormatPart) =>
          part.type !== 'literal' && part.type !== 'currency' ? part.value : ''
        )
        .join('')} ` + addSymbol
    )
  }

  return formatter.format(price)
}

export const downloadFile = (name: string, data: string, type: string, isBinary: boolean) => {
  let convertedData: Uint8Array | undefined

  const byteCharacters = atob(data)

  if (isBinary) {
    const bytes = new Array(byteCharacters.length)

    for (var i = 0; i < byteCharacters.length; i++) {
      bytes[i] = byteCharacters.charCodeAt(i)
    }

    convertedData = new Uint8Array(bytes)
  }

  const blob = new Blob([convertedData ?? data], { type: type })
  const objectURL = window.URL.createObjectURL(blob)
  const anchor = document.createElement('a')

  anchor.href = objectURL
  anchor.download = name

  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)

  URL.revokeObjectURL(objectURL)
}

export const downloadFileFromUrl = (name: string, link: string) => {
  const anchor = document.createElement('a')

  anchor.href = link
  anchor.download = name

  document.body.appendChild(anchor)
  anchor.click()
  URL.revokeObjectURL(link)
  document.body.removeChild(anchor)
}

export const excelToJSON = (file: File): Promise<any[]> => {
  return new Promise(resolve => {
    const reader = new FileReader()

    reader.onload = function (e) {
      var data = e.target?.result

      var workbook = XLSX.read(data, {
        type: 'binary',
      })

      const sheetObjects: any[] = []

      workbook.SheetNames.forEach(function (sheetName) {
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])

        sheetObjects.push(XL_row_object)
      })

      resolve(sheetObjects)
    }

    reader.readAsBinaryString(file)
  })
}

export const excelToJSONWithName = (file: File, sheetName: string): Promise<any[]> => {
  return new Promise(resolve => {
    const reader = new FileReader()

    reader.onload = function (e) {
      var data = e.target?.result

      var workbook = XLSX.read(data, {
        type: 'binary',
      })

      const sheetObjects: any[] = []
      if (workbook.SheetNames.includes(sheetName)) {
        var XL_row_object = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName])
        sheetObjects.push(XL_row_object)
      } else {
        console.error(`Sheet with name "${sheetName}" not found in the Excel file.`)
      }

      resolve(sheetObjects)
    }

    reader.readAsBinaryString(file)
  })
}

export const getThemeFromStorage = (): 'index' | 'despec' | 'netex' | 'datagate' | 'default' => {
  let selectedTheme = ClientStorage.getItem(ConfigHelper.INDEXPAZAR_SELECTED_THEME)

  if ([DEFAULT_THEME, INDEX_THEME, NETEX_THEME, DESPEC_THEME, DATAGATE_THEME].indexOf(selectedTheme) === -1) {
    selectedTheme = DEFAULT_THEME
  }

  return selectedTheme
}

export const getDateByTimezoneOffset = (date: Date) => {
  const newDate = new Date(date)
  newDate.setTime(newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000)

  return newDate
}

export const formatDate = (date: Date) => {
  var d = new Date(date),
    year = '' + d.getFullYear(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [year, month, day].join('')
}

export const formatDateForCampaignInput = (date: Date) => {
  var d = new Date(date),
    year = '' + d.getFullYear(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [year, '-', month, '-', day].join('')
}

export const toDateTimeLocal = date => {
  return date
    .toLocaleString('sv-SE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    })
    .replace(' ', 'T')
}

export enum FormatDateRangeEnum {
  Today = 1,
  Yesterday = 2,
  ThisWeek = 3,
  LastWeek = 4,
  ThisMonth = 5,
  LastMonth = 6,
  Last10Days = 7,
  Last15Days = 8,
  Last30Days = 9,
}

export enum FormatBannerImageEnum {
  Login = 1,
  HomepageLeftTop = 2,
  HomepageLeftBottom = 3,
  HomepageRight = 4,
  CounterHomepageTop = 5,
  CounterHomepageBottom = 6,
  MySpecial = 7,
  MiddleBanner = 9,
  BottomBanner = 10,
  ProductDetail = 11,
  BasketProduct = 16,
  BasketPayment = 17,
}

export const formatDateRange = (formatDateRange: FormatDateRangeEnum) => {
  const date = new Date()
  let d1 = new Date()
  let d2 = new Date()

  switch (formatDateRange) {
    case FormatDateRangeEnum.Yesterday:
      date.setDate(date.getDate() - 1)
      d1 = date
      d2 = date

      break
    case FormatDateRangeEnum.ThisWeek:
      d1 = new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1)))

      break
    case FormatDateRangeEnum.LastWeek:
      d1 = new Date(date.setDate(date.getDate() - date.getDay() + (date.getDay() === 0 ? -13 : -6)))

      d1 = date
      tarih = new Date()
      d2 = new Date(tarih.setDate(tarih.getDate() - tarih.getDay() + (tarih.getDay() === 0 ? -7 : 0)))

      d1 = date

      break
    case FormatDateRangeEnum.ThisMonth:
      var month = '' + (date.getMonth() + 1)
      if (month.length < 2) month = '0' + month
      d1 = new Date(month + '/01/' + date.getFullYear())

      break
    case FormatDateRangeEnum.LastMonth:
      var tarih = new Date(date.setMonth(date.getMonth() - 1))
      var month = '' + (tarih.getMonth() + 1)

      if (month.length < 2) month = '0' + month

      d1 = new Date(month + '/01/' + tarih.getFullYear())

      const gun = d2.getDate()
      d2.setDate(d2.getDate() - gun)

      break
    case FormatDateRangeEnum.Last10Days:
      date.setDate(date.getDate() - 10)
      d1 = date

      break
    case FormatDateRangeEnum.Last15Days:
      date.setDate(date.getDate() - 15)
      d1 = date

      break
    case FormatDateRangeEnum.Last30Days:
      var tarih = new Date(date.setDate(date.getDate() - 30))
      d1 = tarih

      break
    default:
      break
  }

  return [d1, d2]
}

export function printElement(element: HTMLElement | null) {
  if (!element) {
    return
  }

  const printFrame = document.getElementById('iframecontentstoprint') as HTMLIFrameElement

  const pri = printFrame?.contentWindow

  pri?.document.open()
  pri?.document.write(element.innerHTML)
  pri?.document.close()
  pri?.focus()
  pri?.print()
}

export function printElementPeriodicReport(element: HTMLElement | null) {
  if (!element) {
    return
  }

  let printFrame = document.getElementById('iframecontentstoprint') as HTMLIFrameElement

  // If the iframe does not exist, create it
  if (!printFrame) {
    printFrame = document.createElement('iframe')
    printFrame.id = 'iframecontentstoprint'
    printFrame.style.position = 'absolute'
    printFrame.style.width = '0px'
    printFrame.style.height = '0px'
    printFrame.style.border = 'none'
    document.body.appendChild(printFrame)
  }

  const pri = printFrame?.contentWindow
  if (pri) {
    pri.document.open()
    pri.document.write(`
      <html>
        <head>
          <meta charset="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="X-UA-Compatible" content="ie=edge" />
          <title>Invoice</title>
          <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
          <style>
            @media print {
              .no-print {
                display: none;
              }
              .periodic-report {
                page-break-after: always;
              }
              table {
                width: 100%;
                border-collapse: collapse;
              }
              th, td {
                border: 1px solid #ddd;
                padding: 8px;
              }
              th {
                background-color: #f2f2f2;
              }
            }
          </style>
        </head>
        <body>
          ${element.innerHTML}
        </body>
      </html>
    `)
    pri.document.close()
    pri.focus()
    pri.print()
  }
}

export function printElementInvoiceRecord(element: HTMLElement | null) {
  if (!element) {
    return
  }

  const printFrame = document.getElementById('iframecontentstoprint') as HTMLIFrameElement
  const pri = printFrame?.contentWindow

  pri?.document.open()
  pri?.document.write(`
    <html>
      <head>
      	<meta charset="UTF-8" />
		<meta name="viewport" content="width=device-width, initial-scale=1.0" />
		<meta http-equiv="X-UA-Compatible" content="ie=edge" />
		<title>Invoice</title>
        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous">
        <style>
            ul {
                padding: 0;
                margin: 0 0 1rem 0;
                list-style: none;
            }
            body {
                font-family: "Inter", sans-serif;
                margin: 0;
            }
            table {
                width: 100%;
                border-collapse: collapse;
            }
            table,
            table th,
            table td {
                border: 1px solid silver;
            }
            table th {
                text-align: left;
                padding: 8px;
                white-space: nowrap;
            }

            table td {
                text-align: right;
                padding: 8px;
                white-space: nowrap;
            }
            h1,
            h4,
            p {
                margin: 0;
            }

            .container {
                padding: 20px 0;
                width: 1000px;
                max-width: 90%;
                margin: 0 auto;
            }

            .inv-title {
                padding: 10px;
                border: 1px solid silver;
                text-align: center;
                margin-bottom: 30px;
            }

            .inv-logo {
                width: 150px;
                display: block;
                margin: 0 auto;
                margin-bottom: 40px;
            }

            /* header */
            .inv-header {
                display: flex;
                margin-bottom: 20px;
            }
            .inv-header > :nth-child(1) {
                flex: 2;
            }
            .inv-header > :nth-child(2) {
                flex: 1;
            }
            .inv-header h2 {
                font-size: 20px;
                margin: 0 0 0.3rem 0;
            }
            .inv-header ul li {
                font-size: 15px;
                padding: 3px 0;
            }

            /* body */
            .inv-body table th,
            .inv-body table td {
                text-align: left;
            }
            .inv-body {
                margin-bottom: 30px;
            }

            /* footer */
            .inv-footer {
                display: flex;
                flex-direction: row;
            }
            .inv-footer > :nth-child(1) {
                flex: 2;
            }
            .inv-footer > :nth-child(2) {
                flex: 1;
            }
        </style>
      </head>
      <body>
          ${element.innerHTML}
          <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
      </body>
    </html>
  `)

  pri?.document.close()

  // Wait for all images to load before printing
  const images = pri?.document.getElementsByTagName('img')
  if (images) {
    const totalImages = images.length
    let loadedImages = 0

    const checkAndPrint = () => {
      loadedImages++
      if (loadedImages === totalImages) {
        pri?.focus()
        pri?.print()
      }
    }

    if (totalImages === 0) {
      pri?.focus()
      pri?.print()
    } else {
      Array.from(images).forEach(img => {
        img.addEventListener('load', checkAndPrint)
        img.addEventListener('error', checkAndPrint) // in case of broken image links
        img.src = img.getAttribute('data-src') || img.src
      })
    }
  } else {
    pri?.focus()
    pri?.print()
  }
}

export function printElementOrderRecord(element: HTMLElement | null) {
  if (!element) {
    return
  }

  const printFrame = document.getElementById('iframecontentstoprint') as HTMLIFrameElement
  const pri = printFrame?.contentWindow

  pri?.document.open()
  pri?.document.write(`
    <html>
      <head>
        <style>
               body {
            font-family: 'Arial', sans-serif;
          }
          .print-container {
            margin: 5px;
            padding: 5px;
          }
          .print-table {
            border-collapse: collapse;
            width: 100%;
          }
          .order-detail {
            display: flex;
            margin-bottom: 10px;
          }
          .print-table td {
            padding: 0; 
          }
          .totalArea {
            margin-top: 15px;
          }
        </style>
      </head>
      <body>
        <div class="print-container">
          ${element.innerHTML}
        </div>
      </body>
    </html>
  `)

  // Handle asynchronous loading of images in the print view
  const images = pri?.document.getElementsByTagName('img')
  if (images) {
    Array.from(images).forEach(img => {
      img.src = img.getAttribute('data-src') || img.src
    })
  }

  pri?.document.close()
  pri?.focus()
  pri?.print()
}

export const checkImageSize = (e: any, width: number, height: number): Promise<boolean> => {
  return new Promise<boolean>(resolve => {
    let file: any
    let img: any

    if ((file = e.target.files[0])) {
      img = new Image()
      img.onload = function () {
        if (this.width === width && this.height === height) {
          return resolve(true)
        }

        return resolve(false)
      }

      img.src = (window.URL || window.webkitURL).createObjectURL(file)
    }
  })
}

export const splitStockForTruck = (text: string) => {
  let data = [
    {
      date: '',
      stok: '',
    },
  ]
  let i = 0
  const metin = text.split(';')
  metin.map(m => {
    const veri = m.split(',')
    if (veri[0] > formatDate(new Date())) {
      const year = veri[0].slice(0, 4)
      const month = veri[0].slice(4, 6)
      const day = veri[0].slice(6, 8)
      const tarih = day + '/' + month + '/' + year
      if (data.length === 1) data = [{ date: tarih, stok: veri[1] }]
      else data.push({ date: tarih, stok: veri[1] })
      return i++
    }
    if (i === 0) data = []
  })
  return data
}

export const stockIndicator = (text: number) => {
  if (text <= 0) return '0'
  if (text < 5) return '1 +'
  if (text < 10) return '5 +'
  if (text < 25) return '10 +'
  if (text < 50) return '25 +'
  if (text < 100) return '50 +'
  return '100 +'
}

export enum FormatDealerCodeEnum {
  DealerCode = 1,
  DealerType = 2,
  DealerSubType = 3,
}

export const setDealerCode = (dealerCodes, optionArr) => {
  let list: any = []
  let codeData: any = []
  dealerCodes.forEach(item => {
    const codes: any = optionArr?.find((code: any) => {
      if (item.DealerCode.length < 6) {
        return code === item.DealerCode
      } else {
        return code === '0000' + item.DealerCode
      }
    })
    if (codes === undefined) codeData.push(item.DealerCode)
    else list.push({ label: codes, value: codes })
  })
  if (codeData.length === 0) return [[], list]
  return [codeData, list]
}

export const formatBannerImage = (BannerImage: FormatBannerImageEnum) => {
  const date = new Date()
  let d1 = new Date()
  let d2 = new Date()
  let width = 0
  let heigth = 0
  switch (BannerImage) {
    case FormatBannerImageEnum.Login:
      width = 1920
      heigth = 1080
      break
    case FormatBannerImageEnum.HomepageLeftTop:
      width = 480
      heigth = 240
      break
    case FormatBannerImageEnum.HomepageLeftBottom:
      width = 480
      heigth = 240
      break
    case FormatBannerImageEnum.HomepageRight:
      width = 1400
      heigth = 710
      break
    case FormatBannerImageEnum.CounterHomepageTop:
      width = 1420
      heigth = 140
      break
    case FormatBannerImageEnum.CounterHomepageBottom:
      width = 1420
      heigth = 140
      break
    case FormatBannerImageEnum.MySpecial:
      width = 1420
      heigth = 140
      break
    case FormatBannerImageEnum.MiddleBanner:
      width = 352
      heigth = 227
      break
    case FormatBannerImageEnum.BottomBanner:
      width = 690
      heigth = 250
      break
    case FormatBannerImageEnum.ProductDetail:
      width = 420
      heigth = 63
      break
    case FormatBannerImageEnum.BasketProduct:
      width = 485
      heigth = 180
      break
    case FormatBannerImageEnum.BasketPayment:
      width = 1000
      heigth = 110
      break
    default:
      break
  }

  return [width, heigth]
}

export const stringToHtml = (str: string, type?: string) => {
  const data = document.createElement('html')
  data.innerHTML = str
  let datas = data.querySelector('p')
  if (!datas) return str
  if (type && type === 'list') datas.style.color = 'red'
  return datas.outerHTML
}

export const SliderLoop = (str?: any, enumData?: any, type?: any) => {
  let data =
    str &&
    str.filter((bannerItem: any) => {
      return bannerItem.Location === enumData && bannerItem.Category && bannerItem.Category === type
    })
  if (data.length > 1) return true
  return false
}

export const productGroupImage = (str: string) => {
  let img = ''
  switch (str) {
    case 'All In One PC':
      img = 'images/productGroup/urun_gruplari_All_In_One_PC.jpg'
      break
    case 'Masaüstü PC':
      img = 'images/productGroup/urun_gruplari_Masaustu_PC.jpg'
      break
    case 'Monitör':
      img = 'images/productGroup/urun_gruplari_Monitor.jpg'
      break
    case 'Notebook':
      img = 'images/productGroup/urun_gruplari_Notebook.jpg'
      break
    case 'Tablet':
      img = 'images/productGroup/urun_gruplari_Tablet.jpg'
      break
    case 'Aksesuar (Foto-Video)':
      img = 'images/productGroup/urun_gruplari_Aksesuar_(Foto-Video).jpg'
      break
    case 'Çizici':
      img = 'images/productGroup/urun_gruplari_Cizici.jpg'
      break
    case 'Çizici Aksesuarı':
      img = 'images/productGroup/urun_gruplari_Cizici_Aksesuari.jpg'
      break
    case 'Fotoğraf Makinesi':
      img = 'images/productGroup/urun_gruplari_Fotograf_Makinesi.jpg'
      break
    case 'Lens':
      img = 'images/productGroup/urun_gruplari_Lens.jpg'
      break
    case 'Tarayıcı':
      img = 'images/productGroup/urun_gruplari_Tarayici.jpg'
      break
    case 'Yazıcı':
      img = 'images/productGroup/urun_gruplari_Yazici.jpg'
      break
    case 'Yazıcı Aksesuarı':
      img = 'images/productGroup/urun_gruplari_Yazici_Aksesuari.jpg'
      break
    case 'Projeksiyon':
      img = 'images/productGroup/urun_gruplari_Projeksiyon.jpg'
      break
    case 'İşlemci':
      img = 'images/productGroup/urun_gruplari_Islemci.jpg'
      break
    case 'Bellek':
      img = 'images/productGroup/urun_gruplari_Bellek.jpg'
      break
    case 'Hafıza Kartı':
      img = 'images/productGroup/urun_gruplari_Hafiza_Karti.jpg'
      break
    case 'Kart Okuyucu':
      img = 'images/productGroup/urun_gruplari_Kart_Okuyucu.jpg'
      break
    case 'SSD':
      img = 'images/productGroup/urun_gruplari_SSD.jpg'
      break
    case 'Kulaklıklar ve Mikrofonlar':
      img = 'images/productGroup/urun_gruplari_Kulakliklar_ve_Mikrofonlar.jpg'
      break
    case 'Taşınabilir PC Aksesuarı':
      img = 'images/productGroup/urun_gruplari_Tasinabilir_PC_Aksesuari.jpg'
      break
    case 'Gamepad':
      img = 'images/productGroup/urun_gruplari_Gamepad.jpg'
      break
    case 'Office 365':
      img = 'images/productGroup/office_365.jpg'
      break
    case 'İşletim Sistemi':
      img = 'images/productGroup/urun_gruplari_Isletim_Sistemi.jpg'
      break
    case 'Kablolar':
      img = 'images/productGroup/urun_gruplari_Kablolar.jpg'
      break
    case 'Kablolu Klavye Set':
      img = 'images/productGroup/urun_gruplari_Kablolu_Klavye_Set.jpg'
      break
    case 'Kablolu Kulaklık':
      img = 'images/productGroup/urun_gruplari_Kablolu_Kulaklik.jpg'
      break
    case 'Kablolu Mouse':
      img = 'images/productGroup/urun_gruplari_Kablolu_Mouse.jpg'
      break
    case 'Kablosuz Klavye':
      img = 'images/productGroup/urun_gruplari_Kablosuz_Klavye.jpg'
      break
    case 'Kablosuz Klavye Set':
      img = 'images/productGroup/urun_gruplari_Kablosuz_Klavye_Set.jpg'
      break
    case 'Kablosuz Mouse':
      img = 'images/productGroup/urun_gruplari_Kablosuz_Mouse.jpg'
      break
    case 'Konsol*':
      img = 'images/productGroup/urun_gruplari_Konsol.jpg'
      break
    case 'Microsoft ESD':
      img = 'images/productGroup/urun_gruplari_Microsoft_ESD.jpg'
      break
    case 'Oyun Aksesuarları':
      img = 'images/productGroup/urun_gruplari_Oyun_Aksesuarlari.jpg'
      break
    case 'Sunucu Yazılımları':
      img = 'images/productGroup/urun_gruplari_Sunucu_Yazilimlari.jpg'
      break
    case 'Uygulama Yazılımları':
      img = 'images/productGroup/urun_gruplari_Uygulama_Yazilimlari.jpg'
      break
    case 'Web Kamera':
      img = 'images/productGroup/urun_gruplari_Web_Kamera.jpg'
      break
    case 'Nas':
      img = 'images/productGroup/urun_gruplari_Nas.jpg'
      break
    case 'Murekkep':
      img = 'images/productGroup/urun_gruplari_Murekkep.jpeg'
      break
    case 'Laser Toner':
      img = 'images/productGroup/urun_gruplari_Laser_Toner.jpeg'
      break
    case 'Yazıcı Yedek Parçaları':
      img = 'images/productGroup/urun_gruplari_Yazici_Yedek_Parcalari.jpeg'
      break
    case 'Kağıt':
      img = 'images/productGroup/urun_gruplari_Kagit.jpeg'
      break
    case 'Fotokopi Toneri':
      img = 'images/productGroup/urun_gruplari_Fotokopi_Toneri.jpeg'
      break
    case 'Drum':
      img = 'images/productGroup/urun_gruplari_Drum.jpeg'
      break
    case 'Mürekkep Kartuş (GFY/LFP) ':
      img = 'images/productGroup/urun_gruplari_Murekkep_Kartus_(GYF-LFP).jpeg'
      break
    case 'Akıllı Saatler':
      img = 'images/productGroup/urun_gruplari_Akilli_Saatler.jpg'
      break
    case 'Translator':
      img = 'images/productGroup/urun_gruplari_Translator.jpg'
      break
    case 'Bluetooth Hoparlör':
      img = 'images/productGroup/urun_gruplari_BluetoothHoparlör.jpg'
      break
    case 'Kablolu Kulaklık':
      img = 'images/productGroup/urun_gruplari_KabloluKulaklık.jpg'
      break
    case 'Kablosuz Kulaklık':
      img = 'images/productGroup/urun_gruplari_KablosuzKulaklık.jpg'
      break
    case 'Akıllı Telefon':
      img = 'images/productGroup/urun_gruplari_Akıllı_Telefon.jpg'
      break
    case 'SAMSUNG2':
      img = 'images/productGroup/SAMSUNG2.png'
      break
    case 'Akıllı Saat Aksesuarları':
      img = 'images/productGroup/urun_gruplari_Akıllı_Saat_Aksesuarları.jpg'
      break
    case 'Akıllı Telefon':
      img = 'images/productGroup/urun_gruplari_Samsung_Akıllı_Telefon.jpg'
      break
    case 'Anahtar/Swıtch':
      img = 'images/productGroup/urun_gruplari_Anahtar_Switch.jpg'
      break
    case 'Cep Telefonu Kılıfları':
      img = 'images/productGroup/urun_gruplari_Anahtar_Switch.jpg'
      break
    case 'Docking Station':
      img = 'images/productGroup/urun_gruplari_Docking_Station.jpg'
      break
    case 'Kablolar':
      img = 'images/productGroup/urun_gruplari_Kablolar.jpg'
      break
    case 'Kablolu Kulaklık':
      img = 'images/productGroup/urun_gruplari_Kablolulu_Kulaklık.jpg'
      break
    case 'Kablosuz Kulaklık':
      img = 'images/productGroup/urun_gruplari_Kablosuz_Kulaklık.jpg'
      break
    case 'Powerbank':
      img = 'images/productGroup/urun_gruplari_Powerbank.jpg'
      break
    case 'Tablet Aksesuarları':
      img = 'images/productGroup/urun_gruplari_Tablet_Aksesuarlari.jpg'
      break
    case 'Telefon / Tablet Şarj Cihazları':
      img = 'images/productGroup/urun_gruplari_Telefon_Tablet_Şarj_Cihazları.jpg'
      break
    case 'Vive_Pro_Eye':
      img = 'images/productGroup/99HARJ002_00_Vive_Pro_Eye.mp4'
      break
    case 'Sanal Gerçeklik':
      img = 'images/productGroup/urun_gruplari_sanal_gerçeklik.jpg'
      break
    case 'Sanal Gerçeklik Aksesuarları':
      img = 'images/productGroup/urun_gruplari_SanalGerçeklik_Aksesuarları.jpg'
      break
    case 'Kesintisiz Güç Kaynağı (1)':
      img = 'images/productGroup/urun_gruplari_Kesintisiz_Guc_Kaynagi_(1).jpg'
      break
    case 'Kesintisiz Güç Kaynağı':
      img = 'images/productGroup/urun_gruplari_Kesintisiz_Guc_Kaynagi.jpg'
      break
    case 'Bluetooth Hoparlör':
      img = 'images/productGroup/HP-Bluetooth-Hoparlör.jpg'
      break
    case 'Çoğaltıcılar':
      img = 'images/productGroup/hp-cogalticilar.jpg'
      break
    case 'Görüntüleme':
      img = 'images/productGroup/HP-görüntüleme.jpg'
      break
    case 'Kablolu Hoparlör':
      img = 'images/productGroup/hp-kablolu-hoparlor.jpg'
      break
    case 'Kablolu Klavye':
      img = 'images/productGroup/hp-kablolu-klavye.jpg'
      break
    case 'Kablolu Klavye Set':
      img = 'images/productGroup/HP-Kablolu-Klavye-Set.jpg'
      break
    case 'Kablolu Kulaklık':
      img = 'images/productGroup/hp-kablolu-kulaklik.jpg'
      break
    case 'Kablolu Mouse':
      img = 'images/productGroup/hp-kablolu-mouse.jpg'
      break
    case 'Kablosuz Klavye':
      img = 'images/productGroup/hp-kablosuz-klavye.jpg'
      break
    case 'Kablosuz Klavye Set':
      img = 'images/productGroup/HP-Kablosuz-Klavye-Set.jpg'
      break
    case 'Kablosuz Kulaklık':
      img = 'images/productGroup/hp-kablosuz-kulaklik.jpg'
      break
    case 'Kablosuz Kablosuz Mouse':
      img = 'images/productGroup/hp-kablosuz-Mouse.jpg'
      break
    case 'Konsol Aksesuarları':
      img = 'images/productGroup/hp-konsol-aksesuarlari.jpg'
      break
    case 'Notebook Adaptörleri':
      img = 'images/productGroup/hp-notebook-adaptorleri.jpg'
      break
    case 'Notebook Cantaları':
      img = 'images/productGroup/hp-notebook-cantalari.jpg'
      break
    case 'Notebook Kılıfları':
      img = 'images/productGroup/hp-notebook-kiliflari.jpg'
      break
    case 'Notebook Sırt Çantaları':
      img = 'images/productGroup/hp-notebook-sırt-cantalari.jpg'
      break
    case 'Optik Medya':
      img = 'images/productGroup/hp-optik-medya.jpg'
      break
    case 'Powerbank':
      img = 'images/productGroup/hp-powerbank.jpg'
      break
    case 'SSD Dahili':
      img = 'images/productGroup/hp-SSD-Dahili.jpg'
      break
    case 'SSD Harici':
      img = 'images/productGroup/hp-ssd-harici.jpg'
      break
    case 'Stand':
      img = 'images/productGroup/hp-stand.jpg'
      break
    case 'Sunum Kumandaları':
      img = 'images/productGroup/hp-sunum-kumandalari.jpg'
      break
    case 'Telefon Tablet Şarj Cihazları':
      img = 'images/productGroup/hp-telefon-tablet-sarj-cihazlari.jpg'
      break
    case 'Mürekkep Kartuş':
      img = 'images/productGroup/EPSON-Mürekkep-Kartuş.jpg'
      break
    case 'Yazıcı Şeridi':
      img = 'images/productGroup/EPSON-Yazıcı-Şeridi.jpg'
      break
    case 'Temizlik Malzemesi':
      img = 'images/productGroup/Perfects-Temizlik-Malzemesi.jpg'
      break
    case 'Switch':
      img = 'images/productGroup/urun_gruplari_Anahtar_(Switch).jpg'
      break
    case 'Ip Telefon':
      img = 'images/productGroup/urun_gruplari_IP_Telefon.jpg'
      break
    case 'Kablosuz Ağ Ürünü':
      img = 'images/productGroup/urun_gruplari_Kablosuz_Ag_Urunu.jpg'
      break
    case 'Network':
      img = 'images/productGroup/urun_gruplari_Network.jpg'
      break
    case 'Video Konferans':
      img = 'images/productGroup/urun_gruplari_Video Konferans.jpg'
      break
    case 'VOIP Çözümleri':
      img = 'images/productGroup/urun_gruplari_VOIP_Cozumleri.jpg'
      break
    case 'Router':
      img = 'images/productGroup/urun_gruplari_Yonlendirici_(Router).jpg'
      break
    case 'Adaptör':
      img = 'images/productGroup/urun_gruplari_Adaptor.jpg'
      break
    case 'Adaptör*':
      img = 'images/productGroup/urun_gruplari_Adaptor.jpg'
      break
    case 'Aksesuar':
      img = 'images/productGroup/urun_gruplari_Aksesuar.jpg'
      break
    case 'Jack':
      img = 'images/productGroup/urun_gruplari_Jack.jpg'
      break
    case 'Kabinet':
      img = 'images/productGroup/urun_gruplari_Kabinet.jpg'
      break
    case 'Kablolar':
      img = 'images/productGroup/urun_gruplari_Kablolar (1).jpg'
      break
    case 'Konnektör':
      img = 'images/productGroup/urun_gruplari_Konnektor.jpg'
      break
    case 'Patch Cord':
      img = 'images/productGroup/urun_gruplari_Patch_Cord.jpg'
      break
    case 'Patch Panel':
      img = 'images/productGroup/urun_gruplari_Patch_Panel.jpg'
      break
    case 'Priz':
      img = 'images/productGroup/urun_gruplari_Priz.jpg'
      break
    case 'Yeraltı Ek Kutusu':
      img = 'images/productGroup/urun_gruplari_Yeralti_Ek_Kutusu.jpg'
      break
    case 'BARKOD OKUYUCULAR':
      img = 'images/productGroup/urun_gruplari_Barkod Okuyucular.jpg'
      break
    case 'BARKOD YAZICILAR':
      img = 'images/productGroup/urun_gruplari_Barkod Yazıcılar.jpg'
      break
    case 'BATARYA':
      img = 'images/productGroup/urun_gruplari_Batarya.jpg'
      break
    case 'EL TERMİNALLERİ':
      img = 'images/productGroup/urun_gruplari_El Terminalleri.jpg'
      break
    case 'MOBİL PC':
      img = 'images/productGroup/urun_gruplari_Mobil Pcjpg.jpg'
      break
    case 'OT/VT AKSESUARLARI':
      img = 'images/productGroup/urun_gruplari_OT -VT Aksesuarları.jpg'
      break
    case 'SABİT BARKOD OKUYUCULAR':
      img = 'images/productGroup/urun_gruplari_Sabit Barkod Okuyucular.jpg'
      break
    case 'SABİT BARKOD OKUYUCULAR':
      img = 'images/productGroup/urun_gruplari_Sabit Barkod Okuyucular.jpg'
      break
    case 'SUNUCU':
      img = 'images/productGroup/urun_gruplari_Sunucu.jpg'
      break
    case 'SUNUCU AKSESUARI':
      img = 'images/productGroup/urun_gruplari_Sunucu_Aksesuari.jpg'
      break
    case 'VERİ DEPOLAMA AKSESUAR':
      img = 'images/productGroup/urun_gruplari_Veri_Depolama_Aksesuar.jpg'
      break
    case 'TOUGHBOOK DAYANIKLI':
      img = 'images/productGroup/urun_gruplari_Toughbook Dayanıklı.jpg'
      break
    case 'TOUGHBOOK DAYANIKLI':
      img = 'images/productGroup/urun_gruplari_Toughbook Dayanıklı.jpg'
      break
    case 'BARKOD SARF MALZEME':
      img = 'images/productGroup/urun_gruplari_Barkod Sarf Malzeme.jpg'
      break
    case 'YAZILIM VE ARAÇLAR':
      img = 'images/productGroup/urun_gruplari_Yazılım ve Araçlar.jpg'
      break
    default:
      break
  }
  return img
}

export const dataTablesElement = () => {
  return {
    dom: 'Blfrtip',
    pageLength: 10,
    language: {
      emptyTable: 'Gösterilecek ver yok.',
      processing: 'Veriler yükleniyor',
      sDecimal: '.',
      sInfo: '_TOTAL_ kayıttan _START_ - _END_ arasındaki kayıtlar gösteriliyor',
      sInfoFiltered: '(_MAX_ kayıt içerisinden bulunan)',
      sInfoPostFix: '',
      sInfoThousands: '.',
      sLengthMenu: 'Sayfada _MENU_ kayıt göster',
      sLoadingRecords: 'Yükleniyor...',
      sSearch: 'Ara:',
      sZeroRecords: 'Eşleşen kayıt bulunamadı',
      oPaginate: {
        sFirst: 'İlk',
        sLast: 'Son',
        sNext: 'Sonraki',
        sPrevious: 'Önceki',
      },
      oAria: {
        sSortAscending: ': artan sütun sıralamasını aktifleştir',
        sSortDescending: ': azalan sütun sıralamasını aktifleştir',
      },
      select: {
        rows: {
          _: '%d kayıt seçildi',
          '0': '',
          '1': '1 kayıt seçildi',
        },
      },
    },
    buttons: ['excel', 'pdf', 'print'],
  }
}

export const CategoryTree = (a: any) => {
  const data: any = []
  data.push({
    Id: a.Id,
    Title: a.Title,
  })
  for (var item = a.Item; item != null; item = item.Item) {
    data.push({
      Id: item.Id,
      Title: item.Title,
    })
  }
  return data
}

export const BrandModul = () => {
  const t = translate(Translations)
  return [
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.general'),
      value: '1',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.videos'),
      value: '2',
    },

    {
      // label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.technicaldocuments'),
      label: 'Teknik Dokümanlar',
      value: '3',
    },

    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.ımages'),
      value: '4',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.links'),
      value: '5',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.ImportantInformation'),
      value: '7',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.degreepreview360'),
      value: '8',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.licensing'),
      value: '9',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.managedClients'),
      value: '10',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.managedservers'),
      value: '11',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.Managementservers'),
      value: '12',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.MSOffice'),
      value: '13',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.hptopconfig'),
      value: '14',
    },
    {
      label: t('pages.admin.brandPageOperation.brandInformation.brandUpdate.Inteltechnologyprovider'),
      value: '15',
    },
    {
      label: 'Uyumluluk Tablosu(Despec)',
      value: '16',
    },
    {
      label: 'Fiyat Listeleri(Despec)',
      value: '17',
    },
  ]
}

export const productStatus = (str: number) => {
  let data = ''
  switch (str) {
    case 0:
      data = 'Paket Açılmış'
      break
    case 1:
      data = 'Paket Açılmamış'
      break
    case 2:
      data = 'Kutu Deforme'
      break
    default:
      break
  }
  return data
}

export const modeOfDelivery = (str: number) => {
  let data = ''
  switch (str) {
    case 0:
      data = 'Kargo'
      break
    case 1:
      data = 'Depodan Teslim'
      break
    default:
      break
  }
  return data
}

export const reasonForReturn = (str: number) => {
  let data = ''
  switch (str) {
    case 0:
      data = 'Depoda Bekleyen Ürün'
      break
    case 1:
      data = 'Ayıplı Ürün'
      break
    case 2:
      data = 'Yanlış Ürün'
      break
    case 3:
      data = 'Yanlış Sipariş Verilmesi'
      break
    case 4:
      data = 'Siparişin Yanlış Çıkarılması'
      break
    case 5:
      data = 'Siparişten Vazgeçilmesi'
      break
    case 6:
      data = 'Miktar Hatası'
      break
    case 7:
      data = 'Uyumsuz Ürün'
      break
    case 8:
      data = 'Yanlış Katalog Bilgisi'
      break
    default:
      break
  }
  return data
}

export function formatAsCurrency(number, currency: 'TRY' | 'USD' | 'EUR') {
  const formatter = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
  })

  const formattedNumber = formatter.format(number)

  return formattedNumber
}

export function formatPriceNegatif(number, currency: 'TRY' | 'USD' | 'EUR') {
  const formatter = number + (currency === 'TRY' ? ' ₺' : currency === 'USD' ? ' $' : ' €')

  return formatter
}

// Kullanım örneği:
// const number = 1234.56;
// const formattedNumberTRY = formatAsCurrency(number, 'TRY');
// const formattedNumberUSD = formatAsCurrency(number, 'USD');
// console.log(formattedNumberTRY); // "1.234,56 ₺"
// console.log(formattedNumberUSD); // "$1,234.56"

export function xmlBase64toFile(base64Str: string, filename: string) {
  if (!base64Str) {
    throw 'Empty string!'
  }

  const arr = base64Str.split(',')
  const mimeType = 'text/xml'
  const bstr = atob(arr[arr.length - 1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mimeType })
}
