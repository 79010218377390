import React from 'react'
import Item from './Item/Item'
import Header from './Header'
import Sidebar from './Sidebar'
import CustomCard from './Components/CustomCard'

function MySpecialLayout(props: any) {
  return (
    <React.Fragment>
      <section style={{ padding: '20px 0', background: '#f5f5f5' }}>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-lg-3'>
              <Sidebar />
            </div>
            <div className='col-12 col-lg-9 sidebar-right mt-lg-0 mt-5 p-sm-2'>
              <CustomCard>
                <Header />
                {props.children}
              </CustomCard>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default MySpecialLayout
