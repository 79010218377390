import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { translate } from '../../../../base/localization'
import { IException } from '../../../../base/models'
import Translations from '../../../../translations'
import { IProduct, IRecommendedProducts } from '../../../ProductList/models/response/getProductListResponseModel'
import {
  IGetProductByIDResponseModel,
  IProductIyzicoResponseModel,
  IyzicoInstallmentRequestModel,
} from '../../models/getProductByIDResponseModel'
import { GetIyzicoInstallmentsListApi } from '../../services/getIyzicoInstallmentsListApi'
import { GetOpportunitMarketApi } from '../../services/getOpportunitMarketApi'
import { GetProductByIDApi } from '../../services/getProductByIDApi'
import Feedback from '../Feedback/Feedback'
import HeaderLast from '../Header/HeaderLast'
import ProductInfo from '../ProductInfo/ProductInfo'
import Specifications from '../Specifications/Specifications'
import styles from '../styles/ProductDetail.module.scss'
import '../styles/tab.css'
import { IProductColorResponse } from '../../../Admin/ProductOperation/ProductColor/models/models'
import { GetProductColorByProductApi } from '../../../Admin/ProductOperation/ProductColor/services/getProductColorListByProduct'
import { GetProductOtherCompany } from '../../services/getProductOtherCompany'
import { GetSuggestApi } from '../../services/getSuggestApi'

function ProductDetail(props: BaseComponentWithContextProps) {
  const [product, setProduct] = useState<IProduct | undefined>(undefined)
  const [recommendedProducts, setRecommendedProducts] = useState<IRecommendedProducts[]>([])
  const [isDos, setIsDos] = useState<boolean>(false)
  const [isOffice, setIsOffice] = useState<boolean>(false)

  const params = useParams()
  const t = translate(Translations)

  useEffect(() => {
    getProduct(Number(params.id), params.type)
  }, [params.id])

  const getProduct = async (productId, type) => {
    props.showLoading && props.showLoading()
    if (type === 'product') {
      const getProductByIDApi = new GetProductByIDApi({})
      getProductByIDApi
        .getProductByID(productId)
        .then((productResponse: IGetProductByIDResponseModel) => {
          if (productResponse && productResponse.Product) {
            setProduct(productResponse.Product)
          }

          if (productResponse && productResponse.RecommendedProducts) {
            setRecommendedProducts(productResponse.RecommendedProducts)
          }
          if (productResponse && productResponse.IsOffice365) {
            setIsOffice(productResponse.IsOffice365)
          }
          if (productResponse && productResponse.IsWin11AndOffice) {
            setIsDos(productResponse.IsWin11AndOffice)
          }
          props.hideLoading && props.hideLoading()
        })
        .catch((err: IException) => {})
        .finally(() => {
          props.hideLoading && props.hideLoading()
        })
    }
    if (type === 'opportunit') {
      const getOpportunitMarket: GetOpportunitMarketApi = new GetOpportunitMarketApi({})
      getOpportunitMarket
        .getOpportunitMarket(productId)
        .then((productResponse: IProduct) => {
          if (productResponse) {
            setProduct(productResponse)
          }
        })
        .catch((err: IException) => {})
        .finally(() => {
          props.hideLoading && props.hideLoading()
        })
    }
  }

  const onProductChange = (product: IProduct) => {
    setProduct(product)
  }

  // if (!product) {
  //   return (
  //     <div className="container mt-5 mb-5">
  //       {t("pages.productdetail.noProduct")}
  //     </div>
  //   );
  // }
  const [bankInstallmentsData, setBankInstallmentsData] = useState<IProductIyzicoResponseModel[]>()

  const req: IyzicoInstallmentRequestModel = {
    ProductModel: product,
    Warehouse:
      product &&
      (params.type === 'product' ? product.WarehouseModels[0].WarehouseName : product?.OpportunityWarehouse.toString()),
  }

  const getIyzicoInstallments = async () => {
    try {
      const iyzicoInstallments: GetIyzicoInstallmentsListApi = new GetIyzicoInstallmentsListApi({})
      const res: IProductIyzicoResponseModel[] = await iyzicoInstallments.getIyzicoInstallments(req)

      if (res && res.length > 0) {
        setBankInstallmentsData(res)
      }
      // Additional logic if needed
    } catch (error) {
      // Handle errors appropriately
      console.error('Error fetching Iyzico installments:', error)
    }
  }

  const [suggestProducts, setSuggestList] = useState<any>()
  const [productColor, setProductColor] = useState<IProductColorResponse[]>([])
  const [otherProducts, setOtherProducts] = useState<any>()

  useEffect(() => {
    if (params.type === 'product') {
      ProductSuggest()
      getColorList()
      getOtherProducts()
    }
  }, [])

  const ProductSuggest = async () => {
    const getSuggestApi: GetSuggestApi = new GetSuggestApi({})
    const res = await getSuggestApi.getSuggest(Number(params.id))

    if (res) {
      setSuggestList(res)
    }
  }

  const getColorList = async () => {
    const getProductColorApi: GetProductColorByProductApi = new GetProductColorByProductApi({})
    getProductColorApi
      .getProductColor(Number(params.id))
      .then((result: IProductColorResponse[]) => {
        setProductColor(result)
      })
      .catch((err: any) => {
        console.log('renk kaydı bulunamadı')
      })
  }

  const getOtherProducts = async () => {
    const requestData = {
      id: Number(params.id),
    }
    const getProductOthers: GetProductOtherCompany = new GetProductOtherCompany({})
    getProductOthers
      .getProductOtherCompany(requestData)
      .then((result: any) => {
        setOtherProducts(result)
      })
      .catch((err: any) => {
        console.log('başka ürün bulunamadı')
      })
  }

  return (
    <div className='container mt-5 mb-5'>
      <div>
        {product && (
          <>
            <HeaderLast
              product={product}
              recommendedProducts={recommendedProducts}
              onProductChange={onProductChange}
              prop={props}
              IsWin11AndOffice={isDos}
              IsOffice365={isOffice}
              Suggest={suggestProducts}
              productColor={productColor}
              otherProducts={otherProducts}
            />
            <div className={`${styles.mobiL} tabs mt-3 `}>
              <div className=''>
                <ul
                  className={`${styles.mem} nav nav-tabs d-flex justify-content-between align-items-center`}
                  id='myTab'
                  role='tablist'
                >
                  {params.type === 'opportunit' && (
                    <li className='nav-item'>
                      <a
                        className='nav-link active'
                        id='status-tab'
                        data-toggle='tab'
                        href='#status'
                        role='tab'
                        aria-controls='status'
                        aria-selected='true'
                      >
                        Ürün Durumu
                      </a>
                    </li>
                  )}
                  <li className='nav-item'>
                    <a
                      className={params.type === 'product' ? 'nav-link active' : 'nav-link'}
                      id='reviews-tab'
                      data-toggle='tab'
                      href='#reviews'
                      role='tab'
                      aria-controls='reviews'
                      aria-selected='true'
                    >
                      Ürün Bilgisi
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='specifications-tab'
                      data-toggle='tab'
                      href='#specifications'
                      role='tab'
                      aria-controls='specifications'
                      aria-selected='false'
                    >
                      {t('pages.productdetail.TechnicialSpecifications')}
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='payment-options-tab'
                      data-toggle='tab'
                      href='#payment-options'
                      role='tab'
                      aria-controls='payment-options'
                      aria-selected='false'
                      onClick={getIyzicoInstallments}
                    >
                      {t('pages.productdetail.PaymentMethods')}
                    </a>
                  </li>
                  {/* <li className="nav-item">
      <a
        className="nav-link"
        id="user-comments-tab"
        data-toggle="tab"
        href="#user-comments"
        role="tab"
        aria-controls="user-comments"
        aria-selected="false"
      >
        {t("pages.productdetail.Comments")}
      </a>
    </li> */}
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='feedback-tab'
                      data-toggle='tab'
                      href='#feedback'
                      role='tab'
                      aria-controls='feedback'
                      aria-selected='false'
                    >
                      {t('pages.productdetail.Feedback')}
                    </a>
                  </li>
                </ul>

                <div className='row'>
                  <div className='col-lg-12'>
                    <div className={`${styles.mobiL} tab-content`} id='myTabContent'>
                      {params.type === 'opportunit' && (
                        <div
                          className='tab-pane fade show active'
                          id='status'
                          role='tabpanel'
                          aria-labelledby='status-tab'
                        >
                          <p
                            style={{ textAlign: 'center' }}
                            dangerouslySetInnerHTML={{ __html: product.OpportunityDescription }}
                          ></p>
                          <p style={{ fontSize: '18px', textAlign: 'center' }}>
                            * İade Şartı: Fırsat Pazarı ürünleri sadece açılmış, taşıma sırasında ambalajı hasar görmüş
                            veya garanti süresi dolmuş ürünlerdir. Ürünün kendisinde kozmetik hasar, çalışmayan
                            fonksiyon, eksik aksam olması halinde %100 iade garantisi mevcuttur.
                          </p>
                          <p style={{ fontSize: '18px', textAlign: 'center' }}>
                            *{' '}
                            {product.CompanyId === 1
                              ? 'İndex'
                              : product.CompanyId === 2
                              ? 'Netex'
                              : product.CompanyId === 3
                              ? 'Despec'
                              : 'Datagate'}{' '}
                            kampanyayı haber vermeksizin durdurabilir.
                          </p>
                        </div>
                      )}
                      <div
                        className={params.type === 'product' ? 'tab-pane fade show active' : 'tab-pane fade'}
                        id='reviews'
                        role='tabpanel'
                        aria-labelledby='reviews-tab'
                      >
                        <ProductInfo product={product} />
                        {/* <Review name={DATA.name} /> */}
                      </div>

                      <div
                        className='tab-pane fade'
                        id='payment-options'
                        role='tabpanel'
                        aria-labelledby='payment-options-tab'
                        style={{ width: '60%', textAlignLast: 'center', margin: '0 20%' }}
                      >
                        <>
                          <h5>Iyzico Sepet Oranları</h5>
                          <div className='form-group form-fg'>
                            <table className='table'>
                              <thead className='table-light '>
                                <tr style={{ textAlign: 'center' }}>
                                  <th style={{ width: '25%' }}></th>
                                  <th style={{ textAlign: 'center', width: '40%' }}>Taksit Sayısı</th>
                                  <th style={{ textAlign: 'center', width: '35%' }}>Oran</th>
                                </tr>
                              </thead>
                              <tbody>
                                {bankInstallmentsData &&
                                  bankInstallmentsData.map((item, index) => (
                                    <tr key={index}>
                                      <td style={{ textAlign: 'center' }}>
                                        <img
                                          src='/images/iyzico_ile_ode_colored_horizontal.png'
                                          style={{ width: '70%' }}
                                          alt='Iyzico Logo'
                                        />
                                      </td>
                                      <td style={{ textAlign: 'center' }}>{item.Installment && item.Installment}</td>
                                      <td style={{ textAlign: 'center' }}>{item.RateUI && `${item.RateUI}%`}</td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                          <Link to='../myspecial/creditCardRates' target='_blank' className='button-link'>
                            <p className='h5' style={{ color: 'black' }}>
                              <strong>
                                <i className='icon-credit-card'></i> Kredi Kartı Komisyon Oranlarını Görmek İçin
                                Tıklayınız
                              </strong>
                            </p>
                          </Link>
                        </>
                      </div>
                      <div
                        className='tab-pane fade'
                        id='specifications'
                        role='tabpanel'
                        aria-labelledby='specifications-tab'
                      >
                        <Specifications product={product} />
                      </div>
                      {/* <div
      className="tab-pane fade"
      id="user-comments"
      role="tabpanel"
      aria-labelledby="user-comments-tab"
    >
      <Comments
        //name={DATA.name}
        name={product.Name}
      />
    </div> */}
                      <div className='tab-pane fade' id='feedback' role='tabpanel' aria-labelledby='feedback-tab'>
                        <Feedback id={product.Id} name={product.Name} />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-3">
      {product && <Sidebar product={product} />}
       </div> */}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default componentWithContext(ProductDetail)
