/* eslint-disable jsx-a11y/anchor-is-valid */
import { css } from '@emotion/css'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { translate } from '../../../../../base/localization'
import { IException } from '../../../../../base/models'
import IPButton from '../../../../../components/IPButton'
import IPModal from '../../../../../components/IPModal'
import Table from '../../../../../components/Table'
import Translations from '../../../../../translations'
import { BankNotetListResponseModel, IBankNoteList } from '../models/models'
import { DeleteBankNoteApi } from '../services/deleteBankNoteApi'
import { ListBankNoteApi } from '../services/listBankNoteApi'

export interface BanknoteListState {
  bankNotes: IBankNoteList[]
}

function BankNoteList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): BanknoteListState => {
    return {
      bankNotes: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<BanknoteListState>(intialState)
  const [allHistory, setAllHistory] = useState<BankNotetListResponseModel[]>([])
  const [showHistoryDetail, setShowHistoryDetail] = useState<boolean>(false)

  useEffect(() => {
    getBankNotes()
  }, [])

  const getBankNotes = async () => {
    try {
      props.showLoading && props.showLoading()
      const api: ListBankNoteApi = new ListBankNoteApi({})
      const result: IBankNoteList[] | null = await api.listBankNote()

      props.hideLoading && props.hideLoading()

      if (result) {
        setState({ bankNotes: result })
      } else {
        console.error('Received null response from the API.')
        // Handle null response as needed
      }
    } catch (err) {
      props.hideLoading && props.hideLoading()
      await Swal.fire({
        icon: 'error',
        title: (err as IException).description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const deleteBankNote = async (ID: number) => {
    const shouldDelete = await Swal.fire({
      icon: 'warning',
      title: 'Bu notu silmek istediğinizden emin misiniz?',
      showCancelButton: true,
      showConfirmButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
    })

    if (shouldDelete.isConfirmed) {
      const data = state.bankNotes.find(item => item.BankNoteId === ID)

      if (data) {
        const { BankNoteId } = data // Destructure the ID
        const deleteBankNote = new DeleteBankNoteApi({})

        try {
          const result = await deleteBankNote.deleteBankNote(BankNoteId)

          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Banka Taksit Notu Silindi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then(async result => {
              if (result.isConfirmed) {
                getBankNotes()
              }
            })
          }
        } catch (err: any) {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    }
  }

  const navigate: any = useNavigate()
  const updateBankNote = async (Id: number) => {
    var dt = state.bankNotes.find(auth => {
      return auth.BankNoteId === Id
    })
    navigate('/admin/BankAndInstallementOperation/BankNote/AddBankNote', {
      state: { bankNoteData: dt },
    })
  }

  // Tablo başlıkları ve sütun değerleri
  const columnDefinitions = [
    { Header: 'Sıra', accessor: 'col1' },
    { Header: 'Banka Adı', accessor: 'col2' },
    { Header: 'Not', accessor: 'col3' },
    { Header: 'Başlangıç Tarihi', accessor: 'col4' },
    { Header: 'Bitiş Tarihi', accessor: 'col5' },
    { Header: 'Son Değişiklik', accessor: 'col6' },
    { Header: 'Tarihçe', accessor: 'col7' },
    { Header: 'Durum', accessor: 'col8' },
    { Header: 'İşlemler', accessor: 'col9' },
  ]
  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableData = useMemo(() => {
    if (!Array.isArray(state.bankNotes)) {
      return []
    }

    return state.bankNotes.map((item, index) => {
      const data = {
        col1: index + 1,
        col2: item.BankName,
        col3: item.Note,
        col4: moment(item.StartDate).format('DD.MM.YYYY HH:mm:ss'),
        col5: moment(item.EndDate).format('DD.MM.YYYY HH:mm:ss'),
        col6:
          item.UpdatedDate !== null
            ? moment(item.UpdatedDate).format('DD.MM.YYYY HH:mm:ss')
            : moment(item.CreatedDate).format('DD.MM.YYYY HH:mm:ss'),
        col7: (
          <IPButton
            text='Tarihçe'
            onClick={() => {
              setShowHistoryDetail(true)
              var selectedData = state.bankNotes.find(auth => auth.BankName === item.BankName)
              if (selectedData) {
                const { AllHistory } = selectedData

                if (AllHistory && AllHistory.length > 0) {
                  setAllHistory(AllHistory)
                } else {
                  setAllHistory([])
                }
              } else {
                setAllHistory([])
              }
            }}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
        col8: item.IsDeleted ? 'Aktif' : 'Pasif',
        col9: (
          <>
            <a
              onClick={() => updateBankNote(item.BankNoteId)}
              data-action-type='edit'
              role='button'
              className={css`
                cursor: pointer;
              `}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.edit')}
            >
              <i
                className={`${css`
                  font-size: 1.2rem;
                  color: green;
                `} icon-edit`}
              ></i>
            </a>
            <a
              onClick={() => deleteBankNote(item.BankNoteId)}
              data-action-type='delete'
              role='button'
              className={css`
                cursor: pointer;
              `}
              data-toggle='tooltip'
              data-original-title={t('pages.mySpecial.userUpdate.delete')}
            >
              <i
                className={`${css`
                  font-size: 1.2rem;
                  color: red;
                `} icon-trash-2`}
              ></i>
            </a>
          </>
        ),
      }
      return data
    })
  }, [state.bankNotes])

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <div className='col-12 col-md-9 mb-2'>
              <span className='h4 mb-0'>Banka Taksit Notları</span>
            </div>
            <div className='col-12 col-md-2 mb-2'>
              <label className='p-switch switch-color-success'>
                <Link to='/admin/BankAndInstallementOperation/BankNote/AddBankNote'>
                  <IPButton
                    text='Not Ekle'
                    className='btn btn-primary'
                    style={{ height: '45px', marginLeft: '15px' }}
                  />
                </Link>
              </label>
            </div>
            {/* <div className='col-12 col-md-1'>
              <div className='d-flex align-items-center'>
                <span>Excel: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButton}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
              </div>
            </div> */}
          </div>
          <div className='card-body'>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
        </div>
      </div>
      <IPModal
        show={showHistoryDetail}
        onClose={() => setShowHistoryDetail(false)}
        hideConfirm={true}
        width={1200}
        title='Banka Taksit Tarihçe'
      >
        <table
          id='datatable'
          className='table table-bordered table-hover dataTable'
          role='grid'
          aria-describedby='datatable_info'
        >
          <thead className='tableodd'>
            <tr role='row'>
              <th>Değişiklik Tarihi</th>
              <th>Banka Adı</th>
              <th>Başlangıç Tarihi</th>
              <th>Bitiş Tarihi</th>
              <th>Önceki Not</th>
              <th>Yeni Not</th>
              <th>Aktif/Pasif</th>
              <th>Kullanıcı</th>
              <th>Ip</th>
              <th>Açıklama</th>
            </tr>
          </thead>
          <tbody>
            {allHistory && allHistory.length > 0
              ? allHistory.map((ba, index) => (
                  <tr role='row' className='odd' key={ba.BankName}>
                    <td>{ba.ChangeDate ? moment(ba.ChangeDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                    <td>{ba.BankName}</td>
                    <td>{ba.StartDate ? moment(ba.StartDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                    <td>{ba.EndDate ? moment(ba.EndDate).format('DD.MM.YYYY HH:mm:ss') : ''}</td>
                    <td>{ba.OldNote}</td>
                    <td>{ba.NewNote}</td>
                    <td>{ba.IsDeleted && ba.IsDeleted ? 'Aktif' : 'Pasif'}</td>
                    <td>{ba.UserName}</td>
                    <td>{ba.UserIp}</td>
                    <td>{ba.Description}</td>
                  </tr>
                ))
              : ''}
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}
export default componentWithContext(BankNoteList)
