import { css } from '@emotion/css'
import moment from 'moment'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { translate } from '../../../../base/localization'
import { exportToXLSX, getDateByTimezoneOffset, printElement } from '../../../../base/utils/dataHelper'
import IPButton from '../../../../components/IPButton'
import IPInput from '../../../../components/IPInput'
import IPModal from '../../../../components/IPModal'
import IPSelectBox from '../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../components/InputCampaign'
import { GetCompaniesApi } from '../../../../layouts/components/client/services/getCompaniesApi'
import Translations from '../../../../translations'
import { GetSerialNumberCheckResponse } from '../../MyOrders/models/response/getOrderListResponseModel'
import { GetCargoTrackingApi } from '../../MyOrders/services/getCargoTracking'
import { BrandList } from './BrandListApi'
import './style.scss'

interface SerialNumberState {
  companyOptions: ISelectOption[]
  companyValue?: ISelectOption
  companyCode: string
  serialNumber: string
  eInvoiceNumber: string
  invoiceNumber: string
  Marka?: ISelectOption
  showSerialNumbers: boolean
  serialNumbers: GetSerialNumberCheckResponse[]
  brandListOptions: ISelectOption[]
}

function Index(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): SerialNumberState => {
    return {
      companyOptions: [],
      serialNumber: '',
      eInvoiceNumber: '',
      invoiceNumber: '',
      showSerialNumbers: false,
      serialNumbers: [],
      companyCode: '',
      brandListOptions: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<SerialNumberState>(intialState)
  useEffect(() => {
    getCompanies()
  }, [])

  const getCompanies = async () => {
    const res = await new GetCompaniesApi({}).getCompanyApi()
    if (res) {
      const data: any = []
      res.forEach(item => {
        if (item.Name === 'Index Group') return
        data.push({ value: item.Code, label: item.Name })
      })
      setState({ companyOptions: data })
    }
  }

  const getSerialNumberCheck = async () => {
    if (
      state.companyCode.length === 0 &&
      state.serialNumber.length === 0 &&
      state.invoiceNumber.length === 0 &&
      state.eInvoiceNumber.length === 0 &&
      state.Marka
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen yukarıdaki verilerden en az birini giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (state.companyCode.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen şirket seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    if ((startDate && !endDate) || (endDate && !startDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Başlangıç ve bitiş tarihleri aynı anda seçilmelidir.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    props.showLoading()
    const req = {
      CompanyCode: state.companyCode,
      DealerCode: props.user.AccountCode,
      SerialNumber: state.serialNumber,
      InvoiceNumber: state.invoiceNumber,
      EInvoiceNumber: state.eInvoiceNumber,
      Marka: state.Marka?.value,
      StartDate: startDate ? getDateByTimezoneOffset(startDate) : undefined,
      EndDate: endDate ? getDateByTimezoneOffset(endDate) : undefined,
    }
    try {
      const res = await new GetCargoTrackingApi({}).GetSerialNumberCheck(req)
      props.hideLoading()
      setState({ showSerialNumbers: true, serialNumbers: res.map(x => ({ ...x, Date: new Date(x.Date) })) })
    } catch (err: any) {
      props.hideLoading()
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const inititalStartDate = new Date()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const toDateTimeLocal = date => {
    if (date != null) {
      return date
        .toLocaleString('sv-SE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
        .replace(' ', 'T')
    }
    return undefined
  }

  const getBrandList = async () => {
    const campaignBundleService = new BrandList({})
    const res = await campaignBundleService.getBrandList()
    if (res)
      setState({
        brandListOptions: res.Entity.BrandCode.map(item => {
          return { label: item, value: item }
        }),
      })
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  useEffect(() => {
    getBrandList()
  }, [])

  //#region Excel'e aktarma
  const excelButton = async () => {
    try {
      if (state.serialNumbers) {
        const data: any[] = []
        if (state.serialNumbers.length > 0) {
          state.serialNumbers.map(item => {
            return data.push({
              'Seri No': item.SeriNo,
              'Ürün Kodu': item.ProductCode,
              Marka: item.Marka,
              Adet: item.Amount,
              'Şirket Fatura No': item.EInvoiceNumber,
              'Fatura No': item.InvoiceNumber,
              Tarih: moment(item.Date).format('DD.MM.YYYY'),
              'Birim tutar': item.NETWRBR.toFixed(2) + ' ' + item.CurrencyType,
              Kur: item.Currency.toFixed(2),
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Seri No Kontrolü')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }
  //#endregion

  return (
    <div className='serialNumberCheck ' style={{ backgroundColor: 'white' }}>
      <div className='container-fluid'>
        <form>
          <div className='heading-text'>
            <h4>{t('pages.mySpecial.slider.serialNumberCheck')}</h4>
          </div>
          <p>{t('pages.mySpecial.serialNumberCheck.description')}</p>
          <br></br>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <div className='formEl'>
                <label>{t('pages.mySpecial.serialNumberCheck.serialNumber')}</label>
                <IPInput
                  type='text'
                  value={state.serialNumber}
                  onChange={e => setState({ serialNumber: e.target.value })}
                />
              </div>
              <div className='formEl'>
                <label>E-fatura numarası:</label>
                <IPInput
                  type='text'
                  value={state.eInvoiceNumber}
                  onChange={e => setState({ eInvoiceNumber: e.target.value })}
                />
              </div>
              <div className='formEl'>
                <label>{t('pages.mySpecial.serialNumberCheck.InvoiceNumber')}</label>
                <IPInput
                  type='text'
                  value={state.invoiceNumber}
                  onChange={e => setState({ invoiceNumber: e.target.value })}
                />
              </div>
              <div className='formEl'>
                <label>Şirket</label>
                <IPSelectBox
                  options={state.companyOptions}
                  value={state.companyValue}
                  onChangeSingle={option => {
                    if (option) setState({ companyValue: option, companyCode: String(option.value) })
                    else setState({ companyCode: '', companyValue: undefined })
                  }}
                  isClearable
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='formEl'>
                <label>Marka</label>
                <IPSelectBox
                  isClearable
                  options={state.brandListOptions}
                  value={state.Marka}
                  onChangeSingle={option => setState({ Marka: option })}
                />
              </div>
              <br></br>
              <div className='formEl'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, 'Start')}
                  isActive
                />
              </div>
              <br></br>
              <div className='formEl'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, 'End')}
                  isActive
                />
              </div>
              <div className='formEl'>
                <IPButton
                  text={t('pages.mySpecial.serialNumberCheck.okey')}
                  className='button mt-5'
                  onClick={() => getSerialNumberCheck()}
                  isRounded={false}
                />{' '}
              </div>
            </div>
          </div>
        </form>
      </div>
      <IPModal
        show={state.showSerialNumbers}
        onClose={() => setState({ showSerialNumbers: false })}
        maxWidth={1200}
        onConfirm={() => printElement(document.getElementById('tableSerial'))}
        confirmText='Yazdır'
      >
        <>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>Seri No Kontrolü</span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>

          {state.serialNumbers.length === 0 && 'Kayıt bulunamadı.'}
          {state.serialNumbers.length > 0 && (
            <div
              className={`table-responsive ${css`
                tr td,
                tr th {
                  padding: 0.5rem !important;
                }
              `}`}
              id='tableSerial'
            >
              <table className='table table-striped' style={{ minWidth: 860 }}>
                <thead>
                  <tr className='thead-dark'>
                    <th scope='col'>Seri No</th>
                    <th scope='col'>Ürün Kodu</th>
                    <th scope='col'>Marka</th>
                    <th scope='col'>Adet</th>
                    <th scope='col'>Şirket Fatura No</th>
                    <th scope='col'>Fatura No</th>
                    <th scope='col'>Tarih</th>
                    <th scope='col'>Birim Tutar</th>
                    <th scope='col'>Kur</th>
                  </tr>
                </thead>
                <tbody>
                  {state.serialNumbers.map(x => (
                    <tr>
                      <th>{x.SeriNo}</th>
                      <td>{x.ProductCode}</td>
                      <td>{x.Marka}</td>
                      <td>1</td>
                      <td>{x.EInvoiceNumber}</td>
                      <td>{x.InvoiceNumber}</td>
                      <td>{x.Date.toLocaleDateString()}</td>
                      <td>
                        {x.NETWRBR.toFixed(2)} {x.CurrencyType}
                      </td>
                      <td>{x.Currency.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </>
      </IPModal>
    </div>
  )
}
export default componentWithContext(Index)
