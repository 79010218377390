import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'

export interface ClusterAndLimit {
  Id: number
  Cluster: string
  Limit: number
  ClusterName: string
  ClusterType: number
  UsedLimit: number
  RemainderLimit: number
  History?: IHistory[]
}

export interface IHistory {
  Date: Date
  Limit: number
  NameSurname: string
  UserId: number
  UserName: string
}

export interface CheckUsedRequestModel {
  FcvId: number
  PaymentTermsId: number
}
export interface FCVObject {
  Company: string
  ProductCodes: string[]
  ProductSubTypes: string[]
  Quota: string[]
  Brands: string[]
  ExpiryDay: number
  InvoiceNumbers: []
  DistributionChannel: []
  StartDate: string
  EndDate: string
  Enabled: true
  Id?: number
  PaymentTermsName: string
  Type?: number
  ClusterType: number
  ClusterAndLimit: ClusterAndLimit[]
  MaterialNumbers?: []
}

export interface IListPaymentTerms {
  TotalPaymentCount: number
  PaymentTermsList: FCVObject[]
}

export interface IFcvTable {
  Id: number
  DealerCode: string
  Limit: number
  Title: string
  Bool: boolean
  UsedLimit?: number
  RemainderLimit?: number
  StateId: string
  History?: []
}

export interface IListAllFCV {
  Company: string
  Type?: number
  PaymentTermsName: string
  StartDate?: Date
  EndDate?: Date
  DealerCode: string
  PageNumber: number
  PageSize: number
  PageType: number
}

export class InsertFcvService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/addPaymentTerms',
      requesterInfo,
    })
  }

  public async insertFCVRule(request: FCVObject): Promise<FCVObject> {
    return await this.postAsync<FCVObject>(request)
  }
}
