import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { ISurvey } from '../models'

export class UpdatePollAdminApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/survey/surveyUpdate',
      requesterInfo,
    })
  }
  public async updateSurvey(request: any): Promise<boolean> {
    return await this.putAsync<boolean>(undefined, request)
  }
}
