import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import validationSchema from './Validation'
import { GetDealerChannelListResponseModel } from '../models/getDealerChannelListResponseModel'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { DealerService } from '../services/DealerService'
import { IChannelFilteredReport } from '../models/getDealerChannelResponseModel'
import { ChannelFilteredReport } from '../services/channelFilteredReport'
import { downloadFileFromUrl, formatDate, formatDateForCampaignInput } from '../../../../../base/utils/dataHelper'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import _ from 'lodash'
import '../style.scss'
import { ToExcel } from '../services/toExcel'
import InputCampaign from './DealerChannelInput'
import Swal from 'sweetalert2'

interface DealerChannelListState {
  dealerCodeOptions?: []
  campaignName: ISelectOption[]
  notData: boolean
  StartDate: Date
  EndDate: Date
  DealerCode: string
}

function HomeDealerChannel(props: BaseComponentWithContextProps) {
  const initialStartDate = new Date()
  initialStartDate.setDate(1)
  initialStartDate.setHours(0)
  initialStartDate.setMinutes(0)
  initialStartDate.setSeconds(0)
  const getInitialState = (): DealerChannelListState => {
    return {
      campaignName: [],
      notData: false,
      StartDate: initialStartDate,
      EndDate: new Date(),
      DealerCode: '',
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<DealerChannelListState>(intialState)

  const t = translate(Translations)

  // const dealerService = async () => {
  //   const dealerService = new DealerService({})
  //   dealerService.listAll().then((res: any) => {
  //     if (res) {
  //       const dealerCodeList: any = []
  //       const dealerTypeList: any = []
  //       res.forEach((item: any) => {
  //         dealerCodeList.push(item.SapCode.replace('0000',''))
  //         dealerTypeList.push(item.TypeCode)
  //       })

  //       setState({
  //         dealerCodeOptions: dealerCodeList,
  //       })
  //     }
  //   })
  // }

  useEffect(() => {
    filterChannelReport()
    //dealerService()
  }, [])


  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      ProgramDescription: '',
      XDealerCodeList: [],
    },

    validationSchema,
    onSubmit: async () => { },
  })

  const tableRef = useRef<any>()
  useEffect(() => {
    let dt: any
    dt = syncTable([])
    tableRef.current = dt

    return function () {
      dt && dt.destroy()
    }
  }, [])

  const syncTable = (datas: GetDealerChannelListResponseModel[]) => {
    const dt = $(tableRef.current).DataTable({
      pageLength: 10,
      data: datas.map(dt => {
        let target: any = dt.CurrentQuantity + ' / ' + dt.TargetQuantity + ' Adet'
        if (dt.TargetPrice > 0) {
          target = dt.CurrentPrice + ' / ' + dt.TargetPrice + ' TL'
        }
        return {
          DealerCode: dt.DealerCode.replace('0000', ''),
          CampaignName: dt.CampaignName,
          DescriptionNew: dt.DescriptionNew,
          DescriptionDetail: dt.DescriptionDetail,
          Brand: dt.Brand,
          ProductName: dt.ProductName,
          ProductCode: dt.ProductCode,
          target: target,
        }
      }),
      columns: [
        {
          title: 'Bayi Kodu',
          data: 'DealerCode',
        },
        {
          title: 'Kampanya Adı',
          data: 'CampaignName',
        },
        {
          title: 'Detaylı Açıklama',
          data: 'DescriptionNew',
        },
        {
          title: 'Yeni Kayıt Açıklama',
          data: 'DescriptionDetail',
        },
        {
          title: 'Marka',
          data: 'Brand',
        },
        {
          title: 'Ürün Adı',
          data: 'ProductName',
        },
        {
          title: 'Ürün Kodu',
          data: 'ProductCode',
        },
        {
          title: 'Gerçekleşen/Hedef (Adet-TL)',
          data: 'target',
        },
      ],
    } as any)
    return dt
  }

  const filterChannelReport = async () => {
    const datas = (state.DealerCode.split(' '))
    const clnValues = _.clone(values)

    // clnValues.XDealerCodeList = clnValues.XDealerCodeList
    //   ? (clnValues.XDealerCodeList.map((item: ISelectOption) => {
    //     return item.label
    //   }) as any)
    //   : []

    const data: IChannelFilteredReport = {
      DEALER_CODE: datas,
      CampaignName: clnValues.ProgramDescription,
      StartDate: formatDate(state.StartDate),
      EndDate: formatDate(state.EndDate),
    }

    const channelFilteredList: ChannelFilteredReport = new ChannelFilteredReport({})

    props.showLoading()

    channelFilteredList
      .channelFilteredList(data)
      .then(res => {
        if (res) {
          tableRef.current?.clear()
          tableRef.current?.rows
            .add(
              res.map(dt => {
                let target: any = dt.CurrentQuantity + ' / ' + dt.TargetQuantity + ' Adet'
                if (dt.TargetPrice > 0) {
                  target = dt.CurrentPrice + ' / ' + dt.TargetPrice + ' TL'
                }
                return {
                  DealerCode: dt.DealerCode.replace('0000', ''),
                  CampaignName: dt.CampaignName,
                  DescriptionNew: dt.DescriptionNew,
                  DescriptionDetail: dt.DescriptionDetail,
                  Brand: dt.Brand,
                  ProductName: dt.ProductName,
                  ProductCode: dt.ProductCode,
                  target: target,
                }
              })
            )
            .draw(true)
        }
      })
      .catch(err => {
        tableRef.current?.clear().draw(true)
        Swal.fire('Hata', err.description, 'error')
      })
      .finally(() => {
        props.hideLoading()
      })
  }

  const excelFn = async () => {
    const data: IChannelFilteredReport = {
      DEALER_CODE:
        values.XDealerCodeList.length > 0 ? values.XDealerCodeList.map((item: ISelectOption) => item.label) : [],
      CampaignName: values.ProgramDescription,
      StartDate: formatDate(state.StartDate),
      EndDate: formatDate(state.EndDate),
    }

    props.showLoading()

    try {
      const res = await new ToExcel({}).dealerChannelReport(data)
      if (res) {
        downloadFileFromUrl('BayiKanalKampanyaRaporu', res.FileUrl)
        props.hideLoading()
      }
    } catch (err: any) {
      console.error(err)
      props.hideLoading()
    }
  }

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = (date: Date) => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='card'>
        <div className='card-header'>
          <h4>Bayi Kanal Hedef İzleme</h4>
        </div>

        <div className='card-body'>
          <form>
            <div className='row align-items-center'>
              <div className='col-12 col-md-6 mb-3'>
                <label htmlFor='location' className='labeltext'>
                  Bayi Kodu
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  id='BayiKodu'
                  placeholder={''}
                  value={values.XDealerCodeList}
                  onChange={e => {
                    var newParams = state.DealerCode
                    newParams = e.target.value
                    setState({ DealerCode: newParams })
                  }}
                />
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <label className='labeltext'>Kampanya Adı</label>
                <div>
                  <IPInput
                    type='text'
                    id='ProgramDescription'
                    className='form-control'
                    name='ProgramDescription'
                    value={values.ProgramDescription}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, "Start")}
                  isActive
                />
              </div>
              <div className='col-12 col-md-6 mb-3'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, "End")}
                  isActive
                />
              </div>
              <div className='etkin d-flex justify-content-end' style={{ padding: '10px' }}>
                <IPButton
                  type='button'
                  onClick={excelFn}
                  className='btn btn-success'
                  text="Excel'e Yazdır"
                  style={{ width: '150px' }}
                />
                <IPButton
                  type='button'
                  onClick={filterChannelReport}
                  className='btn btn-success'
                  text={t('pages.admin.homeAndSiteOperation.bannersCrud.listBanner.strain')}
                  style={{ width: '25px' }}
                />
              </div>{' '}
              <table id={'datatb'} ref={tableRef} className='table'></table>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(HomeDealerChannel)
