import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
export class GetCampaignToProductsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'campaign/CampaignToProducts/',
      requesterInfo,
    })
  }

  public async getCampaignToProducts(id: number): Promise<any> {
    this.props.url = `${this.props.url}${id}`
    return await this.getAsync<any>()
  }
}
