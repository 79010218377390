import ProductSelector from './ProductSelector'
import './style.scss'

function OneConfig() {
  return (
    <div className='oneConfig-container'>
      <ProductSelector />
    </div>
  )
}
export default OneConfig
