import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { KeyValuePair } from '../../../base/models'
import Image from '../../../components/IPImage/IPImage'
import { useAppSelector } from '../../../redux/app/hooks'
import { selectedTheme } from '../../../redux/slices/theme/themeSlice'
import { IBrandList, IDetail } from '../models/models'
import { GetBrandListApi } from '../services/getBrandListApi'
import { DATAGATE_THEME, DEFAULT_THEME, DESPEC_THEME, INDEX_THEME, NETEX_THEME } from '../../../base/theme/themeConfig'
import { ListNoticeApi } from '../../Admin/HomeAndSiteOperation/HomePageNotice/services/ListNoticeApiNormal'

export default function AllBrands() {
  useEffect(() => {
    getBrands()
  }, [])

  const [brands, setBrands] = useState<IBrandList[]>([])
  const theme = useAppSelector(selectedTheme)
  const [activeTab, setActiveTab] = useState('Markalar')

  const getBrands = async () => {
    const api = new GetBrandListApi({})
    const queryParams: KeyValuePair<string> = new KeyValuePair<string>()
    queryParams.add('IsHomePage', 'false')
    api.queryParams = queryParams
    api.getBrandList(-1, theme.id).then((res: IDetail) => {
      setBrands(res.List)
    })
  }

  const handleTabClick = tab => {
    setActiveTab(tab)
  }

  useEffect(() => {
    getNotice()
  }, [])

  const [notice, setNotice] = useState<any>()

  const getNotice = async () => {
    const listNotice: ListNoticeApi = new ListNoticeApi({})

    listNotice
      .listNoticeApi()
      .then((res: any) => {
        setNotice(res[0].Notice)
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className='container'>
      {/* <div className='pageTitle my-4'>
        <span style={{ fontSize: '1.3vw', marginTop: '3rem' }}>Markalar</span>
      </div> */}

      <div className='col-md-3 d-flex justify-content-between flex-column flex-sm-row mt-5'>
        <h4
          className={`section-back-grey-title ${activeTab === 'Markalar' ? 'active' : ''}`}
          onClick={() => handleTabClick('Markalar')}
          style={{ cursor: 'pointer' }}
        >
          Markalar
        </h4>
        <h4
          className={`section-back-grey-title ${activeTab === 'Duyurular' ? 'active' : ''}`}
          onClick={() => handleTabClick('Duyurular')}
          style={{ cursor: 'pointer' }}
        >
          Duyurular
        </h4>
      </div>
      <div
        className={`seperator m-0 ${theme.name === NETEX_THEME ? 'netex_borderBottom' : ''}${theme.name === INDEX_THEME ? 'index_borderBottom' : ''
          }${theme.name === DEFAULT_THEME ? 'default_borderBottom' : ''}${theme.name === DESPEC_THEME ? 'despec_borderBottom' : ''
          }${theme.name === DATAGATE_THEME ? 'datagate_borderBottom' : ''}`}
      ></div>
      {activeTab === 'Markalar' && (
        <div className='row'>
          {(brands as any[]).map((item: IBrandList) => (
            <div className='col-md-auto mt-lg-0 m-b-20' key={item.Id}>
              <div className='top-brands-item'>
                <div className='CellWithComments'>
                  <Link to={`/brandDetail?brandId=${item.Id}`}>
                    <div className='brand-white-background' style={{ border: '1px solid #ddd' }}>
                      <Image
                        className='rounded-circle'
                        src={item.Image ? item.ImageUrl : '/images/notImage2.png'}
                        alt=''
                        style={{
                          width: '70px',
                          height: 'unset',
                          minWidth: '50px',
                        }}
                      />
                    </div>
                  </Link>
                  {/* <span className='CellComments'>
                  <h5>{item.Name}</h5>
                </span> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {activeTab === 'Duyurular' && (
        <div style={{ paddingInline: '2rem', paddingBottom: '2rem', paddingTop: '2rem', textAlign: 'center' }}>
          <div dangerouslySetInnerHTML={{ __html: notice }} />
        </div>
      )}
    </div>
  )
}
