import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'
import { IParamPosInsatallment } from '../models/models'

export class UpdateParamPosInsatallmentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/paramPosTransaction/updateParamPosInsatallment',
      requesterInfo,
    })
  }

  public async updateParamPosInsatallment(request: IParamPosInsatallment): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
