import React, { useState, useMemo } from 'react'
import IPButton from '../../../../../components/IPButton'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import InputCampaign from '../../../../../components/InputCampaign'
import IPInput from '../../../../../components/IPInput'
import Table from '../../../../../components/Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { ISuccessFullRequest, ISuccessFullResponse } from '../models/models'
import { exportToXLSX, getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import { GetSuccessFullApi } from '../services/getSuccessFullApi'
import moment from 'moment'

interface ISuccessfullState {
  successfulData: ISuccessFullResponse[]
  dealerCode: string
  tkyNumber: string
  companyListOptions: ISelectOption[]
  companyListOption: any
  companyListFirstOption: any
  additionalFilter: boolean
  paramPosfilter: boolean
  mtOptions: ISelectOption[]
  mtOption: any
}

function Successful(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): ISuccessfullState => {
    return {
      successfulData: [],
      dealerCode: '',
      tkyNumber: '',
      additionalFilter: false,
      paramPosfilter: false,
      companyListOption: null,
      companyListFirstOption: null,
      companyListOptions: [
        { label: 'Index', value: '1000' },
        { label: 'Netex', value: '2300' },
        { label: 'Despec', value: '1200' },
        { label: 'Datagate', value: '1100' },
      ],
      mtOption: null,
      mtOptions: [
        { label: 'Index Grup Personel', value: 1 },
        { label: 'Bayi', value: 2 },
      ],
    }
  }
  const [trackingNumberFilter, setTrackingNumberFilter] = useState('')
  const [filteredList, setFilteredList] = useState<ISuccessFullResponse[]>([])
  const [showFilterRow, setShowFilterRow] = useState(false)
  const [bankSelectOptions, setBankSelectOptions] = useState<ISelectOption[]>([])
  const [bankSelectOption, setBankSelectOption] = useState<any>()
  const [dataCounter, setDataCounter] = useState<number | undefined>(undefined)

  //#region Tarih ve saat ayarları
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ISuccessfullState>(intialState)

  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }
  // Klavyeden tarih ve saat girebilmeye imkan veren fonksiyon
  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }
  //#endregion

  // Tablo başlıkları
  const columnDefinitions = [
    { Header: 'Şirket', accessor: 'col1' },
    { Header: 'İşlem Tarihi', accessor: 'col2' },
    { Header: 'Banka', accessor: 'col3' },
    { Header: 'Tutar', accessor: 'col4' },
    { Header: 'Taksit', accessor: 'col5' },
    { Header: 'Firma', accessor: 'col6' },
    { Header: 'Kart İsmi', accessor: 'col7' },
    { Header: 'Kart No', accessor: 'col8' },
    { Header: 'Sipariş Yeri', accessor: 'col9' },
    { Header: 'Kampanya Adı', accessor: 'col10' },
    { Header: 'Sap Sipariş No', accessor: 'col11' },
    { Header: 'Sap Takip No', accessor: 'col12' },
    { Header: 'Pos Takip No', accessor: 'col13' },
    { Header: '3D', accessor: 'col14' },
    { Header: 'Komisyon', accessor: 'col15' },
    { Header: 'Personel', accessor: 'col16' },
  ]

  const tableColumns = useMemo(() => columnDefinitions, [])
  // Tablo verileri
  const tableDataAndTotal = useMemo(() => {
    let totalPayInfo = 0
    const tableData = filteredList.map(item => {
      const cleanedPayInfo = item.PayInfo.replace(/\./g, '').replace(',', '.')
      const payInfoValue = parseFloat(cleanedPayInfo)
      const data = {
        col1:
          item.Company && item.Company === '1000'
            ? 'IDX'
            : item.Company === '2300'
              ? 'NTX'
              : item.Company === '1200'
                ? 'DPC'
                : item.Company === '1100'
                  ? 'DGT'
                  : '',
        col2: item.ProcessTime && moment(item.ProcessTime).format('DD.MM.YYYY HH:mm:ss'),
        col3: item.Bank,
        col4: item.PayInfo,
        col5:
          item.PayInstallment && item.PayInstallment === '0'
            ? 'Tek Çekim'
            : item.PayInstallment === '1'
              ? 'Tek Çekim'
              : item.PayInstallment + ' Taksit',
        col6: item.DealaerCode && item.DealaerCode.replace('0000', ''),
        col7: item.CardName.toUpperCase(),
        col8: item.CarNumber,
        col9: item.ProcessType,
        col10: item.PaymentTermsName,
        col11: item.SapSiparisNO,
        col12: item.SapTakipNO,
        col13: item.OrderNumber,
        col14: item.is3D ? 'Evet' : 'Hayır',
        col15:
          item.ComissionType === 3
            ? 'Komisyonsuz'
            : item.ComissionType === 2
              ? 'Hariç'
              : item.ComissionType === 1
                ? 'Dahil'
                : '',
        col16: item.isMt ? item.UserName : '',
      }
      // if (!isNaN(+item.PayInfo)) {
      //   totalPayInfo += parseFloat(item.PayInfo);
      // }
      if (!isNaN(payInfoValue)) {
        totalPayInfo += payInfoValue
      }
      return data
    })
    return {
      tableData,
      totalPayInfo,
    }
  }, [filteredList])

  //#region Tüm, başarılı ve başarısız işlem sayılarını veren tablo
  const successfulReceipt = useMemo(() => {
    const totalReceipt = filteredList.length
    const successfulReceipt = filteredList.reduce(
      (acc, x) => acc + (x.SapTakipNO && x.SapTakipNO.length > 0 ? 1 : 0),
      0
    )
    const unsuccessfulReceipt = totalReceipt - successfulReceipt

    return [
      { label: 'Makbuzu Oluşan Sayısı', count: successfulReceipt },
      { label: 'Makbuzu Oluşmayan Sayısı', count: unsuccessfulReceipt },
      { label: 'Toplam İşlem Sayısı', count: totalReceipt },
    ]
  }, [filteredList])
  //#endregion

  // Api'den seçilen değerlere göre verileri aldığımız fonksiyon
  const getSuccessfull = async () => {
    if (!startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tarih giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const uniqueBankLabels = {}
    const req: ISuccessFullRequest = {
      Company: state.companyListFirstOption?.value,
      OrderNumber: state.tkyNumber,
      DealerCode: state.dealerCode.length > 5 ? state.dealerCode.padStart(10, '0') : state.dealerCode,
      StartDate: startDate && (getDateByTimezoneOffset(startDate) as any),
      EndDate: endDate && (getDateByTimezoneOffset(endDate) as any),
    }
    props.showLoading()
    const getSuccessFullApi: GetSuccessFullApi = new GetSuccessFullApi({})
    await getSuccessFullApi
      .getSuccessFull(req)
      .then(result => {
        if (result) {
          setBankSelectOptions(
            result
              .map(x => ({
                value: x.BankNo,
                label: x.Bank.toUpperCase(),
              }))
              .filter(option => {
                if (!uniqueBankLabels[option.label]) {
                  uniqueBankLabels[option.label] = true
                  return true
                }
                return false
              })
          )
          setState({ successfulData: result })
          setFilteredList(result)
          setDataCounter(result.length)
          setShowFilterRow(true)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  //#region Detaylı Süzme fonksiyonu
  const filterList = () => {
    const filterConditions: ((item: ISuccessFullResponse) => boolean)[] = []

    if (bankSelectOption && trackingNumberFilter.length < 10) {
      filterConditions.push(b => b.Bank.toUpperCase() === bankSelectOption.label)
    }

    if (trackingNumberFilter) {
      const validLengths = [10, 13, 14]

      if (validLengths.includes(trackingNumberFilter.length)) {
        setBankSelectOption(null)
        state.companyListOption = null
      }
      filterConditions.push(x => x.OrderNumber.includes(trackingNumberFilter.toUpperCase()))
    }

    if (state.companyListOption) {
      filterConditions.push(c => c.Company === state.companyListOption.value)
    }

    if (state.mtOption) {
      filterConditions.push(
        c => c.isMt === (state.mtOption.value === 1 ? true : state.mtOption.value === 2 ? false : '')
      )
    }

    // Sadece Makbuzsuz Süzme
    if (state.additionalFilter) {
      filterConditions.push(x => {
        return x.SapTakipNO?.length < 1 || x.SapTakipNO === null
      })
    }

    // Sadece ParamPos Süzme
    if (state.paramPosfilter) {
      filterConditions.push(x => {
        return x.Bank.toLowerCase().includes("param pos");
      })
    }

    let filteredData: ISuccessFullResponse[] = state.successfulData

    if (filterConditions.length > 0) {
      filteredData = filteredData.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
    }
    setDataCounter(filteredData.length)
    setFilteredList(filteredData)
  }
  //#endregion

  //#region Excel'e aktarma
  const excelButton = async () => {
    try {
      if (filteredList) {
        const data: any[] = []
        if (filteredList.length > 0) {
          filteredList.map(item => {
            return data.push({
              Şirket:
                item.Company && item.Company === '1000'
                  ? 'IDX'
                  : item.Company === '2300'
                    ? 'NTX'
                    : item.Company === '1200'
                      ? 'DPC'
                      : item.Company === '1100'
                        ? 'DGT'
                        : '',
              'İşlem Tarihi': item.ProcessTime && moment(item.ProcessTime).format('DD.MM.YYYY HH:mm:ss'),
              Banka: item.Bank,
              Tutar: parseFloat(item.PayInfo.replaceAll('.', '').replace(',', '.')),
              Taksit:
                item.PayInstallment && item.PayInstallment === '0'
                  ? 'Tek Çekim'
                  : item.PayInstallment === '1'
                    ? 'Tek Çekim'
                    : item.PayInstallment + ' Taksit',
              Firma: item.DealaerCode && item.DealaerCode.replace('0000', ''),
              'Kart İsmi': item.CardName.toUpperCase(),
              'Kart No': item.CarNumber,
              'Sipariş Yeri': item.ProcessType,
              'Kampanya Adı': item.PaymentTermsName,
              'Sap Sipariş No': item.SapSiparisNO,
              'Sap Takip No': item.SapTakipNO,
              'Pos Takip No': item.OrderNumber,
              '3D': item.is3D ? 'Evet' : 'Hayır',
              Komisyon:
                item.ComissionType === 3
                  ? 'Komisyonsuz'
                  : item.ComissionType === 2
                    ? 'Hariç'
                    : item.ComissionType === 1
                      ? 'Dahil'
                      : '',
              Personel: item.isMt ? item.UserName : '',
            })
          })
          if (tableDataAndTotal && tableDataAndTotal.totalPayInfo) {
            const totalPayInfoFormatted =
              tableDataAndTotal.totalPayInfo
                .toFixed(2)
                .replace('.', ',')
                .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'
            data.push({
              Şirket: '',
              'İşlem Tarihi': '',
              Banka: '',
              Tutar: '',
              Taksit: '',
              Firma: '',
              'Kart İsmi': '',
              'Kart No': '',
              'Sipariş Yeri': '',
              'Kampanya Adı': '',
              'Sap Sipariş No': '',
              'Sap Takip No': '',
              'Pos Takip No': '',
              '3D': '',
              Komisyon: '',
              Personel: '',
              'Toplam Tutar': totalPayInfoFormatted,
            })
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Başarılı İşlem Raporu')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }
  //#endregion

  return (
    <div className=''>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>{t('pages.admin.CreditCardOperation.Successful.header')}</span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <form className='formbanner ms-4'>
              <div className='row mb-3'>
                <div className='col-12 col-md-2 mb-2'>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={startDate && toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-2 mb-2'>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={endDate && toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-2 mb-2'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='ProductAddress'
                    placeholder='Bayi Kodu'
                    onChange={e => setState({ dealerCode: String(e.target.value) })}
                    value={state.dealerCode && state.dealerCode}
                  />
                  <p style={{ color: 'red' }}>* Boş bırakırsanız hepsi</p>
                </div>
                <div className='col-12 col-md-2 mb-2'>
                  <IPInput
                    type='text'
                    className='form-control'
                    name='ProductAddress'
                    placeholder='İşlem No'
                    onChange={e => setState({ tkyNumber: String(e.target.value) })}
                    value={state.tkyNumber && state.tkyNumber}
                  />
                </div>
                <div className='col-12 col-md-2 mb-2'>
                  <IPSelectBox
                    isClearable
                    placeholder='Şirket'
                    options={state.companyListOptions}
                    value={state.companyListFirstOption}
                    onChangeSingle={option => {
                      setState({ companyListFirstOption: option })
                    }}
                  />
                  <p style={{ color: 'red' }}>* Boş bırakırsanız hepsi</p>
                </div>
                <div className='col-12 col-md-2 mb-2'>
                  <IPButton text='Göster' className='btn btn-danger' onClick={getSuccessfull} />
                </div>
              </div>
            </form>

            {showFilterRow && (
              <>
                <div
                  className='detailFilter'
                  style={{
                    marginTop: '10px',
                    border: '2px solid #9c9cf1',
                    padding: '0px  5px',
                    paddingTop: '10px',
                  }}
                >
                  <div className='row ms-1'>
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        placeholder='Banka'
                        options={bankSelectOptions}
                        value={bankSelectOption}
                        onChangeSingle={option => {
                          setBankSelectOption(option)
                        }}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        placeholder='Şirket'
                        options={state.companyListOptions}
                        value={state.companyListOption}
                        onChangeSingle={option => {
                          setState({ companyListOption: option })
                        }}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <IPSelectBox
                        isClearable
                        placeholder='Ödeyen Kişi'
                        options={state.mtOptions}
                        value={state.mtOption}
                        onChangeSingle={option => {
                          setState({ mtOption: option })
                        }}
                      />
                    </div>
                    <div className='form-group col-md-2'>
                      <IPInput
                        type='text'
                        id='Title'
                        className='form-control'
                        name='Title'
                        placeholder='Pos Takip No'
                        onChange={e => setTrackingNumberFilter(e.target.value)}
                        value={trackingNumberFilter}
                        onKeyDown={e => {
                          if (e.key === 'Enter') {
                            e.preventDefault()
                            filterList()
                          }
                        }}
                      />
                    </div>
                    <div className='form-group col-md-1' style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type='checkbox'
                        id='additionalFilterCheckbox'
                        checked={state.additionalFilter}
                        onChange={e => {
                          setState({ additionalFilter: e.target.checked })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      <label htmlFor='additionalFilterCheckbox' style={{ fontSize: '1.2rem', marginLeft: '1rem' }}>
                        Sadece Makbuzsuz
                      </label>
                    </div>
                    <div className='form-group col-md-1' style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        type='checkbox'
                        id='paramPosFilterCheckbox'
                        checked={state.paramPosfilter}
                        onChange={e => {
                          setState({ paramPosfilter: e.target.checked })
                        }}
                        style={{ transform: 'scale(1.5)' }}
                      />
                      <label htmlFor='paramPosFilterCheckbox' style={{ fontSize: '1.2rem', marginLeft: '1rem' }}>
                        Sadece Param Pos
                      </label>
                    </div>
                    <div className='col-12 col-md-1 mb-2 ms-2'>
                      <IPButton
                        type='button'
                        className='btn btn-primary'
                        text='Detaylı Süz'
                        onClick={filterList}
                        style={{ height: '45px' }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* UseMemo'dan gelen verilerin tablosu */}
            <div className='m-4'>
              <Table
                columns={tableColumns}
                data={tableDataAndTotal.tableData}
                currentPage={0}
                headerName={`İşlem Tarihi Aralığı: ${new Date(startDate).toLocaleDateString()} - ${new Date(
                  endDate
                ).toLocaleDateString()} ---TOPLAM İŞLEM SAYISI: ${dataCounter}---TOPLAM TUTAR: ${tableDataAndTotal.totalPayInfo
                  .toFixed(2)
                  .replace('.', ',')
                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' ₺'
                  }`}
                columnCount={16}
                backgroundColor='red'
                color='white'
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {/* UseMemo'dan gelen verilerin tablosu */}
            <table className='table' style={{ width: '60%', tableLayout: 'fixed' }}>
              <colgroup>
                {successfulReceipt.map((_, index) => (
                  <col key={index} style={{ width: '33%' }} />
                ))}
              </colgroup>
              <thead>
                <tr>
                  {successfulReceipt.map((data, index) => (
                    <th key={index} style={{ whiteSpace: 'nowrap', textAlign: 'center' }} scope='col'>
                      {data.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {successfulReceipt.map((data, index) => (
                    <td
                      key={index}
                      style={{
                        textAlign: 'center',
                        color:
                          data.label === 'Makbuzu Oluşmayan Sayısı'
                            ? 'red'
                            : data.label === 'Makbuzu Oluşan Sayısı'
                              ? 'green'
                              : 'blue',
                        fontWeight: 'bold',
                      }}
                    >
                      {data.count}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Successful)
