import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IColor } from '../models/models'

export class GetColorApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/color/getColors',
      requesterInfo,
    })
  }

  public async getColors(): Promise<IColor[]> {
    return await this.getAsync<IColor[]>()
  }
}
