import classes from './IPTextarea.module.scss'

interface IPTextbox
  extends React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {
  errors?: string
  touched?: boolean
}

export default function Textarea(props: IPTextbox) {
  const { className, errors, touched, ...others } = props
  return (
    <>
      <textarea className={`${classes.Textarea} ${props.className ?? ''}`} {...others} />
      {errors && touched && <div className='validationError'>{errors}</div>}
    </>
  )
}
