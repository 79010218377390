import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IDefinedCompany, ListDefinedCompany } from '../models/models'

export class ListDefinedCompanyApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealer/definedDealerAndUserInquiry',
      requesterInfo,
    })
  }
  public async listDefinedCompany(Id: string): Promise<IDefinedCompany> {
    this.props.url = `${this.props.url}/${Id ? Id : 0}`
    return await this.getAsync<IDefinedCompany>()
  }
}
