export type INDEX_THEME_TYPE = 'index'
export type DESPEC_THEME_TYPE = 'despec'
export type NETEX_THEME_TYPE = 'netex'
export type DATAGATE_THEME_TYPE = 'datagate'
export type DEFAULT_THEME_TYPE = 'default'

export const INDEX_THEME: INDEX_THEME_TYPE = 'index'
export const DESPEC_THEME: DESPEC_THEME_TYPE = 'despec'
export const NETEX_THEME: NETEX_THEME_TYPE = 'netex'
export const DATAGATE_THEME: DATAGATE_THEME_TYPE = 'datagate'
export const DEFAULT_THEME: DEFAULT_THEME_TYPE = 'default'

const INDEX_COLOR_CODE: string = '#DA291C'
const DESPEC_COLOR_CODE: string = '#298FC2'
const NETEX_COLOR_CODE: string = '#0055C9'
const DATAGATE_COLOR_CODE: string = '#00AFD7'
const DEFAULT_COLOR_CODE: string = '#DC3545'
const DEFAULT_PRICE_COLOR: string = '#ABABAF'

interface IThemeButtonColors {
  text: string
  background: string
}

export interface IThemePriceColors {
  special: string
  normal: string
  enduser: string
}

interface IThemeColors {
  body: string
  text: string
  button: IThemeButtonColors
  backgroundColor?: string
  price: IThemePriceColors
}

export interface IThemeConfig {
  id: number
  name: INDEX_THEME_TYPE | DESPEC_THEME_TYPE | NETEX_THEME_TYPE | DATAGATE_THEME_TYPE | DEFAULT_THEME_TYPE
  colors: IThemeColors
  font: string
  image?: string
}

interface ITheme {
  index: IThemeConfig
  despec: IThemeConfig
  netex: IThemeConfig
  datagate: IThemeConfig
  default: IThemeConfig
}

const DEFAULT_THEME_CONFIG: IThemeConfig = {
  id: 0,
  name: 'default',
  colors: {
    body: '#000000',
    text: '#FFFFFF',
    button: {
      text: '#FFFFFF',
      background: '#FF0000',
    },
    backgroundColor: DEFAULT_COLOR_CODE,
    price: {
      enduser: DEFAULT_PRICE_COLOR,
      normal: DEFAULT_PRICE_COLOR,
      special: DEFAULT_COLOR_CODE,
    },
  },
  font: 'Nexa',
}

const INDEX_THEME_CONFIG: IThemeConfig = {
  id: 1,
  name: 'index',
  colors: {
    body: '#000000',
    text: '#FFFFFF',
    button: {
      text: '#FFFFFF',
      background: '#FF0000',
    },
    backgroundColor: INDEX_COLOR_CODE,
    price: {
      enduser: DEFAULT_PRICE_COLOR,
      normal: DEFAULT_PRICE_COLOR,
      special: INDEX_COLOR_CODE,
    },
  },
  font: 'Nexa',
  image: '/images/index.png',
}

const DESPEC_THEME_CONFIG: IThemeConfig = {
  id: 3,
  name: 'despec',
  colors: {
    body: '#000000',
    text: '#FFFFFF',
    button: {
      text: '#FFFFFF',
      background: '#AA0000',
    },
    backgroundColor: DESPEC_COLOR_CODE,
    price: {
      enduser: DEFAULT_PRICE_COLOR,
      normal: DEFAULT_PRICE_COLOR,
      special: DESPEC_COLOR_CODE,
    },
  },
  font: 'Nexa',
  image: '/images/despec.png',
}

const NETEX_THEME_CONFIG: IThemeConfig = {
  id: 2,
  name: 'netex',
  colors: {
    body: '#000000',
    text: '#FFFFFF',
    button: {
      text: '#FFFFFF',
      background: '#FF0000',
    },
    backgroundColor: NETEX_COLOR_CODE,
    price: {
      enduser: DEFAULT_PRICE_COLOR,
      normal: DEFAULT_PRICE_COLOR,
      special: NETEX_COLOR_CODE,
    },
  },
  font: 'Nexa',
  image: '/images/netex.png',
}

const DATAGATE_THEME_CONFIG: IThemeConfig = {
  id: 4,
  name: 'datagate',
  colors: {
    body: '#000000',
    text: '#FFFFFF',
    button: {
      text: '#FFFFFF',
      background: '#FF0000',
    },
    backgroundColor: DATAGATE_COLOR_CODE,
    price: {
      enduser: DEFAULT_PRICE_COLOR,
      normal: DEFAULT_PRICE_COLOR,
      special: DATAGATE_COLOR_CODE,
    },
  },
  font: 'Nexa',
  image: '/images/datagate.png',
}

const theme: ITheme = Object.assign({
  [INDEX_THEME]: INDEX_THEME_CONFIG,
  [DESPEC_THEME]: DESPEC_THEME_CONFIG,
  [NETEX_THEME]: NETEX_THEME_CONFIG,
  [DATAGATE_THEME]: DATAGATE_THEME_CONFIG,
  [DEFAULT_THEME]: DEFAULT_THEME_CONFIG,
})

const getTheme = (
  themeName: INDEX_THEME_TYPE | DESPEC_THEME_TYPE | NETEX_THEME_TYPE | DATAGATE_THEME_TYPE | DEFAULT_THEME_TYPE
): IThemeConfig => {
  return theme[themeName]
}

export default getTheme
