import { GetWhiteListModel, WhiteListRequestModel } from '../models/whiteDealerModel'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class GetWhiteListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealer/whiteDealerList',
      requesterInfo,
    })
  }

  public async getWhiteList(request: WhiteListRequestModel): Promise<GetWhiteListModel[]> {
    return await this.postAsync<GetWhiteListModel[]>(request)
  }
}
