import React, { useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPDatePicker from '../../../../../components/IPDatePicker'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'

function OpportunityMarketReport() {
  const [dateRange, setDateRange] = useState<any>([new Date(), new Date()])
  const [startDate, endDate] = dateRange
  const [click, setClick] = useState(false)
  const t = translate(Translations)

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              {t('pages.admin.dealerAndOrderReport.opportunityMarketReport.opportunityMarketReport.header')}
            </span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label className='form-label'>Başlangıç Tarihi - Bitiş Tarihi</label>
                  <IPDatePicker
                    selectsRange={true as any}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={update => {
                      setDateRange(update)
                    }}
                  />
                </div>
                <div className='col-lg-4'>
                  <label htmlFor='location' className='form-label'></label>
                  <div className='row'>
                    <br />
                    <div className='col-sm mt-4'>
                      {t(
                        'pages.admin.dealerAndOrderReport.opportunityMarketReport.opportunityMarketReport.showInExcel'
                      )}
                    </div>
                    <div className='col-sm mt-4'>
                      <IPCheckbox />
                    </div>
                  </div>
                </div>

                <div className='col-lg-1'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={t(
                          'pages.admin.dealerAndOrderReport.opportunityMarketReport.opportunityMarketReport.show'
                        )}
                        onClick={() => setClick(true)}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </form>
            <br />
            {click && (
              <div className='row'>
                <div className='col-lg-12'>
                  <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                    <div className='row'>
                      <div className='col-sm-12'>
                        <table
                          id='datatable'
                          className='table table-bordered table-hover dataTable'
                          role='grid'
                          aria-describedby='datatable_info'
                        >
                          <thead>
                            <tr role='row' className='odd'>
                              <th>
                                {t(
                                  'pages.admin.dealerAndOrderReport.opportunityMarketReport.opportunityMarketReport.entryReport',
                                  ['14/01/2020', '15/03/2022']
                                )}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr role='row' className='odd'>
                              <td style={{ color: 'red' }}>
                                {t(
                                  'pages.admin.dealerAndOrderReport.opportunityMarketReport.opportunityMarketReport.noStatistics'
                                )}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr>
                              <th>
                                {t(
                                  'pages.admin.dealerAndOrderReport.opportunityMarketReport.opportunityMarketReport.orderInformation',
                                  ['14/01/2020', '15/03/2022']
                                )}
                              </th>
                            </tr>
                            <tr role='row' className='odd'>
                              <td style={{ color: 'red' }}>
                                {t(
                                  'pages.admin.dealerAndOrderReport.opportunityMarketReport.opportunityMarketReport.noStatistics'
                                )}
                              </td>
                            </tr>
                            <tr></tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OpportunityMarketReport
