import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { css } from '@emotion/css'
import Swal from 'sweetalert2'
import IPButton from '../../../../components/IPButton'
import InputCampaign from './InputCampaign'
import CampaignSelect from './CampaignSelect'
import IPSelectBox from '../../../../components/IPSelectBox'
import IPCheckbox from '../../../../components/IPCheckBox'
import IPFile from '../../../../components/IPFile'
import IPInput from '../../../../components/IPInput'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { DealerType, IyzicoCampaignModel } from './IyzicoCampaignModel'
import { IyzicoCampaignService } from './IyzicoCampaignService'
import { excelToJSON, getDateByTimezoneOffset, uuidv4 } from '../../../../base/utils/dataHelper'
import './IyzicoCampaignAdd.scss'
import { Spinner } from '../../../../components/Spinner'
import { GetPoductSubTypeListApi } from '../CampaignBundleCrud/services/getPoductSubTypeListApi'
import Item from '../../../MySpecial/Item/Item'

export default function IyzicoCampaignAdd() {
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start") {
      setStartDate(data)
    }
    else {
      setEndDate(data)
    }

  }

  const navigate = useNavigate()
  const location: any = useLocation()

  const [isEdit, setIsEdit] = useState(false)
  const [isHistory, setIsHistory] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const defaultState: IyzicoCampaignModel = {
    Id: 0,
    Name: '',
    CompanyCodes: [],
    IsCompanyCodesInclude: false,
    WarehouseCodes: [],
    IsWarehouseCodesInclude: false,
    BrandIdList: [],
    IsBrandIdListInclude: false,
    Quota: [],
    IsQuotaInclude: false,
    ProductSubTypes: [],
    IsProductSubTypesInclude: false,
    ProductCodes: [],
    IsProductCodesInclude: false,
    DealerCodes: [],
    IsDealersInclude: null,
    DealerTypes: [],
    IsDealerTypesInclude: null,
    DealerSubTypes: [],
    IsDealerSubTypesInclude: null,
    StartDate: new Date(startDate),
    EndDate: new Date(endDate),
    Enabled: false,
    Installments: [],
    DealerLimit: 0,
    CampaignLimit: 0
  }
  const [formState, setFormState] = useState(defaultState)
  const setValue = (state, value) => {
    setFormState(prevState => ({ ...prevState, [state]: value }))
  }

  //#region Options
  const [optionList, setOptionList] = useState<any>({
    companyOptions: [],
    warehouseCodeOptions: [],
    brandIdOptions: [],
    quotaCodeOptions: [],
    //dealerCodeOptions: [],
    dealerTypeOptions: [],
    dealerSubTypeOptions: [],
    productSubTypeOptions: [],
    //productCodeOptions: [],
    installmentOptions: [],
  })

  const [companyValue, setCompanyValue] = useState<ISelectOption[] | null>(null)
  const getCompanies = async () => {
    const res = await new IyzicoCampaignService({}).getCompanies()
    if (res) {
      const data: any = []
      res.forEach(item => {
        if (item.Code === 0) return
        data.push({ value: item.Code, label: item.Name })
      })
      return data
    }
  }

  const [warehouseCodeValue, setWarehouseCodeValue] = useState<ISelectOption[] | null>(null)
  const getWarehouseCodes = async () => {
    const res = await new IyzicoCampaignService({}).getWareHouseCode()
    if (!res) return []
    return res.map(item => ({ value: item, label: item }))
  }

  const [brandIdValue, setBrandIdValue] = useState<ISelectOption[] | null>(null)
  const getBrandList = async () => {
    const res = await new IyzicoCampaignService({}).getBrand()
    if (res) {
      const brands: any = []
      res.forEach(item => {
        if (item.Name.length === 0) return
        brands.push({ value: item.Id, label: item.Name })
      })
      return brands
    }
  }

  const [quotaCodesValue, setQuotaCodesValue] = useState<ISelectOption[] | null>(null)
  const [productCodeValue, setProductCodeValue] = useState<ISelectOption[] | null>(null)
  const getProductAndQuotaCodes = async () => {
    const res = await new IyzicoCampaignService({}).getProductAndQuotaCodes()
    if (!res) return []
    const data = {
      quotaCodes: res.Entity.QuotaCode.map(item => ({ label: item, value: item })),
      productCodes: res.Entity.ProductsInfo.map(item => ({ label: item.ProductCode, value: item.ProductCode })),
    }
    return data
  }

  const [dealerCodeValue, setDealerCodeValue] = useState<ISelectOption[] | null>(null)
  const [dealerTypeValue, setDealerTypeValue] = useState<ISelectOption[] | null>(null)
  const [dealerSubTypeValue, setDealerSubTypeValue] = useState<ISelectOption[] | null>(null)
  // const getDealerCodes = async () => {
  //   const res = await new IyzicoCampaignService({}).getDealerCodes()
  //   if (res) {
  //     const dealerCodeList: any = []

  //     res.forEach((item: any) => {
  //       item.SapCode && dealerCodeList.push({ value: item.SapCode.toString().replace('0000', ''), label: item.SapCode.toString().replace('0000', '') })
  //     })

  //     return dealerCodeList
  //   }
  // }
  const getDealerTypes = async () => {
    const res = await new IyzicoCampaignService({}).getDealerTypes()
    if (res) {
      const dealerTypeList: ISelectOption[] = []

      res.forEach((item: DealerType) => {
        item && dealerTypeList.push({ value: item.MusteriGrubu, label: item.MusteriGrubuAciklama })
      })

      return dealerTypeList
    }
  }
  const getDealerSubTypes = async () => {
    const res = await new IyzicoCampaignService({}).getSubTypes()
    if (!res) return []
    return res.map(item => ({ value: item, label: item }))
  }

  const [productSubTypeValue, setProductSubTypeValue] = useState<ISelectOption[] | null>(null)
  const getProductSubTypes = async () => {
    const res = await new GetPoductSubTypeListApi({}).getPoductSubTypeList()
    if (!res) return []
    const dealerSubTypeOptions: ISelectOption[] = []
    res.forEach((item: any) => {
      item && dealerSubTypeOptions.push({ value: item, label: item })
    })
    return dealerSubTypeOptions
  }

  const getInstallments = async () => {
    const res = await new IyzicoCampaignService({}).getInstallments()
    if (!res) return []
    return res.map(item => ({ value: item.Takist, label: `${item.Takist} Taksit - ${item.Rate}` }))
  }

  useEffect(() => {
    setIsLoading(true)
    Promise.all([
      getCompanies(),
      getWarehouseCodes(),
      getBrandList(),
      getProductAndQuotaCodes(),
      //getDealerCodes(),
      getDealerTypes(),
      getDealerSubTypes(),
      getInstallments(),
      getProductSubTypes(),
    ]).then((data: any) => {
      const [
        companyOptions,
        warehouseCodeOptions,
        brandIdOptions,
        productAndQuotaCodes,
        //dealerCodeOptions,
        dealerTypeOptions,
        dealerSubTypeOptions,
        installmentOptions,
        productSubTypeOptions,
      ] = data

      const quotaCodeOptions = productAndQuotaCodes.quotaCodes
      //const productCodeOptions = productAndQuotaCodes.productCodes

      setOptionList({
        companyOptions,
        warehouseCodeOptions,
        brandIdOptions,
        quotaCodeOptions,
        //productCodeOptions,
        //dealerCodeOptions,
        dealerTypeOptions,
        dealerSubTypeOptions,
        installmentOptions,
        productSubTypeOptions,
      })
      setIsLoading(false)
    })
  }, [])
  //#endregion

  useEffect(() => {
    if (location.state && !location.state.history) {
      const data: IyzicoCampaignModel = location.state.data
      setFormState(data)
      setIsEdit(location.state.edit)
      setStartDate(data.StartDate)
      setEndDate(data.EndDate)

      const companyValues: any = []
      for (const code of data.CompanyCodes) {
        const option = optionList.companyOptions.find(item => item.value === +code)
        companyValues.push(option)
      }
      companyValues.length > 0 && setCompanyValue(companyValues)

      if (data.WarehouseCodes) {
        const warehouseCodeList: any = []
        for (const code of data.WarehouseCodes) {
          const option = optionList.warehouseCodeOptions.find(item => +item.value === +code)
          warehouseCodeList.push(option)
        }
        warehouseCodeList.length > 0 && setWarehouseCodeValue(warehouseCodeList)
      }

      const brandIdList: any = []
      for (const code of data.BrandIdList) {
        const option = optionList.brandIdOptions.find(item => item.value === +code)
        brandIdList.push(option)
      }
      brandIdList.length > 0 && setBrandIdValue(brandIdList)

      const quotaList: any = []
      for (const code of data.Quota) {
        const option = optionList.quotaCodeOptions.find(item => +item.value === +code)
        quotaList.push(option)
      }
      quotaList.length > 0 && setQuotaCodesValue(quotaList)

      const productSubTypes = data.ProductSubTypes.map(item => ({ label: item, value: item }))
      productSubTypes.length > 0 && setProductSubTypeValue(productSubTypes)

      const productCodes = data.ProductCodes.map(item => ({ label: item, value: item }))
      productCodes.length > 0 && setProductCodeValue(productCodes)

      const XDealerCodeList: any = []
      data.DealerCodes.forEach(item => {
        if (item && item.length > 0) {
          XDealerCodeList.push({ value: item.replace('0000', ''), label: item.replace('0000', '') })
        }
      })
      setDealerCodeValue(XDealerCodeList)
      // if (data.DealerCodes) {
      //   debugger
      //   const dealerCodeList: any = []
      //   let list: { label: string, value: string }[] = []

      //   data.DealerCodes.forEach((codeItem) => {
      //     list.push({ label: codeItem.replace('0000', ''), value: codeItem.replace('0000', '') })

      //   })
      //   dealerCodeList.push(list)

      //   dealerCodeList.length > 0 && setDealerCodeValue(dealerCodeList)
      // }

      if (data.DealerTypes) {
        const dealerTypeList: any = []
        for (const code of data.DealerTypes) {
          const option = optionList.dealerTypeOptions.find(item => item.value === code)
          dealerTypeList.push(option)
        }
        dealerTypeList.length > 0 && setDealerTypeValue(dealerTypeList)
      }

      if (data.DealerSubTypes) {
        const dealerSubTypeList: any = []
        for (const code of data.DealerSubTypes) {
          const option = optionList.dealerSubTypeOptions.find(item => item.value === code)
          dealerSubTypeList.push(option)
        }
        dealerSubTypeList.length > 0 && setDealerSubTypeValue(dealerSubTypeList)
      }

      if (data.Installments) {
        const installment: any = data.Installments.map(item => {
          const Installment = optionList.installmentOptions.find(i => i.value === item.Installment)
          return { ...item, Installment, InterestRate: item.InterestRate === 0 ? '0' : item.InterestRate }
        })
        installment.length > 0 && setInstallments(installment)
      }
    }
  }, [location, optionList])

  useEffect(() => {
    if (location.state && location.state.history) {
      setIsHistory(location.state.history)
      const data: IyzicoCampaignModel = location.state.data
      setFormState(data)
      setIsEdit(location.state.edit)
      setStartDate(data.StartDate)
      setEndDate(data.EndDate)

      const companyValues: any = []
      for (const code of data.CompanyCodes) {
        const option = optionList.companyOptions.find(item => item.value === +code)
        companyValues.push(option)
      }
      companyValues.length > 0 && setCompanyValue(companyValues)

      if (data.WarehouseCodes) {
        const warehouseCodeList: any = []
        for (const code of data.WarehouseCodes) {
          const option = optionList.warehouseCodeOptions.find(item => +item.value === +code)
          warehouseCodeList.push(option)
        }
        warehouseCodeList.length > 0 && setWarehouseCodeValue(warehouseCodeList)
      }

      const brandIdList: any = []
      for (const code of data.BrandIdList) {
        const option = optionList.brandIdOptions.find(item => item.value === +code)
        brandIdList.push(option)
      }
      brandIdList.length > 0 && setBrandIdValue(brandIdList)

      const quotaList: any = []
      for (const code of data.Quota) {
        const option = optionList.quotaCodeOptions.find(item => +item.value === +code)
        quotaList.push(option)
      }
      quotaList.length > 0 && setQuotaCodesValue(quotaList)

      const productSubTypes = data.ProductSubTypes.map(item => ({ label: item, value: item }))
      productSubTypes.length > 0 && setProductSubTypeValue(productSubTypes)

      const productCodes = data.ProductCodes.map(item => ({ label: item, value: item }))
      productCodes.length > 0 && setProductCodeValue(productCodes)

      const XDealerCodeList: any = []
      data.DealerCodes.forEach(item => {
        if (item && item.length > 0) {
          XDealerCodeList.push({ value: item.replace('0000', ''), label: item.replace('0000', '') })
        }
      })
      setDealerCodeValue(XDealerCodeList)
      // if (data.DealerCodes) {
      //   debugger
      //   const dealerCodeList: any = []
      //   let list: { label: string, value: string }[] = []

      //   data.DealerCodes.forEach((codeItem) => {
      //     list.push({ label: codeItem.replace('0000', ''), value: codeItem.replace('0000', '') })

      //   })
      //   dealerCodeList.push(list)

      //   dealerCodeList.length > 0 && setDealerCodeValue(dealerCodeList)
      // }

      if (data.DealerTypes) {
        const dealerTypeList: any = []
        for (const code of data.DealerTypes) {
          const option = optionList.dealerTypeOptions.find(item => item.value === code)
          dealerTypeList.push(option)
        }
        dealerTypeList.length > 0 && setDealerTypeValue(dealerTypeList)
      }

      if (data.DealerSubTypes) {
        const dealerSubTypeList: any = []
        for (const code of data.DealerSubTypes) {
          const option = optionList.dealerSubTypeOptions.find(item => item.value === code)
          dealerSubTypeList.push(option)
        }
        dealerSubTypeList.length > 0 && setDealerSubTypeValue(dealerSubTypeList)
      }

      if (data.Installments) {
        const installment: any = data.Installments.map(item => {
          const Installment = optionList.installmentOptions.find(i => i.value === item.Installment)
          return { ...item, Installment, InterestRate: item.InterestRate === 0 ? '0' : item.InterestRate }
        })
        installment.length > 0 && setInstallments(installment)
      }
    }
  }, [location, optionList])

  const handleSubmit = async e => {
    e.preventDefault()
    setIsLoading(true)

    if (formState.Name.trim().length === 0) {
      setIsLoading(false)
      return Swal.fire('Hata', 'Kampanya adı boş bırakılamaz!', 'error')
    }

    const req = {
      ...formState,
      StartDate: getDateByTimezoneOffset(startDate) as any,
      EndDate: getDateByTimezoneOffset(endDate) as any,
      Installments: formState.Installments.map(item => ({ ...item, Id: typeof item.Id === 'string' ? 0 : item.Id })),
    }
    let dealerCodeList: string[] = dealerCodeValue?.map(item => (typeof item === 'string' ? item : '0000' + item.value)) || []
    req.DealerCodes = dealerCodeList


    if (isEdit) {
      try {
        const res: any = await new IyzicoCampaignService({}).update(req)

        if (res) {
          Swal.fire(res ? 'Başarılı' : 'Başarısız', res.description ?? '', res ? 'success' : 'error').then(() =>
            navigate('/admin/product-operation/iyzico-campaign')
          )
          setIsLoading(false)
          return
        }
        Swal.fire('Başarısız', res.description ?? '', 'error')
        setIsLoading(false)
      } catch (err: any) {
        Swal.fire('Hata', err.description ?? '', 'error')
        setIsLoading(false)
      }
      return
    }

    try {
      const res: any = await new IyzicoCampaignService({}).add(req)

      if (res) {
        Swal.fire(res ? 'Başarılı' : 'Başarısız', res.description ?? '', res ? 'success' : 'error').then(() =>
          navigate('/admin/product-operation/iyzico-campaign')
        )
        setIsLoading(false)
        return
      }
      Swal.fire('Başarısız', res.description ?? '', 'error')
      setIsLoading(false)
    } catch (err: any) {
      Swal.fire('Hata', err.description ?? '', 'error')
      setIsLoading(false)
    }
  }



  const placeholder = (value: string) => (
    <div
      style={{
        color: '#000',
        fontSize: '12px',
        fontWeight: '600',
      }}
    >
      {value}
    </div>
  )

  //#region DealerCodes
  enum CariListType {
    CariCode,
    CariType,
    CariAltType,
  }
  const [selectedDealerList, setSelectedDealerList] = useState(CariListType.CariCode)
  const selectDealer = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }

  const setDealerCodes = (dealerCodes, optionArr, updateFunc, type) => {
    if (type === "DealerCode") {
      const codeData = dealerCodes.map(item => ({
        label: String(item.DealerCode),
        value: String(item.DealerCode)
      }))

      let list: { label: string, value: string }[] = []

      codeData.forEach((codeItem) => {
        if (!dealerCodeValue?.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })
      const newData = dealerCodeValue ? [...dealerCodeValue, ...list] : list
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex((i) => i.label === item.label)
      })
      setDealerCodeValue(uniqueList)
      setValue(
        'DealerCodes',
        uniqueList
      )
    }
    else {
      const list: any = []
      const codeData: any = []

      dealerCodes.forEach(item => {
        const codes: any = optionArr.find((code: any) => {
          if (item.DealerCode.length === 6) {
            return code.label === item.DealerCode
          } else {
            return code.label === '0000' + item.DealerCode
          }
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push(codes)
      })

      if (codeData.length === 0) {
        return updateFunc(list)
      }

      Swal.fire({
        icon: 'question',
        title: `${codeData.map(dt => dt)} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) return
        updateFunc(list)
      })
    }
  }

  const handleFiles = async (files: File[], type: 'DealerCode' | 'DealerType' | 'DealerSubType') => {
    const dealerCodes: any = await excelToJSON(files[0])

    if (type === 'DealerCode') {
      setDealerCodes(dealerCodes[0], optionList.dealerCodeOptions, setDealerCodeValue, type)
      return
    }
    if (type === 'DealerType') {
      setDealerCodes(dealerCodes[0], optionList.dealerTypeOptions, setDealerTypeValue, type)
      return
    }
    if (type === 'DealerSubType') {
      setDealerCodes(dealerCodes[0], optionList.dealerSubTypeOptions, setDealerSubTypeValue, type)
      return
    }
  }
  //#endregion

  // #region Installments
  interface InstallmentState {
    Id: number
    Installment: ISelectOption | null
    InterestRate: number
  }
  const [installments, setInstallments] = useState<InstallmentState[]>([
    {
      Id: 0,
      Installment: null,
      InterestRate: 0,
    },
  ])

  const addInstallment = () => {
    setInstallments((prevState: any) => {
      const newRecord = {
        Id: uuidv4(),
        Installment: 0,
        InterestRate: 0,
      }
      if (prevState && prevState.length > 0) {
        const prev = prevState.map(item => {
          let Installment = 0
          if (item.Installment && item.Installment.value) {
            Installment = +item.Installment.value
          }
          let InterestRate = 0
          if (item.InterestRate) {
            InterestRate = +item.InterestRate
          }
          return {
            ...item,
            Installment,
            InterestRate,
          }
        })
        setValue('Installments', [...prev, newRecord])
        return [...prevState, newRecord]
      }
      return [newRecord]
    })
  }

  const updateInstallment = (id, key, target) => {
    if (!installments) return

    const updatedState = installments.map(item => {
      if (item.Id === id) {
        item[key] = target
      }
      return item
    })

    let count = 0
    updatedState.forEach(x => {
      if (x.Installment!.value === target.value) count++
    })
    if (count === 2) {
      Swal.fire('Bilgi', 'Aynı taksit seçeneğini seçemezsiniz!', 'info')
      updatedState.pop()
    }

    setInstallments(updatedState)

    const formInstallments = updatedState.map(item => {
      let Installment = 0
      if (item.Installment && item.Installment.value) {
        Installment = +item.Installment.value
      }
      let InterestRate = 0
      if (item.InterestRate) {
        InterestRate = +item.InterestRate
      }
      return { ...item, Installment, InterestRate }
    })

    setValue('Installments', formInstallments)
  }

  const removeInstallment = id => {
    setInstallments((prevState: any) => {
      const updatedState = prevState.filter(item => item.Id !== id)
      setValue(
        'Installments',
        updatedState.map(item => {
          let Installment: any = 0
          if (item.Installment && item.Installment.value) {
            Installment = +item.Installment.value
          }
          return { ...item, Installment }
        })
      )
      return updatedState
    })
  }
  //#endregion

  const onCreateOption = (option, property, updateFunc) => {
    if (property === "ProductCodes") {
      updateFunc(prevState => {
        const newData = {
          value: option[option.length - 1].value,
          label: option[option.length - 1].label,
        }
        if (prevState && prevState.length > 0) {
          for (const state of prevState) {
            if (state.value === newData.value) return [...prevState]
            const prevData = formState[property]
            setValue(property, [...prevData, newData.value])
            return [...prevState, newData]
          }
        }
        setValue(property, [newData.value])
        return [newData]
      })
    }
    else {
      updateFunc(prevState => {
        const newData = {
          value: option[option.length - 1].value,
          label: option[option.length - 1].label,
        }
        if (prevState && prevState.length > 0) {
          for (const state of prevState) {
            if (state.value === newData.value) return [...prevState]
            const prevData = formState[property]
            setValue(property, [...prevData, newData.value])
            return [...prevState, newData]
          }
        }
        setValue(property, [newData.value])
        return [newData]
      })
    }
  }

  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string, value: string }[] = []
      words.forEach((word: any) => {
        if (!dealerCodeValue?.some((item: any) => item.label === word)) {
          const newItem = { label: word, value: word }
          dealerList.push(newItem)
        }
      })
      const newData = dealerCodeValue ? [...dealerCodeValue, ...dealerList] : dealerList
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex((i) => i.label === item.label)
      })
      setDealerCodeValue(uniqueList)
      setValue(
        'DealerCodes',
        uniqueList
      )
      setInputValue('');
    }
  }

  return (
    <>
      <div className='container py-3 iyzicoCampaignAdd'>
        <h1
          className={`mb-3 ${css`
            font-size: 32px;
          `}`}
        >
          Iyzico Kampanya {isEdit ? 'Düzenleme' : 'Ekleme'}
        </h1>

        <IPButton
          onClick={() => navigate('/admin/product-operation/iyzico-campaign')}
          text={'⬅ Listeye Dön'}
          className='listBtn mb-5'
        />

        <form onSubmit={handleSubmit}>
          <div className='row'>
            <div className='col-12 col-md-4 mb-5'>
              <InputCampaign
                required
                id='CampaignName'
                label='Kampanya Adı'
                value={formState.Name}
                onChange={e => setValue('Name', e.target.value)}
                disabled={isHistory}
              />
            </div>

            <div className='col-12 col-md-4 mb-5'>
              <InputCampaign
                type='datetime-local'
                id='StartDate'
                name='StartDate'
                label={'Yayın Başlangıç Tarihi'}
                value={toDateTimeLocal(startDate)}
                onChange={e => getDate(e.target.value, "Start")}
                isActive
                disabled={isHistory}
              />
            </div>

            <div className='col-12 col-md-4 mb-5'>
              <InputCampaign
                type='datetime-local'
                id='EndDate'
                name='EndDate'
                label={'Yayın Bitiş Tarihi'}
                value={toDateTimeLocal(endDate)}
                onChange={e => getDate(e.target.value, "End")}
                isActive
                disabled={isHistory}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4 mb-5'>
              <IPSelectBox
                isMulti
                options={optionList.companyOptions}
                value={companyValue}
                isDisabled={isHistory}
                onChangeMulti={option => {
                  setCompanyValue(option)
                  setValue(
                    'CompanyCodes',
                    option.map(item => item.value.toString())
                  )
                  if (option.length === 0) setValue('IsCompanyCodesInclude', false)
                }}
                placeholder={placeholder('Grup Firma Listesi')}
              />
              <IPCheckbox
                id='IsCompanyCodesInclude'
                label='Grup Firma Listesi Dahil'
                checked={formState.IsCompanyCodesInclude}
                onCheckedChange={checked => setValue('IsCompanyCodesInclude', checked)}
                disabled={isHistory}
              />
            </div>

            <div className='col-12 col-md-4 mb-5'>
              <IPSelectBox
                isMulti
                options={optionList.warehouseCodeOptions}
                value={warehouseCodeValue}
                isDisabled={isHistory}
                onChangeMulti={option => {
                  setWarehouseCodeValue(option)
                  setValue(
                    'WarehouseCodes',
                    option.map(item => item.value)
                  )
                  if (option.length === 0) setValue('IsWarehouseCodesInclude', false)
                }}
                placeholder={placeholder('Depo Kodu')}
              />
              <IPCheckbox
                id='IsWarehouseCodesInclude'
                label='Depo Kod Listesi Dahil'
                checked={formState.IsWarehouseCodesInclude}
                onCheckedChange={checked => setValue('IsWarehouseCodesInclude', checked)}
                disabled={isHistory}
              />
            </div>

            <div className='col-12 col-md-4 mb-5'>
              <IPSelectBox
                isMulti
                options={optionList.brandIdOptions}
                value={brandIdValue}
                isDisabled={isHistory}
                onChangeMulti={option => {
                  setBrandIdValue(option)
                  setValue(
                    'BrandIdList',
                    option.map(item => item.value)
                  )
                  if (option.length === 0) setValue('IsBrandIdListInclude', false)
                }}
                placeholder={placeholder('Marka Listesi')}
              />
              <IPCheckbox
                id='IsBrandIdListInclude'
                label='Marka Listesi Dahil'
                checked={formState.IsBrandIdListInclude}
                onCheckedChange={checked => setValue('IsBrandIdListInclude', checked)}
                disabled={isHistory}
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-md-4 mb-5'>
              <IPSelectBox
                isMulti
                options={optionList.quotaCodeOptions}
                isDisabled={isHistory}
                value={quotaCodesValue}
                onChangeMulti={option => {
                  setQuotaCodesValue(option)
                  setValue(
                    'Quota',
                    option.map(item => item.value)
                  )
                  if (option.length === 0) setValue('IsQuotaInclude', false)
                }}
                placeholder={placeholder('Quota')}
              />
              <IPCheckbox
                id='IsQuotaInclude'
                label='Quota Dahil'
                checked={formState.IsQuotaInclude}
                onCheckedChange={checked => setValue('IsQuotaInclude', checked)}
                disabled={isHistory}
              />
            </div>

            <div className='col-12 col-md-4 mb-5'>
              <IPSelectBox
                isMulti
                isCreatableSelect
                onCreateOption={option => onCreateOption(option, 'ProductSubTypes', setProductSubTypeValue)}
                options={optionList.productSubTypeOptions}
                value={productSubTypeValue}
                isDisabled={isHistory}
                onChangeMulti={option => {
                  setProductSubTypeValue(option)
                  setValue(
                    'ProductSubTypes',
                    option.map(item => item.value)
                  )
                  if (option.length === 0) setValue('IsProductSubTypesInclude', false)
                }}
                placeholder={placeholder('Ürün Alt Tipi')}
              />
              <IPCheckbox
                id='IsProductSubTypesInclude'
                label='Ürün Alt Tipi Dahil'
                checked={formState.IsProductSubTypesInclude}
                disabled={isHistory}
                onCheckedChange={checked => setValue('IsProductSubTypesInclude', checked)}
              />
            </div>

            <div className='col-12 col-md-4 mb-5'>
              <IPSelectBox
                isMulti
                isCreatableSelect
                isDisabled={isHistory}
                onCreateOption={option => onCreateOption(option, 'ProductCodes', setProductCodeValue)}
                //options={optionList.productCodeOptions}
                value={productCodeValue}
                onChangeMulti={option => {
                  setProductCodeValue(option)
                  setValue(
                    'ProductCodes',
                    option.map(item => item.value)
                  )
                  if (option.length === 0) setValue('IsProductCodesInclude', false)
                }}
                placeholder={placeholder('Ürün Kodu')}
              />
              <IPCheckbox
                id='IsProductCodesInclude'
                label='Ürün Kodu Dahil'
                checked={formState.IsProductCodesInclude}
                onCheckedChange={checked => setValue('IsProductCodesInclude', checked)}
                disabled={isHistory}
              />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12 col-md-6 mb-5'>
              <div className='leftContainer mb-5'>
                <div>
                  <a className='campaignExcelBtn' href='/assetWeb/docs/dealer-codes-example.xlsx' target='_blank'>
                    Örnek Excel
                  </a>

                  <div className='listBtnGroup'>
                    <IPButton
                      className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariCode)}`}
                      text={`Cari Kodu Listesi ${dealerCodeValue && dealerCodeValue.length > 0 ? '✅' : ''}`}
                      onClick={() => setSelectedDealerList(CariListType.CariCode)}
                    />
                    <IPButton
                      className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariType)}`}
                      text={`Cari Tipi Listesi ${dealerTypeValue && dealerTypeValue.length > 0 ? '✅' : ''}`}
                      onClick={() => setSelectedDealerList(CariListType.CariType)}
                    />
                    <IPButton
                      className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariAltType)}`}
                      text={`Cari Alt Tipi Listesi ${dealerSubTypeValue && dealerSubTypeValue.length > 0 ? '✅' : ''}`}
                      onClick={() => setSelectedDealerList(CariListType.CariAltType)}
                    />
                  </div>
                </div>

                <div className='p-0 m-0'>
                  <div className={`cariFilterTextAreaWrap ${selectDealer(selectedDealerList, CariListType.CariCode)}`}>
                    <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                      <IPFile
                        id='FileInputDealerCode'
                        multiple={false}
                        handleFiles={e => handleFiles(e, 'DealerCode')}
                        removeAfterSelect={true}
                        className='campaignFileInput'
                        label="Excel'den Yükle"
                        disabled={isHistory}
                      />

                      <IPButton
                        text={'Dahil'}
                        className={`btnIncluded ${formState.IsDealersInclude === true ? 'active' : ''}`}
                        onClick={() => setValue('IsDealersInclude', true)}
                        disabled={isHistory}
                      />
                      <IPButton
                        text={'Hariç'}
                        className={`btnExcept ${formState.IsDealersInclude === false ? 'active' : ''}`}
                        onClick={() => setValue('IsDealersInclude', false)}
                        disabled={isHistory}
                      />
                    </div>
                    <CampaignSelect
                      placeholder={'Cari Kod Listesi'}
                      height={180}
                      //options={optionList.dealerCodeOptions}
                      onChangeMulti={options => {
                        if (!isHistory) {
                          setDealerCodeValue(options)
                          setValue(
                            'DealerCodes',
                            options.map(item => item.value)
                          )
                        }
                      }}
                      value={dealerCodeValue}
                    />
                    <input
                      type="text"
                      placeholder="Cari kodu yazın"
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyPress={handleInputKeyPress}
                      disabled={isHistory}
                    />
                  </div>

                  <div className={`cariFilterTextAreaWrap ${selectDealer(selectedDealerList, CariListType.CariType)}`}>
                    <div className='topBtnGroup d-flex justify-content-center align-items-center mb-5'>
                      <div className='btns'>
                        <IPFile
                          id='FileInputDealerType'
                          multiple={false}
                          handleFiles={e => handleFiles(e, 'DealerType')}
                          removeAfterSelect={true}
                          className='campaignFileInput'
                          label="Excel'den Yükle"
                          disabled={isHistory}
                        />
                      </div>
                      <IPButton
                        text={'Dahil'}
                        className={`btnIncluded ${formState.IsDealerTypesInclude === true ? 'active' : ''}`}
                        onClick={() => setValue('IsDealerTypesInclude', true)}
                        disabled={isHistory}
                      />
                      <IPButton
                        text={'Hariç'}
                        className={`btnExcept ${formState.IsDealerTypesInclude === false ? 'active' : ''}`}
                        onClick={() => setValue('IsDealerTypesInclude', false)}
                        disabled={isHistory}
                      />
                    </div>
                    <CampaignSelect
                      placeholder={'Cari Tipi Listesi'}
                      height={180}
                      options={optionList.dealerTypeOptions}
                      onChangeMulti={options => {
                        if (!isHistory) {
                          setDealerTypeValue(options)
                          setValue(
                            'DealerTypes',
                            options.map(item => item.value)
                          )
                        }
                      }}
                      value={dealerTypeValue}
                    />
                  </div>

                  <div
                    className={`cariFilterTextAreaWrap ${selectDealer(selectedDealerList, CariListType.CariAltType)}`}
                  >
                    <div className='topBtnGroup d-flex justify-content-center align-items-center mb-5'>
                      <div className='btns'>
                        <IPFile
                          id='FileInputDealerSubType'
                          multiple={false}
                          handleFiles={e => handleFiles(e, 'DealerSubType')}
                          removeAfterSelect={true}
                          className='campaignFileInput'
                          label="Excel'den Yükle"
                          disabled={isHistory}
                        />
                      </div>
                      <IPButton
                        text={'Dahil'}
                        className={`btnIncluded ${formState.IsDealerSubTypesInclude === true ? 'active' : ''}`}
                        onClick={() => setValue('IsDealerSubTypesInclude', true)}
                        disabled={isHistory}
                      />
                      <IPButton
                        text={'Hariç'}
                        className={`btnExcept ${formState.IsDealerSubTypesInclude === false ? 'active' : ''}`}
                        onClick={() => setValue('IsDealerSubTypesInclude', false)}
                        disabled={isHistory}
                      />
                    </div>
                    <CampaignSelect
                      placeholder={'Cari Alt Tipi Listesi'}
                      height={180}
                      options={optionList.dealerSubTypeOptions}
                      onChangeMulti={options => {
                        if (!isHistory) {
                          setDealerSubTypeValue(options)
                          setValue(
                            'DealerSubTypes',
                            options.map(item => item.value)
                          )
                        }

                      }}
                      value={dealerSubTypeValue}
                    />
                  </div>
                </div>
              </div>
              <IPCheckbox
                id='Enabled'
                label='Kampanya Durumu Aktif'
                checked={formState.Enabled}
                onCheckedChange={checked => setValue('Enabled', checked)}
                disabled={isHistory}
              />
            </div>
            <div className='col-12 col-md-6 mb-5 row'>
              <div className='col-lg-6'>
                <IPInput type={'number'}
                  value={formState.DealerLimit}
                  disabled={isHistory}
                  onChange={e => {
                    setFormState({ ...formState, DealerLimit: Number(e.target.value) })

                  }}
                  placeholder='Bayiye Özel Limit' />
                <label>Bayiye Özel Limit</label>
              </div>

              <div className='col-lg-6'>
                <IPInput type={'number'}
                  value={formState.CampaignLimit}
                  disabled={isHistory}
                  onChange={e => {
                    setFormState({ ...formState, CampaignLimit: Number(e.target.value) })

                  }}
                  placeholder='Kampanya Limiti' />
                <label>Kampanya Limiti</label>
              </div>
              <div className={window.innerWidth < 1200 ? 'table-responsive' : ''}>
                <table className='campaignTable table table-hover'>
                  <thead>
                    <tr>
                      <th scope='col'>Taksit</th>
                      <th scope='col'>Vade</th>
                      <th scope='col'>İşlem</th>
                    </tr>
                  </thead>

                  <tbody>
                    {installments &&
                      installments.map(item => (
                        <tr key={item.Id}>
                          <td style={{ width: '25%' }}>
                            <IPSelectBox
                              options={optionList.installmentOptions}
                              value={item.Installment}
                              placeholder={placeholder('Taksit')}
                              isDisabled={isHistory}
                              onChangeSingle={option => updateInstallment(item.Id, 'Installment', option)}
                            />
                          </td>
                          <td style={{ width: '35%' }}
                            className={css`
                              input {
                                border: 1px solid hsl(0deg, 0%, 80%) !important;
                              }
                            `}
                          >
                            <IPInput
                              type='text'
                              value={item.InterestRate}
                              disabled={isHistory}
                              onChange={e => {
                                let val = e.target.value

                                if (+val < 0) val = '0'
                                if (val.includes(',')) val = val.replace(',', '.')

                                updateInstallment(item.Id, 'InterestRate', val)
                              }}
                              placeholder='Vade'
                            />
                          </td>
                          <td className='actionCol' style={{ width: '40%' }}>
                            <div
                              className='tableIcon'
                              style={{ display: isHistory ? 'none' : '' }}
                              onClick={() => {
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Satır silinecek emin misiniz?',
                                  showCloseButton: true,
                                  showDenyButton: true,
                                  confirmButtonText: 'Sil!',
                                  confirmButtonAriaLabel: 'Sil',
                                  confirmButtonColor: '#ec4c4c',
                                  denyButtonText: 'İptal',
                                  denyButtonAriaLabel: 'İptal',
                                  denyButtonColor: '#AAA',
                                }).then(result => {
                                  if (result.isConfirmed) {
                                    removeInstallment(item.Id)
                                  }
                                })
                              }}
                            >
                              <i className='icon-delete'></i>
                            </div>
                          </td>
                        </tr>
                      ))}
                    <tr onClick={addInstallment}>
                      <td colSpan={7} style={{ color: '#699bf7', display: isHistory ? 'none' : '' }}>
                        Yeni Kayıt Ekle...
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <IPButton type='submit' text={isEdit ? 'Güncelle' : 'Kaydet'} className='saveBtn my-2' icon='saveIcon.svg' style={{ display: isHistory ? 'none' : '' }} />
        </form>
      </div>
      <Spinner loading={isLoading} />
    </>
  )
}
