import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { ISshSupport } from '../models'

export class getSssSupportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'page/sssSupport',
      requesterInfo,
    })
  }

  public async getSssSupport(): Promise<boolean> {
    return await this.getAsync<boolean>()
  }
}
