import React, { useEffect, useRef, useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { PostAddOpportunityApi } from '../service/postOpportunity'
import Swal from 'sweetalert2'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import {
  excelToJSON,
  getDateByTimezoneOffset,
  getUploadFileRequest,
  handleImageUpload,
  uuidv4,
} from '../../../../../base/utils/dataHelper'
import _ from 'lodash'
import { UpdateOpportunityApi } from '../service/opportunityUpdate'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { CommonPostResponse, ICompany, IDocuments, IWarehouses } from '../../../components/models/models'
import { useCustomReducer } from '../../../../../base/customHooks/customReducer'
import IPImage from '../../../../../components/IPImage'
import { getCompanyApi, getWarehousesApi } from '../../../components/api'
import { IAddOpportunity } from '../models/requestModel'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import IPFile from '../../../../../components/IPFile'
import CampaignSelect from '../../IyzicoCampaign/CampaignSelect'
import './AddOpportunityAisle.scss'
import { IyzicoCampaignService } from '../../IyzicoCampaign/IyzicoCampaignService'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import InputCampaign from '../../../../../components/InputCampaign'
import { isValid } from 'js-base64'
import { PostFirsatApi } from '../../VisualDocumentationCrud/services/postFirsatApi'
import { data } from 'jquery'
import Image from '../../../../../components/IPImage/IPImage'
import { DeleteImageFirsatApi } from '../../VisualDocumentationCrud/services/deleteImageFirsatApi'

interface IOpportunityState {
  productCode: ISelectOption[]
  wareHouseList: ISelectOption[]
  dealerCodeOptions: ISelectOption[]
  dealerSubTypeOptions: ISelectOption[]
}

function AddOpportunityAisle(props: BaseComponentWithContextProps) {

  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())

  const getInitialState = (): IOpportunityState => {
    return {
      productCode: [],
      wareHouseList: [],
      dealerCodeOptions: [],
      dealerSubTypeOptions: [],
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IOpportunityState>(intialState)
  const [quantity, setQuantity] = useState(false)
  const [history, setHistory] = useState(false)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [images, setImages] = useState<any[]>([])
  const inputEl: any = useRef(null)
  let navigate = useNavigate()
  const [documents, setDocuments] = useState<IDocuments[]>([])
  const [dealerCodeValue, setDealerCodeValue] = useState<ISelectOption[]>([])
  const [dealerSubTypeValue, setDealerSubTypeValue] = useState<ISelectOption[]>([])
  const t = translate(Translations)
  // const [dateRange, setDateRange] = useState<any>([new Date(), new Date()])
  // const [startDate, endDate] = dateRange
  const routeLocation = useLocation()
  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    const data: ICompany[] = []
    company
      .filter(com => {
        return com.Name != 'Index Group'
      })
      .map(cm => {
        return data.push(cm)
      })
    setCompanies(data)
  }

  const handleFile = async e => {
    let targetFiles = e.target.files
    targetFiles = await getUploadFileRequest(e)
    targetFiles[0].base64Str = await handleImageUpload(e.target.files[0])
    targetFiles[0].Order = uuidv4()
  }
  const getWareHouseList = async () => {
    const warehouses: IWarehouses[] = await getWarehousesApi()
    const data: ISelectOption[] = []
    if (warehouses) {
      warehouses.filter((ware) => { return ware.Name === "Fırsat" }).map((house) => {
        return data.push({ value: house.WarehouseCode, label: house.WarehouseCode })
      })
    }

    setState({ wareHouseList: data })
    // const campaignBundleService = new CampaignBundleService({})
    // const res = await campaignBundleService.getWareHouseCode()
    // if (res) {
    //   const data: ISelectOption[] = []
    //   res.map((r, index) => {
    //     if (r != 1920) {
    //       data.push({ value: r, label: r })
    //     }
    //   })
    //   setState({ wareHouseList: data })
    // }
  }

  const deleteImage = async (imageId: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Resmi silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const api: DeleteImageFirsatApi = new DeleteImageFirsatApi({})
        const res = await api.deleteImage(imageId)
        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Resim Silindi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async res => {
            setImages(prevState => {
              return prevState
                .filter((prev: any) => {
                  return prev.Id != imageId
                })
                .map(p => {
                  return p
                })
            })
            var sess = sessionStorage.getItem('deletedOpID')
            if (sess != null)
              sessionStorage.setItem('deletedOpID', sessionStorage.getItem('deletedOpID') + "," + imageId)
            else
              sessionStorage.setItem('deletedOpID', imageId.toString())

          })
        }
      }
    })
  }

  const deleteDocument = async data => {
    const documentUpdate = documents
      .filter(doc => {
        return doc.Id != data
      })
      .map(d => {
        return d
      })
    // documents.filter((doc) => {
    //   return doc.Id === data
    // }).map((doc) => {
    //   if (doc.Name === "Banner")
    //     setState({ documentBanner: false, banner: false })
    //   if (doc.Name === "Logo")
    //     setState({ documentLogo: false, logo: false })
    // })
    setDocuments(documentUpdate)
  }
  const initialValues = Object.assign({
    Id: 0,
    ProductStockCode: null,
    WarehouseCode: { value: '1042', label: '1042' },
    ProductOrder: '',
    ProductSerialNo: '',
    Quantity: 0,
    QuantitySold: 0,
    Files: [
    ],
    Description: '',
    Price: 0,
    StartDate: new Date(),
    EndDate: new Date(),
    NightMarket: false,
    OutOfWarranty: false,
    Enabled: false,
    flag: 'ekle',
    CompanyId: null,
    ShortDescription: '',
    DealerCodes: [],
    DealerSubTypes: [],
    Images: [],
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: async () => { },
  })
  const editOpportunity = async () => {
    const clnValues = _.clone(values)
    if (clnValues.ShortDescription && clnValues.ShortDescription.length > 25) {
      Swal.fire({
        icon: 'error',
        title: 'Kısa açıklama 25 karakterden az olmalıdır',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!clnValues.Quantity) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen adet giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const dateStart = new Date()
    dateStart.setTime(startDate)
    // clnValues.ProductStockCode = clnValues.ProductStockCode
    //   ? clnValues.ProductStockCode.value
    //   : "";
    const dateEnd = new Date()
    dateEnd.setTime(endDate)

    clnValues.StartDate = getDateByTimezoneOffset(startDate) as any
    clnValues.EndDate = getDateByTimezoneOffset(endDate) as any
    clnValues.WarehouseCode = values.WarehouseCode.label
    const dealer: any[] = []
    dealerCodeValue && dealerCodeValue.length > 0 && dealerCodeValue.map((dea) => {
      return dealer.push(dea.value)
    })
    clnValues.DealerCodes = dealer
    const subtype: any[] = []
    dealerSubTypeValue && dealerSubTypeValue.length > 0 && dealerSubTypeValue.map((dea) => {
      return subtype.push(dea.value)
    })
    clnValues.DealerSubTypes = subtype
    if (!values.CompanyId) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen Şirket giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    clnValues.CompanyId = values.CompanyId ? Number(values.CompanyId.value) : 0
    if (clnValues) {
      if (clnValues.flag === 'Güncelle') {
        if (clnValues.QuantitySold > clnValues.Quantity) {
          Swal.fire({
            icon: 'error',
            title: 'Adet satılan adetten az olamaz',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          return
        }
        if (clnValues.Price === "") {
          Swal.fire({
            icon: 'error',
            text: "Fiyat boş bırakılamaz!",
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          return
        }
        if (isNaN(+clnValues.Price) || +clnValues.Price < 0) {
          Swal.fire({
            icon: 'error',
            text: "Geçerli bir fiyat giriniz!",
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          return
        }
        props.showLoading()
        delete clnValues.flag
        const updateOpportunity: UpdateOpportunityApi = new UpdateOpportunityApi({})
        const request: Partial<IAddOpportunity> = {
          Id: clnValues.Id,
          CompanyId: clnValues.CompanyId,
          ProductStockCode: clnValues.ProductStockCode,
          WarehouseCode: clnValues.WarehouseCode,
          ProductOrder: clnValues.ProductOrder,
          ProductSerialNo: clnValues.ProductSerialNo,
          Quantity: clnValues.Quantity,
          QuantitySold: clnValues.QuantitySold,
          Description: clnValues.Description,
          Price: clnValues.Price,
          StartDate: clnValues.StartDate,
          EndDate: clnValues.EndDate,
          NightMarket: clnValues.NightMarket,
          OutOfWarranty: clnValues.OutOfWarranty,
          Enabled: clnValues.Enabled,
          ShortDescription: clnValues.ShortDescription,
          DealerCodes: clnValues.DealerCodes,
          DealerSubTypes: clnValues.DealerSubTypes,
        }
        if (clnValues.Files && clnValues.Files.length > 0) {
          request.Files = clnValues.Files
        }

        updateOpportunity
          .putOpportunity(request as IAddOpportunity)
          .then(async result => {
            props.hideLoading()
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Ürün Güncellendi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async result => {
                navigate('/admin/ProductOperation/OpportunityAisleProductList' + (routeLocation.state?.url ? "?" + routeLocation.state?.url : ''))
              })
            }
          })
          .catch((err: any) => {
            props.hideLoading()
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      } else {
        props.showLoading()
        delete clnValues.flag
        const postOpportunity: PostAddOpportunityApi = new PostAddOpportunityApi({})
        postOpportunity
          .postOpportunity(clnValues)
          .then((result: CommonPostResponse) => {
            if (result) {
              props.hideLoading()
              Swal.fire({
                icon: 'success',
                title: 'Fırsat Reyonu Eklendi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async result => {
                navigate('/admin/ProductOperation/OpportunityAisleProductList' + (routeLocation.state?.url ? "?" + routeLocation.state?.url : ''))
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          })
      }
    }
  }

  useEffect(() => {
    if (values.ProductSerialNo && values.ProductSerialNo.length > 0) {
      setFieldValue('Quantity', 1)
      return setQuantity(true)
    }
    setFieldValue('Quantity', null)
    setQuantity(false)
  }, [values.ProductSerialNo])

  useEffect(() => {
    getCompanies()
    getWareHouseList()
    //getDealerCodes()
    getDealerSubTypes()
  }, [])

  useEffect(() => {
    if (companies.length > 0) {
      if (routeLocation.state) {
        const data = (routeLocation.state as any)?.opportunityDetailData
        setHistory((routeLocation.state as any)?.data?.toString() === 'History' ? true : false)
        data.CompanyId = companies
          .filter((item: any) => item.Id === data.CompanyId)
          .map((com: any) => {
            return { value: com.Id, label: com.Name }
          })[0]
        data.WarehouseCode = { value: data.WarehouseCode, label: data.WarehouseCode }
        // setDateRange([data.StartDate ? new Date(data.StartDate) : null, data.EndDate ? new Date(data.EndDate) : null])
        setStartDate(data.StartDate ? new Date(data.StartDate) : null)
        setEndDate(data.EndDate ? new Date(data.EndDate) : null)
        setValues(data)
        const deletedImages = sessionStorage.getItem('deletedOpID')
        if (deletedImages != null) {
          var idList = deletedImages.split(",").map(Number) // Convert elements to numbers
          var filteredImages = data.Images.filter(image => !idList.includes(parseInt(image.Id)))
          setImages(filteredImages)
        }
        else
          setImages(data.Images)

        const dealer: ISelectOption[] = []
        data.DealerCodes.map((dea) => {
          return dealer.push({ value: dea, label: dea.replace("0000", "") })
        })
        setDealerCodeValue(dealer)
        const dealerSubType: ISelectOption[] = []
        if (data.DealerSubTypes) {
          data.DealerSubTypes.map((dea) => {
            return dealerSubType.push({ value: dea, label: dea })
          })
          setDealerSubTypeValue(dealerSubType)
        }
      } else {
        resetForm()
      }
    }
  }, [companies])
  const handleFiles = async (files: any[]) => {
    const event = Object.assign({})
    event.target = Object.assign({})
    event.target.files = files

    const fileList = await getUploadFileRequest(event)

    setFieldValue('Files', fileList)
  }

  const handlePrice = (e: any) => {
    const val = e.target.value
    setFieldValue("Price", val.replace(',', '.'))
  }

  // const handlePriceOnBlur = (e: any) => {
  //   if (e.target.value?.length < 1 || isNaN(Number(e.target.value)) || Number(e.target.value) < 0) {
  //     return setFieldValue("Price", 1)
  //   }
  //   setFieldValue("Price", e.target.value)
  // }
  const handlePriceOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const val = e.target.value.trim() // Remove leading and trailing whitespace

    if (val === "") {
      Swal.fire({
        icon: 'error',
        text: "Fiyat boş bırakılamaz!",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    let isValid = true
    if (isNaN(+val) || +val < 0) {
      isValid = false
      setFieldValue("Price", "", isValid) // Set a default value or handle the invalid case as needed
      Swal.fire({
        icon: 'error',
        text: "Geçerli bir fiyat giriniz!",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    setFieldValue("Price", val, isValid)
  }

  const handleImageFileUpload = async (e: any) => {
    const fileList: any[] = await getUploadFileRequest(e)
    for (const fileData of fileList) {
      if (fileData.size > 5000000) {
        return Swal.fire('Hata', 'Her resim 5 MB\'den küçük olmalıdır!', 'error')
      }

      const allowedTypes = ['.jpeg', '.png', '.gif', '.jpg']
      if (!allowedTypes.includes(fileData.Extension)) {
        return Swal.fire('Hata', 'Hatalı dosya formatı! Sayfayı yeniledikten sonra tekrar deneyiniz.', 'error')
      }
    }

    const data = {
      Files: fileList.map((fileData, index) => ({
        Id: index,
        Label: '',
        Description: '',
        File: fileData,
      })),
      ProductId: (routeLocation.state as any)?.opportunityDetailData.Id
    }

    const postImage: PostFirsatApi = new PostFirsatApi({})
    postImage
      .addDoc(data)
      .then((result: any) => {
        Swal.fire({
          icon: 'success',
          title: result,
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(async res => {
          window.location.href = '/admin/ProductOperation/OpportunityAisleProductList' + (routeLocation.state?.url ? "?" + routeLocation.state?.url : '')
        })

      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const selectDealer = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }
  enum CariListType {
    CariCode,
    CariAltType,
  }
  const [selectedDealerList, setSelectedDealerList] = useState(CariListType.CariCode)
  const setDealerCodes = (dealerCodes, optionArr, updateFunc, type) => {
    if (type === "DealerCode") {
      const codeData = dealerCodes.map(item => ({
        label: String(item.DealerCode),
        value: String('0000' + item.DealerCode)
      }))

      let list: { label: string, value: string }[] = []

      codeData.forEach((codeItem) => {
        if (!dealerCodeValue?.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })
      const newData = dealerCodeValue ? [...dealerCodeValue, ...list] : list
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex((i) => i.label === item.label)
      })
      setDealerCodeValue(uniqueList)
    }
    else {
      const list: any = []
      const codeData: any = []

      dealerCodes.forEach(item => {
        const codes: any = optionArr.find((code: any) => {
          if (item.DealerCode.length === 6) {
            return code.label === item.DealerCode
          } else {
            return code.label === '0000' + item.DealerCode
          }
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push(codes)
      })

      if (codeData.length === 0) {
        return updateFunc(list)
      }

      Swal.fire({
        icon: 'question',
        title: `${codeData.map(dt => dt)} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) return
        updateFunc(list)
      })
    }
  }

  const handleFilesData = async (files: File[], type: 'DealerCode' | 'DealerSubType') => {
    const dealerCodes: any = await excelToJSON(files[0])

    if (type === 'DealerCode') {
      setDealerCodes(dealerCodes[0], state.dealerCodeOptions, setDealerCodeValue, type)
      return
    }
    if (type === 'DealerSubType') {
      setDealerCodes(dealerCodes[0], state.dealerSubTypeOptions, setDealerSubTypeValue, type)
      return
    }
  }


  const getDealerCodes = async () => {
    const res = await new IyzicoCampaignService({}).getDealerCodes()
    if (res) {
      const dealerCodeList: ISelectOption[] = []

      res.forEach((item: any) => {
        item.SapCode && dealerCodeList.push({ value: item.SapCode, label: item.SapCode.toString().replace('0000', '') })
      })

      setState({ dealerCodeOptions: dealerCodeList })
    }
  }
  const getDealerSubTypes = async () => {
    const res = await new IyzicoCampaignService({}).getSubTypes()
    if (!res) return []
    const dealerCodeList: ISelectOption[] = []
    res.map(item => dealerCodeList.push({ value: item, label: item }))
    setState({ dealerSubTypeOptions: dealerCodeList })
  }

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  const [inputValue, setInputValue] = useState('')

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string, value: string }[] = []
      words.forEach((word: any) => {
        if (!dealerCodeValue?.some((item: any) => item.label === '0000' + word)) {
          const newItem = { label: word, value: '0000' + word }
          dealerList.push(newItem)
        }
      })
      const newData = dealerCodeValue ? [...dealerCodeValue, ...dealerList] : dealerList
      const uniqueList = newData.filter((item, index, self) => {
        return index === self.findIndex((i) => i.label === item.label)
      })
      setDealerCodeValue(uniqueList)
      setInputValue('')
    }
  }


  return (
    <div className='container opportunityAisle'>
      <div className='mb-3'>
        <div className='content col-lg-12 m-auto'>
          <div className='card'>
            <div className='card-header'>
              <span className='h4'>
                {t('pages.admin.productOperation.opportunityAisleProduct.addOpportunityAisle.header')}
              </span>
            </div>
            <div className='card-body'>
              <form id='id1' className='formform' noValidate>
                <div className='col-lg-2'>
                  <div className='buttonsuz'>
                    <Link to={`/admin/ProductOperation/OpportunityAisleProductList${(routeLocation.state?.url ? "?" + routeLocation.state?.url : '')}`}>
                      <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                    </Link>
                  </div>
                </div>
                <div className='etkin d-flex justify-content-end'>
                  <label className='p-switch switch-color-success d-flex'>
                    <label className='floagit t-left mr-4'>Etkin</label>
                    <IPCheckbox disabled={history} name='Enabled' onChange={handleChange} checked={values.Enabled} />
                  </label>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-3'>
                    <label htmlFor='SapCodeList' className='labeltext'>
                      Ürün/Stok Kodu
                    </label>
                    <IPInput
                      disabled={history}
                      type='text'
                      className='form-control'
                      name='ProductStockCode'
                      onChange={handleChange}
                      value={values.ProductStockCode}
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.productOperation.opportunityAisleProduct.addOpportunityAisle.serialNumber')}
                    </label>
                    <IPInput
                      disabled={history}
                      type='text'
                      className='form-control'
                      name='ProductSerialNo'
                      onChange={handleChange}
                      value={values.ProductSerialNo}
                    />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-2'>
                    <label className='labeltext'>
                      {t('pages.admin.productOperation.opportunityAisleProduct.addOpportunityAisle.warehouseCode')}
                    </label>
                    <IPSelectBox
                      isDisabled={history}
                      isClearable
                      options={state.wareHouseList}
                      value={values.WarehouseCode}
                      onChangeSingle={option => {
                        setFieldValue('WarehouseCode', option)
                      }}
                    />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-2'>
                    <label className='labeltext'>
                      {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.company')}
                    </label>
                    <IPSelectBox
                      isClearable
                      isDisabled={history}
                      options={
                        companies &&
                        companies.map((item: ICompany) => ({
                          label: item.Name,
                          value: `${item.Id}`,
                        }))
                      }
                      value={values.CompanyId}
                      onChangeSingle={option => {
                        setFieldValue('CompanyId', option)
                      }}
                    />
                  </div>
                  <div className='form-group col-md-3'>
                    <label htmlFor='SapCodeList' className='labeltext'>
                      Kısa Açıklama
                    </label>
                    <IPInput
                      disabled={history}
                      type='text'
                      className='form-control'
                      name='ShortDescription'
                      onChange={handleChange}
                      value={values.ShortDescription}
                    />
                    <p style={{ color: 'red' }}>* Anasayfa ve listelemede gösterim içindir.</p>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-1'>
                    <label htmlFor='stockCode' className='labeltext'>
                      Sıra No
                    </label>
                    <IPInput
                      disabled={history}
                      type='text'
                      className='form-control'
                      name='ProductOrder'
                      onChange={handleChange}
                      value={values.ProductOrder}
                    />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-1'>
                    <label htmlFor='stockCode' className='labeltext'>
                      Fiyat
                    </label>
                    <IPInput
                      disabled={history}
                      type='text'
                      className='form-control'
                      name='Price'
                      onChange={e => {
                        handlePrice(e)
                      }
                      }
                      onBlur={e => handlePriceOnBlur(e)}
                      value={values.Price}
                    />
                  </div>
                  <div className='form-group col-md-1'>
                    <label htmlFor='stockCode' className='labeltext'>
                      {t('pages.admin.productOperation.opportunityAisleProduct.addOpportunityAisle.number')}
                    </label>
                    <IPInput
                      disabled={quantity || history}
                      type='text'
                      className='form-control'
                      name='Quantity'
                      onChange={handleChange}
                      value={values.Quantity}
                    />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-1'>
                    <label htmlFor='stockCode' className='labeltext'>
                      Satılan
                    </label>
                    <IPInput
                      type='text'
                      placeholder={values.QuantitySold}
                      className='form-control'
                      id='SahaDegeri'
                      disabled
                    />
                    <p className='validation-error'></p>
                  </div>
                  <div className='form-group col-md-2'>
                    <label htmlFor='stockCode' className='labeltext'>Başlangıç Tarihi</label>
                    <InputCampaign
                      disabled={history}
                      type='datetime-local'
                      id='StartDate'
                      name='StartDate'
                      label={''}
                      value={toDateTimeLocal(startDate)}
                      onChange={e => getDate(e.target.value, "Start")}
                      isActive
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <label htmlFor='stockCode' className='labeltext'>Bitiş Tarihi</label>
                    <InputCampaign
                      disabled={history}
                      type='datetime-local'
                      id='EndDate'
                      name='EndDate'
                      label={''}
                      value={toDateTimeLocal(endDate)}
                      onChange={e => getDate(e.target.value, "End")}
                      isActive
                    />
                  </div>
                  <div className='form-group row col-md-2' style={{ marginLeft: "10px" }}>
                    <div className='col-md-6'>
                      <label htmlFor='stockCode' className='labeltext'></label>
                      <label className='p-switch switch-color-success'>
                        <label className='floagit'>
                          {t('pages.admin.productOperation.opportunityAisleProduct.addOpportunityAisle.nightMarket')}
                        </label>
                        <IPCheckbox disabled={history} name='NightMarket' onChange={handleChange} checked={values.NightMarket} style={{ marginLeft: "10px", paddingLeft: "0px" }} />
                      </label>
                    </div>
                    <div className='col-md-6' >
                      <label htmlFor='stockCode' className='labeltext'></label>
                      <label className='p-switch switch-color-success'>
                        <label className='floagit'>
                          {t(
                            'pages.admin.productOperation.opportunityAisleProduct.addOpportunityAisle.outOfWarranty'
                          )}
                        </label>
                        <IPCheckbox disabled={history} name='OutOfWarranty' onChange={handleChange} checked={values.OutOfWarranty} style={{ marginLeft: "10px", paddingLeft: "0px" }} />
                      </label>
                    </div>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-6 leftContainer mb-5'>
                    <div>
                      <a className='campaignExcelBtn' href='/assetWeb/docs/dealer-codes-example.xlsx' target='_blank'>
                        Örnek Excel
                      </a>

                      <div className='listBtnGroup'>
                        <IPButton
                          className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariCode)}`}
                          text={`Cari Kodu Listesi ${dealerCodeValue && dealerCodeValue.length > 0 ? '✅' : ''}`}
                          onClick={() => setSelectedDealerList(CariListType.CariCode)}
                        />
                        <IPButton
                          className={`listCariBtn ${selectDealer(selectedDealerList, CariListType.CariAltType)}`}
                          text={`Cari Alt Tipi Listesi ${dealerSubTypeValue && dealerSubTypeValue.length > 0 ? '✅' : ''}`}
                          onClick={() => setSelectedDealerList(CariListType.CariAltType)}
                        />
                      </div>
                    </div>

                    <div className='p-0 m-0'>
                      <div className={`cariFilterTextAreaWrap ${selectDealer(selectedDealerList, CariListType.CariCode)}`}>
                        <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                          <IPFile
                            disabled={history}
                            id='FileInputDealerCode'
                            multiple={false}
                            handleFiles={e => handleFilesData(e, 'DealerCode')}
                            removeAfterSelect={true}
                            className='campaignFileInput'
                            label="Excel'den Yükle"
                          />
                        </div>
                        <CampaignSelect
                          placeholder={'Cari Kod Listesi'}
                          height={180}
                          //options={state.dealerCodeOptions}
                          onChangeMulti={options => {
                            if (!history)
                              setDealerCodeValue(options)
                          }}
                          value={dealerCodeValue}
                        />
                        <input
                          disabled={history}
                          type="text"
                          placeholder="Cari kodu yazın"
                          value={inputValue}
                          onChange={handleInputChange}
                          onKeyPress={handleInputKeyPress}
                        />
                      </div>

                      <div
                        className={`cariFilterTextAreaWrap ${selectDealer(selectedDealerList, CariListType.CariAltType)}`}
                      >
                        <div className='topBtnGroup d-flex justify-content-center align-items-center mb-5'>
                          <IPFile
                            disabled={history}
                            id='FileInputDealerSubType'
                            multiple={false}
                            handleFiles={e => handleFilesData(e, 'DealerSubType')}
                            removeAfterSelect={true}
                            className='campaignFileInput'
                            label="Excel'den Yükle"
                          />
                        </div>
                        <CampaignSelect
                          placeholder={'Cari Alt Tipi Listesi'}
                          height={180}
                          options={state.dealerSubTypeOptions}
                          onChangeMulti={options => {
                            if (!history)
                              setDealerSubTypeValue(options)
                          }}
                          value={dealerSubTypeValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='form-group col-md-6'>
                    <label className='labeltext'>
                      {t('pages.admin.productOperation.opportunityAisleProduct.addOpportunityAisle.description')}
                    </label>
                    <CKEditor
                      disabled={history}
                      editor={ClassicEditor}
                      data={values.Description}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setFieldValue('Description', data)
                      }}
                      onBlur={(event, editor) => { }}
                      onFocus={(event, editor) => { }}
                    />
                  </div>
                </div>
                <div className='form-row'></div>

                <div className='form-row'>
                  {documents &&
                    documents.length > 0 &&
                    documents.map(image => (
                      <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize' key={image.Id}>
                        <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                          <div className='room '>
                            <div className='room-image text-center '>
                              <IPImage src={image.FileUrl} alt='' className='RecentVisits' />
                            </div>
                          </div>
                          <div className='product-description '>
                            <div className='product-title text-left'></div>
                            <div className='product-title text-left'>Adı:{image.Name}</div>
                          </div>
                          <a
                            className='ml-2'
                            data-toggle='tooltip'
                            onClick={() => deleteDocument(image.Id)}
                            style={{
                              cursor: 'pointer',
                              color: 'red',
                              fontSize: '35px',
                            }}
                          >
                            <i className='icon-trash-2'></i>
                          </a>
                        </div>
                      </div>
                    ))}
                </div>
                <div className='etkin d-flex justify-content-end'>
                  <div className='col-md-2'>
                    <IPButton
                      style={{ display: history ? 'none' : '' }}
                      type='button'
                      onClick={() => editOpportunity()}
                      className='btn btn-primary'
                      text={values.flag}
                    />
                  </div>
                </div>
              </form>
              {
                (routeLocation.state as any)?.opportunityDetailData && !history &&
                (
                  <>
                    <div className='card-header'>
                      <span className='h4'>
                        {'Görsel Yükleme'}
                      </span>
                    </div>
                    <div className='card-body'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <div className='row'>
                            {
                              images?.map(a => (
                                <div className='your-recent-visits text-center col-lg-3' style={{ minHeight: '300px', height: '300px' }}>
                                  <Image key={a.Id} src={a.Image} alt='' className='RecentVisits' />
                                  <a
                                    className='ml-2'
                                    data-toggle='tooltip'
                                    onClick={() => deleteImage(a.Id)}
                                    style={{ cursor: 'pointer', color: 'red', fontSize: '25px', display: a.Id == null ? 'none' : '' }}
                                  >
                                    <i className='icon-trash-2' style={{ marginTop: '10px' }}></i>
                                  </a>
                                </div>

                              ))
                            }
                            <div className='your-recent-visits text-center col-lg-3' style={{ minHeight: '300px', height: '300px' }}>
                              <i
                                className='icon-plus'
                                style={{ fontSize: '150px', color: '#adb5bd' }}
                                onClick={() => inputEl.current.click()}
                              ></i>
                              <input
                                ref={inputEl}
                                type='file'
                                name='mobilImage'
                                className='form-control'
                                id='mobilImage'
                                style={{ display: 'none' }}
                                onChange={event => {
                                  handleImageFileUpload(event)
                                }}
                              />
                              <br />
                              <h5 style={{ color: '#adb5bd' }}>Görsel Yükle</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(AddOpportunityAisle)



