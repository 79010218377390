import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { OrderThreedsRequestModel, PaymentRequestModel, SaveOrderRequest, SaveOrderResponse } from '../models/models'

export class SaveOrderApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'order/createOrder',
      requesterInfo,
    })
  }

  public async saveOrder(request: PaymentRequestModel): Promise<any> {
    return await this.postAsync<any>(request)
  }

  public async saveOrder3D(request: PaymentRequestModel): Promise<any> {
    this.props.url = 'order/createOrder3D'
    return await this.postAsync<any>(request)
  }

  public async orderThreedsReturn(request: OrderThreedsRequestModel): Promise<any> {
    this.props.url = 'order/orderThreedsReturn'
    return await this.postAsync<any>(request)
  }
}
