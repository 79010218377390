import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { AgreementPdfModel, CreateSapResult, IApplicationRequest } from '../models/response/getApplicationRequestList'
import { DeleteFileRequest } from '../models/response/sellerApplicationRequestModel'

export class ApplicationRequestService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/applicationRequest/list',
      requesterInfo,
    })
  }

  public async getApplicationRequest(): Promise<IApplicationRequest[]> {
    return await this.getAsync<IApplicationRequest[]>()
  }

  public async filterApplicationRequest(request): Promise<IApplicationRequest[]> {
    this.props.url = `admin/applicationRequest/filter`
    return await this.postAsync<IApplicationRequest[]>(request)
  }

  public async changeStatus(request): Promise<IApplicationRequest> {
    this.props.url = `admin/applicationRequest/changeStatus`
    return await this.putAsync<IApplicationRequest>(undefined, request)
  }

  public async setDelete(request): Promise<any> {
    this.props.url = `admin/applicationRequest/setDelete`
    return await this.putAsync<number>(request)
  }

  public async deleteFile(request: DeleteFileRequest): Promise<any> {
    this.props.url = `admin/applicationRequest/deleteFile`;
    return await this.postAsync<any>(request)
  }

  public async createPdf(idList: number[]): Promise<AgreementPdfModel> {
    this.props.url = `admin/applicationRequest/createPdf`
    return await this.postAsync<AgreementPdfModel>(idList)
  }

  public async sendContract(idList: number[]): Promise<{ Id: number; MailSent: boolean }[]> {
    this.props.url = `admin/applicationRequest/sendContract`
    return await this.postAsync<{ Id: number; MailSent: boolean }[]>(idList)
  }

  public async createSapRecord(idList: number[]): Promise<CreateSapResult[]> {
    this.props.url = `admin/applicationRequest/sapKaydiAc`
    return await this.postAsync<CreateSapResult[]>(idList)
  }
}
