import React, { useState, useMemo, useEffect } from 'react'
import { translate } from '../../../../../base/localization'
import Translations from '../../../../../translations'
import { useCustomReducer } from '../../../../../base/customHooks'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import InputCampaign from '../../../../../components/InputCampaign'
import Table from '../../../../../components/Table'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { ICompany } from '../../../components/models/models'
import { getCompanyApi } from '../../../components/api'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import {
  IGetIceCatReportNotComingRequest,
  IIceCatReportCommitedProductCountRequest,
  IIceCatReportHaveNotComingResponse,
  IIceCatReportHaveNotUpdatedRequest,
  IIceCatReportMaterialNumberRequest,
  IIceCatReportMaterialNumberResponse,
  IIceCatReportNotComing,
  IIceCatReportStatusRequest,
  IIceCatReportStatusResponse,
} from '../models/models'
import { GetIceCatReportCommitedProductCountApi } from '../services/getIceCatReportCommitedProductCountApi'
import { formatDate, formatDateForCampaignInput } from '../../../../../base/utils/dataHelper'
import { GetIceCatReportStatusApi } from '../services/getIceCatReportStatusApi'
import { GetIceCatReportNotComingApi } from '../services/getIceCatReportNotComingApi'
import { GetIceCatReportMaterialNumberApi } from '../services/getIceCatReportMaterialNumberApi'
import { GetIceCatReportHaveNotUpdatedApi } from '../services/getIceCatReportHaveNotUpdatedApi'

interface IIceCatReportState {
  iceCatData: any[]
  iceCatReportStatusData: IIceCatReportStatusResponse[]
  iceCatReportStatusData2: IIceCatReportStatusResponse[]
  allTotal: number
  b2bTotal: number
  productCode: string
  productCode2: string
  company: ISelectOption | null
  iceCatReportMaterialNumber: IIceCatReportMaterialNumberResponse[]
  iceCatReportHaveNotUpdated: IIceCatReportHaveNotComingResponse[]
  first: boolean
  iceCatReportNotComing: IIceCatReportNotComing[]
}

function Index(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [companies, setCompanies] = useState<ICompany[]>([])
  const getInitialState = (): IIceCatReportState => {
    return {
      iceCatData: [],
      allTotal: 0,
      b2bTotal: 0,
      productCode: '',
      company: null,
      iceCatReportStatusData: [],
      iceCatReportStatusData2: [],
      iceCatReportMaterialNumber: [],
      iceCatReportHaveNotUpdated: [],
      first: false,
      iceCatReportNotComing: [],
      productCode2: ""
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IIceCatReportState>(intialState)

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    setCompanies(company)
  }

  const getIceCat = async () => {
    if (!state.company || !startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen şirket ve tarihleri seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    setState({ iceCatReportMaterialNumber: [], iceCatReportHaveNotUpdated: [], first: true })
    if (state.productCode && state.productCode.length > 0) {
      const req: IIceCatReportMaterialNumberRequest = {
        Company: state.company ? String(state.company.value) : '',
        Country: '',
        StartDate: formatDate(startDate),
        EndDate: formatDate(endDate),
        Status: '',
        MaterialNumber: state.productCode,
      }
      props.showLoading()
      const getIceCatReportMaterialNumberApi: GetIceCatReportMaterialNumberApi = new GetIceCatReportMaterialNumberApi(
        {}
      )
      await getIceCatReportMaterialNumberApi
        .getIceCatReportMaterialNumber(req)
        .then(result => {
          if (result) {
            setState({
              iceCatReportNotComing: [],
              iceCatReportHaveNotUpdated: [],
              iceCatReportStatusData: [],
              iceCatReportStatusData2: [],
              iceCatReportMaterialNumber: result,
              productCode2: state.productCode
            })
          }
          props.hideLoading()
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        })
      return
    }
    const reqAll: IIceCatReportCommitedProductCountRequest = {
      Company: state.company ? String(state.company.value) : '',
      Country: '',
      Code: 10,
    }
    props.showLoading()
    const getIceCatReportCommitedProductCountApi: GetIceCatReportCommitedProductCountApi =
      new GetIceCatReportCommitedProductCountApi({})
    await getIceCatReportCommitedProductCountApi
      .getIceCatReportCommitedProductCount(reqAll)
      .then(result => {
        if (result) {
          setState({ allTotal: result.Gonderilen })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    const reqB2B: IIceCatReportCommitedProductCountRequest = {
      Company: state.company ? String(state.company.value) : '',
      Country: '',
      Code: 30,
    }
    props.showLoading()
    await getIceCatReportCommitedProductCountApi
      .getIceCatReportCommitedProductCount(reqB2B)
      .then(result => {
        if (result) {
          setState({ b2bTotal: result.Gonderilen })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    const request: IIceCatReportStatusRequest = {
      Company: state.company ? String(state.company.value) : '',
      Country: '',
      StartDate: formatDate(startDate),
      EndDate: formatDate(endDate),
      Code: '10',
    }
    props.showLoading()
    await new GetIceCatReportStatusApi({})
      .getIceCatReportStatus(request)
      .then(result => {
        if (result) {
          setState({
            iceCatReportNotComing: [],
            iceCatReportHaveNotUpdated: [],
            iceCatReportMaterialNumber: [],
            iceCatReportStatusData: result,
            productCode2: ""
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    await new GetIceCatReportStatusApi({})
      .getIceCatReportStatus({ ...request, Code: '30' })
      .then(result => {
        if (result) {
          setState({
            iceCatReportNotComing: [],
            iceCatReportHaveNotUpdated: [],
            iceCatReportMaterialNumber: [],
            iceCatReportStatusData2: result,
            productCode2: ""
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }
  const getIceCatReportNotComing = async (type: "10" | "30") => {
    const reqs: IGetIceCatReportNotComingRequest = {
      Company: state.company ? String(state.company.value) : '',
      Country: '',
      StartDate: formatDate(startDate),
      EndDate: formatDate(endDate),
      Status: '-99',
      Code: type
    }
    props.showLoading()
    const getIceCatReportNotComingApi: GetIceCatReportNotComingApi = new GetIceCatReportNotComingApi({})
    await getIceCatReportNotComingApi
      .getIceCatReportNotComing(reqs)
      .then(result => {
        if (result) {
          setState({
            iceCatReportNotComing: result,
            iceCatReportHaveNotUpdated: [],
            iceCatReportStatusData: [],
            iceCatReportStatusData2: [],
            iceCatReportMaterialNumber: [],
            productCode2: "",
            allTotal: 0,
            b2bTotal: 0
          })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getIceCatReportCommitedProductCount = async (status: any, code: string) => {
    if (!state.company || !startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen şirket ve tarihleri seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: IIceCatReportHaveNotUpdatedRequest = {
      Company: state.company ? String(state.company.value) : '',
      Country: '',
      StartDate: formatDate(startDate),
      EndDate: formatDate(endDate),
      Status: status,
      Code: code
    }
    props.showLoading()
    const getIceCatReportHaveNotUpdatedApi: GetIceCatReportHaveNotUpdatedApi = new GetIceCatReportHaveNotUpdatedApi({})
    await getIceCatReportHaveNotUpdatedApi
      .getIceCatReportHaveNotUpdated(req)
      .then(result => {
        if (result) {
          props.hideLoading()
          if (result.length > 0) {
            return setState({
              iceCatReportNotComing: [],
              iceCatReportHaveNotUpdated: result,
              iceCatReportStatusData: [],
              iceCatReportStatusData2: [],
              iceCatReportMaterialNumber: [],
              productCode2: "",
              allTotal: 0,
              b2bTotal: 0
            })
          }
          Swal.fire({
            icon: 'error',
            title: 'Veri Bulunamadı.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }

      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
    return
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'İşlem Tarihi',
        accessor: 'col1',
      },
      {
        Header: 'Durum',
        accessor: 'col2',
      },
      {
        Header: 'Adet',
        accessor: 'col3',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.iceCatReportStatusData.map((item, index) => {
        const data = {
          col1: (
            <div
              onClick={() => {
                if (item.Status === -99)
                  return getIceCatReportNotComing("10")
                getIceCatReportCommitedProductCount(String(item.Status), "10")
              }}
              style={{ cursor: 'pointer' }}
            >
              {item.LOG_WHEN}
            </div>
          ),
          col2: (
            <div
              onClick={() => {
                if (item.Status === -99)
                  return getIceCatReportNotComing("10")
                getIceCatReportCommitedProductCount(String(item.Status), "10")
              }}
              style={{ cursor: 'pointer' }}
            >
              {item.VALUE}
            </div>
          ),
          col3: (
            <div
              onClick={() => {
                if (item.Status === -99)
                  return getIceCatReportNotComing("10")
                getIceCatReportCommitedProductCount(String(item.Status), "10")
              }}
              style={{ cursor: 'pointer' }}
            >
              {item.COUNT}
            </div>
          ),
        }
        return data
      }),
    [state.iceCatReportStatusData]
  )

  const iceCatData2 = useMemo(
    () =>
      state.iceCatReportStatusData2.map((item, index) => {
        const data = {
          col1: (
            <div
              onClick={() => {
                if (item.Status === -99)
                  return getIceCatReportNotComing("30")
                getIceCatReportCommitedProductCount(String(item.Status), "30")
              }}
              style={{ cursor: 'pointer' }}
            >
              {item.LOG_WHEN}
            </div>
          ),
          col2: (
            <div
              onClick={() => {
                if (item.Status === -99)
                  return getIceCatReportNotComing("30")
                getIceCatReportCommitedProductCount(String(item.Status), "30")
              }}
              style={{ cursor: 'pointer' }}
            >
              {item.VALUE}
            </div>
          ),
          col3: (
            <div
              onClick={() => {
                if (item.Status === -99)
                  return getIceCatReportNotComing("30")
                getIceCatReportCommitedProductCount(String(item.Status), "30")
              }}
              style={{ cursor: 'pointer' }}
            >
              {item.COUNT}
            </div>
          ),
        }
        return data
      }),
    [state.iceCatReportStatusData2]
  )

  const tableColumns2 = useMemo(
    () => [
      {
        Header: 'IceCat ID',
        accessor: 'col1',
      },
      {
        Header: 'XML URL',
        accessor: 'col2',
      },
      {
        Header: 'IceCat Güncelleme Tarihi',
        accessor: 'col3',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col4',
      },
      {
        Header: 'SAP Kodu',
        accessor: 'col5',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'col6',
      },
      {
        Header: 'İşlem Zamanı',
        accessor: 'col7',
      },
      {
        Header: 'Notlar',
        accessor: 'col8',
      },
      {
        Header: 'EAN',
        accessor: 'col9',
      },
    ],
    []
  )

  const tableColumnsMaterial = useMemo(
    () => [
      {
        Header: 'IceCat ID',
        accessor: 'col1',
      },
      {
        Header: 'XML URL',
        accessor: 'col2',
      },
      {
        Header: 'IceCat Güncelleme Tarihi',
        accessor: 'col3',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col4',
      },
      {
        Header: 'SAP Kodu',
        accessor: 'col5',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'col6',
      },
      {
        Header: 'İşlem Zamanı',
        accessor: 'col7',
      },
      {
        Header: 'Durum',
        accessor: 'col8',
      },
      {
        Header: 'Notlar',
        accessor: 'col9',
      },
      {
        Header: 'EAN',
        accessor: 'col10',
      },
    ],
    []
  )

  const tableData2 = useMemo(
    () =>
      state.iceCatReportMaterialNumber.map((item, index) => {
        const ean = item.EAN_UPC.split("|")
        let special = ""
        ean[0].length > 0 && ean.forEach((spec) => (
          special += `<div style="margin-bottom:10px">${spec}</div>`
        ))
        const data = {
          col1: item.PROD_ID,
          col2: item.PATH_TR,
          col3: new Date(item.TO_DATE_TIME_STAMP).toLocaleDateString(),
          col4: item.INV_CODE,
          col5: item.MATNR,
          col6: item.MAKTX,
          col7: new Date(item.TO_DATE_LOG_WHEN).toLocaleDateString(),
          col8: item.DEGER,
          col9: item.NOTES,
          col10: special.length > 0 && <button
            type='button'
            className={'btn btn-sm btn-secondary'}
            onClick={() => Swal.fire({
              icon: 'info',
              title: 'EAN',
              html: `<div>
                ${special}
         </div > `,
              showConfirmButton: true,
              allowOutsideClick: false,
            })}>Liste</button>
        }
        return data
      }),
    [state.iceCatReportMaterialNumber]
  )

  const tableData3 = useMemo(
    () =>
      state.iceCatReportHaveNotUpdated.map((item, index) => {
        const ean = item.EAN_UPC.split("|")
        let special = ""
        ean[0].length > 0 && ean.forEach((spec) => (
          special += `<div style="margin-bottom:10px">${spec}</div>`
        ))
        const data = {
          col1: item.PROD_ID,
          col2: item.PATH_TR,
          col3: new Date(item.TIME).toLocaleDateString(),
          col4: item.INV_CODE,
          col5: item.MATERIALNUMBER,
          col6: item.MAKTX,
          col7: new Date(item.LOG_DATE).toLocaleDateString(),
          col8: item.NOTES,
          col9: special.length > 0 && <button
            type='button'
            className={'btn btn-sm btn-secondary'}
            onClick={() => Swal.fire({
              icon: 'info',
              title: 'EAN',
              html: `<div>
                ${special}
         </div > `,
              showConfirmButton: true,
              allowOutsideClick: false,
            })}>Liste</button>
        }
        return data
      }),
    [state.iceCatReportHaveNotUpdated]
  )

  const tableColumns3 = useMemo(
    () => [
      {
        Header: 'Ürün Kodu',
        accessor: 'col1',
      },
      {
        Header: 'SAP Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'col3',
      }
    ],
    []
  )

  const tableData4 = useMemo(
    () =>
      state.iceCatReportNotComing.map((item) => {
        const data = {
          col1: item.IDNLF,
          col2: item.MATERIALNUMBER,
          col3: item.MAKTX,
        }
        return data
      }),
    [state.iceCatReportNotComing]
  )

  useEffect(() => {
    getCompanies()
  }, [])

  return (
    <div className='content col-lg-12 m-auto'>
      <div className='card'>
        <div className='card-header'>
          <span className='h4'>IceCat Raporu</span>
        </div>
        <div className='card-body'>
          <form className='formbanner'>
            <div className='row'>
              <div className='col-md-3 mb-3'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={'Başlangıç Tarihi'}
                  value={formatDateForCampaignInput(startDate)}
                  onChange={e => setStartDate(new Date(e.target.value))}
                  isActive
                />
              </div>
              <div className='col-md-3 mb-3'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={'Bitiş Tarihi'}
                  value={formatDateForCampaignInput(endDate)}
                  onChange={e => setEndDate(new Date(e.target.value))}
                  isActive
                />
              </div>
              <div className='col-md-2 mb-3'>
                <IPInput
                  type='text'
                  className='form-control'
                  id='SiparisKodu'
                  placeholder='Material Numarası'
                  value={state.productCode}
                  onChange={e => {
                    setState({ productCode: e.target.value })
                  }}
                />
              </div>
              <div className='col-md-2 mb-3'>
                <IPSelectBox
                  isClearable
                  options={
                    companies &&
                    companies
                      .filter((com: ICompany) => {
                        return com.Name !== 'Index Group'
                      })
                      .map((item: ICompany) => ({
                        label: item.Name,
                        value: `${item.Code}`,
                      }))
                  }
                  value={state.company}
                  placeholder='Şirket Seçiniz'
                  onChangeSingle={option => setState({ company: option })}
                />
              </div>
              <div className='col-md-2 mb-3'>
                <IPButton
                  type='button'
                  className='btn btn-success'
                  style={{ width: '25%' }}
                  onClick={getIceCat}
                  text={t('pages.admin.dealerAndOrderReport.handcraftedOrdersList.handcraftedOrdersList.filtre')}
                />
              </div>
            </div>
          </form>
          {state.iceCatReportMaterialNumber && state.productCode2.length > 0 ? (
            state.iceCatReportMaterialNumber.length > 0 ?
              <div className='row m-4'>
                <Table columns={tableColumnsMaterial} data={tableData2} currentPage={0} />
              </div>
              : <p style={{ color: "red" }}><b>{state.productCode2} material numaralı ürün IceCat e gönderilmiş cevap gelmemiş!</b></p>
          ) : state.iceCatReportHaveNotUpdated && state.iceCatReportHaveNotUpdated.length > 0 ? (
            <div className='row m-4'>
              <Table columns={tableColumns2} data={tableData3} currentPage={0} />
            </div>
          ) : state.iceCatReportNotComing && state.iceCatReportNotComing.length > 0 ? (
            <div className='row m-4'>
              <Table columns={tableColumns3} data={tableData4} currentPage={0} />
            </div>
          ) : (
            <div className='row m-4'>
              {state.allTotal > 0 && (
                <div className='col-md-6'>
                  <p style={{ color: 'red' }}>Hepsi için {state.allTotal} adet ürün gönderildi.</p>
                  <Table
                    textAlign={true}
                    columns={tableColumns}
                    data={tableData}
                    currentPage={0}
                    headerName='IceCat Listesi'
                    columnCount={3}
                  />
                </div>
              )}
              {state.b2bTotal > 0 && (
                <div className='col-md-6'>
                  <p style={{ color: 'red' }}>B2B için {state.b2bTotal} adet ürün gönderildi.</p>
                  <Table
                    textAlign={true}
                    columns={tableColumns}
                    data={iceCatData2}
                    currentPage={0}
                    headerName='IceCat Listesi'
                    columnCount={3}
                  />
                </div>
              )}
              {state.allTotal === 0 && state.b2bTotal === 0 && state.first && 'Veri Bulunamadı.'}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Index)
