import { IPostBrandRequest } from '../models/requestModels'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'

export class UpdateBrandApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/brand/updateBrand',
      requesterInfo,
    })
  }

  public async updateBrand(request: IPostBrandRequest): Promise<CommonPostResponse> {
    return await this.putAsync<CommonPostResponse>(undefined, request)
  }
}
