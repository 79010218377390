import { IAnnouncementList } from '../models/responseModels'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IAnnouncementFilteredRequest } from '../models/requestModels'

export class GetAnnouncementApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/openbox/GetFilteredAnnouncements',
      requesterInfo,
    })
  }

  public async getAnnouncement(request: IAnnouncementFilteredRequest): Promise<IAnnouncementList[]> {
    return await this.postAsync<IAnnouncementList[]>(request)
  }
}
