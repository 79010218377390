import React, { useEffect, useMemo, useState } from 'react'
import { IException } from '../../../../base/models'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { ISimpleRole, RoleUser } from './models'
import { ChangeUsersRoleApi } from './services/chageUsersRoleApi'
import { GetSecurityRoleApi } from './services/getSecurityRoleApi'
import { GetRoleUsersApi } from './services/getRoleUsersApi'
import { GetSimpleUsersApi } from './services/GetSimpleUsersApi'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import IPButton from '../../../../components/IPButton'
import IPSelectBox from '../../../../components/IPSelectBox'
import Swal from 'sweetalert2'
import { GetDealerSimpleUsersApi } from './services/GetDealerSimpleUsersApi'
import IPInput from '../../../../components/IPInput'
import Table from '../../../../components/Table'

function UserRoleDefinitions(props: BaseComponentWithContextProps) {
  const [allRoles, setAllRolles] = useState<ISelectOption[]>([])
  const [selectedRole, setSelectedRole] = useState<ISelectOption[]>([])
  const [allUsers, setAllUsers] = useState<RoleUser[]>([])
  const [selectedRoleUsers, setSelectedRoleUsers] = useState<RoleUser[]>([])
  const [roleBool, setRoleBool] = useState<boolean>(false)
  const [cariCode, setCariCode] = useState<string>("")
  const [usersOption, setUsersOption] = useState<ISelectOption | null>(null)

  useEffect(() => {
    GetAllRolles()
    // GetSimpleUsers()
  }, [])

  const GetRoleUsers = (roleId: number) => {
    const api: GetRoleUsersApi = new GetRoleUsersApi({})
    api
      .getRoleUsers(roleId)
      .then((res: RoleUser[]) => {
        if (res) {
          setSelectedRoleUsers(res)
          // setSelectedRoleUsers(
          //   res.map((user: RoleUser) => {
          //     return { value: user.Id, label: `${user.Name} (${user.AccountCode})` }
          //   })
          // )
        }
      })
      .catch((err: IException) => { })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'col1',
      },
      {
        Header: 'Cari Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Adı',
        accessor: 'col3',
      },
      {
        Header: 'Kullanıcı Adı',
        accessor: 'col4',
      },
      {
        Header: 'İşlemler',
        accessor: 'col5',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      selectedRoleUsers.map((item, index) => {
        const data = {
          col1: index + 1,
          col2: item.AccountCode.replace("0000", ""),
          col3: item.Name,
          col4: item.UserName,
          col5: (
            <a
              data-action-type='delete'
              role='button'
              style={{ cursor: 'pointer' }}
              data-toggle='tooltip'
              data-original-title="Sil"
              onClick={() => setSelectedRoleUsers(selectedRoleUsers.filter((select) => { return select.Id != item.Id }))}
            >
              <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
            </a>
          ),
        }
        return data
      }),
    [selectedRoleUsers]
  )

  const GetSimpleUsers = () => {
    if (!cariCode || cariCode.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen cari kodu yazınız',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    props.showLoading && props.showLoading()
    const data = cariCode.length > 5 ? cariCode.padStart(10, '0')
      : cariCode
    const api: GetDealerSimpleUsersApi = new GetDealerSimpleUsersApi({})
    api
      .getDealerSimpleUsers(data)
      .then((res: RoleUser[]) => {
        if (res) {
          setAllUsers(res)
          props.hideLoading && props.hideLoading()
        }
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const GetAllRolles = () => {
    const api: GetSecurityRoleApi = new GetSecurityRoleApi({})
    api
      .getSecuritRole()
      .then((res: ISimpleRole[]) => {
        if (res) {
          setAllRolles(
            res.map((role: ISimpleRole) => {
              return { value: role.IdSecurityRole, label: role.SecurityRoleName }
            })
          )
        }
      })
      .catch((err: IException) => { })
  }

  const ChangeUsersRole = () => {
    if (!selectedRole[0]) {
      return
    }

    props.showLoading && props.showLoading()

    const api: ChangeUsersRoleApi = new ChangeUsersRoleApi({})
    api
      .changeUsersRole({
        UserIdList: selectedRoleUsers.map(option => Number(option.Id)),
        RoleId: Number(selectedRole[0].value),
      })
      .then((res: any) => {
        props.hideLoading && props.hideLoading()
        Swal.fire({
          icon: 'success',
          title: 'Rol Değiştirme işlemi başarılı.',
          allowOutsideClick: true,
          backdrop: true,
        })
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()

        Swal.fire({
          icon: 'error',
          title: err.description,
          allowOutsideClick: true,
          backdrop: true,
        })
      })
  }

  const TableUserAdd = () => {
    if (!usersOption) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen kullanıcı seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const data = allUsers.find((all) => { return all.Id === usersOption.value })
    if (data) {
      setSelectedRoleUsers([...selectedRoleUsers, data])
      setUsersOption(null)
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Role Kullanıcı Tanımlama</span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-6 mt-5'>
                <IPSelectBox
                  value={selectedRole}
                  options={allRoles}
                  placeholder={'Rol Seçiniz'}
                  onChangeSingle={(option: ISelectOption) => {
                    setSelectedRole([option])
                    GetRoleUsers(Number(option.value))
                    setRoleBool(true)
                  }}
                />
              </div>
            </div>
            {
              roleBool &&
              <>
                <div className='row mt-5'>
                  <div className='form-group col-md-3'>
                    <IPInput
                      name='CariCode'
                      type='text'
                      value={cariCode}
                      onChange={e => {
                        setCariCode(e.target.value)
                      }}
                      placeholder='Cari Kodu'
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <IPButton text='Kullanıcı Getir' className='btn btn-danger' onClick={GetSimpleUsers} />
                  </div>
                  <div className='form-group col-md-3'>
                    <IPSelectBox
                      name='User'
                      placeholder={'Kullanıcı Seçiniz'}
                      onChangeSingle={(option) => {
                        setUsersOption(option)
                      }}
                      value={usersOption}
                      options={allUsers.map((user: RoleUser) => {
                        return { value: user.Id, label: `${user.UserName}(${user.Name})` }
                      })}
                    />
                  </div>
                  <div className='form-group col-md-2'>
                    <IPButton
                      text='Tabloya Ekle'
                      className='btn btn-primary'
                      onClick={TableUserAdd}
                    />
                  </div>
                </div>
                <div className='row mt-5'>
                  <Table columns={tableColumns} data={tableData} currentPage={0} />
                </div>

              </>
            }
            <div className='row justify-content-end'>
              <div className='col-3 mt-5'>
                <IPButton text='Güncelle' className='btn btn-success' onClick={ChangeUsersRole} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(UserRoleDefinitions)
