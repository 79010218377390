import React, { useEffect, useState } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { useCustomReducer } from '../../../../../base/customHooks'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../ProductOperation/IyzicoCampaign/InputCampaign'
import { toDateTimeLocal, uuidv4 } from '../../../../../base/utils/dataHelper'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPInput from '../../../../../components/IPInput'
import Swal from 'sweetalert2'
import _ from 'lodash'
import { GetBankListApi } from '../services/getBankListApi'
import { IInsatallments, IParamPosInsatallment, ProcedureInstalment } from '../models/models'
import { AddParamPosInsatallmentApi } from '../services/addParamPosInsatallmentApi'
import { UpdateParamPosInsatallmentApi } from '../services/updateParamPosInsatallmentApi'
import { DeleteByIdParamPosInsatallmentApi } from '../services/deleteByIdParamPosInsatallmentApi'

interface IParamposState {
  buttonName: string
  banks: ISelectOption[]
  bank?: ISelectOption
  guid: string
}

function Parampos(props: BaseComponentWithContextProps) {
  let navigate = useNavigate()
  const getInitialState = (): IParamposState => {
    return {
      buttonName: "Ekle",
      banks: [],
      guid: ''
    }
  }
  const t = translate(Translations)
  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<IParamposState>(initialState)
  const [installment, setInstallment] = useState<ProcedureInstalment[]>([])
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    addInstallmentField(0)
    getBanks()
  }, [])

  useEffect(() => {
    if (state.banks && state.banks.length > 0 && routeLocation.state && (routeLocation.state as any)?.installementData) {
      const data: IParamPosInsatallment = _.clone((routeLocation.state as any)?.installementData)
      const res: ProcedureInstalment[] = [];
      data.Insatallments.map((ins) => {
        const install = installmentAddNum.find((inst) => { return String(inst.value) === String(ins.Installment) })?.label;
        return res.push({
          Id: ins.Id,
          StateId: uuidv4(),
          InstalmentRate: {
            value: ins.Installment,
            label: install
          },
          TermDay: String(ins.InterestRate)
        })
      })
      setStartDate(data.StartDate)
      setEndDate(data.EndDate)
      setInstallment(res)
      setState({ bank: { value: data.Insatallments[0].BankId, label: data.Insatallments[0].BankName }, buttonName: 'Güncelle', guid: data.Guid })
    }
  }, [state.banks])

  const getBanks = async () => {
    try {
      const res = await new GetBankListApi({}).getBanks()
      if (res) {
        const banks = res.map(x => ({ label: x.BankName, value: x.BankID }))
        if (!banks) return setState({ banks: [] })
        setState({ banks })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }
  const installmentAddNum = [
    {
      value: '1',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['1']),
    },
    {
      value: '2',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['2']),
    },
    {
      value: '3',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['3']),
    },
    {
      value: '4',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['4']),
    },
    {
      value: '5',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['5']),
    },
    {
      value: '6',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['6']),
    },
    {
      value: '9',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['9']),
    },
    {
      value: '12',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['12']),
    },
    {
      value: '15',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['15']),
    },
    {
      value: '18',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['18']),
    },
    {
      value: '24',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['24']),
    },
  ]

  const handleInstalmentRate = (e, item) => {
    const tempInstallment = _.clone(installment)

    if (tempInstallment.length > 1) {
      if (tempInstallment.findIndex((temp) => { return String(temp.InstalmentRate?.value) === String(e.value) && temp.StateId !== item.StateId }) > -1) {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: 'Taksit daha önce seçilmiştir. Onun üzerinde güncelleme yapabilirsiniz.',
          showConfirmButton: true,
          allowOutsideClick: true,
        })
        return
      }
      ; (tempInstallment.find(desc => desc.StateId === item.StateId) as any).InstalmentRate = e
        ; (tempInstallment.find(desc => desc.StateId === item.StateId) as any).Add = false
    } else {
      tempInstallment[0].InstalmentRate = e
    }
    setInstallment(tempInstallment)
  }

  const handleQuantity = (e: any, item: any) => {
    const val = e.target.value
    handleTermDay(val.replace(',', '.'), item)
  }

  const handleTermDay = (e, item) => {
    const tempInstallment = _.clone(installment)
    if (tempInstallment.length > 1) {
      ; (tempInstallment.find(desc => desc.StateId === item.StateId) as any).TermDay = e
        ; (tempInstallment.find(desc => desc.StateId === item.StateId) as any).Add = false
    } else {
      tempInstallment[0].TermDay = e
    }
    setInstallment(tempInstallment)
  }

  const handleQuantityOnBlur = (e: any, item: any) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value)) || Number(e.target.value) < 0) {
      handleTermDay(0, item)
    } else {
      if (Number(e.target.value) < 0) {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: '0 dan küçük olmalıdır',
          showConfirmButton: true,
          allowOutsideClick: true,
        })
        handleTermDay(0, item)
        return
      }
      handleTermDay(e.target.value, item)
    }
  }
  const routeLocation = useLocation()
  const removeInstallmentItem = item => {
    Swal.fire({
      icon: 'question',
      title: 'Silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        if (routeLocation.state && (routeLocation.state as any)?.installementData) {
          props.showLoading();
          const deleteByIdParamPosInsatallmentApi: DeleteByIdParamPosInsatallmentApi = new DeleteByIdParamPosInsatallmentApi({})

          deleteByIdParamPosInsatallmentApi
            .deleteByIdParamPosInsatallment(item.Id)
            .then((res: any) => {
              props.hideLoading();
              Swal.fire({
                icon: 'success',
                title: res,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
            .catch(err => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              props.hideLoading();
            })
        }
        setInstallment(
          installment
            .filter(ins => {
              return ins.StateId !== item.StateId
            })
            .map(i => {
              return i
            })
        )
      }
    })
    return
  }

  const addInstallmentField = (id: number) => {
    const newInstallment = {
      StateId: uuidv4(),
      Id: 0,
      InstalmentRate: null,
      TermDay: ''
    }
    setInstallment([...installment, ...[newInstallment]])
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  const addParamPosInsatallment = async () => {
    let i = 0;
    if (!state.bank || !state.bank.value || !startDate || !endDate)
      i++;

    const install: IInsatallments[] = []
    if (i === 0) {
      installment.map((ins) => {
        if ((!ins.InstalmentRate || !ins.TermDay))
          return i++
        return install.push({
          Id: ins.Id,
          BankId: state.bank ? +state.bank.value : 0,
          BankName: state.bank ? state.bank.label : '',
          Installment: ins.InstalmentRate ? +ins.InstalmentRate.value : 0,
          InterestRate: + ins.TermDay
        })
      })
    }

    if (i > 0) {
      return Swal.fire({
        icon: 'error',
        title: 'Lütfen boş alan bırakmayınız',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
    if (state.guid.length > 0) {
      const req: IParamPosInsatallment = {
        Insatallments: install,
        StartDate: startDate,
        EndDate: endDate,
        Guid: state.guid
      }
      props.showLoading();
      const updateParamPosInsatallmentApi: UpdateParamPosInsatallmentApi = new UpdateParamPosInsatallmentApi({})

      updateParamPosInsatallmentApi
        .updateParamPosInsatallment(req)
        .then((res: any) => {
          props.hideLoading();
          Swal.fire({
            icon: 'success',
            title: res,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async result => {
            navigate('/admin/BankAndInstallementOperation/Parampos/ParamposList')
          })
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading();
        })
    } else {
      const req: IParamPosInsatallment = {
        Insatallments: install,
        StartDate: startDate,
        EndDate: endDate,
        Guid: ''
      }
      props.showLoading();
      const addParamPosInsatallmentApi: AddParamPosInsatallmentApi = new AddParamPosInsatallmentApi({})

      addParamPosInsatallmentApi
        .addParamPosInsatallment(req)
        .then((res: any) => {
          props.hideLoading();
          Swal.fire({
            icon: 'success',
            title: res,
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async result => {
            navigate('/admin/BankAndInstallementOperation/Parampos/ParamposList')
          })
        })
        .catch(err => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading();
        })
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <div className='col-12 col-md-9 mb-2'>
              <span className='h4'>Parampos Taksit {state.buttonName === 'Ekle' ? 'Ekleme' : 'Güncelleme'}</span>
            </div>
            <div className='col-12 col-md-2 mb-2'>
              <div className='buttonsuz'>
                <Link to='/admin/BankAndInstallementOperation/Parampos/ParamposList'>
                  <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                </Link>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form id='id1' className='formform' noValidate>
              <div className='form-row'>
                <div className='col-lg-4'>
                  <label className='labeltext'>Banka</label>
                  <IPSelectBox
                    isClearable
                    id='DealerContents'
                    name='DealerContents'
                    options={state.banks}
                    value={state.bank}
                    onChangeSingle={option => setState({ bank: option })}
                  />
                </div>
                <div className='col-lg-4'>
                  <label className='form-label'>Başlangıç Tarihi</label>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={''}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='col-lg-4'>
                  <label className='form-label'>Bitiş Tarihi</label>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={''}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
              </div>
              {installment.length > 0 &&
                installment.map((item, index) => {
                  return (
                    <div className='row m-t-5' key={item.StateId}>
                      <div className='col-3'>
                        <label className='labeltext'>Taksit Adedi</label>
                        <IPSelectBox
                          isClearable
                          name='InstalmentRate'
                          options={installmentAddNum}
                          value={item.InstalmentRate}
                          onChangeSingle={option => {
                            handleInstalmentRate(option, item)
                          }}
                        />
                      </div>
                      {/* <div className='col-2'> */}
                      <div className='col-3'>
                        <label className='labeltext'>Vade(max)</label>
                        <IPInput
                          name='TermDay'
                          type='text'
                          value={item.TermDay}
                          onChange={e => {
                            handleQuantity(e, item)
                          }}
                          onBlur={e => handleQuantityOnBlur(e, item)}
                        />
                      </div>
                      <div className='col-3'>
                        <label className='labeltext'></label>
                        <IPButton
                          onClick={e => {
                            removeInstallmentItem(item)
                          }}
                          type='button'
                          className='btn btn-danger m-t-5'
                          text='Sil'
                          disabled={installment.length < 2 ? true : false}
                        ></IPButton>
                      </div>
                      <div className='col-3'>
                        <label className='labeltext'></label>
                        <IPButton
                          onClick={e => {
                            addInstallmentField(index)
                          }}
                          type='button'
                          className='btn btn-success m-t-5'
                          text='Ekle'
                        ></IPButton>
                      </div>
                    </div>
                  )
                })}
              <div className='form-row mt-4' style={{ float: 'right' }}>
                <IPButton
                  text={state.buttonName}
                  type='button'
                  className='btn btn-success w-25 float-right'
                  onClick={() => addParamPosInsatallment()}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Parampos)