import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IProduct } from '../../ProductList/models/response/getProductListResponseModel'

export class GetOpportunitMarketApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'opportunitMarket',
      requesterInfo,
    })
  }

  public async getOpportunitMarket(productId: number): Promise<IProduct> {
    this.props.url = `${this.props.url}/${productId}`
    return await this.getAsync<IProduct>()
  }
}
