import React, { useEffect, useRef, useState } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPButton from '../../../../../components/IPButton'
import { useFormik } from 'formik'
import validationSchema from './Validation'
import _ from 'lodash'
import IPFile from '../../../../../components/IPFile'
import { getUploadFileRequest, handleImageUpload } from '../../../../../base/utils/dataHelper'
import { useCustomReducer } from '../../../../../base/customHooks'
import { ISwitch } from '../models/requestModels'
import Swal from 'sweetalert2'
import { UpdateBrandApi } from '../services/updateBrandApi'
import { AddBrandApi } from '../services/addBrandApi'
import { DeleteBrandApi } from '../services/deleteBrandApi'
import $, { map } from 'jquery'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { IBrand, ICompany } from '../../../components/models/models'
import { getBrandApi, getCompanyApi } from '../../../components/api'
import IPModal from '../../../../../components/IPModal'
import IPImage from '../../../../../components/IPImage'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
interface IListBrandState {
  buttonName: string
  fileBool: boolean
  data: ISwitch
}

function Index() {
  const inputEl = useRef<any>(null)
  const t = translate(Translations)
  const [brands, setBrands] = useState<IBrand[]>([])
  type formType = 'create' | 'update'
  const [flag, setFlag] = useState<formType>('create')
  const [tmpImage, setTmpImage] = useState('')
  const tableRef = useRef<any>()
  const [showBrandUpdate, setShowBrandUpdate] = useState<boolean>(false)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const initialValues = Object.assign({
    Id: 0,
    Name: '',
    Image: '',
    SapCode: '',
    File: {
      Label: '',
      Content: '',
      Extension: '',
      FileType: '',
    },
    CallCenter: '',
    Website: '',
    Email: '',
    BrandOrder: 0,
    Enable: false,
    IsHomePage: false,
    Companies: [],
  })

  const getInitialState = (): IListBrandState => {
    return {
      buttonName: 'Ekle',
      fileBool: false,
      data: {
        id: 0,
      },
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListBrandState>(intialState)

  useEffect(() => {
    getBrand()
  }, [])

  const handleBrandImageUpload = async (e: any) => {
    const fileList: any[] = await getUploadFileRequest(e)

    if (e && e.target && e.target.files && e.target.files[0]) {
      const base64Str = await handleImageUpload(e.target.files[0])
      setTmpImage(base64Str)
    }

    if (fileList && fileList.length > 0) {
      setFieldValue('File', fileList[0])
    }
    setState({ fileBool: true })
  }
  useEffect(() => {
    getCompanies()
  }, [])
  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    setCompanies(company)
  }

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: async () => { },
  })

  const editBrand = async () => {
    if (!values.BrandOrder || (values.BrandOrder && values.BrandOrder < 1)) {
      Swal.fire({
        icon: 'error',
        title: 'Sıra numarası 0 dan büyük olmalıdır',
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then((res: any) => { })
      return
    }
    let clnValues = _.clone(values)
    clnValues.Companies = clnValues.Companies
      ? (clnValues.Companies.filter((i: ISelectOption) => {
        return Number(i.value) != 0
      }).map((item: ISelectOption) => Number(item.value)) as any)
      : []
    if (state.fileBool) {
      const updateBrand: UpdateBrandApi = new UpdateBrandApi({})
      updateBrand
        .updateBrand(clnValues)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Marka Güncellendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then((res: any) => {
              window.location.reload()
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    } else {
      if (clnValues.IsHomePage) {
        if (!clnValues.Image || clnValues.Image.length === 0) {
          Swal.fire({
            icon: 'question',
            title:
              'Markayı anasayfada gösterebilmeniz için logo eklemeniz gerekiyor. Markayı anasayfada gösteremezsiniz. Logo Eklemek ister misiniz?',
            showConfirmButton: true,
            allowOutsideClick: false,
            showDenyButton: true,
            confirmButtonText: 'Evet',
            denyButtonText: 'Hayır',
          }).then(async result => {
            if (result.isConfirmed) return
            else {
              clnValues.IsHomePage = false
              const updateBrand: UpdateBrandApi = new UpdateBrandApi({})
              updateBrand
                .updateBrand(clnValues)
                .then(async result => {
                  if (result) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Marka Güncellendi',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                    }).then((res: any) => {
                      window.location.reload()
                    })
                  }
                })
                .catch((err: any) => {
                  Swal.fire({
                    icon: 'error',
                    title: err.description,
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                })
            }
          })
        } else {
          const updateBrand: UpdateBrandApi = new UpdateBrandApi({})
          updateBrand
            .updateBrand(clnValues)
            .then(async result => {
              if (result) {
                Swal.fire({
                  icon: 'success',
                  title: 'Marka Güncellendi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                }).then((res: any) => {
                  window.location.reload()
                })
              }
            })
            .catch((err: any) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        }
      } else {
        const updateBrand: UpdateBrandApi = new UpdateBrandApi({})
        updateBrand
          .updateBrand(clnValues)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Marka Güncellendi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then((res: any) => {
                window.location.reload()
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      }
    }
    // } else {
    //   if (!state.fileBool) {
    //     if (clnValues.IsHomePage) {
    //       Swal.fire({
    //         icon: "question",
    //         title:
    //           "Markayı anasayfada gösterebilmeniz için logo eklemeniz gerekiyor. Markayı anasayfada gösteremezsiniz. Logo Eklemek ister misiniz?",
    //         showConfirmButton: true,
    //         allowOutsideClick: false,
    //         showDenyButton: true,
    //         confirmButtonText: "Evet",
    //         denyButtonText: "Hayır",
    //       }).then(async (result) => {
    //         if (result.isConfirmed)
    //           return;
    //         clnValues.IsHomePage = false;
    //         const addBrand: AddBrandApi = new AddBrandApi({});
    //         const res = await addBrand.addBrand(clnValues);
    //         if (res) {
    //           Swal.fire({
    //             icon: "success",
    //             title: "Marka Eklendi",
    //             showConfirmButton: true,
    //             allowOutsideClick: false,
    //           }).then((res: any) => {
    //             resetForm();
    //             setTmpImage("");
    //             setBrands([...brands, res]);
    //             setState({ fileBool: false });
    //           });
    //         }
    //       });
    //     } else {
    //       const addBrand: AddBrandApi = new AddBrandApi({});
    //       const res = await addBrand.addBrand(clnValues);
    //       if (res) {
    //         Swal.fire({
    //           icon: "success",
    //           title: "Marka Eklendi",
    //           showConfirmButton: true,
    //           allowOutsideClick: false,
    //         }).then((res: any) => {
    //           resetForm();
    //           setTmpImage("");
    //           setBrands([...brands, res]);
    //           setState({ fileBool: false });
    //         });
    //       }
    //     }
    //   } else {
    //     const addBrand: AddBrandApi = new AddBrandApi({});
    //     const res = await addBrand.addBrand(values);
    //     if (res) {
    //       Swal.fire({
    //         icon: "success",
    //         title: "Marka Eklendi",
    //         showConfirmButton: true,
    //         allowOutsideClick: false,
    //       }).then((res: any) => {
    //         resetForm();
    //         setTmpImage("");
    //         setBrands([...brands, res]);
    //         setState({ fileBool: false });
    //       });
    //     }
    //   }
    // }
  }

  // const updateBrand = (data: any) => {
  //   setValues(data);
  //   setState({ buttonName: "Güncelle" });
  // };

  const deleteBrand = (data: any) => {
    Swal.fire({
      icon: 'question',
      title: 'Markayı silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteBrandApi = new DeleteBrandApi({})
        const isDeleted = await deleteBrandApi.deleteBrand(data)
        if (isDeleted) {
          Swal.fire({
            icon: 'success',
            title: 'Marka silindi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then((res: any) => {
            window.location.reload()
          })
        }
      }
    })
  }

  const updateSwitch = async data => {
    let clnValues = _.clone(data)
    clnValues.IsHomePage = !data.IsHomePage
    const api: UpdateBrandApi = new UpdateBrandApi({})
    api.updateBrand(clnValues).then(async result => {
      if (result) {
        Swal.fire({
          icon: 'success',
          title: 'Marka güncellendi',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
          .then((res: any) => {
            window.location.reload()
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      }
    })
  }

  const handleBrandActive = async (row: any, type: string) => {
    const data = row?.data()
    const id = data?.Id

    if (!data || !id) {
      return
    }
    let clnValues = _.clone(data)
    if (type === 'Enabled') {
      clnValues.Enable = !data.Enable
      const api: UpdateBrandApi = new UpdateBrandApi({})
      api
        .updateBrand(clnValues)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Marka güncellendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
              .then((res: any) => {
                return
              })
              .catch((err: any) => {
                Swal.fire({
                  icon: 'error',
                  title: err.description,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
              })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }
    if (type === 'HomePage') {
      if (data.Image && data.Image.length === 0) {
        if (!clnValues.IsHomePage) {
          Swal.fire({
            icon: 'error',
            title: 'Önce logo yüklemelisiniz',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
            .then((res: any) => {
              return
            })
            .catch((err: any) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        } else {
          clnValues.IsHomePage = !clnValues.IsHomePage
          const api: UpdateBrandApi = new UpdateBrandApi({})
          api
            .updateBrand(clnValues)
            .then(async result => {
              if (result) {
                Swal.fire({
                  icon: 'success',
                  title: 'Marka güncellendi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                  .then((res: any) => {
                    return
                  })
                  .catch((err: any) => {
                    Swal.fire({
                      icon: 'error',
                      title: err.description,
                      showConfirmButton: true,
                      allowOutsideClick: false,
                    })
                  })
              }
            })
            .catch((err: any) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        }
      } else {
        if (!data.Image) {
          if (!clnValues.IsHomePage) {
            Swal.fire({
              icon: 'error',
              title: 'Önce logo yüklemelisiniz',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
              .then((res: any) => {
                return
              })
              .catch((err: any) => {
                Swal.fire({
                  icon: 'error',
                  title: err.description,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
              })
          } else {
            clnValues.IsHomePage = !clnValues.IsHomePage
            const api: UpdateBrandApi = new UpdateBrandApi({})
            api
              .updateBrand(clnValues)
              .then(async result => {
                if (result) {
                  Swal.fire({
                    icon: 'success',
                    title: 'Marka güncellendi',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                    .then((res: any) => {
                      return
                    })
                    .catch((err: any) => {
                      Swal.fire({
                        icon: 'error',
                        title: err.description,
                        showConfirmButton: true,
                        allowOutsideClick: false,
                      })
                    })
                }
              })
              .catch((err: any) => {
                Swal.fire({
                  icon: 'error',
                  title: err.description,
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
              })
          }
        } else {
          clnValues.IsHomePage = !clnValues.IsHomePage
          const api: UpdateBrandApi = new UpdateBrandApi({})
          api
            .updateBrand(clnValues)
            .then(async result => {
              if (result) {
                Swal.fire({
                  icon: 'success',
                  title: 'Marka güncellendi',
                  showConfirmButton: true,
                  allowOutsideClick: false,
                })
                  .then((res: any) => {
                    return
                  })
                  .catch((err: any) => {
                    Swal.fire({
                      icon: 'error',
                      title: err.description,
                      showConfirmButton: true,
                      allowOutsideClick: false,
                    })
                  })
              }
            })
            .catch((err: any) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
        }
      }
    }
  }

  const getBrand = async () => {
    const data: any[] = await getBrandApi()
    let datas: any[] = data.filter(d => {
      return d.BrandOrder && d.BrandOrder > 0
    })
    datas = datas.sort(function (a, b) {
      return a.BrandOrder - b.BrandOrder
    })
    let dt: any[] = datas.filter(d => {
      return d.Image && d.Image.length > 0
    })
    datas
      .filter(dta => {
        return !dta.Image
      })
      .map(d => {
        return dt.push(d)
      })
    data
      .filter(dta => {
        return !dta.BrandOrder && dta.Image && dta.Image.length > 0
      })
      .map(d => {
        return dt.push(d)
      })
    data
      .filter(dta => {
        return !dta.BrandOrder
      })
      .map(d => {
        return dt.push(d)
      })
    setBrands(dt)
  }
  useEffect(() => {
    let dt: any
    if (brands && brands.length > 0) {
      dt = syncTable(brands)
      tableRef.current = dt
    }

    $('#datatb tbody').on('change', 'input', function (e) {
      const row = dt.row($(this).parents('tr'))
      const actionType: 'active' | 'ishomepage' = this.getAttribute('data-action-type')
      if (actionType === 'active') {
        handleBrandActive(row, 'Enabled')
      }
      if (actionType === 'ishomepage') {
        handleBrandActive(row, 'HomePage')
      }
    })

    $('#datatb tbody').on('click', 'a', function () {
      const row = dt.row($(this).parents('tr'))
      var data = row?.data()
      var datas = brands.find(auth => {
        return auth.Id === data.Id
      })

      if (!row || !data) {
        return
      }
      let clnValues = _.clone(datas)
      if (clnValues && clnValues.Companies && clnValues.Companies.length > 0) {
        const list: any[] = clnValues.Companies.filter(com => {
          return com != 0
        })
        const company: any[] = []
        companies
          .filter((item: ICompany) => list && list.indexOf(String(item.Id)) > -1)
          .map((com: ICompany) => {
            return company.push({ value: `${com.Id}`, label: com.Name })
          })
        clnValues.Companies = company
      }
      setShowBrandUpdate(true)
      setValues(clnValues)
    })
    return function () {
      dt && dt.destroy()
    }
  }, [brands])

  const syncTable = (datas: IBrand[]) => {
    const dt = $(tableRef.current).DataTable({
      pageLength: 10,
      data: datas.map((dt, index) => {
        let d: String[] = []
        dt.Companies &&
          companies &&
          companies.map(i => {
            dt.Companies.map((com, index) => {
              if (Number(com) === i.Id && index === 0) return d.push(i.Name)
            })
          })
        dt.Companies &&
          companies &&
          companies.map(i => {
            dt.Companies.map((com, index) => {
              if (Number(com) === i.Id && index > 0) return d.push(i.Name)
            })
          })
        var code = d.map(i => {
          return i
        })
        return {
          Index: index + 1,
          Id: dt.Id,
          Order: dt.BrandOrder ? dt.BrandOrder : '-',
          Enable: dt.Enable,
          IsHomePage: dt.IsHomePage,
          Image: dt.Image ? (dt.Image.length > 0 ? dt.ImageUrl : '') : '',
          Name: dt.Name,
          CallCenter: dt.CallCenter,
          WebSite: dt.Website ? dt.Website : '',
          Email: dt.Email,
          Sap: dt.SapCode,
          Company: code,
        }
      }),
      columns: [
        {
          title: '',
          data: 'Index',
          render: (data: any) => {
            return `  <div style="display=none"></div>`
          },
        },
        {
          title: 'Aktif',
          data: 'Enable',
          render: (data: any) => {
            if (data) {
              return `  <label class="p-switch p-switch-sm switch-color-success">
                                <input
                                  type="checkbox"
                                  name="switch"
                                  class="switch"
                                  checked
                                  data-action-type="active"
                                />
                                <span class="p-switch-style"></span>
                              </label>`
            } else {
              return `  <label class="p-switch p-switch-sm switch-color-success">
                                <input
                                  type="checkbox"
                                  name="switch"
                                  class="switch"
                                  data-action-type="active"
                                />
                                <span class="p-switch-style"></span>
                              </label>`
            }
          },
        },
        {
          title: 'Anasayfada Göster',
          data: 'IsHomePage',
          render: (data: any) => {
            if (data) {
              return `  <label class="p-switch p-switch-sm switch-color-success">
                                <input
                                  type="checkbox"
                                  name="switch"
                                  class="switch"
                                  checked
                                  data-action-type="ishomepage"
                                />
                                <span class="p-switch-style"></span>
                              </label>`
            } else {
              return `  <label class="p-switch p-switch-sm switch-color-success">
                                <input
                                  type="checkbox"
                                  name="switch"
                                  class="switch"
                                  data-action-type="ishomepage"
                                />
                                <span class="p-switch-style"></span>
                              </label>`
            }
          },
        },
        {
          title: 'Sıra',
          data: 'Order',
        },
        {
          title: 'Logo',
          data: 'Image',
          render: (data: any) => {
            if (data) {
              if (data.length > 0) {
                return `<img src="${data}" style="width:50px"/>`
              }
            }
            return ''
          },
        },
        {
          title: 'Adı',
          data: 'Name',
        },
        {
          title: 'Şirket',
          data: 'Company',
        },
        {
          title: 'Çağrı Merkezi',
          data: 'CallCenter',
        },
        {
          title: 'Web Sitesi',
          data: 'WebSite',
          render: (data: any) => {
            if (data.length === 0) return ''
            if (data.length <= 10) return data
            else {
              return `
              <div title="${data}">
                ${data.slice(0, 10) + ' ...'}
              </div>`
            }
          },
        },
        {
          title: 'Mail',
          data: 'Email',
        },
        {
          title: 'Sap Kodu',
          data: 'Sap',
        },
        {
          title: 'İşlemler',
          data: 'Id',
          render: (data: any) => {
            return `<a data-action-type="edit" role = "button" style = "cursor:pointer" data - toggle="tooltip" data - original - title={ t('pages.mySpecial.userUpdate.edit') } >
              <i class='icon-edit' style="font-size:1.2rem; color:green"></i></a>`
          },
        },
      ],
    } as any)
    return dt
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Marka Listeleme</span>
          </div>
          <div className='card-body'>
            {/* <div className="col-6">
            <div className="card">
              <div className="card-header">
                <span className="h4">
                  Marka Ekleme | Güncelleme
                </span>
              </div>
              <div className="card-body">
                <form id="id1" className="formform" noValidate>
                  <div className="col-lg-2">
                    <div className="buttonsuz">
                      <IPButton
                        type="button"
                        className="btn btn-primary w-25 mb-5"
                        text=" Formu Sıfırla"
                        onClick={() => {
                          setFlag("create");
                          setFieldValue("Name", "");
                          setFieldValue("CallCenter", "");
                          setFieldValue("SapCode", "");
                          setFieldValue("Website", "");
                          setFieldValue("Email", "");
                          setState({ buttonName: "Ekle" });
                          setTmpImage("");
                        }}
                      />
                    </div>
                  </div>
                </form>
                <div className="form-row" style={{ justifyContent: "center" }}>
                  <div className="form-group col-md-6">
                    <label className="p-switch switch-color-success">
                      <label className="float-left mr-4">
                        {t(
                          "pages.admin.homeAndSiteOperation.bannersCrud.addBanner.publish"
                        )}
                      </label>
                      <IPCheckbox
                        name="Enable"
                        onChange={handleChange}
                        checked={values.Enable}
                      />
                    </label>
                  </div>
                </div>
                <div className="form-group form-fg">
                  <div
                    className="form-row"
                    style={{ justifyContent: "center" }}
                  >
                    {!(values.userId && values.userId > 0) && (
                      <div className="form-group col-md-6">
                        <label htmlFor="Name" className="labeltext">
                          Adı
                        </label>
                        <IPInput
                          type="text"
                          className="form-control"
                          name="Name"
                          value={values.Name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className="form-row"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="form-group col-md-6">
                      <label htmlFor="CallCenter" className="labeltext">
                        Çağrı Merkezi
                      </label>
                      <IPInput
                        type="text"
                        className="form-control"
                        name="CallCenter"
                        value={values.CallCenter}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="form-group col-md-6">
                      <label htmlFor="ParentId" className="labeltext">
                        Website
                      </label>
                      <IPInput
                        type="text"
                        className="form-control"
                        name="Website"
                        value={values.Website}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="form-group col-md-6">
                      <label htmlFor="SapCode" className="labeltext">
                        Sap Kod
                      </label>
                      <IPInput
                        type="text"
                        className="form-control"
                        name="SapCode"
                        value={values.SapCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="form-group col-md-6">
                      <label htmlFor="EMail" className="labeltext">
                        E-Mail
                      </label>
                      <IPInput
                        type="text"
                        className="form-control"
                        name="EMail"
                        value={values.Email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="form-group col-md-6">
                      <label htmlFor="BrandOrder" className="labeltext">
                        Sıra Numarası
                      </label>
                      <IPInput
                        type="text"
                        className="form-control"
                        name="BrandOrder"
                        value={values.BrandOrder}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="form-group col-md-6">
                      <label className="p-switch switch-color-success">
                        <label className="float-left mr-4">
                          Anasayfada Göster
                        </label>
                        <IPCheckbox
                          name="IsHomePage"
                          onChange={handleChange}
                          checked={values.IsHomePage}
                        />
                      </label>
                    </div>
                  </div>
                  <div
                    className="form-row"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="form-group col-md-6">
                      <div className="form-group col-md-4">
                        <label className="labeltext">Logo Ekle</label>
                        {
                          <IPFile
                            type="file"
                            name="Image"
                            className="form-control"
                            id="customFile"
                            onChange={(event) => {
                              handleBrandImageUpload(event);
                            }}
                          />
                        }
                      </div>
                      <div className="form-group col-md-2">
                        {tmpImage ? (
                          <img
                            src={tmpImage}
                            style={{ maxWidth: "80px", maxHeight: "80px" }}
                          />
                        ) : values.Image ? (
                          <img
                            src={values.ImageUrl}
                            style={{ maxWidth: "80px", maxHeight: "80px" }}
                          />
                        ) : null}
                        <p className="validation-error">
                          {" "}
                          {errors.Image && touched.Image ? errors.Image : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-row" style={{ justifyContent: "center" }}>
                  <div className="form-group col-md-6">
                    <IPButton
                      className="btn-md btn-fg btn-block"
                      type="button"
                      text={state.buttonName}
                      onClick={(e) => {
                        editBrand();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            <table id={'datatb'} ref={tableRef} className='table-responsive'></table>
          </div>
        </div>
      </div>
      <IPModal
        show={showBrandUpdate}
        onClose={() => setShowBrandUpdate(false)}
        width={600}
        title='Marka Güncelleme'
        onConfirm={editBrand}
        confirmText='Güncelle'
      >
        <>
          <div className='form-row' style={{ justifyContent: 'end', marginRight: '10px' }}>
            <label className='p-switch switch-color-success'>
              <label className='float-left mr-4'>
                {t('pages.admin.homeAndSiteOperation.bannersCrud.addBanner.publish')}
              </label>
              <IPCheckbox name='Enable' onChange={handleChange} checked={values.Enable} />
            </label>
          </div>
          <div className='form-row'>
            {!(values.userId && values.userId > 0) && (
              <div className='form-group col-md-12'>
                <label htmlFor='Name' className='labeltext'>
                  Adı
                </label>
                <IPInput
                  type='text'
                  className='form-control'
                  name='Name'
                  value={values.Name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            )}
          </div>
          <div className='form-row'>
            <div className='form-group col-md-6'>
              <label htmlFor='CallCenter' className='labeltext'>
                Çağrı Merkezi
              </label>
              <IPInput
                type='text'
                className='form-control'
                name='CallCenter'
                value={values.CallCenter}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='form-group col-md-6'>
              <label htmlFor='ParentId' className='labeltext'>
                Website
              </label>
              <IPInput
                type='text'
                className='form-control'
                name='Website'
                value={values.Website}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-6'>
              <label htmlFor='SapCode' className='labeltext'>
                Sap Kod
              </label>
              <IPInput
                type='text'
                className='form-control'
                name='SapCode'
                value={values.SapCode}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='form-group col-md-6'>
              <label htmlFor='EMail' className='labeltext'>
                E-Mail
              </label>
              <IPInput
                type='text'
                className='form-control'
                name='EMail'
                value={values.Email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-12'>
              <label htmlFor='BrandOrder' className='labeltext'>
                Şirket
              </label>
              <IPSelectBox
                isMulti
                isClearable
                id='Companies'
                name='Companies'
                closeMenuOnSelect={false}
                onChangeMulti={(options: ISelectOption[]) => {
                  setFieldValue('Companies', options)
                }}
                options={
                  companies &&
                  companies.map((item: ICompany) => ({
                    label: item.Name,
                    value: `${item.Id}`,
                  }))
                }
                value={values.Companies}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-6'>
              <label htmlFor='BrandOrder' className='labeltext'>
                Sıra Numarası
              </label>
              <IPInput
                type='text'
                className='form-control'
                name='BrandOrder'
                value={values.BrandOrder}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
            <div className='form-group col-md-6' style={{ marginTop: '25px' }}>
              <label className='p-switch switch-color-success'>
                <label className='float-left mr-4'>Anasayfada Göster</label>
                <IPCheckbox name='IsHomePage' onChange={handleChange} checked={values.IsHomePage} />
              </label>
            </div>
          </div>
          {tmpImage && tmpImage.length > 0 ? (
            <div className='form-group col-md-6'>
              <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                <div className='room '>
                  <div className='room-image text-center '>
                    <IPImage src={tmpImage} alt='' className='RecentVisits' />
                  </div>
                </div>
                <a
                  className='ml-2'
                  data-toggle='tooltip'
                  onClick={() => setTmpImage('')}
                  style={{
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '35px',
                  }}
                >
                  <i className='icon-trash-2'></i>
                </a>
              </div>
            </div>
          ) : values.Image && values.Image.length > 0 ? (
            <div className='form-group col-md-6'>
              <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                <div className='room '>
                  <div className='room-image text-center '>
                    <IPImage src={values.ImageUrl} alt='' className='RecentVisits' />
                  </div>
                </div>
                <a
                  className='ml-2'
                  data-toggle='tooltip'
                  onClick={() => {
                    setFieldValue('Image', '')
                  }}
                  style={{
                    cursor: 'pointer',
                    color: 'red',
                    fontSize: '35px',
                  }}
                >
                  <i className='icon-trash-2'></i>
                </a>
              </div>
            </div>
          ) : (
            <div className='form-group col-md-6'>
              <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                <i
                  className='icon-plus'
                  style={{ fontSize: '150px', color: '#adb5bd' }}
                  onClick={() => inputEl.current.click()}
                ></i>
                <input
                  ref={inputEl}
                  type='file'
                  name='bannerImage'
                  className='form-control'
                  id='bannerImage'
                  style={{ display: 'none' }}
                  onInput={event => {
                    handleBrandImageUpload(event)
                  }}
                />
                <br />
                <h5 style={{ color: '#adb5bd' }}>Logo Ekle</h5>
              </div>
            </div>
          )}
        </>
      </IPModal>
    </div>
  )
}
export default Index
