import { useState } from 'react'
import { Link, NavigateFunction, useNavigate } from 'react-router-dom'

import { css } from '@emotion/css'
import Swal from 'sweetalert2'
import { translate } from '../../../base/localization'
import { splitStockForTruck, stockIndicator } from '../../../base/utils/dataHelper'
import AddToBasket from '../../../components/AddToBasket'
import IPImage from '../../../components/IPImage'
import IPInput from '../../../components/IPInput'
import IPPriceDisplay from '../../../components/IPPriceDisplay'
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks'
import { addItemToBasketRedux } from '../../../redux/slices/basket/basketSlice'
import {
  addToFavoriteProductListRedux,
  productIsOnFavoriteListRedux,
  removeFromFavoriteProductListRedux,
} from '../../../redux/slices/favoriteProduct/favoriteProductSlice'
import { selectedTheme } from '../../../redux/slices/theme/themeSlice'
import Translations from '../../../translations'
import { IBestSellersCampaing, IBestSellersData } from '../models/commonModels'
import { IProduct } from '../models/response/getProductListResponseModel'
import Star from './Icons/Star'

interface IProductCard2Props {
  forwardIQuotePage?(product: IProduct): void
  product: IProduct
  bestSellers?: IBestSellersData[]
  index?: number
  isSelected?: boolean
  onSelectProductForCompare?: () => void
  onAddListToBasketUI?: any
  handleTooltip?: (e: any, content: any) => void
  closeTooltip?: () => void
  isOpportunityMarket?: boolean
  isPromotionalProducts?: boolean
  isBestSellers?: boolean
  bestSellersCampaing?: IBestSellersCampaing[]
}

export default function ProductCard2(props: IProductCard2Props) {
  const t = translate(Translations)
  const [quantity, setQuantity] = useState(1)
  const theme = useAppSelector(selectedTheme)

  const dispatch = useAppDispatch()
  const isProductOnFavorites = useAppSelector(productIsOnFavoriteListRedux(props.product.Id))

  const animateIcon = e => {
    e.target.closest('svg').classList.add('clickAnimation')
    setTimeout(() => {
      e.target.closest('svg').classList.remove('clickAnimation')
    }, 300)
  }

  const handleQuantity = (e: any) => {
    props.onAddListToBasketUI &&
      props.onAddListToBasketUI(props.product, e.target.value, props.isOpportunityMarket, !props.isSelected)
    setQuantity(e.target.value)
  }

  const handleQuantityOnBlur = (e: any) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value))) {
      props.onAddListToBasketUI &&
        props.onAddListToBasketUI(props.product, 1, props.isOpportunityMarket, !props.isSelected)
      setQuantity(1)
    } else {
      props.onAddListToBasketUI &&
        props.onAddListToBasketUI(props.product, e.target.value, props.isOpportunityMarket, !props.isSelected)
      setQuantity(e.target.value)
    }
  }

  const navigate: NavigateFunction = useNavigate()
  const handleStar = (e: any, productId: number) => {
    if (isProductOnFavorites) {
      removeFavorite(productId)
    } else {
      addFavorite(productId)
    }

    animateIcon(e)
  }

  const addFavorite = (productId: number) => {
    dispatch(addToFavoriteProductListRedux(productId))
  }

  const removeFavorite = (productId: number) => {
    dispatch(removeFromFavoriteProductListRedux(productId))
  }

  const addToBasketOrCreateDemand = () => {
    dispatch(
      addItemToBasketRedux({
        ProductId: props.product.Id,
        Quantity: 1,
        IsOppurtunityStock: props.product.OpportunityId ? true : false,
        OpportunitiyStockId: props.product.OpportunityId ?? 0,
      })
    )

    Swal.fire({
      html: `<h5>${t('components.AddToBasket.productWasAddedToBasket')}</h5>`,
      confirmButtonText: `${t('components.AddToBasket.goToBasket')}`,
      confirmButtonColor: '#ec4c4c',
      cancelButtonText: `${t('components.AddToBasket.continueToShopping')}`,
      showCancelButton: true,
      timer: 4000,
      timerProgressBar: true,
    }).then(result => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        navigate('/mycard')
      } else if (result.isDenied) {
      }
    })
  }

  const campaignSVG = (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='18' height='18' fill='#fff'>
      <path d='M190.5 68.8L225.3 128H224 152c-22.1 0-40-17.9-40-40s17.9-40 40-40h2.2c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40H32c-17.7 0-32 14.3-32 32v64c0 17.7 14.3 32 32 32H480c17.7 0 32-14.3 32-32V160c0-17.7-14.3-32-32-32H438.4c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88h-2.2c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0H152C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40H288h-1.3l34.8-59.2C329.1 55.9 342.9 48 357.8 48H360c22.1 0 40 17.9 40 40zM32 288V464c0 26.5 21.5 48 48 48H224V288H32zM288 512H432c26.5 0 48-21.5 48-48V288H288V512z' />
    </svg>
  )

  const tooltipContent = (
    <>
      {props.product.ProductCampaigns &&
        props.product.ProductCampaigns.length > 0 &&
        props.product.ProductCampaigns.map(p => {
          if (p.ProductListCode.length > 0) {
            return (
              <div
                className={css`
                  background-color: #ffff;
                  color: #dc3545;
                  padding: 0.5rem 1rem;
                  border: 1px solid #ccc;
                  border-radius: 0.5rem;
                `}
              >
                {p.ProductListCode}
              </div>
            )
          }
          return null
        })}
    </>
  )

  return (
    <div className='productCard productCard2'>
      {props.isBestSellers && (
        <div className='col-md-12 text-center'>
          <b>
            {
              props.bestSellers?.find(camp => {
                return camp.Index === props.index
              })?.CategoryName
            }
          </b>
        </div>
      )}
      {/* <br /> */}
      {props.product.OnCampaign && (
        <div className='indicator topLeft'>
          <img src='/images/products/discount.svg' alt='' />
        </div>
      )}
      {props.isOpportunityMarket ? (
        <div className='indicator topLeft2'>
          <div className='hasTooltip'>
            {/* <a onClick={() => addToBasketOrCreateDemand()}> */}
            <IPImage src='/images/products/box-percent.png' alt='' />
            {/* <div className="otooltip">
                Fırsat Stok: {props.product.Stock && props.product?.Stock?.Opportunity ? stockIndicator(props.product?.Stock?.Opportunity) : "0"}
              </div>
            </a> */}
          </div>
        </div>
      ) : (
        props.product?.Stock?.Opportunity > 0 && (
          <div className='indicator topLeft2'>
            <div className='hasTooltip'>
              <Link to={`/opportunit/detail/${props.product.OpportunityId}`}>
                {/* <a onClick={() => addToBasketOrCreateDemand()}> */}
                <IPImage src='/images/products/box-percent.png' alt='' />
                <div className='otooltip'>
                  {/* {props.product.OpportunityPriceDisplay} */}
                  <IPPriceDisplay price={props.product.OpportunityPriceDisplay ?? ''} />
                  fiyatla FIRSAT PAZARI'ndan almak için tıklayınız...
                </div>
                {/* </a> */}
              </Link>
            </div>
          </div>
        )
      )}
      {/* props.product?.Stock?.OpportunityFlag > 0 ||
        (props.product?.Stock?.Opportunity > 0 && (
          <div className="indicator bottomLeft">
            {props.product?.Stock?.OpportunityFlag > 0 ? (
              <div className="hasTooltip">
                <IPImage
                  src="/images/phone-solid.svg"
                  alt=""
                  style={{ width: "15px" }}
                />
                <div className="otooltip">Fırsat Stoğu Rezerve Et</div>
              </div>
            ) : (
              props.product?.Stock?.Opportunity > 0 && (
                <div className="hasTooltip">
                  <a onClick={() => addToBasketOrCreateDemand()}>
                    <IPImage src="/images/products/box-percent.png" alt="" />
                    <div className="otooltip">
                      {props.product.OpportunityPriceDisplay} fiyatla FIRSAT PAZARI'ndan almak için tıklayınız...
                    </div>
                  </a>
                </div>
              )
            )}
          </div>
        )) */}

      {props.product.ProductCampaigns && props.product.ProductCampaigns.length > 0 && (
        <div
          className='indicator topRight'
          onMouseEnter={e => props.handleTooltip && props.handleTooltip(e, tooltipContent)}
          onMouseLeave={props.closeTooltip}
        >
          {campaignSVG}
        </div>
      )}
      {/* <div className="indicator bottomRight">Yeni</div> */}

      <input
        className='form-check-input check-box productCheckbox'
        type='checkbox'
        checked={props.isSelected}
        onClick={() => {
          props.onSelectProductForCompare && props.onSelectProductForCompare()
          props.onAddListToBasketUI &&
            props.onAddListToBasketUI(props.product, quantity, props.isOpportunityMarket, !props.isSelected)
        }}
      />

      <div className='productImage'>
        <Link
          to={
            props.isOpportunityMarket
              ? `/opportunit/detail/${props.product.OpportunityId}?from=productList`
              : `/product/detail/${props.product.Id}?from=productList`
          }
        >
          <img src={props.product.Image} alt='' />
        </Link>
      </div>

      <div className='productTextWrapper'>
        <Link
          to={
            props.isOpportunityMarket
              ? `/opportunit/detail/${props.product.OpportunityId}?from=productList`
              : `/product/detail/${props.product.Id}?from=productList`
          }
        >
          <div className='productName'>{props.product.Name}</div>
        </Link>
        <div className='productCode'>
          <span style={{ marginRight: 'auto', lineHeight: '13px' }}>Ürün Kodu: {props.product.ProductCode}</span>
          <span>
            {props.product?.WarrantyMonth > 0 && (
              <div className='circle'>
                <div className='hasTooltip d-flex align-items-center justify-content-center p-1'>
                  <img src='/images/products/quality2.png' alt='' />
                  <div className='otooltip'>Garanti Süresi: {props.product?.WarrantyMonth} ay</div>
                </div>
              </div>
            )}
          </span>
          {props.product.BrandDetail && props.product.BrandDetail.ImageUrl && (
            <div className='circle'>
              <img src={props.product.BrandDetail.ImageUrl} alt='' />
            </div>
          )}
          <div className='action'>
            <img
              src={props.product?.Company?.Id === 2 ? '/images/netex_logo.png' : props.product?.Company?.Image}
              alt=''
              className='seller'
            />
          </div>
          {props.product.Brand === 'HPE' && `${props.product.Company.Code}` === '2300' && (
            <>
              <img src='/images/HPE_logo.png' alt='' />
              <div className='iQuote' onClick={() => props.forwardIQuotePage!(props.product)}>
                iQuote
              </div>
            </>
          )}
        </div>
        {props.isOpportunityMarket && (
          <div className='productCode'>
            <span style={{ marginRight: 'auto', lineHeight: '13px' }}>Seri No: {props.product.OpportunitySeriNo}</span>
          </div>
        )}
        {props.isOpportunityMarket &&
          props.product.OpportunityShortDescription &&
          props.product.OpportunityShortDescription.length > 0 && (
            <div className='position-relative text-danger text-center'>{props.product.OpportunityShortDescription}</div>
          )}
      </div>

      <div className='productPrices'>
        {props.isOpportunityMarket ? (
          <div>
            <span className='priceTitle'>Fırsat Fiyatı:</span>
            <span className='price' style={{ color: theme.colors.price.special }}>
              {/* {props.product?.OpportunityPriceDisplay ?? ''} */}
              <IPPriceDisplay price={props.product?.OpportunityPriceDisplay ?? ''} />
            </span>
          </div>
        ) : (
          <div>
            <span className='priceTitle'>Size Özel:</span>
            {props.isPromotionalProducts ? (
              <span>
                <del className='price mr-1' style={{ color: theme.colors.price.normal }}>
                  {/* {props.product?.Prices?.Normal?.Price ?? ''} */}
                  <IPPriceDisplay price={props.product?.Prices?.Normal?.Price ?? ''} />
                </del>
                <span className='price' style={{ color: theme.colors.price.special }}>
                  {/* {props.product?.CampaignPrice?.CampaignPriceDisplay ?? ''} */}
                  <IPPriceDisplay price={props.product?.CampaignPrice?.CampaignPriceDisplay ?? ''} />
                </span>
              </span>
            ) : props.product?.SapCampaignNo ? (
              props.product?.SapCampaignNo.length > 0 ? (
                <span>
                  <span className='price' style={{ color: theme.colors.price.special }}>
                    {/* {props.product?.Prices?.Special?.Price ?? ''} */}
                    <IPPriceDisplay price={props.product?.Prices?.Special?.Price ?? ''} />
                  </span>
                  <del className='price mr-1' style={{ color: theme.colors.price.normal }}>
                    {/* {props.product?.Prices?.Normal?.Price ?? ''} */}
                    <IPPriceDisplay price={props.product?.Prices?.Normal?.Price ?? ''} />
                  </del>
                </span>
              ) : (
                <span className='price' style={{ color: theme.colors.price.special }}>
                  {/* {props.product?.Prices?.Special?.Price ?? ''} */}
                  <IPPriceDisplay price={props.product?.Prices?.Special?.Price ?? ''} />
                </span>
              )
            ) : (
              <span className='price' style={{ color: theme.colors.price.special }}>
                {/* {props.product?.Prices?.Special?.Price ?? ''} */}
                <IPPriceDisplay price={props.product?.Prices?.Special?.Price ?? ''} />
              </span>
            )}
          </div>
        )}
        {/* <div>
          <span className="priceTitle">Size Özel:</span>
          {props.product?.SapCampaignNo ? (
            props.product?.SapCampaignNo.length > 0 ? (
              <div>
                <span
                  className="price"
                  style={{ color: theme.colors.price.special }}
                >
                  {props.product?.Prices?.Special?.Price ?? ""}
                </span>
                <del
                  className="price"
                  style={{ color: theme.colors.price.normal }}
                >
                  {props.product?.Prices?.Normal?.Price ?? ""}
                </del>
              </div>
            ) : (
              <span
                className="price"
                style={{ color: theme.colors.price.special }}
              >
                {props.product?.Prices?.Special?.Price ?? ""}
              </span>
            )
          ) : (
            <span
              className="price"
              style={{ color: theme.colors.price.special }}
            >
              {props.product?.Prices?.Special?.Price ?? ""}
            </span>
          )}
        </div> */}
        {props.isOpportunityMarket ? (
          <div>
            <span className='priceTitle priceTitle--gray'>Bayi Fiyatı:</span>
            <del className='price mr-1' style={{ color: theme.colors.price.normal }}>
              {/* {props.product?.Prices?.Special?.Price ?? ''} */}
              <IPPriceDisplay price={props.product?.Prices?.Special?.Price ?? ''} />
            </del>
          </div>
        ) : (
          <div>
            <span className='priceTitle priceTitle--gray'>Liste Fiyatı:</span>
            <span
              className={`price ${true ? 'price-none-decoration' : ''}`}
              style={{ color: theme.colors.price.normal }}
            >
              {/* {props.product?.Prices?.Normal?.Price ?? ''} */}
              <IPPriceDisplay price={props.product?.Prices?.Normal?.Price ?? ''} />
            </span>
          </div>
        )}
        {/* <div>
          <span className="priceTitle priceTitle--gray">Liste Fiyatı:</span>
          <span
            className={`price ${true ? "price-none-decoration" : ""}`}
            style={{ color: theme.colors.price.normal }}
          >
            {props.product?.Prices?.Normal?.Price ?? ""}
          </span>
        </div> */}
        {!props.isOpportunityMarket && (
          <div>
            <span className='priceTitle priceTitle--gray'>TESK: </span>
            <span className='price' style={{ color: theme.colors.price.enduser }}>
              {/* {props.product?.Prices?.EndUser?.Price ?? ''} */}
              <IPPriceDisplay price={props.product?.Prices?.EndUser?.Price ?? ''} />
            </span>
          </div>
        )}
      </div>

      <div>
        <div className='stock'>
          Stok:
          {props.isOpportunityMarket
            ? props.product.OpportunityStock
              ? stockIndicator(props.product.OpportunityStock)
              : '0'
            : props.product.Stock && props.product.Stock.Stock
            ? stockIndicator(props.product.Stock.Stock)
            : '0'}
        </div>
        {props.product.StockForTruck && splitStockForTruck(props.product.StockForTruck).length > 0 && (
          <div className='hasTooltip'>
            <img src='/images/products/truck.svg' alt='' />
            <div className='otooltip'>
              {splitStockForTruck(props.product.StockForTruck).map(st => {
                return 'tarih : ' + st.date + ' stok : ' + st.stok
              })}
            </div>
          </div>
        )}
      </div>

      <div style={{ width: '136px' }}>
        <IPInput
          type='number'
          className='stockNumber'
          min={1}
          value={quantity}
          onChange={e => handleQuantity(e)}
          onBlur={e => handleQuantityOnBlur(e)}
        />
        <AddToBasket
          product={props.product}
          quantity={quantity}
          className='productButton2'
          isOpportunityMarket={props.isOpportunityMarket}
          disabled={props.product.Brand === 'HPE' && `${props.product.Company.Code}` === '2300'}
        />
      </div>

      <div className='actions'>
        <Star
          color='#C3C3C4'
          hover='red'
          isStarred={isProductOnFavorites}
          isFavoriteProduct={isProductOnFavorites}
          click={e => handleStar(e, props.product.Id)}
        />
      </div>
    </div>
  )
}
