import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { CommonPostResponse, ISSHContact } from '../models/response/postSSHContactResponseModel'

export class PostSSHContactApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'ssh/sendContactFormEmail',
      requesterInfo,
    })
  }

  public async postSSHContact(request: ISSHContact): Promise<CommonPostResponse> {
    return await this.postAsync<CommonPostResponse>(request)
  }
}
