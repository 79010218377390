import { useEffect, useState } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import { ProcedureDescription } from '../models/models'
import { useFormik } from 'formik'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { PutAuthorizedServicesProcedurApi } from '../services/putAuthorizedServicesApi'
import Swal from 'sweetalert2'
import { PostAuthorizedServicesProcedurApi } from '../services/postAuthorizedServicesProcedurApi'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPInput from '../../../../../components/IPInput'
import IPButton from '../../../../../components/IPButton'
import IPTextarea from '../../../../../components/IPTextarea'
import { getDateByTimezoneOffset, uuidv4 } from '../../../../../base/utils/dataHelper'
import { IBrand } from '../../../components/models/models'
import { getBrandApi } from '../../../components/api'
import InputCampaign from '../../../../../components/InputCampaign'

interface IAddAuthorizedProcedureState {
  brandList: IBrand[]
  authorizedServicesProcedur: any
  buttonName: string
}
function AddAuthorizedProcedure() {
  const navigate: any = useNavigate()
  const getInitialState = (): IAddAuthorizedProcedureState => {
    return {
      brandList: [],
      buttonName: 'Ekle',
      authorizedServicesProcedur: {},
    }
  }
  // const [dateRange, setDateRange] = useState<any>([new Date(), new Date()])
  // const [startDate, endDate] = dateRange
  const routeLocation = useLocation()
  const intialState = getInitialState()

  const [state, setState] = useCustomReducer<IAddAuthorizedProcedureState>(intialState)
  useEffect(() => {
    getBrand()
  }, [])

  const getBrand = async () => {
    const data: any[] = await getBrandApi()
    setState({ brandList: data })
  }
  const initialValues = Object.assign({
    Id: 0,
    Enable: false,
    BrandId: null,
    ProductGroup: '',
    OrderNo: 0,
    StartDate: Date,
    EndDate: Date,
    Description: [
      {
        Name: '',
        Detail: '',
      },
    ],
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: async () => { },
  })

  const [description, setDescription] = useState<ProcedureDescription[]>([
    {
      Id: uuidv4(),
      Name: '',
      Detail: '',
    },
  ])

  useEffect(() => {
    if (routeLocation.state) {
      const data = _.clone((routeLocation.state as any)?.authorizedServicesProcedurData)
      setState({ authorizedServicesProcedur: data, buttonName: 'Güncelle' })
      const clnData = data
      if (clnData) {
        // setDateRange([data.StartDate ? new Date(data.StartDate) : null, data.EndDate ? new Date(data.EndDate) : null])
        state.authorizedServicesProcedur.BrandId = state.brandList
          .filter(item => Number(item.Id) === Number(state.authorizedServicesProcedur.BrandId))
          .map(com => {
            return { value: com.Id, label: com.Name }
          })[0]

        setDescription(clnData.Description)

        setValues(state.authorizedServicesProcedur)
      } else {
        resetForm()
      }
    } else {
      resetForm()
    }
  }, [state.brandList])

  const addDescriptionField = () => {
    setDescription([
      ...description,
      {
        Id: uuidv4(),
        Name: '',
        Detail: '',
      },
    ])
  }

  useEffect(() => {
    setFieldValue('Description', description)
  }, [description])

  const editAddAuthorizedProcedure = async () => {
    let clnValues = _.clone(values)
    if (!clnValues.BrandId) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen marka seçiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    clnValues.BrandId = clnValues.BrandId ? Number(clnValues.BrandId.value) : 0
    clnValues.StartDate = getDateByTimezoneOffset(startDate) as any
    clnValues.EndDate = getDateByTimezoneOffset(endDate) as any

    if (state.buttonName === 'Güncelle') {
      const putAuthorizedServicesProcedur: PutAuthorizedServicesProcedurApi = new PutAuthorizedServicesProcedurApi({})
      putAuthorizedServicesProcedur
        .putAuthorizedServicesProcedur(clnValues)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Yetkili Servis Prosedürü Güncellendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then((res: any) => {
              navigate('/admin/BrandPageOperation/authorizedProcedure/listAuthorizedProcedure')
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    } else {
      const postAuthorizedServicesProcedur: PostAuthorizedServicesProcedurApi = new PostAuthorizedServicesProcedurApi(
        {}
      )

      postAuthorizedServicesProcedur
        .postAuthorizedServicesProcedur(clnValues)
        .then(async result => {
          if (result) {
            Swal.fire({
              icon: 'success',
              title: 'Yetkili Servis Prosedürü Eklendi',
              showConfirmButton: true,
              allowOutsideClick: false,
            }).then((res: any) => {
              navigate('/admin/BrandPageOperation/authorizedProcedure/listAuthorizedProcedure')
            })
          }
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }
  }

  const handleDescriptionName = (e, item) => {
    const { value } = e.target
    // const tempDescription = { ...description };
    const tempDescription = _.clone(description)
    if (description.length > 1) {
      ; (tempDescription.find(desc => desc.Id == item.Id) as any).Name = value
    } else {
      tempDescription[0].Name = value
    }
    setDescription(tempDescription)
  }

  const handleDescriptionDetail = (e, item) => {
    const { value } = e.target
    const tempDescription = _.clone(description)

    if (tempDescription.length > 1) {
      ; (tempDescription.find(desc => desc.Id == item.Id) as any).Detail = value
    } else {
      tempDescription[0].Detail = value
    }
    setDescription(tempDescription)
  }

  const removeDescriptionItem = item => {
    let tempDesc = _.clone(description)
    // tempDesc.filter(d => d.Id !== item.Id);

    if (tempDesc && tempDesc.length > 0) {
      tempDesc = tempDesc.filter((descItem: ProcedureDescription) =>
        [undefined, null, 0].indexOf(item.Id) === -1 ? descItem.Id !== item.Id : null
      )
      setDescription(tempDesc)
    }
  }
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        // hour: '2-digit',
        // minute: '2-digit',
        // second: '2-digit',
      })
      .replace(' ', 'T')
  }


  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Yetkili Servis Prosedürleri Marka Bazında</span>
          </div>
          <div className='card-body'>
            <form id='form1' className='form-validate' noValidate>
              <div className='col-lg-2'>
                <div className='buttonsuz'>
                  <Link to='/admin/BrandPageOperation/authorizedProcedure/listAuthorizedProcedure'>
                    <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                  </Link>
                </div>
              </div>
              <div className='row'>
                <div className='text-right'>
                  <label className='p-switch switch-color-success'>
                    <label htmlFor='active' className='float-left mr-4'>
                      Etkin
                    </label>
                    <IPCheckbox type='checkbox' name='Enable' onChange={handleChange} checked={values.Enable} />
                  </label>
                </div>
              </div>
              <div className='form-row'>
                <div className='col-lg-6'>
                  <label className='labeltext'>Marka</label>
                  <IPSelectBox
                    isClearable
                    id='BrandId'
                    name='BrandId'
                    options={
                      state.brandList &&
                      state.brandList.map(brand => {
                        return { label: brand.Name, value: brand.Id }
                      })
                    }
                    onChangeSingle={option => setFieldValue('BrandId', option)}
                    value={values.BrandId}
                  />
                </div>
                <div className='form-group col-md-6'>
                  <label className='labeltext'>Ürün Grubu</label>
                  <IPInput
                    type='text'
                    id='ProductGroup'
                    className='form-control'
                    name='ProductGroup'
                    value={values.ProductGroup}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='form-group col-md-6'>
                  <label className='labeltext'>Sıralama</label>
                  <div>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='OrderNo'
                      value={values.OrderNo}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>

                <div className='form-group col-md-3 align-self-end'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>
                <div className='form-group col-md-3 align-self-end'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>

                {/* <div className='form-group col-md-6'>
                  <label>Başlangıç Tarihi - Bitiş Tarihi</label>
                  <IPDatePicker
                    selectsRange={true as any}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={update => {
                      setDateRange(update)
                    }}
                  />*/}



              </div>
              <div className='row'>
                <div className='col-2 mt-5'>
                  <IPButton
                    onClick={e => {
                      addDescriptionField()
                    }}
                    type='button'
                    className='btn btn-success'
                    text='İçerik Ekle'
                  ></IPButton>
                </div>
              </div>
              {description.length > 0 &&
                description.map((item, index) => {
                  return (
                    <div className='row'>
                      <div className='col-5'>
                        <label className='labeltext'>Başlık</label>
                        <IPTextarea
                          // className="form-control"
                          name='Name'
                          value={item.Name}
                          onChange={e => {
                            handleDescriptionName(e, item)
                          }}
                        //onBlur={handleBlur}
                        />
                      </div>
                      <div className='col-5'>
                        <label className='labeltext'>İçerik</label>
                        <IPTextarea
                          name='Detail'
                          value={item.Detail}
                          onChange={e => {
                            //handleChange
                            handleDescriptionDetail(e, item)
                          }}
                        //onBlur={handleBlur}
                        />
                      </div>
                      <div className='col-2 mt-5'>
                        <IPButton
                          onClick={e => {
                            removeDescriptionItem(item)
                          }}
                          type='button'
                          className='btn btn-danger'
                          text='Sil'
                        ></IPButton>
                      </div>
                    </div>
                  )
                })}
              <div className='form-row'>
                <div className='d-flex'>
                  <div className='container'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                      }}
                      className='mt-5 mb-5'
                    >
                      <IPButton
                        onClick={e => editAddAuthorizedProcedure()}
                        type='button'
                        className='btn btn-success'
                        text={state.buttonName}
                      ></IPButton>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddAuthorizedProcedure
