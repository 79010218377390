import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { IProduct } from '../../../pages/ProductList/models/response/getProductListResponseModel'
import { RootState } from '../../app/store'
import { AddToFavouriteProductListApi, GetFavouriteProductListApi, RemoveFavoriteApi } from './favoriteProductService'

interface FavoriteProductsState {
  favoriteProducts: IProduct[]
  favoriteProductIdList: number[]
  status: 'idle' | 'loading' | 'failed'
}

const initialState: FavoriteProductsState = {
  favoriteProducts: [],
  favoriteProductIdList: [],
  status: 'idle',
}

export const getFavoriteProductListRedux = createAsyncThunk('favoriteProduct/getFavoriteProducts', async () => {
  const api: GetFavouriteProductListApi = new GetFavouriteProductListApi({})

  const favoriteProductList = await api.getFavouriteProductList();
  let lastProductList = favoriteProductList.map(product => ({
    ...product,
    Images: product.Images.sort((a, b) => Number(a.Description) - Number(b.Description))
  }));


  return lastProductList;
})

export const addToFavoriteProductListRedux = createAsyncThunk(
  'favoriteProduct/addToFavorite',
  async (productId: number) => {
    const api: AddToFavouriteProductListApi = new AddToFavouriteProductListApi({})

    return await api.addFavorite(productId)
  }
)

export const removeFromFavoriteProductListRedux = createAsyncThunk(
  'favoriteProduct/removeFromFavorite',
  async (productId: number) => {
    const api: RemoveFavoriteApi = new RemoveFavoriteApi({})

    return await api.removeFavorite(productId)
  }
)

export const favoritaProductSlice = createSlice({
  name: 'favoriteProduct',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getFavoriteProductListRedux.pending, state => {
        state.status = 'loading'
      })
      .addCase(getFavoriteProductListRedux.fulfilled, (state, action) => {
        state.status = 'idle'
        state.favoriteProductIdList = action.payload.map((prod: IProduct) => prod.Id)
        state.favoriteProducts = action.payload
      })
      .addCase(getFavoriteProductListRedux.rejected, (state, action) => {
        state.status = 'failed'
        state.favoriteProducts = []
      })
      .addCase(addToFavoriteProductListRedux.pending, state => {
        state.status = 'loading'
      })
      .addCase(addToFavoriteProductListRedux.fulfilled, (state, action) => {
        state.status = 'idle'
        state.favoriteProductIdList = action.payload
      })
      .addCase(addToFavoriteProductListRedux.rejected, (state, action) => {
        state.status = 'failed'
        state.favoriteProductIdList = []
      })
      .addCase(removeFromFavoriteProductListRedux.pending, state => {
        state.status = 'loading'
      })
      .addCase(removeFromFavoriteProductListRedux.fulfilled, (state, action) => {
        state.status = 'idle'
        state.favoriteProductIdList = action.payload
        state.favoriteProducts = state.favoriteProducts.filter((elem: IProduct) => elem.Id !== action.meta.arg)
      })
      .addCase(removeFromFavoriteProductListRedux.rejected, (state, action) => {
        state.status = 'failed'
        state.favoriteProductIdList = []
      })
  },
})

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const favoriteProductsRedux = (state: RootState) => state.favoriteProduct.favoriteProducts
export const showFavoriteProductAddingSpinner = (state: RootState) => state.favoriteProduct.status === 'loading'
export const hasAnyFavoriteProductsRedux = (state: RootState) =>
  state.favoriteProduct.favoriteProductIdList && state.favoriteProduct.favoriteProductIdList.length > 0
export const FavoriteProductsCountRedux = (state: RootState) =>
  state.favoriteProduct.favoriteProductIdList && state.favoriteProduct.favoriteProductIdList.length
export const getFavoriteProductIdsRedux = (state: RootState) => state.favoriteProduct.favoriteProductIdList
export const productIsOnFavoriteListRedux = (productId: number) => (state: RootState) =>
  state.favoriteProduct.favoriteProductIdList && state.favoriteProduct.favoriteProductIdList.indexOf(productId) > -1

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default favoritaProductSlice.reducer
