import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IFaultTracckingRequest } from '../models/models'

export class ExcelToFaultTrackingApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'excel/faultTracking',
      requesterInfo,
    })
  }

  public async excelToFaultTracking(request: IFaultTracckingRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
