import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { translate } from '../../base/localization'
import { IException } from '../../base/models'
import { authenticate } from '../../base/proxy/authenticate'
import Button from '../../components/IPButton'
import Image from '../../components/IPImage'
import IPInput from '../../components/IPInput'
import Modal from '../../components/IPModal/IPModal'
import { useAppDispatch } from '../../redux/app/hooks'
import { getFreshBasketRedux } from '../../redux/slices/basket/basketSlice'
import { setUser } from '../../redux/slices/user/userSlice'
import Translations from '../../translations'
import { ForgetPasswordApi } from '../Loginpage/services/forgetPasswordApi'
import { IForgetPasswordRequest } from './models/models'
import './styles.scss'
import validationSchema from './validation'
import { ClientStorage } from '../../base/storage'

const data = [
  {
    id: 1,
    src: '/images/ındex_logo.png',
    alt: 'Index Logo',
  },
  {
    id: 2,
    src: '/images/datagate_logo.png',
    alt: 'Datagate Logo',
  },
  {
    id: 3,
    src: '/images/despec_logo.png',
    alt: 'Despec Logo',
  },
  {
    id: 4,
    src: '/images/netex_logo.png',
    alt: 'Netex Logo',
  },
]

export default function Form(props: any) {
  const [showAddressModal, setShowAddressModal] = useState(false)
  const [showOnlineTrackingModal, setshowOnlineTrackingModal] = useState(false)
  const t = translate(Translations)
  const dispatch = useAppDispatch()
  const [rememberMe, setRememberMe] = useState(false)
  let navigate = useNavigate()

  // Function to handle remember me feature
  useEffect(() => {
    const storedCredentials = localStorage.getItem('rememberedCredentials')
    if (storedCredentials) {
      const { taxCode, userName } = JSON.parse(storedCredentials)
      setValues({ ...values, taxCode, userName })
      setRememberMe(true)
    }
  }, [])

  const { handleSubmit, handleChange, handleBlur, values, errors, touched, setValues } = useFormik({
    initialValues: {
      taxCode: '',
      userName: '',
      password: '',
      accountCode: '',
      email: '',
    },
    onSubmit: async values => {
      try {
        props.showLoading && props.showLoading()
        let code = values.taxCode
        if (values.taxCode.length >= 6) code = values.taxCode.padStart(10, '0')
        let result = await authenticate(code, values.userName, values.password)

        props.hideLoading && props.hideLoading()

        if (result) {
          dispatch(getFreshBasketRedux())
          dispatch(setUser())
          // dispatch(changeTheme(getThemeFromStorage()));
          if (props?.user?.IsApple || ClientStorage.getItem('IsApple') === 1)
            navigate('/AppleNetChannel?page=report', { state: { fromLogin: true } })
          else
            navigate('/homepage', { state: { fromLogin: true } })

          if (rememberMe) {
            localStorage.setItem(
              'rememberedCredentials',
              JSON.stringify({ taxCode: values.taxCode, userName: values.userName })
            )
          } else {
            localStorage.removeItem('rememberedCredentials')
          }
        }
      } catch (error: any) {
        if (error.response) {
          const status = error.response.status

          switch (status) {
            case 401:
              Swal.fire({
                icon: 'error',
                title: 'Oturum Süreniz Dolmuştur!',
                text: 'Lütfen tekrar giriş yapın.',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              break

            case 403:
              Swal.fire({
                icon: 'error',
                title: 'Yetkisiz İşlem!',
                text: 'Bu işlemi gerçekleştirmek için yetkiniz bulunmamaktadır.',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              break

            case 404:
              Swal.fire({
                icon: 'error',
                title: 'Kaynak Bulunamadı!',
                text: 'İlgili kaynak bulunamadı.',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              break

            // Add more cases as needed for different HTTP status codes

            default:
              Swal.fire({
                icon: 'error',
                title: 'Bir Hata Oluştu!',
                text: 'Lütfen daha sonra tekrar deneyin.',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              // You can log the detailed error for further investigation
              console.error('Detailed error:', error)
              break
          }
        } else {
          // Handle non-HTTP errors
          Swal.fire({
            icon: 'error',
            title: 'Bir Hata Oluştu!',
            text: 'Lütfen daha sonra tekrar deneyin.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          console.error('Detailed error:', error)
        }
      }
    },
    validationSchema,
  })

  const handleRememberMe = () => {
    setRememberMe(!rememberMe)
    if (!rememberMe) {
      localStorage.setItem(
        'rememberedCredentials',
        JSON.stringify({ taxCode: values.taxCode, userName: values.userName })
      )
    } else {
      localStorage.removeItem('rememberedCredentials')
    }
  }

  const forgetPassword = async () => {
    setShowAddressModal(false)
    props.showLoading && props.showLoading()
    let code = values.accountCode
    if (values.accountCode.length > 5) code = values.accountCode.padStart(10, '0')
    const forgetPasswordApi: ForgetPasswordApi = new ForgetPasswordApi({})
    const forgetPasswordModel: IForgetPasswordRequest = {
      AccountCode: code,
      Email: values.email,
    }

    forgetPasswordApi
      .forgetPassword(forgetPasswordModel)
      .then((res: boolean) => {
        props.hideLoading && props.hideLoading()

        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Mail adresinize sıfırlama linki gönderilmiştir.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()

        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }
  const [trackingCode, setTrackingCode] = useState('')

  return (
    <React.Fragment>
      <div className='login-section'>
        <div className='logo clearfix'>
          <Link to='/login'>
            <Image
              style={{ height: '80px' }}
              className='form_logo'
              alt='Index Pazar Logo'
              src='/images/indexPazarLogo.png'
            />
          </Link>
          <Link to='/login' style={{ borderLeftStyle: 'outset', borderLeftColor: '#ccc' }}>
            <Image
              style={{ height: '80px' }}
              className='form_logo'
              alt='35. Yıl Logo'
              src='/images/icons/35.yıl_logo.png'
            />
          </Link>
        </div>
        <h2 className='mt-5'>
          {t('pages.loginPage.form.indexGroup')}{' '}
          <span className='red-text'>&nbsp;{t('pages.loginPage.form.welcome')}</span>
        </h2>

        <div className='form-container'>
          <form action='#' method='GET' id='form1' className='form-validate' noValidate onSubmit={handleSubmit}>
            <div className='form-group form-fg'>
              <input
                name='taxCode'
                value={values.taxCode}
                onChange={handleChange}
                onBlur={handleBlur}
                type='text'
                className='form-control'
                placeholder={t('pages.loginPage.form.accountCodeTaxNumber')}
                required
              />
              <i className='far fa-address-book'></i>
            </div>
            {/* {errors.taxCode && touched.taxCode && (
            <div className="error">{errors.taxCode}</div>
          )} */}
            <div className='form-group form-fg'>
              <input
                name='userName'
                value={values.userName}
                onChange={handleChange}
                onBlur={handleBlur}
                type='text'
                className='form-control'
                placeholder={t('pages.loginPage.form.userName')}
              />
              <i className='far fa-user'></i>
            </div>
            {/* {errors.userName && touched.userName && (
            <div className="error">{errors.userName}</div>
          )} */}
            <div className='form-group form-fg'>
              <div className='input-group show-hide-password'>
                <input
                  name='password'
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className='form-control '
                  placeholder={t('pages.loginPage.form.password')}
                  type='password'
                  required
                />
                <i className='icon-lock'> </i>
                <div className='input-group-append'>
                  <span className='input-group-text'>
                    <i className='icon-eye-off' aria-hidden='true'></i>
                  </span>
                </div>
              </div>
              {/* {errors.password && touched.password && (
              <div className="error">{errors.password}</div>
            )} */}
            </div>
            <div
              className='form-group form-fg'
              style={{ display: 'flex', justifyContent: 'space-between', whiteSpace: 'nowrap' }}
            >
              <div className='input-group' style={{ justifyContent: 'space-between' }}>
                <div className=''>
                  <label
                    htmlFor='rememberMe'
                    style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginLeft: '3%' }}
                  >
                    <input
                      id='rememberMe'
                      type='checkbox'
                      checked={rememberMe}
                      onChange={handleRememberMe}
                      style={{ marginRight: '0.5rem' }}
                    />
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: '500',
                      }}
                    >
                      {t('pages.loginPage.form.rememberMe')}
                    </span>
                  </label>
                </div>

                <div className='' style={{ marginRight: '3%' }}>
                  <a
                    onClick={() => setShowAddressModal(true)}
                    style={{ cursor: 'pointer', float: 'inline-end', width: '110px' }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: '500',
                      }}
                    >
                      {t('pages.loginPage.form.forgotMyPassword')}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className='form-group form-fg' style={{ display: 'flex', justifyContent: 'center' }}>
              {/* <div className='col-lg-5'>
                <a onClick={() => setShowAddressModal(true)} style={{ cursor: 'pointer', float: 'inline-start' }}>
                  {t('pages.loginPage.form.forgotMyPassword')}
                </a>
              </div> */}
              <div className='col-lg-5'>
                <Button className='btn-md btn-fg btn-block' type='submit' text={t('pages.loginPage.form.login')} />
              </div>
            </div>
          </form>
        </div>

        <h6 className='footer-bottom-title'>{t('pages.loginPage.form.howDoiBecomeaMember')} </h6>
        <p className='footer-bottom-text text-center'>
          {' '}
          {t('pages.loginPage.form.dealershipApplicationProcess')}
          <Link to='/register'>
            <em>"{t('pages.loginPage.form.onlineApplicationForm')}"</em>
          </Link>{' '}
          {t('pages.loginPage.form.fillIn')}{' '}
        </p>
        <div className='row footer-bottom-iconText'>
          <div className='col-12 col-sm-6 text-center text-sm-left mt-4'>
            <Link to='/register'>
              <i className='fas fa-desktop fa-fw'></i>&nbsp;
              {t('pages.loginPage.form.onlineApplicationForm')}
            </Link>
          </div>
          <div className='col-12 col-sm-6 text-center text-sm-right mt-4'>
            <a style={{ cursor: 'pointer' }} onClick={() => setshowOnlineTrackingModal(true)}>
              <i className='fas fa-search fa-fw'></i>&nbsp;
              {t('pages.loginPage.form.onlineApplicationTracking')}
            </a>
          </div>
        </div>
        <br></br>

        <div className='row company-logos' style={{ position: 'absolute' }}>
          <div className='mb-3'>
            <h6 className='footer-bottom-title'>Mobil Uygulamalar</h6>
            <div className='col d-flex footer-bottom-iconText'>
              <div className='col-6'>
                <Link
                  to='https://play.google.com/store/apps/details?id=com.tr.indexgruppazar'
                  style={{ placeContent: 'end' }}
                >
                  <Image src={'images/playstoreigp.png'} alt={''} style={{ width: '40%' }} />
                </Link>
              </div>
              <div className='col-6'>
                <Link
                  to='https://apps.apple.com/sa/app/index-grup-pazar/id1661776079'
                  style={{ placeContent: 'start' }}
                >
                  <Image src={'images/appstoreigp.png'} alt={''} style={{ width: '40%' }} />
                </Link>
              </div>
            </div>
          </div>
          {data.map(img => (
            <div className='col-3' key={img.id}>
              <a className='btn btn-transparent'>
                {' '}
                <Image className='logo' alt={img.alt} src={img.src} />
              </a>
            </div>
          ))}
        </div>
      </div>
      <Modal
        title={t('pages.loginPage.form.forgotMyPassword')}
        show={showAddressModal}
        onClose={() => setShowAddressModal(false)}
        onConfirm={forgetPassword}
        confirmText={t('pages.loginPage.form.send')}
        width={400}
      //onConfirm={addAddress}
      >
        <>
          <div className='form-row'>
            <div className='form-group align-items-center row col-12'>
              <label className='col-form-label col-lg-12 text-sm-left'>
                {t('pages.loginPage.form.accountCode')} / Vergi No
              </label>
              <br />
              <div className='col-lg-12'>
                <IPInput
                  type='text'
                  className='form-control'
                  name='accountCode'
                  value={values.accountCode}
                  required
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.accountCode}
                  errors={errors.accountCode}
                />
              </div>
            </div>
            <div className='form-group align-items-center row col-12'>
              <label className='col-form-label col-lg-12 text-sm-left text-sm-left'>
                {t('pages.loginPage.form.email')}
              </label>
              <div className='col-lg-12'>
                <IPInput
                  type='text'
                  className='form-control'
                  name='email'
                  required
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.email}
                  errors={errors.email}
                />
              </div>
            </div>
          </div>
        </>
      </Modal>
      <Modal
        title={t('pages.loginPage.form.applicationStatus')}
        show={showOnlineTrackingModal}
        onClose={() => setshowOnlineTrackingModal(false)}
        confirmText={t('pages.loginPage.form.inquire')}
        onConfirm={() => navigate('/register', { state: { trackingCode } })}
        maxWidth={325}
      >
        <>
          <div className='form-row'>
            <div className='form-group align-items-center row col-12'>
              <label className='col-form-label col-lg-12 text-sm-left'>
                {t('pages.loginPage.form.trackingNumber')}/Vergi Numarası/TC. Kimlik No
              </label>
              <br />
              <div className='col-lg-12'>
                <IPInput
                  type='text'
                  className='form-control'
                  name='trackingCode'
                  required
                  onChange={e => setTrackingCode(e.target.value)}
                />
              </div>
            </div>
          </div>
        </>
      </Modal>
    </React.Fragment>
  )
}
