import React, { useEffect, useState } from 'react'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { IAuthorizedServiceProcedurs } from '../models/models'
import IPButton from '../../../../../components/IPButton'

export default function SshProcedures() {
  const t = translate(Translations)
  const routeLocation = useLocation()
  const [state, setState] = useState<IAuthorizedServiceProcedurs[]>([])
  const [datas, setDatas] = useState<IAuthorizedServiceProcedurs>()
  const [tab, setTab] = useState(0)

  useEffect(() => {
  }, [datas])

  useEffect(() => {
    const data = _.clone((routeLocation.state as any)?.data)

    if (data.length > 0) {
      setTab(data[0].Id)
      setState(data)
    }
  }, [routeLocation])

  useEffect(() => {
    state?.map(dta => {
      if (dta.Id === tab) {
        setDatas(dta)
      }
    })
  }, [tab])

  return (
    <div className='sshProcedures ' style={{ backgroundColor: 'white' }}>
      <div className='container-fluid'>
        <form>
          <div className='row align-items-center'>
            <div className='heading-text'>
              <h4>{state.length > 0 && state[0].Brand.Name + ' YETKİLİ SERVİS PROSEDÜRLERİ'}</h4>
            </div>
            <div className='border-div'>
              <div className='formEl'>
                <span>{t('pages.mySpecial.sshProcedures.authorizedServicePoints')}</span>
              </div>
              <a href='/myspecial/ssh/authorizedServicePoints' className='descriptionSSH'>
                {t('pages.mySpecial.sshProcedures.description')}
              </a>
            </div>
          </div>
          <div className='d-flex'>
            {state &&
              state.map(st => (
                <div>
                  {tab == st.Id ? (
                    <IPButton
                      className='form-control'
                      name={st.Brand.Name + ' ( ' + st.ProductGroup + ' )'}
                      onClick={() => setTab(st.Id)}
                      text={st.Brand.Name + ' ( ' + st.ProductGroup + ' )'}
                      style={{
                        color: 'white',
                        backgroundColor: 'red',
                      }}
                    />
                  ) : (
                    <IPButton
                      className='form-control'
                      name={st.Brand.Name + ' ( ' + st.ProductGroup + ' )'}
                      onClick={() => setTab(st.Id)}
                      text={st.Brand.Name + ' ( ' + st.ProductGroup + ' )'}
                      style={{
                        color: 'black',
                        border: '1px solid black',
                        backgroundColor: 'white',
                      }}
                    />
                  )}
                </div>
              ))}
          </div>
          <br />
          {datas && (
            // <table className="table table-bordered table-striped" dangerouslySetInnerHTML={{ __html: datas.Description }}></table>
            <div className='table'>
              <table className='table table-bordered table-responsive d-sm-block d-lg-table table-striped mt-5'>
                <tbody>
                  {datas &&
                    datas.Description &&
                    datas.Description.map((desc, index) => {
                      return (
                        <tr>
                          <td style={{ width: '40%' }}>
                            <b>{desc.Name} </b>
                          </td>
                          <td style={{ width: '60%' }}>{desc.Detail}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}
