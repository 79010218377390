import React, { useEffect, useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPInput from '../../../../../components/IPInput'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { IUntouchedOrdersRequest, IUntouchedOrdersResponse } from '../models/models'
import { PostUntouchedOrdersListApi } from '../services/postUntouchedOrdersListApi'
import { useCustomReducer } from '../../../../../base/customHooks'
import { css } from '@emotion/css'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { getCompanyApi } from '../../../components/api'
import { ICompany } from '../../../components/models/models'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Swal from 'sweetalert2'
import InputCampaign from '../../../../../components/InputCampaign'

interface IUntouchedOrdersListState {
  untouchedOrdersList: IUntouchedOrdersResponse[];
  companyOption?: ISelectOption
  orderNumber: string
  isTouched: boolean
}

function UntouchedOrdersList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): IUntouchedOrdersListState => {
    return {
      untouchedOrdersList: [],
      orderNumber: "",
      isTouched: false
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IUntouchedOrdersListState>(intialState)
  useEffect(() => {
    postUntouchedOrdersList()
    getCompanies();
  }, [])

  const postUntouchedOrdersList = async () => {
    props.showLoading()
    const data: IUntouchedOrdersRequest = {
      Company: state.companyOption ? String(state.companyOption.value) : "",
      OrderNumber: state.orderNumber,
      StartDate: state.orderNumber.length === 0 ? toDateTimeLocal(startDate) : null,
      EndDate: state.orderNumber.length === 0 ? toDateTimeLocal(endDate) : null,
      IsTouched: state.isTouched
    }
    const postUntouchedOrdersListApi: PostUntouchedOrdersListApi = new PostUntouchedOrdersListApi({})
    postUntouchedOrdersListApi.postUntouchedOrdersList(data).then(res => {
      if (res) {
        const dt: IUntouchedOrdersResponse[] = []
        let d = "";
        let i = 1;
        res.map((r) => {
          if (d.length > 0 && d != r.OrderNumber)
            i++;
          d = r.OrderNumber;
          return dt.push({
            AUDAT: r.AUDAT,
            CompanyCode: r.CompanyCode,
            CompanyName: r.CompanyName,
            Currency: r.Currency,
            DealerCode: r.DealerCode,
            EL_DEGME_DURUMU: r.EL_DEGME_DURUMU,
            ERDAT: r.ERDAT,
            InvoiceNumber: r.InvoiceNumber,
            KURDURUM: r.KURDURUM,
            KUR_TRY2USD: r.KUR_TRY2USD,
            NetWorth: r.NetWorth,
            NetWorthTL: r.NetWorthTL,
            NetWorthTLFiy: r.NetWorthTLFiy,
            NetWorthTLMuh: r.NetWorthTLMuh,
            NetWorthUSD: r.NetWorthUSD,
            NetWorthUSD2: r.NetWorthUSD2,
            OrderNumber: r.OrderNumber,
            Status: r.Status,
            Order: i
          })
        })
        setState({ untouchedOrdersList: dt })
        props.hideLoading()
      }
    }).catch((e: any) => {
      Swal.fire({
        icon: 'error',
        title: e.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    })
  }
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const [companies, setCompanies] = useState<ICompany[]>([]);

  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    setCompanies(company)
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.header')}
            </span>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='StartDate'
                    name='StartDate'
                    label={'Başlangıç Tarihi'}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, "Start")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <InputCampaign
                    type='date'
                    id='EndDate'
                    name='EndDate'
                    label={'Bitiş Tarihi'}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, "End")}
                    isActive
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPInput type='text' className='form-control' id='SiparisKodu' placeholder='Sipariş Kodu' onChange={(e) => setState({ orderNumber: e.target.value })} />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPSelectBox
                    isClearable
                    options={
                      companies &&
                      companies.filter((com) => { return com.Name != "Index Group" }).map((item: ICompany) => ({
                        label: item.Name,
                        value: `${item.Code}`,
                      }
                      ))
                    }
                    placeholder="Şirket Seçiniz..."
                    value={state.companyOption}
                    onChangeSingle={option => setState({ companyOption: option })}
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPCheckbox
                    label='El Değmiş'
                    onClick={() => setState({ isTouched: !state.isTouched })}
                    checked={state.isTouched}
                  />
                </div>
                <div className='col-12 col-md-3 mb-3'>
                  <IPButton
                    type='button'
                    className='btn btn-success'
                    text={t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.filtre')}
                    onClick={postUntouchedOrdersList}
                  />
                </div>
              </div>
            </form>
            <br />
            <div className='row'>
              <div className='col-lg-12'>
                <div
                  id='datatable_wrapper'
                  className='dataTables_wrapper dt-bootstrap4 table-responsive '
                  style={{ overflowY: 'hidden' }}
                >
                  <div
                    className={`table-responsive ${css`
                          ::-webkit-scrollbar {
                            height: 6px;
                          }
                        `}`}
                  >
                    <table
                      id='datatable'
                      className='table table-bordered table-hover dataTable'
                      role='grid'
                      aria-describedby='datatable_info'
                    >
                      <thead>
                        <tr role='row' className='odd'>
                          <th colSpan={20}>
                            {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.header')}
                          </th>
                        </tr>
                        <tr role='row' className='odd'>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.handsOnCondition'
                            )}
                          </th>
                          <th>
                            {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.rowCounter')}
                          </th>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.orderCounter'
                            )}
                          </th>
                          <th>
                            {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.market')}
                          </th>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.companyCode'
                            )}
                          </th>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.currentCode'
                            )}
                          </th>
                          <th>
                            {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.orderNo')}
                          </th>
                          <th>
                            {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.orderDate')}
                          </th>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.creationDate'
                            )}
                          </th>
                          <th>
                            {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.status')}
                          </th>
                          <th>
                            {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.billNo')}
                          </th>
                          <th>
                            {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.netWorth')}
                          </th>
                          <th>
                            {t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.currency')}
                          </th>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.netWorthPrice'
                            )}
                          </th>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.netWorthMuh'
                            )}
                          </th>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.netWorthDay'
                            )}
                          </th>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.exchangeStatus'
                            )}
                          </th>
                          <th>
                            {t(
                              'pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.netWorthUsd'
                            )}
                          </th>
                          <th>{t('pages.admin.dealerAndOrderReport.untouchedOrdersList.untouchedOrdersList.usd')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state.untouchedOrdersList && state.untouchedOrdersList.map((touch, index) => (
                            <tr role='row' className='odd' key={index}>
                              <td dangerouslySetInnerHTML={{ __html: touch.EL_DEGME_DURUMU }}></td>
                              <td>{index + 1}</td>
                              <td>{touch.Order}</td>
                              <td>{touch.CompanyName}</td>
                              <td>{touch.CompanyCode}</td>
                              <td>{touch.DealerCode.replace("0000", "")}</td>
                              <td>{touch.OrderNumber}</td>
                              <td>{touch.AUDAT}</td>
                              <td>{touch.ERDAT}</td>
                              <td>{touch.Status}</td>
                              <td>{touch.InvoiceNumber}</td>
                              <td>{touch.NetWorth.toFixed(2)}</td>
                              <td>{touch.Currency}</td>
                              <td>{touch.NetWorthTLFiy.toFixed(2)}</td>
                              <td>{touch.NetWorthTLMuh.toFixed(2)}</td>
                              <td>{touch.NetWorthTL.toFixed(2)}</td>
                              <td>{touch.KURDURUM}</td>
                              <td>{touch.NetWorthUSD.toFixed(2)}</td>
                              <td>{touch.KUR_TRY2USD.toFixed(2)}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(UntouchedOrdersList)