import { useMemo, useState, useEffect } from 'react'
import { translate } from '../../../../base/localization'
import Translations from '../../../../translations'
import IPButton from '../../../../components/IPButton'
import IPInput from '../../../../components/IPInput'
import { DealerForProductsFilterRequest, DealerForProductsFilterResponse, IDealersInfo, ProductOptions } from './models'
import Table from '../../../../components/Table'
import { ProductClickStatisticsService } from './services'
import Swal from 'sweetalert2'
import InputCampaign from '../../../../components/InputCampaign'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import IPSelectBox from '../../../../components/IPSelectBox'
import { getCompanyApi } from '../../components/api'
import { ICompany } from '../../components/models/models'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { useCustomReducer } from '../../../../base/customHooks'
import IPModal from '../../../../components/IPModal'
import { exportToXLSX } from '../../../../base/utils/dataHelper'
import IPCheckbox from '../../../../components/IPCheckBox'

interface IProductClickStatisticsState {
  showModal: boolean
  modalData: IDealersInfo[]
  productCode: string
  first: boolean
  total: boolean
  IncludeDenemeCard: boolean
}
function Index(props: BaseComponentWithContextProps) {
  const getInitialState = (): IProductClickStatisticsState => {
    return {
      showModal: false,
      modalData: [],
      productCode: '',
      first: false,
      total: false,
      IncludeDenemeCard: false,
    }
  }

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IProductClickStatisticsState>(intialState)
  const [productOptions, setProductOptions] = useState<ProductOptions>({
    Company: '5',
    ProductCode: '',
    Brand: '',
    Quota: '',
    DealerCode: '',
    StartDate: startDate,
    EndtDate: endDate,
  })
  const [companies, setCompanies] = useState<ISelectOption[]>([])
  const [companiesOption, setCompaniesOption] = useState<any>(null)
  const [products, setProducts] = useState<DealerForProductsFilterResponse[]>([])

  function updateProductOptions(prop, val) {
    setProductOptions(prev => ({ ...prev, [prop]: val }))
  }

  const t = translate(Translations)

  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000).toISOString()

  async function DealerForProductsFilter(e) {
    e.preventDefault()
    const req: DealerForProductsFilterRequest = {
      BrandCode: productOptions.Brand,
      QuotaCode: productOptions.Quota,
      ProductCode: productOptions.ProductCode,
      CompanyId: companiesOption ? +productOptions.Company : 5,
      DateRange: {
        StartDate: dateToISO(startDate),
        EndDate: dateToISO(endDate),
      },
      IncludeDenemeCard: state.IncludeDenemeCard,
    }
    setState({ IncludeDenemeCard: state.IncludeDenemeCard })
    props.showLoading()
    await new ProductClickStatisticsService({})
      .DealerForProductsFilter(req)
      .then(result => {
        if (result) {
          setProducts(result)
          setState({ first: true })
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Ürün Kodu',
        accessor: 'code',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'name',
        width: 300,
      },
      {
        Header: 'Şirket',
        accessor: 'company',
      },
      {
        Header: 'Kota',
        accessor: 'quota',
      },
      {
        Header: 'Arama ve Liste',
        accessor: 'search',
      },
      {
        Header: 'Fırsat Reyonu',
        accessor: 'opportunity',
      },
      {
        Header: 'En Favoriler',
        accessor: 'topFavorities',
      },
      {
        Header: 'Son İncelenenler',
        accessor: 'recentVisit',
      },
      {
        Header: 'Çok Satanlar',
        accessor: 'bestSellers',
      },
      {
        Header: 'Toplam',
        accessor: 'total',
      },
    ],
    []
  )

  const data = useMemo(
    () =>
      products.map(p => {
        return {
          code: (
            <a
              onClick={() => setState({ showModal: true, modalData: p.TotalDealersInfo, total: true })}
              style={{ cursor: 'pointer', color: 'blue' }}
            >
              {p.ProductCode}
            </a>
          ),
          name: p.ProductName,
          company: p.Company,
          quota: p.Quota,
          showcase: p.HomePageShowCase,
          mainBanner: p.HomePageBanner,
          rightBanner: p.TopRightBanner,
          opportunity:
            p.Opportunity > 0 ? (
              <a
                onClick={() => setState({ showModal: true, modalData: p.OpportunityDealersInfo })}
                style={{ cursor: 'pointer', color: 'blue' }}
              >
                {p.Opportunity}
              </a>
            ) : (
              <a>{p.Opportunity}</a>
            ),
          video: p.Video,
          homePageVideo: p.HomePageVideo,
          discount: p.DiscountPage,
          search:
            p.SearchAndList > 0 ? (
              <a
                onClick={() => setState({ showModal: true, modalData: p.SearchAndListDealersInfo })}
                style={{
                  cursor: 'pointer',
                  color: 'blue',
                }}
              >
                {p.SearchAndList}
              </a>
            ) : (
              p.SearchAndList
            ),
          total:
            p.TotalClick > 0 ? (
              <a
                onClick={() => setState({ showModal: true, modalData: p.TotalDealersInfo, total: true })}
                style={{ cursor: 'pointer', color: 'blue' }}
              >
                {p.TotalClick}
              </a>
            ) : (
              p.TotalClick
            ),
          recentVisit:
            p.RecentVisit > 0 ? (
              <a
                onClick={() => setState({ showModal: true, modalData: p.RecentVisitDealersInfo })}
                style={{ cursor: 'pointer', color: 'blue' }}
              >
                {p.RecentVisit}
              </a>
            ) : (
              <a>{p.RecentVisit}</a>
            ),
          topFavorities:
            p.TopFavorities > 0 ? (
              <a
                onClick={() => setState({ showModal: true, modalData: p.TopFavoritiesDealersInfo })}
                style={{ cursor: 'pointer', color: 'blue' }}
              >
                {p.TopFavorities}
              </a>
            ) : (
              <a>{p.TopFavorities}</a>
            ),
          bestSellers:
            p.BestSellers > 0 ? (
              <a
                onClick={() => setState({ showModal: true, modalData: p.BestSellersDealersInfo })}
                style={{ cursor: 'pointer', color: 'blue' }}
              >
                {p.BestSellers}
              </a>
            ) : (
              <a>{p.BestSellers}</a>
            ),
        }
      }),
    [products]
  )

  const tableColumns = useMemo(
    () => [
      {
        Header: 'Sıra',
        accessor: 'Index',
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'DealerCode',
      },
      {
        Header: 'Hesap Adı/Ünvan',
        accessor: 'DealerName',
        width: 300,
      },
      {
        Header: 'Tıklama',
        accessor: 'Count',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.modalData.map((p, index) => {
        return {
          Index: index + 1,
          DealerCode: p.DealerCode,
          DealerName: p.DealerName,
          Count: state.total ? p.Counts : p.CountValue,
        }
      }),
    [state.modalData]
  )

  const excelButtonWithDetail = async () => {
    try {
      if (products) {
        const combinedData: any[] = []

        if (products.length > 0) {
          for (let index = 0; index < products.length; index++) {
            const productsData = products[index]
            const modalData = productsData.TotalDealersInfo

            combinedData.push({
              Sıra: index + 1,
              'Ürün Kodu': productsData.ProductCode,
              'Ürün Adı': productsData.ProductName,
              Şirket: productsData.Company,
              Kota: productsData.Quota,
              'Arama ve Liste': productsData.SearchAndList,
              'Fırsat Reyonu': productsData.Opportunity,
              'En Favoriler': productsData.TopFavorities,
              'Son İncelenenler': productsData.RecentVisit,
              'Çok Satanlar': productsData.BestSellers,
              Toplam: productsData.TotalClick,
            })

            if (modalData) {
              for (let i = 0; i < modalData.length; i++) {
                const modalRow = modalData[i]

                combinedData.push({
                  Sıra: index + 1,
                  'Ürün Kodu': productsData.ProductCode,
                  'Ürün Adı': productsData.ProductName,
                  Şirket: productsData.Company,
                  Kota: productsData.Quota,
                  'Arama ve Liste': '',
                  'Fırsat Reyonu': '',
                  'En Favoriler': '',
                  'Son İncelenenler': '',
                  'Çok Satanlar': '',
                  Toplam: '',
                  'Detay Sıra': i + 1,
                  'Hesap Kodu': modalRow.DealerCode,
                  'Hesap Adı/Ünvan': modalRow.DealerName,
                  Tıklama: state.total ? modalRow.Counts : modalRow.CountValue,
                })
              }
            }
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(combinedData, 'Detaylı Ürün Tıklama İstatistikleri')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const excelButton = async () => {
    try {
      if (products) {
        const data: any[] = []
        if (products.length > 0) {
          products.map((p, index) => {
            return data.push({
              Sıra: index + 1,
              'Ürün Kodu': p.ProductCode,
              'Ürün Adı': p.ProductName,
              Şirket: p.Company,
              Kota: p.Quota,
              'Arama ve Liste': p.SearchAndList,
              'Fırsat Reyonu': p.Opportunity,
              'En Favoriler': p.TopFavorities,
              'Son İncelenenler': p.RecentVisit,
              'Çok Satanlar': p.BestSellers,
              Toplam: p.TotalClick,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Ürün Tıklama İstatistikleri')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    const data: ISelectOption[] = []
    company
      .filter(com => {
        return com.Name !== 'Index Group'
      })
      .map(co => {
        data.push({ value: co.Id, label: co.Name })
      })
    setCompanies(data)
  }

  useEffect(() => {
    getCompanies()
  }, [])

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>{t('pages.admin.siteReports.productClick.header')}</span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
              <span>Detaylı Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButtonWithDetail}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='form-group form-fg'>
              <form id='form1' className='form-validate' noValidate onSubmit={DealerForProductsFilter}>
                <div className='row'>
                  <div className='d-flex gap-4 flex-wrap align-items-end'>
                    <div className='col-md-2 col-sm-6 mb-3'>
                      <IPInput
                        type='text'
                        className='form-control'
                        name='Quota'
                        placeholder={t('pages.admin.siteReports.productClick.quota')}
                        value={productOptions.Quota}
                        onChange={e => updateProductOptions('Quota', e.target.value)}
                      />
                    </div>
                    <div className='col-md-2 col-sm-6 mb-3'>
                      <IPSelectBox
                        isClearable
                        name='CompanyId'
                        value={companiesOption}
                        onChangeSingle={(option: ISelectOption) => {
                          if (option) {
                            updateProductOptions('Company', option.value)
                          }
                          setCompaniesOption(option)
                        }}
                        options={companies}
                        placeholder='Şirket Seçiniz...'
                      />
                    </div>
                    <div className='col-md-2 col-sm-6 mb-3'>
                      <InputCampaign
                        type='date'
                        id='StartDate'
                        name='StartDate'
                        label={t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.startDate')}
                        value={toDateTimeLocal(startDate)}
                        onChange={e => getDate(e.target.value, 'Start')}
                        isActive
                      />
                    </div>
                    <div className='col-md-2 col-sm-6 mb-3' style={{ alignSelf: 'flex-end' }}>
                      <IPCheckbox
                        label='Deneme Kartı Dahil'
                        onChange={e => setState({ IncludeDenemeCard: !state.IncludeDenemeCard })}
                        checked={state.IncludeDenemeCard}
                      />
                    </div>
                    <div className='col-md-2 col-sm-6 mb-3'>
                      <IPButton
                        type='submit'
                        className='btn btn-danger'
                        text={t('pages.admin.siteReports.mailSend.showButton')}
                      />
                    </div>
                  </div>
                </div>
                <div className='row mt-3'>
                  <div className='d-flex gap-4 flex-wrap align-items-end'>
                    <div className='col-md-2 col-sm-6 mb-3'>
                      <IPInput
                        type='text'
                        className='form-control'
                        name='brandCode'
                        placeholder={t('pages.admin.siteReports.productClick.brandCode')}
                        value={productOptions.Brand}
                        onChange={e => updateProductOptions('Brand', e.target.value)}
                      />
                    </div>
                    <div className='col-md-2 col-sm-6 mb-3'>
                      <IPInput
                        type='text'
                        className='form-control'
                        name='ProductCode'
                        placeholder={t('pages.admin.siteReports.productClick.productCode')}
                        value={productOptions.ProductCode}
                        onChange={e => updateProductOptions('ProductCode', e.target.value)}
                      />
                    </div>
                    <div className='col-md-2 col-sm-6 mb-3'>
                      <InputCampaign
                        type='date'
                        id='EndDate'
                        name='EndDate'
                        label={t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.endDate')}
                        value={toDateTimeLocal(endDate)}
                        onChange={e => getDate(e.target.value, 'End')}
                        // onBlur={() => dateValid()}
                        isActive
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            {state.first && <Table columns={columns} data={data} currentPage={0} />}
          </div>
        </div>
      </div>
      <IPModal
        show={state.showModal}
        onClose={() => setState({ showModal: false, modalData: [], productCode: '', total: false })}
        hideConfirm={true}
        width={1000}
        title='Ürün Tıklama Detay'
      >
        <Table columns={tableColumns} data={tableData} currentPage={0} />
      </IPModal>
    </div>
  )
}

export default componentWithContext(Index)
