/* eslint-disable jsx-a11y/anchor-is-valid */
import { css } from '@emotion/css'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPButton from '../../../../../components/IPButton'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPTextarea from '../../../../../components/IPTextarea'
import InputCampaign from '../../../../../components/InputCampaign'
import { GetWhiteDealerListApi } from '../../../CreditCardPaymentCampaigns/Dealer/services/getWhiteDealerListApi'
import { IBankNoteList } from '../models/models'
import { AddBankNoteApi } from '../services/addBankNoteApi'
import { DeleteBankNoteApi } from '../services/deleteBankNoteApi'
import { ListBankNoteApi } from '../services/listBankNoteApi'
import { UpdateBankNoteApi } from '../services/updateBankNoteApi'

function AddBankNote(props: BaseComponentWithContextProps) {
  const location = useLocation()
  const bankNoteData = (location.state as { bankNoteData?: any })?.bankNoteData

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }
  const navigate: any = useNavigate()

  const [bankNoteList, setBankNoteList] = useState<IBankNoteList[]>([])
  const [isEditing, setIsEditing] = useState(false)
  const [optionList, setOptionList] = useState<any>({
    bankOptions: [],
  })

  const [formData, setFormData] = useState({
    Id: 0,
    BankNames: [] as ISelectOption[],
    Note: '',
    IsDeleted: false,
    StartDate: startDate,
    EndDate: endDate,
  })

  useEffect(() => {
    async function fetchData() {
      props.showLoading()
      try {
        const [bankOptions] = await Promise.all([GetBanks()])
        setOptionList({
          bankOptions,
        })
        fetchBankNoteList()
      } catch (err: any) {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      } finally {
        props.hideLoading()
      }
    }
    fetchData()

    if (bankNoteData) {
      // Update formData and set isEditing if bankNoteData exists
      formData.Id = bankNoteData.BankNoteId
      formData.BankNames = [{ label: bankNoteData.BankName, value: bankNoteData.BankName }]
      formData.Note = bankNoteData.Note
      formData.IsDeleted = bankNoteData.IsDeleted
      formData.StartDate = bankNoteData.StartDate
      formData.EndDate = bankNoteData.EndDate
      setIsEditing(true)
      setStartDate(bankNoteData.StartDate)
      setEndDate(bankNoteData.EndDate)

      setBankNoteList([
        {
          BankNoteId: bankNoteData.BankNoteId,
          BankName: bankNoteData.BankName,
          Note: bankNoteData.Note,
          CreatedDate: bankNoteData.CreatedDate,
          UpdatedDate: bankNoteData.UpdatedDate,
          IsDeleted: bankNoteData.IsDeleted,
          StartDate: bankNoteData.StartDate,
          EndDate: bankNoteData.EndDate,
        },
      ])
    }
  }, [])

  async function GetBanks() {
    try {
      const res = await new GetWhiteDealerListApi({}).getBanks()
      if (res) {
        const banks = res.map(x => ({ label: x.BankName, value: x.BankID }))
        if (!banks) return []
        return banks
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const fetchBankNoteList = async () => {
    try {
      const api: ListBankNoteApi = new ListBankNoteApi({})
      const response: IBankNoteList[] = await api.listBankNote()
      setBankNoteList(response)
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const handleBankNamesChange = options => {
    setFormData({
      ...formData,
      BankNames: options,
    })
  }

  const handleChange = event => {
    const { name, value, type, checked } = event.target

    // For checkboxes, handle the checked state
    const newValue = type === 'checkbox' ? checked : value

    setFormData({
      ...formData,
      [name]: newValue,
    })
  }

  const handleNoteChange = event => {
    setFormData({
      ...formData,
      Note: event.target.value,
    })
  }

  const handleAddBankNote = async () => {
    try {
      // Validate that at least one bank is selected and a note is provided
      if (formData.BankNames.length === 0 || !formData.Note) {
        Swal.fire({
          icon: 'error',
          title: 'Lütfen en az bir banka seçin ve not ekleyin.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      // Validate that the end date is greater than the start date
      if (startDate >= endDate) {
        Swal.fire({
          icon: 'error',
          title: 'Bitiş tarihi, başlangıç tarihinden sonra olmalıdır.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      }

      const isActive = formData.IsDeleted ? true : false

      // Loop through selected banks
      for (const bank of formData.BankNames) {
        const bankName = (bank as any).label
        const requestData = {
          Id: formData.Id,
          BankName: bankName,
          Note: formData.Note,
          IsDeleted: isActive,
          StartDate: startDate,
          EndDate: endDate,
        }
        try {
          if (isEditing) {
            const api: UpdateBankNoteApi = new UpdateBankNoteApi({})
            await api.updateBankNote([requestData])

            await Swal.fire({
              icon: 'success',
              title: `${bankName} için not güncellendi.`,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            navigate('/admin/BankAndInstallementOperation/BankNote/BankNoteList')
          } else {
            const api: AddBankNoteApi = new AddBankNoteApi({})
            await api.addBankNote([requestData])
            // Display a success message for creating a new bank note
            await Swal.fire({
              icon: 'success',
              title: `${bankName} için not oluşturuldu.`,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
          // Clear the form and set isEditing to false
          setFormData({
            Id: 0,
            BankNames: [],
            Note: '',
            IsDeleted: false,
            StartDate: inititalStartDate,
            EndDate: new Date(),
          })
          setIsEditing(false)

          // Fetch the updated note list
          fetchBankNoteList()
        } catch (err: any) {
          await Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    } catch (err: any) {
      await Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const handleEditBankNote = (bankNote: any) => {
    setFormData({
      Id: bankNote.BankNoteId,
      BankNames: bankNote.BankName,
      Note: bankNote.Note,
      IsDeleted: bankNote.IsDeleted,
      StartDate: bankNote.StartDate,
      EndDate: bankNote.EndDate,
    })
    setIsEditing(true)
  }

  const handleDeleteBankNote = async (id: number) => {
    const shouldDelete = await Swal.fire({
      icon: 'warning',
      title: 'Bu notu silmek istediğinizden emin misiniz?',
      showCancelButton: true,
      showConfirmButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
    })

    if (shouldDelete.isConfirmed) {
      try {
        const api: DeleteBankNoteApi = new DeleteBankNoteApi({})
        await api.deleteBankNote(id)
        await Swal.fire({
          icon: 'success',
          title: `Not silindi.`,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      } catch (err: any) {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
      navigate('/admin/BankAndInstallementOperation/BankNote/BankNoteList')
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Banka Taksit Notu {isEditing === true ? ' Güncelleme' : ' Ekleme'}</span>
            <div className='col-12 col-md-2 mb-2'>
              <div className='buttonsuz'>
                <Link to='/admin/BankAndInstallementOperation/BankNote/BankNoteList'>
                  <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                </Link>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form id='id1' className='formform' noValidate>
              <div className='form-row'>
                <div className='col-lg-4'>
                  <label className='form-label'>Başlangıç Tarihi</label>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={''}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='col-lg-4'>
                  <label className='form-label'>Bitiş Tarihi</label>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={''}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
                <div className='col-lg-2 d-flex' style={{ justifyContent: 'end', alignSelf: 'end' }}>
                  <label className='p-switch switch-color-success d-flex'>
                    <label className='floagit t-left mr-4'>Yayınla</label>
                    <IPCheckbox name='IsDeleted' onChange={handleChange} checked={formData.IsDeleted} />
                  </label>
                </div>
                <div className='col-lg-2'>
                  <label className='labeltext'></label>
                  <IPButton
                    onClick={handleAddBankNote}
                    type='button'
                    className='btn btn-success m-t-5'
                    text={isEditing ? 'Güncelle' : 'Not Ekle'}
                  ></IPButton>
                </div>
              </div>

              <div className='form-row m-t-5'>
                <div className='col-md-4'>
                  <label className='labeltext'>Bankalar</label>
                  <IPSelectBox
                    isDisabled={isEditing}
                    isMulti
                    isClearable
                    id='BankNames'
                    name='BankNames'
                    options={optionList.bankOptions}
                    value={formData.BankNames as unknown as ISelectOption}
                    onChangeMulti={options => {
                      handleBankNamesChange(options)
                    }}
                  />
                </div>
                <div className='col-md-4'>
                  <label className='labeltext'>Not</label>
                  <IPTextarea
                    style={{ minHeight: '150px' }}
                    className='form-control'
                    name='BankNote'
                    placeholder='Notunuz...'
                    value={formData.Note as any}
                    onChange={handleNoteChange}
                  />
                </div>

                {isEditing && (
                  <div className='col-md-4'>
                    <label className='labeltext'></label>
                    <IPButton
                      onClick={() => handleDeleteBankNote(formData.Id)}
                      style={{ width: '30%', float: 'inline-end' }}
                      type='button'
                      className='btn btn-danger m-t-5'
                      text='Sil'
                      disabled={bankNoteList.length < 2 ? true : false}
                    ></IPButton>
                  </div>
                )}
              </div>

              <div className='mt-5'>
                <h4>Son Kayıt</h4>
                <table style={{ width: '100%', overflowWrap: 'anywhere' }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: 'left' }}>Banka Adı</th>
                      <th style={{ textAlign: 'left' }}>Not</th>
                      <th style={{ textAlign: 'left' }}>Başlangıç Tarihi</th>
                      <th style={{ textAlign: 'left' }}>Bitiş Tarihi</th>
                      <th style={{ textAlign: 'left' }}>Oluşturma Tarihi</th>
                      <th style={{ textAlign: 'left' }}>Değiştirme Tarihi</th>
                      <th style={{ textAlign: 'left' }}>Durum</th>
                      <th style={{ textAlign: 'center' }}>İşlemler</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bankNoteList.length > 0 && (
                      <tr key={bankNoteList[bankNoteList.length - 1].BankNoteId}>
                        <td style={{ textAlign: 'left' }}>{bankNoteList[bankNoteList.length - 1].BankName}</td>
                        <td style={{ textAlign: 'left' }}>{bankNoteList[bankNoteList.length - 1].Note}</td>
                        <td style={{ textAlign: 'left' }}>
                          {moment(toDateTimeLocal(bankNoteList[bankNoteList.length - 1].StartDate)).format(
                            'DD.MM.YYYY HH:mm:ss'
                          )}
                        </td>
                        <td style={{ textAlign: 'left' }}>
                          {moment(toDateTimeLocal(bankNoteList[bankNoteList.length - 1].EndDate)).format(
                            'DD.MM.YYYY HH:mm:ss'
                          )}
                        </td>
                        <td style={{ textAlign: 'left' }}>
                          {moment(toDateTimeLocal(bankNoteList[bankNoteList.length - 1].CreatedDate)).format(
                            'DD.MM.YYYY HH:mm:ss'
                          )}
                        </td>
                        <td style={{ textAlign: 'left' }}>
                          {bankNoteList[bankNoteList.length - 1]?.UpdatedDate !== null
                            ? moment(toDateTimeLocal(bankNoteList[bankNoteList.length - 1].UpdatedDate)).format(
                                'DD.MM.YYYY HH:mm:ss'
                              )
                            : ''}
                        </td>
                        <td style={{ textAlign: 'left' }}>
                          {bankNoteList[bankNoteList.length - 1].IsDeleted ? 'Yayında' : 'Pasif'}
                        </td>
                        <td style={{ textAlign: 'center' }}>
                          <div>
                            <a
                              onClick={() => handleEditBankNote(bankNoteList[bankNoteList.length - 1])}
                              data-action-type='edit'
                              role='button'
                              className={css`
                                cursor: pointer;
                              `}
                              data-toggle='tooltip'
                              data-original-title='Düzenle'
                            >
                              <i
                                className={`${css`
                                  font-size: 1.2rem;
                                  color: green;
                                `} icon-edit`}
                              ></i>
                            </a>

                            <a
                              style={{ marginLeft: '10px' }}
                              onClick={() => handleDeleteBankNote(bankNoteList[bankNoteList.length - 1].BankNoteId)}
                              data-action-type='delete'
                              role='button'
                              className={css`
                                cursor: pointer;
                              `}
                              data-toggle='tooltip'
                              data-original-title='Sil'
                            >
                              <i
                                className={`${css`
                                  font-size: 1.2rem;
                                  color: red;
                                `} icon-trash-2`}
                              ></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className='form-row mt-4' style={{ float: 'right' }}>
                <IPButton
                  onClick={handleAddBankNote}
                  type='button'
                  className='btn btn-success m-t-5'
                  text={isEditing ? 'Güncelle' : 'Not Ekle'}
                ></IPButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(AddBankNote)
