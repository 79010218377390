import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IBanner } from '../models/responseModels'

export class DeleteBannerApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/banner/delete/',
      requesterInfo,
    })
  }

  public async deleteBanner(id: number): Promise<IBanner[]> {
    this.props.url = `${this.props.url}${id}`
    return await this.deleteAsync<IBanner[]>()
  }
}
