import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IManualCreditCardPaymentControl } from '../models/PaymentModel'

export class GetManualCreditCardPaymentControlApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'manualCreditCardPayment/paymentControl',
      requesterInfo,
    })
  }

  public async getManualCreditCardPaymentControl(request: IManualCreditCardPaymentControl): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
