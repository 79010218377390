import React from 'react'
import { Link } from 'react-router-dom'
import IPButton from '../../../components/IPButton'
import IPImage from '../../../components/IPImage'

export default function Mac() {
  return (
    <div className='container current-account'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='row' style={{ textAlign: 'center', marginTop: '20px' }}>
            <h1>
              <b>İşte Mac.</b>
            </h1>
          </div>
          <div className='row' style={{ textAlign: 'center' }}>
            <h2 style={{ color: 'red' }}>
              <b>İşte 11 neden.</b>
            </h2>
          </div>
          <div className='row' style={{ justifyContent: 'center', marginTop: '20px' }}>
            <Link to='/products?searchKey=mac'>
              <IPButton
                className='col-md-1'
                text='Satın Alın'
                style={{
                  backgroundColor: 'white',
                  border: '2px',
                  color: 'black',
                  borderColor: 'black',
                  borderStyle: 'inset',
                  borderRadius: '10px',
                  marginLeft: '600px',
                }}
              />
            </Link>
          </div>
          <div style={{ backgroundColor: 'black', marginTop: '20px', borderRadius: '20px' }}>
            <div className='row p-20'>
              <div className='col-md-6 p-20'>
                <h4 style={{ color: 'white' }}>1. Neden</h4>
                <h3 style={{ color: 'white' }}>
                  Ciddi bir <br /> çip gücü var.
                </h3>
                <p style={{ color: 'white' }}>
                  Apple M1 çip bildiğiniz her şeyi değiştiriyor. Özel teknolojiler.Kısacası bir dönem bitiyor, yenisi
                  başlıyor. Hem de tüm ekibiniz için. Işık hızında işlem performansı. Ve olağanüstü güç verimliliği.
                  Kısacası bir dönem bitiyor, yenisi başlıyor. Hem de tüm ekibiniz için.
                </p>
                <a href='/assetWeb/docs/apple_M1_Cip__TR.pdf' target={'_blank'}>
                  Apple M1 çip (PDF)
                </a>
              </div>
              <div className='col-md-6'>
                <IPImage src='/images/S2_Chip_1.png' alt='' className='popular_product' />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-7'>
              <div
                className='most-popular-products'
                style={{
                  minHeight: '550px',
                  height: '550px',
                  borderRadius: '20px',
                  marginTop: '20px',
                }}
              >
                <div className='room'>
                  <h4>2. Neden</h4>
                  <h3>Bakınız 1. Neden.</h3>
                  <p>
                    O kadar güçlü ki. Apple M1 çiple süper güçler kazanan MacBook Air şimdi önceki nesle göre 3,5 kata
                    kadar daha hızlı.¹ Aynı anda birden fazla proje üzerinde çalışın, video konferans yaparken hesap
                    tablolarınızı inceleyin ve en yüksek performans gerektiren görevlerin bile kolayca üstesinden gelin.
                  </p>
                  <div
                    className='col-md-12'
                    style={{ justifyContent: 'center', marginTop: '20px', paddingLeft: '150px' }}
                  >
                    <IPImage src='/images/S3_Smart_1.png' alt='' className='popular_product' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-5'>
              <div
                className='most-popular-products'
                style={{
                  minHeight: '550px',
                  height: '550px',
                  borderRadius: '20px',
                  backgroundColor: 'black',
                  marginTop: '20px',
                }}
              >
                <div className='room'>
                  <h4 style={{ color: 'white' }}>3. Neden</h4>
                  <h3 style={{ color: '#00a760' }}>
                    En uzuuuuuu <br /> uuuuuun pil
                    <br />
                    ömrüne sahip.
                  </h3>
                  <p style={{ color: 'white' }}>
                    18 saate ulaşan inanılmaz pil ömrüyle MacBook Air istediğiniz yerde, istediğiniz şekilde,
                    istediğiniz kadar çalışmanıza olanak sağlıyor.
                  </p>
                </div>
                <div className='col-md-12' style={{ marginTop: '100px' }}>
                  <IPImage src='/images/S3_Icon.png' alt='' className='popular_product' />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div
                className='most-popular-products'
                style={{
                  minHeight: '550px',
                  height: '550px',
                  borderRadius: '20px',
                  marginTop: '20px',
                }}
              >
                <div className='room'>
                  <h4>4. Neden</h4>
                  <h3>Mac, IT ile uyum içinde çalışıyor.</h3>
                  <p>
                    Temassız dağıtım sayesinde aygıtların kurulumunu her yerden yapabiliyorsunuz. Üstelik mobil aygıt
                    yönetimiyle Mac’i her ölçekte yönetebiliyorsunuz.
                  </p>
                  <a href='/assetWeb/docs/dagitim_ve_Yonetim__TR.pdf' target={'_blank'}>
                    Dağıtım ve Yönetim (PDF)
                  </a>
                  <div className='col-md-12' style={{ marginTop: '200px', marginLeft: '90px' }}>
                    <IPImage src='/images/S4_Smart_1.png' alt='' className='popular_product' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div
                className='most-popular-products'
                style={{
                  minHeight: '550px',
                  height: '550px',
                  borderRadius: '20px',
                  marginTop: '20px',
                }}
              >
                <div className='room'>
                  <h4>5. Neden</h4>
                  <h3>Mac IT departmanı olmayan şirketlerde kolaylık sağlıyor.</h3>
                  <p>
                    Mac’i kullanmak ve yönetmek çok kolay. Basit kurulum ve Apple Geçiş Yardımcısı sayesinde Mac’inizle
                    çalışmaya hızlıca başlayabiliyorsunuz. Ayrıca güvenilir donanımıyla sizi asla yarı yolda bırakmayan
                    Mac, bir IT ekibi olsun ya da olmasın, tüm şirketler için mükemmel çözümler sunuyor.
                  </p>
                </div>
                <div className='col-md-12' style={{ marginTop: '240px', marginLeft: '90px' }}>
                  <IPImage src='/images/S4_Smart_2.png' alt='' className='popular_product' />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-5'>
              <div
                className='most-popular-products'
                style={{
                  minHeight: '550px',
                  height: '550px',
                  borderRadius: '20px',
                  marginTop: '20px',
                  backgroundColor: '#1f887a',
                }}
              >
                <div className='room'>
                  <h4 style={{ color: 'white' }}>6. Neden</h4>
                  <h3 style={{ color: 'white' }}>Mac, uzun vadede daha ekonomik oluyor.</h3>
                  <p style={{ color: 'white' }}>
                    Daha az destek talebi, daha az yazılım ihtiyacı ve daha yüksek net değerle tasarruflarınız artıyor.
                  </p>
                  <a href='/assetWeb/docs/forrester_TEI_Calismasi__TR.pdf' target={'_blank'}>
                    Forrester TEI Çalışması (PDF)
                  </a>
                  <div className='col-md-12' style={{ width: '250px', marginTop: '40px' }}>
                    <IPImage src='/images/Ellipse1.png' alt='' className='popular_product' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-7'>
              <div
                className='most-popular-products'
                style={{
                  minHeight: '550px',
                  height: '550px',
                  borderRadius: '20px',
                  marginTop: '20px',
                }}
              >
                <div className='room'>
                  <h4>7. Neden</h4>
                  <h3>En yenilikçi şirketler Mac kullanıyor.</h3>
                  <p>Dünyanın en yenilikçi şirketlerinin yüzde 84’ü yaygın bir biçimde Mac kullanıyor.</p>
                </div>
                <div
                  className='col-md-12'
                  style={{
                    width: '1200px',
                    marginTop: '150px ',
                  }}
                >
                  <IPImage src='/images/S5_Smart_1.png' alt='' className='popular_product' />
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <div
                className='most-popular-products'
                style={{
                  minHeight: '550px',
                  height: '550px',
                  borderRadius: '20px',
                  marginTop: '20px',
                }}
              >
                <div className='room'>
                  <h4>8. Neden</h4>
                  <h3>
                    <span style={{ color: '#00a760' }}>Excel.</span>
                    <span style={{ color: '#3e8cff' }}>Zoom.</span>
                    <span style={{ color: '#bfa7c3' }}>Slack.</span>
                    <span> Ve çok daha fazlası.</span>
                  </h3>
                  <p>
                    Microsoft 365 ve Google Workspace’ten SAP ve Dropbox’a kadar ihtiyaç duyduğunuz tüm kurumsal
                    uygulamalar Mac’te mükemmel çalışıyor.⁴ Ve Apple M1 çip sayesinde şimdi popüler iPhone ve iPad
                    uygulamaları da Mac’te çalışıyor.
                  </p>
                  <a href='/assetWeb/docs/uretkenlik__TR.pdf' target={'_blank'}>
                    Üretkenlik (PDF)
                  </a>
                  <div className='col-md-12' style={{ marginTop: '170px', marginLeft: '45px' }}>
                    <IPImage src='/images/S6_Smart_1.png' alt='' className='popular_product' />
                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-6'>
              <div
                className='most-popular-products'
                style={{
                  minHeight: '550px',
                  height: '550px',
                  borderRadius: '20px',
                  marginTop: '20px',
                  backgroundColor: 'black',
                }}
              >
                <div className='room'>
                  <h4 style={{ color: 'white' }}>9. Neden</h4>
                  <h3 style={{ color: 'white' }}>Güvenlik Yerleşik kolaylık sağlıyor.</h3>
                  <p style={{ color: 'white' }}>
                    Donanım tarafından doğrulanan güvenli başlatma, anında şifreleme, Touch ID ve Gatekeeper gibi
                    yerleşik özellikler sayesinde Mac, kutusundan çıkar çıkmaz güvenli.
                  </p>
                </div>
                <a href='/assetWeb/docs/platform_Guvenligi__TR.pdf' target={'_blank'}>
                  Platform Güvenliği (PDF)
                </a>
                <div className='col-md-12' style={{ marginTop: '170px', marginLeft: '420px' }}>
                  <IPImage src='/images/S6_Icon_ffffff_1.png' alt='' className='popular_product' />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px', borderRadius: '20px' }}>
            <div
              className='most-popular-products'
              style={{
                minHeight: '500px',
                height: '500px',
                borderRadius: '20px',
                marginTop: '20px',
              }}
            >
              <div className='row p-20'>
                <div className='col-md-6 p-20'>
                  <h4>10. Neden</h4>
                  <h3>Mac ❤️ iPhone.</h3>
                  <p>
                    Mac, çalışanlarınızın bildiği ve sevdiği diğer Apple aygıtlarıyla aynı hissi veriyor. Üstelik tüm bu
                    aygıtlar birlikte kusursuz çalışıyor. Aygıtlar arasında kopyalama ve yapıştırma işlemi yapın.
                    Ekranınızı genişletin. Ve aramalara istediğiniz yerden yanıt verin.
                  </p>
                </div>
                <div className='col-md-6'>
                  <IPImage src='/images/S7_Smart_1.png' alt='' className='popular_product' />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px', borderRadius: '20px' }}>
            <div
              className='most-popular-products'
              style={{
                minHeight: '500px',
                height: '500px',
                borderRadius: '20px',
                marginTop: '20px',
              }}
            >
              <div className='row p-20'>
                <div className='col-md-6 p-20'>
                  <h4>11. Neden</h4>
                  <h3>Onunla çalışmak büyük zevk.</h3>
                  <p>Ekiplerinize sevdikleri aygıtları verin ve işe koyulun.</p>
                  <a href='/assetWeb/docs/calisanlarin_secimi_Kilavuzu__TR.pdf' target={'_blank'}>
                    Çalışanların Seçimi Kılavuzu (PDF)
                  </a>
                </div>
                <div className='col-md-6'>
                  <IPImage src='/images/S8_Smart_1.png' alt='' className='popular_product' />
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '20px', padding: '20px' }}>
            <div className='row'>
              <div className='col-md-3' style={{ marginTop: '80px' }}>
                <div className='row' style={{ justifyContent: 'center', marginTop: '20px' }}>
                  <IPImage src='/images/S9_1_Smart_1.png' alt='' className='popular_product' />
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                  <h3>
                    <b>
                      <br />
                      MacBook Air
                    </b>
                  </h3>
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                  <p>
                    Hem güçlü hem değerli.
                    <br /> MacBook Air, işletmenizin <br />
                    boyutu ne olursa olsun sizin için <br /> mükemmel bir seçim.
                  </p>
                </div>
                <div className='row' style={{ justifyContent: 'center', marginTop: '20px' }}>
                  <Link to='/products?searchKey=mac'>
                    <IPButton
                      className='col-md-1'
                      text='Satın Alın'
                      style={{
                        backgroundColor: 'white',
                        border: '2px',
                        color: 'black',
                        borderColor: 'black',
                        borderStyle: 'inset',
                        borderRadius: '10px',
                      }}
                    />
                  </Link>
                </div>
              </div>
              <div className='col-md-3' style={{ marginTop: '80px' }}>
                <div className='row' style={{ justifyContent: 'center', marginTop: '20px' }}>
                  <IPImage src='/images/S9_2_Smart_1.png' alt='' className='popular_product' />
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                  <h3>
                    <b>
                      13 inç <br /> MacBook Pro
                    </b>
                  </h3>
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                  <p>
                    Apple M1 çip, bu güçlü <br /> dizüstü bilgisayarı yepyeni <br /> bir profesyonellik düzeyine <br />{' '}
                    taşıyor.
                  </p>
                </div>
                <div className='row' style={{ justifyContent: 'center', marginTop: '20px' }}>
                  <Link to='/products?searchKey=mac'>
                    <IPButton
                      className='col-md-1'
                      text='Satın Alın'
                      style={{
                        backgroundColor: 'white',
                        border: '2px',
                        color: 'black',
                        borderColor: 'black',
                        borderStyle: 'inset',
                        borderRadius: '10px',
                      }}
                    />
                  </Link>
                </div>
              </div>
              <div className='col-md-3' style={{ marginTop: '80px' }}>
                <div className='row' style={{ justifyContent: 'center', marginTop: '20px' }}>
                  <IPImage src='/images/S9_3_Smart_1.png' alt='' className='popular_product' />
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                  <h3>
                    <b>
                      16 inç <br /> MacBook Pro
                    </b>
                  </h3>
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                  <p>
                    Olağanüstü işlem gücü ve <br /> göz alıcı büyük boy <br /> ekranıyla harika işler <br /> çıkarmanız
                    için tasarlandı.
                  </p>
                </div>
                <div className='row' style={{ justifyContent: 'center', marginTop: '20px' }}>
                  <Link to='/products?searchKey=mac'>
                    <IPButton
                      className='col-md-1'
                      text='Satın Alın'
                      style={{
                        backgroundColor: 'white',
                        border: '2px',
                        color: 'black',
                        borderColor: 'black',
                        borderStyle: 'inset',
                        borderRadius: '10px',
                      }}
                    />
                  </Link>
                </div>
              </div>
              <div className='col-md-3'>
                <div className='row' style={{ justifyContent: 'center', marginTop: '20px' }}>
                  <IPImage src='/images/S9_Smart_4.png' alt='' className='popular_product' />
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                  <h3>
                    <b>24 inç iMac</b>
                  </h3>
                </div>
                <div className='row' style={{ textAlign: 'center' }}>
                  <p>
                    Her işin üstesinden gelen <br /> bu masaüstü bilgisayar size <br /> daha fazla bağlantı noktası,{' '}
                    <br /> daha fazla güç ve daha çok <br /> yönlü kullanım imkanı
                    <br /> sunuyor.
                  </p>
                </div>
                <div className='row' style={{ justifyContent: 'center', marginTop: '20px' }}>
                  <Link to='/products?searchKey=mac'>
                    <IPButton
                      className='col-md-1'
                      text='Satın Alın'
                      style={{
                        backgroundColor: 'white',
                        border: '2px',
                        color: 'black',
                        borderColor: 'black',
                        borderStyle: 'inset',
                        borderRadius: '10px',
                      }}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className='row' style={{ textAlign: 'center' }}>
            <p>
              1. Testler Apple tarafından Ekim 2020’de, tümü 16 GB RAM ve 2 TB SSD ile yapılandırılmış, Apple M1 çipe ve
              8 <br />
              çekirdekli GPU’ya sahip ön üretim MacBook Air sistemleri ve 1.2 GHz dört çekirdekli Intel Core i7 tabanlı,
              üretim <br />
              aşamasındaki MacBook Air sistemleri kullanılarak gerçekleştirilmiştir. Final Cut Pro 10.5 ön sürümü ile,
              4096 x 2160 <br />
              çözünürlükte ve saniyede 59,94 kare hızında 4K Apple ProRes RAW medya içeren ve Apple ProRes 422’ye <br />
              dönüştürülen 55 saniyelik bir klip kullanılarak test edilmiştir. Performans testleri belirli bilgisayar
              sistemleri <br />
              kullanılarak gerçekleştirilmiştir ve MacBook Air’in yaklaşık performansını yansıtmaktadır.
              <br />
              2. Testler Apple tarafından Ekim 2020’de, 8 GB RAM ve 512 GB SSD ile yapılandırılmış, Apple M1 çipe ve 8
              <br />
              çekirdekli GPU’ya sahip ön üretim MacBook Air sistemleri kullanılarak gerçekleştirilmiştir. Apple TV
              uygulamasından
              <br />
              film oynatma testi, pil ömrünü, ekran parlaklığı en düşükten 8 tık yukarı ayarlanmış halde 1080p HD içerik
              oynatarak
              <br />
              ölçer. Pil ömrü kullanıma ve yapılandırmaya göre değişir. Daha fazla bilgi için lütfen
              apple.com/tr/batteries sayfasını
              <br />
              ziyaret edin.
              <br />
              3. Apple’ın kendi verileri ve Boston Consulting Group’un En Yenilikçi Şirketler 2021 listesi baz
              alınmıştır
              <br />
              (https://www.bcg.com/en-us/publications/2021/most-innovative-companies-overview).
              <br />
              4. Uygulamalar App Store’dan edinilebilir. Sunulan eserler değişebilir.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
