import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IOrderOrPaymentDetail, IPaymentDetailRequest } from '../models/models'

export class PostPaymentDetailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealerInformation/paymentDetail',
      requesterInfo,
    })
  }

  public async postPaymentDetail(request: any): Promise<IOrderOrPaymentDetail> {
    return await this.postAsync<IOrderOrPaymentDetail>(request)
  }
}
