import { useEffect, useMemo, useRef, useState } from 'react'
import { GetSessionApi } from './services/getSessionApi'
import { ISession } from './models/models'
import Swal from 'sweetalert2'
import { CloseSessionApi } from './services/closeSessionApi'
import Table from '../../../../components/Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import IPInput from '../../../../components/IPInput'

function Index(props: BaseComponentWithContextProps) {
  const [session, setSessions] = useState<ISession[]>([])
  const [filterSession, setFilterSessions] = useState<ISession[]>([])
  const [searchData, setSearchData] = useState<any>("")

  useEffect(() => {
    getSession('')
  }, [])

  const getSession = async (code: string) => {
    props.showLoading()
    const api: GetSessionApi = new GetSessionApi({})
    api.getSession().then((res: ISession[]) => {
      props.hideLoading()
      setSessions(res.sort((a, b) => Number(a.AccountCode) - Number(b.AccountCode)))
      setFilterSessions(res.sort((a, b) => Number(a.AccountCode) - Number(b.AccountCode)))
    }).catch((e: any) => {
      Swal.fire({
        icon: 'error',
        title: e.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    })
  }

  const closedSession = async (data: any) => {
    Swal.fire({
      icon: 'question',
      title: 'Kullanıcının oturumunu sonlandırmak istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const closeSessionApi = new CloseSessionApi({})
        const isDeleted = await closeSessionApi.postSession(data)
        props.hideLoading()
        if (isDeleted) {
          getSession('')
          Swal.fire({
            icon: 'success',
            title: 'Kullanıcı Oturumu sonlandırıldı.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Hesap Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Hesap Adı/Ünvan',
        accessor: 'col3',
      },
      {
        Header: 'Kullanıcı Kodu',
        accessor: 'col4',
      },
      {
        Header: 'IP Adres',
        accessor: 'col5',
      },
      {
        Header: 'Ziyaretçi ID',
        accessor: 'col6',
      },
      {
        Header: 'Başlangıç',
        accessor: 'col7',
      },
      {
        Header: 'Son Erişim',
        accessor: 'col8',
      },
      {
        Header: 'İşlemler',
        accessor: 'col9',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      filterSession.map((dt, index) => {
        const data = {
          col1: index + 1,
          col2: dt.AccountCode.replace('0000', ''),
          col3: dt.DealerName,
          col4: dt.AccountName,
          col5: dt.IPAddress,
          col6: dt.VisitorId,
          col7: dt.Beginning,
          col8: dt.LastAccess,
          col9: <a data-action-type="close" style={{ cursor: 'pointer' }}><i className="fa fa-power-off" onClick={() => { closedSession(dt) }} style={{ color: 'red', marginLeft: '10px' }}></i></a>,
        }
        return data
      }),
    [filterSession]
  )

  useEffect(() => {
    setFilterSessions(session.filter(a => a.AccountCode.includes(searchData) || a.DealerName.toLowerCase().includes(searchData.toString().toLowerCase())))
  }, [searchData])

  return (
    <div className='container'>
      <div className='content col-lg-12'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              <h5>Açık Oturum Sonlandırma</h5>
            </span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-4'>
                <IPInput
                  type='text'
                  name='search'
                  value={searchData}
                  placeholder='Arama'
                  onChange={e => {
                    const data = e.target.value
                    setSearchData(data)
                  }}
                />
              </div>
              <div className='col-lg-12'>
                <Table columns={tableColumns} data={tableData} currentPage={0} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(Index)