import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { NoticeData } from '../models/models'

export class ListNoticeApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/GetHomepageNotice',
      requesterInfo,
    })
  }

  public async listNoticeApi(): Promise<NoticeData[]> {
    return await this.getAsync<NoticeData[]>()
  }
}
