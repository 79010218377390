import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IProductIsActive } from '../models/models'

export class UpdateProductBySearch extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/updateProductBySearch',
      requesterInfo,
    })
  }

  public async updateProductBySearch(req: any): Promise<any[]> {
    return await this.postAsync<any[]>(req)
  }
}
