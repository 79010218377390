import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IWarehouses } from '../models/models'

export class GetWarehousesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/general/warehouses',
      requesterInfo,
    })
  }

  public async getWarehouses(): Promise<IWarehouses[]> {
    return await this.getAsync<IWarehouses[]>()
  }
}
