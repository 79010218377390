// import { ReactNode, useState } from 'react'

// interface IIPAccordionProps {
//   name: string
//   labelName: ReactNode
//   children?: ReactNode
//   isExpanded?: boolean
// }

// export default function IPAccordion(props: IIPAccordionProps) {
//   const onChange = () => {
//     setExpanded(isExpanded => !isExpanded)
//   }

//   const [isExpanded, setExpanded] = useState(props.isExpanded ? true : false)

//   // return (
//   //   <div className='accordion accordion-flat'>
//   //     <div id={`${props.name}`} className={`${isExpanded ? 'ac-item ac-active' : 'ac-item'}`}>
//   //       <h5 onClick={onChange} className='ac-title' id='title'>
//   //         {props.labelName}
//   //       </h5>
//   //       <div className='ac-content' style={{ display: `${isExpanded ? '' : 'none'}` }}>
//   //         {props.children}
//   //       </div>
//   //     </div>
//   //   </div>
//   // )
//   return (
//     <div className='accordion accordion-flat'>
//       <div id={`${props.name}`} className={`${isExpanded ? 'ac-item ac-active' : 'ac-item'}`}>
//         <h5 onClick={onChange} className='ac-title' id='title'>
//           {props.labelName}
//         </h5>
//         <div
//           className='ac-content'
//           style={{
//             display: `${isExpanded ? '' : 'none'}`,
//             maxHeight: '200px',
//             overflowY: 'auto',
//             transition: 'max-height 0.3s ease',
//           }}
//         >
//           {props.children}
//         </div>
//       </div>
//     </div>
//   )
// }

import { ReactNode, useState } from 'react'
import IPCheckbox from '../IPCheckBox'
import React from 'react'
interface IIPAccordionProps {
  name: string
  labelName: ReactNode
  children?: ReactNode
  isExpanded?: boolean
  searchBox?: boolean
}
export default function IPAccordion(props: IIPAccordionProps) {
  const [isExpanded, setExpanded] = useState(props.isExpanded || false)
  const [searchTerm, setSearchTerm] = useState('')

  const onChange = () => {
    setExpanded(prevExpanded => !prevExpanded)
  }

  const filteredAttributes = props.children
    ? React.Children.toArray(props.children).filter(child => {
      if (!props.searchBox) {
        return true
      }

      if (React.isValidElement(child) && child.type === IPCheckbox) {
        return (child.props.label as string).toLowerCase().includes(searchTerm.toLowerCase())
      }

      return true
    })
    : []

  return (
    <div className='accordion accordion-flat'>
      <div id={`${props.name}`} className={`${isExpanded ? 'ac-item ac-active' : 'ac-item'}`}>
        <h5 onClick={onChange} className='ac-title' id='title' style={{ textTransform: 'capitalize' }}>
          {props.labelName}
        </h5>
        {props.searchBox && isExpanded && (
          <>
            <div className='search-box'>
              <input
                style={{ width: '80%', margin: '10px 5% 0px 5%', borderRadius: '150px' }}
                type='text'
                placeholder='Filtrele'
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
            <div className='line mt-3 mb-1'></div>
          </>
        )}
        <div
          className='ac-content'
          style={{
            display: isExpanded ? 'block' : 'none',
            maxHeight: '200px',
            overflowY: 'auto',
            transition: 'max-height 0.3s ease',
          }}
        >
          <div className='scrollable-content'>{filteredAttributes}</div>
        </div>
      </div>
    </div>
  )
}
