import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
export class GetProductLinkApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'productLink/getById/',
      requesterInfo,
    })
  }

  public async getProductLink(id: number): Promise<any> {
    this.props.url = `${this.props.url}${id}`
    return await this.getAsync<any>()
  }
}
