import { IBannerFilteredList } from './../models/requestModels'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IBanner } from '../models/responseModels'

export class BannerFilteredListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/banner/filteredList',
      requesterInfo,
    })
  }

  public async bannerFilteredList(request: IBannerFilteredList): Promise<IBanner[]> {
    return await this.postAsync<IBanner[]>(request)
  }
}
