import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { css } from '@emotion/css'
import Swal from 'sweetalert2'

import { IyzicoCampaignModel } from './IyzicoCampaignModel'
import { IyzicoCampaignService } from './IyzicoCampaignService'
import IPInput from '../../../../components/IPInput'
import './IyzicoCampaign.scss'
import IPModal from '../../../../components/IPModal'
import moment from 'moment'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'

interface ISortControl {
  Name: string
  Enabled: boolean
}

function IyzicoCampaign(props: BaseComponentWithContextProps) {
  const navigate = useNavigate()

  const [campaigns, setCampaigns] = useState<IyzicoCampaignModel[]>([])
  const [sortControl, setSortControl] = useState<ISortControl | null>(null)
  const [searchInput, setSearchInput] = useState('')
  const [history, setHistory] = useState<any>()
  const [show, setShowHistory] = useState<boolean>(false)


  const getCampaigns = async () => {
    try {
      const res: any = await new IyzicoCampaignService({}).list()
      if (res) {
        let campaigns: any = []
        campaigns = res.map(item => ({ ...item, StartDate: new Date(item.StartDate), EndDate: new Date(item.EndDate) }))

        setCampaigns(campaigns)
      }
    } catch (err) {
      console.log('Fetch Iyzico Campaigns Error:', err)
    }
  }

  const sortColumn = (name: string) => {
    return { Name: name, Enabled: sortControl?.Enabled ? !sortControl.Enabled : true }
  }

  const renderArrow = (name: string) => {
    return sortControl?.Name === name ? (
      sortControl?.Enabled ? (
        <span className='arrowDown'></span>
      ) : (
        <span className='arrowUp'></span>
      )
    ) : (
      ''
    )
  }

  useEffect(() => {
    getCampaigns()
    setSortControl(sortColumn('Id'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteCampaign = async (e, id) => {
    e.stopPropagation()
    const deleteRecord = async () => {
      const res = await new IyzicoCampaignService({}).delete(id)
      if (res) {
        Swal.fire('Başarılı', res, 'success')
        setCampaigns(prevState => prevState.filter(state => state.Id !== id))
      }
    }
    Swal.fire({
      icon: 'error',
      title: 'Kampanya silinecek emin misiniz?',
      showCloseButton: true,
      showDenyButton: true,
      confirmButtonText: 'Sil!',
      confirmButtonAriaLabel: 'Sil',
      confirmButtonColor: '#ec4c4c',
      denyButtonText: 'İptal',
      denyButtonAriaLabel: 'İptal',
      denyButtonColor: '#AAA',
    }).then(result => {
      if (result.isConfirmed) {
        try {
          deleteRecord()
        } catch (err: any) {
          Swal.fire('Hata', err.description, 'error')
        }
      }
    })
  }

  const historyCampaign = async (id: number) => {
    props.showLoading()
    const campaignHistory: IyzicoCampaignService = new IyzicoCampaignService({})
    try {
      await campaignHistory
        .historyByID(id)
        .then(res => {
          setHistory(res)
          setShowHistory(true)
          props.hideLoading()
        })
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  }

  return (
    <div className='container IyzicoCampaign'>
      <div className='d-flex justify-content-between align-items-center my-3'>
        <h1
          className={css`
            font-size: 32px;
          `}
        >
          Iyzico Kampanyalar
        </h1>
        <Link to='/admin/product-operation/iyzico-campaign/add' className='btn btn-success'>
          Kampanya Ekle
        </Link>
      </div>

      <div className='table-responsive' style={{ height: 'auto' }}>
        <table className='campaignTable table table-bordered table-hover'>
          <thead>
            <tr>
              <th scope='col' onClick={() => setSortControl(sortColumn('Id'))}>
                <div className='d-flex align-items-center gap-2'>#{renderArrow('Id')}</div>
              </th>
              <th scope='col' onClick={() => setSortControl(sortColumn('Name'))}>
                <div className='d-flex align-items-center gap-2'>
                  KAMPANYA ADI
                  {renderArrow('Name')}
                </div>
              </th>
              <th scope='col' onClick={() => setSortControl(sortColumn('StartDate'))}>
                <div className='d-flex align-items-center gap-2'>
                  BAŞLANGIÇ TARİHİ
                  {renderArrow('StartDate')}
                </div>
              </th>
              <th scope='col' onClick={() => setSortControl(sortColumn('EndDate'))}>
                <div className='d-flex align-items-center gap-2'>
                  BİTİŞ TARİHİ
                  {renderArrow('EndDate')}
                </div>
              </th>
              <th scope='col' onClick={() => setSortControl(sortColumn('Company'))}>
                <div className='d-flex align-items-center gap-2'>
                  Şirket
                  {renderArrow('Company')}
                </div>
              </th>
              <th scope='col' onClick={() => setSortControl(sortColumn('Enabled'))}>
                <div className='d-flex align-items-center gap-2'>
                  DURUMU
                  {renderArrow('Enabled')}
                </div>
              </th>
              <th scope='col'>İŞLEMLER</th>
            </tr>
          </thead>

          <tbody>
            <tr className='inputRow'>
              <td colSpan={6}>
                <IPInput
                  type='text'
                  placeholder='Ara...'
                  value={searchInput}
                  onChange={e => setSearchInput(e.target.value)}
                />
              </td>
            </tr>

            {campaigns &&
              campaigns
                .filter((c: any) => {
                  const filterWord = searchInput.toLowerCase()

                  const filterStatus = c.Enabled ? 'Aktif' : 'Pasif'

                  let filteredData =
                    c.Name.toLowerCase().includes(filterWord) ||
                    c.Id.toString().toLowerCase().includes(filterWord) ||
                    filterStatus.toLowerCase().includes(filterWord) ||
                    c.StartDate.toLocaleDateString('tr-TR').includes(filterWord) ||
                    c.EndDate.toLocaleDateString('tr-TR').includes(filterWord)

                  return filteredData
                })
                .sort((a, b) => {
                  if (sortControl !== null) {
                    return sortControl.Enabled
                      ? a[sortControl.Name] < b[sortControl.Name]
                        ? 1
                        : -1
                      : a[sortControl.Name] > b[sortControl.Name]
                        ? 1
                        : -1
                  }
                  return 0
                })
                .map(campaign => (
                  <tr key={campaign.Id}>
                    <td>{campaign.Id}</td>
                    <td>{campaign.Name}</td>
                    <td>{campaign.StartDate.toLocaleDateString('tr-TR')}</td>
                    <td>{campaign.EndDate.toLocaleDateString('tr-TR')}</td>
                    <td>{campaign.CompanyCodes.join(",")}</td>
                    <td>{campaign.Enabled ? 'Aktif' : 'Pasif'}</td>
                    <td className='position-relative'>
                      <div className='d-flex'>
                        <div
                          title='Düzenle'
                          className='tableIcon edit'
                          onClick={e => {
                            e.stopPropagation()
                            navigate('/admin/product-operation/iyzico-campaign/add', {
                              state: { data: campaign, edit: true },
                            })
                          }}
                        >
                          <i className='icon-edit'></i>
                        </div>
                        <div
                          title='Kopyala'
                          className='tableIcon'
                          onClick={e => {
                            e.stopPropagation()
                            navigate('/admin/product-operation/iyzico-campaign/add', {
                              state: { data: campaign, edit: false },
                            })
                          }}
                        >
                          <i className='icon-copy'></i>
                        </div>
                        <div className='tableIcon delete' title='Sil' onClick={e => deleteCampaign(e, campaign.Id)}>
                          <i className='icon-delete'></i>
                        </div>
                        <div className='tableIcon clock' title='Tarihçe' onClick={() => historyCampaign(campaign.Id)}>
                          <i className='icon-clock'></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
          </tbody>
          <IPModal
            show={show}
            onClose={() => {
              setHistory([])
              setShowHistory(false)
            }
            }
            hideConfirm={true}
            width={1800}
            title='Tarihçe'
          >
            <table className='table'>
              <thead>
                <tr role='row' className='odd'>
                  <th>#</th>
                  <th>Tarih</th>
                  <th>Aktif</th>
                  <th>Şirket</th>
                  <th>Depo</th>
                  <th>Marka ID</th>
                  <th>Kota</th>
                  <th>Ürün Alt Tipi</th>
                  <th>Ürün Kodu</th>
                  <th>Taksit</th>
                  <th>Bayi Limit</th>
                  <th>Kampanya Limit</th>
                  <th>Bayi Kodu</th>
                  <th>Kullanıcı Adı</th>
                </tr>
              </thead>

              <tbody>
                {
                  history && history.length > 0 && history.map((his) => (
                    <tr role='row' className='odd' key={his.Id}>
                      <td>{<a
                        className='btn btn-transparent'
                        style={{ color: 'blue' }}
                        type='button'
                        data-action-type='order'
                        onClick={() => {
                          navigate('/admin/product-operation/iyzico-campaign/add', {
                            state: { data: his, history: true },
                          })
                        }}
                      >
                        {his.Id}
                      </a>}</td>
                      <td>{his.UpdateTime && moment(his.UpdateTime).format('DD.MM.YYYY HH:mm:ss')}</td>
                      <td>{his.Enabled ? "Evet" : "Hayır"}</td>
                      <td style={{ color: his.IsCompanyCodesInclude ? "green" : "red" }}>
                        {his.CompanyCodes && his.CompanyCodes.map((code, index) => (
                          <>
                            {code === "1000" ? "Index" : code === "1100" ? "Datagate" : code === "1200" ? "Despec" : code === "2300" ? "Netex" : ""}{index !== his.CompanyCodes.length - 1 && ","}
                          </>
                        ))}
                      </td>
                      <td style={{ color: his.IsWarehouseCodesInclude ? "green" : "red" }}>
                        {his.WarehouseCodes && his.WarehouseCodes.map((code, index) => (
                          <>
                            {code}{index !== his.WarehouseCodes.length - 1 && ","}
                          </>
                        ))}
                      </td>
                      <td style={{ color: his.IsBrandIdListInclude ? "green" : "red" }}>
                        {his.BrandIdList && his.BrandIdList.map((code, index) => (
                          <>
                            {code}{index !== his.BrandIdList.length - 1 && ","}
                          </>
                        ))}
                      </td>
                      <td style={{ color: his.IsQuotaInclude ? "green" : "red" }}>
                        {his.Quota && his.Quota.map((code, index) => (
                          <>
                            {code}{index !== his.Quota.length - 1 && ","}
                          </>
                        ))}
                      </td>
                      <td style={{ color: his.IsProductSubTypesInclude ? "green" : "red" }}>
                        {his.ProductSubTypes && his.ProductSubTypes.map((code, index) => (
                          <>
                            {code}{index !== his.ProductSubTypes.length - 1 && <br></br>}
                          </>
                        ))}
                      </td>
                      <td style={{ color: his.IsProductCodesInclude ? "green" : "red" }}>
                        {his.ProductCodes && his.ProductCodes.map((code, index) => (
                          <>
                            {code}{index !== his.ProductCodes.length - 1 && <br></br>}
                          </>
                        ))}
                      </td>
                      <td>
                        {his.Installments && his.Installments.map((code, index) => (
                          <>
                            {code.Installment + " Taksit % " + code.InterestRate + " Komisyon"}
                            <br></br>

                          </>
                        ))}
                      </td>
                      <td>{his.DealerLimit}</td>
                      <td>{his.CampaignLimit}</td>
                      <td>{his.UserDealer && his.UserDealer.replace('0000', '')}</td>
                      <td>{his.UserName}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </IPModal>
        </table>
      </div>
    </div>
  )
}
export default componentWithContext(IyzicoCampaign)