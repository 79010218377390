import React, { useEffect, useRef } from 'react'
import FullCalendar, {
  CalendarApi,
  DateSelectArg,
  EventAddArg,
  EventApi,
  EventClickArg,
  EventContentArg,
} from '@fullcalendar/react'
import dayGridPlugin, { DayGridView } from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import { useState } from 'react'
import { Calendar } from '@fullcalendar/core'
import { uuidv4 } from '../../../../../base/utils'
import IPModal from '../../../../../components/IPModal'
import classes from '../../../../Admin/DealerInformationFormOperation/DealerInformation/components/DealerInformation.module.scss'
import IPInput from '../../../../../components/IPInput'
import IPButton from '../../../../../components/IPButton'
import { useFormik } from 'formik'
import IPFile from '../../../../../components/IPFile'
import { createImageUrl, getUploadFileRequest, handleImageUpload } from '../../../../../base/utils/dataHelper'
import IPTextarea from '../../../../../components/IPTextarea'
import * as yup from 'yup'
import { WebinarCalanderRequestModel, WebinarCalanderUpdateRequestModel } from '../models/WebinarCalanderRequestModel'
import { GetAllEvents, WebinarCalanderAdminService } from '../services/WebinarCalanderAdminService'
import '../../../../MySpecial/WebinarCalendar/webinarCalander.scss'
import moment from 'moment'
import Swal from 'sweetalert2'

interface presenterList {
  Id: string
  NameSurname: string
  Description?: string
  IsReadOnly?: boolean
}

export default function WebinarCalendarAdmin() {
  let todayStr = new Date().toISOString().replace(/T.*$/, '') // YYYY-MM-DD of today

  const calendar = useRef<any>()

  const [eventsCheck, setEventsCheck] = useState<Array<EventApi>>([])
  const [modalControl, setModalControl] = useState(false)
  const [presentersList, setPresenterList] = useState<presenterList[]>([])
  const [presenterInput, setPresenterInput] = useState('')
  const [selectInfoCalander, setSelectInfoCalander] = useState<DateSelectArg>()
  const [presenterInputError, setPresenterInputError] = useState(false)
  const [webinarData, setWebinarData] = useState<any[]>()
  const [loadedImage, setLoadedImage] = useState<any>(null)
  const [showBtnGroup, setShowBtnGroup] = useState(false)
  const [selectedEventId, setSelectedEventId] = useState('')
  const [selectedEvent, setSelectedEvent] = useState<any>({})
  const [clickInfoEvent, setClickInfoEvent] = useState<any>()
  const [changedEvent, setChangedEvent] = useState(false)
  const [isDeleteEventImage, setIsDeleteEventImage] = useState(false)
  const inititalStartDate = new Date()
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [selectedTime, setSelectedTime] = useState<any>(moment(inititalStartDate).format('HH:mm'))

  function createEventId() {
    return uuidv4()
  }

  const handleEvents = (events: EventApi[]) => {
    setEventsCheck(events)
  }

  const getEvents = async () => {
    const getWebinarCalander = new GetAllEvents({})
    const res = await getWebinarCalander.getAllEvents()
    if (res) {
      const events = res.map(item => {
        return {
          id: item.Id,
          title: item.Title,
          description: item.Description,
          meetLink: item.MeetLink,
          meetRegisterLink: item.MeetRegisterLink,
          allDay: item.AllDay,
          start: new Date(item.StartDate).toISOString(),
          end: new Date(item.EndDate).toISOString(),
          presenters: item.Presenters,
          eventImage: item.EventImage,
          eventImageUrl: item.EventImageUrl,
        }
      })
      setWebinarData(events ? events : [])
    }
  }

  useEffect(() => {
    getEvents()
  }, [])

  useEffect(() => { }, [eventsCheck])

  useEffect(() => {
    if (modalControl == false) {
      setShowBtnGroup(false)
      resetForm()
      setPresenterList([])
      setLoadedImage(null)
      setChangedEvent(false)
      setIsDeleteEventImage(false)
      setSelectedTime(moment(inititalStartDate).format('HH:mm'))
      // setDeleteEventControl(false)
    }
  }, [modalControl])

  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <>
        {/* <div>Time Text</div> */}
        <b className='d-block' style={{ color: 'white', fontSize: '14px' }}>
          {' '}
          {eventContent.event.title}{' '}
        </b>
        <br />
        <div className='d-block' style={{ color: 'white', fontSize: '12px' }}>
          {' '}
          {eventContent.event._def.extendedProps.description}{' '}
        </div>
        {/* <a href={eventContent.event._def.extendedProps.meetLink} target='_blank' style={{ color: "white", fontSize: "12px" }}> {eventContent.event._def.extendedProps.meetLink} </a> */}
      </>
    )
  }
  //Upload event image function
  const handleFiles = async e => {
    const eventImageList = await getUploadFileRequest(e)
    if (e && e.target && e.target.files && e.target.files[0]) {
      const base64Str = await handleImageUpload(e.target.files[0])
      setLoadedImage(base64Str)
      setChangedEvent(true)
    }

    setFieldValue('eventImage', eventImageList[0])
  }

  const handleEventClick = (clickInfo: EventClickArg) => {
    if (clickInfo.event._def.publicId) {
      // alert(`${clickInfo.event._def.publicId} id'li etkinliğe yönlendirileceksiniz`)
      let clickedEvent
      webinarData?.forEach(item => {
        if (item.id == clickInfo.event._def.publicId) clickedEvent = item
      })
      setValues(clickedEvent as any)
      setSelectedTime(moment(clickedEvent.start).format('HH:mm'))
      setSelectedEventId(clickedEvent.id)
      setSelectedEvent(clickedEvent)
      setPresenterList(clickedEvent.presenters)
      //const docsResponse = JSON.parse(clickedEvent.eventImage as any)
      setLoadedImage(clickedEvent.eventImage)
      setShowBtnGroup(true)
      setModalControl(true)
    }
    setClickInfoEvent(clickInfo)
    // alert("Etkinliği siliniyor?")
    // if (deleteEventControl) clickInfo.event.remove()
  }
  const handleDateSelect = (selectInfo: DateSelectArg) => {
    setModalControl(true)
    setSelectInfoCalander(selectInfo)
  }
  const addEventToCalander = () => {
    let calanderApi = selectInfoCalander?.view.calendar

    calanderApi?.unselect()
    if (values.title && values.description) {
      calanderApi?.addEvent({
        id: createEventId(),
        title: values.title,
        description: values.description,
        meetLink: values.meetLink,
        meetRegisterLink: values.meetRegisterLink,
        presenters: values.presenters,
        start: selectInfoCalander?.startStr,
        end: selectInfoCalander?.endStr,
        allDay: selectInfoCalander?.allDay,
      })
    }
    // calanderApi?.refetchEvents()
    setModalControl(false)
  }
  const closeDocumentModal = () => {
    setModalControl(false)
  }
  const validationSchema = yup.object().shape({
    title: yup.string().min(3, 'En az 3 karakter girilmelidir!').required('Lütfen Konuyu Girin!'),
    description: yup.string().min(3, 'En az 3 karakter girilmelidir!').required('Lütfen Açıklamayı Girin!'),
  })

  const { handleSubmit, handleChange, handleBlur, setFieldValue, setValues, values, errors, touched, resetForm } =
    useFormik({
      initialValues: {
        title: '',
        description: '',
        presenters: presentersList,
        meetLink: '',
        meetRegisterLink: '',
        eventImage: null,
        timem: selectedTime
      },
      validationSchema,
      onSubmit: async () => {
        const isValidTimeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/.test(values.timem);

        if (!isValidTimeFormat) {
          Swal.fire({
            icon: 'error',
            title: 'Hata',
            text: 'Hatalı saat formatı',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          return
        }

        const req: WebinarCalanderRequestModel = {
          id: createEventId(),
          title: values.title,
          description: values.description,
          MeetLink: values.meetLink,
          MeetRegisterLink: values.meetRegisterLink,
          presenters: presentersList,
          startDate: selectInfoCalander?.startStr,
          endDate: selectInfoCalander?.endStr,
          allDay: selectInfoCalander?.allDay,
          File: values.eventImage,
        }
        req.startDate = selectInfoCalander?.startStr + "T" + values.timem
        const webinarCalanderApplication = new WebinarCalanderAdminService({})
        const res = await webinarCalanderApplication.postWebinarCalanderApplication(req)
        if (res) setModalControl(false)
        addEventToCalander()
        resetForm()
        setPresenterList([])
        getEvents().then(() => window.location.reload())
      },
    })
  const deleteEvent = async () => {
    const req = {
      Id: selectedEventId,
    }
    const webinarCalanderApplication = new WebinarCalanderAdminService({})
    const res = await webinarCalanderApplication.deleteEvent(selectedEventId)
    if (res)
      // setDeleteEventControl(true)
      setModalControl(false)
    clickInfoEvent.event.remove()
  }

  const updateEvent = async () => {
    const isValidTimeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/.test(values.timem);

    if (!isValidTimeFormat) {
      Swal.fire({
        icon: 'error',
        title: 'Hata',
        text: 'Hatalı saat formatı',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: WebinarCalanderUpdateRequestModel = {
      id: selectedEventId,
      title: values.title,
      description: values.description,
      MeetLink: values.meetLink,
      MeetRegisterLink: values.meetRegisterLink,
      presenters: presentersList,
      startDate: new Date(selectedEvent?.start),
      endDate: new Date(selectedEvent?.end),
      allDay: selectedEvent?.allDay,
      File: changedEvent ? values.eventImage : null,
      EventImage: isDeleteEventImage ? '' : loadedImage,
    }
    const [hours, minutes] = values.timem.split(':');
    req.startDate.setHours(parseInt(hours, 10), parseInt(minutes, 10));
    const webinarCalanderApplication = new WebinarCalanderAdminService({})
    const res = await webinarCalanderApplication.updateEvent(req)
    // if (res) {
    //   const calendarApi = calendar.current.getApi()
    //   calendarApi.getEventById(res.Id).remove()
    // }

    setModalControl(false)
    getEvents().then(() => window.location.reload())

    //   .then(() => {
    //     const calendarApi = calendar.current.getApi()
    //     const fff = calendarApi.getEventSources()
    //     // fff.remove()
    //     calendarApi.addEventSource(events)
    //     calendarApi.refetchEvents()
    // })
  }

  return (
    <div className='container webinarCalanderMySpecial'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Webinar Takvim</span>
          </div>
          <div className='card-body'>
            <div className='row mb-3'>
              <div className='col-lg-12'>
                {webinarData && (
                  <FullCalendar
                    ref={calendar}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'dayGridMonth timeGridWeek timeGridDay',
                    }}
                    eventsSet={(events: EventApi[]) => {
                      handleEvents(events)
                    }}
                    select={handleDateSelect}
                    eventClick={handleEventClick}
                    eventContent={renderEventContent}
                    initialEvents={webinarData}
                    locales={allLocales}
                    locale={'tr'}
                    firstDay={1}
                    initialView='dayGridMonth'
                    selectable={true}
                    editable={false}
                    buttonText={{
                      day: 'Gün',
                      month: 'Ay',
                      today: 'Bugün',
                      week: 'Hafta',
                    }}
                    eventAdd={(event: EventAddArg) => { }}
                    eventChange={(event: EventAddArg) => { }}
                    eventRemove={(event: EventAddArg) => { }}
                    eventBackgroundColor={'#ec4c4c'}
                    eventBorderColor={'#ee333e'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <IPModal
          width={900}
          show={modalControl}
          hideConfirm={true}
          onClose={closeDocumentModal}
          closeOnEscapeKey={true}
        >
          <div className={`container ${classes.documentModal}`}>
            <div className='row w-100'>
              <div className='col-lg-12'>
                <div className='text-center'>Etkinlik Detaylarını Belirleyin</div>
                <form onSubmit={handleSubmit}>
                  <div className={`position-relative ${errors.title ? 'mb-5' : 'mb-3'}`}>
                    <label>Konu</label>
                    <IPInput
                      type={'text'}
                      placeholder='Konuyu Girin'
                      className=''
                      name='title'
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.title}
                      touched={touched.title}
                    />
                  </div>
                  <div className={`position-relative ${errors.description ? 'mb-5' : 'mb-3'}`}>
                    <label>İçerik</label>
                    <IPInput
                      type={'text'}
                      name='description'
                      placeholder='İçeriği Açıklayın'
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors.description}
                      touched={touched.description}
                    />
                  </div>
                  <div className='position-relative mb-2'>
                    <label>Etkinlik Saati</label>
                    <IPInput
                      type='text'
                      id='timem'
                      name='timem'
                      value={selectedTime}
                      onChange={handleChange}
                    />
                  </div>
                  <div className='position-relative mb-2'>
                    <label>Etkinlik Linki</label>
                    <IPInput
                      type={'text'}
                      name='meetLink'
                      value={values.meetLink}
                      placeholder='Etkinlik Linki'
                      className=' mb-2'
                      onChange={handleChange}
                    />
                  </div>
                  <div className='mb-2'>
                    <label>Etkinlik Kayıt</label>
                    <IPInput
                      type={'text'}
                      name='meetRegisterLink'
                      value={values.meetRegisterLink}
                      placeholder='Etkinlik Kayıt Linki'
                      className=' mb-2'
                      onChange={handleChange}
                    />
                  </div>
                  <div className='mb-2 position-relative'>
                    <label className='d-block'>Etkinlik Resmi</label>
                    {loadedImage && (
                      <div className='position-relative d-inline-block'>
                        <div
                          className='position-absolute top-0 d-block text-white d-flex justify-content-center align-items-center'
                          style={{ width: '28px', height: '28px', backgroundColor: '#ec4c4c', right: 0, zIndex: 2 }}
                          onClick={() => {
                            setIsDeleteEventImage(true)
                            setLoadedImage(null)
                          }}
                        >
                          X
                        </div>
                        <div className='mb-4'>
                          <img className='d-block' src={changedEvent ? loadedImage : selectedEvent.eventImageUrl} />
                        </div>
                      </div>
                    )}
                    <IPFile
                      id={'eventImage'}
                      name='eventImage'
                      onChange={handleFiles}
                      onBlur={handleBlur}
                      multiple={false}
                      removeAfterSelect={true}
                    />
                  </div>

                  <div className={`position-relative ${errors.description || presenterInputError ? 'mb-5' : 'mb-2'}`}>
                    <label>Sunucular</label>
                    {presentersList && (
                      <ul>
                        {presentersList.map(item => {
                          return (
                            <li className='position-relative'>
                              {item.NameSurname}{' '}
                              <span
                                className='position-absolute'
                                style={{ top: '0px', right: '4px', zIndex: '2' }}
                                onClick={() => setPresenterList(presentersList.filter(i => item.Id !== i.Id))}
                              >
                                X
                              </span>
                            </li>
                          )
                        })}
                      </ul>
                    )}

                    <div className='d-flex gap-2'>
                      <div className='w-100'>
                        <IPInput
                          type={'text'}
                          placeholder='Sunum Yapanlar'
                          className=' mb-2'
                          name='presenters'
                          value={presenterInput}
                          onChange={e => {
                            setPresenterInput(e.target.value)
                            if (e.target.value.length < 3) {
                              setPresenterInputError(true)
                            } else {
                              setPresenterInputError(false)
                            }
                          }}
                        />
                        {presenterInputError && (
                          <div className='validationError'>Girdiğin isim 3 karakterden az olamaz!</div>
                        )}
                      </div>
                      <div className=''>
                        {/* <IPButton text={'Ekle'}
                        style={{ width: '10%' }}
                        onClick={() => {
                          if (presenterInput.length > 3) {
                            setPresenterList((prevState) => [...prevState, { id: uuidv4(), nameSurname: presenterInput, isReadOnly: true }])
                            setPresenterInput("")
                          }
                        }
                        }
                      /> */}
                        <div
                          className='position-relative d-flex justify-content-center align-items-center'
                          style={{ backgroundColor: '#ec4c4c', height: '47px', width: '47px' }}
                          onClick={() => {
                            if (presenterInput.length >= 3) {
                              setPresenterList(prevState => [
                                ...prevState,
                                { Id: uuidv4(), NameSurname: presenterInput, IsReadOnly: true },
                              ])
                              setPresenterInput('')
                            }
                          }}
                        >
                          <i className='icon-plus CellWithCommentUpdate' style={{ color: 'white', fontSize: '24px' }}>
                            <span className='CellCommentUpdateWebinarCalanderAdmin'>Ekle</span>
                          </i>{' '}
                        </div>
                      </div>
                    </div>
                  </div>

                  {presentersList.length > 0 && (
                    <>
                      <label>Sunum Görevleri</label>
                      {presentersList.map(item => {
                        return (
                          <>
                            <div
                              onClick={() => {
                                setPresenterList(prevState =>
                                  prevState.map(state => {
                                    if (state.Id === item.Id) {
                                      state.IsReadOnly = false
                                    }
                                    return state
                                  })
                                )
                              }}
                            >
                              {item.NameSurname} <br />
                              <div className='position-relative '>
                                <span
                                  className='position-absolute'
                                  style={{ top: '5px', right: '8px', zIndex: '2' }}
                                  onClick={() =>
                                    setPresenterList(prevState =>
                                      prevState.map(p => {
                                        if (p.Id === item.Id) {
                                          p.Description = ''
                                        }
                                        return p
                                      })
                                    )
                                  }
                                >
                                  X
                                </span>
                                <IPTextarea
                                  //name={item.description}
                                  value={item.Description}
                                  readOnly={item.IsReadOnly ? true : false}
                                  onBlur={() => {
                                    setPresenterList(prevState =>
                                      prevState.map(state => {
                                        if (state.Id === item.Id) {
                                          state.IsReadOnly = true
                                        }
                                        return state
                                      })
                                    )
                                  }}
                                  onChange={e => {
                                    setPresenterList(prevState =>
                                      prevState.map(state => {
                                        if (state.Id === item.Id) {
                                          state.Description = e.target.value
                                        }
                                        return state
                                      })
                                    )
                                  }}
                                  style={{
                                    display: 'inline',
                                    width: '100%',
                                    color: item.IsReadOnly ? '#999' : '#252525',
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </>
                  )}
                  {showBtnGroup && showBtnGroup ? (
                    <div className='col-12 d-flex gap-2'>
                      <IPButton text={'Sil'} type='button' onClick={() => deleteEvent()} />
                      <IPButton
                        text={'Güncelle'}
                        type='button'
                        onClick={() => updateEvent()}
                        style={{ backgroundColor: 'green' }}
                      />
                    </div>
                  ) : (
                    <IPButton text={'Kaydet'} type='submit' />
                  )}
                </form>
              </div>
            </div>
          </div>
        </IPModal>
      </div>
    </div>
  )
}
