import React from 'react'
import { Pie } from 'react-chartjs-2'
import { getRandomColor } from '../../../../../base/utils/dataHelper'
import { ISurveyAnswer, ISurveyReport } from '../models'

interface IChartProps {
  report: ISurveyReport
}

function ChartsPage1(props: IChartProps) {
  const backgroundColors = props.report.Answers.filter(a => a.IsInput == false).map(() => getRandomColor())
  const dataPie = {
    labels: props.report.Answers.filter(a => a.IsInput == false).map((ans: ISurveyAnswer) => ans.Options), // ["Uygun Fiyat %", "Teslim Süresi %", "Ürün Çeşitliliği %", "Tasarım/Kullanışlılık %", "Ödeme Seçenekleri %"],
    datasets: [
      {
        data: props.report.Answers.filter(a => a.IsInput == false).map((ans: ISurveyAnswer) => ans.TotalCount), // [40, 20, 10, 10, 20],
        backgroundColor: backgroundColors,
        hoverBackgroundColor: backgroundColors,
      },
    ],
  }

  return (
    <div>
      <Pie data={dataPie} options={{ responsive: true }} />
    </div>
  )
}

export default ChartsPage1
