import { css } from '@emotion/css'
import _ from 'lodash'
import React, { useEffect, useRef, useState, lazy, Suspense } from 'react'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../base/customHooks/componentHOC'
import { usePagination } from '../../base/customHooks/usePagination'
import { translate } from '../../base/localization'
import { IException, KeyValuePair } from '../../base/models'
import { downloadFileFromUrl, printElement } from '../../base/utils/dataHelper'
import IPAccordion from '../../components/IPAccordion'
import IPAccordionCategory from '../../components/IPAccordion/IPAccordionCategory'
import IPButton from '../../components/IPButton'
import IPCheckbox from '../../components/IPCheckBox'
import IPRadio from '../../components/IPRadio'
import IPSelectBox, { ISelectOption } from '../../components/IPSelectBox/IPSelectBoxMenu'
import Tooltip, { TooltipState, getPosition } from '../../components/Tooltip'
import { IMenu } from '../../layouts/components/client/models'
import { GetCompaniesApi } from '../../layouts/components/client/services/getCompaniesApi'
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks'
import { addListToBasketUIMultiRedux } from '../../redux/slices/basket/basketSlice'
import { getFavoriteProductListRedux } from '../../redux/slices/favoriteProduct/favoriteProductSlice'
import { changeTheme, selectedTheme } from '../../redux/slices/theme/themeSlice'
import { currentUser } from '../../redux/slices/user/userSlice'
import Translations from '../../translations'
import { IBestSellers, ICampaignsProducts } from '../Homepage/commonModels/models'
import CustomCard from './components/CustomCard'
import Pagination from './components/Pagination'
import {
  IAddListToBasketUI,
  IBestSellersCampaing,
  IBestSellersData,
  IBrand,
  ICategory,
  ICompany,
  IGetProductsBySectionResponse,
  ProductListSpecialFilterType,
} from './models/commonModels'
import { IProductCompareExcelResponse } from './models/productCompareExcel'
import { ListBySelectedFeaturesResponse } from './models/productListModel'
import { BrandProductRequest } from './models/productsByBrandModels'
import { IProduct } from './models/response/getProductListResponseModel'
import { Feature, MenuCategory, SearchMenuCatAndCounts } from './models/searchModel'
import { GetCampaignToProductsApi } from './services/getCampaignToProductsApi'
import { GetListProductsByBrandAndProductGroupApi } from './services/getListProductsByBrandAndProductGroupApi'
import { GetProductLinkApi } from './services/getProductLinkApi'
import { GetProductListApi } from './services/getProductListApi'
import { GetProductListByBannerIdApi } from './services/getProductListByBannerIdApi'
import { GetProductListByBrandIdApi, GetProductListByModelApi } from './services/getProductListByBrandIdApi'
import { GetProductListByCategoryIdApi, ListByRequest } from './services/getProductListByCategoryIdApi'
import { GetProductsBySectionApi } from './services/getProductsBySectionApi'
import { PostProductCompareExcelApi } from './services/postProductCompareExcel'
import { SearchProductsApi, SearchRequest } from './services/searchProductsByCategoriesAndCompaniesAllDataApi'
import { GetCampaignsProductsApi } from '../Homepage/services/getCampaignsProductsApi'
import { GetMostSelledProductsByCatagoriesApi } from '../Homepage/services/getMostSelledProductsByCatagoriesApi'
import './styles.scss'
import './styles/ProductCard.scss'
import { ProductIQuotaFormField, ProductIQuotaFormHandles } from './components/ProductIQuotaForm'
import { GetMenusApi } from '../../layouts/components/client/services/getMenusApi'

const CompareProduct = lazy(() => import('./components/CompareProduct'))
const ProductIQuotaForm = lazy(() => import('./components/ProductIQuotaForm'))
const ProductCard1 = lazy(() => import('./components/ProductCard1'))
const ProductCard2 = lazy(() => import('./components/ProductCard2'))
const ProductCard3 = lazy(() => import('./components/ProductCard3'))
const IPModal = lazy(() => import('../../components/IPModal'))
interface ProductListState {
  productList: IProduct[]
  filteredProductList: IProduct[]
  selectedCardView?: string
  isStockChecked?: boolean
  isCampaignChecked?: boolean
  activeKey: string
  selectInputValues: any[]
  searchCategoryValues: string
  searchProductValues: string
  categories: ICategory[]
  brands: IBrand[]
  sellers: ICompany[]
  specialFilter?: string
  selectedCategories?: number[]
  selectedBrands: number[]
  selectedSellers?: number[]
  currentQueryParams: string
  brandId?: string
  categoryId?: number
  oldCat?: number
  oldBrand?: string
  oldSearch?: string
  oldKey?: string
  bannerId?: number
  selectedProductsToCompare: IProduct[]
  addListToBasketUI: IAddListToBasketUI[]
  priceOrderType: null | 'asc' | 'desc'
  searchComplete: boolean
  excelProduct: IProduct[]
  totalProduct: number | null
  features: ListBySelectedFeaturesResponse[]
  bestSellersCampaing: IBestSellersCampaing[]
  bestSellersData: IBestSellersData[]
  currentPage: number
  features2: Feature[]
  MenuCategories: MenuCategory[]
  SearchMenuCatAndCounts: SearchMenuCatAndCounts[]
  SearchMenuCatAndCounts2: SearchMenuCatAndCounts[]
  MenuTitle: string
  productGroups: {
    [key: string]: IProduct[]
  }
  new: boolean
  productType: string
  bestSellerBool: boolean
  first: boolean
  newFilter: boolean
  searchReq?: any
}

const PAGE_SIZE = 52

function ProductList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const dispatch = useAppDispatch()
  const theme = useAppSelector(selectedTheme)

  const getInitialState = (): ProductListState => {
    return {
      productList: [],
      filteredProductList: [],
      activeKey: '',
      selectInputValues: [],
      searchCategoryValues: '',
      searchProductValues: '',
      categories: [],
      brands: [],
      sellers: [],
      selectedBrands: [],
      currentQueryParams: '',
      selectedProductsToCompare: [],
      priceOrderType: null,
      searchComplete: false,
      excelProduct: [],
      totalProduct: 0,
      features: [],
      bestSellersCampaing: [],
      bestSellersData: [],
      addListToBasketUI: [],
      currentPage: 1,
      features2: [],
      MenuCategories: [],
      SearchMenuCatAndCounts: [],
      SearchMenuCatAndCounts2: [],
      MenuTitle: '',
      productGroups: {},
      new: false,
      productType: 'default',
      bestSellerBool: false,
      first: false,
      newFilter: false,
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ProductListState>(intialState)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [selectedFeatures, setSelectedFeatures] = useState<any[]>([])
  const [addListToBasketUI, setAddListToBasketUI] = useState<IAddListToBasketUI[]>([])
  const [oldUrl, setOldUrl] = useState('')
  const [searchParams, setSearchParams] = useSearchParams()

  let location = useLocation()
  const iQuotaFormRef = React.useRef<ProductIQuotaFormHandles>(null)

  const [paginationList, totalPage, selectedPage, setPaginationPage] = usePagination(
    state.filteredProductList,
    state.totalProduct ? state.totalProduct : 0,
    PAGE_SIZE,
    1
  )

  const distinctFeatures = (features: SelectedFeature[]): SelectedFeature[] => {
    const seen: Record<string, boolean> = {};
    return features.filter(feature => {
      const key = `${feature.FeatureId}_${feature.FeatureValue}`;
      if (seen[key]) {
        return false;
      }
      seen[key] = true;
      return true;
    });
  };

  //#region getProducts Methods

  function setCampaignPage(page = 1) {
    props.showLoading()
    const data: ICampaignsProducts = {
      PageSize: PAGE_SIZE,
      PageNumber: page - 1,
      CampaignId: 0,
      Company: theme.id,
    }

    const api: GetCampaignsProductsApi = new GetCampaignsProductsApi({})
    api.getCampaignsProducts(data).then((result: any) => {
      props.hideLoading()
      setState({
        productList: result?.Products,
        filteredProductList: result?.Products,
        totalProduct: result.Count,
        currentPage: page,
        excelProduct: result.Products,
        searchComplete: true,
        new: true,
      })
    })
  }

  const getProductsByCategoryId = async (page = 1, updateProducts = false) => {
    if (!state.categoryId) return console.error('getProductsByCategoryId: No CategoryId!')
    const globalSearch = searchParams.get('global-search')
    const companyId = searchParams.get('companyId')
    const qpFeatures = searchParams.get('featureId')
    const qpFeatureValues = searchParams.get('featureValue')
    const qpCats = searchParams.get('categories')
    const qpStock = searchParams.get('hasStock')
    const qpCampaign = searchParams.get('hasCampaign')
    const qpCompany = searchParams.get('company')
    props.showLoading && props.showLoading()
    setState({ searchComplete: false, currentPage: page })
    var idList = qpFeatures?.split(",")
    var valueList = qpFeatureValues?.split("||")
    let featureList = selectedFeatureList
    let catList = state.selectedCategories != undefined ? state.selectedCategories : qpCats?.split(',').map(Number)
    let hasStock = state.isStockChecked != undefined ? (state.isStockChecked === true ? 1 : 0) : Number(qpStock)
    let hasCampaign = state.isCampaignChecked != undefined ? (state.isCampaignChecked === true ? 1 : 0) : Number(qpCampaign)
    let compList = state.selectedSellers != undefined ? state.selectedSellers : qpCompany?.split(',').map(Number)

    if (state.oldCat === undefined || state.oldCat == state.categoryId && idList?.length === 0) {
      if (idList && valueList && idList.length === valueList.length) {
        for (let i = 0; i < idList.length; i++) {
          const featureId = parseInt(idList[i])
          const featureValue = valueList[i]

          // featureList içinde aynı FeatureId ve FeatureValue çifti zaten var mı kontrol et
          const isFeatureExist = featureList.some(feature =>
            feature.FeatureId === featureId && feature.FeatureValue === featureValue
          )

          // Eğer featureList içinde yoksa ekle
          if (!isFeatureExist) {
            featureList.push({
              FeatureId: featureId,
              FeatureValue: featureValue
            })
          }
        }
      }
    }

    if (catList && catList?.length > 0)
      setState({ selectedCategories: catList })

    if (oldUrl != url && (qpFeatures === null || selectedFeatureList === null))
      featureList = [] as any

    if (oldUrl != url && (qpCats === null || state.selectedCategories === null)) {
      catList = [] as any
      setState({ selectedCategories: catList })
    }

    if (compList && compList?.length > 0)
      setState({ selectedSellers: compList })

    if (oldUrl != url && (qpCompany === null || state.selectedSellers === null)) {
      compList = [] as any
      setState({ selectedSellers: compList })
    }

    if (hasStock && hasStock === 1) {
      setState({ isStockChecked: true })
      state.isStockChecked = true
    }

    if (oldUrl != url && (qpStock === null || state.isStockChecked === false)) {
      hasStock = 0
      setState({ isStockChecked: false })
    }

    if (hasCampaign && hasCampaign === 1) {
      setState({ isCampaignChecked: true })
      state.isCampaignChecked = true
    }

    if (oldUrl != url && (qpCampaign === null || state.isCampaignChecked === false)) {
      hasCampaign = 0
      setState({ isCampaignChecked: false })
    }

    let req: ListByRequest = {
      FeatureList: distinctFeatures(featureList),
      menuId: state.categoryId,
      GroupIds: catList,
      companies: companyId
        ? [companyId] : (oldUrl === window.location.href)
          ? (state.selectedSellers ? state.selectedSellers.map(x => x.toString()) : [])
          : theme.id > 0
            ? globalSearch
              ? []
              : [String(theme.id)]
            : compList
              ? compList.map(String)
              : [],
      HasStock: hasStock === 1 ? true : false,
      take: hasCampaign === 1 ? 99999 : 52,
      skip: hasCampaign === 1 ? 0 : (page - 1) * 52,
      orderString: state.priceOrderType !== null ? 'Fiyat' : '',
      orderType: state.priceOrderType,
    }

    setState({ searchReq: req })
    if (oldUrl !== window.location.href) {
      if (theme.id > 0 && globalSearch) {
        dispatch(changeTheme('default'))
      }
    }

    if ((state.oldCat != undefined) && (state.categoryId != state.oldCat || state.oldSearch != "cat"))
      setState({ selectedSellers: [], selectedCategories: [] })

    setState({ oldSearch: "cat" })

    setSelectedFeatureList((state.categoryId === state.oldCat || state.oldCat === undefined) ? distinctFeatures(featureList) : [])



    if (featureList?.length > 0) {
      // Mevcut URL'in temel kısmını ve query parametrelerini al
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)

      // featureId ve featureValue dizilerini oluştur
      const id = selectedFeatureList.map(a => a.FeatureId.toString())
      const value = selectedFeatureList.map(a => a.FeatureValue)

      // Mevcut parametreleri al
      const existingId = searchParams.get('featureId')?.split(',') || []
      const existingValue = searchParams.get('featureValue')?.split('||') || []

      // id-value çiftlerini oluştur
      const existingPairs = existingId.map((featureId, index) => ({
        id: featureId,
        value: existingValue[index]
      }))

      const newPairs = id.map((featureId, index) => ({
        id: featureId,
        value: value[index]
      }))

      // Güncellenmiş parametreleri ekle veya güncelle
      const updatedPairs = existingPairs.filter(pair =>
        newPairs.some(newPair => newPair.id === pair.id && newPair.value === pair.value)
      )

      newPairs.forEach(newPair => {
        if (!updatedPairs.some(pair => pair.id === newPair.id && pair.value === newPair.value)) {
          updatedPairs.push(newPair);
        }
      })

      // Güncellenmiş parametreleri ayarla
      const updatedId = updatedPairs.map(pair => pair.id)
      const updatedValue = updatedPairs.map(pair => pair.value)

      if (updatedId.length > 0) {
        searchParams.set('featureId', updatedId.join(','))
      } else {
        searchParams.delete('featureId')
      }

      if (updatedValue.length > 0) {
        searchParams.set('featureValue', updatedValue.join('||'))
      } else {
        searchParams.delete('featureValue')
      }

      // Güncellenmiş URL'i oluştur
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)

      // Güncellenmiş URL'i navigate ile yönlendir
      navigate(newUrl)

    }
    else {
      searchParams.delete('featureId')
      searchParams.delete('featureValue')
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)

      // Güncellenmiş URL'i navigate ile yönlendir
      navigate(newUrl)

    }

    if (catList && catList?.length > 0) {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)

      searchParams.set('categories', catList.join(','))
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
      navigate(newUrl)
    }
    else {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)

      searchParams.delete('categories')
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
      navigate(newUrl)
    }

    if (compList && compList?.length > 0) {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)

      searchParams.set('company', compList.join(','))
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
      navigate(newUrl)
    }
    else {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)

      searchParams.delete('company')
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
      navigate(newUrl)
    }


    if (hasStock && hasStock === 1) {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)

      searchParams.set('hasStock', "1")
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
      navigate(newUrl)
    }
    else {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)

      searchParams.delete('hasStock')
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
      navigate(newUrl)
    }

    if (hasCampaign && hasCampaign === 1) {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)

      searchParams.set('hasCampaign', "1")
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
      navigate(newUrl)
    }
    else {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search)

      searchParams.delete('hasCampaign')
      const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
      navigate(newUrl)
    }

    if (updateProducts) {
      req = {
        ...req,
        FeatureList: distinctFeatures(featureList),
        GroupIds: catList,
      }
    } else {
      setState({ first: true, isStockChecked: hasStock === 1 ? true : false, isCampaignChecked: hasCampaign === 1 ? true : false })
    }
    try {
      const res = await new GetProductListByCategoryIdApi({}).getProductsList(req)
      const states = {
        productList: res.products,
        filteredProductList: res.products,
        searchComplete: true,
        excelProduct: res.products,
        totalProduct: res.products.length > 0 ? res.products[0].overall_count : null,
        MenuCategories: (state.categoryId === state.oldCat && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.MenuCategories : res.MenuCategories,
        features2: (state.categoryId === state.oldCat && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.features2 : res.features,
        SearchMenuCatAndCounts: res.SearchMenuCatAndCounts,
        new: true,
      }

      setState({ oldCat: state.categoryId })
      setOldUrl(window.location.href)

      if (updateProducts) {
        const omittedStates = _.omit(states, ['MenuCategories', 'features2', 'SearchMenuCatAndCounts'])
        setState(omittedStates)
      } else setState(states)

      props.hideLoading && props.hideLoading()
    } catch (err: any) {
      props.hideLoading && props.hideLoading()
      setState({ searchComplete: true })
    }
  }

  useEffect(() => {
    if (!searchParams.get('companyId'))
      setActiveTab("filtreler")
  }, [searchParams.get('companyId')])

  useEffect(() => {
    setState({ selectedProductsToCompare: [] })
  }, [searchParams])

  const getBrandProducts = async (page = 1, updateProducts = false, filterPrice = false) => {
    if (!state.brandId) return console.error('GetProductListByBrandId: No BrandId!')

    if (searchParams.get('model')) {
      props.showLoading && props.showLoading()
      setState({ currentPage: page })
      const globalSearch = searchParams.get('global-search')
      let req: BrandProductRequest = {
        brandId: state.brandId,
        modelParam: searchParams.get('model')?.toString(),
        companies:
          oldUrl === window.location.href || oldUrl.length <= 0
            ? state.selectedSellers?.map(x => x.toString()) ?? []
            : theme.id > 0
              ? globalSearch
                ? []
                : [String(theme.id)]
              : [],
        take: 99998, //99999 tane ise kampanya kontrolü yapar. 99998 ise model search için
        skip: (page - 1) * 52,
        HasStock: state.first && updateProducts && state.isStockChecked ? state.isStockChecked : false,
        orderString: state.priceOrderType !== null ? 'Fiyat' : '',
        orderType: state.priceOrderType,
        MenuTitle: state.MenuTitle,
      }
      setState({ searchReq: req })
      if (oldUrl !== window.location.href) {
        if (theme.id > 0 && !globalSearch) setState({ selectedSellers: [theme.id] })
        setOldUrl(window.location.href)
      }
      if (updateProducts) {
        req = {
          ...req,
          FeatureList: selectedFeatureList,
          GroupIds: state.selectedCategories,
        }
      } else {
        setState({ first: true, isStockChecked: false })
      }

      new GetProductListByModelApi({})
        .getProductsList(req)
        .then((res: any) => {
          const productGroups = _.groupBy(res.products, 'MenuTitle')

          const states = {
            productGroups,
            productList: res.products,
            filteredProductList: res.products,
            searchComplete: true,
            excelProduct: res.products,
            totalProduct: res.products.length > 0 ? res.products.length : null,
            MenuCategories: res.MenuCategories,
            features2: res.features,
            SearchMenuCatAndCounts: res.SearchMenuCatAndCounts,
            SearchMenuCatAndCounts2: res.SearchMenuCatAndCounts,
            new: true,
            first: true,
          }
          setState({ oldBrand: state.brandId })

          if (updateProducts) {
            const omittedStates = _.omit(states, ['MenuCategories', 'features2', 'SearchMenuCatAndCounts'])
            setState(omittedStates)
          } else {
            setState(states)
          }

          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
        .catch((err: IException) => {
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
    } else {
      props.showLoading && props.showLoading()
      const globalSearch = searchParams.get('global-search')
      const qpFeatures = searchParams.get('featureId')
      const qpFeatureValues = searchParams.get('featureValue')
      const qpCats = searchParams.get('categories')
      const qpStock = searchParams.get('hasStock')
      const qpCampaign = searchParams.get('hasCampaign')
      const qpTitle = searchParams.get('title')
      const companyId = searchParams.get('companyId')
      const qpCompany = searchParams.get('company')
      searchParams.delete('title')
      var idList = qpFeatures?.split(",")
      var valueList = qpFeatureValues?.split("||")
      let featureList = selectedFeatureList
      let catList = state.selectedCategories != undefined ? state.selectedCategories : qpCats?.split(',').map(Number)
      let hasStock = state.isStockChecked != undefined ? (state.isStockChecked === true ? 1 : 0) : Number(qpStock)
      let hasCampaign = state.isCampaignChecked != undefined ? (state.isCampaignChecked === true ? 1 : 0) : Number(qpCampaign)
      let title = ((state.MenuTitle != undefined && state.MenuTitle != '') || state.MenuTitle != '') ? (state.MenuTitle) : qpTitle
      let compList = state.selectedSellers != undefined ? state.selectedSellers : qpCompany?.split(',').map(Number)
      setState({ searchComplete: false, currentPage: page })

      if (state.oldBrand === undefined || state.oldBrand == state.brandId && idList?.length === 0) {
        if (idList && valueList && idList.length === valueList.length) {
          for (let i = 0; i < idList.length; i++) {
            const featureId = parseInt(idList[i])
            const featureValue = valueList[i]

            // featureList içinde aynı FeatureId ve FeatureValue çifti zaten var mı kontrol et
            const isFeatureExist = featureList.some(feature =>
              feature.FeatureId === featureId && feature.FeatureValue === featureValue
            )

            // Eğer featureList içinde yoksa ekle
            if (!isFeatureExist) {
              featureList.push({
                FeatureId: featureId,
                FeatureValue: featureValue
              })
            }
          }
        }
      }


      if (catList && catList?.length > 0)
        setState({ selectedCategories: catList })

      if (oldUrl != url && (qpFeatures === null || selectedFeatureList === null))
        featureList = [] as any

      if (oldUrl != url && (qpCats === null || state.selectedCategories === null)) {
        catList = [] as any
        setState({ selectedCategories: catList })
      }

      if (compList && compList?.length > 0)
        setState({ selectedSellers: compList })

      if (oldUrl != url && (qpCompany === null || state.selectedSellers === null)) {
        compList = [] as any
        setState({ selectedSellers: compList })
      }

      if (hasStock && hasStock === 1) {
        setState({ isStockChecked: true })
        state.isStockChecked = true
      }

      if (oldUrl != url && (qpStock === null || state.isStockChecked === false)) {
        hasStock = 0
        setState({ isStockChecked: false })
      }

      if (hasCampaign && hasCampaign === 1) {
        setState({ isCampaignChecked: true })
        state.isCampaignChecked = true
      }

      if (oldUrl != url && (qpCampaign === null || state.isCampaignChecked === false)) {
        hasCampaign = 0
        setState({ isCampaignChecked: false })
      }

      if (title != undefined || title != null || title != '') {
        if (title)
          title = decodeURIComponent(title)
        setState({ MenuTitle: title ? title : '' })
        state.MenuTitle = title ? title : ''
      }

      if (oldUrl != url && (qpTitle === null || state.MenuTitle === null || state.MenuTitle === '')) {
        title = null
        setState({ MenuTitle: '' })
      }


      setState({ oldSearch: "brand" })

      let req: BrandProductRequest = {
        FeatureList: distinctFeatures(featureList),
        brandId: state.brandId,
        GroupIds: catList,
        companies: companyId
          ? [companyId] : (oldUrl === window.location.href)
            ? (state.selectedSellers ? state.selectedSellers.map(x => x.toString()) : [])
            : theme.id > 0
              ? globalSearch
                ? []
                : [String(theme.id)]
              : compList
                ? compList.map(String)
                : [],
        HasStock: hasStock === 1 ? true : false,
        take: hasCampaign === 1 ? 99999 : 52,
        skip: hasCampaign === 1 ? 0 : (page - 1) * 52,
        orderString: state.priceOrderType !== null ? 'Fiyat' : '',
        orderType: state.priceOrderType,
        MenuTitle: title ? title : '',
      }
      setState({ searchReq: req })
      if (oldUrl !== window.location.href) {
        if (theme.id > 0 && !globalSearch) setState({ selectedSellers: [theme.id] })
      }

      if ((state.oldBrand != undefined) && (state.brandId != state.oldBrand || state.oldSearch != "brand"))
        setState({ selectedSellers: [], selectedCategories: [] })

      setState({ oldSearch: "brand" })

      setSelectedFeatureList((state.brandId === state.oldBrand || state.oldBrand === undefined) ? distinctFeatures(featureList) : [])

      if (featureList?.length > 0) {
        // Mevcut URL'in temel kısmını ve query parametrelerini al
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        // featureId ve featureValue dizilerini oluştur
        const id = selectedFeatureList.map(a => a.FeatureId.toString())
        const value = selectedFeatureList.map(a => a.FeatureValue)

        // Mevcut parametreleri al
        const existingId = searchParams.get('featureId')?.split(',') || []
        const existingValue = searchParams.get('featureValue')?.split('||') || []

        // id-value çiftlerini oluştur
        const existingPairs = existingId.map((featureId, index) => ({
          id: featureId,
          value: existingValue[index]
        }))

        const newPairs = id.map((featureId, index) => ({
          id: featureId,
          value: value[index]
        }))

        // Güncellenmiş parametreleri ekle veya güncelle
        const updatedPairs = existingPairs.filter(pair =>
          newPairs.some(newPair => newPair.id === pair.id && newPair.value === pair.value)
        )

        newPairs.forEach(newPair => {
          if (!updatedPairs.some(pair => pair.id === newPair.id && pair.value === newPair.value)) {
            updatedPairs.push(newPair);
          }
        })

        // Güncellenmiş parametreleri ayarla
        const updatedId = updatedPairs.map(pair => pair.id)
        const updatedValue = updatedPairs.map(pair => pair.value)

        if (updatedId.length > 0) {
          searchParams.set('featureId', updatedId.join(','))
        } else {
          searchParams.delete('featureId')
        }

        if (updatedValue.length > 0) {
          searchParams.set('featureValue', updatedValue.join('||'))
        } else {
          searchParams.delete('featureValue')
        }

        // Güncellenmiş URL'i oluştur
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)

        // Güncellenmiş URL'i navigate ile yönlendir
        navigate(newUrl)
      }
      else {
        searchParams.delete('featureId')
        searchParams.delete('featureValue')
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)

        // Güncellenmiş URL'i navigate ile yönlendir
        navigate(newUrl)
      }

      if (catList && catList?.length > 0) {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        searchParams.set('categories', catList.join(','))
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }
      else {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        searchParams.delete('categories')
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }

      if (compList && compList?.length > 0) {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        searchParams.set('company', compList.join(','))
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }
      else {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        searchParams.delete('company')
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }

      if (hasStock && hasStock === 1) {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        searchParams.set('hasStock', "1")
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }
      else {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        searchParams.delete('hasStock')
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }

      if (hasCampaign && hasCampaign === 1) {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        searchParams.set('hasCampaign', "1")
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }
      else {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        searchParams.delete('hasCampaign')
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }

      if (title) {
        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.search);

        title = encodeURIComponent(title)
        searchParams.set('title', title)
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }
      else {
        const url = new URL(window.location.href)
        const searchParams = new URLSearchParams(url.search)

        searchParams.delete('title')
        const newUrl = decodeURIComponent(`?${searchParams.toString()}`)
        navigate(newUrl)
      }

      if (updateProducts) {
        req = {
          ...req,
          FeatureList: distinctFeatures(featureList),
          GroupIds: catList,
        }
      } else {
        setState({ first: true, isStockChecked: hasStock === 1 ? true : false, isCampaignChecked: hasCampaign === 1 ? true : false })
      }

      new GetProductListByBrandIdApi({})
        .getProductsList(req)
        .then((res: any) => {
          let productBrand = res.products
          if (filterPrice) {
            productBrand = res.products.sort((a: IProduct, b: IProduct) => {
              return state.priceOrderType === 'asc'
                ? a.Prices?.Special?.TRY - b.Prices?.Special?.TRY
                : b.Prices?.Special?.TRY - a.Prices?.Special?.TRY
            })
          }
          const productGroups = _.groupBy(productBrand, 'MenuTitle')

          const states = {
            productGroups,
            productList: res.products,
            filteredProductList: res.products,
            searchComplete: true,
            excelProduct: res.products,
            totalProduct: res.products.length > 0 ? res.products[0].overall_count : null,
            MenuCategories: (state.categoryId === state.oldCat && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.MenuCategories : res.MenuCategories,
            features2: (state.categoryId === state.oldCat && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.features2 : res.features,
            SearchMenuCatAndCounts: (state.categoryId === state.oldCat && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.SearchMenuCatAndCounts : res.SearchMenuCatAndCounts,
            SearchMenuCatAndCounts2: (state.categoryId === state.oldCat && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.SearchMenuCatAndCounts : res.SearchMenuCatAndCounts,
            new: true,
            first: true,
          }

          setState({ oldBrand: state.brandId })
          setOldUrl(window.location.href)

          if (updateProducts) {
            const omittedStates = _.omit(states, ['MenuCategories', 'features2', 'SearchMenuCatAndCounts'])
            setState(omittedStates)
          } else setState(states)

          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
        .catch((err: IException) => {
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
    }
  }

  const getProducts = async () => {
    if (state.brandId) {
      getBrandProducts()
    } else if (state.categoryId) {
      getProductsByCategoryId()
    } else if (state.bannerId) {
      const getProductListApi: GetProductListByBannerIdApi = new GetProductListByBannerIdApi({})
      props.showLoading && props.showLoading()
      setState({ searchComplete: false })
      getProductListApi
        .getProductsList(state.bannerId)
        .then((result: IProduct[]) => {
          setState({
            productList: result,
            filteredProductList: result,
            excelProduct: result,
            totalProduct: result.length,
            new: false,
          })
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
        .catch((err: IException) => {
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
    } else if (state.specialFilter) {
      if (state.specialFilter === 'promotional-products') {
        const data: ICampaignsProducts = {
          PageSize: PAGE_SIZE,
          PageNumber: 0,
          CampaignId: 0,
          Company: theme.id,
        }
        const api: GetCampaignsProductsApi = new GetCampaignsProductsApi({})
        api.getCampaignsProducts(data).then((result: any) => {
          if (result) {
            setState({
              productList: result?.Products,
              filteredProductList: result?.Products,
              excelProduct: result.Products,
              searchComplete: true,
              totalProduct: result.Count,
              new: true,
            })
            props.hideLoading && props.hideLoading()
          }
        })
        return
      }
      if (state.specialFilter === 'best-sellers') {
        props.showLoading()
        const api: GetMostSelledProductsByCatagoriesApi = new GetMostSelledProductsByCatagoriesApi({})
        const queryParams: KeyValuePair<number> = new KeyValuePair<number>()
        queryParams.add('dayBefore', 7)
        queryParams.add('minSelledProductCount ', 0)
        queryParams.add('minSelledProductQuantityCount', 5)
        queryParams.add('company', theme.id)
        api.queryParams = queryParams
        api.getMostSelledProductsByCatagories().then((result: IBestSellers[]) => {
          if (result) {
            const product: IProduct[] = []
            const category: IBestSellersCampaing[] = []
            const bestSellersData: IBestSellersData[] = []
            result.map((res, index) => {
              category.push({ CategoryName: res.CategoryName, Id: res.Product.Id })
              bestSellersData.push({ CategoryName: res.CategoryName, Id: res.Product.Id, Index: index })
              return product.push(res.Product)
            })
            setState({
              productList: product,
              filteredProductList: product,
              excelProduct: product,
              bestSellersCampaing: category,
              bestSellersData: bestSellersData,
              totalProduct: bestSellersData.length,
              new: false,
              bestSellerBool: true,
            })
            props.hideLoading && props.hideLoading()
          }
        })
        return
      }
      const getProductListApi: GetProductsBySectionApi = new GetProductsBySectionApi({})
      props.showLoading && props.showLoading()
      setState({ searchComplete: false })
      getProductListApi
        .getProductsList(state.specialFilter)
        .then((result: IGetProductsBySectionResponse) => {
          setState({
            productList: result?.Products,
            filteredProductList: result?.Products,
            excelProduct: result.Products,
            totalProduct: result.ProductsCount,
            new: false,
          })
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
        .catch((err: IException) => {
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
    } else {
      const getProductListApi: GetProductListApi = new GetProductListApi({})
      props.showLoading && props.showLoading()
      setState({ searchComplete: false })
      getProductListApi
        .getProducts()
        .then((result: IProduct[]) => {
          setState({
            productList: result,
            filteredProductList: result,
            excelProduct: result,
            totalProduct: result[0].overall_count,
            new: false,
          })
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
        .catch((err: IException) => {
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
    }
  }
  //#endregion

  const getSearchResults = async (page: number = 1, updateProducts = false) => {
    const url = new URL(window.location.href)
    const urlSearchParams = new URLSearchParams(window.location.search)
    const qpSearchKey = urlSearchParams.get('searchKey')
    const companyId = urlSearchParams.get('companyId')

    const searchParams = location.state as any

    props.showLoading && props.showLoading()
    setState({ searchComplete: false, currentPage: page })
    const qpFeatures = urlSearchParams.get('featureId')
    const qpFeatureValues = urlSearchParams.get('featureValue')
    const qpCats = urlSearchParams.get('categories')
    const qpStock = urlSearchParams.get('hasStock')
    const qpCampaign = urlSearchParams.get('hasCampaign')
    const qpCompany = urlSearchParams.get('company')
    const qpTitle = urlSearchParams.get('title')
    urlSearchParams.delete('title')

    var idList = qpFeatures?.split(",")
    var valueList = qpFeatureValues?.split("||")
    let featureList = selectedFeatureList
    let catList = state.selectedCategories != undefined ? state.selectedCategories : qpCats?.split(',').map(Number)
    let hasStock = state.isStockChecked != undefined ? (state.isStockChecked === true ? 1 : 0) : Number(qpStock)
    let hasCampaign = state.isCampaignChecked != undefined ? (state.isCampaignChecked === true ? 1 : 0) : Number(qpCampaign)
    let compList = state.selectedSellers != undefined ? state.selectedSellers : qpCompany?.split(',').map(Number)
    let title = ((state.MenuTitle != undefined && state.MenuTitle != '') || state.MenuTitle != '') ? (state.MenuTitle) : qpTitle

    if (state.oldKey === undefined || state.oldKey == qpSearchKey && idList?.length === 0) {
      if (idList && valueList && idList.length === valueList.length) {
        for (let i = 0; i < idList.length; i++) {
          const featureId = parseInt(idList[i])
          const featureValue = valueList[i]

          // featureList içinde aynı FeatureId ve FeatureValue çifti zaten var mı kontrol et
          const isFeatureExist = featureList.some(feature =>
            feature.FeatureId === featureId && feature.FeatureValue === featureValue
          )

          // Eğer featureList içinde yoksa ekle
          if (!isFeatureExist) {
            featureList.push({
              FeatureId: featureId,
              FeatureValue: featureValue
            })
          }
        }
      }
    }
    setSelectedFeatureList((state.oldKey === qpSearchKey?.toString() || state.oldKey === undefined) ? distinctFeatures(featureList) : [])

    if (catList && catList?.length > 0)
      setState({ selectedCategories: catList })

    if (oldUrl != url.toString() && (qpFeatures === null || selectedFeatureList === null))
      featureList = [] as any

    if (oldUrl != url.toString() && (qpCats === null || state.selectedCategories === null)) {
      catList = [] as any
      setState({ selectedCategories: catList })
    }

    if ((state.oldKey != undefined) && (state.oldKey != qpSearchKey || state.oldSearch != "search"))
      setState({ selectedSellers: [], selectedCategories: [] })

    if (compList && compList?.length > 0)
      setState({ selectedSellers: compList })

    if (oldUrl != url.toString() && (qpCompany === null || state.selectedSellers === null)) {
      compList = [] as any
      setState({ selectedSellers: compList })
    }

    if (hasStock && hasStock === 1) {
      setState({ isStockChecked: true })
      state.isStockChecked = true
    }


    if (oldUrl != url.toString() && (qpStock === null || state.isStockChecked === false)) {
      hasStock = 0
      setState({ isStockChecked: false })
    }

    if (hasCampaign && hasCampaign === 1) {
      setState({ isCampaignChecked: true })
      state.isCampaignChecked = true
    }


    if (oldUrl != url.toString() && (qpCampaign === null || state.isCampaignChecked === false)) {
      hasCampaign = 0
      setState({ isCampaignChecked: false })
    }

    if (title != undefined || title != null || title != '') {
      if (title)
        title = decodeURIComponent(title)
      setState({ MenuTitle: title ? title : '' })
      state.MenuTitle = title ? title : ''
    }

    if (oldUrl != url.toString() && (qpTitle === null || state.MenuTitle === null || state.MenuTitle === '')) {
      title = null
      setState({ MenuTitle: '' })
    }


    setState({ oldSearch: "search" })
    let req: SearchRequest = {
      FeatureList: distinctFeatures(featureList),
      searchKey: searchParams ? searchParams.debouncedSearchTerm : qpSearchKey,
      Categories: searchParams ? searchParams.selectedCategories.map(x => x.toString()) : [],
      //Companies: searchParams ? searchParams.selectedCompanies.map(x => x.toString()) : [],
      Companies: companyId
        ? [companyId] : (oldUrl === window.location.href)
          ? (state.selectedSellers ? state.selectedSellers.map(x => x.toString()) : [])
          : theme.id > 0
            ? [String(theme.id)]
            : compList
              ? compList.map(String)
              : [],
      GroupIds: catList,
      Result: 1,
      MenuTitle: title ? title : '',
      HasStock: hasStock === 1 ? true : false,
      Take: hasCampaign === 1 ? 99999 : 52,
      skip: hasCampaign === 1 ? 0 : (page - 1) * 52,
      orderString: state.priceOrderType !== null ? 'Fiyat' : '',
      orderType: state.priceOrderType,
    }
    setState({ searchReq: req })
    dispatch(changeTheme('default'))

    setSelectedFeatureList((state.categoryId === state.oldCat || state.oldCat === undefined) ? distinctFeatures(featureList) : [])

    if (featureList?.length > 0) {
      // Mevcut URL'in temel kısmını ve query parametrelerini al
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      // featureId ve featureValue dizilerini oluştur
      const id = selectedFeatureList.map(a => a.FeatureId.toString())
      const value = selectedFeatureList.map(a => a.FeatureValue)

      // Mevcut parametreleri al
      const existingId = urlSearchParams.get('featureId')?.split(',') || []
      const existingValue = urlSearchParams.get('featureValue')?.split('||') || []

      // id-value çiftlerini oluştur
      const existingPairs = existingId.map((featureId, index) => ({
        id: featureId,
        value: existingValue[index]
      }))

      const newPairs = id.map((featureId, index) => ({
        id: featureId,
        value: value[index]
      }))

      // Güncellenmiş parametreleri ekle veya güncelle
      const updatedPairs = existingPairs.filter(pair =>
        newPairs.some(newPair => newPair.id === pair.id && newPair.value === pair.value)
      )

      newPairs.forEach(newPair => {
        if (!updatedPairs.some(pair => pair.id === newPair.id && pair.value === newPair.value)) {
          updatedPairs.push(newPair);
        }
      })

      // Güncellenmiş parametreleri ayarla
      const updatedId = updatedPairs.map(pair => pair.id)
      const updatedValue = updatedPairs.map(pair => pair.value)

      if (updatedId.length > 0) {
        urlSearchParams.set('featureId', updatedId.join(','))
      } else {
        urlSearchParams.delete('featureId')
      }

      if (updatedValue.length > 0) {
        urlSearchParams.set('featureValue', updatedValue.join('||'))
      } else {
        urlSearchParams.delete('featureValue')
      }

      // Güncellenmiş URL'i oluştur
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)

      // Güncellenmiş URL'i navigate ile yönlendir
      navigate(newUrl)

    }
    else {
      urlSearchParams.delete('featureId')
      urlSearchParams.delete('featureValue')
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)

      // Güncellenmiş URL'i navigate ile yönlendir
      navigate(newUrl)
    }

    if (catList && catList?.length > 0) {
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      urlSearchParams.set('categories', catList.join(','))
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }
    else {
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      urlSearchParams.delete('categories')
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }

    if (compList && compList?.length > 0) {
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      urlSearchParams.set('company', compList.join(','))
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }
    else {
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      urlSearchParams.delete('company')
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }

    if (hasStock && hasStock === 1) {
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      urlSearchParams.set('hasStock', "1")
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }
    else {
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      urlSearchParams.delete('hasStock')
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }

    if (hasCampaign && hasCampaign === 1) {
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      urlSearchParams.set('hasCampaign', "1")
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }
    else {
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      urlSearchParams.delete('hasCampaign')
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }

    if (title) {
      const url = new URL(window.location.href);
      const urlSearchParams = new URLSearchParams(url.search);

      title = encodeURIComponent(title)
      urlSearchParams.set('title', title)
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }
    else {
      const url = new URL(window.location.href)
      const urlSearchParams = new URLSearchParams(url.search)

      urlSearchParams.delete('title')
      const newUrl = decodeURIComponent(`?${urlSearchParams.toString()}`)
      navigate(newUrl)
    }

    if (updateProducts) {
      req = {
        ...req,
        FeatureList: distinctFeatures(featureList),
        GroupIds: catList,
      }
    } else {
      setState({ first: true, isStockChecked: hasStock === 1 ? true : false, isCampaignChecked: hasCampaign === 1 ? true : false })
    }

    const searchByCategoriesAndCompaniesApi: SearchProductsApi = new SearchProductsApi({})
    searchByCategoriesAndCompaniesApi
      .searchProductsByCategoriesAndCompanies(req)
      .then(result => {
        props.hideLoading && props.hideLoading()

        const productGroups = _.groupBy(result.products, p => {
          const groups = result.SearchMenuCatAndCounts.find(x => x.MenuTitle === p.MenuTitle)?.OrderNum
          if (!groups) return
          return groups
        })

        const states = {
          productList: result.products,
          filteredProductList: result.products,
          productGroups,
          searchComplete: true,
          excelProduct: result.products,
          totalProduct: result.products.length > 0 ? result.products[0].overall_count : 0,
          MenuCategories: (qpSearchKey === state.oldKey && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.MenuCategories : result.MenuCategories,
          features2: (qpSearchKey === state.oldKey && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.features2 : result.features,
          SearchMenuCatAndCounts: (qpSearchKey === state.oldKey && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.SearchMenuCatAndCounts : result.SearchMenuCatAndCounts,
          SearchMenuCatAndCounts2: (qpSearchKey === state.oldKey && (featureList?.length > 0 || (catList && catList?.length > 0) || (compList && compList?.length > 0))) ? state.SearchMenuCatAndCounts : result.SearchMenuCatAndCounts,
          new: true,
        }

        setState({ oldKey: qpSearchKey?.toString() })
        setOldUrl(window.location.href)

        if (updateProducts) {
          const omittedStates = _.omit(states, ['MenuCategories', 'features2', 'SearchMenuCatAndCounts'])
          setState(omittedStates)
        } else setState(states)
      })
      .catch((err: any) => {
        props.hideLoading && props.hideLoading()
        setState({ searchComplete: true })
      })
  }

  // const listBySelectedFeatures = async (categoryId: number) => {
  //   return new ProductListService({})
  //     .listBySelectedFeatures(categoryId)
  //     .then((res: ListBySelectedFeaturesResponse[]) => res)
  // }

  useEffect(() => {
    const getSellers = async () => {
      const res = await new GetCompaniesApi({}).getCompanyApi()
      if (!res) return
      setState({ sellers: res })
    }
    getSellers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const qpSeller = searchParams.get('seller')
  const qrModel = searchParams.get('model')
  useEffect(() => {
    dispatch(getFavoriteProductListRedux())

    let selectedCompanies: number[] = []

    const globalSearch = searchParams.get('global-search')

    switch (theme.name) {
      case 'index':
        selectedCompanies.push(1)
        break
      case 'netex':
        selectedCompanies.push(2)
        break
      case 'despec':
        selectedCompanies.push(3)
        break
      case 'datagate':
        selectedCompanies.push(4)
        break
      default:
        break
    }

    if (globalSearch) {
      setState({
        selectedCardView: '1',
      })
      return
    }
    if (selectedCompanies.length === 0 && qpSeller && qpSeller.length > 0) {
      if (qpSeller === 'index') selectedCompanies.push(1)
      if (qpSeller === 'netex') selectedCompanies.push(2)
      if (qpSeller === 'despec') selectedCompanies.push(3)
      if (qpSeller === 'datagate') selectedCompanies.push(4)
    }
    setState({
      selectedCardView: '1',
      selectedSellers: selectedCompanies?.length > 0 ? selectedCompanies : undefined,
    })
    state.selectedSellers = selectedCompanies?.length > 0 ? selectedCompanies : undefined
  }, [null])

  useEffect(() => {
    if (!state.searchComplete && state.first)
      return
    setState({ currentQueryParams: location.search, isStockChecked: false })

    const qpName = searchParams.get('name')

    const qpBrand = searchParams.get('brand')
    const qpSearchKey = window.location.href.split('searchKey=')[1]
    const qpCategoryId = searchParams.get('categoryId')
    const qpCategory = searchParams.get('category')
    const qpBrandId = searchParams.get('brandId')
    const qpBannerId = searchParams.get('bannerId')
    const qpLinkId = searchParams.get('linkId')
    const qpSpecialFilter = searchParams.get('specialFilter')
    const qpCampaigns = searchParams.get('campaigns')

    if (qpName) {
      // setHeadarSearchData(qpName);
      setState({ searchProductValues: qpName })
      state.searchProductValues = qpName
    }
    if (qrModel) {
      getBrandProducts()
    }
    if (qpCampaigns) {
      props.showLoading && props.showLoading()
      const getCampaignToProductsApi: GetCampaignToProductsApi = new GetCampaignToProductsApi({})
      getCampaignToProductsApi
        .getCampaignToProducts(+qpCampaigns)
        .then((result: any) => {
          props.hideLoading && props.hideLoading()

          setState({
            productList: result.List,
            filteredProductList: result.List,
            searchComplete: true,
            excelProduct: result.List,
            totalProduct: result.Count,
            new: false,
          })
        })
        .catch((err: any) => {
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
      return
    }
    if (qpLinkId) {
      props.showLoading && props.showLoading()
      const getProductLinkApi: GetProductLinkApi = new GetProductLinkApi({})
      getProductLinkApi
        .getProductLink(+qpLinkId)
        .then((result: any) => {
          props.hideLoading && props.hideLoading()

          setState({
            productList: result.ProductList,
            filteredProductList: result.ProductList,
            searchComplete: true,
            excelProduct: result.ProductList,
            totalProduct: result.ProductList.length,
          })
        })
        .catch((err: any) => {
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
      return
    }
    if (qpCategory && qpBrand) {
      props.showLoading && props.showLoading()
      const getListProductsByBrandAndProductGroupApi: GetListProductsByBrandAndProductGroupApi =
        new GetListProductsByBrandAndProductGroupApi({})
      getListProductsByBrandAndProductGroupApi
        .getListProductsByBrandAndProductGroup(qpCategory, qpBrand)
        .then((result: IProduct[]) => {
          props.hideLoading && props.hideLoading()

          setState({
            productList: result,
            filteredProductList: result,
            searchComplete: true,
            excelProduct: result,
            totalProduct: result.length,
            new: true,
          })
        })
        .catch((err: any) => {
          props.hideLoading && props.hideLoading()
          setState({ searchComplete: true })
        })
      return
    }
    if (qpCategoryId) {
      setState({ categoryId: Number(qpCategoryId), brandId: undefined })
      state.categoryId = Number(qpCategoryId)
      state.brandId = undefined
      state.bannerId = undefined
    }

    if (qpBrand) {
      // const brandIds = qpBrand.split(',').map(Number)
      // setState({ selectedBrands: brandIds, categoryId: undefined })
      setState({ brandId: qpBrand, categoryId: undefined })
      state.brandId = qpBrand
      state.categoryId = undefined
      state.bannerId = undefined
    }

    if (qpSpecialFilter && Object.values(ProductListSpecialFilterType).indexOf(qpSpecialFilter) > -1) {
      setState({ specialFilter: qpSpecialFilter, categoryId: undefined, brandId: undefined, bannerId: undefined })
      state.categoryId = undefined
      state.bannerId = undefined
      state.brandId = undefined
      state.specialFilter = qpSpecialFilter
    }

    if (qpBannerId) {
      setState({
        bannerId: Number(qpBannerId),
        categoryId: undefined,
        brandId: undefined,
      })
      state.bannerId = Number(qpBannerId)
      state.categoryId = undefined
      state.brandId = undefined
    }

    if (!(qpSearchKey && qpSearchKey.length > 0)) {
      getProducts()
    } else {
      getSearchResults()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, state.newFilter])

  useEffect(() => {
    const categories: ICategory[] = []
    const brands: IBrand[] = []
    const sellers: ICompany[] = []

    for (const prod of state.productList) {
      if (categories.findIndex((cat: ICategory) => cat.Id === prod.Category?.Id) === -1) {
        categories.push(prod.Category)
      }

      if (brands.findIndex((brand: IBrand) => prod.Brand && brand.Id === prod.BrandId) === -1) {
        brands.push({ Id: prod.BrandId, Name: prod.Brand as any } as any)
      }

      if (sellers.findIndex((seller: ICompany) => prod.Company && seller.Id === prod.Company.Id) === -1) {
        sellers.push(prod.Company)
      }
    }

    if (state.productList && state.productList.length > 0) {
      if (categories.length === 0) {
        setState({ selectedCategories: [] })
      }
      if (brands.length === 0) {
        setState({ selectedBrands: [] })
      }

      if (sellers.length === 0) {
        setState({ selectedSellers: [] })
      }
    }

    const catParams = searchParams.get('selCats')
    if (catParams != null) {
      let cats: any = catParams?.split(',')
      cats = cats?.map(item => +item)
      setState({ selectedCategories: cats })
    }

    setPaginationPage(1)
    filterProductsByClientFilters()

    if (state.new === false) {
      setState({
        categories: categories,
        brands: brands,
        sellers: sellers,
        excelProduct: state.productList,
        // selectedCategories: categories.map((val: ICategory) => val.Id), // Ekran ilk açıldığında kategori checkboxlarının seçili gelmemesi için yorum satırı yapıldı.
        // selectedBrands: brands.map((val: IBrand) => val.Id), // Ekran ilk açıldığında marka checkboxlarının seçili gelmemesi için yorum satırı yapıldı.
        // selectedSellers: sellers.map((val: ICompany) => val.Id), // Ekran ilk açıldığında satıcı checkboxlarının seçili gelmemesi için yorum satırı yapıldı.
      })
    }
  }, [state.productList])

  const customOrder = [1, 4, 3, 2]

  const onStockAvailableCheckedChange = (event: any) => {
    setState({ isStockChecked: event.target.checked })
    state.isStockChecked = event.target.checked

    filterProductsByClientFilters(false, true)
  }

  // const onAccordionChange = (activeKey: string) => {
  //   setState({ activeKey: activeKey === state.activeKey ? '' : activeKey })
  // }

  // const handleProductSearchInput = (value: any) => {
  //   setState({ searchProductValues: value })
  // }

  // const handleCategorySearchInput = (value: any) => {
  //   setState({ searchCategoryValues: value })
  // }

  const filterProductsByClientFilters = (sellerCheckLeftMenu: boolean = false, isStockChecked: boolean = false) => {
    if (isStockChecked) {
      if (searchParams.get('searchKey') !== null) {
        return getSearchResults(1, true)
      }
      if (searchParams.get('brand') !== null) {
        return getBrandProducts(1, true)
      }
      if (searchParams.get('model') !== null) {
        return getBrandProducts(1, true)
      }
      if (searchParams.get('linkId') !== null) {
        const qbLinkId = searchParams.get('linkId')
        if (qbLinkId !== null) {
          props.showLoading()
          const getProductLinkApi: GetProductLinkApi = new GetProductLinkApi({})
          getProductLinkApi
            .getProductLink(+qbLinkId)
            .then((result: any) => {
              props.hideLoading && props.hideLoading()
              let filterProduct: any = result.ProductList.filter(res => {
                return res.Stock && res.Stock.Stock && res.Stock.Stock > 0
              })
              setState({
                productList: result.ProductList,
                filteredProductList: filterProduct,
                searchComplete: true,
                excelProduct: filterProduct,
                totalProduct: filterProduct.length,
              })
              props.hideLoading()
            })
            .catch((err: any) => {
              props.hideLoading && props.hideLoading()
              setState({ searchComplete: true })
            })

          return
        }
      }
      if (searchParams.get('bannerId') !== null) {
        let filteredProductList = state.productList.filter((prod: IProduct) => {
          return (
            (!state.selectedCategories ||
              state.selectedCategories.length === 0 ||
              state.selectedCategories.indexOf(prod.Category.Id) > -1) &&
            (!state.selectedBrands ||
              state.selectedBrands.length === 0 ||
              state.selectedBrands.indexOf(prod.BrandId) > -1) &&
            (!searchParams.get('searchKey') || sellerCheckLeftMenu
              ? !state.selectedSellers ||
              state.selectedSellers.length === 0 ||
              state.selectedSellers.indexOf(prod.CompanyId) > -1
              : true) &&
            ((state.isStockChecked && prod.Stock && prod.Stock.Stock && prod.Stock.Stock > 0) || !state.isStockChecked)
          )
        })
        if (state.priceOrderType !== null) {
          filteredProductList = filteredProductList.sort((a: IProduct, b: IProduct) => {
            return state.priceOrderType === 'asc'
              ? a.Prices?.Special?.TRY - b.Prices?.Special?.TRY
              : b.Prices?.Special?.TRY - a.Prices?.Special?.TRY
          })
        }
        setState({ filteredProductList, excelProduct: filteredProductList })
      }
      getProductsByCategoryId(1, true)
    } else {
      let filteredProductList = state.productList.filter((prod: IProduct) => {
        return (
          (!state.selectedCategories ||
            state.selectedCategories.length === 0 ||
            state.selectedCategories.indexOf(prod.Category.Id) > -1) &&
          (!state.selectedBrands ||
            state.selectedBrands.length === 0 ||
            state.selectedBrands.indexOf(prod.BrandId) > -1) &&
          (!searchParams.get('searchKey') || sellerCheckLeftMenu
            ? !state.selectedSellers ||
            state.selectedSellers.length === 0 ||
            state.selectedSellers.indexOf(prod.CompanyId) > -1
            : true) &&
          ((state.isStockChecked && prod.Stock && prod.Stock.Stock && prod.Stock.Stock > 0) || !state.isStockChecked)
        )
      })

      if (state.priceOrderType !== null) {
        filteredProductList = filteredProductList.sort((a: IProduct, b: IProduct) => {
          return state.priceOrderType === 'asc'
            ? a.Prices?.Special?.TRY - b.Prices?.Special?.TRY
            : b.Prices?.Special?.TRY - a.Prices?.Special?.TRY
        })
      }
      setState({ filteredProductList, excelProduct: filteredProductList })
    }
  }

  useEffect(() => {
    if (state.priceOrderType !== null) {
      if (searchParams.get('searchKey') !== null) {
        getSearchResults(1, true)
      }
      if (searchParams.get('brand') !== null) {
        getBrandProducts(1, true)
      }
      if (searchParams.get('model') !== null) {
        getBrandProducts(1, true)
      }
      const qbLinkId = searchParams.get('linkId')
      if (qbLinkId !== null) {
        props.showLoading()
        const getProductLinkApi: GetProductLinkApi = new GetProductLinkApi({})
        getProductLinkApi
          .getProductLink(+qbLinkId)
          .then((result: any) => {
            props.hideLoading && props.hideLoading()
            let filterProduct: any = result.ProductList.filter(res => {
              return res.Stock && res.Stock.Stock && res.Stock.Stock > 0
            })
            const tmpProds = _.clone(filterProduct)
            setState({
              productList: result.ProductList,
              filteredProductList: tmpProds.sort((a: IProduct, b: IProduct) => {
                return state.priceOrderType === 'asc'
                  ? a.Prices?.Special?.TRY - b.Prices?.Special?.TRY
                  : b.Prices?.Special?.TRY - a.Prices?.Special?.TRY
              }),
              searchComplete: true,
              excelProduct: filterProduct,
              totalProduct: filterProduct.length,
            })
          })
          .catch((err: any) => {
            props.hideLoading && props.hideLoading()
            setState({ searchComplete: true })
          })

        return
      }
      if (searchParams.get('bannerId') !== null) {
        const tmpProds = _.clone(state.filteredProductList)

        return setState({
          filteredProductList: tmpProds.sort((a: IProduct, b: IProduct) => {
            return state.priceOrderType === 'asc'
              ? a.Prices?.Special?.TRY - b.Prices?.Special?.TRY
              : b.Prices?.Special?.TRY - a.Prices?.Special?.TRY
          }),
        })
      }
      getProductsByCategoryId(1, true)
    }
  }, [state.priceOrderType])

  const onCategoryCheckboxClick = (checked: boolean, categoryId: number) => {
    let selectedCategories: number[] = []

    if (checked && state.selectedCategories?.indexOf(categoryId) === -1) {
      selectedCategories = [...state.selectedCategories, categoryId]
    } else if (!checked && state.selectedCategories && state.selectedCategories?.indexOf(categoryId) > -1) {
      selectedCategories = state.selectedCategories?.filter((val: number) => val !== categoryId)
    }

    setPaginationPage(1)

    setState({ selectedCategories, selectedProductsToCompare: [], addListToBasketUI: [] })
    state.selectedCategories = selectedCategories

    filterProductsByClientFilters(true)
  }

  const onCategorySelectSearch = (checked: boolean, categoryId: number) => {
    let selectedCategories: number[] = []

    if (checked && state.selectedCategories?.indexOf(categoryId) === -1) {
      selectedCategories = [...state.selectedCategories, categoryId]
    } else if (!checked && state.selectedCategories && state.selectedCategories?.indexOf(categoryId) > -1) {
      selectedCategories = state.selectedCategories?.filter((val: number) => val !== categoryId)
    }

    setPaginationPage(1)

    setState({ selectedCategories, selectedProductsToCompare: [], addListToBasketUI: [] })
    state.selectedCategories = selectedCategories
  }

  // const onBrandCheckboxClick = (checked: boolean, brandId: number) => {
  //   let selectedBrands: number[] = []

  //   if (checked && state.selectedBrands.indexOf(brandId) === -1) {
  //     selectedBrands = [...state.selectedBrands, brandId]
  //   } else if (!checked && state.selectedBrands.indexOf(brandId) > -1) {
  //     selectedBrands = state.selectedBrands.filter((val: number) => val !== brandId)
  //   }

  //   setPaginationPage(1)

  //   setState({ selectedBrands: selectedBrands })
  //   state.selectedBrands = selectedBrands

  //   filterProductsByClientFilters()
  // }

  const onSellerCheckboxClick = (checked: boolean, seller: number) => {
    let selectedSellers: number[] = []

    if (checked && state.selectedSellers?.indexOf(seller) === -1) {
      selectedSellers = [...state.selectedSellers, seller]
    } else if (!checked && state.selectedSellers && state.selectedSellers?.indexOf(seller) > -1) {
      selectedSellers = state.selectedSellers?.filter((val: number) => val !== seller)
    }

    setPaginationPage(1)

    setState({ selectedSellers })
    state.selectedSellers = selectedSellers

    if (state.new === false) {
      filterProductsByClientFilters()
    }
  }

  useEffect(() => {
    setState({ excelProduct: state.filteredProductList })
  }, [state.filteredProductList])

  //#region Excel ve Compare Process
  const handleExcel = () => {
    const qpSearchKey = window.location.href.split('searchKey=')[1]
    const qpBrand = searchParams.get('brand')
    const qpCategory = searchParams.get('category')
    const qpLinkId = searchParams.get('linkId')
    const qpCampaigns = searchParams.get('campaigns')
    const data: any[] = []
    if (state.selectedProductsToCompare.length === 0) {
      if (!state.totalProduct || state.totalProduct === 0) {
        return Swal.fire({
          icon: 'error',
          title: `Excele aktarılacak ürün bulunmamaktadır.`,
          showConfirmButton: true,
        })
      }
      Swal.fire({
        icon: 'question',
        title: `Toplam ${state.totalProduct} adet ürünü excel'e aktarmak istiyor musunuz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Ürün Seç',
        showCancelButton: true,
        cancelButtonText: 'Kapat',
      }).then(async result => {
        if (result.isConfirmed) {
          // if (state.excelProduct) {
          //   state.excelProduct.map(pro => {
          //     return data.push({
          //       Brand: pro.Brand,
          //       ProductGroup:
          //         pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
          //       Stock: pro.Stock ? String(pro.Stock.Stock) : '',
          //       ProductCode: pro.ProductCode,
          //       ProductDesc: pro.Name,
          //       Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
          //       List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
          //       TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
          //       Company:
          //         pro.CompanyId === 1
          //           ? 'Index'
          //           : pro.CompanyId === 2
          //             ? 'Netex'
          //             : pro.CompanyId === 3
          //               ? 'Despec'
          //               : pro.CompanyId === 4
          //                 ? 'Datagate'
          //                 : '',
          //     })
          //   })
          //   const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
          //   postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
          //     if (result) {
          //       props.hideLoading()
          //       downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
          //     }
          //     return result
          //   })
          //   return data
          // }
          if (qpCampaigns) {
            props.showLoading && props.showLoading()
            const getCampaignToProductsApi: GetCampaignToProductsApi = new GetCampaignToProductsApi({})
            getCampaignToProductsApi.getCampaignToProducts(+qpCampaigns).then((res: any) => {
              if (res) {
                res.products.map(pro => {
                  return data.push({
                    Brand: pro.Brand,
                    ProductGroup:
                      pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                    Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                    ProductCode: pro.ProductCode,
                    ProductDesc: pro.Name,
                    Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                    List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                    TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                    Company:
                      pro.CompanyId === 1
                        ? 'Index'
                        : pro.CompanyId === 2
                          ? 'Netex'
                          : pro.CompanyId === 3
                            ? 'Despec'
                            : pro.CompanyId === 4
                              ? 'Datagate'
                              : '',
                  })
                })
                const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                  if (result) {
                    props.hideLoading()
                    downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                  }
                  return result
                }).catch((err: IException) => {
                  props.hideLoading && props.hideLoading()
                  setState({ searchComplete: true })
                })
                return data
              } else return
            }).catch((err: IException) => {
              props.hideLoading && props.hideLoading()
              setState({ searchComplete: true })
            })
          }
          if (qpLinkId) {
            props.showLoading && props.showLoading()
            const getProductLinkApi: GetProductLinkApi = new GetProductLinkApi({})
            getProductLinkApi.getProductLink(+qpLinkId).then((res: any) => {
              if (res) {
                let filterProduct: any = []
                if (state.isStockChecked)
                  filterProduct = res.ProductList.filter(res => {
                    return res.Stock && res.Stock.Stock && res.Stock.Stock > 0
                  })
                filterProduct.map(pro => {
                  return data.push({
                    Brand: pro.Brand,
                    ProductGroup:
                      pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                    Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                    ProductCode: pro.ProductCode,
                    ProductDesc: pro.Name,
                    Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                    List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                    TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                    Company:
                      pro.CompanyId === 1
                        ? 'Index'
                        : pro.CompanyId === 2
                          ? 'Netex'
                          : pro.CompanyId === 3
                            ? 'Despec'
                            : pro.CompanyId === 4
                              ? 'Datagate'
                              : '',
                  })
                })
                const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                  if (result) {
                    props.hideLoading()
                    downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                  }
                  return result
                }).catch((err: IException) => {
                  props.hideLoading && props.hideLoading()
                  setState({ searchComplete: true })
                })
                return data
              } else return
            }).catch((err: IException) => {
              props.hideLoading && props.hideLoading()
              setState({ searchComplete: true })
            })
            return
          }
          if (qpCategory && qpBrand) {
            props.showLoading && props.showLoading()
            const getListProductsByBrandAndProductGroupApi: GetListProductsByBrandAndProductGroupApi =
              new GetListProductsByBrandAndProductGroupApi({})
            getListProductsByBrandAndProductGroupApi
              .getListProductsByBrandAndProductGroup(qpCategory, qpBrand)
              .then((res: any) => {
                if (res) {
                  res.map(pro => {
                    return data.push({
                      Brand: pro.Brand,
                      ProductGroup:
                        pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                      Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                      ProductCode: pro.ProductCode,
                      ProductDesc: pro.Name,
                      Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                      List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                      TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                      Company:
                        pro.CompanyId === 1
                          ? 'Index'
                          : pro.CompanyId === 2
                            ? 'Netex'
                            : pro.CompanyId === 3
                              ? 'Despec'
                              : pro.CompanyId === 4
                                ? 'Datagate'
                                : '',
                    })
                  })
                  const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                  postOrderExcelApi
                    .postProductCompareExcel(data)
                    .then((result: IProductCompareExcelResponse) => {
                      if (result) {
                        props.hideLoading()
                        downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                      }
                      return result
                    })
                    .catch(err => {
                      props.hideLoading()
                      return
                    })
                  return data
                } else return
              })
              .catch(err => {
                props.hideLoading()
                return
              })
            return
          }

          if (!(qpSearchKey && qpSearchKey.length > 0)) {
            if (state.brandId) {
              const globalSearch = searchParams.get('global-search')

              // function getSellerFromUrl(url) {
              //   const urlParams = new URLSearchParams(url)
              //   return urlParams.get('seller')
              // }

              let req = state.searchReq
              req.take = state.totalProduct
              if (oldUrl !== window.location.href) {
                setState({ selectedSellers: [] })
                if (theme.id > 0 && !globalSearch) setState({ selectedSellers: [theme.id] })
                setOldUrl(window.location.href)
              }
              props.showLoading()
              new GetProductListByBrandIdApi({}).getProductsList(req).then((res: any) => {
                res.products.map(pro => {
                  return data.push({
                    Brand: pro.Brand,
                    ProductGroup:
                      pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                    Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                    ProductCode: pro.ProductCode,
                    ProductDesc: pro.Name,
                    Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                    List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                    TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                    Company:
                      pro.CompanyId === 1
                        ? 'Index'
                        : pro.CompanyId === 2
                          ? 'Netex'
                          : pro.CompanyId === 3
                            ? 'Despec'
                            : pro.CompanyId === 4
                              ? 'Datagate'
                              : '',
                  })
                })
                const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                  if (result) {
                    props.hideLoading()
                    downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                  }
                  return result
                }).catch((err: IException) => {
                  props.hideLoading && props.hideLoading()
                  setState({ searchComplete: true })
                })
                return data
              })
            } else if (state.categoryId) {
              const globalSearch = searchParams.get('global-search')
              let req = state.searchReq
              req.take = state.totalProduct
              if (oldUrl !== window.location.href) {
                setState({ selectedSellers: [] })
                if (theme.id > 0 && !globalSearch) setState({ selectedSellers: [theme.id] })
                setOldUrl(window.location.href)
              }
              props.showLoading()
              new GetProductListByCategoryIdApi({}).getProductsList(req).then((res: any) => {
                res.products.map(pro => {
                  return data.push({
                    Brand: pro.Brand,
                    ProductGroup:
                      pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                    Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                    ProductCode: pro.ProductCode,
                    ProductDesc: pro.Name,
                    Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                    List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                    TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                    Company:
                      pro.CompanyId === 1
                        ? 'Index'
                        : pro.CompanyId === 2
                          ? 'Netex'
                          : pro.CompanyId === 3
                            ? 'Despec'
                            : pro.CompanyId === 4
                              ? 'Datagate'
                              : '',
                  })
                })
                const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                  if (result) {
                    props.hideLoading()
                    downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                  }
                  return result
                }).catch((err: IException) => {
                  props.hideLoading && props.hideLoading()
                  setState({ searchComplete: true })
                })
                return data
              })
            } else if (state.bannerId) {
              const getProductListApi: GetProductListByBannerIdApi = new GetProductListByBannerIdApi({})
              props.showLoading && props.showLoading()
              setState({ searchComplete: false })
              getProductListApi
                .getProductsList(state.bannerId)
                .then((res: IProduct[]) => {
                  res.map(pro => {
                    return data.push({
                      Brand: pro.Brand,
                      ProductGroup:
                        pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                      Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                      ProductCode: pro.ProductCode,
                      ProductDesc: pro.Name,
                      Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                      List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                      TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                      Company:
                        pro.CompanyId === 1
                          ? 'Index'
                          : pro.CompanyId === 2
                            ? 'Netex'
                            : pro.CompanyId === 3
                              ? 'Despec'
                              : pro.CompanyId === 4
                                ? 'Datagate'
                                : '',
                    })
                  })
                  const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                  postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                    if (result) {
                      props.hideLoading()
                      downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                    }
                    return result
                  }).catch((err: IException) => {
                    props.hideLoading && props.hideLoading()
                    setState({ searchComplete: true })
                  })
                  return data
                })
                .catch((err: IException) => {
                  props.hideLoading && props.hideLoading()
                  setState({ searchComplete: true })
                })
            } else if (state.specialFilter) {
              if (state.specialFilter === 'promotional-products') {
                const req: ICampaignsProducts = {
                  PageSize: state.totalProduct!,
                  PageNumber: 0,
                  CampaignId: 0,
                  Company: theme.id,
                }
                const api: GetCampaignsProductsApi = new GetCampaignsProductsApi({})
                api.getCampaignsProducts(req).then((res: any) => {
                  res.Products.map(pro => {
                    return data.push({
                      Brand: pro.Brand,
                      ProductGroup:
                        pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                      Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                      ProductCode: pro.ProductCode,
                      ProductDesc: pro.Name,
                      Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                      List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                      TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                      Company:
                        pro.CompanyId === 1
                          ? 'Index'
                          : pro.CompanyId === 2
                            ? 'Netex'
                            : pro.CompanyId === 3
                              ? 'Despec'
                              : pro.CompanyId === 4
                                ? 'Datagate'
                                : '',
                    })
                  })
                  const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                  postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                    if (result) {
                      props.hideLoading()
                      downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                    }
                    return result
                  }).catch((err: IException) => {
                    props.hideLoading && props.hideLoading()
                    setState({ searchComplete: true })
                  })
                  return data
                })
                return
              }
              if (state.specialFilter === 'best-sellers') {
                props.showLoading()
                const api: GetMostSelledProductsByCatagoriesApi = new GetMostSelledProductsByCatagoriesApi({})
                const queryParams: KeyValuePair<number> = new KeyValuePair<number>()
                queryParams.add('dayBefore', 7)
                queryParams.add('minSelledProductCount ', 0)
                queryParams.add('minSelledProductQuantityCount', 5)
                queryParams.add('company', theme.id)
                api.queryParams = queryParams
                api.getMostSelledProductsByCatagories().then((res: IBestSellers[]) => {
                  if (res) {
                    res.map(r => {
                      return data.push({
                        Brand: r.Product.Brand,
                        ProductGroup:
                          r.Product.MenuTitle !== null
                            ? r.Product.MenuTitle
                            : r.Product.Category.Name !== null
                              ? r.Product.Category.Name
                              : '',
                        Stock: r.Product.Stock ? String(r.Product.Stock.Stock) : '',
                        ProductCode: r.Product.ProductCode,
                        ProductDesc: r.Product.Name,
                        Special: r.Product.Prices
                          ? r.Product.Prices.Special
                            ? r.Product.Prices.Special.Price
                            : ''
                          : '',
                        List: r.Product.Prices ? (r.Product.Prices.Normal ? r.Product.Prices.Normal.Price : '') : '',
                        TESK: r.Product.Prices ? (r.Product.Prices.EndUser ? r.Product.Prices.EndUser.Price : '') : '',
                        Company:
                          r.Product.CompanyId === 1
                            ? 'Index'
                            : r.Product.CompanyId === 2
                              ? 'Netex'
                              : r.Product.CompanyId === 3
                                ? 'Despec'
                                : r.Product.CompanyId === 4
                                  ? 'Datagate'
                                  : '',
                      })
                    })
                    const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                    postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                      if (result) {
                        props.hideLoading()
                        downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                      }
                      return result
                    }).catch((err: IException) => {
                      props.hideLoading && props.hideLoading()
                      setState({ searchComplete: true })
                    })
                    return data
                  }
                })
                return
              }
              const getProductListApi: GetProductsBySectionApi = new GetProductsBySectionApi({})
              props.showLoading && props.showLoading()
              setState({ searchComplete: false })
              getProductListApi
                .getProductsList(state.specialFilter)
                .then((res: IGetProductsBySectionResponse) => {
                  res.Products.map(pro => {
                    return data.push({
                      Brand: pro.Brand,
                      ProductGroup:
                        pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                      Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                      ProductCode: pro.ProductCode,
                      ProductDesc: pro.Name,
                      Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                      List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                      TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                      Company:
                        pro.CompanyId === 1
                          ? 'Index'
                          : pro.CompanyId === 2
                            ? 'Netex'
                            : pro.CompanyId === 3
                              ? 'Despec'
                              : pro.CompanyId === 4
                                ? 'Datagate'
                                : '',
                    })
                  })
                  const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                  postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                    if (result) {
                      props.hideLoading()
                      downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                    }
                    return result
                  }).catch((err: IException) => {
                    props.hideLoading && props.hideLoading()
                    setState({ searchComplete: true })
                  })
                })
                .catch((err: IException) => {
                  props.hideLoading && props.hideLoading()
                  setState({ searchComplete: true })
                })
            } else {
              const getProductListApi: GetProductListApi = new GetProductListApi({})
              props.showLoading && props.showLoading()
              setState({ searchComplete: false })
              getProductListApi
                .getProducts()
                .then((res: IProduct[]) => {
                  res.map(pro => {
                    return data.push({
                      Brand: pro.Brand,
                      ProductGroup:
                        pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                      Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                      ProductCode: pro.ProductCode,
                      ProductDesc: pro.Name,
                      Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                      List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                      TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                      Company:
                        pro.CompanyId === 1
                          ? 'Index'
                          : pro.CompanyId === 2
                            ? 'Netex'
                            : pro.CompanyId === 3
                              ? 'Despec'
                              : pro.CompanyId === 4
                                ? 'Datagate'
                                : '',
                    })
                  })
                  const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
                  postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                    if (result) {
                      props.hideLoading()
                      downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                    }
                    return result
                  }).catch((err: IException) => {
                    props.hideLoading && props.hideLoading()
                    setState({ searchComplete: true })
                  })
                })
                .catch((err: IException) => {
                  props.hideLoading && props.hideLoading()
                  setState({ searchComplete: true })
                })
            }
          } else {
            const globalSearch = searchParams.get('global-search')
            let req = state.searchReq
            req.Take = state.totalProduct
            if (oldUrl !== window.location.href) {
              setState({ selectedSellers: [] })
              if (theme.id > 0 && !globalSearch) setState({ selectedSellers: [theme.id] })
              setOldUrl(window.location.href)
            }
            const searchByCategoriesAndCompaniesApi: SearchProductsApi = new SearchProductsApi({})
            searchByCategoriesAndCompaniesApi.searchProductsByCategoriesAndCompanies(req).then(res => {
              res.products.map(pro => {
                return data.push({
                  Brand: pro.Brand,
                  ProductGroup:
                    pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
                  Stock: pro.Stock ? String(pro.Stock.Stock) : '',
                  ProductCode: pro.ProductCode,
                  ProductDesc: pro.Name,
                  Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
                  List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
                  TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
                  Company:
                    pro.CompanyId === 1
                      ? 'Index'
                      : pro.CompanyId === 2
                        ? 'Netex'
                        : pro.CompanyId === 3
                          ? 'Despec'
                          : pro.CompanyId === 4
                            ? 'Datagate'
                            : '',
                })
              })
              const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
              postOrderExcelApi.postProductCompareExcel(data).then((result: IProductCompareExcelResponse) => {
                if (result) {
                  props.hideLoading()
                  downloadFileFromUrl('Ürün Listeleme', result.FileUrl)
                }
                return result
              }).catch((err: IException) => {
                props.hideLoading && props.hideLoading()
                setState({ searchComplete: true })
              })
              return data
            })
          }
        } else return
      })
    } else {
      state.selectedProductsToCompare.map(pro => {
        return data.push({
          Brand: pro.Brand,
          ProductGroup: pro.MenuTitle !== null ? pro.MenuTitle : pro.Category.Name !== null ? pro.Category.Name : '',
          Stock: pro.Stock ? String(pro.Stock.Stock) : '',
          ProductCode: pro.ProductCode,
          ProductDesc: pro.Name,
          Special: pro.Prices ? (pro.Prices.Special ? pro.Prices.Special.Price : '') : '',
          List: pro.Prices ? (pro.Prices.Normal ? pro.Prices.Normal.Price : '') : '',
          TESK: pro.Prices ? (pro.Prices.EndUser ? pro.Prices.EndUser.Price : '') : '',
          Company:
            pro.CompanyId === 1
              ? 'Index'
              : pro.CompanyId === 2
                ? 'Netex'
                : pro.CompanyId === 3
                  ? 'Despec'
                  : pro.CompanyId === 4
                    ? 'Datagate'
                    : '',
        })
      })
      const postOrderExcelApi: PostProductCompareExcelApi = new PostProductCompareExcelApi({})
      postOrderExcelApi.postProductCompareExcel(data).then((res: IProductCompareExcelResponse) => {
        if (res) {
          downloadFileFromUrl('Ürün Listeleme', res.FileUrl)
        }
      }).catch((err: IException) => {
        props.hideLoading && props.hideLoading()
        setState({ searchComplete: true })
      })
    }
  }

  const handleCompare = () => {
    let errorMessage: string = ''
    let firstCategoryGroup: number
    let categoryError = false

    //ilk seçilen kategoriyi kaydet
    state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
      firstCategoryGroup = productItem.CategoryGroup1
      return 1
    })

    //başka bir kategori seçilmişse hata döndür
    if (
      state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
        return firstCategoryGroup !== productItem.CategoryGroup1
      }) !== -1
    )
      categoryError = true

    if (categoryError)
      errorMessage =
        'Sadece aynı kategorideki ürünler karşılaştırılabilir. Karşılaştırmakta olduğunuz ürünü silmek ister misiniz?'
    else if (state.selectedProductsToCompare.length < 2)
      errorMessage =
        'Karşılaştırma için en az 2 ürün seçilmelidir. Karşılaştırmakta olduğunuz ürünü silmek ister misiniz?'
    else if (state.selectedProductsToCompare.length > 4)
      errorMessage =
        'Karşılaştırma için en fazla 4 ürün seçilebilir. Karşılaştırmakta olduğunuz ürünü silmek ister misiniz?'
    else if (state.selectedProductsToCompare.length < 5 && state.selectedProductsToCompare.length > 1) {
      setModalIsOpen(true)
      props.showLoading()
      return
    }

    Swal.fire({
      icon: 'question',
      title: errorMessage,
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        setState({
          selectedProductsToCompare: [],
          addListToBasketUI: [],
        })
      }
    })
    return setModalIsOpen(false)
  }

  const handleCompareCloseButton = () => {
    Swal.fire({
      icon: 'question',
      title: 'Karşılaştırmakta olduğunuz ürünü silmek ister misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        setState({
          selectedProductsToCompare: [],
          addListToBasketUI: [],
        })
      }
    })
    return setModalIsOpen(false)
  }
  //#endregion

  const url = window.location.href

  const selectProductToCompare = (product: IProduct) => {
    if (
      state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
        return productItem.Id === product.Id
      }) === -1
    ) {
      setState({
        selectedProductsToCompare: [...state.selectedProductsToCompare, product],
      })
    } else {
      // If the product is in the comparison list, it is removed from the list.
      setState({
        selectedProductsToCompare: state.selectedProductsToCompare.filter((productElem: IProduct) => {
          return productElem.Id !== product.Id
        }),
      })
    }
  }
  const onAddListToBasketUI = (product: IProduct, quantity: number, opportunity: boolean, selected: boolean) => {
    if (
      addListToBasketUI.findIndex(pro => {
        return +pro.ProductId === +product.Id
      }) > -1
    ) {
      const req: IAddListToBasketUI[] = []
      if (selected) {
        addListToBasketUI.map(add => {
          if (add.ProductId === product.Id) {
            return req.push({
              DetailId: 0,
              ProductId: product.Id,
              Quantity: +quantity,
              IsOppurtunityStock: opportunity,
              OpportunitiyStockId: opportunity ? (product.OpportunityId ? product.OpportunityId : 0) : 0,
            })
          }
          return req.push(add)
        })
        setAddListToBasketUI(req)
        return
      } else {
        setAddListToBasketUI(prevState => {
          return prevState.filter(state => {
            return state.ProductId !== product.Id
          })
        })
        return
      }
    } else {
      const req: IAddListToBasketUI = {
        DetailId: 0,
        ProductId: product.Id,
        Quantity: +quantity,
        IsOppurtunityStock: opportunity,
        OpportunitiyStockId: opportunity ? (product.OpportunityId ? product.OpportunityId : 0) : 0,
      }
      setAddListToBasketUI([...addListToBasketUI, req])
    }
  }

  const isCategorySelected = (categoryId: number) => {
    return state.selectedCategories && state.selectedCategories.indexOf(categoryId) > -1
  }

  // const isBrandSelected = (brandId: number) => {
  //   return state.selectedBrands && state.selectedBrands.indexOf(brandId) > -1
  // }

  const isSellerSelected = (sellerId: number) => {
    return state.selectedSellers && state.selectedSellers.indexOf(sellerId) > -1
  }
  const navigate = useNavigate()

  const addToCartMultiple = () => {
    dispatch(addListToBasketUIMultiRedux(addListToBasketUI))
      .then((result: any) => {
        Swal.fire({
          html: `<h5>${t('components.AddToBasket.productWasAddedToBasket')}</h5>`,
          confirmButtonText: `${t('components.AddToBasket.goToBasket')}`,
          confirmButtonColor: '#ec4c4c',
          cancelButtonText: `${t('components.AddToBasket.continueToShopping')}`,
          showCancelButton: true,
          timer: 4000,
          timerProgressBar: true,
        }).then(result => {
          if (result.isConfirmed) {
            navigate('/mycard')
          } else if (result.isDenied) {
          }
        })
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const user = useAppSelector(currentUser)

  const forwardIQuotePage = (product: IProduct) => {
    /**
      Mandatory Field uEmail was not included in the request body
      Mandatory Field uName was not included in the request body
      Mandatory Field cAccountNum was not included in the request body
      Mandatory Field cName was not included in the request body
      Mandatory Field cPCode was not included in the request body
      */

    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.uEmail, '0000203179-netex@artim.com.tr')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.uName, 'bvcb')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.cAccountNum, '054455411100000')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.cName, 'com')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.cPCode, '34406')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.base, product.ProductCode)
    iQuotaFormRef.current?.setFormInputValueByName(
      ProductIQuotaFormField.ref,
      `${window.location.origin}/api/iquote-service-response/${user.SessionId}`
    )
    iQuotaFormRef.current?.setFormInputValueByName(
      ProductIQuotaFormField.BasketURL,
      `${window.location.origin}/api/iquote-service-response/${user.SessionId}`
    )

    iQuotaFormRef.current?.submitForm()
  }

  const removeItemFromCompareList = (productId: number) => {
    setState({
      selectedProductsToCompare: state.selectedProductsToCompare.filter(
        (compareItem: IProduct) => compareItem.Id !== productId
      ),
    })
  }

  const defaultTooltipState: TooltipState = {
    show: false,
    coords: {
      top: 0,
      left: 0,
    },
    content: '',
  }

  const [tooltip, setTooltip] = useState(defaultTooltipState)

  const handleTooltip = (e, content) => {
    setTooltip(prev => ({ ...prev, show: true, coords: getPosition(e), content }))
  }

  const closeTooltip = () => {
    setTooltip(prev => ({ ...prev, show: false }))
  }

  //#region Filter Process
  const updateFilteredProductList = () => {
    if (selectedFeatures.length === 0) {
      setState({ filteredProductList: state.productList })
      return
    }

    const filteredProducts: IProduct[] = []
    for (const feature of selectedFeatures) {
      for (const p of state.productList) {
        p.ProductFeaturedResponseModels &&
          p.ProductFeaturedResponseModels.forEach(x => {
            if (
              +x.Featureid === +feature.Featureid &&
              x.FeatureValue.toLowerCase() === feature.Attributevalue.toLowerCase() &&
              ((state.selectedSellers && state.selectedSellers?.length > 0 &&
                state.selectedSellers?.findIndex(seller => {
                  return +seller === +p.CompanyId
                }) > -1) ||
                state.selectedSellers?.length === 0)
            ) {
              if (filteredProducts.indexOf(p) === -1) {
                filteredProducts.push(p)
              }
            }
          })
      }
    }
    setState({ filteredProductList: filteredProducts })
  }

  useEffect(() => {
    updateFilteredProductList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFeatures])

  const handleFilter = (fId: number, aValue: string, checked: boolean) => {
    if (checked) {
      setSelectedFeatures([...selectedFeatures, { Featureid: fId, Attributevalue: aValue }])
      return
    }
    setSelectedFeatures(selectedFeatures.filter(f => f.Attributevalue !== aValue))
  }

  function handleFilterNew() {
    if (searchParams.get('searchKey') !== null) {
      return getSearchResults(1, true)
    }
    if (searchParams.get('brand') !== null) {
      return getBrandProducts(1, true, false)
    }
    if (searchParams.get('model') !== null) {
      return getBrandProducts(1, true)
    }
    getProductsByCategoryId(1, true)
  }

  interface SelectedFeature {
    FeatureId: number
    FeatureValue: string
  }
  const [selectedFeatureList, setSelectedFeatureList] = useState<SelectedFeature[]>([])

  function handleFeatureSelect(FeatureId: number, FeatureValue: string, checked: boolean) {
    if (checked) {
      const alreadyAdded = distinctFeatures(selectedFeatureList).find(x => x.FeatureId === FeatureId && x.FeatureValue === FeatureValue)
      if (alreadyAdded) return

      setSelectedFeatureList(prev => {
        return [
          ...prev,
          {
            FeatureId,
            FeatureValue,
          },
        ]
      })
      return
    }
    const updatedFeatureList = selectedFeatureList.filter(
      x => !(x.FeatureId === FeatureId && x.FeatureValue === FeatureValue)
    )
    setSelectedFeatureList(distinctFeatures(updatedFeatureList))
  }

  const filterBtnRef = useRef<HTMLButtonElement>(null)
  const sidebarRef = useRef<HTMLDivElement>(null)
  const [filterBtnStyles, setFilterBtnStyles] = useState<any>({ position: 'sticky', bottom: '1rem' })

  const filterButtonWidth = sidebarRef.current ? sidebarRef.current.getBoundingClientRect().width : 220
  //#endregion

  let prevPos = window.scrollY
  const handleScroll = () => {
    if (!filterBtnRef.current) return
    if (!sidebarRef.current) return

    const btnRect = filterBtnRef.current.getBoundingClientRect()
    const btnY = btnRect.top + btnRect.height + window.scrollY

    const sidebarRect = sidebarRef.current.getBoundingClientRect()
    const sidebarHeight = sidebarRect.height + 201

    const scrollTop = window.scrollY + window.innerHeight

    prevPos = btnY

    if (btnY > sidebarHeight) {
      setFilterBtnStyles({ position: 'absolute', bottom: 0, maxWidth: '-webkit-fill-available' })
    }
    if (scrollTop < prevPos) {
      setFilterBtnStyles({ position: 'fixed', bottom: '1rem', maxWidth: '-webkit-fill-available' })
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const renderProducts = (data: IProduct[]) => (
    <>
      {data.length > 0 && !state.bestSellerBool && (
        <div
        // className={css`
        //   color: #555;
        //   font-size: 18px;
        //   font-weight: 600;
        //   margin-bottom: 0.8rem;
        //   border-bottom: 1px solid #aaa;
        // `}
        >
          {data[0].MenuTitle}
        </div>
      )}

      <div
        className={`productCardGrid ${state.selectedCardView === '1'
          ? 'productCardGrid1'
          : state.selectedCardView === '2'
            ? 'productCardGrid2'
            : state.selectedCardView === '3'
              ? 'productCardGrid3'
              : ''
          }`}
      >
        {data.map((product: IProduct, index) => {
          if (state.selectedCardView === '1') {
            return (
              <Suspense fallback={null}>
                <ProductCard1
                  bestSellers={state.bestSellersData}
                  index={index}
                  key={product.Id}
                  product={product}
                  isOpportunityMarket={
                    searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'opportunit-market'
                      ? true
                      : false
                  }
                  isPromotionalProducts={
                    searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'promotional-products'
                      ? true
                      : false
                  }
                  isBestSellers={
                    searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'best-sellers'
                      ? true
                      : false
                  }
                  bestSellersCampaing={state.bestSellersCampaing}
                  isSelected={
                    state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
                      return productItem.Id === product.Id
                    }) > -1
                  }
                  forwardIQuotePage={forwardIQuotePage}
                  onSelectProductForCompare={() => selectProductToCompare(product)}
                  onAddListToBasketUI={onAddListToBasketUI}
                  handleTooltip={handleTooltip}
                  closeTooltip={closeTooltip}
                />
              </Suspense>
            )
          }

          if (state.selectedCardView === '2') {
            return (
              <Suspense fallback={null}>
                <ProductCard2
                  bestSellers={state.bestSellersData}
                  index={index}
                  key={product.Id}
                  product={product}
                  isOpportunityMarket={
                    searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'opportunit-market'
                      ? true
                      : false
                  }
                  isPromotionalProducts={
                    searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'promotional-products'
                      ? true
                      : false
                  }
                  isBestSellers={
                    searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'best-sellers'
                      ? true
                      : false
                  }
                  bestSellersCampaing={state.bestSellersCampaing}
                  isSelected={
                    state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
                      return productItem.Id === product.Id
                    }) > -1
                  }
                  forwardIQuotePage={forwardIQuotePage}
                  onSelectProductForCompare={() => selectProductToCompare(product)}
                  onAddListToBasketUI={onAddListToBasketUI}
                  handleTooltip={handleTooltip}
                  closeTooltip={closeTooltip}
                />
              </Suspense>
            )
          }

          if (state.selectedCardView === '3') {
            return (
              <Suspense fallback={null}>
                <ProductCard3
                  bestSellers={state.bestSellersData}
                  index={index}
                  key={product.Id}
                  product={product}
                  isOpportunityMarket={
                    searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'opportunit-market'
                      ? true
                      : false
                  }
                  isPromotionalProducts={
                    searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'promotional-products'
                      ? true
                      : false
                  }
                  isSelected={
                    state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
                      return productItem.Id === product.Id
                    }) > -1
                  }
                  isBestSellers={
                    searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'best-sellers'
                      ? true
                      : false
                  }
                  bestSellersCampaing={state.bestSellersCampaing}
                  forwardIQuotePage={forwardIQuotePage}
                  onSelectProductForCompare={() => selectProductToCompare(product)}
                  onAddListToBasketUI={onAddListToBasketUI}
                  handleTooltip={handleTooltip}
                  closeTooltip={closeTooltip}
                />
              </Suspense>
            )
          }

          return null
        })}
      </div>
    </>
  )

  const products = () => {
    if (
      state.new === true &&
      (searchParams.get('searchKey') !== null ||
        (searchParams.get('brand') !== null && searchParams.get('category') === null)) &&
      state.productGroups
    )
      return Object.keys(state.productGroups).map((x: any) => {
        const name =
          state.SearchMenuCatAndCounts2.length > 0 &&
          state.SearchMenuCatAndCounts2.find(y => +y.OrderNum === +x)?.MenuTitle
        return (
          <div className='mb-4'>
            {
              <div
                className={css`
                  color: #555;
                  font-size: 18px;
                  font-weight: 600;
                  margin-bottom: 0.8rem;
                  // border-bottom: 1px solid #aaa;
                `}
              >
                {searchParams.get('brand') !== null ? (x !== null ? x : '') : name !== null ? name : ''}
              </div>
            }
            <div
              className={`productCardGrid ${state.selectedCardView === '1'
                ? 'productCardGrid1'
                : state.selectedCardView === '2'
                  ? 'productCardGrid2'
                  : state.selectedCardView === '3'
                    ? 'productCardGrid3'
                    : ''
                }`}
            >
              {Object.keys(state.productGroups[x]).map((p: any, index) => {
                const product = state.productGroups[x][p]
                if (state.selectedCardView === '1') {
                  return (
                    <Suspense fallback={null}>
                      <ProductCard1
                        bestSellers={state.bestSellersData}
                        index={index}
                        key={product.Id}
                        product={product}
                        isOpportunityMarket={
                          searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'opportunit-market'
                            ? true
                            : false
                        }
                        isPromotionalProducts={
                          searchParams.get('specialFilter') &&
                            searchParams.get('specialFilter') === 'promotional-products'
                            ? true
                            : false
                        }
                        isBestSellers={
                          searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'best-sellers'
                            ? true
                            : false
                        }
                        bestSellersCampaing={state.bestSellersCampaing}
                        isSelected={
                          state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
                            return productItem.Id === product.Id
                          }) > -1
                        }
                        forwardIQuotePage={forwardIQuotePage}
                        onSelectProductForCompare={() => selectProductToCompare(product)}
                        onAddListToBasketUI={onAddListToBasketUI}
                        handleTooltip={handleTooltip}
                        closeTooltip={closeTooltip}
                      />
                    </Suspense>
                  )
                }

                if (state.selectedCardView === '2') {
                  return (
                    <Suspense fallback={null}>
                      <ProductCard2
                        bestSellers={state.bestSellersData}
                        index={index}
                        key={product.Id}
                        product={product}
                        isOpportunityMarket={
                          searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'opportunit-market'
                            ? true
                            : false
                        }
                        isPromotionalProducts={
                          searchParams.get('specialFilter') &&
                            searchParams.get('specialFilter') === 'promotional-products'
                            ? true
                            : false
                        }
                        isBestSellers={
                          searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'best-sellers'
                            ? true
                            : false
                        }
                        bestSellersCampaing={state.bestSellersCampaing}
                        isSelected={
                          state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
                            return productItem.Id === product.Id
                          }) > -1
                        }
                        forwardIQuotePage={forwardIQuotePage}
                        onSelectProductForCompare={() => selectProductToCompare(product)}
                        onAddListToBasketUI={onAddListToBasketUI}
                        handleTooltip={handleTooltip}
                        closeTooltip={closeTooltip}
                      />
                    </Suspense>
                  )
                }

                if (state.selectedCardView === '3') {
                  return (
                    <Suspense fallback={null}>
                      <ProductCard3
                        bestSellers={state.bestSellersData}
                        index={index}
                        key={product.Id}
                        product={product}
                        isOpportunityMarket={
                          searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'opportunit-market'
                            ? true
                            : false
                        }
                        isPromotionalProducts={
                          searchParams.get('specialFilter') &&
                            searchParams.get('specialFilter') === 'promotional-products'
                            ? true
                            : false
                        }
                        isSelected={
                          state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
                            return productItem.Id === product.Id
                          }) > -1
                        }
                        isBestSellers={
                          searchParams.get('specialFilter') && searchParams.get('specialFilter') === 'best-sellers'
                            ? true
                            : false
                        }
                        bestSellersCampaing={state.bestSellersCampaing}
                        forwardIQuotePage={forwardIQuotePage}
                        onSelectProductForCompare={() => selectProductToCompare(product)}
                        onAddListToBasketUI={onAddListToBasketUI}
                        handleTooltip={handleTooltip}
                        closeTooltip={closeTooltip}
                      />
                    </Suspense>
                  )
                }

                return null
              })}
            </div>
          </div>
        )
      })
    if (state.new === true && searchParams.get('searchKey') === null && searchParams.get('brand') === null)
      return renderProducts(state.filteredProductList)
    if (state.new === true && searchParams.get('brand') !== null && searchParams.get('category') !== null)
      return renderProducts(state.filteredProductList)
    if (state.new === true && searchParams.get('searchKey') === null && searchParams.get('brand') === null)
      return renderProducts(state.filteredProductList)
    if (state.new === false && searchParams.get('searchKey') === null && searchParams.get('brand') === null)
      return renderProducts(paginationList)
  }

  const pagination = () => {
    if (state.totalProduct && state.totalProduct < 52) return null
    if (state.isCampaignChecked) {
      return null
    }
    if (state.new === false) {
      return (
        <div className='col-md-12 mt-4'>
          <Pagination totalPage={totalPage} currentPage={selectedPage} setPage={setPaginationPage} />
        </div>
      )
    }

    let setPage: (page: number) => void = getProductsByCategoryId
    if (searchParams.get('model') !== null) return
    if (searchParams.get('searchKey') !== null) setPage = getSearchResults
    if (searchParams.get('brand') !== null) setPage = getBrandProducts
    if (searchParams.get('specialFilter') === 'promotional-products') setPage = setCampaignPage
    return (
      <div className='col-12 mt-4'>
        <Pagination
          totalPage={Math.ceil(state.totalProduct! / PAGE_SIZE)}
          currentPage={state.currentPage}
          setPage={setPage}
        />
      </div>
    )
  }

  const sıralaOptions: ISelectOption[] = [
    //{ label: 'Sırala', value: ''},
    { label: t('pages.productList.orderByPriceLowToHigh'), value: 'asc' },
    { label: t('pages.productList.orderByPriceHighToLow'), value: 'desc' },
  ]
  const [sortValue, setSortValue] = useState<ISelectOption>()

  // Menu Api
  const getMenuByCompany = (companyId: number) => {
    const api: GetMenusApi = new GetMenusApi({})
    const queryParams: KeyValuePair<number> = new KeyValuePair<number>()
    queryParams.add('companyId', companyId)
    api.queryParams = queryParams
    api.getMenus().then((res: IMenu[]) => {
      setMenuData(res)
    })
  }
  //const menuData = location.state && location.state.menuData ? location.state.menuData : []

  const [menuData, setMenuData] = useState<IMenu[] | undefined>([])

  useEffect(() => {
    if (location.state && location.state.menuData) {
      setMenuData(location.state.menuData)
    }
  }, [location.state])

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const companyId = Number(urlSearchParams.get('companyId')) ?? 0

    const themeValue =
      companyId === 1
        ? 'index'
        : companyId === 2
          ? 'netex'
          : companyId === 3
            ? 'despec'
            : companyId === 4
              ? 'datagate'
              : 'default'
    dispatch(changeTheme(themeValue))

    if (companyId > 0) {
      getMenuByCompany(companyId)
    }
  }, [state.categoryId])

  const hoverClass = css`
    &:hover {
      color: ${props.theme.colors.backgroundColor}!important;
      &::after {
        border-color: ${props.theme.colors.backgroundColor}!important;
      }
    }
  `
  const [activeTab, setActiveTab] = useState('filtreler')

  const groupedCategories = state.MenuCategories.reduce((groups, category) => {
    const parentId = category.ParentID;
    if (!groups[parentId]) {
      groups[parentId] = [];
    }
    groups[parentId].push(category);
    return groups;
  }, {});

  // Gruplanmış kategorileri haritalayarak render edin
  const renderedCategories = Object.keys(groupedCategories).map((parentId) => {
    const categories = groupedCategories[parentId];
    return (
      <div key={parentId}>
        <h5>{categories[0].ParentName}</h5> {/* Assuming all categories in a group have the same ParentName */}
        {categories.map((category) => (
          <div className='form-check ml-0 pl-0 m-b-15' key={category.ID}>
            <input
              className='form-check-input flex-shrink-0'
              value={category.ID}
              id={category.Name}
              type='checkbox'
              checked={isCategorySelected(category.ID)}
              onChange={(e) => {
                onCategorySelectSearch(e.target.checked, category.ID);
                setState({ selectedProductsToCompare: [] });
              }}
            />
            <label className='form-check-label mt-2' htmlFor={category.Name}>
              {category.Name}
            </label>
          </div>
        ))}
      </div>
    );
  });

  return (
    <React.Fragment>
      <section className='sidebar-left p-t-10 p-b-10' style={{ overflow: 'unset' }}>
        <div className='container container-fluid'>
          <div id='page-content' className='sidebar-right p-t-5'>
            <div className='row mt-3'>
              <div className='col-md-3 col-lg-2 pl-lg-0 sidebar sideBarLeft px-2'>
                <div
                  ref={sidebarRef}
                  className='position-sticky'
                  style={{ paddingBottom: 45, top: '200px', maxWidth: '255px', zIndex: '2' }}
                >
                  {/* Tabs */}
                  <div className='d-flex' style={{ maxWidth: '255px', justifyContent: 'space-between' }}>
                    {menuData && searchParams.get('companyId') && menuData.length > 0 ? (
                      <label className='toggle-button'>
                        <input
                          type='checkbox'
                          onChange={() => setActiveTab(activeTab === 'filtreler' ? 'kategoriler' : 'filtreler')}
                        />
                        <span className='knob'></span>
                        <style>{`
                          .toggle-button .knob {
                            background-color: ${theme.colors.backgroundColor};
                          }

                          .toggle-button input:checked + .knob:before {
                            background-color: ${theme.colors.backgroundColor};
                          }
                        `}</style>
                      </label>
                    ) : (
                      <div className='onlyFilter'>
                        <IPButton
                          className='filterTab'
                          style={{ zIndex: activeTab === 'filtreler' ? 2 : 'auto' }}
                          name='filters'
                          onClick={() => setActiveTab('filtreler')}
                          text='Filtreler'
                        />
                      </div>
                    )}
                  </div>
                  {/*Kategoriler Content */}
                  {activeTab === 'kategoriler' && window.innerWidth > 992 && (
                    <div className='container' style={{ padding: '5px 0' }}>
                      <div className='sidebarWrapperProductList'>
                        <div className='sidebar2'>
                          <nav
                            style={{
                              backgroundColor: theme.colors.backgroundColor,
                              maxWidth: '234px',
                            }}
                          >
                            {menuData && searchParams.get('companyId') &&
                              menuData.length > 0 &&
                              menuData.map((menu: IMenu) => {
                                return (
                                  <React.Fragment key={menu.Id}>
                                    {menu.Items && menu.Items.length > 0 ? (
                                      <div className='hasSub'>
                                        <Link key={menu.Id} to={
                                          menu.Link
                                            ? menu.Link
                                            : `/products?categoryId=${menu.Id}${theme.id > 0 ? `&companyId=${theme.id}` : ''
                                            }`
                                        } className={hoverClass}>
                                          {menu.Title}
                                        </Link>
                                        <div className='subMenu'>
                                          {menu.Items.map(item => (
                                            <React.Fragment key={item.Id}>
                                              {item.Items && item.Items.length > 0 ? (
                                                <div className='hasSub'>
                                                  <Link
                                                    key={item.Id}
                                                    to={
                                                      item.Link
                                                        ? item.Link
                                                        : `/products?categoryId=${item.Id}${theme.id > 0 ? `&companyId=${theme.id}` : ''
                                                        }`
                                                    }
                                                    state={{ menuData: menuData }}
                                                    className={hoverClass}
                                                  >
                                                    {item.Title}
                                                  </Link>
                                                  <div className='subMenu'>
                                                    {item.Items.map(subItem => (
                                                      <Link
                                                        key={subItem.Id}
                                                        to={
                                                          subItem.Link
                                                            ? subItem.Link
                                                            : `/products?categoryId=${subItem.Id}${theme.id > 0 ? `&companyId=${theme.id}` : ''
                                                            }`
                                                        }
                                                        state={{ menuData: menuData }}
                                                        className={hoverClass}
                                                      >
                                                        {subItem.Title}
                                                      </Link>
                                                    ))}
                                                  </div>
                                                </div>
                                              ) : (
                                                <Link
                                                  key={item.Id}
                                                  to={
                                                    item.Link
                                                      ? item.Link
                                                      : `/products?categoryId=${item.Id}${theme.id > 0 ? `&companyId=${theme.id}` : ''
                                                      }`
                                                  }
                                                  state={{ menuData: menuData }}
                                                  className={hoverClass}
                                                >
                                                  {item.Title}
                                                </Link>
                                              )}
                                            </React.Fragment>
                                          ))}
                                        </div>
                                      </div>
                                    ) : (
                                      <Link
                                        key={menu.Id}
                                        to={
                                          menu.Link
                                            ? menu.Link
                                            : `/products?categoryId=${menu.Id}${theme.id > 0 ? `&companyId=${theme.id}` : ''}`
                                        }
                                        state={{ menuData: menuData }}
                                        className={hoverClass}
                                      >
                                        {menu.Title}
                                      </Link>
                                    )}
                                  </React.Fragment>
                                )
                              })}
                          </nav>
                        </div>
                      </div>
                    </div>
                  )}
                  {/*Filtreler Content */}
                  {activeTab === 'filtreler' && (
                    <CustomCard>
                      <IPAccordionCategory
                        name={t('pages.productList.category')}
                        labelName={'Alt Kategori'}
                      //isExpanded
                      >
                        <div className='line mt-0 mb-4'></div>
                        {(state.new === true || searchParams.get('searchKey') !== null) &&
                          state.MenuCategories.length > 0 &&
                          <div>
                            {renderedCategories}
                          </div>
                        }
                        {(state.new === false && searchParams.get('searchKey')) === null &&
                          state.categories.length > 0 &&
                          state.categories.map((category: ICategory) => {
                            return (
                              <div className='form-check ml-0 pl-0 m-b-15' key={category.Id}>
                                <input
                                  className='form-check-input flex-shrink-0'
                                  value={category.Id}
                                  id={category.Name}
                                  type='checkbox'
                                  checked={isCategorySelected(category.Id)}
                                  onChange={(e: any) => {
                                    onCategoryCheckboxClick(e.target.checked, category.Id)
                                    setState({ selectedProductsToCompare: [] })
                                  }}
                                />
                                <label className='form-check-label mt-2' htmlFor={category.Name}>
                                  {category.Name}
                                </label>
                              </div>
                            )
                          })}
                      </IPAccordionCategory>
                      {/* Şirket Filtresi */}
                      {
                        searchParams.get('companyId') === null &&
                        <IPAccordion
                          labelName={t('pages.productList.seller')}
                          name='seller'
                        // isExpanded
                        >
                          <div className='logogroup' style={{ marginLeft: '-10px' }}>
                            <div className='line mt-0 mb-4'></div>
                            {state.sellers &&
                              !searchParams.get('model') &&
                              state.sellers
                                .sort((a, b) => customOrder.indexOf(a.Id) - customOrder.indexOf(b.Id))
                                .map((seller: ICompany) => {
                                  return (
                                    <label
                                      htmlFor={`sellerCheck${seller.Id}`}
                                      className='form-check ml-0'
                                      key={seller.Id}
                                    >
                                      <input
                                        className='form-check-input'
                                        checked={isSellerSelected(seller.Id)}
                                        id={`sellerCheck${seller.Id}`}
                                        type='checkbox'
                                        onClick={(e: any) => {
                                          onSellerCheckboxClick(e.target.checked, seller.Id)
                                        }}
                                      />
                                      {seller.Id === 1 ? (
                                        <img
                                          src={seller.Image}
                                          alt=''
                                          className={css`
                                          position: relative;
                                          inset: unset;
                                          width: 70px;
                                          margin-left: 10px;
                                        `}
                                        />
                                      ) : seller.Id === 2 ? (
                                        <img
                                          src={seller.Image}
                                          alt=''
                                          className={css`
                                          position: relative;
                                          inset: unset;
                                          width: 80px;
                                          margin-left: 5px;
                                        `}
                                        />
                                      ) : seller.Id === 3 ? (
                                        <img
                                          src={seller.Image}
                                          alt=''
                                          className={css`
                                          position: relative;
                                          inset: unset;
                                          width: 80px;
                                          margin-left: 10px;
                                        `}
                                        />
                                      ) : seller.Id === 4 ? (
                                        <img
                                          src={seller.Image}
                                          alt=''
                                          className={css`
                                          position: relative;
                                          inset: unset;
                                          width: 90px;
                                          margin-top: 5px;
                                          margin-left: 10px;
                                        `}
                                        />
                                      ) : (
                                        ''
                                      )}
                                    </label>
                                  )
                                })}
                          </div>
                        </IPAccordion>
                      }
                      {/* Marka Filtresi */}
                      {(state.new === true || searchParams.get('searchKey') !== null) &&
                        state.features2.filter(a => searchParams.get('brand') ? a.Feature.FeatureName != "Marka" : true).map(feature => (
                          <IPAccordion
                            name={feature.Feature.FeatureName}
                            labelName={feature.Feature.FeatureName}
                            searchBox
                          // isExpanded
                          >
                            {/* <div className='line mt-0 mb-2'></div> */}
                            {feature.Attributes.map(attribute => (
                              <IPCheckbox
                                id={'attribute' + attribute.AttributeID}
                                label={attribute.AttributeValue}
                                onCheckedChange={checked =>
                                  handleFeatureSelect(feature.Feature.FeatureID, attribute.AttributeValue, checked)
                                }
                                checked={selectedFeatureList.some(a => a.FeatureId === attribute.FeatureID && a.FeatureValue === attribute.AttributeValue)}
                              />
                            ))}
                          </IPAccordion>
                        ))}
                      {(searchParams.get('searchKey') !== null || searchParams.get('brand') !== null) && (
                        <IPAccordion
                          labelName='Menü Filtreleme'
                          name='categoryFilter'
                        // isExpanded
                        >
                          <nav>
                            {state.SearchMenuCatAndCounts.sort((a, b) => (a.OrderNum > b.OrderNum ? 1 : -1)).map(x => (
                              <IPRadio
                                name='radio'
                                text={x.MenuTitle + ' (' + x.Total + ')'}
                                onChange={checked =>
                                  checked
                                    ? setState({ MenuTitle: state.MenuTitle === x.MenuTitle ? '' : x.MenuTitle })
                                    : ''
                                }
                                checked={x.MenuTitle == state.MenuTitle}
                              />
                              // <div
                              //   style={{
                              //     color: state.MenuTitle === x.MenuTitle ? '#ec4c4c' : '#484848',
                              //     cursor: 'pointer',
                              //   }}
                              //   onClick={() => setState({ MenuTitle: state.MenuTitle === x.MenuTitle ? '' : x.MenuTitle })}
                              // >
                              //   {x.MenuTitle} ({x.Total})
                              // </div>
                            ))}
                          </nav>
                        </IPAccordion>
                      )}
                      {state.new === false &&
                        searchParams.get('searchKey') === null &&
                        state.features.map((feature: ListBySelectedFeaturesResponse) => (
                          <IPAccordion
                            name={feature.Feature.FeatureName}
                            labelName={feature.Feature.FeatureName}
                          // isExpanded
                          >
                            {/* <div className='line mt-0 mb-2 w-90'></div> */}
                            {feature.Attributes.map(attribute => (
                              <IPCheckbox
                                id={'attribute' + attribute.AttributeID}
                                label={attribute.AttributeValue}
                                onCheckedChange={checked =>
                                  handleFilter(feature.Feature.FeatureID, attribute.AttributeValue, checked)
                                }
                              />
                            ))}
                          </IPAccordion>
                        ))}
                      <label className='p-switch p-switch-sm switch-color-secondary'>
                        <input
                          type='checkbox'
                          name='switch'
                          checked={state.isStockChecked}
                          onChange={() => {
                            if (state.isStockChecked)
                              setState({ isStockChecked: !state.isStockChecked })
                            else
                              setState({ isStockChecked: true })
                          }}
                        />
                        <span className='p-switch-style'></span>
                        <h5
                          id='title2'
                          style={{
                            fontSize: '12px',
                            marginLeft: '10px',
                            display: 'inline',
                          }}
                        >
                          {t('pages.productList.showOnlyAvailableInStock')}
                        </h5>
                      </label>
                      {(searchParams.get('searchKey') ||
                        searchParams.get('categoryId') ||
                        searchParams.get('brand') ||
                        searchParams.get('model')) && (
                          <label className='p-switch p-switch-sm switch-color-secondary'>
                            <input
                              type='checkbox'
                              name='switch'
                              checked={state.isCampaignChecked}
                              onChange={() => {
                                if (state.isCampaignChecked)
                                  setState({ isCampaignChecked: !state.isCampaignChecked })
                                else
                                  setState({ isCampaignChecked: true })
                              }}
                            />
                            <span className='p-switch-style'></span>
                            <h5
                              id='title2'
                              style={{
                                fontSize: '12px',
                                marginLeft: '10px',
                                display: 'inline',
                              }}
                            >
                              {'Kampanyalı Ürünler'}
                            </h5>
                          </label>
                        )}
                    </CustomCard>
                  )}

                  {(state.new === true || searchParams.get('searchKey') !== null) && activeTab === 'filtreler' && (
                    <button
                      ref={filterBtnRef}
                      type='button'
                      onClick={() => setState({ newFilter: !state.newFilter })}
                      className={`btn btn-sm border-0 text-capitalize d-flex justify-content-center align-items-center ${css`
                        font-size: 16px !important;
                        height: 40px !important;
                        line-height: 40px;
                        background-color: ${menuData && searchParams.get('companyId') && menuData.length > 0
                          ? theme.colors.backgroundColor
                          : '#007bff'} !important;
                        border-radius: 0 !important;
                        z-index: 1;
                        width: ${filterButtonWidth}px;
                        bottom: 1rem;
                        &:hover {
                          background-color: #2250fc !important;
                        }
                      `}`}
                      style={filterBtnStyles}
                    >
                      <span>Filtrele</span>
                    </button>
                  )}
                </div>
              </div>
              <div className='col-md-9 col-lg-10'>
                <div className='row'>
                  <div className='col-md-12'>
                    <div className='breadcrumb px-0' style={{ marginBottom: '5px', display: 'block' }}>
                      <nav aria-label='breadcrumb' className='d-flex justify-content-end flex-wrap gap-2'>
                        <nav className='displayTypes'>
                          <ul className='list list-legend d-flex align-items-center'>
                            <li className='grid_items'>
                              <a role={'button'} data-category='' onClick={() => setState({ selectedCardView: '1' })}>
                                <i className='icon-grid'> </i>
                              </a>
                            </li>
                            <li className='grid_items'>
                              <a role={'button'} data-category='.' onClick={() => setState({ selectedCardView: '2' })}>
                                <i className='icon-list'> </i>
                              </a>
                            </li>
                            <li className='grid_items'>
                              <a role={'button'} data-category='' onClick={() => setState({ selectedCardView: '3' })}>
                                <i className='fa fa-list-alt'></i>
                              </a>
                            </li>
                          </ul>
                        </nav>
                        <ul className='d-flex align-items-center flex-wrap gap-1'>
                          {(searchParams.get('searchKey') ||
                            searchParams.get('categoryId') ||
                            searchParams.get('brand') ||
                            searchParams.get('model')) && (
                              <li className='mr-2'>
                                <label className='p-checkbox checkbox-color-danger mb-0 align-items-center d-flex flex-row-reverse gap-1'>
                                  <span>{'Kampanyalı Ürünler'}</span>
                                  <input
                                    type='checkbox'
                                    name='checkbox'
                                    checked={state.isCampaignChecked}
                                    onChange={() => {
                                      if (state.isCampaignChecked)
                                        setState({ isCampaignChecked: !state.isCampaignChecked })
                                      else
                                        setState({ isCampaignChecked: true })
                                    }}
                                  />
                                  <span className='p-checkbox-style'></span>
                                </label>
                              </li>
                            )}
                          <li className='mr-2'>
                            <label className='p-checkbox checkbox-color-danger mb-0 align-items-center d-flex flex-row-reverse gap-1'>
                              <span>{t('pages.productList.availableInStock')}</span>
                              <input
                                type='checkbox'
                                name='checkbox'
                                checked={state.isStockChecked}
                                onChange={() => {
                                  if (state.isStockChecked)
                                    setState({ isStockChecked: !state.isStockChecked })
                                  else
                                    setState({ isStockChecked: true })
                                }}
                              />
                              <span className='p-checkbox-style'></span>
                            </label>
                          </li>
                          {/* <li className="mr-2">
                          <button
                            type="button"
                            style={{
                              border: "1px solid #EC4C4C",
                              borderRadius: 0,
                              width: "29px",
                              height: "29px",
                            }}
                            className="btn text-capitalize background-white-important d-flex align-items-center"
                          >
                            <i
                              className="fa fa-file-excel"
                              style={{
                                marginLeft: "-6px",
                                fontSize: "18px",
                                color: "#EC4C4C",
                              }}
                            ></i>
                          </button>
                        </li>
                        <li className="mr-2">
                          <button
                            type="button"
                            style={{
                              border: "1px solid #EC4C4C",
                              borderRadius: 0,
                              width: "29px",
                              height: "29px",
                            }}
                            className="btn text-capitalize background-white-important d-flex align-items-center"
                          >
                            <i
                              className="fa fa-columns"
                              style={{
                                marginLeft: "-8px",
                                fontSize: "18px",
                                color: "#EC4C4C",
                              }}
                            ></i>
                          </button>
                        </li> */}
                          <li className='mr-2'>
                            <button
                              type='button'
                              onClick={addToCartMultiple}
                              className='btn btn-sm-addCart border-0 text-capitalize d-flex align-items-center'
                            >
                              <i className='icon-shopping-cart fa-fw' style={{ fontSize: '17px' }}></i> &nbsp;
                              <span style={{ marginTop: '3px', whiteSpace: 'nowrap' }}>
                                {' '}
                                {t('pages.productList.addToCart')}
                              </span>
                            </button>
                          </li>
                          <li className='mr-2'>
                            <button
                              type='button'
                              onClick={handleCompare}
                              className='btn btn-sm-compare border-0 text-capitalize d-flex align-items-center'
                            >
                              <i className='icon-repeat' style={{ fontSize: '17px' }}></i> &nbsp;
                              <span style={{ marginTop: '3px' }}> {t('pages.productList.compare')}</span>
                            </button>
                          </li>
                          <li>
                            <Suspense fallback={null}>
                              <IPModal
                                width={1400}
                                show={modalIsOpen}
                                closeOnEscapeKey={true}
                                onClose={handleCompareCloseButton}
                                onConfirm={() => printElement(document.getElementById('compareToPrint'))}
                                confirmText={'Yazdır'}
                              >
                                <Suspense fallback={null}>
                                  <CompareProduct
                                    products={state.selectedProductsToCompare}
                                    onClose={handleCompareCloseButton}
                                    removeItemFromCompareList={removeItemFromCompareList}
                                    hideLoading={props.hideLoading}
                                  />
                                </Suspense>
                              </IPModal>
                            </Suspense>

                            <div style={{ width: '150px' }}>
                              <IPSelectBox
                                id='sorting'
                                name='sorting'
                                placeholder='Sırala'
                                options={sıralaOptions}
                                value={sortValue}
                                onChangeSingle={option => {
                                  if (option) {
                                    setState({ priceOrderType: option.value as 'asc' | 'desc' })
                                    setSortValue(option)
                                  } else {
                                    setState({ priceOrderType: null })
                                  }
                                }}
                              />
                            </div>

                            {/* <div>
                            <select
                              className='form-control arrow-select'
                              style={{ width: '220px' }}
                              onChange={e => {
                                setState({ priceOrderType: e.target.value as any })
                                state.priceOrderType = e.target.value as any
                              }}
                            >
                              <option disabled selected>
                                Sırala
                              </option>
                              <option selected={state.priceOrderType === 'asc'} value={'asc'}>
                                {t('pages.productList.orderByPriceLowToHigh')}
                              </option>
                              <option selected={state.priceOrderType === 'desc'} value={'desc'}>
                                {t('pages.productList.orderByPriceHighToLow')}
                              </option>
                            </select>
                          </div> */}
                          </li>
                          <li className='mr-2'>
                            <button
                              title="Ürünleri Excel'e Aktar"
                              type='button'
                              style={{
                                border: '1px solid transparent',
                                borderRadius: 0,
                                width: '29px',
                                height: '29px',
                              }}
                              onClick={handleExcel}
                              className='btn btn-sm-excel text-capitalize d-flex align-items-center'
                            >
                              <i
                                className='fas fa-file-excel'
                                style={{
                                  marginLeft: '-6px',
                                  fontSize: '18px',
                                  color: '#2e7d32',
                                }}
                              ></i>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                  <div className='col-md-12'>
                    {(!state.filteredProductList ||
                      (state.filteredProductList && state.filteredProductList.length === 0) ||
                      !state.productGroups ||
                      (state.productGroups.products && state.productGroups.products.length === 0)) &&
                      state.searchComplete && (
                        <div style={{ textAlign: 'center', padding: 10 }}>
                          {t('pages.productList.noProductsFoundBySearchCriteria')}
                        </div>
                      )}
                    {products()}
                    <br></br>
                    {state.searchComplete && state.totalProduct && state.totalProduct > 0
                      ? `Toplam ${state.totalProduct} adet ürün bulundu.`
                      : null}
                  </div>
                  {pagination()}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='floating_btn'>
          <a href='#' onClick={handleCompare}>
            <div className='compare_icon'>
              <i className='icon-repeat'> </i>
            </div>
          </a>
        </div>
      </section>

      <Suspense fallback={null}>
        <ProductIQuotaForm ref={iQuotaFormRef} />
      </Suspense>

      <Tooltip show={tooltip.show} coords={tooltip.coords}>
        {tooltip.content}
      </Tooltip>
    </React.Fragment>
  )
}

export default componentWithContext(ProductList)
