import { css } from '@emotion/css'

interface Props {
  show: boolean
  coords: {
    top: number
    left: number
  }
  children: JSX.Element
  css?: boolean
}

export default function Tooltip(props: Props) {
  const style = css`
    position: fixed;
    width: max-content;
    height: max-content;
    transition: opacity 0.4s;
    pointer-events: none;
    z-index: 50;
    opacity: ${props.show ? '1' : '0'};
    top: ${props.coords.top + 30}px;
    left: ${props.coords.left}px;

    ${props.css &&
    `
      padding: 0.5rem;
      border-radius: 0.5rem; 
      background-color: rgba(0,0,0,0.8); 
      color: #fff;
    `}

    @media (max-width: 920px) {
      left: unset;
      right: 3rem;
    }
  `
  return <div className={style}>{props.children}</div>
}

export interface TooltipState {
  show: boolean
  coords: {
    top: number
    left: number
  }
  content: any
}

export function getPosition(e) {
  const pos = e.target.getBoundingClientRect()
  return {
    top: pos.top,
    left: pos.left,
  }
}
