import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'

export class DeleteMostSelledExceptionProductApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/deleteMostSelledExceptionProduct',
      requesterInfo,
    })
  }

  public async deleteMostSelledExceptionProduct(Id: number): Promise<any> {
    this.props.url = `${this.props.url}?Id=${Id}`
    return await this.getAsync<any>()
  }
}
