import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IOfferSendMail } from '../models/responseModel'

export class OfferSendMailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'offer/send-mail-special',
      requesterInfo,
    })
  }

  public async offerSendMail(request: IOfferSendMail): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
