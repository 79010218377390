import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IDealer } from '../models/models'

export class GetDealerApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealer/ListAll',
      requesterInfo,
    })
  }

  public async getDealer(): Promise<IDealer[]> {
    return await this.getAsync<IDealer[]>()
  }
}
