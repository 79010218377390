import { Resource } from 'i18next'

const resources: Resource = {
  tr: {
    translation: {
      components: {
        AddToBasket: {
          addToBasket: 'Sepete Ekle',
          createDemand: 'Talep Oluştur',
          productWasAddedToBasket: 'Ürün Sepete Eklendi',
          goToBasket: 'Sepete Git',
          continueToShopping: 'Alışverişe Devam Et',
          productDemandWasCreated: 'Ürün Talebi Oluşturuldu',
        },
        selectBox: {
          pleaseSelect: 'Seçiniz...',
        },
        button: {
          defaultButtonLabel: 'Tıkla',
        },
      },
      pages: {
        homePage: {
          welcomeUserText: 'Hoş geldin, ${0} ${1}',
          // specialForToday: 'Bugüne Özel',
          specialForToday: 'Duyurular',
          campaigns: 'Kampanyalar',
          newYear: 'Yeni Yıl',
          privileges: 'Ayrıcalıklar',
          soldSoFar: 'Şimdiye kadar satılan',
          deal: 'Fırsat',
          market: 'Pazarı',
          forMore: 'Daha Fazlası İçin',
          mostPopularProducts: 'En Favoriler',
          outOfStock: 'Stokta Yok',
          topBrand: 'Markalar',
          allBrand: 'Tümünü Göster',
          yourRecentVisits: 'Son İncelediğiniz Ürünler',
          similarProducts: 'Benzer Ürünler',
          productsSimilarToYourSearch: 'Benzer Ürünler',
          latestProducts: 'En Yeniler',
          campaignProducts: 'Kampanyalı Ürünler',
        },
        register: {
          welcomeText: 'Hoş geldiniz',
          bayiForms: {
            turkish: 'TÜRKİYE',
            cyprus: 'KIBRIS',
            eArchive: 'E-Arşiv',
            eInvoice: 'E-Fatura',
            doesNotUse: 'Kullanmaz',
            basic: 'Temel',
            commercial: 'Ticari',
            person: 'Şahış',
            header: 'Yeni Bayi Başvuru Süreci',
            subHeader:
              'Index Grup şirketleri için bayilik başvurunuzu aşağıdaki adımları takip ederek, online olarak yapabilirsiniz.',
            description1:
              "Bayilik başvuru sürecinin başlaması için 'Online Başvuru Formu'nu eksiksiz doldurup, talep edilen belgelerin birer okunaklı taranmış suretini de eklemeniz gerekmektedir. (Talep edilen belgeler eksik yüklendiği takdirde başvurunuz değerlendirmeye alınmayacaktır.)",
            description2:
              'Index Grup şirketleri bayiliği ve e-bayiliği için firma evraklarınızda bilgisayar alım satımı yapıldığına dair ibare bulunmalıdır. Geçerli olan faaliyet alanları için',
            click: 'tıklayınız...',
            description3:
              "Online başvurunuz sonrasında, sizin için oluşturulacak 'Bayi Takip Numarası' ile süreci sistem üzerinden takip edebilirsiniz.",
            description4:
              "Online başvurunuzun onaylanmasının ardından, 'Online Başvuru Formu'nda belirttiğiniz e-posta adresinize 'İndeks Grup E-Pazar Platformu E-Bayi Üyelik Sözleşmesi' gönderilecektir. Sözleşmeyi açıp okumanızın ardından online şekilde onaylayabilmeniz için yine 'Online Başvuru Formu'nda belirttiğiniz cep telefonu numarasına SMS gönderilecek olup, gelen kodu ilgili kutucuğa yazıp göndermeniz halinde 'İndeks Grup E-Pazar Platformu E-Bayi Üyelik Sözleşmesi'ni onaylamış olacaksınız.",
            description5:
              "'İndeks Grup E-Pazar Platformu E-Bayi Üyelik Sözleşmesi'ni onaylamanızın ardından e-posta adresinize sözleşmenin bir örneği gönderilecektir. 'Kullanıcı adı' ve 'şifre'niz de belirttiğiniz aynı e-posta adresinize iletilecektir.",
            requestedDocuments: 'İstenilen Belgeler',
            soleProprietorships: 'Şahıs Firmaları',
            dealerAgreement: 'Bayi Sözleşmesi',
            signatureCircular: 'İmza Sirküsü',
            taxBoard: 'Vergi Levhası',
            identityCard: 'Nüfus Cüzdan Fotokopisi',
            attendanceReceipt: 'Faaliyet Belgesi ya da Yoklama Fişi',
            companyName: 'Şirket Adı',
            address: 'Adres',
            postCode: 'Posta Kodu',
            country: 'Ülke',
            phone: 'Telefon',
            taxpayerType: 'Fatura Mükellef Tipi',
            type: 'Firma Tipi',
            taxNumber: 'Vergi Numarası/TC. Kimlik No',
            iban: 'IBAN Numarası',
            electronicMessage: 'Ticari Elektronik İletilerin tarafıma gönderilmesini kabul ediyorum.',
            yes: 'Evet',
            no: 'Hayır',
            kvk: 'KVKK aydınlatma metnini okuyup, onaylamak için',
            uploadDocument: 'Yüklenecek Evraklar',
            save: 'Kaydet',
            companies: 'Şirketler',
            registryGazette: 'Ticari Sicil Gazetesi (Ortaklık ve İş Alanını Gösteren)',
            nameSurname: 'Yetkili Adı Soyadı',
            tcNo: ' Yetkili T.C. No.',
            city: 'İl',
            town: 'İlçe',
            email: 'E-posta',
            usageType: 'Fatura Kullanım Tipi',
            taxAdministration: 'Vergi Dairesi',
          },
        },
        loginPage: {
          form: {
            indexGroup: "Index Grup Pazar'a",
            welcome: 'Hoş Geldiniz!',
            accountCodeTaxNumber: 'Hesap Kodu / Vergi No',
            userName: 'Kullanıcı Adı / Email',
            password: 'Şifre',
            rememberMe: 'Beni Hatırla',
            forgotMyPassword: 'Şifremi Unuttum',
            login: 'Giriş',
            howDoiBecomeaMember: 'NASIL ÜYE OLURUM?',
            dealershipApplicationProcess: 'Index Grup şirketleri bayilik başvurusu için lütfen ',
            onlineApplicationForm: 'Online Başvuru Formu',
            fillIn: 'nu doldurunuz.',
            onlineApplicationTracking: 'Online Başvuru Takibi',
            send: 'Gönder',
            accountCode: 'Hesap Kodu',
            email: 'Admin Yetkisindeki Kullanıcının E-Posta Adresi:',
            applicationStatus: 'Online Bayi Başvuru Durumunuz',
            trackingNumber: 'Takip No',
            inquire: 'Sorgula',
          },
          slider: {
            legalNoticeInformationText: 'Yasal Uyarı Bilgilendirme Metni',
            samplePartnershipAgreement: 'Örnek İş Ortaklığı Sözleşmesi',
          },
          validation: {
            characterMustBe: '${0} en az ${1} karakter olmalıdır.',
            requiredfield: 'Zorunlu Alan.',
          },
        },
        resetPassword: {
          index: {
            password: 'Yeni Şifre',
            passwordRepeat: 'Yeni Şifre Tekrar',
            reset: 'Güncelle',
          },
          validation: {
            characterMustBe: '${0} en az ${1} karakter olmalıdır.',
            requiredfield: 'Zorunlu Alan.',
          },
        },
        admin: {
          userOperation: {
            userCrud: {
              addUser: {
                userUpdate: 'Güncelle',
                userAdd: 'Ekle',
                dealerCustomer: 'Bayi/müşteri',
                callCenter: 'Çağrı Merkezi',
                companyManagementReports: 'Şirket Yönetim (Raporlar)',
                productManagementPersonnel: 'Ürün Yönetim Personeli',
                channelStaff: 'Kanal Personeli',
                freePos: 'Bedava Pos',
                addingNews: 'Haber Ekleme',
                sshAdministration: 'SSH Yönetim',
                siteManagement: 'Site Yönetim',
                isTheMostAuthoritativeAdministrator: 'En Yetkili/Yöneticidir',
                canMonitorSalesAndOrderMovements: 'Satış ve Sipariş Hareketleri İzleyebilir',
                canOrder: 'Sipariş Verebilir',
                blocked: 'Bloke (${0})',
                passive: 'Pasif',
                deleted: 'Silindi',
                accountCode: 'Hesap Kodu',
                canMakeFinancialTransactions: 'Finansal İşlem Yapabilir',
                canMonitorFinancialAndCurrentMovements: 'Finansal ve Cari Hareketleri İzleyebilir',
                dontSeeSpecialPriceSpecialPrice: 'Özel Fiyat Görmesin; Özel Fiyat + %',
                active: 'Aktif',
                waitingForApproval: 'Onay Bekliyor',

                userUpdateResultMessage: 'Kullanıcı güncellendi!',
                userUpdateErrorResultMessage: 'Kullanıcı güncelleme işleminde hata oldu.',
                userAddResultMessage: 'Kullanıcı eklendi!',
                user: 'Kullanıcı',
                name: 'Ad',
                surname: 'Soyad',
                userName: 'Kullanıcı Adı',
                userCode: 'Kullanıcı Kodu',
                password: 'Şifre',
                passwordRepeat: 'Şifre Tekrar',
                type: 'Tip',
                authorityLevel: 'Yetki Seviyesi',
                email: 'E-Posta',
                phone: 'Telefon',
                phoneNumber: 'Telefon Numarası',
                authorities: 'Yetkiler',
                status: 'Durum',
                note: 'Not',
                companies: 'Şifre Tanımlanacak Şirketler',
                emailSend: 'E-posta Gönder',
                userAddErrorResultMessage: 'Kullanıcı ekleme işleminde hata oldu.',
              },
              listUser: {
                userDeleteMessage: 'Kullanıcı zaten silinmiş!',
                userDeleteConfirmMessage: 'Kullanıcıyı silmek istediğinize emin misiniz?',
                userDeleteResultMessage: 'Silme İşlemi Başarılı!',
                header: 'Kullanıcı Ekleme / Güncelleme',
                field: 'Saha',
                choose: 'Seçiniz',
                accountCode: 'Hesap Kodu',
                accountNameTitle: 'Hesap Adı/Ünvan',
                userCode: 'Kullanıcı Kodu',
                email: 'E-Posta',
                fieldValueCondition: 'Saha Değer / Koşul',
                status: 'Durum',
                active: 'Aktif',
                other: 'Diğer',
                strain: 'Süz',
                addUser: 'Kullanıcı Ekle',
                name: 'Ad',
                surname: 'Soyad',
                userName: 'Kullanıcı Adı',
                creationDate: 'Oluşturulma Tarihi',
                deleted: 'Silindi',
                edit: 'Düzenle',
                delete: 'Sil',
                settings: 'Ayarlar',
              },
              validation: {
                requiredField: 'Zorunlu alan.',
                message: 'En az ${0} karakter olmalıdır.',
                userCode: 'Hesap kodu',
                name: 'İsim',
                surname: 'Soy isim',
                email: 'Geçersiz mail adresi',
                gsm: 'GSM doğru formatta girilmedi',
                userName: 'Kullanıcı adı',
                password: 'Şifreniz en az 6 karakter olmalıdır.',
                passwordConfirm: 'Girilen şifreler aynı olmalıdır.',
                file: 'Bir dosya gerekli',
              },
            },
            sessionClosed: {
              header: 'Açık Oturum Sonlandırma Süzme Koşulları',
              field: 'Saha',
              choose: 'Seçiniz',
              accountCode: 'Hesap Kodu',
              fieldValueCondition: 'Saha Değer / Koşul',
              strain: 'Süz',
              accountNameTitle: 'Hesap Adı/Ünvan',
              userCode: 'Kullanıcı Kodu',
              ipAddress: 'IP Adres',
              visitorID: 'Ziyaretçi ID',
              start: 'Başlangıç',
              lastAccess: 'Son Erişim',
              operation: 'İşlem',
            },
            currentCardInquiry: {
              header: 'Cari Kart (Hesap Kodu) Fihristi Sorgulama/Güncelleme',
              field: 'Saha',
              choose: 'Seçiniz',
              accountCode: 'Hesap Kodu',
              accountNameTitle: 'Hesap Adı/Ünvan',
              fieldValueCondition: 'Saha Değer / Koşul',
              strain: 'Süz',
              cantSeeSpecialPrice: 'Özel Fiyat Göremez',
              groupAgreementAvailable: 'Grup Sözleşmesi Var',
              cannotEnterAuction: 'Açık Eksiltmeye Giremez',
              effective: 'Etkin',
              operation: 'İşlem',
              total: 'Toplam',
              recordListed: 'kayıt listelendi.',
            },
            definedCompany: {
              header: 'Cari Kart (Hesap Kodu) Tanımlı Şirket ve Kullanıcı Sorgulama',
              accountCodeOrTaxNumber: 'Hesap Kodu veya Vergi No',
              strain: 'Süz',
              sapCompanyCode: 'SAP Şirket Kodu',
            },
            prepaidXML: {
              header: 'Son 60 Gündür Faturasız XML Carileri Listesi (RSG, AYS ve KSK hariç)',
              field: 'Saha',
              currentLogo: 'Cari Logo',
              current: 'Cari',
              seeOriginal: 'Orjinalini Gör',
              xmlDefinitionCompany: 'XML Tanım Şirketi',
              channelTem: 'Kanal Tem.',
              operation: 'İşlem',
              accountCode: 'Hesap Kodu',
              accountNameTitle: 'Hesap Adı/Ünvan',
              type: 'Tip',
              email: 'E-posta',
              creationDate: 'Oluşturulma Tarihi',
            },
            xmlUpdate: {
              header: 'Cari Kart (Hesap Kodu) XML Sorgulama/Güncelleme',
              filteringConditions: 'Süzme Koşulları',
              field: 'Saha',
              choose: 'Seçiniz',
              accountCode: 'Hesap Kodu',
              accountNameTitle: 'Hesap Adı/Ünvan',
              allList: 'Tüm Liste',
              companyCode: 'Şirket Kodu',
              fieldValueCondition: 'Saha Değer / Koşul',
              activityStatus: 'Aktiflik Durumu',
              xmlReceiveActive: 'XML Alım Aktif',
              xmlIngestionPassive: 'XML Alım Pasif',
              currentAssets: 'Cari Aktif',
              currentLiability: 'Cari Pasif',
              xmlRecordingActive: 'XML Kayıt Aktif',
              xmlRecordDisabled: 'XML Kayıt Pasif',
              strain: 'Süz',
              warning: 'Uyarı',
              operation: 'İşlem',
              type: 'Tip',
              email: 'E-posta',
              company: 'Şirket',
              taxNumber: 'Vergi No',
              staticIP: 'Statik IP',
              xmlReceiveStatusActive: 'XML Alım Durumu Aktif',
              xmlRecordStatusActive: 'XML Kayıt Durumu Aktif',
              creationTime: 'Oluşturulma Zamanı',
              creative: 'Oluşturan',
              updateTime: 'Güncelleme Zamanı',
              updatedBy: 'Güncelleyen',
              note: 'Not',
            },
            currentLogo: {
              header: 'Cari Logo',
              current: 'Cari',
              logo: 'Logo',
              seeOriginal: 'Orjinalini Gör',
              operation: 'İşlem',
              total: 'Toplam',
              recordListed: 'kayıt listelendi.',
            },
          },
          categoryOperation: {
            categoryCrud: {
              addCategory: {
                header: 'Kategori Ekleme',
                addToMenu: "Menü'ye Ekle",
                company: 'Şirket Adı',
                order: 'Sırası',
                name: 'Kategori Uzun Adı',
                shortName: 'Kategori Kısa Adı',
                add: 'Ekle',
              },
              listCategory: {
                header: 'Kategori Güncelleme/Silme Süzme Koşulları',
                add: 'Kategori Ekle',
                company: 'Şirket',
                name: 'Uzun Adı',
                shortName: 'Kısa Adı',
                edit: 'Düzenle',
                delete: 'Sil',
                settings: 'Ayarlar',
                order: 'Sırası',
              },
              validation: {
                requiredField: 'Zorunlu alan',
                name: 'Kategori ${0} ismi minumum ${1} karakter olmalı.',
              },
            },
            menuCrud: {
              addMenu: {
                header: 'Menü Ekleme',
                addToMenu: "Menü'ye Ekle",
                company: 'Şirket',
                order: 'Sırası',
                name: 'Kategori Uzun Adı',
                shortName: 'Kategori Kısa Adı',
                add: 'Ekle',
              },
              listMenu: {
                header: 'Menü Ekleme',
                addToMenu: "Menü'ye Ekle",
                company: 'Şirket',
                order: 'Sırası',
                name: 'Kategori Uzun Adı',
                shortName: 'Kategori Kısa Adı',
                add: 'Ekle',
                listHeader: 'Menü Güncelleme/Silme Süzme Koşulları',
                subHeader: 'Koşulları girin',
                filtre: 'Süz',
                homeMenu: 'Ana Sayfa Menu Listesi',
              },
            },
          },
          homeAndSiteOperation: {
            bannersCrud: {
              addBanner: {
                header: 'Banner Öğesi Ekleme',
                locationPixel: '${0} (Genişlik: ${1} x Yükseklik: ${2} pixel) ',
                category: 'Kategori',
                // specialForToday: 'Bugüne Özel',
                specialForToday: 'Duyurular',
                compaigns: 'Kampanyalar',
                newYear: 'Yeni Yıl',
                privileges: 'Ayrıcalıklar',
                getSlider: 'Slider',
                beAtTheBottom: 'Alt Alta',
                publish: 'Yayınla',
                bannerName: 'Banner Adı',
                bannerDescription: 'Banner Açıklama',
                placeOfBanner: 'Bannerın Yeri',
                company: 'Şirket',
                counter: 'Sayaç',
                order: 'Sıra Numarası',
                link: 'Resim Link',
                startDate: 'Yayın Başlangıç Tarihi',
                endDate: 'Yayın Bitiş Tarihi',
                typeCode: 'Cari Tip Kodu',
                subtypeCode: 'Cari Alt Tip Kodu',
                addImage: 'Banner Görsel Ekle',
                add: 'Ekle',
              },
              listBanner: {
                header: 'Banner Güncelleme/Silme Süzme Koşulları',
                field: 'Saha',
                choose: 'Seçiniz',
                onTheAir: 'Yayında',
                inTheArchive: 'Arşivde',
                fieldValueCondition: 'Saha Değer / Koşul',
                strain: 'Süz',
                bannerAdd: 'Banner Ekle',
                location: 'Konum',
                active: 'Aktif',
                startAndEndate: 'Başlangıç Tarihi-Bitiş Tarihi',
                title: 'Başlık',
                description: 'Açıklama',
                operation: 'İşlemler',
                edit: 'Düzenle',
                delete: 'Sil',
                settings: 'Ayarlar',
                company: 'Şirket',
                category: 'Kategori',
                typeCode: 'Cari Tip Kodu',
                subtypeCode: 'Cari Alt Tip Kodu',
                bannerDeleted: 'Banner Silindi',
                homeTopLeft: 'Anasayfa Sol Üst',
                homeBottomLeft: 'Anasayfa Sol Alt',
                homepageRight: 'Anasayfa Sağ',
                counterHomeTop: 'Sayaç Anasayfa Üst',
                counterHomeBottom: 'Sayaç Anasayfa Alt ${0}',
                homepageSpecialForMe: 'Anasayfa Bana Özel',
                // specialForToday: 'Bugüne Özel',
                specialForToday: 'Duyurular',
                campaigns: 'Kampanyalar',
                newYear: 'Yeni Yıl',
                privileges: 'Ayrıcalıklar',
              },
              validation: {
                requiredField: 'Zorunlu alan.',
                serialNumber: 'Lütffen Sıra numarası giriniz',
                characterMustBe: '${0} en az ${1} karakter olmalıdır.',
                aFileIsRequired: 'Bir dosya gerekli',
              },
            },
            sap: {
              sapList: {
                header: 'SAP Güncelleme/Senkronizasyon İşlemleri',
                subHeader: 'Çalıştırılacak İşlem (Güncellenecek Veri)',
                currency: 'Döviz Kurları',
                category: 'Kategori ve Grup Tanımları',
                productModel: 'Ürün Marka Model Tanımları',
                productCards: 'Ürün Kartları / Fiyatları (SAP Ürün Kodu)',
                wordSearch: 'Kelime Arama Güncellenmez',
                productPrice: 'Ürün Kartları / Fiyatları (Tüm Ürünler)',
                productQuota: 'Ürün Kartları / Tanımları (Kota Kodu)',
                currentCard: 'Cari Kartlar (SAP Hesap Kodu)',
                workInAll: 'Bütün Şirketlerde Çalıştır!',
                productPricesQuota: 'Ürün Fiyatları (Kota Kodu)',
                productProperty: 'Ürün Özellikleri(Kota Kodu)',
                productOrWord: 'Ürün Kartları / Kelime Arama (Ürün Kodu)',
                linkedProduct: 'Bağlantılı / İlintili Ürün Tanımları',
                menuTree: 'Menü Ağacı',
                run: 'Çalıştır',
              },
            },
            AdsAndCampaingCrud: {
              AdsAndCampaingCrud: {
                header: 'Reklam/Kampanya Güncelleme | Silme',
                filterCondition: 'Süzme Koşulları',
                add: 'Ekle',
                startAndEndDate: 'Başlangıç Tarihi-Bitiş Tarihi',
                field: 'Saha',
                fieldValue: 'Saha Değer Koşul',
                filter: 'Süz',
                order: 'Sıra',
                topic: 'Konu',
                imgName: "Görsel'in Adı",
                imgLink: "Görsel'in Linki",
                venue: 'Gösterim Yeri',
                broadcastStart: 'Yayın Başlangıç',
                broadcastEnd: 'Yayın Bitiş',
                effective: 'Etkin',
                process: 'İşlem',
                listAll: 'Toplam ${0} kayıt listelendi.',
                update: 'Güncelle',
                del: 'Sil',
              },
              AdsAndCampaingAdd: {
                header: 'Reklam/Kampanya Öğesi Ekleme',
                active: 'Etkin',
                topic: 'Konu',
                adsCampImgNam: 'Reklam/Kampanya Görsel Adı',
                adsCampImgLink: 'Reklam/Kampanya Görsel Adı',
                venue: 'Gösterim Yeri',
                currCode: 'Cari Kodu',
                currGroup: 'Cari Grup Kodu',
                currSub: 'Cari Alt Kodu',
                startDate: 'Yayın Başlangıç Tarihi (SS:DD)',
                endDate: 'Yayın Bitiş Tarihi (SS:DD)',
                currType: 'Cari Tip Kodu',
                currSubType: 'Cari Alt Tip Kodu',
                add: 'Ekle',
              },
            },
            AdsAndCampingReset: {
              AdsAndCampaingReset: {
                header: 'Reklam/Kampanya Güncelleme | Silme (Resetleme)',
                filterCond: 'Süzme Koşulları',
                add: 'Ekle',
                startDate: 'Tarih Başlangıç',
                endDate: 'Tarih Bitiş',
                field: 'Saha',
                state: 'Durum',
                filter: 'Süz',
                order: 'Sıra',
                topic: 'Konu',
                imgName: "Görsel'in Adı",
                imgLink: "Görsel'in Linki",
                venue: 'Gösterim Yeri',
                broadcastStart: 'Yayın Başlangıç',
                broadcastEnd: 'Yayın Bitiş',
                effective: 'Etkin',
                process: 'İşlem',
              },
            },
            AnnouncementList: {
              AnnouncementList: {
                header: 'Duyuru Ekleme',
                date: 'Yayın Başlangıç / Bitiş Tarihi',
                topic: 'Konu :',
                titleText: 'Başlık Yazısı',
                dealerList: 'Bayi Listesi',
              },
            },
            BulkAdding: {
              BulkAdding: {
                header: 'Toplu Güncel Ürün Ekleme',
                condition: 'Koşulları girin',
                productList: 'Ürün Listesi',
                add: 'Ekle',
                accountCode: 'Hesap Kodu',
                accountName: 'Hesap Adı',
                orderTrack: 'Sipariş Tipi',
                payType: 'Ödeme Tipi',
                sap: 'SAP Sipariş No',
                campaign: 'Kampanya Kodu',
                orderNow: 'Sipariş Anı',
              },
            },
            campaignQuery: {
              campaignQuery: {
                header: 'Kampanya Sorgulama',
                subHeader: 'Koşulları girin',
                dealerCode: 'Bayi Kodu',
                productCode: 'Ürün Kodu',
                inquire: 'Sorgula',
                start: 'Yayın Başlangıç',
                end: 'Yayın Bitiş',
                status: 'Durumu',
                type: 'Cari Tip Kodu',
                subType: 'Cari Alt Tip Kodu',
                description: 'Açıklama',
                active: 'Etkin',
                operation: 'İşlem',
                total: 'Toplam ${0} kayıt listelendi.',
              },
            },
            currentProductCrud: {
              addProduct: {
                header: 'Güncel Ürün Ekleme',
                active: 'Etkin',
                stockCode: 'Ürün Stok Kodu',
                oldPrice: 'Eski Fiyatı',
                name: 'Ürün Adı',
                description: 'Açıklama',
                start: 'Yayın Başlangıç Tarihi',
                end: 'Yayın Bitiş Tarihi',
                type: 'Cari Tip Kodu',
                subType: 'Cari Alt Tip Kodu',
                add: 'Ekle',
              },
              currentProductCrud: {
                header: 'Güncel Ürün Ekleme / Güncelleme',
                subHeader: 'Süzme Koşulları',
                add: 'Ekle',
                startDate: 'Tarih Başlangıç',
                endDate: 'Tarih Bitiş',
                field: 'Saha',
                fieldValueCondition: 'Saha Değer Koşul',
                filtre: 'Süz',
                productCode: 'Ürün Kodu',
                broadcastStart: 'Yayın Başlangıç',
                broadcastEnd: 'Yayın Bitiş',
                status: 'Durumu',
                typeCode: 'Cari Tip Kodu',
                subTypeCode: 'Cari Alt Tip Kodu',
                description: 'Açıklama',
                active: 'Etkin',
                operation: 'İşlem',
                total: 'Toplam ${0} kayıt listelendi.',
              },
            },
            specifyingCategory: {
              specifyingCategory: {
                header: 'Kategori Ağacı Görünüm Sırası Belirleme',
                subHeader: 'Koşulları girin',
                list: 'Ürün Listesi',
                above: 'Yukarı',
                down: 'Aşağı',
                add: 'Ekle',
                code: 'Hesap Kodu',
                name: 'Hesap Adı',
                orderType: 'Sipariş Tipi',
                paymentType: 'Ödeme Tipi',
                sap: 'SAP Sipariş No',
                campaignCode: 'Kampanya Kodu',
                orderMoment: 'Sipariş Anı',
              },
            },
            representativesHover: {
              header: 'Temsilciler Hover',
              name: 'Ad',
              surname: 'Soyad',
              email: 'E-Posta',
              phone: 'Telefon',
              market: 'Pazar',
            },
            uploadPDF: {
              header: 'Login PDF Yükleme',
              title: 'Başlık',
              partnershipAgreement: 'İş Ortaklığı Sözleşmesi',
              legalNoticeInformation: 'Yasal Uyarı Bilgilendirme',
              uploadFile: 'Dosya Yükle',
              save: 'Kaydet',
              file: 'Dosya',
              transactions: 'İşlemler',
              edit: 'Düzenle',
              delete: 'Sil',
            },
          },
          siteReports: {
            analytics: {
              header: 'Site Operation Analytics',
              enterTheConditions: 'Koşulları Girin',
              startEndDate: 'Başlangıç-Bitiş Tarihi',
            },
            mailSend: {
              header: 'Site Yönetim Uyarı E-posta Gönderimleri Raporu',
              processDateRange: 'İşlem Tarih Aralığı',
              transferType: 'Gönderim Şekli',
              processType: 'İşlem Tipi',
              showButton: 'Göster',
            },
            productClickExcel: {
              header: 'Ürün Tıklama İstatistikleri(Excel)',
              exportToExcelButton: 'Excel’e Aktar',
            },
            productClick: {
              header: 'Ürün Tıklama İstatistikleri',
              brandCode: 'Marka Kodu',
              quotaGroup: 'Kota Grubu',
              quota: 'Kota',
              productCode: 'Ürün Kodu',
              companyType: 'Firma Tipi',
            },
            bannerClickStatistics: {
              header: 'Banner Tıklama İstatistikleri',
              releaseDateRange: 'Yayın Tarihi Aralığı',
              status: 'Durumu',
              filter: 'Süz',
              bannerName: 'Banner Adı',
              startDate: 'Başlangıç Tarihi',
              endDate: 'Bitiş Tarihi',
            },
            thisTooList: {
              header: 'Bu da Olsun Süzme Koşulları',
              listType: 'Liste Tipi',
              dateRange: 'Tarih Aralığı',
            },
            pollList: {
              header: 'Anket Olsun Süzme Koşulları',
              pollResult: 'Anket Sonuçları Listesi',
            },
            infographicLists: {
              header: 'InfoGrafik Listeleri',
              mostWords: 'Son 7 günde en çok aranan 12 kelime-öneri',
              mostProducts: 'Son 7 günde en çok görüntülenen 12 ürün',
              notFound: 'Listenecek Bilgi Bulunamadı',
            },
            iceCatReport: {
              header: 'Rapor Süzme Koşulları',
              iceCatList: 'IceCat Listesi',
              filterResult: '${0} için Dün ${1} adet ürün gönderildi',
              processDate: ' İşlem Tarihi',
              number: 'Adet',
              status: 'Durum',
            },
            pageAnalysis: {
              header: 'Pazar sayfaları giriş analizi',
            },
            productVideosClickStatistics: {
              header: 'Ürün Videoları Tıklanma İstatistikleri-1',
            },
            pollProductList: {
              sellerCode: 'Bayi Kodu',
            },
          },
          dealerAndOrderReport: {
            basketQuotaReport: {
              basketQuotaReport: {
                header: 'Sepet Kota Raporu / (İyzico Sipariş Raporu)',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                show: 'göster',
                transactionDateRange: 'İşlem Tarihi Aralığı',
                transactionExists: 'Seçtiğiniz koşullara uyan işlem bulunmamaktadır.',
              },
            },
            currentEnteredOrders: {
              currentEnteredOrders: {
                header: 'Cari Adına İçerden (pazardan) Girilen Siparişler',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                transactionDateRange: 'İşlem Tarihi Aralığı',
                filtre: 'Süz',
                noRecordsFound: 'Kayıt Bulunamadı!',
              },
            },
            dealerAccess: {
              dealerAccess: {
                header: 'Bayi Erişimleri',
                enterTheConditions: 'Koşulları girin',
                forTheLastFewMonths: 'Son kaç ay için',
                filtre: 'Süz',
                sapCode: 'SAP Kodu',
                accountCode: 'Hesap Kodu',
                accountNameTitle: 'Hesap Adı/Ünvan',
                lastMonthAccess: 'Son ${0} Ay Erişim',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            dealerContentsReport: {
              dealerContentsReport: {
                choose: 'Seçiniz',
                all: 'Hepsi',
                dealerInformationUpdateRequest: 'Bayi bilgileri güncelleme talebi',
                dealerSuggestion: 'Bayi Önerisi',
                header: 'Başvuru İçerikleri Raporu',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                operationType: 'İşlem Tipi',
                show: 'Göster',
                accountCode: 'Hesap Kodu',
                accountName: 'Hesap Adı',
                orderType: 'Sipariş Tipi',
                paymentType: 'Ödeme Tipi',
                sapOrderNo: 'SAP Sipariş No',
                campaignCode: 'Kampanya Kodu',
                orderMoment: 'Sipariş Anı',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            dealerNavigationStatistics: {
              dealerStatistics: {
                header: 'Bayi Gezinme İstatistikleri-1',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                dealerSubtype: 'Bayi Alt Tipi',
                all: 'Boş bırakırsanız hepsi',
                exportToExcel: 'Excele Aktar',
                showOnScreen: 'Ekranda Göster',
                accountCode: 'Hesap Kodu',
                accountName: 'Hesap Adı',
                orderType: 'Sipariş Tipi',
                paymentType: 'Ödeme Tipi',
                sapOrderNo: 'SAP Sipariş No',
                campaignCode: 'Kampanya Kodu',
                orderMoment: 'Sipariş Anı',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            handcraftedOrdersList: {
              handcraftedOrdersList: {
                header: 'El Değmiş Siparişler Listesi',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                orderCode: 'Sipariş Kodu',
                filtre: 'Süz',
                queryResult: 'Sorgu Sonucu',
                noRecordsFound: 'Kayıt Bulunamadı',
              },
            },
            informationOrderMail: {
              informationOrderMail: {
                choose: 'Seçiniz',
                all: 'Hepsi',
                sending: 'Gönderiliyor',
                successful: 'Başarılı',
                cancel: 'İptal',
                unsuccessful: 'Başarısız',
                toBeSent: 'Gönderilecek',
                header: 'Sipariş Bilgi E-Posta Gönderimleri Raporu',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                operationType: 'İşlem Tipi',
                show: 'Göster',
                accountCode: 'Hesap Kodu',
                accountName: 'Hesap Adı',
                orderType: 'Sipariş Tipi',
                paymentType: 'Ödeme Tipi',
                sapOrderNo: 'SAP Sipariş No',
                campaignCode: 'Kampanya Kodu',
                orderMoment: 'Sipariş Anı',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            opportunityMarketReport: {
              opportunityMarketReport: {
                header: 'Fırsat Pazarı Raporu',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                showInExcel: 'Excelde Göster',
                show: 'Göster',
                entryReport: '${0} - ${1} tarihleri arasında Fırsat Pazarı Bayi Griş Raporu',
                noStatistics: 'Seçtiğiniz koşullara uyan istatistik bulunmamaktadır.',
                orderInformation: '${0} - ${1} tarihleri arasında Fırsat Pazarı Sipariş Bilgileri',
              },
            },
            noneUntouchedOrdersList: {
              noneUntouchedOrdersList: {
                header: 'HİÇ El Değmemiş Siparişler Listesi',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                orderCode: 'Sipariş Kodu',
                veryUntouched: 'Çok El Değmiş',
                filtre: 'Süz',
                handsOnCondition: 'El Değme Durumu',
                rowCounter: 'Satır Sayaç',
                orderCounter: 'Sipariş Sayaç',
                market: 'Pazar',
                companyCode: 'Şirket Kodu',
                currentCode: 'Cari Kodu',
                currentName: 'Cari Ad',
                orderNo: 'Sipariş No',
                orderDate: 'Sipariş Tarihi',
                creationDate: 'Oluşturulma Tarihi',
                status: 'Durumu',
                submitForApproval: 'Onaya Gönder',
                paymentMethod: 'Ödeme Şekli',
                paymentTypeDescription: 'Ödeme Şekli Açıklama',
                billNo: 'Fatura No',
                netWorth: 'Net Değer',
                currency: 'Para Birimi',
                netWorthPrice: 'Net Değer  TRY(Sip. Fiy. Kurundan)',
                netWorthMuh: 'Net Değer  TRY(Sip. Muh. Kurundan)',
                netWorthDay: 'Net Değer  TRY(Gün. Kurundan)',
                exchangeStatus: 'Kur Durumu',
                netWorthUsd: 'Net Değer USD',
                usd: "TRY {' > '} USD Kuru",
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            orderOperation: {
              listOrder: {
                header: 'Sipariş Listesi',
                field: 'Saha',
                choose: 'Seçiniz',
                accountCode: 'Hesap Kodu',
                fieldValueCondition: 'Saha Değer / Koşul',
                filtre: 'Süz',
                companyName: 'Firma Adı',
                productName: 'Ürün Adı',
                brand: 'Marka',
                number: 'Adet',
                price: 'Fiyat',
                operation: 'İşlem',
                orderCancel: 'Sipariş İptal',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            orderRapor: {
              listOrderRapor: {
                header: 'Sipariş/Satışlar Raporu',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                orderTypeSelection: 'Sipariş Tipi Seçimi',
                choose: 'Seçiniz',
                all: 'Hepsi',
                warehouseShipment: 'Depodan Sevk',
                financialTransactions: 'Finansal İşlemler',
                aweaItSelection: 'AWEA/IT Seçimi',
                itDealers: 'IT Bayileri',
                inTheArchive: 'Arşivde',
                totalShow: 'Toplam Göster',
                filtre: 'Süz',
                accountCode: 'Hesap Kodu',
                accountName: 'Hesap Adı',
                orderType: 'Sipariş Tipi',
                paymentType: 'Ödeme Tipi',
                sapOrderNo: 'SAP Sipariş No',
                campaignCode: 'Kampanya Kodu',
                orderMoment: 'Sipariş Anı',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            promotionalOrders: {
              promotionalOrders: {
                header: 'Promosyon Siparişleri 1 Listesi',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                orderCode: 'Sipariş Kodu',
                currentCode: 'Cari Kod',
                campaignCode: 'Kampanya Kodu',
                filtre: 'Süz',
                transactionDateRange: 'İşlem Tarih Aralığı',
                noRecordsFound: 'Kayıt Bulunamadı!',
              },
            },
            sasReport: {
              sasReport: {
                header: 'Teslim Tarihi Geçmişte Kalan Sas Raporu 1',
                noRecordsFound: 'Kayıt Bulunamadı!',
              },
            },
            siteNavigationStatistics: {
              statisticsList: {
                header: 'Gezinme İstatistikleri',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                dealerCode: 'Bayi Kodu',
                all: 'Boş bırakırsanız hepsi',
                totalShow: 'Toplam Göster',
                show: 'Göster',
                accountCode: 'Hesap Kodu',
                accountName: 'Hesap Adı',
                orderType: 'Sipariş Tipi',
                paymentType: 'Ödeme Tipi',
                sapOrderNo: 'SAP Sipariş No',
                campaignCode: 'Kampanya Kodu',
                orderMoment: 'Sipariş Anı',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            siteNavigationStatistics2: {
              statisticsList: {
                header: 'Site Gezinme İstatistikleri-2',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                dealerCode: 'Bayi Kodu',
                all: 'Boş bırakırsanız hepsi',
                letEveryDealerCountOnce: 'Her Bayi Bir Kez Sayılsın',
                show: 'Göster',
                accountCode: 'Hesap Kodu',
                accountName: 'Hesap Adı',
                orderType: 'Sipariş Tipi',
                paymentType: 'Ödeme Tipi',
                sapOrderNo: 'SAP Sipariş No',
                campaignCode: 'Kampanya Kodu',
                orderMoment: 'Sipariş Anı',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            untouchedOrdersList: {
              untouchedOrdersList: {
                header: 'El Değmemiş Siparişler Listesi',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                orderCode: 'Sipariş Kodu',
                untouched: 'El Değmiş',
                filtre: 'Süz',
                handsOnCondition: 'El Değme Durumu',
                rowCounter: 'Satır Sayaç',
                orderCounter: 'Sipariş Sayaç',
                market: 'Pazar',
                companyCode: 'Şirket Kodu',
                currentCode: 'Cari Kodu',
                currentName: 'Cari Ad',
                orderNo: 'Sipariş No',
                orderDate: 'Sipariş Tarihi',
                creationDate: 'Oluşturulma Tarihi',
                status: 'Durumu',
                submitForApproval: 'Onaya Gönder',
                paymentMethod: 'Ödeme Şekli',
                paymentTypeDescription: 'Ödeme Şekli Açıklama',
                billNo: 'Fatura No',
                netWorth: 'Net Değer',
                currency: 'Para Birimi',
                netWorthPrice: 'Net Değer  TRY(Sip. Fiy. Kurundan)',
                netWorthMuh: 'Net Değer  TRY(Sip. Muh. Kurundan)',
                netWorthDay: 'Net Değer  TRY(Gün. Kurundan)',
                exchangeStatus: 'Kur Durumu',
                netWorthUsd: 'Net Değer USD',
                usd: 'TRY > USD Kuru',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
            usageReport: {
              usageReport: {
                header: 'FÇV2 Kullanım Raporu',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                show: 'Göster',
                sapDealerCode: 'SAP Bayi Kodu',
                dealerCode: 'Bayi Kodu',
                dealerName: 'Bayi Adı',
                sapProductCode: 'SAP Ürün Kodu',
                productCode: 'Ürün Kodu',
                productName: 'Ürün Adı',
                quota: 'Kota',
                billNo: 'Fatura No',
                billDate: 'Fatura Tarihi',
                receiptNo: 'Makbuz No',
                receiptDate: 'Makbuz Tarihi',
                amount: 'Tutar',
                bank: 'Banka',
                installment: 'Taksit',
                total: 'Toplam',
                recordListed: 'kayıt listelendi.',
              },
            },
          },
          mailOrderOperation: {
            financial: {
              sapFinancial: {
                header: 'Mail Order Makbuz Listesi',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                documentNo: 'Belge No',
                all: '*Boş bırakırsanız hepsi',
                show: 'Göster',
                transactionDateRange: 'İşlem Tarihi Aralığı',
                thereIsNoDocument: 'Seçtiğiniz koşullara uyan belge bulunmamaktadır.',
              },
            },
            mailOrder: {
              mailOrder: {
                header: 'Mail Order Makbuz Listesi',
                enterTheConditions: 'Koşulları girin',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                documentNo: 'Belge No',
                all: '*Boş bırakırsanız hepsi',
                show: 'Göster',
                transactionDateRange: 'İşlem Tarihi Aralığı',
                thereIsNoDocument: 'Seçtiğiniz koşullara uyan belge bulunmamaktadır.',
              },
            },
            manuelCredit: {
              manuelCredit: {
                header: 'Manuel Kredi Kartı Ödeme Ekranı',
                step: '${0}. Adım -',
                yourPaymentInformation: 'Ödeme Bilgileriniz',
                dealerCode: 'Bayi Kodu',
                amountYouWant: 'Çekilmesini istediğiniz tutar',
                commissionType: 'Komisyon Tipi',
                excludingCommission: 'Komisyon Hariç',
                commissionIncluded: 'Komisyon Dahil',
                noCommission: 'Komisyonsuz',
                selectBankAndInstallment: 'Lütfen Banka ve Taksit Seçiniz',
                individualCard: 'Bireysel Kart',
                commercialCard: 'Ticari Kart',
                bank: 'Banka',
                creditCard: 'Kredi Kartı Bilgilerinizi Giriniz',
                nameSurName: 'Adı Soyadı',
                cardNo: 'Kart Numarası',
                expirationDate: 'Son Kullanma Tarihi',
                month: 'Ay',
                year: 'Yıl',
                securityNo: 'Güvenlik Numarası',
                cvcNo: '*Kartınızın arkasındaki CVC numarası',
                payment: 'Kredi Kartı ile Ödeme',
              },
            },
          },
          CreditCardOperation: {
            CreaditCardDefinedCrud: {
              AddCard: {
                header: 'Tanımlı Kredi Kartı Ekleme',
                accountCode: 'Hesap Kodu',
                creditcardnumber: 'Kredi Kart No(ilk 4 ; Son 4 hane)',
                expirationdate: 'Son Kullanma Tarihi',
                add: 'Ekle',
                effect: 'Etkin',
                year: 'Yıl',
                month: 'Ay',
                cardnameandsurname: 'Kart Sahibinin Adı Soyadı',
              },
              listCard: {
                header: 'Tanımlı Kredi Kartı Ekleme / Güncelleme',
                field: 'Saha',
                choose: 'Seçiniz',
                accountcode: 'Hesap Kodu',
                accountNameTitle: 'Hesap Adı/Ünvan',
                cardCode: 'Tanımlı Kredi Kartı Kodu',
                email: 'E-Posta',
                fieldValueCondition: 'Saha Değer / Koşul',
                accountname: 'Hesap Adı/Ünvan',
                active: 'Aktif',
                cardno: 'Kart No',
                expirationdate: 'Son Kullanma Tarihi',
                cardnonameandsurname: 'Kart Sahibinin Adı Soyadı',
                strain: 'Süz',
                effective: ' Etkin',
                process: 'İşlem',
                addCard: ' Ekle',
              },
            },
            CreditCardBlacklistCrud: {
              AddCardBlack: {
                header: 'Kredi Kartı Kara Liste Güncelleme/Silme',
                creditcardno: 'Kredi Kart No',
                effect: ' Etkin',
                addCard: ' Ekle',
              },
              ListCardBlack: {
                header: 'Kredi Kartı Kara Liste Ekleme ',
                cardno: 'Kart No',
                state: 'Durumu',
              },
            },
            BIN: {
              bank: 'Banka',
              binno: 'BIN No',
              status: 'Durum',
              show: 'Süz',
              header: 'BIN Sorgulama',
              bankaname: 'Banka Adı',
              bınno: 'BIN No',
              strain: 'Süz',
            },
            Successful: {
              show: 'Göster',
              enterTheConditions: 'Koşulları Girin',
              startDate: 'Başlangıc Tarihi',
              endDate: 'Bitiş Tarihi',
              header: 'Kredi Kartı - Başarılı İşlemler Raporu',
              transactionExists: 'Seçtiğiniz koşullara uyan kredi kartı işlemi bulunmamaktadır!',
              transactionDateRange: 'Tarih aralığı',
              thereIsNoDocument: 'Dokuman Bulunamadı',
              dealercode: 'Bayi Kodu',
              all: 'Boş bırakırsanız hepsi',
            },
            NumberIssues: {
              show: 'Göster',
              startDate: 'Başlangıc Tarihi',
              endDate: 'Bitiş Tarihi',
              header: 'Kredi Kartı - Numara Sorunları Raporu',
              transactionExists: 'Seçtiğiniz koşullara uyan kredi kartı işlemi bulunmamaktadır!',
              transactionDateRange: 'Tarih aralığı',
            },

            POSIssues: {
              bankerror: 'Banka hata(ön işlem)',
              order: 'Sipariş ve Makbuz Yok',
              noreceipt: 'Sipariş var ,Makbuz yok',
              finishing: 'Banka Hata ,Son işlem',
              operationType: 'İşlem tipi',
              show: 'Göster',
              startDate: 'Başlangıc Tarihi',
              endDate: 'Bitiş Tarihi',
              header: 'Kredi Kartı - POS Sorunları Raporu',
              transactionExists: 'Seçtiğiniz koşullara uyan kredi kartı işlemi bulunmamaktadır!',
              enterTheConditions: 'Koşulları girin',
            },

            SAP: {
              show: 'Göster',
              startDate: 'Başlangıc Tarihi',
              endDate: 'Bitiş Tarihi',
              header: "SAP'de Makbuz Oluşturma",
              transactionExists: 'Seçtiğiniz koşullara uyan kredi kartı işlemi bulunmamaktadır!',
              enterTheConditions: 'Koşulları girin',
              transactionDateRange: 'Tarih aralığı',
            },
          },
          bankAndInstallementOperation: {
            bankCrud: {
              bankAdd: {
                header: 'Banka Taksit/Kampanya Ekleme',
                generalInstallmentCampaign: 'Genel Taksit / Kampanya',
                campaign: 'Cari Grubuna Kampanya',
                brand: 'Cari Grubuna/Marka',
                productQuota: 'Cari Grubuna/Ürün Kotası',
                productGroup: 'Cari Grubuna/Ürün Grubu',
                productCode: 'Cari Grubuna/Ürün Kodu',
                generalFinancialSolutions: 'Genel Finansal Çözümler',
                financialSolutions: 'Cari Grubuna/Finansal Çözümler',
                generalLimit: 'Genel Limitli POS',
                concubineLimited: 'Cariye Limitli POS',
                dgt: 'DGT Sanal TL',
                generalOffer: 'Genel Fırsat POS',
                concubineOpportunity: 'Cariye Fırsat POS',
                inAdvance: 'Peşin',
                installment: '${0} Taksit',
                effective: 'Etkin',
                groupCode: 'Kampanya Grup Kodu',
                bankName: 'Banka Adı',
                selection: 'Taksit/Kampanya Seçimi',
                description: 'Kampanya Açıklama',
                image: 'Kampanya Resmi',
                link: 'Kampanya Linki',
                relatedCode: 'İlgili Kod',
                similar: 'Benzer',
                notSimilar: 'Benzer Değil',
                in: 'İçinde',
                notIn: 'İçinde Değil',
                basketNotes: 'Sepet Notları',
                vatExcluding: 'KDV Hariç Faiz Oranı',
                vatIncluded: 'KDV Dahil Faiz Oranı',
                installments: 'Taksit Adedi',
                additionalInstallments: 'Ek Taksit Adedi',
                startDate: 'Yayın Başlangıç Tarihi (SS:DD)',
                endDate: 'Yayın Bitiş Tarihi (SS:DD)',
                add: 'Ekle',
                productSubType: 'Ürün Alt Tipi',
                company: 'Cari Firma Tipi',
                subType: 'Cari Alt Tipi',
                code: 'Cari Kodu',
              },
              bankUpdateDel: {
                header: 'Banka Taksit / Kampanya Güncelleme/Silme',
                generalInstallmentCampaign: 'Genel Taksit / Kampanya',
                campaign: 'Cari Grubuna Kampanya',
                brand: 'Cari Grubuna/Marka',
                productQuota: 'Cari Grubuna/Ürün Kotası',
                productGroup: 'Cari Grubuna/Ürün Grubu',
                productCode: 'Cari Grubuna/Ürün Kodu',
                generalFinancialSolutions: 'Genel Finansal Çözümler',
                financialSolutions: 'Cari Grubuna/Finansal Çözümler',
                generalLimit: 'Genel Limitli POS',
                concubineLimited: 'Cariye Limitli POS',
                dgt: 'DGT Sanal TL',
                generalOffer: 'Genel Fırsat POS',
                concubineOpportunity: 'Cariye Fırsat POS',
                onAir: 'Yayında',
                inTheArchive: 'Arşivde',
                add: 'Ekle',
                bank: 'Banka',
                field: 'Saha',
                status: 'Durum',
                campaignGroupCode: 'Kampanya Grup Kodu',
                operationStartEndDate: 'İşlem Başlanıç-Bitiş Tarihi',
                filtre: 'Süz',
                type: 'Taksit/Kampanya Tipi',
                groupCode: 'Grup Kodu',
                bankName: 'Banka Adı',
                description: 'Açıklama',
                installment: 'Taksit',
                additionalInstallment: 'Ek Taksit',
                vatExcluding: 'KDV Hariç Faiz',
                vatIncluded: 'KDV Dahil Faiz',
                relatedCode: 'İlgili Kod',
                productSubType: 'Ürün Alt Tipi',
                company: 'Cari Firma Tipi',
                subType: 'Cari Alt Tipi',
                code: 'Cari Kodu',
                startDate: 'Başlangıç T.',
                endDate: 'Bitiş T.',
                notes: 'Notlar',
                effective: 'Etkin',
                operation: 'İşlem',
                update: 'Güncelle',
                delete: 'Sil',
                total: 'Toplam ${0} kayıt listelendi.',
              },
            },
            bulkBankAdd: {
              bulkBankAdd: {
                header: 'Banka Taksit/Kampanya Ekleme',
                generalInstallmentCampaign: 'Genel Taksit / Kampanya',
                campaign: 'Cari Grubuna Kampanya',
                brand: 'Cari Grubuna/Marka',
                productQuota: 'Cari Grubuna/Ürün Kotası',
                productGroup: 'Cari Grubuna/Ürün Grubu',
                productCode: 'Cari Grubuna/Ürün Kodu',
                generalFinancialSolutions: 'Genel Finansal Çözümler',
                financialSolutions: 'Cari Grubuna/Finansal Çözümler',
                generalLimit: 'Genel Limitli POS',
                concubineLimited: 'Cariye Limitli POS',
                dgt: 'DGT Sanal TL',
                generalOffer: 'Genel Fırsat POS',
                concubineOpportunity: 'Cariye Fırsat POS',
                inAdvance: 'Peşin',
                installment: '${0} Taksit',
                effective: 'Etkin',
                groupCode: 'Kampanya Grup Kodu',
                bankName: 'Banka Adı',
                selection: 'Taksit/Kampanya Seçimi',
                description: 'Kampanya Açıklama',
                image: 'Kampanya Resmi',
                link: 'Kampanya Linki',
                relatedCode: 'İlgili Kod',
                similar: 'Benzer',
                notSimilar: 'Benzer Değil',
                in: 'İçinde',
                notIn: 'İçinde Değil',
                productSubType: 'Ürün Alt Tipi',
                company: 'Cari Firma Tipi',
                subType: 'Cari Alt Tipi',
                code: 'Cari Kodu',
                basketNotes: 'Sepet Notları',
                installments: 'Taksit',
                additionalInstallments: 'Ek Taksit Adedi',
                interest: 'Faiz',
                startAndEndDate: 'Yayın Başlangıç-Bitiş Tarihi (SS:DD)',
                endDate: 'Yayın Bitiş Tarihi (SS:DD)',
                add: 'Ekle',
              },
            },
            financialBankDetail: {
              financialBankDetail: {
                header: 'Finansal Çözüm V2 Detay Liste',
                quotaCode: 'Kota Kodu',
                allIfLeftBlank: '* Boş Bırakılırsa Hepsi',
                dealerCode: 'Bayi Kodu',
                bankName: 'Banka Adı',
                operationStartDate: 'İşlem Başlangıç Tarihi',
                operationStartEndDate: 'İşlem Başlangıç-Bitiş Tarihi',
                show: 'Göster',
                type: 'Taksit/Kampanya Tipi',
                groupCode: 'Grup Kodu',
                description: 'Açıklama',
                installment: 'Taksit',
                additionalInstallment: 'Ek Taksit',
                vatExcluding: 'KDV Hariç Faiz',
                vatIncluded: 'KDV Dahil Faiz',
                relatedCode: 'İlgili Kod',
                productSubType: 'Ürün Alt Tipi',
                company: 'Cari Firma Tipi',
                subType: 'Cari Alt Tipi',
                code: 'Cari Kodu',
                startDate: 'Başlangıç T.',
                endDate: 'Bitiş T.',
                notes: 'Notlar',
                effective: 'Etkin',
                operation: 'İşlem',
                update: 'Güncelle',
                delete: 'Sil',
                total: 'Toplam ${0} kayıt listelendi.',
              },
            },
            financialBankRate: {
              financialBankRate: {
                header: 'Banka Taksit/Kampanya Güncelleme/Silme',
                bankName: 'Banka Adı',
                show: 'Göster',
                code: 'Kode',
                description: 'Açıklama',
                installment: 'Taksit',
                additionalInstallment: 'Ek Taksit',
                ratio: 'Oran',
                startDate: 'Başlangıç T.',
                endDate: 'Bitiş T.',
                effective: 'Etkin',
                operation: 'İşlem',
                update: 'Güncelle',
                delete: 'Sil',
                total: 'Toplam ${0} kayıt listelendi.',
              },
            },
            financialQuota: {
              financialQuota: {
                header: 'Finansal Çözüm V2 Kota Liste',
                operationStartDate: 'İşlem Başlangıç Tarihi',
                operationStartEndDate: 'İşlem Başlangıç-Bitiş Tarihi',
                show: 'Göster',
                description: 'Açıklama',
                bankName: 'Banka Adı',
                installment: 'Taksit',
                additionalInstallment: 'Ek Taksit',
                ratio: 'Oran',
                startDate: 'Başlangıç T.',
                endDate: 'Bitiş T.',
                effective: 'Etkin',
                operation: 'İşlem',
                update: 'Güncelle',
                delete: 'Sil',
                total: 'Toplam ${0} kayıt listelendi.',
              },
            },
            subDealerAdd: {
              subDealerAdd: {
                approval: 'Onay',
                rejection: 'Red',
                header: 'Alt Bayi Ekleme',
                bankName: 'Banka Adı',
                approvalRejection: 'Onay / Red',
                taxNumber: 'Vergi Numarası',
                notes: 'Notlar',
                save: 'Kaydet',
              },
            },
            subDealerControl: {
              subDealerControl: {
                header: 'Alt Bayi Sorgulama',
                bankName: 'Banka Adı',
                taxNumber: 'Bayi Kodu veya Vergi No',
                filtre: 'Süz',
                handleCode: 'Sap Kodu',
                accountCode: 'Hesap Kodu',
                accountName: 'Hesap Adı / Ünvan',
                bank: 'Banka',
                status: 'Durum',
                notes: 'Notlar',
                total: 'Toplam ${0} kayıt listelendi.',
              },
            },
          },
          brandPageOperation: {
            authorizedServices: {
              listAuthorizedServices: {
                brand: 'Marka',
                productgroup: 'Ürün Grubu',
                companyname: 'Firma İsmi',
                telephone: 'Telefon',
                email: 'E-posta',
                webadres: 'Web Adresi',
                feepayment: 'Ücret Ödemeli Kargo Kabul Ediliyor Mu?',
                contractedshipping: ' Anlaşmalı Kargo Şirketi (Varsa Gönderi Kodu)',
                authorizedservice: 'Yetkili Servis Noktaları',
                operation: 'İşlemler',
              },
              addAuthorizedServices: {},
            },

            authorizedProcedure: {
              listAuthorizedProcedure: {
                brand: 'Marka',
                productgroup: 'Ürün Grubu',
                descriptions: 'Açıklama',

                date: 'Başlangıç-Bitiş Tarihi',

                arrangement: 'Sıralama',
                buildTime: 'Oluşturma zamanı',
                state: 'Durum',
                operation: 'İşlemler',
              },
              addAuthorizedProcedure: {
                brand: 'Marka',
                productgroup: 'Ürün Grubu',
                descriptions: 'Açıklama',

                requireddocuments: 'İstenilen Evraklar',
                question: 'Müşteri adresinde onarım hizmeti veriyor musunuz?',
                guarantee: 'Garanti Süresi-Servis Süresi',
                cargo: 'Kargo-Ödeme Şekli',
                date: 'Başlangıç-Bitiş Tarihi',

                arrangement: 'Sıralama',
                buildTime: 'Oluşturma zamanı',
                state: 'Durum',
              },
            },

            brandHelp: {
              brandHelp: {
                header: 'Marka Yardım Sayfası',
                filePaths: 'Dosya Yolları',
                brands: '/markalar/MARKA KODU/DOSYA TİPİ/DOSYA ADI',
                brandCode: 'Marka Kodu',
                url: "Sitedeki marka ekranının url'inden öğrenebilirsiniz.",
                function: "Örnek indexpazar'dan alınmıştır.",
                fileTypes: 'Dosya Tipleri',
                links: 'BAĞLANTILAR',
                images: 'GÖRSELLER',
                news: 'HABERLER',
                documents: 'TEKNIK_DOKUMANLAR',
                microsoft: 'Microsoft için TEKNIK_DOKUMANLAR',
                l: 'Microsoft için TEKNIK_DOKUMANLAR/L',
                mgs: 'Microsoft için TEKNIK_DOKUMANLAR/MGS',
                ml: 'Microsoft için TEKNIK_DOKUMANLAR/ML',
                ms: 'Microsoft için TEKNIK_DOKUMANLAR/MS',
                msOffice: 'Microsoft için TEKNIK_DOKUMANLAR/MS Office',
                fileName: 'Dosya Adı',
                filesYouDefine:
                  "Tanımladığınız dosyaların adlarını 'Marka Bilgileri Sorgulama/Güncelleme' ekranında bularak,",
                renameYourFile: 'sistemin vermiş olduğu dosya adı ile dosyanızın adını değiştiriniz.',
                changedFilename:
                  "Değiştirilmiş dosya adı ile yazgel'den gerekli yerlere dosyanızın atılmasını isteyebilirsiniz.",
                browser:
                  " Yandaki gibi internet tarayıcınızda youtube'dan bulduğunuz videolarda aşağıdagösterilen şekilde pazar sitelerinde kullanabilirsiniz.",
                addLink: 'Ekle linkine tıklatıktan sonra göreceğiniz bölgede,',
                withoutDoubleQuotes:
                  'sarı işaretli linki (" çift tırnaksız) koplayaladıktan sonra, başına http koyarak kaydediniz.',
              },
            },
            brandInformation: {
              brandInformation: {
                generalInformation: 'Genel Bilgiler',
                files: 'Video Dosyaları',
                technicalDocument: 'Teknik Döküman (${0})',
                link: 'Teknik Döküman Link (kaynak dışarıda)',
                header: 'Marka Bilgileri Sorgulama/Güncelleme',
                subHeader: 'Süzme Koşulları',
                brand: 'Marka',
                module: 'Modul',
                filtre: 'Süz',
                operation: 'İşlem',
                dealer: 'Bayi Adı',
                daytime: 'Gündüz',
                night: 'Gece',
                solo: 'Yalnız 3D',
                date: 'Yayın Sonlanma Tarihi',
                order: 'Sıralama',
                creationTime: 'Oluşturma Zamanı',
                status: 'Durum',
                addNew: 'Yeni Ekle',
                code: 'Kodu',
                operations: 'İşlemler',
                enddate: 'Bitiş Tarihi',
                startdate: 'Başlangıç Tarihi',
                link1: 'Link',
                description: 'Açıklama',
              },

              brandUpdate: {
                general: 'Genel',
                header: 'Marka Sayfaları Bilgi Tanımlama/ Güncelleme Ekranı',
                subHeader: 'Link (Ekleme)',
                brandCode: 'Marka Kodu',
                releaseDate: 'Yayın Tarihi',
                description: ' Açıklama',
                date: 'Yayın Sonlanma Tarihi',
                order: 'Sıralama',
                status: 'Durumu',
                active: 'Aktif',
                passive: 'Pasif',
                add: 'Ekle',
                StartEnddate: ' Başlangıç Tarihi - Bitiş Tarihi',
                giveup: 'Vazgeç',
                videos: 'Videolar',
                technicaldocuments: 'Teknik Dokümanlar',
                addImage: 'Marka Görseli Ekle',
                addfile: 'Dosya Ekle',
                ımages: 'Görseller',
                links: 'Bağlantılar',
                newsannouncements: 'Haberler/Duyurular',
                degreepreview360: '360 Derece Önizleme',
                hptopconfig: 'HP Üst Yapılandırma',
                Inteltechnologyprovider: 'Intel Teknoloji Sağlayıcısı',
                licensing: 'Lisanslama',
                managedClients: 'Yönetilen Müşteriler ',
                managedservers: 'Yönetilen Sunucular',
                Managementservers: 'Yönetim Sunucuları',
                MSOffice: 'MS Office',
                ImportantInformation: 'Önemli Bilgi',
                brand: 'Marka',
                module1: 'Modul',
                sub1: 'Link',
                addBanner: 'Banner Ekle',
                logo: 'Logo Ekle',
              },
            },
          },
          dealerInformationFormOperation: {
            dealerInformation: {
              dealerInformation: {
                inProgress: 'İşlemi devam eden',
                pdfGenerated: 'PDF oluşturulan',
                confirmationEmailSent: 'Onay Maili gönderilen',
                contractApproved: 'Sözleşme Onaylananlar',
                completed: 'Tamamlanan',
                newlyAdded: 'Yeni eklenen',
                traders: 'İşlem görenler',
                notAccepted: 'Kabul edilmeyenler',
                thoseWithMissingDocuments: 'Eksik belgesi olanlar',
                contractExpected: 'Sözleşmesi beklenenler',
                thoseWhoReachedTheContract: 'Sözleşmesi ulaşanlar',
                passive: 'Pasif',
                active: 'Aktif',
                header: 'Bayi Bilgi Formu Listesi Sorgulama',
                subHeader: 'Süzme Koşulları',
                start: 'Başlangıç',
                end: 'Bitiş',
                status: 'Durumu',
                transactionStatus: 'İşlem Durumu',
                activePassive: 'Aktif/Pasif',
                trackingNumberTC: 'Takip No/TCKN/Vergi No',
                filtre: 'Süz',
                trackingNumber: 'Takip No',
                appellation: 'Ünvan',
                official: 'Yetkili',
                phone: 'Tel',
                mobile: 'Mobil',
                taxAdministration: 'Vergi Dairesi',
                taxNumber: 'Vergi No',
                condition: 'Durum',
                date: 'Oluşturma Tarihi',
                notes: 'Notlar',
                aperturaSap: 'SAP Açılış',
                documents: 'Belgeler',
              },
              dealerOnlineApplication: {
                inProgress: 'İşlemi devam eden',
                pdfGenerated: 'PDF oluşturulan',
                confirmationEmailSent: 'Onay Maili gönderilen',
                contractApproved: 'Sözleşme Onaylananlar',
                completed: 'Tamamlanan',
                newlyAdded: 'Yeni eklenen',
                traders: 'İşlem görenler',
                notAccepted: 'Kabul edilmeyenler',
                thoseWithMissingDocuments: 'Eksik belgesi olanlar',
                contractExpected: 'Sözleşmesi beklenenler',
                thoseWhoReachedTheContract: 'Sözleşmesi ulaşanlar',
                passive: 'Pasif',
                active: 'Aktif',
                header: 'Bayi Online Başvuru History',
                subHeader: 'History Kaydı Bulunamadı',
                status: 'Bayi Online Başvuru Durum Değiştirme',
                save: 'Kaydet',
              },
            },
          },
          freeAndOpportunityPosOperation: {
            addAndEdit: {
              addAndEdit: {
                header: 'Bedava POS Ekleme / Düzenleme',
                transactionPeriod: 'İşlem Dönemi',
                free: 'Bedava POS',
                opportunity: 'Fırsat POS',
                sap: 'Bayi SAP',
                total: 'Tutar',
                dalersLimit: 'Bayinin Limiti varsa üstüne eklenecek yoksa yeni kayıt yapılacak',
                dalersLimitToBeDeducted: 'Bayinin Limitinden düşülecek',
                dalersLimitPassive: 'Bayinin Limiti pasif yapılacak',
                dalersLimitActive: 'Bayinin Limiti aktif yapılacak',
                notes: 'Notlar',
                save: 'Kaydet',
              },
            },
            bank: {
              bankInstallmentReport: {
                header: 'İşlem Listesi',
                subHeader: 'Koşulları Giriniz',
                transactionStatus: 'İşlem Durumu',
                traders: 'İşlem Yapanlar',
                nonTransactions: 'İşlem Yapmayanlar',
                all: 'Hepsi',
                status: 'Durumu',
                active: 'Aktif',
                passive: 'Pasif',
                processType: 'İşlem Tipi',
                listType: 'Liste Tipi',
                free: 'Bedava POS',
                opportunity: 'Fırsat POS',
                total: 'Taksit Toplam',
                detailed: 'Taksit Detaylı',
                period: 'Ait Olduğu Dönem',
                null: '*Boş bırakırsanız hepsi',
                date: 'İşlem Tarihi Aralığı',
                wholePeriod: '*Boş bırakırsanız bütün dönem',
                code: 'Bayi Kodu',
                channelManager: 'Kanal Sorumlusu',
                type: 'Firma Tipi',
                subType: 'Firma Alt Tipi',
                exportToExcel: 'Excele Aktar',
                show: 'Ekranda Göster',
              },
            },
            dealer: {
              whiteDealerList: {
                header: 'Bedava POS Banka Taksit Raporu',
                subHeader: 'Koşulları Giriniz',
                date: 'İşlem Tarihi Aralığı',
                bank: 'Banka Adı',
                code: 'Bayi Kodu (Listesi)',
                subType: 'Bayi Alt Tipi (Listesi)',
                advantageOpportunity: 'Avantaj/Fırsat POS',
                all: 'Hepsi',
                advantageGeneral: 'Avantaj Genel',
                advantageCurrent: 'Avantaj Cari',
                opportunityGeneral: 'Fırsat Genel',
                opportunityCurrent: 'Fırsat Cari',
                show: 'Göster',
              },
            },
            transaction: {
              transactionList: {
                daytime: 'Gündüz',
                night: 'Gece',
                daytimeNight: 'Gündüz ve Gece',
                solo: 'Yalnız 3D',
                header: 'Beyaz Bayi Listesi',
                subHeader: 'Koşulları Girin',
                listType: 'Liste Tipi',
                dealerCode: 'Bayi Kodu',
                filtre: 'Süz',
                code: 'Kodu',
                name: 'Bayi Adı',
              },
            },
          },
          productOperation: {
            addingProductAttributeChangeData: {
              addPropertyModification: {
                header: 'Ürün Özellik Ekleme',
                subHeader: 'Süzme Koşulları',
                group: 'Ürün Grubu',
                category: 'IceCat Kategorisi',
                featureName: 'Özellik Adı',
                featureValue: 'Özellik Değeri',
                number: 'Tanımlı Ürün Sayısı',
                add: 'Ekle',
              },
            },
            addProductFeatures: {
              addProductFeatures: {
                header: 'Ürün Özellik Ekleme',
                subHeader: 'Süzme Koşulları',
                group: 'Ürün Grubu',
                sap: 'SAP Malzeme Numarası',
                note: 'Not',
                featureDescription:
                  'Çoklayan Özellik Açıklaması durumunda Tanımlı Ürün Sayısı çok olanı tercih ediniz. Aynı olan diğer açıklamaları boş bırakınız',
                category: 'IceCat Kategorisi',
                featureName: 'Özellik Adı',
                featureValue: 'Özellik Değeri',
                number: 'Tanımlı Ürün Sayısı',
                add: 'Ekle',
              },
            },
            bestSellerList: {
              bestSellerList: {
                header: 'En çok satanlar Hariç Ekleme',
                list: 'Ürün Listesi',
                add: 'Ekle',
              },
            },
            campaignBundleCrud: {
              campaignBundleAdd: {
                generalInformation: 'Genel Bilgiler',
                videoDocuments: 'Video dosyaları',
                videoLink: 'Video Link',
                technicalDocument: 'Teknik Döküman ${0}',
                header: 'Kampanya/Bundle Ekleme',
                code: 'Bundle Kodu',
                pageDescription: 'Sepet ve Kampanyalar Sayfası Açıklama',
                sap: 'SAP Kampanya Kodu',
                image: 'Bundle Görseli',
                productDetailPage: 'Ürün Detay Sayfası Açıklama',
                listLinkCode: 'Liste Linki Kodu',
                listLinkDescription: 'Liste Linki Açıklama',
                brand: 'Marka',
                feature: 'Özellik',
                codeList: 'Cari Kodu Listesi',
                including: 'Dahil',
                notIncluding: 'Hariç',
                order: 'Sıra No',
                warehouseCode: 'Depo Kodu',
                active: 'Etkin',
                singleChoice: 'Tek Seçimli Kampanya',
                fcv: 'FÇV2 Kampanya',
                numberOfBenefits: 'Kampanya Yararlanma Sayısı',
                avea: 'AVEA Duyuru',
                totalBasket: 'Sepet toplamına kampanya ürünleri dahil olsun',
                turnoverTotal: 'Ciro Toplamı',
                start: 'Yayın Başlangıç Tarihi',
                end: 'Yayın Bitiş Tarihi',
                add: 'Ekle',
                product: 'Ürün Kodu Listesi',
                quota: 'Kota Kodu Listesi',
                group: 'Grup Listesi',
                brandList: 'Marka Listesi',
                subType: 'Alt Tip Listesi',
                minMax: 'Min/Max Adet',
                totalPrice: 'Min/Max Toplam Fiyat',
                totalQuantity: 'Koşul Toplamı Adetine Bak',
                addRow: 'Satır Ekle',
                deleteRow: 'Satır Sil',
                productCode: 'Ürün Kodu',
                resultAction: 'Sonuç İşlem',
                number: 'Adet',
                max: 'Max. Adet',
                amountRatio: 'Tutar/Oran',
                currency: 'Döviz',
              },
              campaignBundleList: {
                header: 'Bundle Güncelleme/Silme',
                onTheAir: 'Yayında',
                inTheArchive: 'Arşivde',
                brand: 'Marka',
                subHeader: 'Süzme Koşulları',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                field: 'Saha',
                fieldValueCondition: 'Saha Değer/Koşul',
                filtre: 'Süz',
                add: 'Ekle',
                operation: 'İşlem',
                bundleCode: 'Bundle Kodu',
                bundleName: 'Bundle Adı',
                currentCode: 'Cari Kodu',
                currentType: 'Cari Tipi',
                currentSubType: 'Cari Alt Tipi',
                codeList: 'Ürün Kodu Listesi',
                broadcastStart: 'Yayın Başlangıç',
                broadcastEnd: 'Yayın Bitiş',
                active: 'Etkin',
                information: 'Üst Bilgi',
                delete: 'Sil',
                copy: 'Kopyala',
              },
            },
            filterPropertiesUndefinedProducts: {
              undefinedProducts: {
                header: "Filtre Özellikleri 'Tanımsız' Kategori Grup Özellik Listesi",
                subHeader: "IceCat'den Hiç Özelliği Gelmemiş Ürünler Listesi",
                noRecordsFound: 'Kayıt Bulunamadı',
              },
            },
            listOfUnsoldProducts: {
              listOfUnsoldProducts: {
                header:
                  'IDX Şirketinde IST Açık, TAMİR Depoları için Son 60 Gündür Satılmayan Stoğu 0 Ürünler Listesi (60 günden eski ürün kartları için)',
                code: 'Ürün Kodu',
                name: 'Ürün Adı',
                group: 'Ürün Grubu',
                brand: 'Marka',
                general: 'Genel',
                company: 'Şirket',
                manager: 'Ürün Müdürü',
              },
            },
            opportunityAisleProduct: {
              addOpportunityAisle: {
                header: 'Fırsat Reyonu Ekleme',
                stockCode: 'Ürün/Stok Kodu',
                warehouseCode: 'Depo Kodu',
                active: 'Etkin',
                order: 'Ürün Sıra No',
                serialNumber: 'Ürün Seri No',
                number: 'Adet',
                quantitySold: 'Satılan Adet',
                image: 'Ürün Resmi',
                price: 'Ürün Fiyatı',
                description: 'Açıklama',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                nightMarket: 'Gece Pazarı',
                outOfWarranty: 'Garanti Dışı',
                add: 'Ekle',
              },
              opportunityAisleProductList: {
                productCode: 'Ürün Kodu',
                onAir: 'Yayında',
                inTheArchive: 'Arşivde',
                sold: 'Satılan',
                unsold: 'Satılamayan',
                header: 'Fırsat Reyonu Ürün Güncelleme/Silme',
                subHeader: 'Süzme Koşulları',
                dateStart: 'Tarih Başlangıç',
                dateEnd: 'Tarih Bitiş',
                fieldValueCondition: 'Saha Değer/Koşul',
                selection: 'Seçim',
                filtre: 'Süz',
                add: 'Ekle',
                order: 'Sıra No',
                code: 'Ürün Kodu',
                warehouseCode: 'Depo Kodu',
                serialNumber: 'Ürün Seri No',
                price: 'Fiyatı',
                image: 'Görsel Adı',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                description: 'Ürün Açıklaması',
                number: 'Adet',
                sell: 'Satıldı',
                active: 'Etkin',
                operation: 'İşlem',
                update: 'Güncelle',
                delete: 'Sil',
              },
            },
            ordersPendingApproval: {
              ordersPendingApproval: {
                header: 'Onay Bekleyen Siparişler',
                notFound: 'Onay için bekleyen Sipariş Bulunamadı !',
              },
            },
            priceChecklist: {
              priceChecklist: {
                header: 'Fiyat Kontrol Listesi',
                quota: 'Kota',
                code: 'Ürün Kodu',
                name: 'Ürün Adı',
              },
            },
            productAttributeChangeData: {
              productFeatureChange: {
                header: 'Ürün Listeleme Kelimesi Arama',
                subHeader: 'Süzme Koşulları',
                search: 'Arama Kelimesi',
                list: 'Listele',
                addNew: 'Yeni Ekle',
                product: 'Ürün Kodu',
                sap: 'SAP Kodu',
                word: 'Kelime',
                activePassive: 'Aktif / Pasif',
              },
              productFeatureChangeAdd: {
                header: 'Ürün Liste Arama Ekleme',
                search: 'Arama Kelimesi',
                list: 'Ürün Listesi',
                send: 'Gönder',
                title: 'Başlık',
                productCode: 'Ürün Kodu',
              },
            },
            productQuantityLimit: {
              addProductLimitation: {
                header: 'Ürün Adet Sınırlaması Ekleme',
                clate: 'Ürün Kodu Klatesi',
                subType: 'Cari Hesap Alt Tip(ler)',
                active: 'Etkin',
                including: 'Dahil',
                notIncluding: 'Hariç',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                max: 'Max Adet',
                notes: 'Notlar',
                warehouseCode: 'Depo Kodu',
                warehouses: '*Boş bırakılırsa bütün depolar',
                add: 'Ekle',
                giveUp: 'Vazgeç',
              },
              productQuantityLimitList: {
                onAir: 'Yayında',
                intheArchive: 'Arşivde',
                header: 'Ürün Adet Sınırlama Güncelleme/Silme',
                subHeader: 'Süzme Koşulları',
                startAndEndDate: 'Başlangıç Tarihi-Bitiş Tarihi',
                selection: 'Seçim',
                filtre: 'Süz',
                add: 'Ürün Sınırlaması Ekle',
                productCode: 'Ürün Kodu',
                number: 'Adet',
                warehouse: 'Depo',
                currentCode: 'Cari Kodu',
                currentType: 'Cari Tipi',
                currentSubType: 'Cari Alt Tipi',
                start: 'Yayın Başlangıç',
                end: 'Yayın Bitiş',
                active: 'Etkin',
                operation: 'İşlem',
                update: 'Güncelle',
                delete: 'Sil',
              },
            },
            productsWithCampaignRequest: {
              campaignProducts: {
                header: 'Kampanya İsteği Olan Ürünler Listesi',
                subHeader: 'Koşulları Giriniz',
                dateStart: 'Tarih Başlangıç',
                dateEnd: 'Tarih Bitiş',
                show: 'Göster',
                dateRange: 'İşlem Tarihi Aralığı : ${0} - ${1}',
                notFound: 'Kampanya Ürün İsteği Bulunamadı !',
              },
            },
            productsWithNoFilterPropertiesEntered: {
              filterProducts: {
                header: 'Filitre Özellikleri Girilmemiş Ürünler Listesi',
                order: 'Sıra',
                b2b: 'B2B de Ürün Aktif Mi',
                id: 'Ürün ID',
                sap: 'SAP Kodu',
                code: 'Ürün Kodu',
                description: 'Ürün Açıklaması',
                categoryId: 'Kategori ID',
                category: 'Kategori',
                featureId: 'Özellik ID',
                feature: 'Özellik',
                groupId: 'IC Grup ID',
                group: 'IC Grup',
                undefined: 'Tanımsız',
              },
            },
            productsWithNoPrice: {
              productsWithNoPrice: {
                header: 'Fiyatı Olmayan Ürünler Listesi',
                order: 'Sıra',
                sap: 'SAP Kodu',
                code: 'Ürün Kodu',
                name: 'Ürün Adı',
                responsible: 'Ürün Sorumlusu',
              },
            },
            productsWithNoSpecialPrice: {
              productsWithNoSpecialPrice: {
                header: 'Özel Fiyatı Olmayan Ürünler Listesi',
                order: 'Sıra',
                code: 'Ürün Kodu',
              },
            },
            productsWithoutPictures: {
              productsWithoutPictures: {
                header: 'Resimleri Eklenmemiş Ürünler Listesi',
                order: 'Sıra',
                quota: 'Kota Kodu',
                md: 'Ürün Md.',
                sap: 'SAP Kodu',
                code: 'Ürün Kodu',
                description: 'Ürün Açıklaması',
                status: 'Durumu',
              },
            },
            productsWithUnspecifiedProperties: {
              unspecifiedProductsList: {
                header: 'Özellikleri Girilmemiş Ürünler Listesi',
                order: 'Sıra',
                status: 'Durum',
                quota: 'Kota Kodu',
                md: 'Ürün Md.',
                sap: 'SAP Kodu',
                description: 'Ürün Açıklaması',
                code: 'Ürün Kodu',
              },
            },
            stopwatchOperations: {
              stopwatchOperations: {
                onAir: 'Yayında',
                inTheArchive: 'Arşivde',
                code: 'Ürün Kodu',
                header: 'Kronometre İşlemleri',
                subHeader: 'Süzme Koşulları',
                field: 'Saha',
                filtre: 'Süz',
                add: 'Kronometre Ekle',
                operation: 'İşlem',
                title: 'Başlık',
                startDate: 'Başlama Tarihi',
                endDate: 'Bitiş Tarihi',
                types: 'Cari Alt Tipleri',
                includedExcluded: 'Alt Tip Dahil/Hariç',
                number: 'Satılan/Kalan Adet',
                start: 'Başlangıç Adedi',
                numberInc: 'Adet Artım Adımı',
                product: 'Ürün(ler)',
                extra: 'Ekstra Açıklama',
                type: 'Hedef Link Tipi',
                value: 'Hedef Link Değeri',
                background: 'Arka Plan Resmi',
                style: 'Yazı Stili',
                image: 'Başlık Görseli',
                message: 'Bitiş Mesajı',
                terminationMessage: 'Sonlanma Mesajı Gösterim Süresi (sn)',
                arrangement: 'Sıralama',
                time: 'Oluşturma Zamanı',
                active: 'Aktiflik',
              },
              stopwatchOperationsAdd: {
                noLink: 'Link Yok',
                detail: 'Ürün Detay Sayfası',
                campaign: 'Kampanya Sayfası',
                productList: 'Ürün Listesi',
                opportunityMarket: 'Fırsat Pazarı Sayfası',
                freeLink: 'Serbest Link',
                titleImage: 'Başlık ${0}',
                header: 'Kronometre Tanımlama/Güncelleme Ekranı',
                title: 'Başlık',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                subTypes: 'Cari Hesap Alt Tip(ler)',
                including: 'Dahil',
                excluding: 'Hariç',
                quantity: 'Başlangıç Adedi',
                pieceDisplay: 'Satılan/Kalan Adet Gösterimi',
                sold: 'Satılan',
                remainder: 'Kalan',
                display: 'Gösterme',
                clade: 'Ürün Kodu Klatesi',
                firstProduct: 'İlk ürün, ürün detay sayfası linkinde kullanılır.',
                increaseCount: 'Adet Artış Sayımı',
                extra: 'Ekstra Açıklama',
                type: 'Hedef Link Tipi',
                background: 'Arka Plan Görseli',
                image: 'Başlık Görseli',
                style: 'Font Stili',
                value: 'Hedef Link Değeri',
                message: 'Sonlanma Mesajı',
                showTime: 'Sonlanma Mesajı Gösteri Süresi',
                status: 'Durumu',
                add: 'Ekle',
                giveUp: 'Vazgeç',
              },
            },
            surprisePriceIdentification: {
              surprisePriceIdentification: {
                onAir: 'Yayında',
                inTheArchive: 'Arşivde',
                product: 'Ürün Kodu',
                header: 'Ödeme Tipine Göre Bundle Güncelleme (Aktif / Pasif Yapma)',
                subHeader: 'Süzme Koşulları',
                field: 'Saha',
                fieldValueCondition: 'Saha Değer/Koşul',
                filtre: 'Süz',
                definition: 'Tipine Göre Bundle Tanımlama',
                bundle: 'Bundle Kodu',
                bundleName: 'Bundle Adı',
                current: 'Cari Kodu',
                currentType: 'Cari Tipi',
                subType: 'Cari Alt Tipi',
                total: 'Tutar',
                paymentType: 'Ödeme Tipi',
                start: 'Yayın Başlangıç',
                end: 'Yayın Bitiş',
                active: 'Etkin',
                operation: 'İşlem',
                update: 'Güncelle',
                delete: 'Sil',
              },
              surprisePriceIdentificationAdd: {
                header: 'Ödeme Tipine Göre Bundle Tanımlama',
                payment: 'Ödeme Tipi',
                excel: "Excel'den yapıştırılacak ;",
                product: 'Ürün Kodu',
                unitPrice: 'Birim Fiyatı',
                money: 'Para Tipi',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                columns: 'Sütunları',
                untitled: 'başlıksız',
                enterInformation: 'Bilgileri girmek için kodu yazıp ',
                repeat: "'a basınız, diğer kodları eklemek için de aynı işlemi tekrar ediniz..",
                currentSubgroup: 'Cari Alt Grup Kodu',
                all: 'Hepsi',
                none: 'Hiçbiri',
                current: 'Cari Kodu',
                save: 'Kaydet',
              },
            },
            visualDocumentationCrud: {
              visualDocumentationAdd: {
                image: 'Ürün Resimi',
                video: 'Ürün Videosu',
                document: 'Ürün Dokümanı',
                link: 'Ürün Linki',
                header: 'Ürüne Yeni Görsel Ekleme',
                code: 'Ürün Kodu',
                type: 'Görsel Tipi',
                description: 'Açıklama',
                name: 'Görsel Adı',
                width: 'Genişlik (px)',
                height: 'Yükseklik (px)',
                notes: 'Notlar',
                active: 'Etkin',
                add: 'Ekle',
              },
              visualDocumentationList: {
                image: 'Ürün Resimi',
                video: 'Ürün Videosu',
                document: 'Ürün Dokümanı',
                link: 'Ürün Linki',
                code: 'Ürün Kodu',
                onAir: 'Yayında',
                inTheArchive: 'Arşivde',
                header: 'Ürüne Ait Görsel Güncelleme/Silme',
                subHeader: 'Süzme Koşulları',
                visual: 'Görsel',
                field: 'Saha',
                fieldValueCondition: 'Saha Değer/Koşul',
                filtre: 'Süz',
                add: 'Ekle',
                type: 'Görselin Tipi',
                name: 'Görselin Adı',
                description: 'Açıklama',
                notes: 'Notlar',
                active: 'Etkin',
                operation: 'İşlem',
                update: 'Güncelle',
                delete: 'Sil',
              },
            },
            visualTransferProcesses: {
              visualTransferProcesses: {
                product: 'Ürün',
                campaign: 'Kampanya',
                extra: 'Ürün Ekstra',
                logo: 'Cari Logosu',
                b2C: 'B2C Haberler',
                b2B: 'B2B Haberler',
                specialCase: 'B2C Özel Durum Açıklamaları',
                header: 'Görsel Aktarım İşlemleri',
                type: 'Görsel Tipi',
                overwrite: 'Üstüne Yaz',
                runtimeError: "Microsoft JScript runtime error '800a139e'",
                unable: 'Unable to write file to temp directory',
                atFunction: 'at function Ox34',
                line: 'Line 1',
                test: 'Deneme',
              },
            },
          },
        },
        myorder: {
          orderCancelResultMessage: 'Siparişiniz İptal Edilmiştir.',
          orderCancelConfirmMessage: 'Siparişi iptal etmek istediğinize emin misiniz?',
        },
        productdetail: {
          userComments: 'Kullanıcı Yorumları',
          commentOnThisProduct: 'Bu ürün hakkında yorumda bulunun',
          accordingToComments: 'Yorumlara göre',
          byLastView: 'Son görüntülenmeye göre',
          basedOnMostViewedComments: 'En çok görüntelenen yorumlara göre',
          fromThePerspectiveOfTheBuyer: 'Alıcı bakışına göre',
          IrecommendBuyingThisProduct: 'Bu ürünü almayı öneriyorum',
          IsThisReviewHelpful: 'Bu yorum faydalı mı ?',
          ProductReview: 'Ürün Yorumu',
          ShareYourCommentsWithUs: 'Yorumlarınızı Bizimle Paylaşın',
          ProductDescriptionAndFeaturesAreSufficient: 'Ürün açıklaması ve özellikleri yeterli',
          TheProductPriceIsHigherThanTheMarket: 'Ürün fiyatı piyasadan daha fazla',
          CampaignsCanBeMadeSpecificToTheProduct: 'Kampanyalar ürüne özel yapılabilir',
          ProductDescriptionAndFeaturesAreWrong: 'Ürün açıklaması ve özellikleri yanlış',
          TargetPrice: 'Hedef Fiyat',
          Number: 'Sayı',
          youCanShareItWithUs: 'Index Pazar ile ilgili düşünceniz varsa, bizimle paylaşabilirsiniz.',
          peopleRecommendThisProduct: 'Bu ürünü alan 320 Kişi öneriyor',
          indexgroupDigitalProducts: 'Index Grup /',
          Recommends: 'Öneriyor',
          BuyersOfThisProduct: 'Bu ürünü alanların',
          Review: 'İnceleme',
          Question: 'Soru',
          Answer: 'Cevap',
          Color: 'Renk',
          Gray: 'Gri',
          ProductFeatures: 'Ürün Özellikleri',
          ProcessorType: 'İşlemci türü',
          Capacity: 'Kapasitesi',
          Type: 'Tipi',
          Undefined: 'Tanımlanmamış',
          ProcessorModel: 'İşlemci Modeli',
          ForMore: 'Daha Fazlası İçin',
          allInformation:
            'Bu sitedeki tüm bilgiler sadece genel bilgilendirme amaçlıdır.Bu siteden ulaşılan bilgilerin tam ve güncel olduğu garantisini vermez. Bu bilgiler tavsiye amaçlı değildir. Kullanılan resimler ve yazılar Index Bilgisayar Tic. şirketine aittir. Her Hakkı Saklıdır.',
          GuaranteeOriginality: 'Orjinalliği Garanti Edin',
          CashPayment: 'Finansal Çözüm',
          ProductTracking: 'Ürün Takibi',
          SalesSupport: 'Satış Desteği',
          FreeShipping: 'Ücretsiz Kargo',
          FastShipping: 'Hızlı Gönderi',
          WouldYouLikeToBecomeAMember: 'Üye Olmak İster misiniz ?',
          FreeDeliveryOnOrders: 'Siparişlerde Ücretsiz Teslimat',
          FirstDeliveryFreeForNewUsers: 'Yeni Kullanıcılara Özel İlk Teslimat Ücretsiz',
          SpecialCampaignsForMe: 'Bana Özel Kampanyalar',
          RelatedProducts: 'İlişkili Ürünler',
          Model: 'Model',
          noProduct: 'ÜRÜN YOK',
          Reviews: 'İncelemeler',
          TechnicialSpecifications: 'Teknik Özellikler',
          Comments: 'Yorumlar',
          Feedback: 'Geri Dönüşler',
          SeeOtherAnswers: 'Diğer Cevapları Gör',
          MyQuestions: 'Sorularım',
          AskQuestionsAboutThisProduct: 'Bu ürün hakkında sorularınızı sorun',
          SortByQuestions: 'Sorulara Göre Sırala',
          RecentQuestions: 'Son Sorular',
          MostHelpfulAnswers: 'En Faydalı Cevaplar',
          More: 'Daha Fazla',
          ProductInformation: 'Ürün Bilgisi',
          ProductCode: 'Ürün Kodu',
          monthsGuarantee: '18 Ay Garanti',
          WithinOneWorkingDay: 'Bir iş günü içerisinde',
          SentByTheGroup: 'Group tarafından gönderilir',
          Seller: 'Satıcı',
          BestPricesIn30Days: '30 gün içindeki en iyi fiyatlar',
          Prices: 'Fiyatlar',
          SpecialForYou: 'Size Özel',
          ListPrice: 'Liste Fiyatı',
          TeskPrice: 'Tesk Fiyatı',
          Included: 'Dahil',
          Stock: 'Stok',
          OtherSellers: 'Diğer Satıcılar',
          Market: 'Pazar',
          Weight: 'Ağırlık',
          Dimensions: 'Ölçüler',
          CPUManufacturer: 'CPU Üreticisi',
          ProcessorSeries: 'İşlemci Serisi',
          Cache: 'Ön Bellek',
          InternalMemoryType: 'Dahili Hafıza Tipi',
          companies: 'Şifre Tanımlanacak Şirketler',
          authorities: 'Yetkiler',
          CPUSpeedRange: 'CPU Hız Aralığı',
          SpecialPrivilegesForIndexGroupMembers: 'IndexGroup Üyelerine Özel Ayrıcalıklar',
          OutletClick1: 'FIRSAT PAZARI’nda',
          OutletClick2: '${0} Tıklayınız!',
          BankOptions: 'Banka Taksit Seçenekleri',
          ScreenSize: 'Ekran Boyutu',
          ContractedBanks: 'Anlaşmalı Bankalar',
          AllLoans: 'Tüm Vadeleri Görüntüle',
          ToSpecialPrice: 'Size Özel Fiyatına Göre',
          ToBranchPrice: 'Liste Fiyatına Göre',
          ToListPrice: 'TESK Fiyatına Göre',
          LoanQty: 'Taksit',
          Total: 'Tutar',
          PaymentMethods: 'Ödeme Seçenekleri',
        },
        productList: {
          availableInStock: 'Stokta Olan Ürünler',
          addInBulk: 'Toplu Ekle',
          excel: 'Excel',
          compare: 'Karşılaştır',
          order: 'Sıralama',
          orderByPriceHighToLow: 'Fiyata Göre Azalan',
          orderByPriceLowToHigh: 'Fiyata Göre Artan',
          mostViewed: 'En Çok İncelenenler',
          popularOnes: 'Popüler Olanlar',
          theNewests: 'En Yeniler',
          theCheapestOnes: 'En Ucuz Olanlar',
          productView: 'Ürün Görünümü',
          noProductsFoundBySearchCriteria: 'Aradığınız kriterlere uygun ürün bulunamamıştır.',
          category: 'Kategori',
          searchInResults: 'Sonuçlar İçinde Ara',
          showOnlyAvailableInStock: 'Stokta Olan Ürünler',
          price: 'Fiyat',
          seller: 'Satıcı',
          brand: 'Marka',
          addToCart: 'Toplu Sepete At',
        },
        about: {
          aboutUs: {
            corporateInformation: 'Kurumsal Bilgiler',
            transportation: 'Ulaşım',
            contactForm: 'İletişim Formu',
            mission: 'MİSYON',
            vision: 'VİZYON',
            history: 'TARİHÇE',
            centerOffice: 'MERKEZ OFİS',
            logisticsCenter: 'LOJİSTİK MERKEZİ',
            address: 'Adres',
            phone: 'Telefon',
            fax: 'Faks',
            email: 'E-posta',
            taxAdministration: 'Vergi Dairesi',
            taxNumber: 'Vergi No',
            branch: 'ŞUBE',
            map: 'HARİTA',
            description:
              'Firmamızla veya Sitemizle ilgili mesaj ve önerilerinizi bekliyoruz lütfen aşağıdaki formu doldurup düşüncelerinizi bizimle paylaşın.',
            communicationsDepartment: 'İletişim Departmanı',
            support: 'Destek',
            salesSupport: 'Satış Destek',
            myCurrentAccount: 'Cari Hesabım',
            name: 'Adınız',
            companyCode: 'Firma Kodu',
            yourCompany: 'Firmanız',
            message: 'Mesaj',
            notes: '*Lütfen, Adınızı ve elektronik posta adresinizi yazmayı unutmayınız.',
            send: 'Formu Gönder',
            reset: 'Formu Temizle',
            tradeRegistryInformation: 'TİCARET SİCİL BİLGİLERİ',
            registrationDate: 'Tescil Tarihi',
            tradeRegistryOffice: 'Ticaret Sicil Memurluğu',
            registrationNumber: 'Sicil Numarası',
            emailAdress: 'E-arşiv Email Adresimiz:',
            central: 'Santral',
            callCenter: 'Çağrı Merkezi',
            value: 'DEĞER',
          },
        },
        openbox: {
          status: 'Durum',
          startDate: 'Başlangıç Tarihi',
          endDate: 'Bitiş Tarihi',
          subject: 'Konu',
          header: 'AÇBOX',
          show: 'Göster',
        },
        survey: {
          desc: 'Görüşleriniz bizim için değerli. Sitelerimizi sizin için daha verimli ve kullanışlı hale getirebilmek için lütfen görüşlerinizi bizimle paylaşın.',
          title: 'Anket',
        },
        mySpecial: {
          slider: {
            accountInfo: 'Hesap Yönetimi',
            informationUpdate: 'Bilgi Güncelleme',
            changePassword: 'Şifre Değiştir',
            addNewUser: 'Yeni Kullanıcı Ekle',
            userUpdate: 'Kullanıcı Güncelleme',
            myOrders: 'Siparişlerim',
            overseasOrderTracking: 'Yurtdışı Sipariş Takibi',
            myReturns: 'İptal/İadelerim',
            myPayments: 'Ödemelerim',
            myOffers: 'Tekliflerim',
            myReports: 'Raporlarım',
            support: 'Destek',
            ssh: 'Satış Sonrası Destek',
            warrantyTracking: 'Garanti Takibi',
            sshProcedures: 'SSH Prosedürleri',
            faultTracking: 'Arıza Takibi',
            serialNumberCheck: 'Seri No Kontrolü',
            sshContactForm: 'SSH İletişim Formu',
            authorizedServicePoints: 'Yetkili Hizmet Noktaları',
            sss: 'Sıkça Sorulan Sorular',
            accountSummaryTable: 'Hesap Özet Tablosu',
            openInvoices: 'Açık Faturalar',
            electronicInvoices: 'Elektronik Faturalar',
            periodicAndDetailedTurnoverReport: 'Dönemsel ve Ayrıntılı Ciro Raporu',
            myCreditCardTransactions: 'Kredi Kartı Hareketlerim',
            imeiSerialNumber: 'Imei/Seri No Sorgulama',
            myCurrentAccount: 'Cari Hesabım',
            currentAccountReconciliation: 'Cari Hesap Mutabakatı',
            currentAccountStatement: 'Cari Hesap Ekstresi',
            letterOfGuarantee: 'Teminat Mektubu',
            myAddresses: 'Adreslerim',
            myFavorites: 'Favorilerim',
            xmlSupport: 'XML Destek',
            webinarCalendar: 'Webinar Takvimi',
            licenseTrackingNotificationRenewal: 'Lisans Takip – Bilgilendirme – Yenileme',
            dealerSpecificPromotionalCampaigns: 'Bayiye Özel Promosyon',
            dealerChannelPrograms: 'Bayi Kanal Programları',
            phone: 'Telefon',
          },
          CurrentAccount: {
            Consensus: {
              addConsensus: {
                header: 'Mutabakat Raporu ',
                sendingcompanyname: 'Firma Adı',
                category: 'Kategori',
                company: 'Şirket',
                add: 'Ekle',
                TaxAdministration: 'Vergi Dairesi',
                TaxNumber: 'Vergi No',
                consensus: 'Mutabıkız',
                noconsensus: 'Mutabık Değiliz',
              },
              listConsensus: {
                header: 'Cari Hesap Mutabakatı',
                addconsensus: 'Mutabakat Yap',
                balance: 'Bakiyesi',
                overduereceivable: 'Vadesi Geçmiş Alacak',
                creditlimit: 'Kredi Limiti',
                availablecreditlimit: 'Kullanılabilir Kredi Limiti',
                company: 'Şirket',
                category: 'Kategori',
                balances: 'Bakiyesi',
                vga: '(VGA) ',
                sendCompany: 'Gönderen Firma',
                interlocutor: 'Muhatap Firma',
              },
            },
          },

          addNewUser: {
            index: {
              active: 'Aktif',
              passive: 'Pasif',
              swalTitle: 'Kullanıcı güncellendi',
              newUserAdded: 'Yeni kullanıcı eklendi',
              updateUser: 'Kullanıcı Güncelleme',
              addingNewUser: 'Yeni Kullanıcı Ekleme',
              item1: 'İstediğiniz hakkın atanması için, karşısındaki kutuyu işaretleyiniz.',
              item2: 'Kullanıcı Şifresi en az 6 karakterden oluşmalıdır.',
              item3: 'Sipariş Verme yetkisi tanınmış kullanıcı, Sipariş Hazırlama yetkisine de sahip olmaktadır.',
              accountCode: 'Hesap Kodu',
              userName: 'Kullanıcı Adı',
              password: 'Şifre',
              passwordConfirm: 'Şifre Onay',
              status: 'Durum',
              email: 'E-Posta',
              notes: 'Notlar',
              isTheMostAuthoritativeAdministrator: 'En yetkili/yöneticidir',
              canMonitorSalesAndOrderMovements: 'Satış ve Sipariş hareketleri izleyebilir',
              canOrder: 'Sipariş verebilir',
              canMakeFinancialTransactions: 'Finansal işlem yapabilir',
              canMonitorFinancialAndCurrentMovements: 'Finansal ve Cari hareketleri izleyebilir',
              dontSeeSpecialPrice: 'Özel Fiyat görmesin',
              specialPrice: 'Özel Fiyat',
              update: 'Güncelle',
              save: 'Kaydet',
            },
            validation: {
              requiredField: 'Zorunlu alan.',
              message: 'en az 3 karakter olmalıdır.',
              userCode: 'Hesap kodu',
              name: 'İsim',
              surname: 'Soy isim',
              email: 'Geçersiz mail adresi',
              gsm: 'GSM doğru formatta girilmedi',
              userName: 'Kullanıcı adı',
              password: 'Şifreniz en az 6 karakter olmalıdır.',
              passwordConfirm: 'Girilen şifreler aynı olmalıdır.',
              file: 'Bir dosya gerekli',
            },
          },
          address: {
            index: {
              newAddressAdded: 'Yeni adres eklendi',
              addressNotAdded: 'Adres Eklenmedi',
              addressUpdated: 'Adres Güncellendi',
              addressNotUpdated: 'Adres Güncellenmedi',
              deleteSwal: 'Adresi silmek istediğinize emin misiniz?',
              addressDeleted: 'Adres Silindi',
              addressNotDeleted: 'Adres Silinmedi',
              swalTitle: 'Adres pasife alınacak yerine yenisi eklenecek. Onaylıyor musunuz?',
              yes: 'Evet',
              no: 'Hayır',
              cancel: 'İptal',
              addAddress: 'Adres Ekle',
              identificationNumber: 'TC Kimlik No',
              name: 'Ad',
              surname: 'Soyad',
              city: 'Şehir',
              district: 'İlçe',
              neighborhood: 'Mahalle',
              postCode: 'Posta Kodu',
              phone: 'Telefon',
              address: 'Adres',
              transactions: 'İşlemler',
              edit: 'Düzenle',
              delete: 'Sil',
              save: 'Kaydet',
              update: 'Güncelle',
            },
            validation: {
              requiredField: 'Zorunlu alan.',
              message: 'en ${0} 11 haneli olmalı',
            },
          },
          authorities: {
            customerRepresentatives: {
              nameSurname: 'Adı Soyadı',
              phone: 'Telefon',
              email: 'E-posta',
            },
            index: {
              nameSurname: 'Adı Soyadı',
              mission: 'Görevi',
              email: 'E-posta',
            },
          },
          header: {
            index: {
              accountCode: 'Hesap Kodu',
              creditLimit: 'Kredi Limiti',
            },

            contact: {
              billingAddress: 'Fatura Adresi',
              phone: 'Telefon',
              fax: 'Faks',
              taxAdministration: 'Vergi Dairesi',
              authorities: 'Yetkililer',
              customerRepresentatives: 'Müşteri Temsilcileri',
            },
          },
          myFavorite: {
            components: {
              productCard1: {
                new: 'Yeni',
                productCode: 'Ürün Kodu',
                listPrice: 'Liste Fiyatı',
                endUserPrice: 'Son kullanıcı fiyatı',
                specialForYou: 'Size Özel',
                stock: 'Stok',
                number: 'Adet',
              },
              productCard2: {
                new: 'Yeni',
                productCode: 'Ürün Kodu',
                listPrice: 'Liste Fiyatı',
                endUserPrice: 'Son Kullanıcı Fiyatı',
                specialForYou: 'Size Özel',
                stock: 'Stok',
              },
              productCard3: {
                new: 'Yeni',
                productCode: 'Ürün Kodu',
                listPrice: 'Liste Fiyatı',
                endUserPrice: 'Son Kullanıcı Fiyatı',
                specialForYou: 'Size Özel',
                stock: 'Stok',
              },
            },
            index: {
              myFavorites: 'Favorilerim',
              indexGroupStore: 'Index Group Mağazası',
              productView: 'Ürün Görünümü',
              first: 'Birinci',
              second: 'İkinci',
              third: 'Üçüncü',
              youCurrentlyDoNotHaveAFavoriteProduct: 'Şuan favori olan ürününüz yoktur.',
            },
          },
          myOrders: {
            components: {
              myOrders: {
                prepared: 'Hazırlanan',
                inCargo: 'Kargoda',
                recentHistory: 'Yakın Tarih',
                distantHistory: 'Uzak Tarih',
                orderCancelResultMessage: 'Siparişiniz İptal Edilmiştir.',
                orderCancelConfirmMessage: 'Siparişi iptal etmek istediğinize emin misiniz?',
                yes: 'Evet',
                no: 'Hayır',
                searchOrder: 'Sipariş Ara...',
                myOrder: 'Siparişim',
                myRankNumber: 'Sipariş Numaram',
                orderAcceptance: 'Sipariş Kabul',
                selectMarketPlace: 'Market Yeri Seç',
                sortByDate: 'Tarihe Göre Sırala',
                lastByDay: 'Son ... Güne Göre',
                startDate: 'Başlangıç Tarihi',
                endDate: 'Bitiş Tarihi',
                time: 'Saat:',
                companyName: 'Şirket Adı',
                productBrand: 'Ürün Markası',
                orderStatus: 'Sipariş Durumu',
                email: 'E-posta',
                print: 'Yazdır',
                list: 'Listele',
                sendToExcel: "Excel'e Gönder",
                marketPlace: 'Market Yeri',
                cancellations: 'İptal Edilenler',
                returns: 'İadeler',
                orderDate: 'Sipariş Tarihi',
                orderSummary: 'Sipariş Özeti',
                user: 'Kullanıcı',
                total: 'Tutar',
                cancelOrder: 'Siparişi İptal Et',
                cardNumber: 'Kart Numarası',
                orderNumber: 'Sipariş Numarası',
                invoiceNumber: 'Fatura Numarası',
                eInvoiceNumber: 'E-Fatura Numarası',
                shippingType: 'Sevk Türü',
                paymentMethod: 'Ödeme Şekli',
                vatRate: 'KDV Oranı',
                currencyUnit: 'Para Birimi',
                deliveryNo: 'Teslimat No',
                shippingCompany: 'Kargo Şirketi',
              },
            },
          },
          userUpdate: {
            accountCode: 'Hesap Kodu',
            userName: 'Kullanıcı Adı',
            email: 'E-Posta',
            status: 'Durum',
            notes: 'Notlar',
            isMostAuthorized: 'En yetkili/yöneticidir',
            isMonitorSalesAndOrder: 'Satış ve Sipariş hareketleri izleyebilir',
            isOrder: 'Sipariş verebilir',
            isMakeFinancialTransactions: 'Finansal işlem yapabilir',
            isMonitorFinancialAndCurrentMovements: 'Finansal ve Cari hareketleri izleyebilir',
            isSeeSpecialPrice: 'Özel Fiyat Görmesin',
            specialPriceRate: 'Özel Fiyat Oranı',
            type: 'Tip',
            operations: 'İşlemler',
            edit: 'Güncelle',
            delete: 'Sil',
            header: 'Kullanıcı Güncelleme',
          },
          warrantyTracking: {
            serialNumber: 'Seri Numarası',
            search: 'Ara',
            description:
              "'Seri Numarası' bölümüne sorgulamak istediğiniz ürünün seri numarasını girerek ürünün garanti süresini öğrenebilirsiniz.",
          },
          sshProcedures: {
            authorizedServiceProcedures: 'YETKİLİ SERVİS PROSEDÜRLERİ',
            authorizedServicePoints: 'Yetkili Servis Noktaları',
            description: 'Tüm Yetkili Servis Listesini görmek için tıklayın.',
            warrantyProcessesBrands: 'MARKALARIN GARANTİ SÜREÇLERİ',
          },
          faultTracking: {
            dateRange: 'Tarih Aralığı',
            searchCriteria: 'Arama Kriterleri',
            closedEntries: 'Kapanmış Kayıtları da Listele',
            search: 'Ara',
          },
          serialNumberCheck: {
            serialNumber: 'Seri Numarası:',
            okey: 'Tamam',
            description:
              'Sorgulamak istediğiniz ürünün Seri Numarasını veya Fatura Numarasını girerek ürünün garanti süresini öğrenebilirsiniz.',
            IDXInvoiceNumber: 'IDX Fatura Numarası:',
            InvoiceNumber: 'Fatura Numarası:',
          },
          sshContactForm: {
            topic: 'Konu',
            eposta: 'E-posta',
            message: 'İletiniz',
            send: 'Gönder',
          },
          authorizedServicePoints: {
            authorizedServicesBrands: 'MARKALARIN YETKİLİ SERVİSLERİ',
          },
        },
      },
      layouts: {
        client: {
          supportPhone: 'Destek Telefonu:',
          goUpstairs: 'Yukarı Çık',
          guaranteeOriginality: 'Güvenli Alışveriş',
          cashPayment: 'Finansal Çözüm',
          productTracking: 'Ürün Takibi',
          salesSupport: 'Satış Desteği',
          withIndexGroup: 'INDEX GRUP ',
          indexGroupNewsroom: 'Index Grup ',
          careerOpportunities: 'Index AŞ ',
          contactIndexGroup: 'Datagate AŞ',
          aboutIndexGroup: 'Despec AŞ ',
          netex: 'Netex AŞ ',
          teklos: 'Teklos AŞ ',
          customerService: 'Müşteri Hizmetleri',
          commonQuestions: 'Yaygın sorular',
          returnedProducts: 'İade Ürünleri',
          termsOfUse: 'Kullanım Şartları',
          privacy: 'Gizlilik',
          reportError: 'Hata bildir',
          shoppingGuideFrom: 'İLETİŞİM',
          howToOrder: 'Nasıl sipariş verilir?',
          orderDeliveryProcedure: 'Sipariş teslim prosedürü',
          paymentMethods: 'Ödeme Yöntemleri',
          beInformedAboutTheLatestDiscounts: 'Son indirimlerden haberdar ol',
          enterYourEmail: 'E-postanızı giriniz...',
          send: 'Gönder',
          myCard: 'Sepetim',
          managementpanel: 'Yönetim Paneli',
          myOrders: 'Siparişlerim',
          specialForMe: 'Bana Özel',
          logout: 'Çıkış',
          menu: 'Menü',
          yourSearchHistory: 'Arama Geçmişiniz',
          mostRecentSearches: 'En Son Aramalar',
          survey: 'Anket',
          myfavorites: 'Favorilerim',
        },
        admin: {
          myProfile: 'Profilim',
          settings: 'Ayarlar',
          goToTheSite: 'Siteye git',
          logout: 'Çıkış',
          search: 'Ara...',
          addFavorite: 'FAVORİ EKLE',
          home: 'ANASAYFA',
        },
      },
    },
  },
  en: {
    translation: {},
  },
  it: {
    translation: {},
  },
}

export default resources
