import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IRolePermissionModel } from '../models'

export class AddRolePermissionsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/userPermission/AddRolePermissionList',
      requesterInfo,
    })
  }

  public async addRolePermissions(roleId: number, request: IRolePermissionModel[]): Promise<any[]> {
    this.props.url = `${this.props.url}/${roleId}`
    return await this.postAsync<any[]>(request)
  }
}
