import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IFrequentlyAskedQuestions } from '../models/models'

export class GetFrequentlyAskedQuestionsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/ssh/listAllQuestionAndAnswer',
      requesterInfo,
    })
  }

  public async getFrequentlyAskedQuestions(): Promise<IFrequentlyAskedQuestions[]> {
    return await this.getAsync<IFrequentlyAskedQuestions[]>()
  }
}
