import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { EInvoicesRequest } from '../models/eInvioices'

export class ElectronicInvoicesToExcelApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'excel/electronicInvoices',
      requesterInfo,
    })
  }

  public async electronicInvoicesToExcel(request: EInvoicesRequest): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
