import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

function formatDate(date) {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [day, month].join('/')
}

export default function ChartBar2(props) {
  const labels = props.data ? props.data.map(item => formatDate(item.DateValue)) : []

  const labelsPerUserData = props.perUserData ? props.perUserData.map(item => formatDate(item.DateValue)) : []

  const labelsSessionPost = props.sessionPost ? props.sessionPost.map(item => formatDate(item.DateValue)) : []

  const labelsTraffic = props.dateValues ? props.dateValues.map(item => formatDate(item.DateValue)) : []

  const data = props.data ? props.data.map(item => Number((item.Value2 / 60).toFixed(2))) : []

  const perUserDatas = props.perUserData ? props.perUserData.map(item => Number((+item.Value).toFixed(2))) : []
  const sessionPost = props.sessionPost ? props.sessionPost.map(item => Math.floor(item.Value)) : []
  const trafficSource = props.trafficSource ? props.trafficSource.map(item => Math.floor(item.Value)) : []
  const avgSessionsDatas = {
    labels,
    datasets: [
      {
        label: 'Ortalama Oturum Süresi',
        fill: true,
        lineTension: 0.3,
        backgroundColor: 'rgba(225, 204,230, .1)',
        borderColor: 'rgb(205, 130, 158)',
        // borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        // borderJoinStyle: 'miter',
        pointBorderColor: 'rgb(205, 130, 158)',
        pointBackgroundColor: 'rgb(255, 0, 0)',
        pointBorderWidth: 10,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(0, 0, 0)',
        pointHoverBorderColor: 'rgba(220, 220, 220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data,
      },
    ],
  }
  const userSessionDatas = {
    labels: labelsPerUserData,
    datasets: [
      {
        label: 'Kullanıcı Başına Oturum Sayısı',
        fill: true,
        lineTension: 0.3,
        backgroundColor: 'rgba(239, 134, 119, .1)',
        borderColor: 'rgb(239, 134, 119)',
        // borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        // borderJoinStyle: 'miter',
        pointBorderColor: 'rgb(239, 134, 119)',
        pointBackgroundColor: 'rgb(255, 0, 0)',
        pointBorderWidth: 10,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(239, 134, 119)',
        pointHoverBorderColor: 'rgba(220, 220, 220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: perUserDatas,
        // data: [896, 745, 1348, 999, 1659, 634, 813, 1852],
      },
    ],
  }
  const sessionDatas = {
    labels: labelsSessionPost,
    datasets: [
      {
        label: 'Oturum',
        fill: true,
        lineTension: 0.3,
        backgroundColor: 'rgba(160, 231, 125, .1)',
        borderColor: 'rgb(160, 231, 125)',
        // borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        // borderJoinStyle: 'miter',
        pointBorderColor: 'rgb(160, 231, 125)',
        pointBackgroundColor: 'rgb(255, 0, 0)',
        pointBorderWidth: 10,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgb(160, 231, 125)',
        pointHoverBorderColor: 'rgba(220, 220, 220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: sessionPost,
        // data: [1232, 257, 852, 653, 753, 648, 1062, 2023],
      },
    ],
  }

  const labelDataControl: any = []

  // const labelData = trafficSource.filter((element) => {
  //     console.log("element",element.Value);

  //     const isDublicate = trafficSource.includes(element.Value);
  //     if (!isDublicate) {
  //       labelDataControl.push(element.Value[1]);
  //       return true;
  //     }
  //     return false;
  //   });
  //console.log("label", labelData);

  const trafficDatas = {
    labels: labelsTraffic,
    datasets: props.trafficSource,
  }

  // useEffect(() => {
  //   if (props.trafficSource) {
  //     setTrafficData({
  //       labels: props.trafficSource ? props.trafficSource.map((item) => formatDate(item.DateValue)) : [],
  //       datasets: props.trafficSource,
  //     });
  //   }
  // }, []);

  return (
    <div>
      {props.type === 'Analytic' && (
        <div className='row my-5  text-center'>
          <div className='col-md-6'>
            <h4>
              Ortalama Oturum Süresi <span style={{ color: '#777' }}>(Dakika)</span>
            </h4>
            <Line data={avgSessionsDatas} options={{ responsive: true }} />
          </div>
          <div className='col-md-6'>
            <h4>
              Kullanıcı Başına Oturum Sayısı <span style={{ color: '#777' }}>(Adet)</span>
            </h4>
            <Line data={userSessionDatas} options={{ responsive: true }} />
          </div>
        </div>
      )}
      <div className='row text-center'>
        {props.type === 'Analytic' && (
          <div className='col-md-6'>
            <h4>
              Oturum
              <span style={{ color: '#777' }}>(Adet)</span>
            </h4>
            <Line data={sessionDatas} options={{ responsive: true }} />
          </div>
        )}
        <div
          className={props.type === 'Analytic' ? 'col-md-6' : 'col-md-12'}
          style={{ height: props.mainChartHeight ? props.mainChartHeight : '50vh' }}
        >
          {props.type === 'Analytic' ? (
            <h5>
              Trafik
              <span style={{ color: '#777' }}>(Kaynak/Araç)</span>
            </h5>
          ) : props.type === 'Dashboard' ? (
            <h5 className='mt-5'></h5>
          ) : (
            <h5>Sipariş Adetlerim/Tutarlarım – Bu Yıl</h5>
          )}
          <Line
            data={trafficDatas}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
          />
          {/* {
              responsive: true,
              scales: {
                y: {
                  max: 500,
                  min: 0,
                  ticks: {
                    stepSize: 0.5,
                  },
                },
              },
            } */}
        </div>
      </div>
    </div>
  )
}
