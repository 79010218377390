import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Swal from 'sweetalert2'
import IPButton from '../../../../../components/IPButton'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPCheckbox from '../../../../../components/IPCheckBox'
import InputCampaign from './DealerChannelInput'
import CampaignSelect from './DealerChannelSelect'
import { useCustomReducer } from '../../../../../base/customHooks'
import { uuidv4 } from '../../../../../base/utils'
import { DealerChannelModel, DealerType } from './../models/DealerChannelModel'
import { DealerChannelService } from '../services/DealerChannelService'
import { DealerService } from '../services/DealerService'
import './DealerChannel.scss'
import IPFile from '../../../../../components/IPFile'
import { excelToJSON, FormatDealerCodeEnum } from '../../../../../base/utils/dataHelper'
import { GetCompanyApi } from '../../../components/services/getCompanyApi'
import { DealerChannelResponse } from '../models/getDealerChannelResponseModel'
import IPTextarea from '../../../../../components/IPTextarea'
import { getCategoryList, getSubTypeApi } from '../../../components/api'
import { setDealerCode } from '../../../../../base/utils/dataHelper'
import IPDatePicker from '../../../../../components/IPDatePicker'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { GetPoductSubTypeListApi } from '../services/getPoductSubTypeListApi'
import { ICategoryList } from '../../../components/models/models'

enum CariListType {
  CariCode,
  CariType,
  CariAltType,
}
interface DealerChannelCondition {
  Id: number
  ProgramBundleId: number
  CompanyCodes: ISelectOption
  XProductCodeList: string[]
  XQuotaCodeList: string[]
  XGroupList: string[]
  XBrandList: string[]
  XProductSubTypeList: string[]
  IsProductCodeList: boolean
  IsProductQuotaCodeList: boolean
  IsProductGroupList: boolean
  IsProductBrandList: boolean
  IsProductSubTypeList: boolean
  IsQuantitiy: boolean
  IsPrice: boolean
  TargetQuantity: number
  TargetPrice: number
  CurrencyType: string
  ProgramDetailDescription: string
  Deleted: boolean
  subTypes: string[]
}

interface ICampaignBasketConditions {
  Id: any
  CampaignBundleId: number
  XProductCodeList: string[]
  XQuotaCodeList: string[]
  XGroupList: string[]
  XBrandList: string[]
  XProductSubTypeList: string[]
  ProductSubTypeList: string
  IsProductCodeList: boolean
  IsProductQuotaCodeList: boolean
  IsProductGroupList: boolean
  IsProductBrandList: boolean
  IsProductSubTypeList: boolean
  IsQuantity: boolean
  IsPrice: boolean
  BasketOptions: number
  CurrencyType: number
  TotalCondition: number
  TargetQuantity: number
  TargetPrice: number
  ProgramDetailDescription: string
  Deleted: boolean
}
interface ICampaignProductCondition {
  Id: number | string
  CampaignBundleId: number
  ProductId: number
  ProductCode: ISelectOption
  ResultOperation: ISelectOption
  Quantity: number
  MaxQuantity: number
  AmountOrRatio: number
  CurrencyType: ISelectOption
}
export interface ICampaignState {
  showModal: boolean
  selectedDealerList?: CariListType
  isProductCodesInclude?: boolean
  isQuotaCodesInclude?: boolean
  isGroupCodesInclude?: boolean
  isBrandsInclude?: boolean
  isSubTypesInclude?: boolean
  productCodeList?: ISelectOption[]
  QuotaCodeList?: ISelectOption[]
  GroupList?: ISelectOption[]
  BrandList?: ISelectOption[]
  ProductSubTypeList?: ISelectOption[]
  isQuantity: boolean
  isPrice: boolean
  targetQuantity: number
  totalCondition: ISelectOption
  targetPrice: string
  currencyType: ISelectOption
  programDetailDescription: string
  //dealerCodeOptions: ISelectOption[]
  dealerTypeOptions: ISelectOption[]
  basketOptions: ISelectOption
  isNewRecord: boolean
  dealerChannelConditions: ICampaignBasketConditions[]
  selectedBasketConditionId: string
  resultOperation?: number
  productConditionQuantity?: number
  productConditionMaxQuantity?: number
  productContionAmountOrRatio?: number
  productConditionCurrencyType?: number
  productCodeOptions?: ISelectOption[]
  quotaCodeOptions?: string[]
  brandListOptions?: string[]
  companyListOptions: ISelectOption[]
  companyList: ISelectOption[]
  channelData?: DealerChannelResponse
  buttonChange: string
  subTypes: string[]
  XDealerCodeList: ISelectOption[]
  XDealerTypeList: ISelectOption[]
  XDealerSubTypeList: ISelectOption[]
  buttonName: string
  categoryList: ISelectOption[]
}
function DealerChannel(props: BaseComponentWithContextProps) {
  const [productSubType, setProductSubType] = useState<ISelectOption[]>([])
  const getProductSubTypes = async () => {
    const getPoductSubTypeList: GetPoductSubTypeListApi = new GetPoductSubTypeListApi({})
    const res = await getPoductSubTypeList.getPoductSubTypeList()
    if (res) {
      const dealerSubTypeOptions: ISelectOption[] = []
      res.forEach((item: any) => {
        item && dealerSubTypeOptions.push({ value: item, label: item })
      })
      setProductSubType(dealerSubTypeOptions)
    }
  }
  let navigate = useNavigate()
  const location = useLocation()
  const routeLocation = useLocation()

  const [dealerChannelConditions, setDealerChannelConditions] = useState<DealerChannelCondition[]>([])
  const [campaignProductConditions, setCampaignProductConditions] = useState<ICampaignProductCondition[]>([
    {
      Id: 0,
      CampaignBundleId: 0,
      ProductId: 0,
      ProductCode: { label: '0', value: '0' },
      ResultOperation: { label: 'Sabit Fiyat', value: 1 },
      Quantity: 0,
      MaxQuantity: 0,
      AmountOrRatio: 0,
      CurrencyType: { label: 'TRY', value: 'TRY' },
    },
  ])

  const getInitialState = (): ICampaignState => {
    return {
      showModal: false,
      isNewRecord: false,
      selectedDealerList: CariListType.CariCode,
      isProductCodesInclude: true,
      isQuotaCodesInclude: true,
      isGroupCodesInclude: true,
      isBrandsInclude: true,
      isSubTypesInclude: true,
      productCodeList: [],
      isQuantity: true,
      isPrice: false,
      targetQuantity: 0,
      targetPrice: '',
      programDetailDescription: '',
      totalCondition: { label: 'Hayır', value: 'H' },
      currencyType: { label: 'TRY', value: 'TRY' },
      basketOptions: { label: 'Sepet', value: 1 },
      dealerChannelConditions: [],
      selectedBasketConditionId: '',
      buttonChange: 'Kaydet',
      companyListOptions: [],
      companyList: [],
      subTypes: [],
      //dealerCodeOptions: [],
      dealerTypeOptions: [],
      XDealerCodeList: [],
      XDealerTypeList: [],
      XDealerSubTypeList: [],
      buttonName: "Kaydet",
      categoryList: []
    }
  }
  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ICampaignState>(intialState)

  const enumFunction = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }

  const validationSchema = yup.object().shape({
    BundleCode: yup.string().required('Lütfen kampanya kodunu girin!'),
    ProgramDescription: yup.string().required('Lütfen açıklamayı girin!'),
    ProductDetailDescription: yup
      .string()
      .nullable()
      .required('Lütfen açıklama girin!')
      .max(50, 'Karakter sınırı 50 yi geçmemeli'),
  })

  const sweetAlert = (icon: 'error' | 'success', title: string, message: string) => {
    return Swal.fire({
      icon,
      title,
      html: `<div style='font-size: 17px'>${message}</div>`,
      showConfirmButton: true,
      allowOutsideClick: false,
    })
  }

  const { handleSubmit, handleChange, handleBlur, setFieldValue, setValues, resetForm, values, errors, touched } =
    useFormik({
      initialValues: {
        Id: 0,
        BundleCode: '',
        ProgramDescription: '',
        ProgramDetailDescription: '',
        XDealerCodeList: [],
        XDealerTypeList: [],
        XDealerSubTypeList: [],
        IsDealerListInclude: true,
        IsDealerSubTypeInclude: true,
        IsDealerTypeInclude: true,
        CreatedDate: new Date(),
        UpdatedDate: new Date(),
        XCompanyCodes: [],
        Enable: true,
        StartDate: startDate,
        EndDate: endDate,
        Deleted: false,
      },
      validationSchema,
      onSubmit: async values => {
        const req: DealerChannelModel = {
          Id: values.Id,
          DealerChannel: {
            ...values,
            Id: values.Id,
            StartDate: startDate,
            EndDate: endDate,
            XDealerCodeList: state.XDealerCodeList.map((code: any) => '0000' + code.value),
            XDealerTypeList: state.XDealerTypeList.map((type: any) => type.value),
            XDealerSubTypeList: state.XDealerSubTypeList.map((subType: any) => subType.value),
            XCompanyCodes: state.companyList?.map((code: any) => code.value),
          },
          DealerChannelCondition: dealerChannelConditions?.map(item => {
            return {
              ...item,
              Id: 0,
              Deleted: false,
            }
          }),
        }

        const campaignBundleService = new DealerChannelService({})

        try {
          const res = await campaignBundleService.InsertProgram(req)
          if (!res) {
            Swal.fire({
              icon: 'error',
              title: 'Beklenmedik bir hata oluştu.',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            return
          }
          Swal.fire({
            icon: 'success',
            title: 'Başarıyla kayıt edildi.',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(() => {
            navigate('/admin/homeAndSiteOperation/ListDealer')
          })
        } catch (err: any) {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      },
    })

  const addDealerChannel = async () => {
    setState({ showModal: false, isNewRecord: false })
    if (!values.XCompanyCodes || !values.XCompanyCodes[0] || values.XCompanyCodes.length === 0) {
      Swal.fire({
        icon: 'error',
        title: "Grup firma seçmelisiniz",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!dealerChannelConditions || dealerChannelConditions.length === 0) {
      Swal.fire({
        icon: 'error',
        title: "Ürün eklemelisiniz",
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    const req: DealerChannelModel = {
      Id: values.Id,
      DealerChannel: {
        ...values,
        XDealerCodeList: state.XDealerCodeList.map((code: any) => '0000' + code.value),
        XDealerTypeList: state.XDealerTypeList.map((type: any) => type.value),
        XDealerSubTypeList: state.XDealerSubTypeList.map((subType: any) => subType.value),
        XCompanyCodes: values.XCompanyCodes?.map((code: any) => code.value),
      },
      DealerChannelCondition: dealerChannelConditions?.map(item => {
        return {
          ...item,
          Id: typeof item.Id === "number" ? item.Id : 0,
          Deleted: false,
        }
      }),
    }
    props.showLoading()
    const dealerChannelService = new DealerChannelService({})

    try {
      let res: any;
      if (state.buttonName === "Kaydet")
        res = await dealerChannelService.InsertProgram(req)
      else
        res = await dealerChannelService.UpdateProgram(req)
      if (!res) {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: "Beklenmedik bir hata oluştu",
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        return
      } else {
        props.hideLoading()
        Swal.fire({
          icon: 'success',
          title: `Bayi Kanal ${state.buttonName === "Kaydet" ? "kaydedildi" : "güncellendi"}`,
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(() => {
          navigate('/admin/homeAndSiteOperation/ListDealer')
        })
      }
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }
  const handleConditionType = type => {
    if (type === 'quantity') {
      setState({
        isPrice: true,
        isQuantity: false,
        targetPrice: '',
        currencyType: { label: 'TRY', value: 'TRY' },
      })
      return
    }
    setState({
      isPrice: false,
      isQuantity: true,
      targetQuantity: 0,
    })
  }

  // const getDealerCodes = async () => {
  //   const dealerService = new DealerService({})
  //   const res: any = await dealerService.listAll()
  //   if (res) {
  //     const dealerCodeOptions: any = []

  //     res.forEach((item: any) => {
  //       item && dealerCodeOptions.push({ value: item.SapCode, label: item.SapCode.replace('0000', '') })
  //     })

  //     setState({ dealerCodeOptions })
  //   }
  // }

  const getDealerTypes = async () => {
    props.showLoading()
    const dealerService = new DealerService({})
    const res: any = await dealerService.getDealerTypes()
    if (res) {
      const dealerTypeOptions: ISelectOption[] = []

      res.forEach((item: DealerType) => {
        item && dealerTypeOptions.push({ value: item.MusteriGrubu, label: item.MusteriGrubuAciklama })
      })
      setState({ dealerTypeOptions })
      props.hideLoading()
    }
  }

  const getProductCode = async () => {
    const campaignBundleService = new DealerChannelService({})
    const res = await campaignBundleService.getProductCode()
    if (res) {
      const options = res.Entity?.ProductsInfo.map(item => {
        return {
          label: item.ProductCode + ' ' + item.ProductName,
          value: item.ProductCode,
        }
      })
      setState({
        productCodeOptions: options,
        quotaCodeOptions: res.Entity?.QuotaCode,
      })
    }
  }
  const getBrandList = async () => {
    const campaignBundleService = new DealerChannelService({})
    const res = await campaignBundleService.getBrandList()
    if (res) setState({ brandListOptions: res.Entity?.BrandCode })
  }
  const getCompany = async () => {
    const campaignBundleService: GetCompanyApi = new GetCompanyApi({})
    const res = await campaignBundleService.getCompanies()
    if (res) {
      setState({
        companyListOptions: res.map(item => {
          return {
            label: item.Name,
            value: item.Id,
          }
        }),
      })
    }
  }

  const renderData = async () => {
    const data = (routeLocation.state as any).channelData

    const dc = data.DealerChannel
    setState({ buttonName: "Güncelle" })
    setValues(data.DealerChannel)
    if (dc.XDealerCodeList && dc.XDealerCodeList.length > 0 && dc.XDealerCodeList[0].length > 0) {
      setState({
        XDealerCodeList: dc.XDealerCodeList.map(code => {
          return {
            value: code,
            label: code.replace("0000", ""),
          }
        })
      })
      setFieldValue(
        'XDealerCodeList',
        dc.XDealerCodeList.map(code => {
          return {
            value: code,
            label: code.replace("0000", ""),
          }
        })
      )
    }

    if (dc.XDealerTypeList && dc.XDealerTypeList.length > 0 && dc.XDealerTypeList[0].length > 0) {
      const data: ISelectOption[] = [];
      dc.XDealerTypeList.map((dea) => {
        return state.dealerTypeOptions.filter((type) => { return dea === type.value }).map((typ) => {
          return data.push(typ)
        })
      })
      setState({
        XDealerTypeList: data
      })
      setFieldValue(
        'XDealerTypeList',
        dc.XDealerTypeList.map(code => {
          return {
            value: code,
            label: code,
          }
        })
      )
    }
    if (dc.XDealerSubTypeList && dc.XDealerSubTypeList.length > 0 && dc.XDealerSubTypeList[0].length > 0) {
      setState({
        XDealerSubTypeList: dc.XDealerSubTypeList.map(code => {
          return {
            value: code,
            label: code,
          }
        })
      })
      setFieldValue(
        'XDealerTypeList',
        dc.XDealerTypeList.map(code => {
          return {
            value: code,
            label: code,
          }
        })
      )
    }
    // await getCompany()

    setFieldValue(
      'XCompanyCodes',
      dc.XCompanyCodes.map(cId => state.companyListOptions.find(option => option.value === Number(cId)))
    )

    setDealerChannelConditions(data.DealerChannelCondition)
  }
  const getSubTypes = async () => {
    const subType = await getSubTypeApi()
    if (subType) setState({ subTypes: subType })
  }
  useEffect(() => {
    getCompany()
    //getDealerCodes()
    getDealerTypes()
    getProductCode()
    getBrandList()
    getSubTypes()
    getProductSubTypes()
    getCategory()
  }, [])

  useEffect(() => {
    if (state.companyListOptions.length > 0 && state.dealerTypeOptions.length > 0) {
      if (routeLocation.state) {
        renderData()
      } else {
        resetForm()
      }
    }
  }, [state.companyListOptions, state.dealerTypeOptions])

  const handleRecord = (id?: string) => {
    const XProductCodeList = state.productCodeList?.map(code => code.value)
    const XQuotaCodeList = state.QuotaCodeList?.map(quota => quota.label)
    const XGroupList = state.GroupList?.map(group => group.value)
    const XBrandList = state.BrandList?.map(brand => brand.label)
    const XProductSubTypeList = state.ProductSubTypeList?.map(item => item.label)

    if (
      state.productCodeList?.length === 0 &&
      state.GroupList?.length === 0 &&
      state.QuotaCodeList?.length === 0 &&
      state.BrandList?.length === 0 &&
      state.ProductSubTypeList?.length === 0 &&
      state.targetQuantity === 0
    ) {
      sweetAlert('error', 'Hata', 'En az bir listeyi doldurmalısınız.')
      return
    }
    if (id) {
      setDealerChannelConditions((prevState: any) =>
        prevState.map(pState => {
          if (pState.Id === id) {
            pState.XProductCodeList = XProductCodeList
            pState.XQuotaCodeList = XQuotaCodeList
            pState.XGroupList = XGroupList
            pState.XBrandList = XBrandList
            pState.XProductSubTypeList = XProductSubTypeList
            pState.IsProductCodeList = state.isProductCodesInclude
            pState.IsProductQuotaCodeList = state.isQuotaCodesInclude
            pState.IsProductBrandList = state.isBrandsInclude
            pState.IsProductGroupList = state.isGroupCodesInclude
            pState.IsProductSubTypeList = state.isSubTypesInclude
            pState.IsQuantity = state.isQuantity ? true : false
            pState.IsPrice = state.isQuantity ? false : true
            pState.TargetQuantity = state.targetQuantity
            pState.BasketOptions = state.basketOptions
            pState.TargetPrice = state.targetPrice
            pState.CurrencyType = state.currencyType.value
            pState.TotalCondition = state.totalCondition
            pState.ProgramDetailDescription = state.programDetailDescription
          }
          return pState
        })
      )
      setState({ showModal: false })
      return
    }

    setDealerChannelConditions((prevState: any) => {
      const newRecord = {
        Id: uuidv4(),
        XProductCodeList,
        XQuotaCodeList,
        XGroupList,
        XBrandList,
        XProductSubTypeList,
        IsProductCodeList: state.isProductCodesInclude,
        IsProductQuotaCodeList: state.isQuotaCodesInclude,
        IsProductGroupList: state.isGroupCodesInclude,
        IsProductBrandList: state.isBrandsInclude,
        IsProductSubTypeList: state.isSubTypesInclude,
        IsQuantity: state.isQuantity,
        IsPrice: state.isPrice,
        TargetQuantity: state.targetQuantity,
        BasketOptions: state.basketOptions.value,
        TargetPrice: +state.targetPrice,
        CurrencyType: state.currencyType.value,
        TotalCondition: state.totalCondition.value,
        ProgramDetailDescription: state.programDetailDescription,
      }

      if (prevState && prevState.length > 0) {
        return [...prevState, newRecord]
      }

      return [newRecord]
    })
    setState({ showModal: false })
  }

  const removeBasketCondition = id => {
    setDealerChannelConditions((prevState: any) => prevState.filter(item => item.Id !== id))
  }

  const addProductCondition = () => {
    setCampaignProductConditions((prevState: any) => {
      const newRecord = {
        Id: uuidv4(),
        CampaignBundleId: 0,
        ProductId: 0,
        ResultOperation: 0,
        Quantity: 0,
        MaxQuantity: 0,
        AmountOrRatio: 0,
        CurrencyType: 0,
      }
      if (prevState && prevState.length > 0) {
        return [...prevState, newRecord]
      }
      return [newRecord]
    })
  }

  const setRecord = (campaign: any) => {
    let productCodeList: any = []
    if (campaign.XProductCodeList && campaign.XProductCodeList.length > 0 && campaign.XProductCodeList[0].length > 0) {
      productCodeList = campaign.XProductCodeList.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }
    let QuotaCodeList: any = []
    if (campaign.XQuotaCodeList && campaign.XQuotaCodeList.length > 0 && campaign.XQuotaCodeList[0].length > 0) {
      QuotaCodeList = campaign.XQuotaCodeList.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }
    let BrandList: any = []
    if (campaign.XBrandList && campaign.XBrandList.length > 0 && campaign.XBrandList[0].length > 0) {
      BrandList = campaign.XBrandList.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }
    let ProductSubTypeList: any = []
    if (
      campaign.XProductSubTypeList &&
      campaign.XProductSubTypeList.length > 0 &&
      campaign.XProductSubTypeList[0].length > 0
    ) {
      ProductSubTypeList = campaign.XProductSubTypeList.map(item => {
        return {
          label: item,
          value: item,
        }
      })
    }
    let GroupList: any = []
    if (campaign.XGroupList && campaign.XGroupList.length > 0 && campaign.XGroupList[0].length > 0) {
      state.categoryList.map((cat) => {
        if (campaign.XGroupList.findIndex((grup) => { return grup === cat.value }) > -1) {
          return GroupList.push(cat)
        }
        return
      })
    }
    setState({
      showModal: true,
      selectedBasketConditionId: campaign.Id,
      productCodeList,
      QuotaCodeList,
      GroupList,
      BrandList,
      ProductSubTypeList,
      targetQuantity: campaign.TargetQuantity,
      targetPrice: campaign.TargetPrice,
      isPrice: campaign.IsPrice,
      isQuantity: campaign.IsQuantity,
      currencyType: { value: campaign.CurrencyType, label: campaign.CurrencyType },
      programDetailDescription: campaign.ProgramDetailDescription
    })
  }

  const dateValid = () => {
    const result = new Date(values.EndDate).getTime() - new Date(values.StartDate).getTime()
    if (result < 60000) Swal.fire('Bayi Kanal Programı bitiş tarihi başlangıç tarihinden önce olamaz.')
  }
  // const dateStartValid = () => {
  //   if (new Date(values.StartDate).getTime() < new Date().getTime()) {
  //     Swal.fire('Bayi Kanal Programı başlangıç tarihi şuan ki tarihten önce olamaz.')
  //     // setFieldValue("StartDate", "");
  //   }
  // }
  const dateEndValid = () => {
    if (new Date(values.EndDate).getTime() < new Date(values.StartDate).getTime()) {
      Swal.fire('Bayi Kanal Programı bitiş tarihi şuan ki tarihten önce olamaz.')
      // setFieldValue("EndDate", "");
    }
  }

  const setABS = (e, name) => {
    const value = Math.abs(+e.target.value)
    setFieldValue(name, value)
  }

  const handleFiles = async (files: File[], type: FormatDealerCodeEnum) => {
    const dealerCodes: any = await excelToJSON(files[0])
    if (type === FormatDealerCodeEnum.DealerCode) {
      const codeData = dealerCodes[0].map(item => ({
        label: String(item.DealerCode),
        value: String(item.DealerCode)
      }))

      let list: { label: string, value: string }[] = []

      if (state.XDealerCodeList) {
        list = state.XDealerCodeList.map((item) => ({
          label: item.label,
          value: String(item.value),
        }))
      }
      codeData.forEach((codeItem) => {
        if (!values.XDealerCodeList.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })

      setState({ XDealerCodeList: list })
      setFieldValue('XDealerCodeList', [
        ...values['XDealerCodeList'],
        ...list.filter((item, index, self) => {
          return index === self.findIndex((i) => i.label === item.label)
        })
      ])
    }
    if (type === FormatDealerCodeEnum.DealerType) {
      const datas = setDealerCode(dealerCodes[0], state.dealerTypeOptions)
      if (datas[0].length > 0) {
        Swal.fire({
          icon: 'question',
          title: `${datas[0].map(dt => {
            return dt
          })} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
          showConfirmButton: true,
          allowOutsideClick: false,
          showDenyButton: true,
          confirmButtonText: 'Evet',
          denyButtonText: 'Hayır',
        }).then(async result => {
          if (result.isConfirmed) {
            return
          }
          setState({ XDealerTypeList: datas[1] })
          return setFieldValue('XDealerTypeList', datas[1])
        })
      } else {
        setState({ XDealerTypeList: datas[1] })
        return setFieldValue('XDealerTypeList', datas[1])
      }
    }
  }

  const getCategory = async () => {
    const data: ICategoryList[] = await getCategoryList()
    const categoryList: ISelectOption[] = []
    if (data) {
      data.map((dt) => {
        return categoryList.push({ value: dt.SapCode, label: dt.Name })
      })
    }
    setState({ categoryList })
  }

  const [inputValue, setInputValue] = useState('')
  const [inputValueCond, setInputValueCond] = useState('')

  const handleInputCondChange = (event) => {
    setInputValueCond(event.target.value)
  }

  const handleInputKeyPressCond = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValueCond()
    }
  }

  const handleAddValueCond = () => {
    if (inputValueCond.trim() !== '') {
      let prodList: { label: string, value: string }[] = []

      if (state.productCodeList) {
        prodList = state.productCodeList.map((item) => ({
          label: item.label,
          value: String(item.value),
        }))
      }
      if (!state.productCodeList?.some((item) => item.label === inputValueCond)) {
        const newItem = { label: inputValueCond, value: inputValueCond }
        prodList.push(newItem)
      }

      setState({ productCodeList: prodList })
      setInputValueCond('')
    }
  }

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string, value: string }[] = []

      if (state.XDealerCodeList) {
        dealerList = state.XDealerCodeList.map((item) => ({
          label: item.label,
          value: String(item.value),
        }))
      }

      words.forEach((word: any) => {
        if (!values.XDealerCodeList.some((item: any) => item.label === word)) {
          const newItem = { label: word, value: word }
          dealerList.push(newItem)
        }
      })
      setState({ XDealerCodeList: dealerList })
      setFieldValue('XDealerCodeList', [
        ...values.XDealerCodeList,
        ...dealerList
      ])
      setInputValue('');
    }
  }

  return (
    <div className='dealerChannel'>
      <div className='container'>
        <div className='d-flex justify-content-between'>
          <div className='d-flex gap-1 align-items-center bg-white rounded-3'>
            <div className='arrow-left'></div>
            <div className='buttonsuz'>
              <Link to='/admin/homeAndSiteOperation/ListDealer'>
                <IPButton text={'Listeye Dön'} className='listBtn' />
              </Link>
            </div>
          </div>
          <IPButton
            type='button'
            text={state.buttonName}
            className='saveBtn'
            icon='saveIcon.svg'
            onClick={() => addDealerChannel()}
          />
        </div>
        <div className='formTitle d-flex align-items-center gap-2 m-l-30'>
          <img src='/images/listIcon.svg' alt='' />
          <div>CARİ FORMS</div>
        </div>
        <div className='formCard'>
          <div className='row align-items-end'>
            <div className='col-6'>
              <div className='mb-4'>
                <InputCampaign
                  required
                  id='BundleCode'
                  name='BundleCode'
                  label={'Bundle Kodu'}
                  value={values.BundleCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.BundleCode}
                  touched={touched.BundleCode}
                />
              </div>
              <div style={{ marginBottom: '9px' }}>
                <InputCampaign
                  required
                  id='ProgramDescription'
                  name='ProgramDescription'
                  label={' Kampanya Adı'}
                  value={values.ProgramDescription}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  errors={errors.ProgramDescription}
                  touched={touched.ProgramDescription}
                />
              </div>
            </div>
            <div className='col-6'>
              <label className='labeltext'>
                Açıklama
                <span className='validation-error'>
                  {errors.ProgramDetailDescription && touched.ProgramDetailDescription
                    ? errors.ProgramDetailDescription
                    : null}
                </span>
              </label>
              <IPTextarea
                name='ProgramDetailDescription'
                value={values.ProgramDetailDescription}
                onChange={handleChange}
                onBlur={handleBlur}
              // errors={errors.Message as string}
              // touched={touched.Message as boolean}
              />
            </div>
          </div>
        </div>
        <div className='formTitle d-flex align-items-center gap-2 m-l-30'>
          <img src='/images/listIcon.svg' alt='' />
          <div>CARİ FİLTRE</div>
        </div>
        <div className='formCard'>
          <div className='cariFilterContainer'>
            <div className='d-flex gap-4 flex-wrap'>
              <div className='leftContainer w-50'>
                <div>
                  <a className='campaignExcelBtn' href='/assetWeb/docs/dealer-codes-example.xlsx' target='_blank'>
                    Örnek Excel
                  </a>

                  <div className='listBtnGroup'>
                    <IPButton
                      className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariCode)}`}
                      text={`Cari Kodu Listesi ${state.XDealerCodeList.length > 0 ? '✅' : ''}`}
                      onClick={() => setState({ selectedDealerList: CariListType.CariCode })}
                    />
                    <IPButton
                      className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariType)}`}
                      text={`Cari Tipi Listesi ${state.XDealerTypeList?.length > 0 ? '✅' : ''}`}
                      onClick={() => setState({ selectedDealerList: CariListType.CariType })}
                    />
                    <IPButton
                      className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariAltType)}`}
                      text={`Cari Alt Tipi Listesi ${state.XDealerSubTypeList?.length > 0 ? '✅' : ''}`}
                      onClick={() =>
                        setState({
                          selectedDealerList: CariListType.CariAltType,
                        })
                      }
                    />
                  </div>
                </div>

                <div className='p-0 m-0'>
                  <div
                    className={`cariFilterTextAreaWrap ${enumFunction(
                      state.selectedDealerList,
                      CariListType.CariCode
                    )}`}
                  >
                    <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                      <IPFile
                        id='FileInputDealerCode'
                        multiple={false}
                        handleFiles={e => handleFiles(e, FormatDealerCodeEnum.DealerCode)}
                        removeAfterSelect={true}
                        className='campaignFileInput'
                        label="Excel'den Yükle"
                      />

                      {/* <IPButton
                        text={'Dahil'}
                        className={`btnIncluded ${values.IsDealerListInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerListInclude', true)}
                      />
                      <IPButton
                        text={'Hariç'}
                        className={`btnExcept ${!values.IsDealerListInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerListInclude', false)}
                      /> */}
                    </div>
                    <CampaignSelect
                      placeholder={'Cari Kod Listesi'}
                      height={180}
                      //options={state.dealerCodeOptions}
                      onChangeMulti={(options: ISelectOption[]) => {
                        setFieldValue('XDealerCodeList', options)
                        setState({ XDealerCodeList: options })
                      }}
                      value={state.XDealerCodeList}
                    />
                    <input
                      type="text"
                      placeholder="Cari kodu yazın"
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyPress={handleInputKeyPress}
                    />
                  </div>

                  <div
                    className={`cariFilterTextAreaWrap ${enumFunction(
                      state.selectedDealerList,
                      CariListType.CariType
                    )}`}
                  >
                    <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                      <div className='btns'>
                        <IPFile
                          id='FileInputDealerType'
                          multiple={false}
                          handleFiles={e => handleFiles(e, FormatDealerCodeEnum.DealerType)}
                          removeAfterSelect={true}
                          className='campaignFileInput'
                          label="Excel'den Yükle"
                        />
                      </div>
                      {/* <IPButton
                        text={'Dahil'}
                        className={`btnIncluded ${values.IsDealerTypeInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerTypeInclude', true)}
                      />
                      <IPButton
                        text={'Hariç'}
                        className={`btnExcept ${!values.IsDealerTypeInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerTypeInclude', false)}
                      /> */}
                    </div>
                    <CampaignSelect
                      placeholder={'Cari Tipi Listesi'}
                      height={180}
                      options={state.dealerTypeOptions}
                      onChangeMulti={(options: ISelectOption[]) => {
                        setFieldValue('XDealerTypeList', options)
                        setState({ XDealerTypeList: options })
                      }}
                      value={state.XDealerTypeList}
                    />
                  </div>

                  <div
                    className={`cariFilterTextAreaWrap ${enumFunction(
                      state.selectedDealerList,
                      CariListType.CariAltType
                    )}`}
                  >
                    <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                      {/* <div className='btns'>
												<IPFile id='FileInputDealerSubType' multiple={false} handleFiles={e => handleFiles(e, 'DealerSubType')} removeAfterSelect className='campaignFileInput' label="Excel'den Yükle" />
											</div> */}
                      {/* <IPButton
                        text={'Dahil'}
                        className={`btnIncluded ${values.IsDealerSubTypeInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerSubTypeInclude', true)}
                      />
                      <IPButton
                        text={'Hariç'}
                        className={`btnExcept ${!values.IsDealerSubTypeInclude ? 'active' : ''}`}
                        onClick={() => setFieldValue('IsDealerSubTypeInclude', false)}
                      /> */}
                    </div>
                    {/* <CampaignSelect
                      placeholder={"Cari Alt Tipi Listesi"}
                      height={180}
                      options={selectOptionData}
                      onChangeMulti={(options: ISelectOption[]) =>
                        setFieldValue("XDealerSubTypeList", options)
                      }
                      value={values.XDealerSubTypeList}
                    /> */}
                    <CampaignSelect
                      placeholder={'Cari Alt Tipi Listesi'}
                      height={180}
                      options={state.subTypes?.map((item: any) => {
                        return { label: item, value: item }
                      })}
                      onChangeMulti={(options: ISelectOption[]) => {
                        setFieldValue('XDealerSubTypeList', options)
                        setState({ XDealerSubTypeList: options })
                      }}
                      value={state.XDealerSubTypeList}
                    />
                  </div>
                </div>
                <div></div>
                {/* <div className="pt-3">
                  <IPSelectBox
                    className="listCariSelectBox"
                    isMulti
                    options={state.companyListOptions}
                    value={values.CompanyCodes}
                    onChangeSingle={(option) =>
                      setFieldValue("CompanyCodes", option)
                    }
                    placeholder={
                      <div
                        style={{
                          color: "#000",
                          fontSize: "11px",
                          fontWeight: "600",
                        }}
                      >
                        Grup Firma Listesi
                      </div>
                    }
                  />
                </div> */}
                <div className='pt-3'>
                  <IPSelectBox
                    className='listCariSelectBox'
                    options={state.companyListOptions}
                    value={values.XCompanyCodes}
                    isMulti
                    onChangeMulti={(options: ISelectOption[]) => setFieldValue('XCompanyCodes', options)}
                  />
                </div>
              </div>
              <div className='rightContainer'>
                <div className='mt-auto'>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={'Yayın Başlangıç Tarihi'}
                    value={values.StartDate.toString()}
                    onChange={handleChange}
                    onBlur={e => {
                      handleBlur(e)
                    }}
                    isActive
                  />

                  {/* <IPDatePicker
                    selected={startDate}
                    showTimeSelect
                    dateFormat="dd.MM.yyyy HH:mm"
                    onChange={(update) => {
                      setStartDate(update);
                    }}
                  /> */}
                </div>
                <div className='mt-auto'>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={'Yayın Bitiş Tarihi'}
                    value={values.EndDate.toString()}
                    onChange={handleChange}
                    onBlur={e => {
                      handleBlur(e)
                      dateEndValid()
                      dateValid()
                    }}
                    isActive
                  />

                  {/* <IPDatePicker
                    selected={endDate}
                    showTimeSelect
                    dateFormat="dd.MM.yyyy HH:mm"
                    onChange={(update) => {
                      setEndDate(update);
                    }}
                  /> */}
                </div>

                <IPCheckbox
                  label='Etkin'
                  labelClassName='campaignLabel'
                  checked={values.Enable}
                  onCheckedChange={isChecked => setFieldValue('Enable', isChecked)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='formCard mt-5 d-flex flex-column gap-4'>
          <div className='topArea'>
            <div className='table-responsive' style={{ height: 'auto' }}>
              <table className='campaignTable table table-hover'>
                <thead>
                  <tr>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Ürün Kodu Listesi</div>
                    </th>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Quota Kodu Listesi</div>
                    </th>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Grup Kodu Listesi</div>
                    </th>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Marka Listesi</div>
                    </th>
                    <th scope='col'>
                      <div className='d-flex align-items-center gap-2'>Alt Tip Listesi</div>
                    </th>
                    <th scope='col'>İŞLEMLER</th>
                  </tr>
                </thead>

                <tbody>
                  {dealerChannelConditions &&
                    dealerChannelConditions.map(campaign => {
                      return (
                        <tr key={campaign.Id}>
                          {
                            campaign.XProductCodeList
                              && campaign.XProductCodeList.length > 1 ?
                              <td>{
                                campaign.XProductCodeList.map((brand, index) => {
                                  if (index === 0)
                                    return brand
                                  return ", " + brand
                                })
                              }</td> : <td>{campaign.XProductCodeList}</td>
                          }
                          {
                            campaign.XQuotaCodeList
                              && campaign.XQuotaCodeList.length > 1 ?
                              <td>{
                                campaign.XQuotaCodeList.map((brand, index) => {
                                  if (index === 0)
                                    return brand
                                  return ", " + brand
                                })
                              }</td> : <td>{campaign.XQuotaCodeList}</td>
                          }
                          {
                            campaign.XGroupList
                              && campaign.XGroupList.length > 1 ?
                              <td>{
                                campaign.XGroupList.map((grup, index) => {
                                  if (state.categoryList.findIndex((cat) => { return cat.value === grup }) > -1) {
                                    if (index === 0) return state.categoryList.find((cat) => { return cat.value === grup })?.label
                                    return "," + state.categoryList.find((cat) => { return cat.value === grup })?.label
                                  }
                                })
                              }</td> : <td>{state.categoryList.find((cat) => { return cat.value === campaign.XGroupList[0] })?.label}</td>
                          }
                          {
                            campaign.XBrandList
                              && campaign.XBrandList.length > 1 ?
                              <td>{
                                campaign.XBrandList.map((brand, index) => {
                                  if (index === 0)
                                    return brand
                                  return ", " + brand
                                })
                              }</td> : <td>{campaign.XBrandList}</td>
                          }
                          {
                            campaign.XProductSubTypeList
                              && campaign.XProductSubTypeList.length > 1 ?
                              <td>{
                                campaign.XProductSubTypeList.map((brand, index) => {
                                  if (index === 0)
                                    return brand
                                  return ", " + brand
                                })
                              }</td> : <td>{campaign.XProductSubTypeList}</td>
                          }
                          <td className='actionCol'>
                            <div className='tableIcon' onClick={() => setRecord(campaign)}>
                              <i className='icon-edit'></i>
                            </div>
                            {
                              state.buttonName === "Kaydet" &&
                              <div
                                className='tableIcon'
                                onClick={() =>
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Satır silinecek emin misiniz?',
                                    showCloseButton: true,
                                    showDenyButton: true,
                                    confirmButtonText: 'Sil!',
                                    confirmButtonAriaLabel: 'Sil',
                                    confirmButtonColor: '#ec4c4c',
                                    denyButtonText: 'İptal',
                                    denyButtonAriaLabel: 'İptal',
                                    denyButtonColor: '#AAA',
                                  }).then(result => {
                                    if (result.isConfirmed) {
                                      removeBasketCondition(campaign.Id)
                                    }
                                  })
                                }
                              >
                                <i className='icon-delete'></i>
                              </div>
                            }
                          </td>
                        </tr>
                      )
                    })}
                  {
                    state.buttonName === "Kaydet" && dealerChannelConditions.length === 0 &&
                    <tr
                      onClick={() => {
                        setState({ showModal: true, isNewRecord: true })
                        state.productCodeList = []
                        state.QuotaCodeList = []
                        state.GroupList = []
                        state.BrandList = []
                        state.ProductSubTypeList = []
                        state.targetQuantity = 0
                        state.targetPrice = ''
                        state.selectedBasketConditionId = ''
                      }}
                    >
                      <td colSpan={6} style={{ color: '#699bf7' }}>
                        Yeni Kayıt Ekle...
                      </td>
                    </tr>
                  }

                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-between mt-3'>
          <div className='d-flex gap-1 align-items-center bg-white rounded-3'>
            <div className='arrow-left'></div>
            <div className='buttonsuz'>
              <Link to='/admin/homeAndSiteOperation/ListDealer'>
                <IPButton text={'Listeye Dön'} className='listBtn' />
              </Link>
            </div>
          </div>
          <IPButton
            type='button'
            text={state.buttonName}
            className='saveBtn'
            icon='saveIcon.svg'
            onClick={() => addDealerChannel()}
          />
        </div>
      </div>

      <div
        className={`campaignModalWrapper ${state.showModal ? 'active' : ''}`}
        onClick={() => setState({ showModal: false, isNewRecord: false })}
      >
        <div className='campaignModal' onClick={e => e.stopPropagation()}>
          <form
            onSubmit={e => {
              e.preventDefault()
              state.isNewRecord ? handleRecord() : handleRecord(state.selectedBasketConditionId)
            }}
          >
            <div className='topAreaListGroup'>
              <div>
                <div className='w-100 text-center mb-4'>Ürün Kodu Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isProductCodesInclude, true)}`}
                    onClick={() =>
                      setState({
                        isProductCodesInclude: true,
                      })
                    }
                  />
                  <IPButton
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isProductCodesInclude, false)}`}
                    onClick={() =>
                      setState({
                        isProductCodesInclude: false,
                      })
                    }
                  />
                </div>
                <div className='topAreaInputGroup position-relative'>
                  <CampaignSelect
                    //options={state.productCodeOptions}
                    value={state.productCodeList}
                    onChangeMulti={options => setState({ productCodeList: options })}
                    placeholder={'Ürün Kodu'}
                    menuStyle
                  />
                  <input
                    type="text"
                    placeholder="Ürün kodu yazın"
                    value={inputValueCond}
                    onChange={handleInputCondChange}
                    onKeyPress={handleInputKeyPressCond}
                  />
                </div>
              </div>
              <div>
                <div className='w-100 text-center mb-4'>Quota Kodu Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isQuotaCodesInclude, true)}`}
                    onClick={() => setState({ isQuotaCodesInclude: true })}
                  />
                  <IPButton
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isQuotaCodesInclude, false)}`}
                    onClick={() => setState({ isQuotaCodesInclude: false })}
                  />
                </div>
                <div className='topAreaInputGroup position-relative'>
                  <CampaignSelect
                    options={state.quotaCodeOptions?.map(item => {
                      return { label: item, value: item }
                    })}
                    placeholder={'Quota Kodu'}
                    value={state.QuotaCodeList}
                    onChangeMulti={options => setState({ QuotaCodeList: options })}
                  />
                </div>
              </div>
              <div>
                <div className='w-100 text-center mb-4'>Grup Kodu Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isGroupCodesInclude, true)}`}
                    onClick={() => setState({ isGroupCodesInclude: true })}
                  />
                  <IPButton
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isGroupCodesInclude, false)}`}
                    onClick={() => setState({ isGroupCodesInclude: false })}
                  />
                </div>
                <div className='topAreaInputGroup position-relative'>
                  <CampaignSelect
                    // options={selectOptionData}
                    placeholder={'Grup Kodu'}
                    value={state.GroupList}
                    options={state.categoryList}
                    onChangeMulti={options => setState({ GroupList: options })}
                  />
                </div>
              </div>
              <div>
                <div className='w-100 text-center mb-4'>Marka Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isBrandsInclude, true)}`}
                    onClick={() => setState({ isBrandsInclude: true })}
                  />
                  <IPButton
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isBrandsInclude, false)}`}
                    onClick={() => setState({ isBrandsInclude: false })}
                  />
                </div>
                <div className='topAreaInputGroup position-relative'>
                  <CampaignSelect
                    options={state.brandListOptions?.map(item => {
                      return { label: item, value: item }
                    })}
                    placeholder={'Marka Kodu'}
                    value={state.BrandList}
                    onChangeMulti={options => setState({ BrandList: options })}
                  />
                </div>
              </div>
              <div>
                <div className='w-100 text-center mb-4'>Alt Tip Listesi</div>
                <div className='topAreaBtnGroup w-100 d-flex justify-content-center align-items-center mb-2'>
                  <IPButton
                    text={'Dahil'}
                    className={`btnTopArea ${enumFunction(state.isSubTypesInclude, true)}`}
                    onClick={() => setState({ isSubTypesInclude: true })}
                  />
                  <IPButton
                    text={'Hariç'}
                    className={`btnTopArea ${enumFunction(state.isSubTypesInclude, false)}`}
                    onClick={() => setState({ isSubTypesInclude: false })}
                  />
                </div>
                <div className='topAreaInputGroup position-relative'>
                  <CampaignSelect
                    options={productSubType}
                    placeholder={'Alt Tip Listesi'}
                    value={state.ProductSubTypeList}
                    onChangeMulti={options => setState({ ProductSubTypeList: options })}
                  />
                </div>
              </div>
            </div>

            <div className='topAreaQuantityGroup d-flex flex-column justify-content-around align-items-center'>
              <div className='contentArea w-100'>
                <div className='contentCard'>
                  <div
                    className={`title ${enumFunction(state.isQuantity, true)}`}
                    onClick={() => handleConditionType('price')}
                  >
                    Adet
                  </div>
                  <div className='contentCardInputArea1 w-100'>
                    <input
                      type='number'
                      disabled={state.isPrice}
                      value={state.targetQuantity}
                      onChange={e => {
                        const value = Math.abs(+e.target.value)
                        setState({ targetQuantity: value })
                      }}
                    />

                    {/* <div className="w-100">
                      <IPSelectBox
                        isDisabled={!state.minMaxType}
                        placeholder={<div>Koşul Top. Adet</div>}
                        value={state.totalCondition}
                      
                        onChangeSingle={(option) =>
                          setState({ totalCondition: option })
                        }
                      />
                    </div> */}
                  </div>
                </div>
                <div className='contentCard'>
                  <div
                    className={`title ${enumFunction(state.isPrice, true)}`}
                    onClick={() => handleConditionType('quantity')}
                  >
                    Tutar
                  </div>
                  <div className='contentCardInputArea '>
                    <input
                      style={{ width: '200px' }}
                      type='text'
                      disabled={state.isQuantity}
                      value={state.targetPrice}
                      onChange={e => {
                        setState({ targetPrice: e.target.value })
                      }}
                    />

                    <div className='w-100'>
                      <IPSelectBox
                        isDisabled={state.isQuantity}
                        value={state.currencyType}
                        options={[
                          { label: 'TRY', value: 'TRY' },
                          // { label: "USD", value: "USD" },
                          // { label: "EUR", value: "EUR" },
                        ]}
                        onChangeSingle={option => setState({ currencyType: option })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-12'>
              <label className='labeltext'>Açıklama</label>
              <IPTextarea
                name='ProgramDetailDescription'
                value={state.programDetailDescription}
                onChange={e => {
                  setState({ programDetailDescription: e.target.value })
                }}
              />
              {/* <p className="validation-error">
                {" "}
                {state.programDetailDescription === "" && "İşlem Yapılamadı."}
              </p> */}
            </div>
            <IPButton
              type='submit'
              // text={state.isNewRecord ? "Yeni Kayıt" : "Kaydet"}
              className='modalBtn'
              onClick={() => setState({ showModal: false, isNewRecord: false })}
              text={(routeLocation.state as any)?.channelData.flag === 'update' ? 'Güncelle' : 'Yeni Kayıt'}
            />
            {!state.isNewRecord && (
              <IPButton
                text={'Sil'}
                className='modalBtn'
                onClick={() =>
                  Swal.fire({
                    icon: 'error',
                    title: 'Satır silinecek emin misiniz?',
                    showCloseButton: true,
                    showDenyButton: true,
                    confirmButtonText: 'Sil!',
                    confirmButtonAriaLabel: 'Sil',
                    confirmButtonColor: '#ec4c4c',
                    denyButtonText: 'İptal',
                    denyButtonAriaLabel: 'İptal',
                    denyButtonColor: '#AAA',
                  }).then(result => {
                    if (result.isConfirmed) {
                      removeBasketCondition(state.selectedBasketConditionId)
                    }
                    setState({ showModal: false, isNewRecord: false })
                  })
                }
              />
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(DealerChannel)