import { translate } from '../../base/localization'
import { IProduct } from '../../pages/ProductList/models/response/getProductListResponseModel'
import Translations from '../../translations'
import IPButton from '../IPButtonSearch'
import { useAppDispatch } from '../../redux/app/hooks'
import { addItemToBasketRedux } from '../../redux/slices/basket/basketSlice'
import Swal from 'sweetalert2'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { GetCreateDemandApi } from '../../redux/slices/basket/basketService'
import { KeyValuePair } from '../../base/models'

interface IAddToBasket {
  product: IProduct
  quantity: number
  isRounded?: undefined | boolean
  className?: string
  isOpportunityMarket?: boolean
  disabled?: boolean
}

export default function AddToBasket(props: IAddToBasket) {
  const t = translate(Translations)
  const dispatch = useAppDispatch()
  const navigate: NavigateFunction = useNavigate()

  const isRounded = [undefined, null, true].indexOf(props.isRounded) > -1

  const isDisabledButton = !(
    (props.product?.Prices?.Special?.Value > 0 &&
      props.product?.Prices?.Normal?.Value > 0 &&
      props.product?.Prices?.EndUser?.Value > 0)
  )

  const addToBasketOrCreateDemand = async () => {
    if (isNaN(props.quantity)) {
      return
    }

    if (isDisabledButton) {
      return
    }

    if (Number(props.quantity) < 1) {
      Swal.fire({
        icon: 'error',
        title: 'Ürün adedi 0 dan büyük olmalıdır.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }

    if (props.isOpportunityMarket && props.product.OpportunityStock > 0) {
      dispatch(
        addItemToBasketRedux({
          ProductId: props.product.Id,
          Quantity: Number(props.quantity),
          IsOppurtunityStock: true,
          OpportunitiyStockId: props.product.OpportunityId ?? 0,
        })
      )
    } else {
      if (props.product?.Stock?.Stock === 0 && props.product?.Stock?.Stock < Number(props.quantity)) {
        const api: GetCreateDemandApi = new GetCreateDemandApi({})
        const queryParams: KeyValuePair<number> = new KeyValuePair<number>()
        queryParams.add('pId', props.product.Id)
        api.queryParams = queryParams
        await api
          .getCreateDemand()
          .then(async result => {
            if (result) {
              Swal.fire({
                html: `<h5>${result}</h5>`,
                confirmButtonText: `${t('components.AddToBasket.continueToShopping')}`,
                confirmButtonColor: '#ec4c4c',
              }).then(result => { })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      } else {
        dispatch(
          addItemToBasketRedux({
            ProductId: props.product.Id,
            Quantity: Number(props.quantity),
            IsOppurtunityStock: false,
            OpportunitiyStockId: 0,
          })
        )
      }
    }
  }

  return (
    <>
      {props.isOpportunityMarket ? (
        <>
          <IPButton
            text={
              props.product?.OpportunityStock > 0
                ? ""
                : ""
            }
            className={props.className ?? ''}
            // isRounded={isRounded}
            style={{
              opacity: isDisabledButton ? 0.5 : 1,
              cursor: isDisabledButton ? 'default' : 'pointer',
              backgroundColor: props.product?.OpportunityStock < 1 ? '#0FBDE0' : '',
              height: '40px',
              width: '60px',
              minWidth: '60px'
            }}
            onClick={addToBasketOrCreateDemand}
            icon={props.product?.Stock?.Stock <= 0 ? 'talepolustur-01.svg' : 'shopping-cart-line-01.svg'}
          />
        </>
      ) : (
        <>
          {props.product?.Stock?.StockFlag > 0 ? (
            // <a href="tel:03124728220">
            <IPButton
              text=''
              className={props.className ?? 'addToBasket'}
              // isRounded={isRounded}
              style={{
                opacity: isDisabledButton ? 0.5 : 1,
                cursor: isDisabledButton ? 'default' : 'pointer',
                backgroundColor: '#699bf7',
                height: '40px',
                width: '60px',
                minWidth: '60px'
              }}
              icon='phone-solid.svg'
            />
          ) : (
            // </a>
            <IPButton
              text={
                props.product?.Stock?.Stock > 0
                  ? ""
                  : ""
              }
              className={props.className ?? ''}
              // isRounded={isRounded}
              style={{
                opacity: isDisabledButton ? 0.5 : 1,
                cursor: isDisabledButton ? 'default' : 'pointer',
                backgroundColor: props.product?.Stock?.Stock < 1 ? '#0FBDE0' : '',
                height: '40px',
                width: '60px',
                minWidth: '60px'
              }}
              onClick={() => {
                if (props.disabled)
                  return Swal.fire({
                    icon: 'error',
                    title: 'Bu ürünü sepete atmak için lütfen iquote ürün linkini kullanınız.',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                  })
                addToBasketOrCreateDemand()
              }}
              icon={props.product?.Stock?.Stock <= 0 ? 'talepolustur-01.svg' : 'shopping-cart-line-01.svg'}
            />
          )}
        </>
      )}
    </>
  )
}
