import { useEffect, useState } from 'react'
import { ISshFinancial } from '../models/models'
import { getSssFinancialApi } from '../services/getSssFinancial'
import IPImage from '../../../components/IPImage'
import { downloadFileFromUrl } from '../../../base/utils/dataHelper'
import IPButton from '../../../components/IPButton'

const Accordion = ({ data }) => {
  const [toggleItem, setToggleItem] = useState(false)

  return (
    <div className={`ac-item ${toggleItem ? 'ac-active' : ''}`}>
      <h5 className='ac-title' onClick={() => setToggleItem(!toggleItem)}>
        {data.Question}
      </h5>
      <div className='ac-content' style={{ display: toggleItem ? 'block' : 'none' }}>
        <p dangerouslySetInnerHTML={{ __html: data.Answer }}></p>
        {data.FileType != '.pdf' ?
          <IPImage
            src={data.FileUrl} alt={''} />
          :
          <IPButton
            className={'active indexButton'}
            name='order'
            onClick={() => downloadFileFromUrl(data.FileUrl, data.FileUrl)}
            text='Doküman İndir'
            style={{ width: '10%' }}
          />
        }
      </div>
    </div>
  )
}

export default function FrequentlyAskedQuestions() {
  const [sssData, setSssData] = useState<ISshFinancial[]>([])

  const getSssFinancial = async () => {
    const api: getSssFinancialApi = new getSssFinancialApi({})

    api
      .getSssFinancial()
      .then((res: any) => {
        setSssData(res)
      })
      .catch(err => { })
  }

  useEffect(() => {
    getSssFinancial()
  }, [])

  return (
    // <div className='container current-account'>
    //   <div className='content col-lg-12 m-auto'>
    //     <div className='card'>
    //       <div className='card-header'>
    //         <span className='h4'>Sıkça Sorulan Sorular</span>
    //       </div>
    //       <div className='card-body'>
    <div className='accordion'>
      {sssData && sssData.map((data: ISshFinancial) => <Accordion data={data} />)}

      {/* <div className="ac-item">
                <h5 className="ac-title">Kredili çalışmak veya mevcut kredi limitimizi artırmak için ne yapmalıyız?</h5>
                <div className="ac-content" style={{ display: "none" }}>
                  <p>Kredi Limit İsteği ve Limit Artırımı sayfasında detaylı bilgiye ve başvuru evraklarına ulaşabilirsiniz.</p>
                </div>
              </div>
              <div className="ac-item">
                <h5 className="ac-title">Fiziki pos talebinde bulunmak için ne yapmalıyım?</h5>
                <div className="ac-content" style={{ display: "none" }}>
                  <p>Fiziki pos talepleri için öncelikle Index’ te kredi limitinizin olması gerekmektedir. Banka başvurusundan önce Satış temsilcinizle irtibata geçmeniz gerekmektedir.</p>
                </div>
              </div>
              <div className="ac-item">
                <h5 className="ac-title">Kredi Kartı süpürme talebinde bulunmak için ne yapmalıyım?</h5>
                <div className="ac-content" style={{ display: "none" }}>
                  <p>Öncelikle Satış Temsilcinizle irtibata geçmeniz gerekmektedir. İlgili onaylar alındıktan sonra süpürme talebinde bulunacağınız bankaya yazılı başvuruda bulunmanız gerekmektedir. Başvuruda bulunurken Index’ in bayisi olduğunuzu bildirmeniz yeterlidir. Süpürme işlemlerinde indexpazar’ da faiz oranlarında görmüş olduğunuz oranlar değil, Index’ in maliyet oranları geçerlidir.</p>
                </div>
              </div> */}
    </div>
  )
}
