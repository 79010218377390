import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IDashBoardReport } from '../models/models'

export class GetDashBoardReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GA4Reporting/DashBoardReport',
      requesterInfo,
    })
  }

  public async getDashBoardReport(id: number): Promise<IDashBoardReport> {
    this.props.url = `${this.props.url}/${id}`
    return await this.getAsync<IDashBoardReport>()
  }
}
