import React, { useEffect } from 'react'
import { useCustomReducer } from '../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { IException } from '../../../../base/models'
import { uuidv4 } from '../../../../base/utils'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { AddRolePermissionsApi } from './services/addRolePermissionListApi'
import { GetSecurityRoleApi } from './services/getSecurityRoleApi'
import { GetAllRolePermissionsApi } from './services/getAllRolePermissionsApi'
import { GetRolePermissionsByRoleIdApi } from './services/getRolePermissionByRoleIdAoi'
import {
  IRolePermissionModel,
  IGetAllRolePermissionsResponse,
  ISimpleRole,
  SecurityActionModel,
  SecurityControllerModel,
  IGetAllUserPermissionsResponse,
} from './models'
import IPButton from '../../../../components/IPButton'
import IPCheckbox from '../../../../components/IPCheckBox'
import IPSelectBox from '../../../../components/IPSelectBox'
import Swal from 'sweetalert2'

export interface AuthorizationDefinitionsToRoleState {
  authorizationInfo?: IGetAllRolePermissionsResponse
  roles: ISimpleRole[]
  selectedRole?: ISelectOption
  selectedAuths: IRolePermissionModel[]
}

function AuthorizationDefinitionsToRole(props: BaseComponentWithContextProps) {
  const getInitialState = (): AuthorizationDefinitionsToRoleState => {
    return {
      roles: [
        { IdSecurityRole: 1, SecurityRoleName: 'con' },
        { IdSecurityRole: 2, SecurityRoleName: 'ban' },
      ],
      selectedAuths: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<AuthorizationDefinitionsToRoleState>(intialState)

  useEffect(() => {
    getAuthorizationInfo()
    getRoles()
  }, [null])

  const getAuthorizationInfo = () => {
    props.showLoading && props.showLoading()

    const api: GetAllRolePermissionsApi = new GetAllRolePermissionsApi({})
    api
      .getAllRolePermissions()
      .then((res: IGetAllUserPermissionsResponse) => {
        setState({ authorizationInfo: res })
        props.hideLoading && props.hideLoading()
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()
      })
  }

  const getRoles = () => {
    props.showLoading && props.showLoading()

    const api: GetSecurityRoleApi = new GetSecurityRoleApi({})
    api
      .getSecuritRole()
      .then((res: ISimpleRole[]) => {
        setState({ roles: res })
        props.hideLoading && props.hideLoading()
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()
      })
  }

  const onChangeRole = (option: ISelectOption) => {
    props.showLoading && props.showLoading()

    const getPermsByRoleApi: GetRolePermissionsByRoleIdApi = new GetRolePermissionsByRoleIdApi({})
    getPermsByRoleApi
      .getRolePermissions(Number(option.value))
      .then((res: IRolePermissionModel[]) => {
        props.hideLoading && props.hideLoading()
        setState({ selectedRole: option })

        if (res) {
          setState({ selectedAuths: res })
        }
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()

        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const isSelectAction = (controllerId: number, actionNumber: number): boolean => {
    const controllerAuth = state.selectedAuths.find(
      (elem: IRolePermissionModel) => elem.IdSecurityController === controllerId
    )

    if (controllerAuth) {
      return (actionNumber & controllerAuth.ActionNumberTotal) === actionNumber
    }

    return false
  }

  const selectAction = (checked: boolean, controllerId: number, actionNumber: number) => {
    if (checked) {
      const controllerAuth = state.selectedAuths.find(
        (elem: IRolePermissionModel) => elem.IdSecurityController === controllerId
      )

      if (controllerAuth && !((actionNumber & controllerAuth.ActionNumberTotal) === actionNumber)) {
        controllerAuth.ActionNumberTotal = controllerAuth.ActionNumberTotal + actionNumber
        setState({ selectedAuths: state.selectedAuths })
      } else {
        const newAuthController: IRolePermissionModel = {
          IdSecurityController: controllerId,
          IdSecurityRole: Number(state.selectedRole?.value),
          ActionNumberTotal: actionNumber,
        }

        setState({ selectedAuths: [...state.selectedAuths, newAuthController] })
      }
    } else {
      const controllerAuth = state.selectedAuths.find(
        (elem: IRolePermissionModel) => elem.IdSecurityController === controllerId
      )

      if (controllerAuth && (actionNumber & controllerAuth.ActionNumberTotal) === actionNumber) {
        controllerAuth.ActionNumberTotal = controllerAuth.ActionNumberTotal - actionNumber
        setState({ selectedAuths: state.selectedAuths })
      }
    }
  }

  const isSelectActionArray = (controllerId: number, actionNumber: number[]): boolean => {
    const controllerAuth = state.selectedAuths.find(
      (elem: IRolePermissionModel) => elem.IdSecurityController === controllerId
    )

    if (controllerAuth) {
      return actionNumber.every((number) =>
        (controllerAuth.ActionNumberTotal & number) === number
      )
    }

    return false;
  }

  const selectActionArray = (checked: boolean, controllerId: number, actionNumbers: SecurityActionModel[]) => {
    const existingAuth = state.selectedAuths.find(
      (elem: IRolePermissionModel) => elem.IdSecurityController === controllerId
    )

    if (checked) {
      if (existingAuth) {
        // If the controller exists in selectedAuths, update its ActionNumberTotal
        actionNumbers.forEach((actionNumber) => {
          existingAuth.ActionNumberTotal |= actionNumber.ActionNumber
        })
      } else {
        // If the controller doesn't exist, create a new entry
        const newAuthController: IRolePermissionModel = {
          IdSecurityController: controllerId,
          IdSecurityRole: Number(state.selectedRole?.value),
          ActionNumberTotal: 0, // Initialize with 0 and then update
        }

        actionNumbers.forEach((actionNumber) => {
          newAuthController.ActionNumberTotal |= actionNumber.ActionNumber
        })

        setState({ selectedAuths: [...state.selectedAuths, newAuthController] })
      }
    } else {
      // If the checkbox is unchecked, remove the specified action numbers
      if (existingAuth) {
        actionNumbers.forEach((actionNumber) => {
          existingAuth.ActionNumberTotal &= ~actionNumber.ActionNumber
        })

        // Remove the controller from selectedAuths if ActionNumberTotal becomes zero
        if (existingAuth.ActionNumberTotal === 0) {
          state.selectedAuths = state.selectedAuths.filter(
            (auth) => auth.IdSecurityController !== controllerId
          )
        }
      }

      setState({ selectedAuths: state.selectedAuths })
    }
  }

  const updatePermissions = () => {
    props.showLoading && props.showLoading()

    const updateRolePermissionsApi: AddRolePermissionsApi = new AddRolePermissionsApi({})
    updateRolePermissionsApi
      .addRolePermissions(Number(state.selectedRole?.value), state.selectedAuths)
      .then(() => {
        props.hideLoading && props.hideLoading()

        Swal.fire({
          icon: 'success',
          title: 'Rol yetkileri güncellendi.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
      .catch((err: IException) => {
        props.hideLoading && props.hideLoading()

        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>Role Yetki Tanımlama</span>
          </div>
          <div className='card-body'>
            <div className='row mt-3'>
              <div className='form-group col-md-6 mt-2'>
                <label className='labeltext'>Rol</label>
                <IPSelectBox
                  name='Role'
                  placeholder={'Rol Seçiniz...'}
                  onChangeSingle={onChangeRole}
                  value={state.selectedRole}
                  options={state.roles.map((role: ISimpleRole) => {
                    return { value: role.IdSecurityRole, label: role.SecurityRoleName }
                  })}
                />
              </div>
              <div className='form-group col-md-4'></div>
              <div className='form-group col-md-2 mt-5'>
                <IPButton text='Güncelle' className='btn btn-primary' onClick={updatePermissions} />
              </div>
            </div>
            <div className='row'>
              {state.selectedRole && (
                <div className='col-md-10'>
                  {state.authorizationInfo?.SecurityControllerModels?.map((scModel: SecurityControllerModel) => {
                    return (
                      <div key={uuidv4()} className='row mt-2'>
                        <div className='col-md-12'>
                          <div className='row ml-0'>
                            <IPCheckbox
                              label={scModel.Description}
                              checked={isSelectActionArray(scModel.IdSecurityController, state.authorizationInfo!.SecurityActionModels?.filter((item: SecurityActionModel) => item.IdSecurityController === scModel.IdSecurityController).map((saModel: SecurityActionModel) => saModel.ActionNumber))}
                              onChange={(e) => selectActionArray(e.target.checked, scModel.IdSecurityController, state.authorizationInfo!.SecurityActionModels?.filter((item: SecurityActionModel) => item.IdSecurityController === scModel.IdSecurityController))}
                            />
                          </div>
                          <div className='row mt-2 ml-5'>
                            <div className='col-md-12'>
                              {state.authorizationInfo?.SecurityActionModels?.filter((item: SecurityActionModel) => {
                                return item.IdSecurityController === scModel.IdSecurityController
                              }).map((saModel: SecurityActionModel) => {
                                return (
                                  <div key={uuidv4()} className='row'>
                                    <div className='col-md-4'>
                                      <IPCheckbox
                                        label={saModel.ActionName}
                                        checked={isSelectAction(scModel.IdSecurityController, saModel.ActionNumber)}
                                        onChange={e =>
                                          selectAction(
                                            e.target.checked,
                                            scModel.IdSecurityController,
                                            saModel.ActionNumber
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(AuthorizationDefinitionsToRole)
