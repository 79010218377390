import React, { useEffect, useState, useMemo } from 'react'
import IPButton from '../../../../components/IPButton'
import { useCustomReducer } from '../../../../base/customHooks'
import './index.scss'
import { TrafficSourcesApi } from '../services/trafficSourcesApi'
import { formatDate, getDateByTimezoneOffset } from '../../../../base/utils/dataHelper'
import {
  ICompony,
  IDashBoardOrderDataRequest,
  IDashBoardOrderDataResponse,
  IDashBoardSapOrderDataResponse,
  IData,
  IOrder,
  MostSoldCategoriesResponse,
  TrafficSourceResponse,
} from '../models/models'
import { getCompanyApi } from '../../components/api'
import { ICompany } from '../../components/models/models'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { GetDashBoardReportApi } from '../services/getDashBoardReportApiApi'
import Swal from 'sweetalert2'
import { GetGeneralDashBoardApi } from '../services/getGeneralDashBoardApi'
import { PostDashBoardOrderDataApi } from '../services/postDashBoardOrderDataApi'
import TrafficChart from './PieChart'
import CurrencyChart from './CurrencyChart'
import Table from '../../../../components/Table'
import { MostSoldCategoriesApi } from '../services/mostSoldCategoiesApi'
import IPDatePicker from '../../../../components/IPDatePicker'
import { DashBoardSapOrderDataApi } from '../services/dashBoardSapOrderDataApi'

interface ContactState {
  buttonMoneyChange: string
  buttonChange: string
  buttonChannelChange: number
  data: IOrder
  index: ICompony
  despec: ICompony
  datagate: ICompony
  netex: ICompony
  creditCard: number
  sessionOpen: number
  orderer: number
  activeUserCount: number
  avgSessionDuration: string
  sessionsCount: number
  sessionsCountByDealer: number
  dashBoardOrderData: IDashBoardOrderDataResponse[]
  campaignData: IData[]
  orderTypeData: IData[]
  paymentData: IData[]
  toggleTable: boolean
  trafficData: TrafficSourceResponse[]
  productData: MostSoldCategoriesResponse[]
  orderSapData?: IDashBoardSapOrderDataResponse
}
function Index(props: BaseComponentWithContextProps) {
  const [data, setData] = useState<IOrder>({
    CompanyOrderDetails: [],
    OrderEUROCount: 0,
    OrderTLCount: 0,
    OrderUSDCount: 0,
    componyOrdersCount: [],
    dataSapList: [],
  })

  const inititalStartDate = new Date()
  inititalStartDate.setMonth(0)
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)

  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  async function getTrafficSource() {
    const req = {
      StartDate: getDateByTimezoneOffset(startDate) as any,
      EndDate: getDateByTimezoneOffset(endDate) as any,
    }
    try {
      const res = await new TrafficSourcesApi({}).trafficSources(req)
      if (res) setState({ trafficData: res })
    } catch (err: any) {
      console.log('trafficSources error:', err)
    }
  }

  async function getMostSoldCategories() {
    try {
      const res = await new MostSoldCategoriesApi({}).mostSoldCategories()
      if (res) setState({ productData: res })
    } catch (err: any) {
      console.log('mostSoldCategories error:', err)
    }
  }

  useEffect(() => {
    getOrdersByDealers(startDate, endDate, 'usd')
    getCompanies()
    getDashBoardReport(1)
    getGeneralDashBoard(1)
    postDashBoardOrderData()
    getTrafficSource()
    getMostSoldCategories()
  }, [])

  const [companies, setCompanies] = useState<ICompany[]>([])
  const getCompanies = async () => {
    const company: any[] = await getCompanyApi()
    setCompanies(company)
  }

  const getOrdersByDealers = async (start, end, money) => {
    let sdate = formatDate(start)
    let edate = formatDate(end)
    if (state.buttonChange === 'month') {
      const tarih = new Date()
      tarih.setMonth(tarih.getMonth() - 1)
      sdate = formatDate(tarih)
      edate = formatDate(new Date())
    } else {
      sdate = formatDate(new Date())
      edate = formatDate(new Date())
    }
    const req = {
      DealerCode: '',
      StartDate: sdate,
      EndDate: edate,
    }
    props.showLoading()
    const dashBoardSapOrderData: DashBoardSapOrderDataApi = new DashBoardSapOrderDataApi({})
    await dashBoardSapOrderData.dashBoardSapOrderData(req).then(res => {
      if (res) {
        setState({ orderSapData: res })
        setState({ buttonMoneyChange: money })
        if (state.buttonChange === 'day') getDashBoardReport(1)
        if (state.buttonChange === 'month') getDashBoardReport(30)
        props.hideLoading()
      }
    })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getDashBoardReport = async (type: 1 | 30) => {
    props.showLoading()
    const getDashBoardReportApi: GetDashBoardReportApi = new GetDashBoardReportApi({})
    getDashBoardReportApi
      .getDashBoardReport(type)
      .then(res => {
        if (res) {
          setState({
            activeUserCount: res.ActiveUserCount,
            avgSessionDuration: res.AvgSessionDuration,
            sessionsCount: res.SessionsCount,
            sessionsCountByDealer: res.SessionsCountByDealer,
          })
          getGeneralDashBoard(type)
          props.hideLoading()
        }
      })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const getGeneralDashBoard = async (type: 1 | 30) => {
    props.showLoading()
    const getGeneralDashBoardApi: GetGeneralDashBoardApi = new GetGeneralDashBoardApi({})
    getGeneralDashBoardApi
      .getGeneralDashBoard(type)
      .then(res => {
        if (res) {
          setState({ campaignData: res.CampaignData, orderTypeData: res.OrderTypeData, paymentData: res.PaymentData })
          props.hideLoading()
        }
      })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }
  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000)
  const postDashBoardOrderData = async () => {
    if (!startDate || !endDate) {
      Swal.fire({
        icon: 'error',
        title: 'Tarih seçiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (endDate.getFullYear() > startDate.getFullYear()) {
      Swal.fire({
        icon: 'error',
        title: 'Aynı yıl içerisinde seçim yapmalısınız!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const inititalEndDate = new Date(endDate)
    inititalEndDate.setMonth(inititalEndDate.getMonth() + 1)
    inititalEndDate.setDate(0)
    inititalEndDate.setHours(23)
    inititalEndDate.setMinutes(59)
    inititalEndDate.setSeconds(59)
    const inititalStartDate = new Date(startDate)
    inititalStartDate.setDate(1)
    inititalStartDate.setHours(0)
    inititalStartDate.setMinutes(0)
    inititalStartDate.setSeconds(0)
    const req: IDashBoardOrderDataRequest = {
      StartDate: dateToISO(inititalStartDate),
      EndDate: dateToISO(inititalEndDate),
    }
    props.showLoading()
    const postDashBoardOrderDataApi: PostDashBoardOrderDataApi = new PostDashBoardOrderDataApi({})
    postDashBoardOrderDataApi
      .postDashBoardOrderData(req)
      .then(res => {
        if (res) {
          setState({ dashBoardOrderData: res })
          props.hideLoading()
        }
      })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getInitialState = (): ContactState => {
    return {
      buttonMoneyChange: 'usd',
      buttonChange: 'day',
      buttonChannelChange: 0,
      data: data,
      index: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      despec: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      datagate: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      netex: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      creditCard: 0,
      sessionOpen: 0,
      orderer: 0,
      activeUserCount: 0,
      avgSessionDuration: '00:00:00',
      sessionsCount: 0,
      sessionsCountByDealer: 0,
      dashBoardOrderData: [],
      campaignData: [],
      orderTypeData: [],
      paymentData: [],
      toggleTable: false,
      trafficData: [],
      productData: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ContactState>(intialState)

  const moneyChange = async money => {
    setState({
      index: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      datagate: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      despec: {
        OrderPrice: 0,
        OrderCount: 0,
      },
      netex: {
        OrderPrice: 0,
        OrderCount: 0,
      },
    })
    let i = 0
    let k = 0
    let total = 0
    data.componyOrdersCount.map(com => {
      if (com.Company === '1000' || com.Company === '1100' || com.Company === '1200' || com.Company === '2300') {
        if (money === 'usd') {
          i = i + com.OrdercountUSD
          k = com.OrderPriceUSD * 100
        }
        if (money === 'try') {
          i = i + com.OrderCountTRY
          k = com.OrderPriceTRY * 100
        }
        if (money === 'eur') {
          i = i + com.OrderCountEUR
          k = com.OrderPriceEUR * 100
        }
        total = total + k
        if (com.Company === '1000') {
          if (money === 'usd') {
            setState({
              index: {
                OrderPrice: com.OrderPriceUSD,
                OrderCount: com.OrdercountUSD,
              },
            })
          }
          if (money === 'try') {
            setState({
              index: {
                OrderPrice: com.OrderPriceTRY,
                OrderCount: com.OrderCountTRY,
              },
            })
          }
          if (money === 'eur') {
            setState({
              index: {
                OrderPrice: com.OrderPriceEUR,
                OrderCount: com.OrderCountEUR,
              },
            })
          }
        }
        if (com.Company === '1100') {
          if (money === 'usd') {
            setState({
              datagate: {
                OrderPrice: com.OrderPriceUSD,
                OrderCount: com.OrdercountUSD,
              },
            })
          }
          if (money === 'try') {
            setState({
              datagate: {
                OrderPrice: com.OrderPriceTRY,
                OrderCount: com.OrderCountTRY,
              },
            })
          }
          if (money === 'eur') {
            setState({
              datagate: {
                OrderPrice: com.OrderPriceEUR,
                OrderCount: com.OrderCountEUR,
              },
            })
          }
        }
        if (com.Company === '1200') {
          if (money === 'usd') {
            setState({
              despec: {
                OrderPrice: com.OrderPriceUSD,
                OrderCount: com.OrdercountUSD,
              },
            })
          }
          if (money === 'try') {
            setState({
              despec: {
                OrderPrice: com.OrderPriceTRY,
                OrderCount: com.OrderCountTRY,
              },
            })
          }
          if (money === 'eur') {
            setState({
              despec: {
                OrderPrice: com.OrderPriceEUR,
                OrderCount: com.OrderCountEUR,
              },
            })
          }
        }
        if (com.Company === '2300') {
          if (money === 'usd') {
            setState({
              netex: {
                OrderPrice: com.OrderPriceUSD,
                OrderCount: com.OrdercountUSD,
              },
            })
          }
          if (money === 'try') {
            setState({
              netex: {
                OrderPrice: com.OrderPriceTRY,
                OrderCount: com.OrderCountTRY,
              },
            })
          }
          if (money === 'eur') {
            setState({
              netex: {
                OrderPrice: com.OrderPriceEUR,
                OrderCount: com.OrderCountEUR,
              },
            })
          }
        }
      }
    })
    total = total / 100
    let dataCreditCard = 0
    let dataSessionOpen = 0
    let orderer = 0
    let moneys = ''
    if (money === 'usd') {
      moneys = 'USD'
    }
    if (money === 'try') {
      moneys = 'TRY'
    }
    if (money === 'eur') {
      moneys = 'EUR'
    }
    data.dataSapList
      .filter(sap => {
        return sap.SIPARIS_PARA_BIRIMI === moneys
      })
      .map(s => {
        orderer = orderer + 1
        if (
          s.ODEME_KODU === '1' ||
          s.ODEME_KODU === '2' ||
          s.ODEME_KODU === '3' ||
          s.ODEME_KODU === '4' ||
          s.ODEME_KODU === '5' ||
          s.ODEME_KODU === '6' ||
          s.ODEME_KODU === '7' ||
          s.ODEME_KODU === '8' ||
          s.ODEME_KODU === '9' ||
          s.ODEME_KODU === 'I' ||
          s.ODEME_KODU === 'M' ||
          s.ODEME_KODU === 'Q' ||
          s.ODEME_KODU === 'R' ||
          s.ODEME_KODU === 'W' ||
          s.ODEME_KODU === 'Ç'
        )
          return (dataCreditCard = dataCreditCard + 1)
        return (dataSessionOpen = dataSessionOpen + 1)
      })
    let totals = dataCreditCard + dataSessionOpen
    let percent = 0
    let percentDifference = 0
    if (totals > 0) {
      percent = dataCreditCard / totals
      percent = 100 * percent
      percent = Number(percent.toFixed(2))
      percentDifference = 100 - percent
      percentDifference = Number(percentDifference.toFixed(2))
    }
    setState({
      buttonChannelChange: state.buttonChannelChange,
      buttonMoneyChange: money,
      buttonChange: state.buttonChange,
      data: data,
      creditCard: percent,
      sessionOpen: percentDifference,
      orderer: orderer,
    })
  }

  const dayChange = async (date, channel) => {
    let sdate = ''
    let edate = ''
    let chan

    if (channel === 30 || channel === 10) {
      chan = channel.toString()
    }
    else {
      chan = null
    }


    if (date === 'day') {
      sdate = formatDate(new Date())
      edate = formatDate(new Date())
    }
    if (date === 'month') {
      const tarih = new Date()
      tarih.setMonth(tarih.getMonth() - 1)
      sdate = formatDate(tarih)
      edate = formatDate(new Date())
    }
    const data = {
      DealerCode: '',
      StartDate: sdate,
      EndDate: edate,
      Channel: chan,
    }
    props.showLoading()
    const dashBoardSapOrderData: DashBoardSapOrderDataApi = new DashBoardSapOrderDataApi({})
    await dashBoardSapOrderData.dashBoardSapOrderData(data).then(res => {
      if (res) {
        setState({ orderSapData: res })
        if (date === 'day') getDashBoardReport(1)
        if (date === 'month') getDashBoardReport(30)
      }
    })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Ay',
        accessor: 'col2',
      },
      {
        Header: 'Sipariş Adedi',
        accessor: 'col3',
      },
      {
        Header: 'Tutar(TRY)',
        accessor: 'col4',
      },
      {
        Header: 'Tutar(USD)',
        accessor: 'col5',
      }
    ],
    []
  )

  const tableData = useMemo(
    () =>
      state.dashBoardOrderData.map((item: IDashBoardOrderDataResponse, index) => ({
        col1: index + 1,
        col2:
          item.Month === 1
            ? 'Ocak'
            : item.Month === 2
              ? 'Şubat'
              : item.Month === 3
                ? 'Mart'
                : item.Month === 4
                  ? 'Nisan'
                  : item.Month === 5
                    ? 'Mayıs'
                    : item.Month === 6
                      ? 'Haziran'
                      : item.Month === 7
                        ? 'Temmuz'
                        : item.Month === 8
                          ? 'Ağustos'
                          : item.Month === 9
                            ? 'Eylül'
                            : item.Month === 10
                              ? 'Ekim'
                              : item.Month === 11
                                ? 'Kasım'
                                : 'Aralık',
        col3: item.OrderCount,
        col4: item.OrderAmountTL,
        col5: item.OrderAmountUSD,
        // col6: item.OrderAmountEURO,
      })),
    [state.dashBoardOrderData]
  )

  const orderPriceAndQuantity = (title: string, value: string) => {
    const orderSapData = state.orderSapData ? (state.orderSapData.CompanyOrderDetails.length > 0 ? state.orderSapData.CompanyOrderDetails : []) : [];
    const orderSapDataFindIndex = orderSapData.findIndex((com) => { return com.Name === String(value) });
    const orderSapDataFind = orderSapData.find((com) => { return com.Name === String(value) });
    let datas: any = 0
    let datasPrice: any = state.buttonMoneyChange === "usd"
      ? "0.00 $"
      : (state.buttonMoneyChange === "try"
        ? "0.00 ₺"
        : "0.00 €")
    if (orderSapDataFindIndex > -1) {
      if (title === "Quantity") {
        datas = (state.buttonMoneyChange === "usd"
          ? (orderSapDataFind
            ? orderSapDataFind.USDCount
            : 0)
          : (state.buttonMoneyChange === "try"
            ? (orderSapDataFind
              ? orderSapDataFind.TLCount
              : 0)
            : (orderSapDataFind
              ? orderSapDataFind.EUROCount
              : 0)))
      } else {
        datasPrice = (state.buttonMoneyChange === "usd"
          ? (orderSapDataFind
            ? orderSapDataFind.USDPrice
            : "0.00 $")
          : (state.buttonMoneyChange === "try"
            ? (orderSapDataFind
              ? orderSapDataFind.TLPrice
              : "0.00 ₺")
            : (orderSapDataFind
              ? orderSapDataFind.EUROPrice
              : "0.00 ₺"))
        )
      }
    }
    if (title === "Quantity") return datas
    return datasPrice
  }

  return (
    <div className='container admin-home'>
      <div className='d-flex justify-content-between m-t-15'>
        <div className='d-flex  m-t-15'>
          <div className='m-r-10'>
            {state.buttonMoneyChange === 'usd' ? (
              <IPButton
                className='form-control admin-home-button-left'
                name='usd'
                onClick={() => moneyChange('usd')}
                text='USD'
                style={{
                  backgroundColor: '#008000',
                  color: 'white',
                }}
              />
            ) : (
              <IPButton
                className='form-control admin-home-button-left'
                name='usd'
                onClick={() => moneyChange('usd')}
                text='USD'
              />
            )}
          </div>
          <div className='m-r-10'>
            {state.buttonMoneyChange === 'try' ? (
              <IPButton
                className='form-control admin-home-button-left'
                name='try'
                onClick={() => moneyChange('try')}
                text='TRY'
                style={{
                  backgroundColor: 'rgba(218, 41, 28)',
                  color: 'white',
                }}
              />
            ) : (
              <IPButton
                className='form-control admin-home-button-left'
                name='try'
                onClick={() => moneyChange('try')}
                text='TRY'
              />
            )}
          </div>
        </div>

        <div className='d-flex  m-t-15'>
          <div className='m-r-10'>
            {state.buttonChannelChange === 0 ? (
              <IPButton
                className='form-control admin-home-button-left'
                name='all'
                onClick={() => {
                  dayChange(state.buttonChange, 0)
                  setState({ buttonChannelChange: 0 })
                }}
                text='HEPSİ'
                style={{
                  backgroundColor: 'blue',
                  color: 'white',
                }}
              />
            ) : (
              <IPButton
                className='form-control admin-home-button-left'
                name='all'
                onClick={() => {
                  dayChange(state.buttonChange, 0)
                  setState({ buttonChannelChange: 0 })
                }}
                text='HEPSİ'
              />
            )}
          </div>
          <div className='m-r-10'>
            {state.buttonChannelChange === 30 ? (
              <IPButton
                className='form-control admin-home-button-left'
                name='b2b'
                onClick={() => {
                  dayChange(state.buttonChange, 30)
                  setState({ buttonChannelChange: 30 })
                }}
                text='B2B'
                style={{
                  backgroundColor: '#008000',
                  color: 'white',
                }}
              />
            ) : (
              <IPButton
                className='form-control admin-home-button-left'
                name='b2b'
                onClick={() => {
                  dayChange(state.buttonChange, 30)
                  setState({ buttonChannelChange: 30 })
                }}
                text='B2B'
              />
            )}
          </div>
          <div className='m-r-10'>
            {state.buttonChannelChange === 10 ? (
              <IPButton
                className='form-control admin-home-button-left'
                name='sap'
                onClick={() => {
                  dayChange(state.buttonChange, 10)
                  setState({ buttonChannelChange: 10 })
                }}
                text='SAP'
                style={{
                  backgroundColor: 'rgba(218, 41, 28)',
                  color: 'white',
                }}
              />
            ) : (
              <IPButton
                className='form-control admin-home-button-left'
                name='sap'
                onClick={() => {
                  dayChange(state.buttonChange, 10)
                  setState({ buttonChannelChange: 10 })
                }}
                text='SAP'
              />
            )}
          </div>
        </div>




        <div className='d-flex m-t-15'>
          <div className='m-r-10'>
            {state.buttonChange === 'day' ? (
              <IPButton
                className='form-control admin-home-button-right'
                name='day'
                onClick={() => {
                  dayChange('day', state.buttonChannelChange)
                  setState({ buttonChange: 'day' })
                }}
                text='Bugün'
                style={{
                  backgroundColor: 'rgba(218, 41, 28)',
                  color: 'white',
                }}
              />
            ) : (
              <IPButton
                className='form-control admin-home-button-right'
                name='day'
                onClick={() => {
                  dayChange('day', state.buttonChannelChange)
                  setState({ buttonChange: 'day' })
                }}
                text='Bugün'
              />
            )}
          </div>
          <div className='m-r-10'>
            {state.buttonChange === 'month' ? (
              <IPButton
                className='form-control admin-home-button-right'
                name='month'
                onClick={() => {
                  dayChange('month', state.buttonChannelChange)
                  setState({ buttonChange: 'month' })
                }}
                text='Son 1 Ay'
                style={{
                  backgroundColor: 'rgba(218, 41, 28)',
                  color: 'white',
                  marginLeft: '10px',
                }}
              />
            ) : (
              <IPButton
                className='form-control admin-home-button-right'
                name='month'
                onClick={() => {
                  dayChange('month', state.buttonChannelChange)
                  setState({ buttonChange: 'month' })
                }}
                text='Son 1 Ay'
                style={{
                  marginLeft: '10px',
                }}
              />
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          border: '1px solid #F0F0F1',
          backgroundColor: 'white',
          padding: '20px',
        }}
      >
        <div className='row'>
          <div className='col-3'>
            <b className='d-block table-title'>Sipariş Sayıları</b>
            <table className='table table-bordered dataTable XmlTable'>
              <tbody>
                <tr role='row'>
                  <td
                    style={{
                      width: '100px',
                      backgroundColor: '#F1F2F2',
                      height: 300
                    }}
                    rowSpan={companies.length + 1}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: (companies.length - 1) * 75 + 'px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                      <p
                        style={{
                          fontSize: '1.3rem',

                          color: '#00A843',
                          fontWeight: '600',
                        }}
                      >
                        {orderPriceAndQuantity("Quantity", "Toplam")}
                      </p>
                    </div>
                  </td>
                </tr>
                {companies
                  .filter(com => {
                    return com.Name !== 'Index Group'
                  })
                  .map(data => (
                    <tr className='' key={data.Id}>
                      <td style={{ verticalAlign: 'middle' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <span style={{ color: '#676767', fontWeight: '500' }}>{data.Name}</span>
                          <p
                            style={{
                              fontSize: '1.3rem',
                              color: '#00A843',
                              fontWeight: '600',
                              marginBottom: 0,
                              wordBreak: 'break-word',
                            }}
                          >
                            {orderPriceAndQuantity("Quantity", String(data.Code))}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='col-3'>
            <b className='d-block table-title'>Sipariş Tutarları</b>
            <table className='table table-bordered dataTable XmlTable'>
              <tbody>
                <tr role='row'>
                  <td
                    style={{
                      width: '100px',
                      backgroundColor: '#F1F2F2',
                      height: 300
                    }}
                    rowSpan={companies.length + 1}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: (companies.length - 1) * 75 + 'px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                      <p
                        style={{
                          fontSize: '1.3rem',
                          color: '#00A843',
                          fontWeight: '600',
                        }}
                      >
                        {orderPriceAndQuantity("Price", "Toplam")}
                      </p>
                    </div>
                  </td>
                </tr>
                {companies
                  .filter(com => {
                    return com.Name !== 'Index Group'
                  })
                  .map(data => (
                    <tr className='' key={data.Id}>
                      <td style={{ verticalAlign: 'middle' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <span style={{ color: '#676767', fontWeight: '500' }}>{data.Name}</span>
                          <p
                            style={{
                              fontSize: '1.3rem',
                              color: '#00A843',
                              fontWeight: '600',
                              marginBottom: 0,
                              wordBreak: 'break-word',
                            }}
                          >
                            {orderPriceAndQuantity("Price", String(data.Code))}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='col-1-5'>
            <b className='d-block board-title'>
              Oturum Sayısı <span className='board-subtitle'></span>
            </b>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 315,
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #E0E0E2',
                borderRadius: '5px',
                boxShadow: '0px 4px 90px rgba(163, 171, 185, 0.14)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                }}
              ></div>

              <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
              <p
                style={{
                  fontSize: '1.3rem',
                  color: '#00A843',
                  fontWeight: '600',
                }}
              >
                {state.sessionsCount}
              </p>
            </div>
          </div>
          <div className='col-1-5'>
            <b className='d-block board-title'>
              Oturum Sayısı <span className='board-subtitle'> ( Tekil Cari) </span>
            </b>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 315,
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #E0E0E2',
                borderRadius: '5px',
                boxShadow: '0px 4px 90px rgba(163, 171, 185, 0.14)',
              }}
            >
              <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
              <p
                style={{
                  fontSize: '1.3rem',
                  color: '#00A843',
                  fontWeight: '600',
                }}
              >
                {state.sessionsCountByDealer}
              </p>
            </div>
          </div>
          <div className='col-1-5'>
            <b className='d-block board-title'>
              Sipariş Veren
              <span className='board-subtitle'> (Tekil Cari) </span>
            </b>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 315,
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #E0E0E2',
                borderRadius: '5px',
                boxShadow: '0px 4px 90px rgba(163, 171, 185, 0.14)',
              }}
            >
              <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
              <p
                style={{
                  fontSize: '1.3rem',
                  color: '#00A843',
                  fontWeight: '600',
                }}
              >
                {state.buttonMoneyChange === "usd" ? state.orderSapData?.OrderUSDCount : state.orderSapData?.OrderTLCount}
              </p>
            </div>
          </div>
          <div className='col-1-5'>
            <b className='d-block board-title'>Giriş Yapan Kullanıcı Adedi</b>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 315,
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #E0E0E2',
                borderRadius: '5px',
                boxShadow: '0px 4px 90px rgba(163, 171, 185, 0.14)',
              }}
            >
              <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
              <p
                style={{
                  fontSize: '1.3rem',
                  color: '#00A843',
                  fontWeight: '600',
                }}
              >
                {state.activeUserCount}
              </p>
            </div>
          </div>
        </div>
        <div className='row mt-3'>
          <div className='col-2'>
            <b className='d-block board-title'>
              Sepet Ödeme Dağılımı<span className='board-subtitle'></span>
            </b>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 319,
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #E0E0E2',
                borderRadius: '5px',
                boxShadow: '0px 4px 90px rgba(163, 171, 185, 0.14)',
              }}
            >
              <div style={{ width: 150 }}>
                <div className='d-flex justify-content-between'>
                  <span style={{ fontWeight: '500', color: '#616161' }}>Iyzico </span>
                  <span>
                    {state.orderTypeData.findIndex(pay => {
                      return pay.Name === 'Iyzico'
                    }) > - 1 ? state.orderTypeData.find(pay => {
                      return pay.Name === 'Iyzico'
                    })?.Count : 0}
                  </span>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                  <span style={{ fontWeight: '500', color: '#616161' }}>Açık Hesap </span>
                  <span>
                    {state.orderTypeData.findIndex(pay => {
                      return pay.Name === 'Kayıtlı Koşullarım'
                    }) > - 1 ? state.orderTypeData.find(pay => {
                      return pay.Name === 'Kayıtlı Koşullarım'
                    })?.Count : 0}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-3'>
            <b className='d-block board-title'>
              Sipariş Tutar Dağılımı <span className='board-subtitle'> </span>
            </b>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 319,
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #E0E0E2',
                borderRadius: '5px',
                boxShadow: '0px 4px 90px rgba(163, 171, 185, 0.14)',
              }}
            >
              <div style={{ width: 250 }}>
                <div className='d-flex justify-content-between'>
                  <span style={{ fontWeight: '500', color: '#616161' }}>Iyzico </span>
                  <span>
                    {state.buttonMoneyChange === 'try'
                      ? (state.orderTypeData.findIndex(pay => {
                        return pay.Name === 'Iyzico'
                      }) > - 1 ? state.orderTypeData.find(pay => {
                        return pay.Name === 'Iyzico'
                      })?.AmountTL : "0.00 ₺")
                      : state.buttonMoneyChange === 'usd'
                        ? (state.orderTypeData.findIndex(pay => {
                          return pay.Name === 'Iyzico'
                        }) > - 1 ? state.orderTypeData.find(pay => {
                          return pay.Name === 'Iyzico'
                        })?.AmountUSD : "0.00 $")
                        : (state.orderTypeData.findIndex(pay => {
                          return pay.Name === 'Iyzico'
                        }) > - 1 ? state.orderTypeData.find(pay => {
                          return pay.Name === 'Iyzico'
                        })?.AmountEURO : "0.00 €")}

                  </span>
                </div>
                <hr />
                <div className='d-flex justify-content-between'>
                  <span style={{ fontWeight: '500', color: '#616161' }}>Açık Hesap</span>
                  <span>
                    {state.buttonMoneyChange === 'try'
                      ? (state.orderTypeData.findIndex(pay => {
                        return pay.Name === 'Kayıtlı Koşullarım'
                      }) > - 1 ? state.orderTypeData.find(pay => {
                        return pay.Name === 'Kayıtlı Koşullarım'
                      })?.AmountTL : "0.00 ₺")
                      : state.buttonMoneyChange === 'usd'
                        ? (state.orderTypeData.findIndex(pay => {
                          return pay.Name === 'Kayıtlı Koşullarım'
                        }) > - 1 ? state.orderTypeData.find(pay => {
                          return pay.Name === 'Kayıtlı Koşullarım'
                        })?.AmountUSD : "0.00 $")
                        : (state.orderTypeData.findIndex(pay => {
                          return pay.Name === 'Kayıtlı Koşullarım'
                        }) > - 1 ? state.orderTypeData.find(pay => {
                          return pay.Name === 'Kayıtlı Koşullarım'
                        })?.AmountEURO : "0.00 €")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='col-3'>
            <b className='d-block board-title'>
              Ödeme Dağılımı <span className='board-subtitle'></span>
            </b>
            <table className='table table-bordered dataTable XmlTable'>
              <tbody>
                <tr role='row'>
                  <td
                    style={{
                      width: '100px',
                      backgroundColor: '#F1F2F2',
                      height: 300,
                    }}
                    rowSpan={companies.length + 1}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: (companies.length - 1) * 75 + 'px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                      <p
                        style={{
                          fontSize: '1.3rem',
                          color: '#00A843',
                          fontWeight: '600',
                        }}
                      >
                        {state.buttonMoneyChange === 'try'
                          ? state.paymentData.find(camp => {
                            return camp.Name === 'Toplam'
                          })?.AmountTL
                          : state.buttonMoneyChange === 'usd'
                            ? state.paymentData.find(camp => {
                              return camp.Name === 'Toplam'
                            })?.AmountUSD
                            : state.paymentData.find(camp => {
                              return camp.Name === 'Toplam'
                            })?.AmountEURO}
                      </p>
                    </div>
                  </td>
                </tr>
                {companies
                  .filter(com => {
                    return com.Name !== 'Index Group'
                  })
                  .map(data => (
                    <tr className='' key={data.Id}>
                      <td style={{ verticalAlign: 'middle' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <span style={{ color: '#676767', fontWeight: '500' }}>{data.Name}</span>
                          <p
                            style={{
                              fontSize: '1.3rem',
                              color: '#00A843',
                              fontWeight: '600',
                              marginBottom: 0,
                              wordBreak: 'break-word',
                            }}
                          >
                            {data.Name === 'Index'
                              ? state.buttonMoneyChange === 'try'
                                ? state.paymentData.find(camp => {
                                  return camp.Name === '1000'
                                })?.AmountTL
                                  ? state.paymentData.find(camp => {
                                    return camp.Name === '1000'
                                  })?.AmountTL
                                  : '0.00 ₺'
                                : state.buttonMoneyChange === 'usd'
                                  ? state.paymentData.find(camp => {
                                    return camp.Name === '1000'
                                  })?.AmountUSD
                                    ? state.paymentData.find(camp => {
                                      return camp.Name === '1000'
                                    })?.AmountUSD
                                    : '0.00 $'
                                  : state.paymentData.find(camp => {
                                    return camp.Name === '1000'
                                  })?.AmountEURO
                                    ? state.paymentData.find(camp => {
                                      return camp.Name === '1000'
                                    })?.AmountEURO
                                    : '0.00 €'
                              : data.Name === 'Datagate'
                                ? state.buttonMoneyChange === 'try'
                                  ? state.paymentData.find(camp => {
                                    return camp.Name === '1100'
                                  })?.AmountTL
                                    ? state.paymentData.find(camp => {
                                      return camp.Name === '1100'
                                    })?.AmountTL
                                    : '0.00 ₺'
                                  : state.buttonMoneyChange === 'usd'
                                    ? state.paymentData.find(camp => {
                                      return camp.Name === '1100'
                                    })?.AmountUSD
                                      ? state.paymentData.find(camp => {
                                        return camp.Name === '1100'
                                      })?.AmountUSD
                                      : '0.00 $'
                                    : state.paymentData.find(camp => {
                                      return camp.Name === '1100'
                                    })?.AmountEURO
                                      ? state.paymentData.find(camp => {
                                        return camp.Name === '1100'
                                      })?.AmountEURO
                                      : '0.00 €'
                                : data.Name === 'Despec'
                                  ? state.buttonMoneyChange === 'try'
                                    ? state.paymentData.find(camp => {
                                      return camp.Name === '1200'
                                    })?.AmountTL
                                      ? state.paymentData.find(camp => {
                                        return camp.Name === '1200'
                                      })?.AmountTL
                                      : '0.00 ₺'
                                    : state.buttonMoneyChange === 'usd'
                                      ? state.paymentData.find(camp => {
                                        return camp.Name === '1200'
                                      })?.AmountUSD
                                        ? state.paymentData.find(camp => {
                                          return camp.Name === '1200'
                                        })?.AmountUSD
                                        : '0.00 $'
                                      : state.paymentData.find(camp => {
                                        return camp.Name === '1200'
                                      })?.AmountEURO
                                        ? state.paymentData.find(camp => {
                                          return camp.Name === '1200'
                                        })?.AmountEURO
                                        : '0.00 €'
                                  : state.buttonMoneyChange === 'try'
                                    ? state.paymentData.find(camp => {
                                      return camp.Name === '2300'
                                    })?.AmountTL
                                      ? state.paymentData.find(camp => {
                                        return camp.Name === '2300'
                                      })?.AmountTL
                                      : '0.00 ₺'
                                    : state.buttonMoneyChange === 'usd'
                                      ? state.paymentData.find(camp => {
                                        return camp.Name === '2300'
                                      })?.AmountUSD
                                        ? state.paymentData.find(camp => {
                                          return camp.Name === '2300'
                                        })?.AmountUSD
                                        : '0.00 $'
                                      : state.paymentData.find(camp => {
                                        return camp.Name === '2300'
                                      })?.AmountEURO
                                        ? state.paymentData.find(camp => {
                                          return camp.Name === '2300'
                                        })?.AmountEURO : "0.00 €"}
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='col-2'>
            <b className='d-block board-title'>
              Kampanya Kullanımı <span className='board-subtitle'> (Tekil Cari) </span>
            </b>
            <table className='table table-bordered dataTable XmlTable'>
              <tbody>
                <tr role='row'>
                  <td
                    style={{
                      width: '100px',
                      backgroundColor: '#F1F2F2',
                      height: 300,
                    }}
                    rowSpan={companies.length + 1}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: (companies.length - 1) * 75 + 'px',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span style={{ fontWeight: '500', color: '#616161' }}>Toplam</span>
                      <p
                        style={{
                          fontSize: '1.3rem',
                          color: '#00A843',
                          fontWeight: '600',
                        }}
                      >
                        {
                          state.campaignData.find(camp => {
                            return camp.Name === 'Toplam'
                          })?.Count
                        }
                      </p>
                    </div>
                  </td>
                </tr>
                {companies
                  .filter(com => {
                    return com.Name !== 'Index Group'
                  })
                  .map(data => (
                    <tr className='' key={data.Id}>
                      <td style={{ verticalAlign: 'middle' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <span style={{ color: '#676767', fontWeight: '500' }}>{data.Name}</span>
                          <p
                            style={{
                              fontSize: '1.3rem',
                              color: '#00A843',
                              fontWeight: '600',
                              marginBottom: 0,
                              wordBreak: 'break-word',
                            }}
                          >
                            {state.campaignData.findIndex(camp => {
                              return camp.Name === String(data.Id)
                            }) > -1
                              ? state.campaignData.find(camp => {
                                return camp.Name === String(data.Id)
                              })?.Count
                              : 0
                            }
                          </p>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='col-2'>
            <b className='d-block board-title'>
              Ort. Kullanım <span className='board-subtitle'>(Süre)</span>
            </b>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: 319,
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #E0E0E2',
                borderRadius: '5px',
                boxShadow: '0px 4px 90px rgba(163, 171, 185, 0.14)',
              }}
            >
              <span style={{ fontWeight: '500', color: '#616161' }}>Ortalama</span>
              <p
                style={{
                  fontSize: '1.3rem',
                  color: '#00A843',
                  fontWeight: '600',
                }}
              >
                {state.avgSessionDuration.slice(0, 8)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-6'>
          <h5>Hangi Sitelerden Trafik Çekiyoruz</h5>
          <div style={{
            width: "500px",
            height: "500px"
          }}>
            <TrafficChart values={state.trafficData} />
          </div>
        </div>
        <div className='col-6'>
          <h5>En Çok Tercih Edilen 10 Farklı Ürün Kategorisi</h5>
          <div style={{
            width: "500px",
            height: "500px"
          }}>
            <TrafficChart values={state.productData.map(x => ({ Name: x.CategoryName, Value: x.SumQuantity }))} />
          </div>

        </div>
      </div>
      <div className='row m-t-10'>
        <div className='col-12 col-md-3 mb-3'>
          <IPDatePicker
            selected={startDate}
            // @ts-ignore
            onChange={([startDate, endDate]) => {
              setStartDate(startDate)
              setEndDate(endDate)
            }}
            // @ts-ignore
            selectsRange
            startDate={startDate}
            endDate={endDate}
            dateFormat='MM/yyyy'
            minDate={new Date('2016')}
            maxDate={new Date()}
            showMonthYearPicker
          />
        </div>
        <div className='col-12 col-md-3 mb-3'>
          <IPButton text='Göster' onClick={postDashBoardOrderData} />
        </div>
        {/* <div className='col-12 col-md-3 mb-3'>
          <IPButton text='Tabloyu Göster' onClick={() => setState({ toggleTable: !state.toggleTable })} />
        </div> */}
      </div>
      <div className='row'>
        {
          state.dashBoardOrderData.length > 0 &&
          <div className='col-6'>
            <h5>Toplam Sipariş</h5>
            <CurrencyChart data={state.dashBoardOrderData} />
          </div>
        }
        <div className='col-6'>
          {/* {state.toggleTable && ( */}
          <div className='row '>
            <Table columns={columns} data={tableData} currentPage={0} />
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(Index)
