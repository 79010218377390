import React, { useState, useEffect } from 'react'
import IPButton from '../../../../components/IPButton'
import IPInput from '../../../../components/IPInput'
import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import { GetCheckBinNumberlApi } from './services/getCheckBinNumberApi'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { getCompanyApi } from '../../components/api'
import { ICompany } from '../../components/models/models'
import IPSelectBox from '../../../../components/IPSelectBox'
import { useCustomReducer } from '../../../../base/customHooks'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { IBIN } from './models/models'
import IPModal from '../../../../components/IPModal'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { GetWhiteDealerListApi } from '../../CreditCardPaymentCampaigns/Dealer/services/getWhiteDealerListApi'
import { BankListResponse } from '../../CreditCardPaymentCampaigns/Dealer/models/models'
import { AddBinNumberApi } from './services/AddBinNumberApi'
import { EditBinNumberApi } from './services/EditBinNumberApi'
import IPCheckbox from '../../../../components/IPCheckBox'

interface IBinState {
  binDetail: IBIN[]
  filterBinDetail: IBIN[]
  binNumber: string
  companies: ICompany[]
  filterCompanies: ICompany[]
  banks: ISelectOption[]
  bankOption?: ISelectOption
  selectedCompany?: ISelectOption,
  selectedStatus?: ISelectOption,
  selectedType?: ISelectOption,
  selectedInfo?: ISelectOption,
  newInfo?: ISelectOption,
  newType?: ISelectOption,
  newBIN?: string
  binAdd: boolean
  active?: number
}

const t = translate(Translations)

function Index(props: BaseComponentWithContextProps) {

  const getInitialState = (): IBinState => {
    return {
      binDetail: [],
      binNumber: "",
      companies: [],
      banks: [],
      filterBinDetail: [],
      selectedCompany: undefined,
      filterCompanies: [],
      binAdd: false,
      active: 1
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IBinState>(intialState)
  const location: any = useLocation()

  useEffect(() => {
    getCompanies()
    getBanks()
  }, [])


  const addBIN = async () => {
    try {
      props.showLoading();
      const req: IBIN = {
        Id: 0,
        BINCode: state.newBIN ?? '',
        BankName: state.bankOption?.label,
        BankRef: Number(state.bankOption?.value),
        Active: 1,
        CardType: Number(state.newType?.value),
        Flags: Number(state.newInfo?.value) || 0,
        Company: ''
      }

      if (location.state && location.state.binData) {
        req.Id = location.state.binData.Id
        req.Active = state.active ? 1 : 0
        const list: EditBinNumberApi = new EditBinNumberApi({})
        await list.EditBinNumber(req).then(res => {
          if (res) {
            Swal.fire({
              icon: 'success',
              title: res,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          }
        })
      }
      else {
        const list: AddBinNumberApi = new AddBinNumberApi({})
        await list.AddBinNumber(req).then(res => {
          if (res) {
            Swal.fire({
              icon: 'success',
              title: res,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            props.hideLoading()
          }
        })
      }

    } catch (err: any) {
      props.hideLoading();
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const getBanks = async () => {
    props.showLoading()
    try {
      const res = await new GetWhiteDealerListApi({}).getBanks()
      if (res) {
        const banks = res.map(x => ({ label: x.BankName, value: x.BankID }))
        if (!banks) return []
        if (location.state && location.state.binData) {
          setState({ newBIN: location.state.binData.BINCode })
          setState({ newType: filterType.find(a => a.value == location.state.binData.CardType) })
          setState({ newInfo: filterInfo.find(a => a.value == location.state.binData.Flags) })
          setState({ active: location.state.binData.Active })
          setState({ bankOption: banks.filter(a => a.label != "Diğer Bankalar" && a.value != 32901).find(a => a.value == location.state.binData.BankRef) })
        }
        props.hideLoading()
        return setState({ banks: banks.filter(a => a.label != "Diğer Bankalar" && a.value != 32901) })

      }
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }



  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    setState({ companies: company })
  }

  const filterType = [
    { label: 'Bireysel Banka', value: 1 },
    { label: 'Bireysel Kredi', value: 2 },
    { label: 'Ticari Banka', value: 4 },
    { label: 'Ticari Kredi', value: 5 },
    { label: 'Ön Ödemeli', value: 3 },
    { label: 'Bilinmiyor', value: 0 },
  ]

  const filterInfo = [
    { label: 'VISA', value: 1 },
    { label: 'MasterCard', value: 2 },
    { label: 'TROY', value: 3 },
    { label: 'Co-Badge', value: 4 },
    { label: 'AMEX', value: 5 },
    { label: 'Bilinmiyor', value: 0 },
  ]

  const navigate: any = useNavigate()

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span className='h4'>{'BIN Numarası Ekleme'}</span>
            <div className='form-label col-lg-2' style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div className='buttonsuz'>
                <Link
                  to={
                    (location.state?.url)
                      ? location.state.url : '/admin/CreditCardOperation/BIN'
                  }
                >
                  <IPButton
                    type='button'
                    className='btn btn-success'
                    text={'Listeye Dön'}
                  />
                </Link>
              </div>
            </div>
          </div>

          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label htmlFor='bayikodu' className='form-label'>
                    {t('pages.admin.CreditCardOperation.BIN.bankaname')}
                  </label>
                  <IPSelectBox
                    isClearable
                    id='Banks'
                    name='Banks'
                    options={state.banks}
                    value={state.bankOption}
                    onChangeSingle={(option) => setState({ bankOption: option })}
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='CardType' className='form-label'>
                    Kart Tipi
                  </label>
                  <IPSelectBox
                    isClearable
                    id='CardInfo'
                    name='CardInfo'
                    options={filterType}
                    value={state.newType}
                    onChangeSingle={(option) => setState({ newType: option })}
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='CardInfo' className='form-label'>
                    Kart Türü
                  </label>
                  <IPSelectBox
                    isClearable
                    id='CardInfo'
                    name='CardInfo'
                    options={filterInfo}
                    value={state.newInfo}
                    onChangeSingle={(option) => setState({ newInfo: option })}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='bayikodu' className='form-label'>
                    {t('pages.admin.CreditCardOperation.BIN.bınno')}
                  </label>
                  <div className='binno'>
                    <IPInput type='text'
                      className='form-control'
                      id='binno'
                      value={state.newBIN}
                      onChange={e => {
                        setState({ newBIN: e.target.value })
                      }}
                    />
                  </div>
                </div>
                <div className='col-lg-1'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Aktiflik
                  </label>
                  <div className='active'>
                    <IPCheckbox type='text'
                      name='Active'
                      onClick={() => setState({ active: state.active === 1 ? 0 : 1 })}
                      checked={state.active === 1 ? true : false}
                      label={'Aktif'}
                    />
                  </div>
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={location.state && location.state.binData ? 'Güncelle' : 'Ekle'}
                        onClick={addBIN}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div >
  )
}

export default componentWithContext(Index)