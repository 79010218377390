import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IAddress, AddAddressRequest, IAddressModel } from '../models/models'

export class AddAddressApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'address/add',
      requesterInfo,
    })
  }

  public async addAddress(request: AddAddressRequest): Promise<IAddressModel> {
    return await this.postAsync<IAddressModel>(request)
  }
}
