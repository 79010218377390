import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { DealerDetailForPagePathRequest, DealerDetailForPagePathResponse } from './models'

export class SiteNavigationService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/GA4Reporting/DealerDetailForPagePath',
      requesterInfo,
    })
  }

  public async DealerDetailForPagePath(
    req: DealerDetailForPagePathRequest
  ): Promise<DealerDetailForPagePathResponse[]> {
    return await this.postAsync<DealerDetailForPagePathResponse[]>(req)
  }
}
