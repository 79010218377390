import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IGetMyOrders } from '../models/request/getMyOrderRequest'
import { IGetMyOrdersResponse } from '../models/response/getOrderListResponseModel'

export class GetMyOrdersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'OrdersHistory/GetMyOrders',
      requesterInfo,
    })
  }

  public async getMyOrders(request: IGetMyOrders): Promise<IGetMyOrdersResponse[]> {
    return await this.postAsync<IGetMyOrdersResponse[]>(request)
  }
}
