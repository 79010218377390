import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { ILicenseTracking } from '../models/license'

export class GetLicenseApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetLicenseTracking',
      requesterInfo,
    })
  }

  public async getLicense(): Promise<ILicenseTracking[]> {
    return await this.postAsync<ILicenseTracking[]>()
  }
}
