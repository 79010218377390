import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IProductIsActive } from '../models/models'

export class IsProductActiveService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/isProductActive',
      requesterInfo,
    })
  }

  public async isProductActive(productCode: string): Promise<IProductIsActive[]> {
    this.props.url = `${this.props.url}?productCode=${productCode}`

    return await this.getAsync<IProductIsActive[]>()
  }
}
