import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'

export class DeleteParamPosInsatallmentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/paramPosTransaction/deleteParamPosInsatallment',
      requesterInfo,
    })
  }

  public async deleteParamPosInsatallment(request: string): Promise<CommonPostResponse> {
    this.props.url = `${this.props.url}?guid=${request}`
    return await this.postAsync<CommonPostResponse>()
  }
}
