import React, { useEffect, useRef, useState } from 'react'
import IPInput from '../../../../../components/IPInput'
import Button from '../../../../../components/IPButton/IPButton'
import Swal from 'sweetalert2'
import IPButton from '../../../../../components/IPButton/IPButton'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ISurvey, ISurveyQuestion, ISurveyAnswer, OptionTypeEnum } from '../models/index'
import { uuidv4 } from '../../../../../base/utils'
import { AddPollAdminApi } from '../services/addPollAdminApi'
import { UpdatePollAdminApi } from '../services/updatePollAdminApi'
import { IException } from '../../../../../base/models'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import IPCheckbox from '../../../../../components/IPCheckBox'
import _ from 'lodash'
import './style.scss'
import InputCampaign from '../../../ProductOperation/CampaignBundleCrud/components/InputCampaign'
import { useCustomReducer } from '../../../../../base/customHooks'
import { excelToJSON } from '../../../../../base/utils/dataHelper'
import { DealerService } from '../../../ProductOperation/CampaignBundleCrud/services/DealerService'
import { DealerType } from '../../../ProductOperation/CampaignBundleCrud/models/CampaignBundleModel'
import { GetSubTypesApi } from '../../../components/services/getSubTypesApi'
import CampaignSelect from '../../../ProductOperation/IyzicoCampaign/CampaignSelect'
import IPFile from '../../../../../components/IPFile'

enum CariListType {
  CariCode,
  CariType,
  CariAltType,
}

interface IAddProductColorState {
  dealerInclude: boolean
  dealerTypeInclude: boolean
  dealerSubTypeInclude: boolean
  selectedDealerList?: CariListType
  dealerCodeList: ISelectOption[]
  dealerTypeList: ISelectOption[]
  dealerSubTypeList: ISelectOption[]
  dealerTypeOptions: ISelectOption[]
  dealerSubTypeOptions: ISelectOption[]
  getSubTypesBool: boolean
  getDealerTypesBool: boolean
  history: boolean
}

const AddPoll = () => {
  const routeLocation = useLocation()
  let navigate = useNavigate()

  const [initialSurvey, setInitialSurvey] = useState<ISurvey>(
    Object.assign({
      Category: '' as string,
      StartDate: new Date(),
      EndDate: new Date(),
      Questions: [
        {
          Guid: uuidv4(),
          Id: 0,
          Enabled: true,
          Answers: [
            {
              QuestionId: 0,
              Enabled: true,
              Guid: uuidv4(),
              Id: 0,
            },
          ],
        },
      ],
    })
  )

  const getInitialState = (): IAddProductColorState => {
    return {
      dealerInclude: true,
      dealerTypeInclude: true,
      dealerSubTypeInclude: true,
      dealerCodeList: [],
      dealerTypeList: [],
      dealerSubTypeList: [],
      dealerTypeOptions: [],
      dealerSubTypeOptions: [],
      selectedDealerList: CariListType.CariCode,
      getSubTypesBool: false,
      getDealerTypesBool: false,
      history: false
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IAddProductColorState>(intialState)

  const [survey, setSurvey] = useState<ISurvey>(initialSurvey)

  const selectTypes: ISelectOption[] = [
    { label: 'Tek Seçim', value: OptionTypeEnum.Radio },
    { label: 'Çoklu Seçim', value: OptionTypeEnum.CheckBox },
    { label: 'Serbest Metin', value: OptionTypeEnum.Input },
    { label: 'Tekli Seçim ve Serbest Metin', value: OptionTypeEnum.RadioAndInput },
    { label: 'Çoklu Seçim ve Serbest Metin', value: OptionTypeEnum.CheckboxAndInput },
  ]
  const [selectedType, setSelectedType] = useState<ISelectOption>()
  const [buttonName, setButtonName] = useState("Ekle")
  const addBtnRef = useRef(null)

  useEffect(() => {
    if (routeLocation.state as any && state.getSubTypesBool && state.getDealerTypesBool) {
      const toBeUpdatedSurvey: ISurvey = (routeLocation.state as any)?.surveyData
      setSurvey(toBeUpdatedSurvey)
      let dealerList: { label: string; value: string }[] = []
      if (routeLocation.state.surveyData.DealerCodes.length > 0) {
        routeLocation.state.surveyData.DealerCodes.toString()
          .split(',')
          .forEach((word: any) => {
            const cleanedWord = word.toString().replace('0000', '')
            if (!dealerList.some(item => item.label === cleanedWord)) {
              const newItem = { label: cleanedWord, value: cleanedWord }
              dealerList.push(newItem)
            }
          })
      }
      setState({
        dealerInclude: routeLocation.state.surveyData.DealerCodesInclude,
        dealerTypeInclude: routeLocation.state.surveyData.DealerTypesInclude,
        dealerSubTypeInclude: routeLocation.state.surveyData.DealerSubTypesInclude,
        dealerCodeList: dealerList,
        dealerTypeList: state.dealerTypeOptions.filter(option =>
          routeLocation.state.surveyData.DealerTypes.toString().split(',').includes(option.value)
        ),
        dealerSubTypeList: state.dealerSubTypeOptions.filter(option =>
          routeLocation.state.surveyData.DealerSubTypes.toString().split(',').includes(option.value)
        ),
      })
      setButtonName("Güncelle")
    }
  }, [
    state.getSubTypesBool,
    state.getDealerTypesBool,
  ])

  const handleQuestionLabelChange = (e: any, questionItem: ISurveyQuestion) => {
    const { value } = e.target
    const tmpSurvey = _.clone(survey)

    questionItem.Text = value
    setSurvey(tmpSurvey)
  }

  const handleAnswersChange = (e, answerItem: ISurveyAnswer, questionItem: ISurveyQuestion) => {
    const tmpSurvey = _.clone(survey)
    const { value } = e.target

    if (selectedType?.label === 'Text') {
      tmpSurvey.Questions[questionItem.Id].OptionType = 2
      tmpSurvey.Questions[questionItem.Id].Answers = []
    } else {
      if (selectedType?.label === 'Tek Seçim')
        (tmpSurvey.Questions.find(x => x.Id === questionItem.Id) as ISurveyQuestion).OptionType = 0
      if (selectedType?.label === 'Çoklu Seçim')
        (tmpSurvey.Questions.find(x => x.Id === questionItem.Id) as ISurveyQuestion).OptionType = 1

      if (answerItem.Id > 0 && questionItem.Id > 0)
        (tmpSurvey.Questions.find(x => x.Id === questionItem.Id) as any).Answers.find(
          a => a.Id === answerItem.Id
        ).Options = value
      else if (answerItem.Id > 0) {
        (tmpSurvey.Questions.find(x => x.Guid === questionItem.Guid) as any).Answers.find(
          a => a.Id === answerItem.Id
        ).Options = value
      }
      else if (questionItem.Id > 0) {
        (tmpSurvey.Questions.find(x => x.Id === questionItem.Id) as any).Answers.find(
          a => a.Guid === answerItem.Guid
        ).Options = value
      }
      else
        (tmpSurvey.Questions.find(x => x.Guid === questionItem.Guid) as any).Answers.find(
          a => a.Guid === answerItem.Guid
        ).Options = value
    }
    setSurvey(tmpSurvey)
  }

  const addQuestionField = () => {
    const tmpSurvey = _.clone(survey)

    if (tmpSurvey.Questions && tmpSurvey.Questions.length > 0) {
      tmpSurvey.Questions.push(
        Object.assign({
          Guid: uuidv4(),
          Id: 0,
          IsInput: false,
          OptionType: OptionTypeEnum.Radio,
          Answers: [
            {
              QuestionId: 0,
              Enabled: true,
              Guid: uuidv4(),
              Id: 0,
            },
          ],
        })
      )
    } else {
      tmpSurvey.Questions = [Object.assign({
        Guid: uuidv4(),
        Id: 0,
        IsInput: false,
        OptionType: OptionTypeEnum.Radio,
        Answers: [
          {
            QuestionId: 0,
            Enabled: true,
            Guid: uuidv4(),
            Id: 0,
          },
        ],
      })]
    }

    setSurvey(tmpSurvey)
  }

  const addAnswerField = (questionItem: ISurveyQuestion) => {
    const tmpSurvey = _.clone(survey)

    if (tmpSurvey.Questions && tmpSurvey.Questions.length > 0) {
      const question = tmpSurvey.Questions.find((question: ISurveyQuestion) =>
        [undefined, null, 0].indexOf(question.Id) === -1
          ? question.Id === questionItem.Id
          : question.Guid === questionItem.Guid
      )
      if (question) {
        const data = question?.Answers;
        data.push(
          Object.assign({
            Guid: uuidv4(),
            Id: 0,
            QuestionId: question.Id,
            Enabled: false,
            InputText: '',
          })
        )
        question.Answers = data
      }
      setSurvey(tmpSurvey)
    }
  }

  const removeQuestion = (questionItem: ISurveyQuestion) => {
    const tmpSurvey = _.clone(survey)
    const questions: ISurveyQuestion[] = []
    if (tmpSurvey.Questions && tmpSurvey.Questions.length > 0) {
      if (tmpSurvey.Questions.length === 1) {
        return Swal.fire({
          icon: 'warning',
          title: 'En az 1 tane soru olmalıdır silemezsiniz!',
          allowOutsideClick: true,
          backdrop: true,
        })
      }
      const dataAns: ISurveyAnswer[] = [];
      tmpSurvey.Questions.map((que: ISurveyQuestion) => {
        if (questionItem.Id > 0 && que.Id === questionItem.Id) {
          que.Deleted = true;
          que.Enabled = false;
          que.Answers.map((ans) => {
            ans.Deleted = true;
            ans.Enabled = false;
            dataAns.push(ans);
          })
          que.Answers = dataAns;
          //   return questions.push({
          //     Id: que.Id,
          //     Guid: que.Guid,
          //     SurveyId: que.SurveyId,
          //     OptionType: que.OptionType,
          //     Text: que.Text,
          //     IsInput: que.IsInput,
          //     CreatedDate: que.CreatedDate,
          //     Deleted: true,
          //     Survey: que.Survey,
          //     Enabled: que.Enabled,
          //     Answers: que.Answers,
          //   })
          // }
        }
        else if (questionItem.Id === 0 && que.Guid === questionItem.Guid)
          return true
        return questions.push(que)
      })
      tmpSurvey.Questions = questions
    }

    setSurvey(tmpSurvey)
  }

  const removeAnswer = (questionItem: ISurveyQuestion, answerItem: ISurveyAnswer) => {
    const tmpSurvey = _.clone(survey)

    if (tmpSurvey.Questions && tmpSurvey.Questions.length > 0) {
      const question = tmpSurvey.Questions.find((question: ISurveyQuestion) =>
        questionItem.Id > 0
          ? question.Id === questionItem.Id
          : question.Guid === questionItem.Guid
      )

      if (question && question.Answers && question.Answers.length > 0) {
        if (question.Answers.length === 1) {
          return Swal.fire({
            icon: 'warning',
            title: 'En az 1 tane cevap olmalıdır silemezsiniz!',
            allowOutsideClick: true,
            backdrop: true,
          })
        }
        const answer: ISurveyAnswer[] = []
        question.Answers.map((ans: ISurveyAnswer) => {
          if (answerItem.Id > 0 && ans.Id === answerItem.Id)
            ans.Deleted = true;
          else if (answerItem.Id === 0 && ans.Guid === answerItem.Guid)
            return true
          return answer.push(ans)
        })
        question.Answers = answer
      }
    }

    setSurvey(tmpSurvey)
  }

  const onChangeSurveyCategoryName = (e, _survey: ISurvey) => {
    const { value } = e.target
    _survey.Category = value
  }

  const onChangeSelectType = (option: ISelectOption, questionId: number, questionGuid: string, survey: ISurvey) => {
    const tmpSurvey = _.clone(survey)
    const data: ISurveyQuestion[] = []
    tmpSurvey.Questions.map((que) => {
      if ((questionId && questionId === que.Id) || (questionGuid && questionGuid === que.Guid))
        que.OptionType = Number(option.value);
      if (option.value === OptionTypeEnum.Input) {
        que.Answers.map((ans) => {
          if (questionId > 0) {
            if (ans.QuestionId === questionId)
              ans.Deleted = true
          }
        }
        )
      }
      data.push(que)
    })


    tmpSurvey.Questions = data
    setSurvey(tmpSurvey)
  }

  const onQuestionEnabledCheckChange = (checked: boolean, questionId: number, questionGuid: string, survey: ISurvey) => {
    const tmpSurvey = _.clone(survey)
    const data: ISurveyQuestion[] = []
    tmpSurvey.Questions.map((que) => {
      if ((questionId && questionId === que.Id) || (questionGuid && questionGuid === que.Guid))
        que.Enabled = checked
      data.push(que)
    })
    tmpSurvey.Questions = data
    setSurvey(tmpSurvey)
  }

  const onAnswerEnabledCheckChange = (
    checked: boolean,
    questionId: number,
    questionGuid: string,
    answerId: number,
    answerGuid: string,
    survey: ISurvey
  ) => {
    const tmpSurvey = _.clone(survey)
    tmpSurvey.Questions.map((que) => {
      if ((questionId && que.Id === questionId) || questionGuid === que.Guid) {
        que.Answers.map((ans) => {
          if (answerId > 0) {
            if (ans.Id === answerId)
              return ans.Enabled = checked;
          }
          else if (answerGuid === ans.Guid)
            return ans.Enabled = checked;
          else
            return true
        })
      }
    })
    setSurvey(tmpSurvey)
  }

  const handleSubmit = () => {
    survey.DealerCodes = state.dealerCodeList.map(a => '0000' + a.value.toString())
    survey.DealerCodesInclude = state.dealerInclude
    survey.DealerTypes = state.dealerTypeList.map(a => a.value.toString())
    survey.DealerTypesInclude = state.dealerTypeInclude
    survey.DealerSubTypes = state.dealerSubTypeList.map(a => a.value.toString())
    survey.DealerSubTypesInclude = state.dealerSubTypeInclude

    if (survey.Questions.length === 0) {
      return Swal.fire({
        icon: 'error',
        title: 'Lütfen soru giriniz',
        allowOutsideClick: true,
        backdrop: true,
      })
    }
    if (survey.Category.length === 0) {
      return Swal.fire({
        icon: 'error',
        title: 'Lütfen anket adı giriniz',
        allowOutsideClick: true,
        backdrop: true,
      })
    }
    if (survey.Id) {
      const api: UpdatePollAdminApi = new UpdatePollAdminApi({})
      api
        .updateSurvey(survey)
        .then((res: boolean) => {
          Swal.fire({
            icon: 'success',
            title: 'Anket başarılı bir şekilde güncellenmiştir.',
            allowOutsideClick: true,
            backdrop: true,
          }).then(() => {
            navigate('/admin/site-operation/pollList')
          })
        })
        .catch((err: IException) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            allowOutsideClick: true,
            backdrop: true,
          })
        })
    } else {
      const api: AddPollAdminApi = new AddPollAdminApi({})
      api
        .addSurvey(survey)
        .then((res: boolean) => {
          Swal.fire({
            icon: 'success',
            title: 'Yeni anket başarılı bir şekilde eklenmiştir.',
            allowOutsideClick: true,
            backdrop: true,
          }).then(() => {
            navigate('/admin/site-operation/pollList')
          })
        })
        .catch((err: IException) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            allowOutsideClick: true,
            backdrop: true,
          })
        })
    }
  }


  const handleFiles = async (files: File[], type: 'DealerCode' | 'DealerType' | 'DealerSubType') => {
    const dealerCodes: any = await excelToJSON(files[0])

    if (type === 'DealerCode') {
      setDealerCodes(dealerCodes[0], 'dealerCodeOptions', 'XDealerCodeList')
      return
    }
    if (type === 'DealerType') {
      setDealerCodes(dealerCodes[0], 'dealerTypeOptions', 'XDealerTypeList')
      return
    }
    if (type === 'DealerSubType') {
      setDealerCodes(dealerCodes[0], 'dealerSubTypeOptions', 'XDealerSubTypeList')
      return
    }
  }

  const enumFunction = (takenState, takenEnum) => {
    return takenState === takenEnum ? 'active' : ''
  }


  const setDealerCodes = (dealerCodes, optionArr, fieldValue) => {
    if (fieldValue === 'XDealerCodeList') {
      const codeData = dealerCodes.map(item => ({
        label: String(item.DealerCode),
        value: String(item.DealerCode),
      }))

      let list: { label: string; value: string }[] = []

      codeData.forEach(codeItem => {
        if (!state.dealerCodeList.some((item: any) => item.label === String(codeItem.label))) {
          list.push(codeItem)
        }
      })

      setState({
        dealerCodeList: [
          ...state.dealerCodeList,
          ...list.filter((item, index, self) => {
            return index === self.findIndex(i => i.label === item.label)
          }),
        ],
      })
    } else if (fieldValue === 'XDealerTypeList') {
      let list: any = []
      let codeData: any = []
      dealerCodes.forEach(item => {
        const codes: any = state[optionArr]?.find((code: any) => {
          if (item.DealerCode < 6) {
            return code.value === item.DealerCode
          } else {
            return code.value === '0000' + item.DealerCode
          }
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push(codes)
      })
      if (codeData.length === 0) return setState({ dealerTypeList: list })

      Swal.fire({
        icon: 'question',
        title: `${codeData.map(dt => {
          return dt
        })} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) {
          return
        }
        return setState({ dealerTypeList: list })
      })
    } else if (fieldValue === 'XDealerSubTypeList') {
      let list: any = []
      let codeData: any = []
      dealerCodes.forEach(item => {
        const codes: any = state[optionArr]?.find((code: any) => {
          if (item.DealerCode < 6) {
            return code.value === item.DealerCode
          } else {
            return code.value === '0000' + item.DealerCode
          }
        })
        if (codes === undefined) codeData.push(item.DealerCode)
        else list.push(codes)
      })
      if (codeData.length === 0) return setState({ dealerSubTypeList: list })

      Swal.fire({
        icon: 'question',
        title: `${codeData.map(dt => {
          return dt
        })} bu cariler bulunamadı. Yeniden yüklemek ister misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) {
          return
        }
        return setState({ dealerSubTypeList: list })
      })
    }
  }



  const onCreateOption = (option, updateFunc) => {
    updateFunc(prevState => {
      const newData = {
        value: option[option.length - 1].value,
        label: option[option.length - 1].label,
      }
      if (prevState && prevState.length > 0) {
        for (const state of prevState) {
          if (state.value === newData.value) return [...prevState]
          return [...prevState, newData]
        }
      }
      return [newData]
    })
  }

  const [inputValue, setInputValue] = useState('')

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleAddValue = () => {
    if (inputValue.trim() !== '') {
      const words = inputValue.split(' ')
      let dealerList: { label: string; value: string }[] = []
      words.forEach((word: any) => {
        if (!state.dealerCodeList.some((item: any) => item.label === word)) {
          const newItem = { label: word, value: word }
          dealerList.push(newItem)
        }
      })
      setState({ dealerCodeList: [...state.dealerCodeList, ...dealerList] })
      setInputValue('')
    }
  }

  const handleInputKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleAddValue()
    }
  }


  const getDealerTypes = async () => {
    const dealerService = new DealerService({})
    const res: any = await dealerService.getDealerTypes()
    if (res) {
      const dealerTypeOptions: ISelectOption[] = []
      res.forEach((item: DealerType) => {
        item && dealerTypeOptions.push({ value: item.MusteriGrubu, label: item.MusteriGrubuAciklama })
      })
      setState({ dealerTypeOptions })
    }
    setState({ getDealerTypesBool: true })
  }

  const getSubTypes = async () => {
    const getSubTypes: GetSubTypesApi = new GetSubTypesApi({})
    const res = await getSubTypes.getSubTypes()

    if (res) {
      const dealerSubTypeOptions: ISelectOption[] = []
      res.forEach((item: any) => {
        item && dealerSubTypeOptions.push({ value: item, label: item })
      })
      setState({ dealerSubTypeOptions })
    }
    setState({ getSubTypesBool: true })
  }


  useEffect(() => {
    getSubTypes()
    getDealerTypes()
  }, [])


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Anket {buttonName}me</span>
            <Link to='/admin/site-operation/pollList'>
              <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
            </Link>
          </div>
          <div className='card-body'>
            <form id='id1' className='formform' noValidate>
              <div className='form-row mt-5'>
                <div className='form-group col-md-4'>
                  <IPInput
                    type='text'
                    value={survey.Category}
                    onChange={e => onChangeSurveyCategoryName(e, survey)}
                    className='Title'
                    placeholder='Anket Başlığı'
                  />
                </div>
                <div className='form-group col-md-4'>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={'Yayın Başlangıç Tarihi'}
                    value={survey.StartDate.toString()}
                    onChange={(e: React.ChangeEvent<any>) => {
                      setSurvey({
                        ...survey,
                        StartDate: e.target.value,
                      })
                    }}
                    isActive
                  />
                </div>
                <div className='form-group col-md-4'>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={'Yayın Bitiş Tarihi'}
                    value={survey.EndDate.toString()}
                    onChange={(e: React.ChangeEvent<any>) => {
                      setSurvey({
                        ...survey,
                        EndDate: e.target.value,
                      })
                    }}
                    isActive
                  />
                </div>
              </div>
              <div>
                <div className='formCard'>
                  <div className='cariFilterContainer'>
                    <div className='d-flex gap-4'>
                      <div className='leftContainer w-50'>
                        <div>
                          <a className='campaignExcelBtn' href='/assetWeb/docs/dealer-codes-example.xlsx' target='_blank'>
                            Örnek Excel
                          </a>

                          <div className='listBtnGroup'>
                            <IPButton
                              className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariCode)}`}
                              text={`Cari Kodu Listesi ${state.dealerCodeList?.length > 0 ? '✅' : ''}`}
                              onClick={() => setState({ selectedDealerList: CariListType.CariCode })}
                            />
                            <IPButton
                              className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariType)}`}
                              text={`Cari Tipi Listesi ${state.dealerTypeList?.length > 0 ? '✅' : ''}`}
                              onClick={() => setState({ selectedDealerList: CariListType.CariType })}
                            />
                            <IPButton
                              className={`listCariBtn ${enumFunction(state.selectedDealerList, CariListType.CariAltType)}`}
                              text={`Cari Alt Tipi Listesi ${state.dealerSubTypeList?.length > 0 ? '✅' : ''}`}
                              onClick={() =>
                                setState({
                                  selectedDealerList: CariListType.CariAltType,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className='p-0 m-0'>
                          <div
                            className={`cariFilterTextAreaWrap ${enumFunction(
                              state.selectedDealerList,
                              CariListType.CariCode
                            )}`}
                            style={{ width: '120%' }}
                          >
                            <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                              <IPFile
                                disabled={state.history}
                                id='FileInputDealerCode'
                                multiple={false}
                                handleFiles={e => handleFiles(e, 'DealerCode')}
                                removeAfterSelect={true}
                                className='campaignFileInput'
                                label="Excel'den Yükle"
                              />

                              <IPButton
                                disabled={state.history}
                                text={'Dahil'}
                                className={`btnIncluded ${state.dealerInclude ? 'active' : ''}`}
                                onClick={() => setState({ dealerInclude: true })}
                                style={{ marginLeft: '10px' }}
                              />
                              <IPButton
                                disabled={state.history}
                                text={'Hariç'}
                                className={`btnExcept ${!state.dealerInclude ? 'active' : ''}`}
                                onClick={() => setState({ dealerInclude: false })}
                              />
                            </div>
                            <CampaignSelect
                              placeholder={(routeLocation.state as any) ? state.dealerCodeList : 'Cari Kod Listesi'}
                              height={180}
                              //options={state.dealerCodeOptions}
                              onChangeMulti={(options: ISelectOption[]) => {
                                if ((routeLocation.state as any)?.data !== 'history') setState({ dealerCodeList: options })
                              }}
                              value={state.dealerCodeList}
                            />
                            <input
                              disabled={(routeLocation.state as any)?.data === 'history' && true}
                              type='text'
                              placeholder='Cari kodu yazın'
                              value={inputValue}
                              onChange={handleInputChange}
                              onKeyPress={handleInputKeyPress}
                            />
                          </div>

                          <div
                            className={`cariFilterTextAreaWrap ${enumFunction(
                              state.selectedDealerList,
                              CariListType.CariType
                            )}`}
                            style={{ width: '120%' }}
                          >
                            <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                              <div className='btns'>
                                <IPFile
                                  id='FileInputDealerType'
                                  multiple={false}
                                  handleFiles={e => handleFiles(e, 'DealerType')}
                                  removeAfterSelect={true}
                                  className='campaignFileInput'
                                  label="Excel'den Yükle"
                                />
                              </div>
                              <IPButton
                                disabled={state.history}
                                text={'Dahil'}
                                className={`btnIncluded ${state.dealerTypeInclude ? 'active' : ''}`}
                                onClick={() => setState({ dealerTypeInclude: true })}
                                style={{ marginLeft: '10px' }}
                              />
                              <IPButton
                                disabled={state.history}
                                text={'Hariç'}
                                className={`btnExcept ${!state.dealerTypeInclude ? 'active' : ''}`}
                                onClick={() => setState({ dealerTypeInclude: false })}
                              />
                            </div>
                            <CampaignSelect
                              placeholder={'Cari Tipi Listesi'}
                              height={180}
                              options={state.dealerTypeOptions}
                              onChangeMulti={(options: ISelectOption[]) => {
                                if ((routeLocation.state as any)?.data != 'history') setState({ dealerTypeList: options })
                              }}
                              value={state.dealerTypeList}
                            />
                          </div>

                          <div
                            className={`cariFilterTextAreaWrap ${enumFunction(
                              state.selectedDealerList,
                              CariListType.CariAltType
                            )}`}
                            style={{ width: '120%' }}
                          >
                            <div className='topBtnGroup d-flex justify-content-center align-items-center mb-4'>
                              {/* <div className='btns'>
												<IPFile id='FileInputDealerSubType' multiple={false} handleFiles={e => handleFiles(e, 'DealerSubType')} removeAfterSelect className='campaignFileInput' label="Excel'den Yükle" />
											</div> */}
                              <IPButton
                                disabled={state.history}
                                text={'Dahil'}
                                className={`btnIncluded ${state.dealerSubTypeInclude ? 'active' : ''}`}
                                onClick={() => setState({ dealerSubTypeInclude: true })}
                                style={{ marginLeft: '10px' }}
                              />
                              <IPButton
                                disabled={state.history}
                                text={'Hariç'}
                                className={`btnExcept ${!state.dealerSubTypeInclude ? 'active' : ''}`}
                                onClick={() => setState({ dealerSubTypeInclude: false })}
                              />
                            </div>
                            <CampaignSelect
                              placeholder={'Cari Alt Tipi Listesi'}
                              height={180}
                              options={state.dealerSubTypeOptions}
                              onChangeMulti={(options: ISelectOption[]) => {
                                if ((routeLocation.state as any)?.data != 'history') setState({ dealerSubTypeList: options })
                              }}
                              value={state.dealerSubTypeList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='form-group form-fg'>
                <div className='row'>
                  <div className='form-group'>
                    <div className='row mb-1 mt-3'>
                      <div className='col-1'>
                        <label htmlFor='inputAddress' style={{ fontSize: '1.6rem' }}>
                          Sorular
                        </label>
                      </div>
                      <div className='col-3'>
                        <IPButton
                          style={{ marginLeft: '1rem' }}
                          onClick={e => addQuestionField()}
                          className='addBtn col-3'
                          text='Soru Ekle'
                        />
                      </div>
                    </div>
                    <div className='container'>
                      <div className='row'>
                        <div className='col-12 mt-3 pt-3'>
                          {survey.Questions &&
                            survey.Questions.filter((que) => { return !que.Deleted }).map((questionItem: ISurveyQuestion, questionIndex: number) => {
                              return (
                                <div key={questionIndex}>
                                  <div
                                    className='row mb-3 p-3'
                                    key={questionItem.Guid}
                                    style={{ border: '1px solid #dedede' }}
                                  >
                                    <div className='col-6'>
                                      <label>Soru {questionIndex + 1}</label>
                                      <IPInput
                                        type='text'
                                        name='questionText'
                                        value={questionItem.Text}
                                        onChange={e => handleQuestionLabelChange(e, questionItem)}
                                        className='w-75 ml-5'
                                      />
                                    </div>
                                    <div className='col-3'>
                                      <IPSelectBox
                                        id='selectedTypes'
                                        name='selectedTypes'
                                        value={selectTypes.filter(
                                          (opt: ISelectOption) => opt.value === questionItem.OptionType
                                        )}
                                        options={selectTypes}
                                        onChangeSingle={(option: ISelectOption) => {
                                          onChangeSelectType(option, questionItem.Id, questionItem.Guid, survey)
                                        }}
                                        placeholder={'Seçim Türü'}
                                      />
                                    </div>
                                    <div className='col-1'>
                                      <IPCheckbox
                                        label={'Aktif'}
                                        checked={questionItem.Enabled}
                                        id={`Enabled${questionItem.Id}`}
                                        name={`Enabled${questionItem.Id}`}
                                        disabled={false}
                                        onChange={e => {
                                          onQuestionEnabledCheckChange(e.target.checked, questionItem.Id, questionItem.Guid, survey)
                                        }}
                                      />
                                    </div>
                                    <div className='col-2'>
                                      <IPButton
                                        style={{ width: '1rem' }}
                                        type='button'
                                        text='Sil'
                                        onClick={e => {
                                          removeQuestion(questionItem)
                                        }}
                                      />
                                    </div>
                                    {questionItem.OptionType !== OptionTypeEnum.Input && (
                                      <div className='form-group mt-5'>
                                        <div className='row'>
                                          <div className='col-2'>
                                            <label htmlFor='inputAddress2' style={{ fontSize: '1.6rem' }}>
                                              Cevaplar
                                            </label>
                                          </div>
                                          <div className='col-2'>
                                            <Button
                                              ref={addBtnRef}
                                              className='addBtn col-2'
                                              text='Cevap Ekle'
                                              onClick={e => {
                                                addAnswerField(questionItem)
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div className='row mb-3 p-3'>
                                          <div className='container'>
                                            <div className='col-12'>
                                              {questionItem.Answers &&
                                                questionItem.Answers.filter((ans) => { return !ans.Deleted && !ans.IsInput }).map(
                                                  (answerItem: ISurveyAnswer, answerIndex: number) => {
                                                    return (
                                                      <div className='row mb-3 p-3' key={answerItem.Guid}>
                                                        <div className='col-9'>
                                                          <label>Cevap {answerIndex + 1}</label>
                                                          <IPInput
                                                            value={answerItem.Options}
                                                            onChange={e => {
                                                              handleAnswersChange(e, answerItem, questionItem)
                                                            }}
                                                            type='text'
                                                            className='w-75 ml-5'
                                                            name='answerText'
                                                          />
                                                        </div>
                                                        <div className='col-1'>
                                                          <IPCheckbox
                                                            label={'Aktif'}
                                                            id={`EnabledAnswer${answerItem.Id}`}
                                                            name={`EnabledAnswer${answerItem.Id}`}
                                                            checked={answerItem.Enabled}
                                                            onChange={e => {
                                                              onAnswerEnabledCheckChange(
                                                                e.target.checked,
                                                                questionItem.Id,
                                                                questionItem.Guid,
                                                                answerItem.Id,
                                                                answerItem.Guid,
                                                                survey
                                                              )
                                                            }}
                                                          />
                                                        </div>
                                                        <div className='col-2'>
                                                          <IPButton
                                                            style={{ width: '1rem' }}
                                                            type='button'
                                                            text='Sil'
                                                            onClick={e => {
                                                              removeAnswer(questionItem, answerItem)
                                                            }}
                                                          />
                                                        </div>
                                                      </div>
                                                    )
                                                  }
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='d-flex'>
                    <div className='container'>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'end',
                        }}
                        className='mt-5 mb-5'
                      >
                        <IPButton
                          type='button'
                          onClick={() => handleSubmit()}
                          className='btn btn-success float-right col-md-2'
                          text={buttonName}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div >
    </div >
  )
}

export default AddPoll
