import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IUserPermissionModel } from '../models'

export class AddUserPermissionsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/userPermission/AddUserActionList',
      requesterInfo,
    })
  }

  public async addUserPermissions(userId: number, request: IUserPermissionModel[]): Promise<any[]> {
    this.props.url = `${this.props.url}/${userId}`
    return await this.postAsync<any[]>(request)
  }
}
