import * as yup from 'yup'

const validations = yup.object().shape({
  CompanyName: yup.string().min(3, 'En az 3 karakter girilmelidir!').required('Lütfen Şirket Adı alanını doldurunuz!'),
  Address: yup.string().min(3, 'En az 3 karakter girilmelidir!').required('Lütfen Adres alanını doldurunuz!'),
  PostCode: yup
    .string()
    .min(5, 'En az 5 karakter girilmelidir!')
    .max(5, 'En fazla 5 karakter girilmelidir!')
    .required('Lütfen Posta Kodu alanını doldurunuz!'),
  Country: yup.object().shape({
    label: yup.string().required('Ülke seçmediniz!'),
    value: yup.string().required('Ülke seçmediniz!'),
  }),
  MobilePhone: yup
    .string()
    .test('len', 'Telefon numarası 10 karakter olmalı!', val => {
      const a = val
        ?.replaceAll('_', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(' ', '')
        .replaceAll(')', '').length
      return a === 10
    })
    .nullable()
    .required('Lütfen Telefon alanını doldurunuz!'),
  InvoiceType: yup.object().shape({
    label: yup.string().required('Fatura mükellef tipi seçmediniz!'),
    value: yup.string().required('Fatura mükellef tipi seçmediniz!'),
  }),
  CompanyType: yup.object().shape({
    label: yup.string().required('Firma tipi seçmediniz!'),
    value: yup.string().required('Firma tipi seçmediniz!'),
  }),
  TaxOrTcNumber: yup
    .string()
    .test('len', 'Vergi No için en az 10 karakter ; T.C No için en az 11 karakter olmalı!', val => {
      const a: any = val?.replaceAll('_', '').length
      return a === 10 || a > 10
    })
    .nullable()
    .required('Lütfen Vergi Numarası/TC Kimlik No alanını doldurunuz!'),
  IbanNumber1: yup
    .string()
    .min(15, 'En az 15 karakter girilmelidir!')
    .max(34, 'En fazla 34 karakter girilmelidir!')
    .required('Lütfen Iban Numarası alanını doldurunuz!'),
  IbanNumberType1: yup.object().shape({
    label: yup.string().required('Kur seçmediniz!'),
    value: yup.string().required('Kur seçmediniz!'),
  }),
  AuthorizedNameSurname: yup
    .string()
    //.matches(/^[A-Za-zÇçĞğİıÖöŞşÜü]+(\s[A-Za-zÇçĞğİıÖöŞşÜü]+){1,2}$/, 'Lütfen geçerli bir Ad Soyad giriniz.') 3 kelime
    .matches(/^(?:[A-Za-zÇçĞğİıÖöŞşÜü]+\s){1,4}[A-Za-zÇçĞğİıÖöŞşÜü]+$/, 'Lütfen geçerli bir Ad Soyad giriniz.')
    .required('Lütfen Yetkili Ad Soyad alanını doldurunuz!'),
  AuthorizedTC: yup
    .string()
    .test('len', 'En az 11 karakter girilmelidir!', val => {
      const a: number = val?.replaceAll('_', '').length ?? 0
      return a >= 10
    })
    .nullable()
    .required('Lütfen Yetkili TC Kimlik alanını doldurunuz!'),
  City: yup.object().shape({
    label: yup.string().required('Şehir seçmediniz!'),
    value: yup.string().required('Şehir seçmediniz!'),
  }),
  Town: yup.object().shape({
    label: yup.string().required('İlçe seçmediniz!'),
    value: yup.string().required('İlçe seçmediniz!'),
  }),
  Neighbourhood: yup.object().shape({
    label: yup.string().required('Mahalle seçmediniz!'),
    value: yup.string().required('Mahalle seçmediniz!'),
  }),
  Email: yup
    .string()
    .email('Geçerli bir email adresi girmelisiniz!')
    .nullable()
    .required('Lütfen Email alanını doldurunuz!'),
  InvoiceUsageType: yup.object().shape({
    label: yup.string().required('Fatura kullanım tipi seçmediniz!'),
    value: yup.string().required('Fatura kullanım tipi seçmediniz!'),
  }),
  MersisNo: yup
    .string()
    .transform(value => value.replace(/_/g, '')) // Remove underscores
    .test('is-16-characters', '16 karakter girilmelidir!', value => {
      return value ? value.length === 16 : true
    })
    .nullable()
    .required('Lütfen Mersis No alanını doldurunuz!'),
  TaxAdministration: yup
    .string()
    .max(16, 'En fazla 16 karakter girilmelidir!')
    .nullable()
    .test('is-not-number', 'Lütfen Vergi Dairesi adını yazınız, Vergi No değil!', function (value) {
      if (value) {
        // Check if the value is not a number (contains at least one non-numeric character)
        return !/^\d+$/.test(value)
      }
      return true // Allow null or empty values
    })
    .required('Lütfen Vergi Dairesi alanını doldurunuz!'),

  CommercialMessage: yup.string().required('Ticari elektronik iletisi seçeneği seçilmeli!'),

  KvkApproval: yup.bool().oneOf([true], 'Lütfen KVKK metnini onaylayınız!'),
})

export default validations
