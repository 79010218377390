import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { IDPP, ITraining } from '../models/models'

export class GetDppTrainigContentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'DppEtCetera/getDppTrainigContent',
      requesterInfo,
    })
  }

  public async getDppTrainigContent(): Promise<ITraining> {
    return await this.getAsync<ITraining>()
  }
}
