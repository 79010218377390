import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IBanner } from '../models/getProductByIDResponseModel'

export class GetProductBannersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/productBanners',
      requesterInfo,
    })
  }

  public async getProductBanners(productId: number): Promise<IBanner[]> {
    this.props.url = `${this.props.url}/${productId}`
    return await this.getAsync<IBanner[]>()
  }
}
