import { css } from '@emotion/css'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useCustomReducer } from '../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../base/customHooks/componentHOC'
import { KeyValuePair } from '../../base/models'
import { DEFAULT_THEME } from '../../base/theme/themeConfig'
import { IMenu } from '../../layouts/components/client/models'
import { GetMenusApi } from '../../layouts/components/client/services/getMenusApi'
import { useAppSelector } from '../../redux/app/hooks'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import HomePageCardLists from './HomePageCardLists'
import HomePageHeader from './HomePageHeader'
import HomePageRedBanner from './HomePageRedBanner'
import HomePageSlider from './HomePageSlider'
import './css/styles.scss'
import { GetOpportunitMarketApi } from './services/getOpportunitMarketApi'
import { GetProductListApi } from './services/getProductListApi'
import Swal from 'sweetalert2'
import HomePageNightMarket from './HomePageNightMarket'
interface IHomepageState {
  menuData: IMenu[]
}

function HomePage(props: BaseComponentWithContextProps) {
  const theme = useAppSelector(selectedTheme)

  const getInitialState = (): IHomepageState => {
    return {
      menuData: [],
    }
  }
  const [product, setProduct] = useState({
    MostFavourites: {
      Count: 0,
      Products: [],
    },
    NewProducts: {
      Count: 0,
      Products: [],
    },
    PromotionalProducts: {
      Count: 0,
      Products: [],
    },
    RecentVisits: {
      Count: 0,
      Products: [],
    },
  })
  const [opportunitMarket, setOpportunitMarket] = useState<any>({
    Count: 0,
    Products: [],
    ProductsCount: 0,
  })
  const initialState = getInitialState()
  const [state, setState] = useCustomReducer<IHomepageState>(initialState)

  useEffect(() => {
    // getCategoriesByCompany(theme.id);
    setProduct({
      MostFavourites: {
        Count: 0,
        Products: [],
      },
      NewProducts: {
        Count: 0,
        Products: [],
      },
      PromotionalProducts: {
        Count: 0,
        Products: [],
      },
      RecentVisits: {
        Count: 0,
        Products: [],
      },
    })
    setOpportunitMarket({
      Count: 0,
      Products: [],
      ProductsCount: 0,
    })
    getMenuByCompany(theme.id)
    getProduct(theme.id)
    getOpportunitMarket(theme.id)
  }, [theme.id])

  const getMenuByCompany = (companyId: number) => {
    const api: GetMenusApi = new GetMenusApi({})
    const queryParams: KeyValuePair<number> = new KeyValuePair<number>()
    queryParams.add('companyId', companyId)
    api.queryParams = queryParams
    api.getMenus().then((res: IMenu[]) => {
      setState({ menuData: res })
    })
  }

  const [searchParams, setSearchParams] = useSearchParams()
  let navigate = useNavigate()

  useEffect(() => {
    const error = searchParams.get('iquoteError')

    if (error != null && error.length > 1) {
      Swal.fire({
        icon: 'error',
        title: error,
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then(async result => {
        if (result.isConfirmed) {
          navigate('/homepage')
        }
      })
    }
  }, [])

  const hoverClass = css`
    &:hover {
      color: ${props.theme.colors.backgroundColor}!important;
      &::after {
        border-color: ${props.theme.colors.backgroundColor}!important;
      }
    }
  `
  const getProduct = async (companyId: number) => {
    try {
      const api = new GetProductListApi({})
      const queryParams = new KeyValuePair<number>()

      queryParams.add('companyId', companyId)
      api.queryParams = queryParams

      const res = await api.getProductList()
      if (res) {
        setProduct(res)
      }
    } catch (error) {
      console.error('Error fetching product list:', error)
    }
  }

  const getOpportunitMarket = async (companyId: number) => {
    try {
      const api = new GetOpportunitMarketApi({})
      const queryParams = new KeyValuePair<number>()

      queryParams.add('companyId', companyId)
      api.queryParams = queryParams

      const res = await api.getOpportunitMarket()
      setOpportunitMarket(res)
    } catch (error) {
      console.error('Error fetching opportunity market:', error)
    }
  }

  return (
    <section>
      {theme.name === DEFAULT_THEME && (
        <>
          <HomePageHeader />
          <HomePageSlider height='140px' />
        </>
      )}
      {theme.name !== DEFAULT_THEME && (
        <div className='container'>
          <div className='sidebarWrapper'>
            {window.innerWidth > 992 && (
              <div className='sidebar2'>
                <div className='companyLogo'>
                  <img src={theme.image} alt='' />
                </div>
                <nav style={{ backgroundColor: theme.colors.backgroundColor }}>
                  {state.menuData &&
                    state.menuData.map((menu: IMenu) => {
                      return (
                        <React.Fragment key={menu.Id}>
                          {menu.Items && menu.Items.length > 0 ? (
                            <div className='hasSub'>
                              <Link
                                key={menu.Id}
                                to={
                                  menu.Link
                                    ? menu.Link
                                    : `/products?categoryId=${menu.Id}${theme.id > 0 ? `&companyId=${theme.id}` : ''}`
                                }
                                className={hoverClass}
                              >
                                {menu.Title}
                              </Link>
                              <div className='subMenu'>
                                {menu.Items.map(item => (
                                  <React.Fragment key={item.Id}>
                                    {item.Items && item.Items.length > 0 ? (
                                      <div className='hasSub'>
                                        <Link
                                          key={item.Id}
                                          to={
                                            item.Link
                                              ? item.Link
                                              : `/products?categoryId=${item.Id}${
                                                  theme.id > 0 ? `&companyId=${theme.id}` : ''
                                                }`
                                          }
                                          state={{ menuData: state.menuData }}
                                          className={hoverClass}
                                        >
                                          {item.Title}
                                        </Link>
                                        <div className='subMenu'>
                                          {item.Items.map(subItem => (
                                            <Link
                                              key={subItem.Id}
                                              to={
                                                subItem.Link
                                                  ? subItem.Link
                                                  : `/products?categoryId=${subItem.Id}${
                                                      theme.id > 0 ? `&companyId=${theme.id}` : ''
                                                    }`
                                              }
                                              state={{ menuData: state.menuData }}
                                              className={hoverClass}
                                            >
                                              {subItem.Title}
                                            </Link>
                                          ))}
                                        </div>
                                      </div>
                                    ) : (
                                      <Link
                                        key={item.Id}
                                        to={
                                          item.Link
                                            ? item.Link
                                            : `/products?categoryId=${item.Id}${
                                                theme.id > 0 ? `&companyId=${theme.id}` : ''
                                              }`
                                        }
                                        state={{ menuData: state.menuData }}
                                        className={hoverClass}
                                      >
                                        {item.Title}
                                      </Link>
                                    )}
                                  </React.Fragment>
                                ))}
                              </div>
                            </div>
                          ) : (
                            <Link
                              key={menu.Id}
                              to={
                                menu.Link
                                  ? menu.Link
                                  : `/products?categoryId=${menu.Id}${theme.id > 0 ? `&companyId=${theme.id}` : ''}`
                              }
                              state={{ menuData: state.menuData }}
                              className={hoverClass}
                            >
                              {menu.Title}
                            </Link>
                          )}
                        </React.Fragment>
                      )
                    })}
                </nav>
              </div>
            )}
            <main>
              <HomePageHeader />
              <HomePageSlider />
            </main>
          </div>
        </div>
      )}

      {opportunitMarket &&
        opportunitMarket.Count > 0 &&
        opportunitMarket.Products.filter(a => a.NightMarket === true).length > 0 && (
          <HomePageNightMarket
            theme={props.theme}
            product={opportunitMarket.Products.filter(a => a.NightMarket === true)}
            count={opportunitMarket.Count}
          />
        )}
      {opportunitMarket &&
        opportunitMarket.Count > 0 &&
        opportunitMarket.Products.filter(a => a.NightMarket === false).length > 0 && (
          <HomePageRedBanner
            theme={props.theme}
            product={opportunitMarket.Products.filter(a => a.NightMarket === false)}
            count={opportunitMarket.Count}
          />
        )}

      {product && <HomePageCardLists product={product} />}
    </section>
  )
}
export default componentWithContext(HomePage)
