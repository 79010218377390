import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IRolePermissionModel } from '../models'

export class GetRolePermissionsByRoleIdApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/userPermission/GetRolePermissionByRoleId',
      requesterInfo,
    })
  }

  public async getRolePermissions(roleId: number): Promise<IRolePermissionModel[]> {
    this.props.url = `${this.props.url}/${roleId}`
    return await this.getAsync<IRolePermissionModel[]>()
  }
}
