import * as yup from 'yup'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'

const t = translate(Translations)
const FILE_SIZE = 12324234
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

const validations = yup.object().shape({
  Topic: yup
    .string()
    .min(6, 'Konu en az 6 karakter olmalı')
    .required(t('pages.admin.homeAndSiteOperation.bannersCrud.validation.requiredField')),
})

export default validations
