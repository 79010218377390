import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { PaymentTransactionListResponse } from '../models/model'
import { PaymentTransactionListRequest } from '../../Bank/models/models'

export class PaymentTransactionApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/paymentTransactionList',
      requesterInfo,
    })
  }

  public async PaymentTransaction(req: PaymentTransactionListRequest): Promise<PaymentTransactionListResponse> {
    return await this.postAsync<PaymentTransactionListResponse>(req)
  }
}
