import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IProductColorRequest, IProductColorResponse } from '../models/models'

export class GetProductColorApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/color/listColor',
      requesterInfo,
    })
  }

  public async getProductColor(): Promise<IProductColorResponse[]> {
    return await this.getAsync<IProductColorResponse[]>()
  }
}
