import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IBannerCategory } from '../../Admin/HomeAndSiteOperation/BannersCrud/models/requestModels'

export class GetBannerCategoriesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/get-banner-categories',
      requesterInfo,
    })
  }

  public async getBannerCategories(): Promise<IBannerCategory[]> {
    return await this.getAsync<IBannerCategory[]>()
  }
}
