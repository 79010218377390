import { css } from '@emotion/css'
import moment from 'moment'
import { useMemo, useState } from 'react'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { translate } from '../../../../base/localization'
import { exportToXLSX, getDateByTimezoneOffset } from '../../../../base/utils/dataHelper'
import IPButton from '../../../../components/IPButton'
import IPCheckbox from '../../../../components/IPCheckBox'
import InputCampaign from '../../../../components/InputCampaign'
import Table from '../../../../components/Table'
import Translations from '../../../../translations'
import {
  CompanyHomepageClickResponseModel,
  DealerCodeClickCountModel,
  DealerForHomePageRequest,
  DealerForHomePageResponse,
  HomePageClickTracking,
  PathOptions,
} from './models'
import { CompanyHomepageClickStatsApi, SiteNavigationService } from './services'

function StatisticsList(props: BaseComponentWithContextProps) {
  //#region Tarih ve saat ayarları
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const [filteredStartDate, setfilteredStartDate] = useState(new Date())
  const [filteredEndDate, setfilteredEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }
  //#endregion

  const [pathOptions, setPathOptions] = useState<PathOptions>({
    DealerCode: '',
    StartDate: startDate,
    EndDate: endDate,
    IncludeDenemeCard: false,
  })
  const [pathOptions2, setPathOptions2] = useState<PathOptions>({
    DealerCode: '',
    StartDate: startDate,
    EndDate: endDate,
    IncludeDenemeCard: false,
  })
  const [paths, setPaths] = useState<DealerForHomePageResponse[]>([])
  const [clickStats, setClickStats] = useState<CompanyHomepageClickResponseModel | null>(null)
  const [totalSessions, setTotalSessions] = useState<number>(0)
  const [uniqueDealerCodes, setUniqueDealerCodes] = useState<string[]>([])

  // Function to calculate total sessions and unique dealer codes
  const calculateMetrics = (data: DealerForHomePageResponse[]) => {
    let sessions = 0
    const dealerCodes: string[] = []

    // Loop through the data to calculate total sessions and collect unique dealer codes
    data.forEach(item => {
      sessions += item.TotalSessions
      item.DealerCodes.forEach(code => {
        if (!dealerCodes.includes(code)) {
          dealerCodes.push(code)
        }
      })
    })

    // Set the state with the calculated values
    setTotalSessions(sessions)
    setUniqueDealerCodes(dealerCodes)
  }

  // function updatePathOptions(prop, val) {
  //   setPathOptions(prev => ({ ...prev, [prop]: val }))
  // }

  const t = translate(Translations)

  const dateToISO = date => new Date(new Date(date).getTime() + 3 * 3600 * 1000).toISOString()

  // const dateValid = () => {
  //   const result = pathOptions.EndDate.getTime() - pathOptions.StartDate.getTime()
  //   if (result < 60000) {
  //     Swal.fire('Bitiş tarihi başlangıç tarihinden önce olamaz.')
  //     updatePathOptions('EndDate', toDateTimeLocal(new Date()))
  //   }
  // }

  async function handleSubmit(e) {
    e.preventDefault()
    if (new Date(endDate) < new Date(startDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Başlangıç bitiş tarihinden sonra olamaz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    const req: DealerForHomePageRequest = {
      DateRange: {
        StartDate: dateToISO(startDate),
        EndDate: dateToISO(endDate),
      },
      DealerCode: pathOptions.DealerCode.length > 0 ? pathOptions.DealerCode : '',
      IncludeDenemeCard: pathOptions.IncludeDenemeCard,
    }
    setPathOptions2({
      StartDate: startDate,
      EndDate: endDate,
      DealerCode: pathOptions.DealerCode,
      IncludeDenemeCard: pathOptions.IncludeDenemeCard,
    })
    props.showLoading()
    await new SiteNavigationService({})
      .DealerForHomePage(req)
      .then(async result => {
        if (result) {
          const sortedResult = result.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime())
          setPaths(sortedResult)
          calculateMetrics(sortedResult)
          setPaths(result)
          setfilteredEndDate(endDate)
          setfilteredStartDate(startDate)
        }
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })

    getCompanyHomepageStats()
  }

  const handleDetail = (data: DealerCodeClickCountModel[]) => {
    let rows = ''

    if (!data) {
      Swal.fire({
        title: 'Hata',
        icon: 'error',
        html: `<div>
        <div style="display: flex; justify-content: center; margin-bottom: 1rem; font-size: 16px;">
          <b>Veri bulunamadı.</b>
        </div>
      </div>`,
        backdrop: true,
        confirmButtonText: 'KAPAT',
        confirmButtonColor: '#333',
      })
      return
    }

    data.length > 0 &&
      data.forEach(x => {
        rows += `<div style="display: flex; margin-bottom: 0.5rem;">
          <div style="width: 150px; text-align: left;">${x.DealerCode}</div>
          <div style="width: 250px;">${x.DealerName}</div>
          <div style="width: 200px;">${x.ActiveUsers}</div>
          <div style="width: 200px;">${x.DealerSessions}</div>
        </div>`
      })

    Swal.fire({
      title: 'Detay',
      icon: 'info',
      width: 'auto',
      html: `<div>
        <b style="display: block; margin-bottom: 1.5rem; font-size: 18px;">Günlük Bayi Bazlı Kullanıcı İstatistikleri</b>
        <div style="display: flex; margin-bottom: 1rem; font-size: 16px;">
          <b style="width: 150px; text-align: left;">Bayi Kodu</b>
          <b style="width: 250px; ">Bayi Adı</b>
          <b style="width: 200px; ">Aktif Kullanıcı</b>
          <b style="width: 200px; ">Oturum Sayısı</b>
        </div>
        ${rows}
      </div>`,
      backdrop: true,
      confirmButtonText: 'KAPAT',
      confirmButtonColor: '#333',
    })
  }

  //#region Site-2 Stats Tablo ve Excel
  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: 'Tarih',
        accessor: 'Date',
        width: 200,
      },
      {
        Header: 'Toplam Session(Oturum)',
        accessor: 'TotalSessionCount',
      },
      {
        Header: 'Aktif Kullanıcı',
        accessor: 'TotalActiveCount',
      },
      // {
      //   Header: 'Detay',
      //   accessor: 'DetailsModel',
      //   width: 100,
      // },
      {
        Header: pathOptions.DealerCode.length > 0 ? 'Bayi Adı' : 'Giriş Yapan Bayi Sayısı',
        accessor: 'SingleCount',
      },
    ]

    if (!pathOptions.DealerCode) {
      baseColumns.splice(3, 0, {
        Header: 'Detay',
        accessor: 'DetailsModel',
        width: 100,
      })
    }
    return baseColumns
  }, [pathOptions.DealerCode])

  const data = useMemo(
    () =>
      paths.map(p => ({
        Date: new Date(p.Date).toLocaleDateString(),
        TotalSessionCount: p.TotalSessions,
        TotalActiveCount: p.TotalActiveCount,
        SingleCount: pathOptions.DealerCode.length > 0 ? p.DealerCodeClickCounts[0].DealerName : p.SingleCount,
        DetailsModel: (
          <IPButton
            text='Detay'
            onClick={() => handleDetail(p.DealerCodeClickCounts)}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
      })),
    [paths]
  )

  const excelButton = async () => {
    try {
      if (paths && paths.length > 0) {
        const data = paths.map((p, index) => {
          const commonFields = {
            Sıra: index + 1,
            Tarih: new Date(p.Date).toLocaleDateString(),
            'Toplam Session(Oturum)': p.TotalSessions,
            'Aktif Kullanıcı': p.TotalActiveCount,
          }

          const dynamicFields =
            pathOptions.DealerCode.length > 0
              ? {
                  'Bayi Adı': p.DealerCodeClickCounts[0]?.DealerName || '',
                }
              : { 'Giriş Yapan Bayi Sayısı': p.SingleCount || '' }

          return { ...commonFields, ...dynamicFields }
        })

        exportToXLSX(
          data,
          moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY') +
            '-' +
            moment(toDateTimeLocal(endDate)).format('DD.MM.YYYY') +
            'Site Gezinme İstatistikleri-2'
        )
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Excele atılacak veri bulunmamaktadır.',
          allowOutsideClick: true,
          backdrop: true,
        })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const excelButtonWithDetail = async () => {
    try {
      if (paths) {
        const combinedData: any[] = []

        if (paths.length > 0) {
          for (let index = 0; index < paths.length; index++) {
            const item = paths[index]
            const modalData = item.DealerCodeClickCounts

            combinedData.push({
              Sıra: index + 1,
              Tarih: new Date(item.Date).toLocaleDateString(),
              'Toplam Session(Oturum)': item.TotalSessions,
              'Toplam Aktif Kullanıcı': item.TotalActiveCount,
              'Giriş Yapan Bayi Sayısı': item.SingleCount,
            })

            if (modalData) {
              for (let i = 0; i < modalData.length; i++) {
                const modalRow = modalData[i]

                combinedData.push({
                  Sıra: index + 1,
                  Tarih: new Date(item.Date).toLocaleDateString(),
                  'Toplam Session(Oturum)': '',
                  'Toplam Aktif Kullanıcı': '',
                  'Giriş Yapan Bayi Sayısı': '',
                  'Detay Sıra': i + 1,
                  'Bayi Kodu': modalRow.DealerCode,
                  'Bayi Adı/Ünvan': modalRow.DealerName,
                  'Aktif Kullanıcı': modalRow.ActiveUsers,
                  'Oturum Sayısı': modalRow.DealerSessions,
                })
              }
            }
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(
          combinedData,
          moment(toDateTimeLocal(startDate)).format('DD.MM.YYYY') +
            '-' +
            moment(toDateTimeLocal(endDate)).format('DD.MM.YYYY') +
            'Detaylı Site Gezinme İstatistikleri-2'
        )
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }
  //#endregion

  const getCompanyHomepageStats = async () => {
    const req = {
      StartDate: getDateByTimezoneOffset(startDate),
      EndDate: getDateByTimezoneOffset(endDate),
      DealerCode: pathOptions.DealerCode.length > 0 ? pathOptions.DealerCode : '',
      IncludeDenemeCard: pathOptions.IncludeDenemeCard,
    }

    try {
      const postCompanyHomepageClicks = new CompanyHomepageClickStatsApi({})
      const result = await postCompanyHomepageClicks.getCompanyHomepageClickStats(req)
      if (result) {
        setClickStats(result)
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const handleClickDetail = (data: HomePageClickTracking[]) => {
    let rows = ''

    if (!data) {
      Swal.fire({
        title: 'Hata',
        icon: 'error',
        html: `<div>
        <div style="display: flex; justify-content: center; margin-bottom: 1rem; font-size: 16px;">
          <b>Veri bulunamadı.</b>
        </div>
      </div>`,
        backdrop: true,
        confirmButtonText: 'KAPAT',
        confirmButtonColor: '#333',
      })
      return
    }
    data.sort((a, b) => b.UserTotalClicks - a.UserTotalClicks)
    data.length > 0 &&
      data.forEach(x => {
        rows += `<div style="display: flex; margin-bottom: 0.5rem;">
          <div style="width: 100px; text-align: left;">${x.CompanyName}</div>
          <div style="width: 150px; ">${x.SapCode}</div>
          <div style="width: 200px; ">${x.UserName}</div>
          <div style="width: 450px; text-align: left; ">${x.MerchantName}</div>
          <div style="width: 200px;">${x.UserTotalClicks}</div>
        </div>`
      })

    Swal.fire({
      title: 'Detay',
      icon: 'info',
      width: 'auto',
      html: `<div>
        <b style="display: block; margin-bottom: 1.5rem; font-size: 18px;">Günlük Bayi Bazlı Tıklanma Sayıları</b>
        <div style="display: flex; margin-bottom: 1rem; font-size: 16px;">
          <b style="width: 100px; text-align: left;">Şirket Adı</b>
          <b style="width: 150px; ">Bayi Kodu</b>
          <b style="width: 200px; ">Kullanıcı Adı</b>
          <b style="width: 450px; ">Bayi Adı</b>
          <b style="width: 200px; ">Kullanıcı Tıklama</b>
        </div>
        ${rows}
      </div>`,
      backdrop: true,
      confirmButtonText: 'KAPAT',
      confirmButtonColor: '#333',
    })
  }

  const columnsCompany = useMemo(() => {
    return [
      {
        Header: 'Şirket',
        accessor: 'Company',
      },
      {
        Header: 'Toplam Anasayfa Tıklanma',
        accessor: 'TotalClickCount',
      },
      {
        Header: 'Kullanıcı Detayları',
        accessor: 'UserDetail',
      },
    ]
  }, [])

  const dataCompany = useMemo(() => {
    if (!clickStats) return []

    const rows = [
      {
        Company: 'Index',
        TotalClickCount: clickStats.TotalClickIndex,
        UserDetail: (
          <IPButton
            text='Detay'
            onClick={() => handleClickDetail(clickStats.HomePageClickTracking.filter(p => p.CompanyId === 1))}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
      },
      {
        Company: 'Datagate',
        TotalClickCount: clickStats.TotalClickDatagate,
        UserDetail: (
          <IPButton
            text='Detay'
            onClick={() => handleClickDetail(clickStats.HomePageClickTracking.filter(p => p.CompanyId === 2))}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
      },
      {
        Company: 'Despec',
        TotalClickCount: clickStats.TotalClickDespec,
        UserDetail: (
          <IPButton
            text='Detay'
            onClick={() => handleClickDetail(clickStats.HomePageClickTracking.filter(p => p.CompanyId === 3))}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
      },
      {
        Company: 'Netex',
        TotalClickCount: clickStats.TotalClickNetex,
        UserDetail: (
          <IPButton
            text='Detay'
            onClick={() => handleClickDetail(clickStats.HomePageClickTracking.filter(p => p.CompanyId === 4))}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
      },
      {
        Company: 'Toplam',
        TotalClickCount: clickStats.TotalClick,
        UserDetail: (
          <IPButton
            text='Detay'
            onClick={() => handleClickDetail(clickStats.HomePageClickTracking)}
            type='button'
            style={{ padding: '0px', width: '120px', height: '30px', color: 'white', borderColor: 'red' }}
            className='btn btn-danger'
          ></IPButton>
        ),
      },
    ]

    return rows
  }, [clickStats])

  // const excelButtonForHomePage = async () => {
  //   try {
  //     if (clickStats) {
  //       const data = paths.map((p, index) => {
  //         return {
  //           Path: p,
  //           Index: clickStats[index] ? clickStats[index].TotalClickIndex : 0,
  //           Datagate: clickStats[index] ? clickStats[index].TotalClickDatagate : 0,
  //           Despec: clickStats[index] ? clickStats[index].TotalClickDespec : 0,
  //           Netex: clickStats[index] ? clickStats[index].TotalClickNetex : 0,
  //           'Toplam Anasayfa Tıklanma': clickStats[index] ? clickStats[index].TotalClick : 0,
  //         }
  //       })

  //       exportToXLSX(data, 'Site Gezinme İstatistikleri-2')
  //     } else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Excele atılacak veri bulunmamaktadır.',
  //         allowOutsideClick: true,
  //         backdrop: true,
  //       })
  //     }
  //   } catch (err: any) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: err.description,
  //       allowOutsideClick: true,
  //       backdrop: true,
  //     })
  //   }
  // }

  const excelButtonWithDetailForHomePage = async () => {
    try {
      if (clickStats && clickStats.HomePageClickTracking.length > 0) {
        const data: any[] = []

        for (const tracking of clickStats.HomePageClickTracking) {
          const rowData = {
            Şirket: tracking.CompanyName,
            'Kullanıcı Adı': tracking.UserName,
            'Sap Kod': tracking.SapCode,
            'Bayi Adı': tracking.MerchantName,
            'Kullanıcı Toplam Tıklama': tracking.UserTotalClicks,
          }
          data.push(rowData)
        }

        if (data.length > 0) {
          exportToXLSX(data, 'Detaylı Şirket Anasayfa İstatistikleri')
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
        }
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>Site Gezinme İstatistikleri-2</span>
            <div className='d-flex align-items-center'>
              <span>Excel: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
              {!pathOptions.DealerCode && (
                <>
                  <span>Detaylı Excel: </span>
                  <button
                    type='button'
                    style={{
                      border: '1px solid #2e7d32',
                      borderRadius: 0,
                      width: '29px',
                      height: '29px',
                      backgroundColor: 'white',
                      margin: '0 10px',
                    }}
                    onClick={excelButtonWithDetail}
                    className='btn text-capitalize background-white-important d-flex align-items-center'
                  >
                    <i
                      className='fas fa-file-excel'
                      style={{
                        marginLeft: '-6px',
                        fontSize: '18px',
                        color: '#2e7d32',
                      }}
                    ></i>
                  </button>
                </>
              )}
            </div>
          </div>
          <div className='card-body'>
            <form
              className={css`
                display: flex;
                align-items: center;
                gap: 1rem;
                flex-wrap: wrap;
              `}
              onSubmit={handleSubmit}
            >
              <div className='col-md-2 col-sm-6'>
                <InputCampaign
                  type='date'
                  id='StartDate'
                  name='StartDate'
                  label={t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.startDate')}
                  value={toDateTimeLocal(startDate)}
                  onChange={e => getDate(e.target.value, 'Start')}
                  isActive
                />
              </div>
              <div className='col-md-2 col-sm-6'>
                <InputCampaign
                  type='date'
                  id='EndDate'
                  name='EndDate'
                  label={t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.endDate')}
                  value={toDateTimeLocal(endDate)}
                  onChange={e => getDate(e.target.value, 'End')}
                  // onBlur={() => dateValid()}
                  isActive
                />
              </div>
              <InputCampaign
                id='dealerCode'
                label={
                  'Bayi Kodu (' +
                  t('pages.admin.dealerAndOrderReport.siteNavigationStatistics.statisticsList.all') +
                  ')'
                }
                style={{ width: 240 }}
                value={pathOptions.DealerCode}
                onChange={e => setPathOptions(prev => ({ ...prev, DealerCode: e.target.value }))}
                isActive={pathOptions.DealerCode.length > 0}
              />
              <div
                className='col-md-2 col-sm-6'
                style={{ alignSelf: 'flex-end' }}
                title='Seçildiğinde 203179 Deneme Kartı verileri dahil edilir.'
              >
                <IPCheckbox
                  label='Deneme Kartı Dahil'
                  onChange={e =>
                    setPathOptions(prev => ({ ...prev, IncludeDenemeCard: !pathOptions.IncludeDenemeCard }))
                  }
                  checked={pathOptions.IncludeDenemeCard}
                />
              </div>
              <div className='col-md-2 col-sm-6 mb-3'>
                <IPButton type='submit' className='btn btn-danger' style={{ width: '100%' }} text='Süz' />
              </div>
            </form>
            <br />
            <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <Table
                      columns={columns}
                      data={data}
                      headerName={`${moment(toDateTimeLocal(filteredStartDate)).format('DD.MM.YYYY')} - ${moment(
                        toDateTimeLocal(filteredEndDate)
                      ).format('DD.MM.YYYY')} Site Gezinme İstatistikleri`}
                      columnCount={8}
                      textAlign={true}
                      backgroundColor='red'
                      color='white'
                    />
                  </div>
                  <div className='col-sm-12 mt-5'>
                    <div className='h3'>Girilen Aralıktaki Özet Bilgi</div>
                    <h5>Toplam Session(Oturum Sayısı): {totalSessions}</h5>
                    <h5>Toplam Giriş Yapan Bayi Sayısı: {uniqueDealerCodes.length}</h5>
                  </div>
                </div>
                <div
                  className='row mt-5'
                  style={{
                    placeContent: 'center',
                  }}
                >
                  <div className='card-header d-flex justify-content-between align-items-center'>
                    <span className='h4 mb-0'>Şirket Anasayfaları Tıklanma İstatistikleri</span>
                    <div className='d-flex align-items-center'>
                      {/* <span>Excel: </span>
                      <button
                        type='button'
                        style={{
                          border: '1px solid #2e7d32',
                          borderRadius: 0,
                          width: '29px',
                          height: '29px',
                          backgroundColor: 'white',
                          margin: '0 10px',
                        }}
                        onClick={excelButtonForHomePage}
                        className='btn text-capitalize background-white-important d-flex align-items-center'
                      >
                        <i
                          className='fas fa-file-excel'
                          style={{
                            marginLeft: '-6px',
                            fontSize: '18px',
                            color: '#2e7d32',
                          }}
                        ></i>
                      </button> */}
                      {clickStats?.HomePageClickTracking && (
                        <>
                          <span>Detaylı Excel: </span>
                          <button
                            type='button'
                            style={{
                              border: '1px solid #2e7d32',
                              borderRadius: 0,
                              width: '29px',
                              height: '29px',
                              backgroundColor: 'white',
                              margin: '0 10px',
                            }}
                            onClick={excelButtonWithDetailForHomePage}
                            className='btn text-capitalize background-white-important d-flex align-items-center'
                          >
                            <i
                              className='fas fa-file-excel'
                              style={{
                                marginLeft: '-6px',
                                fontSize: '18px',
                                color: '#2e7d32',
                              }}
                            ></i>
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <Table
                      columns={columnsCompany}
                      data={dataCompany}
                      headerName={`${moment(toDateTimeLocal(filteredStartDate)).format('DD.MM.YYYY')} - ${moment(
                        toDateTimeLocal(filteredEndDate)
                      ).format('DD.MM.YYYY')} Şirket Anasayfa Tıklanma istatistiği`}
                      columnCount={3}
                      textAlign={true}
                      backgroundColor='red'
                      color='white'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(StatisticsList)
