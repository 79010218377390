import { useEffect, useState } from 'react'

interface IIcon {
  width?: string
  height?: string
  color?: string
  hover?: string
  isStarred?: boolean
  click?: (e: any) => void
  isFavoriteProduct?: boolean
  className?: string
  iconType?: 'star' | 'heart'
}

export default function Heart(props: IIcon) {
  const [color, setColor] = useState(props.isFavoriteProduct ? props.hover : props.color)

  useEffect(() => {
    setColor(props.isFavoriteProduct ? props.hover : props.color)
  }, [props.isFavoriteProduct])

  return (
    <div className={props.className ? props.className : 'action'} onClick={props.click}>
      {props.iconType === 'star' ? (
        <svg
          width={props.width ? props.width : '20'}
          height={props.height ? props.height : '20'}
          viewBox='0 0 20 20'
          onMouseOver={() => {
            setColor(props.hover)
          }}
          onMouseLeave={() => {
            !props.isStarred && setColor(props.color)
          }}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            style={{ transition: 'fill 0.3s' }}
            fill={color ? color : 'none'}
            d='M15.9168 11.7871C15.6578 12.0374 15.5388 12.3994 15.5978 12.7544L16.4867 17.6607C16.5617 18.0765 16.3857 18.4974 16.0368 18.7377C15.6948 18.987 15.2399 19.0169 14.8669 18.8175L10.4385 16.5139C10.2845 16.4321 10.1136 16.3882 9.93858 16.3833H9.66761C9.57363 16.3972 9.48164 16.4271 9.39765 16.473L4.96823 18.7875C4.74926 18.8972 4.50129 18.9361 4.25832 18.8972C3.6664 18.7855 3.27145 18.2231 3.36844 17.6298L4.25832 12.7235C4.31731 12.3655 4.19833 12.0015 3.93936 11.7472L0.328833 8.25695C0.0268723 7.96476 -0.078114 7.52599 0.059868 7.13009C0.193851 6.73519 0.535806 6.447 0.948752 6.38218L5.9181 5.66319C6.29605 5.6243 6.62801 5.39494 6.79799 5.05588L8.9877 0.578385C9.03969 0.478663 9.10669 0.38692 9.18768 0.309137L9.27766 0.239332C9.32466 0.187477 9.37865 0.144596 9.43864 0.109694L9.54763 0.0698051L9.71761 0H10.1386C10.5145 0.0388914 10.8455 0.263265 11.0184 0.598329L13.2371 5.05588C13.3971 5.38197 13.7081 5.60834 14.067 5.66319L19.0364 6.38218C19.4563 6.44201 19.8073 6.73121 19.9463 7.13009C20.0773 7.52998 19.9643 7.96875 19.6563 8.25695L15.9168 11.7871Z'
          />
        </svg>
      ) : (
        <svg
          width={props.width ? props.width : '20'}
          height={props.height ? props.height : '20'}
          style={{ transition: 'transform 0.3s' }}
          viewBox='0 0 20 20'
          onMouseOver={() => {
            setColor(props.hover)
          }}
          onMouseLeave={() => {
            !props.isStarred && setColor(props.color)
          }}
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            style={{ transition: 'fill 0.3s' }}
            fill={color ? color : 'none'}
            d='M9.97006 5.10731C6.90911 0.379213 0.713867 2.95493 0.713867 8.1054C0.713867 11.973 9.2072 18.023 9.97006 18.8097C10.7382 18.023 18.8091 11.973 18.8091 8.1054C18.8091 2.99397 13.0367 0.379213 9.97006 5.10731Z'
          />
        </svg>
      )}
    </div>
  )
}
