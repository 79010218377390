import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IDealerType } from '../models/models'

export class GetDealerTypeListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealer/dealerTypeList',
      requesterInfo,
    })
  }

  public async getDealerTypeList(): Promise<IDealerType[]> {
    return await this.getAsync<IDealerType[]>()
  }
}
