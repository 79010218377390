import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../base/models'
import { IChannelData } from '../models/models'

export class SetFaturaApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'NetChannel/changeStatus',
      requesterInfo,
    })
  }

  public async setFatura(req: any[]): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
