import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../base/models'
import { IUser } from '../models/response/getUserListResponseModel'

export class GetUsersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'dealerInformation/dealerCustomers',
      requesterInfo,
    })
  }

  public async getUsers(): Promise<IUser[]> {
    return await this.getAsync<IUser[]>()
  }
}
