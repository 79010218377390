import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IGetLetterOfIndemnityCommandResponseList, ILetterGuarantee } from '../models/models'

export class GetLetterGuaranteeApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetLetterOfIndemnity',
      requesterInfo,
    })
  }

  public async getLetterGuarantee(request: any): Promise<IGetLetterOfIndemnityCommandResponseList> {
    return await this.postAsync<IGetLetterOfIndemnityCommandResponseList>(request)
  }
}
