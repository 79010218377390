import { css } from '@emotion/css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useCustomReducer } from '../../base/customHooks'
import { translate } from '../../base/localization'
import { IException } from '../../base/models'
import { ServiceRouter } from '../../base/proxy/serviceRouter'
import { SliderLoop } from '../../base/utils/dataHelper'
import Image from '../../components/IPImage'
import Stopwatch from '../../components/Stopwatch'
import { useAppSelector } from '../../redux/app/hooks'
import { selectedTheme } from '../../redux/slices/theme/themeSlice'
import { currentUser } from '../../redux/slices/user/userSlice'
import Translations from '../../translations'
import { IBannerCategory } from '../Admin/HomeAndSiteOperation/BannersCrud/models/requestModels'
import ProductIQuotaForm, {
  ProductIQuotaFormField,
  ProductIQuotaFormHandles,
} from '../ProductList/components/ProductIQuotaForm'
import { IBanner, IBannerTypeEnum, ICounterLocationEnum, ILocationEnum } from './commonModels/models'
import './css/HomePageSlider.scss'
import { GetBannerCategoriesApi } from './services/getBannerCategoriesApi'
import { GetBannersApi } from './services/getBannersApi'
import { OutboundClickRequestModel, PostOutboundClicksApi } from './services/postOutboundClickApi'

enum SliderCategory {
  SpecialForToday = 1,
  Campaigns = 2,
  NewYear = 3,
  Privileges = 4,
}

interface HomepageSliderState {
  banners: IBanner[]
  oneAfterTheOtherStopwatchesOnTop: IBanner[]
  sliderStopwatchesOnTop: IBanner[]
  oneAfterTheOtherStopwatchesOnBottom: IBanner[]
  sliderStopwatchesOnBottom: IBanner[]
  topLeftBanners: IBanner[]
  bottomLeftBanners: IBanner[]
  rightBanners: IBanner[]
  specialForToday: boolean
  campaigns: boolean
  newYear: boolean
  privileges: boolean
  specialForMe?: IBanner
}

function HomePageSlider(props: any) {
  const t = translate(Translations)
  const getInitialState = (): HomepageSliderState => {
    return {
      banners: [],
      oneAfterTheOtherStopwatchesOnTop: [],
      sliderStopwatchesOnTop: [],
      oneAfterTheOtherStopwatchesOnBottom: [],
      sliderStopwatchesOnBottom: [],
      topLeftBanners: [],
      bottomLeftBanners: [],
      rightBanners: [],
      specialForToday: false,
      campaigns: false,
      newYear: false,
      privileges: false,
    }
  }

  const intialState = getInitialState()
  const [state, dispatch] = useCustomReducer<HomepageSliderState>(intialState)
  const theme = useAppSelector(selectedTheme)
  const [activeTab, setActiveTab] = useState(SliderCategory.SpecialForToday)
  const [bannerCategory, setBannerCategory] = useState<IBannerCategory[]>([])

  useEffect(() => {
    getBanners()
    getBannerCategory()
  }, [])

  const getBannerCategory = async () => {
    const getBannerCategoriesApi = new GetBannerCategoriesApi({})
    getBannerCategoriesApi.getBannerCategories().then(res => {
      if (res) {
        setBannerCategory(res)
      }
    })
  }

  const getBanners = () => {
    const api: GetBannersApi = new GetBannersApi({})
    api
      .getBanners()
      .then((res: IBanner[]) => {
        const sortedBanners = res.sort((a, b) => a.Order - b.Order)
        dispatch({ banners: sortedBanners })
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  useEffect(() => {
    dispatch({
      sliderStopwatchesOnTop: [],
      oneAfterTheOtherStopwatchesOnTop: [],
      topLeftBanners: [],
      bottomLeftBanners: [],
      rightBanners: [],
      sliderStopwatchesOnBottom: [],
      oneAfterTheOtherStopwatchesOnBottom: [],
      specialForToday: false,
      campaigns: false,
      newYear: false,
      privileges: false,
    })
    let filteredBanners = state.banners.filter(
      (bannerItem: IBanner) =>
        (bannerItem.CompanyList && bannerItem.CompanyList.indexOf(theme.id) > -1) ||
        (theme.id === 0 && bannerItem.IsHomepage)
    )
    if (filteredBanners && filteredBanners.length > 0) {
      const specialForMe: IBanner[] = filteredBanners.filter((banner: IBanner) => {
        return banner.Location === ILocationEnum.BanaOzel
      })
      dispatch({
        sliderStopwatchesOnTop: filteredBanners.filter((banner: IBanner) => {
          return (
            banner.Location === ILocationEnum.SayacAnaSayfaUst && banner.CounterLocation === ICounterLocationEnum.Slider
          )
        }),
        oneAfterTheOtherStopwatchesOnTop: filteredBanners.filter((banner: IBanner) => {
          return (
            banner.Location === ILocationEnum.SayacAnaSayfaUst &&
            banner.CounterLocation === ICounterLocationEnum.AltAlta
          )
        }),
        topLeftBanners: filteredBanners.filter((banner: IBanner) => {
          return banner.Location === ILocationEnum.AnaSayfaSolUst
        }),
        bottomLeftBanners: filteredBanners.filter((banner: IBanner) => {
          return banner.Location === ILocationEnum.AnaSayfaSolAlt
        }),
        rightBanners: filteredBanners.filter((banner: IBanner) => {
          return banner.Location === ILocationEnum.AnaSayfaSag
        }),
        sliderStopwatchesOnBottom: filteredBanners.filter((banner: IBanner) => {
          return (
            banner.Location === ILocationEnum.SayacAnaSayfaAlt && banner.CounterLocation === ICounterLocationEnum.Slider
          )
        }),
        oneAfterTheOtherStopwatchesOnBottom: filteredBanners.filter((banner: IBanner) => {
          return (
            banner.Location === ILocationEnum.SayacAnaSayfaAlt &&
            banner.CounterLocation === ICounterLocationEnum.AltAlta
          )
        }),
        specialForMe: specialForMe[0],
      })
    }
  }, [state.banners, theme.id])

  useEffect(() => {
    if (state.rightBanners && state.rightBanners.length > 0) {
      state.rightBanners.map((right: any) => {
        if (right.Category === 1) return dispatch({ specialForToday: true })
        if (right.Category === 2) return dispatch({ campaigns: true })
        if (right.Category === 3) return dispatch({ newYear: true })
        if (right.Category === 4) return dispatch({ privileges: true })
      })
    }
  }, [state.rightBanners])
  useEffect(() => {
    if (state.specialForToday) return setActiveTab(SliderCategory.SpecialForToday)
    if (state.campaigns && !state.specialForToday) return setActiveTab(SliderCategory.Campaigns)
    if (state.newYear && !state.campaigns && !state.specialForToday) return setActiveTab(SliderCategory.NewYear)
    if (state.privileges && !state.newYear && !state.campaigns && !state.specialForToday)
      return setActiveTab(SliderCategory.Privileges)
  }, [state.specialForToday, state.campaigns, state.newYear, state.privileges])

  const fullWidthLinks = css`
    a {
      width: 100%;
    }
  `

  const navLinkActive = css`
    color: ${theme.colors.backgroundColor}!important;
    border-bottom: 3px solid ${theme.colors.backgroundColor}!important;
  `

  const iQuotaFormRef = React.useRef<ProductIQuotaFormHandles>(null)
  const user = useAppSelector(currentUser)

  const forwardIQuotePage = () => {
    /**
      Mandatory Field uEmail was not included in the request body
      Mandatory Field uName was not included in the request body
      Mandatory Field cAccountNum was not included in the request body
      Mandatory Field cName was not included in the request body
      Mandatory Field cPCode was not included in the request body
      */
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.uEmail, 'vvvv@bbbb.com')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.uName, 'bvcb')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.cAccountNum, '054455411100000')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.cName, 'com')
    iQuotaFormRef.current?.setFormInputValueByName(ProductIQuotaFormField.cPCode, '34406')
    iQuotaFormRef.current?.setFormInputValueByName(
      ProductIQuotaFormField.ref,
      `${window.location.origin}/api/iquote-service-response/${user.SessionId}`
    )
    iQuotaFormRef.current?.setFormInputValueByName(
      ProductIQuotaFormField.BasketURL,
      `${window.location.origin}/api/iquote-service-response/${user.SessionId}`
    )
    iQuotaFormRef.current?.submitForm()
  }

  const [clickLocked, setClickLocked] = useState(false)

  const handleOutBoundClicks = async (bannerId: number, bannerName: string) => {
    if (clickLocked) return
    setClickLocked(true)

    const req: OutboundClickRequestModel = {
      BannerId: bannerId,
      BannerName: bannerName,
    }
    try {
      const postOutboundlicks: PostOutboundClicksApi = new PostOutboundClicksApi({})
      await postOutboundlicks.postOutboundClicks(req)
    } catch (error) {
      console.error('Error posting company homepage clicks:', error)
    } finally {
      setClickLocked(false)
    }
  }

  return (
    <section className={`home-page-slider ${fullWidthLinks}`}>
      <div className='container'>
        {state.sliderStopwatchesOnTop && state.sliderStopwatchesOnTop.length > 0 && (
          <div className='row mb-4'>
            <div className='col-12'>
              <Swiper
                navigation={true}
                keyboard={true}
                loop={state.sliderStopwatchesOnTop.length === 1 ? false : true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                observer={true}
                observeParents={true}
                modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
                className='mySwiper'
              >
                {state.sliderStopwatchesOnTop.map((stopWatch: IBanner, index: number) => {
                  return (
                    <SwiperSlide key={index}>
                      {stopWatch.BannerType !== IBannerTypeEnum.LinkYok ? (
                        stopWatch.ProductAddress ? (
                          <a
                            href={stopWatch.ProductAddress}
                            target={stopWatch.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                            rel='noreferrer'
                          >
                            <Stopwatch
                              startDate={new Date(stopWatch.StartDate)}
                              endDate={new Date(stopWatch.EndDate)}
                              imageUrl={stopWatch.ImageUrl}
                              isInSlider={true}
                              text={stopWatch.Description}
                              height={props.height}
                            />
                          </a>
                        ) : (
                          <Link
                            to={`/products?bannerId=${stopWatch.Id}`}
                            target={stopWatch.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                          >
                            <Stopwatch
                              startDate={new Date(stopWatch.StartDate)}
                              endDate={new Date(stopWatch.EndDate)}
                              imageUrl={stopWatch.ImageUrl}
                              isInSlider={true}
                              height={props.height}
                              text={stopWatch.Description}
                            />
                          </Link>
                        )
                      ) : (
                        <Stopwatch
                          startDate={new Date(stopWatch.StartDate)}
                          endDate={new Date(stopWatch.EndDate)}
                          imageUrl={stopWatch.ImageUrl}
                          isInSlider={true}
                          height={props.height}
                          text={stopWatch.Description}
                        />
                      )}
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        )}
        {state.oneAfterTheOtherStopwatchesOnTop &&
          state.oneAfterTheOtherStopwatchesOnTop.map((stopWatch: IBanner) => {
            return (
              <div className='row mb-4' key={stopWatch.Id}>
                <div className='col-12'>
                  <SwiperSlide key={stopWatch.Id}>
                    {stopWatch.BannerType !== IBannerTypeEnum.LinkYok ? (
                      stopWatch.ProductAddress ? (
                        <a
                          href={stopWatch.ProductAddress}
                          target={stopWatch.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                          rel='noreferrer'
                          onClick={() => {
                            if (!stopWatch.ProductAddress.includes('https://www.indexgruppazar.com'))
                              handleOutBoundClicks(stopWatch.Id, stopWatch.Title)
                          }}
                        >
                          <Stopwatch
                            startDate={new Date(stopWatch.StartDate)}
                            endDate={new Date(stopWatch.EndDate)}
                            imageUrl={stopWatch.ImageUrl}
                            isInSlider={true}
                            height={props.height}
                            text={stopWatch.Description}
                          />
                        </a>
                      ) : (
                        <Link
                          to={`/products?bannerId=${stopWatch.Id}`}
                          target={stopWatch.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                        >
                          <Stopwatch
                            startDate={new Date(stopWatch.StartDate)}
                            endDate={new Date(stopWatch.EndDate)}
                            imageUrl={stopWatch.ImageUrl}
                            isInSlider={true}
                            height={props.height}
                            text={stopWatch.Description}
                          />
                        </Link>
                      )
                    ) : (
                      <Stopwatch
                        startDate={new Date(stopWatch.StartDate)}
                        endDate={new Date(stopWatch.EndDate)}
                        imageUrl={stopWatch.ImageUrl}
                        isInSlider={true}
                        height={props.height}
                        text={stopWatch.Description}
                      />
                    )}
                  </SwiperSlide>
                </div>
              </div>
            )
          })}
        {/* <div className={bannerStyles}>
          <div className='banner__small'>
            <div className='banner__swiper'></div>
            <div className='banner__swiper'></div>
          </div>
          <div className='banner__main'></div>
        </div> */}
        <div className='banner'>
          {state.topLeftBanners.length > 0 && state.bottomLeftBanners.length > 0 && (
            <div className='banner__small'>
              {state.topLeftBanners.length > 0 && (
                <div className='banner__swiper'>
                  <Swiper
                    navigation={true}
                    pagination={{ clickable: true }}
                    loop={state.bottomLeftBanners.length === 1 ? false : true}
                    mousewheel={true}
                    keyboard={true}
                    autoplay={{
                      delay: 15000,
                      disableOnInteraction: false,
                    }}
                    observer={true}
                    observeParents={true}
                    modules={[Navigation, Pagination, Autoplay, Mousewheel, Keyboard]}
                    className='mySwiper mb-2'
                  >
                    {state.topLeftBanners
                      .filter(item => {
                        {
                          return item.Location == ILocationEnum.AnaSayfaSolUst
                        }
                      })
                      .map((banner, index) => {
                        return (
                          <SwiperSlide key={index}>
                            {banner.BannerType === IBannerTypeEnum.YoutubeVideo ? (
                              banner.VideoId && (
                                <iframe
                                  width='100%'
                                  style={{ height: '400px' }}
                                  src={'https://www.youtube.com/embed/' + banner.VideoId}
                                  title='YouTube video player'
                                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                ></iframe>
                              )
                            ) : banner.BannerType === IBannerTypeEnum.VimeoVideo ? (
                              banner.VideoId && (
                                <iframe
                                  src={
                                    'https://player.vimeo.com/video/' + banner.VideoId + '?h=ff2b296e05&color=ffffff'
                                  }
                                  width='640'
                                  height='360'
                                  allow='autoplay; fullscreen; picture-in-picture'
                                ></iframe>
                              )
                            ) : banner.BannerType !== IBannerTypeEnum.LinkYok ? (
                              banner.Image && banner.Image.toLocaleLowerCase('tr-TR').indexOf('.mp4') > -1 ? (
                                <video
                                  width='100%'
                                  preload='auto'
                                  autoPlay
                                  muted
                                  controls
                                  style={{ height: '100%', padding: '0px 0 0 0' }}
                                >
                                  <source src={banner.ImageUrl} type='video/mp4'></source>
                                  Sorry, your browser doesn't support videos.
                                </video>
                              ) : // ) : banner.ProductAddress ? (
                              //   <a
                              //     href={banner.ProductAddress}
                              //     target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                              //     rel='noreferrer'
                              //   >
                              //     {
                              //       <Image
                              //         className='banner'
                              //         style={{ height: '100%', padding: '0px 0 0 0' }}
                              //         src={banner.ImageUrl}
                              //         alt={banner.Title}
                              //       />
                              //     }
                              //   </a>
                              // )

                              /* Anasayfa iQuote yönlendirmesi */
                              banner.ProductAddress.includes('https://iquote.hpe.com') ? (
                                <img
                                  loading='lazy'
                                  className='banner'
                                  style={{ height: '100%', padding: '0px 0 0 0', cursor: 'pointer' }}
                                  src={banner.ImageUrl}
                                  alt={banner.Title}
                                  onClick={() => {
                                    forwardIQuotePage()
                                    handleOutBoundClicks(banner.Id, banner.Title)
                                  }}
                                />
                              ) : banner.ProductAddress ? (
                                <a
                                  className={
                                    banner.ProductAddress.includes('https://www.indexgruppazar.com')
                                      ? ''
                                      : 'outbound_link'
                                  }
                                  href={banner.ProductAddress}
                                  target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                  rel='noreferrer'
                                  onClick={() => {
                                    if (!banner.ProductAddress.includes('https://www.indexgruppazar.com'))
                                      handleOutBoundClicks(banner.Id, banner.Title)
                                  }}
                                >
                                  {
                                    <Image
                                      className='banner'
                                      style={{ height: '100%', padding: '0px 0 0 0' }}
                                      src={banner.ImageUrl}
                                      alt={banner.Title}
                                    />
                                  }
                                </a>
                              ) : (
                                <Link
                                  to={`/products?bannerId=${banner.Id}`}
                                  target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                >
                                  <Image
                                    className='banner'
                                    style={{ height: '100%', padding: '0px 0 0 0' }}
                                    src={banner.ImageUrl}
                                    alt={banner.Title}
                                  />
                                </Link>
                              )
                            ) : (
                              <Image
                                className='banner'
                                style={{ height: '100%', padding: '0px 0 0 0' }}
                                src={banner.ImageUrl}
                                alt={banner.Title}
                              />
                            )}
                          </SwiperSlide>
                        )
                      })}
                  </Swiper>
                </div>
              )}
              {state.bottomLeftBanners.length > 0 && (
                <div className='banner__swiper'>
                  <Swiper
                    navigation={true}
                    loop={state.bottomLeftBanners.length === 1 ? false : true}
                    autoplay={{
                      delay: 15000,
                      disableOnInteraction: false,
                    }}
                    observer={true}
                    observeParents={true}
                    mousewheel={true}
                    keyboard={true}
                    pagination={{ clickable: true }}
                    modules={[Navigation, Pagination, Autoplay, Mousewheel, Keyboard]}
                    className='mySwiper'
                  >
                    {state.bottomLeftBanners
                      .filter(item => {
                        {
                          return item
                        }
                      })
                      .map((banner, index) => {
                        return (
                          <SwiperSlide key={index}>
                            {banner.BannerType === IBannerTypeEnum.YoutubeVideo ? (
                              banner.VideoId && (
                                <iframe
                                  width='100%'
                                  style={{ height: '400px' }}
                                  src={'https://www.youtube.com/embed/' + banner.VideoId}
                                  title='YouTube video player'
                                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                ></iframe>
                              )
                            ) : banner.BannerType === IBannerTypeEnum.VimeoVideo ? (
                              banner.VideoId && (
                                <iframe
                                  src={
                                    'https://player.vimeo.com/video/' + banner.VideoId + '?h=ff2b296e05&color=ffffff'
                                  }
                                  width='640'
                                  height='360'
                                  allow='autoplay; fullscreen; picture-in-picture'
                                ></iframe>
                              )
                            ) : banner.BannerType !== IBannerTypeEnum.LinkYok ? (
                              banner.Image && banner.Image.toLocaleLowerCase('tr-TR').indexOf('.mp4') > -1 ? (
                                <video
                                  width='100%'
                                  preload='auto'
                                  autoPlay
                                  muted
                                  controls
                                  style={{ height: '100%', padding: '0px 0 0 0' }}
                                >
                                  <source src={banner.ImageUrl} type='video/mp4'></source>
                                  Sorry, your browser doesn't support videos.
                                </video>
                              ) : banner.ProductAddress ? (
                                <a
                                  className={
                                    banner.ProductAddress.includes('https://www.indexgruppazar.com')
                                      ? ''
                                      : 'outbound_link'
                                  }
                                  href={banner.ProductAddress}
                                  target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                  rel='noreferrer'
                                  onClick={() => {
                                    if (!banner.ProductAddress.includes('https://www.indexgruppazar.com'))
                                      handleOutBoundClicks(banner.Id, banner.Title)
                                  }}
                                >
                                  {
                                    <Image
                                      className='banner'
                                      style={{ height: '100%', padding: '0px 0 0 0' }}
                                      src={banner.ImageUrl}
                                      alt={banner.Title}
                                    />
                                  }
                                </a>
                              ) : (
                                <Link
                                  to={`/products?bannerId=${banner.Id}`}
                                  target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                >
                                  {
                                    <Image
                                      className='banner'
                                      style={{ height: '100%', padding: '0px 0 0 0' }}
                                      src={banner.ImageUrl}
                                      alt={banner.Title}
                                    />
                                  }
                                </Link>
                              )
                            ) : (
                              <Image
                                className='banner'
                                style={{ height: '100%', padding: '0px 0 0 0' }}
                                src={banner.ImageUrl}
                                alt={banner.Title}
                              />
                            )}
                          </SwiperSlide>
                        )
                      })}
                  </Swiper>
                </div>
              )}
            </div>
          )}
          {state.rightBanners && state.rightBanners.length > 0 && (
            <div
              className='banner__main'
              style={ServiceRouter.getUrl() === 'https://dev-b2b.vbt.com.tr:1443' ? {} : { MozPaddingEnd: '500px' }}
            >
              <div className='tabs' style={{ height: '100%' }}>
                <ul className='nav nav-tabs' id='myTab' role='tablist' style={{ height: 36 }}>
                  {state.specialForToday && (
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${activeTab === SliderCategory.SpecialForToday ? navLinkActive : ''} ${css`
                          ascent-override: 0%;
                        `}`}
                        id={`${SliderCategory.SpecialForToday}`}
                        data-toggle='tab'
                        href='#specialfor'
                        role='tab'
                        aria-controls='specialfor'
                        aria-selected='true'
                        style={{ height: '36px' }}
                        onClick={() => setActiveTab(SliderCategory.SpecialForToday)}
                      >
                        {bannerCategory.find(ban => ban.Id === 1)?.Description}
                      </a>
                    </li>
                  )}
                  {state.campaigns && (
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${activeTab === SliderCategory.Campaigns ? navLinkActive : ''}`}
                        id={`${SliderCategory.Campaigns}`}
                        data-toggle='tab'
                        href='#campaigns'
                        role='tab'
                        aria-controls='campaigns'
                        aria-selected='false'
                        onClick={() => setActiveTab(SliderCategory.Campaigns)}
                        style={{ height: '36px' }}
                      >
                        {bannerCategory.find(ban => ban.Id === 2)?.Description}
                      </a>
                    </li>
                  )}
                  {state.newYear && (
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${activeTab === SliderCategory.NewYear ? navLinkActive : ''}`}
                        id={`${SliderCategory.NewYear}`}
                        data-toggle='tab'
                        href='#newyear'
                        role='tab'
                        aria-controls='newyear'
                        aria-selected='false'
                        style={{ height: '36px' }}
                        onClick={() => setActiveTab(SliderCategory.NewYear)}
                      >
                        {bannerCategory.find(ban => ban.Id === 3)?.Description}
                      </a>
                    </li>
                  )}
                  {state.privileges && (
                    <li className='nav-item'>
                      <a
                        className={`nav-link ${activeTab === SliderCategory.Privileges ? navLinkActive : ''}`}
                        id={`${SliderCategory.Privileges}`}
                        data-toggle='tab'
                        href='#privileges'
                        role='tab'
                        aria-controls='privileges'
                        aria-selected='false'
                        onClick={() => setActiveTab(SliderCategory.Privileges)}
                        style={{ height: '36px' }}
                      >
                        {bannerCategory.find(ban => ban.Id === 4)?.Description}
                      </a>
                    </li>
                  )}
                </ul>
                <div style={{ height: 'calc(100% - 36px)' }}>
                  {activeTab === SliderCategory.SpecialForToday && (
                    <div
                      className='tab-pane fade active show'
                      id={SliderCategory.SpecialForToday.toString()}
                      role='tabpanel'
                      aria-labelledby='specialfor'
                      style={{ height: '100%' }}
                    >
                      <Swiper
                        navigation={true}
                        keyboard={true}
                        loop={SliderLoop(state.rightBanners, ILocationEnum.AnaSayfaSag, Number(activeTab))}
                        autoplay={{
                          delay: 15000,
                          disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        observer={true}
                        observeParents={true}
                        modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
                        className='mySwiper'
                      >
                        {state.rightBanners &&
                          state.rightBanners
                            .filter((bannerItem: IBanner) => {
                              return (
                                bannerItem.Location === ILocationEnum.AnaSayfaSag &&
                                bannerItem.Category &&
                                bannerItem.Category === Number(activeTab)
                              )
                            })
                            .map((banner: IBanner, index: number) => {
                              return (
                                <SwiperSlide key={index}>
                                  {banner.BannerType === IBannerTypeEnum.YoutubeVideo ? (
                                    banner.VideoId && (
                                      <iframe
                                        width='100%'
                                        style={{ height: '400px' }}
                                        src={'https://www.youtube.com/embed/' + banner.VideoId}
                                        title='YouTube video player'
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                      ></iframe>
                                    )
                                  ) : banner.BannerType === IBannerTypeEnum.VimeoVideo ? (
                                    banner.VideoId && (
                                      <iframe
                                        src={
                                          'https://player.vimeo.com/video/' +
                                          banner.VideoId +
                                          '?h=ff2b296e05&color=ffffff'
                                        }
                                        width='640'
                                        height='360'
                                        allow='autoplay; fullscreen; picture-in-picture'
                                      ></iframe>
                                    )
                                  ) : banner.BannerType !== IBannerTypeEnum.LinkYok ? (
                                    banner.Image && banner.Image.toLocaleLowerCase('tr-TR').indexOf('.mp4') > -1 ? (
                                      <video
                                        width='100%'
                                        preload='auto'
                                        autoPlay
                                        muted
                                        controls
                                        style={{ height: '100%', padding: '0px 0 0 0' }}
                                      >
                                        <source src={banner.ImageUrl} type='video/mp4'></source>
                                        Sorry, your browser doesn't support videos.
                                      </video>
                                    ) : banner.ProductAddress ? (
                                      <a
                                        className={
                                          banner.ProductAddress.includes('https://www.indexgruppazar.com')
                                            ? ''
                                            : 'outbound_link'
                                        }
                                        href={banner.ProductAddress}
                                        target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                        rel='noreferrer'
                                        onClick={() => {
                                          if (!banner.ProductAddress.includes('https://www.indexgruppazar.com'))
                                            handleOutBoundClicks(banner.Id, banner.Title)
                                        }}
                                      >
                                        {
                                          <Image
                                            className='banner'
                                            style={{
                                              height: '100%',
                                              padding: '0px 0 0 0',
                                              objectFit: 'contain',
                                              objectPosition: 'bottom',
                                              backgroundColor: '#f5f5f5',
                                            }}
                                            src={banner.ImageUrl}
                                            alt={banner.Title}
                                          />
                                        }
                                      </a>
                                    ) : (
                                      <Link
                                        to={`/products?bannerId=${banner.Id}`}
                                        target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                      >
                                        {
                                          <Image
                                            className='banner'
                                            style={{
                                              height: '100%',
                                              padding: '0px 0 0 0',
                                              objectFit: 'contain',
                                              objectPosition: 'bottom',
                                              backgroundColor: '#f5f5f5',
                                            }}
                                            src={banner.ImageUrl}
                                            alt={banner.Title}
                                          />
                                        }
                                      </Link>
                                    )
                                  ) : (
                                    <Image
                                      className='banner'
                                      style={{
                                        height: '100%',
                                        padding: '0px 0 0 0',
                                        objectFit: 'contain',
                                        objectPosition: 'bottom',
                                        backgroundColor: '#f5f5f5',
                                      }}
                                      src={banner.ImageUrl}
                                      alt={banner.Title}
                                    />
                                  )}
                                </SwiperSlide>
                              )
                            })}
                      </Swiper>
                    </div>
                  )}
                  {activeTab === SliderCategory.Campaigns && (
                    <div
                      className='tab-pane fade active show'
                      id={SliderCategory.Campaigns.toString()}
                      role='tabpanel'
                      aria-labelledby='campaigns-tab'
                      style={{ height: '100%' }}
                    >
                      <Swiper
                        navigation={true}
                        keyboard={true}
                        loop={SliderLoop(state.rightBanners, ILocationEnum.AnaSayfaSag, Number(activeTab))}
                        autoplay={{
                          delay: 15000,
                          disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        observer={true}
                        observeParents={true}
                        modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
                        className='mySwiper'
                      >
                        {state.rightBanners &&
                          state.rightBanners
                            .filter((bannerItem: IBanner) => {
                              return (
                                bannerItem.Location === ILocationEnum.AnaSayfaSag &&
                                bannerItem.Category &&
                                bannerItem.Category === Number(activeTab)
                              )
                            })
                            .map((banner: IBanner, index: number) => {
                              return (
                                <SwiperSlide key={index}>
                                  {banner.BannerType === IBannerTypeEnum.YoutubeVideo ? (
                                    banner.VideoId && (
                                      <iframe
                                        width='100%'
                                        style={{ height: '400px' }}
                                        src={'https://www.youtube.com/embed/' + banner.VideoId}
                                        title='YouTube video player'
                                        frameBorder='0'
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                        allowFullScreen
                                      ></iframe>
                                    )
                                  ) : banner.BannerType === IBannerTypeEnum.VimeoVideo ? (
                                    banner.VideoId && (
                                      <iframe
                                        src={
                                          'https://player.vimeo.com/video/' +
                                          banner.VideoId +
                                          '?h=ff2b296e05&color=ffffff'
                                        }
                                        width='100%'
                                        height='400px'
                                        allow='autoplay; fullscreen; picture-in-picture'
                                      ></iframe>
                                    )
                                  ) : banner.BannerType !== IBannerTypeEnum.LinkYok ? (
                                    banner.Image && banner.Image.toLocaleLowerCase('tr-TR').indexOf('.mp4') > -1 ? (
                                      <video
                                        width='100%'
                                        preload='auto'
                                        autoPlay
                                        muted
                                        controls
                                        style={{ height: '100%', padding: '0px 0 0 0' }}
                                      >
                                        <source src={banner.ImageUrl} type='video/mp4'></source>
                                        Sorry, your browser doesn't support videos.
                                      </video>
                                    ) : banner.ProductAddress ? (
                                      <a
                                        className={
                                          banner.ProductAddress.includes('https://www.indexgruppazar.com')
                                            ? ''
                                            : 'outbound_link'
                                        }
                                        href={banner.ProductAddress}
                                        target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                        rel='noreferrer'
                                        onClick={() => {
                                          if (!banner.ProductAddress.includes('https://www.indexgruppazar.com'))
                                            handleOutBoundClicks(banner.Id, banner.Title)
                                        }}
                                      >
                                        {
                                          <Image
                                            className='banner'
                                            // style={{ height: '100%', padding: '0px 0 0 0' }}
                                            style={{
                                              height: '100%',
                                              padding: '0px 0 0 0',
                                              objectFit: 'contain',
                                              objectPosition: 'bottom',
                                              backgroundColor: '#f5f5f5',
                                            }}
                                            src={banner.ImageUrl}
                                            alt={banner.Title}
                                          />
                                        }
                                      </a>
                                    ) : (
                                      <Link
                                        to={`/products?bannerId=${banner.Id}`}
                                        target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                      >
                                        {
                                          <Image
                                            className='banner'
                                            // style={{ height: '100%', padding: '0px 0 0 0' }}
                                            style={{
                                              height: '100%',
                                              padding: '0px 0 0 0',
                                              objectFit: 'contain',
                                              objectPosition: 'bottom',
                                              backgroundColor: '#f5f5f5',
                                            }}
                                            src={banner.ImageUrl}
                                            alt={banner.Title}
                                          />
                                        }
                                      </Link>
                                    )
                                  ) : (
                                    <Image
                                      className='banner'
                                      // style={{ height: '100%', padding: '0px 0 0 0' }}
                                      style={{
                                        height: '100%',
                                        padding: '0px 0 0 0',
                                        objectFit: 'contain',
                                        objectPosition: 'bottom',
                                        backgroundColor: '#f5f5f5',
                                      }}
                                      src={banner.ImageUrl}
                                      alt={banner.Title}
                                    />
                                  )}
                                </SwiperSlide>
                              )
                            })}
                      </Swiper>{' '}
                    </div>
                  )}
                  {activeTab === SliderCategory.NewYear && (
                    <div
                      className='tab-pane fade active show'
                      id={SliderCategory.NewYear.toString()}
                      role='tabpanel'
                      aria-labelledby='newyear-tab'
                      style={{ height: '100%' }}
                    >
                      <Swiper
                        navigation={true}
                        keyboard={true}
                        loop={SliderLoop(state.rightBanners, ILocationEnum.AnaSayfaSag, Number(activeTab))}
                        autoplay={{
                          delay: 15000,
                          disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        observer={true}
                        observeParents={true}
                        modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
                        className='mySwiper'
                      >
                        {state.rightBanners &&
                          state.rightBanners
                            .filter((bannerItem: IBanner) => {
                              return (
                                bannerItem.Location === ILocationEnum.AnaSayfaSag &&
                                bannerItem.Category &&
                                bannerItem.Category === Number(activeTab)
                              )
                            })
                            .map((banner: IBanner, index: number) => {
                              return (
                                <SwiperSlide key={index}>
                                  {banner.BannerType === IBannerTypeEnum.YoutubeVideo ? (
                                    banner.VideoId && (
                                      <iframe
                                        width='100%'
                                        style={{ height: '400px' }}
                                        src={'https://www.youtube.com/embed/' + banner.VideoId}
                                        title='YouTube video player'
                                        frameBorder='0'
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                        allowFullScreen
                                      ></iframe>
                                    )
                                  ) : banner.BannerType === IBannerTypeEnum.VimeoVideo ? (
                                    banner.VideoId && (
                                      <iframe
                                        src={
                                          'https://player.vimeo.com/video/' +
                                          banner.VideoId +
                                          '?h=ff2b296e05&color=ffffff'
                                        }
                                        width='640'
                                        height='360'
                                        allow='autoplay; fullscreen; picture-in-picture'
                                      ></iframe>
                                    )
                                  ) : banner.BannerType !== IBannerTypeEnum.LinkYok ? (
                                    banner.Image && banner.Image.toLocaleLowerCase('tr-TR').indexOf('.mp4') > -1 ? (
                                      <video
                                        width='100%'
                                        preload='auto'
                                        autoPlay
                                        muted
                                        controls
                                        style={{ height: '100%', padding: '0px 0 0 0' }}
                                      >
                                        <source src={banner.ImageUrl} type='video/mp4'></source>
                                        Sorry, your browser doesn't support videos.
                                      </video>
                                    ) : banner.ProductAddress ? (
                                      <a
                                        className={
                                          banner.ProductAddress.includes('https://www.indexgruppazar.com')
                                            ? ''
                                            : 'outbound_link'
                                        }
                                        href={banner.ProductAddress}
                                        target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                        rel='noreferrer'
                                        onClick={() => {
                                          if (!banner.ProductAddress.includes('https://www.indexgruppazar.com'))
                                            handleOutBoundClicks(banner.Id, banner.Title)
                                        }}
                                      >
                                        {
                                          <Image
                                            className='banner'
                                            // style={{ height: '100%', padding: '0px 0 0 0' }}
                                            style={{
                                              height: '100%',
                                              padding: '0px 0 0 0',
                                              objectFit: 'contain',
                                              objectPosition: 'bottom',
                                              backgroundColor: '#f5f5f5',
                                            }}
                                            src={banner.ImageUrl}
                                            alt={banner.Title}
                                          />
                                        }
                                      </a>
                                    ) : (
                                      <Link
                                        to={`/products?bannerId=${banner.Id}`}
                                        target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                      >
                                        {
                                          <Image
                                            className='banner'
                                            // style={{ height: '100%', padding: '0px 0 0 0' }}
                                            style={{
                                              height: '100%',
                                              padding: '0px 0 0 0',
                                              objectFit: 'contain',
                                              objectPosition: 'bottom',
                                              backgroundColor: '#f5f5f5',
                                            }}
                                            src={banner.ImageUrl}
                                            alt={banner.Title}
                                          />
                                        }
                                      </Link>
                                    )
                                  ) : (
                                    <Image
                                      className='banner'
                                      // style={{ height: '100%', padding: '0px 0 0 0' }}
                                      style={{
                                        height: '100%',
                                        padding: '0px 0 0 0',
                                        objectFit: 'contain',
                                        objectPosition: 'bottom',
                                        backgroundColor: '#f5f5f5',
                                      }}
                                      src={banner.ImageUrl}
                                      alt={banner.Title}
                                    />
                                  )}
                                </SwiperSlide>
                              )
                            })}
                      </Swiper>
                    </div>
                  )}
                  {activeTab === SliderCategory.Privileges && (
                    <div
                      className='tab-pane fade active show'
                      id={SliderCategory.Privileges.toString()}
                      role='tabpanel'
                      aria-labelledby='privileges-tab'
                      style={{ height: '100%' }}
                    >
                      <Swiper
                        navigation={true}
                        keyboard={true}
                        loop={SliderLoop(state.rightBanners, ILocationEnum.AnaSayfaSag, Number(activeTab))}
                        autoplay={{
                          delay: 15000,
                          disableOnInteraction: false,
                        }}
                        pagination={{ clickable: true }}
                        observer={true}
                        observeParents={true}
                        modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
                        className='mySwiper'
                      >
                        {state.rightBanners &&
                          state.rightBanners
                            .filter((bannerItem: IBanner) => {
                              return (
                                bannerItem.Location === ILocationEnum.AnaSayfaSag &&
                                bannerItem.Category &&
                                bannerItem.Category === Number(activeTab)
                              )
                            })
                            .map((banner: IBanner, index: number) => {
                              return (
                                <SwiperSlide key={index}>
                                  {banner.BannerType === IBannerTypeEnum.YoutubeVideo ? (
                                    banner.VideoId && (
                                      <iframe
                                        width='100%'
                                        style={{ height: '400px' }}
                                        src={'https://www.youtube.com/embed/' + banner.VideoId}
                                        title='YouTube video player'
                                        frameBorder='0'
                                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                        allowFullScreen
                                      ></iframe>
                                    )
                                  ) : banner.BannerType === IBannerTypeEnum.VimeoVideo ? (
                                    banner.VideoId && (
                                      <iframe
                                        src={
                                          'https://player.vimeo.com/video/' +
                                          banner.VideoId +
                                          '?h=ff2b296e05&color=ffffff'
                                        }
                                        width='640'
                                        height='360'
                                        allow='autoplay; fullscreen; picture-in-picture'
                                      ></iframe>
                                    )
                                  ) : banner.BannerType !== IBannerTypeEnum.LinkYok ? (
                                    banner.Image && banner.Image.toLocaleLowerCase('tr-TR').indexOf('.mp4') > -1 ? (
                                      <video
                                        width='100%'
                                        preload='auto'
                                        autoPlay
                                        muted
                                        controls
                                        style={{ height: '100%', padding: '0px 0 0 0' }}
                                      >
                                        <source src={banner.ImageUrl} type='video/mp4'></source>
                                        Sorry, your browser doesn't support videos.
                                      </video>
                                    ) : banner.ProductAddress ? (
                                      <a
                                        className={
                                          banner.ProductAddress.includes('https://www.indexgruppazar.com')
                                            ? ''
                                            : 'outbound_link'
                                        }
                                        href={banner.ProductAddress}
                                        target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                        rel='noreferrer'
                                        onClick={() => {
                                          if (!banner.ProductAddress.includes('https://www.indexgruppazar.com'))
                                            handleOutBoundClicks(banner.Id, banner.Title)
                                        }}
                                      >
                                        {
                                          <Image
                                            className='banner'
                                            // style={{ height: '100%', padding: '0px 0 0 0' }}
                                            style={{
                                              height: '100%',
                                              padding: '0px 0 0 0',
                                              objectFit: 'contain',
                                              objectPosition: 'bottom',
                                              backgroundColor: '#f5f5f5',
                                            }}
                                            src={banner.ImageUrl}
                                            alt={banner.Title}
                                          />
                                        }
                                      </a>
                                    ) : (
                                      <Link
                                        to={`/products?bannerId=${banner.Id}`}
                                        target={banner.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                                      >
                                        {
                                          <Image
                                            className='banner'
                                            // style={{ height: '100%', padding: '0px 0 0 0' }}
                                            style={{
                                              height: '100%',
                                              padding: '0px 0 0 0',
                                              objectFit: 'contain',
                                              objectPosition: 'bottom',
                                              backgroundColor: '#f5f5f5',
                                            }}
                                            src={banner.ImageUrl}
                                            alt={banner.Title}
                                          />
                                        }
                                      </Link>
                                    )
                                  ) : (
                                    <Image
                                      className='banner'
                                      // style={{ height: '100%', padding: '0px 0 0 0' }}
                                      style={{
                                        height: '100%',
                                        padding: '0px 0 0 0',
                                        objectFit: 'contain',
                                        objectPosition: 'bottom',
                                        backgroundColor: '#f5f5f5',
                                      }}
                                      src={banner.ImageUrl}
                                      alt={banner.Title}
                                    />
                                  )}
                                </SwiperSlide>
                              )
                            })}
                      </Swiper>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        {state.sliderStopwatchesOnBottom && state.sliderStopwatchesOnBottom.length > 0 && (
          <div className='row mb-4 p-t-20'>
            <div className='col-12'>
              <Swiper
                navigation={true}
                keyboard={true}
                loop={state.sliderStopwatchesOnBottom.length === 1 ? false : true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{ clickable: true }}
                observer={true}
                observeParents={true}
                modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
                className='mySwiper'
              >
                {state.sliderStopwatchesOnBottom.map((stopWatch: IBanner, index: number) => {
                  return (
                    <SwiperSlide key={stopWatch.Id}>
                      {stopWatch.BannerType !== IBannerTypeEnum.LinkYok ? (
                        stopWatch.ProductAddress ? (
                          <a
                            href={stopWatch.ProductAddress}
                            target={stopWatch.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                            rel='noreferrer'
                            onClick={() => {
                              if (!stopWatch.ProductAddress.includes('https://www.indexgruppazar.com'))
                                handleOutBoundClicks(stopWatch.Id, stopWatch.Title)
                            }}
                          >
                            <Stopwatch
                              startDate={new Date(stopWatch.StartDate)}
                              endDate={new Date(stopWatch.EndDate)}
                              imageUrl={stopWatch.ImageUrl}
                              isInSlider={true}
                              height={props.height}
                              text={stopWatch.Description}
                            />
                          </a>
                        ) : (
                          <Link
                            to={`/products?bannerId=${stopWatch.Id}`}
                            target={stopWatch.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                          >
                            <Stopwatch
                              startDate={new Date(stopWatch.StartDate)}
                              endDate={new Date(stopWatch.EndDate)}
                              imageUrl={stopWatch.ImageUrl}
                              isInSlider={true}
                              height={props.height}
                              text={stopWatch.Description}
                            />
                          </Link>
                        )
                      ) : (
                        <Stopwatch
                          startDate={new Date(stopWatch.StartDate)}
                          endDate={new Date(stopWatch.EndDate)}
                          imageUrl={stopWatch.ImageUrl}
                          isInSlider={true}
                          height={props.height}
                          text={stopWatch.Description}
                        />
                      )}
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        )}
        {state.oneAfterTheOtherStopwatchesOnBottom &&
          state.oneAfterTheOtherStopwatchesOnBottom.map((stopWatch: IBanner) => {
            return (
              <div className='mb-4 mt-4' key={stopWatch.Id}>
                {stopWatch.BannerType !== IBannerTypeEnum.LinkYok ? (
                  <a
                    href={stopWatch.ProductAddress ? stopWatch.ProductAddress : `/products?bannerId=${stopWatch.Id}`}
                    target={stopWatch.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
                    rel='noreferrer'
                    onClick={() => {
                      if (!stopWatch.ProductAddress.includes('https://www.indexgruppazar.com'))
                        handleOutBoundClicks(stopWatch.Id, stopWatch.Title)
                    }}
                  >
                    <Stopwatch
                      startDate={new Date(stopWatch.StartDate)}
                      endDate={new Date(stopWatch.EndDate)}
                      imageUrl={stopWatch.ImageUrl}
                      isInSlider={true}
                      height={props.height}
                      text={stopWatch.Description}
                    />
                  </a>
                ) : (
                  <Stopwatch
                    startDate={new Date(stopWatch.StartDate)}
                    endDate={new Date(stopWatch.EndDate)}
                    imageUrl={stopWatch.ImageUrl}
                    isInSlider={true}
                    height={props.height}
                    text={stopWatch.Description}
                  />
                )}
              </div>
            )
          })}
        {state.specialForMe && state.specialForMe.BannerType !== IBannerTypeEnum.LinkYok ? (
          state.specialForMe.ProductAddress ? (
            <a
              href={state.specialForMe.ProductAddress}
              target={state.specialForMe.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
              rel='noreferrer'
            >
              <div
                className='homeSpecialBanner text-light text-center m-b-0 m-t-20 p-t-50 p-b-50 '
                style={{
                  backgroundImage: state.specialForMe.ImageUrl,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              ></div>
            </a>
          ) : (
            <Link
              to={`/products?bannerId=${state.specialForMe.Id}`}
              target={state.specialForMe.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''}
            >
              <div
                className='homeSpecialBanner text-light text-center m-b-0 m-t-20 p-t-50 p-b-50 '
                style={{
                  backgroundImage: state.specialForMe.ImageUrl,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
              ></div>
            </Link>
          )
        ) : (
          state.specialForMe && (
            <div
              className='homeSpecialBanner text-light text-center m-b-0 m-t-20 p-t-50 p-b-50 '
              style={{
                backgroundImage: state.specialForMe.ImageUrl,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></div>
          )
        )}
      </div>
      <ProductIQuotaForm ref={iQuotaFormRef} />
    </section>
  )
}
export default HomePageSlider
