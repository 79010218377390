import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { INotFoundSpecialPriceCodeRequest, INotFoundSpecialPriceCodeResponse } from '../models/models'

export class NotFoundSpecPriceListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/NotFoundSpecPriceList',
      requesterInfo,
    })
  }

  public async notFoundSpecPriceList(
    request: INotFoundSpecialPriceCodeRequest
  ): Promise<INotFoundSpecialPriceCodeResponse[]> {
    return await this.postAsync<INotFoundSpecialPriceCodeResponse[]>(request)
  }
}
