import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IAddress } from '../../../MyCard/models/models'

export class GetAddressSpecialApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'address/list-special',
      requesterInfo,
    })
  }

  public async getAddresses(): Promise<IAddress> {
    return await this.getAsync<IAddress>()
  }
}
