import _ from 'lodash'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { translate } from '../../../base/localization'
import { IException } from '../../../base/models'
import IPButton from '../../../components/IPButton'
import IPSelectBox, { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import Translations from '../../../translations'
import '../Openbox.scss'
import { IOpenBox } from '../models'
import { GetOpenBoxByUserIdApi } from '../services/getOpenBoxByUser'
import { ReadOpenBoxApi } from '../services/readOpenboxApi'

interface OpenboxState {
  accordionActiveKey: string
  openBoxes: IOpenBox[]
  monthsOption?: ISelectOption
  yearsOption?: ISelectOption
  years: ISelectOption[]
  filterOpenBoxes: IOpenBox[]
}

const ListItem = props => {
  const [showItem, setShowItem] = useState(false)

  return (
    <>
      <tr
        role='row'
        className='odd'
        onClick={() => {
          props.onOpenboxAccordionChange(props.openBox.Id, props.openBox.Enable)
          setShowItem(!showItem)
        }}
        style={{
          fontWeight: props.openBox.Enable ? '700' : '400',
          color: props.openBox.Enable ? 'black' : '#555A6C',
          fontSize: '1.3rem',
          cursor: 'pointer',
        }}
      >
        <td style={{ width: '20px' }}>
          {!props.openBox.Enable ? (
            <img src='/images/bell-regular.svg' alt='' width={20} />
          ) : (
            <img src='/images/bell-regular_kirmizi-01.svg' alt='' width={20} />
          )}
        </td>
        <td>{new Date(props.openBox.StartDate).toLocaleString('tr-TR')}</td>
        <td>{new Date(props.openBox.EndDate).toLocaleString('tr-TR')}</td>
        <td style={{ width: 300 }}>{props.openBox.Topic}</td>
      </tr>
      {showItem && (
        <tr role='row' className='odd'>
          <td colSpan={4}>
            <div
              className='list-inline mb-0'
              style={{
                fontSize: '1.3rem',
                color: 'rgb(97, 97, 97)',
              }}
            >
              {!props.openBox.Image && props.openBox.Link ? (
                <a
                  className={
                    props.openBox.Link.includes('https://www.indexgruppazar.com') ? 'link_id' : 'outbound_link'
                  }
                  href={props.openBox.Link}
                  style={{ cursor: 'pointer' }}
                  target='_blank'
                  rel='noreferrer'
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.openBox.Description ? props.openBox.Description : '',
                    }}
                  ></div>
                </a>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: props.openBox.Description ? props.openBox.Description : '',
                  }}
                ></div>
              )}

              {props.openBox.Image ? (
                props.openBox.Link ? (
                  <a
                    className={
                      props.openBox.Link.includes('https://www.indexgruppazar.com') ? 'link_id' : 'outbound_link'
                    }
                    href={props.openBox.Link}
                    style={{ cursor: 'pointer' }}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img src={props.openBox.ImageUrl} alt={props.openBox.Image} />
                  </a>
                ) : (
                  <img src={props.openBox.ImageUrl} alt={props.openBox.Image} />
                )
              ) : (
                ''
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  )
}

function Openbox(props: BaseComponentWithContextProps) {
  const months: ISelectOption[] = [
    { value: 0, label: 'Ocak' },
    { value: 1, label: 'Şubat' },
    { value: 2, label: 'Mart' },
    { value: 3, label: 'Nisan' },
    { value: 4, label: 'Mayıs' },
    { value: 5, label: 'Haziran' },
    { value: 6, label: 'Temmuz' },
    { value: 7, label: 'Ağustos' },
    { value: 8, label: 'Eylül' },
    { value: 9, label: 'Ekim' },
    { value: 10, label: 'Kasım' },
    { value: 11, label: 'Aralık' },
  ]

  const getInitialState = (): OpenboxState => {
    return {
      accordionActiveKey: '',
      openBoxes: [],
      years: [],
      filterOpenBoxes: [],
    }
  }

  const t = translate(Translations)

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<OpenboxState>(intialState)

  const getOpenBoxes = () => {
    props.showLoading()
    const getOpenBoxApi: GetOpenBoxByUserIdApi = new GetOpenBoxByUserIdApi({})
    getOpenBoxApi
      .getOpenBox()
      .then((res: IOpenBox[]) => {
        const year = new Date().getFullYear()
        const data = [
          { value: year, label: year },
          { value: year - 1, label: year - 1 },
          { value: year - 2, label: year - 2 },
        ]
        const first = new Date(year, new Date().getMonth(), 1)
        const end = new Date(year, new Date().getMonth() + 1, 1)
        const openBoxes = res.filter(open => {
          return open.StartDate && first < new Date(open.StartDate) && new Date(open.StartDate) < end
        })
        const open = res.filter(a => {
          return a.EndDate && first < new Date(a.EndDate) && new Date(a.EndDate) < end
        })
        const datas: IOpenBox[] = []
        openBoxes.map(box => {
          return datas.push(box)
        })
        open.map(box => {
          if (
            datas.findIndex(dt => {
              return dt.Id === box.Id
            }) > -1
          )
            return
          return datas.push(box)
        })
        if (data && data.length > 0)
          datas.sort((a, b) => {
            return b.Id - a.Id
          })
        const monthsSelect: any =
          new Date().getMonth() && months.length > 0
            ? months.find(mon => {
                return new Date().getMonth() === mon.value
              })
            : null
        setState({
          openBoxes: res,
          years: data,
          filterOpenBoxes: datas,
          yearsOption: { label: year, value: year },
          monthsOption: monthsSelect,
        })
        props.hideLoading()
      })
      .catch((err: IException) => {
        props.hideLoading()
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
      })
  }

  useEffect(() => {
    getOpenBoxes()
  }, [])

  const onOpenboxAccordionChange = (activeKey: string, isEnabled?: boolean) => {
    setState({ accordionActiveKey: activeKey === state.accordionActiveKey ? '' : activeKey })

    if (activeKey !== state.accordionActiveKey && isEnabled) {
      const api: ReadOpenBoxApi = new ReadOpenBoxApi({})

      api
        .readOpenBox(Number(activeKey))
        .then((res: boolean) => {
          if (res) {
            const tmpOpenboxes = _.clone(state.openBoxes)
            let tmpOpenBoxIndex = tmpOpenboxes.findIndex((box: IOpenBox) => {
              return Number(activeKey) === box.Id
            })

            if (tmpOpenboxes && tmpOpenBoxIndex > -1) {
              tmpOpenboxes[tmpOpenBoxIndex].Enable = false
            }

            setState({ openBoxes: tmpOpenboxes })
          }
        })
        .catch((err: IException) => {
          Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
        })
    }
  }

  const dateChange = () => {
    if ((state.monthsOption && !state.yearsOption) || (!state.monthsOption && state.yearsOption)) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lütfen ay ve yılı birlikte seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }
    if (!state.monthsOption || !state.yearsOption) {
      return setState({ filterOpenBoxes: state.openBoxes })
    }
    const first = new Date(+state.yearsOption?.value, +state.monthsOption?.value, 1)
    const end = new Date(+state.yearsOption?.value, +state.monthsOption?.value + 1, 1)
    const openBoxes = state.openBoxes.filter(open => {
      return open.StartDate && first < new Date(open.StartDate) && new Date(open.StartDate) < end
    })
    const open = state.openBoxes.filter(a => {
      return a.EndDate && first < new Date(a.EndDate) && new Date(a.EndDate) < end
    })
    const data: IOpenBox[] = []
    openBoxes.map(box => {
      return data.push(box)
    })
    open.map(box => {
      if (
        data.findIndex(dt => {
          return dt.Id === box.Id
        }) > -1
      )
        return
      return data.push(box)
    })
    if (data && data.length > 0)
      data.sort((a, b) => {
        return b.Id - a.Id
      })
    setState({ filterOpenBoxes: data })
  }

  return (
    <div className='openbox'>
      <div className='container'>
        <div className='row'>
          <p style={{ fontSize: '2rem', fontWeight: 500, color: '#616161' }}>{t('pages.openbox.header')}</p>
          <div className='col-12 col-lg-12'>
            <div className='row'>
              <div className='col-md-2'>
                <IPSelectBox
                  isClearable
                  className='UserType'
                  name='UserType'
                  onChangeSingle={(option: ISelectOption) => {
                    setState({ monthsOption: option })
                  }}
                  options={months}
                  value={state.monthsOption}
                />
              </div>

              <div className='col-md-2'>
                <IPSelectBox
                  isClearable
                  className='UserType'
                  name='UserType'
                  onChangeSingle={(option: ISelectOption) => {
                    setState({ yearsOption: option })
                  }}
                  options={state.years}
                  value={state.yearsOption}
                />
              </div>
              <div className='col-md-2'>
                <IPButton className='w-25' text={t('pages.openbox.show')} onClick={dateChange} />
              </div>
            </div>

            <hr />

            <div className='row'>
              <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                <div className='row'>
                  <div className='col-sm-12'>
                    <div className='table-responsive'>
                      <table
                        id='datatable'
                        className='table table-bordered table-hover dataTable'
                        style={{ minWidth: 800 }}
                        role='grid'
                        aria-describedby='datatable_info'
                      >
                        <thead>
                          <tr role='row' className='odd'>
                            <th style={{ width: '20px' }}>{t('pages.openbox.status')}</th>
                            <th style={{ width: '100px' }}>{t('pages.openbox.startDate')}</th>
                            <th style={{ width: '100px' }}>{t('pages.openbox.endDate')}</th>
                            <th>{t('pages.openbox.subject')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {state.filterOpenBoxes &&
                            state.filterOpenBoxes.length > 0 &&
                            state.filterOpenBoxes.map((openBox: IOpenBox) => (
                              <ListItem
                                key={openBox.Id}
                                openBox={openBox}
                                onOpenboxAccordionChange={onOpenboxAccordionChange}
                              />
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(Openbox)
