import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class DeleteWhiteDealerApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealer/deleteWhiteDealerList',
      requesterInfo,
    })
  }

  public async deleteWhiteDealer(request: any): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
