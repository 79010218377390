import * as yup from 'yup'

const validations = yup.object().shape({
  CompanyID: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .nullable(),
  LongName: yup.string().min(2, 'Kategori uzun ismi minumum 3 karakter olmalı.').required('Zorunlu alan'),
  IsMenu: yup.boolean(),
  ShortName: yup.string().min(2, 'Kategori kısa ismi minumum 3 karakter olmalı.').required('Zorunlu alan'),
  Sequence: yup.number().required('Zorunlu alan'),
})

export default validations
