import React, { useEffect, useState } from 'react'
import IPInput from '../../../../../components/IPInput'
import './AddUser.scss'
import { useFormik } from 'formik'
import validationSchema from './validation'
import './styles.scss'
import IPSelectBox from '../../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import IPCheckbox from '../../../../../components/IPCheckBox'
import IPTextarea from '../../../../../components/IPTextarea'
import _ from 'lodash'
import Swal from 'sweetalert2'
import { UpdateUserApi } from '../services/updateUserApi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { PostUserApi } from '../services/postUserApi'
import IPButton from '../../../../../components/IPButton'
import InputMask from '../../../../../components/InputMask'
import { GetUserByIdApi } from '../services/getUserByIdApi'
import { getCompanyApi, getDealerApi } from '../../../components/api'
import { ICompany, IDealer } from '../../../components/models/models'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'

interface IListUserState {
  //dealerLists?: IDealer[]
  id: number
  companies: ICompany[]
  companyOption: ISelectOption[]
}

function User(props: BaseComponentWithContextProps) {

  let navigate = useNavigate()
  const t = translate(Translations)

  const [status, setStatus] = useState<any>(null)
  const [accountCode, setAccountCode] = useState<any>(null)
  const [buttonName, setButtonName] = useState('Ekle')
  const [passwordBool, setPasswordBool] = useState(false)
  const statusOptions: ISelectOption[] = [
    {
      value: 1,
      label: t('pages.admin.userOperation.userCrud.addUser.active'),
    },
    {
      value: 2,
      label: t('pages.admin.userOperation.userCrud.addUser.passive'),
    },
    {
      value: 3,
      label: t('pages.admin.userOperation.userCrud.addUser.deleted'),
    },
    {
      value: 4,
      label: t('pages.admin.userOperation.userCrud.addUser.waitingForApproval'),
    },
    { value: 5, label: 'Bloke' },
  ]

  const initialValues = Object.assign({
    Id: 0,
    Status: '',
    Name: '',
    Surname: '',
    UserCode: '',
    UserName: '',
    PasswordHash: '',
    PasswordConfirm: '',
    Email: '',
    Gsm: '',
    Notes: '',
    IsSendMail: false,
    IsAdmin: false,
    IsSu: false,
    MtUser: false,
    ReportUser: false,
    MtReportCompanyList: ""
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    setValues,
    setFieldValue,
    setFieldTouched,
    validateField,
    validateForm,
    resetForm,
    setErrors,
    values,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValues,
    validationSchema,

    onSubmit: async (values, { setSubmitting, setErrors }) => {
      const errors = validateForm(values);

      if (Object.keys(errors).length > 0) {
        setErrors(await errors);
        setSubmitting(false);
        return;
      }
      try {
        resetForm();
        Swal.fire({
          icon: 'success',
          title: 'Form submitted successfully!',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Submission failed',
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      } finally {
        setSubmitting(false);
      }
    },
  })

  const routeLocation = useLocation()
  const getInitialState = (): IListUserState => {
    return {
      //dealerLists: [],
      id: 0,
      companies: [],
      companyOption: []
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListUserState>(intialState)
  useEffect(() => {
    if (state.companies.length > 0) {
      if (routeLocation.state as any) {
        if ((routeLocation.state as any)?.type === 'UserUpdate' || (routeLocation.state as any)?.type === 'Defined') {
          getUserList((routeLocation.state as any)?.userProp)
          setButtonName('Güncelle')
          setPasswordBool(true)
        }
      }
    }
  }, [state.companies])

  useEffect(() => {
    //getDealer()
    getCompany()
  }, [])

  const getUserList = async id => {
    props.showLoading()
    const getUserList: GetUserByIdApi = new GetUserByIdApi({})
    let DealerCode = "";
    let MtReportCompanyList = "";
    let Status = 0;
    await getUserList.getUserById(id).then(result => {
      if (result) {
        setValues(result)
        DealerCode = result.DealerCode
        MtReportCompanyList = result.MtReportCompanyList
        Status = result.Status
        props.hideLoading()
      }
    })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })

    if (MtReportCompanyList) {
      const data = MtReportCompanyList.split(";")
      const companyOption: ISelectOption[] = [];
      data.map((dt) => {
        if (state.companies.findIndex((com) => { return com.Code === +dt }) > -1) {
          const companySelect = state.companies.filter((com) => { return com.Code === +dt }).pop();
          if (companySelect)
            return companyOption.push({ value: companySelect.Code, label: companySelect.Name })
        }
      })
      setState({ companyOption: companyOption })
    }
    let newDealercode = DealerCode.length === 10 ? DealerCode.replace('0000', '') : DealerCode
    setAccountCode({
      value: newDealercode,
      label: newDealercode,
    })
    statusOptions
      .filter(s => {
        return Number(s.value) === Status
      })
      .map(u => {
        return setStatus({
          value: Status,
          label: u.label,
        })
      })
    setState({ id: (routeLocation.state as any)?.userProp })
  }

  const addUser = async () => {
    const clnValues = _.clone(values)
    const data: any[] = [];
    state.companyOption.map((mt) => data.push(String(mt.value)));
    let datas = data.join(';')
    clnValues.MtReportCompanyList = datas
    clnValues.Id = state.id
    clnValues.Status = status ? status.value : ''
    clnValues.UserCode = accountCode ? String(accountCode.value) : ''
    clnValues.UserCode = "0000" + clnValues.UserCode
    if (buttonName === 'Güncelle') {
      const updateUserApi: UpdateUserApi = new UpdateUserApi({})
      await updateUserApi
        .updateUser(clnValues)
        .then(async result => {
          Swal.fire({
            icon: 'success',
            title: t('pages.mySpecial.addNewUser.index.swalTitle'),
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    } else {
      if (clnValues.PasswordConfirm === clnValues.PasswordHash) {
        const addUserApi: PostUserApi = new PostUserApi({})
        await addUserApi
          .postUser(clnValues)
          .then(async result => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Kullanıcı Eklendi',
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      } else {
        Swal.fire({
          icon: 'success',
          title: 'Girilen Şifreler farklı',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    }
  }

  // const getDealer = async () => {
  //   const dealer: any[] = await getDealerApi()
  //   setState({ dealerLists: dealer })
  // }

  const getCompany = async () => {
    const company: ICompany[] = await getCompanyApi()
    setState({ companies: company })
  }

  const determineUrl = () => {
    const state = routeLocation.state as any;
    if (state?.type === 'User' || state?.type === 'UserUpdate') {
      return state?.url || '/admin/UserOperation/ListUser';
    } else {
      return state?.url || '/admin/UserOperation/DefinedCompany';
    }
  };


  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Kullanıcı Ekleme</span>
            <Link to={determineUrl()}>
              <IPButton
                type='button'
                className='btn btn-primary'
                text='Listeye Dön'
                onClick={() => console.log((routeLocation.state as any)?.type)}
              />
            </Link>

          </div>
          <div className='card-body'>
            <div className='form-group form-fg'>
              <form id='form2' className='form-validate' noValidate onSubmit={handleSubmit} autoComplete='off'>
                <div className='form-row m-t-20'>
                  <div className='form-group col-md-4'>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='Name'
                      placeholder={t('pages.admin.userOperation.userCrud.addUser.name')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Name}
                      errors={errors.Name as string}
                      touched={touched.Name as boolean}
                    />
                  </div>
                  <div className='form-group col-md-4'>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='Surname'
                      placeholder={t('pages.admin.userOperation.userCrud.addUser.surname')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Surname}
                      errors={errors.Surname as string}
                      touched={touched.Surname as boolean}
                    />
                  </div>
                  <div className='form-group col-md-4'>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='UserCode'
                      value={accountCode?.value}
                      onChange={e => setAccountCode({
                        value: e.target.value,
                        label: e.target.value,
                      })}
                      placeholder="Hesap Kodu"
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-4'>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='UserName'
                      placeholder={t('pages.admin.userOperation.userCrud.addUser.userName')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.UserName}
                      errors={errors.UserName as string}
                      touched={touched.UserName as boolean}
                    />
                  </div>
                  <div className='form-group col-md-4'>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='PasswordHash'
                      placeholder={t('pages.admin.userOperation.userCrud.addUser.password')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.PasswordHash}
                      disabled={passwordBool}
                      autoComplete='false'
                    />
                  </div>
                  <div className='form-group col-md-4'>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='PasswordConfirm'
                      placeholder={t('pages.admin.userOperation.userCrud.addUser.password')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={passwordBool}
                      value={values.PasswordConfirm}
                    />
                  </div>
                  <div className='form-group col-md-4'>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='Email'
                      placeholder={t('pages.admin.userOperation.userCrud.addUser.email')}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.Email}
                      errors={errors.Email as string}
                      touched={touched.Email as boolean}
                    />
                  </div>
                  <div className='form-group col-md-4'>
                    <InputMask
                      mask='(999)999-9999'
                      name='Gsm'
                      type='text'
                      className='form-control'
                      value={values.Gsm}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={t('pages.admin.userOperation.userCrud.addUser.phone')}
                      errors={errors.Gsm as string}
                      touched={touched.Gsm as boolean}
                    />
                  </div>
                  <div className='form-group col-md-4'>
                    <IPSelectBox
                      isClearable
                      className='Status'
                      name='Status'
                      onChangeSingle={(option: ISelectOption) => {
                        setStatus(option)
                      }}
                      options={statusOptions}
                      value={status}
                      placeholder="Durum Seçiniz..."
                    />
                  </div>
                </div>
                <div className='form-row mt-3'>
                  <div className='form-group col-md-2'>
                    <IPCheckbox
                      label={t('pages.admin.userOperation.userCrud.addUser.emailSend')}
                      name='IsSendMail'
                      onCheckedChange={isChecked => {
                        setFieldValue('IsSendMail', isChecked)
                      }}
                      checked={values.IsSendMail}
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <IPCheckbox
                      name="IsAdmin"
                      onCheckedChange={isChecked => {
                        setFieldValue('IsAdmin', isChecked)
                      }}
                      checked={values.IsAdmin}
                      label='Admin Kullanıcısı'
                    />
                  </div>
                  <div className="form-group col-md-4">
                    <IPCheckbox
                      name="IsSu"
                      onCheckedChange={isChecked => {
                        if (isChecked)
                          setFieldValue('IsAdmin', isChecked)
                        setFieldValue('IsSu', isChecked)
                      }}
                      checked={values.IsSu}
                      label='Süper Admin Kullanıcısı'
                      disabled={true}
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-2'>

                  </div>
                  <div className='form-group col-md-4'>
                    <p style={{ color: "red" }}>Yetki sınırlaması olan admin kullanıcısı</p>
                  </div>
                  <div className='form-group col-md-4'>
                    <p style={{ color: "red" }}>Yetki sınırlaması olmadan işlem yapabilen kullanıcı</p>
                  </div>
                </div>
                <div className='form-row'>
                  <div className='form-group col-md-12'>
                    <IPTextarea
                      className='form-control'
                      name='Notes'
                      placeholder={t('pages.admin.userOperation.userCrud.addUser.note')}
                      onChange={handleChange}
                      value={values.Notes}
                    // errors={errors.Notes}
                    // touched={touched.Notes}
                    />
                  </div>
                </div>
                <div className='form-row'>
                  <div className="form-group col-md-6">
                    <IPSelectBox
                      isMulti
                      isClearable
                      className="Companies"
                      name="Companies"
                      onChangeMulti={(option: ISelectOption[]) => {
                        setState({ companyOption: option })
                      }}
                      options={state.companies.filter((cm) => !state.companyOption.map(a => a.value).includes(cm.Code) && cm.Name != "Index Group").map((com: ICompany) => {
                        return {
                          value: `${com.Code}`,
                          label: com.Name,
                        }
                      })}
                      value={state.companyOption}
                      placeholder="MT/Rapor Kullanıcısı Şirketleri Seçiniz..."
                    />
                  </div>
                  <div className='form-group col-md-3'>
                    <IPCheckbox
                      disabled={true}
                      label='MT Kullanıcısı'
                      name='MtUser'
                      onCheckedChange={isChecked => {
                        setFieldValue('MtUser', isChecked)
                      }}
                      checked={values.MtUser}
                    />
                  </div>
                  <div className='form-group col-md-3'>
                    <IPCheckbox
                      label='Rapor Kullanıcısı'
                      name='ReportUser'
                      onCheckedChange={isChecked => {
                        setFieldValue('ReportUser', isChecked)
                      }}
                      checked={values.ReportUser}
                    />
                  </div>
                </div>
                <IPButton
                  type='submit'
                  className='btn btn-success float-right col-md-2'
                  onClick={() => addUser()}
                  text={buttonName}
                />
              </form>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default componentWithContext(User)