import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { IRepresentatives } from '../models'

export class GetRepresentativesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/representatives',
      requesterInfo,
    })
  }

  public async getRepresentatives(): Promise<IRepresentatives[]> {
    return await this.getAsync<IRepresentatives[]>()
  }
}
