import { css } from '@emotion/css'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { translate } from '../../../../base/localization'
import {
  FormatDateRangeEnum,
  downloadFileFromUrl,
  exportToXLSX,
  formatDate,
  formatDateRange,
  formatPrice,
  getDateByTimezoneOffset,
  printElement,
} from '../../../../base/utils/dataHelper'
import IPButton from '../../../../components/IPButton'
import ExcelButton from '../../../../components/IPExcelButton/IPExcelButton'
import IPImage from '../../../../components/IPImage'
import IPInput from '../../../../components/IPInput'
import IPModal from '../../../../components/IPModal'
import IPModalImage from '../../../../components/IPModal/IPModalImage'
import IPSelectBox from '../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import InputCampaign from '../../../../components/InputCampaign'
import { useAppSelector } from '../../../../redux/app/hooks'
import { currentUser } from '../../../../redux/slices/user/userSlice'
import Translations from '../../../../translations'
import '../MyOrders.scss'
import { MyOrdersResponse } from '../models/myOrdersExcel'
import { GetSerialNumberCheck, IOrderReturn, IOrderReturnRequest } from '../models/request/getMyOrderRequest'
import { ICargoTracking } from '../models/response/cargoTrackingRes'
import {
  GetSerialNumberCheckResponse,
  ICompany,
  IGetMyOrdersResponse,
  IGetOrderDetailsResponse,
} from '../models/response/getOrderListResponseModel'
import { GetCargoTrackingApi } from '../services/getCargoTracking'
import { GetCompanyApi } from '../services/getCompanyApi'
import { GetDocumentImageApi } from '../services/getDocumentImageApi'
import { GetMyOrdersApi } from '../services/getMyOrdersApi'
import { GetOrderDetailsApi } from '../services/getOrderDetails'
import { PostMyOrderExcel } from '../services/myOrdersExcel'
import { PostOrderReturnApi } from '../services/postOrderReturnApi'
interface OrderListState {
  orderList: IGetMyOrdersResponse[]
  showOrderDetails: boolean
  orderNo: string
  fatNo: string
  // brandList?: IBrand[];
  orderDetails: IGetOrderDetailsResponse[]
  companies?: ISelectOption[]
  company: ISelectOption[]
  companyData: ICompany[]
  period: ISelectOption | null
  showCargoTracking: boolean
  deliveryNo: string
  cargoTracking?: ICargoTracking
  isExpanded: string
  kdvTotal: number
  vadefarki: number
  kargo: number
  total: number
  showInvoiceDetails: boolean
  invoiceNo: string
  showSerialNumbers: boolean
  serialNumbers: GetSerialNumberCheckResponse[]
  detailCompany: string
  showDecline?: boolean
  returnBelgeNo?: string
  returnCompany?: string
  returnIslemNo?: string
  reason?: string
  selectedReason?: ISelectOption
}

const PeriodOptions = [
  {
    value: FormatDateRangeEnum.Today,
    label: 'Bugün',
  },
  {
    value: FormatDateRangeEnum.Yesterday,
    label: 'Dün',
  },
  {
    value: FormatDateRangeEnum.ThisWeek,
    label: 'Bu Hafta',
  },
  {
    value: FormatDateRangeEnum.LastWeek,
    label: 'Geçen Hafta',
  },
  {
    value: FormatDateRangeEnum.ThisMonth,
    label: 'Bu Ay',
  },
  {
    value: FormatDateRangeEnum.LastMonth,
    label: 'Geçen Ay',
  },
  {
    value: FormatDateRangeEnum.Last10Days,
    label: 'Son 10 Gün',
  },
  {
    value: FormatDateRangeEnum.Last15Days,
    label: 'Son 15 Gün',
  },
  {
    value: FormatDateRangeEnum.Last30Days,
    label: 'Son 1 Ay',
  },
]

const orderReturnReasonOptions = [
  {
    value: 0,
    label: 'Depoda Bekleyen Ürün',
  },
  {
    value: 1,
    label: 'Ayıplı Ürün',
  },
  {
    value: 2,
    label: 'Yanlış Ürün',
  },
  {
    value: 3,
    label: 'Yanlış Sipariş Verilmesi',
  },
  {
    value: 4,
    label: 'Siparişin Yanlış Çıkarılması',
  },
  {
    value: 5,
    label: 'Siparişten Vazgeçilmesi',
  },
  {
    value: 6,
    label: 'Miktar Hatası',
  },
  {
    value: 7,
    label: 'Uyumsuz Ürün',
  },
  {
    value: 8,
    label: 'Yanlış Katalog Bilgisi',
  },
  {
    value: 9,
    label: 'Yanlış Teslimat Seçimi',
  },
  {
    value: 10,
    label: 'Diğer',
  },
]

function MyOrders(props: BaseComponentWithContextProps) {
  const navigate: any = useNavigate()
  const t = translate(Translations)
  const user = useAppSelector(currentUser)
  const todayDate = getDateByTimezoneOffset(new Date())
  const lastDate = new Date()
  lastDate.setDate(lastDate.getDate() - 15)
  const orderDate = new Date()
  orderDate.setDate(orderDate.getDate() - 30)
  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(lastDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }
  // const [dateRange, setDateRange] = useState<any>([lastDate, todayDate])
  // const [startDate, endDate] = dateRange
  const [period, setPeriod] = useState<any>(PeriodOptions[PeriodOptions.length - 2])
  const getInitialState = (): OrderListState => {
    return {
      orderList: [],
      showOrderDetails: false,
      orderNo: '',
      fatNo: '',
      orderDetails: [],
      company: [],
      companyData: [],
      period: {
        value: period,
        label: t('components.selectBox.pleaseSelect'),
      },
      showCargoTracking: false,
      deliveryNo: '',
      isExpanded: '',
      kdvTotal: 0,
      vadefarki: 0,
      kargo: 0,
      total: 0,
      showInvoiceDetails: false,
      invoiceNo: '',
      showSerialNumbers: false,
      serialNumbers: [],
      detailCompany: '',
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<OrderListState>(intialState)
  const routeLocation = useLocation()

  useEffect(() => {
    getMyOrders()
    // getData();
    getCompanies()
  }, [])

  let sdate = ''
  let edate = ''

  const tarih = new Date()
  tarih.setDate(tarih.getDate() - 15)
  sdate = formatDate(tarih)
  edate = formatDate(new Date())

  const initialValues = Object.assign({
    CompanyCode: [],
    OrderDocNO: '',
    DealerCode: '',
    StartDate: sdate,
    EndDate: edate,
  })

  const getMyOrders = async () => {
    try {
      props.showLoading()

      const getOrderListApi: GetMyOrdersApi = new GetMyOrdersApi({})
      const getOrdersData: any = await getOrderListApi.getMyOrders(initialValues)

      // Parallelize sorting and reversing operations
      const sortedOrders = getOrdersData.sort((a, b) => Number(a.SIPARIS_BELGENO) - Number(b.SIPARIS_BELGENO))
      const reversedOrders = sortedOrders.reverse()

      setState({ orderList: reversedOrders })

      props.hideLoading()
    } catch (error: any) {
      console.error('Error fetching orders:', error)

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while fetching orders.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      props.hideLoading()
    }
  }

  const getOrderDetails = async (order: string, type: string) => {
    props.showLoading()
    const orderDetails = Object.assign({
      OrderDocNO: order,
      DealerCode: '',
    })
    const getOrderDetailApi: GetOrderDetailsApi = new GetOrderDetailsApi({})
    getOrderDetailApi
      .getOrderDetail(orderDetails)
      .then((result: IGetOrderDetailsResponse[]) => {
        let kdv = 0
        result.map(res => (kdv = kdv + res.SPB_TOPLAM_KDV))
        const vade = Number(Number(result[0].VADE_FARKI_TL) / Number(result[0].KUR_DEGERI))
        const kargo = Number(Number(result[0].KARGO_TL) / Number(result[0].KUR_DEGERI))
        let total = result[0].SPB_SIPARIS_TOPLAMI + kdv
        if (type === 'orderDetails') {
          setState({
            showOrderDetails: true,
            orderDetails: result,
            orderNo: order,
            kdvTotal: kdv,
            vadefarki: vade,
            kargo: kargo,
            total: total,
          })
        } else {
          setState({
            showInvoiceDetails: true,
            orderDetails: result,
            orderNo: order,
            kdvTotal: kdv,
            vadefarki: vade,
            kargo: kargo,
            total: total,
          })
        }
      })
      .catch(error => console.log(error))
    // const getOrdersData: any = await getOrderDetailApi.getOrderDetail(orderDetailsValues)
    props.hideLoading()
  }
  const getCargoTracking = async (delivery: string) => {
    const getCargoTrackingApi: GetCargoTrackingApi = new GetCargoTrackingApi({})
    getCargoTrackingApi
      .getCargoTracking(delivery)
      .then((result: ICargoTracking) => {
        setState({
          cargoTracking: result,
          showCargoTracking: true,
          deliveryNo: delivery,
        })
      })
      .catch(error => console.log(error))
    // const getOrdersData: any = await getOrderDetailApi.getOrderDetail(orderDetailsValues)
  }
  const getCompanies = () => {
    const api: GetCompanyApi = new GetCompanyApi({})
    api.getCompanies().then((res: ICompany[]) => {
      const data: ISelectOption[] = []
      res.map(r => {
        if (r.Name !== 'Index Group') {
          return data.push({
            value: r.Id,
            label: r.Name,
          })
        }
      })
      setState({ companies: data, companyData: res })
    })
  }

  const filterOrder = async () => {
    if (
      ((state.orderNo.length === 0 || state.fatNo.length === 0) && state.company.length === 0 && !startDate) ||
      !endDate
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen yukarıdaki verilerden en az birini giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    let data = Object.assign({
      CompanyCode: [],
      OrderDocNO: '',
      DealerCode: '',
      StartDate: '',
      EndDate: '',
      IGPBIYNO: '',
      FatNO: '',
    })

    let isIGP = false
    let isBIY = false
    let isTKY = false
    let isFat = false

    if (state.fatNo.length > 0) {
      isFat = true
      data = Object.assign({
        CompanyCode: [],
        OrderDocNO: '',
        DealerCode: '',
        StartDate: '',
        EndDate: '',
        IGPBIYNO: '',
        FatNO: state.fatNo,
      })
    } else if (state.orderNo.length > 0) {
      if (state.orderNo.startsWith('IG')) {
        isIGP = true
        data = Object.assign({
          CompanyCode: [],
          OrderDocNO: '',
          DealerCode: '',
          StartDate: '',
          EndDate: '',
          IGPBIYNO: state.orderNo,
        })
      } else if (state.orderNo.startsWith('BIY')) {
        isBIY = true
        data = Object.assign({
          CompanyCode: [],
          OrderDocNO: '',
          DealerCode: '',
          StartDate: '',
          EndDate: '',
          IGPBIYNO: state.orderNo,
        })
      } else if (state.orderNo.startsWith('TKY')) {
        isTKY = true
        data = Object.assign({
          CompanyCode: [],
          OrderDocNO: '',
          DealerCode: '',
          StartDate: '',
          EndDate: '',
        })
      } else {
        data = Object.assign({
          CompanyCode: [],
          OrderDocNO: state.orderNo,
          DealerCode: '',
          StartDate: '',
          EndDate: '',
        })
      }
    } else {
      let sdate = ''
      let edate = ''
      if (startDate && endDate) {
        sdate = formatDate(startDate)
        edate = formatDate(endDate)
      }

      data = Object.assign({
        CompanyCode: state.company
          ? (state.company.map((item: ISelectOption) =>
              String(
                item.label === 'Index'
                  ? '1000'
                  : item.label === 'Despec'
                  ? '1200'
                  : item.label === 'Datagate'
                  ? '1100'
                  : item.label === 'Netex'
                  ? '2300'
                  : ''
              )
            ) as any)
          : [],
        OrderDocNO: '',
        DealerCode: '',
        StartDate: sdate,
        EndDate: edate,
      })
    }

    const getOrderListApi: GetMyOrdersApi = new GetMyOrdersApi({})
    if (isIGP) {
      const getOrdersData: any = await (
        await getOrderListApi.getMyOrders(data)
      ).filter(
        data =>
          data.ISLEM_NO.startsWith(state.orderNo) ||
          state.orderNo === data.ISLEM_NO ||
          data.ISLEM_NO.split('@')[0] === state.orderNo.split(' / ')[0]
      )
      setState({ orderList: getOrdersData })
      props.hideLoading()
    } else if (isFat) {
      const getOrdersData: any = await await getOrderListApi.getMyOrders(data)
      setState({ orderList: getOrdersData })
      props.hideLoading()
    } else if (isBIY) {
      const getOrdersData: any = await (
        await getOrderListApi.getMyOrders(data)
      ).filter(data => data.ISLEM_NO.split('@')[1] === state.orderNo || data.ISLEM_NO === state.orderNo)
      setState({ orderList: getOrdersData })
      props.hideLoading()
    } else if (isTKY) {
      const getOrdersData: any = await (
        await getOrderListApi.getMyOrders(data)
      ).filter(data => data.ISLEM_NO === state.orderNo)
      setState({ orderList: getOrdersData })
      props.hideLoading()
    } else {
      const getOrdersData: any = await (await getOrderListApi.getMyOrders(data))
        .sort((a, b) => Number(a.SIPARIS_BELGENO) - Number(b.SIPARIS_BELGENO))
        .reverse()
      setState({ orderList: getOrdersData })
      props.hideLoading()
    }
  }

  // const getData = async () => {
  //   const brand: any[] = await getBrandApi();

  //   setState({ brandList: brand });
  // };

  const getPeriod = async (data: any, type: string, title: string) => {
    if (type === 'period') {
      if (data) {
        const a = formatDateRange(Number(data.value))
        setStartDate(new Date(a[0]))
        setEndDate(new Date(a[1]))
      }
      setState({ period: data })
    }
    if (type === 'date') {
      if (title === 'Start') setStartDate(data)
      else setEndDate(data)
      setState({ period: null })
      setPeriod(null)
    }
  }

  // const handleOrderSearch = (e) => {
  //   let val = e.target.value;
  //   if (e.key === "Enter" && val.length !== 0) {
  //     setState({
  //       orderList: [
  //         ...state.orderList.filter(
  //           (num) =>
  //             num.SIPARIS_BELGENO == val ||
  //             num.FATURA_BELGENO == val ||
  //             num.DOVIZ_KODU == val ||
  //             num.EFATNO == val ||
  //             num.KAYDI_GIREN == val ||
  //             num.KAYIT_TARIHI == val ||
  //             num.NET_TUTAR == val ||
  //             num.ODEME_BICIMI == val ||
  //             num.ONAY == val ||
  //             num.RED == val ||
  //             num.RED_ACK == val ||
  //             num.SEVK_ACIKLAMA == val ||
  //             num.SEVK_SEKLI == val ||
  //             num.SIPARIS_TARIHI == val ||
  //             num.STOKDURUMU == val ||
  //             num.TESLIMAT_DURUMU == val ||
  //             num.TES_VBELN == val ||
  //             num.USER_TXT == val
  //         ),
  //       ],
  //     });

  //     state.orderList = [
  //       ...state.orderList.filter(
  //         (num) =>
  //           num.SIPARIS_BELGENO == val ||
  //           num.FATURA_BELGENO == val ||
  //           num.DOVIZ_KODU == val ||
  //           num.EFATNO == val ||
  //           num.KAYDI_GIREN == val ||
  //           num.KAYIT_TARIHI == val ||
  //           num.NET_TUTAR == val ||
  //           num.ODEME_BICIMI == val ||
  //           num.ONAY == val ||
  //           num.RED == val ||
  //           num.RED_ACK == val ||
  //           num.SEVK_ACIKLAMA == val ||
  //           num.SEVK_SEKLI == val ||
  //           num.SIPARIS_TARIHI == val ||
  //           num.STOKDURUMU == val ||
  //           num.TESLIMAT_DURUMU == val ||
  //           num.TES_VBELN == val ||
  //           num.USER_TXT == val
  //       ),
  //     ];
  //     return;
  //   }
  //   if (e.keyCode === 8 && val.length == 1) {
  //     getMyOrders();
  //   }
  // };

  // const handleOrderNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let val = e.target.value;

  //   if (val.length !== 0) {
  //     setState({
  //       orderList: [
  //         ...state.orderList.filter((num) => num.SIPARIS_BELGENO.includes(val)),
  //       ],
  //     });
  //     state.orderList = [
  //       ...state.orderList.filter((num) => num.SIPARIS_BELGENO == val),
  //     ];
  //     return;
  //   }
  //   getMyOrders();
  // };
  // const handleStartAndEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let val = e.target.value;

  //   if (val.length !== 0) {
  //     setState({
  //       orderList: [
  //         ...state.orderList.filter((num) => num.SIPARIS_TARIHI.includes(val)),
  //       ],
  //     });
  //     state.orderList = [
  //       ...state.orderList.filter((num) => num.SIPARIS_TARIHI == val),
  //     ];
  //     return;
  //   }
  //   getMyOrders();
  // };
  // const handleSelectedBox = (selectedOption, type: string) => {
  //   switch (type) {
  //     case "MarketBox":
  //       break;
  //     case "DateBox":
  //       if (selectedOption.value == 1) {
  //         state.orderList.sort((a, b) => {
  //           return new Date(b.KAYIT_TARIHI).valueOf() - new Date(a.KAYIT_TARIHI).valueOf();
  //         });
  //         setState({ orderList: [...state.orderList] });
  //       }
  //       if (selectedOption.value == 2) {
  //         state.orderList.sort((a, b) => {
  //           return new Date(a.KAYIT_TARIHI).valueOf() - new Date(b.KAYIT_TARIHI).valueOf();
  //         });
  //         setState({ orderList: [...state.orderList] });
  //       }
  //       break;
  //     case "LastDayBox":
  //       break;
  //     case "StarterDateBox":
  //       break;
  //     case "FinishDateBox":
  //       setState({
  //         orderList: [
  //           ...state.orderList.filter(
  //             (num) =>
  //               new Date(startDate).getFullYear() >
  //               new Date(num.KAYIT_TARIHI).getFullYear()
  //           ),
  //         ],
  //       });
  //       state.orderList = [
  //         ...state.orderList.filter(
  //           (num) =>
  //             new Date(startDate).getFullYear() >
  //             new Date(num.KAYIT_TARIHI).getFullYear()
  //         ),
  //       ];
  //       break;
  //     case "CompanyNameBox":
  //       break;
  //     case "ProductBrandBox":
  //       break;
  //     case "OrderStatusBox":
  //       break;
  //   }
  // };

  // const handleOrderCancel = (orderId) => {
  //   Swal.fire({
  //     html: t(
  //       "pages.mySpecial.myOrders.components.myOrders.orderCancelConfirmMessage"
  //     ), //Siparişi iptal etmek istediğinize emin misiniz?
  //     confirmButtonText: t("pages.mySpecial.myOrders.components.myOrders.yes"), //TODO
  //     confirmButtonColor: "#8CD4F5",
  //     cancelButtonText: t("pages.mySpecial.myOrders.components.myOrders.no"), //TODO
  //     showCancelButton: true,
  //     cancelButtonColor: "#ec4c4c",
  //   }).then(async (result) => {
  //     /* Read more about isConfirmed, isDenied below */
  //     if (result.isConfirmed) {
  //       setState({
  //         orderList: [
  //           ...state.orderList.filter((order) => order.SIPARIS_BELGENO !== orderId),
  //         ],
  //       });
  //       state.orderList = [
  //         ...state.orderList.filter((order) => order.SIPARIS_BELGENO !== orderId),
  //       ];
  //       Swal.fire(
  //         t(
  //           "pages.mySpecial.myOrders.components.myOrders.orderCancelResultMessage"
  //         ) //'Siparişiniz İptal Edilmiştir.',
  //       );
  //     } else if (result.isDenied) {
  //     }
  //   });
  // };

  // const exportToExcel = () => {
  //   const getReportDataApi: GetMyOrdersApi = new GetMyOrdersApi({});
  //   let sdate = "";
  //   let edate = "";
  //   if (startDate != "" && endDate != "") {
  //     sdate = formatDate(startDate);
  //     edate = formatDate(endDate);
  //   }
  //   const req = Object.assign({
  //     CompanyCode: state.company
  //       ? (state.company.map((item: ISelectOption) =>
  //         String(item.label === "Index" ? "1000" : (item.label === "Despec" ? "1200" : (item.label === "Datagate" ? "1100" : (item.label === "Netex" ? "2300" : ""))))
  //       ) as any)
  //       : [],
  //     OrderDocNO: state.orderNo,
  //     DealerCode: "",
  //     StartDate: sdate,
  //     EndDate: edate
  //   });
  //   getReportDataApi.getMyOrders(req).then((res: any) => {
  //     exportToXLSX(res, 'Siparişlerim');
  //   }).catch((err: IException) => {
  //     Swal.fire({
  //       // position: 'top-end',
  //       icon: 'error',
  //       title: err.description,
  //       showConfirmButton: true,
  //       allowOutsideClick: false,
  //       // timer: 1500
  //     });
  //   });
  // }
  const postMyOrderExcel = () => {
    if (state.orderNo.length === 0 && state.company.length === 0 && (!startDate || !endDate)) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen yukarıdaki verilerden en az birini giriniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    let data = Object.assign({
      CompanyCode: [],
      OrderDocNO: '',
      DealerCode: '',
      StartDate: '',
      EndDate: '',
    })
    if (state.orderNo.length > 0) {
      data = Object.assign({
        CompanyCode: [],
        OrderDocNO: state.orderNo,
        DealerCode: '',
        StartDate: '',
        EndDate: '',
      })
    } else {
      let sdate = ''
      let edate = ''
      if (startDate && endDate) {
        sdate = formatDate(startDate)
        edate = formatDate(endDate)
      }

      data = Object.assign({
        CompanyCode: state.company
          ? (state.company.map((item: ISelectOption) =>
              String(
                item.label === 'Index'
                  ? '1000'
                  : item.label === 'Despec'
                  ? '1200'
                  : item.label === 'Datagate'
                  ? '1100'
                  : item.label === 'Netex'
                  ? '2300'
                  : ''
              )
            ) as any)
          : [],
        OrderDocNO: '',
        DealerCode: '',
        StartDate: sdate,
        EndDate: edate,
      })
    }

    const postMyOrderExcelApi: PostMyOrderExcel = new PostMyOrderExcel({})
    postMyOrderExcelApi.postMyOrderExcel(data).then((res: MyOrdersResponse) => {
      if (res) {
        downloadFileFromUrl('Siparişlerim', res.FileUrl)
        props.hideLoading()
      }
    })
  }

  async function getSerialNumberCheck(req: GetSerialNumberCheck) {
    props.showLoading()
    try {
      const res = await new GetCargoTrackingApi({}).GetSerialNumberCheck(req)
      props.hideLoading()
      if (res.length === 0) {
        Swal.fire({
          icon: 'error',
          html: `
      <div>
        <div style="display: flex; justify-content: center; margin-bottom: 1rem; font-size: 16px;">
          <b>Seri No Bilgisi Bulunmamaktadır.</b>
        </div>
      </div>
    `,
          backdrop: true,
          confirmButtonText: 'KAPAT',
          confirmButtonColor: '#b31313',
        })
      } else {
        setState({ showSerialNumbers: true, serialNumbers: res.map(x => ({ ...x, Date: new Date(x.Date) })) })
      }
    } catch (err: any) {
      props.hideLoading()
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const [docImage, setDocImage] = useState<any>()
  const [showDocImage, setShowDocImage] = useState<boolean>(false)

  async function getDocumentImageCheck(req: string) {
    props.showLoading()
    try {
      const res = await new GetDocumentImageApi({}).getImage(JSON.stringify(req))
      props.hideLoading()
      if (res) {
        const imageUrl = `data:image/jpeg;base64,${res.WAOR_PICTURE}`
        setDocImage(imageUrl)
        setShowDocImage(true)
      }
    } catch (err: any) {
      props.hideLoading()
      Swal.fire('Uyarı', err.description, 'warning')
    }
  }
  // Excel'e aktarma
  const excelButton = async () => {
    try {
      if (state.serialNumbers) {
        const data: any[] = []
        if (state.serialNumbers.length > 0) {
          state.serialNumbers.map(item => {
            return data.push({
              'Seri No': item.SeriNo,
              'Ürün Kodu': item.ProductCode,
              //Adet: item.Amount,
              Adet: 1,
              'Şirket Fatura No': item.EInvoiceNumber,
              'Fatura No': item.InvoiceNumber,
              Tarih: item.Date.toLocaleDateString(),
              'Birim Tutar': parseFloat(
                item.NETWRBR.toFixed(2)
                  .replace(/[^\d.,]/g, '')
                  .replace(',', '.')
              ),
              'Döviz Tipi': item.CurrencyType,
              Kur: item.Currency.toFixed(2),
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Seri No Kontrolü')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  async function getShippingInformation(orderNumber: string) {
    props.showLoading()
    try {
      const res = await new GetCargoTrackingApi({}).GetShippingInformation(orderNumber)
      props.hideLoading()

      Swal.fire('Bilgi', res.RDESC, 'info')
    } catch (err: any) {
      props.hideLoading()
      Swal.fire('Hata', err.description, 'error')
    }
  }

  const postOrderReturn = async (order: string, company: string, islemNo: string | undefined) => {
    props.showLoading()
    const orderDetails: IOrderReturn = {
      SiparisNo: order,
      IslemTipi: 0,
      Company: company,
      IslemNo: islemNo || '',
      Reason: state.reason ? state.reason : state.selectedReason?.label,
    }
    const postOrderReturn: PostOrderReturnApi = new PostOrderReturnApi({})
    postOrderReturn
      .postOrderReturn(orderDetails)
      .then((result: IOrderReturnRequest) => {
        Swal.fire({
          icon: 'success',
          title: `Sipariş No: ${result.SiparisNo} Takip No: ${result.TakipNo}`,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        setState({
          showDecline: false,
          selectedReason: undefined,
          reason: undefined,
          returnBelgeNo: undefined,
          returnCompany: undefined,
          returnIslemNo: undefined,
        })
        props.hideLoading()
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        setState({
          showDecline: false,
          selectedReason: undefined,
          reason: undefined,
          returnBelgeNo: undefined,
          returnCompany: undefined,
          returnIslemNo: undefined,
        })
        props.hideLoading()
      })
  }

  return (
    <div className='myOrder py-5' style={{ backgroundColor: 'white' }}>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-12 col-lg-12 pl-md-0'>
            <div className='box'>
              {/* <div className="search">
                <IPInput
                  type="text"
                  placeholder={t(
                    "pages.mySpecial.myOrders.components.myOrders.searchOrder"
                  )}
                  // onKeyDown={handleOrderSearch}

                />
                <img
                  src="/images/my-orders/search.svg"
                  alt=""
                  className="icon"
                  // onClick={handleOrderSearch}
                />
              </div> */}
              <h1 className='orderTitle'>Siparişlerim</h1>
              <form>
                <div className='row'>
                  <div className='col-md-4 mb-lg-0 mb-4 mb-4 mb-lg-0'>
                    <IPInput
                      type='text'
                      value={state.orderNo}
                      placeholder='Sipariş / IGP Numarası'
                      onChange={e => setState({ orderNo: e.target.value })}
                    />
                  </div>
                  {/* <div className="formEl">
                    <label>
                      {t(
                        "pages.mySpecial.myOrders.components.myOrders.selectMarketPlace"
                      )}
                    </label>
                    <IPSelectBox
                      options={MarketOptions}
                      onChangeSingle={(value: ISelectOption) => {
                        handleSelectedBox(
                          { selectedOption: value },
                          "MarketBox"
                        );
                      }}
                    />
                  </div> */}
                  {/* <div className="formEl">
                    <label>
                      {t(
                        "pages.mySpecial.myOrders.components.myOrders.sortByDate"
                      )}
                    </label>
                    <IPSelectBox
                      options={SortByDateOptions}
                      onChangeSingle={(value: ISelectOption) => {
                        handleSelectedBox(value, "DateBox");
                      }}
                    />
                  </div> */}
                  {/* <div className="formEl">
                    <label>
                      {t(
                        "pages.mySpecial.myOrders.components.myOrders.lastByDay"
                      )}
                    </label>
                    <IPInput
                      type="number"
                    />
                  </div> */}
                  <div className='col-md-4 mb-lg-0 mb-4'>
                    <IPSelectBox
                      isMulti
                      isClearable
                      id='company'
                      name='company'
                      placeholder='Şirket'
                      closeMenuOnSelect={false}
                      onChangeMulti={(options: ISelectOption[]) => {
                        setState({ company: options })
                      }}
                      options={state.companies}
                      value={state.company}
                    />
                  </div>
                  <div className='col-md-4 mb-lg-0 mb-4'>
                    <IPSelectBox
                      isClearable
                      name='Period'
                      id='Period'
                      options={PeriodOptions}
                      placeholder='Periyot Aralığı'
                      value={period}
                      onChangeSingle={(option: ISelectOption) => {
                        getPeriod(option, 'period', '')
                        setPeriod(option)
                      }}
                    />
                  </div>

                  {/* <div className="formEl">
                    <label>
                      {t(
                        "pages.mySpecial.myOrders.components.myOrders.productBrand"
                      )}
                    </label>
                    <IPSelectBox
                      options={
                        state.brandList &&
                        state.brandList.map((brand) => {
                          return { label: brand.Name, value: brand.Id };
                        })
                      }
                      onChangeSingle={(value: ISelectOption) => {
                        handleSelectedBox(
                          { selectedOption: value },
                          "ProductBrandBox"
                        );
                      }}
                    />
                  </div> */}
                  {/* <div className="formEl">
                    <label>
                      {t(
                        "pages.mySpecial.myOrders.components.myOrders.orderStatus"
                      )}
                    </label>
                    <IPSelectBox
                      options={OrderStatusOptions}
                      onChangeSingle={(value: ISelectOption) => {
                        handleSelectedBox(
                          { selectedOption: value },
                          "OrderStatusBox"
                        );
                      }}
                    />
                  </div> */}
                </div>
                <br></br>
                <div className='row justify-content-end'>
                  <div className='col-md-4'>
                    <IPInput
                      type='text'
                      value={state.fatNo}
                      placeholder='Fatura / E-Fatura / İrsaliye No'
                      onChange={e => setState({ fatNo: e.target.value })}
                    />
                  </div>
                  <div className='col-md-4'>
                    <InputCampaign
                      type='date'
                      id='StartDate'
                      name='StartDate'
                      label={'Başlangıç Tarihi'}
                      value={toDateTimeLocal(startDate)}
                      onChange={e => getPeriod(e.target.value, 'date', 'Start')}
                      isActive
                    />
                  </div>
                  <div className='col-md-4'>
                    <InputCampaign
                      type='date'
                      id='EndDate'
                      name='EndDate'
                      label={'Bitiş Tarihi'}
                      value={toDateTimeLocal(endDate)}
                      onChange={e => getPeriod(e.target.value, 'date', 'End')}
                      isActive
                    />
                  </div>
                </div>

                <div className='myOrderActions m-t-25 m-b-15 justify-content-center'>
                  <IPButton
                    text={t('pages.mySpecial.myOrders.components.myOrders.list')}
                    className='button'
                    onClick={() => filterOrder()}
                    style={{
                      backgroundColor: 'red',
                      color: 'white',
                      borderRadius: '10px',
                      width: '140px',
                      height: '45px',
                    }}
                  />
                  <ExcelButton onClick={postMyOrderExcel} />
                </div>
              </form>
              {state.orderList.length === 0 ? (
                <div className='center'>
                  <h5>Siparişiniz Bulunmamaktadır!</h5>
                </div>
              ) : (
                state.orderList.map(order => (
                  <div className='marketPlace'>
                    <div>
                      <div
                        className='row item'
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          state.isExpanded !== order.SIPARIS_BELGENO
                            ? setState({ isExpanded: order.SIPARIS_BELGENO })
                            : setState({ isExpanded: '' })
                        }
                      >
                        <div className='col-lg-9'>
                          <div className='row'>
                            <div className='col-lg-4 col-sm-12 d-flex align-items-center'>
                              <div className='itemName'>Şirket:</div>
                              {order.COMPANY_CODE &&
                                state.companyData.map(com => {
                                  if (order.COMPANY_CODE === '1000' && com.Id === 1)
                                    return <img className='sellerImage' src={com.Image} alt='' />
                                  if (order.COMPANY_CODE === '1100' && com.Id === 4)
                                    return <img className='sellerImage' src={com.Image} alt='' />
                                  if (order.COMPANY_CODE === '1200' && com.Id === 3)
                                    return <img className='sellerImage' src={com.Image} alt='' />
                                  if (order.COMPANY_CODE === '2300' && com.Id === 2)
                                    return <img className='sellerImage' src={com.Image} alt='' />
                                })}
                            </div>
                            <div className='col-lg-4 col-sm-12 d-flex align-items-center'>
                              <div className='itemName'>Sipariş Tarihi:</div>
                              <div className='itemValue'>{order.SIPARIS_TARIHI.split('', 10)}</div>
                            </div>
                            <div className='col-lg-4 col-sm-12 d-flex align-items-center'>
                              <div className='itemName'>Sipariş No:</div>
                              <div
                                className='itemValue clickable'
                                onClick={e => {
                                  e.stopPropagation()
                                  setState({ detailCompany: order.COMPANY_CODE })
                                  getOrderDetails(order.SIPARIS_BELGENO, 'orderDetails')
                                }}
                                title='Sipariş Detayı için tıklayın'
                              >
                                {order.SIPARIS_BELGENO}
                              </div>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-lg-4 col-sm-12 d-flex align-items-center'>
                              <div className='itemName'>Tutar:</div>
                              <div className='itemValue' style={{ color: '#EE333E' }}>
                                {order.DOVIZ_KODU === 'TRY'
                                  ? formatPrice(order.NET_TUTAR, 'TRY', 'symbol')
                                  : order.DOVIZ_KODU === 'USD'
                                  ? formatPrice(order.NET_TUTAR, 'USD', 'symbol')
                                  : order.DOVIZ_KODU === 'EUR'
                                  ? formatPrice(order.NET_TUTAR, 'EUR', 'symbol')
                                  : formatPrice(order.NET_TUTAR)}
                              </div>
                            </div>
                            <div className='col-lg-4 col-sm-12 d-flex align-items-center'>
                              <div className='itemName'>Sevk Türü:</div>
                              <div className='itemValue' style={{ color: '#616161' }}>
                                {order.SEVK_ACIKLAMA}
                              </div>
                            </div>
                            <div className='col-lg-4 col-sm-12 d-flex align-items-center'>
                              <div className='itemName'>Durum:</div>
                              <div className='itemValue' style={{ color: '#1acf1a' }}>
                                {order.RED !== ''
                                  ? 'Sipariş Red '
                                  : order.STOKDURUMU === 0
                                  ? 'Stoksuz Sipariş'
                                  : order.ONAY === ''
                                  ? 'Onay Bekliyor '
                                  : order.TESLIMAT_DURUMU === 'A' && order.TES_VBELN === ''
                                  ? 'Revize Edildi'
                                  : order.TESLIMAT_DURUMU === 'A'
                                  ? 'Sevk Ediliyor'
                                  : order.TESLIMAT_DURUMU === 'B'
                                  ? 'Kısmen Sevk Edildi'
                                  : order.TESLIMAT_DURUMU === 'C'
                                  ? 'Sevk Edildi'
                                  : ''}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-lg-2'>
                          <div className='col-lg col-sm-12 d-flex align-items-center'>
                            {order.OrderReturnRecord &&
                              order.OrderReturnStatus != null &&
                              order.OrderReturnType != null && (
                                <div className=''>
                                  <span
                                    style={{
                                      color:
                                        order.OrderReturnStatus === 0
                                          ? 'orange'
                                          : order.OrderReturnStatus === 1
                                          ? 'red'
                                          : order.OrderReturnStatus === 2
                                          ? 'green'
                                          : '',
                                    }}
                                  >
                                    {order.OrderReturnType === 1
                                      ? 'İade '
                                      : order.OrderReturnType === 0
                                      ? 'İptal '
                                      : ''}
                                    {order.OrderReturnStatus === 0
                                      ? 'Onayı Bekleniyor'
                                      : order.OrderReturnStatus === 1
                                      ? 'Talebi Reddedildi'
                                      : order.OrderReturnStatus === 2
                                      ? ' Talebi Onaylandı'
                                      : ''}
                                  </span>
                                </div>
                              )}
                            {order.IsOrderReturn && !order.OrderReturnRecord && (
                              <div className='col-lg col-sm-12 d-flex d-lg-block justify-content-between'>
                                <div
                                  className='d-flex align-items-center gap-3 h-100'
                                  style={{ lineHeight: '18px', cursor: 'pointer' }}
                                  onClick={e => {
                                    e.stopPropagation()
                                    if (order.FATURA_BELGENO !== ' - ') {
                                      Swal.fire({
                                        icon: 'info',
                                        title: 'İade işlemi yapmak istediğinize emin misiniz?',
                                        showDenyButton: true,
                                        confirmButtonColor: 'green',
                                        confirmButtonText: 'Evet',
                                        denyButtonColor: 'red',
                                        denyButtonText: `Hayır`,
                                        width: '600',
                                      }).then(result => {
                                        if (result.isConfirmed) {
                                          navigate('/myspecial/returnForm', {
                                            state: {
                                              order: order.SIPARIS_BELGENO,
                                              efatno: order.EFATNO,
                                            },
                                          })
                                        }
                                      })
                                      return
                                    }
                                    Swal.fire({
                                      icon: 'info',
                                      title: 'İptal işlemi yapmak istediğinize emin misiniz?',
                                      showDenyButton: true,
                                      confirmButtonColor: 'green',
                                      confirmButtonText: 'Evet',
                                      denyButtonColor: 'red',
                                      denyButtonText: `Hayır`,
                                      width: '600',
                                    }).then(result => {
                                      if (result.isConfirmed) {
                                        setState({
                                          showDecline: true,
                                          returnBelgeNo: order.SIPARIS_BELGENO,
                                          returnCompany: order.COMPANY_CODE,
                                          returnIslemNo: order.ISLEM_NO,
                                        })
                                      }
                                    })
                                  }}
                                >
                                  <div className='icon-container'>
                                    {order.FATURA_BELGENO !== ' - ' ? (
                                      <>
                                        <IPImage src='/images/iade.png' alt='' style={{ maxWidth: '40px' }} />
                                        <button className='btn btn-danger'>İade</button>
                                      </>
                                    ) : (
                                      <>
                                        <IPImage src='/images/iptal.png' alt='' style={{ maxWidth: '40px' }} />
                                        <button className='btn btn-danger'>İptal</button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className='col-lg-1 col-sm-12 d-flex d-lg-block'
                          style={{ alignSelf: 'center', textAlign: 'center' }}
                        >
                          {state.isExpanded !== order.SIPARIS_BELGENO && (
                            <img
                              src='/images/icons/down-arrow.png'
                              alt=''
                              width={27}
                              style={{ filter: 'opacity(0.2)' }}
                            />
                          )}
                          {state.isExpanded === order.SIPARIS_BELGENO && (
                            <img
                              src='/images/icons/up-arrow.png'
                              alt=''
                              width={35}
                              style={{ filter: 'opacity(0.2)' }}
                            />
                          )}
                        </div>
                      </div>
                      {state.isExpanded === order.SIPARIS_BELGENO && (
                        <>
                          <div className='row item border-bottom border-top'>
                            <div className='row'>
                              <div className='col-lg-5 col-sm-12 d-flex align-items-center'>
                                <div className='itemName'>Fatura Numarası:</div>
                                {order.FATURA_BELGENO &&
                                order.FATURA_BELGENO.length > 0 &&
                                order.FATURA_BELGENO !== ' - ' ? (
                                  <div
                                    className='itemValue clickable'
                                    onClick={() => {
                                      setState({ invoiceNo: order.FATURA_BELGENO })
                                      getOrderDetails(order.SIPARIS_BELGENO, 'invoice')
                                    }}
                                    title='Fatura detayı için tıklayınız!'
                                  >
                                    {order.FATURA_BELGENO}
                                  </div>
                                ) : (
                                  <div className='itemValue'>{order.FATURA_BELGENO}</div>
                                )}
                              </div>
                              <div className='col-lg-4 col-sm-12 d-flex align-items-center'>
                                <div className='itemName'>Teslimat No:</div>
                                <div
                                  className='itemValue clickable'
                                  onClick={() => {
                                    if (order.SEVK_SEKLI === '01' || order.SEVK_SEKLI === '02') {
                                      getShippingInformation(order.SIPARIS_BELGENO)
                                      return
                                    }
                                    getCargoTracking(order.TES_VBELN)
                                  }}
                                  title='Bilgi için tıklayınız!'
                                >
                                  {order.TES_VBELN}
                                </div>
                              </div>
                              <div className='col-lg-3 col-sm-12 d-flex align-items-center'>
                                <div className='itemName'>İşlem No:</div>
                                <div className='itemValue'>
                                  {/* {order.ISLEM_NO ? order.ISLEM_NO.replaceAll('@', ' / ') : ''} */}
                                  <span className='itemValueText'>
                                    {order.ISLEM_NO ? order.ISLEM_NO.replaceAll('@', ' / ') : ''}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col-lg-5 col-sm-12 d-flex align-items-center'>
                                <div className='itemName'>E-Fatura Numarası:</div>
                                <Link
                                  className='itemValue clickable'
                                  to={`/myspecial/EInvoice?eInvoiceId=${order.EFATNO}`}
                                  target='_blank'
                                  title='Fatura için tıklayınız!'
                                >
                                  {order.EFATNO}
                                </Link>
                              </div>
                              <div className='col-lg-4 col-sm-12 d-flex align-items-center'>
                                <div className='itemName'>
                                  {t('pages.mySpecial.myOrders.components.myOrders.paymentMethod')}:
                                </div>
                                <div className='itemValue'>{order.ODEME_BICIMI}</div>
                              </div>

                              <div className='col-lg-3 col-sm-12 d-flex align-items-center'>
                                <div className='itemName'>
                                  {t('pages.mySpecial.myOrders.components.myOrders.currencyUnit')}:
                                </div>
                                <div className='itemValue'>{order.DOVIZ_KODU}</div>
                              </div>
                            </div>
                          </div>
                          <div className='row item pb-0'>
                            <div className='row'>
                              <div className='col-lg-3 col-sm-12 d-flex align-items-center'>
                                <div
                                  className='d-flex align-items-center gap-3 h-100'
                                  style={{ lineHeight: '18px', cursor: 'pointer' }}
                                  onClick={() => {
                                    if (order.SEVK_SEKLI === '01' || order.SEVK_SEKLI === '02') {
                                      getShippingInformation(order.SIPARIS_BELGENO)
                                      return
                                    }
                                    getCargoTracking(order.TES_VBELN)
                                  }}
                                  title='Kargo bilgisi için tıklayınız!'
                                >
                                  <img src='/images/icons/kargotakip.svg' alt='' style={{ width: '35px' }} />
                                  <div>
                                    <div className='itemName' style={{ color: '#007bff' }}>
                                      Kargo Takip
                                    </div>
                                    <div className='itemValue'></div>
                                  </div>
                                </div>
                              </div>
                              <div className='col-lg-3 col-sm-12 d-flex align-items-center'>
                                <div
                                  className='d-flex align-items-center gap-3 h-100'
                                  style={{ lineHeight: '18px', cursor: 'pointer' }}
                                  onClick={() =>
                                    getSerialNumberCheck({
                                      CompanyCode: order.COMPANY_CODE,
                                      InvoiceNumber: order.FATURA_BELGENO,
                                    })
                                  }
                                >
                                  <img src='/images/my-orders/my-orders.svg' alt='' />
                                  <div>
                                    <div className='itemName' style={{ color: '#007bff' }}>
                                      Seri No Kontrolü
                                    </div>
                                    <div className='itemValue'></div>
                                  </div>
                                </div>
                              </div>
                              {order.TES_VBELN && (
                                <div className='col-lg-3 col-sm-12 d-flex align-items-center'>
                                  <div
                                    className='d-flex align-items-center gap-3 h-100'
                                    style={{ lineHeight: '18px', cursor: 'pointer' }}
                                    onClick={() => getDocumentImageCheck(order.TES_VBELN)}
                                  >
                                    <img src='/images/myspecial-icons/bayikanal.svg' style={{ width: '30px' }} alt='' />
                                    <div>
                                      <div className='itemName' style={{ color: '#007bff' }}>
                                        Teslimat Evrağı
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className='row'>
                              <div className='col-lg-4 col-sm-12 d-flex align-items-center mb-0'>
                                <div className='itemName'>Kullanıcı:</div>
                                <div className='itemValue'>{order.USER_TXT}</div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      <IPModal
        show={state.showSerialNumbers}
        onClose={() => setState({ showSerialNumbers: false })}
        hideConfirm={true}
        maxWidth={1200}
      >
        <>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <span className='h4 mb-0'>Seri No Kontrolü</span>
            <div className='d-flex align-items-center'>
              <a
                onClick={() => {
                  printElement(document.getElementById('seriNo'))
                }}
                className='p-3'
              >
                <img
                  src='/images/icons/yazicidetay.svg'
                  alt=''
                  width={40}
                  height={40}
                  style={{ cursor: 'pointer' }}
                  title='Yazdır'
                />
              </a>
              <ExcelButton onClick={excelButton} width={30} height={30} />
            </div>
          </div>
          <div id='seriNo'>
            <div
              className={`table-responsive ${css`
                tr td,
                tr th {
                  padding: 0.5rem !important;
                }
              `}`}
            >
              <table className='table table-striped' style={{ minWidth: 860 }}>
                <thead>
                  <tr className='thead-dark'>
                    <th scope='col'>Seri No</th>
                    <th scope='col'>Ürün Kodu</th>
                    <th scope='col'>Marka</th>
                    <th scope='col'>Adet</th>
                    <th scope='col'>Şirket Fatura No</th>
                    <th scope='col'>Fatura No</th>
                    <th scope='col'>Tarih</th>
                    <th scope='col'>Birim Tutar</th>
                    <th scope='col'>Kur</th>
                  </tr>
                </thead>
                <tbody>
                  {state.serialNumbers.map(x => (
                    <tr>
                      <th>{x.SeriNo}</th>
                      <td>{x.ProductCode}</td>
                      <td>{x.Marka}</td>
                      <td>1</td>
                      <td>{x.EInvoiceNumber}</td>
                      <td>{x.InvoiceNumber}</td>
                      <td>{x.Date.toLocaleDateString()}</td>
                      <td>
                        {x.NETWRBR.toFixed(2)
                          .replace('.', ',')
                          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}{' '}
                        {x.CurrencyType}
                      </td>
                      <td>{x.Currency.toFixed(2)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      </IPModal>
      <IPModal
        show={state.showOrderDetails}
        onClose={() => setState({ showOrderDetails: false })}
        hideConfirm={true}
        maxWidth={1200}
      >
        <>
          <h4>Sipariş Detayı</h4>

          {state.orderDetails.length === 0 ? (
            'Sipariş detayı bulunmamaktadır.'
          ) : (
            <div id='myOrder' style={{ overflow: 'hidden' }}>
              <div
                style={{
                  border: '1px solid #000000',
                  backgroundColor: 'white',
                  padding: '20px',
                }}
              >
                <div className='row'>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Sipariş No</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_NO}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Fatura No</b>
                      <span className='mr-2'>:</span>
                      {state.invoiceNo}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Ödeme Şekli</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.ODEME_SEKLI}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Kur</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && formatPrice(state.orderDetails[0]?.KUR_DEGERI, 'TRY', 'symbol')}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Tarih</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_TARIHI.split('', 10)}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Sevk Tarihi</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SEVK_TARIHI.split('', 10)}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Vade Tarihi</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.VADE_TARIHI.split('', 10)}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Kur Tipi</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.KUR_TIPI}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>MT</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.MT_ADI}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Sevk Biçimi</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SEVK_ACIKLAMA}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Oluşturan</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.KAYDI_GIREN}
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-start'>
                  {' '}
                  <b style={{ width: '100px' }}>Adres</b>
                  <span className='mr-2'>:</span>
                  {state.orderDetails.length > 0 && state.orderDetails[0]?.ADRES}
                </div>
                <div className='d-flex align-items-center justify-content-start'>
                  {' '}
                  <b style={{ width: '100px' }}>Şirket</b>
                  <span className='mr-2'>:</span>
                  {state.detailCompany === '1000'
                    ? 'Index Bilgisayar Sistemleri Tic. A.Ş.'
                    : state.detailCompany === '1100'
                    ? 'DATAGATE BİLGİSAYAR MALZEMELERİ TİCARET A.Ş'
                    : state.detailCompany === '1200'
                    ? 'Despec Bilgisayar Pazarlama Tic. A.Ş.'
                    : 'NETEKS TEKNOLOJİ ÜRÜNLERİ A.Ş.'}
                </div>
              </div>
              <br></br>

              <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                <thead>
                  <tr className='EInvoicetableDescription'>
                    <td scope='col'>Mal Kodu</td>
                    <td scope='col'>Açıklama</td>
                    <td scope='col'>Mik</td>
                    <td scope='col'>Birim Tutar</td>
                    <td scope='col'>
                      Birim Tutar(
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? '₺'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? '$'
                        : '€'}
                      )
                    </td>
                    <td scope='col'>Toplam Tutar</td>
                    <td scope='col'>
                      Toplam Tutar(
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? '₺'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? '$'
                        : '€'}
                      )
                    </td>
                  </tr>
                </thead>
                {state.orderDetails
                  .filter(list => {
                    return state.orderNo === list.SIPARIS_NO
                  })
                  .map(order => (
                    <tbody key={order.SIPARIS_NO}>
                      <td>{order.URUN_KODU}</td>
                      <td>{order.URUN_ACIKLAMA}</td>
                      <td>{order.TOPLAM_ADET}</td>
                      <td style={{ textAlign: 'right' }}>{formatPrice(order.BIRIM_TUTAR, 'TRY', 'symbol')}</td>
                      <td style={{ textAlign: 'right' }}>
                        {formatPrice(
                          order.SPB_BIRIM_TUTAR,
                          state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                            ? 'TRY'
                            : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                            ? 'USD'
                            : 'EUR',
                          'symbol'
                        )}
                      </td>
                      <td style={{ textAlign: 'right' }}>{formatPrice(order.TOPLAM_TUTAR, 'TRY', 'symbol')}</td>
                      <td style={{ textAlign: 'right' }}>
                        {formatPrice(
                          order.SPB_BIRIM_TUTAR * order.TOPLAM_ADET,
                          state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                            ? 'TRY'
                            : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                            ? 'USD'
                            : 'EUR',
                          'symbol'
                        )}
                      </td>
                    </tbody>
                  ))}
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Vade Farkı</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.vadefarki,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Kargo</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.kargo,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Toplam</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.orderDetails[0]?.SPB_SIPARIS_TOPLAMI,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>KDV</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.kdvTotal,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>G. Toplam</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.total,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
              </table>
            </div>
          )}
          <div className='d-flex col-md-2 m-auto justify-content-center'>
            {/* <IPButton
              style={{
                backgroundColor: '#a6a6a7',
                borderRadius: '10px',
                color: 'white',
              }}
              text='Yazdır'
              className='button'
              //isRounded={false}
              onClick={() => {
                printElement(document.getElementById('myOrder'))
              }}
            /> */}

            <a
              onClick={() => {
                printElement(document.getElementById('myOrder'))
              }}
              className='print-button d-flex justify-content-center align-items-center print-button'
            >
              <img
                src='/images/icons/yazicibeyaz.svg'
                alt=''
                width={40}
                height={40}
                style={{ cursor: 'pointer', marginRight: '5px' }}
                title='Yazdır'
              />
              Yazdır
            </a>
          </div>
        </>
      </IPModal>
      <IPModal
        show={state.showInvoiceDetails}
        onClose={() => setState({ showInvoiceDetails: false })}
        hideConfirm={true}
        width={'auto'}
      >
        <>
          <h4>Fatura Detayı</h4>

          {state.orderDetails.length === 0 ? (
            'Sipariş detayı bulunmamaktadır.'
          ) : (
            <div id='myOrder' style={{ overflow: 'hidden' }}>
              <div
                style={{
                  border: '1px solid #000000',
                  backgroundColor: 'white',
                  padding: '20px',
                }}
              >
                <div className='row'>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>İşlem No</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails[0].ISLEM_NO ? state.orderDetails[0].ISLEM_NO.replaceAll('@', ' / ') : ''}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Fatura No</b>
                      <span className='mr-2'>:</span>
                      {state.invoiceNo}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Ödeme Şekli</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.ODEME_SEKLI}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Kur Tipi</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.KUR_TIPI}
                    </div>
                  </div>

                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Tarih</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_TARIHI.split('', 10)}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Sevk Tarihi</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SEVK_TARIHI.split('', 10)}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Vade Tarihi</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.VADE_TARIHI.split('', 10)}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Kur</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && formatPrice(state.orderDetails[0]?.KUR_DEGERI, 'TRY', 'symbol')}
                    </div>
                  </div>
                  <div className='col'>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>MT</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.MT_ADI}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Sevk Biçimi</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SEVK_ACIKLAMA}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Oluşturan</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.KAYDI_GIREN}
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-start'>
                  {' '}
                  <b style={{ width: '100px' }}>Adres</b>
                  <span className='mr-2'>:</span>
                  {state.orderDetails.length > 0 && state.orderDetails[0]?.ADRES}
                </div>
              </div>
              <br></br>

              <table className='table table-borderless table-responsive d-sm-block d-lg-table table-hover'>
                <thead>
                  <tr className='EInvoicetableDescription'>
                    <td scope='col'>Mal Kodu</td>
                    <td scope='col'>Açıklama</td>
                    <td scope='col'>Mik</td>
                    <td scope='col'>Birim Tutar</td>
                    <td scope='col'>
                      Birim Tutar(
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? '₺'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? '$'
                        : '€'}
                      )
                    </td>
                    <td scope='col'>Toplam Tutar</td>
                    <td scope='col'>
                      Toplam Tutar(
                      {state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? '₺'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? '$'
                        : '€'}
                      )
                    </td>
                  </tr>
                </thead>
                {state.orderDetails
                  .filter(list => {
                    return state.orderNo === list.SIPARIS_NO
                  })
                  .map(order => (
                    <tbody key={order.SIPARIS_NO}>
                      <td>{order.URUN_KODU}</td>
                      <td>{order.URUN_ACIKLAMA}</td>
                      <td>{order.TOPLAM_ADET}</td>
                      <td style={{ textAlign: 'right' }}>{formatPrice(order.BIRIM_TUTAR, 'TRY', 'symbol')}</td>
                      <td style={{ textAlign: 'right' }}>
                        {formatPrice(
                          order.SPB_BIRIM_TUTAR,
                          state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                            ? 'TRY'
                            : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                            ? 'USD'
                            : 'EUR',
                          'symbol'
                        )}
                      </td>
                      <td style={{ textAlign: 'right' }}>{formatPrice(order.TOPLAM_TUTAR, 'TRY', 'symbol')}</td>
                      <td style={{ textAlign: 'right' }}>
                        {formatPrice(
                          order.SPB_BIRIM_TUTAR * order.TOPLAM_ADET,
                          state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                            ? 'TRY'
                            : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                            ? 'USD'
                            : 'EUR',
                          'symbol'
                        )}
                      </td>
                    </tbody>
                  ))}
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Vade Farkı</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.vadefarki,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Kargo</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.kargo,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>Toplam</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.orderDetails[0]?.SPB_SIPARIS_TOPLAMI,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>KDV</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.kdvTotal,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
                <tbody>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>G. Toplam</b>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {formatPrice(
                      state.total,
                      state.orderDetails.length > 0 && state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'TRY'
                        ? 'TRY'
                        : state.orderDetails[0]?.SIPARIS_PARA_BIRIMI === 'USD'
                        ? 'USD'
                        : 'EUR',
                      'symbol'
                    )}
                  </td>
                </tbody>
              </table>
            </div>
          )}
          <div className='d-flex justify-content-center'>
            <IPButton
              text='Yazdır'
              className='button w-25'
              isRounded={false}
              onClick={() => {
                printElement(document.getElementById('myOrder'))
              }}
            />
          </div>
        </>
      </IPModal>
      {/* Kargo Takip Modal */}

      <IPModal
        show={state.showCargoTracking}
        onClose={() => setState({ showCargoTracking: false })}
        hideConfirm={true}
        width={'auto'}
        style={{ maxWidth: '100%' }}
      >
        <>
          <div className='d-flex p-25 justify-content-center'>
            <img src='/images/icons/yurtiçi_logo.svg' alt='Yurtiçi Logo' style={{ width: '180px' }} />
            <div
              className='d-flex align-items-center ms-2 mb-2'
              style={{
                fontWeight: '600',
                fontSize: '21px',
                color: 'black',
              }}
            >
              Sevk Durumu
            </div>
          </div>
          <div className='modal-body-cargo'>
            {[
              { label: 'Alıcı Adres', value: state.cargoTracking?.CargoInfo?.RECEIVERADDRESS },
              { label: 'Çıkış Merkezi', value: state.cargoTracking?.CargoInfo?.CARGOEXPLAIN },
              { label: 'Yurtiçi Kargo Takip No', value: state.cargoTracking?.CargoInfo?.DOCID },
              { label: 'Varış Merkezi', value: state.cargoTracking?.CargoInfo?.ARRIVALCENTER },
              { label: 'Kargo Durumu', value: state.cargoTracking?.CargoInfo?.CARGOEVENTEXP },
              { label: 'Teslim Tarihi', value: state.cargoTracking?.CargoInfo?.DELIVERYWHEN?.split('', 10).join('') },
              { label: 'Sistem No', value: state.cargoTracking?.CargoInfo?.CARGONUM },
            ].map((item, index) => (
              <div className='info-row-cargo' key={index}>
                <span className='label-cargo'>{item.label}</span>
                <span className='colon-cargo'>:</span>
                <span
                  className='value-cargo'
                  style={{
                    color:
                      item.label === 'Kargo Durumu'
                        ? '#1acf1a'
                        : item.label === 'Yurtiçi Kargo Takip No'
                        ? 'blue'
                        : '#666666',
                    textDecoration: item.label === 'Yurtiçi Kargo Takip No' ? 'underline' : 'none',
                    cursor: item.label === 'Yurtiçi Kargo Takip No' ? 'pointer' : 'auto',
                  }}
                  onClick={() => {
                    if (item.label === 'Yurtiçi Kargo Takip No') {
                      window.open(
                        `https://www.yurticikargo.com/tr/online-servisler/gonderi-sorgula?code=${item.value}`,
                        '_blank'
                      )
                    }
                  }}
                >
                  {item.value}
                </span>
              </div>
            ))}
          </div>
        </>
      </IPModal>
      <IPModalImage show={showDocImage} onClose={() => setShowDocImage(false)} hideConfirm={true} maxWidth={1200}>
        <>
          <div className='container'>
            <img src={docImage} style={{ width: '40%' }}></img>
          </div>
        </>
      </IPModalImage>
      <IPModal
        show={state.showDecline === true}
        onClose={() =>
          setState({
            showDecline: false,
            selectedReason: undefined,
            reason: undefined,
            returnBelgeNo: undefined,
            returnCompany: undefined,
            returnIslemNo: undefined,
          })
        }
        hideConfirm={false}
        width={800}
        height={500}
        title='İptal Nedeni Seçiniz'
        onConfirm={() => {
          if (
            state.returnBelgeNo &&
            state.returnCompany
            // && state.returnIslemNo
          )
            postOrderReturn(state.returnBelgeNo, state.returnCompany, state.returnIslemNo)
        }}
      >
        <table className='table'>
          <tbody>
            <tr role='row' className='odd'>
              <td>
                <IPSelectBox
                  isClearable
                  placeholder='Durum'
                  options={orderReturnReasonOptions}
                  value={state.selectedReason}
                  onChangeSingle={option => setState({ selectedReason: option })}
                />
              </td>
            </tr>
            {state.selectedReason?.value === 10 && (
              <>
                <br></br>
                <tr>
                  <td>
                    <IPInput
                      type='text'
                      className='form-control'
                      name='Reason'
                      placeholder='İptal nedeni yazınız.'
                      onChange={e => setState({ reason: e.target.value })}
                      value={state.reason}
                    />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </IPModal>
    </div>
  )
}

export default componentWithContext(MyOrders)
