import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isLoggedIn } from '../base/proxy/authenticate'
import ReactGA from 'react-ga4'
import { useAppSelector } from '../redux/app/hooks'
import { currentUser } from '../redux/slices/user/userSlice'

const TRACKING_ID = 'G-PZ3DNFQ2TB'

const withLogin =
  (WrappedComponent: any) =>
  ({ ...props }) => {
    const navigate = useNavigate()
    const location = useLocation()

    const [hasAuthorized, setAuthorized] = useState(false)
    const user = useAppSelector(currentUser)

    const accountCode = user.AccountCode ? user.AccountCode.slice(4) : ''
    const userId = user.UserId ? user.UserId : ''

    userId &&
      accountCode &&
      ReactGA.initialize(TRACKING_ID, {
        gaOptions: {
          clientId: userId,
          userId: accountCode,
        },
      })

    const page = window.location.pathname + window.location.search

    const ga4Req = {
      hitType: 'pageview',
      page,
      title: page,
      accountCode,
      userId,
      itemId: userId,
      groupId: accountCode,
      date: new Date().toLocaleString('tr-TR'),
    }
    userId && accountCode && ReactGA.send(ga4Req)

    useEffect(() => {
      isLoggedInLocal()
    }, [])

    const isLoggedInLocal = () => {
      if (isLoggedIn()) {
        setAuthorized(true)
      } else {
        window.location.href = '/login'
      }
    }

    return <>{hasAuthorized && <WrappedComponent {...props} />}</>
  }

export { withLogin }
