import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { ISurveyReport } from '../models'

export class GetSurveyById extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/survey/SurveyReportResult/',
      requesterInfo,
    })
  }

  public async getSurveyResultById(Id: number): Promise<any[]> {
    this.props.url = this.props.url + Id
    return await this.getAsync<any[]>()
  }
}
