import React, { useEffect, useState, useMemo } from 'react'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { GetXmlInfoDetailsApi } from '../services/getXmlInfoDetailsApi'
import Swal from 'sweetalert2'
import {
  ICancelXmlDemandRequest,
  ICompany,
  ICompanySelect,
  IHistory,
  ILinks,
  IXmlDemandRequest,
  IXmlList,
} from '../models/models'
import { useCustomReducer } from '../../../../base/customHooks'
import { Link } from 'react-router-dom'
import IPInput from '../../../../components/IPInput'
import IPCheckbox from '../../../../components/IPCheckBox'
import IPButton from '../../../../components/IPButton'
import { GetXmlDemandHistoryApi } from '../services/getXmlDemandHistoryApi'
import IPModal from '../../../../components/IPModal'
import Table from '../../../../components/Table'
import { InsertXmlDemandApi } from '../services/insertXmlDemandApi'
import { CancelXmlDemandApi } from '../services/cancelXmlDemandApi'

interface IXmlState {
  xmlList?: IXmlList
  company: ICompanySelect[]
  companySelect: number
  linkData: ILinks[]
  companyDatas: ICompany[]
  email: string
  ipAddresses: string
  idx: boolean
  ntx: boolean
  dgx: boolean
}
function Xml(props: BaseComponentWithContextProps) {
  const [showHistory, setShowHistory] = useState<boolean>(false)
  const getInitialState = (): IXmlState => {
    return {
      company: [],
      linkData: [],
      companySelect: 1,
      companyDatas: [],
      email: '',
      ipAddresses: '',
      idx: false,
      ntx: false,
      dgx: false,
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IXmlState>(intialState)
  const [historyData, setHistoryData] = useState<IHistory[]>([])
  const getXmlInfoDetails = async () => {
    props.showLoading()
    const getXmlInfoDetailsApi: GetXmlInfoDetailsApi = new GetXmlInfoDetailsApi({})

    await getXmlInfoDetailsApi
      .getXmlInfoDetails()
      .then(async (result: IXmlList) => {
        if (result) {
          const company: ICompanySelect[] = []
          result.Companies.map(com => {
            if (com.CmpCode === 'IDX') {
              company.push({ Id: com.CompanyId, Name: 'INDEX' })
              return setState({ linkData: com.Links, idx: true })
            }
          })
          result.Companies.map(com => {
            if (com.CmpCode === 'DGX') {
              if (company.length === 0) setState({ companySelect: com.CompanyId, linkData: com.Links })
              setState({ dgx: true })
              return company.push({ Id: com.CompanyId, Name: 'DATAGATE' })
            }
          })
          result.Companies.map(com => {
            if (com.CmpCode === 'DPC') {
              if (company.length === 0) setState({ companySelect: com.CompanyId, linkData: com.Links })
              return company.push({ Id: com.CompanyId, Name: 'DESPEC' })
            }
          })
          result.Companies.map(com => {
            if (com.CmpCode === 'NTX') {
              if (company.length === 0) setState({ companySelect: com.CompanyId, linkData: com.Links })
              setState({ ntx: true })
              return company.push({ Id: com.CompanyId, Name: 'NETEX' })
            }
          })
          setState({
            xmlList: result,
            company,
            companyDatas: result.Companies,
            email: result.Email,
            ipAddresses: result.IpAddresses,
          })
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const getXmlDemandHistory = async () => {
    props.showLoading()
    const getXmlDemandHistoryApi: GetXmlDemandHistoryApi = new GetXmlDemandHistoryApi({})
    await getXmlDemandHistoryApi
      .getXmlDemandHistory()
      .then(async (result: any) => {
        if (result) {
          setHistoryData(result)
          setShowHistory(true)
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description !== 'OK' ? err.description : 'Geçmiş başvurunuz yoktur.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const insertXmlDemand = async () => {
    if (state.xmlList && state.xmlList.IsHasWaitingDemand) {
      Swal.fire({
        icon: 'error',
        title: 'Daha önce onay başvurunuz bulunmaktadır. Başvuru geçmişinde başvurularınıza bakabilirsiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!state.idx && !state.ntx && !state.dgx) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen şirket seçiniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    let company = ''
    if (state.idx)
      company += '1'
    if (state.ntx) {
      if (company.length > 0) company += ','
      company += '2'
    }
    if (state.dgx) {
      if (company.length > 0) company += ','
      company += '4'
    }
    const req: IXmlDemandRequest = {
      Id: 0,
      IpAddresses: state.ipAddresses.replaceAll(',', '/').replaceAll(' ', ''),
      Companies: company,
      Email: state.email,
    }
    const insertXmlDemandApi: InsertXmlDemandApi = new InsertXmlDemandApi({})
    await insertXmlDemandApi
      .insertXmlDemand(req)
      .then(async (result: any) => {
        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Başvurunuz gönderilmiştir.',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        }
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  const selectCompany = async (id: number) => {
    setState({
      companySelect: id,
      linkData: state.companyDatas.find(com => {
        return com.CompanyId === id
      })?.Links,
    })
  }
  useEffect(() => {
    getXmlInfoDetails()
  }, [])

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Email',
        accessor: 'col2',
      },
      {
        Header: 'Ip Adresleri',
        accessor: 'col3',
      },
      {
        Header: 'Şirket',
        accessor: 'col4',
      },
      {
        Header: 'Başvuru Tarihi',
        accessor: 'col5',
      },
      {
        Header: 'Başvuran',
        accessor: 'col6',
      },
      {
        Header: 'Onay Tarihi',
        accessor: 'col7',
      },
      {
        Header: 'Onaylayan',
        accessor: 'col8',
      },
      {
        Header: 'Durum',
        accessor: 'col9',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      historyData.map((item, index) => {
        const data = {
          col1: index + 1,
          col2: item.Email,
          col3: item.IpAddresses && item.IpAddresses.length > 0 && item.IpAddresses.replace(',', ', '),
          col4: item.Companies && item.Companies.length > 0 && item.Companies.replace(' ', ', '),
          col5: item.CreateDate.toLocaleString(),
          col6: item.CreateUser,
          col7: item.ApproveDate && item.ApproveDate.toLocaleString(),
          col8: item.ApproveUser,
          col9:
            item.ApproveStatus === 'Onay Bekliyor' ? (
              <button
                type='button'
                className={'btn btn-sm btn-secondary'}
                onClick={() => {
                  Swal.fire({
                    icon: 'question',
                    title: 'Xml başvurusunu iptal etmek istediğinize emin misiniz?',
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    showDenyButton: true,
                    confirmButtonText: 'Evet',
                    denyButtonText: 'Hayır',
                  }).then(async result => {
                    if (result.isConfirmed) {
                      setHistoryData(prevState => {
                        return prevState.map((prev: any) => {
                          if (prev.Id === item.Id) {
                            prev = {
                              ApproveDate: prev.ApproveDate,
                              ApproveStatus: 'İptal Edildi',
                              ApproveUser: prev.ApproveUser,
                              Companies: prev.Companies,
                              CreateDate: prev.CreateDate,
                              CreateUser: prev.CreateUser,
                              Email: prev.Email,
                              Id: prev.Id,
                              IpAddresses: prev.IpAddresses,
                            }
                          }
                          return prev
                        })
                      })
                      approveRejectXmlDemand(item.Id, 3)
                    }
                  })
                }}
              >
                İptal
              </button>
            ) : (
              item.ApproveStatus
            ),
        }

        return data
      }),
    [historyData]
  )

  const approveRejectXmlDemand = async (id: number, status: number) => {
    const req: ICancelXmlDemandRequest = {
      Id: id,
      status: status,
    }
    props.showLoading()
    const cancelXmlDemandApi: CancelXmlDemandApi = new CancelXmlDemandApi({})
    await cancelXmlDemandApi
      .cancelXmlDemand(req)
      .then((result: any) => {
        Swal.fire({
          icon: 'error',
          title: 'Xml başvuru iptal edildi.',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        getXmlInfoDetails()
        props.hideLoading()
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  return (
    <div className='container-fluid'>
      <hr />
      <h4>
        <b>XML BİLGİLERİNİZ</b>
      </h4>
      <hr />
      <div className='d-flex gap-4'>
        <div style={{ width: '400px' }}>
          <b>SİTE BİLGİLERİNİZ</b>
        </div>
        <div style={{ width: '900px' }}>
          <b>ADRES</b>
        </div>
      </div>
      <hr />
      <div className='d-flex gap-4'>
        <div style={{ width: '400px' }}>IP NUMARA(LARI)NIZ</div>
        <div style={{ width: '900px' }}>
          {state.xmlList?.IpAddresses &&
            state.xmlList?.IpAddresses.length > 0 &&
            state.xmlList?.IpAddresses.replace('/', ',')}
        </div>
      </div>
      <hr />
      <div className='d-flex gap-4'>
        <div style={{ width: '400px' }}>XML LİNKLERİNİZ</div>
        <div style={{ width: '300px' }}>XML BİLGİSİ</div>
        <div style={{ width: '600px' }}>LİNKLER</div>
      </div>
      <hr />
      <nav className='cari-hesap--tab-menu'>
        <ul>
          {state.company && state.company.length > 0
            ? state.company.map(com => (
              <li
                onClick={() => selectCompany(com.Id)}
                className={`dropdown-submenu company-tab ${state.companySelect === com.Id ? 'active-company-tab' : ''
                  }`}
              >
                <span>{com.Name}</span>
              </li>
            ))
            : ''}
        </ul>
      </nav>
      {state.linkData.length > 0 && (
        <>
          {state.linkData.map((link, index) => (
            <div className='d-flex gap-4'>
              <div style={{ width: '400px' }}>{index === 0 && 'XML LİNKLERİ'}</div>
              <div style={{ width: '300px' }}>{link.Name}</div>
              <div style={{ width: '600px' }}>
                <a href={link.Link} target='_blank'>
                  {link.Link}
                </a>
              </div>
            </div>
          ))}
        </>
      )}
      <hr />
      <div className='d-flex gap-4'>
        <div style={{ width: '400px' }}>
          <b>XML ALIM BİLGİLERİNİZ</b>
        </div>
        <div style={{ width: '300px' }}>
          <b>SON XML ALIM TARİHİ</b>
        </div>
        <div style={{ width: '600px' }}>
          <b>Son alımdan sonra geçen gün</b>
        </div>
      </div>
      <hr />
      <div className='d-flex gap-4'>
        <div style={{ width: '400px' }}>SON XML ALIMINIZ</div>
        <div style={{ width: '300px' }}></div>
        <div style={{ width: '600px' }}></div>
      </div>
      <hr />
      <div className='d-flex gap-4'>
        <div style={{ width: '400px' }}>
          <b>XML ENTEGRASYON DÖKÜMANI</b>
        </div>
        <div style={{ width: '900px' }}>
          <a
            href='https://www.indexpazar.com/sessman/images/indexgrup%20xml%20d%C3%B6k%C3%BCman%C4%B1.pdf'
            target='_blank'
            style={{ color: 'red' }}
          >
            XML Entegrasyon Dökümanı indirmek için tıklayınız...
          </a>
        </div>
      </div>
      <hr style={{ marginBottom: '5px' }} />
      <hr />
      <h4>
        <b>XML BAŞVURUSU</b>
      </h4>
      <hr />
      <p>
        * Index Grup XML başvurusunda bulunarak talepte bulunabilirsiniz. Başvurunuzun uygun görülmesi durumunda size
        ait xml kodları e-posta adresinize gönderilecektir.
      </p>
      <p>* 2 Ay süresince ilgili şirketten alım yapmadığınız durumlarda XML paylaşımınız sonlandırılacaktır.</p>
      <p>* Ürün Fiyatlarını içeren XML dosyası ertesi gün yayınlanacaktır.</p>
      <hr />
      <h4>
        <b>XML BAŞVURU FORMU</b>
      </h4>
      <hr />
      <div className='row mb-2'>
        <div className='col-md-2'></div>
        <div className='col-md-2' style={{ marginTop: '10px' }}>
          E-Mail Adresiniz
        </div>
        <div className='col-md-4'>
          {' '}
          <IPInput type='text' value={state.email} onChange={e => setState({ email: e.target.value })} />
        </div>
      </div>
      {/* <div className='row mb-2'>
        <div className='col-md-2'></div>
        <div className='col-md-2'>Web Siteniz</div>
        <div className='col-md-4'><IPInput type='text' /></div>
      </div> */}
      <div className='row mb-2'>
        <div className='col-md-2'></div>
        <div className='col-md-2' style={{ marginTop: '10px' }}>
          Sabit IP Adresi
        </div>
        <div className='col-md-4'>
          {' '}
          <IPInput
            type='text'
            value={state.ipAddresses && state.ipAddresses.length > 0 ? state.ipAddresses.replace('/', ',') : ''}
            onChange={e => setState({ ipAddresses: e.target.value })}
          />
        </div>
        <div className='col-md-4' style={{ color: 'red', marginTop: '10px' }}>
          * Birden fazla ise virgül ile ayırınız!
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-md-2'></div>
        <div className='col-md-2'>Şirket</div>
        <div className='col-md-1'>
          <IPCheckbox label='Index' checked={state.idx} onClick={() => setState({ idx: !state.idx })} />
        </div>
        <div className='col-md-1'>
          <IPCheckbox label='Netex' checked={state.ntx} onClick={() => setState({ ntx: !state.ntx })} />
        </div>
        <div className='col-md-1'>
          <IPCheckbox label='Datagate' checked={state.dgx} onClick={() => setState({ dgx: !state.dgx })} />
        </div>
      </div>
      <div className='row mb-2'>
        <div className='col-md-2'></div>
        <div className='col-md-2'></div>
        <div className='col-md-3'></div>
        <div className='col-md-2'>
          <IPButton text='Gönder' onClick={insertXmlDemand} />
        </div>
        <div className='col-md-3'>
          <IPButton text='Başvuru Geçmişi' onClick={getXmlDemandHistory} />
        </div>
      </div>
      <IPModal
        show={showHistory}
        onClose={() => setShowHistory(false)}
        hideConfirm={true}
        width={1200}
        title='XML Başvuru Geçmişi'
      >
        <Table columns={tableColumns} data={tableData} currentPage={0} />
      </IPModal>
    </div>
  )
}
export default componentWithContext(Xml)
