import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class GetUndefinedFilteredProductsApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetNotFilteredProductsCategory',
      requesterInfo,
    })
  }

  public async getUndefinedFilteredProducts(): Promise<any[]> {
    return await this.getAsync<any[]>()
  }
}
