import React, { useEffect, useMemo, useState } from 'react'
import { useCustomReducer } from '../../../../base/customHooks'
import IPButton from '../../../../components/IPButton'
import IPInput from '../../../../components/IPInput'
import IPSelectBox, { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import Translations from '../../../../translations'
import { translate } from '../../../../base/localization'
import { ProductPropertiesService } from './services'
import Swal from 'sweetalert2'
import Table from '../../../../components/Table'
import { ChangePropertiesReq, ProductPropertiesHistory, ProductPropertiesRes } from './models'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'
import { GetHistoryApi } from './getHistoryApi'
import moment from 'moment'
import InputCampaign from '../../../../components/InputCampaign'

interface IProductState {
  selectedField?: ISelectOption
  sapMaterialNumber: string
  productProperties: ProductPropertiesRes[]
  productGroups: ISelectOption[]
  page: string | null
  history: boolean
  historyModel: ProductPropertiesHistory[]
}

function AddProductFeatures(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): IProductState => {
    return {
      sapMaterialNumber: '',
      productProperties: [],
      productGroups: [],
      page: "0",
      history: false,
      historyModel: []
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IProductState>(intialState)

  async function getProducts() {
    try {
      props.showLoading()
      const res = await new ProductPropertiesService({}).filter({
        MatNumber: state.sapMaterialNumber,
        GroupCode: state.selectedField ? (state.selectedField.value as string) : '',
      })
      if (res) {
        setState({ productProperties: res })
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())
  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === "Start")
      setStartDate(data)
    else
      setEndDate(data)
  }

  const History = async () => {
    const req = {
      StartDate: startDate,
      EndDate: endDate
    }
    props.showLoading()
    const history: GetHistoryApi = new GetHistoryApi({})
    history.getHistory(req).then((res: any) => {
      if (res) {
        setState({ history: true, historyModel: res })
        props.hideLoading()
      }
    })
      .catch((e: any) => {
        Swal.fire({
          icon: 'error',
          title: e.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        props.hideLoading()
      })
  }

  async function changeProperties() {
    if (!state.sapMaterialNumber || state.sapMaterialNumber.length === 0) {
      Swal.fire('Hata', 'Lütfen SAP Malzeme Numarası giriniz', 'error')
      return
    }
    const req: ChangePropertiesReq[] = []
    let i = 0;
    state.productProperties.map(x => {
      if (x.AttribValue && x.AttribValue.length > 0)
        i++;
      return req.push({
        Id: x.Id,
        PRODUCTID: x.PRODUCTID,
        CategoryID: x.CategoryID,
        CATNAME: x.CATNAME,
        ProductCode: x.ProductCode,
        MatNumber: state.sapMaterialNumber,
        GroupID: x.GroupID,
        GroupName: x.GroupName,
        FEATUREID: x.FEATUREID,
        AttribName: x.AttribName,
        AttribValue: x.AttribValue ?? '',
        OrderId: x.OrderId,
      })
    })
    if (i === 0) {
      Swal.fire({
        icon: 'error',
        title: 'En az 1 tane özellik değeri giriniz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    try {
      const res = await new ProductPropertiesService({}).changeProperties(req)
      if (res) {
        Swal.fire('Başarılı', 'Değişiklik başarıyla gerçekleşti.', 'success')
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  async function getProductGroups() {
    try {
      const res = await new ProductPropertiesService({}).productGroupsList()
      if (res) {
        const productGroups = res.map(x => ({ label: x.Name, value: x.SapCode }))
        setState({ productGroups })
      }
    } catch (err: any) {
      console.log('Response', err)
    }
  }

  useEffect(() => {
    getProductGroups()
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'IceCat Kategorisi',
        accessor: 'Name',
      },
      {
        Header: 'Özellik Adı',
        accessor: 'AttribName',
      },
      {
        Header: 'Özellik Değeri',
        accessor: 'AttribValue',
      },
      {
        Header: 'Tanımlı Ürün Sayısı',
        accessor: 'OzTanUrSayisi',
      },
    ],
    []
  )

  const columnDefinitions = [
    { Header: '#', accessor: 'col1' },
    { Header: 'Order ID', accessor: 'col12' },
    { Header: 'Prop ID', accessor: 'col2' },
    { Header: 'Ürün Kodu', accessor: 'col3' },
    { Header: 'Malzeme Numarası', accessor: 'col4' },
    { Header: 'Kategori ID', accessor: 'col5' },
    { Header: 'Kategori Adı', accessor: 'col6' },
    { Header: 'Grup ID', accessor: 'col7' },
    { Header: 'Grup Adı', accessor: 'col8' },
    { Header: 'Özellik ID', accessor: 'col9' },
    { Header: 'Özellik Adı', accessor: 'col10' },
    { Header: 'Özellik Değeri', accessor: 'col11' },
    { Header: 'Tarih', accessor: 'col13' },
    { Header: 'Bayi Kodu', accessor: 'col14' },
    { Header: 'Kullanıcı Adı', accessor: 'col15' },
  ];

  const tableColumns = useMemo(() => columnDefinitions, []);

  const tableDataAndTotal = useMemo(
    () => {
      const tableData = state.historyModel.map(item => {
        const data = {
          col1: item.Id,
          col2: item.PropId,
          col3: item.ProductCode,
          col4: item.MatNumber,
          col5: item.CategoryID,
          col6: item.CATNAME,
          col7: item.GroupID,
          col8: item.GroupName,
          col9: item.FEATUREID,
          col10: item.AttribName,
          col11: item.AttribValue,
          col12: item.OrderId,
          col13: item.UpdateTime && moment(item.UpdateTime).format('DD.MM.YYYY HH:mm:ss'),
          col14: item.UserDealer && item.UserDealer.replace('0000', ''),
          col15: item.UserName,
        }
        // if (!isNaN(+item.PayInfo)) {
        //   totalPayInfo += parseFloat(item.PayInfo);
        // }
        return data
      })
      return {
        tableData,
      };
    }
    , [History])

  const data = useMemo(
    () =>
      state.productProperties.map(p => {
        return {
          Name: <IPInput type='text' className='form-control' value={p.CATNAME} disabled={true} />,
          AttribName: <IPInput type='text' className='form-control' value={p.AttribName} disabled={true} />,
          AttribValue: (
            <IPInput
              type='text'
              className='form-control'
              value={p.AttribValue}
              onChange={e => {
                const productProperties = state.productProperties.map(x => {
                  if (x.Id === p.Id) {
                    x.AttribValue = e.target.value
                  }
                  return x
                })
                setState({ productProperties, page: sessionStorage.getItem("Page") })
              }}
            />
          ),
          OzTanUrSayisi: <IPInput type='text' className='form-control' value={p.OzTanUrSayisi} disabled={true} />,
        }
      }),
    [state.productProperties]
  )

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>{t('pages.admin.productOperation.addProductFeatures.addProductFeatures.header')}</span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='row'>
                  <div className='col-lg-4'>
                    <IPSelectBox
                      id='DealerContents'
                      name='DealerContents'
                      options={state.productGroups}
                      onChangeSingle={selectedField => setState({ selectedField })}
                      value={state.selectedField}
                      placeholder="Ürün Grubu Seçiniz"
                      isClearable
                    />
                  </div>
                  <div className='col-lg-4 mb-3'>
                    <IPInput
                      type='text'
                      className='form-control'
                      id='Product'
                      value={state.sapMaterialNumber}
                      onChange={e => setState({ sapMaterialNumber: e.target.value })}
                      placeholder='SAP Malzeme Numarası'
                    />
                  </div>
                  <div className='col-2 mb-3'>
                    <IPButton type='button' className='btn btn-success' text={'Göster'} onClick={getProducts} />
                  </div>
                </div>
                <br />
                {
                  state.productProperties.length > 0 &&
                  <div className='row mb-4'>
                    <div className='col-2'>
                      {t('pages.admin.productOperation.addProductFeatures.addProductFeatures.note')}
                    </div>
                    <div className='col-10' style={{ fontSize: 17 }}>
                      {t('pages.admin.productOperation.addProductFeatures.addProductFeatures.featureDescription')}
                    </div>
                  </div>
                }

                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-12'>
                      <Table columns={columns} data={data} currentPage={state.page ? +state.page : 0} />
                    </div>
                    {state.productProperties.length > 0 && (
                      <div className='ml-auto col-2'>
                        <IPButton type='button' className='btn btn-success' text='Ekle' onClick={changeProperties} />
                      </div>
                    )}
                  </div>
                </div>
                <div className='col-lg-12'>
                  <div className='form-row justify-content-center'>
                    <div className='form-group col-md-3'>
                      <InputCampaign
                        type='date'
                        id='StartDate'
                        name='StartDate'
                        label={'Başlangıç Tarihi'}
                        value={toDateTimeLocal(startDate)}
                        onChange={e => getDate(e.target.value, "Start")}
                        isActive
                      />
                    </div>

                    <div className='form-group col-md-3'>
                      <InputCampaign
                        type='date'
                        id='EndDate'
                        name='EndDate'
                        label={'Bitiş Tarihi'}
                        value={toDateTimeLocal(endDate)}
                        onChange={e => getDate(e.target.value, "End")}
                        isActive
                      />
                    </div>
                  </div>
                  <h5 style={{ textAlign: 'center' }}>
                    <a onClick={History} style={{ cursor: 'pointer' }}>
                      Geçmişi Göster
                    </a>
                  </h5>
                  {state.history &&
                    <Table columns={tableColumns} data={tableDataAndTotal.tableData} currentPage={0} headerName={"Özellik Ekleme Geçmişi"} columnCount={16} backgroundColor="red" color="white" />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(AddProductFeatures)
