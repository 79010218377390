import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IMailSendRequest, IMailSendResponse } from '../models/models'

export class AlertEmailSubmissionsReportApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/alertEmailSubmissionsReport',
      requesterInfo,
    })
  }

  public async alertEmailSubmissionsReport(request: IMailSendRequest): Promise<IMailSendResponse[]> {
    return await this.postAsync<IMailSendResponse[]>(request)
  }
}
