export class ServiceRouter {
  public static getAuthenticateUrl = (): string => {
    // const envName: string = ServiceRouter.getEnvName();
    // const config = getRouterConfig(envName);

    // if (config === undefined) {
    //   throw new Error(`Environment name(${ServiceRouter.getEnvName()}) not found!`);
    // }

    // return config.authorityURL || '';

    // TODO :
    return `${ServiceRouter.getServiceUrl()}auth/login`
  }

  public static getLogoutUrl = (): string => {
    // const envName: string = ServiceRouter.getEnvName();
    // const config = getRouterConfig(envName);

    // if (config === undefined) {
    //   throw new Error(`Environment name(${ServiceRouter.getEnvName()}) not found!`);
    // }

    // return config.authorityURL || '';

    // TODO :
    return `${ServiceRouter.getServiceUrl()}auth/logout`
  }

  public static getServiceUrl = (): string => {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

    if (!isLocalhost) {
      return `${window.location.origin}/api/`
    }

    return process.env.REACT_APP_API_URL ?? ''
  }
  
  public static getUrl = (): string => {
    const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'

    if (!isLocalhost) {
      return `${window.location.origin}`
    }

    return process.env.REACT_APP_URL ?? ''
  }
}
