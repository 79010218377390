import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useCustomReducer } from '../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../base/customHooks/componentHOC'
import { translate } from '../../../base/localization'
import { DEFAULT_THEME } from '../../../base/theme/themeConfig'
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks'
import {
  favoriteProductsRedux,
  getFavoriteProductListRedux,
} from '../../../redux/slices/favoriteProduct/favoriteProductSlice'
import Translations from '../../../translations'
import ProductCard2 from '../../ProductList/components/ProductCard2'
import ProductCard3 from '../../ProductList/components/ProductCard3'
import ProductCardFav from '../../ProductList/components/ProductCardFav'
import { IProduct } from '../../ProductList/models/response/getProductListResponseModel'

interface ProductListState {
  selectedCardView?: string
  isStockChecked: boolean
  categories: any[]
  categoryListActiveKey: string
  isBrandListAccordionExpanded: boolean
  isSellerListAccordionExpanded: boolean
  selectInputValues: any[]
  searchCategoryValues: string
  searchProductValues: string
  selectedBrands: number[]
  selectedSellers: string[]
  currentQueryParams: string
  brandId?: number
  categoryId?: number
  selectedProductsToCompare: IProduct[]
}

function ProductList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)

  const getInitialState = (): ProductListState => {
    return {
      isStockChecked: true,
      categories: [],
      categoryListActiveKey: '',
      isBrandListAccordionExpanded: true,
      isSellerListAccordionExpanded: true,
      selectInputValues: [],
      searchCategoryValues: '',
      searchProductValues: '',
      selectedSellers: [],
      selectedBrands: [],
      currentQueryParams: '',
      selectedProductsToCompare: [],
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ProductListState>(intialState)
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(true)
  //let location = useLocation()
  //const fovariteProducts = useAppSelector(favoriteProductsRedux)

  const [searchParams] = useSearchParams()

  const favoriteProductList = useAppSelector(favoriteProductsRedux)

  useEffect(() => {
    setState({ selectedCardView: '1' })

    if (props.theme.name !== DEFAULT_THEME) {
      setState({ selectedSellers: [props.theme.name] })
      searchParams.set('seller', [props.theme.name].toString())
    }
  }, [null])

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getFavoriteProductListRedux())
      setIsLoading(false)
    }

    fetchData()
  }, [dispatch])

  if (isLoading) {
    props.showLoading() // or return a loading spinner or any placeholder
  } else props.hideLoading()

  return (
    <React.Fragment>
      <section id='page-content' className='sidebar-left pt-0 background-white'>
        <div className='container-fluid '>
          <div className='row'>
            <div className='col-lg-12 content pl-0'>
              <div className='row'>
                <div className='col-md-3 heading-text mb-1 d-flex align-items-center'>
                  <h4>Favorilerim</h4>
                </div>
                <div className='col-md-9 d-flex justify-content-end'>
                  <div className='page-menu myFav border-bottom py-0 py-lg-3' style={{ borderTop: 'none' }}>
                    <div className='container'>
                      <nav className=' float-rigth h-auto d-flex justify-content-between'>
                        <ul className='list list-legend d-flex align-items-center'>
                          <li className='grid_items' style={{ backgroundColor: 'transparent' }}>
                            <a href='#' data-category='' onClick={() => setState({ selectedCardView: '1' })}>
                              <i className='icon-grid'> </i>
                            </a>
                          </li>
                          <li className='grid_items' style={{ backgroundColor: 'transparent' }}>
                            <a href='#' data-category='.' onClick={() => setState({ selectedCardView: '2' })}>
                              <i className='icon-list'> </i>
                            </a>
                          </li>
                          <li className='grid_items' style={{ backgroundColor: 'transparent' }}>
                            <a href='#' data-category='' onClick={() => setState({ selectedCardView: '3' })}>
                              <i className='fa fa-list-alt'></i>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className='col-md-12'>
                  {(!favoriteProductList || (favoriteProductList && favoriteProductList.length === 0)) && (
                    <div style={{ textAlign: 'center', padding: 10 }}>
                      {t('pages.mySpecial.myFavorite.index.youCurrentlyDoNotHaveAFavoriteProduct')}
                    </div>
                  )}
                  <div
                    className={`productCardGrid ${state.selectedCardView === '1'
                      ? 'productCardGrid1'
                      : state.selectedCardView === '2'
                        ? 'productCardGrid2'
                        : state.selectedCardView === '3'
                          ? 'productCardGrid3'
                          : ''
                      }`}
                  >
                    {favoriteProductList.map((product: IProduct) => {
                      if (state.selectedCardView === '1') {
                        return (
                          <ProductCardFav
                            key={product.Id}
                            product={product}
                            isSelected={
                              state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
                                return productItem.Id === product.Id
                              }) > -1
                            }
                          />
                        )
                      }

                      if (state.selectedCardView === '2') {
                        return (
                          <ProductCard2
                            key={product.Id}
                            product={product}
                            isSelected={
                              state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
                                return productItem.Id === product.Id
                              }) > -1
                            }
                          />
                        )
                      }

                      if (state.selectedCardView === '3') {
                        return (
                          <ProductCard3
                            key={product.Id}
                            product={product}
                            isSelected={
                              state.selectedProductsToCompare.findIndex((productItem: IProduct) => {
                                return productItem.Id === product.Id
                              }) > -1
                            }
                          />
                        )
                      }

                      return null
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export default componentWithContext(ProductList)
