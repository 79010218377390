import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { ISendContactFormRequestModel } from '../models'

export class SendContactFormApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'general/contact',
      requesterInfo,
    })
  }

  public async sendForm(request: ISendContactFormRequestModel): Promise<boolean> {
    return await this.postAsync<boolean>(request)
  }
}
