import { Link } from "react-router-dom"

function NotFound(params: any) {
  return <>
    <div className="m-5">404 Not Found</div>
    <div className="m-5"><b>Anasayfaya dönmek için <Link to='/homepage'><span>tıklayınız</span></Link>.</b></div>
  </>
}

export default NotFound
