import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IBestSellers } from '../commonModels/models'

export class GetMostSelledProductsByCatagoriesApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'product/GetMostSelledProductsByCatagories',
      requesterInfo,
    })
  }

  public async getMostSelledProductsByCatagories(): Promise<IBestSellers[]> {
    return await this.getAsync<IBestSellers[]>()
  }
}
