import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { INetChannelContentData } from '../models/model'

export class SaveNetChannelContentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/netChannelContent/saveNetChannelContent',
      requesterInfo,
    })
  }

  public async saveNetChannelContent(req: INetChannelContentData[]): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
