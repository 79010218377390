import * as yup from 'yup'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'

const t = translate(Translations)
const FILE_SIZE = 12324234
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png']

const validations = yup.object().shape({
  Active: yup.boolean(),
  CompanyId: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .nullable(),

  Category: yup
    .array()
    .of(
      yup.object().shape({
        label: yup.string(),
        value: yup.string(),
      })
    )
    .nullable(),

  Counter: yup.object().nullable(),

  Link: yup
    .string()
    .min(6, t('pages.admin.homeAndSiteOperation.bannersCrud.validation.characterMustBe', ['Link', '6'])),
  Title: yup
    .string()
    .min(3, t('pages.admin.homeAndSiteOperation.bannersCrud.validation.characterMustBe', ['Başlık', '3']))
    .required(t('pages.admin.homeAndSiteOperation.bannersCrud.validation.requiredField')),
  // Description: yup
  //   .string()
  //   .min(6, t('pages.admin.homeAndSiteOperation.bannersCrud.validation.characterMustBe', ['Açıklama', '6']))
  //   .required(t('pages.admin.homeAndSiteOperation.bannersCrud.validation.requiredField')),
  StartDate: yup.date().required(''),
  EndDate: yup.date().required(''),
})

export default validations
