import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IMenu } from '../models/responseModels'

export class GetMenuApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/menu/list',
      requesterInfo,
    })
  }

  public async getMenu(request: any): Promise<IMenu[]> {
    this.props.url = `${this.props.url}?companyId=${request ? request : 0}`
    return await this.getAsync<IMenu[]>()
  }
}
