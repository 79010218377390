import { IRequesterInfo } from '../../../../base/models'
import { ClientProxy } from '../../../../base/proxy/clientProxy'
import { ChangePropertiesReq, ProductGroups, ProductPropertiesRes } from './models'

export class ProductPropertiesService extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/ProductProperties/filter',
      requesterInfo,
    })
  }

  public async filter(req: { MatNumber: string; GroupCode: string }): Promise<ProductPropertiesRes[]> {
    return await this.postAsync<ProductPropertiesRes[]>(req)
  }

  public async changeProperties(req: ChangePropertiesReq[]): Promise<boolean> {
    this.props.url = 'admin/ProductProperties/changeProperties'
    return await this.postAsync<boolean>(req)
  }

  public async productGroupsList(): Promise<ProductGroups[]> {
    this.props.url = 'admin/product/productGroupsList'
    return await this.getAsync<ProductGroups[]>()
  }
}
