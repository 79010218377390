import { useCustomReducer } from '../../../../base/customHooks'
import IPButton from '../../../../components/IPButton'
import { useState, useEffect, useMemo, ChangeEvent, useRef, MouseEvent } from 'react'
import './PeriodicReport.scss'
import { PeriodicReportService } from './services'
import Swal from 'sweetalert2'
import IPSelectBox from '../../../../components/IPSelectBox'
import { ISelectOption } from '../../../../components/IPSelectBox/IPSelectBox'
import { GetCompaniesApi } from '../../CurrentAccount/Extract/services/getCompaniesApi'
import {
  GetPeriodicTurnoverSubQueryResponse,
  PeriodicReportRequest,
  PeriodicTurnoverSendMailRequest,
  SapPeriodicTurnOverResponseModel,
} from './models'
import { utils, writeFile, CellObject } from 'xlsx'
import IPDatePicker from '../../../../components/IPDatePicker'
import { css } from '@emotion/css'
import _ from 'lodash'
import IPModal from '../../../../components/IPModal'
import IPInput from '../../../../components/IPInput'
import { formatAsCurrency, printElementPeriodicReport } from '../../../../base/utils/dataHelper'
import { Spinner } from '../../../../components/Spinner'
import moment from 'moment'
import { Bar, Doughnut, Line, Pie, Radar, Scatter, getElementAtEvent } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  RadialLinearScale,
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  InteractionItem,
} from 'chart.js'
import IPExcelButton from '../../../../components/IPExcelButton'
import IPPrintButton from '../../../../components/IPPrintButton'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../base/customHooks/componentHOC'

ChartJS.register(
  CategoryScale,
  RadialLinearScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
)

interface IReportState {
  sliceOption: ISelectOption | null
  companies: ISelectOption[]
  companyValue: ISelectOption | null
  chart: SapPeriodicTurnOverResponseModel[]
  categoryCode: ISelectOption[] | null
  isModalOpened: boolean
  periodicData: GetPeriodicTurnoverSubQueryResponse[]
  periodicDataSecond: GetPeriodicTurnoverSubQueryResponse[]
  periodicDataThird: GetPeriodicTurnoverSubQueryResponse[]
  brandData: GetPeriodicTurnoverSubQueryResponse[]
  showMailModal: boolean
  excelFile: string | null
  mail: string
}

const ISOFORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]'

function Reports(props: any & BaseComponentWithContextProps) {
  const getInitialState = (): IReportState => {
    return {
      sliceOption: null,
      companies: [],
      companyValue: null,
      chart: [],
      categoryCode: null,
      isModalOpened: false,
      periodicData: [],
      periodicDataSecond: [],
      periodicDataThird: [],
      brandData: [],
      showMailModal: false,
      excelFile: null,
      mail: '',
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IReportState>(intialState)
  const [loading, setLoading] = useState(false)
  const [base64String, setBase64String] = useState('')
  const [isClicked, setIsClicked] = useState(false)
  const [period, setPeriod] = useState(new Date())
  const [selectedMonthRange, setSelectedMonthRange] = useState({
    StartDate: new Date().toISOString(),
    EndDate: new Date().toISOString(),
    text: '',
  })

  const chartRef = useRef<any>(null)

  const handleClick = () => {
    if (!isClicked) {
      setIsClicked(true)
      setTimeout(() => {
        setIsClicked(false)
      }, 2000)
    }
  }

  interface PeriodicData {
    products: GetPeriodicTurnoverSubQueryResponse[]
    brands: GetPeriodicTurnoverSubQueryResponse[]
  }
  const [data, setData] = useState<PeriodicData>({
    products: [],
    brands: [],
  })

  enum View {
    Bar,
    Pie,
    Area,
    Line,
    Scatter,
    Doughnut,
    Gannt,
    Radar,
  }

  const views = {
    bar: <i className='fas fa-solid fa-chart-column'></i>,
    pie: <i className='fas fa-solid fa-chart-pie'></i>,
    area: <i className='fas fa-solid fa-chart-area'></i>,
    line: <i className='fas fa-solid fa-chart-line'></i>,
    scatter: <img src='/images/scatter-plot-50.png' alt='' />,
    doughnut: <img src='/images/doughnut-chart-50.png' alt='' />,
    gannt: <img src='/images/gantt-chart.png' alt='' />,
    radar: <img src='/images/radar-plot-50.png' alt='' />,
  }
  const [view, setView] = useState({ view: View.Bar, icon: views.bar })
  const [showViews, setShowViews] = useState(false)

  const getCompanies = async () => {
    new GetCompaniesApi({}).getCompanies().then((res: any[]) => {
      if (res) {
        setState({ companies: res.map(x => ({ value: x.Code, label: x.Name })) })
      }
    })
  }

  //#region Excel Aktarma
  // const exportToExcel = () => {
  //   const omitFields = ['id', 'show', 'subProducts', 'subBrands', 'fetched']

  //   // Ensure that data.products and data.brands are not undefined
  //   const productsData = data.products || []
  //   const brandsData = data.brands || []

  //   const products: Partial<GetPeriodicTurnoverSubQueryResponse>[] = []
  //   const subProducts: Partial<GetPeriodicTurnoverSubQueryResponse>[] = []

  //   const extractSubProducts = (product: GetPeriodicTurnoverSubQueryResponse) => {
  //     if (product.subProducts && product.subProducts.length > 0) {
  //       subProducts.push(...product.subProducts.map(sub => _.omit(sub, omitFields)))
  //       product.subProducts.forEach(sub => extractSubProducts(sub))
  //     }
  //   }

  //   productsData.forEach(product => {
  //     products.push(_.omit(product, omitFields))
  //     extractSubProducts(product)
  //   })

  //   const brands: Partial<GetPeriodicTurnoverSubQueryResponse>[] = []
  //   const subBrands: Partial<GetPeriodicTurnoverSubQueryResponse>[] = []

  //   brandsData.forEach(brand => {
  //     brands.push(_.omit(brand, omitFields))
  //     if (brand.subBrands && brand.subBrands.length > 0) {
  //       subBrands.push(...brand.subBrands.map(sub => _.omit(sub, omitFields)))
  //     }
  //   })

  //   const ensureCompleteData = (
  //     data: Partial<GetPeriodicTurnoverSubQueryResponse>[]
  //   ): GetPeriodicTurnoverSubQueryResponse[] => {
  //     return data.map(item => ({
  //       KOD: item.KOD || '',
  //       ACIKLAMA: item.ACIKLAMA || '',
  //       EvrakNo: item.EvrakNo || '',
  //       MIKTAR: item.MIKTAR || 0,
  //       TUTARTL: item.TUTARTL || 0,
  //       TUTARUSD: item.TUTARUSD || 0,
  //       ...item,
  //     })) as GetPeriodicTurnoverSubQueryResponse[]
  //   }

  //   const rows = {
  //     products: ensureCompleteData(products),
  //     subProducts: ensureCompleteData(subProducts),
  //     brands: ensureCompleteData(brands),
  //     subBrands: ensureCompleteData(subBrands),
  //   }

  //   console.log('Products:', rows.products)
  //   console.log('SubProducts:', rows.subProducts)
  //   console.log('Brands:', rows.brands)
  //   console.log('SubBrands:', rows.subBrands)

  //   /* Generate worksheets and workbook */
  //   const workbook = utils.book_new()

  //   const addSheet = (data, sheetName, headers) => {
  //     if (data.length > 0) {
  //       const worksheet = utils.json_to_sheet(data)

  //       // Add headers to the worksheet
  //       utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' })

  //       // Optionally adjust the column widths
  //       const colWidths = headers.map(header => ({ wch: header.length + 2 })) // Adding 2 for padding
  //       worksheet['!cols'] = colWidths

  //       // Check if the sheet already exists
  //       const existingSheetIndex = workbook.SheetNames.indexOf(sheetName)
  //       if (existingSheetIndex !== -1) {
  //         // If the sheet already exists, remove it before adding the new one
  //         delete workbook.Sheets[sheetName]
  //         workbook.SheetNames.splice(existingSheetIndex, 1)
  //       }

  //       utils.book_append_sheet(workbook, worksheet, sheetName)
  //     } else {
  //       console.warn(`${sheetName} için veri yok, sayfa oluşturma işlemi atlanıyor.`)
  //     }
  //   }

  //   // Define different headers for each sheet
  //   const productHeaders = ['KOD', 'KATEGORİ ADI', 'EVRAK NO', 'MİKTAR', 'TUTAR (TL)', 'TUTAR (USD)']
  //   const subProductHeaders = ['KOD', 'ALT KATEGORİ', 'EVRAK NO', 'MİKTAR', 'TUTAR (TL)', 'TUTAR (USD)']
  //   const brandHeaders = ['KOTA KOD', 'MARKA ADI', 'EVRAK NO', 'ÜRÜN MİKTAR', 'TUTAR (TL)', 'TUTAR (USD)']
  //   const subBrandHeaders = ['EVRAK NO', 'MİKTAR', 'TUTAR (TL)', 'TUTAR (USD)', 'AÇIKLAMA', 'ÜRÜN ADI (KODU)']

  //   addSheet(rows.products, 'Kategoriler', productHeaders)
  //   addSheet(rows.subProducts, 'Alt Kategoriler', subProductHeaders)
  //   addSheet(rows.brands, 'Markalar', brandHeaders)
  //   addSheet(rows.subBrands, 'Ürünler', subBrandHeaders)

  //   writeFile(workbook, 'donemsel-ciro-raporu.xlsx', { compression: true })
  // }

  interface FlattenedSubProduct extends Omit<GetPeriodicTurnoverSubQueryResponse, 'subProducts'> {}

  const exportToExcel = () => {
    const omitFields = ['id', 'show', 'subProducts', 'subBrands', 'fetched', 'parentId', 'level']

    // Ensure that data.products and data.brands are not undefined
    const productsData = data.products || []
    const brandsData = data.brands || []

    const flattenSubProducts = (
      product: GetPeriodicTurnoverSubQueryResponse,
      parentId: number | null = null,
      level: number = 0
    ): FlattenedSubProduct[] => {
      const flatSubProducts: FlattenedSubProduct[] = []
      if (product.subProducts && product.subProducts.length > 0) {
        product.subProducts.forEach(sub => {
          const flattenedSub = {
            ..._.omit(sub, omitFields),
          } as FlattenedSubProduct
          flatSubProducts.push(flattenedSub)
          flatSubProducts.push(...flattenSubProducts(sub, sub.id, level + 1))
        })
      }
      return flatSubProducts
    }

    const flatProducts: Partial<GetPeriodicTurnoverSubQueryResponse>[] = []
    const flatSubProducts: FlattenedSubProduct[] = []

    productsData.forEach(product => {
      flatProducts.push(_.omit(product, omitFields))
      flatSubProducts.push(...flattenSubProducts(product, product.id, 0))
    })

    const brands: Partial<GetPeriodicTurnoverSubQueryResponse>[] = brandsData.map(brand => _.omit(brand, omitFields))
    const subBrands: Partial<GetPeriodicTurnoverSubQueryResponse>[] = brandsData.reduce(
      (acc: Partial<GetPeriodicTurnoverSubQueryResponse>[], brand) => {
        if (brand.subBrands && brand.subBrands.length > 0) {
          acc.push(
            ...brand.subBrands.map(sub => _.omit(sub, omitFields) as Partial<GetPeriodicTurnoverSubQueryResponse>)
          )
        }
        return acc
      },
      []
    )

    const ensureCompleteData = (
      data: Partial<GetPeriodicTurnoverSubQueryResponse>[]
    ): GetPeriodicTurnoverSubQueryResponse[] => {
      return data.map(item => ({
        KOD: item.KOD || '',
        ACIKLAMA: item.ACIKLAMA || '',
        EvrakNo: item.EvrakNo || '',
        MIKTAR: item.MIKTAR || 0,
        TUTARTL: item.TUTARTL || 0,
        TUTARUSD: item.TUTARUSD || 0,
        ...item,
      })) as GetPeriodicTurnoverSubQueryResponse[]
    }

    const rows = {
      products: ensureCompleteData(flatProducts),
      subProducts: ensureCompleteData(flatSubProducts),
      brands: ensureCompleteData(brands),
      subBrands: ensureCompleteData(subBrands),
    }

    /* Generate worksheets and workbook */
    const workbook = utils.book_new()

    const addSheet = (data: any[], sheetName: string, headers: string[]) => {
      if (data.length > 0) {
        const worksheet = utils.json_to_sheet(data)

        // Add headers to the worksheet
        utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' })

        // Make headers bold
        headers.forEach((header, index) => {
          const cellAddress = utils.encode_cell({ c: index, r: 0 })
          const cell: CellObject = worksheet[cellAddress]
          if (cell && !cell.s) cell.s = {}
          if (cell && cell.s) {
            cell.s.font = { bold: true }
          }
        })

        // Optionally adjust the column widths
        const colWidths = headers.map(header => ({ wch: header.length + 2 })) // Adding 2 for padding
        worksheet['!cols'] = colWidths

        // Check if the sheet already exists
        const existingSheetIndex = workbook.SheetNames.indexOf(sheetName)
        if (existingSheetIndex !== -1) {
          // If the sheet already exists, remove it before adding the new one
          delete workbook.Sheets[sheetName]
          workbook.SheetNames.splice(existingSheetIndex, 1)
        }

        utils.book_append_sheet(workbook, worksheet, sheetName)
      } else {
        console.warn(`${sheetName} için veri yok, sayfa oluşturma işlemi atlanıyor.`)
      }
    }

    // Define different headers for each sheet
    const productHeaders = ['KOD', 'KATEGORİ ADI', 'EVRAK NO', 'MİKTAR', 'TUTAR (TL)', 'TUTAR (USD)']
    const subProductHeaders = [
      'KOD',
      'ALT KATEGORİ',
      'EVRAK NO',
      'MİKTAR',
      'TUTAR (TL)',
      'TUTAR (USD)',
      'ÜRÜN ADI',
      'ÜRÜN KODU',
    ]
    const brandHeaders = ['KOTA KOD', 'MARKA ADI', 'EVRAK NO', 'ÜRÜN MİKTAR', 'TUTAR (TL)', 'TUTAR (USD)']
    const subBrandHeaders = ['', '', 'EVRAK NO', 'MİKTAR', 'TUTAR (TL)', 'TUTAR (USD)', 'AÇIKLAMA', 'ÜRÜN ADI (KODU)']

    addSheet(rows.products, 'Kategoriler', productHeaders)
    addSheet(rows.subProducts, 'Alt Kategoriler ve Ürünler', subProductHeaders)
    addSheet(rows.brands, 'Markalar', brandHeaders)
    addSheet(rows.subBrands, 'Markaların Tüm Ürünler', subBrandHeaders)

    writeFile(workbook, 'donemsel-ciro-raporu.xlsx', { compression: true })
  }

  //#endregion

  useEffect(() => {
    getCompanies()
  }, [])

  const getElement = (element: InteractionItem[]) => {
    if (!element.length) return

    const { datasetIndex, index } = element[0]

    const month = chartData.labels[index]
    // const value = chartData.datasets[datasetIndex].data[index]

    handleMonthSelection(index, month)
  }

  const handleChartOnClick = (event: MouseEvent<HTMLCanvasElement>) => {
    const { current: chart } = chartRef

    if (!chart) {
      return
    }

    getElement(getElementAtEvent(chart, event))
  }

  const chartData = useMemo(() => {
    const chart = state.chart

    const TL: any = []
    const USD: any = []
    const labels: string[] = []

    for (const val in chart) {
      if (Object.prototype.hasOwnProperty.call(chart, val)) {
        const element = chart[val]
        if (val.includes('_USD') && USD.length < 12) {
          USD.push(element)
        } else if (TL.length < 12) {
          TL.push(element)
          labels.push(val)
        }
      }
    }

    let datasets: any = [
      {
        fill: view.view === View.Area ? 'shape' : false,
        label: 'Türk Lirası',
        data: TL,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        fill: view.view === View.Area ? 'shape' : false,
        label: 'Dolar',
        data: USD,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ]

    // if (view.view === View.Scatter) {
    //   datasets = [
    //     {
    //       label: 'Türk Lirası',
    //       data: {
    //         x: TL,
    //         y: USD,
    //       },
    //       backgroundColor: 'rgba(255, 99, 132, 1)',
    //     },
    //   ]
    // }

    return {
      labels,
      datasets,
    }
  }, [state.chart, view.view])

  let chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  }

  let viewComponent = <Bar ref={chartRef} onClick={handleChartOnClick} data={chartData} options={chartOptions} />
  if (view.view === View.Bar)
    viewComponent = <Bar ref={chartRef} onClick={handleChartOnClick} data={chartData} options={chartOptions} />
  if (view.view === View.Pie)
    viewComponent = <Pie ref={chartRef} onClick={handleChartOnClick} data={chartData} options={chartOptions} />
  if (view.view === View.Area)
    viewComponent = <Line ref={chartRef} onClick={handleChartOnClick} data={chartData} options={chartOptions} />
  if (view.view === View.Line)
    viewComponent = <Line ref={chartRef} onClick={handleChartOnClick} data={chartData} options={chartOptions} />
  if (view.view === View.Scatter) {
    chartOptions = {
      ...chartOptions,
      // @ts-ignore
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    }
    viewComponent = <Scatter ref={chartRef} onClick={handleChartOnClick} data={chartData} options={chartOptions} />
  }
  if (view.view === View.Doughnut)
    viewComponent = <Doughnut ref={chartRef} onClick={handleChartOnClick} data={chartData} options={chartOptions} />
  if (view.view === View.Gannt) {
    chartOptions = {
      indexAxis: 'y' as const,
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        legend: {
          //@ts-ignore
          position: 'right' as const,
        },
      },
    }
    viewComponent = <Bar ref={chartRef} onClick={handleChartOnClick} data={chartData} options={chartOptions} />
  }
  if (view.view === View.Radar)
    viewComponent = <Radar ref={chartRef} onClick={handleChartOnClick} data={chartData} options={chartOptions} />

  const api = new PeriodicReportService({})

  const errorSwal = text =>
    Swal.fire({
      title: 'Hata',
      icon: 'error',
      text,
      backdrop: true,
      confirmButtonText: 'KAPAT',
      confirmButtonColor: '#333',
    })

  const GetPeriodicTurnover = async req => {
    try {
      const res = await api.GetPeriodicTurnOver(req)
      if (res) {
        return res
      }
    } catch (err: any) {
      errorSwal(err.description)
    }
  }

  const GetPeriodicTurnoverSubProduct = async req => {
    try {
      const res = await api.GetPeriodicTurnoverSubProduct(req)
      if (res) {
        return res.map((x, id) => ({ ...x, id: id + 1, show: false, fetched: false, subProducts: [] }))
      }
    } catch (err: any) {
      errorSwal(err.description)
    }
  }

  const GetPeriodicTurnoverSubBrand = async req => {
    try {
      const res = await api.GetPeriodicTurnoverSubBrand(req)
      if (res) {
        return res.map((x, id) => ({ ...x, id: id + 1, show: false, fetched: false, subBrands: [] }))
      }
    } catch (err: any) {
      errorSwal(err.description)
    }
  }

  async function handleSubmit() {
    handleClick()

    if (!state.companyValue) {
      return errorSwal('Şirket seçmediniz!')
    }
    if (!period) {
      return errorSwal('Başlangıç tarihi seçmediniz!')
    }

    const StartDate = moment(`${period.getFullYear()}-1-1`).format(ISOFORMAT)
    const EndDate = moment(`${period.getFullYear()}`).endOf('year').format(ISOFORMAT)

    setSelectedMonthRange({ StartDate, EndDate, text: '' })

    const req: PeriodicReportRequest = {
      CompanyCode: Number(state.companyValue.value),
      StartDate,
      EndDate,
    }

    setLoading(true)
    Promise.all([GetPeriodicTurnover(req), GetPeriodicTurnoverSubProduct(req), GetPeriodicTurnoverSubBrand(req)]).then(
      (data: any) => {
        const [chart, products, brands] = data
        setState({ chart })
        setData({ products, brands })
        setLoading(false)
      }
    )
  }

  async function handleMonthSelection(month: number, text: string) {
    if (!state.companyValue) {
      return errorSwal('Şirket seçmediniz!')
    }

    const StartDate = moment(`${period.getFullYear()}-${month + 1}-1`).format(ISOFORMAT)
    const EndDate = moment(`${period.getFullYear()}-${month + 1}`)
      .endOf('month')
      .format(ISOFORMAT)

    setSelectedMonthRange({ StartDate, EndDate, text })

    const req: PeriodicReportRequest = {
      CompanyCode: Number(state.companyValue.value),
      StartDate,
      EndDate,
    }

    setLoading(true)
    Promise.all([GetPeriodicTurnoverSubProduct(req), GetPeriodicTurnoverSubBrand(req)]).then((data: any) => {
      const [products, brands] = data
      setData({ products, brands })
      setLoading(false)
    })
  }

  interface ProductIdList {
    first: number
    second: number
    third: number
  }

  const subProductFirst = async (req, idList: ProductIdList, Excel: boolean) => {
    let isFetched = false
    const products = data.products.map(x => {
      if (x.id === idList.first && x.fetched === true) {
        if (!Excel) {
          x.show = !x.show
        }
        isFetched = true
      }
      return x
    })
    if (isFetched) return setData(prev => ({ ...prev, products }))

    try {
      const res = await api.GetPeriodicTurnoverSubProductOne(req)

      if (!res) return errorSwal('Veri bulunamadi.')

      const subProducts = res.map((x, id) => ({ ...x, id: id + 1, show: false, fetched: false, subProducts: [] }))

      const products = data.products.map(x => {
        if (x.id === idList.first) {
          if (!Excel) {
            x.show = !x.show
          }

          if (x.fetched === false) {
            x.subProducts = subProducts
            x.fetched = true
          }
        }
        return x
      })

      setData(prev => ({ ...prev, products }))
    } catch (err: any) {
      errorSwal(err.description)
    }
  }

  const subProductSecond = async (req, idList: ProductIdList, Excel: boolean) => {
    let isFetched = false
    const products = data.products.map(one => {
      if (one.id === idList.first) {
        if (!one.subProducts) return one
        one.subProducts = one.subProducts.map(two => {
          if (two.id === idList.second && two.fetched === true) {
            if (!Excel) {
              two.show = !two.show
            }
            isFetched = true
          }
          return two
        })
      }
      return one
    })
    if (isFetched) return setData(prev => ({ ...prev, products }))

    try {
      const res = await api.GetPeriodicTurnoverSubProductSecond(req)

      if (!res) return errorSwal('Veri bulunamadi.')

      const subProducts = res.map((x, id) => ({ ...x, id: id + 1, show: false, fetched: false, subProducts: [] }))

      setData(prev => {
        const products = prev.products.map(one => {
          if (one.id === idList.first) {
            if (!one.subProducts) return one
            one.subProducts = one.subProducts.map(two => {
              if (two.id === idList.second) {
                if (!Excel) {
                  two.show = !two.show
                }
                if (two.fetched === false) {
                  two.subProducts = subProducts
                  two.fetched = true
                }
              }
              return two
            })
          }
          return one
        })
        return { ...prev, products }
      })
    } catch (err: any) {
      errorSwal(err.description)
    }
  }

  const subProductThird = async (req, idList: ProductIdList, Excel: boolean) => {
    let isFetched = false
    const products = data.products.map(one => {
      if (one.id === idList.first) {
        if (!one.subProducts) return one
        one.subProducts = one.subProducts.map(two => {
          if (two.id === idList.second) {
            if (!two.subProducts) return two
            two.subProducts = two.subProducts.map(three => {
              if (three.id === idList.second && three.fetched === true) {
                if (!Excel) {
                  three.show = !three.show
                }
                isFetched = true
              }
              return three
            })
          }
          return two
        })
      }
      return one
    })
    if (isFetched) return setData(prev => ({ ...prev, products }))

    try {
      const res = await api.GetPeriodicTurnoverSubProductThird(req)

      if (!res) return errorSwal('Veri bulunamadi.')

      const subProducts = res.map((x, id) => ({ ...x, id: id + 1, show: false, fetched: false, subProducts: [] }))

      setData(prev => {
        const products = prev.products.map(one => {
          if (one.id === idList.first) {
            one.subProducts = one.subProducts
              ? one.subProducts.map(two => {
                  if (two.id === idList.second) {
                    two.subProducts = two.subProducts
                      ? two.subProducts.map(three => {
                          if (three.id === idList.third) {
                            if (!Excel) {
                              three.show = !three.show
                            }
                            if (three.fetched === false) {
                              three.subProducts = subProducts
                              three.fetched = true
                            }
                          }
                          return three
                        })
                      : []
                  }
                  return two
                })
              : []
          }
          return one
        })
        return { ...prev, products }
      })
    } catch (err: any) {
      errorSwal(err.description)
    }
  }

  const subBrandOne = async (req, idList: ProductIdList, Excel: boolean) => {
    let isFetched = false

    const brands = data.brands.map(x => {
      if (x.id === idList.first && x.fetched === true) {
        if (!Excel) {
          x.show = !x.show
        }
        isFetched = true
      }
      return x
    })
    if (isFetched) return setData(prev => ({ ...prev, brands }))

    try {
      const res = await api.GetPeriodicTurnoverSubBrandOne(req)

      if (!res) return errorSwal('Veri bulunamadi.')

      const subBrands = res.map((x, id) => ({ ...x, id: id + 1, show: false, fetched: false, subBrands: [] }))

      setData(prev => {
        const brands = prev.brands.map(x => {
          if (x.id === idList.first) {
            if (!Excel) {
              x.show = !x.show
            }
            if (x.fetched === false) {
              x.subBrands = subBrands
              x.fetched = true
            }
          }
          return x
        })
        return { ...prev, brands }
      })
    } catch (err: any) {
      errorSwal(err.description)
    }
  }

  const handleService = async (CategoryCode: string, api, Excel: boolean, idList?: ProductIdList, p0?: () => void) => {
    if (!state.companyValue) {
      return Swal.fire('Hata', 'Şirket seçmediniz!', 'error')
    }

    if (!period) {
      return Swal.fire('Hata', 'Başlangıç tarihi seçmediniz!', 'error')
    }

    const req: PeriodicReportRequest = {
      CompanyCode: Number(state.companyValue.value),
      StartDate: selectedMonthRange.StartDate,
      EndDate: selectedMonthRange.EndDate,
      CategoryCode,
      Excel,
    }

    await api(req, idList, Excel)
  }

  const alignPricesRight = css`
    th:last-child,
    th:nth-last-child(2),
    td:last-child,
    td:nth-last-child(2) {
      text-align: right;
    }
  `

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    const reader = new FileReader()

    reader.onload = e => {
      const base64 = e.target?.result as string
      setBase64String(base64.split('base64,')[1])
    }

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  async function sendMail() {
    setLoading(true)
    const req: PeriodicTurnoverSendMailRequest = {
      Base64Data: base64String,
      MailAddress: state.mail,
    }
    try {
      const res = await api.PeriodicTurnoverSendMail(req)

      if (!res) return setLoading(false)

      setState({ showMailModal: false, mail: '' })
      setBase64String('')
      setLoading(false)
    } catch (err: any) {
      errorSwal(err.description)
      setLoading(false)
    }
  }

  return (
    <>
      <div className='Reports'>
        <div className='heading-text'>
          <h4>Dönemsel ve Ayrıntılı Ciro Raporu</h4>
        </div>
        <form>
          <div className='row align-items-bottom'>
            <div className='col-md-3 mb-3'>
              <div>Dönem</div>
              <IPDatePicker
                selected={period}
                // @ts-ignore
                onChange={period => setPeriod(period)}
                dateFormat={'yyyy'}
                minDate={new Date('2016')}
                maxDate={new Date()}
                showYearPicker
              />
            </div>

            <div className='col-md-3 mb-3'>
              <IPSelectBox
                isClearable
                value={state.companyValue}
                options={state.companies}
                onChangeSingle={option => setState({ companyValue: option })}
                placeholder={'Şirket Seçiniz...'}
              />
            </div>

            <div className='col-md-3'>
              <div
                className={`viewContainer ${showViews ? 'active' : ''}`}
                onMouseEnter={() => setShowViews(true)}
                onMouseLeave={() => setShowViews(false)}
              >
                <div className='viewButton'>
                  Grafik Görünümü:
                  <div className='viewIcon'>{view.icon}</div>
                </div>
                <nav className='viewDropdown'>
                  <div
                    onClick={() => {
                      setView({ view: View.Bar, icon: views.bar })
                    }}
                  >
                    <i className='fas fa-solid fa-chart-column'></i>
                  </div>
                  <div
                    onClick={() => {
                      setView({ view: View.Pie, icon: views.pie })
                    }}
                  >
                    <i className='fas fa-solid fa-chart-pie'></i>
                  </div>
                  <div
                    onClick={() => {
                      setView({ view: View.Area, icon: views.area })
                    }}
                  >
                    <i className='fas fa-solid fa-chart-area'></i>
                  </div>
                  <div
                    onClick={() => {
                      setView({ view: View.Line, icon: views.line })
                    }}
                  >
                    <i className='fas fa-solid fa-chart-line'></i>
                  </div>
                  {/* <div
                  onClick={() => {
                    setView({ view: View.Scatter, icon: views.scatter })
                  }}
                >
                  <img src='/images/scatter-plot-50.png' alt='' />
                </div> */}
                  <div
                    onClick={() => {
                      setView({ view: View.Doughnut, icon: views.doughnut })
                    }}
                  >
                    <img src='/images/doughnut-chart-50.png' alt='' />
                  </div>
                  <div
                    onClick={() => {
                      setView({ view: View.Gannt, icon: views.gannt })
                    }}
                  >
                    <img src='/images/gantt-chart.png' alt='' />
                  </div>
                  <div
                    onClick={() => {
                      setView({ view: View.Radar, icon: views.radar })
                    }}
                  >
                    <img src='/images/radar-plot-50.png' alt='' />
                  </div>
                </nav>
              </div>
            </div>

            <div className='col-md-2 m-b-10'>
              <IPButton text='Göster' style={{ height: 47 }} onClick={handleSubmit} disabled={isClicked} />
            </div>
          </div>

          <div className='periodic-graph mb-5'>{viewComponent}</div>

          <div id='printable'>
            {data.products.length > 0 && (
              <div className='periodic-report m-t-10 mt-lg-0'>
                <div className='d-flex justify-content-between'>
                  <b>Ürünler</b>
                  {selectedMonthRange.text && (
                    <div className='d-flex gap-1 align-items-center mb-2'>
                      <div style={{ fontSize: 16, fontStyle: 'italic', color: 'red' }}>
                        {selectedMonthRange.text} ayı verileri gösterilmektedir.
                      </div>
                      <div className='btn btn-dark btn-sm ml-2' onClick={handleSubmit}>
                        Tümünü Göster
                      </div>
                    </div>
                  )}
                </div>

                <table className={`table table-hover table-striped ${alignPricesRight}`}>
                  <thead>
                    <tr>
                      <th scope='col'>Kategori</th>
                      <th scope='col'>Miktar</th>
                      <th scope='col'>Tutar(TL)</th>
                      <th scope='col'>Tutar(USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.products.length > 0 &&
                      data.products.map(product => {
                        return (
                          <>
                            <tr>
                              <th
                                scope='row'
                                onClick={() => {
                                  handleService(product.KOD, subProductFirst, false, {
                                    first: product.id,
                                    second: 0,
                                    third: 0,
                                  })
                                }}
                                style={{ fontSize: 16, cursor: 'pointer' }}
                              >
                                {product.show ? '-' : '+'} {product.ACIKLAMA}
                              </th>
                              <td>{product.MIKTAR}</td>
                              <td>{formatAsCurrency(product.TUTARTL, 'TRY')}</td>
                              <td>{formatAsCurrency(product.TUTARUSD, 'USD')}</td>
                            </tr>
                            {product.show
                              ? product.subProducts &&
                                product.subProducts.length > 0 &&
                                product.subProducts.map(one => {
                                  return (
                                    <>
                                      <tr
                                        onClick={() => {
                                          handleService(one.KOD, subProductSecond, false, {
                                            first: product.id,
                                            second: one.id,
                                            third: 0,
                                          })
                                        }}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <td>
                                          <div style={{ paddingLeft: 20 }}>
                                            {one.show ? '-' : '+'}
                                            {one.ACIKLAMA}
                                          </div>
                                        </td>
                                        <td>{one.MIKTAR}</td>
                                        <td style={{ textAlign: 'right' }}>{formatAsCurrency(one.TUTARTL, 'TRY')}</td>
                                        <td style={{ textAlign: 'right' }}>{formatAsCurrency(one.TUTARUSD, 'USD')}</td>
                                      </tr>
                                      {one.subProducts &&
                                        one.show &&
                                        one.subProducts.length > 0 &&
                                        one.subProducts.map(two => {
                                          return (
                                            <>
                                              <tr
                                                onClick={() => {
                                                  handleService(two.KOD, subProductThird, false, {
                                                    first: product.id,
                                                    second: one.id,
                                                    third: two.id,
                                                  })
                                                }}
                                                style={{ cursor: 'pointer' }}
                                              >
                                                <td>
                                                  <div style={{ paddingLeft: 50 }}>
                                                    {two.show ? '-' : '+'}
                                                    {two.ACIKLAMA}
                                                  </div>
                                                </td>
                                                <td>{two.MIKTAR}</td>
                                                <td>{formatAsCurrency(two.TUTARTL, 'TRY')}</td>
                                                <td>{formatAsCurrency(two.TUTARUSD, 'USD')}</td>
                                              </tr>
                                              {two.subProducts &&
                                                two.show &&
                                                two.subProducts.length > 0 &&
                                                two.subProducts.map(three => {
                                                  return (
                                                    <tr>
                                                      <td>
                                                        <div style={{ paddingLeft: 100 }}>{three.Aciklama}</div>
                                                        <div style={{ paddingLeft: 100 }}>
                                                          Evrak No: {three.EvrakNo}
                                                        </div>
                                                      </td>
                                                      <td>{three.MIKTAR}</td>
                                                      <td>{formatAsCurrency(three.TUTARTL, 'TRY')}</td>
                                                      <td>{formatAsCurrency(three.TUTARUSD, 'USD')}</td>
                                                    </tr>
                                                  )
                                                })}
                                            </>
                                          )
                                        })}
                                    </>
                                  )
                                })
                              : null}
                          </>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            )}

            {data.brands.length > 0 && (
              <div className='periodic-report m-t-10 mt-lg-0'>
                <div className='d-flex justify-content-between'>
                  <b>Markalar</b>
                </div>

                <table className={`table table-hover table-striped ${alignPricesRight}`}>
                  <thead>
                    <tr>
                      <th scope='col'>Kategori</th>
                      <th scope='col'>Miktar</th>
                      <th scope='col'>Tutar(TL)</th>
                      <th scope='col'>Tutar(USD)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.brands.length > 0 &&
                      data.brands.map(brand => {
                        return (
                          <>
                            <tr>
                              <th
                                scope='row'
                                onClick={() => {
                                  handleService(brand.KOD, subBrandOne, false, {
                                    first: brand.id,
                                    second: 0,
                                    third: 0,
                                  })
                                }}
                                style={{ fontSize: 16, cursor: 'pointer' }}
                              >
                                {brand.show ? '-' : '+'} {brand.ACIKLAMA}
                              </th>
                              <td>{brand.MIKTAR}</td>
                              <td>{formatAsCurrency(brand.TUTARTL, 'TRY')}</td>
                              <td>{formatAsCurrency(brand.TUTARUSD, 'USD')}</td>
                            </tr>
                            {brand.show
                              ? brand.subBrands &&
                                brand.subBrands.length > 0 &&
                                brand.subBrands.map(one => {
                                  return (
                                    <>
                                      <tr>
                                        <td>
                                          <div style={{ paddingLeft: 20 }}>{one.Aciklama}</div>
                                        </td>
                                        <td>{one.MIKTAR}</td>
                                        <td>{formatAsCurrency(one.TUTARTL, 'TRY')}</td>
                                        <td>{formatAsCurrency(one.TUTARUSD, 'USD')}</td>
                                      </tr>
                                    </>
                                  )
                                })
                              : null}
                          </>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          <div className='myOrderActions m-t-25 m-b-15 justify-content-center'>
            <IPExcelButton
              onClick={async () => {
                // Handle brands
                props.showLoading()
                await Promise.all(
                  data.brands.map(async brand => {
                    await handleService(brand.KOD, subBrandOne, true, {
                      first: brand.id,
                      second: 0,
                      third: 0,
                    })
                  })
                )

                for (const product of data.products) {
                  await handleService(product.KOD, subProductFirst, true, {
                    first: product.id,
                    second: 0,
                    third: 0,
                  })

                  if (product.subProducts) {
                    for (const one of product.subProducts) {
                      await handleService(one.KOD, subProductSecond, true, {
                        first: product.id,
                        second: one.id,
                        third: 0,
                      })

                      if (one.subProducts) {
                        for (const two of one.subProducts) {
                          await handleService(two.KOD, subProductThird, true, {
                            first: product.id,
                            second: one.id,
                            third: two.id,
                          })
                        }
                      }
                    }
                  }
                }

                props.hideLoading()
                exportToExcel()
              }}
              width={40}
              height={40}
            />

            <IPPrintButton
              onClick={async () => {
                props.showLoading()

                const initialBrands = JSON.parse(JSON.stringify(data.brands))
                const initialProducts = JSON.parse(JSON.stringify(data.products))

                await Promise.all(
                  data.brands.map(async brand => {
                    await handleService(brand.KOD, subBrandOne, false, {
                      first: brand.id,
                      second: 0,
                      third: 0,
                    })
                  })
                )

                for (const product of data.products) {
                  await handleService(product.KOD, subProductFirst, false, {
                    first: product.id,
                    second: 0,
                    third: 0,
                  })

                  if (product.subProducts) {
                    for (const one of product.subProducts) {
                      await handleService(one.KOD, subProductSecond, false, {
                        first: product.id,
                        second: one.id,
                        third: 0,
                      })

                      if (one.subProducts) {
                        for (const two of one.subProducts) {
                          await handleService(two.KOD, subProductThird, false, {
                            first: product.id,
                            second: one.id,
                            third: two.id,
                          })
                        }
                      }
                    }
                  }
                }

                printElementPeriodicReport(document.getElementById('printable'))
                // Reset the show state after printing
                setData(prev => ({ ...prev, brands: initialBrands, products: initialProducts }))
                props.hideLoading()
              }}
              width={40}
              height={40}
            />
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setState({ showMailModal: true })
              }}
              className='print-button ms-2 d-flex justify-content-center align-items-center'
            >
              <img
                style={{ marginTop: '-5px' }}
                src='/images/icons/epostadetay.svg'
                alt=''
                width={40}
                height={40}
                title='Mail Gönder'
              />
              Mail Gönder
            </a>
          </div>
        </form>
      </div>
      <IPModal
        show={state.showMailModal}
        onClose={() => setState({ showMailModal: false })}
        onConfirm={sendMail}
        confirmText='Mail Gönder'
        width='auto'
        maxWidth={1440}
      >
        <div className='m-5 p-5'>
          <IPInput
            type={'text'}
            placeholder='Mail'
            value={state.mail}
            onChange={e => setState({ mail: e.target.value })}
            className='mb-3'
          />
          <input type='file' id='fileInput' onChange={handleFileChange} style={{ display: 'none' }} />
          <label
            htmlFor='fileInput'
            className={css`
              padding: 10px;
              background-color: #f2f2f2;
              color: #555;
              border: 1px solid #ccc;
              cursor: pointer;
              border-radius: 4px;

              &:hover {
                background-color: #e0e0e0;
              }
            `}
          >
            {base64String ? (
              <>
                Excel Yüklendi{' '}
                <span
                  onClick={e => {
                    e.stopPropagation()
                    setBase64String('')
                  }}
                  className={css`display: inline-flex; align-items: center; justify-content: center; font-size: 14px: border: 1px solid #666; padding: 0.5rem`}
                >
                  x
                </span>
              </>
            ) : (
              'Excel Yükle'
            )}
          </label>
        </div>
      </IPModal>
      <Spinner loading={loading} />
    </>
  )
}

export default componentWithContext(Reports)
