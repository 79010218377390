import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppThunk } from '../../app/store'
import {
  Basket,
  addItemToBasketApi,
  removeItemFromBasketApi,
  ClearBasketApi,
  SwitchBasketApi,
  ISetBasketManagementByIdsRequest,
  ISwitchBasketRequest,
  SetBasketManageByBasketIdsApi,
  getBasketApi,
  reduceProductQuantityOnBasketApi,
  ISingleCampaignReplaceRequest,
  ChangeGiftItemOnBasketApi,
  GetCampaignListOnBasketApi,
  GetApplyCampaignOnBasketApi,
  ISimpleCampaign,
  ISimpleBasket,
  increaseProductQuantityOnBasketApi,
  addListToBasketUIViaBundle,
  addListToBasketUIMulti,
} from './basketApi'
import {
  IAddItemToBasketRequest,
  GetBasketService,
  IReduceProductQuantityOnBasketRequest,
  IIncreaseProductQuantityOnBasketRequest,
} from './basketService'
import { IAddListToBasketUI } from '../../../pages/ProductList/models/commonModels'
import { ClientStorage } from '../../../base/storage'

interface IBasketAddError {
  description?: string
  isProductAdded?: boolean
}

export interface BasketState {
  basket: Basket
  basketCount: number
  campaignList: ISimpleCampaign[]
  selectedCampaign: number
  basketApiCallStatus: 'idle' | 'loading' | 'failed'
  addBasketError: IBasketAddError | null
}

const initialState: BasketState = {
  basket: Object.assign({}),
  basketCount: 0,
  campaignList: [],
  selectedCampaign: 0,
  basketApiCallStatus: 'idle',
  addBasketError: null,
}

export const getFreshBasketRedux = createAsyncThunk('basket/getFreshBasket', async () => {
  if (ClientStorage.getItem('IsApple') != 1) {
    const response = await getBasketApi()

    return response
  }
  else
    return null as unknown as Basket

})

export const addItemToBasketRedux = createAsyncThunk(
  'basket/addItemToBasket',
  async (request: IAddItemToBasketRequest) => {
    try {
      const response = await addItemToBasketApi(request)
      return response
    } catch (error) {
      return error as any
    }
  }
)

export const addListToBasketUIViaBundleRedux = createAsyncThunk(
  'basket2/addListToBasketUIViaBundle',
  async (request: number) => {
    try {
      const response = await addListToBasketUIViaBundle(request)
      return response
    } catch (error) {
      return error as any
    }
  }
)

export const addListToBasketUIMultiRedux = createAsyncThunk(
  'basket2/addListToBasketUI',
  async (request: IAddListToBasketUI[]) => {
    try {
      const response = await addListToBasketUIMulti(request)
      return response
    } catch (error) {
      return error as any
    }
  }
)

export const removeItemFromBasketRedux = createAsyncThunk(
  'basket/removeItemFromBasket',
  async (basketItemId: number) => {
    const response = await removeItemFromBasketApi(basketItemId)

    return response
  }
)

export const reduceProductQuantityOnBasketRedux = createAsyncThunk(
  'basket/reduceProductQuantityOnBasket',
  async (request: IReduceProductQuantityOnBasketRequest) => {
    const response = await reduceProductQuantityOnBasketApi(request)

    return response
  }
)

export const increaseProductQuantityOnBasketRedux = createAsyncThunk(
  'basket/increaseProductQuantityOnBasket',
  async (request: IIncreaseProductQuantityOnBasketRequest) => {
    const response = await increaseProductQuantityOnBasketApi(request)

    return response
  }
)

export const clearBasketRedux = createAsyncThunk('basket/clearBasket', async (basketId: number) => {
  const response = await ClearBasketApi(basketId)

  return response
})

export const switchBasketRedux = createAsyncThunk('basket/switchBasket', async (request: ISwitchBasketRequest) => {
  const response = await SwitchBasketApi(request)

  return response
})

export const copyMoveOrDeleteFromBasketRedux = createAsyncThunk(
  'basket/copyMoveOrDeleteFromBasket',
  async (request: ISetBasketManagementByIdsRequest) => {
    const response = await SetBasketManageByBasketIdsApi(request)

    return response
  }
)

export const changeGiftItemOnBasketRedux = createAsyncThunk(
  'basket/changeGiftItemOnBasket',
  async (request: ISingleCampaignReplaceRequest) => {
    const response = await ChangeGiftItemOnBasketApi(request)

    return response
  }
)

export const getCampaignListOnBasketRedux = createAsyncThunk('basket/getCampaignListOnBasket', async () => {
  const response = await GetCampaignListOnBasketApi()

  return response
})

// export const getApplyCampaignOnBasketRedux = createAsyncThunk('basket/getApplyCampaignOnBasket', async (id: number) => {
//   const response = await GetApplyCampaignOnBasketApi(id)

//   return response
// })
export const getApplyCampaignOnBasketRedux = createAsyncThunk('basket/getApplyCampaignOnBasket', async (data: any) => {
  const response = await GetApplyCampaignOnBasketApi(data)

  return response
})
export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    incrementBasketCount: state => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.basketCount += 1
    },
    setBasket: (state, action: PayloadAction<ISimpleBasket>) => {
      state.basket.ActiveBasket = action.payload
    },
    clearAddBasketError: state => {
      state.addBasketError = null
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: builder => {
    builder
      .addCase(getFreshBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(getFreshBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.basket = action.payload
        state.basketCount = action.payload && action.payload.ActiveBasket ? action.payload.ActiveBasket.BasketCount : 0
      })
      .addCase(getFreshBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        state.basket = Object.assign({})
        state.basketCount = 0
      })
      .addCase(addItemToBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(addItemToBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'

        if (action.payload.description) {
          state.addBasketError = Object.assign({ description: action.payload.description, isProductAdded: false })
        } else {
          state.addBasketError = Object.assign({ isProductAdded: true })
          state.basket.ActiveBasket = action.payload
          state.basketCount = action.payload ? action.payload.BasketCount : 0
        }
      })
      .addCase(addItemToBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(removeItemFromBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(removeItemFromBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.basket.ActiveBasket = action.payload
        state.basketCount = action.payload ? action.payload.BasketCount : 0
      })
      .addCase(removeItemFromBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(clearBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(clearBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.basket = action.payload
        state.basketCount = action.payload && action.payload.ActiveBasket ? action.payload.ActiveBasket.BasketCount : 0
      })
      .addCase(clearBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(reduceProductQuantityOnBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(reduceProductQuantityOnBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.basket.ActiveBasket = action.payload
        state.basketCount = action.payload && action.payload.BasketCount
      })
      .addCase(reduceProductQuantityOnBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(increaseProductQuantityOnBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(increaseProductQuantityOnBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.basket.ActiveBasket = action.payload
        state.basketCount = action.payload ? action.payload.BasketCount : 0
      })
      .addCase(increaseProductQuantityOnBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(switchBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(switchBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.basket.ActiveBasket = action.payload?.BasketUI
        state.basketCount = action.payload?.BasketUI ? action.payload.BasketUI.BasketCount : 0
      })
      .addCase(switchBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(copyMoveOrDeleteFromBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(copyMoveOrDeleteFromBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.basket.ActiveBasket = action.payload.ActiveBasketUI
        state.basketCount =
          action.payload && action.payload.ActiveBasketUI ? action.payload.ActiveBasketUI.BasketCount : 0
      })
      .addCase(copyMoveOrDeleteFromBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(changeGiftItemOnBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(changeGiftItemOnBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.basket.ActiveBasket = action.payload
        state.basketCount = action.payload ? action.payload.BasketCount : 0
      })
      .addCase(changeGiftItemOnBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(getCampaignListOnBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(getCampaignListOnBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.campaignList = action.payload

        if (action.payload) {
          const activeCampaign = action.payload.find((item: ISimpleCampaign) => item.IsActive)

          state.selectedCampaign = activeCampaign ? activeCampaign.Id : 0
        }
      })
      .addCase(getCampaignListOnBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        state.campaignList = []
      })
      .addCase(getApplyCampaignOnBasketRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(getApplyCampaignOnBasketRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'
        state.basket.ActiveBasket = action.payload
        state.basketCount = action.payload ? action.payload.BasketCount : 0

        const activeCampaign = action.payload.AvailableCampModels.find((item: ISimpleCampaign) => item.IsActive)

        state.selectedCampaign = activeCampaign ? activeCampaign.Id : -1
      })
      .addCase(getApplyCampaignOnBasketRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(addListToBasketUIViaBundleRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(addListToBasketUIViaBundleRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'

        if (action.payload.description) {
          state.addBasketError = Object.assign({ description: action.payload.description, isProductAdded: false })
        } else {
          state.addBasketError = Object.assign({ isProductAdded: true })
          state.basket.ActiveBasket = action.payload
          state.basketCount = action.payload ? action.payload.BasketCount : 0
        }
      })
      .addCase(addListToBasketUIViaBundleRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
      .addCase(addListToBasketUIMultiRedux.pending, state => {
        state.basketApiCallStatus = 'loading'
      })
      .addCase(addListToBasketUIMultiRedux.fulfilled, (state, action) => {
        state.basketApiCallStatus = 'idle'

        if (action.payload.description) {
          state.addBasketError = Object.assign({ description: action.payload.description, isProductAdded: false })
        } else {
          state.addBasketError = Object.assign({ isProductAdded: true })
          state.basket.ActiveBasket = action.payload
          state.basketCount = action.payload ? action.payload.BasketCount : 0
        }
      })
      .addCase(addListToBasketUIMultiRedux.rejected, (state, action) => {
        state.basketApiCallStatus = 'failed'
        // state.basket = Object.assign({});
        // state.basketCount = 0;
      })
  },
})

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const basketApiCallStatusRedux = (state: RootState) => state.basket.basketApiCallStatus
export const getBasketCountRedux = (state: RootState) => state.basket.basketCount
export const getBasketRedux = (state: RootState) => state.basket.basket
export const basketCampaignListRedux = (state: RootState) => state.basket.campaignList
export const basketSelectedCampaignRedux = (state: RootState) => state.basket.selectedCampaign
export const addBasketError = (state: RootState) => state.basket.addBasketError
export const setBasketRedux = basketSlice.actions.setBasket
export const clearBasketError = basketSlice.actions.clearAddBasketError

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (
//   dispatch,
//   getState
// ) => {
//   const currentValue = selectCount(getState());
//   if (currentValue % 2 === 1) {
//     dispatch(incrementByAmount(amount));
//   }
// };

export default basketSlice.reducer
