import React, { useEffect } from 'react'
import FullCalendar, { CalendarApi, DateSelectArg, EventApi, EventClickArg, EventContentArg } from '@fullcalendar/react'
import dayGridPlugin, { DayGridView } from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import { useState } from 'react'
import { Calendar } from '@fullcalendar/core'
import { GetAllEvents } from '../../Admin/HomeAndSiteOperation/WebinarCalander/services/WebinarCalanderAdminService'
import { uuidv4 } from '../../../base/utils/dataHelper'
import IPModal from '../../../components/IPModal'
import './webinarCalander.scss'

let id = 0
export default function Index() {
  const [events, setEvents] = useState<Array<EventApi>>([])
  const [webinarData, setWebinarData] = useState<any[]>()
  const [modalControl, setModalControl] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<any>({})
  const [getImage, setGetImage] = useState()

  const getEvents = async () => {
    const getWebinarCalander = new GetAllEvents({})
    const response = await getWebinarCalander.getAllEvents()
    if (response) {
      return response
    }
  }

  const initialEventsData = res => {
    setWebinarData(
      res
        ? res.map(item => {
          return {
            id: item.Id,
            title: item.Title,
            description: item.Description,
            meetLink: item.MeetLink,
            meetRegisterLink: item.MeetRegisterLink,
            allDay: item.AllDay,
            start: new Date(item.StartDate).toISOString(),
            end: new Date(item.EndDate).toISOString(),
            presenters: item.Presenters,
            eventImage: item.EventImage,
            eventImageUrl: item.EventImageUrl
          }
        })
        : []
    )
  }
  useEffect(() => {
    getEvents().then(res => {
      initialEventsData(res)
    })
  }, [])

  const renderEventContent = (eventContent: EventContentArg) => {
    return (
      <>
        {/* <div>Time Text</div> */}
        <div className='d-block' style={{ color: 'white', width: '100%' }}>
          <b> {eventContent.event.title} </b>
        </div>
        <div className='d-block' style={{ color: 'white', fontSize: '12px' }}>
          {' '}
          {eventContent.event._def.extendedProps.description}{' '}
        </div>
      </>
    )
  }

  const handleEventClick = (clickInfo: EventClickArg) => {
    let clickedInfo
    webinarData?.forEach(item => {
      if (item.id == clickInfo.event._def.publicId) clickedInfo = item
    })
    setSelectedEvent(clickedInfo)
    setGetImage(clickInfo.event._def.extendedProps.EventImageUrl)
    setModalControl(true)
  }
  const closeDocumentModal = () => {
    setModalControl(false)
  }
  return (
    <div className='container-fluid mb-3 webinarCalanderMySpecial'>
      <div className='row mb-3'>
        <div className='col-lg-12'>
          {webinarData && (
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth timeGridWeek timeGridDay',
              }}
              //eventsSet={handleEvents}
              //select={handleDateSelect}
              eventClick={handleEventClick}
              eventContent={renderEventContent}
              initialEvents={webinarData}
              locales={allLocales}
              locale={'tr'}
              firstDay={1}
              initialView='dayGridMonth'
              selectable={false}
              editable={false}
              buttonText={{
                day: 'Gün',
                month: 'Ay',
                today: 'Bugün',
                week: 'Hafta',
              }}
              eventBackgroundColor={'#ec4c4c'}
              eventBorderColor={'#ee333e'}
            />
          )}
        </div>
      </div>
      <IPModal show={modalControl} width={1200} hideConfirm={true} onClose={closeDocumentModal} closeOnEscapeKey={true}>
        <>
          <div className='text-center fs-1 font-weight-600 m-15'>{selectedEvent?.title}</div>
          <div className='d-flex justify-content-between px-3'>
            <div>
              <span className='font-weight-600 fs-4'>Tarih : </span>
              {new Date(selectedEvent?.start).toLocaleDateString('tr-TR').replace(/T.*$/, '')}
            </div>
            <div>
              <span className='font-weight-600 fs-4'>Saat : </span>
              {selectedEvent &&
                new Date(selectedEvent?.start)
                  .toLocaleDateString('tr-TR', {
                    hour: '2-digit',
                    minute: '2-digit',
                  })
                  .slice(10)}
            </div>
          </div>
          <div className='w-100 p-0 px-2 my-3'>
            {getImage && (
              <div className='col-12 mb-4  w-100'>
                <img key={uuidv4()} src={getImage} style={{ maxWidth: '100%' }} />
              </div>
            )}
          </div>
          <div className='w-100 px-3 my-3'>
            <div className='fs-4 font-weight-600'>Açıklama</div>
            <div>{selectedEvent?.description}</div>
          </div>
          <div className='px-3'>
            {selectedEvent.presenters &&
              selectedEvent.presenters?.map(item => {
                return (
                  <div className='d-flex border border-1 p-3 my-3 gap-4'>
                    <div className='w-25 border-right '>{item.NameSurname}</div>
                    <div className='w-75 '>{item.Description}</div>
                  </div>
                )
              })}
          </div>
          <div className='w-100 px-3 my-3'>
            <div className='fs-4 font-weight-600 mb-2'>Bağlantı Linkleri</div>
            <div className='d-flex'>
              <div className='w-25'>Toplantı Linki </div>
              <a href={selectedEvent?.meetLink} target='_blank' className='w-75 '>
                {selectedEvent?.meetLink}
              </a>
            </div>
            <div className='d-flex'>
              <div className='w-25'>Toplantı Kayıt Linki </div>
              <a href={selectedEvent?.meetRegisterLink} target='_blank' className='w-75 '>
                {selectedEvent?.meetRegisterLink}
              </a>
            </div>
            {
              selectedEvent?.eventImage && (
                <div className='d-flex'>
                  <a href={selectedEvent?.eventImageUrl} target='_blank' className='w-75'>
                    <img src={selectedEvent?.eventImageUrl} alt="Event" />
                  </a>
                </div>
              )
            }
          </div>
        </>
      </IPModal>
    </div>
  )
}
