import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IOrderDetail } from '../models/models'
import { OrderExcelResponse } from '../models/orderExcel'

export class PostOrderExcelApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'excel/OrderExcel',
      requesterInfo,
    })
  }

  public async postOrderExcel(request: IOrderDetail): Promise<OrderExcelResponse> {
    return await this.postAsync<OrderExcelResponse>(request)
  }
}
