import { css } from '@emotion/css'
import React, { CSSProperties, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import IPButton from '../IPButton'
import './IPModal.scss'
import { Link } from 'react-router-dom'

interface IPModalProps {
  show: boolean
  onClose?: () => void
  onConfirm?: () => void
  confirmText?: string
  hideConfirm?: boolean
  children: JSX.Element
  buttons?: JSX.Element
  checkbox?: JSX.Element
  title?: React.ReactNode
  closeOnEscapeKey?: boolean
  style?: CSSProperties
  width?: Number | 'auto'
  maxWidth?: Number
  link?: string
}

const IPModal = (props: IPModalProps) => {
  const closeOnEscapeKeyDown = (e: KeyboardEvent) => {
    if (props.closeOnEscapeKey) {
      const key = e.key || e.keyCode

      if (key === 'Escape' || key === 'Esc' || key === 27) {
        props.onClose && props.onClose()
      }
    }
  }

  useEffect(() => {
    props.closeOnEscapeKey && document.body.addEventListener('keydown', closeOnEscapeKeyDown)

    return function cleanup() {
      props.closeOnEscapeKey && document.body.removeEventListener('keydown', closeOnEscapeKeyDown)
    }
  }, [])

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
      // classNames={{
      //   appear: 'fade-in',
      //   appearActive: 'fade-in-active',
      //   appearDone: 'fade-in-appeared',
      //   enter: 'fade-in-enter',
      //   enterActive: 'fade-in-enter-active',
      //   enterDone: 'fade-in-done',
      //   exit: 'fade-out',
      //   exitActive: 'fade-out-active',
      //   exitDone: 'fade-out-active',
      // }}
    >
      <div className='modal' style={props.style}>
        <div
          className={`modal-content ${css`
            ${props.width && 'width:' + props.width}${typeof props.width === 'number' && 'px'};
            ${props.maxWidth && 'max-width:' + props.maxWidth}px;
          `}`}
          onClick={e => e.stopPropagation()}
        >
          {props.title && (
            <div className='modal-header'>
              <h4 className='modal-title'>{props.title}</h4>
            </div>
          )}
          <div className='modal-body'>{props.children}</div>
          <div className='modal-footer'>
            {props.checkbox ? (
              <div
                style={{
                  display: 'flex',
                  flex: 'auto',
                }}
              >
                {props.checkbox}
              </div>
            ) : (
              ''
            )}
            <IPButton
              text='Kapat'
              style={{ width: 'auto', padding: '15px 20px' }}
              className='modal-button'
              onClick={props.onClose}
            />
            {props.link && props.link.length > 0
              ? props.hideConfirm
                ? props.hideConfirm
                : true && (
                    <Link to={props.link}>
                      <IPButton
                        text={props.confirmText ? props.confirmText : 'Siparişe Kaydet'}
                        style={{ width: 'auto', padding: '15px 20px' }}
                        className='modal-button'
                        // onClick={props.onConfirm}
                      />
                    </Link>
                  )
              : props.hideConfirm
              ? props.hideConfirm
              : true && (
                  <IPButton
                    text={props.confirmText ? props.confirmText : 'Siparişe Kaydet'}
                    style={{ width: 'auto', padding: '15px 20px' }}
                    className='modal-button'
                    onClick={props.onConfirm}
                  />
                )}
            {props.buttons ? props.buttons : ''}
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById('ip-modal')!
  )
}

export default IPModal
