import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { ICompany } from '../../../../ProductList/models/commonModels'
import { IFrequentlyAskedQuestions } from '../models/models'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { GetFrequentlyAskedQuestionsApi } from '../services/getFrequentlyAskedQuestionsApi'
import Swal from 'sweetalert2'
import { DeleteFrequentlyAskedQuestionsApi } from '../services/deleteFrequentlyAskedQuestionsApi'
import { getCompanyApi } from '../../../components/api'
import { css } from '@emotion/css'
import Table from '../../../../../components/Table'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPSelectBox from '../../../../../components/IPSelectBox/IPSelectBoxMenu'

function List(props: BaseComponentWithContextProps) {
  const [searchParams, setSearchParams] = useSearchParams()

  const sssplace = searchParams.get('Yer')
  const sssstatus = searchParams.get('Durum')
  const places = [
    { label: 'Finansal', value: 'Finansal' },
    { label: 'SSH', value: 'SSH' },
    { label: 'Pazar', value: 'Pazar' },
  ]

  const statusOption = [
    { label: 'Aktif', value: 1 },
    { label: 'Pasif', value: 0 },
  ]
  const navigate: any = useNavigate()
  const [frequentlyAskedQuestions, setFrequentlyAskedQuestions] = useState<IFrequentlyAskedQuestions[]>([])
  const [frequentlyAskedQuestionsFilter, setFrequentlyAskedQuestionsFilter] = useState<IFrequentlyAskedQuestions[]>([])
  const [selectedPlace, setSelectedPlace] = useState<any>(places.find(a => a.label === sssplace))
  const [selectedStatus, setSelectedStatus] = useState<any>(statusOption.find(a => a.label === sssstatus))

  useEffect(() => {
    getFrequentlyAskedQuestions()
  }, [])

  const getFrequentlyAskedQuestions = async () => {
    props.showLoading()
    const getFrequentlyAskedQuestionsApi = new GetFrequentlyAskedQuestionsApi({})

    const frequentlyAskedQuestionsApi = await getFrequentlyAskedQuestionsApi.getFrequentlyAskedQuestions()
    setFrequentlyAskedQuestions(frequentlyAskedQuestionsApi as any)
    setFrequentlyAskedQuestionsFilter(frequentlyAskedQuestionsApi as any)

    props.hideLoading()
  }

  const deleteFrequentlyAskedQuestions = async (id: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Soruyu silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteFrequentlyAskedQuestionsApi = new DeleteFrequentlyAskedQuestionsApi({})
        const isDeleted = await deleteFrequentlyAskedQuestionsApi.deleteFrequentlyAskedQuestions(id)
        if (isDeleted) {
          getFrequentlyAskedQuestions()
          Swal.fire({
            icon: 'success',
            title: 'Soru Silindi',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    })
  }

  const tableColumns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'col1',
      },
      {
        Header: 'Soru',
        accessor: 'question',
      },
      {
        Header: 'Cevap',
        accessor: 'answer',
      },
      {
        Header: 'Yer',
        accessor: 'place',
      },
      {
        Header: 'Sıra',
        accessor: 'order',
      },
      {
        Header: 'Dosya/Resim',
        accessor: 'file',
      },
      {
        Header: 'Durum',
        accessor: 'status',
      },
      {
        Header: 'İşlemler',
        accessor: 'col6',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      frequentlyAskedQuestionsFilter.map((item, index) => {

        const data = {
          col1: index + 1,
          question: item.Question,
          answer: item.Answer,
          place: item.Place,
          order: item.Order,
          file: item.FileUrl ? <a href={item.FileUrl} target='_blank' title={item.FileUrl}><span style={{ color: 'green', cursor: 'pointer' }}>Var</span></a> : <span style={{ color: 'red' }}>Yok</span>,
          status: item.Enabled ? 'Aktif' : 'Pasif',
          col6: (
            <div>
              <a
                data-action-type='edit'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={'düzenle'}
                onClick={() => {
                  navigate('/admin/settings/frequentlyAskedQuestions/add', {
                    state: { data: item, flag: 'update', url: window.location.href },
                  })
                }}
              >
                <i className='icon-edit' style={{ fontSize: '1.2rem', color: 'green' }}></i>
              </a>
              <a
                data-action-type='delete'
                role='button'
                style={{ cursor: 'pointer' }}
                data-toggle='tooltip'
                data-original-title={'Sil'}
                onClick={() => deleteFrequentlyAskedQuestions(item.Id)}
              >
                <i className='icon-trash-2' style={{ fontSize: '1.2rem', color: 'red' }}></i>
              </a>
            </div>
          ),
        }

        return data
      }),
    [frequentlyAskedQuestionsFilter]
  )


  useEffect(() => {
    if (!selectedPlace && selectedStatus) {
      var enableData = selectedStatus.value === 1 ? true : false
      setFrequentlyAskedQuestionsFilter(frequentlyAskedQuestions.filter(a => a.Enabled == enableData))
    }
    else if (selectedPlace && !selectedStatus) {
      setFrequentlyAskedQuestionsFilter(frequentlyAskedQuestions.filter(a => a.Place.includes(selectedPlace.value)))
    }
    else if (!selectedPlace && !selectedStatus)
      setFrequentlyAskedQuestionsFilter(frequentlyAskedQuestions)
    else if (selectedPlace && selectedStatus) {
      var enableData = selectedStatus.value === 1 ? true : false
      setFrequentlyAskedQuestionsFilter(frequentlyAskedQuestions.filter(a => a.Place.includes(selectedPlace.value) && a.Enabled == enableData))
    }

    const queryParams = {
      Yer: (selectedPlace !== "" && selectedPlace != null) ? selectedPlace.value : undefined,
      Durum: selectedStatus ? statusOption.find(a => a.value == Number(selectedStatus?.value))?.label : undefined,
    };

    const filteredQueryParams = Object.entries(queryParams)
      .filter(([key, value]) => value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

    const navigateRoute = `/admin/settings/frequentlyAskedQuestions?${filteredQueryParams}`;
    navigate(navigateRoute)


  }, [selectedPlace, selectedStatus, frequentlyAskedQuestions])

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <div className='row'>
              <div className='mb-3'>
                <span className='h4'>Sık Sorulan Sorular</span>
                <Link to='/admin/settings/frequentlyAskedQuestions/add'>
                  <button type='button' className='btn btn-primary' style={{ float: 'right' }}>
                    Soru Ekle
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className='row  mb-5'>
              <div className='col-lg-3'>
                <label htmlFor='place' className='form-label'>
                  Yer
                </label>
                <IPSelectBox
                  isClearable
                  id='Place'
                  name='Place'
                  options={places}
                  value={selectedPlace}
                  onChangeSingle={(option) => setSelectedPlace(option)}
                />
              </div>
              <div className='col-lg-3'>
                <label htmlFor='place' className='form-label'>
                  Durum
                </label>
                <IPSelectBox
                  isClearable
                  id='Status'
                  name='Status'
                  options={statusOption}
                  value={selectedStatus}
                  onChangeSingle={(option) => setSelectedStatus(option)}
                />
              </div>
            </div>
            <div
              className={`row ${css`
                table {
                  min-width: 992px;
                }
                #datatb_length label {
                  min-width: 200px;
                }
              `}`}
            >
              <div className='table-responsive'>
                <Table columns={tableColumns} data={tableData} currentPage={0} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(List)