import React, { useEffect } from 'react'
import IPButton from '../../../components/IPButton'
import { useCustomReducer } from '../../../base/customHooks'
import IPImage from '../../../components/IPImage'
import { IBank, ICreditCardCommissionRates, IGetBankInstallmentsResponse, IInstallments, IParampos, IParamposList } from '../models/models'
import { GetBankInstallmentsApi } from '../services/getBankInstallmentsApi'
import data from '../../../api/creditCardCommissionRates.json'
import { GetCompaniesApi } from '../../MySpecial/CurrentAccount/Extract/services/getCompaniesApi'
import { ICompany } from '../../ProductList/models/commonModels'
import IPSelectBox from '../../../components/IPSelectBox'
import { ISelectOption } from '../../../components/IPSelectBox/IPSelectBox'
import { financialButtonStyles } from '../FinancialInformationLayout'
import { exportToXLSX } from '../../../base/utils/dataHelper'
import Swal from 'sweetalert2'
import { GetBankListApi } from '../services/getBankList'
import { ListParamPosInsatallmentApi } from '../services/listParamPosInsatallmentApi'

enum operationType {
  Cart = 1,
  Payments = 2,
}

interface ICreditCardComissionRatesState {
  operation: number
  bankInstallmentsData?: IGetBankInstallmentsResponse
  creditCardCommissionRates: ICreditCardCommissionRates[]
  companies: ICompany[]
  companyOption?: ISelectOption
  typeOption?: ISelectOption
  installment: IInstallments[]
  banks: ISelectOption[]
  bankOption?: ISelectOption
  campaigns: ISelectOption[]
  campaignsOption?: ISelectOption
  paramposBool: boolean
}

export default function CreditCardComissionRates2() {
  const getInitialState = (): ICreditCardComissionRatesState => {
    return {
      operation: operationType.Cart,
      creditCardCommissionRates: data.bank,
      companies: [],
      installment: [],
      banks: [],
      campaigns: [],
      paramposBool: false
    }
  }

  const types = [
    { label: 'Avantaj POS', value: '0' },
    { label: 'Fırsat POS', value: '1' },
    { label: 'FÇV', value: '2' },
    { label: 'Cari', value: '3' },
  ]

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<ICreditCardComissionRatesState>(intialState)

  useEffect(() => {
    if (state.companies.length > 0) getBankInstallments()
  }, [state.companies, state.operation])
  useEffect(() => {
    getCompanies()
  }, [])

  const getBankInstallments = async () => {
    const bankInstallments: GetBankInstallmentsApi = new GetBankInstallmentsApi({})
    bankInstallments.getBankInstallments().then((res: IGetBankInstallmentsResponse) => {
      if (res) {
        const data: IInstallments[] = []
        res.PaymentInstallments.map(payment => {
          payment.Installments.map(ins => {
            return data.push({
              BankId: ins.BankId,
              BankName: ins.BankName,
              Id: ins.Id,
              Installment: ins.Installment,
              InterestRate: ins.InterestRate,
              PaymentTermsId: ins.PaymentTermsId,
              PaymentTermsName: ins.PaymentTermsName,
              Type: ins.Type,
              Company: payment.Company,
              AdditionalInstallment: ins.AdditionalInstallment,
            })
          })
        })
        setState({ bankInstallmentsData: res, installment: data })
      }
    })
  }

  const getCompanies = async () => {
    const getCompaniesApi: GetCompaniesApi = new GetCompaniesApi({})
    getCompaniesApi.getCompanies().then((res: ICompany[]) => {
      if (res) {
        const data: ICompany[] = res
        data.push({
          Name: 'Index (Param Pos)',
          Image: '',
          IyzipayMerchantKey: '',
          Id: 100,
          Code: '100'
        })
        setState({ companies: res })
      }
    })
  }

  const selectCompany = async (option: ISelectOption) => {
    const data: ISelectOption[] = []
    const dataCampaign: ISelectOption[] = []
    if (option.value === '100') {
      const getBankListApi: GetBankListApi = new GetBankListApi({})
      getBankListApi.getBankList().then((res: IBank[]) => {
        if (res) {
          res.map((ins) => {
            if (
              data.findIndex(dt => {
                return +dt.value === +ins.BankID
              }) === -1
            )
              return data.push({ value: ins.BankID, label: ins.BankName })
            return data
          })
          return setState({ companyOption: option, banks: data, campaigns: dataCampaign })
        }
      })
    }
    state.bankInstallmentsData?.PaymentInstallments.filter(pay => {
      return +pay.Company === +option.value
    }).map(payment =>
      payment.Installments.map(ins => {
        if (
          data.findIndex(dt => {
            return +dt.value === +ins.BankId
          }) === -1
        )
          data.push({ value: ins.BankId, label: ins.BankName })
        if (ins.PaymentTermsId > 0) {
          if (
            dataCampaign.findIndex(dt => {
              return +dt.value === +ins.PaymentTermsId
            }) > -1
          )
            return dataCampaign.push({ value: ins.PaymentTermsId, label: ins.PaymentTermsName })
        }
      })
    )
    setState({ companyOption: option, banks: data, campaigns: dataCampaign })
  }

  useEffect(() => {
    filterInstallment()
  }, [state.companyOption, state.typeOption, state.bankOption, state.campaignsOption])

  const filterInstallment = async () => {
    let data: IInstallments[] = []
    if (state.paramposBool) {
      if (state.bankOption) {
        const listParamPosInsatallmentApi: ListParamPosInsatallmentApi = new ListParamPosInsatallmentApi({})
        listParamPosInsatallmentApi.listParamPosInsatallment(+state.bankOption.value).then((res: IParamposList[]) => {
          if (res) {
            res.map((ins) => {
              return data.push({
                ...ins,
                BankId: String(ins.BankId),
                PaymentTermsId: 0,
                PaymentTermsName: '',
                Type: '',
                Company: '',
                AdditionalInstallment: ''
              })
            })
            return setState({ installment: data })
          }
        })
      }
    }
    if (!state.companyOption && !state.typeOption && !state.bankOption && !state.campaignsOption) {
      state.bankInstallmentsData?.PaymentInstallments.map(payment => {
        payment.Installments.map(ins => {
          return data.push({
            BankId: ins.BankId,
            BankName: ins.BankName,
            Id: ins.Id,
            Installment: ins.Installment,
            InterestRate: ins.InterestRate,
            PaymentTermsId: ins.PaymentTermsId,
            PaymentTermsName: ins.PaymentTermsName,
            Type: ins.Type,
            Company: payment.Company,
            AdditionalInstallment: ins.AdditionalInstallment
          })
        })
      })
      return setState({ installment: data })
    }
    if (state.companyOption) {
      state.bankInstallmentsData?.PaymentInstallments.filter(pay => {
        return state.companyOption?.value === +pay.Company
      }).map(payment =>
        payment.Installments.map(ins => {
          return data.push({
            BankId: ins.BankId,
            BankName: ins.BankName,
            Id: ins.Id,
            Installment: ins.Installment,
            InterestRate: ins.InterestRate,
            PaymentTermsId: ins.PaymentTermsId,
            PaymentTermsName: ins.PaymentTermsName,
            Type: ins.Type,
            Company: payment.Company,
            AdditionalInstallment: ins.AdditionalInstallment

          })
        })
      )
    }
    if (state.typeOption) {
      if (data.length > 0) {
        data = data.filter(ins => {
          return ins.Type === state.typeOption?.value
        })
      } else if (!state.companyOption) {
        state.bankInstallmentsData?.PaymentInstallments.map(payment => {
          payment.Installments.filter(insa => {
            return insa.Type === state.typeOption?.value
          }).map(ins => {
            return data.push({
              BankId: ins.BankId,
              BankName: ins.BankName,
              Id: ins.Id,
              Installment: ins.Installment,
              InterestRate: ins.InterestRate,
              PaymentTermsId: ins.PaymentTermsId,
              PaymentTermsName: ins.PaymentTermsName,
              Type: ins.Type,
              Company: payment.Company,
              AdditionalInstallment: ins.AdditionalInstallment

            })
          })
        })
      }
    }
    if (state.bankOption) {
      if (data.length > 0) {
        data = data.filter(ins => {
          return ins.BankId === state.bankOption?.value
        })
      } else if (!state.companyOption && !state.typeOption) {
        state.bankInstallmentsData?.PaymentInstallments.map(payment => {
          payment.Installments.filter(insa => {
            return insa.BankId === state.bankOption?.value
          }).map(ins => {
            return data.push({
              BankId: ins.BankId,
              BankName: ins.BankName,
              Id: ins.Id,
              Installment: ins.Installment,
              InterestRate: ins.InterestRate,
              PaymentTermsId: ins.PaymentTermsId,
              PaymentTermsName: ins.PaymentTermsName,
              Type: ins.Type,
              Company: payment.Company,
              AdditionalInstallment: ins.AdditionalInstallment
            })
          })
        })
      }
    }
    if (state.campaignsOption) {
      if (data.length > 0) {
        data = data.filter(ins => {
          return ins.PaymentTermsId === state.campaignsOption?.value
        })
      } else if (!state.companyOption && !state.typeOption) {
        state.bankInstallmentsData?.PaymentInstallments.map(payment => {
          payment.Installments.filter(insa => {
            return insa.PaymentTermsId === state.campaignsOption?.value
          }).map(ins => {
            return data.push({
              BankId: ins.BankId,
              BankName: ins.BankName,
              Id: ins.Id,
              Installment: ins.Installment,
              InterestRate: ins.InterestRate,
              PaymentTermsId: ins.PaymentTermsId,
              PaymentTermsName: ins.PaymentTermsName,
              Type: ins.Type,
              Company: payment.Company,
              AdditionalInstallment: ins.AdditionalInstallment
            })
          })
        })
      }
    }
    setState({ installment: data })
  }

  const excelButton = async () => {
    try {
      if (state.installment) {
        const data: any[] = []
        if (state.installment.length > 0) {
          state.installment.map((item, index) => {
            if (state.paramposBool) {
              return data.push({
                Sıra: index + 1,
                'Banka Adı': item.BankName,
                'Taksit': item.Installment,
                'Faiz Oranı': item.InterestRate,
              })
            }
            return data.push({
              Sıra: index + 1,
              'Banka Adı': item.BankName,
              'Şirket':
                (item.Company &&
                  state.companies.find(com => {
                    return +com.Code === +item.Company
                  })?.Name)
              ,
              'Ödeme Tipi': item.Type,
              'Taksit': item.Installment,
              'Ek Taksit Seçeneği': item.AdditionalInstallment,
              'Faiz Oranı': item.InterestRate,
              'Kampanya': item.PaymentTermsName,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Kredi Kartı Komisyon Oranları')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  return (
    <div className='col-md-12'>
      <div className={financialButtonStyles}>
        <IPButton
          className={`${state.operation === operationType.Cart ? 'active' : ''}`}
          name='order'
          onClick={() =>
            setState({
              operation: operationType.Cart,
              companyOption: undefined,
              banks: [],
              campaigns: [],
              bankOption: undefined,
              campaignsOption: undefined,
              typeOption: undefined,
            })
          }
          text='Sepet'
        />

        <IPButton
          className={`${state.operation === operationType.Payments ? 'active' : ''}`}
          name='company'
          onClick={() => setState({ operation: operationType.Payments })}
          text='Ödemelerim'
          style={{
            color: 'white',
            backgroundColor: 'red',
          }}
        />
      </div>

      {state.operation === operationType.Payments && (
        <div className='col-md-12'>
          <div className='row' style={{ marginBottom: '10px' }}>
            <div className='col-md-3'>
              <IPSelectBox
                isClearable
                placeholder='Şirket'
                options={state.companies
                  .filter(co => {
                    return co.Name !== 'Index Group'
                  })
                  .map(com => {
                    return { value: com.Code, label: com.Name }
                  })}
                value={state.companyOption ? state.companyOption : null}
                onChangeSingle={(option: ISelectOption) => {
                  if (option) {
                    selectCompany(option)
                    setState({ bankOption: undefined, campaignsOption: undefined, paramposBool: option.value === '100' })
                  } else
                    setState({
                      companyOption: undefined,
                      banks: [],
                      campaigns: [],
                      bankOption: undefined,
                      campaignsOption: undefined,
                    })
                }}
              />
            </div>
            <div className='col-md-3'>
              <IPSelectBox
                isClearable
                placeholder='Tip'
                options={types}
                isDisabled={state.paramposBool}
                value={state.typeOption ? state.typeOption : null}
                onChangeSingle={(option: ISelectOption) => {
                  if (option) setState({ typeOption: option })
                  else setState({ typeOption: undefined })
                }}
              />
            </div>
            <div className='col-md-3'>
              <IPSelectBox
                isClearable
                placeholder='Banka'
                options={state.banks}
                value={state.bankOption ? state.bankOption : null}
                onChangeSingle={(option: ISelectOption) => {
                  if (option) setState({ bankOption: option })
                  else setState({ bankOption: undefined })
                }}
              />
            </div>
            <div className='col-md-3'>
              <IPSelectBox
                isClearable
                placeholder='Kampanya'
                isDisabled={state.paramposBool}
                options={state.campaigns}
                value={state.campaignsOption ? state.campaignsOption : null}
                onChangeSingle={(option: ISelectOption) => {
                  if (option) setState({ campaignsOption: option })
                  else setState({ campaignsOption: undefined })
                }}
              />
            </div>
            {/* <div className='col-md-2'>
              <IPButton
                className='filter'
                name='filter'
                onClick={() => filterInstallment()}
                text='Süz'
                style={{
                  width: "25px"
                }}
              />
            </div> */}
          </div>
        </div>
      )}
      {state.operation === operationType.Cart && (
        <>
          <h5>Iyzico Sepet Oranları</h5>
          <div className='form-group form-fg'>
            <table className='table'>
              <thead className='table-light '>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '25%' }}></th>
                  <th style={{ textAlign: 'center', width: '40%' }}>Taksit Sayısı</th>
                  <th style={{ textAlign: 'center', width: '35%' }}>Oran</th>
                </tr>
              </thead>
              <tbody>
                {state.bankInstallmentsData &&
                  state.bankInstallmentsData.BasketInstallments.length > 0 &&
                  (state.bankInstallmentsData.BasketInstallments as any).map(installment => {
                    return (
                      <tr key={installment.Id}>
                        <td style={{ textAlign: 'center' }}><img src='../images/iyzico_ile_ode_colored_horizontal.png' style={{ width: '40%' }}></img></td>
                        <td style={{ textAlign: 'center' }}>{installment.TaksitSayisi && installment.TaksitSayisi}</td>
                        <td style={{ textAlign: 'center' }}>{installment.Oran && `${installment.Oran}%`}</td>
                      </tr>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </>
      )}
      {state.operation === operationType.Payments && (
        <>

          <div className='row mt-2 mb-2'>
            <div className='col-md-3'>
              <h5>Ödeme Oranları</h5>
            </div>

            <div className='col-md-9 d-flex' style={{ placeContent: 'flex-end' }}>
              <span>Komisyon Oranları Exceli: </span>
              <button
                type='button'
                style={{
                  border: '1px solid #2e7d32',
                  borderRadius: 0,
                  width: '29px',
                  height: '29px',
                  backgroundColor: 'white',
                  margin: '0 10px',
                }}
                onClick={excelButton}
                className='btn text-capitalize background-white-important d-flex align-items-center'
              >
                <i
                  className='fas fa-file-excel'
                  style={{
                    marginLeft: '-6px',
                    fontSize: '18px',
                    color: '#2e7d32',
                  }}
                ></i>
              </button>
            </div>
          </div>

          <div className='form-group form-fg'>
            <table className='table'>
              <thead className='table-light'>
                <tr style={{ textAlign: 'center' }}>
                  <th style={{ width: '250px' }}>Banka</th>
                  {!state.paramposBool && <th>Şirket</th>}
                  {!state.paramposBool && <th>Ödeme Tipi</th>}
                  <th>Taksit</th>
                  <th>Faiz Oran</th>
                  {!state.paramposBool && <th>Kampanya</th>}
                </tr>
              </thead>
              <tbody>
                {state.installment &&
                  state.installment.length > 0 &&
                  (state.installment as any)?.sort((a, b) => {
                    // Önce Company'ye göre sıralama
                    const companyComparison = a.Company - b.Company;
                    if (companyComparison !== 0) {
                      return companyComparison;
                    }

                    // Sonra Type'a göre sıralama
                    const typeComparison = b.Type - a.Type;
                    if (typeComparison !== 0) {
                      return typeComparison;
                    }

                    // Ardından BankName'e göre sıralama
                    if (a.BankName === "Diğer Bankalar" && b.BankName !== "Diğer Bankalar") {
                      return 1; // "Diğer Bankalar" en sonda olsun
                    } else if (a.BankName !== "Diğer Bankalar" && b.BankName === "Diğer Bankalar") {
                      return -1; // "Diğer Bankalar" en sonda olsun
                    } else {
                      // "Diğer Bankalar" değilse, alfabetik sıralamaya devam et
                      const bankNameComparison = a.BankName.localeCompare(b.BankName);
                      if (bankNameComparison !== 0) {
                        return bankNameComparison;
                      }
                    }

                    // En son Installment'a göre numerik sıralama
                    return a.Installment - b.Installment;
                  })
                    .map(installment => {
                      return (
                        <tr role='row' className='odd' style={{ textAlign: 'center' }} key={installment.Id}>
                          <td>
                            <IPImage
                              src={
                                (state.creditCardCommissionRates as any).find(bank => bank.Id === installment.BankId)?.Bank
                              }
                              alt=''
                              className=''
                              style={{}}
                            />
                          </td>
                          {
                            !state.paramposBool &&
                            <td>
                              {installment.Company &&
                                state.companies.find(com => {
                                  return +com.Code === +installment.Company
                                })?.Name}
                            </td>
                          }
                          {
                            !state.paramposBool &&
                            <td>
                              {installment.Type &&
                                types.find(type => {
                                  return type.value === installment.Type
                                })?.label}
                            </td>
                          }
                          <td>{installment.Installment}{installment.AdditionalInstallment && " " + installment.AdditionalInstallment}</td>
                          <td>{`${installment.InterestRate}%`}</td>
                          {!state.paramposBool && <td>{installment.PaymentTermsName}</td>}

                        </tr>
                      )
                    })}
              </tbody>
            </table>
          </div>
        </>
      )}

      <div className='form-group form-fg' style={{ marginTop: '50px' }}>
        <li>
          Diğer bankalar seçenegi kayıtlı olmayan bankalar için geçerlidir. Bu seçenekle 3D işlem yapılamamaktadır.
        </li>
        <li>YKB 300 TL altındaki işlemlerinize banka tarafından taksit uygulanmamaktadır.</li>
        <li>İş Bankasında 500TL ve altı çekimlerde taksitlendirme yapılmamaktadır.</li>
        <li>Akbank' ta 100TL ve altı çekimlerde taksitlendirme yapılmamaktadır.</li>
        <li>Yukarıdaki tabloda bulunan oranlar KDV dahil toplam maliyetlerinizdir.</li>
      </div>
    </div>
  )
}
