import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IBanner } from '../commonModels/models'

export class GetBannersApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'homepage/banner-list',
      requesterInfo,
    })
  }

  public async getBanners(): Promise<IBanner[]> {
    return await this.getAsync<IBanner[]>()
  }
}
