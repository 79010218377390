import { ISapList } from '../models/responseModels'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class GetSapListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/sapSyncHistory',
      requesterInfo,
    })
  }

  public async getSyncHistory(req: any): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
