import { IRequesterInfo } from '../../../base/models'
import { ClientProxy } from '../../../base/proxy/clientProxy'
import { IGetBasketListResponse } from '../models/models'

export class GetUserBasketListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'basket2/getUserBaskets',
      requesterInfo,
    })
  }

  public async getBaskets(): Promise<IGetBasketListResponse> {
    return await this.getAsync<IGetBasketListResponse>()
  }
}
