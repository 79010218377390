import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IGuarantee, IWarrantyTracking } from '../models/models'

export class GetGuaranteeDetailApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/GetGuaranteeDetail',
      requesterInfo,
    })
  }

  public async getGuaranteeDetail(request: IWarrantyTracking): Promise<IGuarantee> {
    return await this.postAsync<IGuarantee>(request)
  }
}
